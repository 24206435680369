import React from "react";
import Base from "./Base";
import Input from "./Input";

// SeparatedInput is a super class of common separator input
// components. (For example: PhoneInput, ZipcodeInput).
//
// Basically, it is used to unify the internal state management
// behavior.
class SeparatedInput extends Base {
  constructor(props) {
    super(props);

    this.parts = 3;
    this.separator = "-";

    this.state = this._getState(props);
    this.onChangeField = this.onChangeField.bind(this);
  }

  setParts(parts, props) {
    this.parts = parts;
    this.state = this._getState(props);
  }

  _getState({ value }) {
    const state = {};
    const values = (value || "").split(this.separator);

    for (let i = 0; i < this.parts; i++) {
      state[i] = values[i] || "";
    }

    return state;
  }

  _getValue(state) {
    const values = [];

    for (let i = 0; i < this.parts; i++) {
      values.push(state[i]);
    }

    if (values.every(val => !val)) {
      return "";
    }

    return values.join(this.separator);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentValue = this._getValue(this.state);
    if (nextProps.value !== currentValue) {
      this.setState({ ...this._getState(nextProps) });
    }
  }

  onChangeField(field) {
    const _this = this;

    return value => {
      const newState = { ..._this.state, [field]: value };
      _this.setState(newState);

      const newValue = this._getValue(newState);
      _this.props.onChange(newValue);
    };
  }

  getSeparatedParts() {
    let parts = [];
    for (let i = 0; i < this.parts; i++) {
      parts.push({ name: i, value: this.state[i] });
    }
    return parts;
  }

  _renderInputs() {
    return this.getSeparatedParts().map(({ name, value }, idx) => (
      <Input
        key={idx}
        type="text"
        value={value}
        onChange={this.onChangeField(name)}
      />
    ));
  }

  render() {
    return <div>{this._renderInputs()}</div>;
  }
}

SeparatedInput.defaultProps = {
  ...Base.defaultProps
};

SeparatedInput.propTypes = {
  ...Base.propTypes
};

export default SeparatedInput;
