import { uploadPLPToGA } from "../GA";
import { toCamelCase, getStartIndex } from "./helper";

export const uploadPLPImpressionsData = () => {
  const $recommendsComponent = document.querySelector(".js-recommendations");

  if ($recommendsComponent) {
    const $products = $recommendsComponent.querySelector(
      "div[data-tracing-source]"
    );

    if ($products) {
      const products = $products.dataset.tracingSource;

      uploadPLPToGA({
        products: JSON.parse(products, toCamelCase),
        startIndex: getStartIndex()
      });
    }
  }
};

class UploadRecommendsData {
  constructor() {
    this.init();
  }

  init() {
    uploadPLPImpressionsData();
  }
}

export default UploadRecommendsData;
