import $ from "jquery";
import debounce from "lodash.debounce";

class AboutPage {
  constructor() {
    this.bindEvents();

    if ($(window).width() >= 768) {
      $("header > .topbar-wrapper + .main-menu").hide();
    }
  }

  handleResize() {
    if ($(window).width() < 768) {
      $("header > .topbar-wrapper + .main-menu").show();
    } else {
      $("header > .topbar-wrapper + .main-menu").hide();
    }
  }

  bindEvents() {
    $(".heritage-nav .js-nav-tab").on(
      "click",
      this.handleClickNavTab.bind(this)
    );
    $(".heritage .js-nav-tab").on(
      "click",
      this.handleClickNavTabAndScrollTop.bind(this)
    );
    $(window).on("resize", debounce(this.handleResize, 250));
  }

  handleClickNavTab(event) {
    event.preventDefault();

    const $target = $(event.currentTarget);
    const contentId = $target.attr("href");
    if ($(contentId).hasClass("active")) {
      return false;
    } else {
      $(".heritage-nav .js-nav-tab")
        .parent()
        .removeClass("active");
      $(`[href="${contentId}"]`)
        .parent()
        .addClass("active");

      $(".heritage").removeClass("active");
      $(contentId).addClass("active");
    }
  }

  handleClickNavTabAndScrollTop(event) {
    this.handleClickNavTab(event);

    $("html, body").animate({ scrollTop: 0 }, 300);
  }
}

export default AboutPage;
