// Copied from https://github.com/theplant/lacoste3/blob/4854474f95b9f93701554b0b53ab60870b98d574/frontend/gtm.tsx#L101-L111
const gtmDataLayerPush = (data: {
  [key: string]:
    | string
    | number
    | Array<{ [key: string]: string | number | null | undefined }>
    | null
    | undefined
    | Object;
}) => {
  window && window.dataLayer && window.dataLayer.push(data);
};

export { gtmDataLayerPush };
