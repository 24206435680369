//Copy from https://github.com/theplant/mastani/blob/25fe97bd65ddb3ff2ebc78bf7bf004fb5cdff86f/frontend/form.tsx

import * as React from "react";
import * as R from "ramda";
import { ProjectionClass } from "@theplant/ecjs/projection";

import { proto } from "./proto";

export interface State<T> {
  input: T;
  error?: proto.IValidationError;
  dirtyFields: { [key: string]: true };
  submitState: "new" | "submitting" | "submitted" | "submit-failed";
}

// any here is because the type of `input` depends on the value of
// `path`
type UpdateAction = { type: "UPDATE_INPUT"; path: string[]; input: any };

export type Action =
  | UpdateAction
  | { type: "SET_ERRORS"; error: proto.ValidationError }
  | { type: "ADD_ERROR"; error: proto.ValidationError.IFieldViolation }
  | { type: "TOUCH_INPUT"; path: string[] }
  | { type: "SUBMIT_FAILED"; error: proto.ValidationError }
  | { type: "RESET_FORM_STATE" };

const deepMap = (
  state: any = {},
  { type, path: inputPath, input }: UpdateAction
): any => {
  const [key, ...path] = inputPath;
  if (key != null) {
    const val = state[key];
    let newVal = val;
    if (path.length > 0) {
      newVal = deepMap(newVal, {
        type,
        input,
        path
      });
    } else {
      newVal = input;
    }
    return {
      ...(state as any),
      [key]: newVal
    };
  }
  return state;
};

const reducer = <T extends {}>(input: T) => {
  const initialState: State<T> = {
    input,
    dirtyFields: {},
    submitState: "new"
  };

  return (s = initialState, a: Action): State<T> => {
    switch (a.type) {
      case "UPDATE_INPUT":
        return {
          ...s,
          // Not type-safe as deepMap is `any => any`, it relies on
          // the path and input of `a` being correct.
          input: deepMap(s.input, a)
        };
      case "TOUCH_INPUT":
        const fv = (s.error && s.error.fieldViolations) || [];
        // remove the existing errors of this field
        // to fix:
        // when some of the fields associated with each other,
        // if you touched one of these fields, others errors also returned
        // then you touched another one, that field was marked dirty, its error displayed even though the input was correct
        // ref: https://github.com/theplant/aigle/issues/1516
        const newFV = fv.filter(
          f =>
            f.field && f.field.toLowerCase() !== a.path.join(".").toLowerCase()
        );

        return {
          ...s,
          dirtyFields: {
            ...s.dirtyFields,
            [a.path.join(".").toLowerCase()]: true
          },
          error: {
            ...s.error,
            fieldViolations: newFV
          }
        };
      case "SET_ERRORS":
        return {
          ...s,
          error: a.error
        };
      case "ADD_ERROR":
        const oldFV = (s.error && s.error.fieldViolations) || [];

        if (oldFV.some(i => R.equals(i, a.error))) {
          return s;
        }

        return {
          ...s,
          error: {
            ...s.error,
            fieldViolations: oldFV.concat(a.error)
          }
        };
      case "SUBMIT_FAILED":
        return {
          ...s,
          submitState: "submit-failed",
          error: a.error
        };
      case "RESET_FORM_STATE":
        return { ...s, dirtyFields: {}, submitState: "new" };
    }
    return s;
  };
};

type DispatchProps<T> = {
  updateInput: (input: T) => void;
  touchInput: () => void;
};

const setErrors = (error: proto.ValidationError): Action => ({
  type: "SET_ERRORS",
  error
});

const addError = (error: proto.ValidationError.IFieldViolation): Action => ({
  type: "ADD_ERROR",
  error
});

const submitError = (error: proto.ValidationError): Action => ({
  type: "SUBMIT_FAILED",
  error
});

function updateInput(path: string[]) {
  return (input: any) => ({
    type: "UPDATE_INPUT",
    path,
    input
  });
}

function touchInput(path: string[]) {
  return () => ({
    type: "TOUCH_INPUT",
    path
  });
}

const resetFormState = (): Action => ({
  type: "RESET_FORM_STATE"
});

////////////////////////////////////////
// VIEW

export type FieldProps<T, A> = DispatchProps<T> & {
  value: T | undefined | null;
  errors: proto.ValidationError.IFieldViolation[];
  dirty: boolean;
} & A;

function lookup(p: string[], s: any) {
  let path = p;
  let value = s.input;
  while (path.length >= 1 && value != null) {
    let [key, ...rest] = path;
    path = rest;
    value = value[key];
  }
  return value;
}

const empty: proto.ValidationError.IFieldViolation[] = [];

function filterE(
  p: string[],
  err?: proto.IValidationError
): proto.ValidationError.IFieldViolation[] {
  if (err == null || err.fieldViolations == null) {
    return empty;
  }

  const path = p.join(".").toLowerCase();
  const errors = err.fieldViolations.filter(
    ({ field }) => field && field.toLowerCase() === path
  );
  return errors.length > 0 ? errors : empty;
}

function buildSelector<T>(p: string[]) {
  return (s: State<T>) => {
    const value = lookup(p, s);
    const errors = filterE(p, s.error);
    const dirty =
      s.dirtyFields[p.join(".").toLowerCase()] ||
      s.submitState === "submit-failed";
    return { value, errors, dirty };
  };
}

type InputProps<T, A> = {
  f: Form<T, A>;
  field?: Extract<keyof T, string>;
  component: React.ComponentType<FieldProps<T, A> | FieldProps<T[keyof T], A>>;
};

class Input<T, A> extends React.PureComponent<InputProps<T, A>> {
  private K: React.ComponentType<{}>;

  constructor(props: InputProps<T, A>) {
    super(props);

    const { f, component } = this.props;

    let path = props.field == null ? f.path : f.path.concat(props.field);
    const mstp = buildSelector(path);
    this.K = f.projection.connect(mstp, {
      ...(f.actions as any), // Typescript can't handle spreading into generic types
      updateInput: updateInput(path),
      touchInput: touchInput(path)
    })(component as any); // FIXME any
  }

  render() {
    return <this.K />;
  }
}

type InputFieldProps<T, A> = {
  component: React.ComponentType<FieldProps<T, A>>;
};

type FocusedInputFieldProps<T, A, K extends keyof T> = {
  field: K;
  component: React.ComponentType<FieldProps<T[K], A>>;
};

type PartialNullable<T> = { [P in keyof T]?: T[P] | null };

type PartialMap<T, U> = (PartialNullable<T>) & U;

class Form<T, A> {
  constructor(
    public path: string[],
    public actions: A,
    public projection: ProjectionClass
  ) {
    this.field = this.field.bind(this);
  }

  // This can't be a method as it needs to have `f` in params to be
  // able to infer `string` from `T[K] = string | undefined`
  static on<T extends {}, U extends {}, K extends Extract<keyof T, string>, A>(
    f: Form<PartialMap<T, U>, A>,
    key: K
  ): Form<T[K], A> {
    return new this([...f.path, key], f.actions, f.projection);
  }

  on = <K extends Extract<keyof T, string>>({
    field,
    component
  }: FocusedInputFieldProps<T, A, K>) => {
    const P = Input as React.ComponentType<InputProps<T, A>>;
    // FIXME any
    return <P f={this} field={field} component={component as any} />;
  };

  field<P extends InputFieldProps<T, A>>({
    component
  }: {
    component: P["component"];
  }): React.ReactElement<any> {
    const C = Input as React.ComponentType<InputProps<T, A>>;
    // FIXME any
    return <C f={this} component={component as any} />;
  }
}

export {
  reducer,
  Form,
  setErrors,
  updateInput,
  touchInput,
  addError,
  submitError,
  resetFormState
};
