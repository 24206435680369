import * as React from "react";

import cx from "classnames";

import { formatCurrency } from "../../components/helpers/currency";

import { selectItemIsDiscounted } from "../../orders";

import { theplant } from "../../proto";
type IOrderItem = theplant.ec.service.orders.IOrderItem;

const Price = ({ orderItem }: { orderItem: IOrderItem }) => (
  <>
    <p className="price">
      <span className={cx({ original: selectItemIsDiscounted(orderItem) })}>
        {formatCurrency(
          orderItem.sumUp &&
            orderItem.sumUp.prime &&
            orderItem.sumUp.prime.amountWithTax
        )}
      </span>
      {selectItemIsDiscounted(orderItem) ? (
        <span className="sale">
          {formatCurrency(
            orderItem.sumUp &&
              orderItem.sumUp.discounted &&
              orderItem.sumUp.discounted.amountWithTax
          )}
        </span>
      ) : null}
      {orderItem.sumUp && orderItem.sumUp.calculatedDiscountRate ? (
        <span className="off-tag">{`-${orderItem.sumUp.calculatedDiscountRate}%`}</span>
      ) : null}
    </p>
  </>
);

export { Price };
