import { createContext } from "react";
import { proto } from "../../proto";

interface ICheckoutErrorContext {
  errors: proto.ValidationError.IFieldViolation[];
  isSubmitFailed: boolean;
}

export const CheckoutErrorContext = createContext<ICheckoutErrorContext>({
  errors: [],
  isSubmitFailed: false,
});