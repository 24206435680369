import { ORDER_STATES } from "../constants/Texts";
import { formatDateString } from "../helper";
import { translatePrefecture as translateAddressPrefecture } from "../components/helpers/address";
import {
  mapProtoOrderItemToOrderItemDataProps,
  mapProtoSumUpToSumUpSubItemDataProps,
} from "./checkout";

// Refer result: '東京都品川区東田中4-22-15 鈴木 育子様',
const _formatShippingAddress = (address = {}) => {
  const { prefectureJapanese, city, address1, address2, name } = address;
  return [prefectureJapanese, city, address1, address2, " ", name, "様"].join(
    ""
  );
};

// Refer result: '福岡県福岡市博多区博多駅中央街1-1 JR博多シティ アミュプラザ博多7Fエーグルアミュプラザ博多'
const _formatStoreAddress = (address = {}) => {
  const { prefecture, city, address1, name } = address;
  return [prefecture, city, address1, name].join("");
};

const getId = (protoOrder = {}) => {
  const { code } = protoOrder;
  return code;
};

const isCancellable = (protoOrder = {}) => {
  const { user_can_cancel = false } = protoOrder;
  return user_can_cancel;
};

const isCancelled = (protoOrder = {}) => {
  const { patched_sum_up } = protoOrder;
  return !!patched_sum_up;
};

const isDraft = (protoOrder = {}) => {
  const { state } = protoOrder;
  return !state || state === 0;
};

const mapProtoOrderToOrderDataProp = (
  protoOrder = {},
  prefectureNameToJapaneseMap = {}
) => {
  const {
    code,
    state,
    confirmed_at,
    shipping_address,
    order_items = [],
    sum_up,
    store_order_info,
    convenience_pickup_info,
    shipment,
    shipment_packages,
  } = protoOrder;

  const shippingAddress = translateAddressPrefecture(
    shipping_address,
    prefectureNameToJapaneseMap
  );
  const shippingAddressInfo = _formatShippingAddress(shippingAddress);
  const _isCancelled = isCancelled(protoOrder);

  // It is a deliver to store order
  let storeOrderAddress = "";
  let storeOrderTel = "";
  let isUserCancelUnallowed = false;
  if (
    store_order_info &&
    store_order_info.pickup_store &&
    store_order_info.pickup_store.address
  ) {
    storeOrderAddress = _formatStoreAddress(
      store_order_info.pickup_store.address
    );
    storeOrderTel = store_order_info.pickup_store.address.phone;
  }
  if (store_order_info && !!store_order_info.is_user_cancel_unallowed) {
    isUserCancelUnallowed = true;
  }

  const combiniInfo =
    convenience_pickup_info && convenience_pickup_info.store_info;
  const combiniAddress =
    (combiniInfo && (combiniInfo.saddr || "") + (combiniInfo.sname || "")) ||
    "";
  const combiniTel = (combiniInfo && combiniInfo.stel) || "";

  // FIXME the patched_sum_up is always zero. Is it good to display in the frontend?
  // const sumUp = _isCancelled ? patched_sum_up : sum_up
  const sumUp = sum_up;

  return {
    id: getId(protoOrder),
    code,
    state,
    formattedState: ORDER_STATES[state],
    confirmed_at,
    formattedOrderDate: formatDateString(confirmed_at),
    shipping_address,
    shippingAddress,
    shippingAddressInfo,
    storeOrderAddress,
    storeOrderTel,
    combiniAddress,
    combiniTel,
    // shows secret code when order is SHIPPED(state = 5)
    // see https://github.com/theplant/aigle/blob/3c674a7044f109a1d3320a1cf4ea9e0865cdcc9d/aigleapp/front/src/constants/Texts.js#L40-L64
    secretCode:
      state === 5
        ? convenience_pickup_info && convenience_pickup_info.secret_code
        : "",
    shipmentId: shipment && shipment.id,
    orderItems: order_items.map(mapProtoOrderItemToOrderItemDataProps),
    sumUpItems: mapProtoSumUpToSumUpSubItemDataProps(sumUp || {}),
    isCancellable: isCancellable(protoOrder),
    isUserCancelUnallowed,
    isCancelled: _isCancelled,
    shipmentPackages: shipment_packages,
  };
};

export {
  _formatShippingAddress,
  _formatStoreAddress,
  getId,
  isDraft,
  isCancellable,
  isCancelled,
  mapProtoOrderToOrderDataProp,
};
