// copy from https://github.com/theplant/boostjs/blob/6404f567d33c3bf0bab2bc41653328d4b9821431/boost-aigle/AigleUI/helper.ts#L1-L36
function hyphensToCamelCase(str: string) {
  var arr = str.split(/[_-]/);
  var newStr = "";
  for (var i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr[0] + newStr;
}

function toCamel(o: Object) {
  let newO: { [key: string]: string } = {};

  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === "object") {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    Object.entries(o).forEach(([key, value]) => {
      if (o.hasOwnProperty(key)) {
        let newKey = hyphensToCamelCase(key).toString();
        if (value instanceof Array || (value && value.constructor === Object)) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    });
  }

  return newO;
}

export { toCamel };
