import { getCreditCardError } from "../shared/getCreditCardError";

export const handlerCreditCardError = (
  fieldErrors: any[],
  cvcString?: string
) => {
  const result: any = {};
  fieldErrors?.forEach((errorItem) => {
    const { responseField, msg } = getCreditCardError([errorItem], cvcString);
    const newErrorItem = [{ ...errorItem, msg: msg || errorItem.msg }];
    switch (responseField) {
      case "card_number":
        result.numberError = newErrorItem;
        break;
      case "cardholder_name":
        result.nameError = newErrorItem;
        break;
      case "card_year":
        result.yearError = newErrorItem;
        break;
      case "card_month":
        result.monthError = newErrorItem;
        break;
      case "card_valid_term":
        result.termError = newErrorItem;
        break;
      case "card_conf_number":
        result.confError = newErrorItem;
        break;
      default:
        //   The error from creditCard will only need to display the field with `creditCard`
        if (errorItem?.field?.toLowerCase().includes("creditcard")) {
          result.otherError = newErrorItem;
        }
        break;
    }
  });
  return result;
};
