import React from "react";
import { CUSTOMER_CALL_NUMBER } from "../../../constants/Texts";
import Divider from "../../molecules/Divider";

const CannotCancel = () => (
  <>
    {/* eslint-disable-next-line */}
    <p className="desc">
      お客様のご注文は準備中になっていますので、取り消しご希望の場合はカスタマーサポートにお問い合わせください。
    </p>
    <Divider />
    <p className="support-title">
      <svg
        width="36"
        height="13"
        viewBox="0 0 36 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.16 12H0.064L3.168 0.447999H5.808L8.912 12H6.816L6.384 10.288C6.32 10.048 6.176 9.936 5.936 9.936H3.04C2.8 9.936 2.656 10.048 2.592 10.288L2.16 12ZM3.552 8.16H5.424C5.696 8.16 5.84 8 5.776 7.712L4.736 3.632C4.64 3.232 4.336 3.232 4.24 3.632L3.2 7.712C3.136 8 3.28 8.16 3.552 8.16ZM12.0248 12H9.92875V0.447999H12.0248V12ZM16.9204 12.16C15.1124 12.16 13.7204 11.024 13.7204 8.448V4.08C13.7204 1.408 15.2084 0.271999 17.3524 0.271999C19.3524 0.271999 20.7604 1.216 20.9204 3.552H18.8244C18.7284 2.528 18.1844 2.128 17.3524 2.128C16.3924 2.128 15.8324 2.736 15.8324 3.84V8.592C15.8324 9.696 16.3924 10.304 17.3524 10.304C18.1844 10.304 18.8244 9.792 18.8244 8.64V8.144C18.8244 7.888 18.6804 7.744 18.4244 7.744H17.4164V6.032H20.9204V12H19.4644L19.3364 11.52C19.2564 11.2 19.0804 11.168 18.8404 11.424C18.3924 11.904 17.7364 12.16 16.9204 12.16ZM28.4868 12H22.6788V0.447999H24.7748V9.776C24.7748 10.032 24.9188 10.176 25.1748 10.176H28.4868V12ZM35.598 12H29.71V0.447999H35.534V2.272H32.206C31.95 2.272 31.806 2.416 31.806 2.672V4.72C31.806 4.976 31.95 5.12 32.206 5.12H34.926V6.96H32.206C31.95 6.96 31.806 7.104 31.806 7.36V9.776C31.806 10.032 31.95 10.176 32.206 10.176H35.598V12Z"
          fill="black"
        />
      </svg>
      お客様サポート
    </p>
    <p className="tel">{CUSTOMER_CALL_NUMBER}</p>
    <p className="time">電話受付時間 : 10時～17時（1月1日を除く）</p>
  </>
);

export default CannotCancel;
