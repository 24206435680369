import {
    init as initSticky,
} from "../CheckoutApp/shared/StickyHelper";

class StickyContent {
    constructor() {
        initSticky()
    }
}

export default StickyContent;