import { newCustomError } from "../helper";
// The following text is copied from https://github.com/theplant/lacoste/blob/master/config/locales/en.yml

// en.address.create_successfully
export const ADDRESS_CREATE_SUCCESSFULLY = "住所を追加しました。"; // TODO useless messages
// en.address.update_successfully
export const ADDRESS_UPDATE_SUCCESSFULLY = "住所を更新しました。"; // TODO useless messages

export const ADDRESS_DELETE_CONFIRM = "本当によろしいですか？";
export const ADDRESS_DELETE_SUCCESSFULLY = "住所を削除しました。";

export const CREDIT_CARD_DELETE_CONFIRM = "本当によろしいですか？";
export const DELETE_CREDIT_CARD_SUCCESSFULLY =
  "クレジットカードが削除されました。"; // TODO remove useless messages

export const CHECKOUT_APPLY_SHIPPING_ADDRESS_SUCCESSFULLY =
  "Apply shipping address successfully!"; // TODO remove useless messages
export const CHECKOUT_APPLY_BILLING_ADDRESS_SUCCESSFULLY =
  "Apply billing address successfully!"; // TODO remove useless messages

export const CHECKOUT_APPLY_COD_SUCCESSFULLY = "Apply COD successfully!"; // TODO remove useless messages
export const CHECKOUT_APPLY_NEW_CREDIT_CARD_SUCCESSFULLY =
  "Apply new credit card successfully!"; // TODO remove useless messages
export const CHECKOUT_APPLY_EXISTING_CREDIT_CARD_SUCCESSFULLY =
  "Apply existing credit card successfully!"; // TODO remove useless messages

export const INTERNAL_SERVER_ERROR = "内部エラーが発生しました。";

export const ORDER_CANCEL_CONFIRM = "本当によろしいですか？";

export const CHECKOUT_APPLY_POINTS_ERROR_INVALID_POINTS =
  "特典コードが間違っています。";
export const CHECKOUT_APPLY_POINTS_ERROR_OVER_THE_LIMIT =
  "お持ちのポイント及び購入金額を超過しています。";

export const CREDIT_CARD_IS_INVALID = "無効なクレジットカード。";

export const COMMON_CREDIT_CARD_ERROR = newCustomError(CREDIT_CARD_IS_INVALID);

export const ERR_NOT_ENOUGH_STOCK =
  "申し訳ありません。商品は在庫切れのため購入できません。";
