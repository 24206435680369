// Copied from https://github.com/theplant/lacoste3/blob/master/frontend/protoHelper.ts
import { theplant } from "./proto";
type IProperty = theplant.ec.service.base.IProperty;

// Copied directly from: https://github.com/theplant/mastani/blob/38ef57b/frontend/products/productField.ts#L98-L113
const getProperty = (
  propertyName: string,
  properties?: IProperty[] | null
): string[] | null => {
  if (!properties) {
    return null;
  }

  const property = properties.find(({ field }) => field === propertyName);

  if (!property || !property.values) {
    return null;
  }

  return property.values;
};

// copy from https://github.com/theplant/ecjs/blob/cf3ac57bb5d0ba51310431fc68b20500be8db54e/packages/lacoste/protoHelper.ts#L22-L46
const getAllProperties = (
  propertyName: string,
  properties?: IProperty[] | null
): string[] | null => {
  if (!properties) {
    return null;
  }

  const filterProperties = properties.filter(
    ({ field }) => field === propertyName
  );

  if (!filterProperties || !filterProperties.values) {
    return null;
  }

  let allValues: string[] = [];
  filterProperties.forEach(({ values }) => {
    if (values) {
      allValues = allValues.concat(values);
    }
  });

  return allValues;
};

const firstPropertyValue = (
  propertyName: string,
  properties?: IProperty[] | null
): string | null => {
  const values = getProperty(propertyName, properties);

  return (values && values[0]) || null;
};

export { getProperty, firstPropertyValue, getAllProperties };
