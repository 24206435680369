import * as React from "react";

import { Dispatch } from "redux";

import { IServiceError, makeService } from "@theplant/ecjs/prottp";
import { withContext, ContextProps, Context } from "@theplant/ecjs/context";
import { KeyedProjection } from "@theplant/ecjs/projection";

import {
  setErrors,
  Form,
  State as FormState,
  reducer as formReducer,
  updateInput,
  submitError,
} from "../form";
import { takeLatest } from "redux-saga/effects";

import { theplant, proto } from "../proto";
import { PrefillAddressAction, prefill } from "./order";
type ILoginInfo = theplant.ec.api.users.ILoginInfo;
type ISetInitialPasswordParams =
  theplant.ec.api.users.ISetInitialPasswordParams;
type IEmailStatus = theplant.ec.api.users.IEmailStatus;
type IUser = theplant.ec.service.users.IUser;
type IRegister = theplant.ec.api.users.IRegisterParams;
type IAddress = theplant.ec.service.users.IAddress;
const LoginService = theplant.ec.api.users.LoginService;
const SetUserInitialPasswordService =
  theplant.ec.api.users.SetUserInitialPasswordService;

type InternalState = {
  loginForm: ILoginInfo;
  isEmailExists: boolean;
  registerForm: ISetInitialPasswordParams;
};

type State = FormState<InternalState>;

const reducer = formReducer<InternalState>({
  loginForm: {},
  isEmailExists: false,
  registerForm: {},
});

type Action =
  | { type: "UPDATE_INPUT"; path: string[]; input: any }
  | {
      type: "SUBMIT_FAILED" | "SET_ERRORS";
      error: proto.ValidationError;
    };

type DP = {
  checkEmail: (email: string) => Promise<boolean | void>;
  login: (
    email: string,
    password: string,
    orderCode?: string
  ) => Promise<IUser | null | void>;
  register: (form: ISetInitialPasswordParams) => Promise<boolean | void>;
  reset: () => void;
};

export type UsersProps = { users: State & DP };

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  { requestContext }: ContextProps
): DP => ({
  checkEmail: checkEmail(dispatch, requestContext),
  login: login(dispatch, requestContext),
  register: register(dispatch, requestContext),
  reset: reset(dispatch),
});

const selectIsEmailExists = (emailStatus: IEmailStatus) =>
  emailStatus &&
  emailStatus.status ===
    theplant.ec.api.users.EmailStatus.Status.EXISTS_AND_HAS_PASSWORD;

const didCheckEmail =
  (dispatch: Dispatch<Action>) => (isEmailExists: boolean) => {
    dispatch(updateInput(["isEmailExists"])(isEmailExists) as Action);
    // clear errors
    dispatch(setErrors(new proto.ValidationError()) as Action);
  };

const reset = (dispatch: Dispatch<Action>) => () => {
  didCheckEmail(dispatch)(false);
};

const didFetchError =
  (dispatch: Dispatch<Action>, form: string) => (error: IServiceError) => {
    if (
      error.type === "validation-error" &&
      error.errors.fieldViolations &&
      error.errors.fieldViolations.length > 0
    ) {
      let err = error.errors;

      err.fieldViolations = error.errors.fieldViolations.map((e) => ({
        ...e,
        field: form + "." + e.field,
      }));

      dispatch(submitError(err as proto.ValidationError) as Action);
    }
  };

const checkEmail =
  (dispatch: Dispatch<Action>, context: Context) =>
  (email: string): Promise<boolean | void> =>
    makeService(LoginService, context)
      .checkEmail({ email })
      .then((emailStatus: IEmailStatus) => {
        const isEmailExists = selectIsEmailExists(emailStatus);

        didCheckEmail(dispatch)(isEmailExists);

        return isEmailExists;
      }, didFetchError(dispatch, "LoginForm"));

const didLogin = (dispatch: Dispatch<Action>) => () => {
  // clear loginForm after logged in, reset isEmailExists to false
  dispatch(updateInput(["loginForm"])({}) as Action);
  dispatch(updateInput(["isEmailExists"])(false) as Action);
};

const login =
  (dispatch: Dispatch<Action>, context: Context) =>
  (
    email: string,
    password: string,
    orderCode?: string
  ): Promise<IUser | null | void> =>
    makeService(LoginService, context)
      .login({ email, password, orderCode })
      .then(({ user }) => {
        if (user) {
          didLogin(dispatch)();
          return user;
        } else {
          return null;
        }
      }, didFetchError(dispatch, "LoginForm"));

const didRegister = (dispatch: Dispatch<Action>) => () => {
  // clear registerForm after logged in, reset isEmailExists to false
  dispatch(updateInput(["registerForm"])({}) as Action);
  dispatch(updateInput(["isEmailExists"])(false) as Action);
};

const register =
  (dispatch: Dispatch<Action>, context: Context) =>
  (form: ISetInitialPasswordParams): Promise<boolean | void> =>
    makeService(SetUserInitialPasswordService, context)
      .setUserInitialPassword(form)
      .then(() => {
        didRegister(dispatch)();

        return true;
      }, didFetchError(dispatch, "RegisterForm"));

const Projection = KeyedProjection("users");

function* userFormSaga({ context }: { context: Context }) {
  yield takeLatest("PREFILL_FROM_POSTAL_CODE", prefill, context);
}

const UserFormSaga = Projection.saga(userFormSaga);

const withUsersService = <Props extends {}>(
  ns: string,
  C: React.ComponentType<Props & UsersProps>
): React.ComponentType<Props> => {
  const Connected = Projection.connect(
    (s: State) => s,
    mapDispatchToProps,
    (s, d, o): Props & UsersProps => ({
      users: {
        ...s,
        ...d,
      },
      ...(o as any),
    })
  )(C);

  return withContext((props) => (
    <Projection ns="checkout">
      <Projection ns={ns}>
        <UserFormSaga context={props.requestContext} />
        <Connected {...(props as any)} />
      </Projection>
    </Projection>
  ));
};

type RegisterAction = {
  prefillFromPostalCode: (
    path: string[],
    address: IAddress,
    usingEnglishNameForPrefecture: boolean
  ) => PrefillAddressAction;
};
const actions: RegisterAction = {
  prefillFromPostalCode: (path, address, usingEnglishNameForPrefecture) => ({
    type: "PREFILL_FROM_POSTAL_CODE",
    path,
    address,
    usingEnglishNameForPrefecture,
  }),
};

const usersForm = new Form<InternalState, {}>([], actions, Projection);

const initialState = reducer(undefined, { type: "" } as any);

const registerForm = new Form<{ form: IRegister }, {}>([], actions, Projection);

export type UsersState = State;

export {
  reducer as usersReducer,
  initialState as usersInitialState,
  withUsersService,
  usersForm,
  registerForm,
};
