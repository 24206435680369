import * as React from "react";

const DeleteIcon = ({
  onClick,
  className,
}: {
  onClick?: (e: any) => void;
  className?: string;
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    onClick={onClick}
    className={className}
  >
    <path
      d="M20.5 4.00293H16.5V3.50293C16.5 2.67793 15.825 2.00293 15 2.00293H9C8.175 2.00293 7.5 2.67793 7.5 3.50293V4.00293H3.5C3.225 4.00293 3 4.22793 3 4.50293C3 4.77793 3.225 5.00293 3.5 5.00293H4.03L4.89 19.6479C4.97 20.9679 6.065 22.0029 7.385 22.0029H16.615C17.935 22.0029 19.035 20.9679 19.11 19.6479L19.97 5.00293H20.5C20.775 5.00293 21 4.77793 21 4.50293C21 4.22793 20.775 4.00293 20.5 4.00293ZM9 3.00293H15C15.275 3.00293 15.5 3.22793 15.5 3.50293V4.00293H8.5V3.50293C8.5 3.22793 8.725 3.00293 9 3.00293ZM18.11 19.5929C18.065 20.3829 17.405 21.0029 16.61 21.0029H7.385C6.59 21.0029 5.935 20.3829 5.885 19.5929L5.03 5.00293H18.97L18.11 19.5929ZM12.5 7.50293V18.5029C12.5 18.7779 12.275 19.0029 12 19.0029C11.725 19.0029 11.5 18.7779 11.5 18.5029V7.50293C11.5 7.22793 11.725 7.00293 12 7.00293C12.275 7.00293 12.5 7.22793 12.5 7.50293ZM16.02 7.00293C16.295 7.01293 16.51 7.24793 16.495 7.52293L15.995 18.5229C15.985 18.7929 15.765 19.0029 15.5 19.0029C15.49 19.0029 15.485 19.0029 15.475 19.0029C15.2 18.9879 14.985 18.7579 15 18.4829L15.5 7.48293C15.515 7.20293 15.765 6.99793 16.02 7.00293ZM8.5 7.47793L9 18.4779C9.01 18.7529 8.8 18.9879 8.525 18.9979C8.515 18.9979 8.51 18.9979 8.5 18.9979C8.235 18.9979 8.015 18.7879 8 18.5179L7.5 7.51793C7.49 7.24293 7.7 7.00793 7.975 6.99793C8.255 6.99793 8.485 7.20293 8.5 7.47793Z"
      fill="#292929"
    />
  </svg>
);

export { DeleteIcon };
