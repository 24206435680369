import React from "react";

const CancelBtn = ({ close }: { close: () => void }) => (
  <button className="none-border-btn" onClick={close}>
    キャンセル
  </button>
);

const SubmitBtn = ({ disabled }: { disabled: boolean }) => (
  <input
    type="submit"
    className={`primary-btn auto-width ${disabled ? "disabled" : ""}`}
    value="保存する"
    disabled={disabled}
  />
);

export { CancelBtn, SubmitBtn };
