import React, { useState, useEffect } from "react";
import { theplant } from "../../proto";
import TableItem from "./Table";
import { toCamelCase } from "../../gtm/uploadDataComponent/helper";
type ISizeGuideTable = theplant.ec.service.sizeguide.ISizeGuideTable;
type ISizeGuide = theplant.ec.service.sizeguide.ISizeGuide;
type ISizeGuideMeasurement = theplant.ec.service.sizeguide.ISizeGuideMeasurement;

///////////////////////////////
// Table
type TableProps = {
  table: ISizeGuideTable;
};

const Table = ({ table }: TableProps) => {
  const { name, group, rows, description } = table;

  return (
    <div>
      <h3>{(name !== "" && name) || (group && group.join("、"))}</h3>
      {rows && <TableItem rows={rows} />}
      {description && (
        <div
          dangerouslySetInnerHTML={{
            __html: description
          }}
        />
      )}
    </div>
  );
};

const SizeTable = ({ tables }: { tables?: ISizeGuideTable[] | null }) =>
  tables ? (
    <>
      {tables.map((table, i) => (
        <Table table={table} key={i} />
      ))}
    </>
  ) : null;

const Measurements = ({
  measurements
}: {
  measurements?: ISizeGuideMeasurement[] | null;
}) =>
  measurements && measurements.length > 0 ? (
    <>
      {measurements.map(({ name, bodyHtml }, i) => (
        <React.Fragment key={name || i}>
          <h3>{name}</h3>
          {bodyHtml && (
            <div dangerouslySetInnerHTML={{ __html: bodyHtml }} />
          )}
        </React.Fragment>
      ))}
    </>
  ) : null;

type Props = {
  sizeGuide: ISizeGuide;
};

const SizeGuide = ({ sizeGuide: { tables, measurements } }: Props) => {
  let productDimensions: ISizeGuideMeasurement[] = [];
  let otherMeasurements: ISizeGuideMeasurement[] = [];

  if (measurements) {
    productDimensions = measurements.filter(m => m && m.name === "商品寸法");
    otherMeasurements = measurements.filter(m => m && m.name !== "商品寸法");
  }

  let sizeApplicationGuide: ISizeGuideTable[] = [];
  let otherSizeGuide: ISizeGuideTable[] = [];
  let actualSizeGuide: ISizeGuideTable[] = [];

  if (tables) {
    tables.forEach(t => {
      if (t.name) {
        if (t.name.indexOf("サイズ適応ガイド") > -1) {
          return sizeApplicationGuide.push(t);
        }
        if (t.name.indexOf("実寸サイズ") > -1) {
          return actualSizeGuide.push(t);
        }
        return otherSizeGuide.push(t);
      } else if (t.group) {
        if (t.group.find(g => g === "サイズ適応ガイド")) {
          return sizeApplicationGuide.push(t);
        }
        if (t.group.find(g => g === "実寸サイズ")) {
          return actualSizeGuide.push(t);
        }
        return otherSizeGuide.push(t);
      }

      return otherSizeGuide.push(t);
    });
  }

  return (
    <div>
      <SizeTable tables={actualSizeGuide} />
      {/* 
        1. Switch the order of "サイズ適用ガイド" and "商品寸法”
        2. When actual sizeguide is not empty, show 商品寸法 and hide others
        refer: https://theplanttokyo.atlassian.net/browse/LAC-1524/ */}
      {actualSizeGuide.length > 0 ? (
        <Measurements measurements={productDimensions} />
      ) : (
        <>
          <SizeTable tables={sizeApplicationGuide} />
          <SizeTable tables={otherSizeGuide} />
          <Measurements measurements={otherMeasurements} />
        </>
      )}
    </div>
  );
};

const SizeGuideComponent = () => {
  const [sizeGuide, setSizeGuide] = useState<ISizeGuide | null>();

  useEffect(() => {
    const sizeGuideDataDOM = document.getElementById("size-guide-data");
    if (sizeGuideDataDOM) {
      const sizeGuideData = sizeGuideDataDOM.innerHTML;
      if(sizeGuideData.trim() !== ""){
        const data = JSON.parse(sizeGuideData, toCamelCase);
        if (sizeGuideData && data) {
          setSizeGuide(data as ISizeGuide);
        }
      }
    }
  }, []);

  return sizeGuide ? <SizeGuide sizeGuide={sizeGuide} /> : null;
};

export default SizeGuideComponent

export { SizeGuide, SizeTable };
