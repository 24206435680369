import * as React from "react";

import { checkoutForm } from "../../orders";
import { Form } from "../../form";
import { theplant } from "../../proto";

type ICheckoutInput = theplant.ec.api.orders.ICheckoutInput;
const input = Form.on(checkoutForm, "form");

type PrefillFieldProps<T> = {
  value: T;
  updateInput: (v: T) => void;
};

class _PrefillField<T> extends React.Component<PrefillFieldProps<T>, {}> {
  componentDidMount() {
    const { updateInput, value } = this.props;
    updateInput(value);
  }
  render() {
    return null;
  }
}

interface Props<K extends keyof ICheckoutInput> {
  field: K;
  value: ICheckoutInput[K];
}

const PrefillField = <K extends keyof ICheckoutInput>({
  field,
  value
}: Props<K>) => (
  <input.on
    field={field}
    component={({ updateInput }) => (
      <>
        {/* eslint-disable-next-line */}
        <_PrefillField updateInput={updateInput} value={value} />
      </>
    )}
  />
);

export { PrefillField };
