import * as types from "../constants/ActionTypes";

const open = () => ({ type: types.PROFILE_FORM_OPEN });
const close = () => ({ type: types.PROFILE_FORM_CLOSE });

const changeField = (field, value) => ({
  type: types.PROFILE_FORM_CHANGE_FIELD,
  field,
  value
});

const submit = () => ({ type: types.PROFILE_FORM_WILL_SUBMIT });

const submitSuccess = data => ({ type: types.PROFILE_FORM_DID_SUBMIT, data });

const submitFailure = error => ({
  type: types.PROFILE_FORM_DID_SUBMIT_FAILED,
  error
});

export { open, close, changeField, submit, submitSuccess, submitFailure };
