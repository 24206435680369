// Refer: https://github.com/theplant/ec/blob/master/orders/proto/spec.proto#L23-L42
export const ZERO_ORDER = {
  code: "",
  customer_id: "",
  customer: {},
  order_items: [],
  gift_wrapping: {},
  state: 0,
  coupons_used: [],
  points_used: 0,
  confirmed_at: "",
  payment_info: {},
  payment_transactions: [],
  shipping_type: 0,
  delivery_info: {},
  pickup_store: {},
  order_in_store: {},
  store_order_info: {},
  sum_up: {},
  user_can_cancel: false,
};

export const ZERO_VALIDATES_EMAIL_RESPONSE = {
  is_existing_account: false,
  is_used: false,
};
