import { connect } from "react-redux";

type PlainMap = { [key: string]: string };

type Action = {
  type: "SET_CONFIGURATION";
  config: PlainMap;
};

const setConfig = (config: PlainMap): Action => ({
  type: "SET_CONFIGURATION",
  config: config,
});

const reducer = (s: PlainMap = {}, a: Action) =>
  a.type === "SET_CONFIGURATION" ? a.config : s;

export type ConfigProps = { config: PlainMap };

const withConfig = <P>(
  C: React.ComponentType<P & ConfigProps>
): React.ComponentType<P & any> =>
  connect<ConfigProps, null, P, ConfigProps>(({ config }) => ({
    config,
  }))(C);

// CRA only works with custom environment variable start with `REACT_APP_`
// this function remove `REACT_APP_` from env key
const parseEnv = (env: PlainMap | undefined | null) => {
  let parsedEnv: PlainMap = {};

  if (!env) {
    return parsedEnv;
  }

  Object.keys(env).forEach((e) => {
    if (e.startsWith("REACT_APP_")) {
      parsedEnv[e.replace(/REACT_APP_/, "")] = env[e] || "";
    }
  });

  return parsedEnv;
};

const env = parseEnv((window as any).reactApp as PlainMap);

export { setConfig, reducer, withConfig, env, parseEnv as _parseEnv };
