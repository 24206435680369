import React, { useEffect, useState } from "react";

const CheckboxInput = (props: Props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    if (props.checked !== checked) {
      setChecked(props.checked);
    }
  }, [props.checked, checked]);

  const change = (event: any) => {
    const checked = event.target.checked;
    if (props.onChange && props.onChange(checked) !== false) {
      setChecked(checked);
    }
  };

  return (
    <label className={`checkbox ${props.className}`}>
      <input
        type="checkbox"
        name={props.name}
        checked={checked}
        value={String(checked)}
        onChange={change}
      />
      <span>{props.label}</span>
    </label>
  );
};

export type Props = {
  checked?: boolean;
  name?: string;
  label?: string;
  className?: string;
  onChange?: (checked: boolean) => {};
};

export default CheckboxInput;
