import React, { useState, useEffect } from "react";
import { Input } from "./shared/Input";
import { AddressForm, NamesForm } from "./Shipping/NameAndAddress";
import { Subscription, BirthdayAndGender } from "./shared/RegisterForm";
import { REGISTER_PATH } from "../constants/Paths";
import { Form } from "../form";
import { registerForm, UsersProps, withUsersService } from "../orders/users";
import Authn from "../js/authn";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ConfigProps } from "../config";

const form = Form.on(registerForm, "form");
const profile = Form.on(form, "profile");
const billingAddress = Form.on(profile, "billingAddress");
const emailForm = Form.on(form, "email");
const passwordForm = Form.on(form, "password");

type State = {
  xsrfToken?: string | null;
  fromEmailParam?: string | null;
  email?: string | null;
  recaptchaPulicKey?: string | null;
};
const RegisterApp = (
  props: RouteComponentProps<{}> &
    UsersProps & { redirPath?: string; inRegisterPage?: boolean } & ConfigProps
) => {
  const [data, setData] = useState<State>({
    fromEmailParam: undefined,
  });

  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    const registerWrapper = document.getElementById(
      "register-app"
    ) as HTMLDivElement;
    const xsrfToken = registerWrapper.getAttribute("data-xsrfToken");
    const fromEmailParam = registerWrapper.getAttribute("data-fromEmailParam");
    const email = registerWrapper.getAttribute("data-email");
    const recaptchaPulicKey =
      registerWrapper.getAttribute("data-recaptchaPulicKey") ||
      props.config.Recaptcha_PublicKey;

    const recaptchaScript = "https://www.google.com/recaptcha/api.js";
    const recaptchaScriptDiv = document.createElement("script");
    recaptchaScriptDiv.src = recaptchaScript;
    recaptchaScriptDiv.async = true;
    document.body.appendChild(recaptchaScriptDiv);

    setData({
      xsrfToken,
      fromEmailParam,
      email,
      recaptchaPulicKey,
    });

    const be = (window as any).be;
    if (be) {
      be.emit("ecView", {
        type: "register",
      });
    }

    (window as any).recaptcha_callback = recaptcha_callback;

    setIsMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recaptcha_callback = () => {
    const gRecaptchaDiv = document.querySelector(".g-recaptcha");
    if (gRecaptchaDiv) {
      const error = gRecaptchaDiv.querySelector(".error");
      // remove old error in callback
      if (error) {
        error.remove();
      }
    }
  };

  return (
    <div className="reg-form-wrapper">
      <h3>AIGLE CLUB 新規会員登録</h3>
      <form
        name="aigle-register-form"
        action={REGISTER_PATH}
        className="reg-form js-reg-form"
        method="POST"
      >
        <p className="desc text-center">
          ご登録いただいた情報は、いつでもマイページからご確認・ご修正できます。
        </p>
        <div className="white-panel">
          <p className="title">お客様情報</p>
          <input
            type="hidden"
            name="from_email"
            value={(data && data.fromEmailParam) || undefined}
          />
          <div className="name-wrapper">
            <NamesForm form={billingAddress} />
          </div>
          <BirthdayAndGender />
        </div>
        <div className="white-panel email-and-password-panel mt-24">
          <p className="title">アドレスを登録する</p>
          <div className="grid-row">
            <emailForm.field
              component={(props) => {
                const { value, ...rest } = props;
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const emailRef = React.useRef();

                // eslint-disable-next-line react-hooks/rules-of-hooks
                React.useEffect(() => {
                  if (emailRef) {
                    const current = emailRef.current;
                    if (current) {
                      (current as any).addEventListener("change", () => {
                        const newValue = (current as any).value;

                        if ((window as any)["$efo"]) {
                          props.updateInput(newValue);
                        }
                      });
                    }
                  }
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, []);

                return (
                  <Input
                    {...rest}
                    name="email"
                    label="メールアドレス"
                    placeholder="Eメールを入力"
                    inputRef={emailRef}
                  />
                );
              }}
            />
            <passwordForm.field
              component={(props) => (
                <>
                  <Input
                    {...props}
                    type="password"
                    name="password"
                    label="パスワード"
                    placeholder="パスワードを入力"
                  />
                  <Input
                    {...props}
                    type="password"
                    name="confirm_password"
                    label="パスワード"
                    placeholder="パスワードを入力"
                    style={{ height: 0, overflow: "hidden" }}
                  />
                </>
              )}
            />
          </div>
          <AddressForm
            form={billingAddress}
            registerPageUseEnglishPrefecture
            addressOptional
            inRegisterPage={props.inRegisterPage}
          />
        </div>
        <Subscription />

        <div
          className="g-recaptcha"
          data-sitekey={data && data.recaptchaPulicKey}
          data-callback="recaptcha_callback"
        />
        <div className="reg-form-btn-group">
          <div className="form-group">
            <input
              type="hidden"
              name="csrf_token"
              value={(data && data.xsrfToken) || undefined}
            />
            <button type="submit" className="primary-btn register-button">
              登録する
            </button>
          </div>
        </div>
      </form>
      {isMount && <Authn.Register {...props} />}
    </div>
  );
};

let connectedRegisterApp = withUsersService("users", withRouter(RegisterApp));

export default connectedRegisterApp;
export { RegisterApp };
