import * as React from "react";

import cx from "classnames";

import { Errors } from "../Errors";

import {
  DataProps,
  selectAbilityIsEnabled,
  checkoutForm
} from "../../../orders";
import { Form } from "../../../form";

import { theplant } from "../../../proto";
const Ability = theplant.ec.api.orders.Ability;
const DeliveryMethod = theplant.ec.api.orders.DeliveryMethod;

const input = Form.on(checkoutForm, "form");
const deliveryMethod = Form.on(input, "deliveryMethod");

const DeliveryMethods = ({
  data,
  isInAmazonPayPage,
  isGuestUser
}: {
  data: DataProps["data"]["data"];
  isInAmazonPayPage?: boolean;
  isGuestUser?: boolean | null;
}) => {
  const canStorePickUp = selectAbilityIsEnabled(data, Ability.CAN_STORE_PICKUP);
  const canCombiniPickUp = selectAbilityIsEnabled(
    data,
    Ability.CAN_CONVENIENCE_PICKUP
  );

  return (
    <section className="delivery-methods section-wrapper">
      <deliveryMethod.field
        // inside of component depends on the props abilities
        key={
          (canStorePickUp || "").toString() +
          (canCombiniPickUp || "").toString()
        }
        component={({
          value,
          updateInput,
          touchInput,
          errors,
          dirty,
          specifyBillingAddress
        }) => (
          <>
            {/* eslint-disable-next-line */}
            <a
              className={cx({
                active: value === DeliveryMethod.HOME_DELIVERY
              })}
              onClick={() => {
                updateInput(DeliveryMethod.HOME_DELIVERY);
                if (!isGuestUser) {
                  specifyBillingAddress(false);
                }
                touchInput();
              }}
            >
              <span>住所に配送</span>
            </a>

            {/* amazon pay screen cannot delivery to store */}
            {canStorePickUp && !isInAmazonPayPage && (
              <>
                {/* eslint-disable-next-line */}
                <a
                  className={cx({
                    active: value === DeliveryMethod.STORE_PICKUP
                  })}
                  onClick={() => {
                    updateInput(DeliveryMethod.STORE_PICKUP);
                    // must specify billing address on store pickup
                    specifyBillingAddress(true);
                    touchInput();
                  }}
                >
                  <span>店舗受け取り</span>
                </a>
              </>
            )}

            {/* amazon pay screen cannot delivery to combinni */}
            {canCombiniPickUp && !isInAmazonPayPage && (
              <>
                {/* eslint-disable-next-line */}
                <a
                  className={cx({
                    active: value === DeliveryMethod.CONVENIENCE_PICKUP
                  })}
                  onClick={() => {
                    updateInput(DeliveryMethod.CONVENIENCE_PICKUP);
                    // must specify billing address on store pickup
                    specifyBillingAddress(true);
                    touchInput();
                  }}
                >
                  <span>コンビニ</span>
                </a>
              </>
            )}

            <Errors errors={dirty ? errors : []} />
          </>
        )}
      />
    </section>
  );
};

export { DeliveryMethods };
