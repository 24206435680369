import * as React from "react";

import { Support } from "./Support";

const EmptyCart = () => (
  <div className="row">
    <div className="col">
      <div className="empty-cart white-panel">
        <p>カートには何も入っていません。</p>
        <a href="/">お買い物を続ける</a>
      </div>

      <Support />
    </div>
  </div>
);

export { EmptyCart };
