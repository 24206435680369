import * as React from "react";

import { proto } from "../../proto";
import { CHECKOUT_CART_PATH } from "../../constants/Paths";
import { alert } from "../../js/shared/alert";

type Props = {
  errors?: proto.ValidationError.IFieldViolation[];
  showErrorAndRedirectToCart?: boolean;
  translateMessage?: string;
};

const TIMEOUT = 3000;

const Popup = ({ errors, showErrorAndRedirectToCart }: Props) => {
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const goBackToCart = () => {
    window.location.href = CHECKOUT_CART_PATH;
  };

  const onClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  };

  React.useEffect(() => {
    if (errors && errors.length > 0) {
      alert(errors.map(({ msg }) => msg).join(", "), onClose);

      if (showErrorAndRedirectToCart) {
        timerRef.current = setTimeout(() => {
          if (showErrorAndRedirectToCart && timerRef.current) {
            goBackToCart();
          } else {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
          }
        }, TIMEOUT);
      } else {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      }
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [errors, showErrorAndRedirectToCart]);

  return null;
};

export default Popup;
