import React from "react";

const ToolsBar = ({ checkedWishItemsCount, onDelete, onMoveToCart }: Props) => (
  <div className="wishlist-toolsbar">
    <span className="count">製品：{checkedWishItemsCount} 件</span>
    {/* eslint-disable-next-line */}
    <div className="button-area">
      <button onClick={onDelete} className="outline-btn delete-it">
        削除
      </button>
      {/* eslint-disable-next-line */}
      <button onClick={onMoveToCart} className="primary-btn add-to-cart">
        カートに移動
      </button>
    </div>
  </div>
);

ToolsBar.defaultProps = {
  checkedWishItemsCount: 0,
};

type Props = {
  checkedWishItemsCount?: number;
  onDelete?: () => void;
  onMoveToCart?: () => void;
};

export default ToolsBar;
