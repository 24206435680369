import * as React from "react";

import { CheckoutProps } from "../.";
import { EmptyCart } from "./EmptyCart";
import { selectSumUpItems } from "../shared/SumUp";
import { CartSummary } from "../shared/CartSummary";
import { PaymentList } from "../shared/PaymentList";
import { Support } from "./Support";
import { Coupon } from "./Coupon";
import { CheckoutButton } from "./CheckoutButton";
import {
  selectOrderHasRestrictedItemsInInStoreApp,
  Products,
} from "./Products";
import { CartPromotionMessage } from "./CartPromotionMessage";

import { selectCartIsEmpty } from "../../orders";

import { isInStoreApp } from "./helper";
import { CHECKOUT_CART_PATH } from "../../constants/Paths";
import Stepper from "../Molecules/Stepper";
import { Loading } from "../Loading";
import { CheckWaitingPayOrder } from "./CheckWaitingPayOrder";

import {
  UploadCheckoutProgressDataToGTM,
  UploadCheckoutEventDataToGTM,
} from "../../gtm/GA";

import { theplant } from "../../proto";
const Ability = theplant.ec.api.orders.Ability;
type IOrderItem = theplant.ec.service.orders.IOrderItem;
type IOrder = theplant.ec.service.orders.IOrder;

const countQuantity = (a: number, c: IOrderItem): number =>
  a + Number(c.quantity || 0);

class CartView extends React.Component<CheckoutProps> {
  componentDidMount() {
    window.scrollTo(0, 0);

    const cart = this.props.cart.cart;
    emitQubitItemsData(cart, "basket");
  }

  UNSAFE_componentWillReceiveProps(nextProps: CheckoutProps) {
    const cart = this.props.cart.cart;
    const orderItems = cart && cart.orderItems ? cart.orderItems : [];
    const nextCart = nextProps.cart.cart;
    const nextOrderItems =
      nextCart && nextCart.orderItems ? nextCart.orderItems : [];
    if (
      orderItems.reduce(countQuantity, 0) !==
      nextOrderItems.reduce(countQuantity, 0)
    ) {
      emitQubitItemsData(nextCart, "basket");
    }
  }

  render() {
    const {
      cart: { cart, isGuestUser, Abilities, state, clearCart, updateQuantity },
    } = this.props;

    if (state && state.type === "fetching") {
      return <Loading />;
    }

    return (
      <div
        className={`cart-screen layout-content ${
          selectCartIsEmpty(cart) && `empty-cart-screen`
        }`}
      >
        <CheckWaitingPayOrder isGuestUser={isGuestUser} />
        {selectCartIsEmpty(cart) ? (
          <>
            <Stepper pathname={CHECKOUT_CART_PATH} />
            <div className="row">
              <div className="col">
                <CartPromotionMessage cart={this.props.cart} />
              </div>
            </div>
            <EmptyCart />
          </>
        ) : (
          <>
            <div className="row">
              <div className="col span-8 padding-md">
                <Stepper pathname={CHECKOUT_CART_PATH} />
                <CartPromotionMessage cart={this.props.cart} />
                <Products
                  cart={cart}
                  clearCart={clearCart}
                  updateQuantity={updateQuantity}
                />

                {!isInStoreApp &&
                  Abilities &&
                  Abilities.indexOf(Ability.CAN_APPLY_COUPON) !== -1 && (
                    <Coupon cart={this.props.cart} />
                  )}

                <Support className="desktop-only" />
              </div>

              <div className="col span-4 white-panel sumup-panel">
                <div className="sumup-section ">
                  <CartSummary cart={cart} noItems />
                  {isInStoreApp ? (
                    <a
                      className="primary-btn checkout-button"
                      href="/checkout"
                      onClick={buttonOnclickToGTM(
                        cart || {},
                        "begin_checkout",
                        "primary"
                      )}
                    >
                      ご購入手続き
                    </a>
                  ) : (
                    <CheckoutButton
                      disabled={selectOrderHasRestrictedItemsInInStoreApp(cart)}
                      isGuestUser={isGuestUser}
                      onClick={buttonOnclickToGTM(
                        cart || {},
                        "begin_checkout",
                        "primary"
                      )}
                    />
                  )}

                  {isInStoreApp ? (
                    <PaymentList withAmazonPay={false} />
                  ) : (
                    <PaymentList withAmazonPay={true} />
                  )}
                </div>

                <Support />

                <UploadCheckoutProgressDataToGTM order={cart} fieldStep={1} />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const buttonOnclickToGTM =
  (cart: IOrder, eventName: string, checkoutType: string) => () => {
    UploadCheckoutEventDataToGTM({ order: cart, eventName, checkoutType });
  };

const emitQubitItemsData = (
  cart: IOrder | null,
  type: "basket" | "checkout"
) => {
  const cartItems = cart && cart.orderItems ? cart.orderItems : [];
  const sumUp = selectSumUpItems(cart && cart.sumUp) || [];

  if (sumUp.length === 0) {
    return;
  }

  const be = (window as any).be;
  if (be) {
    be.emit("ecView", {
      type: type,
      currency: "JPY",
      language: "ja-jp",
    });
    be.emit("ecUser", {
      user: (window as any).user,
    });

    const quoteAmount = Number((sumUp[0] ? sumUp[0].amount : 0) || 0);
    const billingAmount = Number(
      (sumUp[sumUp.length - 1] ? sumUp[sumUp.length - 1].amount : 0) || 0
    );

    // esBasketSummary
    be.emit("ecBasketSummary", {
      basket: {
        subtotalIncludingTax: {
          currency: "JPY",
          value: quoteAmount,
        },
        total: {
          currency: "JPY",
          value: billingAmount,
        },
        quantity: cartItems.reduce(countQuantity, 0),
      },
    });

    const basketSummary = {
      subtotal: {
        currency: "JPY",
        value: quoteAmount,
      },
      total: {
        currency: "JPY",
        value: billingAmount,
      },
      quantity: cartItems.reduce(countQuantity, 0),
    };

    // esBasketItem
    if (cartItems.length > 0) {
      cartItems.forEach((item) => {
        be.emit("ecBasketItem", {
          basket: basketSummary,
          product: {
            sku: item.productCode,
            productId: item.articleCode,
            name: item.productName,
            stock: Number(item.quantityInStock || 0),
            price: {
              currency: "JPY",
              value: Number(item.sellingPrice || 0),
            },
          },
          quantity: Number(item.quantity || 0),
          subtotal: {
            currency: "JPY",
            value: Number(
              (item.sumUp && item.sumUp.discounted
                ? item.sumUp.discounted.amount
                : 0) || 0
            ),
          },
          subtotalIncludingTax: {
            currency: "JPY",
            value: Number(
              (item.sumUp && item.sumUp.discounted
                ? item.sumUp.discounted.amountWithTax
                : 0) || 0
            ),
          },
        });
      });
    }
  }
};

export { CartView as Cart, emitQubitItemsData };
