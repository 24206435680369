import { useEffect, useState } from "react";
import {
  TABLET_HEADER_BREAKPOINT,
  DESKTOP_BREAKPOINT,
} from "../../constants/Misc";


const useIsTablet = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const detectIsDesktop = () => {
    setIsDesktop(window.innerWidth >= TABLET_HEADER_BREAKPOINT);
  };

  useEffect(() => {
    detectIsDesktop();
    window.addEventListener("resize", detectIsDesktop);

    return () => {
      window.removeEventListener("resize", detectIsDesktop);
    };
  }, []);

  return isDesktop;
};

const _isDesktop = () => window.innerWidth >= DESKTOP_BREAKPOINT;

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const detectIsDesktop = () => {
    setIsDesktop(window.innerWidth >= DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    detectIsDesktop();
    window.addEventListener("resize", detectIsDesktop);

    return () => {
      window.removeEventListener("resize", detectIsDesktop);
    };
  }, []);

  return isDesktop;
};

export { useIsDesktop, useIsTablet, _isDesktop };
