import React from "react";
import cx from "classnames";
import { CheckedSVG } from "../../../shared/svg";

const ItemCard = ({
  fetching,
  is_default,
  onClick,
  content,
  onEdit,
  disabled,
  onDelete,
  selectedLabel,
  className,
}: {
  fetching?: boolean;
  is_default?: boolean;
  onClick?: () => void;
  content: React.ReactNode;
  onEdit?: () => void;
  disabled?: boolean;
  onDelete?: () => void;
  selectedLabel?: string;
  className?: string;
}) => (
  <div className="item-card">
    <div
      className={`info ${!fetching && is_default ? "selected" : ""} ${
        className ? className : ""
      }`}
      onClick={onClick}
    >
      {content}
      {is_default && <CheckedSVG />}
    </div>
    <div className="control-area">
      {is_default ? (
        <span className="selected-text">{selectedLabel}</span>
      ) : null}
      <div className="button-area">
        {onDelete && (
          <button
            disabled={disabled}
            onClick={onDelete}
            className={cx({ disabled: disabled })}
          >
            削除
          </button>
        )}
        {onEdit && <button onClick={onEdit}>編集</button>}
      </div>
    </div>
  </div>
);

export { ItemCard };
