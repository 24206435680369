const getDate = (date: Date) => {
  var youbi = ["日", "月", "火", "水", "木", "金", "土"];

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    youbi: youbi[date.getDay()],
  };
};

export { getDate };
