import PropTypes from "prop-types";
import React from "react";
import Base from "./Base";
import RadioInput from "./RadioInput";

import { GENDER_LABELS } from "../../../constants/Misc";

class GenderInput extends Base {
  _renderRadioInputs() {
    return GENDER_LABELS.map(([value, label, englishLabel], idx) => (
      <RadioInput
        key={idx}
        checkedValue={value}
        value={this.props.value}
        onChange={this.props.onChange}
        name={this.props.name}
        newdesign={this.props.newdesign}
        englishLabel={englishLabel}
      >
        {label}
      </RadioInput>
    ));
  }

  render() {
    return (
      <div className="form-group gender">
        {/* // 24 - 6 = 18 */}
        <label className="input-label mb-18">性別（任意）</label>
        <div className="gender-select-group">{this._renderRadioInputs()}</div>
        <div className="radio-error-container"></div>
        {this.renderErrors({ className: "radio-error-container" })}
      </div>
    );
  }
}

GenderInput.propTypes = {
  ...Base.propTypes,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default GenderInput;
