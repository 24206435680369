import React from "react";

import { Errors } from "../Errors";

import { proto } from "../../../proto";

// field:"Form.Payment"
// code:"PAYMENT_FAILURE"
// msg:"order payment failed"
const PaymentError = ({ error }: { error?: proto.IValidationError }) => {
  const err =
    error && error.fieldViolations
      ? error.fieldViolations.filter((e) => e.field === "Form.Payment")
      : [];

  if (err.length === 0) {
    return null;
  }

  return <Errors errors={err} />;
};

export { PaymentError };