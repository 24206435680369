import * as React from "react";

import { Errors, AddressInCompleteError } from "../shared/Errors";
import { withConfig, ConfigProps } from "../../config";
import { checkoutForm } from "../../orders";
import { Form } from "../../form";
import {
  downloadAmazonScriptAndRunCallback,
  CHANGE_ADDRESS_ID,
  CHANGE_PAYMENT_ID,
  bindAmazonButtonAction,
} from "./helper";
import { withData, DataProps } from "../../orders/data";
import Popup from "./Popup";
import { translate } from "../../constants/ErrorMessages";

import { theplant, proto } from "../../proto";
import { prefectures } from "../../orders/prefectureData";
type IAmazonPayInput = theplant.ec.service.orders.IAmazonPayInput;
type IValidationResult = theplant.ec.api.orders.IValidationResult;

const input = Form.on(checkoutForm, "form");
const amazonPayInputForm = Form.on(input, "amazonPayInput");

type AmazonPaymentWidgetsProps = {
  updateInput: (input: IAmazonPayInput) => void;
  touchInput: () => void;
  onClick?: () => void;
  confirmAmazonPay?: boolean;
  amazonCheckoutSessionId: string;
  error: proto.IValidationError | undefined;
};

type Props = ConfigProps & AmazonPaymentWidgetsProps & DataProps;

const _AmazonPaymentWidgets = (props: Props) => {
  const {
    amazonCheckoutSessionId,
    config,
    data: { data },
    onClick,
    error,
  } = props;

  React.useEffect(() => {
    // when in did mount, data is null, and render nothing
    // after receive data, then bind button event to mounted button div
    if (amazonCheckoutSessionId && data) {
      downloadAmazonScriptAndRunCallback({
        url: config.AMAZON_PAY_SCRIPT,
        callback: () => {
          if (amazonCheckoutSessionId) {
            bindAmazonButtonAction(amazonCheckoutSessionId);
          }
        },
      });
    }
  }, [amazonCheckoutSessionId, data, config.AMAZON_PAY_SCRIPT]);

  if (!data) {
    return null;
  }

  return (
    <div className="white-panel">
      <div className="amazon-widget-wrapper image-wrapper">
        <figure>
          <img src="/img/logo-amazonPay.png" alt="amazon pay logo" />
        </figure>
      </div>
      <AddressWidget
        shippingAddress={data.amazonpayShippingAddress}
        onClick={onClick}
      />
      <AddressInCompleteError error={error} />
      <PaymentWidget
        paymentPreferences={data.amazonpayPaymentReference}
        onClick={onClick}
      />
      <div className="amazon-widget-wrapper">
        <p className="desc">
          ＊プレゼントとしてご自身以外の住所を商品をお届け先に指定されますと、ご依頼主様の情報が納品書に掲載されません。贈り物としてご自身以外にご注文される場合は、お手数ですが通常の決済方法でお進みください。
        </p>
      </div>
      <amazonPayInputForm.field
        component={({ errors, dirty }) => (
          <>
            <Errors errors={dirty ? errors : []} />
            <Popup errors={translate(errors)} showErrorAndRedirectToCart />
          </>
        )}
      />
      <amazonPayInputForm.on
        field="amazonPaySessionId"
        component={({ errors, dirty }) => (
          <>
            <Errors errors={dirty ? errors : []} />
            <Popup errors={translate(errors)} showErrorAndRedirectToCart />
          </>
        )}
      />
    </div>
  );
};

const AddressWidget = ({
  shippingAddress,
  onClick,
}: {
  shippingAddress: IValidationResult["amazonpayShippingAddress"];
  onClick?: () => void;
}) => (
  <div className="amazon-widget-wrapper">
    <div className="content">
      <h4 className="section-title">配送先情報</h4>
      {shippingAddress &&
        (shippingAddress.familyName || shippingAddress.givenName) && (
          <span>
            {shippingAddress.familyName +
              " 〒" +
              shippingAddress.zipcode +
              " " +
              // when AmazonPay adderss prefecture is `Tokyo`, display it to `東京都`
              ((
                prefectures.find(
                  ({ english }) => english === shippingAddress.prefecture
                ) || {}
              ).name || shippingAddress.prefecture) +
              " " +
              shippingAddress.address1 +
              " " +
              shippingAddress.address2 +
              " " +
              shippingAddress.phone}
          </span>
        )}
    </div>
    <button id={CHANGE_ADDRESS_ID} className="outline-btn" onClick={onClick}>
      変更
    </button>
  </div>
);

const PaymentWidget = ({
  paymentPreferences,
  onClick,
}: {
  paymentPreferences: IValidationResult["amazonpayPaymentReference"];
  onClick?: () => void;
}) => (
  <div className="amazon-widget-wrapper payment">
    <div className="content">
      <h4 className="section-title">支払い方法</h4>
      {paymentPreferences &&
        paymentPreferences.length > 0 &&
        paymentPreferences.map((paymentDescriptor, i) => (
          <div className="payment-detail" key={i}>
            <span key={paymentDescriptor + i}>
              Amazon ギフト券と {paymentDescriptor}
            </span>
          </div>
        ))}
    </div>
    <button id={CHANGE_PAYMENT_ID} className="outline-btn" onClick={onClick}>
      変更
    </button>
  </div>
);

const AmazonPaymentWidget = withConfig<AmazonPaymentWidgetsProps>(
  withData<AmazonPaymentWidgetsProps & ConfigProps>(
    _AmazonPaymentWidgets as any
  )
);

export { AmazonPaymentWidget };
