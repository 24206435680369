import React, { useState, useEffect } from "react";
import { Input } from "./shared/Input";

type State = {
  xsrfToken?: string | null;
  email?: string | null;
  errMsg?: string | null;
  recoverPath?: string | null;
  registerPath?: string | null;
  loginPath?: string | null;
};
const LoginApp = () => {
  const [data, setData] = useState<State>();

  useEffect(() => {
    const loginWrapper = document.getElementById("login-app") as HTMLDivElement;
    const xsrfToken = loginWrapper.getAttribute("data-xsrfToken");
    const email = loginWrapper.getAttribute("data-email");
    const errMsg = loginWrapper.getAttribute("data-errMsg");
    const recoverPath = loginWrapper.getAttribute("data-recoverPath");
    const registerPath = loginWrapper.getAttribute("data-registerPath");
    const loginPath = loginWrapper.getAttribute("data-loginPath");

    setData({
      xsrfToken,
      email,
      errMsg,
      recoverPath,
      registerPath,
      loginPath,
    });
  }, []);

  return (
    <form
      className="js-login-form"
      action={(data && data.loginPath) || ""}
      method="POST"
    >
      <div className="login-screen layout-content">
        <div className="row padding-md">
          <div className="col span-3"></div>
          <div className="col span-6">
            <div className="white-panel">
              <h2 className="login-title">ログイン</h2>
              <div className="section grid-row-1">
                <p className="title">ご登録済みのお客様</p>
                <div className="form-group">
                  <Input
                    label="メールアドレス"
                    placeholder="例）taro@aigle.jp"
                    type="email"
                    id="email"
                    name="email"
                  />
                </div>
                <div className="form-group">
                  <Input
                    type="password"
                    id="password"
                    name="password"
                    label="パスワード"
                  />
                  {data && data.errMsg && (
                    <span id="login-error" className="error">
                      {(data && data.errMsg) || ""}
                    </span>
                  )}
                </div>
                <div className="forget-password">
                  <a href={(data && data.recoverPath) || ""}>
                    パスワードを忘れた方はこちら
                  </a>
                </div>
                <input
                  type="hidden"
                  name="csrf_token"
                  value={(data && data.xsrfToken) || undefined}
                />
                <button type="submit" className="primary-btn register-button">
                  ログイン
                </button>
              </div>
            </div>
            <div className="white-panel">
              <h2 className="register-title">新規会員登録</h2>
              <div className="section">
                <p className="title">
                  ご入会いただくと、ポイントが貯められたり、会員限定セールなどの情報をいち早く入手できます。
                </p>
                <a
                  className="primary-btn"
                  href={(data && data.registerPath) || ""}
                >
                  新規アカウントを作成
                </a>
              </div>
            </div>
          </div>
          <div className="col span-3"></div>
        </div>
      </div>
    </form>
  );
};

export default LoginApp;
