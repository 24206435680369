import $ from "jquery";
import { uploadPLPProductClick } from "../GA";

class UploadProductCardData {
  id;
  productCode;
  name;
  currentPage;
  taxPrice;
  pageName;
  dataPerPage;
  productIndex;
  articleCode;
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(document).off("click", ".ga-product-click");
    $(document).on("click", ".ga-product-click", this.handleClickOnProduct.bind(this));
    $(".ga-product-click").on(
      "mouseover",
      ".color-list li",
      this.handleMouseOverColor.bind(this)
    );
  }

  handleClickOnProduct(event) {
    const $target = $(event.currentTarget);
    this.category = $target.data("category");
    const $recommendationsDOM = document.querySelector(".recommendations");
    if ($recommendationsDOM === null) {
      this.currentPage = $(".pager.js-product-pager")
        .find("ul li.active")
        .data("page") || 1;
      this.dataPerPage = $(".pager.js-product-pager").data("perpage") || 0;

      this.productIndex = $target.data("position");
      this.position =
        (this.currentPage - 1) * this.dataPerPage + this.productIndex + 1;
      this.listName = window.location.pathname;
    } else {
      this.position = $target.data("position") + 1;
      const $recommendationsHeading = $recommendationsDOM.querySelector(
        "h4.heading"
      );
      if ($recommendationsHeading) {
        this.listName = $recommendationsHeading.textContent;
      }
    }

    const pathname = window.location.pathname;
    const priceHasSale = $target.find(".price").find(".sale");
    const plpListName = $(".js-product-list-header h1");
    const homeListName = $target.closest(".product-listing-container");
    const viewedProductListName = $target.closest(".recently-viewed");
    const $listname = plpListName.length > 0 ? plpListName
                      : homeListName.length > 0 ? homeListName.find(".h2")
                      : viewedProductListName.length > 0 ? viewedProductListName.find(".products-slider-title")
                      : null;

    if (priceHasSale.length > 0) {
      this.price = $target.find(".price").find(".original").data("price");
      this.discount = Number(this.price) - Number(priceHasSale.data("price"));
    } else {
      this.price = $target.find(".price").data("price") || $target.find(".price span").data("price");
      this.discount = 0;
    }
    this.articleCode = $target.attr("data-articlecode");
    this.productCode = $target.find(".js-current-url").data("product-code") || $target.data("product-code");
    this.name = $target.find("h6").attr("title");
    this.listname = $listname ? $listname.text() : "";
    this.colorCode = $target.data("colorcode");
    this.sizeCode = $target.data("sizecode");
    this.genderName = $target.data("gendername");

    uploadPLPProductClick({
      id: this.productCode,
      name: this.name,
      price: Number(this.price || 0),
      discount: Number(this.discount || 0),
      category: this.category,
      variant: this.colorCode,
      position: this.position,
      list: pathname === "/" ? "home | recommendation" : pathname.indexOf("/products") > -1 ? "product | recommendation" : pathname.indexOf("/search") > -1 ? "search" : this.listName,
      listname: pathname === "/"
        ? "recommendations"
        : pathname.indexOf("/products") > -1
        ? "recommendations"
        : pathname.indexOf("/search") > -1
        ? "instant search results"
        : this.listname,
      quantity: 1,
      coupon: "",
      brand: this.sizeCode,
      item_category3: this.genderName,
      item_category5: this.articleCode,
    });
  }
  handleMouseOverColor(event) {
    const $target = $(event.currentTarget);
    this.articleCode = $target.attr("data-articlecode");
    $target.parents(".product-card").attr("data-articlecode", this.articleCode);
  }
}

export default UploadProductCardData;
