import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const initialState = {
  ui: {
    showing: false
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.MUST_UPDATE_PROFILE_SHOW:
      return { ...s, showing: true };
    default:
      return s;
  }
};

export default combineReducers({ ui });

export { initialState };
