import { theplant } from "../proto";

import { CheckoutState } from "./reducer";
import { connect } from "react-redux";

import { IServiceError } from "@theplant/ecjs/prottp";

type State = {
  result: theplant.ec.api.orders.IConfirmResult | null;
  state:
    | {
        type: null | "fetching";
      }
    | {
        type: "error";
        error: IServiceError;
      };
};

const initialState: State = { result: null, state: { type: null } };

type Action =
  | { type: "WILL_FETCH_CONFIRM_RESULT" }
  | {
      type: "FETCH_CONFIRM_RESULT";
      result?: theplant.ec.api.orders.IConfirmResult;
    }
  | { type: "FETCH_CONFIRM_RESULT_ERROR"; error: IServiceError };

const reducer = (s: State = initialState, a: Action): State => {
  switch (a.type) {
    case "WILL_FETCH_CONFIRM_RESULT":
      return { ...s, state: { type: "fetching" } };
    case "FETCH_CONFIRM_RESULT":
      return { result: a.result || null, state: { type: null } };
    case "FETCH_CONFIRM_RESULT_ERROR":
      return { ...s, state: { type: "error", error: a.error } };
  }
  return s;
};

type DP = {
  onConfirmOrder: (r?: theplant.ec.api.orders.IConfirmResult) => void;
  willConfirmOrder: () => void;
};

export type ConfirmResultProps = { confirmResult: State & DP };

const onConfirmOrder = (
  result?: theplant.ec.api.orders.IConfirmResult
): Action => ({
  type: "FETCH_CONFIRM_RESULT",
  result,
});

const mapDispatchToProps: DP = {
  onConfirmOrder,
  willConfirmOrder: () => ({ type: "WILL_FETCH_CONFIRM_RESULT" }),
};

const withConfirmResult = <P>(
  C: React.ComponentType<P & ConfirmResultProps>
): React.ComponentType<P & any> =>
  connect<State, DP, P, ConfirmResultProps, { checkout: CheckoutState }>(
    ({ checkout: { confirmResult } }) => confirmResult,
    mapDispatchToProps,
    (s, d, o) => ({
      confirmResult: {
        ...s,
        ...d,
      },
      ...(o as any),
    })
  )(C);

export type ConfirmResultState = State;

export {
  withConfirmResult,
  reducer as confirmResultReducer,
  initialState as confirmResultInitialState,
};
