import React from "react";
import { Dispatch } from "redux";

import { KeyedProjection } from "@theplant/ecjs/projection";
import { makeService, IServiceError } from "@theplant/ecjs/prottp";
import { withContext, ContextProps, Context } from "@theplant/ecjs/context";

import { theplant, proto } from "../proto";
type IOrder = theplant.ec.service.orders.IOrder;
type IOrderItem = theplant.ec.service.orders.IOrderItem;
const CartService = theplant.ec.api.orders.CartService;
type Ability = theplant.ec.api.orders.Ability;
type GetCartResult = theplant.ec.api.orders.GetCartResult;

type State = {
  cart: IOrder | null;
  Abilities?: Ability[] | null;
  isGuestUser?: boolean | null;
  couponCodeInput: string | null;
  couponCodeError?: proto.ValidationError | null;
  state:
    | {
        type:
          | null
          | "fetching"
          | "adding"
          | "updating"
          | "clearing"
          | "applying";
      }
    | {
        type: "error";
        error: IServiceError;
      };
};

type Action =
  | { type: "WILL_FETCH_CART" }
  | {
      type: "ADD_TO_CART";
      code: string;
    }
  | {
      type: "UPDATE_QUANTITY";
      articleCode: string;
      quantity: number;
    }
  | {
      type: "FETCH_CART";
      order: IOrder;
    }
  | {
      type: "FETCH_CART_ERROR";
      error: IServiceError;
    }
  | {
      type: "FETCH_ABILITIES";
      Abilities?: Ability[] | null;
    }
  | {
      type: "FETCH_IS_GUEST_USER";
      isGuestUser?: boolean | null;
    }
  | { type: "WILL_CLEAR_CART" }
  | { type: "WILL_APPLY_COUPON_CODE" }
  | { type: "APPLY_COUPON_CODE" }
  | { type: "APPLY_COUPON_CODE_ERROR"; error: proto.ValidationError }
  | { type: "WILL_CANCEL_COUPON_CODE" }
  | { type: "UPDATE_COUPON_CODE_INPUT"; couponCode: string };

const initialState: State = {
  cart: null,
  couponCodeInput: null,
  state: { type: null }
};

const reducer = (s: State = initialState, a: Action): State => {
  switch (a.type) {
    case "WILL_FETCH_CART":
      return { ...s, state: { type: "fetching" } };
    case "ADD_TO_CART":
      return { ...s, state: { type: "adding" } };
    case "UPDATE_QUANTITY":
      return { ...s, state: { type: "updating" } };
    case "FETCH_CART":
      return { ...s, cart: a.order, state: { type: null } };
    case "FETCH_CART_ERROR":
      return { ...s, state: { type: "error", error: a.error } };
    case "FETCH_ABILITIES":
      return { ...s, Abilities: a.Abilities };
    case "FETCH_IS_GUEST_USER":
      return { ...s, isGuestUser: a.isGuestUser };
    case "WILL_CLEAR_CART":
      return { ...s, state: { type: "clearing" } };
    case "WILL_APPLY_COUPON_CODE":
      return { ...s, state: { type: "applying" } };
    case "APPLY_COUPON_CODE":
      return {
        ...s,
        state: { type: null },
        couponCodeInput: null,
        couponCodeError: null
      };
    case "APPLY_COUPON_CODE_ERROR":
      return { ...s, state: { type: null }, couponCodeError: a.error };
    case "UPDATE_COUPON_CODE_INPUT":
      return { ...s, couponCodeInput: a.couponCode };
  }
  return s;
};

// Can't be an interface as then it can't be passed to `connect` (?!)
type DP = {
  addToCart: (p: string) => Promise<IOrder>;
  updateQuantity: (p: string, n: number) => Promise<IOrder>;
  fetchCart: () => void;
  refreshCart: (o: IOrder) => void;
  clearCart: () => Promise<IOrder>;
  updateCouponCodeInput: (couponCode: string) => void;
  applyCouponCode: (couponCode: string) => Promise<void>;
  cancelCouponCode: () => Promise<IOrder>;
  updateAbilities: (ailities: Ability[]) => void;
};

export type CartProps = { cart: State & DP };

const selectCartSize = (s: IOrder | null): number | null =>
  s != null && s.orderItems != null
    ? s.orderItems.reduce((count, item) => count + Number(item.quantity), 0)
    : null;

const selectCartItem = (
  s: IOrder | null,
  code: string | undefined
): IOrderItem | null =>
  (s &&
    s.orderItems &&
    s.orderItems.find(({ articleCode }) => articleCode === code)) ||
  null;

const selectCartIsLoading = (s: State["state"]): boolean =>
  s.type !== null && s.type !== "error";

const selectItemIsOutOfStock = (s: IOrderItem | null): boolean =>
  s && s.quantity && s.quantityInStock
    ? Number(s.quantity) > Number(s.quantityInStock) ||
      Number(s.quantityInStock) === 0
    : true;

const selectOrderHasOutOfStockItems = (s: IOrder | null): boolean =>
  s && s.orderItems ? s.orderItems.some(selectItemIsOutOfStock) : false;

const selectAllItemsAreOutOfStock = (s: IOrder | null): boolean =>
  s && s.orderItems && s.orderItems.length > 0
    ? s.orderItems.every(selectItemIsOutOfStock)
    : false;

const selectItemIsRestricted = (s: IOrderItem | null): boolean =>
  !!s && !!s.restrictedReasons && s.restrictedReasons.length > 0;

const selectOrderHasRestrictedItems = (s: IOrder | null): boolean =>
  s && s.orderItems ? s.orderItems.some(selectItemIsRestricted) : false;

const selectCartIsEmpty = (s: IOrder | null): boolean =>
  s && s.orderItems && s.orderItems.length > 0 ? false : true;

const selectItemIsDiscounted = (s: IOrderItem | null): boolean =>
  s &&
  s.sumUp &&
  s.sumUp.prime &&
  s.sumUp.discounted &&
  Number(s.sumUp.discounted.priceWithTax) !== Number(s.sumUp.prime.priceWithTax)
    ? true
    : false;

const selectCouponsUsed = (cart: IOrder | null): string =>
  cart && cart.couponsUsed && cart.couponsUsed.length > 0
    ? cart.couponsUsed[0]
    : "";

/**
 * select if the order is paying all amount with applied points,
 * or if the applying points(`applyingPoints`) is going to pay all amount
 *
 * its calculate is points + pointTax === subItems's total price + fee(like delivery fee)
 * one special case is when use cod to pay, it has cod fee.
 * when use point to pay, cod fee is removed, so need remove cod fee in compare
 */
const selectIsPayingAllAmountWithPoints = (
  cart: IOrder | null,
  paymentType: theplant.ec.service.orders.PaymentType | null | undefined,
  codeFee: number,
  applyingPoints?: number
): boolean => {
  if (
    !cart ||
    !cart.sumUp ||
    !cart.sumUp.subItems ||
    !cart.sumUp.quoteAmountWithTax
  ) {
    return false;
  }

  const quoteAmountWithTax = Number(cart.sumUp.quoteAmountWithTax);
  let pointsAmount = applyingPoints || Number(cart.pointsUsed || 0);

  const {
    COUPON_DISCOUNT,
    PROMOTION_DISCOUNT,
    POINTS_DEDUCTION,
    POINTS_TAX_DEDUCTION
  } = theplant.ec.service.accounting.SubItemType;

  // COUPON_DISCOUNT & PROMOTION_DISCOUNT are contained in TOTAL_DISCOUNT,
  // also filter out the POINTS_DEDUCTION
  const subItems = cart.sumUp.subItems.filter(
    item =>
      item.subType !== COUPON_DISCOUNT &&
      item.subType !== PROMOTION_DISCOUNT &&
      item.subType !== POINTS_DEDUCTION &&
      item.subType !== POINTS_TAX_DEDUCTION
  );

  const totalAmount = subItems.reduce(
    (accumulator, item) => accumulator + Number(item.amount || 0),
    quoteAmountWithTax
  );

  const pointTax = pointsAmount ? Math.abs(Number(Math.round(pointsAmount / 10))) : 0;

  pointsAmount += pointTax;

  if (paymentType === theplant.ec.service.orders.PaymentType.COD) {
    return pointsAmount === totalAmount - codeFee;
  }

  return pointsAmount === totalAmount;
};

const updateAbilities =
  (dispatch: Dispatch<Action>) => (Abilities: Ability[]) => {
    dispatch({ type: "FETCH_ABILITIES", Abilities });
  };

const didFetchCart =
  (dispatch: Dispatch<Action>) =>
  ({ order, Abilities, isGuestUser }: GetCartResult) => {
    dispatch({ type: "FETCH_CART", order } as Action);
    dispatch({ type: "FETCH_ABILITIES", Abilities } as Action);
    dispatch({ type: "FETCH_IS_GUEST_USER", isGuestUser } as Action);
    return order || {};
  };

const didFetchCartError = (dispatch: Dispatch<Action>) => (error: any) => {
  dispatch({ type: "FETCH_CART_ERROR", error } as Action);
  throw error;
};

const updateQuantity =
  (dispatch: Dispatch<Action>, context: Context) =>
  (articleCode: string, quantity: number): Promise<IOrder> => {
    dispatch({ type: "UPDATE_QUANTITY", articleCode, quantity } as Action);

    return makeService(CartService, context)
      .setVariantCount({ articleCode, quantity })
      .then(didFetchCart(dispatch), didFetchCartError(dispatch));
  };

const addToCart =
  (dispatch: Dispatch<Action>, context: Context) =>
  (code: string): Promise<IOrder> => {
    dispatch({ type: "ADD_TO_CART", code } as Action);

    return makeService(CartService, context)
      .addVariant({ code })
      .then(didFetchCart(dispatch), didFetchCartError(dispatch));
  };

const fetchCart =
  (dispatch: Dispatch<Action>, context: Context) => (): Promise<{}> => {
    dispatch({ type: "WILL_FETCH_CART" });
    return makeService(CartService, context)
      .getCart({})
      .then(didFetchCart(dispatch), error =>
        dispatch({ type: "FETCH_CART_ERROR", error })
      );
  };

const clearCart =
  (dispatch: Dispatch<Action>, context: Context) => (): Promise<IOrder> => {
    dispatch({ type: "WILL_CLEAR_CART" } as Action);
    return makeService(CartService, context)
      .clearCart({})
      .then(didFetchCart(dispatch), didFetchCartError(dispatch));
  };

const applyCouponCode =
  (dispatch: Dispatch<Action>, context: Context) =>
  (couponCode: string): Promise<void> => {
    dispatch({ type: "WILL_APPLY_COUPON_CODE" } as Action);
    return makeService(CartService, context)
      .validateCartCoupon({ couponCode })
      .then(
        order => {
          dispatch({ type: "APPLY_COUPON_CODE" } as Action);
          didFetchCart(dispatch)(order);
        },
        e => {
          if (e && e.errors && e.errors.fieldViolations) {
            const error: proto.ValidationError = e.errors;
            error.fieldViolations = error.fieldViolations.map(error => ({
              ...error,
              field: "Form." + error.field
            }));
            dispatch({ type: "APPLY_COUPON_CODE_ERROR", error } as Action);
          }
        }
      );
  };

const cancelCouponCode =
  (dispatch: Dispatch<Action>, context: Context) => (): Promise<IOrder> => {
    dispatch({ type: "WILL_CANCEL_COUPON_CODE" } as Action);
    return makeService(CartService, context)
      .cancelCartCoupon({ couponCode: null })
      .then(didFetchCart(dispatch), didFetchCartError(dispatch));
  };

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  { requestContext }: ContextProps
): DP => ({
  updateQuantity: updateQuantity(dispatch, requestContext),
  addToCart: addToCart(dispatch, requestContext),
  fetchCart: fetchCart(dispatch, requestContext),
  refreshCart: order => dispatch({ type: "FETCH_CART", order } as Action),
  clearCart: clearCart(dispatch, requestContext),
  updateCouponCodeInput: couponCode =>
    dispatch({ type: "UPDATE_COUPON_CODE_INPUT", couponCode }),
  applyCouponCode: applyCouponCode(dispatch, requestContext),
  cancelCouponCode: cancelCouponCode(dispatch, requestContext),
  updateAbilities: updateAbilities(dispatch)
});

class CartFetcher extends React.Component<CartProps> {
  componentDidMount() {
    if (this.props.cart.cart == null && this.props.cart.state.type == null) {
      this.props.cart.fetchCart();
    }
  }
  render() {
    return null;
  }
}

const Projection = KeyedProjection("cart");

const withCartService = <Props extends {}>(
  ns: string,
  C: React.ComponentType<Props & CartProps>
): React.ComponentType<Props> => {
  const Connected = withContext(
    Projection.connect(
      (s: State) => s,
      mapDispatchToProps,
      (s, d, o): Props & CartProps => ({
        cart: {
          ...s,
          ...d
        },
        ...(o as any) // `any`: Typescript can't handle generic spread
      })
    )(props => (
      <>
        <C {...props} />
        <CartFetcher {...props} />
      </>
    ))
  );

  // FIXME any here is because composition of higher-order components
  // (withContext . Projection.connect) results in a type that is
  // unworkable.
  const W = (props: any) => (
    <Projection ns={ns}>
      <Connected {...props} />
    </Projection>
  );

  return W;
};

type PropertyMap = {
  [key: string]: string[] | null | undefined;
};

const propertiesToPropertyMap = (
  properties: theplant.ec.service.base.IProperty[] | null | undefined
): PropertyMap =>
  (properties || []).reduce((mapped: PropertyMap, property = {}) => {
    if (typeof property.field === "string") {
      mapped[property.field] = property.values;
    }
    return mapped;
  }, {});

const selectCartItemProperty = (
  s: IOrderItem | null,
  field: string
): string | undefined => {
  const discountContextsPropertyMap = propertiesToPropertyMap(
    s && s.discountContexts
  );

  return (discountContextsPropertyMap[field] || [])[0];
};

export type CartState = State;
export type CartAction = Action;

export {
  initialState as cartInitialState,
  reducer as cartReducer,
  withCartService,
  selectCartSize,
  selectCartItem,
  selectCartIsLoading,
  selectItemIsOutOfStock,
  selectOrderHasOutOfStockItems,
  selectAllItemsAreOutOfStock,
  selectItemIsRestricted,
  selectOrderHasRestrictedItems,
  selectCartIsEmpty,
  selectItemIsDiscounted,
  selectCouponsUsed,
  selectIsPayingAllAmountWithPoints,
  selectCartItemProperty
};
