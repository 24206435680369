import { put, call, takeLatest } from "redux-saga/effects";

import * as actions from "../actions/wishItems";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* refresh() {
  const { response, error } = yield call(Api.getWishItems);

  if (error) {
    yield put(actions.refreshFailure(error));
  } else {
    yield put(actions.refreshSuccess(response.data));
  }
}

function* watch() {
  yield takeLatest(types.WISH_ITEMS_WILL_REFRESH, refresh);
}

export default watch;

export { refresh };
