import React from "react";

const PointsTable = () => (
  <div className="points-table">
    <table>
      <tbody>
        <tr>
          <th>&nbsp;</th>
          <th>REGULAR</th>
          <th>SILVER</th>
          <th>GOLD</th>
          <th>PLATINUM</th>
        </tr>
        <tr>
          <td>年間お買上げ金額</td>
          <td>2万円未満</td>
          <td>2万円以上</td>
          <td>5万円以上</td>
          <td>10万円以上</td>
        </tr>
        <tr>
          <td>ポイント還元率</td>
          <td>1%</td>
          <td>3%</td>
          <td>5%</td>
          <td>5%</td>
        </tr>
        <tr>
          <td>スペシャルセールへのご招待</td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
        </tr>
        <tr>
          <td>バースデーポイント</td>
          <td>1000P</td>
          <td>1500P</td>
          <td>2000P</td>
          <td>2500P</td>
        </tr>
        <tr>
          <td>ストアイベントへのご招待</td>
          <td>&nbsp;</td>
          <td>●</td>
          <td>●</td>
          <td>●</td>
        </tr>
        {/*
                <tr>
                    <td>配送料金</td>
                    <td>税込み1万円以上</td>
                    <td>税込み1万円以上</td>
                    <td>いつでも無料</td>
                    <td>いつでも無料</td>
                </tr>
                <tr>
                    <td>ラッピング料金</td>
                    <td>250円</td>
                    <td>250円</td>
                    <td>いつでも無料</td>
                    <td>いつでも無料</td>
                </tr>
                */}
        <tr>
          <td>ノベルティの配布（不定期）</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>●</td>
          <td>●</td>
        </tr>
        <tr>
          <td>VIPイベントへのご招待</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>●</td>
          <td>●</td>
        </tr>
        <tr>
          <td>シークレットセールへのご招待</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>●</td>
        </tr>
        <tr>
          <td>限定ポイント</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>●</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const Status = () => (
  <div className="content points-sub-tab points-status-sub-tab">
    <h6>メンバーステータス</h6>
    <p>
      毎月１回、過去１年のお買い上げ金額に応じて、翌月からのステータスを変更いたします。
      <br />
      ランクアップ、ランクダウンの計算に使用されるお買い上げ金額は、獲得ポイントが確定ポイントとなった時点の金額を使用いたします。
    </p>
    <PointsTable />
    <strong>ステータスの更新について</strong>
    <p>
      ポイントはお買い上げ日から２０日間仮ポイントとなります。毎月末の確定ポイント数に応じて、ランクアップ
      • ランクダウンいたします
    </p>
  </div>
);

export default Status;

export { PointsTable };
