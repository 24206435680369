import { call } from "redux-saga/effects";
import Api from "../Api";

import {
  alert as sweetAlert,
  confirm as sweetConfirm
} from "../js/shared/alert";

function* alert(message) {
  yield call(sweetAlert, message);
}

function* redirect(redirectTo) {
  yield call(Api.redirect, redirectTo);
}

function* confirm(message) {
  return yield call(sweetConfirm, message);
}

export { alert, redirect, confirm };
