import { all } from "redux-saga/effects";

import watchPrefectures from "./prefectures";
import watchProfile from "./profile";
import watchProfileForm from "./profileForm";
import watchPasswordForm from "./passwordForm";
import watchEmailForm from "./emailForm";
import watchMustUpdateProfile from "./mustUpdateProfile";
import watchShippingAddresses from "./shippingAddresses";
import watchCreditCards from "./creditCards";
import watchPoints from "./points";
import watchPointsHistory from "./pointsHistory";
import watchOrders from "./orders";
import watchAccountShippingAddressForm from "./accountShippingAddressForm";
import watchWishItems from "./wishItems";
import watchCartItems from "./cartItems";
import watchAccountWishItems from "./accountWishItems";
import watchAccountCreditCardForm from "./accountCreditCardForm";
import watchAccountViewedProducts from "./accountViewedProducts";

export default function* rootSaga() {
  yield all([
    watchPrefectures(),
    watchProfile(),
    watchProfileForm(),
    watchPasswordForm(),
    watchEmailForm(),
    watchMustUpdateProfile(),
    watchShippingAddresses(),
    watchCreditCards(),
    watchPoints(),
    watchPointsHistory(),
    watchOrders(),
    watchAccountShippingAddressForm(),
    watchWishItems(),
    watchCartItems(),
    watchAccountWishItems(),
    watchAccountCreditCardForm(),
    watchAccountViewedProducts()
  ]);
}
