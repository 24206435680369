import { put, call, takeLatest, all } from "redux-saga/effects";

import * as actions from "../actions/orders";
import * as types from "../constants/ActionTypes";
import Api from "../Api";
import { alert, confirm } from "./common";
import * as messages from "../constants/Messages";

function* refresh() {
  // check waiting pay order before getOrders
  // but it can be slience executed, without get result or errors
  // temproray not call because lacoste prod find there are waiting pay order on order history
  // refer: https://theplant.slack.com/archives/C048URTTCR5/p1717126132082109
  // yield call(Api.checkWaitingPayOrder);
  const { response, error } = yield call(Api.getOrders);

  if (error) {
    yield put(actions.refreshFailure(error));
  } else {
    yield put(actions.refreshSuccess(response.data));
  }
}

function* cancelOrderByCondition({ id }) {
  yield call(cancelOrder, { id });
}

function* cancelOrder({ id }) {
  const confirmed = yield call(confirm, messages.ORDER_CANCEL_CONFIRM);

  if (confirmed) {
    const { response, error } = yield call(Api.cancelOrder, id);

    if (error) {
      yield put(actions.cancelFailure(error));
      // FIXME shouldn't handle some API error?
      yield call(alert, messages.INTERNAL_SERVER_ERROR);
    } else {
      yield put(actions.cancelSuccess(response.data));
    }
  }
}

function* watch() {
  yield all([
    takeLatest(types.ORDERS_WILL_REFRESH, refresh),
    takeLatest(types.ORDERS_WILL_CANCEL, cancelOrderByCondition),
  ]);
}

export default watch;

export { refresh, cancelOrderByCondition, cancelOrder };
