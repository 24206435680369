import * as React from "react";

export type CheckboxInputProps = {
  children?: React.ReactNode;
  checked?: boolean | null;
  onChange?: (checked: boolean) => void;
  name?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
};

const CheckboxSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0ZM7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
      fill="black"
    />
  </svg>
);

const CheckboxUnSelected = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 2V16H2V2H16ZM16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
      fill="black"
    />
  </svg>
);

const CheckboxInput = ({
  children,
  checked,
  onChange,
  name,
  className,
  label,
  disabled
}: CheckboxInputProps) => (
  <label className={`checkbox ${className ? className : ""}`}>
    <div className="checkbox-container">
      <input
        type="checkbox"
        name={name}
        disabled={disabled}
        value={String(checked)}
        checked={checked || false}
        onChange={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
      />
      {checked ? <CheckboxSelected /> : <CheckboxUnSelected />}
    </div>
    <span>{label || children}</span>
  </label>
);

export { CheckboxInput };
