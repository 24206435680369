import React from "react";
import { AddToWishList } from "./AddToWishList";
import { Color } from "./Color";

const PDP = () => {
  return (
    <>
      <AddToWishList />
      <Color />
    </>
  );
};

export default PDP;
