import { connect, Dispatch } from "react-redux";
import { compose } from "redux";

import { makeService, IServiceError } from "@theplant/ecjs/prottp";
import { withContext, ContextProps, Context } from "@theplant/ecjs/context";
import { searchStores as _searchStores } from "./helper";

import { theplant } from "../proto";
type IStore = theplant.ec.service.stores.IStore;
const DeliveryMethod = theplant.ec.api.orders.DeliveryMethod;
const CheckoutService = theplant.ec.api.orders.CheckoutService;

// ref: https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingResponses
export interface GeocoderResult {
  types: string[];
  formatted_address?: string;
  address_components: Array<{
    short_name: string;
    long_name: string;
    postcode_localities: string[];
    types: string[];
  }>;
  partial_match?: boolean;
  place_id: string;
  postcode_localities?: string[];
  geometry: google.maps.GeocoderGeometry;
}

interface State {
  isInMapView: boolean;
  state:
    | { type: "loading" | "searching" | null }
    | {
        type: "error";
        error: IServiceError;
      };
  allStores: IStore[];
  myStore?: IStore | null;
  storesResults: IStore[];
}

const initialState: State = {
  isInMapView: false,
  state: { type: null },
  allStores: [],
  storesResults: [],
};

type Action =
  | { type: "SELECT_STORES_MAP_VIEW" }
  | { type: "SELECT_STORES_LIST_VIEW" }
  | { type: "WILL_FETCH_BOUTIQURE_STORES" }
  | {
      type: "FETCH_STORES";
      stores: IStore[];
    }
  | {
      type: "FETCH_MY_STORE";
      myStore?: IStore | null;
    }
  | { type: "FETCH_STORES_ERROR"; error: IServiceError }
  | { type: "WILL_SEARCH_STORES" }
  | { type: "SEARCH_STORES"; stores: IStore[] }
  | { type: "SEARCH_STORES_ERROR"; error: IServiceError };

const reducer = (s = initialState, a: Action) => {
  switch (a.type) {
    case "SELECT_STORES_MAP_VIEW":
      return { ...s, isInMapView: true };
    case "SELECT_STORES_LIST_VIEW":
      return { ...s, isInMapView: false };
    case "WILL_FETCH_BOUTIQURE_STORES":
      return { ...s, state: { type: "loading" } };
    case "FETCH_STORES":
      return { ...s, allStores: a.stores };
    case "FETCH_MY_STORE":
      return { ...s, myStore: a.myStore };
    case "FETCH_STORES_ERROR":
      return { ...s, state: { type: "error", error: a.error } };
    case "WILL_SEARCH_STORES":
      return { ...s, state: { type: "searching" } };
    case "SEARCH_STORES":
      return { ...s, storesResults: a.stores, state: { type: null } };
    case "SEARCH_STORES_ERROR":
      return { ...s, state: { type: "error", error: a.error } };
    default:
      return s;
  }
};

type DP = {
  selectMapView: () => void;
  selectListView: () => void;
  fetchBoutiqueStores: () => Promise<void>;
  searchStores: (
    inputValue: string
  ) => Promise<google.maps.GeocoderResult | undefined>;
  refreshStoresResults: (stores: IStore[]) => void;
  refreshStores: (stores: IStore[]) => void;
};

export type StoreFinderProps = { storeFinder: State & DP };

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  { requestContext }: ContextProps
): DP => ({
  selectMapView: () => dispatch({ type: "SELECT_STORES_MAP_VIEW" }),
  selectListView: () => dispatch({ type: "SELECT_STORES_LIST_VIEW" }),
  fetchBoutiqueStores: fetchBoutiqueStores(dispatch, requestContext),
  searchStores: searchStores(dispatch),
  refreshStores: (stores: IStore[]) =>
    dispatch({
      type: "FETCH_STORES",
      stores,
    }),
  refreshStoresResults: (stores: IStore[]) =>
    dispatch({
      type: "SEARCH_STORES",
      stores,
    }),
});

const fetchBoutiqueStores =
  (dispatch: Dispatch<Action>, context: Context) => (): Promise<void> => {
    dispatch({ type: "WILL_FETCH_BOUTIQURE_STORES" });

    return makeService(CheckoutService, context)
      .validateInput({
        deliveryMethod: DeliveryMethod.STORE_PICKUP,
      })
      .then(
        (result) => {
          dispatch({
            type: "FETCH_MY_STORE",
            myStore: result.myStore,
          });
          dispatch({
            type: "FETCH_STORES",
            stores: result.allStores,
          });
        },
        (error) => {
          dispatch({ type: "FETCH_STORES_ERROR", error });
        }
      );
  };

const searchStores =
  (dispatch: Dispatch<Action>) =>
  (inputValue: string): Promise<google.maps.GeocoderResult | undefined> => {
    dispatch({ type: "WILL_SEARCH_STORES" });

    return _searchStores(inputValue);
  };

const withStoreFinder = compose(
  withContext,
  connect<State, DP, any, StoreFinderProps, any>(
    ({ storeFinder }: { storeFinder: State }) => storeFinder,
    mapDispatchToProps,
    (s, d, o) => ({
      storeFinder: {
        ...s,
        ...d,
      },
      ...(o as any),
    })
  )
);

export { reducer as storeFinderReducer, withStoreFinder };
