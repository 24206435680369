import Swal from "sweetalert2";

const alert = (msg: string, onClose?: () => void) =>
  Swal.fire({
    text: msg,
    confirmButtonColor: "rgba(0,0,0,.75)",
    confirmButtonText: "OK",
    onClose
  });

const confirm = (msg: string) =>
  Swal.fire({
    text: msg,
    showCancelButton: true,
    confirmButtonColor: "rgba(0,0,0,.75)",
    cancelButtonColor: "#aaa",
    cancelButtonText: "No",
    confirmButtonText: "Yes"
  }).then(result => !!result.value);

export { alert, confirm };
