// Copied from https://github.com/theplant/ecjs/blob/c6b5dc9d88a85b205b8b2d57d297d7658252da5b/packages/lacoste/containers/GTM/GA4.ts

// GA4 Measure ecommerce struct is not the same as GA
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#measure_purchases
import { gtmDataLayerPush } from ".";

const currency = "JPY";

export type Data = {
  name?: string | null;
  id?: string | null;
  price?: number | Long | null;
  brand?: string | null;
  category?: string | null;
  gender?: string | null;
  color?: string | null;
  size?: string | null;
  variant?: string | null;
  quantity?: number | Long | null;
  coupon?: string | null;
  discount?: number | null;
  index?: number | null;
  item_list_id?: string;
  item_list_name?: string;
  item_category3?: string | null;
  item_category5?: string | null;
};

type Item = {
  item_id: string;
  item_name: string;
  item_variant: string;
  item_brand: string;
  item_category: string;
  item_category3: string;
  price: number;
  quantity: number;
  coupon: string;
  currency: string;
  discount?: number;
  index?: number;
  item_list_id?: string;
  item_list_name?: string;
  item_location_id?: string;
};

const getGA4Data = (data: Data[]): Item[] =>
  (data || []).map((item) => {
    let newObj: any = {};
    Object.entries(item).forEach(([key, value]) => {
      let newKey =
        key === "id" || key === "name" || key === "brand" || key === "variant"
          ? `item_` + key
          : key;

      if (key === "position") {
        newKey = "index";
      }
      if (key === "list") {
        newKey = "item_list_id";
        // trim / and replace value / to -
        // https://theplanttokyo.atlassian.net/browse/LAX-3165?focusedCommentId=167277
        value =
          value
            ?.toString()
            .replace(/^\/+|\/+$/g, "")
            .replace(/\//g, "-") || "home";
      }
      if (key === "listname") {
        newKey = "item_list_name";
      }
      if (key === "gender") {
        newKey = "item_category3";
      }

      if (key === "category") {
        if (value) {
          const categories = (value as string).split("/") || [];

          for (var i = 0; i < categories.length; i++) {
            var newIndex = i;
            let newCategoryKey = `item_category`;
            if (newIndex > 0) {
              newCategoryKey += newIndex + 1;
            }

            newObj[newCategoryKey] = categories[i];
          }
        }
      } else {
        newObj[newKey] = value;
      }
    });

    newObj.currency = currency;

    return newObj;
  });

const event = {
  view_item_list: "view_item_list",
  view_item: "view_item",
  select_item: "select_item",
  add_to_cart: "add_to_cart",
  remove_from_cart: "remove_from_cart",
  view_cart: "view_cart",
  begin_checkout: "begin_checkout",
  add_shipping_info: "add_shipping_info",
  add_payment_info: "add_payment_info",
  purchase: "purchase",
  checkout_confirm_order: "checkout_confirm_order",
  c_gtmga_page_view: "c_gtmga_page_view",
};

type Ecommerce = {
  items?: Item[];
  value?: number;
  subtotal_value?: number;
  checkout_step?: string;
  payment_type?: string;
  payment_surcharge?: number;
  shipping?: number;
  tax?: number;
  coupon?: string | null;
  coupon_value?: number | null;
  currency?: string;
  transaction_id?: string | null;
  affiliation?: string;
  shipping_tier?: string;
  gift_box?: number;
  loyalty_discount?: number;
};

type UserData = {
  login_status?: string;
  user_type?: string;
  newsletter_optin?: boolean;
  returning_customer?: string;
  user_id?: string;
};

type PageData = {};

const pushDataForGA4 = ({
  event,
  data,
  user_data,
  page_data,
  checkout_type,
  value,
  subtotal_value,
  checkout_step,
  currency,
  payment_type,
  payment_surcharge,
  shipping,
  tax,
  coupon,
  coupon_value,
  transaction_id,
  affiliation,
  shipping_tier,
  gift_box,
  loyalty_discount,
}: {
  event: string;
  data?: Data[];
  checkout_type?: string;
  user_data?: UserData;
  page_data?: PageData;
} & Ecommerce) => {
  let ecommerce: Ecommerce = {};

  if (data !== undefined) {
    ecommerce.items = getGA4Data(data);
  }

  if (value !== undefined) {
    ecommerce.value = value;
  }
  if (subtotal_value !== undefined) {
    ecommerce.subtotal_value = subtotal_value;
  }
  if (currency !== undefined) {
    ecommerce.currency = currency;
  }
  if (coupon !== undefined) {
    ecommerce.coupon = coupon;
  }
  if (coupon_value !== undefined) {
    ecommerce.coupon_value = coupon_value;
  }
  if (payment_type !== undefined) {
    ecommerce.payment_type = payment_type;
  }
  if (checkout_step !== undefined) {
    ecommerce.checkout_step = checkout_step;
  }
  if (payment_surcharge !== undefined && payment_type === "cash") {
    ecommerce.payment_surcharge = payment_surcharge;
  }
  if (shipping !== undefined) {
    ecommerce.shipping = shipping;
  }
  if (tax !== undefined) {
    ecommerce.tax = tax;
  }
  if (transaction_id !== undefined) {
    ecommerce.transaction_id = transaction_id;
  }
  if (affiliation !== undefined) {
    ecommerce.affiliation = affiliation;
  }
  if (shipping_tier !== undefined) {
    ecommerce.shipping_tier = shipping_tier;
  }
  if (gift_box !== undefined) {
    ecommerce.gift_box = gift_box;
  }
  if (loyalty_discount) {
    ecommerce.loyalty_discount = loyalty_discount;
  }

  const GA4Data: {
    event: string;
    ecommerce?: Ecommerce;
    checkout_type?: string;
    user_data?: UserData;
    page_data?: PageData;
  } = {
    event,
    ecommerce,
  };

  if (Object.keys(ecommerce).length > 0) {
    GA4Data.ecommerce = ecommerce;
  }

  if (checkout_type !== undefined) {
    GA4Data.checkout_type = checkout_type;
  }

  if (user_data !== undefined) {
    GA4Data.user_data = user_data;
  }

  if (page_data !== undefined) {
    GA4Data.page_data = page_data;
  }

  // Clear the previous ecommerce object.
  gtmDataLayerPush({
    ecommerce: null,
  });

  gtmDataLayerPush(GA4Data);
};

export { event, pushDataForGA4 };
