import $ from "jquery";

class InstoreAppHome {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".js-top-level-tile").on("click", this.enterSubLevel.bind(this));
    $(".js-back-to-top-level-tile").on("click", this.backToTopLevel.bind(this));
  }

  enterSubLevel() {
    $(".tiles-wrapper").addClass("in-sub-level");

    // eslint-disable-next-line
    const $target = $(event.currentTarget);
    $target.parent().addClass("active");

    $target.fadeOut(0, function() {
      $target.next().fadeIn(300);
    });

    $("html, body").animate({ scrollTop: 0 }, 300);
  }

  backToTopLevel() {
    $(".tiles-wrapper").removeClass("in-sub-level");

    $(".tiles-wrapper")
      .find(">li.active")
      .removeClass("active");

    // eslint-disable-next-line
    const $target = $(event.currentTarget);
    $target.closest(".sub-tiles-wrapper").fadeOut(0, function() {
      $target
        .closest(".sub-tiles-wrapper")
        .prev()
        .fadeIn(100);
    });

    $("html, body").animate({ scrollTop: 0 }, 300);
  }
}

export default InstoreAppHome;
