import React, { useRef } from "react";
import { connect } from "react-redux";

import { Route, Switch, withRouter } from "react-router-dom";

import $ from "jquery";
import cx from "classnames";
import { didLoadedReactApp } from "../js/helper";

import Personal, {
  EmailAndPassword,
  ShippingAddresses,
  CreditCards,
} from "./AccountApp/Personal";
import Wishlist from "./AccountApp/Wishlist";
import Orders from "./AccountApp/Orders";
import RecentlyViewed from "./AccountApp/RecentlyViewed";
import MyStore from "./AccountApp/MyStore.tsx";
import Points from "./AccountApp/Points";

import About from "./AccountApp/Points/About";
import Howto from "./AccountApp/Points/Howto";
import History from "./AccountApp/Points/History";
import Status from "./AccountApp/Points/Status";
import {
  ExpandSvg,
  UserInfoSvg,
  UserProfileSvg,
  AddressSvg,
  UserCreditCardSvg,
  WishListSvg,
  OrderSvg,
  PointSvg,
  RecentlyViewSvg,
  MyStoreSvg,
} from "./shared/svg";

import {
  usePrevious,
  useFixMenu,
  useIsMobile,
  useOpenMenuDisableBodyScroll,
} from "./hooks";

import { refresh as refreshProfile } from "../actions/profile";

import * as paths from "../constants/Paths";
import * as texts from "../constants/Texts";
import { isMobile } from "../js/client";

const TABS = [
  {
    path: paths.ACCOUNT_PERSONAL_PATH,
    component: Personal,
    label: texts.ACCOUNT_PERSONAL,
    className: "user-info",
    svg: UserInfoSvg,
  },
  {
    path: paths.ACCOUNT_PROFILE_PATH,
    component: EmailAndPassword,
    label: texts.ACCOUNT_PROFILE,
    className: "user-profile",
    svg: UserProfileSvg,
  },
  {
    path: paths.ACCOUNT_ADDRESS_PATH,
    component: ShippingAddresses,
    label: texts.ACCOUNT_ADDRESS,
    className: "user-address",
    svg: AddressSvg,
  },
  {
    path: paths.ACCOUNT_CREDIT_CARD_PATH,
    component: CreditCards,
    label: texts.ACCOUNT_CREDIT_CARD,
    className: "user-credit",
    svg: UserCreditCardSvg,
  },
  {
    path: paths.ACCOUNT_WISHLIST_PATH,
    component: Wishlist,
    label: isMobile() ? texts.ACCOUNT_WISHLIST__MOBILE : texts.ACCOUNT_WISHLIST,
    svg: WishListSvg,
  },
  {
    path: paths.ACCOUNT_ORDERS_PATH,
    component: Orders,
    label: texts.ACCOUNT_ORDERS,
    svg: OrderSvg,
  },
  {
    path: paths.ACCOUNT_POINTS_PATH,
    component: Points,
    label: texts.ACCOUNT_POINTS,
    svg: PointSvg,
    routes: [
      {
        path: paths.ACCOUNT_POINTS_HISTORY_PATH,
        component: History,
        label: "ポイント利用・獲得履歴",
      },
      {
        path: paths.ACCOUNT_POINTS_ABOUT_PATH,
        component: About,
        label: "Aigleポイントとは",
      },
      {
        path: paths.ACCOUNT_POINTS_HOWTO_PATH,
        component: Howto,
        label: "ポイントのご利用方法",
      },
      {
        path: paths.ACCOUNT_POINTS_STATUS_PATH,
        component: Status,
        label: "ステータスの更新について",
      },
    ],
  },
  {
    path: paths.ACCOUNT_RECENTLY_VIEWED_PATH,
    component: RecentlyViewed,
    label: texts.ACCOUNT_RECENTLY_VIEWED,
    svg: RecentlyViewSvg,
  },
  {
    path: paths.ACCOUNT_MYSTORE_PATH,
    component: MyStore,
    label: texts.ACCOUNT_MYSTORE,
    svg: MyStoreSvg,
  },
];

const TabButton = ({ children, active, onClick }) => (
  <li onClick={onClick} className={cx("tab", { active })}>
    {children}
  </li>
);
const TabContent = ({ children, className }) => (
  <li className={cx("tab-content-wrapper", className)}>{children}</li>
);

const setWhiteBackground = (onlyRemove) => {
  const accountApp = document.querySelector("#account-app");
  if (accountApp) {
    if (onlyRemove) {
      accountApp.classList.remove("white-bg");
    } else {
      accountApp.classList.toggle("white-bg");
    }
  }
};

const Account = (props) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [menuTop, setMenuTop] = React.useState(0);
  const isMobile = useIsMobile();
  const ref = useRef();
  const navRef = useFixMenu(isMobile);

  const { refreshProfile } = props;
  React.useEffect(() => {
    didLoadedReactApp();
    refreshProfile();
  }, [refreshProfile]);

  const prevProps = usePrevious(props);
  const prevPathname =
    prevProps && prevProps.location && prevProps.location.pathname;
  const currentPathname = props.location.pathname;

  React.useEffect(() => {
    if (
      prevPathname !== currentPathname &&
      currentPathname.indexOf("point") === -1
    ) {
      // scroll into view on mobile
      if ($(window).width() < 768) {
        $("html, body").animate({
          scrollTop: $(".tabs-wrapper .tab.active").offset().top - 95,
        });
      }
    }
  }, [currentPathname, prevPathname]);

  const linkTo = (path) => {
    return () => {
      setOpenMenu(false);
      setWhiteBackground(true);

      const { prevTabPath } = props;
      const { push } = props.history;

      // redirect to prev points sub tab
      if (path === paths.ACCOUNT_POINTS_PATH && prevTabPath) {
        push(prevTabPath);

        return;
      }

      push(path);
    };
  };

  const logout = () => {
    if (window.amazon) {
      window.amazon.Login.logout();
    }
  };

  let title = TABS[0].label;
  let tabs = [];
  let tabContent = [];

  const { state: locationState, pathname } = props.location;
  const isTabClosed = locationState && locationState.isTabClosed;

  TABS.forEach((tab, idx) => {
    const isCurrentTab = pathname.indexOf(tab.path) !== -1;

    tabs.push(
      <TabButton
        key={`tab-${idx}`}
        active={isCurrentTab}
        onClick={linkTo(tab.path)}
      >
        <div className="svg sp-only">{tab.svg}</div>
        {tab.label}
      </TabButton>
    );

    if (isCurrentTab) {
      tabContent.push(
        <TabContent
          key={`tab-content-${idx}`}
          className={(tab.className || "") + (isTabClosed ? " closed" : "")}
        >
          <Switch>
            {TABS.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </TabContent>
      );
    }

    if (isCurrentTab) {
      title = tab.label;
    }
  });

  const toggleMenuOnMobile = () => {
    if (isMobile) {
      setOpenMenu(!openMenu);
      const nav = navRef.current;
      if (nav) {
        const navClient = nav.getBoundingClientRect();
        const menuTop = navClient.top + navClient.height;
        setOpenMenu(!openMenu);
        setMenuTop(menuTop);

        setWhiteBackground();
      }
    }
  };

  useOpenMenuDisableBodyScroll(openMenu, ref.current);

  return (
    <div className="wrapper grid-row-4-and-8">
      <div
        className={`menu-section ${openMenu ? "open" : ""}`}
        style={{ top: menuTop }}
        ref={ref}
      >
        <h1 className="title desktop-only">MY PAGE</h1>
        <ol className="tabs-wrapper">{tabs}</ol>
        <div className="welcome-and-logout">
          <a href="/auth/logout" className="outline-btn" onClick={logout}>
            ログアウト
          </a>
        </div>
      </div>
      <div className="content-area">
        <nav onClick={toggleMenuOnMobile} id="account-nav" ref={navRef}>
          <h2 className={`page-title ${openMenu ? "open" : ""}`}>
            <span className="sp-only menu-title">MENU</span>
            {title}
            <ExpandSvg className="sp-only" />
          </h2>
        </nav>
        <div className="account-section">{tabContent}</div>
      </div>
    </div>
  );
};

const ConnectedAccount = withRouter(
  connect(
    ({
      profile: {
        data: { familyName },
      },
      points: {
        ui: { prevTabPath },
      },
    }) => ({
      familyName,
      prevTabPath,
    }),
    {
      refreshProfile,
    }
  )(Account)
);

const RouteWithSubRoutes = (route) => (
  <Route
    path={route.path}
    render={(props) => <route.component {...props} routes={route.routes} />}
  />
);

const AccountApp = () => (
  <Route path={paths.ACCOUNT_PATH} component={ConnectedAccount} />
);

export default AccountApp;

export { TABS, RouteWithSubRoutes };
