import React from "react";

import { imgProps, ProductImageSize } from "../productImage";
import { theplant } from "../../proto";
import {
  orderedImagesAndVariants,
  isShowEvenThoughOutOfStock,
  anyStoreInInventory,
  isVariantsOutOfStock,
  getColorSwatchImage,
} from "../helper";
import { getColorName } from "../productField"

const displayColorCode = ({
  variants,
  colorCode,
  selectedColorCode,
}: {
  variants: theplant.ec.service.products.IVariant[];
  colorCode: string;
  selectedColorCode?: string;
}) => {
  // request from https://github.com/theplant/lacoste3/pull/1981
  // When it is selected by url's colorCode
  // When some of variants have inventory
  if (colorCode === selectedColorCode) {
    return true;
  }

  return !isVariantsOutOfStock(variants);
};

type ColorItemProps = {
  code: string;
  name?: string | null;
  selected: boolean;
  imgSrc: string;
  clickHandler: () => void;
};

const ColorItem = ({
  code,
  name,
  selected,
  imgSrc,
  clickHandler,
}: ColorItemProps) => (
  <li
    className={`colorItem ${selected ? "active" : ""}`}
    onClick={clickHandler}
    data-name={name}
    data-code={code}
    data-colorName={name}
  >
    {/* eslint-disable-next-line */}
    <a data-colorName={name}>
      <span
        className="color-swatch"
        data-colorName={name}
        style={{
          background: `url(${
            imgProps(imgSrc, ProductImageSize.CA).src
          }) no-repeat center`,
        }}
      />
    </a>
  </li>
);

export type Props = {
  product: theplant.ec.service.products.IProduct;
  selectedColorCode: string;
  changeColor: (color: string) => void;
  inStoreInventoryPanel?: boolean;
};

const ColorSwatch = (props: Props) => {
  const { product, selectedColorCode, changeColor, inStoreInventoryPanel } =
    props;
  const colorName = "";

  return (
    <div className="pdp-info__colors color-list">
      <label className="title">カラーを選択: <span className="color-name">{colorName}</span></label>
      <ul>
        {(
          (product &&
            orderedImagesAndVariants(product, { defaultOrder: true })) ||
          []
        ).map(({ colorCode, images, variants }, i) => {
          let display =
            isShowEvenThoughOutOfStock(product) ||
            displayColorCode({
              variants,
              colorCode,
              selectedColorCode: props.selectedColorCode,
            });

          // when inStoreInventoryPanel
          if (inStoreInventoryPanel) {
            if (anyStoreInInventory(variants)) {
              display = true;
            } else {
              display = false;
            }
          }

          if (!display) {
            return null;
          }

          const imageSrc = (images && getColorSwatchImage(images)) || "";

          const currentVariant = variants && variants[0];
          const colorNames = getColorName(currentVariant.filterProperties)||[]

          return (
            <ColorItem
              key={(currentVariant && currentVariant.articleCode) || i}
              code={colorCode}
              selected={selectedColorCode === colorCode}
              imgSrc={imageSrc}
              clickHandler={() => changeColor(colorCode || "")}
              name={colorNames[0] || ""}
            />
          );
        })}
      </ul>
    </div>
  );
};

export { ColorSwatch };
