export const ADDRESS_TYPE_SHIPPING = "shipping";
export const ADDRESS_TYPE_BILLING = "billing";

// Payment type that copied from `theplant/ec/orders/proto` spec.
//
// Refer: https://github.com/theplant/ec/blob/cd2f681b59dbd80279099e4fd910aa7f97a2f5d4/orders/proto/spec.pb.go#L71-L78
export const PAYMENT_TYPE_NO_PAYMENT = 0;
export const PAYMENT_TYPE_COD = 1;
export const PAYMENT_TYPE_CREDIT_CARD = 2;
export const PAYMENT_TYPE_POINT_COMPLETED = 3;

// Supported credit card brands refer here:
// https://github.com/qor/gomerchant/blob/ba1a78b79af10537d0e84bb0338a488cd2b9797a/credit_card.go#L8-L21
export const VISA = "visa";
export const JCB = "jcb";
export const MASTER_CARD = "master";
export const AMERICAN_EXPRESS = "american_express";
export const DINERS_CLUB = "diners_club";

export const BRANDS = [MASTER_CARD, VISA, AMERICAN_EXPRESS, JCB, DINERS_CLUB];

export const BRAND_TO_ISSUER_MAP = {
  [VISA]: "VISA",
  [JCB]: "JCB",
  [MASTER_CARD]: "MasterCard",
  [AMERICAN_EXPRESS]: "AmericanExpress",
  [DINERS_CLUB]: "DinersClub",
};

export const DELIVERY_INFO_OPTION_NORMAL = "normal";
export const DELIVERY_INFO_OPTION_SPECIFIED = "specified";
export const DELIVERY_INFO_OPTION_TO_STORE = "delivery_to_store";

// Those gender constants should keep update with the `theplant/ec/customers` package.
// Refer: https://github.com/theplant/ec/blob/cf8214862b1c27858104fe1ee78960207da41d38/customers/proto/spec.pb.go#L34-L40
export const GENDER_LABELS = [
  ["1", "男性", "MALE"],
  ["2", "女性", "FEMALE"],
  ["0", "その他", "UNKNOWN"],
];

export const MAX_CART_ITEM_QUANTITY = 5;

export const DESKTOP_HEADER_BREAKPOINT = 1280;
export const DESKTOP_BREAKPOINT = 1024;
export const TABLET_HEADER_BREAKPOINT = 768;

export const PREVIEW_MAIN = "#main.pagebuilder-containers";
