import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";
import { removeFieldErrorMessages } from "./helpers/validationError";

const initialState = {
  data: {},
  ui: {
    showingSuccessMessage: false,
    isOpen: false,
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.EMAIL_FORM_OPEN:
      return {
        ...s,
        isOpen: true,
        fetching: false,
        error: null,
        showingSuccessMessage: false
      };
    case types.EMAIL_FORM_CLOSE:
      return { ...s, isOpen: false };
    case types.EMAIL_FORM_WILL_SUBMIT:
      return { ...s, fetching: true, error: null };
    case types.EMAIL_FORM_DID_SUBMIT_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.EMAIL_FORM_DID_SUBMIT:
      return {
        ...s,
        fetching: false,
        error: null,
        showingSuccessMessage: true
      };
    case types.EMAIL_FORM_CHANGE_FIELD:
      const error = removeFieldErrorMessages(s.error, a.field);
      return { ...s, error };
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.EMAIL_FORM_OPEN:
      return { ...initialState.data };
    case types.EMAIL_FORM_CHANGE_FIELD:
      return { ...s, [a.field]: a.value };
    default:
      return s;
  }
};

const getEmailFormData = state => state.emailForm.data;

export default combineReducers({ ui, data });

export { initialState, getEmailFormData };
