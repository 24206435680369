import React, { useState, useEffect } from "react";

import { CheckoutProps } from "../.";
import { HomeDeliveryOptions } from "../shared/Delivery";
import { AmazonPaymentWidget } from "./AmazonPaymentWidgets";
import { GiftWrapping } from "../shared/GiftWrapping";
import { Points } from "../shared/Points";
import { PrefillField } from "../shared/PrefillField";
import { CartSummaryAndConfirmButton } from "../shared/CartSummaryAndConfirmButton";
import { EmptyCart } from "../Cart/EmptyCart";
import { CHECKOUT_AMAZON_PAY_PATH } from "../../constants/Paths";
import Stepper from "../Molecules/Stepper";
import { CHECKOUT_CART_PATH } from "../../constants/Paths";
import {
  PrefillInputFromLocalStorage,
  saveInLocalStorage,
} from "../shared/PrefillInputFromLocalStorage";

import { selectCouponsUsed, selectCartIsEmpty } from "../../orders";
import { emitQubitItemsData } from "../Cart";
import { UploadCheckoutEventDataToGTM } from "../../gtm/GA";

import {
  getSessionIdAndCompleteAmazonPayInURL,
  getAmazonPayloadUrl,
} from "../AmazonPay/helper";
import { confirmAmazonOrder } from "./confirmAmazonOrder";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { IValidationError } from "@theplant/ecjs/prottp";
import Popup from "./Popup";
import { translate } from "../../constants/ErrorMessages";

import { theplant } from "../../proto";
import { Loading } from "../Loading";

const PaymentType = theplant.ec.service.orders.PaymentType;

type AmazonPayProps = CheckoutProps &
  RouteComponentProps<{}> & {
    amazonCheckoutSessionId?: string | string[];
  };

const AmazonPayInner = (props: AmazonPayProps) => {
  const {
    amazonCheckoutSessionId,
    cart: { cart },
    data: { data },
    checkout: { updateDataForm, validateInput, updateAddressForm, error },
  } = props;
  const [loading, setLoading] = useState(true);
  const [redirecting, setRedirecting] = React.useState(false);

  useEffect(() => {
    const { amazonPayResultReturnUrl } = getAmazonPayloadUrl();

    updateDataForm(
      "amazonPayInput",
      new theplant.ec.service.orders.AmazonPayInput({
        amazonPaySessionId: String(amazonCheckoutSessionId),
        amazonPayResultReturnUrl,
      })
    );

    validateInput();
    setLoading(true);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  const onClickAmazonPayWidget = () => {
    const inputForm = props.checkout.input.form;
    if (inputForm) {
      saveInLocalStorage(inputForm);
    }
  };

  const updateAddressToAmazonPayAddress = () => {
    if (data) {
      const { amazonpayShippingAddress } = data;
      if (amazonpayShippingAddress) {
        updateAddressForm("shippingAddress", amazonpayShippingAddress);
        updateAddressForm("billingAddress", amazonpayShippingAddress);
      }
    }
  };

  const callOnConfirm = () => {
    UploadCheckoutEventDataToGTM({
      order: props.cart.cart,
      eventName: "add_shipping_info",
      deliveryMethod: props.checkout.input.form.deliveryMethod,
      giftWrapping: props.checkout.input.form?.giftWrapping,
    });
    confirmAmazonOrder({
      confirm: props.checkout.confirm,
      amazonpayRedirectUrl: data?.amazonpayRedirectUrl,
      setRedirecting,
      updateAddressToAmazonPayAddress,
    });
  };

  if (redirecting) {
    return <div className="full-screen-loading" />;
  }

  const couponsUsed = selectCouponsUsed(cart);

  if (cart && selectCartIsEmpty(cart) && !loading) {
    const { error } = props.data.state as {
      type: "error";
      error: IValidationError;
    };

    return (
      <>
        {error && error.errors.fieldViolations ? (
          <Popup errors={translate(error.errors.fieldViolations)} />
        ) : null}
        <EmptyCart />
      </>
    );
  }

  return (
    <div className="amazon-pay-screen layout-content relative-position">
      {loading && <Loading />}

      <PrefillField field="isTermsAgreed" value={true} />
      <PrefillField field="paymentType" value={PaymentType.AMAZON_PAY} />
      <PrefillField
        key={(couponsUsed || "").toString()}
        field="couponCode"
        value={couponsUsed}
      />
      {!loading && <PrefillInputFromLocalStorage checkout={props.checkout} />}

      <div className="row">
        <div className="col span-8 padding-md">
          <Stepper pathname={CHECKOUT_AMAZON_PAY_PATH} />
          <AmazonPaymentWidget
            amazonCheckoutSessionId={amazonCheckoutSessionId}
            onClick={onClickAmazonPayWidget}
            error={error}
          />

          <HomeDeliveryOptions
            data={data}
            checkout={props.checkout}
            isAmazonPay
          />

          <Points
            data={data}
            cart={cart}
            checkout={props.checkout}
            isAmazonPay
            updating={props.data.state.type === "fetching"}
          />

          <GiftWrapping
            data={data}
            form={props.checkout.input.form}
            isAmazonPay
            cart={cart}
            state={props.checkout.input.state}
          />
        </div>

        <div className="col span-4 white-panel sumup-panel">
          <CartSummaryAndConfirmButton
            isAmazonPayInComplete={true}
            cart={cart}
            checkout={props.checkout}
            data={props.data}
            confirmAmazonOrder={callOnConfirm}
          />
        </div>
      </div>
    </div>
  );
};

const AmazonPay = (props: AmazonPayProps) => {
  const [amazonCheckoutSessionId, setAmazonCheckoutSessionId] = useState<
    string | string[] | undefined
  >(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { amazonCheckoutSessionId } = getSessionIdAndCompleteAmazonPayInURL();

    if (!amazonCheckoutSessionId) {
      // redirect to cart page if no checkout session
      window.location.replace(CHECKOUT_CART_PATH);
    } else {
      setAmazonCheckoutSessionId(amazonCheckoutSessionId);
    }

    emitQubitItemsData(props.cart.cart, "checkout");
    // eslint-disable-next-line
  }, []);

  return amazonCheckoutSessionId ? (
    <AmazonPayInner
      {...props}
      amazonCheckoutSessionId={amazonCheckoutSessionId}
    />
  ) : null;
};

const AmazonPayPage = withRouter(AmazonPay);

export { AmazonPayPage as AmazonPay };
