import { useEffect } from "react";
import $ from "jquery";
import { ACCOUNT_WISHLIST_PATH } from "../../../constants/Paths";
import {
  getSelectedArticleCode,
  renderErrorMessages,
  addSizeNotSelectedError,
} from "./helper";
import {
  requireAuth,
  parseJSONHandlerFuncResponseForAjax,
} from "../../../js/helper";

const AddToWishList = () => {
  const handleClickAddToWishlist = () => {
    requireAuth(() => {
      const articleCode = getSelectedArticleCode();
      console.log("handleClickAddToWishlist", articleCode);
      if (articleCode) {
        postWishItems(articleCode);
      } else {
        addSizeNotSelectedError();
      }
    });
  };

  const postWishItems = (articleCode: string) => {
    $.ajax({
      url: "/account/wish-item.json",
      type: "POST",
      data: JSON.stringify({ params: [articleCode] }),
      success: function (data) {
        const { error, errorMessages } =
          parseJSONHandlerFuncResponseForAjax(data);
        if (error) {
          if (errorMessages) {
            let messages = { ...errorMessages } as string[];
            renderErrorMessages(messages);
          }
          return;
        }

        window.location.href = ACCOUNT_WISHLIST_PATH;
      },
    });
  };

  useEffect(() => {
    const wishlistBtn = document.querySelector(".js-add-to-wishlist");

    if (wishlistBtn) {
      wishlistBtn.addEventListener("click", handleClickAddToWishlist);
    }

    return () => {
      if (wishlistBtn) {
        wishlistBtn.removeEventListener("click", handleClickAddToWishlist);
      }
    };
    // eslint-disable-next-line
  }, []);

  return null;
};

export { AddToWishList };
