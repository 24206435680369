import React from "react";
import { GENDER_LABELS } from "../../../../constants/Misc";

const getPrefectureJapaneseByName = (prefectures = [], sourceName: string) => {
  let result = sourceName;

  prefectures.forEach(({ name, japanese }) => {
    if (name === sourceName) {
      result = japanese;
    }
  });

  return result;
};

const getGender = (gender: string) => {
  const find = GENDER_LABELS.find((item) => item[0] === gender);

  return find ? find[1] : GENDER_LABELS[2][1];
};

const Brief = ({
  profileState: { data: profileData },
  prefecturesState: { data: prefecturesData },
  openForm,
}: any) => {
  let {
    dateOfBirth,
    phone,
    familyName,
    givenName,
    katakanaFamilyName,
    katakanaGivenName,
    zipcode,
    prefecture,
    city,
    address1,
    address2,
    gender,
  } = profileData;

  let prefectureJapanese = getPrefectureJapaneseByName(
    prefecturesData,
    prefecture
  );

  const g = getGender(gender);

  return (
    <div>
      <div className="user-info-col">
        <div className="grid-row">
          <span className="user-name">
            姓名：{familyName} {givenName}
          </span>
          <span className="katakana-name">
            姓名（カナ）：{katakanaFamilyName} {katakanaGivenName}
          </span>
          <span className="birthday">生年月日：{dateOfBirth}</span>
          <span className="gender">性別：{g}</span>
          <span className="zipcode">郵便番号：〒{zipcode}</span>
          <span className="phone">電話番号 : {phone}</span>
        </div>
        <div className="grid-row-1 grid-gap">
          <span className="user-address">
            ご住所： {prefectureJapanese}
            {city}
            {address1}
            {address2}
          </span>
        </div>
        <div className="button-wrapper">
          <button type="button" className="edit" onClick={openForm}>
            変更する
          </button>
        </div>
      </div>
    </div>
  );
};

export default Brief;

export { getPrefectureJapaneseByName };
