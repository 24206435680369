import React from "react";

const LoadingSVG = () => (
  <div className="loader-indicator">
    <svg
      width="200"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
      className="circular"
    >
      <circle
        cx="50"
        cy="50"
        r="20"
        className="path"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  </div>
);

const Loading = ({ className }: { className?: string }) => {
  return (
    <div className={`loading ${className}`}>
      <LoadingSVG />
    </div>
  );
};

const InlineLoading = () => {
  return (
    <div className="inline-loading">
      <Loading />
    </div>
  );
};

export { InlineLoading, Loading };
