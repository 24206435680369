import { theplant } from "../proto";
import { calcPrice } from "../product/helper";
import {
  getCategoryCode,
  getGenderName,
  getColorCode,
  getSize,
} from "../product/productField";
import { IImpression, UploadOrderItemProps, CheckoutProps } from "./GAtype";
import { brand } from "./constants";
import { usedPoints, deliveryFee } from "../orders/helper";

type IProduct = theplant.ec.service.products.IProduct;
type IVariant = theplant.ec.service.products.IVariant;

const defaultVariant = ({
  product,
  colorCode,
  sizeCode,
}: {
  product: IProduct;
  colorCode?: string | null;
  sizeCode?: string | null;
}) => {
  const { variants } = product;
  let variant: IVariant | undefined | null;

  if (variants) {
    variant = variants[0];

    if (colorCode) {
      const matchedVariants = variants.filter(
        (v) => getColorCode(v.filterProperties) === colorCode
      );

      variant = matchedVariants[0];

      if (sizeCode) {
        variant = matchedVariants.find(
          (v) => getSize(v.filterProperties) === sizeCode
        );
      }
    }
  }

  return variant;
};

const getImpresssion = ({
  product,
  colorCode,
  sizeCode,
}: {
  product: IProduct;
  colorCode?: string | null;
  sizeCode?: string | null;
}): IImpression | undefined => {
  let impression;
  const { code, name, filterProperties } = product;
  const variant = defaultVariant({ product, colorCode, sizeCode });
  if (code && name && variant) {
    const { price, discounted } = calcPrice(variant);
    const { articleCode } = variant;

    impression = {
      id: code,
      name,
      price: price || discounted,
      discount: price && discounted ? price - discounted : 0,
      brand,
      category: getCategoryCode(filterProperties),
      gender: getGenderName(filterProperties) || "",
      color: colorCode || getColorCode(variant.filterProperties) || "",
      size: sizeCode || getSize(variant.filterProperties) || "",
      variant: articleCode,
    };
  }

  return impression;
};

const plpList = () => {
  var list = window && window.location && window.location.pathname;

  const $recommendations = document.querySelector(
    ".js-recommendations"
  ) as HTMLElement;
  if ($recommendations) {
    const $recommendationsHeading = document.querySelector(
      ".js-recommendations h4.heading"
    ) as HTMLElement;
    if ($recommendationsHeading) {
      list = $recommendationsHeading.innerText;
    }
  }
  return list;
};

const getImpresssions = ({
  products,
  startIndex,
}: {
  products: IProduct[];
  startIndex: number;
}): IImpression[] => {
  let impressions: Array<IImpression> = [];
  products.forEach((p, index) => {
    const { code, name } = p;
    const impression = getImpresssion({ product: p });

    if (code && name && impression) {
      impressions.push({
        ...impression,
        position: startIndex + index + 1 || 1,
        list: plpList(),
      });
    }
  });

  return impressions;
};

const getCoupon = (cart: theplant.ec.service.orders.IOrder) => {
  return cart.couponsUsed ? cart.couponsUsed.join(",") : undefined;
};

const getDataFromOrderItem = ({
  item,
  coupon,
}: {
  item: theplant.ec.service.orders.IOrderItem;
  coupon?: string | null;
}): UploadOrderItemProps => {
  const { productCode, productName, articleCode, discountContexts, quantity } =
    item;

  const { price, discounted } = calcPrice(item);

  const orderItem: UploadOrderItemProps = {
    name: productName,
    id: productCode,
    price: price || discounted,
    discount: price && discounted ? price - discounted : 0,
    brand,
    category: getCategoryCode(discountContexts),
    gender: getGenderName(discountContexts) || "",
    color: getColorCode(discountContexts) || "",
    size: getSize(discountContexts) || "",
    variant: articleCode,
    quantity: Number(quantity || 0),
    coupon: coupon || "",
  };

  return orderItem;
};

const getUploadDataFormOrder = (order: CheckoutProps["order"]) => {
  if (!order) {
    return {};
  }

  const { orderItems } = order;

  if (!orderItems) {
    return {};
  }

  const coupon = getCoupon(order);

  const orderECPurchaseItems: Object[] = [];

  if (orderItems.length > 0) {
    orderItems.forEach((item, index) => {
      const data = getDataFromOrderItem({ item, coupon });
      data.index = index + 1;
      orderECPurchaseItems.push(data);
    });
  }

  return { orderECPurchaseItems, coupon };
};

const getUploadTransactionData = (order: CheckoutProps["order"]) => {
  if (!order) {
    return;
  }

  const { orderItems, sumUp } = order;

  if (!orderItems) {
    return;
  }

  let purchase;

  if (orderItems.length > 0 && sumUp) {
    const { orderECPurchaseItems, coupon } = getUploadDataFormOrder(order);

    const orderBillingAmountTax = Number(sumUp.billingAmountTax || 0);
    const orderBillingAmountWithTax = Number(sumUp.billingAmountWithTax || 0);
    const orderDeliveryFee = Number(deliveryFee(sumUp.subItems || []));
    const orderUsedPoints = Math.abs(Number(usedPoints(sumUp.subItems || [])));

    purchase = {
      actionField: {
        id: order.code,
        affiliation: "Aigle EC SITE",
        // refer: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce
        // ga('ec:setAction', 'purchase', {...}) part
        revenue: orderBillingAmountWithTax + orderUsedPoints,
        tax: orderBillingAmountTax,
        shipping: orderDeliveryFee,
        coupon,
      },
      products: orderECPurchaseItems,
    };
  }

  return purchase;
};

export {
  getImpresssion,
  getImpresssions,
  getCoupon,
  getDataFromOrderItem,
  getUploadDataFormOrder,
  getUploadTransactionData,
};

const _test = {
  defaultVariant,
  getCoupon,
};

export { _test };
