import React from "react";

export type withRecordOrderSentInBrowserProps = {
  orderHadSent?: (orderId?: string | null) => void;
  orderFirstSent?: (orderId?: string | null) => string | undefined;
  orderAlreadySent?: (orderId?: string | null) => string | undefined;
};

const withRecordOrderSentInBrowser =
  <P extends {}>(
    C: React.ComponentType<P>
  ): React.ComponentType<P & withRecordOrderSentInBrowserProps> =>
  (props: P) => {
    // store orderId in localStorage to indicate it already been sent
    // to avoid data will be sent multiple times
    // refer: https://www.simoahava.com/analytics/prevent-google-analytics-duplicate-transactions-with-customtask/
    var transactionDeduper = {
      keyName: "_transaction_ids",
      cookieExpiresDays: 1,
    };

    const readFromStorage = (key: string) => {
      if (!window.Storage) {
        // From: https://stackoverflow.com/a/15724300/2367037
        const value = "; " + document.cookie;
        const parts = value.split("; " + key + "=");
        if (parts && parts.length === 2) {
          const result = parts.pop();
          return result && result.split(";").shift();
        }
      }

      return window && window.localStorage && window.localStorage.getItem(key);
    };

    const writeToStorage = (key: string, value: string, expireDays: number) => {
      if (!window.Storage) {
        var expiresDate = new Date();
        expiresDate.setDate(expiresDate.getDate() + expireDays);
        document.cookie =
          key + "=" + value + ";expires=" + expiresDate.toUTCString();
      } else {
        if (window && window.localStorage)
          window.localStorage.setItem(key, value);
      }
    };

    const orderHadSent = (orderId?: string | null) => {
      const storedIds = JSON.parse(
        readFromStorage(transactionDeduper.keyName) || "[]"
      );

      storedIds.push(orderId);

      writeToStorage(
        transactionDeduper.keyName,
        JSON.stringify(storedIds),
        transactionDeduper.cookieExpiresDays
      );
    };

    const orderFirstSent = (orderId?: string | null) => {
      const storedIds = JSON.parse(
        readFromStorage(transactionDeduper.keyName) || "[]"
      );

      return storedIds.indexOf(orderId) === -1;
    };

    const orderAlreadySent = (orderId?: string | null) => {
      const storedIds = JSON.parse(
        readFromStorage(transactionDeduper.keyName) || "[]"
      );

      return storedIds.indexOf(orderId) > -1;
    };

    return (
      <C
        {...props}
        orderHadSent={orderHadSent}
        orderFirstSent={orderFirstSent}
        orderAlreadySent={orderAlreadySent}
      />
    );
  };

export { withRecordOrderSentInBrowser };
