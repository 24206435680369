import { put, call, select, takeLatest } from "redux-saga/effects";

import { getFormData } from "../reducers/accountCreditCardForm";
import * as actions from "../actions/accountCreditCardForm";
import { refresh as refreshCreditCards } from "../actions/creditCards";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* submit(params) {
  const formData = yield select(getFormData);
  let { error, response } = yield call(Api.createCreditCard, formData);
  error = response?.data?.field_violations;

  if (error) {
    yield put(actions.submitFailure(error));
  } else {
    yield put(actions.submitSuccess());
    yield put(refreshCreditCards());
    if (params && params.callback) {
      yield call(params.callback);
    }
  }
}

function* watch() {
  yield takeLatest(types.ACCOUNT_CREDIT_CARD_FORM_WILL_SUBMIT, submit);
}

export default watch;

export { submit };
