import React, { Fragment } from "react";
import ShopItem from "../../StoreFinder/ShopItem";

interface IProps {
  store: any;
  setShowList: (show: boolean) => void;
}

const FavStore = ({ store, setShowList }: IProps) => {
  return (
    <Fragment>
      <h3>お気に入り店舗</h3>
      <p className="desc">
        この画面ではお気に入りショップを変更できます。
        変更した先のお気に入りショップから素敵な特典をお知らせするメールマガジンが届きます。お気に入りショップを検索し、登録するショップを選択してください。
      </p>
      <div className="search-shop-list new-my-store-fav-wrap">
        <ShopItem
          canDutyFree={store.is_tax_exempt}
          canPoint={store.is_points_usable}
          shopName={store.name}
          lat={store.latitude}
          lng={store.longitude}
          zipcode={store.zip}
          addresss={store.city + store.address}
          tel={store.phone}
          id={store.id}
          form={store.form}
          workTime={store.business_hours}
          noIndex={true}
          {...store}
        />
      </div>
      <button
        className="new-primary-btn new-my-store-go-list"
        onClick={() => setShowList(true)}
      >
        登録店舗を変更する
      </button>
    </Fragment>
  );
};

export default FavStore;
