import { env } from "../config";
import { toggleBodyOverflowHidden } from "./toggleBodyOverflowHidden";
interface IOption {
  amount?: string;
  maxWidth?: string;
  maxAmount?: string;
  typeClass?: string;
  styleType?: string;
  enable?: boolean;
}
export function initSmartpayMessaging(appendEleClass: string, option: IOption) {
  if (!document) return;
  if (!env.SMART_PAY_PAYMENT_SCRIPT || !env.SMART_PAY_KEY) return;
  const appendEle = document.querySelector(appendEleClass);
  if (!appendEle) return;
  const {
    amount = "3000",
    typeClass = "smartpay-osm-product",
    styleType = "block",
    enable = false,
  } = option;
  if (!enable) return;

  const currentSmartpayEle = appendEle.firstChild;
  if (currentSmartpayEle) {
    appendEle.removeChild(currentSmartpayEle);
  }

  const divEle = document.createElement("div");
  divEle.classList.add("pdp-smartpay-message-wrapper");
  divEle.classList.add(typeClass);

  divEle.setAttribute("data-prop-type", styleType);
  divEle.setAttribute("data-prop-theme", "white");
  divEle.setAttribute("data-prop-logo-theme", "color");
  divEle.setAttribute("data-prop-amount", amount);
  divEle.setAttribute("data-prop-quantity", "1");
  // default max amount
  divEle.setAttribute("data-prop-max-amount", "300000");
  // width: 100%
  divEle.setAttribute("data-prop-max-width", "10000");

  appendEle.append(divEle);
  // load script after insert element
  loadSmartpayScript();
}

const uniqId = "SMARTPAY_LOAD_SCRIPT_UNIQ_ID";

function loadSmartpayScript() {
  if (document.querySelector(`#${uniqId}`)) {
    // @ts-ignore
    const render = window?.smartpay?.messaging?.render;
    if (render) {
      render();
    }
    return;
  }
  window.addEventListener("message", smartpayOpenModalEventListener, true);

  const scriptEle = document.createElement("script");
  scriptEle.id = uniqId;
  scriptEle.defer = true;
  scriptEle.src = env.SMART_PAY_PAYMENT_SCRIPT;
  scriptEle.setAttribute("data-merchant", env.SMART_PAY_KEY);
  scriptEle.setAttribute("data-platform-detection", "disabled");
  document.head.appendChild(scriptEle);
}

function smartpayOpenModalEventListener(event: MessageEvent) {
  if (event.origin === document.location.origin) {
    try {
      const data = JSON.parse(event.data);
      if (data.namespace === "smartpay" && data.event === "showProductModal") {
        toggleBodyOverflowHidden();
        // delay call the close BTN
        setTimeout(() => {
          const closeBTN = document.querySelector("img[alt=Close]");
          closeBTN?.addEventListener("click", toggleBodyOverflowHidden, {
            once: true,
          });
        });
      }
    } catch (error) {
      // silent error
    }
  }
}
