export const PREFECTURES_WILL_REFRESH = "PREFECTURES_WILL_REFRESH";
export const PREFECTURES_DID_REFRESH = "PREFECTURES_DID_REFRESH";
export const PREFECTURES_DID_REFRESH_FAILED = "PREFECTURES_DID_REFRESH_FAILED";

export const PROFILE_WILL_REFRESH = "PROFILE_WILL_REFRESH";
export const PROFILE_DID_REFRESH = "PROFILE_DID_REFRESH";
export const PROFILE_DID_REFRESH_FAILED = "PROFILE_DID_REFRESH_FAILED";

export const PROFILE_FORM_OPEN = "PROFILE_FORM_OPEN";
export const PROFILE_FORM_CLOSE = "PROFILE_FORM_CLOSE";
export const PROFILE_FORM_CHANGE_FIELD = "PROFILE_FORM_CHANGE_FIELD";
export const PROFILE_FORM_WILL_SUBMIT = "PROFILE_FORM_WILL_SUBMIT";
export const PROFILE_FORM_DID_SUBMIT = "PROFILE_FORM_DID_SUBMIT";
export const PROFILE_FORM_DID_SUBMIT_FAILED = "PROFILE_FORM_DID_SUBMIT_FAILED";

export const REGIST_FORM_CHANGE_FIELD = "REGIST_FORM_CHANGE_FIELD";

export const MUST_UPDATE_PROFILE_SHOW = "MUST_UPDATE_PROFILE_SHOW";

export const PASSWORD_FORM_OPEN = "PASSWORD_FORM_OPEN";
export const PASSWORD_FORM_CLOSE = "PASSWORD_FORM_CLOSE";
export const PASSWORD_FORM_CHANGE_FIELD = "PASSWORD_FORM_CHANGE_FIELD";
export const PASSWORD_FORM_WILL_SUBMIT = "PASSWORD_FORM_WILL_SUBMIT";
export const PASSWORD_FORM_DID_SUBMIT = "PASSWORD_FORM_DID_SUBMIT";
export const PASSWORD_FORM_DID_SUBMIT_FAILED =
  "PASSWORD_FORM_DID_SUBMIT_FAILED";

export const EMAIL_FORM_OPEN = "EMAIL_FORM_OPEN";
export const EMAIL_FORM_CLOSE = "EMAIL_FORM_CLOSE";
export const EMAIL_FORM_CHANGE_FIELD = "EMAIL_FORM_CHANGE_FIELD";
export const EMAIL_FORM_WILL_SUBMIT = "EMAIL_FORM_WILL_SUBMIT";
export const EMAIL_FORM_DID_SUBMIT = "EMAIL_FORM_DID_SUBMIT";
export const EMAIL_FORM_DID_SUBMIT_FAILED = "EMAIL_FORM_DID_SUBMIT_FAILED";

export const SHIPPING_ADDRESSES_WILL_REFRESH =
  "SHIPPING_ADDRESSES_WILL_REFRESH";
export const SHIPPING_ADDRESSES_DID_REFRESH = "SHIPPING_ADDRESSES_DID_REFRESH";
export const SHIPPING_ADDRESSES_DID_REFRESH_FAILED =
  "SHIPPING_ADDRESSES_DID_REFRESH_FAILED";

export const SHIPPING_ADDRESSES_WILL_DELETE = "SHIPPING_ADDRESSES_WILL_DELETE";
export const SHIPPING_ADDRESSES_DID_DELETE = "SHIPPING_ADDRESSES_DID_DELETE";
export const SHIPPING_ADDRESSES_DID_DELETE_FAILED =
  "SHIPPING_ADDRESSES_DID_DELETE_FAILED";

export const SHIPPING_ADDRESSES_UPDATE = "SHIPPING_ADDRESSES_UPDATE";

export const ACCOUNT_SHIPPING_ADDRESS_FORM_NEW =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_NEW";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_EDIT =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_EDIT";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_LOAD =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_LOAD";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_CLOSE =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_CLOSE";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_CHANGE_FIELD =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_CHANGE_FIELD";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_WILL_SUBMIT =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_WILL_SUBMIT";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT";
export const ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT_FAILED =
  "ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT_FAILED";
export const ACCOUNT_SHIPPING_ADDRESS_WILL_SELECT_AS_DEFAULT =
  "ACCOUNT_SHIPPING_ADDRESS_WILL_SELECT_AS_DEFAULT";
export const ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT =
  "ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT";
export const ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT_FAILED =
  "ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT_FAILED";

export const ACCOUNT_CREDIT_CARD_FORM_NEW = "ACCOUNT_CREDIT_CARD_FORM_NEW";
export const ACCOUNT_CREDIT_CARD_FORM_EDIT = "AACCOUNT_CREDIT_CARD_FORM_EDIT";
export const ACCOUNT_CREDIT_CARDS_DID_FORM_LOAD = "ACCOUNT_CREDIT_CARDS_DID_FORM_LOAD";
export const ACCOUNT_CREDIT_CARD_FORM_CLOSE = "ACCOUNT_CREDIT_CARD_FORM_CLOSE";
export const ACCOUNT_CREDIT_CARD_FORM_CHANGE_FIELD =
  "ACCOUNT_CREDIT_CARD_FORM_CHANGE_FIELD";
export const ACCOUNT_CREDIT_CARD_FORM_WILL_SUBMIT =
  "ACCOUNT_CREDIT_CARD_FORM_WILL_SUBMIT";
export const ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT =
  "ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT";
export const ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT_FAILED =
  "ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT_FAILED";

export const CHECKOUT_WILL_REFRESH = "CHECKOUT_WILL_REFRESH";
export const CHECKOUT_DID_REFRESH = "CHECKOUT_DID_REFRESH";
export const CHECKOUT_DID_REFRESH_FAILED = "CHECKOUT_DID_REFRESH_FAILED";

export const CHECKOUT_WILL_CONFIRM = "CHECKOUT_WILL_CONFIRM";
export const CHECKOUT_DID_CONFIRM = "CHECKOUT_DID_CONFIRM";
export const CHECKOUT_DID_CONFIRM_FAILED = "CHECKOUT_DID_CONFIRM_FAILED";

export const CHECKOUT_WILL_MOUNT = "CHECKOUT_WILL_MOUNT";

export const CHECKOUT_SHOW_WARNING = "CHECKOUT_SHOW_WARNING";

export const CHECKOUT_ADDRESS_FORM_NEW = "CHECKOUT_ADDRESS_FORM_NEW";
export const CHECKOUT_ADDRESS_FORM_EDIT = "CHECKOUT_ADDRESS_FORM_EDIT";
export const CHECKOUT_ADDRESS_FORM_LOAD = "CHECKOUT_ADDRESS_FORM_LOAD";
export const CHECKOUT_ADDRESS_FORM_CLOSE = "CHECKOUT_ADDRESS_FORM_CLOSE";
export const CHECKOUT_ADDRESS_FORM_CHANGE_FIELD =
  "CHECKOUT_ADDRESS_FORM_CHANGE_FIELD";
export const CHECKOUT_ADDRESS_FORM_WILL_SUBMIT =
  "CHECKOUT_ADDRESS_FORM_WILL_SUBMIT";
export const CHECKOUT_ADDRESS_FORM_DID_SUBMIT =
  "CHECKOUT_ADDRESS_FORM_DID_SUBMIT";
export const CHECKOUT_ADDRESS_FORM_DID_SUBMIT_FAILED =
  "CHECKOUT_ADDRESS_FORM_DID_SUBMIT_FAILED";
export const CHECKOUT_ADDRESS_FORM_WILL_APPLY =
  "CHECKOUT_ADDRESS_FORM_WILL_APPLY";
export const CHECKOUT_ADDRESS_FORM_DID_APPLY =
  "CHECKOUT_ADDRESS_FORM_DID_APPLY";
export const CHECKOUT_ADDRESS_FORM_DID_APPLY_FAILED =
  "CHECKOUT_ADDRESS_FORM_DID_APPLY_FAILED";

export const CREDIT_CARDS_WILL_REFRESH = "CREDIT_CARDS_WILL_REFRESH";
export const CREDIT_CARDS_DID_REFRESH = "CREDIT_CARDS_DID_REFRESH";
export const CREDIT_CARDS_DID_REFRESH_FAILED =
  "CREDIT_CARDS_DID_REFRESH_FAILED";

export const CREDIT_CARDS_WILL_DELETE = "CREDIT_CARDS_WILL_DELETE";
export const CREDIT_CARDS_DID_DELETE = "CREDIT_CARDS_DID_DELETE";
export const CREDIT_CARDS_DID_DELETE_FAILED = "CREDIT_CARDS_DID_DELETE_FAILED";

export const CHECKOUT_CREDIT_CARD_FORM_NEW = "CHECKOUT_CREDIT_CARD_FORM_NEW";
export const CHECKOUT_CREDIT_CARD_FORM_LOAD = "CHECKOUT_CREDIT_CARD_FORM_LOAD";
export const CHECKOUT_CREDIT_CARD_FORM_CLOSE =
  "CHECKOUT_CREDIT_CARD_FORM_CLOSE";
export const CHECKOUT_CREDIT_CARD_FORM_CHANGE_FIELD =
  "CHECKOUT_CREDIT_CARD_FORM_CHANGE_FIELD";
export const CHECKOUT_CREDIT_CARD_FORM_WILL_SUBMIT =
  "CHECKOUT_CREDIT_CARD_FORM_WILL_SUBMIT";
export const CHECKOUT_CREDIT_CARD_FORM_DID_SUBMIT =
  "CHECKOUT_CREDIT_CARD_FORM_DID_SUBMIT";
export const CHECKOUT_CREDIT_CARD_FORM_DID_SUBMIT_FAILED =
  "CHECKOUT_CREDIT_CARD_FORM_DID_SUBMIT_FAILED";
export const CHECKOUT_CREDIT_CARD_FORM_WILL_APPLY =
  "CHECKOUT_CREDIT_CARD_FORM_WILL_APPLY";
export const CHECKOUT_CREDIT_CARD_FORM_DID_APPLY =
  "CHECKOUT_CREDIT_CARD_FORM_DID_APPLY";
export const CHECKOUT_CREDIT_CARD_FORM_DID_APPLY_FAILED =
  "CHECKOUT_CREDIT_CARD_FORM_DID_APPLY_FAILED";

export const CHECKOUT_CREDIT_CARD_FORM_WILL_APPLY_COD =
  "CHECKOUT_CREDIT_CARD_FORM_WILL_APPLY_COD";
export const CHECKOUT_CREDIT_CARD_FORM_DID_APPLY_COD =
  "CHECKOUT_CREDIT_CARD_FORM_DID_APPLY_COD";
export const CHECKOUT_CREDIT_CARD_FORM_DID_APPLY_COD_FAILED =
  "CHECKOUT_CREDIT_CARD_FORM_DID_APPLY_COD_FAILED";

export const CHECKOUT_DELIVERY_DATES_WILL_REFRESH =
  "CHECKOUT_DELIVERY_DATES_WILL_REFRESH";
export const CHECKOUT_DELIVERY_DATES_DID_REFRESH =
  "CHECKOUT_DELIVERY_DATES_DID_REFRESH";
export const CHECKOUT_DELIVERY_DATES_DID_REFRESH_FAILED =
  "CHECKOUT_DELIVERY_DATES_DID_REFRESH_FAILED";

export const CHECKOUT_COUPON_FORM_CHANGE_FIELD =
  "CHECKOUT_COUPON_FORM_CHANGE_FIELD";
export const CHECKOUT_COUPON_FORM_WILL_SUBMIT =
  "CHECKOUT_COUPON_FORM_WILL_SUBMIT";
export const CHECKOUT_COUPON_FORM_DID_SUBMIT =
  "CHECKOUT_COUPON_FORM_DID_SUBMIT";
export const CHECKOUT_COUPON_FORM_DID_SUBMIT_FAILED =
  "CHECKOUT_COUPON_FORM_DID_SUBMIT_FAILED";

export const CHECKOUT_COUPON_WILL_DELETE = "CHECKOUT_COUPON_WILL_DELETE";
export const CHECKOUT_COUPON_DID_DELETE = "CHECKOUT_COUPON_DID_DELETE";
export const CHECKOUT_COUPON_DID_DELETE_FAILED =
  "CHECKOUT_COUPON_DID_DELETE_FAILED";

export const CHECKOUT_POINTS_WILL_REFRESH = "CHECKOUT_POINTS_WILL_REFRESH";
export const CHECKOUT_POINTS_DID_REFRESH = "CHECKOUT_POINTS_DID_REFRESH";
export const CHECKOUT_POINTS_DID_REFRESH_FAILED =
  "CHECKOUT_POINTS_DID_REFRESH_FAILED";

export const CHECKOUT_POINTS_WILL_DELETE = "CHECKOUT_POINTS_WILL_DELETE";
export const CHECKOUT_POINTS_DID_DELETE = "CHECKOUT_POINTS_DID_DELETE";
export const CHECKOUT_POINTS_DID_DELETE_FAILED =
  "CHECKOUT_POINTS_DID_DELETE_FAILED";

export const CHECKOUT_POINTS_FORM_CHANGE_FIELD =
  "CHECKOUT_POINTS_FORM_CHANGE_FIELD";
export const CHECKOUT_POINTS_FORM_WILL_SUBMIT =
  "CHECKOUT_POINTS_FORM_WILL_SUBMIT";
export const CHECKOUT_POINTS_FORM_DID_SUBMIT =
  "CHECKOUT_POINTS_FORM_DID_SUBMIT";
export const CHECKOUT_POINTS_FORM_DID_SUBMIT_FAILED =
  "CHECKOUT_POINTS_FORM_DID_SUBMIT_FAILED";

export const CHECKOUT_DELIVERY_INFO_WILL_APPLY_SPECIFIED_OPTION =
  "CHECKOUT_DELIVERY_INFO_WILL_APPLY_SPECIFIED_OPTION";
export const CHECKOUT_DELIVERY_INFO_WILL_APPLY =
  "CHECKOUT_DELIVERY_INFO_WILL_APPLY";
export const CHECKOUT_DELIVERY_INFO_DID_APPLY =
  "CHECKOUT_DELIVERY_INFO_DID_APPLY";
export const CHECKOUT_DELIVERY_INFO_DID_APPLY_FAILED =
  "CHECKOUT_DELIVERY_INFO_DID_APPLY_FAILED";

export const CHECKOUT_DELIVERY_INFO_FORM_OPEN =
  "CHECKOUT_DELIVERY_INFO_FORM_OPEN";
export const CHECKOUT_DELIVERY_INFO_FORM_LOAD =
  "CHECKOUT_DELIVERY_INFO_FORM_LOAD";
export const CHECKOUT_DELIVERY_INFO_FORM_CLOSE =
  "CHECKOUT_DELIVERY_INFO_FORM_CLOSE";
export const CHECKOUT_DELIVERY_INFO_FORM_CHANGE_FIELD =
  "CHECKOUT_DELIVERY_INFO_FORM_CHANGE_FIELD";
export const CHECKOUT_DELIVERY_INFO_FORM_WILL_SUBMIT =
  "CHECKOUT_DELIVERY_INFO_FORM_WILL_SUBMIT";
export const CHECKOUT_DELIVERY_INFO_FORM_DID_SUBMIT =
  "CHECKOUT_DELIVERY_INFO_FORM_DID_SUBMIT";
export const CHECKOUT_DELIVERY_INFO_FORM_DID_SUBMIT_FAILED =
  "CHECKOUT_DELIVERY_INFO_FORM_DID_SUBMIT_FAILED";

export const CHECKOUT_GIFT_WRAPPING_FORM_CHANGE_FIELD =
  "CHECKOUT_GIFT_WRAPPING_FORM_CHANGE_FIELD";
export const CHECKOUT_GIFT_WRAPPING_FORM_WILL_SUBMIT =
  "CHECKOUT_GIFT_WRAPPING_FORM_WILL_SUBMIT";
export const CHECKOUT_GIFT_WRAPPING_FORM_DID_SUBMIT =
  "CHECKOUT_GIFT_WRAPPING_FORM_DID_SUBMIT";
export const CHECKOUT_GIFT_WRAPPING_FORM_DID_SUBMIT_FAILED =
  "CHECKOUT_GIFT_WRAPPING_FORM_DID_SUBMIT_FAILED";

export const CHECKOUT_GIFT_WRAPPING_POPUP_OPEN =
  "CHECKOUT_GIFT_WRAPPING_POPUP_OPEN";
export const CHECKOUT_GIFT_WRAPPING_POPUP_CLOSE =
  "CHECKOUT_GIFT_WRAPPING_POPUP_CLOSE";

export const CHECKOUT_STORE_PICK_UP_WILL_REFRESH =
  "CHECKOUT_STORE_PICK_UP_WILL_REFRESH";
export const CHECKOUT_STORE_PICK_UP_DID_REFRESH =
  "CHECKOUT_STORE_PICK_UP_DID_REFRESH";
export const CHECKOUT_STORE_PICK_UP_DID_REFRESH_FAILED =
  "CHECKOUT_STORE_PICK_UP_DID_REFRESH_FAILED";
export const CHECKOUT_STORE_PICK_UP_ORDER_WILL_APPLY =
  "CHECKOUT_STORE_PICK_UP_ORDER_WILL_APPLY";
export const CHECKOUT_STORE_PICK_UP_ORDER_DID_APPLY =
  "CHECKOUT_STORE_PICK_UP_ORDER_DID_APPLY";
export const CHECKOUT_STORE_PICK_UP_ORDER_DID_APPLY_FAILED =
  "CHECKOUT_STORE_PICK_UP_ORDER_DID_APPLY_FAILED";
export const CHECKOUT_STORE_PICK_UP_ORDER_WILL_CANCEL =
  "CHECKOUT_STORE_PICK_UP_ORDER_WILL_CANCEL";
export const CHECKOUT_STORE_PICK_UP_ORDER_DID_CANCEL =
  "CHECKOUT_STORE_PICK_UP_ORDER_DID_CANCEL";
export const CHECKOUT_STORE_PICK_UP_ORDER_DID_CANCEL_FAILED =
  "CHECKOUT_STORE_PICK_UP_ORDER_DID_CANCEL_FAILED";
export const CHECKOUT_STORE_PICK_UP_STORE_WILL_APPLY =
  "CHECKOUT_STORE_PICK_UP_STORE_WILL_APPLY";
export const CHECKOUT_STORE_PICK_UP_STORE_DID_APPLY =
  "CHECKOUT_STORE_PICK_UP_STORE_DID_APPLY";
export const CHECKOUT_STORE_PICK_UP_STORE_DID_APPLY_FAILED =
  "CHECKOUT_STORE_PICK_UP_STORE_DID_APPLY_FAILED";
export const CHECKOUT_STORE_PICK_UP_POPUP_OPEN =
  "CHECKOUT_STORE_PICK_UP_POPUP_OPEN";
export const CHECKOUT_STORE_PICK_UP_POPUP_CLOSE =
  "CHECKOUT_STORE_PICK_UP_POPUP_CLOSE";
export const CHECKOUT_STORE_PICK_UP_POPUP_SHOW_MAP_TAB =
  "CHECKOUT_STORE_PICK_UP_POPUP_SHOW_MAP_TAB";
export const CHECKOUT_STORE_PICK_UP_POPUP_SHOW_LIST_TAB =
  "CHECKOUT_STORE_PICK_UP_POPUP_SHOW_LIST_TAB";

export const FEES_WILL_REFRESH = "FEES_WILL_REFRESH";
export const FEES_DID_REFRESH = "FEES_DID_REFRESH";
export const FEES_DID_REFRESH_FAILED = "FEES_DID_REFRESH_FAILED";

export const POINTS_WILL_REFRESH = "POINTS_WILL_REFRESH";
export const POINTS_DID_REFRESH = "POINTS_DID_REFRESH";
export const POINTS_DID_REFRESH_FAILED = "POINTS_DID_REFRESH_FAILED";
export const SWTICH_POINTS_SUB_TABS = "SWTICH_POINTS_SUB_TABS";

export const POINTS_HISTORY_WILL_REFRESH = "POINTS_HISTORY_WILL_REFRESH";
export const POINTS_HISTORY_DID_REFRESH = "POINTS_HISTORY_DID_REFRESH";
export const POINTS_HISTORY_DID_REFRESH_FAILED =
  "POINTS_HISTORY_DID_REFRESH_FAILED";

export const WISH_ITEMS_WILL_REFRESH = "WISH_ITEMS_WILL_REFRESH";
export const WISH_ITEMS_DID_REFRESH = "WISH_ITEMS_DID_REFRESH";
export const WISH_ITEMS_DID_REFRESH_FAILED = "WISH_ITEMS_DID_REFRESH_FAILED";

export const WISH_ITEMS_DID_DELETE = "WISH_ITEMS_DID_DELETE";

export const ACCOUNT_WISH_ITEMS_TOGGLE_CHECKED =
  "ACCOUNT_WISH_ITEMS_TOGGLE_CHECKED";

export const ACCOUNT_WISH_ITEMS_WILL_DELETE = "ACCOUNT_WISH_ITEMS_WILL_DELETE";
export const ACCOUNT_WISH_ITEMS_DID_DELETE = "ACCOUNT_WISH_ITEMS_DID_DELETE";
export const ACCOUNT_WISH_ITEMS_DID_DELETE_FAILED =
  "ACCOUNT_WISH_ITEMS_DID_DELETE_FAILED";

export const ACCOUNT_WISH_ITEMS_WILL_MOVE_TO_CART =
  "ACCOUNT_WISH_ITEMS_WILL_MOVE_TO_CART";
export const ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART =
  "ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART";
export const ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART_FAILED =
  "ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART_FAILED";

export const ORDERS_WILL_REFRESH = "ORDERS_WILL_REFRESH";
export const ORDERS_DID_REFRESH = "ORDERS_DID_REFRESH";
export const ORDERS_DID_REFRESH_FAILED = "ORDERS_DID_REFRESH_FAILED";

export const ORDERS_WILL_CANCEL = "ORDERS_WILL_CANCEL";
export const ORDERS_DID_CANCEL = "ORDERS_DID_CANCEL";
export const ORDERS_DID_CANCEL_FAILED = "ORDERS_DID_CANCEL_FAILED";

export const CART_ITEMS_WILL_REFRESH = "CART_ITEMS_WILL_REFRESH";
export const CART_ITEMS_DID_REFRESH = "CART_ITEMS_DID_REFRESH";
export const CART_ITEMS_DID_REFRESH_FAILED = "CART_ITEMS_DID_REFRESH_FAILED";

export const CART_ITEMS_TOTAL_QUANTITY_WILL_REFRESH =
  "CART_ITEMS_TOTAL_QUANTITY_WILL_REFRESH";
export const CART_ITEMS_TOTAL_QUANTITY_DID_REFRESH =
  "CART_ITEMS_TOTAL_QUANTITY_DID_REFRESH";
export const CART_ITEMS_TOTAL_QUANTITY_DID_REFRESH_FAILED =
  "CART_ITEMS_TOTAL_QUANTITY_DID_REFRESH_FAILED";

export const CART_APP_WILL_MOUNT = "CART_APP_WILL_MOUNT";

export const CART_ITEMS_WILL_CLEAR = "CART_ITEMS_WILL_CLEAR";
export const CART_ITEMS_DID_CLEAR = "CART_ITEMS_DID_CLEAR";

export const CART_ITEM_WILL_DELETE = "CART_ITEM_WILL_DELETE";
export const CART_ITEM_DID_DELETE = "CART_ITEM_DID_DELETE";
export const CART_ITEM_DID_DELETE_FAILED = "CART_ITEM_DID_DELETE_FAILED";

export const CART_ITEM_WILL_MOVE_TO_WISHLIST =
  "CART_ITEM_WILL_MOVE_TO_WISHLIST";
export const CART_ITEM_DID_MOVE_TO_WISHLIST = "CART_ITEM_DID_MOVE_TO_WISHLIST";
export const CART_ITEM_DID_MOVE_TO_WISHLIST_FAILED =
  "CART_ITEM_DID_MOVE_TO_WISHLIST_FAILED";

export const CART_ITEM_QUANTITY_WILL_CHANGE = "CART_ITEM_QUANTITY_WILL_CHANGE";
export const CART_ITEM_QUANTITY_DID_CHANGE = "CART_ITEM_QUANTITY_DID_CHANGE";
export const CART_ITEM_QUANTITY_DID_CHANGE_FAILED =
  "CART_ITEM_QUANTITY_DID_CHANGE_FAILED";

export const ACCOUNT_VIEWED_PRODUCTS_WILL_REFRESH =
  "ACCOUNT_VIEWED_PRODUCTS_WILL_REFRESH";
export const ACCOUNT_VIEWED_PRODUCTS_DID_REFRESH =
  "ACCOUNT_VIEWED_PRODUCTS_DID_REFRESH";
export const ACCOUNT_VIEWED_PRODUCTS_REFRESH_FAILED =
  "ACCOUNT_VIEWED_PRODUCTS_REFRESH_FAILED";
