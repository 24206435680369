import * as React from "react";

import { CheckboxInput } from "../shared/CheckboxInput";
import { Errors } from "../shared/Errors";

import { checkoutForm } from "../../orders";
import { Form } from "../../form";

const input = Form.on(checkoutForm, "form");
const specifyBillingAddressForm = Form.on(input, "specifyBillingAddress");

const SpecifyBillingAddressOption = () => (
  <div className="specify-billing-address">
    <specifyBillingAddressForm.field
      component={({ value, updateInput, touchInput, errors, dirty }) => (
        <>
          <CheckboxInput
            checked={!value}
            onChange={(checked) => {
              updateInput(!checked);
              touchInput();
            }}
            className="mt-15"
          >
            <span>注文者情報と配送先情報は同じ</span>
          </CheckboxInput>
          <Errors errors={dirty ? errors : []} />
        </>
      )}
    />
  </div>
);

export { SpecifyBillingAddressOption };
