/*eslint-disable*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.theplant = (function() {

    /**
     * Namespace theplant.
     * @exports theplant
     * @namespace
     */
    var theplant = {};

    theplant.ec = (function() {

        /**
         * Namespace ec.
         * @memberof theplant
         * @namespace
         */
        var ec = {};

        ec.api = (function() {

            /**
             * Namespace api.
             * @memberof theplant.ec
             * @namespace
             */
            var api = {};

            api.Empty = (function() {

                /**
                 * Properties of an Empty.
                 * @memberof theplant.ec.api
                 * @interface IEmpty
                 */

                /**
                 * Constructs a new Empty.
                 * @memberof theplant.ec.api
                 * @classdesc Represents an Empty.
                 * @implements IEmpty
                 * @constructor
                 * @param {theplant.ec.api.IEmpty=} [properties] Properties to set
                 */
                function Empty(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Empty instance using the specified properties.
                 * @function create
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {theplant.ec.api.IEmpty=} [properties] Properties to set
                 * @returns {theplant.ec.api.Empty} Empty instance
                 */
                Empty.create = function create(properties) {
                    return new Empty(properties);
                };

                /**
                 * Encodes the specified Empty message. Does not implicitly {@link theplant.ec.api.Empty.verify|verify} messages.
                 * @function encode
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {theplant.ec.api.IEmpty} message Empty message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Empty.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Empty message, length delimited. Does not implicitly {@link theplant.ec.api.Empty.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {theplant.ec.api.IEmpty} message Empty message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Empty.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an Empty message from the specified reader or buffer.
                 * @function decode
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {theplant.ec.api.Empty} Empty
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Empty.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.Empty();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an Empty message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {theplant.ec.api.Empty} Empty
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Empty.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an Empty message.
                 * @function verify
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Empty.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates an Empty message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {theplant.ec.api.Empty} Empty
                 */
                Empty.fromObject = function fromObject(object) {
                    if (object instanceof $root.theplant.ec.api.Empty)
                        return object;
                    return new $root.theplant.ec.api.Empty();
                };

                /**
                 * Creates a plain object from an Empty message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof theplant.ec.api.Empty
                 * @static
                 * @param {theplant.ec.api.Empty} message Empty
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Empty.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Empty to JSON.
                 * @function toJSON
                 * @memberof theplant.ec.api.Empty
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Empty.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Empty;
            })();

            /**
             * ErrorCode enum.
             * @name theplant.ec.api.ErrorCode
             * @enum {string}
             * @property {number} OK=0 OK value
             * @property {number} CANCELED=1 CANCELED value
             * @property {number} UNKNOWN=2 UNKNOWN value
             * @property {number} INVALIDARGUMENT=3 INVALIDARGUMENT value
             * @property {number} DEADLINEEXCEEDED=4 DEADLINEEXCEEDED value
             * @property {number} NOTFOUND=5 NOTFOUND value
             * @property {number} ALREADYEXISTS=6 ALREADYEXISTS value
             * @property {number} PERMISSIONDENIED=7 PERMISSIONDENIED value
             * @property {number} UNAUTHENTICATED=16 UNAUTHENTICATED value
             * @property {number} RESOURCEEXHAUSTED=8 RESOURCEEXHAUSTED value
             * @property {number} FAILEDPRECONDITION=9 FAILEDPRECONDITION value
             * @property {number} ABORTED=10 ABORTED value
             * @property {number} OUTOFRANGE=11 OUTOFRANGE value
             * @property {number} UNIMPLEMENTED=12 UNIMPLEMENTED value
             * @property {number} INTERNAL=13 INTERNAL value
             * @property {number} UNAVAILABLE=14 UNAVAILABLE value
             * @property {number} DATALOSS=15 DATALOSS value
             * @property {number} LTE=17 LTE value
             * @property {number} GTE=18 GTE value
             * @property {number} STRICT_REQUIRED=19 STRICT_REQUIRED value
             * @property {number} INVALID_ZIPCODE=20 INVALID_ZIPCODE value
             * @property {number} NOTFOUND_ZIPCODE=49 NOTFOUND_ZIPCODE value
             * @property {number} INVALID_PREFECTURE=21 INVALID_PREFECTURE value
             * @property {number} INVALID_PHONE=22 INVALID_PHONE value
             * @property {number} INVALID_DATE_OF_BIRTH=23 INVALID_DATE_OF_BIRTH value
             * @property {number} INVALID_GENDER=24 INVALID_GENDER value
             * @property {number} INVALID_EMAIL_FORMAT=25 INVALID_EMAIL_FORMAT value
             * @property {number} INVALID_CONTACT_US_TITLE=50 INVALID_CONTACT_US_TITLE value
             * @property {number} INVALID_ORDER_CODE=51 INVALID_ORDER_CODE value
             * @property {number} INVALID_ARTICLE_CODE=65 INVALID_ARTICLE_CODE value
             * @property {number} INVALID_CONTACT_US_REASON_TYPE=52 INVALID_CONTACT_US_REASON_TYPE value
             * @property {number} PASSWORD_CONFIRMATION_NOT_MATCH=26 PASSWORD_CONFIRMATION_NOT_MATCH value
             * @property {number} EMAIL_ALREADY_EXISTS=27 EMAIL_ALREADY_EXISTS value
             * @property {number} EMAIL_NOT_EXISTS=30 EMAIL_NOT_EXISTS value
             * @property {number} INVALID_EMAIL_OR_PASSWORD=28 INVALID_EMAIL_OR_PASSWORD value
             * @property {number} USER_ACCOUNT_LOCKED=29 USER_ACCOUNT_LOCKED value
             * @property {number} INVALID_TOKEN=31 INVALID_TOKEN value
             * @property {number} PASSWORD_SAME_AS_OLD_PASSWORD=32 PASSWORD_SAME_AS_OLD_PASSWORD value
             * @property {number} NEW_EMAIL_SAME_AS_OLD_EMAIL=33 NEW_EMAIL_SAME_AS_OLD_EMAIL value
             * @property {number} EMAIL_SENT_FAILED=53 EMAIL_SENT_FAILED value
             * @property {number} EMAIL_NOT_MATCH=63 EMAIL_NOT_MATCH value
             * @property {number} NO_PASSWORD_FOR_THIS_USER=34 NO_PASSWORD_FOR_THIS_USER value
             * @property {number} PASSWORD_INCORRECT=35 PASSWORD_INCORRECT value
             * @property {number} NOT_ENOUGH_STOCK=36 NOT_ENOUGH_STOCK value
             * @property {number} ORDER_ITEM_NOT_EXISTS=37 ORDER_ITEM_NOT_EXISTS value
             * @property {number} INVALID_ORDER_STATE=38 INVALID_ORDER_STATE value
             * @property {number} ORDER_CANNOT_BE_CANCELED=39 ORDER_CANNOT_BE_CANCELED value
             * @property {number} ORDER_NOT_EXISTS=40 ORDER_NOT_EXISTS value
             * @property {number} ORDER_NOT_BELONGS_TO_CUSTOMER=41 ORDER_NOT_BELONGS_TO_CUSTOMER value
             * @property {number} ADD_NOT_EXISTS_PRODUCT=42 ADD_NOT_EXISTS_PRODUCT value
             * @property {number} ADD_UNPUBLISHED_PRODUCT=43 ADD_UNPUBLISHED_PRODUCT value
             * @property {number} ADD_CONFLICT_PRODUCT=59 ADD_CONFLICT_PRODUCT value
             * @property {number} ADD_AMOUNT_LIMIT_PRODUCT=60 ADD_AMOUNT_LIMIT_PRODUCT value
             * @property {number} ORDER_CANNOT_REQUEST_RETURN=64 ORDER_CANNOT_REQUEST_RETURN value
             * @property {number} ORDER_ITEM_CANNOT_RETURN_AGAIN=66 ORDER_ITEM_CANNOT_RETURN_AGAIN value
             * @property {number} ORDER_CONTAINS_CUSTOMIZATION_CANNOT_REQUEST_RETURN=68 ORDER_CONTAINS_CUSTOMIZATION_CANNOT_REQUEST_RETURN value
             * @property {number} ORDER_CONTAINS_CUSTOMIZATION_CANNOT_BE_CANCELED=69 ORDER_CONTAINS_CUSTOMIZATION_CANNOT_BE_CANCELED value
             * @property {number} USER_NOT_EXISTS=44 USER_NOT_EXISTS value
             * @property {number} DISAGREED_TERMS=45 DISAGREED_TERMS value
             * @property {number} INVALID_USER_BILLING_ADDRESS=54 INVALID_USER_BILLING_ADDRESS value
             * @property {number} UNSPECIFIED_CREDIT_CARD=46 UNSPECIFIED_CREDIT_CARD value
             * @property {number} PAYMENT_FAILURE=47 PAYMENT_FAILURE value
             * @property {number} UNSPECIFIED_PAYMENT_TYPE=48 UNSPECIFIED_PAYMENT_TYPE value
             * @property {number} INVALID=55 INVALID value
             * @property {number} STRICT_EMPTY=56 STRICT_EMPTY value
             * @property {number} EXPIRED=57 EXPIRED value
             * @property {number} CAN_NOT_CONVENIENCE_PICKUP=58 CAN_NOT_CONVENIENCE_PICKUP value
             * @property {number} EXCEED_CART_AMOUNT_LIMIT=61 EXCEED_CART_AMOUNT_LIMIT value
             * @property {number} INVALID_MESSAGE_FORMAT=62 INVALID_MESSAGE_FORMAT value
             * @property {number} CREDIT_CARD_ERRORS_FROM_CREDIT_CARD_SERVICE=67 CREDIT_CARD_ERRORS_FROM_CREDIT_CARD_SERVICE value
             * @property {number} QUANTITY_OF_SINGLE_SHIP_FROM_STORE_ITEM_HAS_REACHED_THE_MAXIMUM=70 QUANTITY_OF_SINGLE_SHIP_FROM_STORE_ITEM_HAS_REACHED_THE_MAXIMUM value
             * @property {number} OPLUX_CREDIT_CARD_VERTIFICATION=71 OPLUX_CREDIT_CARD_VERTIFICATION value
             * @property {number} CANOPUS_API_FAILED=72 CANOPUS_API_FAILED value
             * @property {number} EMAIL_SENT_INVALIDE=73 EMAIL_SENT_INVALIDE value
             * @property {number} AMAZON_PAY_SHIPPING_ADDRESS_INVALIDE=74 AMAZON_PAY_SHIPPING_ADDRESS_INVALIDE value
             * @property {number} RAKUTEN_PAY_REQUIRE_TRANSACTION_ID=75 RAKUTEN_PAY_REQUIRE_TRANSACTION_ID value
             * @property {number} AMAZON_PAY_BILLING_ADDRESS_INVALIDE=76 AMAZON_PAY_BILLING_ADDRESS_INVALIDE value
             * @property {number} PAY_PAY_REQUIRE_TRANSACTION_ID=77 PAY_PAY_REQUIRE_TRANSACTION_ID value
             * @property {number} PAYMENT_CHECKOUT_DECLINED_OR_CANCELED=78 PAYMENT_CHECKOUT_DECLINED_OR_CANCELED value
             */
            api.ErrorCode = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "OK"] = 0;
                values[valuesById[1] = "CANCELED"] = 1;
                values[valuesById[2] = "UNKNOWN"] = 2;
                values[valuesById[3] = "INVALIDARGUMENT"] = 3;
                values[valuesById[4] = "DEADLINEEXCEEDED"] = 4;
                values[valuesById[5] = "NOTFOUND"] = 5;
                values[valuesById[6] = "ALREADYEXISTS"] = 6;
                values[valuesById[7] = "PERMISSIONDENIED"] = 7;
                values[valuesById[16] = "UNAUTHENTICATED"] = 16;
                values[valuesById[8] = "RESOURCEEXHAUSTED"] = 8;
                values[valuesById[9] = "FAILEDPRECONDITION"] = 9;
                values[valuesById[10] = "ABORTED"] = 10;
                values[valuesById[11] = "OUTOFRANGE"] = 11;
                values[valuesById[12] = "UNIMPLEMENTED"] = 12;
                values[valuesById[13] = "INTERNAL"] = 13;
                values[valuesById[14] = "UNAVAILABLE"] = 14;
                values[valuesById[15] = "DATALOSS"] = 15;
                values[valuesById[17] = "LTE"] = 17;
                values[valuesById[18] = "GTE"] = 18;
                values[valuesById[19] = "STRICT_REQUIRED"] = 19;
                values[valuesById[20] = "INVALID_ZIPCODE"] = 20;
                values[valuesById[49] = "NOTFOUND_ZIPCODE"] = 49;
                values[valuesById[21] = "INVALID_PREFECTURE"] = 21;
                values[valuesById[22] = "INVALID_PHONE"] = 22;
                values[valuesById[23] = "INVALID_DATE_OF_BIRTH"] = 23;
                values[valuesById[24] = "INVALID_GENDER"] = 24;
                values[valuesById[25] = "INVALID_EMAIL_FORMAT"] = 25;
                values[valuesById[50] = "INVALID_CONTACT_US_TITLE"] = 50;
                values[valuesById[51] = "INVALID_ORDER_CODE"] = 51;
                values[valuesById[65] = "INVALID_ARTICLE_CODE"] = 65;
                values[valuesById[52] = "INVALID_CONTACT_US_REASON_TYPE"] = 52;
                values[valuesById[26] = "PASSWORD_CONFIRMATION_NOT_MATCH"] = 26;
                values[valuesById[27] = "EMAIL_ALREADY_EXISTS"] = 27;
                values[valuesById[30] = "EMAIL_NOT_EXISTS"] = 30;
                values[valuesById[28] = "INVALID_EMAIL_OR_PASSWORD"] = 28;
                values[valuesById[29] = "USER_ACCOUNT_LOCKED"] = 29;
                values[valuesById[31] = "INVALID_TOKEN"] = 31;
                values[valuesById[32] = "PASSWORD_SAME_AS_OLD_PASSWORD"] = 32;
                values[valuesById[33] = "NEW_EMAIL_SAME_AS_OLD_EMAIL"] = 33;
                values[valuesById[53] = "EMAIL_SENT_FAILED"] = 53;
                values[valuesById[63] = "EMAIL_NOT_MATCH"] = 63;
                values[valuesById[34] = "NO_PASSWORD_FOR_THIS_USER"] = 34;
                values[valuesById[35] = "PASSWORD_INCORRECT"] = 35;
                values[valuesById[36] = "NOT_ENOUGH_STOCK"] = 36;
                values[valuesById[37] = "ORDER_ITEM_NOT_EXISTS"] = 37;
                values[valuesById[38] = "INVALID_ORDER_STATE"] = 38;
                values[valuesById[39] = "ORDER_CANNOT_BE_CANCELED"] = 39;
                values[valuesById[40] = "ORDER_NOT_EXISTS"] = 40;
                values[valuesById[41] = "ORDER_NOT_BELONGS_TO_CUSTOMER"] = 41;
                values[valuesById[42] = "ADD_NOT_EXISTS_PRODUCT"] = 42;
                values[valuesById[43] = "ADD_UNPUBLISHED_PRODUCT"] = 43;
                values[valuesById[59] = "ADD_CONFLICT_PRODUCT"] = 59;
                values[valuesById[60] = "ADD_AMOUNT_LIMIT_PRODUCT"] = 60;
                values[valuesById[64] = "ORDER_CANNOT_REQUEST_RETURN"] = 64;
                values[valuesById[66] = "ORDER_ITEM_CANNOT_RETURN_AGAIN"] = 66;
                values[valuesById[68] = "ORDER_CONTAINS_CUSTOMIZATION_CANNOT_REQUEST_RETURN"] = 68;
                values[valuesById[69] = "ORDER_CONTAINS_CUSTOMIZATION_CANNOT_BE_CANCELED"] = 69;
                values[valuesById[44] = "USER_NOT_EXISTS"] = 44;
                values[valuesById[45] = "DISAGREED_TERMS"] = 45;
                values[valuesById[54] = "INVALID_USER_BILLING_ADDRESS"] = 54;
                values[valuesById[46] = "UNSPECIFIED_CREDIT_CARD"] = 46;
                values[valuesById[47] = "PAYMENT_FAILURE"] = 47;
                values[valuesById[48] = "UNSPECIFIED_PAYMENT_TYPE"] = 48;
                values[valuesById[55] = "INVALID"] = 55;
                values[valuesById[56] = "STRICT_EMPTY"] = 56;
                values[valuesById[57] = "EXPIRED"] = 57;
                values[valuesById[58] = "CAN_NOT_CONVENIENCE_PICKUP"] = 58;
                values[valuesById[61] = "EXCEED_CART_AMOUNT_LIMIT"] = 61;
                values[valuesById[62] = "INVALID_MESSAGE_FORMAT"] = 62;
                values[valuesById[67] = "CREDIT_CARD_ERRORS_FROM_CREDIT_CARD_SERVICE"] = 67;
                values[valuesById[70] = "QUANTITY_OF_SINGLE_SHIP_FROM_STORE_ITEM_HAS_REACHED_THE_MAXIMUM"] = 70;
                values[valuesById[71] = "OPLUX_CREDIT_CARD_VERTIFICATION"] = 71;
                values[valuesById[72] = "CANOPUS_API_FAILED"] = 72;
                values[valuesById[73] = "EMAIL_SENT_INVALIDE"] = 73;
                values[valuesById[74] = "AMAZON_PAY_SHIPPING_ADDRESS_INVALIDE"] = 74;
                values[valuesById[75] = "RAKUTEN_PAY_REQUIRE_TRANSACTION_ID"] = 75;
                values[valuesById[76] = "AMAZON_PAY_BILLING_ADDRESS_INVALIDE"] = 76;
                values[valuesById[77] = "PAY_PAY_REQUIRE_TRANSACTION_ID"] = 77;
                values[valuesById[78] = "PAYMENT_CHECKOUT_DECLINED_OR_CANCELED"] = 78;
                return values;
            })();

            api.apns = (function() {

                /**
                 * Namespace apns.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var apns = {};

                apns.DeviceToken = (function() {

                    /**
                     * Properties of a DeviceToken.
                     * @memberof theplant.ec.api.apns
                     * @interface IDeviceToken
                     * @property {string|null} [storeId] DeviceToken storeId
                     * @property {string|null} [deviceToken] DeviceToken deviceToken
                     */

                    /**
                     * Constructs a new DeviceToken.
                     * @memberof theplant.ec.api.apns
                     * @classdesc Represents a DeviceToken.
                     * @implements IDeviceToken
                     * @constructor
                     * @param {theplant.ec.api.apns.IDeviceToken=} [properties] Properties to set
                     */
                    function DeviceToken(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeviceToken storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @instance
                     */
                    DeviceToken.prototype.storeId = "";

                    /**
                     * DeviceToken deviceToken.
                     * @member {string} deviceToken
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @instance
                     */
                    DeviceToken.prototype.deviceToken = "";

                    /**
                     * Creates a new DeviceToken instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {theplant.ec.api.apns.IDeviceToken=} [properties] Properties to set
                     * @returns {theplant.ec.api.apns.DeviceToken} DeviceToken instance
                     */
                    DeviceToken.create = function create(properties) {
                        return new DeviceToken(properties);
                    };

                    /**
                     * Encodes the specified DeviceToken message. Does not implicitly {@link theplant.ec.api.apns.DeviceToken.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {theplant.ec.api.apns.IDeviceToken} message DeviceToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeviceToken.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.storeId);
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.deviceToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeviceToken message, length delimited. Does not implicitly {@link theplant.ec.api.apns.DeviceToken.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {theplant.ec.api.apns.IDeviceToken} message DeviceToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeviceToken.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeviceToken message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.apns.DeviceToken} DeviceToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeviceToken.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.apns.DeviceToken();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.string();
                                break;
                            case 2:
                                message.deviceToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeviceToken message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.apns.DeviceToken} DeviceToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeviceToken.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeviceToken message.
                     * @function verify
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeviceToken.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            if (!$util.isString(message.deviceToken))
                                return "deviceToken: string expected";
                        return null;
                    };

                    /**
                     * Creates a DeviceToken message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.apns.DeviceToken} DeviceToken
                     */
                    DeviceToken.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.apns.DeviceToken)
                            return object;
                        var message = new $root.theplant.ec.api.apns.DeviceToken();
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.deviceToken != null)
                            message.deviceToken = String(object.deviceToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeviceToken message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @static
                     * @param {theplant.ec.api.apns.DeviceToken} message DeviceToken
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeviceToken.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeId = "";
                            object.deviceToken = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            object.deviceToken = message.deviceToken;
                        return object;
                    };

                    /**
                     * Converts this DeviceToken to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.apns.DeviceToken
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeviceToken.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeviceToken;
                })();

                apns.RegisterStoreDeviceTokenResult = (function() {

                    /**
                     * Properties of a RegisterStoreDeviceTokenResult.
                     * @memberof theplant.ec.api.apns
                     * @interface IRegisterStoreDeviceTokenResult
                     * @property {string|null} [storeId] RegisterStoreDeviceTokenResult storeId
                     * @property {string|null} [deviceToken] RegisterStoreDeviceTokenResult deviceToken
                     */

                    /**
                     * Constructs a new RegisterStoreDeviceTokenResult.
                     * @memberof theplant.ec.api.apns
                     * @classdesc Represents a RegisterStoreDeviceTokenResult.
                     * @implements IRegisterStoreDeviceTokenResult
                     * @constructor
                     * @param {theplant.ec.api.apns.IRegisterStoreDeviceTokenResult=} [properties] Properties to set
                     */
                    function RegisterStoreDeviceTokenResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RegisterStoreDeviceTokenResult storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @instance
                     */
                    RegisterStoreDeviceTokenResult.prototype.storeId = "";

                    /**
                     * RegisterStoreDeviceTokenResult deviceToken.
                     * @member {string} deviceToken
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @instance
                     */
                    RegisterStoreDeviceTokenResult.prototype.deviceToken = "";

                    /**
                     * Creates a new RegisterStoreDeviceTokenResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.IRegisterStoreDeviceTokenResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.apns.RegisterStoreDeviceTokenResult} RegisterStoreDeviceTokenResult instance
                     */
                    RegisterStoreDeviceTokenResult.create = function create(properties) {
                        return new RegisterStoreDeviceTokenResult(properties);
                    };

                    /**
                     * Encodes the specified RegisterStoreDeviceTokenResult message. Does not implicitly {@link theplant.ec.api.apns.RegisterStoreDeviceTokenResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.IRegisterStoreDeviceTokenResult} message RegisterStoreDeviceTokenResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterStoreDeviceTokenResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.storeId);
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.deviceToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified RegisterStoreDeviceTokenResult message, length delimited. Does not implicitly {@link theplant.ec.api.apns.RegisterStoreDeviceTokenResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.IRegisterStoreDeviceTokenResult} message RegisterStoreDeviceTokenResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterStoreDeviceTokenResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RegisterStoreDeviceTokenResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.apns.RegisterStoreDeviceTokenResult} RegisterStoreDeviceTokenResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterStoreDeviceTokenResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.apns.RegisterStoreDeviceTokenResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.string();
                                break;
                            case 2:
                                message.deviceToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RegisterStoreDeviceTokenResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.apns.RegisterStoreDeviceTokenResult} RegisterStoreDeviceTokenResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterStoreDeviceTokenResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RegisterStoreDeviceTokenResult message.
                     * @function verify
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RegisterStoreDeviceTokenResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            if (!$util.isString(message.deviceToken))
                                return "deviceToken: string expected";
                        return null;
                    };

                    /**
                     * Creates a RegisterStoreDeviceTokenResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.apns.RegisterStoreDeviceTokenResult} RegisterStoreDeviceTokenResult
                     */
                    RegisterStoreDeviceTokenResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.apns.RegisterStoreDeviceTokenResult)
                            return object;
                        var message = new $root.theplant.ec.api.apns.RegisterStoreDeviceTokenResult();
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.deviceToken != null)
                            message.deviceToken = String(object.deviceToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RegisterStoreDeviceTokenResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.RegisterStoreDeviceTokenResult} message RegisterStoreDeviceTokenResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RegisterStoreDeviceTokenResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeId = "";
                            object.deviceToken = "";
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            object.deviceToken = message.deviceToken;
                        return object;
                    };

                    /**
                     * Converts this RegisterStoreDeviceTokenResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.apns.RegisterStoreDeviceTokenResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RegisterStoreDeviceTokenResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RegisterStoreDeviceTokenResult;
                })();

                apns.DeleteDeviceTokenInput = (function() {

                    /**
                     * Properties of a DeleteDeviceTokenInput.
                     * @memberof theplant.ec.api.apns
                     * @interface IDeleteDeviceTokenInput
                     * @property {string|null} [deviceToken] DeleteDeviceTokenInput deviceToken
                     */

                    /**
                     * Constructs a new DeleteDeviceTokenInput.
                     * @memberof theplant.ec.api.apns
                     * @classdesc Represents a DeleteDeviceTokenInput.
                     * @implements IDeleteDeviceTokenInput
                     * @constructor
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenInput=} [properties] Properties to set
                     */
                    function DeleteDeviceTokenInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeleteDeviceTokenInput deviceToken.
                     * @member {string} deviceToken
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @instance
                     */
                    DeleteDeviceTokenInput.prototype.deviceToken = "";

                    /**
                     * Creates a new DeleteDeviceTokenInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenInput} DeleteDeviceTokenInput instance
                     */
                    DeleteDeviceTokenInput.create = function create(properties) {
                        return new DeleteDeviceTokenInput(properties);
                    };

                    /**
                     * Encodes the specified DeleteDeviceTokenInput message. Does not implicitly {@link theplant.ec.api.apns.DeleteDeviceTokenInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenInput} message DeleteDeviceTokenInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDeviceTokenInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeleteDeviceTokenInput message, length delimited. Does not implicitly {@link theplant.ec.api.apns.DeleteDeviceTokenInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenInput} message DeleteDeviceTokenInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDeviceTokenInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeleteDeviceTokenInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenInput} DeleteDeviceTokenInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDeviceTokenInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.apns.DeleteDeviceTokenInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.deviceToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeleteDeviceTokenInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenInput} DeleteDeviceTokenInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDeviceTokenInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeleteDeviceTokenInput message.
                     * @function verify
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteDeviceTokenInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            if (!$util.isString(message.deviceToken))
                                return "deviceToken: string expected";
                        return null;
                    };

                    /**
                     * Creates a DeleteDeviceTokenInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenInput} DeleteDeviceTokenInput
                     */
                    DeleteDeviceTokenInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.apns.DeleteDeviceTokenInput)
                            return object;
                        var message = new $root.theplant.ec.api.apns.DeleteDeviceTokenInput();
                        if (object.deviceToken != null)
                            message.deviceToken = String(object.deviceToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeleteDeviceTokenInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @static
                     * @param {theplant.ec.api.apns.DeleteDeviceTokenInput} message DeleteDeviceTokenInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteDeviceTokenInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.deviceToken = "";
                        if (message.deviceToken != null && message.hasOwnProperty("deviceToken"))
                            object.deviceToken = message.deviceToken;
                        return object;
                    };

                    /**
                     * Converts this DeleteDeviceTokenInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteDeviceTokenInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeleteDeviceTokenInput;
                })();

                apns.DeleteDeviceTokenResult = (function() {

                    /**
                     * Properties of a DeleteDeviceTokenResult.
                     * @memberof theplant.ec.api.apns
                     * @interface IDeleteDeviceTokenResult
                     */

                    /**
                     * Constructs a new DeleteDeviceTokenResult.
                     * @memberof theplant.ec.api.apns
                     * @classdesc Represents a DeleteDeviceTokenResult.
                     * @implements IDeleteDeviceTokenResult
                     * @constructor
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenResult=} [properties] Properties to set
                     */
                    function DeleteDeviceTokenResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Creates a new DeleteDeviceTokenResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenResult} DeleteDeviceTokenResult instance
                     */
                    DeleteDeviceTokenResult.create = function create(properties) {
                        return new DeleteDeviceTokenResult(properties);
                    };

                    /**
                     * Encodes the specified DeleteDeviceTokenResult message. Does not implicitly {@link theplant.ec.api.apns.DeleteDeviceTokenResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenResult} message DeleteDeviceTokenResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDeviceTokenResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        return writer;
                    };

                    /**
                     * Encodes the specified DeleteDeviceTokenResult message, length delimited. Does not implicitly {@link theplant.ec.api.apns.DeleteDeviceTokenResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenResult} message DeleteDeviceTokenResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDeviceTokenResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeleteDeviceTokenResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenResult} DeleteDeviceTokenResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDeviceTokenResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.apns.DeleteDeviceTokenResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeleteDeviceTokenResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenResult} DeleteDeviceTokenResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDeviceTokenResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeleteDeviceTokenResult message.
                     * @function verify
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteDeviceTokenResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        return null;
                    };

                    /**
                     * Creates a DeleteDeviceTokenResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.apns.DeleteDeviceTokenResult} DeleteDeviceTokenResult
                     */
                    DeleteDeviceTokenResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.apns.DeleteDeviceTokenResult)
                            return object;
                        return new $root.theplant.ec.api.apns.DeleteDeviceTokenResult();
                    };

                    /**
                     * Creates a plain object from a DeleteDeviceTokenResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @static
                     * @param {theplant.ec.api.apns.DeleteDeviceTokenResult} message DeleteDeviceTokenResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteDeviceTokenResult.toObject = function toObject() {
                        return {};
                    };

                    /**
                     * Converts this DeleteDeviceTokenResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.apns.DeleteDeviceTokenResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteDeviceTokenResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeleteDeviceTokenResult;
                })();

                apns.APNSDeviceTokenService = (function() {

                    /**
                     * Constructs a new APNSDeviceTokenService service.
                     * @memberof theplant.ec.api.apns
                     * @classdesc Represents a APNSDeviceTokenService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function APNSDeviceTokenService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (APNSDeviceTokenService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = APNSDeviceTokenService;

                    /**
                     * Creates new APNSDeviceTokenService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {APNSDeviceTokenService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    APNSDeviceTokenService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.apns.APNSDeviceTokenService#registerStoreDeviceToken}.
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @typedef RegisterStoreDeviceTokenCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.apns.RegisterStoreDeviceTokenResult} [response] RegisterStoreDeviceTokenResult
                     */

                    /**
                     * Calls RegisterStoreDeviceToken.
                     * @function registerStoreDeviceToken
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @instance
                     * @param {theplant.ec.api.apns.IDeviceToken} request DeviceToken message or plain object
                     * @param {theplant.ec.api.apns.APNSDeviceTokenService.RegisterStoreDeviceTokenCallback} callback Node-style callback called with the error, if any, and RegisterStoreDeviceTokenResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(APNSDeviceTokenService.prototype.registerStoreDeviceToken = function registerStoreDeviceToken(request, callback) {
                        return this.rpcCall(registerStoreDeviceToken, $root.theplant.ec.api.apns.DeviceToken, $root.theplant.ec.api.apns.RegisterStoreDeviceTokenResult, request, callback);
                    }, "name", { value: "RegisterStoreDeviceToken" });

                    /**
                     * Calls RegisterStoreDeviceToken.
                     * @function registerStoreDeviceToken
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @instance
                     * @param {theplant.ec.api.apns.IDeviceToken} request DeviceToken message or plain object
                     * @returns {Promise<theplant.ec.api.apns.RegisterStoreDeviceTokenResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.apns.APNSDeviceTokenService#deleteDeviceToken}.
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @typedef DeleteDeviceTokenCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.apns.DeleteDeviceTokenResult} [response] DeleteDeviceTokenResult
                     */

                    /**
                     * Calls DeleteDeviceToken.
                     * @function deleteDeviceToken
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @instance
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenInput} request DeleteDeviceTokenInput message or plain object
                     * @param {theplant.ec.api.apns.APNSDeviceTokenService.DeleteDeviceTokenCallback} callback Node-style callback called with the error, if any, and DeleteDeviceTokenResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(APNSDeviceTokenService.prototype.deleteDeviceToken = function deleteDeviceToken(request, callback) {
                        return this.rpcCall(deleteDeviceToken, $root.theplant.ec.api.apns.DeleteDeviceTokenInput, $root.theplant.ec.api.apns.DeleteDeviceTokenResult, request, callback);
                    }, "name", { value: "DeleteDeviceToken" });

                    /**
                     * Calls DeleteDeviceToken.
                     * @function deleteDeviceToken
                     * @memberof theplant.ec.api.apns.APNSDeviceTokenService
                     * @instance
                     * @param {theplant.ec.api.apns.IDeleteDeviceTokenInput} request DeleteDeviceTokenInput message or plain object
                     * @returns {Promise<theplant.ec.api.apns.DeleteDeviceTokenResult>} Promise
                     * @variation 2
                     */

                    return APNSDeviceTokenService;
                })();

                return apns;
            })();

            api.blog = (function() {

                /**
                 * Namespace blog.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var blog = {};

                blog.GetArticleListPrams = (function() {

                    /**
                     * Properties of a GetArticleListPrams.
                     * @memberof theplant.ec.api.blog
                     * @interface IGetArticleListPrams
                     * @property {theplant.ec.service.blog.ICategory|null} [category] GetArticleListPrams category
                     * @property {theplant.ec.service.base.IPagination|null} [pagination] GetArticleListPrams pagination
                     */

                    /**
                     * Constructs a new GetArticleListPrams.
                     * @memberof theplant.ec.api.blog
                     * @classdesc Represents a GetArticleListPrams.
                     * @implements IGetArticleListPrams
                     * @constructor
                     * @param {theplant.ec.api.blog.IGetArticleListPrams=} [properties] Properties to set
                     */
                    function GetArticleListPrams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetArticleListPrams category.
                     * @member {theplant.ec.service.blog.ICategory|null|undefined} category
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @instance
                     */
                    GetArticleListPrams.prototype.category = null;

                    /**
                     * GetArticleListPrams pagination.
                     * @member {theplant.ec.service.base.IPagination|null|undefined} pagination
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @instance
                     */
                    GetArticleListPrams.prototype.pagination = null;

                    /**
                     * Creates a new GetArticleListPrams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {theplant.ec.api.blog.IGetArticleListPrams=} [properties] Properties to set
                     * @returns {theplant.ec.api.blog.GetArticleListPrams} GetArticleListPrams instance
                     */
                    GetArticleListPrams.create = function create(properties) {
                        return new GetArticleListPrams(properties);
                    };

                    /**
                     * Encodes the specified GetArticleListPrams message. Does not implicitly {@link theplant.ec.api.blog.GetArticleListPrams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {theplant.ec.api.blog.IGetArticleListPrams} message GetArticleListPrams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetArticleListPrams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.category != null && message.hasOwnProperty("category"))
                            $root.theplant.ec.service.blog.Category.encode(message.category, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pagination != null && message.hasOwnProperty("pagination"))
                            $root.theplant.ec.service.base.Pagination.encode(message.pagination, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetArticleListPrams message, length delimited. Does not implicitly {@link theplant.ec.api.blog.GetArticleListPrams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {theplant.ec.api.blog.IGetArticleListPrams} message GetArticleListPrams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetArticleListPrams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetArticleListPrams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.blog.GetArticleListPrams} GetArticleListPrams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetArticleListPrams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.blog.GetArticleListPrams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.category = $root.theplant.ec.service.blog.Category.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.pagination = $root.theplant.ec.service.base.Pagination.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetArticleListPrams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.blog.GetArticleListPrams} GetArticleListPrams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetArticleListPrams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetArticleListPrams message.
                     * @function verify
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetArticleListPrams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.category != null && message.hasOwnProperty("category")) {
                            var error = $root.theplant.ec.service.blog.Category.verify(message.category);
                            if (error)
                                return "category." + error;
                        }
                        if (message.pagination != null && message.hasOwnProperty("pagination")) {
                            var error = $root.theplant.ec.service.base.Pagination.verify(message.pagination);
                            if (error)
                                return "pagination." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a GetArticleListPrams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.blog.GetArticleListPrams} GetArticleListPrams
                     */
                    GetArticleListPrams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.blog.GetArticleListPrams)
                            return object;
                        var message = new $root.theplant.ec.api.blog.GetArticleListPrams();
                        if (object.category != null) {
                            if (typeof object.category !== "object")
                                throw TypeError(".theplant.ec.api.blog.GetArticleListPrams.category: object expected");
                            message.category = $root.theplant.ec.service.blog.Category.fromObject(object.category);
                        }
                        if (object.pagination != null) {
                            if (typeof object.pagination !== "object")
                                throw TypeError(".theplant.ec.api.blog.GetArticleListPrams.pagination: object expected");
                            message.pagination = $root.theplant.ec.service.base.Pagination.fromObject(object.pagination);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetArticleListPrams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @static
                     * @param {theplant.ec.api.blog.GetArticleListPrams} message GetArticleListPrams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetArticleListPrams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.category = null;
                            object.pagination = null;
                        }
                        if (message.category != null && message.hasOwnProperty("category"))
                            object.category = $root.theplant.ec.service.blog.Category.toObject(message.category, options);
                        if (message.pagination != null && message.hasOwnProperty("pagination"))
                            object.pagination = $root.theplant.ec.service.base.Pagination.toObject(message.pagination, options);
                        return object;
                    };

                    /**
                     * Converts this GetArticleListPrams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.blog.GetArticleListPrams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetArticleListPrams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetArticleListPrams;
                })();

                blog.ArticleList = (function() {

                    /**
                     * Properties of an ArticleList.
                     * @memberof theplant.ec.api.blog
                     * @interface IArticleList
                     * @property {Array.<theplant.ec.service.blog.IArticle>|null} [articles] ArticleList articles
                     * @property {boolean|null} [hasMore] ArticleList hasMore
                     */

                    /**
                     * Constructs a new ArticleList.
                     * @memberof theplant.ec.api.blog
                     * @classdesc Represents an ArticleList.
                     * @implements IArticleList
                     * @constructor
                     * @param {theplant.ec.api.blog.IArticleList=} [properties] Properties to set
                     */
                    function ArticleList(properties) {
                        this.articles = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ArticleList articles.
                     * @member {Array.<theplant.ec.service.blog.IArticle>} articles
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @instance
                     */
                    ArticleList.prototype.articles = $util.emptyArray;

                    /**
                     * ArticleList hasMore.
                     * @member {boolean} hasMore
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @instance
                     */
                    ArticleList.prototype.hasMore = false;

                    /**
                     * Creates a new ArticleList instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {theplant.ec.api.blog.IArticleList=} [properties] Properties to set
                     * @returns {theplant.ec.api.blog.ArticleList} ArticleList instance
                     */
                    ArticleList.create = function create(properties) {
                        return new ArticleList(properties);
                    };

                    /**
                     * Encodes the specified ArticleList message. Does not implicitly {@link theplant.ec.api.blog.ArticleList.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {theplant.ec.api.blog.IArticleList} message ArticleList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articles != null && message.articles.length)
                            for (var i = 0; i < message.articles.length; ++i)
                                $root.theplant.ec.service.blog.Article.encode(message.articles[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.hasMore != null && message.hasOwnProperty("hasMore"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.hasMore);
                        return writer;
                    };

                    /**
                     * Encodes the specified ArticleList message, length delimited. Does not implicitly {@link theplant.ec.api.blog.ArticleList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {theplant.ec.api.blog.IArticleList} message ArticleList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ArticleList message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.blog.ArticleList} ArticleList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.blog.ArticleList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.articles && message.articles.length))
                                    message.articles = [];
                                message.articles.push($root.theplant.ec.service.blog.Article.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.hasMore = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ArticleList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.blog.ArticleList} ArticleList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ArticleList message.
                     * @function verify
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ArticleList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articles != null && message.hasOwnProperty("articles")) {
                            if (!Array.isArray(message.articles))
                                return "articles: array expected";
                            for (var i = 0; i < message.articles.length; ++i) {
                                var error = $root.theplant.ec.service.blog.Article.verify(message.articles[i]);
                                if (error)
                                    return "articles." + error;
                            }
                        }
                        if (message.hasMore != null && message.hasOwnProperty("hasMore"))
                            if (typeof message.hasMore !== "boolean")
                                return "hasMore: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an ArticleList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.blog.ArticleList} ArticleList
                     */
                    ArticleList.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.blog.ArticleList)
                            return object;
                        var message = new $root.theplant.ec.api.blog.ArticleList();
                        if (object.articles) {
                            if (!Array.isArray(object.articles))
                                throw TypeError(".theplant.ec.api.blog.ArticleList.articles: array expected");
                            message.articles = [];
                            for (var i = 0; i < object.articles.length; ++i) {
                                if (typeof object.articles[i] !== "object")
                                    throw TypeError(".theplant.ec.api.blog.ArticleList.articles: object expected");
                                message.articles[i] = $root.theplant.ec.service.blog.Article.fromObject(object.articles[i]);
                            }
                        }
                        if (object.hasMore != null)
                            message.hasMore = Boolean(object.hasMore);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ArticleList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @static
                     * @param {theplant.ec.api.blog.ArticleList} message ArticleList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ArticleList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.articles = [];
                        if (options.defaults)
                            object.hasMore = false;
                        if (message.articles && message.articles.length) {
                            object.articles = [];
                            for (var j = 0; j < message.articles.length; ++j)
                                object.articles[j] = $root.theplant.ec.service.blog.Article.toObject(message.articles[j], options);
                        }
                        if (message.hasMore != null && message.hasOwnProperty("hasMore"))
                            object.hasMore = message.hasMore;
                        return object;
                    };

                    /**
                     * Converts this ArticleList to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.blog.ArticleList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ArticleList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ArticleList;
                })();

                blog.Categories = (function() {

                    /**
                     * Properties of a Categories.
                     * @memberof theplant.ec.api.blog
                     * @interface ICategories
                     * @property {Array.<theplant.ec.service.blog.ICategory>|null} [categories] Categories categories
                     */

                    /**
                     * Constructs a new Categories.
                     * @memberof theplant.ec.api.blog
                     * @classdesc Represents a Categories.
                     * @implements ICategories
                     * @constructor
                     * @param {theplant.ec.api.blog.ICategories=} [properties] Properties to set
                     */
                    function Categories(properties) {
                        this.categories = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Categories categories.
                     * @member {Array.<theplant.ec.service.blog.ICategory>} categories
                     * @memberof theplant.ec.api.blog.Categories
                     * @instance
                     */
                    Categories.prototype.categories = $util.emptyArray;

                    /**
                     * Creates a new Categories instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {theplant.ec.api.blog.ICategories=} [properties] Properties to set
                     * @returns {theplant.ec.api.blog.Categories} Categories instance
                     */
                    Categories.create = function create(properties) {
                        return new Categories(properties);
                    };

                    /**
                     * Encodes the specified Categories message. Does not implicitly {@link theplant.ec.api.blog.Categories.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {theplant.ec.api.blog.ICategories} message Categories message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Categories.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.categories != null && message.categories.length)
                            for (var i = 0; i < message.categories.length; ++i)
                                $root.theplant.ec.service.blog.Category.encode(message.categories[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Categories message, length delimited. Does not implicitly {@link theplant.ec.api.blog.Categories.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {theplant.ec.api.blog.ICategories} message Categories message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Categories.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Categories message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.blog.Categories} Categories
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Categories.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.blog.Categories();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.categories && message.categories.length))
                                    message.categories = [];
                                message.categories.push($root.theplant.ec.service.blog.Category.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Categories message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.blog.Categories} Categories
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Categories.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Categories message.
                     * @function verify
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Categories.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.categories != null && message.hasOwnProperty("categories")) {
                            if (!Array.isArray(message.categories))
                                return "categories: array expected";
                            for (var i = 0; i < message.categories.length; ++i) {
                                var error = $root.theplant.ec.service.blog.Category.verify(message.categories[i]);
                                if (error)
                                    return "categories." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Categories message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.blog.Categories} Categories
                     */
                    Categories.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.blog.Categories)
                            return object;
                        var message = new $root.theplant.ec.api.blog.Categories();
                        if (object.categories) {
                            if (!Array.isArray(object.categories))
                                throw TypeError(".theplant.ec.api.blog.Categories.categories: array expected");
                            message.categories = [];
                            for (var i = 0; i < object.categories.length; ++i) {
                                if (typeof object.categories[i] !== "object")
                                    throw TypeError(".theplant.ec.api.blog.Categories.categories: object expected");
                                message.categories[i] = $root.theplant.ec.service.blog.Category.fromObject(object.categories[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Categories message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.blog.Categories
                     * @static
                     * @param {theplant.ec.api.blog.Categories} message Categories
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Categories.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.categories = [];
                        if (message.categories && message.categories.length) {
                            object.categories = [];
                            for (var j = 0; j < message.categories.length; ++j)
                                object.categories[j] = $root.theplant.ec.service.blog.Category.toObject(message.categories[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Categories to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.blog.Categories
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Categories.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Categories;
                })();

                blog.ArticleId = (function() {

                    /**
                     * Properties of an ArticleId.
                     * @memberof theplant.ec.api.blog
                     * @interface IArticleId
                     * @property {number|Long|null} [id] ArticleId id
                     */

                    /**
                     * Constructs a new ArticleId.
                     * @memberof theplant.ec.api.blog
                     * @classdesc Represents an ArticleId.
                     * @implements IArticleId
                     * @constructor
                     * @param {theplant.ec.api.blog.IArticleId=} [properties] Properties to set
                     */
                    function ArticleId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ArticleId id.
                     * @member {number|Long} id
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @instance
                     */
                    ArticleId.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new ArticleId instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {theplant.ec.api.blog.IArticleId=} [properties] Properties to set
                     * @returns {theplant.ec.api.blog.ArticleId} ArticleId instance
                     */
                    ArticleId.create = function create(properties) {
                        return new ArticleId(properties);
                    };

                    /**
                     * Encodes the specified ArticleId message. Does not implicitly {@link theplant.ec.api.blog.ArticleId.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {theplant.ec.api.blog.IArticleId} message ArticleId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified ArticleId message, length delimited. Does not implicitly {@link theplant.ec.api.blog.ArticleId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {theplant.ec.api.blog.IArticleId} message ArticleId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ArticleId message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.blog.ArticleId} ArticleId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.blog.ArticleId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ArticleId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.blog.ArticleId} ArticleId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ArticleId message.
                     * @function verify
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ArticleId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an ArticleId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.blog.ArticleId} ArticleId
                     */
                    ArticleId.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.blog.ArticleId)
                            return object;
                        var message = new $root.theplant.ec.api.blog.ArticleId();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from an ArticleId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @static
                     * @param {theplant.ec.api.blog.ArticleId} message ArticleId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ArticleId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        return object;
                    };

                    /**
                     * Converts this ArticleId to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.blog.ArticleId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ArticleId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ArticleId;
                })();

                blog.BlogService = (function() {

                    /**
                     * Constructs a new BlogService service.
                     * @memberof theplant.ec.api.blog
                     * @classdesc Represents a BlogService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function BlogService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (BlogService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BlogService;

                    /**
                     * Creates new BlogService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.blog.BlogService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {BlogService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    BlogService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.blog.BlogService#getAllCategories}.
                     * @memberof theplant.ec.api.blog.BlogService
                     * @typedef GetAllCategoriesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.blog.Categories} [response] Categories
                     */

                    /**
                     * Calls GetAllCategories.
                     * @function getAllCategories
                     * @memberof theplant.ec.api.blog.BlogService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.blog.BlogService.GetAllCategoriesCallback} callback Node-style callback called with the error, if any, and Categories
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(BlogService.prototype.getAllCategories = function getAllCategories(request, callback) {
                        return this.rpcCall(getAllCategories, $root.theplant.ec.api.Empty, $root.theplant.ec.api.blog.Categories, request, callback);
                    }, "name", { value: "GetAllCategories" });

                    /**
                     * Calls GetAllCategories.
                     * @function getAllCategories
                     * @memberof theplant.ec.api.blog.BlogService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.blog.Categories>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.blog.BlogService#getArticleList}.
                     * @memberof theplant.ec.api.blog.BlogService
                     * @typedef GetArticleListCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.blog.ArticleList} [response] ArticleList
                     */

                    /**
                     * Calls GetArticleList.
                     * @function getArticleList
                     * @memberof theplant.ec.api.blog.BlogService
                     * @instance
                     * @param {theplant.ec.api.blog.IGetArticleListPrams} request GetArticleListPrams message or plain object
                     * @param {theplant.ec.api.blog.BlogService.GetArticleListCallback} callback Node-style callback called with the error, if any, and ArticleList
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(BlogService.prototype.getArticleList = function getArticleList(request, callback) {
                        return this.rpcCall(getArticleList, $root.theplant.ec.api.blog.GetArticleListPrams, $root.theplant.ec.api.blog.ArticleList, request, callback);
                    }, "name", { value: "GetArticleList" });

                    /**
                     * Calls GetArticleList.
                     * @function getArticleList
                     * @memberof theplant.ec.api.blog.BlogService
                     * @instance
                     * @param {theplant.ec.api.blog.IGetArticleListPrams} request GetArticleListPrams message or plain object
                     * @returns {Promise<theplant.ec.api.blog.ArticleList>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.blog.BlogService#getArticleById}.
                     * @memberof theplant.ec.api.blog.BlogService
                     * @typedef GetArticleByIdCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.blog.Article} [response] Article
                     */

                    /**
                     * Calls GetArticleById.
                     * @function getArticleById
                     * @memberof theplant.ec.api.blog.BlogService
                     * @instance
                     * @param {theplant.ec.api.blog.IArticleId} request ArticleId message or plain object
                     * @param {theplant.ec.api.blog.BlogService.GetArticleByIdCallback} callback Node-style callback called with the error, if any, and Article
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(BlogService.prototype.getArticleById = function getArticleById(request, callback) {
                        return this.rpcCall(getArticleById, $root.theplant.ec.api.blog.ArticleId, $root.theplant.ec.service.blog.Article, request, callback);
                    }, "name", { value: "GetArticleById" });

                    /**
                     * Calls GetArticleById.
                     * @function getArticleById
                     * @memberof theplant.ec.api.blog.BlogService
                     * @instance
                     * @param {theplant.ec.api.blog.IArticleId} request ArticleId message or plain object
                     * @returns {Promise<theplant.ec.service.blog.Article>} Promise
                     * @variation 2
                     */

                    return BlogService;
                })();

                return blog;
            })();

            api.contact_us = (function() {

                /**
                 * Namespace contact_us.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var contact_us = {};

                contact_us.Reason = (function() {

                    /**
                     * Properties of a Reason.
                     * @memberof theplant.ec.api.contact_us
                     * @interface IReason
                     * @property {theplant.ec.service.contact_us.ReasonType|null} [reasonType] Reason reasonType
                     * @property {boolean|null} [isOrderCodeRequired] Reason isOrderCodeRequired
                     */

                    /**
                     * Constructs a new Reason.
                     * @memberof theplant.ec.api.contact_us
                     * @classdesc Represents a Reason.
                     * @implements IReason
                     * @constructor
                     * @param {theplant.ec.api.contact_us.IReason=} [properties] Properties to set
                     */
                    function Reason(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Reason reasonType.
                     * @member {theplant.ec.service.contact_us.ReasonType} reasonType
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @instance
                     */
                    Reason.prototype.reasonType = 0;

                    /**
                     * Reason isOrderCodeRequired.
                     * @member {boolean} isOrderCodeRequired
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @instance
                     */
                    Reason.prototype.isOrderCodeRequired = false;

                    /**
                     * Creates a new Reason instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {theplant.ec.api.contact_us.IReason=} [properties] Properties to set
                     * @returns {theplant.ec.api.contact_us.Reason} Reason instance
                     */
                    Reason.create = function create(properties) {
                        return new Reason(properties);
                    };

                    /**
                     * Encodes the specified Reason message. Does not implicitly {@link theplant.ec.api.contact_us.Reason.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {theplant.ec.api.contact_us.IReason} message Reason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Reason.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reasonType != null && message.hasOwnProperty("reasonType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.reasonType);
                        if (message.isOrderCodeRequired != null && message.hasOwnProperty("isOrderCodeRequired"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isOrderCodeRequired);
                        return writer;
                    };

                    /**
                     * Encodes the specified Reason message, length delimited. Does not implicitly {@link theplant.ec.api.contact_us.Reason.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {theplant.ec.api.contact_us.IReason} message Reason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Reason.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Reason message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.contact_us.Reason} Reason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Reason.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.contact_us.Reason();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reasonType = reader.int32();
                                break;
                            case 2:
                                message.isOrderCodeRequired = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Reason message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.contact_us.Reason} Reason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Reason.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Reason message.
                     * @function verify
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Reason.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reasonType != null && message.hasOwnProperty("reasonType"))
                            switch (message.reasonType) {
                            default:
                                return "reasonType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                                break;
                            }
                        if (message.isOrderCodeRequired != null && message.hasOwnProperty("isOrderCodeRequired"))
                            if (typeof message.isOrderCodeRequired !== "boolean")
                                return "isOrderCodeRequired: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Reason message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.contact_us.Reason} Reason
                     */
                    Reason.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.contact_us.Reason)
                            return object;
                        var message = new $root.theplant.ec.api.contact_us.Reason();
                        switch (object.reasonType) {
                        case "UNKNOWN":
                        case 0:
                            message.reasonType = 0;
                            break;
                        case "ORDERS":
                        case 1:
                            message.reasonType = 1;
                            break;
                        case "SHIPMENTS":
                        case 2:
                            message.reasonType = 2;
                            break;
                        case "RETURNS_AND_REFUNDS":
                        case 3:
                            message.reasonType = 3;
                            break;
                        case "CUSTOMER_ACCOUNT":
                        case 4:
                            message.reasonType = 4;
                            break;
                        case "PRODUCT_AND_STOCK":
                        case 5:
                            message.reasonType = 5;
                            break;
                        case "STORES":
                        case 6:
                            message.reasonType = 6;
                            break;
                        case "CORPORATE_REQUESTS":
                        case 7:
                            message.reasonType = 7;
                            break;
                        case "SPONSORSHIP":
                        case 8:
                            message.reasonType = 8;
                            break;
                        case "RECRUITMENT":
                        case 9:
                            message.reasonType = 9;
                            break;
                        case "COMMERCIAL_REQUESTS":
                        case 10:
                            message.reasonType = 10;
                            break;
                        case "PERSONAL_DATA":
                        case 11:
                            message.reasonType = 11;
                            break;
                        }
                        if (object.isOrderCodeRequired != null)
                            message.isOrderCodeRequired = Boolean(object.isOrderCodeRequired);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Reason message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @static
                     * @param {theplant.ec.api.contact_us.Reason} message Reason
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Reason.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.reasonType = options.enums === String ? "UNKNOWN" : 0;
                            object.isOrderCodeRequired = false;
                        }
                        if (message.reasonType != null && message.hasOwnProperty("reasonType"))
                            object.reasonType = options.enums === String ? $root.theplant.ec.service.contact_us.ReasonType[message.reasonType] : message.reasonType;
                        if (message.isOrderCodeRequired != null && message.hasOwnProperty("isOrderCodeRequired"))
                            object.isOrderCodeRequired = message.isOrderCodeRequired;
                        return object;
                    };

                    /**
                     * Converts this Reason to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.contact_us.Reason
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Reason.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Reason;
                })();

                contact_us.ReasonList = (function() {

                    /**
                     * Properties of a ReasonList.
                     * @memberof theplant.ec.api.contact_us
                     * @interface IReasonList
                     * @property {Array.<theplant.ec.api.contact_us.IReason>|null} [reasons] ReasonList reasons
                     */

                    /**
                     * Constructs a new ReasonList.
                     * @memberof theplant.ec.api.contact_us
                     * @classdesc Represents a ReasonList.
                     * @implements IReasonList
                     * @constructor
                     * @param {theplant.ec.api.contact_us.IReasonList=} [properties] Properties to set
                     */
                    function ReasonList(properties) {
                        this.reasons = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ReasonList reasons.
                     * @member {Array.<theplant.ec.api.contact_us.IReason>} reasons
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @instance
                     */
                    ReasonList.prototype.reasons = $util.emptyArray;

                    /**
                     * Creates a new ReasonList instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {theplant.ec.api.contact_us.IReasonList=} [properties] Properties to set
                     * @returns {theplant.ec.api.contact_us.ReasonList} ReasonList instance
                     */
                    ReasonList.create = function create(properties) {
                        return new ReasonList(properties);
                    };

                    /**
                     * Encodes the specified ReasonList message. Does not implicitly {@link theplant.ec.api.contact_us.ReasonList.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {theplant.ec.api.contact_us.IReasonList} message ReasonList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReasonList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reasons != null && message.reasons.length)
                            for (var i = 0; i < message.reasons.length; ++i)
                                $root.theplant.ec.api.contact_us.Reason.encode(message.reasons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ReasonList message, length delimited. Does not implicitly {@link theplant.ec.api.contact_us.ReasonList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {theplant.ec.api.contact_us.IReasonList} message ReasonList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReasonList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ReasonList message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.contact_us.ReasonList} ReasonList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReasonList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.contact_us.ReasonList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.reasons && message.reasons.length))
                                    message.reasons = [];
                                message.reasons.push($root.theplant.ec.api.contact_us.Reason.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ReasonList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.contact_us.ReasonList} ReasonList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReasonList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ReasonList message.
                     * @function verify
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReasonList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reasons != null && message.hasOwnProperty("reasons")) {
                            if (!Array.isArray(message.reasons))
                                return "reasons: array expected";
                            for (var i = 0; i < message.reasons.length; ++i) {
                                var error = $root.theplant.ec.api.contact_us.Reason.verify(message.reasons[i]);
                                if (error)
                                    return "reasons." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a ReasonList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.contact_us.ReasonList} ReasonList
                     */
                    ReasonList.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.contact_us.ReasonList)
                            return object;
                        var message = new $root.theplant.ec.api.contact_us.ReasonList();
                        if (object.reasons) {
                            if (!Array.isArray(object.reasons))
                                throw TypeError(".theplant.ec.api.contact_us.ReasonList.reasons: array expected");
                            message.reasons = [];
                            for (var i = 0; i < object.reasons.length; ++i) {
                                if (typeof object.reasons[i] !== "object")
                                    throw TypeError(".theplant.ec.api.contact_us.ReasonList.reasons: object expected");
                                message.reasons[i] = $root.theplant.ec.api.contact_us.Reason.fromObject(object.reasons[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ReasonList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @static
                     * @param {theplant.ec.api.contact_us.ReasonList} message ReasonList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReasonList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.reasons = [];
                        if (message.reasons && message.reasons.length) {
                            object.reasons = [];
                            for (var j = 0; j < message.reasons.length; ++j)
                                object.reasons[j] = $root.theplant.ec.api.contact_us.Reason.toObject(message.reasons[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this ReasonList to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.contact_us.ReasonList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReasonList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ReasonList;
                })();

                contact_us.ContactUsService = (function() {

                    /**
                     * Constructs a new ContactUsService service.
                     * @memberof theplant.ec.api.contact_us
                     * @classdesc Represents a ContactUsService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ContactUsService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ContactUsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ContactUsService;

                    /**
                     * Creates new ContactUsService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ContactUsService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ContactUsService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.contact_us.ContactUsService#contactUs}.
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @typedef ContactUsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ContactUs.
                     * @function contactUs
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @instance
                     * @param {theplant.ec.service.contact_us.IContactUsContent} request ContactUsContent message or plain object
                     * @param {theplant.ec.api.contact_us.ContactUsService.ContactUsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ContactUsService.prototype.contactUs = function contactUs(request, callback) {
                        return this.rpcCall(contactUs, $root.theplant.ec.service.contact_us.ContactUsContent, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ContactUs" });

                    /**
                     * Calls ContactUs.
                     * @function contactUs
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @instance
                     * @param {theplant.ec.service.contact_us.IContactUsContent} request ContactUsContent message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.contact_us.ContactUsService#getReasonList}.
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @typedef GetReasonListCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.contact_us.ReasonList} [response] ReasonList
                     */

                    /**
                     * Calls GetReasonList.
                     * @function getReasonList
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.contact_us.ContactUsService.GetReasonListCallback} callback Node-style callback called with the error, if any, and ReasonList
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ContactUsService.prototype.getReasonList = function getReasonList(request, callback) {
                        return this.rpcCall(getReasonList, $root.theplant.ec.api.Empty, $root.theplant.ec.api.contact_us.ReasonList, request, callback);
                    }, "name", { value: "GetReasonList" });

                    /**
                     * Calls GetReasonList.
                     * @function getReasonList
                     * @memberof theplant.ec.api.contact_us.ContactUsService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.contact_us.ReasonList>} Promise
                     * @variation 2
                     */

                    return ContactUsService;
                })();

                return contact_us;
            })();

            api.customization = (function() {

                /**
                 * Namespace customization.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var customization = {};

                customization.Session = (function() {

                    /**
                     * Properties of a Session.
                     * @memberof theplant.ec.api.customization
                     * @interface ISession
                     * @property {string|null} [session] Session session
                     */

                    /**
                     * Constructs a new Session.
                     * @memberof theplant.ec.api.customization
                     * @classdesc Represents a Session.
                     * @implements ISession
                     * @constructor
                     * @param {theplant.ec.api.customization.ISession=} [properties] Properties to set
                     */
                    function Session(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Session session.
                     * @member {string} session
                     * @memberof theplant.ec.api.customization.Session
                     * @instance
                     */
                    Session.prototype.session = "";

                    /**
                     * Creates a new Session instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {theplant.ec.api.customization.ISession=} [properties] Properties to set
                     * @returns {theplant.ec.api.customization.Session} Session instance
                     */
                    Session.create = function create(properties) {
                        return new Session(properties);
                    };

                    /**
                     * Encodes the specified Session message. Does not implicitly {@link theplant.ec.api.customization.Session.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {theplant.ec.api.customization.ISession} message Session message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Session.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.session != null && message.hasOwnProperty("session"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.session);
                        return writer;
                    };

                    /**
                     * Encodes the specified Session message, length delimited. Does not implicitly {@link theplant.ec.api.customization.Session.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {theplant.ec.api.customization.ISession} message Session message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Session.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Session message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.customization.Session} Session
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Session.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.customization.Session();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.session = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Session message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.customization.Session} Session
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Session.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Session message.
                     * @function verify
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Session.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.session != null && message.hasOwnProperty("session"))
                            if (!$util.isString(message.session))
                                return "session: string expected";
                        return null;
                    };

                    /**
                     * Creates a Session message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.customization.Session} Session
                     */
                    Session.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.customization.Session)
                            return object;
                        var message = new $root.theplant.ec.api.customization.Session();
                        if (object.session != null)
                            message.session = String(object.session);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Session message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.customization.Session
                     * @static
                     * @param {theplant.ec.api.customization.Session} message Session
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Session.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.session = "";
                        if (message.session != null && message.hasOwnProperty("session"))
                            object.session = message.session;
                        return object;
                    };

                    /**
                     * Converts this Session to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.customization.Session
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Session.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Session;
                })();

                customization.CustomizationService = (function() {

                    /**
                     * Constructs a new CustomizationService service.
                     * @memberof theplant.ec.api.customization
                     * @classdesc Represents a CustomizationService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CustomizationService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (CustomizationService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CustomizationService;

                    /**
                     * Creates new CustomizationService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CustomizationService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CustomizationService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.customization.CustomizationService#getSession}.
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @typedef GetSessionCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.customization.Session} [response] Session
                     */

                    /**
                     * Calls GetSession.
                     * @function getSession
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.customization.CustomizationService.GetSessionCallback} callback Node-style callback called with the error, if any, and Session
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CustomizationService.prototype.getSession = function getSession(request, callback) {
                        return this.rpcCall(getSession, $root.theplant.ec.api.Empty, $root.theplant.ec.api.customization.Session, request, callback);
                    }, "name", { value: "GetSession" });

                    /**
                     * Calls GetSession.
                     * @function getSession
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.customization.Session>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.customization.CustomizationService#getCustomizationSetting}.
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @typedef GetCustomizationSettingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.customization.CustomizationSetting} [response] CustomizationSetting
                     */

                    /**
                     * Calls GetCustomizationSetting.
                     * @function getCustomizationSetting
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.customization.CustomizationService.GetCustomizationSettingCallback} callback Node-style callback called with the error, if any, and CustomizationSetting
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CustomizationService.prototype.getCustomizationSetting = function getCustomizationSetting(request, callback) {
                        return this.rpcCall(getCustomizationSetting, $root.theplant.ec.api.Empty, $root.theplant.ec.service.customization.CustomizationSetting, request, callback);
                    }, "name", { value: "GetCustomizationSetting" });

                    /**
                     * Calls GetCustomizationSetting.
                     * @function getCustomizationSetting
                     * @memberof theplant.ec.api.customization.CustomizationService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.customization.CustomizationSetting>} Promise
                     * @variation 2
                     */

                    return CustomizationService;
                })();

                return customization;
            })();

            api.instore = (function() {

                /**
                 * Namespace instore.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var instore = {};

                instore.Orders = (function() {

                    /**
                     * Properties of an Orders.
                     * @memberof theplant.ec.api.instore
                     * @interface IOrders
                     * @property {Array.<theplant.ec.service.orders.IOrder>|null} [orders] Orders orders
                     */

                    /**
                     * Constructs a new Orders.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an Orders.
                     * @implements IOrders
                     * @constructor
                     * @param {theplant.ec.api.instore.IOrders=} [properties] Properties to set
                     */
                    function Orders(properties) {
                        this.orders = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Orders orders.
                     * @member {Array.<theplant.ec.service.orders.IOrder>} orders
                     * @memberof theplant.ec.api.instore.Orders
                     * @instance
                     */
                    Orders.prototype.orders = $util.emptyArray;

                    /**
                     * Creates a new Orders instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {theplant.ec.api.instore.IOrders=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.Orders} Orders instance
                     */
                    Orders.create = function create(properties) {
                        return new Orders(properties);
                    };

                    /**
                     * Encodes the specified Orders message. Does not implicitly {@link theplant.ec.api.instore.Orders.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {theplant.ec.api.instore.IOrders} message Orders message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Orders.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orders != null && message.orders.length)
                            for (var i = 0; i < message.orders.length; ++i)
                                $root.theplant.ec.service.orders.Order.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Orders message, length delimited. Does not implicitly {@link theplant.ec.api.instore.Orders.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {theplant.ec.api.instore.IOrders} message Orders message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Orders.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Orders message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.Orders} Orders
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Orders.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.Orders();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.orders && message.orders.length))
                                    message.orders = [];
                                message.orders.push($root.theplant.ec.service.orders.Order.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Orders message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.Orders} Orders
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Orders.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Orders message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Orders.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orders != null && message.hasOwnProperty("orders")) {
                            if (!Array.isArray(message.orders))
                                return "orders: array expected";
                            for (var i = 0; i < message.orders.length; ++i) {
                                var error = $root.theplant.ec.service.orders.Order.verify(message.orders[i]);
                                if (error)
                                    return "orders." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an Orders message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.Orders} Orders
                     */
                    Orders.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.Orders)
                            return object;
                        var message = new $root.theplant.ec.api.instore.Orders();
                        if (object.orders) {
                            if (!Array.isArray(object.orders))
                                throw TypeError(".theplant.ec.api.instore.Orders.orders: array expected");
                            message.orders = [];
                            for (var i = 0; i < object.orders.length; ++i) {
                                if (typeof object.orders[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.Orders.orders: object expected");
                                message.orders[i] = $root.theplant.ec.service.orders.Order.fromObject(object.orders[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an Orders message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.Orders
                     * @static
                     * @param {theplant.ec.api.instore.Orders} message Orders
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Orders.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.orders = [];
                        if (message.orders && message.orders.length) {
                            object.orders = [];
                            for (var j = 0; j < message.orders.length; ++j)
                                object.orders[j] = $root.theplant.ec.service.orders.Order.toObject(message.orders[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Orders to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.Orders
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Orders.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Orders;
                })();

                instore.ShippingTypeInput = (function() {

                    /**
                     * Properties of a ShippingTypeInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IShippingTypeInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ShippingTypeInput userAuthnToken
                     * @property {string|null} [orderCode] ShippingTypeInput orderCode
                     * @property {theplant.ec.service.store_orders.ShippingType|null} [shippingType] ShippingTypeInput shippingType
                     */

                    /**
                     * Constructs a new ShippingTypeInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ShippingTypeInput.
                     * @implements IShippingTypeInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IShippingTypeInput=} [properties] Properties to set
                     */
                    function ShippingTypeInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShippingTypeInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @instance
                     */
                    ShippingTypeInput.prototype.userAuthnToken = null;

                    /**
                     * ShippingTypeInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @instance
                     */
                    ShippingTypeInput.prototype.orderCode = "";

                    /**
                     * ShippingTypeInput shippingType.
                     * @member {theplant.ec.service.store_orders.ShippingType} shippingType
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @instance
                     */
                    ShippingTypeInput.prototype.shippingType = 0;

                    /**
                     * Creates a new ShippingTypeInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.IShippingTypeInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ShippingTypeInput} ShippingTypeInput instance
                     */
                    ShippingTypeInput.create = function create(properties) {
                        return new ShippingTypeInput(properties);
                    };

                    /**
                     * Encodes the specified ShippingTypeInput message. Does not implicitly {@link theplant.ec.api.instore.ShippingTypeInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.IShippingTypeInput} message ShippingTypeInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingTypeInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.shippingType != null && message.hasOwnProperty("shippingType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.shippingType);
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShippingTypeInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ShippingTypeInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.IShippingTypeInput} message ShippingTypeInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingTypeInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShippingTypeInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ShippingTypeInput} ShippingTypeInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingTypeInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ShippingTypeInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.shippingType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShippingTypeInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ShippingTypeInput} ShippingTypeInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingTypeInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShippingTypeInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShippingTypeInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.shippingType != null && message.hasOwnProperty("shippingType"))
                            switch (message.shippingType) {
                            default:
                                return "shippingType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a ShippingTypeInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ShippingTypeInput} ShippingTypeInput
                     */
                    ShippingTypeInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ShippingTypeInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ShippingTypeInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ShippingTypeInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        switch (object.shippingType) {
                        case "NO_SHIPPING":
                        case 0:
                            message.shippingType = 0;
                            break;
                        case "TO_ADDRESS":
                        case 1:
                            message.shippingType = 1;
                            break;
                        case "TO_STORE":
                        case 2:
                            message.shippingType = 2;
                            break;
                        case "TO_OTHER_ADDRESS":
                        case 3:
                            message.shippingType = 3;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShippingTypeInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.ShippingTypeInput} message ShippingTypeInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShippingTypeInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.shippingType = options.enums === String ? "NO_SHIPPING" : 0;
                            object.userAuthnToken = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.shippingType != null && message.hasOwnProperty("shippingType"))
                            object.shippingType = options.enums === String ? $root.theplant.ec.service.store_orders.ShippingType[message.shippingType] : message.shippingType;
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this ShippingTypeInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ShippingTypeInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShippingTypeInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShippingTypeInput;
                })();

                instore.PaymentTypeInput = (function() {

                    /**
                     * Properties of a PaymentTypeInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IPaymentTypeInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] PaymentTypeInput userAuthnToken
                     * @property {string|null} [orderCode] PaymentTypeInput orderCode
                     * @property {theplant.ec.service.store_orders.StoreOrderPaymentType|null} [paymentType] PaymentTypeInput paymentType
                     */

                    /**
                     * Constructs a new PaymentTypeInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a PaymentTypeInput.
                     * @implements IPaymentTypeInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IPaymentTypeInput=} [properties] Properties to set
                     */
                    function PaymentTypeInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaymentTypeInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @instance
                     */
                    PaymentTypeInput.prototype.userAuthnToken = null;

                    /**
                     * PaymentTypeInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @instance
                     */
                    PaymentTypeInput.prototype.orderCode = "";

                    /**
                     * PaymentTypeInput paymentType.
                     * @member {theplant.ec.service.store_orders.StoreOrderPaymentType} paymentType
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @instance
                     */
                    PaymentTypeInput.prototype.paymentType = 0;

                    /**
                     * Creates a new PaymentTypeInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.IPaymentTypeInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.PaymentTypeInput} PaymentTypeInput instance
                     */
                    PaymentTypeInput.create = function create(properties) {
                        return new PaymentTypeInput(properties);
                    };

                    /**
                     * Encodes the specified PaymentTypeInput message. Does not implicitly {@link theplant.ec.api.instore.PaymentTypeInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.IPaymentTypeInput} message PaymentTypeInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentTypeInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.paymentType);
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PaymentTypeInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.PaymentTypeInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.IPaymentTypeInput} message PaymentTypeInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentTypeInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaymentTypeInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.PaymentTypeInput} PaymentTypeInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentTypeInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.PaymentTypeInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.paymentType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaymentTypeInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.PaymentTypeInput} PaymentTypeInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentTypeInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaymentTypeInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaymentTypeInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            switch (message.paymentType) {
                            default:
                                return "paymentType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a PaymentTypeInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.PaymentTypeInput} PaymentTypeInput
                     */
                    PaymentTypeInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.PaymentTypeInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.PaymentTypeInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.PaymentTypeInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        switch (object.paymentType) {
                        case "NO_STORE_ORDER_PAYMENT_TYPE":
                        case 0:
                            message.paymentType = 0;
                            break;
                        case "PAID_IN_STORE":
                        case 1:
                            message.paymentType = 1;
                            break;
                        case "WILL_PAY_IN_STORE":
                        case 2:
                            message.paymentType = 2;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaymentTypeInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @static
                     * @param {theplant.ec.api.instore.PaymentTypeInput} message PaymentTypeInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentTypeInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.paymentType = options.enums === String ? "NO_STORE_ORDER_PAYMENT_TYPE" : 0;
                            object.userAuthnToken = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            object.paymentType = options.enums === String ? $root.theplant.ec.service.store_orders.StoreOrderPaymentType[message.paymentType] : message.paymentType;
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this PaymentTypeInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.PaymentTypeInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentTypeInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaymentTypeInput;
                })();

                instore.ExternalPaymentIDInput = (function() {

                    /**
                     * Properties of an ExternalPaymentIDInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IExternalPaymentIDInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ExternalPaymentIDInput userAuthnToken
                     * @property {string|null} [orderCode] ExternalPaymentIDInput orderCode
                     * @property {string|null} [externalPaymentId] ExternalPaymentIDInput externalPaymentId
                     */

                    /**
                     * Constructs a new ExternalPaymentIDInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an ExternalPaymentIDInput.
                     * @implements IExternalPaymentIDInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IExternalPaymentIDInput=} [properties] Properties to set
                     */
                    function ExternalPaymentIDInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ExternalPaymentIDInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @instance
                     */
                    ExternalPaymentIDInput.prototype.userAuthnToken = null;

                    /**
                     * ExternalPaymentIDInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @instance
                     */
                    ExternalPaymentIDInput.prototype.orderCode = "";

                    /**
                     * ExternalPaymentIDInput externalPaymentId.
                     * @member {string} externalPaymentId
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @instance
                     */
                    ExternalPaymentIDInput.prototype.externalPaymentId = "";

                    /**
                     * Creates a new ExternalPaymentIDInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {theplant.ec.api.instore.IExternalPaymentIDInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ExternalPaymentIDInput} ExternalPaymentIDInput instance
                     */
                    ExternalPaymentIDInput.create = function create(properties) {
                        return new ExternalPaymentIDInput(properties);
                    };

                    /**
                     * Encodes the specified ExternalPaymentIDInput message. Does not implicitly {@link theplant.ec.api.instore.ExternalPaymentIDInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {theplant.ec.api.instore.IExternalPaymentIDInput} message ExternalPaymentIDInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExternalPaymentIDInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalPaymentId);
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ExternalPaymentIDInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ExternalPaymentIDInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {theplant.ec.api.instore.IExternalPaymentIDInput} message ExternalPaymentIDInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExternalPaymentIDInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ExternalPaymentIDInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ExternalPaymentIDInput} ExternalPaymentIDInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExternalPaymentIDInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ExternalPaymentIDInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.externalPaymentId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ExternalPaymentIDInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ExternalPaymentIDInput} ExternalPaymentIDInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExternalPaymentIDInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ExternalPaymentIDInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExternalPaymentIDInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            if (!$util.isString(message.externalPaymentId))
                                return "externalPaymentId: string expected";
                        return null;
                    };

                    /**
                     * Creates an ExternalPaymentIDInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ExternalPaymentIDInput} ExternalPaymentIDInput
                     */
                    ExternalPaymentIDInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ExternalPaymentIDInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ExternalPaymentIDInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ExternalPaymentIDInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.externalPaymentId != null)
                            message.externalPaymentId = String(object.externalPaymentId);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ExternalPaymentIDInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @static
                     * @param {theplant.ec.api.instore.ExternalPaymentIDInput} message ExternalPaymentIDInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExternalPaymentIDInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.externalPaymentId = "";
                            object.userAuthnToken = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            object.externalPaymentId = message.externalPaymentId;
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this ExternalPaymentIDInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ExternalPaymentIDInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExternalPaymentIDInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ExternalPaymentIDInput;
                })();

                instore.ApplyOrderShippingAddressInput = (function() {

                    /**
                     * Properties of an ApplyOrderShippingAddressInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IApplyOrderShippingAddressInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ApplyOrderShippingAddressInput userAuthnToken
                     * @property {string|null} [orderCode] ApplyOrderShippingAddressInput orderCode
                     * @property {theplant.ec.service.users.IAddress|null} [address] ApplyOrderShippingAddressInput address
                     */

                    /**
                     * Constructs a new ApplyOrderShippingAddressInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an ApplyOrderShippingAddressInput.
                     * @implements IApplyOrderShippingAddressInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IApplyOrderShippingAddressInput=} [properties] Properties to set
                     */
                    function ApplyOrderShippingAddressInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ApplyOrderShippingAddressInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @instance
                     */
                    ApplyOrderShippingAddressInput.prototype.userAuthnToken = null;

                    /**
                     * ApplyOrderShippingAddressInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @instance
                     */
                    ApplyOrderShippingAddressInput.prototype.orderCode = "";

                    /**
                     * ApplyOrderShippingAddressInput address.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} address
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @instance
                     */
                    ApplyOrderShippingAddressInput.prototype.address = null;

                    /**
                     * Creates a new ApplyOrderShippingAddressInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyOrderShippingAddressInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ApplyOrderShippingAddressInput} ApplyOrderShippingAddressInput instance
                     */
                    ApplyOrderShippingAddressInput.create = function create(properties) {
                        return new ApplyOrderShippingAddressInput(properties);
                    };

                    /**
                     * Encodes the specified ApplyOrderShippingAddressInput message. Does not implicitly {@link theplant.ec.api.instore.ApplyOrderShippingAddressInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyOrderShippingAddressInput} message ApplyOrderShippingAddressInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyOrderShippingAddressInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderCode);
                        if (message.address != null && message.hasOwnProperty("address"))
                            $root.theplant.ec.service.users.Address.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ApplyOrderShippingAddressInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ApplyOrderShippingAddressInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyOrderShippingAddressInput} message ApplyOrderShippingAddressInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyOrderShippingAddressInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ApplyOrderShippingAddressInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ApplyOrderShippingAddressInput} ApplyOrderShippingAddressInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyOrderShippingAddressInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ApplyOrderShippingAddressInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.orderCode = reader.string();
                                break;
                            case 3:
                                message.address = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ApplyOrderShippingAddressInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ApplyOrderShippingAddressInput} ApplyOrderShippingAddressInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyOrderShippingAddressInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ApplyOrderShippingAddressInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ApplyOrderShippingAddressInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.address != null && message.hasOwnProperty("address")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.address);
                            if (error)
                                return "address." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an ApplyOrderShippingAddressInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ApplyOrderShippingAddressInput} ApplyOrderShippingAddressInput
                     */
                    ApplyOrderShippingAddressInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ApplyOrderShippingAddressInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ApplyOrderShippingAddressInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyOrderShippingAddressInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.address != null) {
                            if (typeof object.address !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyOrderShippingAddressInput.address: object expected");
                            message.address = $root.theplant.ec.service.users.Address.fromObject(object.address);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ApplyOrderShippingAddressInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.ApplyOrderShippingAddressInput} message ApplyOrderShippingAddressInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ApplyOrderShippingAddressInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userAuthnToken = null;
                            object.orderCode = "";
                            object.address = null;
                        }
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.address != null && message.hasOwnProperty("address"))
                            object.address = $root.theplant.ec.service.users.Address.toObject(message.address, options);
                        return object;
                    };

                    /**
                     * Converts this ApplyOrderShippingAddressInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ApplyOrderShippingAddressInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ApplyOrderShippingAddressInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ApplyOrderShippingAddressInput;
                })();

                instore.OrderCode = (function() {

                    /**
                     * Properties of an OrderCode.
                     * @memberof theplant.ec.api.instore
                     * @interface IOrderCode
                     * @property {string|null} [orderCode] OrderCode orderCode
                     */

                    /**
                     * Constructs a new OrderCode.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an OrderCode.
                     * @implements IOrderCode
                     * @constructor
                     * @param {theplant.ec.api.instore.IOrderCode=} [properties] Properties to set
                     */
                    function OrderCode(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderCode orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @instance
                     */
                    OrderCode.prototype.orderCode = "";

                    /**
                     * Creates a new OrderCode instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {theplant.ec.api.instore.IOrderCode=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.OrderCode} OrderCode instance
                     */
                    OrderCode.create = function create(properties) {
                        return new OrderCode(properties);
                    };

                    /**
                     * Encodes the specified OrderCode message. Does not implicitly {@link theplant.ec.api.instore.OrderCode.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {theplant.ec.api.instore.IOrderCode} message OrderCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCode.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderCode message, length delimited. Does not implicitly {@link theplant.ec.api.instore.OrderCode.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {theplant.ec.api.instore.IOrderCode} message OrderCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCode.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderCode message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.OrderCode} OrderCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCode.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.OrderCode();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderCode message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.OrderCode} OrderCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCode.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderCode message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderCode.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        return null;
                    };

                    /**
                     * Creates an OrderCode message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.OrderCode} OrderCode
                     */
                    OrderCode.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.OrderCode)
                            return object;
                        var message = new $root.theplant.ec.api.instore.OrderCode();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderCode message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @static
                     * @param {theplant.ec.api.instore.OrderCode} message OrderCode
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderCode.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        return object;
                    };

                    /**
                     * Converts this OrderCode to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.OrderCode
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderCode.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderCode;
                })();

                instore.CloseOrderInput = (function() {

                    /**
                     * Properties of a CloseOrderInput.
                     * @memberof theplant.ec.api.instore
                     * @interface ICloseOrderInput
                     * @property {string|null} [orderCode] CloseOrderInput orderCode
                     * @property {theplant.ec.service.store_orders.IStoreOrderClosingInfo|null} [closingInfo] CloseOrderInput closingInfo
                     */

                    /**
                     * Constructs a new CloseOrderInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a CloseOrderInput.
                     * @implements ICloseOrderInput
                     * @constructor
                     * @param {theplant.ec.api.instore.ICloseOrderInput=} [properties] Properties to set
                     */
                    function CloseOrderInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CloseOrderInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @instance
                     */
                    CloseOrderInput.prototype.orderCode = "";

                    /**
                     * CloseOrderInput closingInfo.
                     * @member {theplant.ec.service.store_orders.IStoreOrderClosingInfo|null|undefined} closingInfo
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @instance
                     */
                    CloseOrderInput.prototype.closingInfo = null;

                    /**
                     * Creates a new CloseOrderInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.ICloseOrderInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.CloseOrderInput} CloseOrderInput instance
                     */
                    CloseOrderInput.create = function create(properties) {
                        return new CloseOrderInput(properties);
                    };

                    /**
                     * Encodes the specified CloseOrderInput message. Does not implicitly {@link theplant.ec.api.instore.CloseOrderInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.ICloseOrderInput} message CloseOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CloseOrderInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.closingInfo != null && message.hasOwnProperty("closingInfo"))
                            $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.encode(message.closingInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CloseOrderInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.CloseOrderInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.ICloseOrderInput} message CloseOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CloseOrderInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CloseOrderInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.CloseOrderInput} CloseOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CloseOrderInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.CloseOrderInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.closingInfo = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CloseOrderInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.CloseOrderInput} CloseOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CloseOrderInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CloseOrderInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CloseOrderInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.closingInfo != null && message.hasOwnProperty("closingInfo")) {
                            var error = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.verify(message.closingInfo);
                            if (error)
                                return "closingInfo." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CloseOrderInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.CloseOrderInput} CloseOrderInput
                     */
                    CloseOrderInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.CloseOrderInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.CloseOrderInput();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.closingInfo != null) {
                            if (typeof object.closingInfo !== "object")
                                throw TypeError(".theplant.ec.api.instore.CloseOrderInput.closingInfo: object expected");
                            message.closingInfo = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.fromObject(object.closingInfo);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CloseOrderInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.CloseOrderInput} message CloseOrderInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CloseOrderInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.closingInfo = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.closingInfo != null && message.hasOwnProperty("closingInfo"))
                            object.closingInfo = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.toObject(message.closingInfo, options);
                        return object;
                    };

                    /**
                     * Converts this CloseOrderInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.CloseOrderInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CloseOrderInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CloseOrderInput;
                })();

                instore.SearchInput = (function() {

                    /**
                     * Properties of a SearchInput.
                     * @memberof theplant.ec.api.instore
                     * @interface ISearchInput
                     * @property {string|null} [keyword] SearchInput keyword
                     * @property {number|Long|null} [page] SearchInput page
                     * @property {number|Long|null} [size] SearchInput size
                     */

                    /**
                     * Constructs a new SearchInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a SearchInput.
                     * @implements ISearchInput
                     * @constructor
                     * @param {theplant.ec.api.instore.ISearchInput=} [properties] Properties to set
                     */
                    function SearchInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchInput keyword.
                     * @member {string} keyword
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @instance
                     */
                    SearchInput.prototype.keyword = "";

                    /**
                     * SearchInput page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @instance
                     */
                    SearchInput.prototype.page = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SearchInput size.
                     * @member {number|Long} size
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @instance
                     */
                    SearchInput.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new SearchInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {theplant.ec.api.instore.ISearchInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.SearchInput} SearchInput instance
                     */
                    SearchInput.create = function create(properties) {
                        return new SearchInput(properties);
                    };

                    /**
                     * Encodes the specified SearchInput message. Does not implicitly {@link theplant.ec.api.instore.SearchInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {theplant.ec.api.instore.ISearchInput} message SearchInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.keyword);
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.page);
                        if (message.size != null && message.hasOwnProperty("size"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.size);
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.SearchInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {theplant.ec.api.instore.ISearchInput} message SearchInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.SearchInput} SearchInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.SearchInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.keyword = reader.string();
                                break;
                            case 2:
                                message.page = reader.uint64();
                                break;
                            case 3:
                                message.size = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.SearchInput} SearchInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            if (!$util.isString(message.keyword))
                                return "keyword: string expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                                return "size: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a SearchInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.SearchInput} SearchInput
                     */
                    SearchInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.SearchInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.SearchInput();
                        if (object.keyword != null)
                            message.keyword = String(object.keyword);
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = true;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber(true);
                        if (object.size != null)
                            if ($util.Long)
                                (message.size = $util.Long.fromValue(object.size)).unsigned = true;
                            else if (typeof object.size === "string")
                                message.size = parseInt(object.size, 10);
                            else if (typeof object.size === "number")
                                message.size = object.size;
                            else if (typeof object.size === "object")
                                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @static
                     * @param {theplant.ec.api.instore.SearchInput} message SearchInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.keyword = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.size = options.longs === String ? "0" : 0;
                        }
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            object.keyword = message.keyword;
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber(true) : message.page;
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (typeof message.size === "number")
                                object.size = options.longs === String ? String(message.size) : message.size;
                            else
                                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
                        return object;
                    };

                    /**
                     * Converts this SearchInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.SearchInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchInput;
                })();

                instore.DeliveryDateRange = (function() {

                    /**
                     * Properties of a DeliveryDateRange.
                     * @memberof theplant.ec.api.instore
                     * @interface IDeliveryDateRange
                     * @property {theplant.ec.service.orders.IDeliveryDateRange|null} [deliveryDateRange] DeliveryDateRange deliveryDateRange
                     */

                    /**
                     * Constructs a new DeliveryDateRange.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a DeliveryDateRange.
                     * @implements IDeliveryDateRange
                     * @constructor
                     * @param {theplant.ec.api.instore.IDeliveryDateRange=} [properties] Properties to set
                     */
                    function DeliveryDateRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeliveryDateRange deliveryDateRange.
                     * @member {theplant.ec.service.orders.IDeliveryDateRange|null|undefined} deliveryDateRange
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @instance
                     */
                    DeliveryDateRange.prototype.deliveryDateRange = null;

                    /**
                     * Creates a new DeliveryDateRange instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.api.instore.IDeliveryDateRange=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.DeliveryDateRange} DeliveryDateRange instance
                     */
                    DeliveryDateRange.create = function create(properties) {
                        return new DeliveryDateRange(properties);
                    };

                    /**
                     * Encodes the specified DeliveryDateRange message. Does not implicitly {@link theplant.ec.api.instore.DeliveryDateRange.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.api.instore.IDeliveryDateRange} message DeliveryDateRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryDateRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange"))
                            $root.theplant.ec.service.orders.DeliveryDateRange.encode(message.deliveryDateRange, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified DeliveryDateRange message, length delimited. Does not implicitly {@link theplant.ec.api.instore.DeliveryDateRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.api.instore.IDeliveryDateRange} message DeliveryDateRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryDateRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeliveryDateRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.DeliveryDateRange} DeliveryDateRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryDateRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.DeliveryDateRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeliveryDateRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.DeliveryDateRange} DeliveryDateRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryDateRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeliveryDateRange message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeliveryDateRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange")) {
                            var error = $root.theplant.ec.service.orders.DeliveryDateRange.verify(message.deliveryDateRange);
                            if (error)
                                return "deliveryDateRange." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a DeliveryDateRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.DeliveryDateRange} DeliveryDateRange
                     */
                    DeliveryDateRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.DeliveryDateRange)
                            return object;
                        var message = new $root.theplant.ec.api.instore.DeliveryDateRange();
                        if (object.deliveryDateRange != null) {
                            if (typeof object.deliveryDateRange !== "object")
                                throw TypeError(".theplant.ec.api.instore.DeliveryDateRange.deliveryDateRange: object expected");
                            message.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.fromObject(object.deliveryDateRange);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeliveryDateRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.api.instore.DeliveryDateRange} message DeliveryDateRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeliveryDateRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.deliveryDateRange = null;
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange"))
                            object.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.toObject(message.deliveryDateRange, options);
                        return object;
                    };

                    /**
                     * Converts this DeliveryDateRange to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.DeliveryDateRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeliveryDateRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeliveryDateRange;
                })();

                instore.ApplyDeliveryInfoInput = (function() {

                    /**
                     * Properties of an ApplyDeliveryInfoInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IApplyDeliveryInfoInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ApplyDeliveryInfoInput userAuthnToken
                     * @property {string|null} [orderCode] ApplyDeliveryInfoInput orderCode
                     * @property {theplant.ec.service.orders.IDeliveryInfo|null} [deliveryInfo] ApplyDeliveryInfoInput deliveryInfo
                     */

                    /**
                     * Constructs a new ApplyDeliveryInfoInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an ApplyDeliveryInfoInput.
                     * @implements IApplyDeliveryInfoInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IApplyDeliveryInfoInput=} [properties] Properties to set
                     */
                    function ApplyDeliveryInfoInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ApplyDeliveryInfoInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @instance
                     */
                    ApplyDeliveryInfoInput.prototype.userAuthnToken = null;

                    /**
                     * ApplyDeliveryInfoInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @instance
                     */
                    ApplyDeliveryInfoInput.prototype.orderCode = "";

                    /**
                     * ApplyDeliveryInfoInput deliveryInfo.
                     * @member {theplant.ec.service.orders.IDeliveryInfo|null|undefined} deliveryInfo
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @instance
                     */
                    ApplyDeliveryInfoInput.prototype.deliveryInfo = null;

                    /**
                     * Creates a new ApplyDeliveryInfoInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyDeliveryInfoInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ApplyDeliveryInfoInput} ApplyDeliveryInfoInput instance
                     */
                    ApplyDeliveryInfoInput.create = function create(properties) {
                        return new ApplyDeliveryInfoInput(properties);
                    };

                    /**
                     * Encodes the specified ApplyDeliveryInfoInput message. Does not implicitly {@link theplant.ec.api.instore.ApplyDeliveryInfoInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyDeliveryInfoInput} message ApplyDeliveryInfoInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyDeliveryInfoInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderCode);
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            $root.theplant.ec.service.orders.DeliveryInfo.encode(message.deliveryInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ApplyDeliveryInfoInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ApplyDeliveryInfoInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyDeliveryInfoInput} message ApplyDeliveryInfoInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyDeliveryInfoInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ApplyDeliveryInfoInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ApplyDeliveryInfoInput} ApplyDeliveryInfoInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyDeliveryInfoInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ApplyDeliveryInfoInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.orderCode = reader.string();
                                break;
                            case 3:
                                message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ApplyDeliveryInfoInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ApplyDeliveryInfoInput} ApplyDeliveryInfoInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyDeliveryInfoInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ApplyDeliveryInfoInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ApplyDeliveryInfoInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo")) {
                            var error = $root.theplant.ec.service.orders.DeliveryInfo.verify(message.deliveryInfo);
                            if (error)
                                return "deliveryInfo." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an ApplyDeliveryInfoInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ApplyDeliveryInfoInput} ApplyDeliveryInfoInput
                     */
                    ApplyDeliveryInfoInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ApplyDeliveryInfoInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ApplyDeliveryInfoInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyDeliveryInfoInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.deliveryInfo != null) {
                            if (typeof object.deliveryInfo !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyDeliveryInfoInput.deliveryInfo: object expected");
                            message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.fromObject(object.deliveryInfo);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ApplyDeliveryInfoInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @static
                     * @param {theplant.ec.api.instore.ApplyDeliveryInfoInput} message ApplyDeliveryInfoInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ApplyDeliveryInfoInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userAuthnToken = null;
                            object.orderCode = "";
                            object.deliveryInfo = null;
                        }
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            object.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.toObject(message.deliveryInfo, options);
                        return object;
                    };

                    /**
                     * Converts this ApplyDeliveryInfoInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ApplyDeliveryInfoInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ApplyDeliveryInfoInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ApplyDeliveryInfoInput;
                })();

                instore.PayWithoutItemsInput = (function() {

                    /**
                     * Properties of a PayWithoutItemsInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IPayWithoutItemsInput
                     * @property {string|null} [orderCode] PayWithoutItemsInput orderCode
                     * @property {string|null} [externalPaymentId] PayWithoutItemsInput externalPaymentId
                     * @property {Array.<theplant.ec.service.orders.IAppliedOrderItem>|null} [withoutItems] PayWithoutItemsInput withoutItems
                     */

                    /**
                     * Constructs a new PayWithoutItemsInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a PayWithoutItemsInput.
                     * @implements IPayWithoutItemsInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IPayWithoutItemsInput=} [properties] Properties to set
                     */
                    function PayWithoutItemsInput(properties) {
                        this.withoutItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PayWithoutItemsInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @instance
                     */
                    PayWithoutItemsInput.prototype.orderCode = "";

                    /**
                     * PayWithoutItemsInput externalPaymentId.
                     * @member {string} externalPaymentId
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @instance
                     */
                    PayWithoutItemsInput.prototype.externalPaymentId = "";

                    /**
                     * PayWithoutItemsInput withoutItems.
                     * @member {Array.<theplant.ec.service.orders.IAppliedOrderItem>} withoutItems
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @instance
                     */
                    PayWithoutItemsInput.prototype.withoutItems = $util.emptyArray;

                    /**
                     * Creates a new PayWithoutItemsInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {theplant.ec.api.instore.IPayWithoutItemsInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.PayWithoutItemsInput} PayWithoutItemsInput instance
                     */
                    PayWithoutItemsInput.create = function create(properties) {
                        return new PayWithoutItemsInput(properties);
                    };

                    /**
                     * Encodes the specified PayWithoutItemsInput message. Does not implicitly {@link theplant.ec.api.instore.PayWithoutItemsInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {theplant.ec.api.instore.IPayWithoutItemsInput} message PayWithoutItemsInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PayWithoutItemsInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalPaymentId);
                        if (message.withoutItems != null && message.withoutItems.length)
                            for (var i = 0; i < message.withoutItems.length; ++i)
                                $root.theplant.ec.service.orders.AppliedOrderItem.encode(message.withoutItems[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PayWithoutItemsInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.PayWithoutItemsInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {theplant.ec.api.instore.IPayWithoutItemsInput} message PayWithoutItemsInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PayWithoutItemsInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PayWithoutItemsInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.PayWithoutItemsInput} PayWithoutItemsInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PayWithoutItemsInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.PayWithoutItemsInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.externalPaymentId = reader.string();
                                break;
                            case 3:
                                if (!(message.withoutItems && message.withoutItems.length))
                                    message.withoutItems = [];
                                message.withoutItems.push($root.theplant.ec.service.orders.AppliedOrderItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PayWithoutItemsInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.PayWithoutItemsInput} PayWithoutItemsInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PayWithoutItemsInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PayWithoutItemsInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PayWithoutItemsInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            if (!$util.isString(message.externalPaymentId))
                                return "externalPaymentId: string expected";
                        if (message.withoutItems != null && message.hasOwnProperty("withoutItems")) {
                            if (!Array.isArray(message.withoutItems))
                                return "withoutItems: array expected";
                            for (var i = 0; i < message.withoutItems.length; ++i) {
                                var error = $root.theplant.ec.service.orders.AppliedOrderItem.verify(message.withoutItems[i]);
                                if (error)
                                    return "withoutItems." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a PayWithoutItemsInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.PayWithoutItemsInput} PayWithoutItemsInput
                     */
                    PayWithoutItemsInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.PayWithoutItemsInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.PayWithoutItemsInput();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.externalPaymentId != null)
                            message.externalPaymentId = String(object.externalPaymentId);
                        if (object.withoutItems) {
                            if (!Array.isArray(object.withoutItems))
                                throw TypeError(".theplant.ec.api.instore.PayWithoutItemsInput.withoutItems: array expected");
                            message.withoutItems = [];
                            for (var i = 0; i < object.withoutItems.length; ++i) {
                                if (typeof object.withoutItems[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.PayWithoutItemsInput.withoutItems: object expected");
                                message.withoutItems[i] = $root.theplant.ec.service.orders.AppliedOrderItem.fromObject(object.withoutItems[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PayWithoutItemsInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @static
                     * @param {theplant.ec.api.instore.PayWithoutItemsInput} message PayWithoutItemsInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PayWithoutItemsInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.withoutItems = [];
                        if (options.defaults) {
                            object.orderCode = "";
                            object.externalPaymentId = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            object.externalPaymentId = message.externalPaymentId;
                        if (message.withoutItems && message.withoutItems.length) {
                            object.withoutItems = [];
                            for (var j = 0; j < message.withoutItems.length; ++j)
                                object.withoutItems[j] = $root.theplant.ec.service.orders.AppliedOrderItem.toObject(message.withoutItems[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this PayWithoutItemsInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.PayWithoutItemsInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PayWithoutItemsInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PayWithoutItemsInput;
                })();

                instore.GiftWrappingInput = (function() {

                    /**
                     * Properties of a GiftWrappingInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IGiftWrappingInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] GiftWrappingInput userAuthnToken
                     * @property {string|null} [orderCode] GiftWrappingInput orderCode
                     * @property {theplant.ec.service.orders.IGiftWrapping|null} [giftWrapping] GiftWrappingInput giftWrapping
                     */

                    /**
                     * Constructs a new GiftWrappingInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GiftWrappingInput.
                     * @implements IGiftWrappingInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IGiftWrappingInput=} [properties] Properties to set
                     */
                    function GiftWrappingInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GiftWrappingInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @instance
                     */
                    GiftWrappingInput.prototype.userAuthnToken = null;

                    /**
                     * GiftWrappingInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @instance
                     */
                    GiftWrappingInput.prototype.orderCode = "";

                    /**
                     * GiftWrappingInput giftWrapping.
                     * @member {theplant.ec.service.orders.IGiftWrapping|null|undefined} giftWrapping
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @instance
                     */
                    GiftWrappingInput.prototype.giftWrapping = null;

                    /**
                     * Creates a new GiftWrappingInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {theplant.ec.api.instore.IGiftWrappingInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GiftWrappingInput} GiftWrappingInput instance
                     */
                    GiftWrappingInput.create = function create(properties) {
                        return new GiftWrappingInput(properties);
                    };

                    /**
                     * Encodes the specified GiftWrappingInput message. Does not implicitly {@link theplant.ec.api.instore.GiftWrappingInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {theplant.ec.api.instore.IGiftWrappingInput} message GiftWrappingInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GiftWrappingInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            $root.theplant.ec.service.orders.GiftWrapping.encode(message.giftWrapping, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GiftWrappingInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GiftWrappingInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {theplant.ec.api.instore.IGiftWrappingInput} message GiftWrappingInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GiftWrappingInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GiftWrappingInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GiftWrappingInput} GiftWrappingInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GiftWrappingInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GiftWrappingInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GiftWrappingInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GiftWrappingInput} GiftWrappingInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GiftWrappingInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GiftWrappingInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GiftWrappingInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping")) {
                            var error = $root.theplant.ec.service.orders.GiftWrapping.verify(message.giftWrapping);
                            if (error)
                                return "giftWrapping." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a GiftWrappingInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GiftWrappingInput} GiftWrappingInput
                     */
                    GiftWrappingInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GiftWrappingInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GiftWrappingInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.GiftWrappingInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.giftWrapping != null) {
                            if (typeof object.giftWrapping !== "object")
                                throw TypeError(".theplant.ec.api.instore.GiftWrappingInput.giftWrapping: object expected");
                            message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.fromObject(object.giftWrapping);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GiftWrappingInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @static
                     * @param {theplant.ec.api.instore.GiftWrappingInput} message GiftWrappingInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GiftWrappingInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.giftWrapping = null;
                            object.userAuthnToken = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            object.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.toObject(message.giftWrapping, options);
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this GiftWrappingInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GiftWrappingInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GiftWrappingInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GiftWrappingInput;
                })();

                instore.MarkAsPickedUpOrReturnInput = (function() {

                    /**
                     * Properties of a MarkAsPickedUpOrReturnInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IMarkAsPickedUpOrReturnInput
                     * @property {string|null} [orderCode] MarkAsPickedUpOrReturnInput orderCode
                     * @property {Array.<theplant.ec.service.orders.IAppliedOrderItem>|null} [returnItems] MarkAsPickedUpOrReturnInput returnItems
                     */

                    /**
                     * Constructs a new MarkAsPickedUpOrReturnInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a MarkAsPickedUpOrReturnInput.
                     * @implements IMarkAsPickedUpOrReturnInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IMarkAsPickedUpOrReturnInput=} [properties] Properties to set
                     */
                    function MarkAsPickedUpOrReturnInput(properties) {
                        this.returnItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MarkAsPickedUpOrReturnInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @instance
                     */
                    MarkAsPickedUpOrReturnInput.prototype.orderCode = "";

                    /**
                     * MarkAsPickedUpOrReturnInput returnItems.
                     * @member {Array.<theplant.ec.service.orders.IAppliedOrderItem>} returnItems
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @instance
                     */
                    MarkAsPickedUpOrReturnInput.prototype.returnItems = $util.emptyArray;

                    /**
                     * Creates a new MarkAsPickedUpOrReturnInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {theplant.ec.api.instore.IMarkAsPickedUpOrReturnInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.MarkAsPickedUpOrReturnInput} MarkAsPickedUpOrReturnInput instance
                     */
                    MarkAsPickedUpOrReturnInput.create = function create(properties) {
                        return new MarkAsPickedUpOrReturnInput(properties);
                    };

                    /**
                     * Encodes the specified MarkAsPickedUpOrReturnInput message. Does not implicitly {@link theplant.ec.api.instore.MarkAsPickedUpOrReturnInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {theplant.ec.api.instore.IMarkAsPickedUpOrReturnInput} message MarkAsPickedUpOrReturnInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MarkAsPickedUpOrReturnInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.returnItems != null && message.returnItems.length)
                            for (var i = 0; i < message.returnItems.length; ++i)
                                $root.theplant.ec.service.orders.AppliedOrderItem.encode(message.returnItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified MarkAsPickedUpOrReturnInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.MarkAsPickedUpOrReturnInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {theplant.ec.api.instore.IMarkAsPickedUpOrReturnInput} message MarkAsPickedUpOrReturnInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MarkAsPickedUpOrReturnInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MarkAsPickedUpOrReturnInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.MarkAsPickedUpOrReturnInput} MarkAsPickedUpOrReturnInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MarkAsPickedUpOrReturnInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.MarkAsPickedUpOrReturnInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                if (!(message.returnItems && message.returnItems.length))
                                    message.returnItems = [];
                                message.returnItems.push($root.theplant.ec.service.orders.AppliedOrderItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MarkAsPickedUpOrReturnInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.MarkAsPickedUpOrReturnInput} MarkAsPickedUpOrReturnInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MarkAsPickedUpOrReturnInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MarkAsPickedUpOrReturnInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MarkAsPickedUpOrReturnInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.returnItems != null && message.hasOwnProperty("returnItems")) {
                            if (!Array.isArray(message.returnItems))
                                return "returnItems: array expected";
                            for (var i = 0; i < message.returnItems.length; ++i) {
                                var error = $root.theplant.ec.service.orders.AppliedOrderItem.verify(message.returnItems[i]);
                                if (error)
                                    return "returnItems." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a MarkAsPickedUpOrReturnInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.MarkAsPickedUpOrReturnInput} MarkAsPickedUpOrReturnInput
                     */
                    MarkAsPickedUpOrReturnInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.MarkAsPickedUpOrReturnInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.MarkAsPickedUpOrReturnInput();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.returnItems) {
                            if (!Array.isArray(object.returnItems))
                                throw TypeError(".theplant.ec.api.instore.MarkAsPickedUpOrReturnInput.returnItems: array expected");
                            message.returnItems = [];
                            for (var i = 0; i < object.returnItems.length; ++i) {
                                if (typeof object.returnItems[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.MarkAsPickedUpOrReturnInput.returnItems: object expected");
                                message.returnItems[i] = $root.theplant.ec.service.orders.AppliedOrderItem.fromObject(object.returnItems[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a MarkAsPickedUpOrReturnInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @static
                     * @param {theplant.ec.api.instore.MarkAsPickedUpOrReturnInput} message MarkAsPickedUpOrReturnInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MarkAsPickedUpOrReturnInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.returnItems = [];
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.returnItems && message.returnItems.length) {
                            object.returnItems = [];
                            for (var j = 0; j < message.returnItems.length; ++j)
                                object.returnItems[j] = $root.theplant.ec.service.orders.AppliedOrderItem.toObject(message.returnItems[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this MarkAsPickedUpOrReturnInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.MarkAsPickedUpOrReturnInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MarkAsPickedUpOrReturnInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MarkAsPickedUpOrReturnInput;
                })();

                instore.RegisterInput = (function() {

                    /**
                     * Properties of a RegisterInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IRegisterInput
                     * @property {boolean|null} [isTermsAndPrivacyAgreed] RegisterInput isTermsAndPrivacyAgreed
                     * @property {string|null} [email] RegisterInput email
                     * @property {string|null} [password] RegisterInput password
                     * @property {string|null} [passwordConfirmation] RegisterInput passwordConfirmation
                     * @property {theplant.ec.service.users.IProfile|null} [profile] RegisterInput profile
                     */

                    /**
                     * Constructs a new RegisterInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a RegisterInput.
                     * @implements IRegisterInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IRegisterInput=} [properties] Properties to set
                     */
                    function RegisterInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RegisterInput isTermsAndPrivacyAgreed.
                     * @member {boolean} isTermsAndPrivacyAgreed
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @instance
                     */
                    RegisterInput.prototype.isTermsAndPrivacyAgreed = false;

                    /**
                     * RegisterInput email.
                     * @member {string} email
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @instance
                     */
                    RegisterInput.prototype.email = "";

                    /**
                     * RegisterInput password.
                     * @member {string} password
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @instance
                     */
                    RegisterInput.prototype.password = "";

                    /**
                     * RegisterInput passwordConfirmation.
                     * @member {string} passwordConfirmation
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @instance
                     */
                    RegisterInput.prototype.passwordConfirmation = "";

                    /**
                     * RegisterInput profile.
                     * @member {theplant.ec.service.users.IProfile|null|undefined} profile
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @instance
                     */
                    RegisterInput.prototype.profile = null;

                    /**
                     * Creates a new RegisterInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {theplant.ec.api.instore.IRegisterInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.RegisterInput} RegisterInput instance
                     */
                    RegisterInput.create = function create(properties) {
                        return new RegisterInput(properties);
                    };

                    /**
                     * Encodes the specified RegisterInput message. Does not implicitly {@link theplant.ec.api.instore.RegisterInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {theplant.ec.api.instore.IRegisterInput} message RegisterInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.passwordConfirmation);
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            $root.theplant.ec.service.users.Profile.encode(message.profile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isTermsAndPrivacyAgreed);
                        return writer;
                    };

                    /**
                     * Encodes the specified RegisterInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.RegisterInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {theplant.ec.api.instore.IRegisterInput} message RegisterInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RegisterInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.RegisterInput} RegisterInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.RegisterInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 6:
                                message.isTermsAndPrivacyAgreed = reader.bool();
                                break;
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.password = reader.string();
                                break;
                            case 3:
                                message.passwordConfirmation = reader.string();
                                break;
                            case 4:
                                message.profile = $root.theplant.ec.service.users.Profile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RegisterInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.RegisterInput} RegisterInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RegisterInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RegisterInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            if (typeof message.isTermsAndPrivacyAgreed !== "boolean")
                                return "isTermsAndPrivacyAgreed: boolean expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            if (!$util.isString(message.passwordConfirmation))
                                return "passwordConfirmation: string expected";
                        if (message.profile != null && message.hasOwnProperty("profile")) {
                            var error = $root.theplant.ec.service.users.Profile.verify(message.profile);
                            if (error)
                                return "profile." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a RegisterInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.RegisterInput} RegisterInput
                     */
                    RegisterInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.RegisterInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.RegisterInput();
                        if (object.isTermsAndPrivacyAgreed != null)
                            message.isTermsAndPrivacyAgreed = Boolean(object.isTermsAndPrivacyAgreed);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.passwordConfirmation != null)
                            message.passwordConfirmation = String(object.passwordConfirmation);
                        if (object.profile != null) {
                            if (typeof object.profile !== "object")
                                throw TypeError(".theplant.ec.api.instore.RegisterInput.profile: object expected");
                            message.profile = $root.theplant.ec.service.users.Profile.fromObject(object.profile);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RegisterInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @static
                     * @param {theplant.ec.api.instore.RegisterInput} message RegisterInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RegisterInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.password = "";
                            object.passwordConfirmation = "";
                            object.profile = null;
                            object.isTermsAndPrivacyAgreed = false;
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            object.passwordConfirmation = message.passwordConfirmation;
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            object.profile = $root.theplant.ec.service.users.Profile.toObject(message.profile, options);
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            object.isTermsAndPrivacyAgreed = message.isTermsAndPrivacyAgreed;
                        return object;
                    };

                    /**
                     * Converts this RegisterInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.RegisterInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RegisterInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RegisterInput;
                })();

                instore.UserAuthnToken = (function() {

                    /**
                     * Properties of a UserAuthnToken.
                     * @memberof theplant.ec.api.instore
                     * @interface IUserAuthnToken
                     * @property {string|null} [userAuthnToken] UserAuthnToken userAuthnToken
                     */

                    /**
                     * Constructs a new UserAuthnToken.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a UserAuthnToken.
                     * @implements IUserAuthnToken
                     * @constructor
                     * @param {theplant.ec.api.instore.IUserAuthnToken=} [properties] Properties to set
                     */
                    function UserAuthnToken(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserAuthnToken userAuthnToken.
                     * @member {string} userAuthnToken
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @instance
                     */
                    UserAuthnToken.prototype.userAuthnToken = "";

                    /**
                     * Creates a new UserAuthnToken instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.IUserAuthnToken=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.UserAuthnToken} UserAuthnToken instance
                     */
                    UserAuthnToken.create = function create(properties) {
                        return new UserAuthnToken(properties);
                    };

                    /**
                     * Encodes the specified UserAuthnToken message. Does not implicitly {@link theplant.ec.api.instore.UserAuthnToken.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.IUserAuthnToken} message UserAuthnToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserAuthnToken.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userAuthnToken);
                        return writer;
                    };

                    /**
                     * Encodes the specified UserAuthnToken message, length delimited. Does not implicitly {@link theplant.ec.api.instore.UserAuthnToken.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.IUserAuthnToken} message UserAuthnToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserAuthnToken.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a UserAuthnToken message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.UserAuthnToken} UserAuthnToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserAuthnToken.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.UserAuthnToken();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a UserAuthnToken message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.UserAuthnToken} UserAuthnToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserAuthnToken.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a UserAuthnToken message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserAuthnToken.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            if (!$util.isString(message.userAuthnToken))
                                return "userAuthnToken: string expected";
                        return null;
                    };

                    /**
                     * Creates a UserAuthnToken message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.UserAuthnToken} UserAuthnToken
                     */
                    UserAuthnToken.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.UserAuthnToken)
                            return object;
                        var message = new $root.theplant.ec.api.instore.UserAuthnToken();
                        if (object.userAuthnToken != null)
                            message.userAuthnToken = String(object.userAuthnToken);
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserAuthnToken message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.UserAuthnToken} message UserAuthnToken
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserAuthnToken.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.userAuthnToken = "";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = message.userAuthnToken;
                        return object;
                    };

                    /**
                     * Converts this UserAuthnToken to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.UserAuthnToken
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserAuthnToken.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UserAuthnToken;
                })();

                instore.LoginKey = (function() {

                    /**
                     * Properties of a LoginKey.
                     * @memberof theplant.ec.api.instore
                     * @interface ILoginKey
                     * @property {string|null} [loginKey] LoginKey loginKey
                     */

                    /**
                     * Constructs a new LoginKey.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a LoginKey.
                     * @implements ILoginKey
                     * @constructor
                     * @param {theplant.ec.api.instore.ILoginKey=} [properties] Properties to set
                     */
                    function LoginKey(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LoginKey loginKey.
                     * @member {string} loginKey
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @instance
                     */
                    LoginKey.prototype.loginKey = "";

                    /**
                     * Creates a new LoginKey instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {theplant.ec.api.instore.ILoginKey=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.LoginKey} LoginKey instance
                     */
                    LoginKey.create = function create(properties) {
                        return new LoginKey(properties);
                    };

                    /**
                     * Encodes the specified LoginKey message. Does not implicitly {@link theplant.ec.api.instore.LoginKey.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {theplant.ec.api.instore.ILoginKey} message LoginKey message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LoginKey.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.loginKey != null && message.hasOwnProperty("loginKey"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.loginKey);
                        return writer;
                    };

                    /**
                     * Encodes the specified LoginKey message, length delimited. Does not implicitly {@link theplant.ec.api.instore.LoginKey.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {theplant.ec.api.instore.ILoginKey} message LoginKey message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LoginKey.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a LoginKey message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.LoginKey} LoginKey
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LoginKey.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.LoginKey();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.loginKey = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a LoginKey message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.LoginKey} LoginKey
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LoginKey.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a LoginKey message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LoginKey.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.loginKey != null && message.hasOwnProperty("loginKey"))
                            if (!$util.isString(message.loginKey))
                                return "loginKey: string expected";
                        return null;
                    };

                    /**
                     * Creates a LoginKey message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.LoginKey} LoginKey
                     */
                    LoginKey.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.LoginKey)
                            return object;
                        var message = new $root.theplant.ec.api.instore.LoginKey();
                        if (object.loginKey != null)
                            message.loginKey = String(object.loginKey);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LoginKey message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @static
                     * @param {theplant.ec.api.instore.LoginKey} message LoginKey
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LoginKey.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.loginKey = "";
                        if (message.loginKey != null && message.hasOwnProperty("loginKey"))
                            object.loginKey = message.loginKey;
                        return object;
                    };

                    /**
                     * Converts this LoginKey to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.LoginKey
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LoginKey.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LoginKey;
                })();

                instore.ShippingAddresses = (function() {

                    /**
                     * Properties of a ShippingAddresses.
                     * @memberof theplant.ec.api.instore
                     * @interface IShippingAddresses
                     * @property {Array.<theplant.ec.service.users.IShippingAddress>|null} [shippingAddresses] ShippingAddresses shippingAddresses
                     */

                    /**
                     * Constructs a new ShippingAddresses.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ShippingAddresses.
                     * @implements IShippingAddresses
                     * @constructor
                     * @param {theplant.ec.api.instore.IShippingAddresses=} [properties] Properties to set
                     */
                    function ShippingAddresses(properties) {
                        this.shippingAddresses = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShippingAddresses shippingAddresses.
                     * @member {Array.<theplant.ec.service.users.IShippingAddress>} shippingAddresses
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @instance
                     */
                    ShippingAddresses.prototype.shippingAddresses = $util.emptyArray;

                    /**
                     * Creates a new ShippingAddresses instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.instore.IShippingAddresses=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ShippingAddresses} ShippingAddresses instance
                     */
                    ShippingAddresses.create = function create(properties) {
                        return new ShippingAddresses(properties);
                    };

                    /**
                     * Encodes the specified ShippingAddresses message. Does not implicitly {@link theplant.ec.api.instore.ShippingAddresses.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.instore.IShippingAddresses} message ShippingAddresses message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddresses.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shippingAddresses != null && message.shippingAddresses.length)
                            for (var i = 0; i < message.shippingAddresses.length; ++i)
                                $root.theplant.ec.service.users.ShippingAddress.encode(message.shippingAddresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShippingAddresses message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ShippingAddresses.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.instore.IShippingAddresses} message ShippingAddresses message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddresses.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShippingAddresses message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ShippingAddresses} ShippingAddresses
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddresses.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ShippingAddresses();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.shippingAddresses && message.shippingAddresses.length))
                                    message.shippingAddresses = [];
                                message.shippingAddresses.push($root.theplant.ec.service.users.ShippingAddress.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShippingAddresses message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ShippingAddresses} ShippingAddresses
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddresses.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShippingAddresses message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShippingAddresses.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shippingAddresses != null && message.hasOwnProperty("shippingAddresses")) {
                            if (!Array.isArray(message.shippingAddresses))
                                return "shippingAddresses: array expected";
                            for (var i = 0; i < message.shippingAddresses.length; ++i) {
                                var error = $root.theplant.ec.service.users.ShippingAddress.verify(message.shippingAddresses[i]);
                                if (error)
                                    return "shippingAddresses." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a ShippingAddresses message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ShippingAddresses} ShippingAddresses
                     */
                    ShippingAddresses.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ShippingAddresses)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ShippingAddresses();
                        if (object.shippingAddresses) {
                            if (!Array.isArray(object.shippingAddresses))
                                throw TypeError(".theplant.ec.api.instore.ShippingAddresses.shippingAddresses: array expected");
                            message.shippingAddresses = [];
                            for (var i = 0; i < object.shippingAddresses.length; ++i) {
                                if (typeof object.shippingAddresses[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.ShippingAddresses.shippingAddresses: object expected");
                                message.shippingAddresses[i] = $root.theplant.ec.service.users.ShippingAddress.fromObject(object.shippingAddresses[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShippingAddresses message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.instore.ShippingAddresses} message ShippingAddresses
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShippingAddresses.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.shippingAddresses = [];
                        if (message.shippingAddresses && message.shippingAddresses.length) {
                            object.shippingAddresses = [];
                            for (var j = 0; j < message.shippingAddresses.length; ++j)
                                object.shippingAddresses[j] = $root.theplant.ec.service.users.ShippingAddress.toObject(message.shippingAddresses[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this ShippingAddresses to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ShippingAddresses
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShippingAddresses.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShippingAddresses;
                })();

                instore.SearchUser = (function() {

                    /**
                     * Properties of a SearchUser.
                     * @memberof theplant.ec.api.instore
                     * @interface ISearchUser
                     * @property {string|null} [email] SearchUser email
                     * @property {string|null} [familyName] SearchUser familyName
                     * @property {string|null} [givenName] SearchUser givenName
                     * @property {string|null} [katakanaFamilyName] SearchUser katakanaFamilyName
                     * @property {string|null} [katakanaGivenName] SearchUser katakanaGivenName
                     * @property {string|null} [phone] SearchUser phone
                     */

                    /**
                     * Constructs a new SearchUser.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a SearchUser.
                     * @implements ISearchUser
                     * @constructor
                     * @param {theplant.ec.api.instore.ISearchUser=} [properties] Properties to set
                     */
                    function SearchUser(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchUser email.
                     * @member {string} email
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     */
                    SearchUser.prototype.email = "";

                    /**
                     * SearchUser familyName.
                     * @member {string} familyName
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     */
                    SearchUser.prototype.familyName = "";

                    /**
                     * SearchUser givenName.
                     * @member {string} givenName
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     */
                    SearchUser.prototype.givenName = "";

                    /**
                     * SearchUser katakanaFamilyName.
                     * @member {string} katakanaFamilyName
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     */
                    SearchUser.prototype.katakanaFamilyName = "";

                    /**
                     * SearchUser katakanaGivenName.
                     * @member {string} katakanaGivenName
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     */
                    SearchUser.prototype.katakanaGivenName = "";

                    /**
                     * SearchUser phone.
                     * @member {string} phone
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     */
                    SearchUser.prototype.phone = "";

                    /**
                     * Creates a new SearchUser instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {theplant.ec.api.instore.ISearchUser=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.SearchUser} SearchUser instance
                     */
                    SearchUser.create = function create(properties) {
                        return new SearchUser(properties);
                    };

                    /**
                     * Encodes the specified SearchUser message. Does not implicitly {@link theplant.ec.api.instore.SearchUser.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {theplant.ec.api.instore.ISearchUser} message SearchUser message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchUser.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.familyName);
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.givenName);
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.katakanaFamilyName);
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.katakanaGivenName);
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.phone);
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchUser message, length delimited. Does not implicitly {@link theplant.ec.api.instore.SearchUser.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {theplant.ec.api.instore.ISearchUser} message SearchUser message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchUser.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchUser message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.SearchUser} SearchUser
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchUser.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.SearchUser();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.familyName = reader.string();
                                break;
                            case 3:
                                message.givenName = reader.string();
                                break;
                            case 4:
                                message.katakanaFamilyName = reader.string();
                                break;
                            case 5:
                                message.katakanaGivenName = reader.string();
                                break;
                            case 6:
                                message.phone = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchUser message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.SearchUser} SearchUser
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchUser.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchUser message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchUser.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            if (!$util.isString(message.familyName))
                                return "familyName: string expected";
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            if (!$util.isString(message.givenName))
                                return "givenName: string expected";
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            if (!$util.isString(message.katakanaFamilyName))
                                return "katakanaFamilyName: string expected";
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            if (!$util.isString(message.katakanaGivenName))
                                return "katakanaGivenName: string expected";
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            if (!$util.isString(message.phone))
                                return "phone: string expected";
                        return null;
                    };

                    /**
                     * Creates a SearchUser message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.SearchUser} SearchUser
                     */
                    SearchUser.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.SearchUser)
                            return object;
                        var message = new $root.theplant.ec.api.instore.SearchUser();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.familyName != null)
                            message.familyName = String(object.familyName);
                        if (object.givenName != null)
                            message.givenName = String(object.givenName);
                        if (object.katakanaFamilyName != null)
                            message.katakanaFamilyName = String(object.katakanaFamilyName);
                        if (object.katakanaGivenName != null)
                            message.katakanaGivenName = String(object.katakanaGivenName);
                        if (object.phone != null)
                            message.phone = String(object.phone);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchUser message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @static
                     * @param {theplant.ec.api.instore.SearchUser} message SearchUser
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchUser.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.familyName = "";
                            object.givenName = "";
                            object.katakanaFamilyName = "";
                            object.katakanaGivenName = "";
                            object.phone = "";
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            object.familyName = message.familyName;
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            object.givenName = message.givenName;
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            object.katakanaFamilyName = message.katakanaFamilyName;
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            object.katakanaGivenName = message.katakanaGivenName;
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            object.phone = message.phone;
                        return object;
                    };

                    /**
                     * Converts this SearchUser to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.SearchUser
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchUser.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchUser;
                })();

                instore.SearchUsersResult = (function() {

                    /**
                     * Properties of a SearchUsersResult.
                     * @memberof theplant.ec.api.instore
                     * @interface ISearchUsersResult
                     * @property {Array.<theplant.ec.api.instore.ISearchUser>|null} [searchUsers] SearchUsersResult searchUsers
                     */

                    /**
                     * Constructs a new SearchUsersResult.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a SearchUsersResult.
                     * @implements ISearchUsersResult
                     * @constructor
                     * @param {theplant.ec.api.instore.ISearchUsersResult=} [properties] Properties to set
                     */
                    function SearchUsersResult(properties) {
                        this.searchUsers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchUsersResult searchUsers.
                     * @member {Array.<theplant.ec.api.instore.ISearchUser>} searchUsers
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @instance
                     */
                    SearchUsersResult.prototype.searchUsers = $util.emptyArray;

                    /**
                     * Creates a new SearchUsersResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {theplant.ec.api.instore.ISearchUsersResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.SearchUsersResult} SearchUsersResult instance
                     */
                    SearchUsersResult.create = function create(properties) {
                        return new SearchUsersResult(properties);
                    };

                    /**
                     * Encodes the specified SearchUsersResult message. Does not implicitly {@link theplant.ec.api.instore.SearchUsersResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {theplant.ec.api.instore.ISearchUsersResult} message SearchUsersResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchUsersResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.searchUsers != null && message.searchUsers.length)
                            for (var i = 0; i < message.searchUsers.length; ++i)
                                $root.theplant.ec.api.instore.SearchUser.encode(message.searchUsers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchUsersResult message, length delimited. Does not implicitly {@link theplant.ec.api.instore.SearchUsersResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {theplant.ec.api.instore.ISearchUsersResult} message SearchUsersResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchUsersResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchUsersResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.SearchUsersResult} SearchUsersResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchUsersResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.SearchUsersResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.searchUsers && message.searchUsers.length))
                                    message.searchUsers = [];
                                message.searchUsers.push($root.theplant.ec.api.instore.SearchUser.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchUsersResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.SearchUsersResult} SearchUsersResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchUsersResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchUsersResult message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchUsersResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.searchUsers != null && message.hasOwnProperty("searchUsers")) {
                            if (!Array.isArray(message.searchUsers))
                                return "searchUsers: array expected";
                            for (var i = 0; i < message.searchUsers.length; ++i) {
                                var error = $root.theplant.ec.api.instore.SearchUser.verify(message.searchUsers[i]);
                                if (error)
                                    return "searchUsers." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchUsersResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.SearchUsersResult} SearchUsersResult
                     */
                    SearchUsersResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.SearchUsersResult)
                            return object;
                        var message = new $root.theplant.ec.api.instore.SearchUsersResult();
                        if (object.searchUsers) {
                            if (!Array.isArray(object.searchUsers))
                                throw TypeError(".theplant.ec.api.instore.SearchUsersResult.searchUsers: array expected");
                            message.searchUsers = [];
                            for (var i = 0; i < object.searchUsers.length; ++i) {
                                if (typeof object.searchUsers[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.SearchUsersResult.searchUsers: object expected");
                                message.searchUsers[i] = $root.theplant.ec.api.instore.SearchUser.fromObject(object.searchUsers[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchUsersResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @static
                     * @param {theplant.ec.api.instore.SearchUsersResult} message SearchUsersResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchUsersResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.searchUsers = [];
                        if (message.searchUsers && message.searchUsers.length) {
                            object.searchUsers = [];
                            for (var j = 0; j < message.searchUsers.length; ++j)
                                object.searchUsers[j] = $root.theplant.ec.api.instore.SearchUser.toObject(message.searchUsers[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SearchUsersResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.SearchUsersResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchUsersResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchUsersResult;
                })();

                instore.Email = (function() {

                    /**
                     * Properties of an Email.
                     * @memberof theplant.ec.api.instore
                     * @interface IEmail
                     * @property {string|null} [email] Email email
                     */

                    /**
                     * Constructs a new Email.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an Email.
                     * @implements IEmail
                     * @constructor
                     * @param {theplant.ec.api.instore.IEmail=} [properties] Properties to set
                     */
                    function Email(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Email email.
                     * @member {string} email
                     * @memberof theplant.ec.api.instore.Email
                     * @instance
                     */
                    Email.prototype.email = "";

                    /**
                     * Creates a new Email instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {theplant.ec.api.instore.IEmail=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.Email} Email instance
                     */
                    Email.create = function create(properties) {
                        return new Email(properties);
                    };

                    /**
                     * Encodes the specified Email message. Does not implicitly {@link theplant.ec.api.instore.Email.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {theplant.ec.api.instore.IEmail} message Email message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Email.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        return writer;
                    };

                    /**
                     * Encodes the specified Email message, length delimited. Does not implicitly {@link theplant.ec.api.instore.Email.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {theplant.ec.api.instore.IEmail} message Email message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Email.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Email message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.Email} Email
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Email.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.Email();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Email message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.Email} Email
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Email.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Email message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Email.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        return null;
                    };

                    /**
                     * Creates an Email message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.Email} Email
                     */
                    Email.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.Email)
                            return object;
                        var message = new $root.theplant.ec.api.instore.Email();
                        if (object.email != null)
                            message.email = String(object.email);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Email message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.Email
                     * @static
                     * @param {theplant.ec.api.instore.Email} message Email
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Email.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.email = "";
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        return object;
                    };

                    /**
                     * Converts this Email to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.Email
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Email.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Email;
                })();

                instore.ValidateEmailResult = (function() {

                    /**
                     * Properties of a ValidateEmailResult.
                     * @memberof theplant.ec.api.instore
                     * @interface IValidateEmailResult
                     * @property {boolean|null} [isValid] ValidateEmailResult isValid
                     * @property {boolean|null} [isUsed] ValidateEmailResult isUsed
                     */

                    /**
                     * Constructs a new ValidateEmailResult.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ValidateEmailResult.
                     * @implements IValidateEmailResult
                     * @constructor
                     * @param {theplant.ec.api.instore.IValidateEmailResult=} [properties] Properties to set
                     */
                    function ValidateEmailResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ValidateEmailResult isValid.
                     * @member {boolean} isValid
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @instance
                     */
                    ValidateEmailResult.prototype.isValid = false;

                    /**
                     * ValidateEmailResult isUsed.
                     * @member {boolean} isUsed
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @instance
                     */
                    ValidateEmailResult.prototype.isUsed = false;

                    /**
                     * Creates a new ValidateEmailResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {theplant.ec.api.instore.IValidateEmailResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ValidateEmailResult} ValidateEmailResult instance
                     */
                    ValidateEmailResult.create = function create(properties) {
                        return new ValidateEmailResult(properties);
                    };

                    /**
                     * Encodes the specified ValidateEmailResult message. Does not implicitly {@link theplant.ec.api.instore.ValidateEmailResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {theplant.ec.api.instore.IValidateEmailResult} message ValidateEmailResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ValidateEmailResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isValid != null && message.hasOwnProperty("isValid"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isValid);
                        if (message.isUsed != null && message.hasOwnProperty("isUsed"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isUsed);
                        return writer;
                    };

                    /**
                     * Encodes the specified ValidateEmailResult message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ValidateEmailResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {theplant.ec.api.instore.IValidateEmailResult} message ValidateEmailResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ValidateEmailResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ValidateEmailResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ValidateEmailResult} ValidateEmailResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ValidateEmailResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ValidateEmailResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.isValid = reader.bool();
                                break;
                            case 2:
                                message.isUsed = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ValidateEmailResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ValidateEmailResult} ValidateEmailResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ValidateEmailResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ValidateEmailResult message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ValidateEmailResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isValid != null && message.hasOwnProperty("isValid"))
                            if (typeof message.isValid !== "boolean")
                                return "isValid: boolean expected";
                        if (message.isUsed != null && message.hasOwnProperty("isUsed"))
                            if (typeof message.isUsed !== "boolean")
                                return "isUsed: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a ValidateEmailResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ValidateEmailResult} ValidateEmailResult
                     */
                    ValidateEmailResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ValidateEmailResult)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ValidateEmailResult();
                        if (object.isValid != null)
                            message.isValid = Boolean(object.isValid);
                        if (object.isUsed != null)
                            message.isUsed = Boolean(object.isUsed);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ValidateEmailResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @static
                     * @param {theplant.ec.api.instore.ValidateEmailResult} message ValidateEmailResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ValidateEmailResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isValid = false;
                            object.isUsed = false;
                        }
                        if (message.isValid != null && message.hasOwnProperty("isValid"))
                            object.isValid = message.isValid;
                        if (message.isUsed != null && message.hasOwnProperty("isUsed"))
                            object.isUsed = message.isUsed;
                        return object;
                    };

                    /**
                     * Converts this ValidateEmailResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ValidateEmailResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ValidateEmailResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ValidateEmailResult;
                })();

                instore.PhoneNumber = (function() {

                    /**
                     * Properties of a PhoneNumber.
                     * @memberof theplant.ec.api.instore
                     * @interface IPhoneNumber
                     * @property {string|null} [phoneNumber] PhoneNumber phoneNumber
                     */

                    /**
                     * Constructs a new PhoneNumber.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a PhoneNumber.
                     * @implements IPhoneNumber
                     * @constructor
                     * @param {theplant.ec.api.instore.IPhoneNumber=} [properties] Properties to set
                     */
                    function PhoneNumber(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PhoneNumber phoneNumber.
                     * @member {string} phoneNumber
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @instance
                     */
                    PhoneNumber.prototype.phoneNumber = "";

                    /**
                     * Creates a new PhoneNumber instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {theplant.ec.api.instore.IPhoneNumber=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.PhoneNumber} PhoneNumber instance
                     */
                    PhoneNumber.create = function create(properties) {
                        return new PhoneNumber(properties);
                    };

                    /**
                     * Encodes the specified PhoneNumber message. Does not implicitly {@link theplant.ec.api.instore.PhoneNumber.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {theplant.ec.api.instore.IPhoneNumber} message PhoneNumber message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PhoneNumber.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.phoneNumber);
                        return writer;
                    };

                    /**
                     * Encodes the specified PhoneNumber message, length delimited. Does not implicitly {@link theplant.ec.api.instore.PhoneNumber.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {theplant.ec.api.instore.IPhoneNumber} message PhoneNumber message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PhoneNumber.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PhoneNumber message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.PhoneNumber} PhoneNumber
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PhoneNumber.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.PhoneNumber();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.phoneNumber = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PhoneNumber message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.PhoneNumber} PhoneNumber
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PhoneNumber.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PhoneNumber message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PhoneNumber.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                            if (!$util.isString(message.phoneNumber))
                                return "phoneNumber: string expected";
                        return null;
                    };

                    /**
                     * Creates a PhoneNumber message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.PhoneNumber} PhoneNumber
                     */
                    PhoneNumber.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.PhoneNumber)
                            return object;
                        var message = new $root.theplant.ec.api.instore.PhoneNumber();
                        if (object.phoneNumber != null)
                            message.phoneNumber = String(object.phoneNumber);
                        return message;
                    };

                    /**
                     * Creates a plain object from a PhoneNumber message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @static
                     * @param {theplant.ec.api.instore.PhoneNumber} message PhoneNumber
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PhoneNumber.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.phoneNumber = "";
                        if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                            object.phoneNumber = message.phoneNumber;
                        return object;
                    };

                    /**
                     * Converts this PhoneNumber to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.PhoneNumber
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PhoneNumber.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PhoneNumber;
                })();

                instore.ValidatePhoneNumberResult = (function() {

                    /**
                     * Properties of a ValidatePhoneNumberResult.
                     * @memberof theplant.ec.api.instore
                     * @interface IValidatePhoneNumberResult
                     * @property {boolean|null} [isValid] ValidatePhoneNumberResult isValid
                     * @property {string|null} [formattedPhoneNumber] ValidatePhoneNumberResult formattedPhoneNumber
                     */

                    /**
                     * Constructs a new ValidatePhoneNumberResult.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ValidatePhoneNumberResult.
                     * @implements IValidatePhoneNumberResult
                     * @constructor
                     * @param {theplant.ec.api.instore.IValidatePhoneNumberResult=} [properties] Properties to set
                     */
                    function ValidatePhoneNumberResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ValidatePhoneNumberResult isValid.
                     * @member {boolean} isValid
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @instance
                     */
                    ValidatePhoneNumberResult.prototype.isValid = false;

                    /**
                     * ValidatePhoneNumberResult formattedPhoneNumber.
                     * @member {string} formattedPhoneNumber
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @instance
                     */
                    ValidatePhoneNumberResult.prototype.formattedPhoneNumber = "";

                    /**
                     * Creates a new ValidatePhoneNumberResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {theplant.ec.api.instore.IValidatePhoneNumberResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ValidatePhoneNumberResult} ValidatePhoneNumberResult instance
                     */
                    ValidatePhoneNumberResult.create = function create(properties) {
                        return new ValidatePhoneNumberResult(properties);
                    };

                    /**
                     * Encodes the specified ValidatePhoneNumberResult message. Does not implicitly {@link theplant.ec.api.instore.ValidatePhoneNumberResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {theplant.ec.api.instore.IValidatePhoneNumberResult} message ValidatePhoneNumberResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ValidatePhoneNumberResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isValid != null && message.hasOwnProperty("isValid"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isValid);
                        if (message.formattedPhoneNumber != null && message.hasOwnProperty("formattedPhoneNumber"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.formattedPhoneNumber);
                        return writer;
                    };

                    /**
                     * Encodes the specified ValidatePhoneNumberResult message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ValidatePhoneNumberResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {theplant.ec.api.instore.IValidatePhoneNumberResult} message ValidatePhoneNumberResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ValidatePhoneNumberResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ValidatePhoneNumberResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ValidatePhoneNumberResult} ValidatePhoneNumberResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ValidatePhoneNumberResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ValidatePhoneNumberResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.isValid = reader.bool();
                                break;
                            case 2:
                                message.formattedPhoneNumber = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ValidatePhoneNumberResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ValidatePhoneNumberResult} ValidatePhoneNumberResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ValidatePhoneNumberResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ValidatePhoneNumberResult message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ValidatePhoneNumberResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isValid != null && message.hasOwnProperty("isValid"))
                            if (typeof message.isValid !== "boolean")
                                return "isValid: boolean expected";
                        if (message.formattedPhoneNumber != null && message.hasOwnProperty("formattedPhoneNumber"))
                            if (!$util.isString(message.formattedPhoneNumber))
                                return "formattedPhoneNumber: string expected";
                        return null;
                    };

                    /**
                     * Creates a ValidatePhoneNumberResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ValidatePhoneNumberResult} ValidatePhoneNumberResult
                     */
                    ValidatePhoneNumberResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ValidatePhoneNumberResult)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ValidatePhoneNumberResult();
                        if (object.isValid != null)
                            message.isValid = Boolean(object.isValid);
                        if (object.formattedPhoneNumber != null)
                            message.formattedPhoneNumber = String(object.formattedPhoneNumber);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ValidatePhoneNumberResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @static
                     * @param {theplant.ec.api.instore.ValidatePhoneNumberResult} message ValidatePhoneNumberResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ValidatePhoneNumberResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isValid = false;
                            object.formattedPhoneNumber = "";
                        }
                        if (message.isValid != null && message.hasOwnProperty("isValid"))
                            object.isValid = message.isValid;
                        if (message.formattedPhoneNumber != null && message.hasOwnProperty("formattedPhoneNumber"))
                            object.formattedPhoneNumber = message.formattedPhoneNumber;
                        return object;
                    };

                    /**
                     * Converts this ValidatePhoneNumberResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ValidatePhoneNumberResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ValidatePhoneNumberResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ValidatePhoneNumberResult;
                })();

                instore.GetUserInput = (function() {

                    /**
                     * Properties of a GetUserInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetUserInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] GetUserInput userAuthnToken
                     */

                    /**
                     * Constructs a new GetUserInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetUserInput.
                     * @implements IGetUserInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetUserInput=} [properties] Properties to set
                     */
                    function GetUserInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetUserInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @instance
                     */
                    GetUserInput.prototype.userAuthnToken = null;

                    /**
                     * Creates a new GetUserInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetUserInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetUserInput} GetUserInput instance
                     */
                    GetUserInput.create = function create(properties) {
                        return new GetUserInput(properties);
                    };

                    /**
                     * Encodes the specified GetUserInput message. Does not implicitly {@link theplant.ec.api.instore.GetUserInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetUserInput} message GetUserInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetUserInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetUserInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetUserInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetUserInput} message GetUserInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetUserInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetUserInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetUserInput} GetUserInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetUserInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetUserInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetUserInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetUserInput} GetUserInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetUserInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetUserInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetUserInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a GetUserInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetUserInput} GetUserInput
                     */
                    GetUserInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetUserInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetUserInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.GetUserInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetUserInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @static
                     * @param {theplant.ec.api.instore.GetUserInput} message GetUserInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetUserInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.userAuthnToken = null;
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this GetUserInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetUserInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetUserInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetUserInput;
                })();

                instore.GetShippingAddressesInput = (function() {

                    /**
                     * Properties of a GetShippingAddressesInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetShippingAddressesInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] GetShippingAddressesInput userAuthnToken
                     */

                    /**
                     * Constructs a new GetShippingAddressesInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetShippingAddressesInput.
                     * @implements IGetShippingAddressesInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetShippingAddressesInput=} [properties] Properties to set
                     */
                    function GetShippingAddressesInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetShippingAddressesInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @instance
                     */
                    GetShippingAddressesInput.prototype.userAuthnToken = null;

                    /**
                     * Creates a new GetShippingAddressesInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetShippingAddressesInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetShippingAddressesInput} GetShippingAddressesInput instance
                     */
                    GetShippingAddressesInput.create = function create(properties) {
                        return new GetShippingAddressesInput(properties);
                    };

                    /**
                     * Encodes the specified GetShippingAddressesInput message. Does not implicitly {@link theplant.ec.api.instore.GetShippingAddressesInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetShippingAddressesInput} message GetShippingAddressesInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShippingAddressesInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetShippingAddressesInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetShippingAddressesInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetShippingAddressesInput} message GetShippingAddressesInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShippingAddressesInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetShippingAddressesInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetShippingAddressesInput} GetShippingAddressesInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShippingAddressesInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetShippingAddressesInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetShippingAddressesInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetShippingAddressesInput} GetShippingAddressesInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShippingAddressesInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetShippingAddressesInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetShippingAddressesInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a GetShippingAddressesInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetShippingAddressesInput} GetShippingAddressesInput
                     */
                    GetShippingAddressesInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetShippingAddressesInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetShippingAddressesInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.GetShippingAddressesInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetShippingAddressesInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @static
                     * @param {theplant.ec.api.instore.GetShippingAddressesInput} message GetShippingAddressesInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetShippingAddressesInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.userAuthnToken = null;
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this GetShippingAddressesInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetShippingAddressesInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetShippingAddressesInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetShippingAddressesInput;
                })();

                instore.ApplyUserShippingAddressInput = (function() {

                    /**
                     * Properties of an ApplyUserShippingAddressInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IApplyUserShippingAddressInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ApplyUserShippingAddressInput userAuthnToken
                     * @property {theplant.ec.service.users.IShippingAddress|null} [shippingAddress] ApplyUserShippingAddressInput shippingAddress
                     */

                    /**
                     * Constructs a new ApplyUserShippingAddressInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an ApplyUserShippingAddressInput.
                     * @implements IApplyUserShippingAddressInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IApplyUserShippingAddressInput=} [properties] Properties to set
                     */
                    function ApplyUserShippingAddressInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ApplyUserShippingAddressInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @instance
                     */
                    ApplyUserShippingAddressInput.prototype.userAuthnToken = null;

                    /**
                     * ApplyUserShippingAddressInput shippingAddress.
                     * @member {theplant.ec.service.users.IShippingAddress|null|undefined} shippingAddress
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @instance
                     */
                    ApplyUserShippingAddressInput.prototype.shippingAddress = null;

                    /**
                     * Creates a new ApplyUserShippingAddressInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyUserShippingAddressInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ApplyUserShippingAddressInput} ApplyUserShippingAddressInput instance
                     */
                    ApplyUserShippingAddressInput.create = function create(properties) {
                        return new ApplyUserShippingAddressInput(properties);
                    };

                    /**
                     * Encodes the specified ApplyUserShippingAddressInput message. Does not implicitly {@link theplant.ec.api.instore.ApplyUserShippingAddressInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyUserShippingAddressInput} message ApplyUserShippingAddressInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyUserShippingAddressInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress"))
                            $root.theplant.ec.service.users.ShippingAddress.encode(message.shippingAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ApplyUserShippingAddressInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ApplyUserShippingAddressInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyUserShippingAddressInput} message ApplyUserShippingAddressInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyUserShippingAddressInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ApplyUserShippingAddressInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ApplyUserShippingAddressInput} ApplyUserShippingAddressInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyUserShippingAddressInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ApplyUserShippingAddressInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.shippingAddress = $root.theplant.ec.service.users.ShippingAddress.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ApplyUserShippingAddressInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ApplyUserShippingAddressInput} ApplyUserShippingAddressInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyUserShippingAddressInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ApplyUserShippingAddressInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ApplyUserShippingAddressInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress")) {
                            var error = $root.theplant.ec.service.users.ShippingAddress.verify(message.shippingAddress);
                            if (error)
                                return "shippingAddress." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an ApplyUserShippingAddressInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ApplyUserShippingAddressInput} ApplyUserShippingAddressInput
                     */
                    ApplyUserShippingAddressInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ApplyUserShippingAddressInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ApplyUserShippingAddressInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyUserShippingAddressInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.shippingAddress != null) {
                            if (typeof object.shippingAddress !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyUserShippingAddressInput.shippingAddress: object expected");
                            message.shippingAddress = $root.theplant.ec.service.users.ShippingAddress.fromObject(object.shippingAddress);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ApplyUserShippingAddressInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @static
                     * @param {theplant.ec.api.instore.ApplyUserShippingAddressInput} message ApplyUserShippingAddressInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ApplyUserShippingAddressInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userAuthnToken = null;
                            object.shippingAddress = null;
                        }
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress"))
                            object.shippingAddress = $root.theplant.ec.service.users.ShippingAddress.toObject(message.shippingAddress, options);
                        return object;
                    };

                    /**
                     * Converts this ApplyUserShippingAddressInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ApplyUserShippingAddressInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ApplyUserShippingAddressInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ApplyUserShippingAddressInput;
                })();

                instore.ApplyProfileInput = (function() {

                    /**
                     * Properties of an ApplyProfileInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IApplyProfileInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ApplyProfileInput userAuthnToken
                     * @property {theplant.ec.service.users.IProfile|null} [profile] ApplyProfileInput profile
                     */

                    /**
                     * Constructs a new ApplyProfileInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an ApplyProfileInput.
                     * @implements IApplyProfileInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IApplyProfileInput=} [properties] Properties to set
                     */
                    function ApplyProfileInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ApplyProfileInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @instance
                     */
                    ApplyProfileInput.prototype.userAuthnToken = null;

                    /**
                     * ApplyProfileInput profile.
                     * @member {theplant.ec.service.users.IProfile|null|undefined} profile
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @instance
                     */
                    ApplyProfileInput.prototype.profile = null;

                    /**
                     * Creates a new ApplyProfileInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyProfileInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ApplyProfileInput} ApplyProfileInput instance
                     */
                    ApplyProfileInput.create = function create(properties) {
                        return new ApplyProfileInput(properties);
                    };

                    /**
                     * Encodes the specified ApplyProfileInput message. Does not implicitly {@link theplant.ec.api.instore.ApplyProfileInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyProfileInput} message ApplyProfileInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyProfileInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            $root.theplant.ec.service.users.Profile.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ApplyProfileInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ApplyProfileInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {theplant.ec.api.instore.IApplyProfileInput} message ApplyProfileInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyProfileInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ApplyProfileInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ApplyProfileInput} ApplyProfileInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyProfileInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ApplyProfileInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.profile = $root.theplant.ec.service.users.Profile.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ApplyProfileInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ApplyProfileInput} ApplyProfileInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyProfileInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ApplyProfileInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ApplyProfileInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.profile != null && message.hasOwnProperty("profile")) {
                            var error = $root.theplant.ec.service.users.Profile.verify(message.profile);
                            if (error)
                                return "profile." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an ApplyProfileInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ApplyProfileInput} ApplyProfileInput
                     */
                    ApplyProfileInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ApplyProfileInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ApplyProfileInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyProfileInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.profile != null) {
                            if (typeof object.profile !== "object")
                                throw TypeError(".theplant.ec.api.instore.ApplyProfileInput.profile: object expected");
                            message.profile = $root.theplant.ec.service.users.Profile.fromObject(object.profile);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ApplyProfileInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @static
                     * @param {theplant.ec.api.instore.ApplyProfileInput} message ApplyProfileInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ApplyProfileInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userAuthnToken = null;
                            object.profile = null;
                        }
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            object.profile = $root.theplant.ec.service.users.Profile.toObject(message.profile, options);
                        return object;
                    };

                    /**
                     * Converts this ApplyProfileInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ApplyProfileInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ApplyProfileInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ApplyProfileInput;
                })();

                instore.CheckoutInput = (function() {

                    /**
                     * Properties of a CheckoutInput.
                     * @memberof theplant.ec.api.instore
                     * @interface ICheckoutInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] CheckoutInput userAuthnToken
                     */

                    /**
                     * Constructs a new CheckoutInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a CheckoutInput.
                     * @implements ICheckoutInput
                     * @constructor
                     * @param {theplant.ec.api.instore.ICheckoutInput=} [properties] Properties to set
                     */
                    function CheckoutInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CheckoutInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.userAuthnToken = null;

                    /**
                     * Creates a new CheckoutInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.instore.ICheckoutInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.CheckoutInput} CheckoutInput instance
                     */
                    CheckoutInput.create = function create(properties) {
                        return new CheckoutInput(properties);
                    };

                    /**
                     * Encodes the specified CheckoutInput message. Does not implicitly {@link theplant.ec.api.instore.CheckoutInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.instore.ICheckoutInput} message CheckoutInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CheckoutInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CheckoutInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.CheckoutInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.instore.ICheckoutInput} message CheckoutInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CheckoutInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CheckoutInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.CheckoutInput} CheckoutInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CheckoutInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.CheckoutInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CheckoutInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.CheckoutInput} CheckoutInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CheckoutInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CheckoutInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CheckoutInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CheckoutInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.CheckoutInput} CheckoutInput
                     */
                    CheckoutInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.CheckoutInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.CheckoutInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.CheckoutInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CheckoutInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.instore.CheckoutInput} message CheckoutInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CheckoutInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.userAuthnToken = null;
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        return object;
                    };

                    /**
                     * Converts this CheckoutInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.CheckoutInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CheckoutInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CheckoutInput;
                })();

                instore.ConfirmOrderInput = (function() {

                    /**
                     * Properties of a ConfirmOrderInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IConfirmOrderInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] ConfirmOrderInput userAuthnToken
                     * @property {string|null} [orderCode] ConfirmOrderInput orderCode
                     */

                    /**
                     * Constructs a new ConfirmOrderInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ConfirmOrderInput.
                     * @implements IConfirmOrderInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IConfirmOrderInput=} [properties] Properties to set
                     */
                    function ConfirmOrderInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ConfirmOrderInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @instance
                     */
                    ConfirmOrderInput.prototype.userAuthnToken = null;

                    /**
                     * ConfirmOrderInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @instance
                     */
                    ConfirmOrderInput.prototype.orderCode = "";

                    /**
                     * Creates a new ConfirmOrderInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.IConfirmOrderInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ConfirmOrderInput} ConfirmOrderInput instance
                     */
                    ConfirmOrderInput.create = function create(properties) {
                        return new ConfirmOrderInput(properties);
                    };

                    /**
                     * Encodes the specified ConfirmOrderInput message. Does not implicitly {@link theplant.ec.api.instore.ConfirmOrderInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.IConfirmOrderInput} message ConfirmOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConfirmOrderInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified ConfirmOrderInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ConfirmOrderInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.IConfirmOrderInput} message ConfirmOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConfirmOrderInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ConfirmOrderInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ConfirmOrderInput} ConfirmOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConfirmOrderInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ConfirmOrderInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.orderCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ConfirmOrderInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ConfirmOrderInput} ConfirmOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConfirmOrderInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ConfirmOrderInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ConfirmOrderInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a ConfirmOrderInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ConfirmOrderInput} ConfirmOrderInput
                     */
                    ConfirmOrderInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ConfirmOrderInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ConfirmOrderInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.ConfirmOrderInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ConfirmOrderInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @static
                     * @param {theplant.ec.api.instore.ConfirmOrderInput} message ConfirmOrderInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ConfirmOrderInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userAuthnToken = null;
                            object.orderCode = "";
                        }
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        return object;
                    };

                    /**
                     * Converts this ConfirmOrderInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ConfirmOrderInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ConfirmOrderInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ConfirmOrderInput;
                })();

                instore.GetDeliveryDatesInput = (function() {

                    /**
                     * Properties of a GetDeliveryDatesInput.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetDeliveryDatesInput
                     * @property {theplant.ec.api.instore.IUserAuthnToken|null} [userAuthnToken] GetDeliveryDatesInput userAuthnToken
                     * @property {string|null} [orderCode] GetDeliveryDatesInput orderCode
                     */

                    /**
                     * Constructs a new GetDeliveryDatesInput.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetDeliveryDatesInput.
                     * @implements IGetDeliveryDatesInput
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetDeliveryDatesInput=} [properties] Properties to set
                     */
                    function GetDeliveryDatesInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetDeliveryDatesInput userAuthnToken.
                     * @member {theplant.ec.api.instore.IUserAuthnToken|null|undefined} userAuthnToken
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @instance
                     */
                    GetDeliveryDatesInput.prototype.userAuthnToken = null;

                    /**
                     * GetDeliveryDatesInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @instance
                     */
                    GetDeliveryDatesInput.prototype.orderCode = "";

                    /**
                     * Creates a new GetDeliveryDatesInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetDeliveryDatesInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetDeliveryDatesInput} GetDeliveryDatesInput instance
                     */
                    GetDeliveryDatesInput.create = function create(properties) {
                        return new GetDeliveryDatesInput(properties);
                    };

                    /**
                     * Encodes the specified GetDeliveryDatesInput message. Does not implicitly {@link theplant.ec.api.instore.GetDeliveryDatesInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetDeliveryDatesInput} message GetDeliveryDatesInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetDeliveryDatesInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            $root.theplant.ec.api.instore.UserAuthnToken.encode(message.userAuthnToken, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetDeliveryDatesInput message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetDeliveryDatesInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {theplant.ec.api.instore.IGetDeliveryDatesInput} message GetDeliveryDatesInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetDeliveryDatesInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetDeliveryDatesInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetDeliveryDatesInput} GetDeliveryDatesInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetDeliveryDatesInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetDeliveryDatesInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.orderCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetDeliveryDatesInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetDeliveryDatesInput} GetDeliveryDatesInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetDeliveryDatesInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetDeliveryDatesInput message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetDeliveryDatesInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken")) {
                            var error = $root.theplant.ec.api.instore.UserAuthnToken.verify(message.userAuthnToken);
                            if (error)
                                return "userAuthnToken." + error;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a GetDeliveryDatesInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetDeliveryDatesInput} GetDeliveryDatesInput
                     */
                    GetDeliveryDatesInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetDeliveryDatesInput)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetDeliveryDatesInput();
                        if (object.userAuthnToken != null) {
                            if (typeof object.userAuthnToken !== "object")
                                throw TypeError(".theplant.ec.api.instore.GetDeliveryDatesInput.userAuthnToken: object expected");
                            message.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.fromObject(object.userAuthnToken);
                        }
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetDeliveryDatesInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @static
                     * @param {theplant.ec.api.instore.GetDeliveryDatesInput} message GetDeliveryDatesInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetDeliveryDatesInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userAuthnToken = null;
                            object.orderCode = "";
                        }
                        if (message.userAuthnToken != null && message.hasOwnProperty("userAuthnToken"))
                            object.userAuthnToken = $root.theplant.ec.api.instore.UserAuthnToken.toObject(message.userAuthnToken, options);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        return object;
                    };

                    /**
                     * Converts this GetDeliveryDatesInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetDeliveryDatesInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetDeliveryDatesInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetDeliveryDatesInput;
                })();

                instore.IsHemmingPantsResult = (function() {

                    /**
                     * Properties of an IsHemmingPantsResult.
                     * @memberof theplant.ec.api.instore
                     * @interface IIsHemmingPantsResult
                     * @property {boolean|null} [isHemmingPants] IsHemmingPantsResult isHemmingPants
                     */

                    /**
                     * Constructs a new IsHemmingPantsResult.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an IsHemmingPantsResult.
                     * @implements IIsHemmingPantsResult
                     * @constructor
                     * @param {theplant.ec.api.instore.IIsHemmingPantsResult=} [properties] Properties to set
                     */
                    function IsHemmingPantsResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * IsHemmingPantsResult isHemmingPants.
                     * @member {boolean} isHemmingPants
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @instance
                     */
                    IsHemmingPantsResult.prototype.isHemmingPants = false;

                    /**
                     * Creates a new IsHemmingPantsResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.instore.IIsHemmingPantsResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.IsHemmingPantsResult} IsHemmingPantsResult instance
                     */
                    IsHemmingPantsResult.create = function create(properties) {
                        return new IsHemmingPantsResult(properties);
                    };

                    /**
                     * Encodes the specified IsHemmingPantsResult message. Does not implicitly {@link theplant.ec.api.instore.IsHemmingPantsResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.instore.IIsHemmingPantsResult} message IsHemmingPantsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IsHemmingPantsResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isHemmingPants != null && message.hasOwnProperty("isHemmingPants"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isHemmingPants);
                        return writer;
                    };

                    /**
                     * Encodes the specified IsHemmingPantsResult message, length delimited. Does not implicitly {@link theplant.ec.api.instore.IsHemmingPantsResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.instore.IIsHemmingPantsResult} message IsHemmingPantsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IsHemmingPantsResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an IsHemmingPantsResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.IsHemmingPantsResult} IsHemmingPantsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IsHemmingPantsResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.IsHemmingPantsResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.isHemmingPants = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an IsHemmingPantsResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.IsHemmingPantsResult} IsHemmingPantsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IsHemmingPantsResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an IsHemmingPantsResult message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IsHemmingPantsResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isHemmingPants != null && message.hasOwnProperty("isHemmingPants"))
                            if (typeof message.isHemmingPants !== "boolean")
                                return "isHemmingPants: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an IsHemmingPantsResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.IsHemmingPantsResult} IsHemmingPantsResult
                     */
                    IsHemmingPantsResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.IsHemmingPantsResult)
                            return object;
                        var message = new $root.theplant.ec.api.instore.IsHemmingPantsResult();
                        if (object.isHemmingPants != null)
                            message.isHemmingPants = Boolean(object.isHemmingPants);
                        return message;
                    };

                    /**
                     * Creates a plain object from an IsHemmingPantsResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.instore.IsHemmingPantsResult} message IsHemmingPantsResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IsHemmingPantsResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.isHemmingPants = false;
                        if (message.isHemmingPants != null && message.hasOwnProperty("isHemmingPants"))
                            object.isHemmingPants = message.isHemmingPants;
                        return object;
                    };

                    /**
                     * Converts this IsHemmingPantsResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.IsHemmingPantsResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IsHemmingPantsResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return IsHemmingPantsResult;
                })();

                instore.StoreService = (function() {

                    /**
                     * Constructs a new StoreService service.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a StoreService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (StoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreService;

                    /**
                     * Creates new StoreService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.instore.StoreService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.StoreService#getStoreInfo}.
                     * @memberof theplant.ec.api.instore.StoreService
                     * @typedef GetStoreInfoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.stores.Store} [response] Store
                     */

                    /**
                     * Calls GetStoreInfo.
                     * @function getStoreInfo
                     * @memberof theplant.ec.api.instore.StoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.instore.StoreService.GetStoreInfoCallback} callback Node-style callback called with the error, if any, and Store
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.getStoreInfo = function getStoreInfo(request, callback) {
                        return this.rpcCall(getStoreInfo, $root.theplant.ec.api.Empty, $root.theplant.ec.service.stores.Store, request, callback);
                    }, "name", { value: "GetStoreInfo" });

                    /**
                     * Calls GetStoreInfo.
                     * @function getStoreInfo
                     * @memberof theplant.ec.api.instore.StoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.stores.Store>} Promise
                     * @variation 2
                     */

                    return StoreService;
                })();

                instore.OrderService = (function() {

                    /**
                     * Constructs a new OrderService service.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an OrderService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function OrderService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (OrderService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = OrderService;

                    /**
                     * Creates new OrderService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.instore.OrderService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {OrderService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    OrderService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#getAllOrders}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef GetAllOrdersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.Orders} [response] Orders
                     */

                    /**
                     * Calls GetAllOrders.
                     * @function getAllOrders
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.instore.OrderService.GetAllOrdersCallback} callback Node-style callback called with the error, if any, and Orders
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.getAllOrders = function getAllOrders(request, callback) {
                        return this.rpcCall(getAllOrders, $root.theplant.ec.api.Empty, $root.theplant.ec.api.instore.Orders, request, callback);
                    }, "name", { value: "GetAllOrders" });

                    /**
                     * Calls GetAllOrders.
                     * @function getAllOrders
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.instore.Orders>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#applyShippingType}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ApplyShippingTypeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ApplyShippingType.
                     * @function applyShippingType
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IShippingTypeInput} request ShippingTypeInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ApplyShippingTypeCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.applyShippingType = function applyShippingType(request, callback) {
                        return this.rpcCall(applyShippingType, $root.theplant.ec.api.instore.ShippingTypeInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ApplyShippingType" });

                    /**
                     * Calls ApplyShippingType.
                     * @function applyShippingType
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IShippingTypeInput} request ShippingTypeInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#applyPaymentType}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ApplyPaymentTypeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ApplyPaymentType.
                     * @function applyPaymentType
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IPaymentTypeInput} request PaymentTypeInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ApplyPaymentTypeCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.applyPaymentType = function applyPaymentType(request, callback) {
                        return this.rpcCall(applyPaymentType, $root.theplant.ec.api.instore.PaymentTypeInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ApplyPaymentType" });

                    /**
                     * Calls ApplyPaymentType.
                     * @function applyPaymentType
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IPaymentTypeInput} request PaymentTypeInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#applyExternalPaymentID}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ApplyExternalPaymentIDCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ApplyExternalPaymentID.
                     * @function applyExternalPaymentID
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IExternalPaymentIDInput} request ExternalPaymentIDInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ApplyExternalPaymentIDCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.applyExternalPaymentID = function applyExternalPaymentID(request, callback) {
                        return this.rpcCall(applyExternalPaymentID, $root.theplant.ec.api.instore.ExternalPaymentIDInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ApplyExternalPaymentID" });

                    /**
                     * Calls ApplyExternalPaymentID.
                     * @function applyExternalPaymentID
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IExternalPaymentIDInput} request ExternalPaymentIDInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#applyShippingAddress}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ApplyShippingAddressCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ApplyShippingAddress.
                     * @function applyShippingAddress
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyOrderShippingAddressInput} request ApplyOrderShippingAddressInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ApplyShippingAddressCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.applyShippingAddress = function applyShippingAddress(request, callback) {
                        return this.rpcCall(applyShippingAddress, $root.theplant.ec.api.instore.ApplyOrderShippingAddressInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ApplyShippingAddress" });

                    /**
                     * Calls ApplyShippingAddress.
                     * @function applyShippingAddress
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyOrderShippingAddressInput} request ApplyOrderShippingAddressInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#checkout}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef CheckoutCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls Checkout.
                     * @function checkout
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.ICheckoutInput} request CheckoutInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.CheckoutCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.checkout = function checkout(request, callback) {
                        return this.rpcCall(checkout, $root.theplant.ec.api.instore.CheckoutInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "Checkout" });

                    /**
                     * Calls Checkout.
                     * @function checkout
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.ICheckoutInput} request CheckoutInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#confirmOrder}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ConfirmOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ConfirmOrder.
                     * @function confirmOrder
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IConfirmOrderInput} request ConfirmOrderInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ConfirmOrderCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.confirmOrder = function confirmOrder(request, callback) {
                        return this.rpcCall(confirmOrder, $root.theplant.ec.api.instore.ConfirmOrderInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ConfirmOrder" });

                    /**
                     * Calls ConfirmOrder.
                     * @function confirmOrder
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IConfirmOrderInput} request ConfirmOrderInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#markAsArrived}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef MarkAsArrivedCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls MarkAsArrived.
                     * @function markAsArrived
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @param {theplant.ec.api.instore.OrderService.MarkAsArrivedCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.markAsArrived = function markAsArrived(request, callback) {
                        return this.rpcCall(markAsArrived, $root.theplant.ec.api.instore.OrderCode, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "MarkAsArrived" });

                    /**
                     * Calls MarkAsArrived.
                     * @function markAsArrived
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#markAsPickedUp}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef MarkAsPickedUpCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls MarkAsPickedUp.
                     * @function markAsPickedUp
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @param {theplant.ec.api.instore.OrderService.MarkAsPickedUpCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.markAsPickedUp = function markAsPickedUp(request, callback) {
                        return this.rpcCall(markAsPickedUp, $root.theplant.ec.api.instore.OrderCode, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "MarkAsPickedUp" });

                    /**
                     * Calls MarkAsPickedUp.
                     * @function markAsPickedUp
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#cancelOrder}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef CancelOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls CancelOrder.
                     * @function cancelOrder
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @param {theplant.ec.api.instore.OrderService.CancelOrderCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.cancelOrder = function cancelOrder(request, callback) {
                        return this.rpcCall(cancelOrder, $root.theplant.ec.api.instore.OrderCode, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "CancelOrder" });

                    /**
                     * Calls CancelOrder.
                     * @function cancelOrder
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#closeOrder}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef CloseOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls CloseOrder.
                     * @function closeOrder
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.ICloseOrderInput} request CloseOrderInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.CloseOrderCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.closeOrder = function closeOrder(request, callback) {
                        return this.rpcCall(closeOrder, $root.theplant.ec.api.instore.CloseOrderInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "CloseOrder" });

                    /**
                     * Calls CloseOrder.
                     * @function closeOrder
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.ICloseOrderInput} request CloseOrderInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#patchOrderSumUp}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef PatchOrderSumUpCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls PatchOrderSumUp.
                     * @function patchOrderSumUp
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.service.orders.IOrder} request Order message or plain object
                     * @param {theplant.ec.api.instore.OrderService.PatchOrderSumUpCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.patchOrderSumUp = function patchOrderSumUp(request, callback) {
                        return this.rpcCall(patchOrderSumUp, $root.theplant.ec.service.orders.Order, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "PatchOrderSumUp" });

                    /**
                     * Calls PatchOrderSumUp.
                     * @function patchOrderSumUp
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.service.orders.IOrder} request Order message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#getDeliveryDates}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef GetDeliveryDatesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.DeliveryDateRange} [response] DeliveryDateRange
                     */

                    /**
                     * Calls GetDeliveryDates.
                     * @function getDeliveryDates
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetDeliveryDatesInput} request GetDeliveryDatesInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.GetDeliveryDatesCallback} callback Node-style callback called with the error, if any, and DeliveryDateRange
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.getDeliveryDates = function getDeliveryDates(request, callback) {
                        return this.rpcCall(getDeliveryDates, $root.theplant.ec.api.instore.GetDeliveryDatesInput, $root.theplant.ec.api.instore.DeliveryDateRange, request, callback);
                    }, "name", { value: "GetDeliveryDates" });

                    /**
                     * Calls GetDeliveryDates.
                     * @function getDeliveryDates
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetDeliveryDatesInput} request GetDeliveryDatesInput message or plain object
                     * @returns {Promise<theplant.ec.api.instore.DeliveryDateRange>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#applyDeliveryInfo}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ApplyDeliveryInfoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ApplyDeliveryInfo.
                     * @function applyDeliveryInfo
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyDeliveryInfoInput} request ApplyDeliveryInfoInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ApplyDeliveryInfoCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.applyDeliveryInfo = function applyDeliveryInfo(request, callback) {
                        return this.rpcCall(applyDeliveryInfo, $root.theplant.ec.api.instore.ApplyDeliveryInfoInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ApplyDeliveryInfo" });

                    /**
                     * Calls ApplyDeliveryInfo.
                     * @function applyDeliveryInfo
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyDeliveryInfoInput} request ApplyDeliveryInfoInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#payWithoutItems}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef PayWithoutItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls PayWithoutItems.
                     * @function payWithoutItems
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IPayWithoutItemsInput} request PayWithoutItemsInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.PayWithoutItemsCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.payWithoutItems = function payWithoutItems(request, callback) {
                        return this.rpcCall(payWithoutItems, $root.theplant.ec.api.instore.PayWithoutItemsInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "PayWithoutItems" });

                    /**
                     * Calls PayWithoutItems.
                     * @function payWithoutItems
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IPayWithoutItemsInput} request PayWithoutItemsInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#getFeeSetting}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef GetFeeSettingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.accounting.FeeSetting} [response] FeeSetting
                     */

                    /**
                     * Calls GetFeeSetting.
                     * @function getFeeSetting
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.instore.OrderService.GetFeeSettingCallback} callback Node-style callback called with the error, if any, and FeeSetting
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.getFeeSetting = function getFeeSetting(request, callback) {
                        return this.rpcCall(getFeeSetting, $root.theplant.ec.api.Empty, $root.theplant.ec.service.accounting.FeeSetting, request, callback);
                    }, "name", { value: "GetFeeSetting" });

                    /**
                     * Calls GetFeeSetting.
                     * @function getFeeSetting
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.accounting.FeeSetting>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#applyGiftWrapping}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef ApplyGiftWrappingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls ApplyGiftWrapping.
                     * @function applyGiftWrapping
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IGiftWrappingInput} request GiftWrappingInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.ApplyGiftWrappingCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.applyGiftWrapping = function applyGiftWrapping(request, callback) {
                        return this.rpcCall(applyGiftWrapping, $root.theplant.ec.api.instore.GiftWrappingInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "ApplyGiftWrapping" });

                    /**
                     * Calls ApplyGiftWrapping.
                     * @function applyGiftWrapping
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IGiftWrappingInput} request GiftWrappingInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#markAsPickedUpOrReturn}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef MarkAsPickedUpOrReturnCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls MarkAsPickedUpOrReturn.
                     * @function markAsPickedUpOrReturn
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IMarkAsPickedUpOrReturnInput} request MarkAsPickedUpOrReturnInput message or plain object
                     * @param {theplant.ec.api.instore.OrderService.MarkAsPickedUpOrReturnCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.markAsPickedUpOrReturn = function markAsPickedUpOrReturn(request, callback) {
                        return this.rpcCall(markAsPickedUpOrReturn, $root.theplant.ec.api.instore.MarkAsPickedUpOrReturnInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "MarkAsPickedUpOrReturn" });

                    /**
                     * Calls MarkAsPickedUpOrReturn.
                     * @function markAsPickedUpOrReturn
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IMarkAsPickedUpOrReturnInput} request MarkAsPickedUpOrReturnInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.OrderService#isHemmingPants}.
                     * @memberof theplant.ec.api.instore.OrderService
                     * @typedef IsHemmingPantsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.IsHemmingPantsResult} [response] IsHemmingPantsResult
                     */

                    /**
                     * Calls IsHemmingPants.
                     * @function isHemmingPants
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @param {theplant.ec.api.instore.OrderService.IsHemmingPantsCallback} callback Node-style callback called with the error, if any, and IsHemmingPantsResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(OrderService.prototype.isHemmingPants = function isHemmingPants(request, callback) {
                        return this.rpcCall(isHemmingPants, $root.theplant.ec.api.instore.OrderCode, $root.theplant.ec.api.instore.IsHemmingPantsResult, request, callback);
                    }, "name", { value: "IsHemmingPants" });

                    /**
                     * Calls IsHemmingPants.
                     * @function isHemmingPants
                     * @memberof theplant.ec.api.instore.OrderService
                     * @instance
                     * @param {theplant.ec.api.instore.IOrderCode} request OrderCode message or plain object
                     * @returns {Promise<theplant.ec.api.instore.IsHemmingPantsResult>} Promise
                     * @variation 2
                     */

                    return OrderService;
                })();

                instore.UserService = (function() {

                    /**
                     * Constructs a new UserService service.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a UserService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function UserService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (UserService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = UserService;

                    /**
                     * Creates new UserService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.instore.UserService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {UserService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    UserService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#searchUsers}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef SearchUsersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.SearchUsersResult} [response] SearchUsersResult
                     */

                    /**
                     * Calls SearchUsers.
                     * @function searchUsers
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.ISearchInput} request SearchInput message or plain object
                     * @param {theplant.ec.api.instore.UserService.SearchUsersCallback} callback Node-style callback called with the error, if any, and SearchUsersResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.searchUsers = function searchUsers(request, callback) {
                        return this.rpcCall(searchUsers, $root.theplant.ec.api.instore.SearchInput, $root.theplant.ec.api.instore.SearchUsersResult, request, callback);
                    }, "name", { value: "SearchUsers" });

                    /**
                     * Calls SearchUsers.
                     * @function searchUsers
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.ISearchInput} request SearchInput message or plain object
                     * @returns {Promise<theplant.ec.api.instore.SearchUsersResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#register}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef RegisterCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.UserAuthnToken} [response] UserAuthnToken
                     */

                    /**
                     * Calls Register.
                     * @function register
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IRegisterInput} request RegisterInput message or plain object
                     * @param {theplant.ec.api.instore.UserService.RegisterCallback} callback Node-style callback called with the error, if any, and UserAuthnToken
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.register = function register(request, callback) {
                        return this.rpcCall(register, $root.theplant.ec.api.instore.RegisterInput, $root.theplant.ec.api.instore.UserAuthnToken, request, callback);
                    }, "name", { value: "Register" });

                    /**
                     * Calls Register.
                     * @function register
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IRegisterInput} request RegisterInput message or plain object
                     * @returns {Promise<theplant.ec.api.instore.UserAuthnToken>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#getAuthnToken}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef GetAuthnTokenCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.UserAuthnToken} [response] UserAuthnToken
                     */

                    /**
                     * Calls GetAuthnToken.
                     * @function getAuthnToken
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.ILoginKey} request LoginKey message or plain object
                     * @param {theplant.ec.api.instore.UserService.GetAuthnTokenCallback} callback Node-style callback called with the error, if any, and UserAuthnToken
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.getAuthnToken = function getAuthnToken(request, callback) {
                        return this.rpcCall(getAuthnToken, $root.theplant.ec.api.instore.LoginKey, $root.theplant.ec.api.instore.UserAuthnToken, request, callback);
                    }, "name", { value: "GetAuthnToken" });

                    /**
                     * Calls GetAuthnToken.
                     * @function getAuthnToken
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.ILoginKey} request LoginKey message or plain object
                     * @returns {Promise<theplant.ec.api.instore.UserAuthnToken>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#getUser}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef GetUserCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls GetUser.
                     * @function getUser
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetUserInput} request GetUserInput message or plain object
                     * @param {theplant.ec.api.instore.UserService.GetUserCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.getUser = function getUser(request, callback) {
                        return this.rpcCall(getUser, $root.theplant.ec.api.instore.GetUserInput, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "GetUser" });

                    /**
                     * Calls GetUser.
                     * @function getUser
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetUserInput} request GetUserInput message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#getShippingAddresses}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef GetShippingAddressesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.ShippingAddresses} [response] ShippingAddresses
                     */

                    /**
                     * Calls GetShippingAddresses.
                     * @function getShippingAddresses
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetShippingAddressesInput} request GetShippingAddressesInput message or plain object
                     * @param {theplant.ec.api.instore.UserService.GetShippingAddressesCallback} callback Node-style callback called with the error, if any, and ShippingAddresses
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.getShippingAddresses = function getShippingAddresses(request, callback) {
                        return this.rpcCall(getShippingAddresses, $root.theplant.ec.api.instore.GetShippingAddressesInput, $root.theplant.ec.api.instore.ShippingAddresses, request, callback);
                    }, "name", { value: "GetShippingAddresses" });

                    /**
                     * Calls GetShippingAddresses.
                     * @function getShippingAddresses
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetShippingAddressesInput} request GetShippingAddressesInput message or plain object
                     * @returns {Promise<theplant.ec.api.instore.ShippingAddresses>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#applyShippingAddress}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef ApplyShippingAddressCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.ShippingAddress} [response] ShippingAddress
                     */

                    /**
                     * Calls ApplyShippingAddress.
                     * @function applyShippingAddress
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyUserShippingAddressInput} request ApplyUserShippingAddressInput message or plain object
                     * @param {theplant.ec.api.instore.UserService.ApplyShippingAddressCallback} callback Node-style callback called with the error, if any, and ShippingAddress
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.applyShippingAddress = function applyShippingAddress(request, callback) {
                        return this.rpcCall(applyShippingAddress, $root.theplant.ec.api.instore.ApplyUserShippingAddressInput, $root.theplant.ec.service.users.ShippingAddress, request, callback);
                    }, "name", { value: "ApplyShippingAddress" });

                    /**
                     * Calls ApplyShippingAddress.
                     * @function applyShippingAddress
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyUserShippingAddressInput} request ApplyUserShippingAddressInput message or plain object
                     * @returns {Promise<theplant.ec.service.users.ShippingAddress>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#applyProfile}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef ApplyProfileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls ApplyProfile.
                     * @function applyProfile
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyProfileInput} request ApplyProfileInput message or plain object
                     * @param {theplant.ec.api.instore.UserService.ApplyProfileCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.applyProfile = function applyProfile(request, callback) {
                        return this.rpcCall(applyProfile, $root.theplant.ec.api.instore.ApplyProfileInput, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "ApplyProfile" });

                    /**
                     * Calls ApplyProfile.
                     * @function applyProfile
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IApplyProfileInput} request ApplyProfileInput message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#validateEmail}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef ValidateEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.ValidateEmailResult} [response] ValidateEmailResult
                     */

                    /**
                     * Calls ValidateEmail.
                     * @function validateEmail
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IEmail} request Email message or plain object
                     * @param {theplant.ec.api.instore.UserService.ValidateEmailCallback} callback Node-style callback called with the error, if any, and ValidateEmailResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.validateEmail = function validateEmail(request, callback) {
                        return this.rpcCall(validateEmail, $root.theplant.ec.api.instore.Email, $root.theplant.ec.api.instore.ValidateEmailResult, request, callback);
                    }, "name", { value: "ValidateEmail" });

                    /**
                     * Calls ValidateEmail.
                     * @function validateEmail
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IEmail} request Email message or plain object
                     * @returns {Promise<theplant.ec.api.instore.ValidateEmailResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.UserService#validatePhoneNumber}.
                     * @memberof theplant.ec.api.instore.UserService
                     * @typedef ValidatePhoneNumberCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.ValidatePhoneNumberResult} [response] ValidatePhoneNumberResult
                     */

                    /**
                     * Calls ValidatePhoneNumber.
                     * @function validatePhoneNumber
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IPhoneNumber} request PhoneNumber message or plain object
                     * @param {theplant.ec.api.instore.UserService.ValidatePhoneNumberCallback} callback Node-style callback called with the error, if any, and ValidatePhoneNumberResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(UserService.prototype.validatePhoneNumber = function validatePhoneNumber(request, callback) {
                        return this.rpcCall(validatePhoneNumber, $root.theplant.ec.api.instore.PhoneNumber, $root.theplant.ec.api.instore.ValidatePhoneNumberResult, request, callback);
                    }, "name", { value: "ValidatePhoneNumber" });

                    /**
                     * Calls ValidatePhoneNumber.
                     * @function validatePhoneNumber
                     * @memberof theplant.ec.api.instore.UserService
                     * @instance
                     * @param {theplant.ec.api.instore.IPhoneNumber} request PhoneNumber message or plain object
                     * @returns {Promise<theplant.ec.api.instore.ValidatePhoneNumberResult>} Promise
                     * @variation 2
                     */

                    return UserService;
                })();

                instore.CertificateAndPrivateKey = (function() {

                    /**
                     * Properties of a CertificateAndPrivateKey.
                     * @memberof theplant.ec.api.instore
                     * @interface ICertificateAndPrivateKey
                     * @property {string|null} [certificate] CertificateAndPrivateKey certificate
                     * @property {string|null} [privateKey] CertificateAndPrivateKey privateKey
                     */

                    /**
                     * Constructs a new CertificateAndPrivateKey.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a CertificateAndPrivateKey.
                     * @implements ICertificateAndPrivateKey
                     * @constructor
                     * @param {theplant.ec.api.instore.ICertificateAndPrivateKey=} [properties] Properties to set
                     */
                    function CertificateAndPrivateKey(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CertificateAndPrivateKey certificate.
                     * @member {string} certificate
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @instance
                     */
                    CertificateAndPrivateKey.prototype.certificate = "";

                    /**
                     * CertificateAndPrivateKey privateKey.
                     * @member {string} privateKey
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @instance
                     */
                    CertificateAndPrivateKey.prototype.privateKey = "";

                    /**
                     * Creates a new CertificateAndPrivateKey instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {theplant.ec.api.instore.ICertificateAndPrivateKey=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.CertificateAndPrivateKey} CertificateAndPrivateKey instance
                     */
                    CertificateAndPrivateKey.create = function create(properties) {
                        return new CertificateAndPrivateKey(properties);
                    };

                    /**
                     * Encodes the specified CertificateAndPrivateKey message. Does not implicitly {@link theplant.ec.api.instore.CertificateAndPrivateKey.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {theplant.ec.api.instore.ICertificateAndPrivateKey} message CertificateAndPrivateKey message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CertificateAndPrivateKey.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.certificate != null && message.hasOwnProperty("certificate"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.certificate);
                        if (message.privateKey != null && message.hasOwnProperty("privateKey"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.privateKey);
                        return writer;
                    };

                    /**
                     * Encodes the specified CertificateAndPrivateKey message, length delimited. Does not implicitly {@link theplant.ec.api.instore.CertificateAndPrivateKey.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {theplant.ec.api.instore.ICertificateAndPrivateKey} message CertificateAndPrivateKey message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CertificateAndPrivateKey.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CertificateAndPrivateKey message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.CertificateAndPrivateKey} CertificateAndPrivateKey
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CertificateAndPrivateKey.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.CertificateAndPrivateKey();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.certificate = reader.string();
                                break;
                            case 2:
                                message.privateKey = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CertificateAndPrivateKey message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.CertificateAndPrivateKey} CertificateAndPrivateKey
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CertificateAndPrivateKey.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CertificateAndPrivateKey message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CertificateAndPrivateKey.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.certificate != null && message.hasOwnProperty("certificate"))
                            if (!$util.isString(message.certificate))
                                return "certificate: string expected";
                        if (message.privateKey != null && message.hasOwnProperty("privateKey"))
                            if (!$util.isString(message.privateKey))
                                return "privateKey: string expected";
                        return null;
                    };

                    /**
                     * Creates a CertificateAndPrivateKey message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.CertificateAndPrivateKey} CertificateAndPrivateKey
                     */
                    CertificateAndPrivateKey.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.CertificateAndPrivateKey)
                            return object;
                        var message = new $root.theplant.ec.api.instore.CertificateAndPrivateKey();
                        if (object.certificate != null)
                            message.certificate = String(object.certificate);
                        if (object.privateKey != null)
                            message.privateKey = String(object.privateKey);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CertificateAndPrivateKey message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @static
                     * @param {theplant.ec.api.instore.CertificateAndPrivateKey} message CertificateAndPrivateKey
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CertificateAndPrivateKey.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.certificate = "";
                            object.privateKey = "";
                        }
                        if (message.certificate != null && message.hasOwnProperty("certificate"))
                            object.certificate = message.certificate;
                        if (message.privateKey != null && message.hasOwnProperty("privateKey"))
                            object.privateKey = message.privateKey;
                        return object;
                    };

                    /**
                     * Converts this CertificateAndPrivateKey to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.CertificateAndPrivateKey
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CertificateAndPrivateKey.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CertificateAndPrivateKey;
                })();

                instore.Certificate = (function() {

                    /**
                     * Properties of a Certificate.
                     * @memberof theplant.ec.api.instore
                     * @interface ICertificate
                     * @property {string|null} [certificate] Certificate certificate
                     */

                    /**
                     * Constructs a new Certificate.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a Certificate.
                     * @implements ICertificate
                     * @constructor
                     * @param {theplant.ec.api.instore.ICertificate=} [properties] Properties to set
                     */
                    function Certificate(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Certificate certificate.
                     * @member {string} certificate
                     * @memberof theplant.ec.api.instore.Certificate
                     * @instance
                     */
                    Certificate.prototype.certificate = "";

                    /**
                     * Creates a new Certificate instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {theplant.ec.api.instore.ICertificate=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.Certificate} Certificate instance
                     */
                    Certificate.create = function create(properties) {
                        return new Certificate(properties);
                    };

                    /**
                     * Encodes the specified Certificate message. Does not implicitly {@link theplant.ec.api.instore.Certificate.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {theplant.ec.api.instore.ICertificate} message Certificate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Certificate.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.certificate != null && message.hasOwnProperty("certificate"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.certificate);
                        return writer;
                    };

                    /**
                     * Encodes the specified Certificate message, length delimited. Does not implicitly {@link theplant.ec.api.instore.Certificate.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {theplant.ec.api.instore.ICertificate} message Certificate message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Certificate.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Certificate message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.Certificate} Certificate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Certificate.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.Certificate();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.certificate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Certificate message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.Certificate} Certificate
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Certificate.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Certificate message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Certificate.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.certificate != null && message.hasOwnProperty("certificate"))
                            if (!$util.isString(message.certificate))
                                return "certificate: string expected";
                        return null;
                    };

                    /**
                     * Creates a Certificate message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.Certificate} Certificate
                     */
                    Certificate.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.Certificate)
                            return object;
                        var message = new $root.theplant.ec.api.instore.Certificate();
                        if (object.certificate != null)
                            message.certificate = String(object.certificate);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Certificate message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.Certificate
                     * @static
                     * @param {theplant.ec.api.instore.Certificate} message Certificate
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Certificate.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.certificate = "";
                        if (message.certificate != null && message.hasOwnProperty("certificate"))
                            object.certificate = message.certificate;
                        return object;
                    };

                    /**
                     * Converts this Certificate to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.Certificate
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Certificate.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Certificate;
                })();

                instore.AuthnToken = (function() {

                    /**
                     * Properties of an AuthnToken.
                     * @memberof theplant.ec.api.instore
                     * @interface IAuthnToken
                     * @property {string|null} [Token] AuthnToken Token
                     */

                    /**
                     * Constructs a new AuthnToken.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an AuthnToken.
                     * @implements IAuthnToken
                     * @constructor
                     * @param {theplant.ec.api.instore.IAuthnToken=} [properties] Properties to set
                     */
                    function AuthnToken(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AuthnToken Token.
                     * @member {string} Token
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @instance
                     */
                    AuthnToken.prototype.Token = "";

                    /**
                     * Creates a new AuthnToken instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.IAuthnToken=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.AuthnToken} AuthnToken instance
                     */
                    AuthnToken.create = function create(properties) {
                        return new AuthnToken(properties);
                    };

                    /**
                     * Encodes the specified AuthnToken message. Does not implicitly {@link theplant.ec.api.instore.AuthnToken.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.IAuthnToken} message AuthnToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AuthnToken.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.Token != null && message.hasOwnProperty("Token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Token);
                        return writer;
                    };

                    /**
                     * Encodes the specified AuthnToken message, length delimited. Does not implicitly {@link theplant.ec.api.instore.AuthnToken.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.IAuthnToken} message AuthnToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AuthnToken.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AuthnToken message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.AuthnToken} AuthnToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AuthnToken.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.AuthnToken();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.Token = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AuthnToken message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.AuthnToken} AuthnToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AuthnToken.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AuthnToken message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AuthnToken.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.Token != null && message.hasOwnProperty("Token"))
                            if (!$util.isString(message.Token))
                                return "Token: string expected";
                        return null;
                    };

                    /**
                     * Creates an AuthnToken message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.AuthnToken} AuthnToken
                     */
                    AuthnToken.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.AuthnToken)
                            return object;
                        var message = new $root.theplant.ec.api.instore.AuthnToken();
                        if (object.Token != null)
                            message.Token = String(object.Token);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AuthnToken message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @static
                     * @param {theplant.ec.api.instore.AuthnToken} message AuthnToken
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AuthnToken.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.Token = "";
                        if (message.Token != null && message.hasOwnProperty("Token"))
                            object.Token = message.Token;
                        return object;
                    };

                    /**
                     * Converts this AuthnToken to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.AuthnToken
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AuthnToken.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AuthnToken;
                })();

                instore.SignCertificateParams = (function() {

                    /**
                     * Properties of a SignCertificateParams.
                     * @memberof theplant.ec.api.instore
                     * @interface ISignCertificateParams
                     * @property {string|null} [authnToken] SignCertificateParams authnToken
                     * @property {string|null} [csr] SignCertificateParams csr
                     * @property {theplant.ec.service.tls.IDevice|null} [device] SignCertificateParams device
                     */

                    /**
                     * Constructs a new SignCertificateParams.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a SignCertificateParams.
                     * @implements ISignCertificateParams
                     * @constructor
                     * @param {theplant.ec.api.instore.ISignCertificateParams=} [properties] Properties to set
                     */
                    function SignCertificateParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SignCertificateParams authnToken.
                     * @member {string} authnToken
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @instance
                     */
                    SignCertificateParams.prototype.authnToken = "";

                    /**
                     * SignCertificateParams csr.
                     * @member {string} csr
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @instance
                     */
                    SignCertificateParams.prototype.csr = "";

                    /**
                     * SignCertificateParams device.
                     * @member {theplant.ec.service.tls.IDevice|null|undefined} device
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @instance
                     */
                    SignCertificateParams.prototype.device = null;

                    /**
                     * Creates a new SignCertificateParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {theplant.ec.api.instore.ISignCertificateParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.SignCertificateParams} SignCertificateParams instance
                     */
                    SignCertificateParams.create = function create(properties) {
                        return new SignCertificateParams(properties);
                    };

                    /**
                     * Encodes the specified SignCertificateParams message. Does not implicitly {@link theplant.ec.api.instore.SignCertificateParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {theplant.ec.api.instore.ISignCertificateParams} message SignCertificateParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SignCertificateParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.authnToken != null && message.hasOwnProperty("authnToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.authnToken);
                        if (message.csr != null && message.hasOwnProperty("csr"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.csr);
                        if (message.device != null && message.hasOwnProperty("device"))
                            $root.theplant.ec.service.tls.Device.encode(message.device, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SignCertificateParams message, length delimited. Does not implicitly {@link theplant.ec.api.instore.SignCertificateParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {theplant.ec.api.instore.ISignCertificateParams} message SignCertificateParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SignCertificateParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SignCertificateParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.SignCertificateParams} SignCertificateParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SignCertificateParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.SignCertificateParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.authnToken = reader.string();
                                break;
                            case 2:
                                message.csr = reader.string();
                                break;
                            case 3:
                                message.device = $root.theplant.ec.service.tls.Device.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SignCertificateParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.SignCertificateParams} SignCertificateParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SignCertificateParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SignCertificateParams message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SignCertificateParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.authnToken != null && message.hasOwnProperty("authnToken"))
                            if (!$util.isString(message.authnToken))
                                return "authnToken: string expected";
                        if (message.csr != null && message.hasOwnProperty("csr"))
                            if (!$util.isString(message.csr))
                                return "csr: string expected";
                        if (message.device != null && message.hasOwnProperty("device")) {
                            var error = $root.theplant.ec.service.tls.Device.verify(message.device);
                            if (error)
                                return "device." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SignCertificateParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.SignCertificateParams} SignCertificateParams
                     */
                    SignCertificateParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.SignCertificateParams)
                            return object;
                        var message = new $root.theplant.ec.api.instore.SignCertificateParams();
                        if (object.authnToken != null)
                            message.authnToken = String(object.authnToken);
                        if (object.csr != null)
                            message.csr = String(object.csr);
                        if (object.device != null) {
                            if (typeof object.device !== "object")
                                throw TypeError(".theplant.ec.api.instore.SignCertificateParams.device: object expected");
                            message.device = $root.theplant.ec.service.tls.Device.fromObject(object.device);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SignCertificateParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @static
                     * @param {theplant.ec.api.instore.SignCertificateParams} message SignCertificateParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SignCertificateParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.authnToken = "";
                            object.csr = "";
                            object.device = null;
                        }
                        if (message.authnToken != null && message.hasOwnProperty("authnToken"))
                            object.authnToken = message.authnToken;
                        if (message.csr != null && message.hasOwnProperty("csr"))
                            object.csr = message.csr;
                        if (message.device != null && message.hasOwnProperty("device"))
                            object.device = $root.theplant.ec.service.tls.Device.toObject(message.device, options);
                        return object;
                    };

                    /**
                     * Converts this SignCertificateParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.SignCertificateParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SignCertificateParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SignCertificateParams;
                })();

                instore.AuthnTokenAndDevice = (function() {

                    /**
                     * Properties of an AuthnTokenAndDevice.
                     * @memberof theplant.ec.api.instore
                     * @interface IAuthnTokenAndDevice
                     * @property {string|null} [token] AuthnTokenAndDevice token
                     * @property {theplant.ec.service.tls.IDevice|null} [device] AuthnTokenAndDevice device
                     */

                    /**
                     * Constructs a new AuthnTokenAndDevice.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an AuthnTokenAndDevice.
                     * @implements IAuthnTokenAndDevice
                     * @constructor
                     * @param {theplant.ec.api.instore.IAuthnTokenAndDevice=} [properties] Properties to set
                     */
                    function AuthnTokenAndDevice(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AuthnTokenAndDevice token.
                     * @member {string} token
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @instance
                     */
                    AuthnTokenAndDevice.prototype.token = "";

                    /**
                     * AuthnTokenAndDevice device.
                     * @member {theplant.ec.service.tls.IDevice|null|undefined} device
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @instance
                     */
                    AuthnTokenAndDevice.prototype.device = null;

                    /**
                     * Creates a new AuthnTokenAndDevice instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {theplant.ec.api.instore.IAuthnTokenAndDevice=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.AuthnTokenAndDevice} AuthnTokenAndDevice instance
                     */
                    AuthnTokenAndDevice.create = function create(properties) {
                        return new AuthnTokenAndDevice(properties);
                    };

                    /**
                     * Encodes the specified AuthnTokenAndDevice message. Does not implicitly {@link theplant.ec.api.instore.AuthnTokenAndDevice.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {theplant.ec.api.instore.IAuthnTokenAndDevice} message AuthnTokenAndDevice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AuthnTokenAndDevice.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        if (message.device != null && message.hasOwnProperty("device"))
                            $root.theplant.ec.service.tls.Device.encode(message.device, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AuthnTokenAndDevice message, length delimited. Does not implicitly {@link theplant.ec.api.instore.AuthnTokenAndDevice.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {theplant.ec.api.instore.IAuthnTokenAndDevice} message AuthnTokenAndDevice message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AuthnTokenAndDevice.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AuthnTokenAndDevice message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.AuthnTokenAndDevice} AuthnTokenAndDevice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AuthnTokenAndDevice.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.AuthnTokenAndDevice();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            case 2:
                                message.device = $root.theplant.ec.service.tls.Device.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AuthnTokenAndDevice message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.AuthnTokenAndDevice} AuthnTokenAndDevice
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AuthnTokenAndDevice.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AuthnTokenAndDevice message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AuthnTokenAndDevice.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        if (message.device != null && message.hasOwnProperty("device")) {
                            var error = $root.theplant.ec.service.tls.Device.verify(message.device);
                            if (error)
                                return "device." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an AuthnTokenAndDevice message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.AuthnTokenAndDevice} AuthnTokenAndDevice
                     */
                    AuthnTokenAndDevice.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.AuthnTokenAndDevice)
                            return object;
                        var message = new $root.theplant.ec.api.instore.AuthnTokenAndDevice();
                        if (object.token != null)
                            message.token = String(object.token);
                        if (object.device != null) {
                            if (typeof object.device !== "object")
                                throw TypeError(".theplant.ec.api.instore.AuthnTokenAndDevice.device: object expected");
                            message.device = $root.theplant.ec.service.tls.Device.fromObject(object.device);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AuthnTokenAndDevice message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @static
                     * @param {theplant.ec.api.instore.AuthnTokenAndDevice} message AuthnTokenAndDevice
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AuthnTokenAndDevice.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.token = "";
                            object.device = null;
                        }
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        if (message.device != null && message.hasOwnProperty("device"))
                            object.device = $root.theplant.ec.service.tls.Device.toObject(message.device, options);
                        return object;
                    };

                    /**
                     * Converts this AuthnTokenAndDevice to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.AuthnTokenAndDevice
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AuthnTokenAndDevice.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AuthnTokenAndDevice;
                })();

                instore.TLSCertificateService = (function() {

                    /**
                     * Constructs a new TLSCertificateService service.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a TLSCertificateService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function TLSCertificateService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (TLSCertificateService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TLSCertificateService;

                    /**
                     * Creates new TLSCertificateService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.instore.TLSCertificateService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {TLSCertificateService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    TLSCertificateService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.TLSCertificateService#getCertificateAndPrivateKey}.
                     * @memberof theplant.ec.api.instore.TLSCertificateService
                     * @typedef GetCertificateAndPrivateKeyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.CertificateAndPrivateKey} [response] CertificateAndPrivateKey
                     */

                    /**
                     * Calls GetCertificateAndPrivateKey.
                     * @function getCertificateAndPrivateKey
                     * @memberof theplant.ec.api.instore.TLSCertificateService
                     * @instance
                     * @param {theplant.ec.api.instore.IAuthnToken} request AuthnToken message or plain object
                     * @param {theplant.ec.api.instore.TLSCertificateService.GetCertificateAndPrivateKeyCallback} callback Node-style callback called with the error, if any, and CertificateAndPrivateKey
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TLSCertificateService.prototype.getCertificateAndPrivateKey = function getCertificateAndPrivateKey(request, callback) {
                        return this.rpcCall(getCertificateAndPrivateKey, $root.theplant.ec.api.instore.AuthnToken, $root.theplant.ec.api.instore.CertificateAndPrivateKey, request, callback);
                    }, "name", { value: "GetCertificateAndPrivateKey" });

                    /**
                     * Calls GetCertificateAndPrivateKey.
                     * @function getCertificateAndPrivateKey
                     * @memberof theplant.ec.api.instore.TLSCertificateService
                     * @instance
                     * @param {theplant.ec.api.instore.IAuthnToken} request AuthnToken message or plain object
                     * @returns {Promise<theplant.ec.api.instore.CertificateAndPrivateKey>} Promise
                     * @variation 2
                     */

                    return TLSCertificateService;
                })();

                instore.TLSDeviceCertificateService = (function() {

                    /**
                     * Constructs a new TLSDeviceCertificateService service.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a TLSDeviceCertificateService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function TLSDeviceCertificateService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (TLSDeviceCertificateService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = TLSDeviceCertificateService;

                    /**
                     * Creates new TLSDeviceCertificateService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {TLSDeviceCertificateService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    TLSDeviceCertificateService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.TLSDeviceCertificateService#signCertificate}.
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @typedef SignCertificateCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.Certificate} [response] Certificate
                     */

                    /**
                     * Calls SignCertificate.
                     * @function signCertificate
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @instance
                     * @param {theplant.ec.api.instore.ISignCertificateParams} request SignCertificateParams message or plain object
                     * @param {theplant.ec.api.instore.TLSDeviceCertificateService.SignCertificateCallback} callback Node-style callback called with the error, if any, and Certificate
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TLSDeviceCertificateService.prototype.signCertificate = function signCertificate(request, callback) {
                        return this.rpcCall(signCertificate, $root.theplant.ec.api.instore.SignCertificateParams, $root.theplant.ec.api.instore.Certificate, request, callback);
                    }, "name", { value: "SignCertificate" });

                    /**
                     * Calls SignCertificate.
                     * @function signCertificate
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @instance
                     * @param {theplant.ec.api.instore.ISignCertificateParams} request SignCertificateParams message or plain object
                     * @returns {Promise<theplant.ec.api.instore.Certificate>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.TLSDeviceCertificateService#getCertificateAndPrivateKey}.
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @typedef GetCertificateAndPrivateKeyCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.CertificateAndPrivateKey} [response] CertificateAndPrivateKey
                     */

                    /**
                     * Calls GetCertificateAndPrivateKey.
                     * @function getCertificateAndPrivateKey
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @instance
                     * @param {theplant.ec.api.instore.IAuthnTokenAndDevice} request AuthnTokenAndDevice message or plain object
                     * @param {theplant.ec.api.instore.TLSDeviceCertificateService.GetCertificateAndPrivateKeyCallback} callback Node-style callback called with the error, if any, and CertificateAndPrivateKey
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(TLSDeviceCertificateService.prototype.getCertificateAndPrivateKey = function getCertificateAndPrivateKey(request, callback) {
                        return this.rpcCall(getCertificateAndPrivateKey, $root.theplant.ec.api.instore.AuthnTokenAndDevice, $root.theplant.ec.api.instore.CertificateAndPrivateKey, request, callback);
                    }, "name", { value: "GetCertificateAndPrivateKey" });

                    /**
                     * Calls GetCertificateAndPrivateKey.
                     * @function getCertificateAndPrivateKey
                     * @memberof theplant.ec.api.instore.TLSDeviceCertificateService
                     * @instance
                     * @param {theplant.ec.api.instore.IAuthnTokenAndDevice} request AuthnTokenAndDevice message or plain object
                     * @returns {Promise<theplant.ec.api.instore.CertificateAndPrivateKey>} Promise
                     * @variation 2
                     */

                    return TLSDeviceCertificateService;
                })();

                instore.GetResponse = (function() {

                    /**
                     * Properties of a GetResponse.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetResponse
                     * @property {Array.<theplant.ec.service.inventory.IShipFromStore>|null} [orders] GetResponse orders
                     * @property {number|Long|null} [total] GetResponse total
                     */

                    /**
                     * Constructs a new GetResponse.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetResponse.
                     * @implements IGetResponse
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetResponse=} [properties] Properties to set
                     */
                    function GetResponse(properties) {
                        this.orders = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetResponse orders.
                     * @member {Array.<theplant.ec.service.inventory.IShipFromStore>} orders
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @instance
                     */
                    GetResponse.prototype.orders = $util.emptyArray;

                    /**
                     * GetResponse total.
                     * @member {number|Long} total
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @instance
                     */
                    GetResponse.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new GetResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetResponse} GetResponse instance
                     */
                    GetResponse.create = function create(properties) {
                        return new GetResponse(properties);
                    };

                    /**
                     * Encodes the specified GetResponse message. Does not implicitly {@link theplant.ec.api.instore.GetResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetResponse} message GetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orders != null && message.orders.length)
                            for (var i = 0; i < message.orders.length; ++i)
                                $root.theplant.ec.service.inventory.ShipFromStore.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.total != null && message.hasOwnProperty("total"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.total);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetResponse message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetResponse} message GetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetResponse} GetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.orders && message.orders.length))
                                    message.orders = [];
                                message.orders.push($root.theplant.ec.service.inventory.ShipFromStore.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.total = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetResponse} GetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orders != null && message.hasOwnProperty("orders")) {
                            if (!Array.isArray(message.orders))
                                return "orders: array expected";
                            for (var i = 0; i < message.orders.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.ShipFromStore.verify(message.orders[i]);
                                if (error)
                                    return "orders." + error;
                            }
                        }
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                                return "total: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a GetResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetResponse} GetResponse
                     */
                    GetResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetResponse)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetResponse();
                        if (object.orders) {
                            if (!Array.isArray(object.orders))
                                throw TypeError(".theplant.ec.api.instore.GetResponse.orders: array expected");
                            message.orders = [];
                            for (var i = 0; i < object.orders.length; ++i) {
                                if (typeof object.orders[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.GetResponse.orders: object expected");
                                message.orders[i] = $root.theplant.ec.service.inventory.ShipFromStore.fromObject(object.orders[i]);
                            }
                        }
                        if (object.total != null)
                            if ($util.Long)
                                (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                            else if (typeof object.total === "string")
                                message.total = parseInt(object.total, 10);
                            else if (typeof object.total === "number")
                                message.total = object.total;
                            else if (typeof object.total === "object")
                                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @static
                     * @param {theplant.ec.api.instore.GetResponse} message GetResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.orders = [];
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.total = options.longs === String ? "0" : 0;
                        if (message.orders && message.orders.length) {
                            object.orders = [];
                            for (var j = 0; j < message.orders.length; ++j)
                                object.orders[j] = $root.theplant.ec.service.inventory.ShipFromStore.toObject(message.orders[j], options);
                        }
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (typeof message.total === "number")
                                object.total = options.longs === String ? String(message.total) : message.total;
                            else
                                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                        return object;
                    };

                    /**
                     * Converts this GetResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetResponse;
                })();

                instore.GetShipFromStoreDetailsParam = (function() {

                    /**
                     * Properties of a GetShipFromStoreDetailsParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetShipFromStoreDetailsParam
                     * @property {string|null} [orderCode] GetShipFromStoreDetailsParam orderCode
                     * @property {string|null} [articleCode] GetShipFromStoreDetailsParam articleCode
                     */

                    /**
                     * Constructs a new GetShipFromStoreDetailsParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetShipFromStoreDetailsParam.
                     * @implements IGetShipFromStoreDetailsParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetShipFromStoreDetailsParam=} [properties] Properties to set
                     */
                    function GetShipFromStoreDetailsParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetShipFromStoreDetailsParam orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @instance
                     */
                    GetShipFromStoreDetailsParam.prototype.orderCode = "";

                    /**
                     * GetShipFromStoreDetailsParam articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @instance
                     */
                    GetShipFromStoreDetailsParam.prototype.articleCode = "";

                    /**
                     * Creates a new GetShipFromStoreDetailsParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetShipFromStoreDetailsParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetShipFromStoreDetailsParam} GetShipFromStoreDetailsParam instance
                     */
                    GetShipFromStoreDetailsParam.create = function create(properties) {
                        return new GetShipFromStoreDetailsParam(properties);
                    };

                    /**
                     * Encodes the specified GetShipFromStoreDetailsParam message. Does not implicitly {@link theplant.ec.api.instore.GetShipFromStoreDetailsParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetShipFromStoreDetailsParam} message GetShipFromStoreDetailsParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShipFromStoreDetailsParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetShipFromStoreDetailsParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetShipFromStoreDetailsParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetShipFromStoreDetailsParam} message GetShipFromStoreDetailsParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShipFromStoreDetailsParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetShipFromStoreDetailsParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetShipFromStoreDetailsParam} GetShipFromStoreDetailsParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShipFromStoreDetailsParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetShipFromStoreDetailsParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetShipFromStoreDetailsParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetShipFromStoreDetailsParam} GetShipFromStoreDetailsParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShipFromStoreDetailsParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetShipFromStoreDetailsParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetShipFromStoreDetailsParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a GetShipFromStoreDetailsParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetShipFromStoreDetailsParam} GetShipFromStoreDetailsParam
                     */
                    GetShipFromStoreDetailsParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetShipFromStoreDetailsParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetShipFromStoreDetailsParam();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetShipFromStoreDetailsParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.GetShipFromStoreDetailsParam} message GetShipFromStoreDetailsParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetShipFromStoreDetailsParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        return object;
                    };

                    /**
                     * Converts this GetShipFromStoreDetailsParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetShipFromStoreDetailsParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetShipFromStoreDetailsParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetShipFromStoreDetailsParam;
                })();

                instore.ShipFromStoreDetailsResponse = (function() {

                    /**
                     * Properties of a ShipFromStoreDetailsResponse.
                     * @memberof theplant.ec.api.instore
                     * @interface IShipFromStoreDetailsResponse
                     * @property {theplant.ec.service.inventory.IShipFromStore|null} [shipFromStoreOrder] ShipFromStoreDetailsResponse shipFromStoreOrder
                     * @property {theplant.ec.service.orders.IOrder|null} [order] ShipFromStoreDetailsResponse order
                     */

                    /**
                     * Constructs a new ShipFromStoreDetailsResponse.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ShipFromStoreDetailsResponse.
                     * @implements IShipFromStoreDetailsResponse
                     * @constructor
                     * @param {theplant.ec.api.instore.IShipFromStoreDetailsResponse=} [properties] Properties to set
                     */
                    function ShipFromStoreDetailsResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShipFromStoreDetailsResponse shipFromStoreOrder.
                     * @member {theplant.ec.service.inventory.IShipFromStore|null|undefined} shipFromStoreOrder
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @instance
                     */
                    ShipFromStoreDetailsResponse.prototype.shipFromStoreOrder = null;

                    /**
                     * ShipFromStoreDetailsResponse order.
                     * @member {theplant.ec.service.orders.IOrder|null|undefined} order
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @instance
                     */
                    ShipFromStoreDetailsResponse.prototype.order = null;

                    /**
                     * Creates a new ShipFromStoreDetailsResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IShipFromStoreDetailsResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ShipFromStoreDetailsResponse} ShipFromStoreDetailsResponse instance
                     */
                    ShipFromStoreDetailsResponse.create = function create(properties) {
                        return new ShipFromStoreDetailsResponse(properties);
                    };

                    /**
                     * Encodes the specified ShipFromStoreDetailsResponse message. Does not implicitly {@link theplant.ec.api.instore.ShipFromStoreDetailsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IShipFromStoreDetailsResponse} message ShipFromStoreDetailsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStoreDetailsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.shipFromStoreOrder != null && message.hasOwnProperty("shipFromStoreOrder"))
                            $root.theplant.ec.service.inventory.ShipFromStore.encode(message.shipFromStoreOrder, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.order != null && message.hasOwnProperty("order"))
                            $root.theplant.ec.service.orders.Order.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShipFromStoreDetailsResponse message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ShipFromStoreDetailsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IShipFromStoreDetailsResponse} message ShipFromStoreDetailsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStoreDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShipFromStoreDetailsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ShipFromStoreDetailsResponse} ShipFromStoreDetailsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStoreDetailsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ShipFromStoreDetailsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.shipFromStoreOrder = $root.theplant.ec.service.inventory.ShipFromStore.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.order = $root.theplant.ec.service.orders.Order.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShipFromStoreDetailsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ShipFromStoreDetailsResponse} ShipFromStoreDetailsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStoreDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShipFromStoreDetailsResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShipFromStoreDetailsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.shipFromStoreOrder != null && message.hasOwnProperty("shipFromStoreOrder")) {
                            var error = $root.theplant.ec.service.inventory.ShipFromStore.verify(message.shipFromStoreOrder);
                            if (error)
                                return "shipFromStoreOrder." + error;
                        }
                        if (message.order != null && message.hasOwnProperty("order")) {
                            var error = $root.theplant.ec.service.orders.Order.verify(message.order);
                            if (error)
                                return "order." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ShipFromStoreDetailsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ShipFromStoreDetailsResponse} ShipFromStoreDetailsResponse
                     */
                    ShipFromStoreDetailsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ShipFromStoreDetailsResponse)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ShipFromStoreDetailsResponse();
                        if (object.shipFromStoreOrder != null) {
                            if (typeof object.shipFromStoreOrder !== "object")
                                throw TypeError(".theplant.ec.api.instore.ShipFromStoreDetailsResponse.shipFromStoreOrder: object expected");
                            message.shipFromStoreOrder = $root.theplant.ec.service.inventory.ShipFromStore.fromObject(object.shipFromStoreOrder);
                        }
                        if (object.order != null) {
                            if (typeof object.order !== "object")
                                throw TypeError(".theplant.ec.api.instore.ShipFromStoreDetailsResponse.order: object expected");
                            message.order = $root.theplant.ec.service.orders.Order.fromObject(object.order);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShipFromStoreDetailsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.ShipFromStoreDetailsResponse} message ShipFromStoreDetailsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShipFromStoreDetailsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.shipFromStoreOrder = null;
                            object.order = null;
                        }
                        if (message.shipFromStoreOrder != null && message.hasOwnProperty("shipFromStoreOrder"))
                            object.shipFromStoreOrder = $root.theplant.ec.service.inventory.ShipFromStore.toObject(message.shipFromStoreOrder, options);
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = $root.theplant.ec.service.orders.Order.toObject(message.order, options);
                        return object;
                    };

                    /**
                     * Converts this ShipFromStoreDetailsResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ShipFromStoreDetailsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShipFromStoreDetailsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShipFromStoreDetailsResponse;
                })();

                instore.GetNotificationParam = (function() {

                    /**
                     * Properties of a GetNotificationParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetNotificationParam
                     * @property {Array.<string>|null} [sortParams] GetNotificationParam sortParams
                     */

                    /**
                     * Constructs a new GetNotificationParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetNotificationParam.
                     * @implements IGetNotificationParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetNotificationParam=} [properties] Properties to set
                     */
                    function GetNotificationParam(properties) {
                        this.sortParams = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetNotificationParam sortParams.
                     * @member {Array.<string>} sortParams
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @instance
                     */
                    GetNotificationParam.prototype.sortParams = $util.emptyArray;

                    /**
                     * Creates a new GetNotificationParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetNotificationParam} GetNotificationParam instance
                     */
                    GetNotificationParam.create = function create(properties) {
                        return new GetNotificationParam(properties);
                    };

                    /**
                     * Encodes the specified GetNotificationParam message. Does not implicitly {@link theplant.ec.api.instore.GetNotificationParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationParam} message GetNotificationParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sortParams != null && message.sortParams.length)
                            for (var i = 0; i < message.sortParams.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sortParams[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetNotificationParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetNotificationParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationParam} message GetNotificationParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetNotificationParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetNotificationParam} GetNotificationParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetNotificationParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sortParams && message.sortParams.length))
                                    message.sortParams = [];
                                message.sortParams.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetNotificationParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetNotificationParam} GetNotificationParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetNotificationParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetNotificationParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sortParams != null && message.hasOwnProperty("sortParams")) {
                            if (!Array.isArray(message.sortParams))
                                return "sortParams: array expected";
                            for (var i = 0; i < message.sortParams.length; ++i)
                                if (!$util.isString(message.sortParams[i]))
                                    return "sortParams: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a GetNotificationParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetNotificationParam} GetNotificationParam
                     */
                    GetNotificationParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetNotificationParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetNotificationParam();
                        if (object.sortParams) {
                            if (!Array.isArray(object.sortParams))
                                throw TypeError(".theplant.ec.api.instore.GetNotificationParam.sortParams: array expected");
                            message.sortParams = [];
                            for (var i = 0; i < object.sortParams.length; ++i)
                                message.sortParams[i] = String(object.sortParams[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetNotificationParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.GetNotificationParam} message GetNotificationParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetNotificationParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sortParams = [];
                        if (message.sortParams && message.sortParams.length) {
                            object.sortParams = [];
                            for (var j = 0; j < message.sortParams.length; ++j)
                                object.sortParams[j] = message.sortParams[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this GetNotificationParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetNotificationParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetNotificationParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetNotificationParam;
                })();

                instore.GetNotificationsResponse = (function() {

                    /**
                     * Properties of a GetNotificationsResponse.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetNotificationsResponse
                     * @property {Array.<theplant.ec.service.inventory.IShipFromStoreNotification>|null} [notifications] GetNotificationsResponse notifications
                     */

                    /**
                     * Constructs a new GetNotificationsResponse.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetNotificationsResponse.
                     * @implements IGetNotificationsResponse
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetNotificationsResponse=} [properties] Properties to set
                     */
                    function GetNotificationsResponse(properties) {
                        this.notifications = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetNotificationsResponse notifications.
                     * @member {Array.<theplant.ec.service.inventory.IShipFromStoreNotification>} notifications
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @instance
                     */
                    GetNotificationsResponse.prototype.notifications = $util.emptyArray;

                    /**
                     * Creates a new GetNotificationsResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationsResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetNotificationsResponse} GetNotificationsResponse instance
                     */
                    GetNotificationsResponse.create = function create(properties) {
                        return new GetNotificationsResponse(properties);
                    };

                    /**
                     * Encodes the specified GetNotificationsResponse message. Does not implicitly {@link theplant.ec.api.instore.GetNotificationsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationsResponse} message GetNotificationsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notifications != null && message.notifications.length)
                            for (var i = 0; i < message.notifications.length; ++i)
                                $root.theplant.ec.service.inventory.ShipFromStoreNotification.encode(message.notifications[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetNotificationsResponse message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetNotificationsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationsResponse} message GetNotificationsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetNotificationsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetNotificationsResponse} GetNotificationsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetNotificationsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.notifications && message.notifications.length))
                                    message.notifications = [];
                                message.notifications.push($root.theplant.ec.service.inventory.ShipFromStoreNotification.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetNotificationsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetNotificationsResponse} GetNotificationsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetNotificationsResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetNotificationsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notifications != null && message.hasOwnProperty("notifications")) {
                            if (!Array.isArray(message.notifications))
                                return "notifications: array expected";
                            for (var i = 0; i < message.notifications.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.ShipFromStoreNotification.verify(message.notifications[i]);
                                if (error)
                                    return "notifications." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a GetNotificationsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetNotificationsResponse} GetNotificationsResponse
                     */
                    GetNotificationsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetNotificationsResponse)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetNotificationsResponse();
                        if (object.notifications) {
                            if (!Array.isArray(object.notifications))
                                throw TypeError(".theplant.ec.api.instore.GetNotificationsResponse.notifications: array expected");
                            message.notifications = [];
                            for (var i = 0; i < object.notifications.length; ++i) {
                                if (typeof object.notifications[i] !== "object")
                                    throw TypeError(".theplant.ec.api.instore.GetNotificationsResponse.notifications: object expected");
                                message.notifications[i] = $root.theplant.ec.service.inventory.ShipFromStoreNotification.fromObject(object.notifications[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetNotificationsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @static
                     * @param {theplant.ec.api.instore.GetNotificationsResponse} message GetNotificationsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetNotificationsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.notifications = [];
                        if (message.notifications && message.notifications.length) {
                            object.notifications = [];
                            for (var j = 0; j < message.notifications.length; ++j)
                                object.notifications[j] = $root.theplant.ec.service.inventory.ShipFromStoreNotification.toObject(message.notifications[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this GetNotificationsResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetNotificationsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetNotificationsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetNotificationsResponse;
                })();

                instore.AcceptNotificationParam = (function() {

                    /**
                     * Properties of an AcceptNotificationParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IAcceptNotificationParam
                     * @property {string|null} [orderCode] AcceptNotificationParam orderCode
                     * @property {string|null} [articleCode] AcceptNotificationParam articleCode
                     */

                    /**
                     * Constructs a new AcceptNotificationParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents an AcceptNotificationParam.
                     * @implements IAcceptNotificationParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IAcceptNotificationParam=} [properties] Properties to set
                     */
                    function AcceptNotificationParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AcceptNotificationParam orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @instance
                     */
                    AcceptNotificationParam.prototype.orderCode = "";

                    /**
                     * AcceptNotificationParam articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @instance
                     */
                    AcceptNotificationParam.prototype.articleCode = "";

                    /**
                     * Creates a new AcceptNotificationParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IAcceptNotificationParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.AcceptNotificationParam} AcceptNotificationParam instance
                     */
                    AcceptNotificationParam.create = function create(properties) {
                        return new AcceptNotificationParam(properties);
                    };

                    /**
                     * Encodes the specified AcceptNotificationParam message. Does not implicitly {@link theplant.ec.api.instore.AcceptNotificationParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IAcceptNotificationParam} message AcceptNotificationParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AcceptNotificationParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified AcceptNotificationParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.AcceptNotificationParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IAcceptNotificationParam} message AcceptNotificationParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AcceptNotificationParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AcceptNotificationParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.AcceptNotificationParam} AcceptNotificationParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AcceptNotificationParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.AcceptNotificationParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AcceptNotificationParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.AcceptNotificationParam} AcceptNotificationParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AcceptNotificationParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AcceptNotificationParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AcceptNotificationParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        return null;
                    };

                    /**
                     * Creates an AcceptNotificationParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.AcceptNotificationParam} AcceptNotificationParam
                     */
                    AcceptNotificationParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.AcceptNotificationParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.AcceptNotificationParam();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AcceptNotificationParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.AcceptNotificationParam} message AcceptNotificationParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AcceptNotificationParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        return object;
                    };

                    /**
                     * Converts this AcceptNotificationParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.AcceptNotificationParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AcceptNotificationParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AcceptNotificationParam;
                })();

                instore.GetNotificationDetailsParam = (function() {

                    /**
                     * Properties of a GetNotificationDetailsParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetNotificationDetailsParam
                     * @property {number|Long|null} [notificationId] GetNotificationDetailsParam notificationId
                     */

                    /**
                     * Constructs a new GetNotificationDetailsParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetNotificationDetailsParam.
                     * @implements IGetNotificationDetailsParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsParam=} [properties] Properties to set
                     */
                    function GetNotificationDetailsParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetNotificationDetailsParam notificationId.
                     * @member {number|Long} notificationId
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @instance
                     */
                    GetNotificationDetailsParam.prototype.notificationId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new GetNotificationDetailsParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsParam} GetNotificationDetailsParam instance
                     */
                    GetNotificationDetailsParam.create = function create(properties) {
                        return new GetNotificationDetailsParam(properties);
                    };

                    /**
                     * Encodes the specified GetNotificationDetailsParam message. Does not implicitly {@link theplant.ec.api.instore.GetNotificationDetailsParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsParam} message GetNotificationDetailsParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationDetailsParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.notificationId);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetNotificationDetailsParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetNotificationDetailsParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsParam} message GetNotificationDetailsParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationDetailsParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetNotificationDetailsParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsParam} GetNotificationDetailsParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationDetailsParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetNotificationDetailsParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notificationId = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetNotificationDetailsParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsParam} GetNotificationDetailsParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationDetailsParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetNotificationDetailsParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetNotificationDetailsParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (!$util.isInteger(message.notificationId) && !(message.notificationId && $util.isInteger(message.notificationId.low) && $util.isInteger(message.notificationId.high)))
                                return "notificationId: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a GetNotificationDetailsParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsParam} GetNotificationDetailsParam
                     */
                    GetNotificationDetailsParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetNotificationDetailsParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetNotificationDetailsParam();
                        if (object.notificationId != null)
                            if ($util.Long)
                                (message.notificationId = $util.Long.fromValue(object.notificationId)).unsigned = false;
                            else if (typeof object.notificationId === "string")
                                message.notificationId = parseInt(object.notificationId, 10);
                            else if (typeof object.notificationId === "number")
                                message.notificationId = object.notificationId;
                            else if (typeof object.notificationId === "object")
                                message.notificationId = new $util.LongBits(object.notificationId.low >>> 0, object.notificationId.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetNotificationDetailsParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @static
                     * @param {theplant.ec.api.instore.GetNotificationDetailsParam} message GetNotificationDetailsParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetNotificationDetailsParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notificationId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notificationId = options.longs === String ? "0" : 0;
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (typeof message.notificationId === "number")
                                object.notificationId = options.longs === String ? String(message.notificationId) : message.notificationId;
                            else
                                object.notificationId = options.longs === String ? $util.Long.prototype.toString.call(message.notificationId) : options.longs === Number ? new $util.LongBits(message.notificationId.low >>> 0, message.notificationId.high >>> 0).toNumber() : message.notificationId;
                        return object;
                    };

                    /**
                     * Converts this GetNotificationDetailsParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetNotificationDetailsParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetNotificationDetailsParam;
                })();

                instore.GetNotificationDetailsResponse = (function() {

                    /**
                     * Properties of a GetNotificationDetailsResponse.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetNotificationDetailsResponse
                     * @property {theplant.ec.service.inventory.IShipFromStoreNotification|null} [notification] GetNotificationDetailsResponse notification
                     * @property {theplant.ec.service.orders.IOrder|null} [order] GetNotificationDetailsResponse order
                     */

                    /**
                     * Constructs a new GetNotificationDetailsResponse.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetNotificationDetailsResponse.
                     * @implements IGetNotificationDetailsResponse
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsResponse=} [properties] Properties to set
                     */
                    function GetNotificationDetailsResponse(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetNotificationDetailsResponse notification.
                     * @member {theplant.ec.service.inventory.IShipFromStoreNotification|null|undefined} notification
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @instance
                     */
                    GetNotificationDetailsResponse.prototype.notification = null;

                    /**
                     * GetNotificationDetailsResponse order.
                     * @member {theplant.ec.service.orders.IOrder|null|undefined} order
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @instance
                     */
                    GetNotificationDetailsResponse.prototype.order = null;

                    /**
                     * Creates a new GetNotificationDetailsResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsResponse} GetNotificationDetailsResponse instance
                     */
                    GetNotificationDetailsResponse.create = function create(properties) {
                        return new GetNotificationDetailsResponse(properties);
                    };

                    /**
                     * Encodes the specified GetNotificationDetailsResponse message. Does not implicitly {@link theplant.ec.api.instore.GetNotificationDetailsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsResponse} message GetNotificationDetailsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationDetailsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notification != null && message.hasOwnProperty("notification"))
                            $root.theplant.ec.service.inventory.ShipFromStoreNotification.encode(message.notification, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.order != null && message.hasOwnProperty("order"))
                            $root.theplant.ec.service.orders.Order.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetNotificationDetailsResponse message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetNotificationDetailsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsResponse} message GetNotificationDetailsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetNotificationDetailsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetNotificationDetailsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsResponse} GetNotificationDetailsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationDetailsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetNotificationDetailsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notification = $root.theplant.ec.service.inventory.ShipFromStoreNotification.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.order = $root.theplant.ec.service.orders.Order.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetNotificationDetailsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsResponse} GetNotificationDetailsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetNotificationDetailsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetNotificationDetailsResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetNotificationDetailsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notification != null && message.hasOwnProperty("notification")) {
                            var error = $root.theplant.ec.service.inventory.ShipFromStoreNotification.verify(message.notification);
                            if (error)
                                return "notification." + error;
                        }
                        if (message.order != null && message.hasOwnProperty("order")) {
                            var error = $root.theplant.ec.service.orders.Order.verify(message.order);
                            if (error)
                                return "order." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a GetNotificationDetailsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetNotificationDetailsResponse} GetNotificationDetailsResponse
                     */
                    GetNotificationDetailsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetNotificationDetailsResponse)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetNotificationDetailsResponse();
                        if (object.notification != null) {
                            if (typeof object.notification !== "object")
                                throw TypeError(".theplant.ec.api.instore.GetNotificationDetailsResponse.notification: object expected");
                            message.notification = $root.theplant.ec.service.inventory.ShipFromStoreNotification.fromObject(object.notification);
                        }
                        if (object.order != null) {
                            if (typeof object.order !== "object")
                                throw TypeError(".theplant.ec.api.instore.GetNotificationDetailsResponse.order: object expected");
                            message.order = $root.theplant.ec.service.orders.Order.fromObject(object.order);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetNotificationDetailsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @static
                     * @param {theplant.ec.api.instore.GetNotificationDetailsResponse} message GetNotificationDetailsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetNotificationDetailsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.notification = null;
                            object.order = null;
                        }
                        if (message.notification != null && message.hasOwnProperty("notification"))
                            object.notification = $root.theplant.ec.service.inventory.ShipFromStoreNotification.toObject(message.notification, options);
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = $root.theplant.ec.service.orders.Order.toObject(message.order, options);
                        return object;
                    };

                    /**
                     * Converts this GetNotificationDetailsResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetNotificationDetailsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetNotificationDetailsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetNotificationDetailsResponse;
                })();

                instore.RefuseNotificationParam = (function() {

                    /**
                     * Properties of a RefuseNotificationParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IRefuseNotificationParam
                     * @property {number|Long|null} [notificationId] RefuseNotificationParam notificationId
                     * @property {string|null} [refuseReason] RefuseNotificationParam refuseReason
                     */

                    /**
                     * Constructs a new RefuseNotificationParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a RefuseNotificationParam.
                     * @implements IRefuseNotificationParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IRefuseNotificationParam=} [properties] Properties to set
                     */
                    function RefuseNotificationParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RefuseNotificationParam notificationId.
                     * @member {number|Long} notificationId
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @instance
                     */
                    RefuseNotificationParam.prototype.notificationId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * RefuseNotificationParam refuseReason.
                     * @member {string} refuseReason
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @instance
                     */
                    RefuseNotificationParam.prototype.refuseReason = "";

                    /**
                     * Creates a new RefuseNotificationParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IRefuseNotificationParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.RefuseNotificationParam} RefuseNotificationParam instance
                     */
                    RefuseNotificationParam.create = function create(properties) {
                        return new RefuseNotificationParam(properties);
                    };

                    /**
                     * Encodes the specified RefuseNotificationParam message. Does not implicitly {@link theplant.ec.api.instore.RefuseNotificationParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IRefuseNotificationParam} message RefuseNotificationParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RefuseNotificationParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.notificationId);
                        if (message.refuseReason != null && message.hasOwnProperty("refuseReason"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.refuseReason);
                        return writer;
                    };

                    /**
                     * Encodes the specified RefuseNotificationParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.RefuseNotificationParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.IRefuseNotificationParam} message RefuseNotificationParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RefuseNotificationParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RefuseNotificationParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.RefuseNotificationParam} RefuseNotificationParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RefuseNotificationParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.RefuseNotificationParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.notificationId = reader.int64();
                                break;
                            case 2:
                                message.refuseReason = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RefuseNotificationParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.RefuseNotificationParam} RefuseNotificationParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RefuseNotificationParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RefuseNotificationParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RefuseNotificationParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (!$util.isInteger(message.notificationId) && !(message.notificationId && $util.isInteger(message.notificationId.low) && $util.isInteger(message.notificationId.high)))
                                return "notificationId: integer|Long expected";
                        if (message.refuseReason != null && message.hasOwnProperty("refuseReason"))
                            if (!$util.isString(message.refuseReason))
                                return "refuseReason: string expected";
                        return null;
                    };

                    /**
                     * Creates a RefuseNotificationParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.RefuseNotificationParam} RefuseNotificationParam
                     */
                    RefuseNotificationParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.RefuseNotificationParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.RefuseNotificationParam();
                        if (object.notificationId != null)
                            if ($util.Long)
                                (message.notificationId = $util.Long.fromValue(object.notificationId)).unsigned = false;
                            else if (typeof object.notificationId === "string")
                                message.notificationId = parseInt(object.notificationId, 10);
                            else if (typeof object.notificationId === "number")
                                message.notificationId = object.notificationId;
                            else if (typeof object.notificationId === "object")
                                message.notificationId = new $util.LongBits(object.notificationId.low >>> 0, object.notificationId.high >>> 0).toNumber();
                        if (object.refuseReason != null)
                            message.refuseReason = String(object.refuseReason);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RefuseNotificationParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @static
                     * @param {theplant.ec.api.instore.RefuseNotificationParam} message RefuseNotificationParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RefuseNotificationParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notificationId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notificationId = options.longs === String ? "0" : 0;
                            object.refuseReason = "";
                        }
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (typeof message.notificationId === "number")
                                object.notificationId = options.longs === String ? String(message.notificationId) : message.notificationId;
                            else
                                object.notificationId = options.longs === String ? $util.Long.prototype.toString.call(message.notificationId) : options.longs === Number ? new $util.LongBits(message.notificationId.low >>> 0, message.notificationId.high >>> 0).toNumber() : message.notificationId;
                        if (message.refuseReason != null && message.hasOwnProperty("refuseReason"))
                            object.refuseReason = message.refuseReason;
                        return object;
                    };

                    /**
                     * Converts this RefuseNotificationParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.RefuseNotificationParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RefuseNotificationParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RefuseNotificationParam;
                })();

                instore.ShipOrderParam = (function() {

                    /**
                     * Properties of a ShipOrderParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IShipOrderParam
                     * @property {string|null} [orderCode] ShipOrderParam orderCode
                     * @property {string|null} [articleCode] ShipOrderParam articleCode
                     * @property {theplant.ec.service.inventory.IShipmentInfo|null} [shipmentInfo] ShipOrderParam shipmentInfo
                     */

                    /**
                     * Constructs a new ShipOrderParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ShipOrderParam.
                     * @implements IShipOrderParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IShipOrderParam=} [properties] Properties to set
                     */
                    function ShipOrderParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShipOrderParam orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @instance
                     */
                    ShipOrderParam.prototype.orderCode = "";

                    /**
                     * ShipOrderParam articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @instance
                     */
                    ShipOrderParam.prototype.articleCode = "";

                    /**
                     * ShipOrderParam shipmentInfo.
                     * @member {theplant.ec.service.inventory.IShipmentInfo|null|undefined} shipmentInfo
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @instance
                     */
                    ShipOrderParam.prototype.shipmentInfo = null;

                    /**
                     * Creates a new ShipOrderParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.IShipOrderParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.ShipOrderParam} ShipOrderParam instance
                     */
                    ShipOrderParam.create = function create(properties) {
                        return new ShipOrderParam(properties);
                    };

                    /**
                     * Encodes the specified ShipOrderParam message. Does not implicitly {@link theplant.ec.api.instore.ShipOrderParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.IShipOrderParam} message ShipOrderParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipOrderParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.shipmentInfo != null && message.hasOwnProperty("shipmentInfo"))
                            $root.theplant.ec.service.inventory.ShipmentInfo.encode(message.shipmentInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShipOrderParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.ShipOrderParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.IShipOrderParam} message ShipOrderParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipOrderParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShipOrderParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.ShipOrderParam} ShipOrderParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipOrderParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.ShipOrderParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.shipmentInfo = $root.theplant.ec.service.inventory.ShipmentInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShipOrderParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.ShipOrderParam} ShipOrderParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipOrderParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShipOrderParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShipOrderParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.shipmentInfo != null && message.hasOwnProperty("shipmentInfo")) {
                            var error = $root.theplant.ec.service.inventory.ShipmentInfo.verify(message.shipmentInfo);
                            if (error)
                                return "shipmentInfo." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ShipOrderParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.ShipOrderParam} ShipOrderParam
                     */
                    ShipOrderParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.ShipOrderParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.ShipOrderParam();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.shipmentInfo != null) {
                            if (typeof object.shipmentInfo !== "object")
                                throw TypeError(".theplant.ec.api.instore.ShipOrderParam.shipmentInfo: object expected");
                            message.shipmentInfo = $root.theplant.ec.service.inventory.ShipmentInfo.fromObject(object.shipmentInfo);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShipOrderParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.ShipOrderParam} message ShipOrderParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShipOrderParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                            object.shipmentInfo = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.shipmentInfo != null && message.hasOwnProperty("shipmentInfo"))
                            object.shipmentInfo = $root.theplant.ec.service.inventory.ShipmentInfo.toObject(message.shipmentInfo, options);
                        return object;
                    };

                    /**
                     * Converts this ShipOrderParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.ShipOrderParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShipOrderParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShipOrderParam;
                })();

                instore.CancelOrderParam = (function() {

                    /**
                     * Properties of a CancelOrderParam.
                     * @memberof theplant.ec.api.instore
                     * @interface ICancelOrderParam
                     * @property {string|null} [orderCode] CancelOrderParam orderCode
                     * @property {string|null} [articleCode] CancelOrderParam articleCode
                     * @property {string|null} [cancelReason] CancelOrderParam cancelReason
                     * @property {boolean|null} [isResendNotification] CancelOrderParam isResendNotification
                     */

                    /**
                     * Constructs a new CancelOrderParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a CancelOrderParam.
                     * @implements ICancelOrderParam
                     * @constructor
                     * @param {theplant.ec.api.instore.ICancelOrderParam=} [properties] Properties to set
                     */
                    function CancelOrderParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CancelOrderParam orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @instance
                     */
                    CancelOrderParam.prototype.orderCode = "";

                    /**
                     * CancelOrderParam articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @instance
                     */
                    CancelOrderParam.prototype.articleCode = "";

                    /**
                     * CancelOrderParam cancelReason.
                     * @member {string} cancelReason
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @instance
                     */
                    CancelOrderParam.prototype.cancelReason = "";

                    /**
                     * CancelOrderParam isResendNotification.
                     * @member {boolean} isResendNotification
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @instance
                     */
                    CancelOrderParam.prototype.isResendNotification = false;

                    /**
                     * Creates a new CancelOrderParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.ICancelOrderParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.CancelOrderParam} CancelOrderParam instance
                     */
                    CancelOrderParam.create = function create(properties) {
                        return new CancelOrderParam(properties);
                    };

                    /**
                     * Encodes the specified CancelOrderParam message. Does not implicitly {@link theplant.ec.api.instore.CancelOrderParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.ICancelOrderParam} message CancelOrderParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CancelOrderParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.cancelReason);
                        if (message.isResendNotification != null && message.hasOwnProperty("isResendNotification"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isResendNotification);
                        return writer;
                    };

                    /**
                     * Encodes the specified CancelOrderParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.CancelOrderParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.ICancelOrderParam} message CancelOrderParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CancelOrderParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CancelOrderParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.CancelOrderParam} CancelOrderParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CancelOrderParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.CancelOrderParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.cancelReason = reader.string();
                                break;
                            case 4:
                                message.isResendNotification = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CancelOrderParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.CancelOrderParam} CancelOrderParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CancelOrderParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CancelOrderParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CancelOrderParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            if (!$util.isString(message.cancelReason))
                                return "cancelReason: string expected";
                        if (message.isResendNotification != null && message.hasOwnProperty("isResendNotification"))
                            if (typeof message.isResendNotification !== "boolean")
                                return "isResendNotification: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a CancelOrderParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.CancelOrderParam} CancelOrderParam
                     */
                    CancelOrderParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.CancelOrderParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.CancelOrderParam();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.cancelReason != null)
                            message.cancelReason = String(object.cancelReason);
                        if (object.isResendNotification != null)
                            message.isResendNotification = Boolean(object.isResendNotification);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CancelOrderParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @static
                     * @param {theplant.ec.api.instore.CancelOrderParam} message CancelOrderParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CancelOrderParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                            object.cancelReason = "";
                            object.isResendNotification = false;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            object.cancelReason = message.cancelReason;
                        if (message.isResendNotification != null && message.hasOwnProperty("isResendNotification"))
                            object.isResendNotification = message.isResendNotification;
                        return object;
                    };

                    /**
                     * Converts this CancelOrderParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.CancelOrderParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CancelOrderParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CancelOrderParam;
                })();

                instore.GetCompleteParam = (function() {

                    /**
                     * Properties of a GetCompleteParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetCompleteParam
                     * @property {Array.<string>|null} [sortParams] GetCompleteParam sortParams
                     * @property {number|Long|null} [page] GetCompleteParam page
                     * @property {number|Long|null} [perPage] GetCompleteParam perPage
                     */

                    /**
                     * Constructs a new GetCompleteParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetCompleteParam.
                     * @implements IGetCompleteParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetCompleteParam=} [properties] Properties to set
                     */
                    function GetCompleteParam(properties) {
                        this.sortParams = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetCompleteParam sortParams.
                     * @member {Array.<string>} sortParams
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @instance
                     */
                    GetCompleteParam.prototype.sortParams = $util.emptyArray;

                    /**
                     * GetCompleteParam page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @instance
                     */
                    GetCompleteParam.prototype.page = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * GetCompleteParam perPage.
                     * @member {number|Long} perPage
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @instance
                     */
                    GetCompleteParam.prototype.perPage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new GetCompleteParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetCompleteParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetCompleteParam} GetCompleteParam instance
                     */
                    GetCompleteParam.create = function create(properties) {
                        return new GetCompleteParam(properties);
                    };

                    /**
                     * Encodes the specified GetCompleteParam message. Does not implicitly {@link theplant.ec.api.instore.GetCompleteParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetCompleteParam} message GetCompleteParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompleteParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sortParams != null && message.sortParams.length)
                            for (var i = 0; i < message.sortParams.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sortParams[i]);
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.page);
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.perPage);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetCompleteParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetCompleteParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetCompleteParam} message GetCompleteParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCompleteParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetCompleteParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetCompleteParam} GetCompleteParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompleteParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetCompleteParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sortParams && message.sortParams.length))
                                    message.sortParams = [];
                                message.sortParams.push(reader.string());
                                break;
                            case 2:
                                message.page = reader.int64();
                                break;
                            case 3:
                                message.perPage = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetCompleteParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetCompleteParam} GetCompleteParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCompleteParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetCompleteParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCompleteParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sortParams != null && message.hasOwnProperty("sortParams")) {
                            if (!Array.isArray(message.sortParams))
                                return "sortParams: array expected";
                            for (var i = 0; i < message.sortParams.length; ++i)
                                if (!$util.isString(message.sortParams[i]))
                                    return "sortParams: string[] expected";
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (!$util.isInteger(message.perPage) && !(message.perPage && $util.isInteger(message.perPage.low) && $util.isInteger(message.perPage.high)))
                                return "perPage: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a GetCompleteParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetCompleteParam} GetCompleteParam
                     */
                    GetCompleteParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetCompleteParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetCompleteParam();
                        if (object.sortParams) {
                            if (!Array.isArray(object.sortParams))
                                throw TypeError(".theplant.ec.api.instore.GetCompleteParam.sortParams: array expected");
                            message.sortParams = [];
                            for (var i = 0; i < object.sortParams.length; ++i)
                                message.sortParams[i] = String(object.sortParams[i]);
                        }
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = false;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber();
                        if (object.perPage != null)
                            if ($util.Long)
                                (message.perPage = $util.Long.fromValue(object.perPage)).unsigned = false;
                            else if (typeof object.perPage === "string")
                                message.perPage = parseInt(object.perPage, 10);
                            else if (typeof object.perPage === "number")
                                message.perPage = object.perPage;
                            else if (typeof object.perPage === "object")
                                message.perPage = new $util.LongBits(object.perPage.low >>> 0, object.perPage.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetCompleteParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @static
                     * @param {theplant.ec.api.instore.GetCompleteParam} message GetCompleteParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCompleteParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sortParams = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.perPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.perPage = options.longs === String ? "0" : 0;
                        }
                        if (message.sortParams && message.sortParams.length) {
                            object.sortParams = [];
                            for (var j = 0; j < message.sortParams.length; ++j)
                                object.sortParams[j] = message.sortParams[j];
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber() : message.page;
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (typeof message.perPage === "number")
                                object.perPage = options.longs === String ? String(message.perPage) : message.perPage;
                            else
                                object.perPage = options.longs === String ? $util.Long.prototype.toString.call(message.perPage) : options.longs === Number ? new $util.LongBits(message.perPage.low >>> 0, message.perPage.high >>> 0).toNumber() : message.perPage;
                        return object;
                    };

                    /**
                     * Converts this GetCompleteParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetCompleteParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCompleteParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetCompleteParam;
                })();

                instore.GetShipFromStoreOrdersParam = (function() {

                    /**
                     * Properties of a GetShipFromStoreOrdersParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetShipFromStoreOrdersParam
                     * @property {Array.<string>|null} [sortParams] GetShipFromStoreOrdersParam sortParams
                     */

                    /**
                     * Constructs a new GetShipFromStoreOrdersParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetShipFromStoreOrdersParam.
                     * @implements IGetShipFromStoreOrdersParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetShipFromStoreOrdersParam=} [properties] Properties to set
                     */
                    function GetShipFromStoreOrdersParam(properties) {
                        this.sortParams = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetShipFromStoreOrdersParam sortParams.
                     * @member {Array.<string>} sortParams
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @instance
                     */
                    GetShipFromStoreOrdersParam.prototype.sortParams = $util.emptyArray;

                    /**
                     * Creates a new GetShipFromStoreOrdersParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetShipFromStoreOrdersParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetShipFromStoreOrdersParam} GetShipFromStoreOrdersParam instance
                     */
                    GetShipFromStoreOrdersParam.create = function create(properties) {
                        return new GetShipFromStoreOrdersParam(properties);
                    };

                    /**
                     * Encodes the specified GetShipFromStoreOrdersParam message. Does not implicitly {@link theplant.ec.api.instore.GetShipFromStoreOrdersParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetShipFromStoreOrdersParam} message GetShipFromStoreOrdersParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShipFromStoreOrdersParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sortParams != null && message.sortParams.length)
                            for (var i = 0; i < message.sortParams.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sortParams[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetShipFromStoreOrdersParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetShipFromStoreOrdersParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetShipFromStoreOrdersParam} message GetShipFromStoreOrdersParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetShipFromStoreOrdersParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetShipFromStoreOrdersParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetShipFromStoreOrdersParam} GetShipFromStoreOrdersParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShipFromStoreOrdersParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetShipFromStoreOrdersParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.sortParams && message.sortParams.length))
                                    message.sortParams = [];
                                message.sortParams.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetShipFromStoreOrdersParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetShipFromStoreOrdersParam} GetShipFromStoreOrdersParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetShipFromStoreOrdersParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetShipFromStoreOrdersParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetShipFromStoreOrdersParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sortParams != null && message.hasOwnProperty("sortParams")) {
                            if (!Array.isArray(message.sortParams))
                                return "sortParams: array expected";
                            for (var i = 0; i < message.sortParams.length; ++i)
                                if (!$util.isString(message.sortParams[i]))
                                    return "sortParams: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a GetShipFromStoreOrdersParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetShipFromStoreOrdersParam} GetShipFromStoreOrdersParam
                     */
                    GetShipFromStoreOrdersParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetShipFromStoreOrdersParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetShipFromStoreOrdersParam();
                        if (object.sortParams) {
                            if (!Array.isArray(object.sortParams))
                                throw TypeError(".theplant.ec.api.instore.GetShipFromStoreOrdersParam.sortParams: array expected");
                            message.sortParams = [];
                            for (var i = 0; i < object.sortParams.length; ++i)
                                message.sortParams[i] = String(object.sortParams[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetShipFromStoreOrdersParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @static
                     * @param {theplant.ec.api.instore.GetShipFromStoreOrdersParam} message GetShipFromStoreOrdersParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetShipFromStoreOrdersParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.sortParams = [];
                        if (message.sortParams && message.sortParams.length) {
                            object.sortParams = [];
                            for (var j = 0; j < message.sortParams.length; ++j)
                                object.sortParams[j] = message.sortParams[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this GetShipFromStoreOrdersParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetShipFromStoreOrdersParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetShipFromStoreOrdersParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetShipFromStoreOrdersParam;
                })();

                instore.GetStoreMonthlyHistoryListParam = (function() {

                    /**
                     * Properties of a GetStoreMonthlyHistoryListParam.
                     * @memberof theplant.ec.api.instore
                     * @interface IGetStoreMonthlyHistoryListParam
                     * @property {number|Long|null} [page] GetStoreMonthlyHistoryListParam page
                     * @property {number|Long|null} [perPage] GetStoreMonthlyHistoryListParam perPage
                     */

                    /**
                     * Constructs a new GetStoreMonthlyHistoryListParam.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a GetStoreMonthlyHistoryListParam.
                     * @implements IGetStoreMonthlyHistoryListParam
                     * @constructor
                     * @param {theplant.ec.api.instore.IGetStoreMonthlyHistoryListParam=} [properties] Properties to set
                     */
                    function GetStoreMonthlyHistoryListParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetStoreMonthlyHistoryListParam page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @instance
                     */
                    GetStoreMonthlyHistoryListParam.prototype.page = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * GetStoreMonthlyHistoryListParam perPage.
                     * @member {number|Long} perPage
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @instance
                     */
                    GetStoreMonthlyHistoryListParam.prototype.perPage = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new GetStoreMonthlyHistoryListParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetStoreMonthlyHistoryListParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.instore.GetStoreMonthlyHistoryListParam} GetStoreMonthlyHistoryListParam instance
                     */
                    GetStoreMonthlyHistoryListParam.create = function create(properties) {
                        return new GetStoreMonthlyHistoryListParam(properties);
                    };

                    /**
                     * Encodes the specified GetStoreMonthlyHistoryListParam message. Does not implicitly {@link theplant.ec.api.instore.GetStoreMonthlyHistoryListParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetStoreMonthlyHistoryListParam} message GetStoreMonthlyHistoryListParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreMonthlyHistoryListParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.page);
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.perPage);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetStoreMonthlyHistoryListParam message, length delimited. Does not implicitly {@link theplant.ec.api.instore.GetStoreMonthlyHistoryListParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {theplant.ec.api.instore.IGetStoreMonthlyHistoryListParam} message GetStoreMonthlyHistoryListParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetStoreMonthlyHistoryListParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetStoreMonthlyHistoryListParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.instore.GetStoreMonthlyHistoryListParam} GetStoreMonthlyHistoryListParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreMonthlyHistoryListParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.instore.GetStoreMonthlyHistoryListParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.page = reader.uint64();
                                break;
                            case 2:
                                message.perPage = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetStoreMonthlyHistoryListParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.instore.GetStoreMonthlyHistoryListParam} GetStoreMonthlyHistoryListParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetStoreMonthlyHistoryListParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetStoreMonthlyHistoryListParam message.
                     * @function verify
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetStoreMonthlyHistoryListParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (!$util.isInteger(message.perPage) && !(message.perPage && $util.isInteger(message.perPage.low) && $util.isInteger(message.perPage.high)))
                                return "perPage: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a GetStoreMonthlyHistoryListParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.instore.GetStoreMonthlyHistoryListParam} GetStoreMonthlyHistoryListParam
                     */
                    GetStoreMonthlyHistoryListParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.instore.GetStoreMonthlyHistoryListParam)
                            return object;
                        var message = new $root.theplant.ec.api.instore.GetStoreMonthlyHistoryListParam();
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = true;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber(true);
                        if (object.perPage != null)
                            if ($util.Long)
                                (message.perPage = $util.Long.fromValue(object.perPage)).unsigned = true;
                            else if (typeof object.perPage === "string")
                                message.perPage = parseInt(object.perPage, 10);
                            else if (typeof object.perPage === "number")
                                message.perPage = object.perPage;
                            else if (typeof object.perPage === "object")
                                message.perPage = new $util.LongBits(object.perPage.low >>> 0, object.perPage.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetStoreMonthlyHistoryListParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @static
                     * @param {theplant.ec.api.instore.GetStoreMonthlyHistoryListParam} message GetStoreMonthlyHistoryListParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetStoreMonthlyHistoryListParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.perPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.perPage = options.longs === String ? "0" : 0;
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber(true) : message.page;
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (typeof message.perPage === "number")
                                object.perPage = options.longs === String ? String(message.perPage) : message.perPage;
                            else
                                object.perPage = options.longs === String ? $util.Long.prototype.toString.call(message.perPage) : options.longs === Number ? new $util.LongBits(message.perPage.low >>> 0, message.perPage.high >>> 0).toNumber(true) : message.perPage;
                        return object;
                    };

                    /**
                     * Converts this GetStoreMonthlyHistoryListParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.instore.GetStoreMonthlyHistoryListParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetStoreMonthlyHistoryListParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetStoreMonthlyHistoryListParam;
                })();

                instore.ShipFromStoreService = (function() {

                    /**
                     * Constructs a new ShipFromStoreService service.
                     * @memberof theplant.ec.api.instore
                     * @classdesc Represents a ShipFromStoreService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ShipFromStoreService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ShipFromStoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ShipFromStoreService;

                    /**
                     * Creates new ShipFromStoreService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ShipFromStoreService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ShipFromStoreService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getShipFromStoreOrders}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetShipFromStoreOrdersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.GetResponse} [response] GetResponse
                     */

                    /**
                     * Calls GetShipFromStoreOrders.
                     * @function getShipFromStoreOrders
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetShipFromStoreOrdersParam} request GetShipFromStoreOrdersParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetShipFromStoreOrdersCallback} callback Node-style callback called with the error, if any, and GetResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getShipFromStoreOrders = function getShipFromStoreOrders(request, callback) {
                        return this.rpcCall(getShipFromStoreOrders, $root.theplant.ec.api.instore.GetShipFromStoreOrdersParam, $root.theplant.ec.api.instore.GetResponse, request, callback);
                    }, "name", { value: "GetShipFromStoreOrders" });

                    /**
                     * Calls GetShipFromStoreOrders.
                     * @function getShipFromStoreOrders
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetShipFromStoreOrdersParam} request GetShipFromStoreOrdersParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.GetResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getShipFromStoreOrderDetails}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetShipFromStoreOrderDetailsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.ShipFromStoreDetailsResponse} [response] ShipFromStoreDetailsResponse
                     */

                    /**
                     * Calls GetShipFromStoreOrderDetails.
                     * @function getShipFromStoreOrderDetails
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetShipFromStoreDetailsParam} request GetShipFromStoreDetailsParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetShipFromStoreOrderDetailsCallback} callback Node-style callback called with the error, if any, and ShipFromStoreDetailsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getShipFromStoreOrderDetails = function getShipFromStoreOrderDetails(request, callback) {
                        return this.rpcCall(getShipFromStoreOrderDetails, $root.theplant.ec.api.instore.GetShipFromStoreDetailsParam, $root.theplant.ec.api.instore.ShipFromStoreDetailsResponse, request, callback);
                    }, "name", { value: "GetShipFromStoreOrderDetails" });

                    /**
                     * Calls GetShipFromStoreOrderDetails.
                     * @function getShipFromStoreOrderDetails
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetShipFromStoreDetailsParam} request GetShipFromStoreDetailsParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.ShipFromStoreDetailsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#shippingShipFromStoreOrder}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef ShippingShipFromStoreOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.ShipFromStoreDetailsResponse} [response] ShipFromStoreDetailsResponse
                     */

                    /**
                     * Calls ShippingShipFromStoreOrder.
                     * @function shippingShipFromStoreOrder
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IShipOrderParam} request ShipOrderParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.ShippingShipFromStoreOrderCallback} callback Node-style callback called with the error, if any, and ShipFromStoreDetailsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.shippingShipFromStoreOrder = function shippingShipFromStoreOrder(request, callback) {
                        return this.rpcCall(shippingShipFromStoreOrder, $root.theplant.ec.api.instore.ShipOrderParam, $root.theplant.ec.api.instore.ShipFromStoreDetailsResponse, request, callback);
                    }, "name", { value: "ShippingShipFromStoreOrder" });

                    /**
                     * Calls ShippingShipFromStoreOrder.
                     * @function shippingShipFromStoreOrder
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IShipOrderParam} request ShipOrderParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.ShipFromStoreDetailsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#cancelShipFromStoreOrder}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef CancelShipFromStoreOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.ShipFromStoreDetailsResponse} [response] ShipFromStoreDetailsResponse
                     */

                    /**
                     * Calls CancelShipFromStoreOrder.
                     * @function cancelShipFromStoreOrder
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.ICancelOrderParam} request CancelOrderParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.CancelShipFromStoreOrderCallback} callback Node-style callback called with the error, if any, and ShipFromStoreDetailsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.cancelShipFromStoreOrder = function cancelShipFromStoreOrder(request, callback) {
                        return this.rpcCall(cancelShipFromStoreOrder, $root.theplant.ec.api.instore.CancelOrderParam, $root.theplant.ec.api.instore.ShipFromStoreDetailsResponse, request, callback);
                    }, "name", { value: "CancelShipFromStoreOrder" });

                    /**
                     * Calls CancelShipFromStoreOrder.
                     * @function cancelShipFromStoreOrder
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.ICancelOrderParam} request CancelOrderParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.ShipFromStoreDetailsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getCompleteShipFromStoreOrders}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetCompleteShipFromStoreOrdersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.GetResponse} [response] GetResponse
                     */

                    /**
                     * Calls GetCompleteShipFromStoreOrders.
                     * @function getCompleteShipFromStoreOrders
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetCompleteParam} request GetCompleteParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetCompleteShipFromStoreOrdersCallback} callback Node-style callback called with the error, if any, and GetResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getCompleteShipFromStoreOrders = function getCompleteShipFromStoreOrders(request, callback) {
                        return this.rpcCall(getCompleteShipFromStoreOrders, $root.theplant.ec.api.instore.GetCompleteParam, $root.theplant.ec.api.instore.GetResponse, request, callback);
                    }, "name", { value: "GetCompleteShipFromStoreOrders" });

                    /**
                     * Calls GetCompleteShipFromStoreOrders.
                     * @function getCompleteShipFromStoreOrders
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetCompleteParam} request GetCompleteParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.GetResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getShipFromStoreNotifications}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetShipFromStoreNotificationsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.GetNotificationsResponse} [response] GetNotificationsResponse
                     */

                    /**
                     * Calls GetShipFromStoreNotifications.
                     * @function getShipFromStoreNotifications
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetNotificationParam} request GetNotificationParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetShipFromStoreNotificationsCallback} callback Node-style callback called with the error, if any, and GetNotificationsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getShipFromStoreNotifications = function getShipFromStoreNotifications(request, callback) {
                        return this.rpcCall(getShipFromStoreNotifications, $root.theplant.ec.api.instore.GetNotificationParam, $root.theplant.ec.api.instore.GetNotificationsResponse, request, callback);
                    }, "name", { value: "GetShipFromStoreNotifications" });

                    /**
                     * Calls GetShipFromStoreNotifications.
                     * @function getShipFromStoreNotifications
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetNotificationParam} request GetNotificationParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.GetNotificationsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#acceptNotification}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef AcceptNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.GetNotificationDetailsResponse} [response] GetNotificationDetailsResponse
                     */

                    /**
                     * Calls AcceptNotification.
                     * @function acceptNotification
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IAcceptNotificationParam} request AcceptNotificationParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.AcceptNotificationCallback} callback Node-style callback called with the error, if any, and GetNotificationDetailsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.acceptNotification = function acceptNotification(request, callback) {
                        return this.rpcCall(acceptNotification, $root.theplant.ec.api.instore.AcceptNotificationParam, $root.theplant.ec.api.instore.GetNotificationDetailsResponse, request, callback);
                    }, "name", { value: "AcceptNotification" });

                    /**
                     * Calls AcceptNotification.
                     * @function acceptNotification
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IAcceptNotificationParam} request AcceptNotificationParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.GetNotificationDetailsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getShipFromStoreNotificationDetails}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetShipFromStoreNotificationDetailsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.GetNotificationDetailsResponse} [response] GetNotificationDetailsResponse
                     */

                    /**
                     * Calls GetShipFromStoreNotificationDetails.
                     * @function getShipFromStoreNotificationDetails
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsParam} request GetNotificationDetailsParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetShipFromStoreNotificationDetailsCallback} callback Node-style callback called with the error, if any, and GetNotificationDetailsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getShipFromStoreNotificationDetails = function getShipFromStoreNotificationDetails(request, callback) {
                        return this.rpcCall(getShipFromStoreNotificationDetails, $root.theplant.ec.api.instore.GetNotificationDetailsParam, $root.theplant.ec.api.instore.GetNotificationDetailsResponse, request, callback);
                    }, "name", { value: "GetShipFromStoreNotificationDetails" });

                    /**
                     * Calls GetShipFromStoreNotificationDetails.
                     * @function getShipFromStoreNotificationDetails
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetNotificationDetailsParam} request GetNotificationDetailsParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.GetNotificationDetailsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#refuseNotification}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef RefuseNotificationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.instore.GetNotificationDetailsResponse} [response] GetNotificationDetailsResponse
                     */

                    /**
                     * Calls RefuseNotification.
                     * @function refuseNotification
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IRefuseNotificationParam} request RefuseNotificationParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.RefuseNotificationCallback} callback Node-style callback called with the error, if any, and GetNotificationDetailsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.refuseNotification = function refuseNotification(request, callback) {
                        return this.rpcCall(refuseNotification, $root.theplant.ec.api.instore.RefuseNotificationParam, $root.theplant.ec.api.instore.GetNotificationDetailsResponse, request, callback);
                    }, "name", { value: "RefuseNotification" });

                    /**
                     * Calls RefuseNotification.
                     * @function refuseNotification
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IRefuseNotificationParam} request RefuseNotificationParam message or plain object
                     * @returns {Promise<theplant.ec.api.instore.GetNotificationDetailsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getStoreCurrentMonthlyData}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetStoreCurrentMonthlyDataCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.inventory.MonthData} [response] MonthData
                     */

                    /**
                     * Calls GetStoreCurrentMonthlyData.
                     * @function getStoreCurrentMonthlyData
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetStoreCurrentMonthlyDataCallback} callback Node-style callback called with the error, if any, and MonthData
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getStoreCurrentMonthlyData = function getStoreCurrentMonthlyData(request, callback) {
                        return this.rpcCall(getStoreCurrentMonthlyData, $root.theplant.ec.api.Empty, $root.theplant.ec.service.inventory.MonthData, request, callback);
                    }, "name", { value: "GetStoreCurrentMonthlyData" });

                    /**
                     * Calls GetStoreCurrentMonthlyData.
                     * @function getStoreCurrentMonthlyData
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.inventory.MonthData>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.instore.ShipFromStoreService#getStoreMonthlyHistoryList}.
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @typedef GetStoreMonthlyHistoryListCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.inventory.HistoryList} [response] HistoryList
                     */

                    /**
                     * Calls GetStoreMonthlyHistoryList.
                     * @function getStoreMonthlyHistoryList
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetStoreMonthlyHistoryListParam} request GetStoreMonthlyHistoryListParam message or plain object
                     * @param {theplant.ec.api.instore.ShipFromStoreService.GetStoreMonthlyHistoryListCallback} callback Node-style callback called with the error, if any, and HistoryList
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getStoreMonthlyHistoryList = function getStoreMonthlyHistoryList(request, callback) {
                        return this.rpcCall(getStoreMonthlyHistoryList, $root.theplant.ec.api.instore.GetStoreMonthlyHistoryListParam, $root.theplant.ec.service.inventory.HistoryList, request, callback);
                    }, "name", { value: "GetStoreMonthlyHistoryList" });

                    /**
                     * Calls GetStoreMonthlyHistoryList.
                     * @function getStoreMonthlyHistoryList
                     * @memberof theplant.ec.api.instore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.instore.IGetStoreMonthlyHistoryListParam} request GetStoreMonthlyHistoryListParam message or plain object
                     * @returns {Promise<theplant.ec.service.inventory.HistoryList>} Promise
                     * @variation 2
                     */

                    return ShipFromStoreService;
                })();

                return instore;
            })();

            api.menu = (function() {

                /**
                 * Namespace menu.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var menu = {};

                menu.MenuItems = (function() {

                    /**
                     * Properties of a MenuItems.
                     * @memberof theplant.ec.api.menu
                     * @interface IMenuItems
                     * @property {Array.<theplant.ec.service.menu.IMenuItem>|null} [menuItems] MenuItems menuItems
                     */

                    /**
                     * Constructs a new MenuItems.
                     * @memberof theplant.ec.api.menu
                     * @classdesc Represents a MenuItems.
                     * @implements IMenuItems
                     * @constructor
                     * @param {theplant.ec.api.menu.IMenuItems=} [properties] Properties to set
                     */
                    function MenuItems(properties) {
                        this.menuItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MenuItems menuItems.
                     * @member {Array.<theplant.ec.service.menu.IMenuItem>} menuItems
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @instance
                     */
                    MenuItems.prototype.menuItems = $util.emptyArray;

                    /**
                     * Creates a new MenuItems instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {theplant.ec.api.menu.IMenuItems=} [properties] Properties to set
                     * @returns {theplant.ec.api.menu.MenuItems} MenuItems instance
                     */
                    MenuItems.create = function create(properties) {
                        return new MenuItems(properties);
                    };

                    /**
                     * Encodes the specified MenuItems message. Does not implicitly {@link theplant.ec.api.menu.MenuItems.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {theplant.ec.api.menu.IMenuItems} message MenuItems message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MenuItems.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.menuItems != null && message.menuItems.length)
                            for (var i = 0; i < message.menuItems.length; ++i)
                                $root.theplant.ec.service.menu.MenuItem.encode(message.menuItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified MenuItems message, length delimited. Does not implicitly {@link theplant.ec.api.menu.MenuItems.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {theplant.ec.api.menu.IMenuItems} message MenuItems message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MenuItems.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MenuItems message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.menu.MenuItems} MenuItems
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MenuItems.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.menu.MenuItems();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.menuItems && message.menuItems.length))
                                    message.menuItems = [];
                                message.menuItems.push($root.theplant.ec.service.menu.MenuItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MenuItems message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.menu.MenuItems} MenuItems
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MenuItems.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MenuItems message.
                     * @function verify
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MenuItems.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.menuItems != null && message.hasOwnProperty("menuItems")) {
                            if (!Array.isArray(message.menuItems))
                                return "menuItems: array expected";
                            for (var i = 0; i < message.menuItems.length; ++i) {
                                var error = $root.theplant.ec.service.menu.MenuItem.verify(message.menuItems[i]);
                                if (error)
                                    return "menuItems." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a MenuItems message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.menu.MenuItems} MenuItems
                     */
                    MenuItems.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.menu.MenuItems)
                            return object;
                        var message = new $root.theplant.ec.api.menu.MenuItems();
                        if (object.menuItems) {
                            if (!Array.isArray(object.menuItems))
                                throw TypeError(".theplant.ec.api.menu.MenuItems.menuItems: array expected");
                            message.menuItems = [];
                            for (var i = 0; i < object.menuItems.length; ++i) {
                                if (typeof object.menuItems[i] !== "object")
                                    throw TypeError(".theplant.ec.api.menu.MenuItems.menuItems: object expected");
                                message.menuItems[i] = $root.theplant.ec.service.menu.MenuItem.fromObject(object.menuItems[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a MenuItems message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @static
                     * @param {theplant.ec.api.menu.MenuItems} message MenuItems
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MenuItems.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.menuItems = [];
                        if (message.menuItems && message.menuItems.length) {
                            object.menuItems = [];
                            for (var j = 0; j < message.menuItems.length; ++j)
                                object.menuItems[j] = $root.theplant.ec.service.menu.MenuItem.toObject(message.menuItems[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this MenuItems to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.menu.MenuItems
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MenuItems.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MenuItems;
                })();

                menu.MenuService = (function() {

                    /**
                     * Constructs a new MenuService service.
                     * @memberof theplant.ec.api.menu
                     * @classdesc Represents a MenuService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function MenuService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (MenuService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MenuService;

                    /**
                     * Creates new MenuService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.menu.MenuService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {MenuService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    MenuService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.menu.MenuService#getMenuItems}.
                     * @memberof theplant.ec.api.menu.MenuService
                     * @typedef GetMenuItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.menu.MenuItems} [response] MenuItems
                     */

                    /**
                     * Calls GetMenuItems.
                     * @function getMenuItems
                     * @memberof theplant.ec.api.menu.MenuService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.menu.MenuService.GetMenuItemsCallback} callback Node-style callback called with the error, if any, and MenuItems
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MenuService.prototype.getMenuItems = function getMenuItems(request, callback) {
                        return this.rpcCall(getMenuItems, $root.theplant.ec.api.Empty, $root.theplant.ec.api.menu.MenuItems, request, callback);
                    }, "name", { value: "GetMenuItems" });

                    /**
                     * Calls GetMenuItems.
                     * @function getMenuItems
                     * @memberof theplant.ec.api.menu.MenuService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.menu.MenuItems>} Promise
                     * @variation 2
                     */

                    return MenuService;
                })();

                return menu;
            })();

            api.orders = (function() {

                /**
                 * Namespace orders.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var orders = {};

                orders.CountInput = (function() {

                    /**
                     * Properties of a CountInput.
                     * @memberof theplant.ec.api.orders
                     * @interface ICountInput
                     * @property {string|null} [articleCode] CountInput articleCode
                     * @property {number|Long|null} [quantity] CountInput quantity
                     * @property {string|null} [customizationSession] CountInput customizationSession
                     */

                    /**
                     * Constructs a new CountInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CountInput.
                     * @implements ICountInput
                     * @constructor
                     * @param {theplant.ec.api.orders.ICountInput=} [properties] Properties to set
                     */
                    function CountInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CountInput articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.orders.CountInput
                     * @instance
                     */
                    CountInput.prototype.articleCode = "";

                    /**
                     * CountInput quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.api.orders.CountInput
                     * @instance
                     */
                    CountInput.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CountInput customizationSession.
                     * @member {string} customizationSession
                     * @memberof theplant.ec.api.orders.CountInput
                     * @instance
                     */
                    CountInput.prototype.customizationSession = "";

                    /**
                     * Creates a new CountInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {theplant.ec.api.orders.ICountInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.CountInput} CountInput instance
                     */
                    CountInput.create = function create(properties) {
                        return new CountInput(properties);
                    };

                    /**
                     * Encodes the specified CountInput message. Does not implicitly {@link theplant.ec.api.orders.CountInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {theplant.ec.api.orders.ICountInput} message CountInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CountInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.quantity);
                        if (message.customizationSession != null && message.hasOwnProperty("customizationSession"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.customizationSession);
                        return writer;
                    };

                    /**
                     * Encodes the specified CountInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.CountInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {theplant.ec.api.orders.ICountInput} message CountInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CountInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CountInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.CountInput} CountInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CountInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.CountInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.quantity = reader.int64();
                                break;
                            case 3:
                                message.customizationSession = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CountInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.CountInput} CountInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CountInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CountInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CountInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.customizationSession != null && message.hasOwnProperty("customizationSession"))
                            if (!$util.isString(message.customizationSession))
                                return "customizationSession: string expected";
                        return null;
                    };

                    /**
                     * Creates a CountInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.CountInput} CountInput
                     */
                    CountInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.CountInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.CountInput();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        if (object.customizationSession != null)
                            message.customizationSession = String(object.customizationSession);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CountInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.CountInput
                     * @static
                     * @param {theplant.ec.api.orders.CountInput} message CountInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CountInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            object.customizationSession = "";
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        if (message.customizationSession != null && message.hasOwnProperty("customizationSession"))
                            object.customizationSession = message.customizationSession;
                        return object;
                    };

                    /**
                     * Converts this CountInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.CountInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CountInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CountInput;
                })();

                orders.ArticleCode = (function() {

                    /**
                     * Properties of an ArticleCode.
                     * @memberof theplant.ec.api.orders
                     * @interface IArticleCode
                     * @property {string|null} [code] ArticleCode code
                     * @property {theplant.ec.service.customization.ICustomization|null} [customization] ArticleCode customization
                     */

                    /**
                     * Constructs a new ArticleCode.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents an ArticleCode.
                     * @implements IArticleCode
                     * @constructor
                     * @param {theplant.ec.api.orders.IArticleCode=} [properties] Properties to set
                     */
                    function ArticleCode(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ArticleCode code.
                     * @member {string} code
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @instance
                     */
                    ArticleCode.prototype.code = "";

                    /**
                     * ArticleCode customization.
                     * @member {theplant.ec.service.customization.ICustomization|null|undefined} customization
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @instance
                     */
                    ArticleCode.prototype.customization = null;

                    /**
                     * Creates a new ArticleCode instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {theplant.ec.api.orders.IArticleCode=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.ArticleCode} ArticleCode instance
                     */
                    ArticleCode.create = function create(properties) {
                        return new ArticleCode(properties);
                    };

                    /**
                     * Encodes the specified ArticleCode message. Does not implicitly {@link theplant.ec.api.orders.ArticleCode.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {theplant.ec.api.orders.IArticleCode} message ArticleCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleCode.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            $root.theplant.ec.service.customization.Customization.encode(message.customization, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ArticleCode message, length delimited. Does not implicitly {@link theplant.ec.api.orders.ArticleCode.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {theplant.ec.api.orders.IArticleCode} message ArticleCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleCode.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ArticleCode message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.ArticleCode} ArticleCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleCode.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.ArticleCode();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.string();
                                break;
                            case 2:
                                message.customization = $root.theplant.ec.service.customization.Customization.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ArticleCode message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.ArticleCode} ArticleCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleCode.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ArticleCode message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ArticleCode.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.customization != null && message.hasOwnProperty("customization")) {
                            var error = $root.theplant.ec.service.customization.Customization.verify(message.customization);
                            if (error)
                                return "customization." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an ArticleCode message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.ArticleCode} ArticleCode
                     */
                    ArticleCode.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.ArticleCode)
                            return object;
                        var message = new $root.theplant.ec.api.orders.ArticleCode();
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.customization != null) {
                            if (typeof object.customization !== "object")
                                throw TypeError(".theplant.ec.api.orders.ArticleCode.customization: object expected");
                            message.customization = $root.theplant.ec.service.customization.Customization.fromObject(object.customization);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ArticleCode message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @static
                     * @param {theplant.ec.api.orders.ArticleCode} message ArticleCode
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ArticleCode.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.code = "";
                            object.customization = null;
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            object.customization = $root.theplant.ec.service.customization.Customization.toObject(message.customization, options);
                        return object;
                    };

                    /**
                     * Converts this ArticleCode to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.ArticleCode
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ArticleCode.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ArticleCode;
                })();

                orders.CheckoutInput = (function() {

                    /**
                     * Properties of a CheckoutInput.
                     * @memberof theplant.ec.api.orders
                     * @interface ICheckoutInput
                     * @property {string|null} [email] CheckoutInput email
                     * @property {boolean|null} [isSubscribed] CheckoutInput isSubscribed
                     * @property {theplant.ec.service.users.IAddress|null} [shippingAddress] CheckoutInput shippingAddress
                     * @property {boolean|null} [specifyBillingAddress] CheckoutInput specifyBillingAddress
                     * @property {theplant.ec.service.users.IAddress|null} [billingAddress] CheckoutInput billingAddress
                     * @property {theplant.ec.service.orders.PaymentType|null} [paymentType] CheckoutInput paymentType
                     * @property {string|null} [creditCardId] CheckoutInput creditCardId
                     * @property {boolean|null} [isTermsAgreed] CheckoutInput isTermsAgreed
                     * @property {theplant.ec.service.orders.ICreditCardInput|null} [creditCardInput] CheckoutInput creditCardInput
                     * @property {theplant.ec.service.orders.IAmazonPayInput|null} [amazonPayInput] CheckoutInput amazonPayInput
                     * @property {theplant.ec.service.orders.IGiftWrapping|null} [giftWrapping] CheckoutInput giftWrapping
                     * @property {string|null} [password] CheckoutInput password
                     * @property {string|null} [dateOfBirth] CheckoutInput dateOfBirth
                     * @property {theplant.ec.service.users.UserGender|null} [gender] CheckoutInput gender
                     * @property {string|null} [couponCode] CheckoutInput couponCode
                     * @property {number|Long|null} [points] CheckoutInput points
                     * @property {boolean|null} [getUserPoints] CheckoutInput getUserPoints
                     * @property {theplant.ec.api.orders.DeliveryMethod|null} [deliveryMethod] CheckoutInput deliveryMethod
                     * @property {string|null} [storeId] CheckoutInput storeId
                     * @property {theplant.ec.service.orders.IDeliveryInfo|null} [deliveryInfo] CheckoutInput deliveryInfo
                     * @property {boolean|null} [isSaveCreditCard] CheckoutInput isSaveCreditCard
                     * @property {boolean|null} [isSubscribedSms] CheckoutInput isSubscribedSms
                     * @property {string|null} [shippingAddressId] CheckoutInput shippingAddressId
                     * @property {string|null} [billingAddressId] CheckoutInput billingAddressId
                     * @property {theplant.ec.service.convenience_pickup.IStoreInfo|null} [storeInfo] CheckoutInput storeInfo
                     * @property {string|null} [opluxDeviceInfo] CheckoutInput opluxDeviceInfo
                     * @property {string|null} [creditCardCvc] CheckoutInput creditCardCvc
                     * @property {theplant.ec.api.orders.IRakutenANDPayPayInput|null} [rakutenAndPayPayInput] CheckoutInput rakutenAndPayPayInput
                     * @property {theplant.ec.api.orders.ISmartPayInput|null} [smartPayInput] CheckoutInput smartPayInput
                     */

                    /**
                     * Constructs a new CheckoutInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CheckoutInput.
                     * @implements ICheckoutInput
                     * @constructor
                     * @param {theplant.ec.api.orders.ICheckoutInput=} [properties] Properties to set
                     */
                    function CheckoutInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CheckoutInput email.
                     * @member {string} email
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.email = "";

                    /**
                     * CheckoutInput isSubscribed.
                     * @member {boolean} isSubscribed
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.isSubscribed = false;

                    /**
                     * CheckoutInput shippingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} shippingAddress
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.shippingAddress = null;

                    /**
                     * CheckoutInput specifyBillingAddress.
                     * @member {boolean} specifyBillingAddress
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.specifyBillingAddress = false;

                    /**
                     * CheckoutInput billingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} billingAddress
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.billingAddress = null;

                    /**
                     * CheckoutInput paymentType.
                     * @member {theplant.ec.service.orders.PaymentType} paymentType
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.paymentType = 0;

                    /**
                     * CheckoutInput creditCardId.
                     * @member {string} creditCardId
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.creditCardId = "";

                    /**
                     * CheckoutInput isTermsAgreed.
                     * @member {boolean} isTermsAgreed
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.isTermsAgreed = false;

                    /**
                     * CheckoutInput creditCardInput.
                     * @member {theplant.ec.service.orders.ICreditCardInput|null|undefined} creditCardInput
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.creditCardInput = null;

                    /**
                     * CheckoutInput amazonPayInput.
                     * @member {theplant.ec.service.orders.IAmazonPayInput|null|undefined} amazonPayInput
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.amazonPayInput = null;

                    /**
                     * CheckoutInput giftWrapping.
                     * @member {theplant.ec.service.orders.IGiftWrapping|null|undefined} giftWrapping
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.giftWrapping = null;

                    /**
                     * CheckoutInput password.
                     * @member {string} password
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.password = "";

                    /**
                     * CheckoutInput dateOfBirth.
                     * @member {string} dateOfBirth
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.dateOfBirth = "";

                    /**
                     * CheckoutInput gender.
                     * @member {theplant.ec.service.users.UserGender} gender
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.gender = 0;

                    /**
                     * CheckoutInput couponCode.
                     * @member {string} couponCode
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.couponCode = "";

                    /**
                     * CheckoutInput points.
                     * @member {number|Long} points
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.points = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * CheckoutInput getUserPoints.
                     * @member {boolean} getUserPoints
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.getUserPoints = false;

                    /**
                     * CheckoutInput deliveryMethod.
                     * @member {theplant.ec.api.orders.DeliveryMethod} deliveryMethod
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.deliveryMethod = 0;

                    /**
                     * CheckoutInput storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.storeId = "";

                    /**
                     * CheckoutInput deliveryInfo.
                     * @member {theplant.ec.service.orders.IDeliveryInfo|null|undefined} deliveryInfo
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.deliveryInfo = null;

                    /**
                     * CheckoutInput isSaveCreditCard.
                     * @member {boolean} isSaveCreditCard
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.isSaveCreditCard = false;

                    /**
                     * CheckoutInput isSubscribedSms.
                     * @member {boolean} isSubscribedSms
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.isSubscribedSms = false;

                    /**
                     * CheckoutInput shippingAddressId.
                     * @member {string} shippingAddressId
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.shippingAddressId = "";

                    /**
                     * CheckoutInput billingAddressId.
                     * @member {string} billingAddressId
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.billingAddressId = "";

                    /**
                     * CheckoutInput storeInfo.
                     * @member {theplant.ec.service.convenience_pickup.IStoreInfo|null|undefined} storeInfo
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.storeInfo = null;

                    /**
                     * CheckoutInput opluxDeviceInfo.
                     * @member {string} opluxDeviceInfo
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.opluxDeviceInfo = "";

                    /**
                     * CheckoutInput creditCardCvc.
                     * @member {string} creditCardCvc
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.creditCardCvc = "";

                    /**
                     * CheckoutInput rakutenAndPayPayInput.
                     * @member {theplant.ec.api.orders.IRakutenANDPayPayInput|null|undefined} rakutenAndPayPayInput
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.rakutenAndPayPayInput = null;

                    /**
                     * CheckoutInput smartPayInput.
                     * @member {theplant.ec.api.orders.ISmartPayInput|null|undefined} smartPayInput
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     */
                    CheckoutInput.prototype.smartPayInput = null;

                    /**
                     * Creates a new CheckoutInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.orders.ICheckoutInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.CheckoutInput} CheckoutInput instance
                     */
                    CheckoutInput.create = function create(properties) {
                        return new CheckoutInput(properties);
                    };

                    /**
                     * Encodes the specified CheckoutInput message. Does not implicitly {@link theplant.ec.api.orders.CheckoutInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.orders.ICheckoutInput} message CheckoutInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CheckoutInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.isSubscribed != null && message.hasOwnProperty("isSubscribed"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isSubscribed);
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.shippingAddress, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.specifyBillingAddress != null && message.hasOwnProperty("specifyBillingAddress"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.specifyBillingAddress);
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.billingAddress, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.paymentType);
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.creditCardId);
                        if (message.isTermsAgreed != null && message.hasOwnProperty("isTermsAgreed"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isTermsAgreed);
                        if (message.creditCardInput != null && message.hasOwnProperty("creditCardInput"))
                            $root.theplant.ec.service.orders.CreditCardInput.encode(message.creditCardInput, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.amazonPayInput != null && message.hasOwnProperty("amazonPayInput"))
                            $root.theplant.ec.service.orders.AmazonPayInput.encode(message.amazonPayInput, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            $root.theplant.ec.service.orders.GiftWrapping.encode(message.giftWrapping, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.password);
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.dateOfBirth);
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.gender);
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.couponCode);
                        if (message.points != null && message.hasOwnProperty("points"))
                            writer.uint32(/* id 16, wireType 0 =*/128).uint64(message.points);
                        if (message.getUserPoints != null && message.hasOwnProperty("getUserPoints"))
                            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.getUserPoints);
                        if (message.deliveryMethod != null && message.hasOwnProperty("deliveryMethod"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.deliveryMethod);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.storeId);
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            $root.theplant.ec.service.orders.DeliveryInfo.encode(message.deliveryInfo, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                        if (message.isSaveCreditCard != null && message.hasOwnProperty("isSaveCreditCard"))
                            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.isSaveCreditCard);
                        if (message.isSubscribedSms != null && message.hasOwnProperty("isSubscribedSms"))
                            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.isSubscribedSms);
                        if (message.shippingAddressId != null && message.hasOwnProperty("shippingAddressId"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.shippingAddressId);
                        if (message.billingAddressId != null && message.hasOwnProperty("billingAddressId"))
                            writer.uint32(/* id 24, wireType 2 =*/194).string(message.billingAddressId);
                        if (message.storeInfo != null && message.hasOwnProperty("storeInfo"))
                            $root.theplant.ec.service.convenience_pickup.StoreInfo.encode(message.storeInfo, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                        if (message.creditCardCvc != null && message.hasOwnProperty("creditCardCvc"))
                            writer.uint32(/* id 26, wireType 2 =*/210).string(message.creditCardCvc);
                        if (message.opluxDeviceInfo != null && message.hasOwnProperty("opluxDeviceInfo"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.opluxDeviceInfo);
                        if (message.rakutenAndPayPayInput != null && message.hasOwnProperty("rakutenAndPayPayInput"))
                            $root.theplant.ec.api.orders.RakutenANDPayPayInput.encode(message.rakutenAndPayPayInput, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                        if (message.smartPayInput != null && message.hasOwnProperty("smartPayInput"))
                            $root.theplant.ec.api.orders.SmartPayInput.encode(message.smartPayInput, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CheckoutInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.CheckoutInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.orders.ICheckoutInput} message CheckoutInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CheckoutInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CheckoutInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.CheckoutInput} CheckoutInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CheckoutInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.CheckoutInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.isSubscribed = reader.bool();
                                break;
                            case 3:
                                message.shippingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.specifyBillingAddress = reader.bool();
                                break;
                            case 5:
                                message.billingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.paymentType = reader.int32();
                                break;
                            case 7:
                                message.creditCardId = reader.string();
                                break;
                            case 8:
                                message.isTermsAgreed = reader.bool();
                                break;
                            case 9:
                                message.creditCardInput = $root.theplant.ec.service.orders.CreditCardInput.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.amazonPayInput = $root.theplant.ec.service.orders.AmazonPayInput.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.password = reader.string();
                                break;
                            case 13:
                                message.dateOfBirth = reader.string();
                                break;
                            case 14:
                                message.gender = reader.int32();
                                break;
                            case 15:
                                message.couponCode = reader.string();
                                break;
                            case 16:
                                message.points = reader.uint64();
                                break;
                            case 17:
                                message.getUserPoints = reader.bool();
                                break;
                            case 18:
                                message.deliveryMethod = reader.int32();
                                break;
                            case 19:
                                message.storeId = reader.string();
                                break;
                            case 20:
                                message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.decode(reader, reader.uint32());
                                break;
                            case 21:
                                message.isSaveCreditCard = reader.bool();
                                break;
                            case 22:
                                message.isSubscribedSms = reader.bool();
                                break;
                            case 23:
                                message.shippingAddressId = reader.string();
                                break;
                            case 24:
                                message.billingAddressId = reader.string();
                                break;
                            case 25:
                                message.storeInfo = $root.theplant.ec.service.convenience_pickup.StoreInfo.decode(reader, reader.uint32());
                                break;
                            case 27:
                                message.opluxDeviceInfo = reader.string();
                                break;
                            case 26:
                                message.creditCardCvc = reader.string();
                                break;
                            case 28:
                                message.rakutenAndPayPayInput = $root.theplant.ec.api.orders.RakutenANDPayPayInput.decode(reader, reader.uint32());
                                break;
                            case 29:
                                message.smartPayInput = $root.theplant.ec.api.orders.SmartPayInput.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CheckoutInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.CheckoutInput} CheckoutInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CheckoutInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CheckoutInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CheckoutInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.isSubscribed != null && message.hasOwnProperty("isSubscribed"))
                            if (typeof message.isSubscribed !== "boolean")
                                return "isSubscribed: boolean expected";
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.shippingAddress);
                            if (error)
                                return "shippingAddress." + error;
                        }
                        if (message.specifyBillingAddress != null && message.hasOwnProperty("specifyBillingAddress"))
                            if (typeof message.specifyBillingAddress !== "boolean")
                                return "specifyBillingAddress: boolean expected";
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.billingAddress);
                            if (error)
                                return "billingAddress." + error;
                        }
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            switch (message.paymentType) {
                            default:
                                return "paymentType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 10:
                                break;
                            }
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            if (!$util.isString(message.creditCardId))
                                return "creditCardId: string expected";
                        if (message.isTermsAgreed != null && message.hasOwnProperty("isTermsAgreed"))
                            if (typeof message.isTermsAgreed !== "boolean")
                                return "isTermsAgreed: boolean expected";
                        if (message.creditCardInput != null && message.hasOwnProperty("creditCardInput")) {
                            var error = $root.theplant.ec.service.orders.CreditCardInput.verify(message.creditCardInput);
                            if (error)
                                return "creditCardInput." + error;
                        }
                        if (message.amazonPayInput != null && message.hasOwnProperty("amazonPayInput")) {
                            var error = $root.theplant.ec.service.orders.AmazonPayInput.verify(message.amazonPayInput);
                            if (error)
                                return "amazonPayInput." + error;
                        }
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping")) {
                            var error = $root.theplant.ec.service.orders.GiftWrapping.verify(message.giftWrapping);
                            if (error)
                                return "giftWrapping." + error;
                        }
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            if (!$util.isString(message.dateOfBirth))
                                return "dateOfBirth: string expected";
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            switch (message.gender) {
                            default:
                                return "gender: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            if (!$util.isString(message.couponCode))
                                return "couponCode: string expected";
                        if (message.points != null && message.hasOwnProperty("points"))
                            if (!$util.isInteger(message.points) && !(message.points && $util.isInteger(message.points.low) && $util.isInteger(message.points.high)))
                                return "points: integer|Long expected";
                        if (message.getUserPoints != null && message.hasOwnProperty("getUserPoints"))
                            if (typeof message.getUserPoints !== "boolean")
                                return "getUserPoints: boolean expected";
                        if (message.deliveryMethod != null && message.hasOwnProperty("deliveryMethod"))
                            switch (message.deliveryMethod) {
                            default:
                                return "deliveryMethod: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo")) {
                            var error = $root.theplant.ec.service.orders.DeliveryInfo.verify(message.deliveryInfo);
                            if (error)
                                return "deliveryInfo." + error;
                        }
                        if (message.isSaveCreditCard != null && message.hasOwnProperty("isSaveCreditCard"))
                            if (typeof message.isSaveCreditCard !== "boolean")
                                return "isSaveCreditCard: boolean expected";
                        if (message.isSubscribedSms != null && message.hasOwnProperty("isSubscribedSms"))
                            if (typeof message.isSubscribedSms !== "boolean")
                                return "isSubscribedSms: boolean expected";
                        if (message.shippingAddressId != null && message.hasOwnProperty("shippingAddressId"))
                            if (!$util.isString(message.shippingAddressId))
                                return "shippingAddressId: string expected";
                        if (message.billingAddressId != null && message.hasOwnProperty("billingAddressId"))
                            if (!$util.isString(message.billingAddressId))
                                return "billingAddressId: string expected";
                        if (message.storeInfo != null && message.hasOwnProperty("storeInfo")) {
                            var error = $root.theplant.ec.service.convenience_pickup.StoreInfo.verify(message.storeInfo);
                            if (error)
                                return "storeInfo." + error;
                        }
                        if (message.opluxDeviceInfo != null && message.hasOwnProperty("opluxDeviceInfo"))
                            if (!$util.isString(message.opluxDeviceInfo))
                                return "opluxDeviceInfo: string expected";
                        if (message.creditCardCvc != null && message.hasOwnProperty("creditCardCvc"))
                            if (!$util.isString(message.creditCardCvc))
                                return "creditCardCvc: string expected";
                        if (message.rakutenAndPayPayInput != null && message.hasOwnProperty("rakutenAndPayPayInput")) {
                            var error = $root.theplant.ec.api.orders.RakutenANDPayPayInput.verify(message.rakutenAndPayPayInput);
                            if (error)
                                return "rakutenAndPayPayInput." + error;
                        }
                        if (message.smartPayInput != null && message.hasOwnProperty("smartPayInput")) {
                            var error = $root.theplant.ec.api.orders.SmartPayInput.verify(message.smartPayInput);
                            if (error)
                                return "smartPayInput." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CheckoutInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.CheckoutInput} CheckoutInput
                     */
                    CheckoutInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.CheckoutInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.CheckoutInput();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.isSubscribed != null)
                            message.isSubscribed = Boolean(object.isSubscribed);
                        if (object.shippingAddress != null) {
                            if (typeof object.shippingAddress !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.shippingAddress: object expected");
                            message.shippingAddress = $root.theplant.ec.service.users.Address.fromObject(object.shippingAddress);
                        }
                        if (object.specifyBillingAddress != null)
                            message.specifyBillingAddress = Boolean(object.specifyBillingAddress);
                        if (object.billingAddress != null) {
                            if (typeof object.billingAddress !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.billingAddress: object expected");
                            message.billingAddress = $root.theplant.ec.service.users.Address.fromObject(object.billingAddress);
                        }
                        switch (object.paymentType) {
                        case "NO_PAYMENT":
                        case 0:
                            message.paymentType = 0;
                            break;
                        case "COD":
                        case 1:
                            message.paymentType = 1;
                            break;
                        case "CREDIT_CARD":
                        case 2:
                            message.paymentType = 2;
                            break;
                        case "PAY_ALL_WITH_POINT":
                        case 3:
                            message.paymentType = 3;
                            break;
                        case "PAY_ALL_WITH_GIFT_CARD":
                        case 4:
                            message.paymentType = 4;
                            break;
                        case "OTHER":
                        case 5:
                            message.paymentType = 5;
                            break;
                        case "AMAZON_PAY":
                        case 6:
                            message.paymentType = 6;
                            break;
                        case "RAKUTEN_PAY":
                        case 7:
                            message.paymentType = 7;
                            break;
                        case "PAY_PAY":
                        case 8:
                            message.paymentType = 8;
                            break;
                        case "SMART_PAY":
                        case 10:
                            message.paymentType = 10;
                            break;
                        }
                        if (object.creditCardId != null)
                            message.creditCardId = String(object.creditCardId);
                        if (object.isTermsAgreed != null)
                            message.isTermsAgreed = Boolean(object.isTermsAgreed);
                        if (object.creditCardInput != null) {
                            if (typeof object.creditCardInput !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.creditCardInput: object expected");
                            message.creditCardInput = $root.theplant.ec.service.orders.CreditCardInput.fromObject(object.creditCardInput);
                        }
                        if (object.amazonPayInput != null) {
                            if (typeof object.amazonPayInput !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.amazonPayInput: object expected");
                            message.amazonPayInput = $root.theplant.ec.service.orders.AmazonPayInput.fromObject(object.amazonPayInput);
                        }
                        if (object.giftWrapping != null) {
                            if (typeof object.giftWrapping !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.giftWrapping: object expected");
                            message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.fromObject(object.giftWrapping);
                        }
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.dateOfBirth != null)
                            message.dateOfBirth = String(object.dateOfBirth);
                        switch (object.gender) {
                        case "UNKNOWN":
                        case 0:
                            message.gender = 0;
                            break;
                        case "MALE":
                        case 1:
                            message.gender = 1;
                            break;
                        case "FEMALE":
                        case 2:
                            message.gender = 2;
                            break;
                        }
                        if (object.couponCode != null)
                            message.couponCode = String(object.couponCode);
                        if (object.points != null)
                            if ($util.Long)
                                (message.points = $util.Long.fromValue(object.points)).unsigned = true;
                            else if (typeof object.points === "string")
                                message.points = parseInt(object.points, 10);
                            else if (typeof object.points === "number")
                                message.points = object.points;
                            else if (typeof object.points === "object")
                                message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber(true);
                        if (object.getUserPoints != null)
                            message.getUserPoints = Boolean(object.getUserPoints);
                        switch (object.deliveryMethod) {
                        case "HOME_DELIVERY":
                        case 0:
                            message.deliveryMethod = 0;
                            break;
                        case "STORE_PICKUP":
                        case 1:
                            message.deliveryMethod = 1;
                            break;
                        case "CONVENIENCE_PICKUP":
                        case 2:
                            message.deliveryMethod = 2;
                            break;
                        }
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.deliveryInfo != null) {
                            if (typeof object.deliveryInfo !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.deliveryInfo: object expected");
                            message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.fromObject(object.deliveryInfo);
                        }
                        if (object.isSaveCreditCard != null)
                            message.isSaveCreditCard = Boolean(object.isSaveCreditCard);
                        if (object.isSubscribedSms != null)
                            message.isSubscribedSms = Boolean(object.isSubscribedSms);
                        if (object.shippingAddressId != null)
                            message.shippingAddressId = String(object.shippingAddressId);
                        if (object.billingAddressId != null)
                            message.billingAddressId = String(object.billingAddressId);
                        if (object.storeInfo != null) {
                            if (typeof object.storeInfo !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.storeInfo: object expected");
                            message.storeInfo = $root.theplant.ec.service.convenience_pickup.StoreInfo.fromObject(object.storeInfo);
                        }
                        if (object.opluxDeviceInfo != null)
                            message.opluxDeviceInfo = String(object.opluxDeviceInfo);
                        if (object.creditCardCvc != null)
                            message.creditCardCvc = String(object.creditCardCvc);
                        if (object.rakutenAndPayPayInput != null) {
                            if (typeof object.rakutenAndPayPayInput !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.rakutenAndPayPayInput: object expected");
                            message.rakutenAndPayPayInput = $root.theplant.ec.api.orders.RakutenANDPayPayInput.fromObject(object.rakutenAndPayPayInput);
                        }
                        if (object.smartPayInput != null) {
                            if (typeof object.smartPayInput !== "object")
                                throw TypeError(".theplant.ec.api.orders.CheckoutInput.smartPayInput: object expected");
                            message.smartPayInput = $root.theplant.ec.api.orders.SmartPayInput.fromObject(object.smartPayInput);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CheckoutInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @static
                     * @param {theplant.ec.api.orders.CheckoutInput} message CheckoutInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CheckoutInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.isSubscribed = false;
                            object.shippingAddress = null;
                            object.specifyBillingAddress = false;
                            object.billingAddress = null;
                            object.paymentType = options.enums === String ? "NO_PAYMENT" : 0;
                            object.creditCardId = "";
                            object.isTermsAgreed = false;
                            object.creditCardInput = null;
                            object.amazonPayInput = null;
                            object.giftWrapping = null;
                            object.password = "";
                            object.dateOfBirth = "";
                            object.gender = options.enums === String ? "UNKNOWN" : 0;
                            object.couponCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.points = options.longs === String ? "0" : 0;
                            object.getUserPoints = false;
                            object.deliveryMethod = options.enums === String ? "HOME_DELIVERY" : 0;
                            object.storeId = "";
                            object.deliveryInfo = null;
                            object.isSaveCreditCard = false;
                            object.isSubscribedSms = false;
                            object.shippingAddressId = "";
                            object.billingAddressId = "";
                            object.storeInfo = null;
                            object.creditCardCvc = "";
                            object.opluxDeviceInfo = "";
                            object.rakutenAndPayPayInput = null;
                            object.smartPayInput = null;
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.isSubscribed != null && message.hasOwnProperty("isSubscribed"))
                            object.isSubscribed = message.isSubscribed;
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress"))
                            object.shippingAddress = $root.theplant.ec.service.users.Address.toObject(message.shippingAddress, options);
                        if (message.specifyBillingAddress != null && message.hasOwnProperty("specifyBillingAddress"))
                            object.specifyBillingAddress = message.specifyBillingAddress;
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            object.billingAddress = $root.theplant.ec.service.users.Address.toObject(message.billingAddress, options);
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            object.paymentType = options.enums === String ? $root.theplant.ec.service.orders.PaymentType[message.paymentType] : message.paymentType;
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            object.creditCardId = message.creditCardId;
                        if (message.isTermsAgreed != null && message.hasOwnProperty("isTermsAgreed"))
                            object.isTermsAgreed = message.isTermsAgreed;
                        if (message.creditCardInput != null && message.hasOwnProperty("creditCardInput"))
                            object.creditCardInput = $root.theplant.ec.service.orders.CreditCardInput.toObject(message.creditCardInput, options);
                        if (message.amazonPayInput != null && message.hasOwnProperty("amazonPayInput"))
                            object.amazonPayInput = $root.theplant.ec.service.orders.AmazonPayInput.toObject(message.amazonPayInput, options);
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            object.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.toObject(message.giftWrapping, options);
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            object.dateOfBirth = message.dateOfBirth;
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = options.enums === String ? $root.theplant.ec.service.users.UserGender[message.gender] : message.gender;
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            object.couponCode = message.couponCode;
                        if (message.points != null && message.hasOwnProperty("points"))
                            if (typeof message.points === "number")
                                object.points = options.longs === String ? String(message.points) : message.points;
                            else
                                object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber(true) : message.points;
                        if (message.getUserPoints != null && message.hasOwnProperty("getUserPoints"))
                            object.getUserPoints = message.getUserPoints;
                        if (message.deliveryMethod != null && message.hasOwnProperty("deliveryMethod"))
                            object.deliveryMethod = options.enums === String ? $root.theplant.ec.api.orders.DeliveryMethod[message.deliveryMethod] : message.deliveryMethod;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            object.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.toObject(message.deliveryInfo, options);
                        if (message.isSaveCreditCard != null && message.hasOwnProperty("isSaveCreditCard"))
                            object.isSaveCreditCard = message.isSaveCreditCard;
                        if (message.isSubscribedSms != null && message.hasOwnProperty("isSubscribedSms"))
                            object.isSubscribedSms = message.isSubscribedSms;
                        if (message.shippingAddressId != null && message.hasOwnProperty("shippingAddressId"))
                            object.shippingAddressId = message.shippingAddressId;
                        if (message.billingAddressId != null && message.hasOwnProperty("billingAddressId"))
                            object.billingAddressId = message.billingAddressId;
                        if (message.storeInfo != null && message.hasOwnProperty("storeInfo"))
                            object.storeInfo = $root.theplant.ec.service.convenience_pickup.StoreInfo.toObject(message.storeInfo, options);
                        if (message.creditCardCvc != null && message.hasOwnProperty("creditCardCvc"))
                            object.creditCardCvc = message.creditCardCvc;
                        if (message.opluxDeviceInfo != null && message.hasOwnProperty("opluxDeviceInfo"))
                            object.opluxDeviceInfo = message.opluxDeviceInfo;
                        if (message.rakutenAndPayPayInput != null && message.hasOwnProperty("rakutenAndPayPayInput"))
                            object.rakutenAndPayPayInput = $root.theplant.ec.api.orders.RakutenANDPayPayInput.toObject(message.rakutenAndPayPayInput, options);
                        if (message.smartPayInput != null && message.hasOwnProperty("smartPayInput"))
                            object.smartPayInput = $root.theplant.ec.api.orders.SmartPayInput.toObject(message.smartPayInput, options);
                        return object;
                    };

                    /**
                     * Converts this CheckoutInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.CheckoutInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CheckoutInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CheckoutInput;
                })();

                orders.ConfirmResult = (function() {

                    /**
                     * Properties of a ConfirmResult.
                     * @memberof theplant.ec.api.orders
                     * @interface IConfirmResult
                     * @property {theplant.ec.service.orders.IOrder|null} [order] ConfirmResult order
                     * @property {string|null} [setInitialPasswordToken] ConfirmResult setInitialPasswordToken
                     * @property {string|null} [setGuestUserInitialPasswordToken] ConfirmResult setGuestUserInitialPasswordToken
                     * @property {boolean|null} [canMergeGuestUser] ConfirmResult canMergeGuestUser
                     * @property {theplant.ec.service.orders.IDeliveryDateRange|null} [deliveryDateRange] ConfirmResult deliveryDateRange
                     * @property {string|null} [amazonpayEmail] ConfirmResult amazonpayEmail
                     * @property {boolean|null} [isGuestUserEmailExisting] ConfirmResult isGuestUserEmailExisting
                     */

                    /**
                     * Constructs a new ConfirmResult.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a ConfirmResult.
                     * @implements IConfirmResult
                     * @constructor
                     * @param {theplant.ec.api.orders.IConfirmResult=} [properties] Properties to set
                     */
                    function ConfirmResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ConfirmResult order.
                     * @member {theplant.ec.service.orders.IOrder|null|undefined} order
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.order = null;

                    /**
                     * ConfirmResult setInitialPasswordToken.
                     * @member {string} setInitialPasswordToken
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.setInitialPasswordToken = "";

                    /**
                     * ConfirmResult setGuestUserInitialPasswordToken.
                     * @member {string} setGuestUserInitialPasswordToken
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.setGuestUserInitialPasswordToken = "";

                    /**
                     * ConfirmResult canMergeGuestUser.
                     * @member {boolean} canMergeGuestUser
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.canMergeGuestUser = false;

                    /**
                     * ConfirmResult deliveryDateRange.
                     * @member {theplant.ec.service.orders.IDeliveryDateRange|null|undefined} deliveryDateRange
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.deliveryDateRange = null;

                    /**
                     * ConfirmResult amazonpayEmail.
                     * @member {string} amazonpayEmail
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.amazonpayEmail = "";

                    /**
                     * ConfirmResult isGuestUserEmailExisting.
                     * @member {boolean} isGuestUserEmailExisting
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     */
                    ConfirmResult.prototype.isGuestUserEmailExisting = false;

                    /**
                     * Creates a new ConfirmResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {theplant.ec.api.orders.IConfirmResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.ConfirmResult} ConfirmResult instance
                     */
                    ConfirmResult.create = function create(properties) {
                        return new ConfirmResult(properties);
                    };

                    /**
                     * Encodes the specified ConfirmResult message. Does not implicitly {@link theplant.ec.api.orders.ConfirmResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {theplant.ec.api.orders.IConfirmResult} message ConfirmResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConfirmResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.order != null && message.hasOwnProperty("order"))
                            $root.theplant.ec.service.orders.Order.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.setInitialPasswordToken != null && message.hasOwnProperty("setInitialPasswordToken"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.setInitialPasswordToken);
                        if (message.setGuestUserInitialPasswordToken != null && message.hasOwnProperty("setGuestUserInitialPasswordToken"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.setGuestUserInitialPasswordToken);
                        if (message.canMergeGuestUser != null && message.hasOwnProperty("canMergeGuestUser"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.canMergeGuestUser);
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange"))
                            $root.theplant.ec.service.orders.DeliveryDateRange.encode(message.deliveryDateRange, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.amazonpayEmail != null && message.hasOwnProperty("amazonpayEmail"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.amazonpayEmail);
                        if (message.isGuestUserEmailExisting != null && message.hasOwnProperty("isGuestUserEmailExisting"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isGuestUserEmailExisting);
                        return writer;
                    };

                    /**
                     * Encodes the specified ConfirmResult message, length delimited. Does not implicitly {@link theplant.ec.api.orders.ConfirmResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {theplant.ec.api.orders.IConfirmResult} message ConfirmResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConfirmResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ConfirmResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.ConfirmResult} ConfirmResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConfirmResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.ConfirmResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.order = $root.theplant.ec.service.orders.Order.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.setInitialPasswordToken = reader.string();
                                break;
                            case 3:
                                message.setGuestUserInitialPasswordToken = reader.string();
                                break;
                            case 4:
                                message.canMergeGuestUser = reader.bool();
                                break;
                            case 5:
                                message.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.amazonpayEmail = reader.string();
                                break;
                            case 8:
                                message.isGuestUserEmailExisting = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ConfirmResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.ConfirmResult} ConfirmResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConfirmResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ConfirmResult message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ConfirmResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.order != null && message.hasOwnProperty("order")) {
                            var error = $root.theplant.ec.service.orders.Order.verify(message.order);
                            if (error)
                                return "order." + error;
                        }
                        if (message.setInitialPasswordToken != null && message.hasOwnProperty("setInitialPasswordToken"))
                            if (!$util.isString(message.setInitialPasswordToken))
                                return "setInitialPasswordToken: string expected";
                        if (message.setGuestUserInitialPasswordToken != null && message.hasOwnProperty("setGuestUserInitialPasswordToken"))
                            if (!$util.isString(message.setGuestUserInitialPasswordToken))
                                return "setGuestUserInitialPasswordToken: string expected";
                        if (message.canMergeGuestUser != null && message.hasOwnProperty("canMergeGuestUser"))
                            if (typeof message.canMergeGuestUser !== "boolean")
                                return "canMergeGuestUser: boolean expected";
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange")) {
                            var error = $root.theplant.ec.service.orders.DeliveryDateRange.verify(message.deliveryDateRange);
                            if (error)
                                return "deliveryDateRange." + error;
                        }
                        if (message.amazonpayEmail != null && message.hasOwnProperty("amazonpayEmail"))
                            if (!$util.isString(message.amazonpayEmail))
                                return "amazonpayEmail: string expected";
                        if (message.isGuestUserEmailExisting != null && message.hasOwnProperty("isGuestUserEmailExisting"))
                            if (typeof message.isGuestUserEmailExisting !== "boolean")
                                return "isGuestUserEmailExisting: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a ConfirmResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.ConfirmResult} ConfirmResult
                     */
                    ConfirmResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.ConfirmResult)
                            return object;
                        var message = new $root.theplant.ec.api.orders.ConfirmResult();
                        if (object.order != null) {
                            if (typeof object.order !== "object")
                                throw TypeError(".theplant.ec.api.orders.ConfirmResult.order: object expected");
                            message.order = $root.theplant.ec.service.orders.Order.fromObject(object.order);
                        }
                        if (object.setInitialPasswordToken != null)
                            message.setInitialPasswordToken = String(object.setInitialPasswordToken);
                        if (object.setGuestUserInitialPasswordToken != null)
                            message.setGuestUserInitialPasswordToken = String(object.setGuestUserInitialPasswordToken);
                        if (object.canMergeGuestUser != null)
                            message.canMergeGuestUser = Boolean(object.canMergeGuestUser);
                        if (object.deliveryDateRange != null) {
                            if (typeof object.deliveryDateRange !== "object")
                                throw TypeError(".theplant.ec.api.orders.ConfirmResult.deliveryDateRange: object expected");
                            message.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.fromObject(object.deliveryDateRange);
                        }
                        if (object.amazonpayEmail != null)
                            message.amazonpayEmail = String(object.amazonpayEmail);
                        if (object.isGuestUserEmailExisting != null)
                            message.isGuestUserEmailExisting = Boolean(object.isGuestUserEmailExisting);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ConfirmResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @static
                     * @param {theplant.ec.api.orders.ConfirmResult} message ConfirmResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ConfirmResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.order = null;
                            object.setInitialPasswordToken = "";
                            object.setGuestUserInitialPasswordToken = "";
                            object.canMergeGuestUser = false;
                            object.deliveryDateRange = null;
                            object.amazonpayEmail = "";
                            object.isGuestUserEmailExisting = false;
                        }
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = $root.theplant.ec.service.orders.Order.toObject(message.order, options);
                        if (message.setInitialPasswordToken != null && message.hasOwnProperty("setInitialPasswordToken"))
                            object.setInitialPasswordToken = message.setInitialPasswordToken;
                        if (message.setGuestUserInitialPasswordToken != null && message.hasOwnProperty("setGuestUserInitialPasswordToken"))
                            object.setGuestUserInitialPasswordToken = message.setGuestUserInitialPasswordToken;
                        if (message.canMergeGuestUser != null && message.hasOwnProperty("canMergeGuestUser"))
                            object.canMergeGuestUser = message.canMergeGuestUser;
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange"))
                            object.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.toObject(message.deliveryDateRange, options);
                        if (message.amazonpayEmail != null && message.hasOwnProperty("amazonpayEmail"))
                            object.amazonpayEmail = message.amazonpayEmail;
                        if (message.isGuestUserEmailExisting != null && message.hasOwnProperty("isGuestUserEmailExisting"))
                            object.isGuestUserEmailExisting = message.isGuestUserEmailExisting;
                        return object;
                    };

                    /**
                     * Converts this ConfirmResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.ConfirmResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ConfirmResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ConfirmResult;
                })();

                orders.ValidationResult = (function() {

                    /**
                     * Properties of a ValidationResult.
                     * @memberof theplant.ec.api.orders
                     * @interface IValidationResult
                     * @property {proto.IValidationError|null} [error] ValidationResult error
                     * @property {theplant.ec.service.orders.IOrder|null} [order] ValidationResult order
                     * @property {theplant.ec.service.orders.IDeliveryDateRange|null} [deliveryDateRange] ValidationResult deliveryDateRange
                     * @property {Array.<theplant.ec.service.stores.IStore>|null} [allStores] ValidationResult allStores
                     * @property {Array.<theplant.ec.api.orders.Ability>|null} [Abilities] ValidationResult Abilities
                     * @property {theplant.ec.service.accounting.IFeeSetting|null} [feeSetting] ValidationResult feeSetting
                     * @property {Array.<theplant.ec.service.users.IShippingAddress>|null} [userShippingAddress] ValidationResult userShippingAddress
                     * @property {Array.<theplant.ec.service.orders.ICreditCard>|null} [userCreditCards] ValidationResult userCreditCards
                     * @property {boolean|null} [isGuestUser] ValidationResult isGuestUser
                     * @property {theplant.ec.service.stores.IStore|null} [myStore] ValidationResult myStore
                     * @property {boolean|null} [isFreeGiftWrappingFee] ValidationResult isFreeGiftWrappingFee
                     * @property {theplant.ec.service.users.IAddress|null} [amazonpayShippingAddress] ValidationResult amazonpayShippingAddress
                     * @property {Array.<string>|null} [amazonpayPaymentReference] ValidationResult amazonpayPaymentReference
                     * @property {string|null} [amazonpayRedirectUrl] ValidationResult amazonpayRedirectUrl
                     * @property {theplant.ec.service.users.IAddress|null} [amazonpayBillingAddress] ValidationResult amazonpayBillingAddress
                     * @property {theplant.ec.service.orders.IDeliveryInfo|null} [deliveryInfo] ValidationResult deliveryInfo
                     * @property {theplant.ec.service.orders.IGiftWrapping|null} [giftWrapping] ValidationResult giftWrapping
                     * @property {number|Long|null} [points] ValidationResult points
                     * @property {string|null} [rakutenPayRedirectHtml] ValidationResult rakutenPayRedirectHtml
                     * @property {string|null} [payPayRedirectHtml] ValidationResult payPayRedirectHtml
                     * @property {boolean|null} [canUseRakutenPay] ValidationResult canUseRakutenPay
                     * @property {string|null} [smartPaySessionId] ValidationResult smartPaySessionId
                     * @property {string|null} [smartPayOrderId] ValidationResult smartPayOrderId
                     * @property {string|null} [smartPayRedirectUrl] ValidationResult smartPayRedirectUrl
                     * @property {string|null} [rakutenPayTransactionId] ValidationResult rakutenPayTransactionId
                     * @property {string|null} [payPayTransactionId] ValidationResult payPayTransactionId
                     */

                    /**
                     * Constructs a new ValidationResult.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a ValidationResult.
                     * @implements IValidationResult
                     * @constructor
                     * @param {theplant.ec.api.orders.IValidationResult=} [properties] Properties to set
                     */
                    function ValidationResult(properties) {
                        this.allStores = [];
                        this.Abilities = [];
                        this.userShippingAddress = [];
                        this.userCreditCards = [];
                        this.amazonpayPaymentReference = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ValidationResult error.
                     * @member {proto.IValidationError|null|undefined} error
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.error = null;

                    /**
                     * ValidationResult order.
                     * @member {theplant.ec.service.orders.IOrder|null|undefined} order
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.order = null;

                    /**
                     * ValidationResult deliveryDateRange.
                     * @member {theplant.ec.service.orders.IDeliveryDateRange|null|undefined} deliveryDateRange
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.deliveryDateRange = null;

                    /**
                     * ValidationResult allStores.
                     * @member {Array.<theplant.ec.service.stores.IStore>} allStores
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.allStores = $util.emptyArray;

                    /**
                     * ValidationResult Abilities.
                     * @member {Array.<theplant.ec.api.orders.Ability>} Abilities
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.Abilities = $util.emptyArray;

                    /**
                     * ValidationResult feeSetting.
                     * @member {theplant.ec.service.accounting.IFeeSetting|null|undefined} feeSetting
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.feeSetting = null;

                    /**
                     * ValidationResult userShippingAddress.
                     * @member {Array.<theplant.ec.service.users.IShippingAddress>} userShippingAddress
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.userShippingAddress = $util.emptyArray;

                    /**
                     * ValidationResult userCreditCards.
                     * @member {Array.<theplant.ec.service.orders.ICreditCard>} userCreditCards
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.userCreditCards = $util.emptyArray;

                    /**
                     * ValidationResult isGuestUser.
                     * @member {boolean} isGuestUser
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.isGuestUser = false;

                    /**
                     * ValidationResult myStore.
                     * @member {theplant.ec.service.stores.IStore|null|undefined} myStore
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.myStore = null;

                    /**
                     * ValidationResult isFreeGiftWrappingFee.
                     * @member {boolean} isFreeGiftWrappingFee
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.isFreeGiftWrappingFee = false;

                    /**
                     * ValidationResult amazonpayShippingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} amazonpayShippingAddress
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.amazonpayShippingAddress = null;

                    /**
                     * ValidationResult amazonpayPaymentReference.
                     * @member {Array.<string>} amazonpayPaymentReference
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.amazonpayPaymentReference = $util.emptyArray;

                    /**
                     * ValidationResult amazonpayRedirectUrl.
                     * @member {string} amazonpayRedirectUrl
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.amazonpayRedirectUrl = "";

                    /**
                     * ValidationResult amazonpayBillingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} amazonpayBillingAddress
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.amazonpayBillingAddress = null;

                    /**
                     * ValidationResult deliveryInfo.
                     * @member {theplant.ec.service.orders.IDeliveryInfo|null|undefined} deliveryInfo
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.deliveryInfo = null;

                    /**
                     * ValidationResult giftWrapping.
                     * @member {theplant.ec.service.orders.IGiftWrapping|null|undefined} giftWrapping
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.giftWrapping = null;

                    /**
                     * ValidationResult points.
                     * @member {number|Long} points
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.points = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * ValidationResult rakutenPayRedirectHtml.
                     * @member {string} rakutenPayRedirectHtml
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.rakutenPayRedirectHtml = "";

                    /**
                     * ValidationResult payPayRedirectHtml.
                     * @member {string} payPayRedirectHtml
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.payPayRedirectHtml = "";

                    /**
                     * ValidationResult canUseRakutenPay.
                     * @member {boolean} canUseRakutenPay
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.canUseRakutenPay = false;

                    /**
                     * ValidationResult smartPaySessionId.
                     * @member {string} smartPaySessionId
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.smartPaySessionId = "";

                    /**
                     * ValidationResult smartPayOrderId.
                     * @member {string} smartPayOrderId
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.smartPayOrderId = "";

                    /**
                     * ValidationResult smartPayRedirectUrl.
                     * @member {string} smartPayRedirectUrl
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.smartPayRedirectUrl = "";

                    /**
                     * ValidationResult rakutenPayTransactionId.
                     * @member {string} rakutenPayTransactionId
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.rakutenPayTransactionId = "";

                    /**
                     * ValidationResult payPayTransactionId.
                     * @member {string} payPayTransactionId
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     */
                    ValidationResult.prototype.payPayTransactionId = "";

                    /**
                     * Creates a new ValidationResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {theplant.ec.api.orders.IValidationResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.ValidationResult} ValidationResult instance
                     */
                    ValidationResult.create = function create(properties) {
                        return new ValidationResult(properties);
                    };

                    /**
                     * Encodes the specified ValidationResult message. Does not implicitly {@link theplant.ec.api.orders.ValidationResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {theplant.ec.api.orders.IValidationResult} message ValidationResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ValidationResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.error != null && message.hasOwnProperty("error"))
                            $root.proto.ValidationError.encode(message.error, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.order != null && message.hasOwnProperty("order"))
                            $root.theplant.ec.service.orders.Order.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange"))
                            $root.theplant.ec.service.orders.DeliveryDateRange.encode(message.deliveryDateRange, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.allStores != null && message.allStores.length)
                            for (var i = 0; i < message.allStores.length; ++i)
                                $root.theplant.ec.service.stores.Store.encode(message.allStores[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.Abilities != null && message.Abilities.length) {
                            writer.uint32(/* id 5, wireType 2 =*/42).fork();
                            for (var i = 0; i < message.Abilities.length; ++i)
                                writer.int32(message.Abilities[i]);
                            writer.ldelim();
                        }
                        if (message.feeSetting != null && message.hasOwnProperty("feeSetting"))
                            $root.theplant.ec.service.accounting.FeeSetting.encode(message.feeSetting, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.userShippingAddress != null && message.userShippingAddress.length)
                            for (var i = 0; i < message.userShippingAddress.length; ++i)
                                $root.theplant.ec.service.users.ShippingAddress.encode(message.userShippingAddress[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.userCreditCards != null && message.userCreditCards.length)
                            for (var i = 0; i < message.userCreditCards.length; ++i)
                                $root.theplant.ec.service.orders.CreditCard.encode(message.userCreditCards[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.isGuestUser != null && message.hasOwnProperty("isGuestUser"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isGuestUser);
                        if (message.myStore != null && message.hasOwnProperty("myStore"))
                            $root.theplant.ec.service.stores.Store.encode(message.myStore, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.isFreeGiftWrappingFee != null && message.hasOwnProperty("isFreeGiftWrappingFee"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isFreeGiftWrappingFee);
                        if (message.amazonpayShippingAddress != null && message.hasOwnProperty("amazonpayShippingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.amazonpayShippingAddress, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.amazonpayPaymentReference != null && message.amazonpayPaymentReference.length)
                            for (var i = 0; i < message.amazonpayPaymentReference.length; ++i)
                                writer.uint32(/* id 14, wireType 2 =*/114).string(message.amazonpayPaymentReference[i]);
                        if (message.amazonpayRedirectUrl != null && message.hasOwnProperty("amazonpayRedirectUrl"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.amazonpayRedirectUrl);
                        if (message.amazonpayBillingAddress != null && message.hasOwnProperty("amazonpayBillingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.amazonpayBillingAddress, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            $root.theplant.ec.service.orders.DeliveryInfo.encode(message.deliveryInfo, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            $root.theplant.ec.service.orders.GiftWrapping.encode(message.giftWrapping, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        if (message.points != null && message.hasOwnProperty("points"))
                            writer.uint32(/* id 19, wireType 0 =*/152).uint64(message.points);
                        if (message.rakutenPayRedirectHtml != null && message.hasOwnProperty("rakutenPayRedirectHtml"))
                            writer.uint32(/* id 20, wireType 2 =*/162).string(message.rakutenPayRedirectHtml);
                        if (message.payPayRedirectHtml != null && message.hasOwnProperty("payPayRedirectHtml"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.payPayRedirectHtml);
                        if (message.canUseRakutenPay != null && message.hasOwnProperty("canUseRakutenPay"))
                            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.canUseRakutenPay);
                        if (message.smartPaySessionId != null && message.hasOwnProperty("smartPaySessionId"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.smartPaySessionId);
                        if (message.smartPayOrderId != null && message.hasOwnProperty("smartPayOrderId"))
                            writer.uint32(/* id 24, wireType 2 =*/194).string(message.smartPayOrderId);
                        if (message.smartPayRedirectUrl != null && message.hasOwnProperty("smartPayRedirectUrl"))
                            writer.uint32(/* id 25, wireType 2 =*/202).string(message.smartPayRedirectUrl);
                        if (message.rakutenPayTransactionId != null && message.hasOwnProperty("rakutenPayTransactionId"))
                            writer.uint32(/* id 26, wireType 2 =*/210).string(message.rakutenPayTransactionId);
                        if (message.payPayTransactionId != null && message.hasOwnProperty("payPayTransactionId"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.payPayTransactionId);
                        return writer;
                    };

                    /**
                     * Encodes the specified ValidationResult message, length delimited. Does not implicitly {@link theplant.ec.api.orders.ValidationResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {theplant.ec.api.orders.IValidationResult} message ValidationResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ValidationResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ValidationResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.ValidationResult} ValidationResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ValidationResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.ValidationResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.error = $root.proto.ValidationError.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.order = $root.theplant.ec.service.orders.Order.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.decode(reader, reader.uint32());
                                break;
                            case 4:
                                if (!(message.allStores && message.allStores.length))
                                    message.allStores = [];
                                message.allStores.push($root.theplant.ec.service.stores.Store.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                if (!(message.Abilities && message.Abilities.length))
                                    message.Abilities = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.Abilities.push(reader.int32());
                                } else
                                    message.Abilities.push(reader.int32());
                                break;
                            case 6:
                                message.feeSetting = $root.theplant.ec.service.accounting.FeeSetting.decode(reader, reader.uint32());
                                break;
                            case 7:
                                if (!(message.userShippingAddress && message.userShippingAddress.length))
                                    message.userShippingAddress = [];
                                message.userShippingAddress.push($root.theplant.ec.service.users.ShippingAddress.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                if (!(message.userCreditCards && message.userCreditCards.length))
                                    message.userCreditCards = [];
                                message.userCreditCards.push($root.theplant.ec.service.orders.CreditCard.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                message.isGuestUser = reader.bool();
                                break;
                            case 10:
                                message.myStore = $root.theplant.ec.service.stores.Store.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.isFreeGiftWrappingFee = reader.bool();
                                break;
                            case 13:
                                message.amazonpayShippingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 14:
                                if (!(message.amazonpayPaymentReference && message.amazonpayPaymentReference.length))
                                    message.amazonpayPaymentReference = [];
                                message.amazonpayPaymentReference.push(reader.string());
                                break;
                            case 15:
                                message.amazonpayRedirectUrl = reader.string();
                                break;
                            case 16:
                                message.amazonpayBillingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.decode(reader, reader.uint32());
                                break;
                            case 19:
                                message.points = reader.uint64();
                                break;
                            case 20:
                                message.rakutenPayRedirectHtml = reader.string();
                                break;
                            case 21:
                                message.payPayRedirectHtml = reader.string();
                                break;
                            case 22:
                                message.canUseRakutenPay = reader.bool();
                                break;
                            case 23:
                                message.smartPaySessionId = reader.string();
                                break;
                            case 24:
                                message.smartPayOrderId = reader.string();
                                break;
                            case 25:
                                message.smartPayRedirectUrl = reader.string();
                                break;
                            case 26:
                                message.rakutenPayTransactionId = reader.string();
                                break;
                            case 27:
                                message.payPayTransactionId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ValidationResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.ValidationResult} ValidationResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ValidationResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ValidationResult message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ValidationResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.error != null && message.hasOwnProperty("error")) {
                            var error = $root.proto.ValidationError.verify(message.error);
                            if (error)
                                return "error." + error;
                        }
                        if (message.order != null && message.hasOwnProperty("order")) {
                            var error = $root.theplant.ec.service.orders.Order.verify(message.order);
                            if (error)
                                return "order." + error;
                        }
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange")) {
                            var error = $root.theplant.ec.service.orders.DeliveryDateRange.verify(message.deliveryDateRange);
                            if (error)
                                return "deliveryDateRange." + error;
                        }
                        if (message.allStores != null && message.hasOwnProperty("allStores")) {
                            if (!Array.isArray(message.allStores))
                                return "allStores: array expected";
                            for (var i = 0; i < message.allStores.length; ++i) {
                                var error = $root.theplant.ec.service.stores.Store.verify(message.allStores[i]);
                                if (error)
                                    return "allStores." + error;
                            }
                        }
                        if (message.Abilities != null && message.hasOwnProperty("Abilities")) {
                            if (!Array.isArray(message.Abilities))
                                return "Abilities: array expected";
                            for (var i = 0; i < message.Abilities.length; ++i)
                                switch (message.Abilities[i]) {
                                default:
                                    return "Abilities: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                    break;
                                }
                        }
                        if (message.feeSetting != null && message.hasOwnProperty("feeSetting")) {
                            var error = $root.theplant.ec.service.accounting.FeeSetting.verify(message.feeSetting);
                            if (error)
                                return "feeSetting." + error;
                        }
                        if (message.userShippingAddress != null && message.hasOwnProperty("userShippingAddress")) {
                            if (!Array.isArray(message.userShippingAddress))
                                return "userShippingAddress: array expected";
                            for (var i = 0; i < message.userShippingAddress.length; ++i) {
                                var error = $root.theplant.ec.service.users.ShippingAddress.verify(message.userShippingAddress[i]);
                                if (error)
                                    return "userShippingAddress." + error;
                            }
                        }
                        if (message.userCreditCards != null && message.hasOwnProperty("userCreditCards")) {
                            if (!Array.isArray(message.userCreditCards))
                                return "userCreditCards: array expected";
                            for (var i = 0; i < message.userCreditCards.length; ++i) {
                                var error = $root.theplant.ec.service.orders.CreditCard.verify(message.userCreditCards[i]);
                                if (error)
                                    return "userCreditCards." + error;
                            }
                        }
                        if (message.isGuestUser != null && message.hasOwnProperty("isGuestUser"))
                            if (typeof message.isGuestUser !== "boolean")
                                return "isGuestUser: boolean expected";
                        if (message.myStore != null && message.hasOwnProperty("myStore")) {
                            var error = $root.theplant.ec.service.stores.Store.verify(message.myStore);
                            if (error)
                                return "myStore." + error;
                        }
                        if (message.isFreeGiftWrappingFee != null && message.hasOwnProperty("isFreeGiftWrappingFee"))
                            if (typeof message.isFreeGiftWrappingFee !== "boolean")
                                return "isFreeGiftWrappingFee: boolean expected";
                        if (message.amazonpayShippingAddress != null && message.hasOwnProperty("amazonpayShippingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.amazonpayShippingAddress);
                            if (error)
                                return "amazonpayShippingAddress." + error;
                        }
                        if (message.amazonpayPaymentReference != null && message.hasOwnProperty("amazonpayPaymentReference")) {
                            if (!Array.isArray(message.amazonpayPaymentReference))
                                return "amazonpayPaymentReference: array expected";
                            for (var i = 0; i < message.amazonpayPaymentReference.length; ++i)
                                if (!$util.isString(message.amazonpayPaymentReference[i]))
                                    return "amazonpayPaymentReference: string[] expected";
                        }
                        if (message.amazonpayRedirectUrl != null && message.hasOwnProperty("amazonpayRedirectUrl"))
                            if (!$util.isString(message.amazonpayRedirectUrl))
                                return "amazonpayRedirectUrl: string expected";
                        if (message.amazonpayBillingAddress != null && message.hasOwnProperty("amazonpayBillingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.amazonpayBillingAddress);
                            if (error)
                                return "amazonpayBillingAddress." + error;
                        }
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo")) {
                            var error = $root.theplant.ec.service.orders.DeliveryInfo.verify(message.deliveryInfo);
                            if (error)
                                return "deliveryInfo." + error;
                        }
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping")) {
                            var error = $root.theplant.ec.service.orders.GiftWrapping.verify(message.giftWrapping);
                            if (error)
                                return "giftWrapping." + error;
                        }
                        if (message.points != null && message.hasOwnProperty("points"))
                            if (!$util.isInteger(message.points) && !(message.points && $util.isInteger(message.points.low) && $util.isInteger(message.points.high)))
                                return "points: integer|Long expected";
                        if (message.rakutenPayRedirectHtml != null && message.hasOwnProperty("rakutenPayRedirectHtml"))
                            if (!$util.isString(message.rakutenPayRedirectHtml))
                                return "rakutenPayRedirectHtml: string expected";
                        if (message.payPayRedirectHtml != null && message.hasOwnProperty("payPayRedirectHtml"))
                            if (!$util.isString(message.payPayRedirectHtml))
                                return "payPayRedirectHtml: string expected";
                        if (message.canUseRakutenPay != null && message.hasOwnProperty("canUseRakutenPay"))
                            if (typeof message.canUseRakutenPay !== "boolean")
                                return "canUseRakutenPay: boolean expected";
                        if (message.smartPaySessionId != null && message.hasOwnProperty("smartPaySessionId"))
                            if (!$util.isString(message.smartPaySessionId))
                                return "smartPaySessionId: string expected";
                        if (message.smartPayOrderId != null && message.hasOwnProperty("smartPayOrderId"))
                            if (!$util.isString(message.smartPayOrderId))
                                return "smartPayOrderId: string expected";
                        if (message.smartPayRedirectUrl != null && message.hasOwnProperty("smartPayRedirectUrl"))
                            if (!$util.isString(message.smartPayRedirectUrl))
                                return "smartPayRedirectUrl: string expected";
                        if (message.rakutenPayTransactionId != null && message.hasOwnProperty("rakutenPayTransactionId"))
                            if (!$util.isString(message.rakutenPayTransactionId))
                                return "rakutenPayTransactionId: string expected";
                        if (message.payPayTransactionId != null && message.hasOwnProperty("payPayTransactionId"))
                            if (!$util.isString(message.payPayTransactionId))
                                return "payPayTransactionId: string expected";
                        return null;
                    };

                    /**
                     * Creates a ValidationResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.ValidationResult} ValidationResult
                     */
                    ValidationResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.ValidationResult)
                            return object;
                        var message = new $root.theplant.ec.api.orders.ValidationResult();
                        if (object.error != null) {
                            if (typeof object.error !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.error: object expected");
                            message.error = $root.proto.ValidationError.fromObject(object.error);
                        }
                        if (object.order != null) {
                            if (typeof object.order !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.order: object expected");
                            message.order = $root.theplant.ec.service.orders.Order.fromObject(object.order);
                        }
                        if (object.deliveryDateRange != null) {
                            if (typeof object.deliveryDateRange !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.deliveryDateRange: object expected");
                            message.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.fromObject(object.deliveryDateRange);
                        }
                        if (object.allStores) {
                            if (!Array.isArray(object.allStores))
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.allStores: array expected");
                            message.allStores = [];
                            for (var i = 0; i < object.allStores.length; ++i) {
                                if (typeof object.allStores[i] !== "object")
                                    throw TypeError(".theplant.ec.api.orders.ValidationResult.allStores: object expected");
                                message.allStores[i] = $root.theplant.ec.service.stores.Store.fromObject(object.allStores[i]);
                            }
                        }
                        if (object.Abilities) {
                            if (!Array.isArray(object.Abilities))
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.Abilities: array expected");
                            message.Abilities = [];
                            for (var i = 0; i < object.Abilities.length; ++i)
                                switch (object.Abilities[i]) {
                                default:
                                case "CAN_GIFT_WRAPPING":
                                case 0:
                                    message.Abilities[i] = 0;
                                    break;
                                case "CAN_DELIVERY_DATE":
                                case 1:
                                    message.Abilities[i] = 1;
                                    break;
                                case "CAN_STORE_PICKUP":
                                case 2:
                                    message.Abilities[i] = 2;
                                    break;
                                case "CAN_APPLY_POINT":
                                case 3:
                                    message.Abilities[i] = 3;
                                    break;
                                case "CAN_APPLY_COD":
                                case 4:
                                    message.Abilities[i] = 4;
                                    break;
                                case "CAN_REGISTER":
                                case 5:
                                    message.Abilities[i] = 5;
                                    break;
                                case "CAN_LOGIN":
                                case 6:
                                    message.Abilities[i] = 6;
                                    break;
                                case "CAN_APPLY_COUPON":
                                case 7:
                                    message.Abilities[i] = 7;
                                    break;
                                case "CAN_CONVENIENCE_PICKUP":
                                case 8:
                                    message.Abilities[i] = 8;
                                    break;
                                case "CAN_APPLY_AMAZONPAY":
                                case 9:
                                    message.Abilities[i] = 9;
                                    break;
                                case "ENABLE_CUSTOMIZATION":
                                case 10:
                                    message.Abilities[i] = 10;
                                    break;
                                case "CAN_APPLY_SMARTPAY":
                                case 11:
                                    message.Abilities[i] = 11;
                                    break;
                                case "CAN_APPLY_RAKUTENPAY":
                                case 12:
                                    message.Abilities[i] = 12;
                                    break;
                                case "CAN_APPLY_PAYPAY":
                                case 13:
                                    message.Abilities[i] = 13;
                                    break;
                                }
                        }
                        if (object.feeSetting != null) {
                            if (typeof object.feeSetting !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.feeSetting: object expected");
                            message.feeSetting = $root.theplant.ec.service.accounting.FeeSetting.fromObject(object.feeSetting);
                        }
                        if (object.userShippingAddress) {
                            if (!Array.isArray(object.userShippingAddress))
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.userShippingAddress: array expected");
                            message.userShippingAddress = [];
                            for (var i = 0; i < object.userShippingAddress.length; ++i) {
                                if (typeof object.userShippingAddress[i] !== "object")
                                    throw TypeError(".theplant.ec.api.orders.ValidationResult.userShippingAddress: object expected");
                                message.userShippingAddress[i] = $root.theplant.ec.service.users.ShippingAddress.fromObject(object.userShippingAddress[i]);
                            }
                        }
                        if (object.userCreditCards) {
                            if (!Array.isArray(object.userCreditCards))
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.userCreditCards: array expected");
                            message.userCreditCards = [];
                            for (var i = 0; i < object.userCreditCards.length; ++i) {
                                if (typeof object.userCreditCards[i] !== "object")
                                    throw TypeError(".theplant.ec.api.orders.ValidationResult.userCreditCards: object expected");
                                message.userCreditCards[i] = $root.theplant.ec.service.orders.CreditCard.fromObject(object.userCreditCards[i]);
                            }
                        }
                        if (object.isGuestUser != null)
                            message.isGuestUser = Boolean(object.isGuestUser);
                        if (object.myStore != null) {
                            if (typeof object.myStore !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.myStore: object expected");
                            message.myStore = $root.theplant.ec.service.stores.Store.fromObject(object.myStore);
                        }
                        if (object.isFreeGiftWrappingFee != null)
                            message.isFreeGiftWrappingFee = Boolean(object.isFreeGiftWrappingFee);
                        if (object.amazonpayShippingAddress != null) {
                            if (typeof object.amazonpayShippingAddress !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.amazonpayShippingAddress: object expected");
                            message.amazonpayShippingAddress = $root.theplant.ec.service.users.Address.fromObject(object.amazonpayShippingAddress);
                        }
                        if (object.amazonpayPaymentReference) {
                            if (!Array.isArray(object.amazonpayPaymentReference))
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.amazonpayPaymentReference: array expected");
                            message.amazonpayPaymentReference = [];
                            for (var i = 0; i < object.amazonpayPaymentReference.length; ++i)
                                message.amazonpayPaymentReference[i] = String(object.amazonpayPaymentReference[i]);
                        }
                        if (object.amazonpayRedirectUrl != null)
                            message.amazonpayRedirectUrl = String(object.amazonpayRedirectUrl);
                        if (object.amazonpayBillingAddress != null) {
                            if (typeof object.amazonpayBillingAddress !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.amazonpayBillingAddress: object expected");
                            message.amazonpayBillingAddress = $root.theplant.ec.service.users.Address.fromObject(object.amazonpayBillingAddress);
                        }
                        if (object.deliveryInfo != null) {
                            if (typeof object.deliveryInfo !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.deliveryInfo: object expected");
                            message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.fromObject(object.deliveryInfo);
                        }
                        if (object.giftWrapping != null) {
                            if (typeof object.giftWrapping !== "object")
                                throw TypeError(".theplant.ec.api.orders.ValidationResult.giftWrapping: object expected");
                            message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.fromObject(object.giftWrapping);
                        }
                        if (object.points != null)
                            if ($util.Long)
                                (message.points = $util.Long.fromValue(object.points)).unsigned = true;
                            else if (typeof object.points === "string")
                                message.points = parseInt(object.points, 10);
                            else if (typeof object.points === "number")
                                message.points = object.points;
                            else if (typeof object.points === "object")
                                message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber(true);
                        if (object.rakutenPayRedirectHtml != null)
                            message.rakutenPayRedirectHtml = String(object.rakutenPayRedirectHtml);
                        if (object.payPayRedirectHtml != null)
                            message.payPayRedirectHtml = String(object.payPayRedirectHtml);
                        if (object.canUseRakutenPay != null)
                            message.canUseRakutenPay = Boolean(object.canUseRakutenPay);
                        if (object.smartPaySessionId != null)
                            message.smartPaySessionId = String(object.smartPaySessionId);
                        if (object.smartPayOrderId != null)
                            message.smartPayOrderId = String(object.smartPayOrderId);
                        if (object.smartPayRedirectUrl != null)
                            message.smartPayRedirectUrl = String(object.smartPayRedirectUrl);
                        if (object.rakutenPayTransactionId != null)
                            message.rakutenPayTransactionId = String(object.rakutenPayTransactionId);
                        if (object.payPayTransactionId != null)
                            message.payPayTransactionId = String(object.payPayTransactionId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ValidationResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @static
                     * @param {theplant.ec.api.orders.ValidationResult} message ValidationResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ValidationResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.allStores = [];
                            object.Abilities = [];
                            object.userShippingAddress = [];
                            object.userCreditCards = [];
                            object.amazonpayPaymentReference = [];
                        }
                        if (options.defaults) {
                            object.error = null;
                            object.order = null;
                            object.deliveryDateRange = null;
                            object.feeSetting = null;
                            object.isGuestUser = false;
                            object.myStore = null;
                            object.isFreeGiftWrappingFee = false;
                            object.amazonpayShippingAddress = null;
                            object.amazonpayRedirectUrl = "";
                            object.amazonpayBillingAddress = null;
                            object.deliveryInfo = null;
                            object.giftWrapping = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.points = options.longs === String ? "0" : 0;
                            object.rakutenPayRedirectHtml = "";
                            object.payPayRedirectHtml = "";
                            object.canUseRakutenPay = false;
                            object.smartPaySessionId = "";
                            object.smartPayOrderId = "";
                            object.smartPayRedirectUrl = "";
                            object.rakutenPayTransactionId = "";
                            object.payPayTransactionId = "";
                        }
                        if (message.error != null && message.hasOwnProperty("error"))
                            object.error = $root.proto.ValidationError.toObject(message.error, options);
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = $root.theplant.ec.service.orders.Order.toObject(message.order, options);
                        if (message.deliveryDateRange != null && message.hasOwnProperty("deliveryDateRange"))
                            object.deliveryDateRange = $root.theplant.ec.service.orders.DeliveryDateRange.toObject(message.deliveryDateRange, options);
                        if (message.allStores && message.allStores.length) {
                            object.allStores = [];
                            for (var j = 0; j < message.allStores.length; ++j)
                                object.allStores[j] = $root.theplant.ec.service.stores.Store.toObject(message.allStores[j], options);
                        }
                        if (message.Abilities && message.Abilities.length) {
                            object.Abilities = [];
                            for (var j = 0; j < message.Abilities.length; ++j)
                                object.Abilities[j] = options.enums === String ? $root.theplant.ec.api.orders.Ability[message.Abilities[j]] : message.Abilities[j];
                        }
                        if (message.feeSetting != null && message.hasOwnProperty("feeSetting"))
                            object.feeSetting = $root.theplant.ec.service.accounting.FeeSetting.toObject(message.feeSetting, options);
                        if (message.userShippingAddress && message.userShippingAddress.length) {
                            object.userShippingAddress = [];
                            for (var j = 0; j < message.userShippingAddress.length; ++j)
                                object.userShippingAddress[j] = $root.theplant.ec.service.users.ShippingAddress.toObject(message.userShippingAddress[j], options);
                        }
                        if (message.userCreditCards && message.userCreditCards.length) {
                            object.userCreditCards = [];
                            for (var j = 0; j < message.userCreditCards.length; ++j)
                                object.userCreditCards[j] = $root.theplant.ec.service.orders.CreditCard.toObject(message.userCreditCards[j], options);
                        }
                        if (message.isGuestUser != null && message.hasOwnProperty("isGuestUser"))
                            object.isGuestUser = message.isGuestUser;
                        if (message.myStore != null && message.hasOwnProperty("myStore"))
                            object.myStore = $root.theplant.ec.service.stores.Store.toObject(message.myStore, options);
                        if (message.isFreeGiftWrappingFee != null && message.hasOwnProperty("isFreeGiftWrappingFee"))
                            object.isFreeGiftWrappingFee = message.isFreeGiftWrappingFee;
                        if (message.amazonpayShippingAddress != null && message.hasOwnProperty("amazonpayShippingAddress"))
                            object.amazonpayShippingAddress = $root.theplant.ec.service.users.Address.toObject(message.amazonpayShippingAddress, options);
                        if (message.amazonpayPaymentReference && message.amazonpayPaymentReference.length) {
                            object.amazonpayPaymentReference = [];
                            for (var j = 0; j < message.amazonpayPaymentReference.length; ++j)
                                object.amazonpayPaymentReference[j] = message.amazonpayPaymentReference[j];
                        }
                        if (message.amazonpayRedirectUrl != null && message.hasOwnProperty("amazonpayRedirectUrl"))
                            object.amazonpayRedirectUrl = message.amazonpayRedirectUrl;
                        if (message.amazonpayBillingAddress != null && message.hasOwnProperty("amazonpayBillingAddress"))
                            object.amazonpayBillingAddress = $root.theplant.ec.service.users.Address.toObject(message.amazonpayBillingAddress, options);
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            object.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.toObject(message.deliveryInfo, options);
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            object.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.toObject(message.giftWrapping, options);
                        if (message.points != null && message.hasOwnProperty("points"))
                            if (typeof message.points === "number")
                                object.points = options.longs === String ? String(message.points) : message.points;
                            else
                                object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber(true) : message.points;
                        if (message.rakutenPayRedirectHtml != null && message.hasOwnProperty("rakutenPayRedirectHtml"))
                            object.rakutenPayRedirectHtml = message.rakutenPayRedirectHtml;
                        if (message.payPayRedirectHtml != null && message.hasOwnProperty("payPayRedirectHtml"))
                            object.payPayRedirectHtml = message.payPayRedirectHtml;
                        if (message.canUseRakutenPay != null && message.hasOwnProperty("canUseRakutenPay"))
                            object.canUseRakutenPay = message.canUseRakutenPay;
                        if (message.smartPaySessionId != null && message.hasOwnProperty("smartPaySessionId"))
                            object.smartPaySessionId = message.smartPaySessionId;
                        if (message.smartPayOrderId != null && message.hasOwnProperty("smartPayOrderId"))
                            object.smartPayOrderId = message.smartPayOrderId;
                        if (message.smartPayRedirectUrl != null && message.hasOwnProperty("smartPayRedirectUrl"))
                            object.smartPayRedirectUrl = message.smartPayRedirectUrl;
                        if (message.rakutenPayTransactionId != null && message.hasOwnProperty("rakutenPayTransactionId"))
                            object.rakutenPayTransactionId = message.rakutenPayTransactionId;
                        if (message.payPayTransactionId != null && message.hasOwnProperty("payPayTransactionId"))
                            object.payPayTransactionId = message.payPayTransactionId;
                        return object;
                    };

                    /**
                     * Converts this ValidationResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.ValidationResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ValidationResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ValidationResult;
                })();

                orders.GetCartResult = (function() {

                    /**
                     * Properties of a GetCartResult.
                     * @memberof theplant.ec.api.orders
                     * @interface IGetCartResult
                     * @property {proto.IValidationError|null} [error] GetCartResult error
                     * @property {theplant.ec.service.orders.IOrder|null} [order] GetCartResult order
                     * @property {Array.<theplant.ec.api.orders.Ability>|null} [Abilities] GetCartResult Abilities
                     * @property {boolean|null} [isGuestUser] GetCartResult isGuestUser
                     */

                    /**
                     * Constructs a new GetCartResult.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a GetCartResult.
                     * @implements IGetCartResult
                     * @constructor
                     * @param {theplant.ec.api.orders.IGetCartResult=} [properties] Properties to set
                     */
                    function GetCartResult(properties) {
                        this.Abilities = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetCartResult error.
                     * @member {proto.IValidationError|null|undefined} error
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @instance
                     */
                    GetCartResult.prototype.error = null;

                    /**
                     * GetCartResult order.
                     * @member {theplant.ec.service.orders.IOrder|null|undefined} order
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @instance
                     */
                    GetCartResult.prototype.order = null;

                    /**
                     * GetCartResult Abilities.
                     * @member {Array.<theplant.ec.api.orders.Ability>} Abilities
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @instance
                     */
                    GetCartResult.prototype.Abilities = $util.emptyArray;

                    /**
                     * GetCartResult isGuestUser.
                     * @member {boolean} isGuestUser
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @instance
                     */
                    GetCartResult.prototype.isGuestUser = false;

                    /**
                     * Creates a new GetCartResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {theplant.ec.api.orders.IGetCartResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.GetCartResult} GetCartResult instance
                     */
                    GetCartResult.create = function create(properties) {
                        return new GetCartResult(properties);
                    };

                    /**
                     * Encodes the specified GetCartResult message. Does not implicitly {@link theplant.ec.api.orders.GetCartResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {theplant.ec.api.orders.IGetCartResult} message GetCartResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCartResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.order != null && message.hasOwnProperty("order"))
                            $root.theplant.ec.service.orders.Order.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.Abilities != null && message.Abilities.length) {
                            writer.uint32(/* id 2, wireType 2 =*/18).fork();
                            for (var i = 0; i < message.Abilities.length; ++i)
                                writer.int32(message.Abilities[i]);
                            writer.ldelim();
                        }
                        if (message.isGuestUser != null && message.hasOwnProperty("isGuestUser"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isGuestUser);
                        if (message.error != null && message.hasOwnProperty("error"))
                            $root.proto.ValidationError.encode(message.error, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetCartResult message, length delimited. Does not implicitly {@link theplant.ec.api.orders.GetCartResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {theplant.ec.api.orders.IGetCartResult} message GetCartResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetCartResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetCartResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.GetCartResult} GetCartResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCartResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.GetCartResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 4:
                                message.error = $root.proto.ValidationError.decode(reader, reader.uint32());
                                break;
                            case 1:
                                message.order = $root.theplant.ec.service.orders.Order.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.Abilities && message.Abilities.length))
                                    message.Abilities = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.Abilities.push(reader.int32());
                                } else
                                    message.Abilities.push(reader.int32());
                                break;
                            case 3:
                                message.isGuestUser = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetCartResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.GetCartResult} GetCartResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetCartResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetCartResult message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetCartResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.error != null && message.hasOwnProperty("error")) {
                            var error = $root.proto.ValidationError.verify(message.error);
                            if (error)
                                return "error." + error;
                        }
                        if (message.order != null && message.hasOwnProperty("order")) {
                            var error = $root.theplant.ec.service.orders.Order.verify(message.order);
                            if (error)
                                return "order." + error;
                        }
                        if (message.Abilities != null && message.hasOwnProperty("Abilities")) {
                            if (!Array.isArray(message.Abilities))
                                return "Abilities: array expected";
                            for (var i = 0; i < message.Abilities.length; ++i)
                                switch (message.Abilities[i]) {
                                default:
                                    return "Abilities: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                case 13:
                                    break;
                                }
                        }
                        if (message.isGuestUser != null && message.hasOwnProperty("isGuestUser"))
                            if (typeof message.isGuestUser !== "boolean")
                                return "isGuestUser: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a GetCartResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.GetCartResult} GetCartResult
                     */
                    GetCartResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.GetCartResult)
                            return object;
                        var message = new $root.theplant.ec.api.orders.GetCartResult();
                        if (object.error != null) {
                            if (typeof object.error !== "object")
                                throw TypeError(".theplant.ec.api.orders.GetCartResult.error: object expected");
                            message.error = $root.proto.ValidationError.fromObject(object.error);
                        }
                        if (object.order != null) {
                            if (typeof object.order !== "object")
                                throw TypeError(".theplant.ec.api.orders.GetCartResult.order: object expected");
                            message.order = $root.theplant.ec.service.orders.Order.fromObject(object.order);
                        }
                        if (object.Abilities) {
                            if (!Array.isArray(object.Abilities))
                                throw TypeError(".theplant.ec.api.orders.GetCartResult.Abilities: array expected");
                            message.Abilities = [];
                            for (var i = 0; i < object.Abilities.length; ++i)
                                switch (object.Abilities[i]) {
                                default:
                                case "CAN_GIFT_WRAPPING":
                                case 0:
                                    message.Abilities[i] = 0;
                                    break;
                                case "CAN_DELIVERY_DATE":
                                case 1:
                                    message.Abilities[i] = 1;
                                    break;
                                case "CAN_STORE_PICKUP":
                                case 2:
                                    message.Abilities[i] = 2;
                                    break;
                                case "CAN_APPLY_POINT":
                                case 3:
                                    message.Abilities[i] = 3;
                                    break;
                                case "CAN_APPLY_COD":
                                case 4:
                                    message.Abilities[i] = 4;
                                    break;
                                case "CAN_REGISTER":
                                case 5:
                                    message.Abilities[i] = 5;
                                    break;
                                case "CAN_LOGIN":
                                case 6:
                                    message.Abilities[i] = 6;
                                    break;
                                case "CAN_APPLY_COUPON":
                                case 7:
                                    message.Abilities[i] = 7;
                                    break;
                                case "CAN_CONVENIENCE_PICKUP":
                                case 8:
                                    message.Abilities[i] = 8;
                                    break;
                                case "CAN_APPLY_AMAZONPAY":
                                case 9:
                                    message.Abilities[i] = 9;
                                    break;
                                case "ENABLE_CUSTOMIZATION":
                                case 10:
                                    message.Abilities[i] = 10;
                                    break;
                                case "CAN_APPLY_SMARTPAY":
                                case 11:
                                    message.Abilities[i] = 11;
                                    break;
                                case "CAN_APPLY_RAKUTENPAY":
                                case 12:
                                    message.Abilities[i] = 12;
                                    break;
                                case "CAN_APPLY_PAYPAY":
                                case 13:
                                    message.Abilities[i] = 13;
                                    break;
                                }
                        }
                        if (object.isGuestUser != null)
                            message.isGuestUser = Boolean(object.isGuestUser);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetCartResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @static
                     * @param {theplant.ec.api.orders.GetCartResult} message GetCartResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetCartResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.Abilities = [];
                        if (options.defaults) {
                            object.order = null;
                            object.isGuestUser = false;
                            object.error = null;
                        }
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = $root.theplant.ec.service.orders.Order.toObject(message.order, options);
                        if (message.Abilities && message.Abilities.length) {
                            object.Abilities = [];
                            for (var j = 0; j < message.Abilities.length; ++j)
                                object.Abilities[j] = options.enums === String ? $root.theplant.ec.api.orders.Ability[message.Abilities[j]] : message.Abilities[j];
                        }
                        if (message.isGuestUser != null && message.hasOwnProperty("isGuestUser"))
                            object.isGuestUser = message.isGuestUser;
                        if (message.error != null && message.hasOwnProperty("error"))
                            object.error = $root.proto.ValidationError.toObject(message.error, options);
                        return object;
                    };

                    /**
                     * Converts this GetCartResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.GetCartResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetCartResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetCartResult;
                })();

                /**
                 * Ability enum.
                 * @name theplant.ec.api.orders.Ability
                 * @enum {string}
                 * @property {number} CAN_GIFT_WRAPPING=0 CAN_GIFT_WRAPPING value
                 * @property {number} CAN_DELIVERY_DATE=1 CAN_DELIVERY_DATE value
                 * @property {number} CAN_STORE_PICKUP=2 CAN_STORE_PICKUP value
                 * @property {number} CAN_APPLY_POINT=3 CAN_APPLY_POINT value
                 * @property {number} CAN_APPLY_COD=4 CAN_APPLY_COD value
                 * @property {number} CAN_REGISTER=5 CAN_REGISTER value
                 * @property {number} CAN_LOGIN=6 CAN_LOGIN value
                 * @property {number} CAN_APPLY_COUPON=7 CAN_APPLY_COUPON value
                 * @property {number} CAN_CONVENIENCE_PICKUP=8 CAN_CONVENIENCE_PICKUP value
                 * @property {number} CAN_APPLY_AMAZONPAY=9 CAN_APPLY_AMAZONPAY value
                 * @property {number} ENABLE_CUSTOMIZATION=10 ENABLE_CUSTOMIZATION value
                 * @property {number} CAN_APPLY_SMARTPAY=11 CAN_APPLY_SMARTPAY value
                 * @property {number} CAN_APPLY_RAKUTENPAY=12 CAN_APPLY_RAKUTENPAY value
                 * @property {number} CAN_APPLY_PAYPAY=13 CAN_APPLY_PAYPAY value
                 */
                orders.Ability = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CAN_GIFT_WRAPPING"] = 0;
                    values[valuesById[1] = "CAN_DELIVERY_DATE"] = 1;
                    values[valuesById[2] = "CAN_STORE_PICKUP"] = 2;
                    values[valuesById[3] = "CAN_APPLY_POINT"] = 3;
                    values[valuesById[4] = "CAN_APPLY_COD"] = 4;
                    values[valuesById[5] = "CAN_REGISTER"] = 5;
                    values[valuesById[6] = "CAN_LOGIN"] = 6;
                    values[valuesById[7] = "CAN_APPLY_COUPON"] = 7;
                    values[valuesById[8] = "CAN_CONVENIENCE_PICKUP"] = 8;
                    values[valuesById[9] = "CAN_APPLY_AMAZONPAY"] = 9;
                    values[valuesById[10] = "ENABLE_CUSTOMIZATION"] = 10;
                    values[valuesById[11] = "CAN_APPLY_SMARTPAY"] = 11;
                    values[valuesById[12] = "CAN_APPLY_RAKUTENPAY"] = 12;
                    values[valuesById[13] = "CAN_APPLY_PAYPAY"] = 13;
                    return values;
                })();

                orders.OrderCode = (function() {

                    /**
                     * Properties of an OrderCode.
                     * @memberof theplant.ec.api.orders
                     * @interface IOrderCode
                     * @property {string|null} [code] OrderCode code
                     */

                    /**
                     * Constructs a new OrderCode.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents an OrderCode.
                     * @implements IOrderCode
                     * @constructor
                     * @param {theplant.ec.api.orders.IOrderCode=} [properties] Properties to set
                     */
                    function OrderCode(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderCode code.
                     * @member {string} code
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @instance
                     */
                    OrderCode.prototype.code = "";

                    /**
                     * Creates a new OrderCode instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {theplant.ec.api.orders.IOrderCode=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.OrderCode} OrderCode instance
                     */
                    OrderCode.create = function create(properties) {
                        return new OrderCode(properties);
                    };

                    /**
                     * Encodes the specified OrderCode message. Does not implicitly {@link theplant.ec.api.orders.OrderCode.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {theplant.ec.api.orders.IOrderCode} message OrderCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCode.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderCode message, length delimited. Does not implicitly {@link theplant.ec.api.orders.OrderCode.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {theplant.ec.api.orders.IOrderCode} message OrderCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCode.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderCode message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.OrderCode} OrderCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCode.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.OrderCode();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderCode message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.OrderCode} OrderCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCode.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderCode message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderCode.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        return null;
                    };

                    /**
                     * Creates an OrderCode message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.OrderCode} OrderCode
                     */
                    OrderCode.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.OrderCode)
                            return object;
                        var message = new $root.theplant.ec.api.orders.OrderCode();
                        if (object.code != null)
                            message.code = String(object.code);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderCode message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @static
                     * @param {theplant.ec.api.orders.OrderCode} message OrderCode
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderCode.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.code = "";
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        return object;
                    };

                    /**
                     * Converts this OrderCode to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.OrderCode
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderCode.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderCode;
                })();

                orders.GetOrderInput = (function() {

                    /**
                     * Properties of a GetOrderInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IGetOrderInput
                     * @property {string|null} [orderCode] GetOrderInput orderCode
                     * @property {string|null} [email] GetOrderInput email
                     */

                    /**
                     * Constructs a new GetOrderInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a GetOrderInput.
                     * @implements IGetOrderInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IGetOrderInput=} [properties] Properties to set
                     */
                    function GetOrderInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetOrderInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @instance
                     */
                    GetOrderInput.prototype.orderCode = "";

                    /**
                     * GetOrderInput email.
                     * @member {string} email
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @instance
                     */
                    GetOrderInput.prototype.email = "";

                    /**
                     * Creates a new GetOrderInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.IGetOrderInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.GetOrderInput} GetOrderInput instance
                     */
                    GetOrderInput.create = function create(properties) {
                        return new GetOrderInput(properties);
                    };

                    /**
                     * Encodes the specified GetOrderInput message. Does not implicitly {@link theplant.ec.api.orders.GetOrderInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.IGetOrderInput} message GetOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetOrderInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetOrderInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.GetOrderInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.IGetOrderInput} message GetOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetOrderInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetOrderInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.GetOrderInput} GetOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetOrderInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.GetOrderInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.email = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetOrderInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.GetOrderInput} GetOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetOrderInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetOrderInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetOrderInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        return null;
                    };

                    /**
                     * Creates a GetOrderInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.GetOrderInput} GetOrderInput
                     */
                    GetOrderInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.GetOrderInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.GetOrderInput();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.email != null)
                            message.email = String(object.email);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetOrderInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.GetOrderInput} message GetOrderInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetOrderInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.email = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        return object;
                    };

                    /**
                     * Converts this GetOrderInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.GetOrderInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetOrderInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetOrderInput;
                })();

                orders.RequestReturnInput = (function() {

                    /**
                     * Properties of a RequestReturnInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IRequestReturnInput
                     * @property {string|null} [orderCode] RequestReturnInput orderCode
                     * @property {Array.<theplant.ec.service.orders.IRequestReturn>|null} [requestReturn] RequestReturnInput requestReturn
                     */

                    /**
                     * Constructs a new RequestReturnInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a RequestReturnInput.
                     * @implements IRequestReturnInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IRequestReturnInput=} [properties] Properties to set
                     */
                    function RequestReturnInput(properties) {
                        this.requestReturn = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RequestReturnInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @instance
                     */
                    RequestReturnInput.prototype.orderCode = "";

                    /**
                     * RequestReturnInput requestReturn.
                     * @member {Array.<theplant.ec.service.orders.IRequestReturn>} requestReturn
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @instance
                     */
                    RequestReturnInput.prototype.requestReturn = $util.emptyArray;

                    /**
                     * Creates a new RequestReturnInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {theplant.ec.api.orders.IRequestReturnInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.RequestReturnInput} RequestReturnInput instance
                     */
                    RequestReturnInput.create = function create(properties) {
                        return new RequestReturnInput(properties);
                    };

                    /**
                     * Encodes the specified RequestReturnInput message. Does not implicitly {@link theplant.ec.api.orders.RequestReturnInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {theplant.ec.api.orders.IRequestReturnInput} message RequestReturnInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestReturnInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.requestReturn != null && message.requestReturn.length)
                            for (var i = 0; i < message.requestReturn.length; ++i)
                                $root.theplant.ec.service.orders.RequestReturn.encode(message.requestReturn[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RequestReturnInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.RequestReturnInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {theplant.ec.api.orders.IRequestReturnInput} message RequestReturnInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestReturnInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RequestReturnInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.RequestReturnInput} RequestReturnInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestReturnInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.RequestReturnInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                if (!(message.requestReturn && message.requestReturn.length))
                                    message.requestReturn = [];
                                message.requestReturn.push($root.theplant.ec.service.orders.RequestReturn.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RequestReturnInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.RequestReturnInput} RequestReturnInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestReturnInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RequestReturnInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RequestReturnInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.requestReturn != null && message.hasOwnProperty("requestReturn")) {
                            if (!Array.isArray(message.requestReturn))
                                return "requestReturn: array expected";
                            for (var i = 0; i < message.requestReturn.length; ++i) {
                                var error = $root.theplant.ec.service.orders.RequestReturn.verify(message.requestReturn[i]);
                                if (error)
                                    return "requestReturn." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a RequestReturnInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.RequestReturnInput} RequestReturnInput
                     */
                    RequestReturnInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.RequestReturnInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.RequestReturnInput();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.requestReturn) {
                            if (!Array.isArray(object.requestReturn))
                                throw TypeError(".theplant.ec.api.orders.RequestReturnInput.requestReturn: array expected");
                            message.requestReturn = [];
                            for (var i = 0; i < object.requestReturn.length; ++i) {
                                if (typeof object.requestReturn[i] !== "object")
                                    throw TypeError(".theplant.ec.api.orders.RequestReturnInput.requestReturn: object expected");
                                message.requestReturn[i] = $root.theplant.ec.service.orders.RequestReturn.fromObject(object.requestReturn[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RequestReturnInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @static
                     * @param {theplant.ec.api.orders.RequestReturnInput} message RequestReturnInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RequestReturnInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.requestReturn = [];
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.requestReturn && message.requestReturn.length) {
                            object.requestReturn = [];
                            for (var j = 0; j < message.requestReturn.length; ++j)
                                object.requestReturn[j] = $root.theplant.ec.service.orders.RequestReturn.toObject(message.requestReturn[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this RequestReturnInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.RequestReturnInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RequestReturnInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RequestReturnInput;
                })();

                orders.MyOrdersInput = (function() {

                    /**
                     * Properties of a MyOrdersInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IMyOrdersInput
                     * @property {number|Long|null} [page] MyOrdersInput page
                     * @property {number|Long|null} [size] MyOrdersInput size
                     */

                    /**
                     * Constructs a new MyOrdersInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a MyOrdersInput.
                     * @implements IMyOrdersInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IMyOrdersInput=} [properties] Properties to set
                     */
                    function MyOrdersInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MyOrdersInput page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @instance
                     */
                    MyOrdersInput.prototype.page = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * MyOrdersInput size.
                     * @member {number|Long} size
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @instance
                     */
                    MyOrdersInput.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new MyOrdersInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {theplant.ec.api.orders.IMyOrdersInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.MyOrdersInput} MyOrdersInput instance
                     */
                    MyOrdersInput.create = function create(properties) {
                        return new MyOrdersInput(properties);
                    };

                    /**
                     * Encodes the specified MyOrdersInput message. Does not implicitly {@link theplant.ec.api.orders.MyOrdersInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {theplant.ec.api.orders.IMyOrdersInput} message MyOrdersInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MyOrdersInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.page);
                        if (message.size != null && message.hasOwnProperty("size"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.size);
                        return writer;
                    };

                    /**
                     * Encodes the specified MyOrdersInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.MyOrdersInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {theplant.ec.api.orders.IMyOrdersInput} message MyOrdersInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MyOrdersInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MyOrdersInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.MyOrdersInput} MyOrdersInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MyOrdersInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.MyOrdersInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.page = reader.uint64();
                                break;
                            case 2:
                                message.size = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MyOrdersInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.MyOrdersInput} MyOrdersInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MyOrdersInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MyOrdersInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MyOrdersInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                                return "size: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a MyOrdersInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.MyOrdersInput} MyOrdersInput
                     */
                    MyOrdersInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.MyOrdersInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.MyOrdersInput();
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = true;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber(true);
                        if (object.size != null)
                            if ($util.Long)
                                (message.size = $util.Long.fromValue(object.size)).unsigned = true;
                            else if (typeof object.size === "string")
                                message.size = parseInt(object.size, 10);
                            else if (typeof object.size === "number")
                                message.size = object.size;
                            else if (typeof object.size === "object")
                                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a MyOrdersInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @static
                     * @param {theplant.ec.api.orders.MyOrdersInput} message MyOrdersInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MyOrdersInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.size = options.longs === String ? "0" : 0;
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber(true) : message.page;
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (typeof message.size === "number")
                                object.size = options.longs === String ? String(message.size) : message.size;
                            else
                                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
                        return object;
                    };

                    /**
                     * Converts this MyOrdersInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.MyOrdersInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MyOrdersInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MyOrdersInput;
                })();

                orders.MyOrdersResult = (function() {

                    /**
                     * Properties of a MyOrdersResult.
                     * @memberof theplant.ec.api.orders
                     * @interface IMyOrdersResult
                     * @property {Array.<theplant.ec.service.orders.IOrder>|null} [order] MyOrdersResult order
                     */

                    /**
                     * Constructs a new MyOrdersResult.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a MyOrdersResult.
                     * @implements IMyOrdersResult
                     * @constructor
                     * @param {theplant.ec.api.orders.IMyOrdersResult=} [properties] Properties to set
                     */
                    function MyOrdersResult(properties) {
                        this.order = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MyOrdersResult order.
                     * @member {Array.<theplant.ec.service.orders.IOrder>} order
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @instance
                     */
                    MyOrdersResult.prototype.order = $util.emptyArray;

                    /**
                     * Creates a new MyOrdersResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {theplant.ec.api.orders.IMyOrdersResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.MyOrdersResult} MyOrdersResult instance
                     */
                    MyOrdersResult.create = function create(properties) {
                        return new MyOrdersResult(properties);
                    };

                    /**
                     * Encodes the specified MyOrdersResult message. Does not implicitly {@link theplant.ec.api.orders.MyOrdersResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {theplant.ec.api.orders.IMyOrdersResult} message MyOrdersResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MyOrdersResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.order != null && message.order.length)
                            for (var i = 0; i < message.order.length; ++i)
                                $root.theplant.ec.service.orders.Order.encode(message.order[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified MyOrdersResult message, length delimited. Does not implicitly {@link theplant.ec.api.orders.MyOrdersResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {theplant.ec.api.orders.IMyOrdersResult} message MyOrdersResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MyOrdersResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MyOrdersResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.MyOrdersResult} MyOrdersResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MyOrdersResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.MyOrdersResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.order && message.order.length))
                                    message.order = [];
                                message.order.push($root.theplant.ec.service.orders.Order.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MyOrdersResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.MyOrdersResult} MyOrdersResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MyOrdersResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MyOrdersResult message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MyOrdersResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.order != null && message.hasOwnProperty("order")) {
                            if (!Array.isArray(message.order))
                                return "order: array expected";
                            for (var i = 0; i < message.order.length; ++i) {
                                var error = $root.theplant.ec.service.orders.Order.verify(message.order[i]);
                                if (error)
                                    return "order." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a MyOrdersResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.MyOrdersResult} MyOrdersResult
                     */
                    MyOrdersResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.MyOrdersResult)
                            return object;
                        var message = new $root.theplant.ec.api.orders.MyOrdersResult();
                        if (object.order) {
                            if (!Array.isArray(object.order))
                                throw TypeError(".theplant.ec.api.orders.MyOrdersResult.order: array expected");
                            message.order = [];
                            for (var i = 0; i < object.order.length; ++i) {
                                if (typeof object.order[i] !== "object")
                                    throw TypeError(".theplant.ec.api.orders.MyOrdersResult.order: object expected");
                                message.order[i] = $root.theplant.ec.service.orders.Order.fromObject(object.order[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a MyOrdersResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @static
                     * @param {theplant.ec.api.orders.MyOrdersResult} message MyOrdersResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MyOrdersResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.order = [];
                        if (message.order && message.order.length) {
                            object.order = [];
                            for (var j = 0; j < message.order.length; ++j)
                                object.order[j] = $root.theplant.ec.service.orders.Order.toObject(message.order[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this MyOrdersResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.MyOrdersResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MyOrdersResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MyOrdersResult;
                })();

                orders.VariantInput = (function() {

                    /**
                     * Properties of a VariantInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IVariantInput
                     * @property {string|null} [oldArticleCode] VariantInput oldArticleCode
                     * @property {string|null} [newArticleCode] VariantInput newArticleCode
                     * @property {number|Long|null} [quantity] VariantInput quantity
                     */

                    /**
                     * Constructs a new VariantInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a VariantInput.
                     * @implements IVariantInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IVariantInput=} [properties] Properties to set
                     */
                    function VariantInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * VariantInput oldArticleCode.
                     * @member {string} oldArticleCode
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @instance
                     */
                    VariantInput.prototype.oldArticleCode = "";

                    /**
                     * VariantInput newArticleCode.
                     * @member {string} newArticleCode
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @instance
                     */
                    VariantInput.prototype.newArticleCode = "";

                    /**
                     * VariantInput quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @instance
                     */
                    VariantInput.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new VariantInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {theplant.ec.api.orders.IVariantInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.VariantInput} VariantInput instance
                     */
                    VariantInput.create = function create(properties) {
                        return new VariantInput(properties);
                    };

                    /**
                     * Encodes the specified VariantInput message. Does not implicitly {@link theplant.ec.api.orders.VariantInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {theplant.ec.api.orders.IVariantInput} message VariantInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VariantInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.oldArticleCode != null && message.hasOwnProperty("oldArticleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.oldArticleCode);
                        if (message.newArticleCode != null && message.hasOwnProperty("newArticleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.newArticleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.quantity);
                        return writer;
                    };

                    /**
                     * Encodes the specified VariantInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.VariantInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {theplant.ec.api.orders.IVariantInput} message VariantInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    VariantInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a VariantInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.VariantInput} VariantInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VariantInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.VariantInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.oldArticleCode = reader.string();
                                break;
                            case 2:
                                message.newArticleCode = reader.string();
                                break;
                            case 3:
                                message.quantity = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a VariantInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.VariantInput} VariantInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    VariantInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a VariantInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    VariantInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.oldArticleCode != null && message.hasOwnProperty("oldArticleCode"))
                            if (!$util.isString(message.oldArticleCode))
                                return "oldArticleCode: string expected";
                        if (message.newArticleCode != null && message.hasOwnProperty("newArticleCode"))
                            if (!$util.isString(message.newArticleCode))
                                return "newArticleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a VariantInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.VariantInput} VariantInput
                     */
                    VariantInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.VariantInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.VariantInput();
                        if (object.oldArticleCode != null)
                            message.oldArticleCode = String(object.oldArticleCode);
                        if (object.newArticleCode != null)
                            message.newArticleCode = String(object.newArticleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a VariantInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @static
                     * @param {theplant.ec.api.orders.VariantInput} message VariantInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    VariantInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.oldArticleCode = "";
                            object.newArticleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                        }
                        if (message.oldArticleCode != null && message.hasOwnProperty("oldArticleCode"))
                            object.oldArticleCode = message.oldArticleCode;
                        if (message.newArticleCode != null && message.hasOwnProperty("newArticleCode"))
                            object.newArticleCode = message.newArticleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        return object;
                    };

                    /**
                     * Converts this VariantInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.VariantInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    VariantInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return VariantInput;
                })();

                /**
                 * DeliveryMethod enum.
                 * @name theplant.ec.api.orders.DeliveryMethod
                 * @enum {string}
                 * @property {number} HOME_DELIVERY=0 HOME_DELIVERY value
                 * @property {number} STORE_PICKUP=1 STORE_PICKUP value
                 * @property {number} CONVENIENCE_PICKUP=2 CONVENIENCE_PICKUP value
                 */
                orders.DeliveryMethod = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "HOME_DELIVERY"] = 0;
                    values[valuesById[1] = "STORE_PICKUP"] = 1;
                    values[valuesById[2] = "CONVENIENCE_PICKUP"] = 2;
                    return values;
                })();

                orders.CouponInput = (function() {

                    /**
                     * Properties of a CouponInput.
                     * @memberof theplant.ec.api.orders
                     * @interface ICouponInput
                     * @property {string|null} [couponCode] CouponInput couponCode
                     */

                    /**
                     * Constructs a new CouponInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CouponInput.
                     * @implements ICouponInput
                     * @constructor
                     * @param {theplant.ec.api.orders.ICouponInput=} [properties] Properties to set
                     */
                    function CouponInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CouponInput couponCode.
                     * @member {string} couponCode
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @instance
                     */
                    CouponInput.prototype.couponCode = "";

                    /**
                     * Creates a new CouponInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {theplant.ec.api.orders.ICouponInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.CouponInput} CouponInput instance
                     */
                    CouponInput.create = function create(properties) {
                        return new CouponInput(properties);
                    };

                    /**
                     * Encodes the specified CouponInput message. Does not implicitly {@link theplant.ec.api.orders.CouponInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {theplant.ec.api.orders.ICouponInput} message CouponInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CouponInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.couponCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified CouponInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.CouponInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {theplant.ec.api.orders.ICouponInput} message CouponInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CouponInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CouponInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.CouponInput} CouponInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CouponInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.CouponInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.couponCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CouponInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.CouponInput} CouponInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CouponInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CouponInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CouponInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            if (!$util.isString(message.couponCode))
                                return "couponCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a CouponInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.CouponInput} CouponInput
                     */
                    CouponInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.CouponInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.CouponInput();
                        if (object.couponCode != null)
                            message.couponCode = String(object.couponCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CouponInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @static
                     * @param {theplant.ec.api.orders.CouponInput} message CouponInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CouponInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.couponCode = "";
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            object.couponCode = message.couponCode;
                        return object;
                    };

                    /**
                     * Converts this CouponInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.CouponInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CouponInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CouponInput;
                })();

                orders.ApplyDimensionAfterHemmingInput = (function() {

                    /**
                     * Properties of an ApplyDimensionAfterHemmingInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IApplyDimensionAfterHemmingInput
                     * @property {string|null} [productCode] ApplyDimensionAfterHemmingInput productCode
                     * @property {string|null} [articleCode] ApplyDimensionAfterHemmingInput articleCode
                     * @property {string|null} [dimensionAfterHemming] ApplyDimensionAfterHemmingInput dimensionAfterHemming
                     */

                    /**
                     * Constructs a new ApplyDimensionAfterHemmingInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents an ApplyDimensionAfterHemmingInput.
                     * @implements IApplyDimensionAfterHemmingInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IApplyDimensionAfterHemmingInput=} [properties] Properties to set
                     */
                    function ApplyDimensionAfterHemmingInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ApplyDimensionAfterHemmingInput productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @instance
                     */
                    ApplyDimensionAfterHemmingInput.prototype.productCode = "";

                    /**
                     * ApplyDimensionAfterHemmingInput articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @instance
                     */
                    ApplyDimensionAfterHemmingInput.prototype.articleCode = "";

                    /**
                     * ApplyDimensionAfterHemmingInput dimensionAfterHemming.
                     * @member {string} dimensionAfterHemming
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @instance
                     */
                    ApplyDimensionAfterHemmingInput.prototype.dimensionAfterHemming = "";

                    /**
                     * Creates a new ApplyDimensionAfterHemmingInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.IApplyDimensionAfterHemmingInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.ApplyDimensionAfterHemmingInput} ApplyDimensionAfterHemmingInput instance
                     */
                    ApplyDimensionAfterHemmingInput.create = function create(properties) {
                        return new ApplyDimensionAfterHemmingInput(properties);
                    };

                    /**
                     * Encodes the specified ApplyDimensionAfterHemmingInput message. Does not implicitly {@link theplant.ec.api.orders.ApplyDimensionAfterHemmingInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.IApplyDimensionAfterHemmingInput} message ApplyDimensionAfterHemmingInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyDimensionAfterHemmingInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.productCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.dimensionAfterHemming);
                        return writer;
                    };

                    /**
                     * Encodes the specified ApplyDimensionAfterHemmingInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.ApplyDimensionAfterHemmingInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.IApplyDimensionAfterHemmingInput} message ApplyDimensionAfterHemmingInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ApplyDimensionAfterHemmingInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ApplyDimensionAfterHemmingInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.ApplyDimensionAfterHemmingInput} ApplyDimensionAfterHemmingInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyDimensionAfterHemmingInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.ApplyDimensionAfterHemmingInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.productCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.dimensionAfterHemming = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ApplyDimensionAfterHemmingInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.ApplyDimensionAfterHemmingInput} ApplyDimensionAfterHemmingInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ApplyDimensionAfterHemmingInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ApplyDimensionAfterHemmingInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ApplyDimensionAfterHemmingInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            if (!$util.isString(message.dimensionAfterHemming))
                                return "dimensionAfterHemming: string expected";
                        return null;
                    };

                    /**
                     * Creates an ApplyDimensionAfterHemmingInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.ApplyDimensionAfterHemmingInput} ApplyDimensionAfterHemmingInput
                     */
                    ApplyDimensionAfterHemmingInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.ApplyDimensionAfterHemmingInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.ApplyDimensionAfterHemmingInput();
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.dimensionAfterHemming != null)
                            message.dimensionAfterHemming = String(object.dimensionAfterHemming);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ApplyDimensionAfterHemmingInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.ApplyDimensionAfterHemmingInput} message ApplyDimensionAfterHemmingInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ApplyDimensionAfterHemmingInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.productCode = "";
                            object.articleCode = "";
                            object.dimensionAfterHemming = "";
                        }
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            object.dimensionAfterHemming = message.dimensionAfterHemming;
                        return object;
                    };

                    /**
                     * Converts this ApplyDimensionAfterHemmingInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.ApplyDimensionAfterHemmingInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ApplyDimensionAfterHemmingInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ApplyDimensionAfterHemmingInput;
                })();

                orders.RemoveDimensionAfterHemmingInput = (function() {

                    /**
                     * Properties of a RemoveDimensionAfterHemmingInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IRemoveDimensionAfterHemmingInput
                     * @property {string|null} [articleCode] RemoveDimensionAfterHemmingInput articleCode
                     */

                    /**
                     * Constructs a new RemoveDimensionAfterHemmingInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a RemoveDimensionAfterHemmingInput.
                     * @implements IRemoveDimensionAfterHemmingInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IRemoveDimensionAfterHemmingInput=} [properties] Properties to set
                     */
                    function RemoveDimensionAfterHemmingInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RemoveDimensionAfterHemmingInput articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @instance
                     */
                    RemoveDimensionAfterHemmingInput.prototype.articleCode = "";

                    /**
                     * Creates a new RemoveDimensionAfterHemmingInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.IRemoveDimensionAfterHemmingInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.RemoveDimensionAfterHemmingInput} RemoveDimensionAfterHemmingInput instance
                     */
                    RemoveDimensionAfterHemmingInput.create = function create(properties) {
                        return new RemoveDimensionAfterHemmingInput(properties);
                    };

                    /**
                     * Encodes the specified RemoveDimensionAfterHemmingInput message. Does not implicitly {@link theplant.ec.api.orders.RemoveDimensionAfterHemmingInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.IRemoveDimensionAfterHemmingInput} message RemoveDimensionAfterHemmingInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveDimensionAfterHemmingInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified RemoveDimensionAfterHemmingInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.RemoveDimensionAfterHemmingInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.IRemoveDimensionAfterHemmingInput} message RemoveDimensionAfterHemmingInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveDimensionAfterHemmingInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RemoveDimensionAfterHemmingInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.RemoveDimensionAfterHemmingInput} RemoveDimensionAfterHemmingInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveDimensionAfterHemmingInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.RemoveDimensionAfterHemmingInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RemoveDimensionAfterHemmingInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.RemoveDimensionAfterHemmingInput} RemoveDimensionAfterHemmingInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveDimensionAfterHemmingInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RemoveDimensionAfterHemmingInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoveDimensionAfterHemmingInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a RemoveDimensionAfterHemmingInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.RemoveDimensionAfterHemmingInput} RemoveDimensionAfterHemmingInput
                     */
                    RemoveDimensionAfterHemmingInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.RemoveDimensionAfterHemmingInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.RemoveDimensionAfterHemmingInput();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RemoveDimensionAfterHemmingInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @static
                     * @param {theplant.ec.api.orders.RemoveDimensionAfterHemmingInput} message RemoveDimensionAfterHemmingInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoveDimensionAfterHemmingInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.articleCode = "";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        return object;
                    };

                    /**
                     * Converts this RemoveDimensionAfterHemmingInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.RemoveDimensionAfterHemmingInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoveDimensionAfterHemmingInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RemoveDimensionAfterHemmingInput;
                })();

                orders.FinishOrderInput = (function() {

                    /**
                     * Properties of a FinishOrderInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IFinishOrderInput
                     * @property {string|null} [orderCode] FinishOrderInput orderCode
                     * @property {string|null} [cvc] FinishOrderInput cvc
                     */

                    /**
                     * Constructs a new FinishOrderInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a FinishOrderInput.
                     * @implements IFinishOrderInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IFinishOrderInput=} [properties] Properties to set
                     */
                    function FinishOrderInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FinishOrderInput orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @instance
                     */
                    FinishOrderInput.prototype.orderCode = "";

                    /**
                     * FinishOrderInput cvc.
                     * @member {string} cvc
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @instance
                     */
                    FinishOrderInput.prototype.cvc = "";

                    /**
                     * Creates a new FinishOrderInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.IFinishOrderInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.FinishOrderInput} FinishOrderInput instance
                     */
                    FinishOrderInput.create = function create(properties) {
                        return new FinishOrderInput(properties);
                    };

                    /**
                     * Encodes the specified FinishOrderInput message. Does not implicitly {@link theplant.ec.api.orders.FinishOrderInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.IFinishOrderInput} message FinishOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FinishOrderInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.cvc != null && message.hasOwnProperty("cvc"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cvc);
                        return writer;
                    };

                    /**
                     * Encodes the specified FinishOrderInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.FinishOrderInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.IFinishOrderInput} message FinishOrderInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FinishOrderInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FinishOrderInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.FinishOrderInput} FinishOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FinishOrderInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.FinishOrderInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.cvc = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FinishOrderInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.FinishOrderInput} FinishOrderInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FinishOrderInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FinishOrderInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FinishOrderInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.cvc != null && message.hasOwnProperty("cvc"))
                            if (!$util.isString(message.cvc))
                                return "cvc: string expected";
                        return null;
                    };

                    /**
                     * Creates a FinishOrderInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.FinishOrderInput} FinishOrderInput
                     */
                    FinishOrderInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.FinishOrderInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.FinishOrderInput();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.cvc != null)
                            message.cvc = String(object.cvc);
                        return message;
                    };

                    /**
                     * Creates a plain object from a FinishOrderInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @static
                     * @param {theplant.ec.api.orders.FinishOrderInput} message FinishOrderInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FinishOrderInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.cvc = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.cvc != null && message.hasOwnProperty("cvc"))
                            object.cvc = message.cvc;
                        return object;
                    };

                    /**
                     * Converts this FinishOrderInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.FinishOrderInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FinishOrderInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FinishOrderInput;
                })();

                orders.CartService = (function() {

                    /**
                     * Constructs a new CartService service.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CartService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CartService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (CartService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CartService;

                    /**
                     * Creates new CartService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.orders.CartService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CartService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CartService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#addVariant}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef AddVariantCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls AddVariant.
                     * @function addVariant
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IArticleCode} request ArticleCode message or plain object
                     * @param {theplant.ec.api.orders.CartService.AddVariantCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.addVariant = function addVariant(request, callback) {
                        return this.rpcCall(addVariant, $root.theplant.ec.api.orders.ArticleCode, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "AddVariant" });

                    /**
                     * Calls AddVariant.
                     * @function addVariant
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IArticleCode} request ArticleCode message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#setVariantCount}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef SetVariantCountCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls SetVariantCount.
                     * @function setVariantCount
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.ICountInput} request CountInput message or plain object
                     * @param {theplant.ec.api.orders.CartService.SetVariantCountCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.setVariantCount = function setVariantCount(request, callback) {
                        return this.rpcCall(setVariantCount, $root.theplant.ec.api.orders.CountInput, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "SetVariantCount" });

                    /**
                     * Calls SetVariantCount.
                     * @function setVariantCount
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.ICountInput} request CountInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#getCart}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef GetCartCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls GetCart.
                     * @function getCart
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.orders.CartService.GetCartCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.getCart = function getCart(request, callback) {
                        return this.rpcCall(getCart, $root.theplant.ec.api.Empty, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "GetCart" });

                    /**
                     * Calls GetCart.
                     * @function getCart
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#changeVariant}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef ChangeVariantCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls ChangeVariant.
                     * @function changeVariant
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IVariantInput} request VariantInput message or plain object
                     * @param {theplant.ec.api.orders.CartService.ChangeVariantCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.changeVariant = function changeVariant(request, callback) {
                        return this.rpcCall(changeVariant, $root.theplant.ec.api.orders.VariantInput, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "ChangeVariant" });

                    /**
                     * Calls ChangeVariant.
                     * @function changeVariant
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IVariantInput} request VariantInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#validateCartCoupon}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef ValidateCartCouponCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls ValidateCartCoupon.
                     * @function validateCartCoupon
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.ICouponInput} request CouponInput message or plain object
                     * @param {theplant.ec.api.orders.CartService.ValidateCartCouponCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.validateCartCoupon = function validateCartCoupon(request, callback) {
                        return this.rpcCall(validateCartCoupon, $root.theplant.ec.api.orders.CouponInput, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "ValidateCartCoupon" });

                    /**
                     * Calls ValidateCartCoupon.
                     * @function validateCartCoupon
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.ICouponInput} request CouponInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#clearCart}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef ClearCartCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls ClearCart.
                     * @function clearCart
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.orders.CartService.ClearCartCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.clearCart = function clearCart(request, callback) {
                        return this.rpcCall(clearCart, $root.theplant.ec.api.Empty, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "ClearCart" });

                    /**
                     * Calls ClearCart.
                     * @function clearCart
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#cancelCartCoupon}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef CancelCartCouponCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls CancelCartCoupon.
                     * @function cancelCartCoupon
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.ICouponInput} request CouponInput message or plain object
                     * @param {theplant.ec.api.orders.CartService.CancelCartCouponCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.cancelCartCoupon = function cancelCartCoupon(request, callback) {
                        return this.rpcCall(cancelCartCoupon, $root.theplant.ec.api.orders.CouponInput, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "CancelCartCoupon" });

                    /**
                     * Calls CancelCartCoupon.
                     * @function cancelCartCoupon
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.ICouponInput} request CouponInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#applyDimensionAfterHemmingForCartItem}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef ApplyDimensionAfterHemmingForCartItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls ApplyDimensionAfterHemmingForCartItem.
                     * @function applyDimensionAfterHemmingForCartItem
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IApplyDimensionAfterHemmingInput} request ApplyDimensionAfterHemmingInput message or plain object
                     * @param {theplant.ec.api.orders.CartService.ApplyDimensionAfterHemmingForCartItemCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.applyDimensionAfterHemmingForCartItem = function applyDimensionAfterHemmingForCartItem(request, callback) {
                        return this.rpcCall(applyDimensionAfterHemmingForCartItem, $root.theplant.ec.api.orders.ApplyDimensionAfterHemmingInput, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "ApplyDimensionAfterHemmingForCartItem" });

                    /**
                     * Calls ApplyDimensionAfterHemmingForCartItem.
                     * @function applyDimensionAfterHemmingForCartItem
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IApplyDimensionAfterHemmingInput} request ApplyDimensionAfterHemmingInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#removeDimensionAfterHemmingForCartItem}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef RemoveDimensionAfterHemmingForCartItemCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls RemoveDimensionAfterHemmingForCartItem.
                     * @function removeDimensionAfterHemmingForCartItem
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IRemoveDimensionAfterHemmingInput} request RemoveDimensionAfterHemmingInput message or plain object
                     * @param {theplant.ec.api.orders.CartService.RemoveDimensionAfterHemmingForCartItemCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.removeDimensionAfterHemmingForCartItem = function removeDimensionAfterHemmingForCartItem(request, callback) {
                        return this.rpcCall(removeDimensionAfterHemmingForCartItem, $root.theplant.ec.api.orders.RemoveDimensionAfterHemmingInput, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "RemoveDimensionAfterHemmingForCartItem" });

                    /**
                     * Calls RemoveDimensionAfterHemmingForCartItem.
                     * @function removeDimensionAfterHemmingForCartItem
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IRemoveDimensionAfterHemmingInput} request RemoveDimensionAfterHemmingInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CartService#applyCustomization}.
                     * @memberof theplant.ec.api.orders.CartService
                     * @typedef ApplyCustomizationCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetCartResult} [response] GetCartResult
                     */

                    /**
                     * Calls ApplyCustomization.
                     * @function applyCustomization
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IArticleCode} request ArticleCode message or plain object
                     * @param {theplant.ec.api.orders.CartService.ApplyCustomizationCallback} callback Node-style callback called with the error, if any, and GetCartResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CartService.prototype.applyCustomization = function applyCustomization(request, callback) {
                        return this.rpcCall(applyCustomization, $root.theplant.ec.api.orders.ArticleCode, $root.theplant.ec.api.orders.GetCartResult, request, callback);
                    }, "name", { value: "ApplyCustomization" });

                    /**
                     * Calls ApplyCustomization.
                     * @function applyCustomization
                     * @memberof theplant.ec.api.orders.CartService
                     * @instance
                     * @param {theplant.ec.api.orders.IArticleCode} request ArticleCode message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetCartResult>} Promise
                     * @variation 2
                     */

                    return CartService;
                })();

                orders.CheckoutService = (function() {

                    /**
                     * Constructs a new CheckoutService service.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CheckoutService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CheckoutService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (CheckoutService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CheckoutService;

                    /**
                     * Creates new CheckoutService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CheckoutService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CheckoutService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#checkout}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef CheckoutCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls Checkout.
                     * @function checkout
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.CheckoutCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.checkout = function checkout(request, callback) {
                        return this.rpcCall(checkout, $root.theplant.ec.api.Empty, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "Checkout" });

                    /**
                     * Calls Checkout.
                     * @function checkout
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#confirm}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef ConfirmCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.ConfirmResult} [response] ConfirmResult
                     */

                    /**
                     * Calls Confirm.
                     * @function confirm
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.ICheckoutInput} request CheckoutInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.ConfirmCallback} callback Node-style callback called with the error, if any, and ConfirmResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.confirm = function confirm(request, callback) {
                        return this.rpcCall(confirm, $root.theplant.ec.api.orders.CheckoutInput, $root.theplant.ec.api.orders.ConfirmResult, request, callback);
                    }, "name", { value: "Confirm" });

                    /**
                     * Calls Confirm.
                     * @function confirm
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.ICheckoutInput} request CheckoutInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.ConfirmResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#cancelMyOrder}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef CancelMyOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls CancelMyOrder.
                     * @function cancelMyOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IOrderCode} request OrderCode message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.CancelMyOrderCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.cancelMyOrder = function cancelMyOrder(request, callback) {
                        return this.rpcCall(cancelMyOrder, $root.theplant.ec.api.orders.OrderCode, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "CancelMyOrder" });

                    /**
                     * Calls CancelMyOrder.
                     * @function cancelMyOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IOrderCode} request OrderCode message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#getMyOrders}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef GetMyOrdersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.MyOrdersResult} [response] MyOrdersResult
                     */

                    /**
                     * Calls GetMyOrders.
                     * @function getMyOrders
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IMyOrdersInput} request MyOrdersInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.GetMyOrdersCallback} callback Node-style callback called with the error, if any, and MyOrdersResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.getMyOrders = function getMyOrders(request, callback) {
                        return this.rpcCall(getMyOrders, $root.theplant.ec.api.orders.MyOrdersInput, $root.theplant.ec.api.orders.MyOrdersResult, request, callback);
                    }, "name", { value: "GetMyOrders" });

                    /**
                     * Calls GetMyOrders.
                     * @function getMyOrders
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IMyOrdersInput} request MyOrdersInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.MyOrdersResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#validateInput}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef ValidateInputCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.ValidationResult} [response] ValidationResult
                     */

                    /**
                     * Calls ValidateInput.
                     * @function validateInput
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.ICheckoutInput} request CheckoutInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.ValidateInputCallback} callback Node-style callback called with the error, if any, and ValidationResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.validateInput = function validateInput(request, callback) {
                        return this.rpcCall(validateInput, $root.theplant.ec.api.orders.CheckoutInput, $root.theplant.ec.api.orders.ValidationResult, request, callback);
                    }, "name", { value: "ValidateInput" });

                    /**
                     * Calls ValidateInput.
                     * @function validateInput
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.ICheckoutInput} request CheckoutInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.ValidationResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#getPoints}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef GetPointsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.points.AvailableUsePoint} [response] AvailableUsePoint
                     */

                    /**
                     * Calls GetPoints.
                     * @function getPoints
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.ICheckoutInput} request CheckoutInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.GetPointsCallback} callback Node-style callback called with the error, if any, and AvailableUsePoint
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.getPoints = function getPoints(request, callback) {
                        return this.rpcCall(getPoints, $root.theplant.ec.api.orders.CheckoutInput, $root.theplant.ec.service.points.AvailableUsePoint, request, callback);
                    }, "name", { value: "GetPoints" });

                    /**
                     * Calls GetPoints.
                     * @function getPoints
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.ICheckoutInput} request CheckoutInput message or plain object
                     * @returns {Promise<theplant.ec.service.points.AvailableUsePoint>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#signature}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef SignatureCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.SignatureResp} [response] SignatureResp
                     */

                    /**
                     * Calls Signature.
                     * @function signature
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.service.orders.IAmazonPayCreateSessionInput} request AmazonPayCreateSessionInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.SignatureCallback} callback Node-style callback called with the error, if any, and SignatureResp
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.signature = function signature(request, callback) {
                        return this.rpcCall(signature, $root.theplant.ec.service.orders.AmazonPayCreateSessionInput, $root.theplant.ec.api.orders.SignatureResp, request, callback);
                    }, "name", { value: "Signature" });

                    /**
                     * Calls Signature.
                     * @function signature
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.service.orders.IAmazonPayCreateSessionInput} request AmazonPayCreateSessionInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.SignatureResp>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#getOrder}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef GetOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls GetOrder.
                     * @function getOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IGetOrderInput} request GetOrderInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.GetOrderCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.getOrder = function getOrder(request, callback) {
                        return this.rpcCall(getOrder, $root.theplant.ec.api.orders.GetOrderInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "GetOrder" });

                    /**
                     * Calls GetOrder.
                     * @function getOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IGetOrderInput} request GetOrderInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#requestReturn}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef RequestReturnCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.orders.Order} [response] Order
                     */

                    /**
                     * Calls RequestReturn.
                     * @function requestReturn
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IRequestReturnInput} request RequestReturnInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.RequestReturnCallback} callback Node-style callback called with the error, if any, and Order
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.requestReturn = function requestReturn(request, callback) {
                        return this.rpcCall(requestReturn, $root.theplant.ec.api.orders.RequestReturnInput, $root.theplant.ec.service.orders.Order, request, callback);
                    }, "name", { value: "RequestReturn" });

                    /**
                     * Calls RequestReturn.
                     * @function requestReturn
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IRequestReturnInput} request RequestReturnInput message or plain object
                     * @returns {Promise<theplant.ec.service.orders.Order>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#finishOrder}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef FinishOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.ConfirmResult} [response] ConfirmResult
                     */

                    /**
                     * Calls FinishOrder.
                     * @function finishOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IFinishOrderInput} request FinishOrderInput message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.FinishOrderCallback} callback Node-style callback called with the error, if any, and ConfirmResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.finishOrder = function finishOrder(request, callback) {
                        return this.rpcCall(finishOrder, $root.theplant.ec.api.orders.FinishOrderInput, $root.theplant.ec.api.orders.ConfirmResult, request, callback);
                    }, "name", { value: "FinishOrder" });

                    /**
                     * Calls FinishOrder.
                     * @function finishOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.orders.IFinishOrderInput} request FinishOrderInput message or plain object
                     * @returns {Promise<theplant.ec.api.orders.ConfirmResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CheckoutService#checkWaitingPayOrder}.
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @typedef CheckWaitingPayOrderCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.ConfirmResult} [response] ConfirmResult
                     */

                    /**
                     * Calls CheckWaitingPayOrder.
                     * @function checkWaitingPayOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.orders.CheckoutService.CheckWaitingPayOrderCallback} callback Node-style callback called with the error, if any, and ConfirmResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CheckoutService.prototype.checkWaitingPayOrder = function checkWaitingPayOrder(request, callback) {
                        return this.rpcCall(checkWaitingPayOrder, $root.theplant.ec.api.Empty, $root.theplant.ec.api.orders.ConfirmResult, request, callback);
                    }, "name", { value: "CheckWaitingPayOrder" });

                    /**
                     * Calls CheckWaitingPayOrder.
                     * @function checkWaitingPayOrder
                     * @memberof theplant.ec.api.orders.CheckoutService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.orders.ConfirmResult>} Promise
                     * @variation 2
                     */

                    return CheckoutService;
                })();

                orders.CreateCreditCardInput = (function() {

                    /**
                     * Properties of a CreateCreditCardInput.
                     * @memberof theplant.ec.api.orders
                     * @interface ICreateCreditCardInput
                     * @property {theplant.ec.service.orders.ICreditCardInput|null} [card] CreateCreditCardInput card
                     */

                    /**
                     * Constructs a new CreateCreditCardInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CreateCreditCardInput.
                     * @implements ICreateCreditCardInput
                     * @constructor
                     * @param {theplant.ec.api.orders.ICreateCreditCardInput=} [properties] Properties to set
                     */
                    function CreateCreditCardInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateCreditCardInput card.
                     * @member {theplant.ec.service.orders.ICreditCardInput|null|undefined} card
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @instance
                     */
                    CreateCreditCardInput.prototype.card = null;

                    /**
                     * Creates a new CreateCreditCardInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.ICreateCreditCardInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.CreateCreditCardInput} CreateCreditCardInput instance
                     */
                    CreateCreditCardInput.create = function create(properties) {
                        return new CreateCreditCardInput(properties);
                    };

                    /**
                     * Encodes the specified CreateCreditCardInput message. Does not implicitly {@link theplant.ec.api.orders.CreateCreditCardInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.ICreateCreditCardInput} message CreateCreditCardInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCreditCardInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.card != null && message.hasOwnProperty("card"))
                            $root.theplant.ec.service.orders.CreditCardInput.encode(message.card, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateCreditCardInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.CreateCreditCardInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.ICreateCreditCardInput} message CreateCreditCardInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateCreditCardInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateCreditCardInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.CreateCreditCardInput} CreateCreditCardInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCreditCardInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.CreateCreditCardInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.card = $root.theplant.ec.service.orders.CreditCardInput.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateCreditCardInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.CreateCreditCardInput} CreateCreditCardInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateCreditCardInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateCreditCardInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateCreditCardInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.card != null && message.hasOwnProperty("card")) {
                            var error = $root.theplant.ec.service.orders.CreditCardInput.verify(message.card);
                            if (error)
                                return "card." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CreateCreditCardInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.CreateCreditCardInput} CreateCreditCardInput
                     */
                    CreateCreditCardInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.CreateCreditCardInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.CreateCreditCardInput();
                        if (object.card != null) {
                            if (typeof object.card !== "object")
                                throw TypeError(".theplant.ec.api.orders.CreateCreditCardInput.card: object expected");
                            message.card = $root.theplant.ec.service.orders.CreditCardInput.fromObject(object.card);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateCreditCardInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.CreateCreditCardInput} message CreateCreditCardInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateCreditCardInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.card = null;
                        if (message.card != null && message.hasOwnProperty("card"))
                            object.card = $root.theplant.ec.service.orders.CreditCardInput.toObject(message.card, options);
                        return object;
                    };

                    /**
                     * Converts this CreateCreditCardInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.CreateCreditCardInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateCreditCardInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreateCreditCardInput;
                })();

                orders.DeleteCreditCardInput = (function() {

                    /**
                     * Properties of a DeleteCreditCardInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IDeleteCreditCardInput
                     * @property {string|null} [creditCardId] DeleteCreditCardInput creditCardId
                     */

                    /**
                     * Constructs a new DeleteCreditCardInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a DeleteCreditCardInput.
                     * @implements IDeleteCreditCardInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IDeleteCreditCardInput=} [properties] Properties to set
                     */
                    function DeleteCreditCardInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeleteCreditCardInput creditCardId.
                     * @member {string} creditCardId
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @instance
                     */
                    DeleteCreditCardInput.prototype.creditCardId = "";

                    /**
                     * Creates a new DeleteCreditCardInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.IDeleteCreditCardInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.DeleteCreditCardInput} DeleteCreditCardInput instance
                     */
                    DeleteCreditCardInput.create = function create(properties) {
                        return new DeleteCreditCardInput(properties);
                    };

                    /**
                     * Encodes the specified DeleteCreditCardInput message. Does not implicitly {@link theplant.ec.api.orders.DeleteCreditCardInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.IDeleteCreditCardInput} message DeleteCreditCardInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteCreditCardInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.creditCardId);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeleteCreditCardInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.DeleteCreditCardInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.IDeleteCreditCardInput} message DeleteCreditCardInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteCreditCardInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeleteCreditCardInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.DeleteCreditCardInput} DeleteCreditCardInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteCreditCardInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.DeleteCreditCardInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.creditCardId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeleteCreditCardInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.DeleteCreditCardInput} DeleteCreditCardInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteCreditCardInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeleteCreditCardInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteCreditCardInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            if (!$util.isString(message.creditCardId))
                                return "creditCardId: string expected";
                        return null;
                    };

                    /**
                     * Creates a DeleteCreditCardInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.DeleteCreditCardInput} DeleteCreditCardInput
                     */
                    DeleteCreditCardInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.DeleteCreditCardInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.DeleteCreditCardInput();
                        if (object.creditCardId != null)
                            message.creditCardId = String(object.creditCardId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeleteCreditCardInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @static
                     * @param {theplant.ec.api.orders.DeleteCreditCardInput} message DeleteCreditCardInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteCreditCardInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.creditCardId = "";
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            object.creditCardId = message.creditCardId;
                        return object;
                    };

                    /**
                     * Converts this DeleteCreditCardInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.DeleteCreditCardInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteCreditCardInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeleteCreditCardInput;
                })();

                orders.GetMyCreditCardsResult = (function() {

                    /**
                     * Properties of a GetMyCreditCardsResult.
                     * @memberof theplant.ec.api.orders
                     * @interface IGetMyCreditCardsResult
                     * @property {Array.<theplant.ec.service.orders.ICreditCard>|null} [cards] GetMyCreditCardsResult cards
                     */

                    /**
                     * Constructs a new GetMyCreditCardsResult.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a GetMyCreditCardsResult.
                     * @implements IGetMyCreditCardsResult
                     * @constructor
                     * @param {theplant.ec.api.orders.IGetMyCreditCardsResult=} [properties] Properties to set
                     */
                    function GetMyCreditCardsResult(properties) {
                        this.cards = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetMyCreditCardsResult cards.
                     * @member {Array.<theplant.ec.service.orders.ICreditCard>} cards
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @instance
                     */
                    GetMyCreditCardsResult.prototype.cards = $util.emptyArray;

                    /**
                     * Creates a new GetMyCreditCardsResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {theplant.ec.api.orders.IGetMyCreditCardsResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.GetMyCreditCardsResult} GetMyCreditCardsResult instance
                     */
                    GetMyCreditCardsResult.create = function create(properties) {
                        return new GetMyCreditCardsResult(properties);
                    };

                    /**
                     * Encodes the specified GetMyCreditCardsResult message. Does not implicitly {@link theplant.ec.api.orders.GetMyCreditCardsResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {theplant.ec.api.orders.IGetMyCreditCardsResult} message GetMyCreditCardsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetMyCreditCardsResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cards != null && message.cards.length)
                            for (var i = 0; i < message.cards.length; ++i)
                                $root.theplant.ec.service.orders.CreditCard.encode(message.cards[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetMyCreditCardsResult message, length delimited. Does not implicitly {@link theplant.ec.api.orders.GetMyCreditCardsResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {theplant.ec.api.orders.IGetMyCreditCardsResult} message GetMyCreditCardsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetMyCreditCardsResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetMyCreditCardsResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.GetMyCreditCardsResult} GetMyCreditCardsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetMyCreditCardsResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.GetMyCreditCardsResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.cards && message.cards.length))
                                    message.cards = [];
                                message.cards.push($root.theplant.ec.service.orders.CreditCard.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetMyCreditCardsResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.GetMyCreditCardsResult} GetMyCreditCardsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetMyCreditCardsResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetMyCreditCardsResult message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetMyCreditCardsResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cards != null && message.hasOwnProperty("cards")) {
                            if (!Array.isArray(message.cards))
                                return "cards: array expected";
                            for (var i = 0; i < message.cards.length; ++i) {
                                var error = $root.theplant.ec.service.orders.CreditCard.verify(message.cards[i]);
                                if (error)
                                    return "cards." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a GetMyCreditCardsResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.GetMyCreditCardsResult} GetMyCreditCardsResult
                     */
                    GetMyCreditCardsResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.GetMyCreditCardsResult)
                            return object;
                        var message = new $root.theplant.ec.api.orders.GetMyCreditCardsResult();
                        if (object.cards) {
                            if (!Array.isArray(object.cards))
                                throw TypeError(".theplant.ec.api.orders.GetMyCreditCardsResult.cards: array expected");
                            message.cards = [];
                            for (var i = 0; i < object.cards.length; ++i) {
                                if (typeof object.cards[i] !== "object")
                                    throw TypeError(".theplant.ec.api.orders.GetMyCreditCardsResult.cards: object expected");
                                message.cards[i] = $root.theplant.ec.service.orders.CreditCard.fromObject(object.cards[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetMyCreditCardsResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @static
                     * @param {theplant.ec.api.orders.GetMyCreditCardsResult} message GetMyCreditCardsResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetMyCreditCardsResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cards = [];
                        if (message.cards && message.cards.length) {
                            object.cards = [];
                            for (var j = 0; j < message.cards.length; ++j)
                                object.cards[j] = $root.theplant.ec.service.orders.CreditCard.toObject(message.cards[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this GetMyCreditCardsResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.GetMyCreditCardsResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetMyCreditCardsResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetMyCreditCardsResult;
                })();

                orders.CreditCardService = (function() {

                    /**
                     * Constructs a new CreditCardService service.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CreditCardService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function CreditCardService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (CreditCardService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = CreditCardService;

                    /**
                     * Creates new CreditCardService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {CreditCardService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    CreditCardService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CreditCardService#createCreditCard}.
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @typedef CreateCreditCardCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {proto.ValidationError} [response] ValidationError
                     */

                    /**
                     * Calls CreateCreditCard.
                     * @function createCreditCard
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @instance
                     * @param {theplant.ec.api.orders.ICreateCreditCardInput} request CreateCreditCardInput message or plain object
                     * @param {theplant.ec.api.orders.CreditCardService.CreateCreditCardCallback} callback Node-style callback called with the error, if any, and ValidationError
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CreditCardService.prototype.createCreditCard = function createCreditCard(request, callback) {
                        return this.rpcCall(createCreditCard, $root.theplant.ec.api.orders.CreateCreditCardInput, $root.proto.ValidationError, request, callback);
                    }, "name", { value: "CreateCreditCard" });

                    /**
                     * Calls CreateCreditCard.
                     * @function createCreditCard
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @instance
                     * @param {theplant.ec.api.orders.ICreateCreditCardInput} request CreateCreditCardInput message or plain object
                     * @returns {Promise<proto.ValidationError>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CreditCardService#deleteCreditCard}.
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @typedef DeleteCreditCardCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls DeleteCreditCard.
                     * @function deleteCreditCard
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @instance
                     * @param {theplant.ec.api.orders.IDeleteCreditCardInput} request DeleteCreditCardInput message or plain object
                     * @param {theplant.ec.api.orders.CreditCardService.DeleteCreditCardCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CreditCardService.prototype.deleteCreditCard = function deleteCreditCard(request, callback) {
                        return this.rpcCall(deleteCreditCard, $root.theplant.ec.api.orders.DeleteCreditCardInput, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "DeleteCreditCard" });

                    /**
                     * Calls DeleteCreditCard.
                     * @function deleteCreditCard
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @instance
                     * @param {theplant.ec.api.orders.IDeleteCreditCardInput} request DeleteCreditCardInput message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.CreditCardService#getMyCreditCards}.
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @typedef GetMyCreditCardsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.GetMyCreditCardsResult} [response] GetMyCreditCardsResult
                     */

                    /**
                     * Calls GetMyCreditCards.
                     * @function getMyCreditCards
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.orders.CreditCardService.GetMyCreditCardsCallback} callback Node-style callback called with the error, if any, and GetMyCreditCardsResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(CreditCardService.prototype.getMyCreditCards = function getMyCreditCards(request, callback) {
                        return this.rpcCall(getMyCreditCards, $root.theplant.ec.api.Empty, $root.theplant.ec.api.orders.GetMyCreditCardsResult, request, callback);
                    }, "name", { value: "GetMyCreditCards" });

                    /**
                     * Calls GetMyCreditCards.
                     * @function getMyCreditCards
                     * @memberof theplant.ec.api.orders.CreditCardService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.orders.GetMyCreditCardsResult>} Promise
                     * @variation 2
                     */

                    return CreditCardService;
                })();

                orders.ProductCodes = (function() {

                    /**
                     * Properties of a ProductCodes.
                     * @memberof theplant.ec.api.orders
                     * @interface IProductCodes
                     * @property {Array.<string>|null} [productCodes] ProductCodes productCodes
                     */

                    /**
                     * Constructs a new ProductCodes.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a ProductCodes.
                     * @implements IProductCodes
                     * @constructor
                     * @param {theplant.ec.api.orders.IProductCodes=} [properties] Properties to set
                     */
                    function ProductCodes(properties) {
                        this.productCodes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductCodes productCodes.
                     * @member {Array.<string>} productCodes
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @instance
                     */
                    ProductCodes.prototype.productCodes = $util.emptyArray;

                    /**
                     * Creates a new ProductCodes instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {theplant.ec.api.orders.IProductCodes=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.ProductCodes} ProductCodes instance
                     */
                    ProductCodes.create = function create(properties) {
                        return new ProductCodes(properties);
                    };

                    /**
                     * Encodes the specified ProductCodes message. Does not implicitly {@link theplant.ec.api.orders.ProductCodes.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {theplant.ec.api.orders.IProductCodes} message ProductCodes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductCodes.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productCodes != null && message.productCodes.length)
                            for (var i = 0; i < message.productCodes.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.productCodes[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductCodes message, length delimited. Does not implicitly {@link theplant.ec.api.orders.ProductCodes.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {theplant.ec.api.orders.IProductCodes} message ProductCodes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductCodes.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductCodes message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.ProductCodes} ProductCodes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductCodes.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.ProductCodes();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.productCodes && message.productCodes.length))
                                    message.productCodes = [];
                                message.productCodes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductCodes message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.ProductCodes} ProductCodes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductCodes.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductCodes message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductCodes.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productCodes != null && message.hasOwnProperty("productCodes")) {
                            if (!Array.isArray(message.productCodes))
                                return "productCodes: array expected";
                            for (var i = 0; i < message.productCodes.length; ++i)
                                if (!$util.isString(message.productCodes[i]))
                                    return "productCodes: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a ProductCodes message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.ProductCodes} ProductCodes
                     */
                    ProductCodes.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.ProductCodes)
                            return object;
                        var message = new $root.theplant.ec.api.orders.ProductCodes();
                        if (object.productCodes) {
                            if (!Array.isArray(object.productCodes))
                                throw TypeError(".theplant.ec.api.orders.ProductCodes.productCodes: array expected");
                            message.productCodes = [];
                            for (var i = 0; i < object.productCodes.length; ++i)
                                message.productCodes[i] = String(object.productCodes[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductCodes message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @static
                     * @param {theplant.ec.api.orders.ProductCodes} message ProductCodes
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductCodes.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.productCodes = [];
                        if (message.productCodes && message.productCodes.length) {
                            object.productCodes = [];
                            for (var j = 0; j < message.productCodes.length; ++j)
                                object.productCodes[j] = message.productCodes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this ProductCodes to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.ProductCodes
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductCodes.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductCodes;
                })();

                orders.CanHemmingPantsResult = (function() {

                    /**
                     * Properties of a CanHemmingPantsResult.
                     * @memberof theplant.ec.api.orders
                     * @interface ICanHemmingPantsResult
                     * @property {Array.<boolean>|null} [pantsAdjusts] CanHemmingPantsResult pantsAdjusts
                     */

                    /**
                     * Constructs a new CanHemmingPantsResult.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a CanHemmingPantsResult.
                     * @implements ICanHemmingPantsResult
                     * @constructor
                     * @param {theplant.ec.api.orders.ICanHemmingPantsResult=} [properties] Properties to set
                     */
                    function CanHemmingPantsResult(properties) {
                        this.pantsAdjusts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CanHemmingPantsResult pantsAdjusts.
                     * @member {Array.<boolean>} pantsAdjusts
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @instance
                     */
                    CanHemmingPantsResult.prototype.pantsAdjusts = $util.emptyArray;

                    /**
                     * Creates a new CanHemmingPantsResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.orders.ICanHemmingPantsResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.CanHemmingPantsResult} CanHemmingPantsResult instance
                     */
                    CanHemmingPantsResult.create = function create(properties) {
                        return new CanHemmingPantsResult(properties);
                    };

                    /**
                     * Encodes the specified CanHemmingPantsResult message. Does not implicitly {@link theplant.ec.api.orders.CanHemmingPantsResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.orders.ICanHemmingPantsResult} message CanHemmingPantsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CanHemmingPantsResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.pantsAdjusts != null && message.pantsAdjusts.length) {
                            writer.uint32(/* id 1, wireType 2 =*/10).fork();
                            for (var i = 0; i < message.pantsAdjusts.length; ++i)
                                writer.bool(message.pantsAdjusts[i]);
                            writer.ldelim();
                        }
                        return writer;
                    };

                    /**
                     * Encodes the specified CanHemmingPantsResult message, length delimited. Does not implicitly {@link theplant.ec.api.orders.CanHemmingPantsResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.orders.ICanHemmingPantsResult} message CanHemmingPantsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CanHemmingPantsResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CanHemmingPantsResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.CanHemmingPantsResult} CanHemmingPantsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CanHemmingPantsResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.CanHemmingPantsResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.pantsAdjusts && message.pantsAdjusts.length))
                                    message.pantsAdjusts = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.pantsAdjusts.push(reader.bool());
                                } else
                                    message.pantsAdjusts.push(reader.bool());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CanHemmingPantsResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.CanHemmingPantsResult} CanHemmingPantsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CanHemmingPantsResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CanHemmingPantsResult message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CanHemmingPantsResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.pantsAdjusts != null && message.hasOwnProperty("pantsAdjusts")) {
                            if (!Array.isArray(message.pantsAdjusts))
                                return "pantsAdjusts: array expected";
                            for (var i = 0; i < message.pantsAdjusts.length; ++i)
                                if (typeof message.pantsAdjusts[i] !== "boolean")
                                    return "pantsAdjusts: boolean[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a CanHemmingPantsResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.CanHemmingPantsResult} CanHemmingPantsResult
                     */
                    CanHemmingPantsResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.CanHemmingPantsResult)
                            return object;
                        var message = new $root.theplant.ec.api.orders.CanHemmingPantsResult();
                        if (object.pantsAdjusts) {
                            if (!Array.isArray(object.pantsAdjusts))
                                throw TypeError(".theplant.ec.api.orders.CanHemmingPantsResult.pantsAdjusts: array expected");
                            message.pantsAdjusts = [];
                            for (var i = 0; i < object.pantsAdjusts.length; ++i)
                                message.pantsAdjusts[i] = Boolean(object.pantsAdjusts[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CanHemmingPantsResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @static
                     * @param {theplant.ec.api.orders.CanHemmingPantsResult} message CanHemmingPantsResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CanHemmingPantsResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.pantsAdjusts = [];
                        if (message.pantsAdjusts && message.pantsAdjusts.length) {
                            object.pantsAdjusts = [];
                            for (var j = 0; j < message.pantsAdjusts.length; ++j)
                                object.pantsAdjusts[j] = message.pantsAdjusts[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this CanHemmingPantsResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.CanHemmingPantsResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CanHemmingPantsResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CanHemmingPantsResult;
                })();

                orders.HemmingPantsFee = (function() {

                    /**
                     * Properties of a HemmingPantsFee.
                     * @memberof theplant.ec.api.orders
                     * @interface IHemmingPantsFee
                     * @property {number|Long|null} [hemmingPantsFee] HemmingPantsFee hemmingPantsFee
                     */

                    /**
                     * Constructs a new HemmingPantsFee.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a HemmingPantsFee.
                     * @implements IHemmingPantsFee
                     * @constructor
                     * @param {theplant.ec.api.orders.IHemmingPantsFee=} [properties] Properties to set
                     */
                    function HemmingPantsFee(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * HemmingPantsFee hemmingPantsFee.
                     * @member {number|Long} hemmingPantsFee
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @instance
                     */
                    HemmingPantsFee.prototype.hemmingPantsFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new HemmingPantsFee instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {theplant.ec.api.orders.IHemmingPantsFee=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.HemmingPantsFee} HemmingPantsFee instance
                     */
                    HemmingPantsFee.create = function create(properties) {
                        return new HemmingPantsFee(properties);
                    };

                    /**
                     * Encodes the specified HemmingPantsFee message. Does not implicitly {@link theplant.ec.api.orders.HemmingPantsFee.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {theplant.ec.api.orders.IHemmingPantsFee} message HemmingPantsFee message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HemmingPantsFee.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.hemmingPantsFee);
                        return writer;
                    };

                    /**
                     * Encodes the specified HemmingPantsFee message, length delimited. Does not implicitly {@link theplant.ec.api.orders.HemmingPantsFee.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {theplant.ec.api.orders.IHemmingPantsFee} message HemmingPantsFee message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HemmingPantsFee.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a HemmingPantsFee message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.HemmingPantsFee} HemmingPantsFee
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HemmingPantsFee.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.HemmingPantsFee();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.hemmingPantsFee = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a HemmingPantsFee message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.HemmingPantsFee} HemmingPantsFee
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HemmingPantsFee.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a HemmingPantsFee message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HemmingPantsFee.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            if (!$util.isInteger(message.hemmingPantsFee) && !(message.hemmingPantsFee && $util.isInteger(message.hemmingPantsFee.low) && $util.isInteger(message.hemmingPantsFee.high)))
                                return "hemmingPantsFee: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a HemmingPantsFee message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.HemmingPantsFee} HemmingPantsFee
                     */
                    HemmingPantsFee.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.HemmingPantsFee)
                            return object;
                        var message = new $root.theplant.ec.api.orders.HemmingPantsFee();
                        if (object.hemmingPantsFee != null)
                            if ($util.Long)
                                (message.hemmingPantsFee = $util.Long.fromValue(object.hemmingPantsFee)).unsigned = true;
                            else if (typeof object.hemmingPantsFee === "string")
                                message.hemmingPantsFee = parseInt(object.hemmingPantsFee, 10);
                            else if (typeof object.hemmingPantsFee === "number")
                                message.hemmingPantsFee = object.hemmingPantsFee;
                            else if (typeof object.hemmingPantsFee === "object")
                                message.hemmingPantsFee = new $util.LongBits(object.hemmingPantsFee.low >>> 0, object.hemmingPantsFee.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a HemmingPantsFee message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @static
                     * @param {theplant.ec.api.orders.HemmingPantsFee} message HemmingPantsFee
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HemmingPantsFee.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.hemmingPantsFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.hemmingPantsFee = options.longs === String ? "0" : 0;
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            if (typeof message.hemmingPantsFee === "number")
                                object.hemmingPantsFee = options.longs === String ? String(message.hemmingPantsFee) : message.hemmingPantsFee;
                            else
                                object.hemmingPantsFee = options.longs === String ? $util.Long.prototype.toString.call(message.hemmingPantsFee) : options.longs === Number ? new $util.LongBits(message.hemmingPantsFee.low >>> 0, message.hemmingPantsFee.high >>> 0).toNumber(true) : message.hemmingPantsFee;
                        return object;
                    };

                    /**
                     * Converts this HemmingPantsFee to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.HemmingPantsFee
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HemmingPantsFee.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return HemmingPantsFee;
                })();

                orders.SizeInfoInput = (function() {

                    /**
                     * Properties of a SizeInfoInput.
                     * @memberof theplant.ec.api.orders
                     * @interface ISizeInfoInput
                     * @property {string|null} [sizeCode] SizeInfoInput sizeCode
                     * @property {theplant.ec.service.sizeguide.ISizeGuideInput|null} [input] SizeInfoInput input
                     */

                    /**
                     * Constructs a new SizeInfoInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a SizeInfoInput.
                     * @implements ISizeInfoInput
                     * @constructor
                     * @param {theplant.ec.api.orders.ISizeInfoInput=} [properties] Properties to set
                     */
                    function SizeInfoInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeInfoInput sizeCode.
                     * @member {string} sizeCode
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @instance
                     */
                    SizeInfoInput.prototype.sizeCode = "";

                    /**
                     * SizeInfoInput input.
                     * @member {theplant.ec.service.sizeguide.ISizeGuideInput|null|undefined} input
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @instance
                     */
                    SizeInfoInput.prototype.input = null;

                    /**
                     * Creates a new SizeInfoInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {theplant.ec.api.orders.ISizeInfoInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.SizeInfoInput} SizeInfoInput instance
                     */
                    SizeInfoInput.create = function create(properties) {
                        return new SizeInfoInput(properties);
                    };

                    /**
                     * Encodes the specified SizeInfoInput message. Does not implicitly {@link theplant.ec.api.orders.SizeInfoInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {theplant.ec.api.orders.ISizeInfoInput} message SizeInfoInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeInfoInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sizeCode != null && message.hasOwnProperty("sizeCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sizeCode);
                        if (message.input != null && message.hasOwnProperty("input"))
                            $root.theplant.ec.service.sizeguide.SizeGuideInput.encode(message.input, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeInfoInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.SizeInfoInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {theplant.ec.api.orders.ISizeInfoInput} message SizeInfoInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeInfoInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeInfoInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.SizeInfoInput} SizeInfoInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeInfoInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.SizeInfoInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sizeCode = reader.string();
                                break;
                            case 2:
                                message.input = $root.theplant.ec.service.sizeguide.SizeGuideInput.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeInfoInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.SizeInfoInput} SizeInfoInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeInfoInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeInfoInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeInfoInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sizeCode != null && message.hasOwnProperty("sizeCode"))
                            if (!$util.isString(message.sizeCode))
                                return "sizeCode: string expected";
                        if (message.input != null && message.hasOwnProperty("input")) {
                            var error = $root.theplant.ec.service.sizeguide.SizeGuideInput.verify(message.input);
                            if (error)
                                return "input." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SizeInfoInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.SizeInfoInput} SizeInfoInput
                     */
                    SizeInfoInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.SizeInfoInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.SizeInfoInput();
                        if (object.sizeCode != null)
                            message.sizeCode = String(object.sizeCode);
                        if (object.input != null) {
                            if (typeof object.input !== "object")
                                throw TypeError(".theplant.ec.api.orders.SizeInfoInput.input: object expected");
                            message.input = $root.theplant.ec.service.sizeguide.SizeGuideInput.fromObject(object.input);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeInfoInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @static
                     * @param {theplant.ec.api.orders.SizeInfoInput} message SizeInfoInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeInfoInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sizeCode = "";
                            object.input = null;
                        }
                        if (message.sizeCode != null && message.hasOwnProperty("sizeCode"))
                            object.sizeCode = message.sizeCode;
                        if (message.input != null && message.hasOwnProperty("input"))
                            object.input = $root.theplant.ec.service.sizeguide.SizeGuideInput.toObject(message.input, options);
                        return object;
                    };

                    /**
                     * Converts this SizeInfoInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.SizeInfoInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeInfoInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeInfoInput;
                })();

                orders.HemmingPantsService = (function() {

                    /**
                     * Constructs a new HemmingPantsService service.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a HemmingPantsService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function HemmingPantsService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (HemmingPantsService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = HemmingPantsService;

                    /**
                     * Creates new HemmingPantsService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {HemmingPantsService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    HemmingPantsService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.HemmingPantsService#canHemmingPants}.
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @typedef CanHemmingPantsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.CanHemmingPantsResult} [response] CanHemmingPantsResult
                     */

                    /**
                     * Calls CanHemmingPants.
                     * @function canHemmingPants
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @instance
                     * @param {theplant.ec.api.orders.IProductCodes} request ProductCodes message or plain object
                     * @param {theplant.ec.api.orders.HemmingPantsService.CanHemmingPantsCallback} callback Node-style callback called with the error, if any, and CanHemmingPantsResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(HemmingPantsService.prototype.canHemmingPants = function canHemmingPants(request, callback) {
                        return this.rpcCall(canHemmingPants, $root.theplant.ec.api.orders.ProductCodes, $root.theplant.ec.api.orders.CanHemmingPantsResult, request, callback);
                    }, "name", { value: "CanHemmingPants" });

                    /**
                     * Calls CanHemmingPants.
                     * @function canHemmingPants
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @instance
                     * @param {theplant.ec.api.orders.IProductCodes} request ProductCodes message or plain object
                     * @returns {Promise<theplant.ec.api.orders.CanHemmingPantsResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.HemmingPantsService#getHemmingPantsFee}.
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @typedef GetHemmingPantsFeeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.orders.HemmingPantsFee} [response] HemmingPantsFee
                     */

                    /**
                     * Calls GetHemmingPantsFee.
                     * @function getHemmingPantsFee
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.orders.HemmingPantsService.GetHemmingPantsFeeCallback} callback Node-style callback called with the error, if any, and HemmingPantsFee
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(HemmingPantsService.prototype.getHemmingPantsFee = function getHemmingPantsFee(request, callback) {
                        return this.rpcCall(getHemmingPantsFee, $root.theplant.ec.api.Empty, $root.theplant.ec.api.orders.HemmingPantsFee, request, callback);
                    }, "name", { value: "GetHemmingPantsFee" });

                    /**
                     * Calls GetHemmingPantsFee.
                     * @function getHemmingPantsFee
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.orders.HemmingPantsFee>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.orders.HemmingPantsService#getSizeInfo}.
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @typedef GetSizeInfoCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.sizeguide.SizeGuideTable} [response] SizeGuideTable
                     */

                    /**
                     * Calls GetSizeInfo.
                     * @function getSizeInfo
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @instance
                     * @param {theplant.ec.api.orders.ISizeInfoInput} request SizeInfoInput message or plain object
                     * @param {theplant.ec.api.orders.HemmingPantsService.GetSizeInfoCallback} callback Node-style callback called with the error, if any, and SizeGuideTable
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(HemmingPantsService.prototype.getSizeInfo = function getSizeInfo(request, callback) {
                        return this.rpcCall(getSizeInfo, $root.theplant.ec.api.orders.SizeInfoInput, $root.theplant.ec.service.sizeguide.SizeGuideTable, request, callback);
                    }, "name", { value: "GetSizeInfo" });

                    /**
                     * Calls GetSizeInfo.
                     * @function getSizeInfo
                     * @memberof theplant.ec.api.orders.HemmingPantsService
                     * @instance
                     * @param {theplant.ec.api.orders.ISizeInfoInput} request SizeInfoInput message or plain object
                     * @returns {Promise<theplant.ec.service.sizeguide.SizeGuideTable>} Promise
                     * @variation 2
                     */

                    return HemmingPantsService;
                })();

                orders.SignatureResp = (function() {

                    /**
                     * Properties of a SignatureResp.
                     * @memberof theplant.ec.api.orders
                     * @interface ISignatureResp
                     * @property {string|null} [signature] SignatureResp signature
                     */

                    /**
                     * Constructs a new SignatureResp.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a SignatureResp.
                     * @implements ISignatureResp
                     * @constructor
                     * @param {theplant.ec.api.orders.ISignatureResp=} [properties] Properties to set
                     */
                    function SignatureResp(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SignatureResp signature.
                     * @member {string} signature
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @instance
                     */
                    SignatureResp.prototype.signature = "";

                    /**
                     * Creates a new SignatureResp instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {theplant.ec.api.orders.ISignatureResp=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.SignatureResp} SignatureResp instance
                     */
                    SignatureResp.create = function create(properties) {
                        return new SignatureResp(properties);
                    };

                    /**
                     * Encodes the specified SignatureResp message. Does not implicitly {@link theplant.ec.api.orders.SignatureResp.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {theplant.ec.api.orders.ISignatureResp} message SignatureResp message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SignatureResp.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.signature != null && message.hasOwnProperty("signature"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.signature);
                        return writer;
                    };

                    /**
                     * Encodes the specified SignatureResp message, length delimited. Does not implicitly {@link theplant.ec.api.orders.SignatureResp.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {theplant.ec.api.orders.ISignatureResp} message SignatureResp message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SignatureResp.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SignatureResp message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.SignatureResp} SignatureResp
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SignatureResp.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.SignatureResp();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.signature = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SignatureResp message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.SignatureResp} SignatureResp
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SignatureResp.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SignatureResp message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SignatureResp.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.signature != null && message.hasOwnProperty("signature"))
                            if (!$util.isString(message.signature))
                                return "signature: string expected";
                        return null;
                    };

                    /**
                     * Creates a SignatureResp message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.SignatureResp} SignatureResp
                     */
                    SignatureResp.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.SignatureResp)
                            return object;
                        var message = new $root.theplant.ec.api.orders.SignatureResp();
                        if (object.signature != null)
                            message.signature = String(object.signature);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SignatureResp message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @static
                     * @param {theplant.ec.api.orders.SignatureResp} message SignatureResp
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SignatureResp.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.signature = "";
                        if (message.signature != null && message.hasOwnProperty("signature"))
                            object.signature = message.signature;
                        return object;
                    };

                    /**
                     * Converts this SignatureResp to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.SignatureResp
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SignatureResp.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SignatureResp;
                })();

                orders.RakutenANDPayPayInput = (function() {

                    /**
                     * Properties of a RakutenANDPayPayInput.
                     * @memberof theplant.ec.api.orders
                     * @interface IRakutenANDPayPayInput
                     * @property {string|null} [transactionId] RakutenANDPayPayInput transactionId
                     * @property {string|null} [returnUrl] RakutenANDPayPayInput returnUrl
                     * @property {string|null} [cancelUrl] RakutenANDPayPayInput cancelUrl
                     */

                    /**
                     * Constructs a new RakutenANDPayPayInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a RakutenANDPayPayInput.
                     * @implements IRakutenANDPayPayInput
                     * @constructor
                     * @param {theplant.ec.api.orders.IRakutenANDPayPayInput=} [properties] Properties to set
                     */
                    function RakutenANDPayPayInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RakutenANDPayPayInput transactionId.
                     * @member {string} transactionId
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @instance
                     */
                    RakutenANDPayPayInput.prototype.transactionId = "";

                    /**
                     * RakutenANDPayPayInput returnUrl.
                     * @member {string} returnUrl
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @instance
                     */
                    RakutenANDPayPayInput.prototype.returnUrl = "";

                    /**
                     * RakutenANDPayPayInput cancelUrl.
                     * @member {string} cancelUrl
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @instance
                     */
                    RakutenANDPayPayInput.prototype.cancelUrl = "";

                    /**
                     * Creates a new RakutenANDPayPayInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {theplant.ec.api.orders.IRakutenANDPayPayInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.RakutenANDPayPayInput} RakutenANDPayPayInput instance
                     */
                    RakutenANDPayPayInput.create = function create(properties) {
                        return new RakutenANDPayPayInput(properties);
                    };

                    /**
                     * Encodes the specified RakutenANDPayPayInput message. Does not implicitly {@link theplant.ec.api.orders.RakutenANDPayPayInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {theplant.ec.api.orders.IRakutenANDPayPayInput} message RakutenANDPayPayInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RakutenANDPayPayInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionId);
                        if (message.returnUrl != null && message.hasOwnProperty("returnUrl"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.returnUrl);
                        if (message.cancelUrl != null && message.hasOwnProperty("cancelUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.cancelUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified RakutenANDPayPayInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.RakutenANDPayPayInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {theplant.ec.api.orders.IRakutenANDPayPayInput} message RakutenANDPayPayInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RakutenANDPayPayInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RakutenANDPayPayInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.RakutenANDPayPayInput} RakutenANDPayPayInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RakutenANDPayPayInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.RakutenANDPayPayInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.transactionId = reader.string();
                                break;
                            case 2:
                                message.returnUrl = reader.string();
                                break;
                            case 3:
                                message.cancelUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RakutenANDPayPayInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.RakutenANDPayPayInput} RakutenANDPayPayInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RakutenANDPayPayInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RakutenANDPayPayInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RakutenANDPayPayInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            if (!$util.isString(message.transactionId))
                                return "transactionId: string expected";
                        if (message.returnUrl != null && message.hasOwnProperty("returnUrl"))
                            if (!$util.isString(message.returnUrl))
                                return "returnUrl: string expected";
                        if (message.cancelUrl != null && message.hasOwnProperty("cancelUrl"))
                            if (!$util.isString(message.cancelUrl))
                                return "cancelUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates a RakutenANDPayPayInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.RakutenANDPayPayInput} RakutenANDPayPayInput
                     */
                    RakutenANDPayPayInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.RakutenANDPayPayInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.RakutenANDPayPayInput();
                        if (object.transactionId != null)
                            message.transactionId = String(object.transactionId);
                        if (object.returnUrl != null)
                            message.returnUrl = String(object.returnUrl);
                        if (object.cancelUrl != null)
                            message.cancelUrl = String(object.cancelUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RakutenANDPayPayInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @static
                     * @param {theplant.ec.api.orders.RakutenANDPayPayInput} message RakutenANDPayPayInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RakutenANDPayPayInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.transactionId = "";
                            object.returnUrl = "";
                            object.cancelUrl = "";
                        }
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            object.transactionId = message.transactionId;
                        if (message.returnUrl != null && message.hasOwnProperty("returnUrl"))
                            object.returnUrl = message.returnUrl;
                        if (message.cancelUrl != null && message.hasOwnProperty("cancelUrl"))
                            object.cancelUrl = message.cancelUrl;
                        return object;
                    };

                    /**
                     * Converts this RakutenANDPayPayInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.RakutenANDPayPayInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RakutenANDPayPayInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RakutenANDPayPayInput;
                })();

                orders.SmartPayInput = (function() {

                    /**
                     * Properties of a SmartPayInput.
                     * @memberof theplant.ec.api.orders
                     * @interface ISmartPayInput
                     * @property {string|null} [successUrl] SmartPayInput successUrl
                     * @property {string|null} [cancelUrl] SmartPayInput cancelUrl
                     * @property {string|null} [sessionId] SmartPayInput sessionId
                     * @property {string|null} [orderId] SmartPayInput orderId
                     * @property {string|null} [redirectUrl] SmartPayInput redirectUrl
                     */

                    /**
                     * Constructs a new SmartPayInput.
                     * @memberof theplant.ec.api.orders
                     * @classdesc Represents a SmartPayInput.
                     * @implements ISmartPayInput
                     * @constructor
                     * @param {theplant.ec.api.orders.ISmartPayInput=} [properties] Properties to set
                     */
                    function SmartPayInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SmartPayInput successUrl.
                     * @member {string} successUrl
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @instance
                     */
                    SmartPayInput.prototype.successUrl = "";

                    /**
                     * SmartPayInput cancelUrl.
                     * @member {string} cancelUrl
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @instance
                     */
                    SmartPayInput.prototype.cancelUrl = "";

                    /**
                     * SmartPayInput sessionId.
                     * @member {string} sessionId
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @instance
                     */
                    SmartPayInput.prototype.sessionId = "";

                    /**
                     * SmartPayInput orderId.
                     * @member {string} orderId
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @instance
                     */
                    SmartPayInput.prototype.orderId = "";

                    /**
                     * SmartPayInput redirectUrl.
                     * @member {string} redirectUrl
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @instance
                     */
                    SmartPayInput.prototype.redirectUrl = "";

                    /**
                     * Creates a new SmartPayInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {theplant.ec.api.orders.ISmartPayInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.orders.SmartPayInput} SmartPayInput instance
                     */
                    SmartPayInput.create = function create(properties) {
                        return new SmartPayInput(properties);
                    };

                    /**
                     * Encodes the specified SmartPayInput message. Does not implicitly {@link theplant.ec.api.orders.SmartPayInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {theplant.ec.api.orders.ISmartPayInput} message SmartPayInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SmartPayInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.successUrl != null && message.hasOwnProperty("successUrl"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.successUrl);
                        if (message.cancelUrl != null && message.hasOwnProperty("cancelUrl"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.cancelUrl);
                        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.sessionId);
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.orderId);
                        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.redirectUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified SmartPayInput message, length delimited. Does not implicitly {@link theplant.ec.api.orders.SmartPayInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {theplant.ec.api.orders.ISmartPayInput} message SmartPayInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SmartPayInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SmartPayInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.orders.SmartPayInput} SmartPayInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SmartPayInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.orders.SmartPayInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.successUrl = reader.string();
                                break;
                            case 2:
                                message.cancelUrl = reader.string();
                                break;
                            case 3:
                                message.sessionId = reader.string();
                                break;
                            case 4:
                                message.orderId = reader.string();
                                break;
                            case 5:
                                message.redirectUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SmartPayInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.orders.SmartPayInput} SmartPayInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SmartPayInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SmartPayInput message.
                     * @function verify
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SmartPayInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.successUrl != null && message.hasOwnProperty("successUrl"))
                            if (!$util.isString(message.successUrl))
                                return "successUrl: string expected";
                        if (message.cancelUrl != null && message.hasOwnProperty("cancelUrl"))
                            if (!$util.isString(message.cancelUrl))
                                return "cancelUrl: string expected";
                        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                            if (!$util.isString(message.sessionId))
                                return "sessionId: string expected";
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            if (!$util.isString(message.orderId))
                                return "orderId: string expected";
                        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                            if (!$util.isString(message.redirectUrl))
                                return "redirectUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates a SmartPayInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.orders.SmartPayInput} SmartPayInput
                     */
                    SmartPayInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.orders.SmartPayInput)
                            return object;
                        var message = new $root.theplant.ec.api.orders.SmartPayInput();
                        if (object.successUrl != null)
                            message.successUrl = String(object.successUrl);
                        if (object.cancelUrl != null)
                            message.cancelUrl = String(object.cancelUrl);
                        if (object.sessionId != null)
                            message.sessionId = String(object.sessionId);
                        if (object.orderId != null)
                            message.orderId = String(object.orderId);
                        if (object.redirectUrl != null)
                            message.redirectUrl = String(object.redirectUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SmartPayInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @static
                     * @param {theplant.ec.api.orders.SmartPayInput} message SmartPayInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SmartPayInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.successUrl = "";
                            object.cancelUrl = "";
                            object.sessionId = "";
                            object.orderId = "";
                            object.redirectUrl = "";
                        }
                        if (message.successUrl != null && message.hasOwnProperty("successUrl"))
                            object.successUrl = message.successUrl;
                        if (message.cancelUrl != null && message.hasOwnProperty("cancelUrl"))
                            object.cancelUrl = message.cancelUrl;
                        if (message.sessionId != null && message.hasOwnProperty("sessionId"))
                            object.sessionId = message.sessionId;
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            object.orderId = message.orderId;
                        if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                            object.redirectUrl = message.redirectUrl;
                        return object;
                    };

                    /**
                     * Converts this SmartPayInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.orders.SmartPayInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SmartPayInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SmartPayInput;
                })();

                return orders;
            })();

            api.products = (function() {

                /**
                 * Namespace products.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var products = {};

                products.ProductCode = (function() {

                    /**
                     * Properties of a ProductCode.
                     * @memberof theplant.ec.api.products
                     * @interface IProductCode
                     * @property {string|null} [code] ProductCode code
                     */

                    /**
                     * Constructs a new ProductCode.
                     * @memberof theplant.ec.api.products
                     * @classdesc Represents a ProductCode.
                     * @implements IProductCode
                     * @constructor
                     * @param {theplant.ec.api.products.IProductCode=} [properties] Properties to set
                     */
                    function ProductCode(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductCode code.
                     * @member {string} code
                     * @memberof theplant.ec.api.products.ProductCode
                     * @instance
                     */
                    ProductCode.prototype.code = "";

                    /**
                     * Creates a new ProductCode instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {theplant.ec.api.products.IProductCode=} [properties] Properties to set
                     * @returns {theplant.ec.api.products.ProductCode} ProductCode instance
                     */
                    ProductCode.create = function create(properties) {
                        return new ProductCode(properties);
                    };

                    /**
                     * Encodes the specified ProductCode message. Does not implicitly {@link theplant.ec.api.products.ProductCode.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {theplant.ec.api.products.IProductCode} message ProductCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductCode.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductCode message, length delimited. Does not implicitly {@link theplant.ec.api.products.ProductCode.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {theplant.ec.api.products.IProductCode} message ProductCode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductCode.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductCode message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.products.ProductCode} ProductCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductCode.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.products.ProductCode();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductCode message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.products.ProductCode} ProductCode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductCode.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductCode message.
                     * @function verify
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductCode.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        return null;
                    };

                    /**
                     * Creates a ProductCode message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.products.ProductCode} ProductCode
                     */
                    ProductCode.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.products.ProductCode)
                            return object;
                        var message = new $root.theplant.ec.api.products.ProductCode();
                        if (object.code != null)
                            message.code = String(object.code);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductCode message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.products.ProductCode
                     * @static
                     * @param {theplant.ec.api.products.ProductCode} message ProductCode
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductCode.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.code = "";
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        return object;
                    };

                    /**
                     * Converts this ProductCode to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.products.ProductCode
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductCode.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductCode;
                })();

                products.ProductResponse = (function() {

                    /**
                     * Properties of a ProductResponse.
                     * @memberof theplant.ec.api.products
                     * @interface IProductResponse
                     * @property {theplant.ec.service.products.IProduct|null} [product] ProductResponse product
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [rdGroups] ProductResponse rdGroups
                     * @property {theplant.ec.service.breadcrumb.IBreadcrumb|null} [breadcrumb] ProductResponse breadcrumb
                     */

                    /**
                     * Constructs a new ProductResponse.
                     * @memberof theplant.ec.api.products
                     * @classdesc Represents a ProductResponse.
                     * @implements IProductResponse
                     * @constructor
                     * @param {theplant.ec.api.products.IProductResponse=} [properties] Properties to set
                     */
                    function ProductResponse(properties) {
                        this.rdGroups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductResponse product.
                     * @member {theplant.ec.service.products.IProduct|null|undefined} product
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @instance
                     */
                    ProductResponse.prototype.product = null;

                    /**
                     * ProductResponse rdGroups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} rdGroups
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @instance
                     */
                    ProductResponse.prototype.rdGroups = $util.emptyArray;

                    /**
                     * ProductResponse breadcrumb.
                     * @member {theplant.ec.service.breadcrumb.IBreadcrumb|null|undefined} breadcrumb
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @instance
                     */
                    ProductResponse.prototype.breadcrumb = null;

                    /**
                     * Creates a new ProductResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {theplant.ec.api.products.IProductResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.products.ProductResponse} ProductResponse instance
                     */
                    ProductResponse.create = function create(properties) {
                        return new ProductResponse(properties);
                    };

                    /**
                     * Encodes the specified ProductResponse message. Does not implicitly {@link theplant.ec.api.products.ProductResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {theplant.ec.api.products.IProductResponse} message ProductResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.product != null && message.hasOwnProperty("product"))
                            $root.theplant.ec.service.products.Product.encode(message.product, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.rdGroups != null && message.rdGroups.length)
                            for (var i = 0; i < message.rdGroups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.rdGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.breadcrumb != null && message.hasOwnProperty("breadcrumb"))
                            $root.theplant.ec.service.breadcrumb.Breadcrumb.encode(message.breadcrumb, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductResponse message, length delimited. Does not implicitly {@link theplant.ec.api.products.ProductResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {theplant.ec.api.products.IProductResponse} message ProductResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.products.ProductResponse} ProductResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.products.ProductResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.product = $root.theplant.ec.service.products.Product.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.rdGroups && message.rdGroups.length))
                                    message.rdGroups = [];
                                message.rdGroups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.breadcrumb = $root.theplant.ec.service.breadcrumb.Breadcrumb.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.products.ProductResponse} ProductResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.product != null && message.hasOwnProperty("product")) {
                            var error = $root.theplant.ec.service.products.Product.verify(message.product);
                            if (error)
                                return "product." + error;
                        }
                        if (message.rdGroups != null && message.hasOwnProperty("rdGroups")) {
                            if (!Array.isArray(message.rdGroups))
                                return "rdGroups: array expected";
                            for (var i = 0; i < message.rdGroups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.rdGroups[i]);
                                if (error)
                                    return "rdGroups." + error;
                            }
                        }
                        if (message.breadcrumb != null && message.hasOwnProperty("breadcrumb")) {
                            var error = $root.theplant.ec.service.breadcrumb.Breadcrumb.verify(message.breadcrumb);
                            if (error)
                                return "breadcrumb." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ProductResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.products.ProductResponse} ProductResponse
                     */
                    ProductResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.products.ProductResponse)
                            return object;
                        var message = new $root.theplant.ec.api.products.ProductResponse();
                        if (object.product != null) {
                            if (typeof object.product !== "object")
                                throw TypeError(".theplant.ec.api.products.ProductResponse.product: object expected");
                            message.product = $root.theplant.ec.service.products.Product.fromObject(object.product);
                        }
                        if (object.rdGroups) {
                            if (!Array.isArray(object.rdGroups))
                                throw TypeError(".theplant.ec.api.products.ProductResponse.rdGroups: array expected");
                            message.rdGroups = [];
                            for (var i = 0; i < object.rdGroups.length; ++i) {
                                if (typeof object.rdGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.api.products.ProductResponse.rdGroups: object expected");
                                message.rdGroups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.rdGroups[i]);
                            }
                        }
                        if (object.breadcrumb != null) {
                            if (typeof object.breadcrumb !== "object")
                                throw TypeError(".theplant.ec.api.products.ProductResponse.breadcrumb: object expected");
                            message.breadcrumb = $root.theplant.ec.service.breadcrumb.Breadcrumb.fromObject(object.breadcrumb);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @static
                     * @param {theplant.ec.api.products.ProductResponse} message ProductResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rdGroups = [];
                        if (options.defaults) {
                            object.product = null;
                            object.breadcrumb = null;
                        }
                        if (message.product != null && message.hasOwnProperty("product"))
                            object.product = $root.theplant.ec.service.products.Product.toObject(message.product, options);
                        if (message.rdGroups && message.rdGroups.length) {
                            object.rdGroups = [];
                            for (var j = 0; j < message.rdGroups.length; ++j)
                                object.rdGroups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.rdGroups[j], options);
                        }
                        if (message.breadcrumb != null && message.hasOwnProperty("breadcrumb"))
                            object.breadcrumb = $root.theplant.ec.service.breadcrumb.Breadcrumb.toObject(message.breadcrumb, options);
                        return object;
                    };

                    /**
                     * Converts this ProductResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.products.ProductResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductResponse;
                })();

                products.ProductCodes = (function() {

                    /**
                     * Properties of a ProductCodes.
                     * @memberof theplant.ec.api.products
                     * @interface IProductCodes
                     * @property {Array.<string>|null} [codes] ProductCodes codes
                     */

                    /**
                     * Constructs a new ProductCodes.
                     * @memberof theplant.ec.api.products
                     * @classdesc Represents a ProductCodes.
                     * @implements IProductCodes
                     * @constructor
                     * @param {theplant.ec.api.products.IProductCodes=} [properties] Properties to set
                     */
                    function ProductCodes(properties) {
                        this.codes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductCodes codes.
                     * @member {Array.<string>} codes
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @instance
                     */
                    ProductCodes.prototype.codes = $util.emptyArray;

                    /**
                     * Creates a new ProductCodes instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {theplant.ec.api.products.IProductCodes=} [properties] Properties to set
                     * @returns {theplant.ec.api.products.ProductCodes} ProductCodes instance
                     */
                    ProductCodes.create = function create(properties) {
                        return new ProductCodes(properties);
                    };

                    /**
                     * Encodes the specified ProductCodes message. Does not implicitly {@link theplant.ec.api.products.ProductCodes.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {theplant.ec.api.products.IProductCodes} message ProductCodes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductCodes.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.codes != null && message.codes.length)
                            for (var i = 0; i < message.codes.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.codes[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductCodes message, length delimited. Does not implicitly {@link theplant.ec.api.products.ProductCodes.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {theplant.ec.api.products.IProductCodes} message ProductCodes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductCodes.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductCodes message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.products.ProductCodes} ProductCodes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductCodes.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.products.ProductCodes();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.codes && message.codes.length))
                                    message.codes = [];
                                message.codes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductCodes message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.products.ProductCodes} ProductCodes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductCodes.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductCodes message.
                     * @function verify
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductCodes.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.codes != null && message.hasOwnProperty("codes")) {
                            if (!Array.isArray(message.codes))
                                return "codes: array expected";
                            for (var i = 0; i < message.codes.length; ++i)
                                if (!$util.isString(message.codes[i]))
                                    return "codes: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a ProductCodes message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.products.ProductCodes} ProductCodes
                     */
                    ProductCodes.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.products.ProductCodes)
                            return object;
                        var message = new $root.theplant.ec.api.products.ProductCodes();
                        if (object.codes) {
                            if (!Array.isArray(object.codes))
                                throw TypeError(".theplant.ec.api.products.ProductCodes.codes: array expected");
                            message.codes = [];
                            for (var i = 0; i < object.codes.length; ++i)
                                message.codes[i] = String(object.codes[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductCodes message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @static
                     * @param {theplant.ec.api.products.ProductCodes} message ProductCodes
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductCodes.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.codes = [];
                        if (message.codes && message.codes.length) {
                            object.codes = [];
                            for (var j = 0; j < message.codes.length; ++j)
                                object.codes[j] = message.codes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this ProductCodes to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.products.ProductCodes
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductCodes.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductCodes;
                })();

                products.ProductsResponse = (function() {

                    /**
                     * Properties of a ProductsResponse.
                     * @memberof theplant.ec.api.products
                     * @interface IProductsResponse
                     * @property {Array.<theplant.ec.service.products.IProduct>|null} [products] ProductsResponse products
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [rdGroups] ProductsResponse rdGroups
                     */

                    /**
                     * Constructs a new ProductsResponse.
                     * @memberof theplant.ec.api.products
                     * @classdesc Represents a ProductsResponse.
                     * @implements IProductsResponse
                     * @constructor
                     * @param {theplant.ec.api.products.IProductsResponse=} [properties] Properties to set
                     */
                    function ProductsResponse(properties) {
                        this.products = [];
                        this.rdGroups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductsResponse products.
                     * @member {Array.<theplant.ec.service.products.IProduct>} products
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @instance
                     */
                    ProductsResponse.prototype.products = $util.emptyArray;

                    /**
                     * ProductsResponse rdGroups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} rdGroups
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @instance
                     */
                    ProductsResponse.prototype.rdGroups = $util.emptyArray;

                    /**
                     * Creates a new ProductsResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.IProductsResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.products.ProductsResponse} ProductsResponse instance
                     */
                    ProductsResponse.create = function create(properties) {
                        return new ProductsResponse(properties);
                    };

                    /**
                     * Encodes the specified ProductsResponse message. Does not implicitly {@link theplant.ec.api.products.ProductsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.IProductsResponse} message ProductsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.products != null && message.products.length)
                            for (var i = 0; i < message.products.length; ++i)
                                $root.theplant.ec.service.products.Product.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.rdGroups != null && message.rdGroups.length)
                            for (var i = 0; i < message.rdGroups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.rdGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductsResponse message, length delimited. Does not implicitly {@link theplant.ec.api.products.ProductsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.IProductsResponse} message ProductsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.products.ProductsResponse} ProductsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.products.ProductsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.products && message.products.length))
                                    message.products = [];
                                message.products.push($root.theplant.ec.service.products.Product.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.rdGroups && message.rdGroups.length))
                                    message.rdGroups = [];
                                message.rdGroups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.products.ProductsResponse} ProductsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductsResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.products != null && message.hasOwnProperty("products")) {
                            if (!Array.isArray(message.products))
                                return "products: array expected";
                            for (var i = 0; i < message.products.length; ++i) {
                                var error = $root.theplant.ec.service.products.Product.verify(message.products[i]);
                                if (error)
                                    return "products." + error;
                            }
                        }
                        if (message.rdGroups != null && message.hasOwnProperty("rdGroups")) {
                            if (!Array.isArray(message.rdGroups))
                                return "rdGroups: array expected";
                            for (var i = 0; i < message.rdGroups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.rdGroups[i]);
                                if (error)
                                    return "rdGroups." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a ProductsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.products.ProductsResponse} ProductsResponse
                     */
                    ProductsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.products.ProductsResponse)
                            return object;
                        var message = new $root.theplant.ec.api.products.ProductsResponse();
                        if (object.products) {
                            if (!Array.isArray(object.products))
                                throw TypeError(".theplant.ec.api.products.ProductsResponse.products: array expected");
                            message.products = [];
                            for (var i = 0; i < object.products.length; ++i) {
                                if (typeof object.products[i] !== "object")
                                    throw TypeError(".theplant.ec.api.products.ProductsResponse.products: object expected");
                                message.products[i] = $root.theplant.ec.service.products.Product.fromObject(object.products[i]);
                            }
                        }
                        if (object.rdGroups) {
                            if (!Array.isArray(object.rdGroups))
                                throw TypeError(".theplant.ec.api.products.ProductsResponse.rdGroups: array expected");
                            message.rdGroups = [];
                            for (var i = 0; i < object.rdGroups.length; ++i) {
                                if (typeof object.rdGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.api.products.ProductsResponse.rdGroups: object expected");
                                message.rdGroups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.rdGroups[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.ProductsResponse} message ProductsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.products = [];
                            object.rdGroups = [];
                        }
                        if (message.products && message.products.length) {
                            object.products = [];
                            for (var j = 0; j < message.products.length; ++j)
                                object.products[j] = $root.theplant.ec.service.products.Product.toObject(message.products[j], options);
                        }
                        if (message.rdGroups && message.rdGroups.length) {
                            object.rdGroups = [];
                            for (var j = 0; j < message.rdGroups.length; ++j)
                                object.rdGroups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.rdGroups[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this ProductsResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.products.ProductsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductsResponse;
                })();

                products.FilterProductsResponse = (function() {

                    /**
                     * Properties of a FilterProductsResponse.
                     * @memberof theplant.ec.api.products
                     * @interface IFilterProductsResponse
                     * @property {theplant.ec.service.products.IFilterProductsResult|null} [result] FilterProductsResponse result
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [rdGroups] FilterProductsResponse rdGroups
                     */

                    /**
                     * Constructs a new FilterProductsResponse.
                     * @memberof theplant.ec.api.products
                     * @classdesc Represents a FilterProductsResponse.
                     * @implements IFilterProductsResponse
                     * @constructor
                     * @param {theplant.ec.api.products.IFilterProductsResponse=} [properties] Properties to set
                     */
                    function FilterProductsResponse(properties) {
                        this.rdGroups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterProductsResponse result.
                     * @member {theplant.ec.service.products.IFilterProductsResult|null|undefined} result
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @instance
                     */
                    FilterProductsResponse.prototype.result = null;

                    /**
                     * FilterProductsResponse rdGroups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} rdGroups
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @instance
                     */
                    FilterProductsResponse.prototype.rdGroups = $util.emptyArray;

                    /**
                     * Creates a new FilterProductsResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.IFilterProductsResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.products.FilterProductsResponse} FilterProductsResponse instance
                     */
                    FilterProductsResponse.create = function create(properties) {
                        return new FilterProductsResponse(properties);
                    };

                    /**
                     * Encodes the specified FilterProductsResponse message. Does not implicitly {@link theplant.ec.api.products.FilterProductsResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.IFilterProductsResponse} message FilterProductsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterProductsResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.result != null && message.hasOwnProperty("result"))
                            $root.theplant.ec.service.products.FilterProductsResult.encode(message.result, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.rdGroups != null && message.rdGroups.length)
                            for (var i = 0; i < message.rdGroups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.rdGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterProductsResponse message, length delimited. Does not implicitly {@link theplant.ec.api.products.FilterProductsResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.IFilterProductsResponse} message FilterProductsResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterProductsResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterProductsResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.products.FilterProductsResponse} FilterProductsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterProductsResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.products.FilterProductsResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.result = $root.theplant.ec.service.products.FilterProductsResult.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.rdGroups && message.rdGroups.length))
                                    message.rdGroups = [];
                                message.rdGroups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterProductsResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.products.FilterProductsResponse} FilterProductsResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterProductsResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterProductsResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterProductsResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.result != null && message.hasOwnProperty("result")) {
                            var error = $root.theplant.ec.service.products.FilterProductsResult.verify(message.result);
                            if (error)
                                return "result." + error;
                        }
                        if (message.rdGroups != null && message.hasOwnProperty("rdGroups")) {
                            if (!Array.isArray(message.rdGroups))
                                return "rdGroups: array expected";
                            for (var i = 0; i < message.rdGroups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.rdGroups[i]);
                                if (error)
                                    return "rdGroups." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a FilterProductsResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.products.FilterProductsResponse} FilterProductsResponse
                     */
                    FilterProductsResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.products.FilterProductsResponse)
                            return object;
                        var message = new $root.theplant.ec.api.products.FilterProductsResponse();
                        if (object.result != null) {
                            if (typeof object.result !== "object")
                                throw TypeError(".theplant.ec.api.products.FilterProductsResponse.result: object expected");
                            message.result = $root.theplant.ec.service.products.FilterProductsResult.fromObject(object.result);
                        }
                        if (object.rdGroups) {
                            if (!Array.isArray(object.rdGroups))
                                throw TypeError(".theplant.ec.api.products.FilterProductsResponse.rdGroups: array expected");
                            message.rdGroups = [];
                            for (var i = 0; i < object.rdGroups.length; ++i) {
                                if (typeof object.rdGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.api.products.FilterProductsResponse.rdGroups: object expected");
                                message.rdGroups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.rdGroups[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterProductsResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @static
                     * @param {theplant.ec.api.products.FilterProductsResponse} message FilterProductsResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterProductsResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rdGroups = [];
                        if (options.defaults)
                            object.result = null;
                        if (message.result != null && message.hasOwnProperty("result"))
                            object.result = $root.theplant.ec.service.products.FilterProductsResult.toObject(message.result, options);
                        if (message.rdGroups && message.rdGroups.length) {
                            object.rdGroups = [];
                            for (var j = 0; j < message.rdGroups.length; ++j)
                                object.rdGroups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.rdGroups[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this FilterProductsResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.products.FilterProductsResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterProductsResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterProductsResponse;
                })();

                products.ProductService = (function() {

                    /**
                     * Constructs a new ProductService service.
                     * @memberof theplant.ec.api.products
                     * @classdesc Represents a ProductService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ProductService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ProductService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProductService;

                    /**
                     * Creates new ProductService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.products.ProductService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ProductService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ProductService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.products.ProductService#getProduct}.
                     * @memberof theplant.ec.api.products.ProductService
                     * @typedef GetProductCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.products.ProductResponse} [response] ProductResponse
                     */

                    /**
                     * Calls GetProduct.
                     * @function getProduct
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.api.products.IProductCode} request ProductCode message or plain object
                     * @param {theplant.ec.api.products.ProductService.GetProductCallback} callback Node-style callback called with the error, if any, and ProductResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ProductService.prototype.getProduct = function getProduct(request, callback) {
                        return this.rpcCall(getProduct, $root.theplant.ec.api.products.ProductCode, $root.theplant.ec.api.products.ProductResponse, request, callback);
                    }, "name", { value: "GetProduct" });

                    /**
                     * Calls GetProduct.
                     * @function getProduct
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.api.products.IProductCode} request ProductCode message or plain object
                     * @returns {Promise<theplant.ec.api.products.ProductResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.products.ProductService#getProducts}.
                     * @memberof theplant.ec.api.products.ProductService
                     * @typedef GetProductsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.products.ProductsResponse} [response] ProductsResponse
                     */

                    /**
                     * Calls GetProducts.
                     * @function getProducts
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.api.products.IProductCodes} request ProductCodes message or plain object
                     * @param {theplant.ec.api.products.ProductService.GetProductsCallback} callback Node-style callback called with the error, if any, and ProductsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ProductService.prototype.getProducts = function getProducts(request, callback) {
                        return this.rpcCall(getProducts, $root.theplant.ec.api.products.ProductCodes, $root.theplant.ec.api.products.ProductsResponse, request, callback);
                    }, "name", { value: "GetProducts" });

                    /**
                     * Calls GetProducts.
                     * @function getProducts
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.api.products.IProductCodes} request ProductCodes message or plain object
                     * @returns {Promise<theplant.ec.api.products.ProductsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.products.ProductService#getFilterGroups}.
                     * @memberof theplant.ec.api.products.ProductService
                     * @typedef GetFilterGroupsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.products.FilterProductsResponse} [response] FilterProductsResponse
                     */

                    /**
                     * Calls GetFilterGroups.
                     * @function getFilterGroups
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.service.products.ISearchOptions} request SearchOptions message or plain object
                     * @param {theplant.ec.api.products.ProductService.GetFilterGroupsCallback} callback Node-style callback called with the error, if any, and FilterProductsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ProductService.prototype.getFilterGroups = function getFilterGroups(request, callback) {
                        return this.rpcCall(getFilterGroups, $root.theplant.ec.service.products.SearchOptions, $root.theplant.ec.api.products.FilterProductsResponse, request, callback);
                    }, "name", { value: "GetFilterGroups" });

                    /**
                     * Calls GetFilterGroups.
                     * @function getFilterGroups
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.service.products.ISearchOptions} request SearchOptions message or plain object
                     * @returns {Promise<theplant.ec.api.products.FilterProductsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.products.ProductService#filterProducts}.
                     * @memberof theplant.ec.api.products.ProductService
                     * @typedef FilterProductsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.products.FilterProductsResponse} [response] FilterProductsResponse
                     */

                    /**
                     * Calls FilterProducts.
                     * @function filterProducts
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.service.products.ISearchOptions} request SearchOptions message or plain object
                     * @param {theplant.ec.api.products.ProductService.FilterProductsCallback} callback Node-style callback called with the error, if any, and FilterProductsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ProductService.prototype.filterProducts = function filterProducts(request, callback) {
                        return this.rpcCall(filterProducts, $root.theplant.ec.service.products.SearchOptions, $root.theplant.ec.api.products.FilterProductsResponse, request, callback);
                    }, "name", { value: "FilterProducts" });

                    /**
                     * Calls FilterProducts.
                     * @function filterProducts
                     * @memberof theplant.ec.api.products.ProductService
                     * @instance
                     * @param {theplant.ec.service.products.ISearchOptions} request SearchOptions message or plain object
                     * @returns {Promise<theplant.ec.api.products.FilterProductsResponse>} Promise
                     * @variation 2
                     */

                    return ProductService;
                })();

                return products;
            })();

            api.productslisting = (function() {

                /**
                 * Namespace productslisting.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var productslisting = {};

                productslisting.Param = (function() {

                    /**
                     * Properties of a Param.
                     * @memberof theplant.ec.api.productslisting
                     * @interface IParam
                     * @property {string|null} [url] Param url
                     */

                    /**
                     * Constructs a new Param.
                     * @memberof theplant.ec.api.productslisting
                     * @classdesc Represents a Param.
                     * @implements IParam
                     * @constructor
                     * @param {theplant.ec.api.productslisting.IParam=} [properties] Properties to set
                     */
                    function Param(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Param url.
                     * @member {string} url
                     * @memberof theplant.ec.api.productslisting.Param
                     * @instance
                     */
                    Param.prototype.url = "";

                    /**
                     * Creates a new Param instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {theplant.ec.api.productslisting.IParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.productslisting.Param} Param instance
                     */
                    Param.create = function create(properties) {
                        return new Param(properties);
                    };

                    /**
                     * Encodes the specified Param message. Does not implicitly {@link theplant.ec.api.productslisting.Param.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {theplant.ec.api.productslisting.IParam} message Param message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Param.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.url != null && message.hasOwnProperty("url"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                        return writer;
                    };

                    /**
                     * Encodes the specified Param message, length delimited. Does not implicitly {@link theplant.ec.api.productslisting.Param.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {theplant.ec.api.productslisting.IParam} message Param message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Param.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Param message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.productslisting.Param} Param
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Param.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.productslisting.Param();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.url = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Param message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.productslisting.Param} Param
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Param.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Param message.
                     * @function verify
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Param.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        return null;
                    };

                    /**
                     * Creates a Param message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.productslisting.Param} Param
                     */
                    Param.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.productslisting.Param)
                            return object;
                        var message = new $root.theplant.ec.api.productslisting.Param();
                        if (object.url != null)
                            message.url = String(object.url);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Param message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.productslisting.Param
                     * @static
                     * @param {theplant.ec.api.productslisting.Param} message Param
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Param.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.url = "";
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        return object;
                    };

                    /**
                     * Converts this Param to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.productslisting.Param
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Param.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Param;
                })();

                productslisting.ProductslistingService = (function() {

                    /**
                     * Constructs a new ProductslistingService service.
                     * @memberof theplant.ec.api.productslisting
                     * @classdesc Represents a ProductslistingService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ProductslistingService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ProductslistingService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ProductslistingService;

                    /**
                     * Creates new ProductslistingService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.productslisting.ProductslistingService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ProductslistingService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ProductslistingService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.productslisting.ProductslistingService#getProductsListing}.
                     * @memberof theplant.ec.api.productslisting.ProductslistingService
                     * @typedef GetProductsListingCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.productslisting.ProductListing} [response] ProductListing
                     */

                    /**
                     * Calls GetProductsListing.
                     * @function getProductsListing
                     * @memberof theplant.ec.api.productslisting.ProductslistingService
                     * @instance
                     * @param {theplant.ec.api.productslisting.IParam} request Param message or plain object
                     * @param {theplant.ec.api.productslisting.ProductslistingService.GetProductsListingCallback} callback Node-style callback called with the error, if any, and ProductListing
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ProductslistingService.prototype.getProductsListing = function getProductsListing(request, callback) {
                        return this.rpcCall(getProductsListing, $root.theplant.ec.api.productslisting.Param, $root.theplant.ec.service.productslisting.ProductListing, request, callback);
                    }, "name", { value: "GetProductsListing" });

                    /**
                     * Calls GetProductsListing.
                     * @function getProductsListing
                     * @memberof theplant.ec.api.productslisting.ProductslistingService
                     * @instance
                     * @param {theplant.ec.api.productslisting.IParam} request Param message or plain object
                     * @returns {Promise<theplant.ec.service.productslisting.ProductListing>} Promise
                     * @variation 2
                     */

                    return ProductslistingService;
                })();

                return productslisting;
            })();

            api.recentlyviewed = (function() {

                /**
                 * Namespace recentlyviewed.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var recentlyviewed = {};

                recentlyviewed.RecentlyViewedParams = (function() {

                    /**
                     * Properties of a RecentlyViewedParams.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @interface IRecentlyViewedParams
                     * @property {number|Long|null} [page] RecentlyViewedParams page
                     * @property {number|Long|null} [size] RecentlyViewedParams size
                     */

                    /**
                     * Constructs a new RecentlyViewedParams.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @classdesc Represents a RecentlyViewedParams.
                     * @implements IRecentlyViewedParams
                     * @constructor
                     * @param {theplant.ec.api.recentlyviewed.IRecentlyViewedParams=} [properties] Properties to set
                     */
                    function RecentlyViewedParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RecentlyViewedParams page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @instance
                     */
                    RecentlyViewedParams.prototype.page = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * RecentlyViewedParams size.
                     * @member {number|Long} size
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @instance
                     */
                    RecentlyViewedParams.prototype.size = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new RecentlyViewedParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IRecentlyViewedParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.recentlyviewed.RecentlyViewedParams} RecentlyViewedParams instance
                     */
                    RecentlyViewedParams.create = function create(properties) {
                        return new RecentlyViewedParams(properties);
                    };

                    /**
                     * Encodes the specified RecentlyViewedParams message. Does not implicitly {@link theplant.ec.api.recentlyviewed.RecentlyViewedParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IRecentlyViewedParams} message RecentlyViewedParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecentlyViewedParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.page);
                        if (message.size != null && message.hasOwnProperty("size"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.size);
                        return writer;
                    };

                    /**
                     * Encodes the specified RecentlyViewedParams message, length delimited. Does not implicitly {@link theplant.ec.api.recentlyviewed.RecentlyViewedParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IRecentlyViewedParams} message RecentlyViewedParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RecentlyViewedParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RecentlyViewedParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.recentlyviewed.RecentlyViewedParams} RecentlyViewedParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecentlyViewedParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.recentlyviewed.RecentlyViewedParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.page = reader.uint64();
                                break;
                            case 2:
                                message.size = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RecentlyViewedParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.recentlyviewed.RecentlyViewedParams} RecentlyViewedParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RecentlyViewedParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RecentlyViewedParams message.
                     * @function verify
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RecentlyViewedParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (!$util.isInteger(message.size) && !(message.size && $util.isInteger(message.size.low) && $util.isInteger(message.size.high)))
                                return "size: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a RecentlyViewedParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.recentlyviewed.RecentlyViewedParams} RecentlyViewedParams
                     */
                    RecentlyViewedParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.recentlyviewed.RecentlyViewedParams)
                            return object;
                        var message = new $root.theplant.ec.api.recentlyviewed.RecentlyViewedParams();
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = true;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber(true);
                        if (object.size != null)
                            if ($util.Long)
                                (message.size = $util.Long.fromValue(object.size)).unsigned = true;
                            else if (typeof object.size === "string")
                                message.size = parseInt(object.size, 10);
                            else if (typeof object.size === "number")
                                message.size = object.size;
                            else if (typeof object.size === "object")
                                message.size = new $util.LongBits(object.size.low >>> 0, object.size.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RecentlyViewedParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.RecentlyViewedParams} message RecentlyViewedParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RecentlyViewedParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.size = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.size = options.longs === String ? "0" : 0;
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber(true) : message.page;
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (typeof message.size === "number")
                                object.size = options.longs === String ? String(message.size) : message.size;
                            else
                                object.size = options.longs === String ? $util.Long.prototype.toString.call(message.size) : options.longs === Number ? new $util.LongBits(message.size.low >>> 0, message.size.high >>> 0).toNumber(true) : message.size;
                        return object;
                    };

                    /**
                     * Converts this RecentlyViewedParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RecentlyViewedParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RecentlyViewedParams;
                })();

                recentlyviewed.ViewProductParams = (function() {

                    /**
                     * Properties of a ViewProductParams.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @interface IViewProductParams
                     * @property {string|null} [productCode] ViewProductParams productCode
                     * @property {string|null} [articleCode] ViewProductParams articleCode
                     */

                    /**
                     * Constructs a new ViewProductParams.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @classdesc Represents a ViewProductParams.
                     * @implements IViewProductParams
                     * @constructor
                     * @param {theplant.ec.api.recentlyviewed.IViewProductParams=} [properties] Properties to set
                     */
                    function ViewProductParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ViewProductParams productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @instance
                     */
                    ViewProductParams.prototype.productCode = "";

                    /**
                     * ViewProductParams articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @instance
                     */
                    ViewProductParams.prototype.articleCode = "";

                    /**
                     * Creates a new ViewProductParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IViewProductParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.recentlyviewed.ViewProductParams} ViewProductParams instance
                     */
                    ViewProductParams.create = function create(properties) {
                        return new ViewProductParams(properties);
                    };

                    /**
                     * Encodes the specified ViewProductParams message. Does not implicitly {@link theplant.ec.api.recentlyviewed.ViewProductParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IViewProductParams} message ViewProductParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ViewProductParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.productCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified ViewProductParams message, length delimited. Does not implicitly {@link theplant.ec.api.recentlyviewed.ViewProductParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IViewProductParams} message ViewProductParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ViewProductParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ViewProductParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.recentlyviewed.ViewProductParams} ViewProductParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ViewProductParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.recentlyviewed.ViewProductParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.productCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ViewProductParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.recentlyviewed.ViewProductParams} ViewProductParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ViewProductParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ViewProductParams message.
                     * @function verify
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ViewProductParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a ViewProductParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.recentlyviewed.ViewProductParams} ViewProductParams
                     */
                    ViewProductParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.recentlyviewed.ViewProductParams)
                            return object;
                        var message = new $root.theplant.ec.api.recentlyviewed.ViewProductParams();
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ViewProductParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.ViewProductParams} message ViewProductParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ViewProductParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.productCode = "";
                            object.articleCode = "";
                        }
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        return object;
                    };

                    /**
                     * Converts this ViewProductParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.recentlyviewed.ViewProductParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ViewProductParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ViewProductParams;
                })();

                recentlyviewed.RemoveViewedProductsParams = (function() {

                    /**
                     * Properties of a RemoveViewedProductsParams.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @interface IRemoveViewedProductsParams
                     * @property {Array.<string>|null} [articleCodes] RemoveViewedProductsParams articleCodes
                     */

                    /**
                     * Constructs a new RemoveViewedProductsParams.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @classdesc Represents a RemoveViewedProductsParams.
                     * @implements IRemoveViewedProductsParams
                     * @constructor
                     * @param {theplant.ec.api.recentlyviewed.IRemoveViewedProductsParams=} [properties] Properties to set
                     */
                    function RemoveViewedProductsParams(properties) {
                        this.articleCodes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RemoveViewedProductsParams articleCodes.
                     * @member {Array.<string>} articleCodes
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @instance
                     */
                    RemoveViewedProductsParams.prototype.articleCodes = $util.emptyArray;

                    /**
                     * Creates a new RemoveViewedProductsParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IRemoveViewedProductsParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.recentlyviewed.RemoveViewedProductsParams} RemoveViewedProductsParams instance
                     */
                    RemoveViewedProductsParams.create = function create(properties) {
                        return new RemoveViewedProductsParams(properties);
                    };

                    /**
                     * Encodes the specified RemoveViewedProductsParams message. Does not implicitly {@link theplant.ec.api.recentlyviewed.RemoveViewedProductsParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IRemoveViewedProductsParams} message RemoveViewedProductsParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveViewedProductsParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCodes != null && message.articleCodes.length)
                            for (var i = 0; i < message.articleCodes.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCodes[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified RemoveViewedProductsParams message, length delimited. Does not implicitly {@link theplant.ec.api.recentlyviewed.RemoveViewedProductsParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.IRemoveViewedProductsParams} message RemoveViewedProductsParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RemoveViewedProductsParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RemoveViewedProductsParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.recentlyviewed.RemoveViewedProductsParams} RemoveViewedProductsParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveViewedProductsParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.recentlyviewed.RemoveViewedProductsParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.articleCodes && message.articleCodes.length))
                                    message.articleCodes = [];
                                message.articleCodes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RemoveViewedProductsParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.recentlyviewed.RemoveViewedProductsParams} RemoveViewedProductsParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RemoveViewedProductsParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RemoveViewedProductsParams message.
                     * @function verify
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RemoveViewedProductsParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCodes != null && message.hasOwnProperty("articleCodes")) {
                            if (!Array.isArray(message.articleCodes))
                                return "articleCodes: array expected";
                            for (var i = 0; i < message.articleCodes.length; ++i)
                                if (!$util.isString(message.articleCodes[i]))
                                    return "articleCodes: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a RemoveViewedProductsParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.recentlyviewed.RemoveViewedProductsParams} RemoveViewedProductsParams
                     */
                    RemoveViewedProductsParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.recentlyviewed.RemoveViewedProductsParams)
                            return object;
                        var message = new $root.theplant.ec.api.recentlyviewed.RemoveViewedProductsParams();
                        if (object.articleCodes) {
                            if (!Array.isArray(object.articleCodes))
                                throw TypeError(".theplant.ec.api.recentlyviewed.RemoveViewedProductsParams.articleCodes: array expected");
                            message.articleCodes = [];
                            for (var i = 0; i < object.articleCodes.length; ++i)
                                message.articleCodes[i] = String(object.articleCodes[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RemoveViewedProductsParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @static
                     * @param {theplant.ec.api.recentlyviewed.RemoveViewedProductsParams} message RemoveViewedProductsParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RemoveViewedProductsParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.articleCodes = [];
                        if (message.articleCodes && message.articleCodes.length) {
                            object.articleCodes = [];
                            for (var j = 0; j < message.articleCodes.length; ++j)
                                object.articleCodes[j] = message.articleCodes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this RemoveViewedProductsParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.recentlyviewed.RemoveViewedProductsParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RemoveViewedProductsParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RemoveViewedProductsParams;
                })();

                recentlyviewed.RecentlyViewedService = (function() {

                    /**
                     * Constructs a new RecentlyViewedService service.
                     * @memberof theplant.ec.api.recentlyviewed
                     * @classdesc Represents a RecentlyViewedService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function RecentlyViewedService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (RecentlyViewedService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RecentlyViewedService;

                    /**
                     * Creates new RecentlyViewedService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {RecentlyViewedService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    RecentlyViewedService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.recentlyviewed.RecentlyViewedService#recentlyViewedProducts}.
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @typedef RecentlyViewedProductsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.products.ProductsResponse} [response] ProductsResponse
                     */

                    /**
                     * Calls RecentlyViewedProducts.
                     * @function recentlyViewedProducts
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @instance
                     * @param {theplant.ec.api.recentlyviewed.IRecentlyViewedParams} request RecentlyViewedParams message or plain object
                     * @param {theplant.ec.api.recentlyviewed.RecentlyViewedService.RecentlyViewedProductsCallback} callback Node-style callback called with the error, if any, and ProductsResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RecentlyViewedService.prototype.recentlyViewedProducts = function recentlyViewedProducts(request, callback) {
                        return this.rpcCall(recentlyViewedProducts, $root.theplant.ec.api.recentlyviewed.RecentlyViewedParams, $root.theplant.ec.api.products.ProductsResponse, request, callback);
                    }, "name", { value: "RecentlyViewedProducts" });

                    /**
                     * Calls RecentlyViewedProducts.
                     * @function recentlyViewedProducts
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @instance
                     * @param {theplant.ec.api.recentlyviewed.IRecentlyViewedParams} request RecentlyViewedParams message or plain object
                     * @returns {Promise<theplant.ec.api.products.ProductsResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.recentlyviewed.RecentlyViewedService#viewProduct}.
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @typedef ViewProductCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ViewProduct.
                     * @function viewProduct
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @instance
                     * @param {theplant.ec.api.recentlyviewed.IViewProductParams} request ViewProductParams message or plain object
                     * @param {theplant.ec.api.recentlyviewed.RecentlyViewedService.ViewProductCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RecentlyViewedService.prototype.viewProduct = function viewProduct(request, callback) {
                        return this.rpcCall(viewProduct, $root.theplant.ec.api.recentlyviewed.ViewProductParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ViewProduct" });

                    /**
                     * Calls ViewProduct.
                     * @function viewProduct
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @instance
                     * @param {theplant.ec.api.recentlyviewed.IViewProductParams} request ViewProductParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.recentlyviewed.RecentlyViewedService#removeViewedProducts}.
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @typedef RemoveViewedProductsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls RemoveViewedProducts.
                     * @function removeViewedProducts
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @instance
                     * @param {theplant.ec.api.recentlyviewed.IRemoveViewedProductsParams} request RemoveViewedProductsParams message or plain object
                     * @param {theplant.ec.api.recentlyviewed.RecentlyViewedService.RemoveViewedProductsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RecentlyViewedService.prototype.removeViewedProducts = function removeViewedProducts(request, callback) {
                        return this.rpcCall(removeViewedProducts, $root.theplant.ec.api.recentlyviewed.RemoveViewedProductsParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "RemoveViewedProducts" });

                    /**
                     * Calls RemoveViewedProducts.
                     * @function removeViewedProducts
                     * @memberof theplant.ec.api.recentlyviewed.RecentlyViewedService
                     * @instance
                     * @param {theplant.ec.api.recentlyviewed.IRemoveViewedProductsParams} request RemoveViewedProductsParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return RecentlyViewedService;
                })();

                return recentlyviewed;
            })();

            api.referencedata = (function() {

                /**
                 * Namespace referencedata.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var referencedata = {};

                referencedata.RDItems = (function() {

                    /**
                     * Properties of a RDItems.
                     * @memberof theplant.ec.api.referencedata
                     * @interface IRDItems
                     * @property {Array.<theplant.ec.service.referencedata.IRDItem>|null} [items] RDItems items
                     */

                    /**
                     * Constructs a new RDItems.
                     * @memberof theplant.ec.api.referencedata
                     * @classdesc Represents a RDItems.
                     * @implements IRDItems
                     * @constructor
                     * @param {theplant.ec.api.referencedata.IRDItems=} [properties] Properties to set
                     */
                    function RDItems(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDItems items.
                     * @member {Array.<theplant.ec.service.referencedata.IRDItem>} items
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @instance
                     */
                    RDItems.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new RDItems instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDItems=} [properties] Properties to set
                     * @returns {theplant.ec.api.referencedata.RDItems} RDItems instance
                     */
                    RDItems.create = function create(properties) {
                        return new RDItems(properties);
                    };

                    /**
                     * Encodes the specified RDItems message. Does not implicitly {@link theplant.ec.api.referencedata.RDItems.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDItems} message RDItems message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDItems.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.referencedata.RDItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RDItems message, length delimited. Does not implicitly {@link theplant.ec.api.referencedata.RDItems.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDItems} message RDItems message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDItems.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDItems message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.referencedata.RDItems} RDItems
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDItems.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.referencedata.RDItems();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.referencedata.RDItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDItems message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.referencedata.RDItems} RDItems
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDItems.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDItems message.
                     * @function verify
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDItems.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a RDItems message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.referencedata.RDItems} RDItems
                     */
                    RDItems.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.referencedata.RDItems)
                            return object;
                        var message = new $root.theplant.ec.api.referencedata.RDItems();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.api.referencedata.RDItems.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.api.referencedata.RDItems.items: object expected");
                                message.items[i] = $root.theplant.ec.service.referencedata.RDItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDItems message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @static
                     * @param {theplant.ec.api.referencedata.RDItems} message RDItems
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDItems.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.referencedata.RDItem.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this RDItems to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.referencedata.RDItems
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDItems.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDItems;
                })();

                referencedata.RDFilterConditions = (function() {

                    /**
                     * Properties of a RDFilterConditions.
                     * @memberof theplant.ec.api.referencedata
                     * @interface IRDFilterConditions
                     * @property {Array.<theplant.ec.service.referencedata.IRDFilterCondition>|null} [conds] RDFilterConditions conds
                     * @property {string|null} [metaItemType] RDFilterConditions metaItemType
                     * @property {theplant.ec.service.referencedata.RDItemOrder|null} [metaOrder] RDFilterConditions metaOrder
                     */

                    /**
                     * Constructs a new RDFilterConditions.
                     * @memberof theplant.ec.api.referencedata
                     * @classdesc Represents a RDFilterConditions.
                     * @implements IRDFilterConditions
                     * @constructor
                     * @param {theplant.ec.api.referencedata.IRDFilterConditions=} [properties] Properties to set
                     */
                    function RDFilterConditions(properties) {
                        this.conds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDFilterConditions conds.
                     * @member {Array.<theplant.ec.service.referencedata.IRDFilterCondition>} conds
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @instance
                     */
                    RDFilterConditions.prototype.conds = $util.emptyArray;

                    /**
                     * RDFilterConditions metaItemType.
                     * @member {string} metaItemType
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @instance
                     */
                    RDFilterConditions.prototype.metaItemType = "";

                    /**
                     * RDFilterConditions metaOrder.
                     * @member {theplant.ec.service.referencedata.RDItemOrder} metaOrder
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @instance
                     */
                    RDFilterConditions.prototype.metaOrder = 0;

                    /**
                     * Creates a new RDFilterConditions instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDFilterConditions=} [properties] Properties to set
                     * @returns {theplant.ec.api.referencedata.RDFilterConditions} RDFilterConditions instance
                     */
                    RDFilterConditions.create = function create(properties) {
                        return new RDFilterConditions(properties);
                    };

                    /**
                     * Encodes the specified RDFilterConditions message. Does not implicitly {@link theplant.ec.api.referencedata.RDFilterConditions.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDFilterConditions} message RDFilterConditions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDFilterConditions.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.conds != null && message.conds.length)
                            for (var i = 0; i < message.conds.length; ++i)
                                $root.theplant.ec.service.referencedata.RDFilterCondition.encode(message.conds[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.metaItemType != null && message.hasOwnProperty("metaItemType"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.metaItemType);
                        if (message.metaOrder != null && message.hasOwnProperty("metaOrder"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.metaOrder);
                        return writer;
                    };

                    /**
                     * Encodes the specified RDFilterConditions message, length delimited. Does not implicitly {@link theplant.ec.api.referencedata.RDFilterConditions.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDFilterConditions} message RDFilterConditions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDFilterConditions.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDFilterConditions message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.referencedata.RDFilterConditions} RDFilterConditions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDFilterConditions.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.referencedata.RDFilterConditions();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.conds && message.conds.length))
                                    message.conds = [];
                                message.conds.push($root.theplant.ec.service.referencedata.RDFilterCondition.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.metaItemType = reader.string();
                                break;
                            case 3:
                                message.metaOrder = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDFilterConditions message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.referencedata.RDFilterConditions} RDFilterConditions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDFilterConditions.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDFilterConditions message.
                     * @function verify
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDFilterConditions.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.conds != null && message.hasOwnProperty("conds")) {
                            if (!Array.isArray(message.conds))
                                return "conds: array expected";
                            for (var i = 0; i < message.conds.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDFilterCondition.verify(message.conds[i]);
                                if (error)
                                    return "conds." + error;
                            }
                        }
                        if (message.metaItemType != null && message.hasOwnProperty("metaItemType"))
                            if (!$util.isString(message.metaItemType))
                                return "metaItemType: string expected";
                        if (message.metaOrder != null && message.hasOwnProperty("metaOrder"))
                            switch (message.metaOrder) {
                            default:
                                return "metaOrder: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a RDFilterConditions message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.referencedata.RDFilterConditions} RDFilterConditions
                     */
                    RDFilterConditions.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.referencedata.RDFilterConditions)
                            return object;
                        var message = new $root.theplant.ec.api.referencedata.RDFilterConditions();
                        if (object.conds) {
                            if (!Array.isArray(object.conds))
                                throw TypeError(".theplant.ec.api.referencedata.RDFilterConditions.conds: array expected");
                            message.conds = [];
                            for (var i = 0; i < object.conds.length; ++i) {
                                if (typeof object.conds[i] !== "object")
                                    throw TypeError(".theplant.ec.api.referencedata.RDFilterConditions.conds: object expected");
                                message.conds[i] = $root.theplant.ec.service.referencedata.RDFilterCondition.fromObject(object.conds[i]);
                            }
                        }
                        if (object.metaItemType != null)
                            message.metaItemType = String(object.metaItemType);
                        switch (object.metaOrder) {
                        case "POSITION_ORDER":
                        case 0:
                            message.metaOrder = 0;
                            break;
                        case "VALUES_ORDER":
                        case 1:
                            message.metaOrder = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDFilterConditions message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @static
                     * @param {theplant.ec.api.referencedata.RDFilterConditions} message RDFilterConditions
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDFilterConditions.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.conds = [];
                        if (options.defaults) {
                            object.metaItemType = "";
                            object.metaOrder = options.enums === String ? "POSITION_ORDER" : 0;
                        }
                        if (message.conds && message.conds.length) {
                            object.conds = [];
                            for (var j = 0; j < message.conds.length; ++j)
                                object.conds[j] = $root.theplant.ec.service.referencedata.RDFilterCondition.toObject(message.conds[j], options);
                        }
                        if (message.metaItemType != null && message.hasOwnProperty("metaItemType"))
                            object.metaItemType = message.metaItemType;
                        if (message.metaOrder != null && message.hasOwnProperty("metaOrder"))
                            object.metaOrder = options.enums === String ? $root.theplant.ec.service.referencedata.RDItemOrder[message.metaOrder] : message.metaOrder;
                        return object;
                    };

                    /**
                     * Converts this RDFilterConditions to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.referencedata.RDFilterConditions
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDFilterConditions.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDFilterConditions;
                })();

                referencedata.RDGroups = (function() {

                    /**
                     * Properties of a RDGroups.
                     * @memberof theplant.ec.api.referencedata
                     * @interface IRDGroups
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [groups] RDGroups groups
                     */

                    /**
                     * Constructs a new RDGroups.
                     * @memberof theplant.ec.api.referencedata
                     * @classdesc Represents a RDGroups.
                     * @implements IRDGroups
                     * @constructor
                     * @param {theplant.ec.api.referencedata.IRDGroups=} [properties] Properties to set
                     */
                    function RDGroups(properties) {
                        this.groups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDGroups groups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} groups
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @instance
                     */
                    RDGroups.prototype.groups = $util.emptyArray;

                    /**
                     * Creates a new RDGroups instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDGroups=} [properties] Properties to set
                     * @returns {theplant.ec.api.referencedata.RDGroups} RDGroups instance
                     */
                    RDGroups.create = function create(properties) {
                        return new RDGroups(properties);
                    };

                    /**
                     * Encodes the specified RDGroups message. Does not implicitly {@link theplant.ec.api.referencedata.RDGroups.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDGroups} message RDGroups message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDGroups.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.groups != null && message.groups.length)
                            for (var i = 0; i < message.groups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.groups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RDGroups message, length delimited. Does not implicitly {@link theplant.ec.api.referencedata.RDGroups.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {theplant.ec.api.referencedata.IRDGroups} message RDGroups message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDGroups.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDGroups message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.referencedata.RDGroups} RDGroups
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDGroups.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.referencedata.RDGroups();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.groups && message.groups.length))
                                    message.groups = [];
                                message.groups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDGroups message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.referencedata.RDGroups} RDGroups
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDGroups.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDGroups message.
                     * @function verify
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDGroups.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.groups != null && message.hasOwnProperty("groups")) {
                            if (!Array.isArray(message.groups))
                                return "groups: array expected";
                            for (var i = 0; i < message.groups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.groups[i]);
                                if (error)
                                    return "groups." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a RDGroups message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.referencedata.RDGroups} RDGroups
                     */
                    RDGroups.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.referencedata.RDGroups)
                            return object;
                        var message = new $root.theplant.ec.api.referencedata.RDGroups();
                        if (object.groups) {
                            if (!Array.isArray(object.groups))
                                throw TypeError(".theplant.ec.api.referencedata.RDGroups.groups: array expected");
                            message.groups = [];
                            for (var i = 0; i < object.groups.length; ++i) {
                                if (typeof object.groups[i] !== "object")
                                    throw TypeError(".theplant.ec.api.referencedata.RDGroups.groups: object expected");
                                message.groups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.groups[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDGroups message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @static
                     * @param {theplant.ec.api.referencedata.RDGroups} message RDGroups
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDGroups.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.groups = [];
                        if (message.groups && message.groups.length) {
                            object.groups = [];
                            for (var j = 0; j < message.groups.length; ++j)
                                object.groups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.groups[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this RDGroups to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.referencedata.RDGroups
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDGroups.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDGroups;
                })();

                referencedata.ReferenceDataService = (function() {

                    /**
                     * Constructs a new ReferenceDataService service.
                     * @memberof theplant.ec.api.referencedata
                     * @classdesc Represents a ReferenceDataService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ReferenceDataService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ReferenceDataService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ReferenceDataService;

                    /**
                     * Creates new ReferenceDataService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ReferenceDataService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ReferenceDataService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.referencedata.ReferenceDataService#getItems}.
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @typedef GetItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.referencedata.RDItems} [response] RDItems
                     */

                    /**
                     * Calls GetItems.
                     * @function getItems
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @instance
                     * @param {theplant.ec.service.referencedata.IRDFilterCondition} request RDFilterCondition message or plain object
                     * @param {theplant.ec.api.referencedata.ReferenceDataService.GetItemsCallback} callback Node-style callback called with the error, if any, and RDItems
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ReferenceDataService.prototype.getItems = function getItems(request, callback) {
                        return this.rpcCall(getItems, $root.theplant.ec.service.referencedata.RDFilterCondition, $root.theplant.ec.api.referencedata.RDItems, request, callback);
                    }, "name", { value: "GetItems" });

                    /**
                     * Calls GetItems.
                     * @function getItems
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @instance
                     * @param {theplant.ec.service.referencedata.IRDFilterCondition} request RDFilterCondition message or plain object
                     * @returns {Promise<theplant.ec.api.referencedata.RDItems>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.referencedata.ReferenceDataService#getGroups}.
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @typedef GetGroupsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.referencedata.RDGroups} [response] RDGroups
                     */

                    /**
                     * Calls GetGroups.
                     * @function getGroups
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @instance
                     * @param {theplant.ec.api.referencedata.IRDFilterConditions} request RDFilterConditions message or plain object
                     * @param {theplant.ec.api.referencedata.ReferenceDataService.GetGroupsCallback} callback Node-style callback called with the error, if any, and RDGroups
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ReferenceDataService.prototype.getGroups = function getGroups(request, callback) {
                        return this.rpcCall(getGroups, $root.theplant.ec.api.referencedata.RDFilterConditions, $root.theplant.ec.api.referencedata.RDGroups, request, callback);
                    }, "name", { value: "GetGroups" });

                    /**
                     * Calls GetGroups.
                     * @function getGroups
                     * @memberof theplant.ec.api.referencedata.ReferenceDataService
                     * @instance
                     * @param {theplant.ec.api.referencedata.IRDFilterConditions} request RDFilterConditions message or plain object
                     * @returns {Promise<theplant.ec.api.referencedata.RDGroups>} Promise
                     * @variation 2
                     */

                    return ReferenceDataService;
                })();

                return referencedata;
            })();

            api.rollingtext = (function() {

                /**
                 * Namespace rollingtext.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var rollingtext = {};

                rollingtext.RollingTextService = (function() {

                    /**
                     * Constructs a new RollingTextService service.
                     * @memberof theplant.ec.api.rollingtext
                     * @classdesc Represents a RollingTextService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function RollingTextService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (RollingTextService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = RollingTextService;

                    /**
                     * Creates new RollingTextService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {RollingTextService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    RollingTextService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.rollingtext.RollingTextService#get}.
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @typedef GetCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.rollingtext.RollingTexts} [response] RollingTexts
                     */

                    /**
                     * Calls Get.
                     * @function get
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.rollingtext.RollingTextService.GetCallback} callback Node-style callback called with the error, if any, and RollingTexts
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RollingTextService.prototype.get = function get(request, callback) {
                        return this.rpcCall(get, $root.theplant.ec.api.Empty, $root.theplant.ec.service.rollingtext.RollingTexts, request, callback);
                    }, "name", { value: "Get" });

                    /**
                     * Calls Get.
                     * @function get
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.rollingtext.RollingTexts>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.rollingtext.RollingTextService#close}.
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @typedef CloseCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls Close.
                     * @function close
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.rollingtext.RollingTextService.CloseCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(RollingTextService.prototype.close = function close(request, callback) {
                        return this.rpcCall(close, $root.theplant.ec.api.Empty, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "Close" });

                    /**
                     * Calls Close.
                     * @function close
                     * @memberof theplant.ec.api.rollingtext.RollingTextService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return RollingTextService;
                })();

                return rollingtext;
            })();

            api.seo = (function() {

                /**
                 * Namespace seo.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var seo = {};

                seo.SEOService = (function() {

                    /**
                     * Constructs a new SEOService service.
                     * @memberof theplant.ec.api.seo
                     * @classdesc Represents a SEOService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function SEOService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (SEOService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SEOService;

                    /**
                     * Creates new SEOService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.seo.SEOService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {SEOService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    SEOService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.seo.SEOService#getSEO}.
                     * @memberof theplant.ec.api.seo.SEOService
                     * @typedef GetSEOCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.seo.SEOResult} [response] SEOResult
                     */

                    /**
                     * Calls GetSEO.
                     * @function getSEO
                     * @memberof theplant.ec.api.seo.SEOService
                     * @instance
                     * @param {theplant.ec.service.seo.ISEOParams} request SEOParams message or plain object
                     * @param {theplant.ec.api.seo.SEOService.GetSEOCallback} callback Node-style callback called with the error, if any, and SEOResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SEOService.prototype.getSEO = function getSEO(request, callback) {
                        return this.rpcCall(getSEO, $root.theplant.ec.service.seo.SEOParams, $root.theplant.ec.service.seo.SEOResult, request, callback);
                    }, "name", { value: "GetSEO" });

                    /**
                     * Calls GetSEO.
                     * @function getSEO
                     * @memberof theplant.ec.api.seo.SEOService
                     * @instance
                     * @param {theplant.ec.service.seo.ISEOParams} request SEOParams message or plain object
                     * @returns {Promise<theplant.ec.service.seo.SEOResult>} Promise
                     * @variation 2
                     */

                    return SEOService;
                })();

                return seo;
            })();

            api.shipfromstore = (function() {

                /**
                 * Namespace shipfromstore.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var shipfromstore = {};

                shipfromstore.GetParam = (function() {

                    /**
                     * Properties of a GetParam.
                     * @memberof theplant.ec.api.shipfromstore
                     * @interface IGetParam
                     * @property {string|null} [orderCode] GetParam orderCode
                     */

                    /**
                     * Constructs a new GetParam.
                     * @memberof theplant.ec.api.shipfromstore
                     * @classdesc Represents a GetParam.
                     * @implements IGetParam
                     * @constructor
                     * @param {theplant.ec.api.shipfromstore.IGetParam=} [properties] Properties to set
                     */
                    function GetParam(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetParam orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @instance
                     */
                    GetParam.prototype.orderCode = "";

                    /**
                     * Creates a new GetParam instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {theplant.ec.api.shipfromstore.IGetParam=} [properties] Properties to set
                     * @returns {theplant.ec.api.shipfromstore.GetParam} GetParam instance
                     */
                    GetParam.create = function create(properties) {
                        return new GetParam(properties);
                    };

                    /**
                     * Encodes the specified GetParam message. Does not implicitly {@link theplant.ec.api.shipfromstore.GetParam.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {theplant.ec.api.shipfromstore.IGetParam} message GetParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetParam.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified GetParam message, length delimited. Does not implicitly {@link theplant.ec.api.shipfromstore.GetParam.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {theplant.ec.api.shipfromstore.IGetParam} message GetParam message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetParam.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetParam message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.shipfromstore.GetParam} GetParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetParam.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.shipfromstore.GetParam();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetParam message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.shipfromstore.GetParam} GetParam
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetParam.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetParam message.
                     * @function verify
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetParam.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a GetParam message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.shipfromstore.GetParam} GetParam
                     */
                    GetParam.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.shipfromstore.GetParam)
                            return object;
                        var message = new $root.theplant.ec.api.shipfromstore.GetParam();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetParam message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @static
                     * @param {theplant.ec.api.shipfromstore.GetParam} message GetParam
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetParam.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        return object;
                    };

                    /**
                     * Converts this GetParam to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.shipfromstore.GetParam
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetParam.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetParam;
                })();

                shipfromstore.GetResponse = (function() {

                    /**
                     * Properties of a GetResponse.
                     * @memberof theplant.ec.api.shipfromstore
                     * @interface IGetResponse
                     * @property {Array.<theplant.ec.service.inventory.IShipFromStore>|null} [items] GetResponse items
                     */

                    /**
                     * Constructs a new GetResponse.
                     * @memberof theplant.ec.api.shipfromstore
                     * @classdesc Represents a GetResponse.
                     * @implements IGetResponse
                     * @constructor
                     * @param {theplant.ec.api.shipfromstore.IGetResponse=} [properties] Properties to set
                     */
                    function GetResponse(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GetResponse items.
                     * @member {Array.<theplant.ec.service.inventory.IShipFromStore>} items
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @instance
                     */
                    GetResponse.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new GetResponse instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {theplant.ec.api.shipfromstore.IGetResponse=} [properties] Properties to set
                     * @returns {theplant.ec.api.shipfromstore.GetResponse} GetResponse instance
                     */
                    GetResponse.create = function create(properties) {
                        return new GetResponse(properties);
                    };

                    /**
                     * Encodes the specified GetResponse message. Does not implicitly {@link theplant.ec.api.shipfromstore.GetResponse.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {theplant.ec.api.shipfromstore.IGetResponse} message GetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetResponse.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.inventory.ShipFromStore.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified GetResponse message, length delimited. Does not implicitly {@link theplant.ec.api.shipfromstore.GetResponse.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {theplant.ec.api.shipfromstore.IGetResponse} message GetResponse message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GetResponse.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GetResponse message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.shipfromstore.GetResponse} GetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetResponse.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.shipfromstore.GetResponse();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.inventory.ShipFromStore.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GetResponse message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.shipfromstore.GetResponse} GetResponse
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GetResponse.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GetResponse message.
                     * @function verify
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GetResponse.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.ShipFromStore.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a GetResponse message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.shipfromstore.GetResponse} GetResponse
                     */
                    GetResponse.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.shipfromstore.GetResponse)
                            return object;
                        var message = new $root.theplant.ec.api.shipfromstore.GetResponse();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.api.shipfromstore.GetResponse.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.api.shipfromstore.GetResponse.items: object expected");
                                message.items[i] = $root.theplant.ec.service.inventory.ShipFromStore.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a GetResponse message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @static
                     * @param {theplant.ec.api.shipfromstore.GetResponse} message GetResponse
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GetResponse.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.inventory.ShipFromStore.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this GetResponse to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.shipfromstore.GetResponse
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GetResponse.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GetResponse;
                })();

                shipfromstore.ShipFromStoreService = (function() {

                    /**
                     * Constructs a new ShipFromStoreService service.
                     * @memberof theplant.ec.api.shipfromstore
                     * @classdesc Represents a ShipFromStoreService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ShipFromStoreService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ShipFromStoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ShipFromStoreService;

                    /**
                     * Creates new ShipFromStoreService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ShipFromStoreService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ShipFromStoreService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.shipfromstore.ShipFromStoreService#getShipFromStoreOrders}.
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @typedef GetShipFromStoreOrdersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.shipfromstore.GetResponse} [response] GetResponse
                     */

                    /**
                     * Calls GetShipFromStoreOrders.
                     * @function getShipFromStoreOrders
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.shipfromstore.IGetParam} request GetParam message or plain object
                     * @param {theplant.ec.api.shipfromstore.ShipFromStoreService.GetShipFromStoreOrdersCallback} callback Node-style callback called with the error, if any, and GetResponse
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.getShipFromStoreOrders = function getShipFromStoreOrders(request, callback) {
                        return this.rpcCall(getShipFromStoreOrders, $root.theplant.ec.api.shipfromstore.GetParam, $root.theplant.ec.api.shipfromstore.GetResponse, request, callback);
                    }, "name", { value: "GetShipFromStoreOrders" });

                    /**
                     * Calls GetShipFromStoreOrders.
                     * @function getShipFromStoreOrders
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.api.shipfromstore.IGetParam} request GetParam message or plain object
                     * @returns {Promise<theplant.ec.api.shipfromstore.GetResponse>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.shipfromstore.ShipFromStoreService#applyShipFromStoreOrders}.
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @typedef ApplyShipFromStoreOrdersCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ApplyShipFromStoreOrders.
                     * @function applyShipFromStoreOrders
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.service.orders.IOrder} request Order message or plain object
                     * @param {theplant.ec.api.shipfromstore.ShipFromStoreService.ApplyShipFromStoreOrdersCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ShipFromStoreService.prototype.applyShipFromStoreOrders = function applyShipFromStoreOrders(request, callback) {
                        return this.rpcCall(applyShipFromStoreOrders, $root.theplant.ec.service.orders.Order, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ApplyShipFromStoreOrders" });

                    /**
                     * Calls ApplyShipFromStoreOrders.
                     * @function applyShipFromStoreOrders
                     * @memberof theplant.ec.api.shipfromstore.ShipFromStoreService
                     * @instance
                     * @param {theplant.ec.service.orders.IOrder} request Order message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return ShipFromStoreService;
                })();

                return shipfromstore;
            })();

            api.sizeguide = (function() {

                /**
                 * Namespace sizeguide.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var sizeguide = {};

                sizeguide.SizeGuideService = (function() {

                    /**
                     * Constructs a new SizeGuideService service.
                     * @memberof theplant.ec.api.sizeguide
                     * @classdesc Represents a SizeGuideService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function SizeGuideService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (SizeGuideService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SizeGuideService;

                    /**
                     * Creates new SizeGuideService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.sizeguide.SizeGuideService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {SizeGuideService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    SizeGuideService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.sizeguide.SizeGuideService#getSizeGuide}.
                     * @memberof theplant.ec.api.sizeguide.SizeGuideService
                     * @typedef GetSizeGuideCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.sizeguide.SizeGuide} [response] SizeGuide
                     */

                    /**
                     * Calls GetSizeGuide.
                     * @function getSizeGuide
                     * @memberof theplant.ec.api.sizeguide.SizeGuideService
                     * @instance
                     * @param {theplant.ec.service.sizeguide.ISizeGuideInput} request SizeGuideInput message or plain object
                     * @param {theplant.ec.api.sizeguide.SizeGuideService.GetSizeGuideCallback} callback Node-style callback called with the error, if any, and SizeGuide
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SizeGuideService.prototype.getSizeGuide = function getSizeGuide(request, callback) {
                        return this.rpcCall(getSizeGuide, $root.theplant.ec.service.sizeguide.SizeGuideInput, $root.theplant.ec.service.sizeguide.SizeGuide, request, callback);
                    }, "name", { value: "GetSizeGuide" });

                    /**
                     * Calls GetSizeGuide.
                     * @function getSizeGuide
                     * @memberof theplant.ec.api.sizeguide.SizeGuideService
                     * @instance
                     * @param {theplant.ec.service.sizeguide.ISizeGuideInput} request SizeGuideInput message or plain object
                     * @returns {Promise<theplant.ec.service.sizeguide.SizeGuide>} Promise
                     * @variation 2
                     */

                    return SizeGuideService;
                })();

                return sizeguide;
            })();

            api.stores = (function() {

                /**
                 * Namespace stores.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var stores = {};

                stores.StoresResult = (function() {

                    /**
                     * Properties of a StoresResult.
                     * @memberof theplant.ec.api.stores
                     * @interface IStoresResult
                     * @property {Array.<theplant.ec.service.stores.IStore>|null} [stores] StoresResult stores
                     */

                    /**
                     * Constructs a new StoresResult.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a StoresResult.
                     * @implements IStoresResult
                     * @constructor
                     * @param {theplant.ec.api.stores.IStoresResult=} [properties] Properties to set
                     */
                    function StoresResult(properties) {
                        this.stores = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoresResult stores.
                     * @member {Array.<theplant.ec.service.stores.IStore>} stores
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @instance
                     */
                    StoresResult.prototype.stores = $util.emptyArray;

                    /**
                     * Creates a new StoresResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {theplant.ec.api.stores.IStoresResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.stores.StoresResult} StoresResult instance
                     */
                    StoresResult.create = function create(properties) {
                        return new StoresResult(properties);
                    };

                    /**
                     * Encodes the specified StoresResult message. Does not implicitly {@link theplant.ec.api.stores.StoresResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {theplant.ec.api.stores.IStoresResult} message StoresResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoresResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stores != null && message.stores.length)
                            for (var i = 0; i < message.stores.length; ++i)
                                $root.theplant.ec.service.stores.Store.encode(message.stores[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified StoresResult message, length delimited. Does not implicitly {@link theplant.ec.api.stores.StoresResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {theplant.ec.api.stores.IStoresResult} message StoresResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoresResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoresResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.stores.StoresResult} StoresResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoresResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.stores.StoresResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stores && message.stores.length))
                                    message.stores = [];
                                message.stores.push($root.theplant.ec.service.stores.Store.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoresResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.stores.StoresResult} StoresResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoresResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoresResult message.
                     * @function verify
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoresResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stores != null && message.hasOwnProperty("stores")) {
                            if (!Array.isArray(message.stores))
                                return "stores: array expected";
                            for (var i = 0; i < message.stores.length; ++i) {
                                var error = $root.theplant.ec.service.stores.Store.verify(message.stores[i]);
                                if (error)
                                    return "stores." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a StoresResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.stores.StoresResult} StoresResult
                     */
                    StoresResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.stores.StoresResult)
                            return object;
                        var message = new $root.theplant.ec.api.stores.StoresResult();
                        if (object.stores) {
                            if (!Array.isArray(object.stores))
                                throw TypeError(".theplant.ec.api.stores.StoresResult.stores: array expected");
                            message.stores = [];
                            for (var i = 0; i < object.stores.length; ++i) {
                                if (typeof object.stores[i] !== "object")
                                    throw TypeError(".theplant.ec.api.stores.StoresResult.stores: object expected");
                                message.stores[i] = $root.theplant.ec.service.stores.Store.fromObject(object.stores[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoresResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @static
                     * @param {theplant.ec.api.stores.StoresResult} message StoresResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoresResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stores = [];
                        if (message.stores && message.stores.length) {
                            object.stores = [];
                            for (var j = 0; j < message.stores.length; ++j)
                                object.stores[j] = $root.theplant.ec.service.stores.Store.toObject(message.stores[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this StoresResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.stores.StoresResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoresResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoresResult;
                })();

                stores.StoreID = (function() {

                    /**
                     * Properties of a StoreID.
                     * @memberof theplant.ec.api.stores
                     * @interface IStoreID
                     * @property {string|null} [storeId] StoreID storeId
                     */

                    /**
                     * Constructs a new StoreID.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a StoreID.
                     * @implements IStoreID
                     * @constructor
                     * @param {theplant.ec.api.stores.IStoreID=} [properties] Properties to set
                     */
                    function StoreID(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreID storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.api.stores.StoreID
                     * @instance
                     */
                    StoreID.prototype.storeId = "";

                    /**
                     * Creates a new StoreID instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {theplant.ec.api.stores.IStoreID=} [properties] Properties to set
                     * @returns {theplant.ec.api.stores.StoreID} StoreID instance
                     */
                    StoreID.create = function create(properties) {
                        return new StoreID(properties);
                    };

                    /**
                     * Encodes the specified StoreID message. Does not implicitly {@link theplant.ec.api.stores.StoreID.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {theplant.ec.api.stores.IStoreID} message StoreID message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreID.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.storeId);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreID message, length delimited. Does not implicitly {@link theplant.ec.api.stores.StoreID.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {theplant.ec.api.stores.IStoreID} message StoreID message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreID.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreID message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.stores.StoreID} StoreID
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreID.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.stores.StoreID();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreID message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.stores.StoreID} StoreID
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreID.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreID message.
                     * @function verify
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreID.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        return null;
                    };

                    /**
                     * Creates a StoreID message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.stores.StoreID} StoreID
                     */
                    StoreID.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.stores.StoreID)
                            return object;
                        var message = new $root.theplant.ec.api.stores.StoreID();
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreID message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.stores.StoreID
                     * @static
                     * @param {theplant.ec.api.stores.StoreID} message StoreID
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreID.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.storeId = "";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        return object;
                    };

                    /**
                     * Converts this StoreID to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.stores.StoreID
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreID.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreID;
                })();

                stores.PrefectureResult = (function() {

                    /**
                     * Properties of a PrefectureResult.
                     * @memberof theplant.ec.api.stores
                     * @interface IPrefectureResult
                     * @property {Array.<theplant.ec.service.stores.IPrefecture>|null} [prefectures] PrefectureResult prefectures
                     */

                    /**
                     * Constructs a new PrefectureResult.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a PrefectureResult.
                     * @implements IPrefectureResult
                     * @constructor
                     * @param {theplant.ec.api.stores.IPrefectureResult=} [properties] Properties to set
                     */
                    function PrefectureResult(properties) {
                        this.prefectures = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PrefectureResult prefectures.
                     * @member {Array.<theplant.ec.service.stores.IPrefecture>} prefectures
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @instance
                     */
                    PrefectureResult.prototype.prefectures = $util.emptyArray;

                    /**
                     * Creates a new PrefectureResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {theplant.ec.api.stores.IPrefectureResult=} [properties] Properties to set
                     * @returns {theplant.ec.api.stores.PrefectureResult} PrefectureResult instance
                     */
                    PrefectureResult.create = function create(properties) {
                        return new PrefectureResult(properties);
                    };

                    /**
                     * Encodes the specified PrefectureResult message. Does not implicitly {@link theplant.ec.api.stores.PrefectureResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {theplant.ec.api.stores.IPrefectureResult} message PrefectureResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PrefectureResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.prefectures != null && message.prefectures.length)
                            for (var i = 0; i < message.prefectures.length; ++i)
                                $root.theplant.ec.service.stores.Prefecture.encode(message.prefectures[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PrefectureResult message, length delimited. Does not implicitly {@link theplant.ec.api.stores.PrefectureResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {theplant.ec.api.stores.IPrefectureResult} message PrefectureResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PrefectureResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PrefectureResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.stores.PrefectureResult} PrefectureResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PrefectureResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.stores.PrefectureResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.prefectures && message.prefectures.length))
                                    message.prefectures = [];
                                message.prefectures.push($root.theplant.ec.service.stores.Prefecture.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PrefectureResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.stores.PrefectureResult} PrefectureResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PrefectureResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PrefectureResult message.
                     * @function verify
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PrefectureResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.prefectures != null && message.hasOwnProperty("prefectures")) {
                            if (!Array.isArray(message.prefectures))
                                return "prefectures: array expected";
                            for (var i = 0; i < message.prefectures.length; ++i) {
                                var error = $root.theplant.ec.service.stores.Prefecture.verify(message.prefectures[i]);
                                if (error)
                                    return "prefectures." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a PrefectureResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.stores.PrefectureResult} PrefectureResult
                     */
                    PrefectureResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.stores.PrefectureResult)
                            return object;
                        var message = new $root.theplant.ec.api.stores.PrefectureResult();
                        if (object.prefectures) {
                            if (!Array.isArray(object.prefectures))
                                throw TypeError(".theplant.ec.api.stores.PrefectureResult.prefectures: array expected");
                            message.prefectures = [];
                            for (var i = 0; i < object.prefectures.length; ++i) {
                                if (typeof object.prefectures[i] !== "object")
                                    throw TypeError(".theplant.ec.api.stores.PrefectureResult.prefectures: object expected");
                                message.prefectures[i] = $root.theplant.ec.service.stores.Prefecture.fromObject(object.prefectures[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PrefectureResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @static
                     * @param {theplant.ec.api.stores.PrefectureResult} message PrefectureResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PrefectureResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.prefectures = [];
                        if (message.prefectures && message.prefectures.length) {
                            object.prefectures = [];
                            for (var j = 0; j < message.prefectures.length; ++j)
                                object.prefectures[j] = $root.theplant.ec.service.stores.Prefecture.toObject(message.prefectures[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this PrefectureResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.stores.PrefectureResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PrefectureResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PrefectureResult;
                })();

                stores.StoreInfoWithStockInput = (function() {

                    /**
                     * Properties of a StoreInfoWithStockInput.
                     * @memberof theplant.ec.api.stores
                     * @interface IStoreInfoWithStockInput
                     * @property {string|null} [articleCode] StoreInfoWithStockInput articleCode
                     * @property {string|null} [prefecture] StoreInfoWithStockInput prefecture
                     */

                    /**
                     * Constructs a new StoreInfoWithStockInput.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a StoreInfoWithStockInput.
                     * @implements IStoreInfoWithStockInput
                     * @constructor
                     * @param {theplant.ec.api.stores.IStoreInfoWithStockInput=} [properties] Properties to set
                     */
                    function StoreInfoWithStockInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreInfoWithStockInput articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @instance
                     */
                    StoreInfoWithStockInput.prototype.articleCode = "";

                    /**
                     * StoreInfoWithStockInput prefecture.
                     * @member {string} prefecture
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @instance
                     */
                    StoreInfoWithStockInput.prototype.prefecture = "";

                    /**
                     * Creates a new StoreInfoWithStockInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {theplant.ec.api.stores.IStoreInfoWithStockInput=} [properties] Properties to set
                     * @returns {theplant.ec.api.stores.StoreInfoWithStockInput} StoreInfoWithStockInput instance
                     */
                    StoreInfoWithStockInput.create = function create(properties) {
                        return new StoreInfoWithStockInput(properties);
                    };

                    /**
                     * Encodes the specified StoreInfoWithStockInput message. Does not implicitly {@link theplant.ec.api.stores.StoreInfoWithStockInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {theplant.ec.api.stores.IStoreInfoWithStockInput} message StoreInfoWithStockInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfoWithStockInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.prefecture);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreInfoWithStockInput message, length delimited. Does not implicitly {@link theplant.ec.api.stores.StoreInfoWithStockInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {theplant.ec.api.stores.IStoreInfoWithStockInput} message StoreInfoWithStockInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfoWithStockInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreInfoWithStockInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.stores.StoreInfoWithStockInput} StoreInfoWithStockInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfoWithStockInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.stores.StoreInfoWithStockInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.prefecture = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreInfoWithStockInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.stores.StoreInfoWithStockInput} StoreInfoWithStockInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfoWithStockInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreInfoWithStockInput message.
                     * @function verify
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreInfoWithStockInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            if (!$util.isString(message.prefecture))
                                return "prefecture: string expected";
                        return null;
                    };

                    /**
                     * Creates a StoreInfoWithStockInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.stores.StoreInfoWithStockInput} StoreInfoWithStockInput
                     */
                    StoreInfoWithStockInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.stores.StoreInfoWithStockInput)
                            return object;
                        var message = new $root.theplant.ec.api.stores.StoreInfoWithStockInput();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.prefecture != null)
                            message.prefecture = String(object.prefecture);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreInfoWithStockInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @static
                     * @param {theplant.ec.api.stores.StoreInfoWithStockInput} message StoreInfoWithStockInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreInfoWithStockInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            object.prefecture = "";
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            object.prefecture = message.prefecture;
                        return object;
                    };

                    /**
                     * Converts this StoreInfoWithStockInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.stores.StoreInfoWithStockInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreInfoWithStockInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreInfoWithStockInput;
                })();

                stores.StoreInfoWithStock = (function() {

                    /**
                     * Properties of a StoreInfoWithStock.
                     * @memberof theplant.ec.api.stores
                     * @interface IStoreInfoWithStock
                     * @property {Array.<theplant.ec.service.stores.IStoreInfoWithStock>|null} [stores] StoreInfoWithStock stores
                     */

                    /**
                     * Constructs a new StoreInfoWithStock.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a StoreInfoWithStock.
                     * @implements IStoreInfoWithStock
                     * @constructor
                     * @param {theplant.ec.api.stores.IStoreInfoWithStock=} [properties] Properties to set
                     */
                    function StoreInfoWithStock(properties) {
                        this.stores = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreInfoWithStock stores.
                     * @member {Array.<theplant.ec.service.stores.IStoreInfoWithStock>} stores
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.stores = $util.emptyArray;

                    /**
                     * Creates a new StoreInfoWithStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.api.stores.IStoreInfoWithStock=} [properties] Properties to set
                     * @returns {theplant.ec.api.stores.StoreInfoWithStock} StoreInfoWithStock instance
                     */
                    StoreInfoWithStock.create = function create(properties) {
                        return new StoreInfoWithStock(properties);
                    };

                    /**
                     * Encodes the specified StoreInfoWithStock message. Does not implicitly {@link theplant.ec.api.stores.StoreInfoWithStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.api.stores.IStoreInfoWithStock} message StoreInfoWithStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfoWithStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stores != null && message.stores.length)
                            for (var i = 0; i < message.stores.length; ++i)
                                $root.theplant.ec.service.stores.StoreInfoWithStock.encode(message.stores[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreInfoWithStock message, length delimited. Does not implicitly {@link theplant.ec.api.stores.StoreInfoWithStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.api.stores.IStoreInfoWithStock} message StoreInfoWithStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfoWithStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreInfoWithStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.stores.StoreInfoWithStock} StoreInfoWithStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfoWithStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.stores.StoreInfoWithStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stores && message.stores.length))
                                    message.stores = [];
                                message.stores.push($root.theplant.ec.service.stores.StoreInfoWithStock.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreInfoWithStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.stores.StoreInfoWithStock} StoreInfoWithStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfoWithStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreInfoWithStock message.
                     * @function verify
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreInfoWithStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stores != null && message.hasOwnProperty("stores")) {
                            if (!Array.isArray(message.stores))
                                return "stores: array expected";
                            for (var i = 0; i < message.stores.length; ++i) {
                                var error = $root.theplant.ec.service.stores.StoreInfoWithStock.verify(message.stores[i]);
                                if (error)
                                    return "stores." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a StoreInfoWithStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.stores.StoreInfoWithStock} StoreInfoWithStock
                     */
                    StoreInfoWithStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.stores.StoreInfoWithStock)
                            return object;
                        var message = new $root.theplant.ec.api.stores.StoreInfoWithStock();
                        if (object.stores) {
                            if (!Array.isArray(object.stores))
                                throw TypeError(".theplant.ec.api.stores.StoreInfoWithStock.stores: array expected");
                            message.stores = [];
                            for (var i = 0; i < object.stores.length; ++i) {
                                if (typeof object.stores[i] !== "object")
                                    throw TypeError(".theplant.ec.api.stores.StoreInfoWithStock.stores: object expected");
                                message.stores[i] = $root.theplant.ec.service.stores.StoreInfoWithStock.fromObject(object.stores[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreInfoWithStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.api.stores.StoreInfoWithStock} message StoreInfoWithStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreInfoWithStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stores = [];
                        if (message.stores && message.stores.length) {
                            object.stores = [];
                            for (var j = 0; j < message.stores.length; ++j)
                                object.stores[j] = $root.theplant.ec.service.stores.StoreInfoWithStock.toObject(message.stores[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this StoreInfoWithStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.stores.StoreInfoWithStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreInfoWithStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreInfoWithStock;
                })();

                stores.StoreService = (function() {

                    /**
                     * Constructs a new StoreService service.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a StoreService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function StoreService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (StoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = StoreService;

                    /**
                     * Creates new StoreService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.stores.StoreService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {StoreService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    StoreService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.stores.StoreService#stores}.
                     * @memberof theplant.ec.api.stores.StoreService
                     * @typedef StoresCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.stores.StoresResult} [response] StoresResult
                     */

                    /**
                     * Calls Stores.
                     * @function stores
                     * @memberof theplant.ec.api.stores.StoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.stores.StoreService.StoresCallback} callback Node-style callback called with the error, if any, and StoresResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.stores = function stores(request, callback) {
                        return this.rpcCall(stores, $root.theplant.ec.api.Empty, $root.theplant.ec.api.stores.StoresResult, request, callback);
                    }, "name", { value: "Stores" });

                    /**
                     * Calls Stores.
                     * @function stores
                     * @memberof theplant.ec.api.stores.StoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.stores.StoresResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.stores.StoreService#getPrefectures}.
                     * @memberof theplant.ec.api.stores.StoreService
                     * @typedef GetPrefecturesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.stores.PrefectureResult} [response] PrefectureResult
                     */

                    /**
                     * Calls GetPrefectures.
                     * @function getPrefectures
                     * @memberof theplant.ec.api.stores.StoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.stores.StoreService.GetPrefecturesCallback} callback Node-style callback called with the error, if any, and PrefectureResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.getPrefectures = function getPrefectures(request, callback) {
                        return this.rpcCall(getPrefectures, $root.theplant.ec.api.Empty, $root.theplant.ec.api.stores.PrefectureResult, request, callback);
                    }, "name", { value: "GetPrefectures" });

                    /**
                     * Calls GetPrefectures.
                     * @function getPrefectures
                     * @memberof theplant.ec.api.stores.StoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.stores.PrefectureResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.stores.StoreService#getStoreWithStockByArticleCode}.
                     * @memberof theplant.ec.api.stores.StoreService
                     * @typedef GetStoreWithStockByArticleCodeCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.stores.StoreInfoWithStock} [response] StoreInfoWithStock
                     */

                    /**
                     * Calls GetStoreWithStockByArticleCode.
                     * @function getStoreWithStockByArticleCode
                     * @memberof theplant.ec.api.stores.StoreService
                     * @instance
                     * @param {theplant.ec.api.stores.IStoreInfoWithStockInput} request StoreInfoWithStockInput message or plain object
                     * @param {theplant.ec.api.stores.StoreService.GetStoreWithStockByArticleCodeCallback} callback Node-style callback called with the error, if any, and StoreInfoWithStock
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(StoreService.prototype.getStoreWithStockByArticleCode = function getStoreWithStockByArticleCode(request, callback) {
                        return this.rpcCall(getStoreWithStockByArticleCode, $root.theplant.ec.api.stores.StoreInfoWithStockInput, $root.theplant.ec.api.stores.StoreInfoWithStock, request, callback);
                    }, "name", { value: "GetStoreWithStockByArticleCode" });

                    /**
                     * Calls GetStoreWithStockByArticleCode.
                     * @function getStoreWithStockByArticleCode
                     * @memberof theplant.ec.api.stores.StoreService
                     * @instance
                     * @param {theplant.ec.api.stores.IStoreInfoWithStockInput} request StoreInfoWithStockInput message or plain object
                     * @returns {Promise<theplant.ec.api.stores.StoreInfoWithStock>} Promise
                     * @variation 2
                     */

                    return StoreService;
                })();

                stores.MyStoreService = (function() {

                    /**
                     * Constructs a new MyStoreService service.
                     * @memberof theplant.ec.api.stores
                     * @classdesc Represents a MyStoreService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function MyStoreService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (MyStoreService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = MyStoreService;

                    /**
                     * Creates new MyStoreService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {MyStoreService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    MyStoreService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.stores.MyStoreService#myStores}.
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @typedef MyStoresCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.stores.StoresResult} [response] StoresResult
                     */

                    /**
                     * Calls MyStores.
                     * @function myStores
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.stores.MyStoreService.MyStoresCallback} callback Node-style callback called with the error, if any, and StoresResult
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MyStoreService.prototype.myStores = function myStores(request, callback) {
                        return this.rpcCall(myStores, $root.theplant.ec.api.Empty, $root.theplant.ec.api.stores.StoresResult, request, callback);
                    }, "name", { value: "MyStores" });

                    /**
                     * Calls MyStores.
                     * @function myStores
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.stores.StoresResult>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.stores.MyStoreService#changeMyStore}.
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @typedef ChangeMyStoreCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ChangeMyStore.
                     * @function changeMyStore
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @instance
                     * @param {theplant.ec.api.stores.IStoreID} request StoreID message or plain object
                     * @param {theplant.ec.api.stores.MyStoreService.ChangeMyStoreCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(MyStoreService.prototype.changeMyStore = function changeMyStore(request, callback) {
                        return this.rpcCall(changeMyStore, $root.theplant.ec.api.stores.StoreID, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ChangeMyStore" });

                    /**
                     * Calls ChangeMyStore.
                     * @function changeMyStore
                     * @memberof theplant.ec.api.stores.MyStoreService
                     * @instance
                     * @param {theplant.ec.api.stores.IStoreID} request StoreID message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return MyStoreService;
                })();

                return stores;
            })();

            api.users = (function() {

                /**
                 * Namespace users.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var users = {};

                users.RegisterParams = (function() {

                    /**
                     * Properties of a RegisterParams.
                     * @memberof theplant.ec.api.users
                     * @interface IRegisterParams
                     * @property {boolean|null} [isTermsAndPrivacyAgreed] RegisterParams isTermsAndPrivacyAgreed
                     * @property {string|null} [email] RegisterParams email
                     * @property {string|null} [password] RegisterParams password
                     * @property {string|null} [passwordConfirmation] RegisterParams passwordConfirmation
                     * @property {theplant.ec.service.users.IProfile|null} [profile] RegisterParams profile
                     * @property {string|null} [registerJwt] RegisterParams registerJwt
                     * @property {boolean|null} [isAddressStrictRequired] RegisterParams isAddressStrictRequired
                     */

                    /**
                     * Constructs a new RegisterParams.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a RegisterParams.
                     * @implements IRegisterParams
                     * @constructor
                     * @param {theplant.ec.api.users.IRegisterParams=} [properties] Properties to set
                     */
                    function RegisterParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RegisterParams isTermsAndPrivacyAgreed.
                     * @member {boolean} isTermsAndPrivacyAgreed
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.isTermsAndPrivacyAgreed = false;

                    /**
                     * RegisterParams email.
                     * @member {string} email
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.email = "";

                    /**
                     * RegisterParams password.
                     * @member {string} password
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.password = "";

                    /**
                     * RegisterParams passwordConfirmation.
                     * @member {string} passwordConfirmation
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.passwordConfirmation = "";

                    /**
                     * RegisterParams profile.
                     * @member {theplant.ec.service.users.IProfile|null|undefined} profile
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.profile = null;

                    /**
                     * RegisterParams registerJwt.
                     * @member {string} registerJwt
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.registerJwt = "";

                    /**
                     * RegisterParams isAddressStrictRequired.
                     * @member {boolean} isAddressStrictRequired
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     */
                    RegisterParams.prototype.isAddressStrictRequired = false;

                    /**
                     * Creates a new RegisterParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {theplant.ec.api.users.IRegisterParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.RegisterParams} RegisterParams instance
                     */
                    RegisterParams.create = function create(properties) {
                        return new RegisterParams(properties);
                    };

                    /**
                     * Encodes the specified RegisterParams message. Does not implicitly {@link theplant.ec.api.users.RegisterParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {theplant.ec.api.users.IRegisterParams} message RegisterParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.passwordConfirmation);
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            $root.theplant.ec.service.users.Profile.encode(message.profile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.registerJwt != null && message.hasOwnProperty("registerJwt"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.registerJwt);
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isTermsAndPrivacyAgreed);
                        if (message.isAddressStrictRequired != null && message.hasOwnProperty("isAddressStrictRequired"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isAddressStrictRequired);
                        return writer;
                    };

                    /**
                     * Encodes the specified RegisterParams message, length delimited. Does not implicitly {@link theplant.ec.api.users.RegisterParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {theplant.ec.api.users.IRegisterParams} message RegisterParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RegisterParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.RegisterParams} RegisterParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.RegisterParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 6:
                                message.isTermsAndPrivacyAgreed = reader.bool();
                                break;
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.password = reader.string();
                                break;
                            case 3:
                                message.passwordConfirmation = reader.string();
                                break;
                            case 4:
                                message.profile = $root.theplant.ec.service.users.Profile.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.registerJwt = reader.string();
                                break;
                            case 7:
                                message.isAddressStrictRequired = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RegisterParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.RegisterParams} RegisterParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RegisterParams message.
                     * @function verify
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RegisterParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            if (typeof message.isTermsAndPrivacyAgreed !== "boolean")
                                return "isTermsAndPrivacyAgreed: boolean expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            if (!$util.isString(message.passwordConfirmation))
                                return "passwordConfirmation: string expected";
                        if (message.profile != null && message.hasOwnProperty("profile")) {
                            var error = $root.theplant.ec.service.users.Profile.verify(message.profile);
                            if (error)
                                return "profile." + error;
                        }
                        if (message.registerJwt != null && message.hasOwnProperty("registerJwt"))
                            if (!$util.isString(message.registerJwt))
                                return "registerJwt: string expected";
                        if (message.isAddressStrictRequired != null && message.hasOwnProperty("isAddressStrictRequired"))
                            if (typeof message.isAddressStrictRequired !== "boolean")
                                return "isAddressStrictRequired: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a RegisterParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.RegisterParams} RegisterParams
                     */
                    RegisterParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.RegisterParams)
                            return object;
                        var message = new $root.theplant.ec.api.users.RegisterParams();
                        if (object.isTermsAndPrivacyAgreed != null)
                            message.isTermsAndPrivacyAgreed = Boolean(object.isTermsAndPrivacyAgreed);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.passwordConfirmation != null)
                            message.passwordConfirmation = String(object.passwordConfirmation);
                        if (object.profile != null) {
                            if (typeof object.profile !== "object")
                                throw TypeError(".theplant.ec.api.users.RegisterParams.profile: object expected");
                            message.profile = $root.theplant.ec.service.users.Profile.fromObject(object.profile);
                        }
                        if (object.registerJwt != null)
                            message.registerJwt = String(object.registerJwt);
                        if (object.isAddressStrictRequired != null)
                            message.isAddressStrictRequired = Boolean(object.isAddressStrictRequired);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RegisterParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @static
                     * @param {theplant.ec.api.users.RegisterParams} message RegisterParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RegisterParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.password = "";
                            object.passwordConfirmation = "";
                            object.profile = null;
                            object.registerJwt = "";
                            object.isTermsAndPrivacyAgreed = false;
                            object.isAddressStrictRequired = false;
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            object.passwordConfirmation = message.passwordConfirmation;
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            object.profile = $root.theplant.ec.service.users.Profile.toObject(message.profile, options);
                        if (message.registerJwt != null && message.hasOwnProperty("registerJwt"))
                            object.registerJwt = message.registerJwt;
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            object.isTermsAndPrivacyAgreed = message.isTermsAndPrivacyAgreed;
                        if (message.isAddressStrictRequired != null && message.hasOwnProperty("isAddressStrictRequired"))
                            object.isAddressStrictRequired = message.isAddressStrictRequired;
                        return object;
                    };

                    /**
                     * Converts this RegisterParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.RegisterParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RegisterParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RegisterParams;
                })();

                users.LoginInfo = (function() {

                    /**
                     * Properties of a LoginInfo.
                     * @memberof theplant.ec.api.users
                     * @interface ILoginInfo
                     * @property {string|null} [email] LoginInfo email
                     * @property {string|null} [password] LoginInfo password
                     */

                    /**
                     * Constructs a new LoginInfo.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a LoginInfo.
                     * @implements ILoginInfo
                     * @constructor
                     * @param {theplant.ec.api.users.ILoginInfo=} [properties] Properties to set
                     */
                    function LoginInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LoginInfo email.
                     * @member {string} email
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @instance
                     */
                    LoginInfo.prototype.email = "";

                    /**
                     * LoginInfo password.
                     * @member {string} password
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @instance
                     */
                    LoginInfo.prototype.password = "";

                    /**
                     * Creates a new LoginInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {theplant.ec.api.users.ILoginInfo=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.LoginInfo} LoginInfo instance
                     */
                    LoginInfo.create = function create(properties) {
                        return new LoginInfo(properties);
                    };

                    /**
                     * Encodes the specified LoginInfo message. Does not implicitly {@link theplant.ec.api.users.LoginInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {theplant.ec.api.users.ILoginInfo} message LoginInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LoginInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                        return writer;
                    };

                    /**
                     * Encodes the specified LoginInfo message, length delimited. Does not implicitly {@link theplant.ec.api.users.LoginInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {theplant.ec.api.users.ILoginInfo} message LoginInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LoginInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a LoginInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.LoginInfo} LoginInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LoginInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.LoginInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.password = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a LoginInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.LoginInfo} LoginInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LoginInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a LoginInfo message.
                     * @function verify
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LoginInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        return null;
                    };

                    /**
                     * Creates a LoginInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.LoginInfo} LoginInfo
                     */
                    LoginInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.LoginInfo)
                            return object;
                        var message = new $root.theplant.ec.api.users.LoginInfo();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.password != null)
                            message.password = String(object.password);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LoginInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @static
                     * @param {theplant.ec.api.users.LoginInfo} message LoginInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LoginInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.password = "";
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        return object;
                    };

                    /**
                     * Converts this LoginInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.LoginInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LoginInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LoginInfo;
                })();

                users.CheckoutLoginInfo = (function() {

                    /**
                     * Properties of a CheckoutLoginInfo.
                     * @memberof theplant.ec.api.users
                     * @interface ICheckoutLoginInfo
                     * @property {string|null} [email] CheckoutLoginInfo email
                     * @property {string|null} [password] CheckoutLoginInfo password
                     * @property {string|null} [orderCode] CheckoutLoginInfo orderCode
                     */

                    /**
                     * Constructs a new CheckoutLoginInfo.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a CheckoutLoginInfo.
                     * @implements ICheckoutLoginInfo
                     * @constructor
                     * @param {theplant.ec.api.users.ICheckoutLoginInfo=} [properties] Properties to set
                     */
                    function CheckoutLoginInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CheckoutLoginInfo email.
                     * @member {string} email
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @instance
                     */
                    CheckoutLoginInfo.prototype.email = "";

                    /**
                     * CheckoutLoginInfo password.
                     * @member {string} password
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @instance
                     */
                    CheckoutLoginInfo.prototype.password = "";

                    /**
                     * CheckoutLoginInfo orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @instance
                     */
                    CheckoutLoginInfo.prototype.orderCode = "";

                    /**
                     * Creates a new CheckoutLoginInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {theplant.ec.api.users.ICheckoutLoginInfo=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.CheckoutLoginInfo} CheckoutLoginInfo instance
                     */
                    CheckoutLoginInfo.create = function create(properties) {
                        return new CheckoutLoginInfo(properties);
                    };

                    /**
                     * Encodes the specified CheckoutLoginInfo message. Does not implicitly {@link theplant.ec.api.users.CheckoutLoginInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {theplant.ec.api.users.ICheckoutLoginInfo} message CheckoutLoginInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CheckoutLoginInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.orderCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified CheckoutLoginInfo message, length delimited. Does not implicitly {@link theplant.ec.api.users.CheckoutLoginInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {theplant.ec.api.users.ICheckoutLoginInfo} message CheckoutLoginInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CheckoutLoginInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CheckoutLoginInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.CheckoutLoginInfo} CheckoutLoginInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CheckoutLoginInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.CheckoutLoginInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.password = reader.string();
                                break;
                            case 3:
                                message.orderCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CheckoutLoginInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.CheckoutLoginInfo} CheckoutLoginInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CheckoutLoginInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CheckoutLoginInfo message.
                     * @function verify
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CheckoutLoginInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a CheckoutLoginInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.CheckoutLoginInfo} CheckoutLoginInfo
                     */
                    CheckoutLoginInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.CheckoutLoginInfo)
                            return object;
                        var message = new $root.theplant.ec.api.users.CheckoutLoginInfo();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CheckoutLoginInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @static
                     * @param {theplant.ec.api.users.CheckoutLoginInfo} message CheckoutLoginInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CheckoutLoginInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.password = "";
                            object.orderCode = "";
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        return object;
                    };

                    /**
                     * Converts this CheckoutLoginInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.CheckoutLoginInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CheckoutLoginInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CheckoutLoginInfo;
                })();

                users.UserEmail = (function() {

                    /**
                     * Properties of a UserEmail.
                     * @memberof theplant.ec.api.users
                     * @interface IUserEmail
                     * @property {string|null} [email] UserEmail email
                     */

                    /**
                     * Constructs a new UserEmail.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a UserEmail.
                     * @implements IUserEmail
                     * @constructor
                     * @param {theplant.ec.api.users.IUserEmail=} [properties] Properties to set
                     */
                    function UserEmail(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserEmail email.
                     * @member {string} email
                     * @memberof theplant.ec.api.users.UserEmail
                     * @instance
                     */
                    UserEmail.prototype.email = "";

                    /**
                     * Creates a new UserEmail instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {theplant.ec.api.users.IUserEmail=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.UserEmail} UserEmail instance
                     */
                    UserEmail.create = function create(properties) {
                        return new UserEmail(properties);
                    };

                    /**
                     * Encodes the specified UserEmail message. Does not implicitly {@link theplant.ec.api.users.UserEmail.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {theplant.ec.api.users.IUserEmail} message UserEmail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserEmail.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        return writer;
                    };

                    /**
                     * Encodes the specified UserEmail message, length delimited. Does not implicitly {@link theplant.ec.api.users.UserEmail.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {theplant.ec.api.users.IUserEmail} message UserEmail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserEmail.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a UserEmail message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.UserEmail} UserEmail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserEmail.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.UserEmail();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a UserEmail message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.UserEmail} UserEmail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserEmail.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a UserEmail message.
                     * @function verify
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserEmail.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        return null;
                    };

                    /**
                     * Creates a UserEmail message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.UserEmail} UserEmail
                     */
                    UserEmail.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.UserEmail)
                            return object;
                        var message = new $root.theplant.ec.api.users.UserEmail();
                        if (object.email != null)
                            message.email = String(object.email);
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserEmail message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.UserEmail
                     * @static
                     * @param {theplant.ec.api.users.UserEmail} message UserEmail
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserEmail.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.email = "";
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        return object;
                    };

                    /**
                     * Converts this UserEmail to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.UserEmail
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserEmail.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UserEmail;
                })();

                users.ChangeEmailParams = (function() {

                    /**
                     * Properties of a ChangeEmailParams.
                     * @memberof theplant.ec.api.users
                     * @interface IChangeEmailParams
                     * @property {string|null} [password] ChangeEmailParams password
                     * @property {string|null} [newEmail] ChangeEmailParams newEmail
                     */

                    /**
                     * Constructs a new ChangeEmailParams.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a ChangeEmailParams.
                     * @implements IChangeEmailParams
                     * @constructor
                     * @param {theplant.ec.api.users.IChangeEmailParams=} [properties] Properties to set
                     */
                    function ChangeEmailParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ChangeEmailParams password.
                     * @member {string} password
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @instance
                     */
                    ChangeEmailParams.prototype.password = "";

                    /**
                     * ChangeEmailParams newEmail.
                     * @member {string} newEmail
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @instance
                     */
                    ChangeEmailParams.prototype.newEmail = "";

                    /**
                     * Creates a new ChangeEmailParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {theplant.ec.api.users.IChangeEmailParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.ChangeEmailParams} ChangeEmailParams instance
                     */
                    ChangeEmailParams.create = function create(properties) {
                        return new ChangeEmailParams(properties);
                    };

                    /**
                     * Encodes the specified ChangeEmailParams message. Does not implicitly {@link theplant.ec.api.users.ChangeEmailParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {theplant.ec.api.users.IChangeEmailParams} message ChangeEmailParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangeEmailParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.password);
                        if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.newEmail);
                        return writer;
                    };

                    /**
                     * Encodes the specified ChangeEmailParams message, length delimited. Does not implicitly {@link theplant.ec.api.users.ChangeEmailParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {theplant.ec.api.users.IChangeEmailParams} message ChangeEmailParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangeEmailParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ChangeEmailParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.ChangeEmailParams} ChangeEmailParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangeEmailParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.ChangeEmailParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.password = reader.string();
                                break;
                            case 2:
                                message.newEmail = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ChangeEmailParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.ChangeEmailParams} ChangeEmailParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangeEmailParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ChangeEmailParams message.
                     * @function verify
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ChangeEmailParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                            if (!$util.isString(message.newEmail))
                                return "newEmail: string expected";
                        return null;
                    };

                    /**
                     * Creates a ChangeEmailParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.ChangeEmailParams} ChangeEmailParams
                     */
                    ChangeEmailParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.ChangeEmailParams)
                            return object;
                        var message = new $root.theplant.ec.api.users.ChangeEmailParams();
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.newEmail != null)
                            message.newEmail = String(object.newEmail);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ChangeEmailParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @static
                     * @param {theplant.ec.api.users.ChangeEmailParams} message ChangeEmailParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ChangeEmailParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.password = "";
                            object.newEmail = "";
                        }
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                            object.newEmail = message.newEmail;
                        return object;
                    };

                    /**
                     * Converts this ChangeEmailParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.ChangeEmailParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ChangeEmailParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ChangeEmailParams;
                })();

                users.SetPasswordParams = (function() {

                    /**
                     * Properties of a SetPasswordParams.
                     * @memberof theplant.ec.api.users
                     * @interface ISetPasswordParams
                     * @property {string|null} [token] SetPasswordParams token
                     * @property {string|null} [password] SetPasswordParams password
                     * @property {string|null} [passwordConfirmation] SetPasswordParams passwordConfirmation
                     */

                    /**
                     * Constructs a new SetPasswordParams.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a SetPasswordParams.
                     * @implements ISetPasswordParams
                     * @constructor
                     * @param {theplant.ec.api.users.ISetPasswordParams=} [properties] Properties to set
                     */
                    function SetPasswordParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SetPasswordParams token.
                     * @member {string} token
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @instance
                     */
                    SetPasswordParams.prototype.token = "";

                    /**
                     * SetPasswordParams password.
                     * @member {string} password
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @instance
                     */
                    SetPasswordParams.prototype.password = "";

                    /**
                     * SetPasswordParams passwordConfirmation.
                     * @member {string} passwordConfirmation
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @instance
                     */
                    SetPasswordParams.prototype.passwordConfirmation = "";

                    /**
                     * Creates a new SetPasswordParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ISetPasswordParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.SetPasswordParams} SetPasswordParams instance
                     */
                    SetPasswordParams.create = function create(properties) {
                        return new SetPasswordParams(properties);
                    };

                    /**
                     * Encodes the specified SetPasswordParams message. Does not implicitly {@link theplant.ec.api.users.SetPasswordParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ISetPasswordParams} message SetPasswordParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetPasswordParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.passwordConfirmation);
                        return writer;
                    };

                    /**
                     * Encodes the specified SetPasswordParams message, length delimited. Does not implicitly {@link theplant.ec.api.users.SetPasswordParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ISetPasswordParams} message SetPasswordParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetPasswordParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SetPasswordParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.SetPasswordParams} SetPasswordParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetPasswordParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.SetPasswordParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            case 2:
                                message.password = reader.string();
                                break;
                            case 3:
                                message.passwordConfirmation = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SetPasswordParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.SetPasswordParams} SetPasswordParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetPasswordParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SetPasswordParams message.
                     * @function verify
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SetPasswordParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            if (!$util.isString(message.passwordConfirmation))
                                return "passwordConfirmation: string expected";
                        return null;
                    };

                    /**
                     * Creates a SetPasswordParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.SetPasswordParams} SetPasswordParams
                     */
                    SetPasswordParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.SetPasswordParams)
                            return object;
                        var message = new $root.theplant.ec.api.users.SetPasswordParams();
                        if (object.token != null)
                            message.token = String(object.token);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.passwordConfirmation != null)
                            message.passwordConfirmation = String(object.passwordConfirmation);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SetPasswordParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.SetPasswordParams} message SetPasswordParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SetPasswordParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.token = "";
                            object.password = "";
                            object.passwordConfirmation = "";
                        }
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            object.passwordConfirmation = message.passwordConfirmation;
                        return object;
                    };

                    /**
                     * Converts this SetPasswordParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.SetPasswordParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SetPasswordParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SetPasswordParams;
                })();

                users.ChangeEmailToken = (function() {

                    /**
                     * Properties of a ChangeEmailToken.
                     * @memberof theplant.ec.api.users
                     * @interface IChangeEmailToken
                     * @property {string|null} [token] ChangeEmailToken token
                     */

                    /**
                     * Constructs a new ChangeEmailToken.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a ChangeEmailToken.
                     * @implements IChangeEmailToken
                     * @constructor
                     * @param {theplant.ec.api.users.IChangeEmailToken=} [properties] Properties to set
                     */
                    function ChangeEmailToken(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ChangeEmailToken token.
                     * @member {string} token
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @instance
                     */
                    ChangeEmailToken.prototype.token = "";

                    /**
                     * Creates a new ChangeEmailToken instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {theplant.ec.api.users.IChangeEmailToken=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.ChangeEmailToken} ChangeEmailToken instance
                     */
                    ChangeEmailToken.create = function create(properties) {
                        return new ChangeEmailToken(properties);
                    };

                    /**
                     * Encodes the specified ChangeEmailToken message. Does not implicitly {@link theplant.ec.api.users.ChangeEmailToken.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {theplant.ec.api.users.IChangeEmailToken} message ChangeEmailToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangeEmailToken.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        return writer;
                    };

                    /**
                     * Encodes the specified ChangeEmailToken message, length delimited. Does not implicitly {@link theplant.ec.api.users.ChangeEmailToken.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {theplant.ec.api.users.IChangeEmailToken} message ChangeEmailToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangeEmailToken.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ChangeEmailToken message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.ChangeEmailToken} ChangeEmailToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangeEmailToken.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.ChangeEmailToken();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ChangeEmailToken message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.ChangeEmailToken} ChangeEmailToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangeEmailToken.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ChangeEmailToken message.
                     * @function verify
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ChangeEmailToken.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        return null;
                    };

                    /**
                     * Creates a ChangeEmailToken message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.ChangeEmailToken} ChangeEmailToken
                     */
                    ChangeEmailToken.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.ChangeEmailToken)
                            return object;
                        var message = new $root.theplant.ec.api.users.ChangeEmailToken();
                        if (object.token != null)
                            message.token = String(object.token);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ChangeEmailToken message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @static
                     * @param {theplant.ec.api.users.ChangeEmailToken} message ChangeEmailToken
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ChangeEmailToken.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.token = "";
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        return object;
                    };

                    /**
                     * Converts this ChangeEmailToken to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.ChangeEmailToken
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ChangeEmailToken.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ChangeEmailToken;
                })();

                users.SetPasswordToken = (function() {

                    /**
                     * Properties of a SetPasswordToken.
                     * @memberof theplant.ec.api.users
                     * @interface ISetPasswordToken
                     * @property {string|null} [token] SetPasswordToken token
                     */

                    /**
                     * Constructs a new SetPasswordToken.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a SetPasswordToken.
                     * @implements ISetPasswordToken
                     * @constructor
                     * @param {theplant.ec.api.users.ISetPasswordToken=} [properties] Properties to set
                     */
                    function SetPasswordToken(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SetPasswordToken token.
                     * @member {string} token
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @instance
                     */
                    SetPasswordToken.prototype.token = "";

                    /**
                     * Creates a new SetPasswordToken instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {theplant.ec.api.users.ISetPasswordToken=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.SetPasswordToken} SetPasswordToken instance
                     */
                    SetPasswordToken.create = function create(properties) {
                        return new SetPasswordToken(properties);
                    };

                    /**
                     * Encodes the specified SetPasswordToken message. Does not implicitly {@link theplant.ec.api.users.SetPasswordToken.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {theplant.ec.api.users.ISetPasswordToken} message SetPasswordToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetPasswordToken.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        return writer;
                    };

                    /**
                     * Encodes the specified SetPasswordToken message, length delimited. Does not implicitly {@link theplant.ec.api.users.SetPasswordToken.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {theplant.ec.api.users.ISetPasswordToken} message SetPasswordToken message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetPasswordToken.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SetPasswordToken message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.SetPasswordToken} SetPasswordToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetPasswordToken.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.SetPasswordToken();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.token = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SetPasswordToken message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.SetPasswordToken} SetPasswordToken
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetPasswordToken.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SetPasswordToken message.
                     * @function verify
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SetPasswordToken.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        return null;
                    };

                    /**
                     * Creates a SetPasswordToken message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.SetPasswordToken} SetPasswordToken
                     */
                    SetPasswordToken.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.SetPasswordToken)
                            return object;
                        var message = new $root.theplant.ec.api.users.SetPasswordToken();
                        if (object.token != null)
                            message.token = String(object.token);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SetPasswordToken message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @static
                     * @param {theplant.ec.api.users.SetPasswordToken} message SetPasswordToken
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SetPasswordToken.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.token = "";
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        return object;
                    };

                    /**
                     * Converts this SetPasswordToken to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.SetPasswordToken
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SetPasswordToken.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SetPasswordToken;
                })();

                users.TokenStatus = (function() {

                    /**
                     * Properties of a TokenStatus.
                     * @memberof theplant.ec.api.users
                     * @interface ITokenStatus
                     * @property {theplant.ec.api.users.TokenStatus.Status|null} [status] TokenStatus status
                     */

                    /**
                     * Constructs a new TokenStatus.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a TokenStatus.
                     * @implements ITokenStatus
                     * @constructor
                     * @param {theplant.ec.api.users.ITokenStatus=} [properties] Properties to set
                     */
                    function TokenStatus(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TokenStatus status.
                     * @member {theplant.ec.api.users.TokenStatus.Status} status
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @instance
                     */
                    TokenStatus.prototype.status = 0;

                    /**
                     * Creates a new TokenStatus instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {theplant.ec.api.users.ITokenStatus=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.TokenStatus} TokenStatus instance
                     */
                    TokenStatus.create = function create(properties) {
                        return new TokenStatus(properties);
                    };

                    /**
                     * Encodes the specified TokenStatus message. Does not implicitly {@link theplant.ec.api.users.TokenStatus.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {theplant.ec.api.users.ITokenStatus} message TokenStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TokenStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.status != null && message.hasOwnProperty("status"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                        return writer;
                    };

                    /**
                     * Encodes the specified TokenStatus message, length delimited. Does not implicitly {@link theplant.ec.api.users.TokenStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {theplant.ec.api.users.ITokenStatus} message TokenStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TokenStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TokenStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.TokenStatus} TokenStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TokenStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.TokenStatus();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.status = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TokenStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.TokenStatus} TokenStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TokenStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TokenStatus message.
                     * @function verify
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TokenStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a TokenStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.TokenStatus} TokenStatus
                     */
                    TokenStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.TokenStatus)
                            return object;
                        var message = new $root.theplant.ec.api.users.TokenStatus();
                        switch (object.status) {
                        case "VALID":
                        case 0:
                            message.status = 0;
                            break;
                        case "INVALID":
                        case 1:
                            message.status = 1;
                            break;
                        case "EXPIRED":
                        case 2:
                            message.status = 2;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a TokenStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @static
                     * @param {theplant.ec.api.users.TokenStatus} message TokenStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TokenStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.status = options.enums === String ? "VALID" : 0;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.theplant.ec.api.users.TokenStatus.Status[message.status] : message.status;
                        return object;
                    };

                    /**
                     * Converts this TokenStatus to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.TokenStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TokenStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Status enum.
                     * @name theplant.ec.api.users.TokenStatus.Status
                     * @enum {string}
                     * @property {number} VALID=0 VALID value
                     * @property {number} INVALID=1 INVALID value
                     * @property {number} EXPIRED=2 EXPIRED value
                     */
                    TokenStatus.Status = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "VALID"] = 0;
                        values[valuesById[1] = "INVALID"] = 1;
                        values[valuesById[2] = "EXPIRED"] = 2;
                        return values;
                    })();

                    return TokenStatus;
                })();

                users.ChangePasswordParams = (function() {

                    /**
                     * Properties of a ChangePasswordParams.
                     * @memberof theplant.ec.api.users
                     * @interface IChangePasswordParams
                     * @property {string|null} [oldPassword] ChangePasswordParams oldPassword
                     * @property {string|null} [newPassword] ChangePasswordParams newPassword
                     * @property {string|null} [newPasswordConfirmation] ChangePasswordParams newPasswordConfirmation
                     */

                    /**
                     * Constructs a new ChangePasswordParams.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a ChangePasswordParams.
                     * @implements IChangePasswordParams
                     * @constructor
                     * @param {theplant.ec.api.users.IChangePasswordParams=} [properties] Properties to set
                     */
                    function ChangePasswordParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ChangePasswordParams oldPassword.
                     * @member {string} oldPassword
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @instance
                     */
                    ChangePasswordParams.prototype.oldPassword = "";

                    /**
                     * ChangePasswordParams newPassword.
                     * @member {string} newPassword
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @instance
                     */
                    ChangePasswordParams.prototype.newPassword = "";

                    /**
                     * ChangePasswordParams newPasswordConfirmation.
                     * @member {string} newPasswordConfirmation
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @instance
                     */
                    ChangePasswordParams.prototype.newPasswordConfirmation = "";

                    /**
                     * Creates a new ChangePasswordParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {theplant.ec.api.users.IChangePasswordParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.ChangePasswordParams} ChangePasswordParams instance
                     */
                    ChangePasswordParams.create = function create(properties) {
                        return new ChangePasswordParams(properties);
                    };

                    /**
                     * Encodes the specified ChangePasswordParams message. Does not implicitly {@link theplant.ec.api.users.ChangePasswordParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {theplant.ec.api.users.IChangePasswordParams} message ChangePasswordParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangePasswordParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.oldPassword);
                        if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
                        if (message.newPasswordConfirmation != null && message.hasOwnProperty("newPasswordConfirmation"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPasswordConfirmation);
                        return writer;
                    };

                    /**
                     * Encodes the specified ChangePasswordParams message, length delimited. Does not implicitly {@link theplant.ec.api.users.ChangePasswordParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {theplant.ec.api.users.IChangePasswordParams} message ChangePasswordParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangePasswordParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ChangePasswordParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.ChangePasswordParams} ChangePasswordParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangePasswordParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.ChangePasswordParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.oldPassword = reader.string();
                                break;
                            case 2:
                                message.newPassword = reader.string();
                                break;
                            case 3:
                                message.newPasswordConfirmation = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ChangePasswordParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.ChangePasswordParams} ChangePasswordParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangePasswordParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ChangePasswordParams message.
                     * @function verify
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ChangePasswordParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                            if (!$util.isString(message.oldPassword))
                                return "oldPassword: string expected";
                        if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                            if (!$util.isString(message.newPassword))
                                return "newPassword: string expected";
                        if (message.newPasswordConfirmation != null && message.hasOwnProperty("newPasswordConfirmation"))
                            if (!$util.isString(message.newPasswordConfirmation))
                                return "newPasswordConfirmation: string expected";
                        return null;
                    };

                    /**
                     * Creates a ChangePasswordParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.ChangePasswordParams} ChangePasswordParams
                     */
                    ChangePasswordParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.ChangePasswordParams)
                            return object;
                        var message = new $root.theplant.ec.api.users.ChangePasswordParams();
                        if (object.oldPassword != null)
                            message.oldPassword = String(object.oldPassword);
                        if (object.newPassword != null)
                            message.newPassword = String(object.newPassword);
                        if (object.newPasswordConfirmation != null)
                            message.newPasswordConfirmation = String(object.newPasswordConfirmation);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ChangePasswordParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ChangePasswordParams} message ChangePasswordParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ChangePasswordParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.oldPassword = "";
                            object.newPassword = "";
                            object.newPasswordConfirmation = "";
                        }
                        if (message.oldPassword != null && message.hasOwnProperty("oldPassword"))
                            object.oldPassword = message.oldPassword;
                        if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                            object.newPassword = message.newPassword;
                        if (message.newPasswordConfirmation != null && message.hasOwnProperty("newPasswordConfirmation"))
                            object.newPasswordConfirmation = message.newPasswordConfirmation;
                        return object;
                    };

                    /**
                     * Converts this ChangePasswordParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.ChangePasswordParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ChangePasswordParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ChangePasswordParams;
                })();

                users.Zipcode = (function() {

                    /**
                     * Properties of a Zipcode.
                     * @memberof theplant.ec.api.users
                     * @interface IZipcode
                     * @property {string|null} [zipcode] Zipcode zipcode
                     */

                    /**
                     * Constructs a new Zipcode.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a Zipcode.
                     * @implements IZipcode
                     * @constructor
                     * @param {theplant.ec.api.users.IZipcode=} [properties] Properties to set
                     */
                    function Zipcode(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Zipcode zipcode.
                     * @member {string} zipcode
                     * @memberof theplant.ec.api.users.Zipcode
                     * @instance
                     */
                    Zipcode.prototype.zipcode = "";

                    /**
                     * Creates a new Zipcode instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {theplant.ec.api.users.IZipcode=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.Zipcode} Zipcode instance
                     */
                    Zipcode.create = function create(properties) {
                        return new Zipcode(properties);
                    };

                    /**
                     * Encodes the specified Zipcode message. Does not implicitly {@link theplant.ec.api.users.Zipcode.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {theplant.ec.api.users.IZipcode} message Zipcode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Zipcode.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.zipcode != null && message.hasOwnProperty("zipcode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.zipcode);
                        return writer;
                    };

                    /**
                     * Encodes the specified Zipcode message, length delimited. Does not implicitly {@link theplant.ec.api.users.Zipcode.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {theplant.ec.api.users.IZipcode} message Zipcode message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Zipcode.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Zipcode message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.Zipcode} Zipcode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Zipcode.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.Zipcode();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.zipcode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Zipcode message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.Zipcode} Zipcode
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Zipcode.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Zipcode message.
                     * @function verify
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Zipcode.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.zipcode != null && message.hasOwnProperty("zipcode"))
                            if (!$util.isString(message.zipcode))
                                return "zipcode: string expected";
                        return null;
                    };

                    /**
                     * Creates a Zipcode message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.Zipcode} Zipcode
                     */
                    Zipcode.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.Zipcode)
                            return object;
                        var message = new $root.theplant.ec.api.users.Zipcode();
                        if (object.zipcode != null)
                            message.zipcode = String(object.zipcode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Zipcode message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.Zipcode
                     * @static
                     * @param {theplant.ec.api.users.Zipcode} message Zipcode
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Zipcode.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.zipcode = "";
                        if (message.zipcode != null && message.hasOwnProperty("zipcode"))
                            object.zipcode = message.zipcode;
                        return object;
                    };

                    /**
                     * Converts this Zipcode to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.Zipcode
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Zipcode.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Zipcode;
                })();

                users.EmailStatus = (function() {

                    /**
                     * Properties of an EmailStatus.
                     * @memberof theplant.ec.api.users
                     * @interface IEmailStatus
                     * @property {theplant.ec.api.users.EmailStatus.Status|null} [status] EmailStatus status
                     */

                    /**
                     * Constructs a new EmailStatus.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents an EmailStatus.
                     * @implements IEmailStatus
                     * @constructor
                     * @param {theplant.ec.api.users.IEmailStatus=} [properties] Properties to set
                     */
                    function EmailStatus(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * EmailStatus status.
                     * @member {theplant.ec.api.users.EmailStatus.Status} status
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @instance
                     */
                    EmailStatus.prototype.status = 0;

                    /**
                     * Creates a new EmailStatus instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {theplant.ec.api.users.IEmailStatus=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.EmailStatus} EmailStatus instance
                     */
                    EmailStatus.create = function create(properties) {
                        return new EmailStatus(properties);
                    };

                    /**
                     * Encodes the specified EmailStatus message. Does not implicitly {@link theplant.ec.api.users.EmailStatus.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {theplant.ec.api.users.IEmailStatus} message EmailStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EmailStatus.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.status != null && message.hasOwnProperty("status"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.status);
                        return writer;
                    };

                    /**
                     * Encodes the specified EmailStatus message, length delimited. Does not implicitly {@link theplant.ec.api.users.EmailStatus.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {theplant.ec.api.users.IEmailStatus} message EmailStatus message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    EmailStatus.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an EmailStatus message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.EmailStatus} EmailStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EmailStatus.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.EmailStatus();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.status = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an EmailStatus message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.EmailStatus} EmailStatus
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    EmailStatus.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an EmailStatus message.
                     * @function verify
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    EmailStatus.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.status != null && message.hasOwnProperty("status"))
                            switch (message.status) {
                            default:
                                return "status: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates an EmailStatus message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.EmailStatus} EmailStatus
                     */
                    EmailStatus.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.EmailStatus)
                            return object;
                        var message = new $root.theplant.ec.api.users.EmailStatus();
                        switch (object.status) {
                        case "NOT_EXISTS":
                        case 0:
                            message.status = 0;
                            break;
                        case "EXISTS_AND_HAS_PASSWORD":
                        case 1:
                            message.status = 1;
                            break;
                        case "EXISTS_AND_NO_PASSWORD":
                        case 2:
                            message.status = 2;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an EmailStatus message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @static
                     * @param {theplant.ec.api.users.EmailStatus} message EmailStatus
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    EmailStatus.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.status = options.enums === String ? "NOT_EXISTS" : 0;
                        if (message.status != null && message.hasOwnProperty("status"))
                            object.status = options.enums === String ? $root.theplant.ec.api.users.EmailStatus.Status[message.status] : message.status;
                        return object;
                    };

                    /**
                     * Converts this EmailStatus to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.EmailStatus
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    EmailStatus.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Status enum.
                     * @name theplant.ec.api.users.EmailStatus.Status
                     * @enum {string}
                     * @property {number} NOT_EXISTS=0 NOT_EXISTS value
                     * @property {number} EXISTS_AND_HAS_PASSWORD=1 EXISTS_AND_HAS_PASSWORD value
                     * @property {number} EXISTS_AND_NO_PASSWORD=2 EXISTS_AND_NO_PASSWORD value
                     */
                    EmailStatus.Status = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "NOT_EXISTS"] = 0;
                        values[valuesById[1] = "EXISTS_AND_HAS_PASSWORD"] = 1;
                        values[valuesById[2] = "EXISTS_AND_NO_PASSWORD"] = 2;
                        return values;
                    })();

                    return EmailStatus;
                })();

                users.ShippingAddresses = (function() {

                    /**
                     * Properties of a ShippingAddresses.
                     * @memberof theplant.ec.api.users
                     * @interface IShippingAddresses
                     * @property {Array.<theplant.ec.service.users.IShippingAddress>|null} [addresses] ShippingAddresses addresses
                     */

                    /**
                     * Constructs a new ShippingAddresses.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a ShippingAddresses.
                     * @implements IShippingAddresses
                     * @constructor
                     * @param {theplant.ec.api.users.IShippingAddresses=} [properties] Properties to set
                     */
                    function ShippingAddresses(properties) {
                        this.addresses = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShippingAddresses addresses.
                     * @member {Array.<theplant.ec.service.users.IShippingAddress>} addresses
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @instance
                     */
                    ShippingAddresses.prototype.addresses = $util.emptyArray;

                    /**
                     * Creates a new ShippingAddresses instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.users.IShippingAddresses=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.ShippingAddresses} ShippingAddresses instance
                     */
                    ShippingAddresses.create = function create(properties) {
                        return new ShippingAddresses(properties);
                    };

                    /**
                     * Encodes the specified ShippingAddresses message. Does not implicitly {@link theplant.ec.api.users.ShippingAddresses.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.users.IShippingAddresses} message ShippingAddresses message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddresses.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.addresses != null && message.addresses.length)
                            for (var i = 0; i < message.addresses.length; ++i)
                                $root.theplant.ec.service.users.ShippingAddress.encode(message.addresses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShippingAddresses message, length delimited. Does not implicitly {@link theplant.ec.api.users.ShippingAddresses.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.users.IShippingAddresses} message ShippingAddresses message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddresses.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShippingAddresses message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.ShippingAddresses} ShippingAddresses
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddresses.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.ShippingAddresses();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.addresses && message.addresses.length))
                                    message.addresses = [];
                                message.addresses.push($root.theplant.ec.service.users.ShippingAddress.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShippingAddresses message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.ShippingAddresses} ShippingAddresses
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddresses.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShippingAddresses message.
                     * @function verify
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShippingAddresses.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.addresses != null && message.hasOwnProperty("addresses")) {
                            if (!Array.isArray(message.addresses))
                                return "addresses: array expected";
                            for (var i = 0; i < message.addresses.length; ++i) {
                                var error = $root.theplant.ec.service.users.ShippingAddress.verify(message.addresses[i]);
                                if (error)
                                    return "addresses." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a ShippingAddresses message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.ShippingAddresses} ShippingAddresses
                     */
                    ShippingAddresses.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.ShippingAddresses)
                            return object;
                        var message = new $root.theplant.ec.api.users.ShippingAddresses();
                        if (object.addresses) {
                            if (!Array.isArray(object.addresses))
                                throw TypeError(".theplant.ec.api.users.ShippingAddresses.addresses: array expected");
                            message.addresses = [];
                            for (var i = 0; i < object.addresses.length; ++i) {
                                if (typeof object.addresses[i] !== "object")
                                    throw TypeError(".theplant.ec.api.users.ShippingAddresses.addresses: object expected");
                                message.addresses[i] = $root.theplant.ec.service.users.ShippingAddress.fromObject(object.addresses[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShippingAddresses message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @static
                     * @param {theplant.ec.api.users.ShippingAddresses} message ShippingAddresses
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShippingAddresses.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.addresses = [];
                        if (message.addresses && message.addresses.length) {
                            object.addresses = [];
                            for (var j = 0; j < message.addresses.length; ++j)
                                object.addresses[j] = $root.theplant.ec.service.users.ShippingAddress.toObject(message.addresses[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this ShippingAddresses to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.ShippingAddresses
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShippingAddresses.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShippingAddresses;
                })();

                users.ShippingAddressID = (function() {

                    /**
                     * Properties of a ShippingAddressID.
                     * @memberof theplant.ec.api.users
                     * @interface IShippingAddressID
                     * @property {string|null} [id] ShippingAddressID id
                     */

                    /**
                     * Constructs a new ShippingAddressID.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a ShippingAddressID.
                     * @implements IShippingAddressID
                     * @constructor
                     * @param {theplant.ec.api.users.IShippingAddressID=} [properties] Properties to set
                     */
                    function ShippingAddressID(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShippingAddressID id.
                     * @member {string} id
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @instance
                     */
                    ShippingAddressID.prototype.id = "";

                    /**
                     * Creates a new ShippingAddressID instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {theplant.ec.api.users.IShippingAddressID=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.ShippingAddressID} ShippingAddressID instance
                     */
                    ShippingAddressID.create = function create(properties) {
                        return new ShippingAddressID(properties);
                    };

                    /**
                     * Encodes the specified ShippingAddressID message. Does not implicitly {@link theplant.ec.api.users.ShippingAddressID.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {theplant.ec.api.users.IShippingAddressID} message ShippingAddressID message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddressID.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified ShippingAddressID message, length delimited. Does not implicitly {@link theplant.ec.api.users.ShippingAddressID.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {theplant.ec.api.users.IShippingAddressID} message ShippingAddressID message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddressID.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShippingAddressID message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.ShippingAddressID} ShippingAddressID
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddressID.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.ShippingAddressID();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShippingAddressID message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.ShippingAddressID} ShippingAddressID
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddressID.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShippingAddressID message.
                     * @function verify
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShippingAddressID.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        return null;
                    };

                    /**
                     * Creates a ShippingAddressID message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.ShippingAddressID} ShippingAddressID
                     */
                    ShippingAddressID.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.ShippingAddressID)
                            return object;
                        var message = new $root.theplant.ec.api.users.ShippingAddressID();
                        if (object.id != null)
                            message.id = String(object.id);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShippingAddressID message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @static
                     * @param {theplant.ec.api.users.ShippingAddressID} message ShippingAddressID
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShippingAddressID.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        return object;
                    };

                    /**
                     * Converts this ShippingAddressID to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.ShippingAddressID
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShippingAddressID.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShippingAddressID;
                })();

                users.UserId = (function() {

                    /**
                     * Properties of a UserId.
                     * @memberof theplant.ec.api.users
                     * @interface IUserId
                     * @property {string|null} [id] UserId id
                     */

                    /**
                     * Constructs a new UserId.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a UserId.
                     * @implements IUserId
                     * @constructor
                     * @param {theplant.ec.api.users.IUserId=} [properties] Properties to set
                     */
                    function UserId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserId id.
                     * @member {string} id
                     * @memberof theplant.ec.api.users.UserId
                     * @instance
                     */
                    UserId.prototype.id = "";

                    /**
                     * Creates a new UserId instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {theplant.ec.api.users.IUserId=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.UserId} UserId instance
                     */
                    UserId.create = function create(properties) {
                        return new UserId(properties);
                    };

                    /**
                     * Encodes the specified UserId message. Does not implicitly {@link theplant.ec.api.users.UserId.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {theplant.ec.api.users.IUserId} message UserId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        return writer;
                    };

                    /**
                     * Encodes the specified UserId message, length delimited. Does not implicitly {@link theplant.ec.api.users.UserId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {theplant.ec.api.users.IUserId} message UserId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a UserId message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.UserId} UserId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.UserId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a UserId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.UserId} UserId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a UserId message.
                     * @function verify
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        return null;
                    };

                    /**
                     * Creates a UserId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.UserId} UserId
                     */
                    UserId.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.UserId)
                            return object;
                        var message = new $root.theplant.ec.api.users.UserId();
                        if (object.id != null)
                            message.id = String(object.id);
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.UserId
                     * @static
                     * @param {theplant.ec.api.users.UserId} message UserId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        return object;
                    };

                    /**
                     * Converts this UserId to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.UserId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UserId;
                })();

                users.LoginOrSetInitialPasswordRes = (function() {

                    /**
                     * Properties of a LoginOrSetInitialPasswordRes.
                     * @memberof theplant.ec.api.users
                     * @interface ILoginOrSetInitialPasswordRes
                     * @property {theplant.ec.service.users.IUser|null} [user] LoginOrSetInitialPasswordRes user
                     * @property {boolean|null} [includeNovelty] LoginOrSetInitialPasswordRes includeNovelty
                     */

                    /**
                     * Constructs a new LoginOrSetInitialPasswordRes.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a LoginOrSetInitialPasswordRes.
                     * @implements ILoginOrSetInitialPasswordRes
                     * @constructor
                     * @param {theplant.ec.api.users.ILoginOrSetInitialPasswordRes=} [properties] Properties to set
                     */
                    function LoginOrSetInitialPasswordRes(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LoginOrSetInitialPasswordRes user.
                     * @member {theplant.ec.service.users.IUser|null|undefined} user
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @instance
                     */
                    LoginOrSetInitialPasswordRes.prototype.user = null;

                    /**
                     * LoginOrSetInitialPasswordRes includeNovelty.
                     * @member {boolean} includeNovelty
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @instance
                     */
                    LoginOrSetInitialPasswordRes.prototype.includeNovelty = false;

                    /**
                     * Creates a new LoginOrSetInitialPasswordRes instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {theplant.ec.api.users.ILoginOrSetInitialPasswordRes=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.LoginOrSetInitialPasswordRes} LoginOrSetInitialPasswordRes instance
                     */
                    LoginOrSetInitialPasswordRes.create = function create(properties) {
                        return new LoginOrSetInitialPasswordRes(properties);
                    };

                    /**
                     * Encodes the specified LoginOrSetInitialPasswordRes message. Does not implicitly {@link theplant.ec.api.users.LoginOrSetInitialPasswordRes.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {theplant.ec.api.users.ILoginOrSetInitialPasswordRes} message LoginOrSetInitialPasswordRes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LoginOrSetInitialPasswordRes.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.user != null && message.hasOwnProperty("user"))
                            $root.theplant.ec.service.users.User.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.includeNovelty != null && message.hasOwnProperty("includeNovelty"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.includeNovelty);
                        return writer;
                    };

                    /**
                     * Encodes the specified LoginOrSetInitialPasswordRes message, length delimited. Does not implicitly {@link theplant.ec.api.users.LoginOrSetInitialPasswordRes.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {theplant.ec.api.users.ILoginOrSetInitialPasswordRes} message LoginOrSetInitialPasswordRes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LoginOrSetInitialPasswordRes.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a LoginOrSetInitialPasswordRes message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.LoginOrSetInitialPasswordRes} LoginOrSetInitialPasswordRes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LoginOrSetInitialPasswordRes.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.LoginOrSetInitialPasswordRes();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.user = $root.theplant.ec.service.users.User.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.includeNovelty = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a LoginOrSetInitialPasswordRes message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.LoginOrSetInitialPasswordRes} LoginOrSetInitialPasswordRes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LoginOrSetInitialPasswordRes.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a LoginOrSetInitialPasswordRes message.
                     * @function verify
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LoginOrSetInitialPasswordRes.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.user != null && message.hasOwnProperty("user")) {
                            var error = $root.theplant.ec.service.users.User.verify(message.user);
                            if (error)
                                return "user." + error;
                        }
                        if (message.includeNovelty != null && message.hasOwnProperty("includeNovelty"))
                            if (typeof message.includeNovelty !== "boolean")
                                return "includeNovelty: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a LoginOrSetInitialPasswordRes message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.LoginOrSetInitialPasswordRes} LoginOrSetInitialPasswordRes
                     */
                    LoginOrSetInitialPasswordRes.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.LoginOrSetInitialPasswordRes)
                            return object;
                        var message = new $root.theplant.ec.api.users.LoginOrSetInitialPasswordRes();
                        if (object.user != null) {
                            if (typeof object.user !== "object")
                                throw TypeError(".theplant.ec.api.users.LoginOrSetInitialPasswordRes.user: object expected");
                            message.user = $root.theplant.ec.service.users.User.fromObject(object.user);
                        }
                        if (object.includeNovelty != null)
                            message.includeNovelty = Boolean(object.includeNovelty);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LoginOrSetInitialPasswordRes message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @static
                     * @param {theplant.ec.api.users.LoginOrSetInitialPasswordRes} message LoginOrSetInitialPasswordRes
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LoginOrSetInitialPasswordRes.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.user = null;
                            object.includeNovelty = false;
                        }
                        if (message.user != null && message.hasOwnProperty("user"))
                            object.user = $root.theplant.ec.service.users.User.toObject(message.user, options);
                        if (message.includeNovelty != null && message.hasOwnProperty("includeNovelty"))
                            object.includeNovelty = message.includeNovelty;
                        return object;
                    };

                    /**
                     * Converts this LoginOrSetInitialPasswordRes to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.LoginOrSetInitialPasswordRes
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LoginOrSetInitialPasswordRes.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return LoginOrSetInitialPasswordRes;
                })();

                users.AuthnService = (function() {

                    /**
                     * Constructs a new AuthnService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents an AuthnService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AuthnService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (AuthnService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AuthnService;

                    /**
                     * Creates new AuthnService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.AuthnService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AuthnService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AuthnService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#currentUser}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef CurrentUserCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls CurrentUser.
                     * @function currentUser
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.AuthnService.CurrentUserCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.currentUser = function currentUser(request, callback) {
                        return this.rpcCall(currentUser, $root.theplant.ec.api.Empty, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "CurrentUser" });

                    /**
                     * Calls CurrentUser.
                     * @function currentUser
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#validateRegister}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef ValidateRegisterCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ValidateRegister.
                     * @function validateRegister
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IRegisterParams} request RegisterParams message or plain object
                     * @param {theplant.ec.api.users.AuthnService.ValidateRegisterCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.validateRegister = function validateRegister(request, callback) {
                        return this.rpcCall(validateRegister, $root.theplant.ec.api.users.RegisterParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ValidateRegister" });

                    /**
                     * Calls ValidateRegister.
                     * @function validateRegister
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IRegisterParams} request RegisterParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#register}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef RegisterCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls Register.
                     * @function register
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IRegisterParams} request RegisterParams message or plain object
                     * @param {theplant.ec.api.users.AuthnService.RegisterCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.register = function register(request, callback) {
                        return this.rpcCall(register, $root.theplant.ec.api.users.RegisterParams, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "Register" });

                    /**
                     * Calls Register.
                     * @function register
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IRegisterParams} request RegisterParams message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#validateLogin}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef ValidateLoginCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ValidateLogin.
                     * @function validateLogin
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ILoginInfo} request LoginInfo message or plain object
                     * @param {theplant.ec.api.users.AuthnService.ValidateLoginCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.validateLogin = function validateLogin(request, callback) {
                        return this.rpcCall(validateLogin, $root.theplant.ec.api.users.LoginInfo, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ValidateLogin" });

                    /**
                     * Calls ValidateLogin.
                     * @function validateLogin
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ILoginInfo} request LoginInfo message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#login}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef LoginCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls Login.
                     * @function login
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ILoginInfo} request LoginInfo message or plain object
                     * @param {theplant.ec.api.users.AuthnService.LoginCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.login = function login(request, callback) {
                        return this.rpcCall(login, $root.theplant.ec.api.users.LoginInfo, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "Login" });

                    /**
                     * Calls Login.
                     * @function login
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ILoginInfo} request LoginInfo message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#logout}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef LogoutCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls Logout.
                     * @function logout
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.AuthnService.LogoutCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.logout = function logout(request, callback) {
                        return this.rpcCall(logout, $root.theplant.ec.api.Empty, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "Logout" });

                    /**
                     * Calls Logout.
                     * @function logout
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#sendResetPasswordEmail}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef SendResetPasswordEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls SendResetPasswordEmail.
                     * @function sendResetPasswordEmail
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IUserEmail} request UserEmail message or plain object
                     * @param {theplant.ec.api.users.AuthnService.SendResetPasswordEmailCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.sendResetPasswordEmail = function sendResetPasswordEmail(request, callback) {
                        return this.rpcCall(sendResetPasswordEmail, $root.theplant.ec.api.users.UserEmail, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "SendResetPasswordEmail" });

                    /**
                     * Calls SendResetPasswordEmail.
                     * @function sendResetPasswordEmail
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IUserEmail} request UserEmail message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#setPassword}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef SetPasswordCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls SetPassword.
                     * @function setPassword
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ISetPasswordParams} request SetPasswordParams message or plain object
                     * @param {theplant.ec.api.users.AuthnService.SetPasswordCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.setPassword = function setPassword(request, callback) {
                        return this.rpcCall(setPassword, $root.theplant.ec.api.users.SetPasswordParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "SetPassword" });

                    /**
                     * Calls SetPassword.
                     * @function setPassword
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ISetPasswordParams} request SetPasswordParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#checkSetPasswordToken}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef CheckSetPasswordTokenCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.TokenStatus} [response] TokenStatus
                     */

                    /**
                     * Calls CheckSetPasswordToken.
                     * @function checkSetPasswordToken
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ISetPasswordToken} request SetPasswordToken message or plain object
                     * @param {theplant.ec.api.users.AuthnService.CheckSetPasswordTokenCallback} callback Node-style callback called with the error, if any, and TokenStatus
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.checkSetPasswordToken = function checkSetPasswordToken(request, callback) {
                        return this.rpcCall(checkSetPasswordToken, $root.theplant.ec.api.users.SetPasswordToken, $root.theplant.ec.api.users.TokenStatus, request, callback);
                    }, "name", { value: "CheckSetPasswordToken" });

                    /**
                     * Calls CheckSetPasswordToken.
                     * @function checkSetPasswordToken
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ISetPasswordToken} request SetPasswordToken message or plain object
                     * @returns {Promise<theplant.ec.api.users.TokenStatus>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#sendChangeEmailTokenToNewEmail}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef SendChangeEmailTokenToNewEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls SendChangeEmailTokenToNewEmail.
                     * @function sendChangeEmailTokenToNewEmail
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IChangeEmailParams} request ChangeEmailParams message or plain object
                     * @param {theplant.ec.api.users.AuthnService.SendChangeEmailTokenToNewEmailCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.sendChangeEmailTokenToNewEmail = function sendChangeEmailTokenToNewEmail(request, callback) {
                        return this.rpcCall(sendChangeEmailTokenToNewEmail, $root.theplant.ec.api.users.ChangeEmailParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "SendChangeEmailTokenToNewEmail" });

                    /**
                     * Calls SendChangeEmailTokenToNewEmail.
                     * @function sendChangeEmailTokenToNewEmail
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IChangeEmailParams} request ChangeEmailParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#changeEmail}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef ChangeEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ChangeEmail.
                     * @function changeEmail
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IChangeEmailToken} request ChangeEmailToken message or plain object
                     * @param {theplant.ec.api.users.AuthnService.ChangeEmailCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.changeEmail = function changeEmail(request, callback) {
                        return this.rpcCall(changeEmail, $root.theplant.ec.api.users.ChangeEmailToken, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ChangeEmail" });

                    /**
                     * Calls ChangeEmail.
                     * @function changeEmail
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IChangeEmailToken} request ChangeEmailToken message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#changePasswordByOldPassword}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef ChangePasswordByOldPasswordCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ChangePasswordByOldPassword.
                     * @function changePasswordByOldPassword
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IChangePasswordParams} request ChangePasswordParams message or plain object
                     * @param {theplant.ec.api.users.AuthnService.ChangePasswordByOldPasswordCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.changePasswordByOldPassword = function changePasswordByOldPassword(request, callback) {
                        return this.rpcCall(changePasswordByOldPassword, $root.theplant.ec.api.users.ChangePasswordParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ChangePasswordByOldPassword" });

                    /**
                     * Calls ChangePasswordByOldPassword.
                     * @function changePasswordByOldPassword
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.users.IChangePasswordParams} request ChangePasswordParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AuthnService#withdraw}.
                     * @memberof theplant.ec.api.users.AuthnService
                     * @typedef WithdrawCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls Withdraw.
                     * @function withdraw
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.AuthnService.WithdrawCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AuthnService.prototype.withdraw = function withdraw(request, callback) {
                        return this.rpcCall(withdraw, $root.theplant.ec.api.Empty, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "Withdraw" });

                    /**
                     * Calls Withdraw.
                     * @function withdraw
                     * @memberof theplant.ec.api.users.AuthnService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return AuthnService;
                })();

                users.AccountService = (function() {

                    /**
                     * Constructs a new AccountService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents an AccountService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function AccountService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (AccountService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = AccountService;

                    /**
                     * Creates new AccountService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.AccountService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {AccountService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    AccountService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AccountService#applyProfile}.
                     * @memberof theplant.ec.api.users.AccountService
                     * @typedef ApplyProfileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls ApplyProfile.
                     * @function applyProfile
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.service.users.IProfile} request Profile message or plain object
                     * @param {theplant.ec.api.users.AccountService.ApplyProfileCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.applyProfile = function applyProfile(request, callback) {
                        return this.rpcCall(applyProfile, $root.theplant.ec.service.users.Profile, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "ApplyProfile" });

                    /**
                     * Calls ApplyProfile.
                     * @function applyProfile
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.service.users.IProfile} request Profile message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AccountService#validateProfile}.
                     * @memberof theplant.ec.api.users.AccountService
                     * @typedef ValidateProfileCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls ValidateProfile.
                     * @function validateProfile
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.service.users.IProfile} request Profile message or plain object
                     * @param {theplant.ec.api.users.AccountService.ValidateProfileCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.validateProfile = function validateProfile(request, callback) {
                        return this.rpcCall(validateProfile, $root.theplant.ec.service.users.Profile, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "ValidateProfile" });

                    /**
                     * Calls ValidateProfile.
                     * @function validateProfile
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.service.users.IProfile} request Profile message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AccountService#checkEmail}.
                     * @memberof theplant.ec.api.users.AccountService
                     * @typedef CheckEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.EmailStatus} [response] EmailStatus
                     */

                    /**
                     * Calls CheckEmail.
                     * @function checkEmail
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.api.users.IUserEmail} request UserEmail message or plain object
                     * @param {theplant.ec.api.users.AccountService.CheckEmailCallback} callback Node-style callback called with the error, if any, and EmailStatus
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.checkEmail = function checkEmail(request, callback) {
                        return this.rpcCall(checkEmail, $root.theplant.ec.api.users.UserEmail, $root.theplant.ec.api.users.EmailStatus, request, callback);
                    }, "name", { value: "CheckEmail" });

                    /**
                     * Calls CheckEmail.
                     * @function checkEmail
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.api.users.IUserEmail} request UserEmail message or plain object
                     * @returns {Promise<theplant.ec.api.users.EmailStatus>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AccountService#getShippingAddresses}.
                     * @memberof theplant.ec.api.users.AccountService
                     * @typedef GetShippingAddressesCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.ShippingAddresses} [response] ShippingAddresses
                     */

                    /**
                     * Calls GetShippingAddresses.
                     * @function getShippingAddresses
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.AccountService.GetShippingAddressesCallback} callback Node-style callback called with the error, if any, and ShippingAddresses
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.getShippingAddresses = function getShippingAddresses(request, callback) {
                        return this.rpcCall(getShippingAddresses, $root.theplant.ec.api.Empty, $root.theplant.ec.api.users.ShippingAddresses, request, callback);
                    }, "name", { value: "GetShippingAddresses" });

                    /**
                     * Calls GetShippingAddresses.
                     * @function getShippingAddresses
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.users.ShippingAddresses>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AccountService#putShippingAddress}.
                     * @memberof theplant.ec.api.users.AccountService
                     * @typedef PutShippingAddressCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.ShippingAddress} [response] ShippingAddress
                     */

                    /**
                     * Calls PutShippingAddress.
                     * @function putShippingAddress
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.service.users.IShippingAddress} request ShippingAddress message or plain object
                     * @param {theplant.ec.api.users.AccountService.PutShippingAddressCallback} callback Node-style callback called with the error, if any, and ShippingAddress
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.putShippingAddress = function putShippingAddress(request, callback) {
                        return this.rpcCall(putShippingAddress, $root.theplant.ec.service.users.ShippingAddress, $root.theplant.ec.service.users.ShippingAddress, request, callback);
                    }, "name", { value: "PutShippingAddress" });

                    /**
                     * Calls PutShippingAddress.
                     * @function putShippingAddress
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.service.users.IShippingAddress} request ShippingAddress message or plain object
                     * @returns {Promise<theplant.ec.service.users.ShippingAddress>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.AccountService#deleteShippingAddress}.
                     * @memberof theplant.ec.api.users.AccountService
                     * @typedef DeleteShippingAddressCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls DeleteShippingAddress.
                     * @function deleteShippingAddress
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.api.users.IShippingAddressID} request ShippingAddressID message or plain object
                     * @param {theplant.ec.api.users.AccountService.DeleteShippingAddressCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(AccountService.prototype.deleteShippingAddress = function deleteShippingAddress(request, callback) {
                        return this.rpcCall(deleteShippingAddress, $root.theplant.ec.api.users.ShippingAddressID, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "DeleteShippingAddress" });

                    /**
                     * Calls DeleteShippingAddress.
                     * @function deleteShippingAddress
                     * @memberof theplant.ec.api.users.AccountService
                     * @instance
                     * @param {theplant.ec.api.users.IShippingAddressID} request ShippingAddressID message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return AccountService;
                })();

                users.ZipcodeService = (function() {

                    /**
                     * Constructs a new ZipcodeService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a ZipcodeService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function ZipcodeService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (ZipcodeService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = ZipcodeService;

                    /**
                     * Creates new ZipcodeService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.ZipcodeService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {ZipcodeService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    ZipcodeService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.ZipcodeService#find}.
                     * @memberof theplant.ec.api.users.ZipcodeService
                     * @typedef FindCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.zipcode.ZipcodeAddress} [response] ZipcodeAddress
                     */

                    /**
                     * Calls Find.
                     * @function find
                     * @memberof theplant.ec.api.users.ZipcodeService
                     * @instance
                     * @param {theplant.ec.api.users.IZipcode} request Zipcode message or plain object
                     * @param {theplant.ec.api.users.ZipcodeService.FindCallback} callback Node-style callback called with the error, if any, and ZipcodeAddress
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(ZipcodeService.prototype.find = function find(request, callback) {
                        return this.rpcCall(find, $root.theplant.ec.api.users.Zipcode, $root.theplant.ec.service.zipcode.ZipcodeAddress, request, callback);
                    }, "name", { value: "Find" });

                    /**
                     * Calls Find.
                     * @function find
                     * @memberof theplant.ec.api.users.ZipcodeService
                     * @instance
                     * @param {theplant.ec.api.users.IZipcode} request Zipcode message or plain object
                     * @returns {Promise<theplant.ec.service.zipcode.ZipcodeAddress>} Promise
                     * @variation 2
                     */

                    return ZipcodeService;
                })();

                users.CanopusPointRecord = (function() {

                    /**
                     * Properties of a CanopusPointRecord.
                     * @memberof theplant.ec.api.users
                     * @interface ICanopusPointRecord
                     * @property {string|null} [orderCode] CanopusPointRecord orderCode
                     * @property {string|null} [chargeDate] CanopusPointRecord chargeDate
                     * @property {string|null} [shopId] CanopusPointRecord shopId
                     * @property {string|null} [changeReason] CanopusPointRecord changeReason
                     * @property {string|null} [pointText] CanopusPointRecord pointText
                     * @property {boolean|null} [isGain] CanopusPointRecord isGain
                     * @property {string|null} [pointRemark] CanopusPointRecord pointRemark
                     */

                    /**
                     * Constructs a new CanopusPointRecord.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a CanopusPointRecord.
                     * @implements ICanopusPointRecord
                     * @constructor
                     * @param {theplant.ec.api.users.ICanopusPointRecord=} [properties] Properties to set
                     */
                    function CanopusPointRecord(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CanopusPointRecord orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.orderCode = "";

                    /**
                     * CanopusPointRecord chargeDate.
                     * @member {string} chargeDate
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.chargeDate = "";

                    /**
                     * CanopusPointRecord shopId.
                     * @member {string} shopId
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.shopId = "";

                    /**
                     * CanopusPointRecord changeReason.
                     * @member {string} changeReason
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.changeReason = "";

                    /**
                     * CanopusPointRecord pointText.
                     * @member {string} pointText
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.pointText = "";

                    /**
                     * CanopusPointRecord isGain.
                     * @member {boolean} isGain
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.isGain = false;

                    /**
                     * CanopusPointRecord pointRemark.
                     * @member {string} pointRemark
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     */
                    CanopusPointRecord.prototype.pointRemark = "";

                    /**
                     * Creates a new CanopusPointRecord instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {theplant.ec.api.users.ICanopusPointRecord=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.CanopusPointRecord} CanopusPointRecord instance
                     */
                    CanopusPointRecord.create = function create(properties) {
                        return new CanopusPointRecord(properties);
                    };

                    /**
                     * Encodes the specified CanopusPointRecord message. Does not implicitly {@link theplant.ec.api.users.CanopusPointRecord.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {theplant.ec.api.users.ICanopusPointRecord} message CanopusPointRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CanopusPointRecord.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.chargeDate != null && message.hasOwnProperty("chargeDate"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.chargeDate);
                        if (message.shopId != null && message.hasOwnProperty("shopId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.shopId);
                        if (message.changeReason != null && message.hasOwnProperty("changeReason"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.changeReason);
                        if (message.pointText != null && message.hasOwnProperty("pointText"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pointText);
                        if (message.isGain != null && message.hasOwnProperty("isGain"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isGain);
                        if (message.pointRemark != null && message.hasOwnProperty("pointRemark"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.pointRemark);
                        return writer;
                    };

                    /**
                     * Encodes the specified CanopusPointRecord message, length delimited. Does not implicitly {@link theplant.ec.api.users.CanopusPointRecord.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {theplant.ec.api.users.ICanopusPointRecord} message CanopusPointRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CanopusPointRecord.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CanopusPointRecord message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.CanopusPointRecord} CanopusPointRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CanopusPointRecord.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.CanopusPointRecord();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.chargeDate = reader.string();
                                break;
                            case 3:
                                message.shopId = reader.string();
                                break;
                            case 4:
                                message.changeReason = reader.string();
                                break;
                            case 5:
                                message.pointText = reader.string();
                                break;
                            case 6:
                                message.isGain = reader.bool();
                                break;
                            case 7:
                                message.pointRemark = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CanopusPointRecord message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.CanopusPointRecord} CanopusPointRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CanopusPointRecord.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CanopusPointRecord message.
                     * @function verify
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CanopusPointRecord.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.chargeDate != null && message.hasOwnProperty("chargeDate"))
                            if (!$util.isString(message.chargeDate))
                                return "chargeDate: string expected";
                        if (message.shopId != null && message.hasOwnProperty("shopId"))
                            if (!$util.isString(message.shopId))
                                return "shopId: string expected";
                        if (message.changeReason != null && message.hasOwnProperty("changeReason"))
                            if (!$util.isString(message.changeReason))
                                return "changeReason: string expected";
                        if (message.pointText != null && message.hasOwnProperty("pointText"))
                            if (!$util.isString(message.pointText))
                                return "pointText: string expected";
                        if (message.isGain != null && message.hasOwnProperty("isGain"))
                            if (typeof message.isGain !== "boolean")
                                return "isGain: boolean expected";
                        if (message.pointRemark != null && message.hasOwnProperty("pointRemark"))
                            if (!$util.isString(message.pointRemark))
                                return "pointRemark: string expected";
                        return null;
                    };

                    /**
                     * Creates a CanopusPointRecord message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.CanopusPointRecord} CanopusPointRecord
                     */
                    CanopusPointRecord.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.CanopusPointRecord)
                            return object;
                        var message = new $root.theplant.ec.api.users.CanopusPointRecord();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.chargeDate != null)
                            message.chargeDate = String(object.chargeDate);
                        if (object.shopId != null)
                            message.shopId = String(object.shopId);
                        if (object.changeReason != null)
                            message.changeReason = String(object.changeReason);
                        if (object.pointText != null)
                            message.pointText = String(object.pointText);
                        if (object.isGain != null)
                            message.isGain = Boolean(object.isGain);
                        if (object.pointRemark != null)
                            message.pointRemark = String(object.pointRemark);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CanopusPointRecord message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @static
                     * @param {theplant.ec.api.users.CanopusPointRecord} message CanopusPointRecord
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CanopusPointRecord.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.chargeDate = "";
                            object.shopId = "";
                            object.changeReason = "";
                            object.pointText = "";
                            object.isGain = false;
                            object.pointRemark = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.chargeDate != null && message.hasOwnProperty("chargeDate"))
                            object.chargeDate = message.chargeDate;
                        if (message.shopId != null && message.hasOwnProperty("shopId"))
                            object.shopId = message.shopId;
                        if (message.changeReason != null && message.hasOwnProperty("changeReason"))
                            object.changeReason = message.changeReason;
                        if (message.pointText != null && message.hasOwnProperty("pointText"))
                            object.pointText = message.pointText;
                        if (message.isGain != null && message.hasOwnProperty("isGain"))
                            object.isGain = message.isGain;
                        if (message.pointRemark != null && message.hasOwnProperty("pointRemark"))
                            object.pointRemark = message.pointRemark;
                        return object;
                    };

                    /**
                     * Converts this CanopusPointRecord to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.CanopusPointRecord
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CanopusPointRecord.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CanopusPointRecord;
                })();

                users.CustomerPointHistory = (function() {

                    /**
                     * Properties of a CustomerPointHistory.
                     * @memberof theplant.ec.api.users
                     * @interface ICustomerPointHistory
                     * @property {theplant.ec.service.points.ICustomerPointBase|null} [base] CustomerPointHistory base
                     * @property {Array.<theplant.ec.api.users.ICanopusPointRecord>|null} [Records] CustomerPointHistory Records
                     */

                    /**
                     * Constructs a new CustomerPointHistory.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a CustomerPointHistory.
                     * @implements ICustomerPointHistory
                     * @constructor
                     * @param {theplant.ec.api.users.ICustomerPointHistory=} [properties] Properties to set
                     */
                    function CustomerPointHistory(properties) {
                        this.Records = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomerPointHistory base.
                     * @member {theplant.ec.service.points.ICustomerPointBase|null|undefined} base
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @instance
                     */
                    CustomerPointHistory.prototype.base = null;

                    /**
                     * CustomerPointHistory Records.
                     * @member {Array.<theplant.ec.api.users.ICanopusPointRecord>} Records
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @instance
                     */
                    CustomerPointHistory.prototype.Records = $util.emptyArray;

                    /**
                     * Creates a new CustomerPointHistory instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.api.users.ICustomerPointHistory=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.CustomerPointHistory} CustomerPointHistory instance
                     */
                    CustomerPointHistory.create = function create(properties) {
                        return new CustomerPointHistory(properties);
                    };

                    /**
                     * Encodes the specified CustomerPointHistory message. Does not implicitly {@link theplant.ec.api.users.CustomerPointHistory.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.api.users.ICustomerPointHistory} message CustomerPointHistory message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointHistory.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.base != null && message.hasOwnProperty("base"))
                            $root.theplant.ec.service.points.CustomerPointBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.Records != null && message.Records.length)
                            for (var i = 0; i < message.Records.length; ++i)
                                $root.theplant.ec.api.users.CanopusPointRecord.encode(message.Records[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomerPointHistory message, length delimited. Does not implicitly {@link theplant.ec.api.users.CustomerPointHistory.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.api.users.ICustomerPointHistory} message CustomerPointHistory message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointHistory.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomerPointHistory message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.CustomerPointHistory} CustomerPointHistory
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointHistory.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.CustomerPointHistory();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.base = $root.theplant.ec.service.points.CustomerPointBase.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.Records && message.Records.length))
                                    message.Records = [];
                                message.Records.push($root.theplant.ec.api.users.CanopusPointRecord.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomerPointHistory message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.CustomerPointHistory} CustomerPointHistory
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointHistory.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomerPointHistory message.
                     * @function verify
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerPointHistory.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.base != null && message.hasOwnProperty("base")) {
                            var error = $root.theplant.ec.service.points.CustomerPointBase.verify(message.base);
                            if (error)
                                return "base." + error;
                        }
                        if (message.Records != null && message.hasOwnProperty("Records")) {
                            if (!Array.isArray(message.Records))
                                return "Records: array expected";
                            for (var i = 0; i < message.Records.length; ++i) {
                                var error = $root.theplant.ec.api.users.CanopusPointRecord.verify(message.Records[i]);
                                if (error)
                                    return "Records." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a CustomerPointHistory message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.CustomerPointHistory} CustomerPointHistory
                     */
                    CustomerPointHistory.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.CustomerPointHistory)
                            return object;
                        var message = new $root.theplant.ec.api.users.CustomerPointHistory();
                        if (object.base != null) {
                            if (typeof object.base !== "object")
                                throw TypeError(".theplant.ec.api.users.CustomerPointHistory.base: object expected");
                            message.base = $root.theplant.ec.service.points.CustomerPointBase.fromObject(object.base);
                        }
                        if (object.Records) {
                            if (!Array.isArray(object.Records))
                                throw TypeError(".theplant.ec.api.users.CustomerPointHistory.Records: array expected");
                            message.Records = [];
                            for (var i = 0; i < object.Records.length; ++i) {
                                if (typeof object.Records[i] !== "object")
                                    throw TypeError(".theplant.ec.api.users.CustomerPointHistory.Records: object expected");
                                message.Records[i] = $root.theplant.ec.api.users.CanopusPointRecord.fromObject(object.Records[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomerPointHistory message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.api.users.CustomerPointHistory} message CustomerPointHistory
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerPointHistory.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.Records = [];
                        if (options.defaults)
                            object.base = null;
                        if (message.base != null && message.hasOwnProperty("base"))
                            object.base = $root.theplant.ec.service.points.CustomerPointBase.toObject(message.base, options);
                        if (message.Records && message.Records.length) {
                            object.Records = [];
                            for (var j = 0; j < message.Records.length; ++j)
                                object.Records[j] = $root.theplant.ec.api.users.CanopusPointRecord.toObject(message.Records[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this CustomerPointHistory to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.CustomerPointHistory
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerPointHistory.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CustomerPointHistory;
                })();

                users.PointService = (function() {

                    /**
                     * Constructs a new PointService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a PointService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function PointService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (PointService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = PointService;

                    /**
                     * Creates new PointService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.PointService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {PointService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    PointService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.PointService#rank}.
                     * @memberof theplant.ec.api.users.PointService
                     * @typedef RankCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.points.CustomerPointRank} [response] CustomerPointRank
                     */

                    /**
                     * Calls Rank.
                     * @function rank
                     * @memberof theplant.ec.api.users.PointService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.PointService.RankCallback} callback Node-style callback called with the error, if any, and CustomerPointRank
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PointService.prototype.rank = function rank(request, callback) {
                        return this.rpcCall(rank, $root.theplant.ec.api.Empty, $root.theplant.ec.service.points.CustomerPointRank, request, callback);
                    }, "name", { value: "Rank" });

                    /**
                     * Calls Rank.
                     * @function rank
                     * @memberof theplant.ec.api.users.PointService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.points.CustomerPointRank>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.PointService#history}.
                     * @memberof theplant.ec.api.users.PointService
                     * @typedef HistoryCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.CustomerPointHistory} [response] CustomerPointHistory
                     */

                    /**
                     * Calls History.
                     * @function history
                     * @memberof theplant.ec.api.users.PointService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.PointService.HistoryCallback} callback Node-style callback called with the error, if any, and CustomerPointHistory
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(PointService.prototype.history = function history(request, callback) {
                        return this.rpcCall(history, $root.theplant.ec.api.Empty, $root.theplant.ec.api.users.CustomerPointHistory, request, callback);
                    }, "name", { value: "History" });

                    /**
                     * Calls History.
                     * @function history
                     * @memberof theplant.ec.api.users.PointService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.users.CustomerPointHistory>} Promise
                     * @variation 2
                     */

                    return PointService;
                })();

                users.LoginService = (function() {

                    /**
                     * Constructs a new LoginService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a LoginService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function LoginService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (LoginService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = LoginService;

                    /**
                     * Creates new LoginService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.LoginService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {LoginService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    LoginService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.LoginService#login}.
                     * @memberof theplant.ec.api.users.LoginService
                     * @typedef LoginCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.LoginOrSetInitialPasswordRes} [response] LoginOrSetInitialPasswordRes
                     */

                    /**
                     * Calls Login.
                     * @function login
                     * @memberof theplant.ec.api.users.LoginService
                     * @instance
                     * @param {theplant.ec.api.users.ICheckoutLoginInfo} request CheckoutLoginInfo message or plain object
                     * @param {theplant.ec.api.users.LoginService.LoginCallback} callback Node-style callback called with the error, if any, and LoginOrSetInitialPasswordRes
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LoginService.prototype.login = function login(request, callback) {
                        return this.rpcCall(login, $root.theplant.ec.api.users.CheckoutLoginInfo, $root.theplant.ec.api.users.LoginOrSetInitialPasswordRes, request, callback);
                    }, "name", { value: "Login" });

                    /**
                     * Calls Login.
                     * @function login
                     * @memberof theplant.ec.api.users.LoginService
                     * @instance
                     * @param {theplant.ec.api.users.ICheckoutLoginInfo} request CheckoutLoginInfo message or plain object
                     * @returns {Promise<theplant.ec.api.users.LoginOrSetInitialPasswordRes>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.LoginService#currentUser}.
                     * @memberof theplant.ec.api.users.LoginService
                     * @typedef CurrentUserCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.service.users.User} [response] User
                     */

                    /**
                     * Calls CurrentUser.
                     * @function currentUser
                     * @memberof theplant.ec.api.users.LoginService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.users.LoginService.CurrentUserCallback} callback Node-style callback called with the error, if any, and User
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LoginService.prototype.currentUser = function currentUser(request, callback) {
                        return this.rpcCall(currentUser, $root.theplant.ec.api.Empty, $root.theplant.ec.service.users.User, request, callback);
                    }, "name", { value: "CurrentUser" });

                    /**
                     * Calls CurrentUser.
                     * @function currentUser
                     * @memberof theplant.ec.api.users.LoginService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.service.users.User>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.users.LoginService#checkEmail}.
                     * @memberof theplant.ec.api.users.LoginService
                     * @typedef CheckEmailCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.EmailStatus} [response] EmailStatus
                     */

                    /**
                     * Calls CheckEmail.
                     * @function checkEmail
                     * @memberof theplant.ec.api.users.LoginService
                     * @instance
                     * @param {theplant.ec.api.users.IUserEmail} request UserEmail message or plain object
                     * @param {theplant.ec.api.users.LoginService.CheckEmailCallback} callback Node-style callback called with the error, if any, and EmailStatus
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(LoginService.prototype.checkEmail = function checkEmail(request, callback) {
                        return this.rpcCall(checkEmail, $root.theplant.ec.api.users.UserEmail, $root.theplant.ec.api.users.EmailStatus, request, callback);
                    }, "name", { value: "CheckEmail" });

                    /**
                     * Calls CheckEmail.
                     * @function checkEmail
                     * @memberof theplant.ec.api.users.LoginService
                     * @instance
                     * @param {theplant.ec.api.users.IUserEmail} request UserEmail message or plain object
                     * @returns {Promise<theplant.ec.api.users.EmailStatus>} Promise
                     * @variation 2
                     */

                    return LoginService;
                })();

                users.GuestAuthnService = (function() {

                    /**
                     * Constructs a new GuestAuthnService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a GuestAuthnService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function GuestAuthnService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (GuestAuthnService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = GuestAuthnService;

                    /**
                     * Creates new GuestAuthnService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.GuestAuthnService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {GuestAuthnService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    GuestAuthnService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.GuestAuthnService#setGuestUserInitialPassword}.
                     * @memberof theplant.ec.api.users.GuestAuthnService
                     * @typedef SetGuestUserInitialPasswordCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls SetGuestUserInitialPassword.
                     * @function setGuestUserInitialPassword
                     * @memberof theplant.ec.api.users.GuestAuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ISetPasswordParams} request SetPasswordParams message or plain object
                     * @param {theplant.ec.api.users.GuestAuthnService.SetGuestUserInitialPasswordCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(GuestAuthnService.prototype.setGuestUserInitialPassword = function setGuestUserInitialPassword(request, callback) {
                        return this.rpcCall(setGuestUserInitialPassword, $root.theplant.ec.api.users.SetPasswordParams, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "SetGuestUserInitialPassword" });

                    /**
                     * Calls SetGuestUserInitialPassword.
                     * @function setGuestUserInitialPassword
                     * @memberof theplant.ec.api.users.GuestAuthnService
                     * @instance
                     * @param {theplant.ec.api.users.ISetPasswordParams} request SetPasswordParams message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return GuestAuthnService;
                })();

                users.SetInitialPasswordParams = (function() {

                    /**
                     * Properties of a SetInitialPasswordParams.
                     * @memberof theplant.ec.api.users
                     * @interface ISetInitialPasswordParams
                     * @property {boolean|null} [isTermsAndPrivacyAgreed] SetInitialPasswordParams isTermsAndPrivacyAgreed
                     * @property {string|null} [token] SetInitialPasswordParams token
                     * @property {string|null} [password] SetInitialPasswordParams password
                     * @property {string|null} [passwordConfirmation] SetInitialPasswordParams passwordConfirmation
                     * @property {string|null} [orderCode] SetInitialPasswordParams orderCode
                     * @property {theplant.ec.service.users.IProfile|null} [profile] SetInitialPasswordParams profile
                     * @property {boolean|null} [isAddressStrictRequired] SetInitialPasswordParams isAddressStrictRequired
                     */

                    /**
                     * Constructs a new SetInitialPasswordParams.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a SetInitialPasswordParams.
                     * @implements ISetInitialPasswordParams
                     * @constructor
                     * @param {theplant.ec.api.users.ISetInitialPasswordParams=} [properties] Properties to set
                     */
                    function SetInitialPasswordParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SetInitialPasswordParams isTermsAndPrivacyAgreed.
                     * @member {boolean} isTermsAndPrivacyAgreed
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.isTermsAndPrivacyAgreed = false;

                    /**
                     * SetInitialPasswordParams token.
                     * @member {string} token
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.token = "";

                    /**
                     * SetInitialPasswordParams password.
                     * @member {string} password
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.password = "";

                    /**
                     * SetInitialPasswordParams passwordConfirmation.
                     * @member {string} passwordConfirmation
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.passwordConfirmation = "";

                    /**
                     * SetInitialPasswordParams orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.orderCode = "";

                    /**
                     * SetInitialPasswordParams profile.
                     * @member {theplant.ec.service.users.IProfile|null|undefined} profile
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.profile = null;

                    /**
                     * SetInitialPasswordParams isAddressStrictRequired.
                     * @member {boolean} isAddressStrictRequired
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     */
                    SetInitialPasswordParams.prototype.isAddressStrictRequired = false;

                    /**
                     * Creates a new SetInitialPasswordParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ISetInitialPasswordParams=} [properties] Properties to set
                     * @returns {theplant.ec.api.users.SetInitialPasswordParams} SetInitialPasswordParams instance
                     */
                    SetInitialPasswordParams.create = function create(properties) {
                        return new SetInitialPasswordParams(properties);
                    };

                    /**
                     * Encodes the specified SetInitialPasswordParams message. Does not implicitly {@link theplant.ec.api.users.SetInitialPasswordParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ISetInitialPasswordParams} message SetInitialPasswordParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetInitialPasswordParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.token != null && message.hasOwnProperty("token"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.token);
                        if (message.password != null && message.hasOwnProperty("password"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.passwordConfirmation);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.orderCode);
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isTermsAndPrivacyAgreed);
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            $root.theplant.ec.service.users.Profile.encode(message.profile, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.isAddressStrictRequired != null && message.hasOwnProperty("isAddressStrictRequired"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isAddressStrictRequired);
                        return writer;
                    };

                    /**
                     * Encodes the specified SetInitialPasswordParams message, length delimited. Does not implicitly {@link theplant.ec.api.users.SetInitialPasswordParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.ISetInitialPasswordParams} message SetInitialPasswordParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetInitialPasswordParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SetInitialPasswordParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.users.SetInitialPasswordParams} SetInitialPasswordParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetInitialPasswordParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.users.SetInitialPasswordParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 7:
                                message.isTermsAndPrivacyAgreed = reader.bool();
                                break;
                            case 1:
                                message.token = reader.string();
                                break;
                            case 2:
                                message.password = reader.string();
                                break;
                            case 3:
                                message.passwordConfirmation = reader.string();
                                break;
                            case 6:
                                message.orderCode = reader.string();
                                break;
                            case 8:
                                message.profile = $root.theplant.ec.service.users.Profile.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.isAddressStrictRequired = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SetInitialPasswordParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.users.SetInitialPasswordParams} SetInitialPasswordParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetInitialPasswordParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SetInitialPasswordParams message.
                     * @function verify
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SetInitialPasswordParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            if (typeof message.isTermsAndPrivacyAgreed !== "boolean")
                                return "isTermsAndPrivacyAgreed: boolean expected";
                        if (message.token != null && message.hasOwnProperty("token"))
                            if (!$util.isString(message.token))
                                return "token: string expected";
                        if (message.password != null && message.hasOwnProperty("password"))
                            if (!$util.isString(message.password))
                                return "password: string expected";
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            if (!$util.isString(message.passwordConfirmation))
                                return "passwordConfirmation: string expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.profile != null && message.hasOwnProperty("profile")) {
                            var error = $root.theplant.ec.service.users.Profile.verify(message.profile);
                            if (error)
                                return "profile." + error;
                        }
                        if (message.isAddressStrictRequired != null && message.hasOwnProperty("isAddressStrictRequired"))
                            if (typeof message.isAddressStrictRequired !== "boolean")
                                return "isAddressStrictRequired: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a SetInitialPasswordParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.users.SetInitialPasswordParams} SetInitialPasswordParams
                     */
                    SetInitialPasswordParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.users.SetInitialPasswordParams)
                            return object;
                        var message = new $root.theplant.ec.api.users.SetInitialPasswordParams();
                        if (object.isTermsAndPrivacyAgreed != null)
                            message.isTermsAndPrivacyAgreed = Boolean(object.isTermsAndPrivacyAgreed);
                        if (object.token != null)
                            message.token = String(object.token);
                        if (object.password != null)
                            message.password = String(object.password);
                        if (object.passwordConfirmation != null)
                            message.passwordConfirmation = String(object.passwordConfirmation);
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.profile != null) {
                            if (typeof object.profile !== "object")
                                throw TypeError(".theplant.ec.api.users.SetInitialPasswordParams.profile: object expected");
                            message.profile = $root.theplant.ec.service.users.Profile.fromObject(object.profile);
                        }
                        if (object.isAddressStrictRequired != null)
                            message.isAddressStrictRequired = Boolean(object.isAddressStrictRequired);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SetInitialPasswordParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @static
                     * @param {theplant.ec.api.users.SetInitialPasswordParams} message SetInitialPasswordParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SetInitialPasswordParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.token = "";
                            object.password = "";
                            object.passwordConfirmation = "";
                            object.orderCode = "";
                            object.isTermsAndPrivacyAgreed = false;
                            object.profile = null;
                            object.isAddressStrictRequired = false;
                        }
                        if (message.token != null && message.hasOwnProperty("token"))
                            object.token = message.token;
                        if (message.password != null && message.hasOwnProperty("password"))
                            object.password = message.password;
                        if (message.passwordConfirmation != null && message.hasOwnProperty("passwordConfirmation"))
                            object.passwordConfirmation = message.passwordConfirmation;
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.isTermsAndPrivacyAgreed != null && message.hasOwnProperty("isTermsAndPrivacyAgreed"))
                            object.isTermsAndPrivacyAgreed = message.isTermsAndPrivacyAgreed;
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            object.profile = $root.theplant.ec.service.users.Profile.toObject(message.profile, options);
                        if (message.isAddressStrictRequired != null && message.hasOwnProperty("isAddressStrictRequired"))
                            object.isAddressStrictRequired = message.isAddressStrictRequired;
                        return object;
                    };

                    /**
                     * Converts this SetInitialPasswordParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.users.SetInitialPasswordParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SetInitialPasswordParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SetInitialPasswordParams;
                })();

                users.SetUserInitialPasswordService = (function() {

                    /**
                     * Constructs a new SetUserInitialPasswordService service.
                     * @memberof theplant.ec.api.users
                     * @classdesc Represents a SetUserInitialPasswordService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function SetUserInitialPasswordService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (SetUserInitialPasswordService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SetUserInitialPasswordService;

                    /**
                     * Creates new SetUserInitialPasswordService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.users.SetUserInitialPasswordService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {SetUserInitialPasswordService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    SetUserInitialPasswordService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.users.SetUserInitialPasswordService#setUserInitialPassword}.
                     * @memberof theplant.ec.api.users.SetUserInitialPasswordService
                     * @typedef SetUserInitialPasswordCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.users.LoginOrSetInitialPasswordRes} [response] LoginOrSetInitialPasswordRes
                     */

                    /**
                     * Calls SetUserInitialPassword.
                     * @function setUserInitialPassword
                     * @memberof theplant.ec.api.users.SetUserInitialPasswordService
                     * @instance
                     * @param {theplant.ec.api.users.ISetInitialPasswordParams} request SetInitialPasswordParams message or plain object
                     * @param {theplant.ec.api.users.SetUserInitialPasswordService.SetUserInitialPasswordCallback} callback Node-style callback called with the error, if any, and LoginOrSetInitialPasswordRes
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(SetUserInitialPasswordService.prototype.setUserInitialPassword = function setUserInitialPassword(request, callback) {
                        return this.rpcCall(setUserInitialPassword, $root.theplant.ec.api.users.SetInitialPasswordParams, $root.theplant.ec.api.users.LoginOrSetInitialPasswordRes, request, callback);
                    }, "name", { value: "SetUserInitialPassword" });

                    /**
                     * Calls SetUserInitialPassword.
                     * @function setUserInitialPassword
                     * @memberof theplant.ec.api.users.SetUserInitialPasswordService
                     * @instance
                     * @param {theplant.ec.api.users.ISetInitialPasswordParams} request SetInitialPasswordParams message or plain object
                     * @returns {Promise<theplant.ec.api.users.LoginOrSetInitialPasswordRes>} Promise
                     * @variation 2
                     */

                    return SetUserInitialPasswordService;
                })();

                return users;
            })();

            api.wishlist = (function() {

                /**
                 * Namespace wishlist.
                 * @memberof theplant.ec.api
                 * @namespace
                 */
                var wishlist = {};

                wishlist.WishItemDetail = (function() {

                    /**
                     * Properties of a WishItemDetail.
                     * @memberof theplant.ec.api.wishlist
                     * @interface IWishItemDetail
                     * @property {theplant.ec.service.wishlist.IWishItem|null} [item] WishItemDetail item
                     * @property {theplant.ec.service.products.IProduct|null} [product] WishItemDetail product
                     */

                    /**
                     * Constructs a new WishItemDetail.
                     * @memberof theplant.ec.api.wishlist
                     * @classdesc Represents a WishItemDetail.
                     * @implements IWishItemDetail
                     * @constructor
                     * @param {theplant.ec.api.wishlist.IWishItemDetail=} [properties] Properties to set
                     */
                    function WishItemDetail(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * WishItemDetail item.
                     * @member {theplant.ec.service.wishlist.IWishItem|null|undefined} item
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @instance
                     */
                    WishItemDetail.prototype.item = null;

                    /**
                     * WishItemDetail product.
                     * @member {theplant.ec.service.products.IProduct|null|undefined} product
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @instance
                     */
                    WishItemDetail.prototype.product = null;

                    /**
                     * Creates a new WishItemDetail instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {theplant.ec.api.wishlist.IWishItemDetail=} [properties] Properties to set
                     * @returns {theplant.ec.api.wishlist.WishItemDetail} WishItemDetail instance
                     */
                    WishItemDetail.create = function create(properties) {
                        return new WishItemDetail(properties);
                    };

                    /**
                     * Encodes the specified WishItemDetail message. Does not implicitly {@link theplant.ec.api.wishlist.WishItemDetail.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {theplant.ec.api.wishlist.IWishItemDetail} message WishItemDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WishItemDetail.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.item != null && message.hasOwnProperty("item"))
                            $root.theplant.ec.service.wishlist.WishItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.product != null && message.hasOwnProperty("product"))
                            $root.theplant.ec.service.products.Product.encode(message.product, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified WishItemDetail message, length delimited. Does not implicitly {@link theplant.ec.api.wishlist.WishItemDetail.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {theplant.ec.api.wishlist.IWishItemDetail} message WishItemDetail message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WishItemDetail.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WishItemDetail message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.wishlist.WishItemDetail} WishItemDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WishItemDetail.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.wishlist.WishItemDetail();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.item = $root.theplant.ec.service.wishlist.WishItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.product = $root.theplant.ec.service.products.Product.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WishItemDetail message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.wishlist.WishItemDetail} WishItemDetail
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WishItemDetail.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WishItemDetail message.
                     * @function verify
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WishItemDetail.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.item != null && message.hasOwnProperty("item")) {
                            var error = $root.theplant.ec.service.wishlist.WishItem.verify(message.item);
                            if (error)
                                return "item." + error;
                        }
                        if (message.product != null && message.hasOwnProperty("product")) {
                            var error = $root.theplant.ec.service.products.Product.verify(message.product);
                            if (error)
                                return "product." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a WishItemDetail message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.wishlist.WishItemDetail} WishItemDetail
                     */
                    WishItemDetail.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.wishlist.WishItemDetail)
                            return object;
                        var message = new $root.theplant.ec.api.wishlist.WishItemDetail();
                        if (object.item != null) {
                            if (typeof object.item !== "object")
                                throw TypeError(".theplant.ec.api.wishlist.WishItemDetail.item: object expected");
                            message.item = $root.theplant.ec.service.wishlist.WishItem.fromObject(object.item);
                        }
                        if (object.product != null) {
                            if (typeof object.product !== "object")
                                throw TypeError(".theplant.ec.api.wishlist.WishItemDetail.product: object expected");
                            message.product = $root.theplant.ec.service.products.Product.fromObject(object.product);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a WishItemDetail message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @static
                     * @param {theplant.ec.api.wishlist.WishItemDetail} message WishItemDetail
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WishItemDetail.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.item = null;
                            object.product = null;
                        }
                        if (message.item != null && message.hasOwnProperty("item"))
                            object.item = $root.theplant.ec.service.wishlist.WishItem.toObject(message.item, options);
                        if (message.product != null && message.hasOwnProperty("product"))
                            object.product = $root.theplant.ec.service.products.Product.toObject(message.product, options);
                        return object;
                    };

                    /**
                     * Converts this WishItemDetail to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.wishlist.WishItemDetail
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WishItemDetail.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return WishItemDetail;
                })();

                wishlist.WishList = (function() {

                    /**
                     * Properties of a WishList.
                     * @memberof theplant.ec.api.wishlist
                     * @interface IWishList
                     * @property {Array.<theplant.ec.api.wishlist.IWishItemDetail>|null} [wishItems] WishList wishItems
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [rdGroups] WishList rdGroups
                     */

                    /**
                     * Constructs a new WishList.
                     * @memberof theplant.ec.api.wishlist
                     * @classdesc Represents a WishList.
                     * @implements IWishList
                     * @constructor
                     * @param {theplant.ec.api.wishlist.IWishList=} [properties] Properties to set
                     */
                    function WishList(properties) {
                        this.wishItems = [];
                        this.rdGroups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * WishList wishItems.
                     * @member {Array.<theplant.ec.api.wishlist.IWishItemDetail>} wishItems
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @instance
                     */
                    WishList.prototype.wishItems = $util.emptyArray;

                    /**
                     * WishList rdGroups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} rdGroups
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @instance
                     */
                    WishList.prototype.rdGroups = $util.emptyArray;

                    /**
                     * Creates a new WishList instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {theplant.ec.api.wishlist.IWishList=} [properties] Properties to set
                     * @returns {theplant.ec.api.wishlist.WishList} WishList instance
                     */
                    WishList.create = function create(properties) {
                        return new WishList(properties);
                    };

                    /**
                     * Encodes the specified WishList message. Does not implicitly {@link theplant.ec.api.wishlist.WishList.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {theplant.ec.api.wishlist.IWishList} message WishList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WishList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.wishItems != null && message.wishItems.length)
                            for (var i = 0; i < message.wishItems.length; ++i)
                                $root.theplant.ec.api.wishlist.WishItemDetail.encode(message.wishItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.rdGroups != null && message.rdGroups.length)
                            for (var i = 0; i < message.rdGroups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.rdGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified WishList message, length delimited. Does not implicitly {@link theplant.ec.api.wishlist.WishList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {theplant.ec.api.wishlist.IWishList} message WishList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WishList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WishList message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.wishlist.WishList} WishList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WishList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.wishlist.WishList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.wishItems && message.wishItems.length))
                                    message.wishItems = [];
                                message.wishItems.push($root.theplant.ec.api.wishlist.WishItemDetail.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.rdGroups && message.rdGroups.length))
                                    message.rdGroups = [];
                                message.rdGroups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WishList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.wishlist.WishList} WishList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WishList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WishList message.
                     * @function verify
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WishList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.wishItems != null && message.hasOwnProperty("wishItems")) {
                            if (!Array.isArray(message.wishItems))
                                return "wishItems: array expected";
                            for (var i = 0; i < message.wishItems.length; ++i) {
                                var error = $root.theplant.ec.api.wishlist.WishItemDetail.verify(message.wishItems[i]);
                                if (error)
                                    return "wishItems." + error;
                            }
                        }
                        if (message.rdGroups != null && message.hasOwnProperty("rdGroups")) {
                            if (!Array.isArray(message.rdGroups))
                                return "rdGroups: array expected";
                            for (var i = 0; i < message.rdGroups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.rdGroups[i]);
                                if (error)
                                    return "rdGroups." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a WishList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.wishlist.WishList} WishList
                     */
                    WishList.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.wishlist.WishList)
                            return object;
                        var message = new $root.theplant.ec.api.wishlist.WishList();
                        if (object.wishItems) {
                            if (!Array.isArray(object.wishItems))
                                throw TypeError(".theplant.ec.api.wishlist.WishList.wishItems: array expected");
                            message.wishItems = [];
                            for (var i = 0; i < object.wishItems.length; ++i) {
                                if (typeof object.wishItems[i] !== "object")
                                    throw TypeError(".theplant.ec.api.wishlist.WishList.wishItems: object expected");
                                message.wishItems[i] = $root.theplant.ec.api.wishlist.WishItemDetail.fromObject(object.wishItems[i]);
                            }
                        }
                        if (object.rdGroups) {
                            if (!Array.isArray(object.rdGroups))
                                throw TypeError(".theplant.ec.api.wishlist.WishList.rdGroups: array expected");
                            message.rdGroups = [];
                            for (var i = 0; i < object.rdGroups.length; ++i) {
                                if (typeof object.rdGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.api.wishlist.WishList.rdGroups: object expected");
                                message.rdGroups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.rdGroups[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a WishList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @static
                     * @param {theplant.ec.api.wishlist.WishList} message WishList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WishList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.wishItems = [];
                            object.rdGroups = [];
                        }
                        if (message.wishItems && message.wishItems.length) {
                            object.wishItems = [];
                            for (var j = 0; j < message.wishItems.length; ++j)
                                object.wishItems[j] = $root.theplant.ec.api.wishlist.WishItemDetail.toObject(message.wishItems[j], options);
                        }
                        if (message.rdGroups && message.rdGroups.length) {
                            object.rdGroups = [];
                            for (var j = 0; j < message.rdGroups.length; ++j)
                                object.rdGroups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.rdGroups[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this WishList to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.wishlist.WishList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WishList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return WishList;
                })();

                wishlist.ArticleCodes = (function() {

                    /**
                     * Properties of an ArticleCodes.
                     * @memberof theplant.ec.api.wishlist
                     * @interface IArticleCodes
                     * @property {Array.<string>|null} [articleCodes] ArticleCodes articleCodes
                     */

                    /**
                     * Constructs a new ArticleCodes.
                     * @memberof theplant.ec.api.wishlist
                     * @classdesc Represents an ArticleCodes.
                     * @implements IArticleCodes
                     * @constructor
                     * @param {theplant.ec.api.wishlist.IArticleCodes=} [properties] Properties to set
                     */
                    function ArticleCodes(properties) {
                        this.articleCodes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ArticleCodes articleCodes.
                     * @member {Array.<string>} articleCodes
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @instance
                     */
                    ArticleCodes.prototype.articleCodes = $util.emptyArray;

                    /**
                     * Creates a new ArticleCodes instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {theplant.ec.api.wishlist.IArticleCodes=} [properties] Properties to set
                     * @returns {theplant.ec.api.wishlist.ArticleCodes} ArticleCodes instance
                     */
                    ArticleCodes.create = function create(properties) {
                        return new ArticleCodes(properties);
                    };

                    /**
                     * Encodes the specified ArticleCodes message. Does not implicitly {@link theplant.ec.api.wishlist.ArticleCodes.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {theplant.ec.api.wishlist.IArticleCodes} message ArticleCodes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleCodes.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCodes != null && message.articleCodes.length)
                            for (var i = 0; i < message.articleCodes.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCodes[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified ArticleCodes message, length delimited. Does not implicitly {@link theplant.ec.api.wishlist.ArticleCodes.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {theplant.ec.api.wishlist.IArticleCodes} message ArticleCodes message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleCodes.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ArticleCodes message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.api.wishlist.ArticleCodes} ArticleCodes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleCodes.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.api.wishlist.ArticleCodes();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.articleCodes && message.articleCodes.length))
                                    message.articleCodes = [];
                                message.articleCodes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ArticleCodes message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.api.wishlist.ArticleCodes} ArticleCodes
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleCodes.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ArticleCodes message.
                     * @function verify
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ArticleCodes.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCodes != null && message.hasOwnProperty("articleCodes")) {
                            if (!Array.isArray(message.articleCodes))
                                return "articleCodes: array expected";
                            for (var i = 0; i < message.articleCodes.length; ++i)
                                if (!$util.isString(message.articleCodes[i]))
                                    return "articleCodes: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates an ArticleCodes message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.api.wishlist.ArticleCodes} ArticleCodes
                     */
                    ArticleCodes.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.api.wishlist.ArticleCodes)
                            return object;
                        var message = new $root.theplant.ec.api.wishlist.ArticleCodes();
                        if (object.articleCodes) {
                            if (!Array.isArray(object.articleCodes))
                                throw TypeError(".theplant.ec.api.wishlist.ArticleCodes.articleCodes: array expected");
                            message.articleCodes = [];
                            for (var i = 0; i < object.articleCodes.length; ++i)
                                message.articleCodes[i] = String(object.articleCodes[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ArticleCodes message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @static
                     * @param {theplant.ec.api.wishlist.ArticleCodes} message ArticleCodes
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ArticleCodes.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.articleCodes = [];
                        if (message.articleCodes && message.articleCodes.length) {
                            object.articleCodes = [];
                            for (var j = 0; j < message.articleCodes.length; ++j)
                                object.articleCodes[j] = message.articleCodes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this ArticleCodes to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.api.wishlist.ArticleCodes
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ArticleCodes.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ArticleCodes;
                })();

                wishlist.WishlistService = (function() {

                    /**
                     * Constructs a new WishlistService service.
                     * @memberof theplant.ec.api.wishlist
                     * @classdesc Represents a WishlistService
                     * @extends $protobuf.rpc.Service
                     * @constructor
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     */
                    function WishlistService(rpcImpl, requestDelimited, responseDelimited) {
                        $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
                    }

                    (WishlistService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = WishlistService;

                    /**
                     * Creates new WishlistService service using the specified rpc implementation.
                     * @function create
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @static
                     * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
                     * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
                     * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
                     * @returns {WishlistService} RPC service. Useful where requests and/or responses are streamed.
                     */
                    WishlistService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                        return new this(rpcImpl, requestDelimited, responseDelimited);
                    };

                    /**
                     * Callback as used by {@link theplant.ec.api.wishlist.WishlistService#addWishItems}.
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @typedef AddWishItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls AddWishItems.
                     * @function addWishItems
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @param {theplant.ec.api.wishlist.WishlistService.AddWishItemsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WishlistService.prototype.addWishItems = function addWishItems(request, callback) {
                        return this.rpcCall(addWishItems, $root.theplant.ec.api.wishlist.ArticleCodes, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "AddWishItems" });

                    /**
                     * Calls AddWishItems.
                     * @function addWishItems
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.wishlist.WishlistService#getWishlist}.
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @typedef GetWishlistCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.wishlist.WishList} [response] WishList
                     */

                    /**
                     * Calls GetWishlist.
                     * @function getWishlist
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @param {theplant.ec.api.wishlist.WishlistService.GetWishlistCallback} callback Node-style callback called with the error, if any, and WishList
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WishlistService.prototype.getWishlist = function getWishlist(request, callback) {
                        return this.rpcCall(getWishlist, $root.theplant.ec.api.Empty, $root.theplant.ec.api.wishlist.WishList, request, callback);
                    }, "name", { value: "GetWishlist" });

                    /**
                     * Calls GetWishlist.
                     * @function getWishlist
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.IEmpty} request Empty message or plain object
                     * @returns {Promise<theplant.ec.api.wishlist.WishList>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.wishlist.WishlistService#removeWishItems}.
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @typedef RemoveWishItemsCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls RemoveWishItems.
                     * @function removeWishItems
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @param {theplant.ec.api.wishlist.WishlistService.RemoveWishItemsCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WishlistService.prototype.removeWishItems = function removeWishItems(request, callback) {
                        return this.rpcCall(removeWishItems, $root.theplant.ec.api.wishlist.ArticleCodes, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "RemoveWishItems" });

                    /**
                     * Calls RemoveWishItems.
                     * @function removeWishItems
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.wishlist.WishlistService#moveWishItemsToCart}.
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @typedef MoveWishItemsToCartCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls MoveWishItemsToCart.
                     * @function moveWishItemsToCart
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @param {theplant.ec.api.wishlist.WishlistService.MoveWishItemsToCartCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WishlistService.prototype.moveWishItemsToCart = function moveWishItemsToCart(request, callback) {
                        return this.rpcCall(moveWishItemsToCart, $root.theplant.ec.api.wishlist.ArticleCodes, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "MoveWishItemsToCart" });

                    /**
                     * Calls MoveWishItemsToCart.
                     * @function moveWishItemsToCart
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    /**
                     * Callback as used by {@link theplant.ec.api.wishlist.WishlistService#moveCartItemsToWishList}.
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @typedef MoveCartItemsToWishListCallback
                     * @type {function}
                     * @param {Error|null} error Error, if any
                     * @param {theplant.ec.api.Empty} [response] Empty
                     */

                    /**
                     * Calls MoveCartItemsToWishList.
                     * @function moveCartItemsToWishList
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @param {theplant.ec.api.wishlist.WishlistService.MoveCartItemsToWishListCallback} callback Node-style callback called with the error, if any, and Empty
                     * @returns {undefined}
                     * @variation 1
                     */
                    Object.defineProperty(WishlistService.prototype.moveCartItemsToWishList = function moveCartItemsToWishList(request, callback) {
                        return this.rpcCall(moveCartItemsToWishList, $root.theplant.ec.api.wishlist.ArticleCodes, $root.theplant.ec.api.Empty, request, callback);
                    }, "name", { value: "MoveCartItemsToWishList" });

                    /**
                     * Calls MoveCartItemsToWishList.
                     * @function moveCartItemsToWishList
                     * @memberof theplant.ec.api.wishlist.WishlistService
                     * @instance
                     * @param {theplant.ec.api.wishlist.IArticleCodes} request ArticleCodes message or plain object
                     * @returns {Promise<theplant.ec.api.Empty>} Promise
                     * @variation 2
                     */

                    return WishlistService;
                })();

                return wishlist;
            })();

            return api;
        })();

        ec.service = (function() {

            /**
             * Namespace service.
             * @memberof theplant.ec
             * @namespace
             */
            var service = {};

            service.blog = (function() {

                /**
                 * Namespace blog.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var blog = {};

                blog.Tag = (function() {

                    /**
                     * Properties of a Tag.
                     * @memberof theplant.ec.service.blog
                     * @interface ITag
                     * @property {number|Long|null} [id] Tag id
                     * @property {string|null} [title] Tag title
                     * @property {string|null} [titleWithSlug] Tag titleWithSlug
                     */

                    /**
                     * Constructs a new Tag.
                     * @memberof theplant.ec.service.blog
                     * @classdesc Represents a Tag.
                     * @implements ITag
                     * @constructor
                     * @param {theplant.ec.service.blog.ITag=} [properties] Properties to set
                     */
                    function Tag(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Tag id.
                     * @member {number|Long} id
                     * @memberof theplant.ec.service.blog.Tag
                     * @instance
                     */
                    Tag.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Tag title.
                     * @member {string} title
                     * @memberof theplant.ec.service.blog.Tag
                     * @instance
                     */
                    Tag.prototype.title = "";

                    /**
                     * Tag titleWithSlug.
                     * @member {string} titleWithSlug
                     * @memberof theplant.ec.service.blog.Tag
                     * @instance
                     */
                    Tag.prototype.titleWithSlug = "";

                    /**
                     * Creates a new Tag instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {theplant.ec.service.blog.ITag=} [properties] Properties to set
                     * @returns {theplant.ec.service.blog.Tag} Tag instance
                     */
                    Tag.create = function create(properties) {
                        return new Tag(properties);
                    };

                    /**
                     * Encodes the specified Tag message. Does not implicitly {@link theplant.ec.service.blog.Tag.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {theplant.ec.service.blog.ITag} message Tag message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Tag.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                        if (message.titleWithSlug != null && message.hasOwnProperty("titleWithSlug"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.titleWithSlug);
                        return writer;
                    };

                    /**
                     * Encodes the specified Tag message, length delimited. Does not implicitly {@link theplant.ec.service.blog.Tag.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {theplant.ec.service.blog.ITag} message Tag message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Tag.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Tag message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.blog.Tag} Tag
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Tag.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.blog.Tag();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.title = reader.string();
                                break;
                            case 3:
                                message.titleWithSlug = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Tag message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.blog.Tag} Tag
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Tag.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Tag message.
                     * @function verify
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Tag.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.titleWithSlug != null && message.hasOwnProperty("titleWithSlug"))
                            if (!$util.isString(message.titleWithSlug))
                                return "titleWithSlug: string expected";
                        return null;
                    };

                    /**
                     * Creates a Tag message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.blog.Tag} Tag
                     */
                    Tag.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.blog.Tag)
                            return object;
                        var message = new $root.theplant.ec.service.blog.Tag();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.titleWithSlug != null)
                            message.titleWithSlug = String(object.titleWithSlug);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Tag message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.blog.Tag
                     * @static
                     * @param {theplant.ec.service.blog.Tag} message Tag
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Tag.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.title = "";
                            object.titleWithSlug = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.titleWithSlug != null && message.hasOwnProperty("titleWithSlug"))
                            object.titleWithSlug = message.titleWithSlug;
                        return object;
                    };

                    /**
                     * Converts this Tag to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.blog.Tag
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Tag.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Tag;
                })();

                blog.Category = (function() {

                    /**
                     * Properties of a Category.
                     * @memberof theplant.ec.service.blog
                     * @interface ICategory
                     * @property {number|Long|null} [id] Category id
                     * @property {number|Long|null} [tagId] Category tagId
                     * @property {string|null} [tagTitle] Category tagTitle
                     * @property {string|null} [tagTitleWithSlug] Category tagTitleWithSlug
                     */

                    /**
                     * Constructs a new Category.
                     * @memberof theplant.ec.service.blog
                     * @classdesc Represents a Category.
                     * @implements ICategory
                     * @constructor
                     * @param {theplant.ec.service.blog.ICategory=} [properties] Properties to set
                     */
                    function Category(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Category id.
                     * @member {number|Long} id
                     * @memberof theplant.ec.service.blog.Category
                     * @instance
                     */
                    Category.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Category tagId.
                     * @member {number|Long} tagId
                     * @memberof theplant.ec.service.blog.Category
                     * @instance
                     */
                    Category.prototype.tagId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Category tagTitle.
                     * @member {string} tagTitle
                     * @memberof theplant.ec.service.blog.Category
                     * @instance
                     */
                    Category.prototype.tagTitle = "";

                    /**
                     * Category tagTitleWithSlug.
                     * @member {string} tagTitleWithSlug
                     * @memberof theplant.ec.service.blog.Category
                     * @instance
                     */
                    Category.prototype.tagTitleWithSlug = "";

                    /**
                     * Creates a new Category instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {theplant.ec.service.blog.ICategory=} [properties] Properties to set
                     * @returns {theplant.ec.service.blog.Category} Category instance
                     */
                    Category.create = function create(properties) {
                        return new Category(properties);
                    };

                    /**
                     * Encodes the specified Category message. Does not implicitly {@link theplant.ec.service.blog.Category.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {theplant.ec.service.blog.ICategory} message Category message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Category.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.tagId != null && message.hasOwnProperty("tagId"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.tagId);
                        if (message.tagTitle != null && message.hasOwnProperty("tagTitle"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.tagTitle);
                        if (message.tagTitleWithSlug != null && message.hasOwnProperty("tagTitleWithSlug"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.tagTitleWithSlug);
                        return writer;
                    };

                    /**
                     * Encodes the specified Category message, length delimited. Does not implicitly {@link theplant.ec.service.blog.Category.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {theplant.ec.service.blog.ICategory} message Category message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Category.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Category message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.blog.Category} Category
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Category.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.blog.Category();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.tagId = reader.int64();
                                break;
                            case 3:
                                message.tagTitle = reader.string();
                                break;
                            case 4:
                                message.tagTitleWithSlug = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Category message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.blog.Category} Category
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Category.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Category message.
                     * @function verify
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Category.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.tagId != null && message.hasOwnProperty("tagId"))
                            if (!$util.isInteger(message.tagId) && !(message.tagId && $util.isInteger(message.tagId.low) && $util.isInteger(message.tagId.high)))
                                return "tagId: integer|Long expected";
                        if (message.tagTitle != null && message.hasOwnProperty("tagTitle"))
                            if (!$util.isString(message.tagTitle))
                                return "tagTitle: string expected";
                        if (message.tagTitleWithSlug != null && message.hasOwnProperty("tagTitleWithSlug"))
                            if (!$util.isString(message.tagTitleWithSlug))
                                return "tagTitleWithSlug: string expected";
                        return null;
                    };

                    /**
                     * Creates a Category message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.blog.Category} Category
                     */
                    Category.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.blog.Category)
                            return object;
                        var message = new $root.theplant.ec.service.blog.Category();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.tagId != null)
                            if ($util.Long)
                                (message.tagId = $util.Long.fromValue(object.tagId)).unsigned = false;
                            else if (typeof object.tagId === "string")
                                message.tagId = parseInt(object.tagId, 10);
                            else if (typeof object.tagId === "number")
                                message.tagId = object.tagId;
                            else if (typeof object.tagId === "object")
                                message.tagId = new $util.LongBits(object.tagId.low >>> 0, object.tagId.high >>> 0).toNumber();
                        if (object.tagTitle != null)
                            message.tagTitle = String(object.tagTitle);
                        if (object.tagTitleWithSlug != null)
                            message.tagTitleWithSlug = String(object.tagTitleWithSlug);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Category message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.blog.Category
                     * @static
                     * @param {theplant.ec.service.blog.Category} message Category
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Category.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.tagId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.tagId = options.longs === String ? "0" : 0;
                            object.tagTitle = "";
                            object.tagTitleWithSlug = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.tagId != null && message.hasOwnProperty("tagId"))
                            if (typeof message.tagId === "number")
                                object.tagId = options.longs === String ? String(message.tagId) : message.tagId;
                            else
                                object.tagId = options.longs === String ? $util.Long.prototype.toString.call(message.tagId) : options.longs === Number ? new $util.LongBits(message.tagId.low >>> 0, message.tagId.high >>> 0).toNumber() : message.tagId;
                        if (message.tagTitle != null && message.hasOwnProperty("tagTitle"))
                            object.tagTitle = message.tagTitle;
                        if (message.tagTitleWithSlug != null && message.hasOwnProperty("tagTitleWithSlug"))
                            object.tagTitleWithSlug = message.tagTitleWithSlug;
                        return object;
                    };

                    /**
                     * Converts this Category to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.blog.Category
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Category.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Category;
                })();

                blog.Article = (function() {

                    /**
                     * Properties of an Article.
                     * @memberof theplant.ec.service.blog
                     * @interface IArticle
                     * @property {number|Long|null} [id] Article id
                     * @property {string|null} [title] Article title
                     * @property {string|null} [titleWithSlug] Article titleWithSlug
                     * @property {string|null} [publishDate] Article publishDate
                     * @property {string|null} [content] Article content
                     * @property {string|null} [imageUrl] Article imageUrl
                     * @property {Array.<theplant.ec.service.blog.ITag>|null} [tags] Article tags
                     * @property {theplant.ec.service.blog.ITag|null} [tag] Article tag
                     * @property {Array.<theplant.ec.service.base.IImage>|null} [images] Article images
                     */

                    /**
                     * Constructs a new Article.
                     * @memberof theplant.ec.service.blog
                     * @classdesc Represents an Article.
                     * @implements IArticle
                     * @constructor
                     * @param {theplant.ec.service.blog.IArticle=} [properties] Properties to set
                     */
                    function Article(properties) {
                        this.tags = [];
                        this.images = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Article id.
                     * @member {number|Long} id
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Article title.
                     * @member {string} title
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.title = "";

                    /**
                     * Article titleWithSlug.
                     * @member {string} titleWithSlug
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.titleWithSlug = "";

                    /**
                     * Article publishDate.
                     * @member {string} publishDate
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.publishDate = "";

                    /**
                     * Article content.
                     * @member {string} content
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.content = "";

                    /**
                     * Article imageUrl.
                     * @member {string} imageUrl
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.imageUrl = "";

                    /**
                     * Article tags.
                     * @member {Array.<theplant.ec.service.blog.ITag>} tags
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.tags = $util.emptyArray;

                    /**
                     * Article tag.
                     * @member {theplant.ec.service.blog.ITag|null|undefined} tag
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.tag = null;

                    /**
                     * Article images.
                     * @member {Array.<theplant.ec.service.base.IImage>} images
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     */
                    Article.prototype.images = $util.emptyArray;

                    /**
                     * Creates a new Article instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {theplant.ec.service.blog.IArticle=} [properties] Properties to set
                     * @returns {theplant.ec.service.blog.Article} Article instance
                     */
                    Article.create = function create(properties) {
                        return new Article(properties);
                    };

                    /**
                     * Encodes the specified Article message. Does not implicitly {@link theplant.ec.service.blog.Article.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {theplant.ec.service.blog.IArticle} message Article message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Article.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                        if (message.titleWithSlug != null && message.hasOwnProperty("titleWithSlug"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.titleWithSlug);
                        if (message.publishDate != null && message.hasOwnProperty("publishDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.publishDate);
                        if (message.content != null && message.hasOwnProperty("content"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.content);
                        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl);
                        if (message.tags != null && message.tags.length)
                            for (var i = 0; i < message.tags.length; ++i)
                                $root.theplant.ec.service.blog.Tag.encode(message.tags[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.tag != null && message.hasOwnProperty("tag"))
                            $root.theplant.ec.service.blog.Tag.encode(message.tag, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.images != null && message.images.length)
                            for (var i = 0; i < message.images.length; ++i)
                                $root.theplant.ec.service.base.Image.encode(message.images[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Article message, length delimited. Does not implicitly {@link theplant.ec.service.blog.Article.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {theplant.ec.service.blog.IArticle} message Article message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Article.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Article message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.blog.Article} Article
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Article.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.blog.Article();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.title = reader.string();
                                break;
                            case 3:
                                message.titleWithSlug = reader.string();
                                break;
                            case 4:
                                message.publishDate = reader.string();
                                break;
                            case 5:
                                message.content = reader.string();
                                break;
                            case 6:
                                message.imageUrl = reader.string();
                                break;
                            case 7:
                                if (!(message.tags && message.tags.length))
                                    message.tags = [];
                                message.tags.push($root.theplant.ec.service.blog.Tag.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                message.tag = $root.theplant.ec.service.blog.Tag.decode(reader, reader.uint32());
                                break;
                            case 9:
                                if (!(message.images && message.images.length))
                                    message.images = [];
                                message.images.push($root.theplant.ec.service.base.Image.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Article message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.blog.Article} Article
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Article.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Article message.
                     * @function verify
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Article.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.titleWithSlug != null && message.hasOwnProperty("titleWithSlug"))
                            if (!$util.isString(message.titleWithSlug))
                                return "titleWithSlug: string expected";
                        if (message.publishDate != null && message.hasOwnProperty("publishDate"))
                            if (!$util.isString(message.publishDate))
                                return "publishDate: string expected";
                        if (message.content != null && message.hasOwnProperty("content"))
                            if (!$util.isString(message.content))
                                return "content: string expected";
                        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                            if (!$util.isString(message.imageUrl))
                                return "imageUrl: string expected";
                        if (message.tags != null && message.hasOwnProperty("tags")) {
                            if (!Array.isArray(message.tags))
                                return "tags: array expected";
                            for (var i = 0; i < message.tags.length; ++i) {
                                var error = $root.theplant.ec.service.blog.Tag.verify(message.tags[i]);
                                if (error)
                                    return "tags." + error;
                            }
                        }
                        if (message.tag != null && message.hasOwnProperty("tag")) {
                            var error = $root.theplant.ec.service.blog.Tag.verify(message.tag);
                            if (error)
                                return "tag." + error;
                        }
                        if (message.images != null && message.hasOwnProperty("images")) {
                            if (!Array.isArray(message.images))
                                return "images: array expected";
                            for (var i = 0; i < message.images.length; ++i) {
                                var error = $root.theplant.ec.service.base.Image.verify(message.images[i]);
                                if (error)
                                    return "images." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an Article message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.blog.Article} Article
                     */
                    Article.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.blog.Article)
                            return object;
                        var message = new $root.theplant.ec.service.blog.Article();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.titleWithSlug != null)
                            message.titleWithSlug = String(object.titleWithSlug);
                        if (object.publishDate != null)
                            message.publishDate = String(object.publishDate);
                        if (object.content != null)
                            message.content = String(object.content);
                        if (object.imageUrl != null)
                            message.imageUrl = String(object.imageUrl);
                        if (object.tags) {
                            if (!Array.isArray(object.tags))
                                throw TypeError(".theplant.ec.service.blog.Article.tags: array expected");
                            message.tags = [];
                            for (var i = 0; i < object.tags.length; ++i) {
                                if (typeof object.tags[i] !== "object")
                                    throw TypeError(".theplant.ec.service.blog.Article.tags: object expected");
                                message.tags[i] = $root.theplant.ec.service.blog.Tag.fromObject(object.tags[i]);
                            }
                        }
                        if (object.tag != null) {
                            if (typeof object.tag !== "object")
                                throw TypeError(".theplant.ec.service.blog.Article.tag: object expected");
                            message.tag = $root.theplant.ec.service.blog.Tag.fromObject(object.tag);
                        }
                        if (object.images) {
                            if (!Array.isArray(object.images))
                                throw TypeError(".theplant.ec.service.blog.Article.images: array expected");
                            message.images = [];
                            for (var i = 0; i < object.images.length; ++i) {
                                if (typeof object.images[i] !== "object")
                                    throw TypeError(".theplant.ec.service.blog.Article.images: object expected");
                                message.images[i] = $root.theplant.ec.service.base.Image.fromObject(object.images[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an Article message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.blog.Article
                     * @static
                     * @param {theplant.ec.service.blog.Article} message Article
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Article.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.tags = [];
                            object.images = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.title = "";
                            object.titleWithSlug = "";
                            object.publishDate = "";
                            object.content = "";
                            object.imageUrl = "";
                            object.tag = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.titleWithSlug != null && message.hasOwnProperty("titleWithSlug"))
                            object.titleWithSlug = message.titleWithSlug;
                        if (message.publishDate != null && message.hasOwnProperty("publishDate"))
                            object.publishDate = message.publishDate;
                        if (message.content != null && message.hasOwnProperty("content"))
                            object.content = message.content;
                        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                            object.imageUrl = message.imageUrl;
                        if (message.tags && message.tags.length) {
                            object.tags = [];
                            for (var j = 0; j < message.tags.length; ++j)
                                object.tags[j] = $root.theplant.ec.service.blog.Tag.toObject(message.tags[j], options);
                        }
                        if (message.tag != null && message.hasOwnProperty("tag"))
                            object.tag = $root.theplant.ec.service.blog.Tag.toObject(message.tag, options);
                        if (message.images && message.images.length) {
                            object.images = [];
                            for (var j = 0; j < message.images.length; ++j)
                                object.images[j] = $root.theplant.ec.service.base.Image.toObject(message.images[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Article to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.blog.Article
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Article.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Article;
                })();

                return blog;
            })();

            service.base = (function() {

                /**
                 * Namespace base.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var base = {};

                base.Property = (function() {

                    /**
                     * Properties of a Property.
                     * @memberof theplant.ec.service.base
                     * @interface IProperty
                     * @property {string|null} [field] Property field
                     * @property {Array.<string>|null} [values] Property values
                     */

                    /**
                     * Constructs a new Property.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents a Property.
                     * @implements IProperty
                     * @constructor
                     * @param {theplant.ec.service.base.IProperty=} [properties] Properties to set
                     */
                    function Property(properties) {
                        this.values = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Property field.
                     * @member {string} field
                     * @memberof theplant.ec.service.base.Property
                     * @instance
                     */
                    Property.prototype.field = "";

                    /**
                     * Property values.
                     * @member {Array.<string>} values
                     * @memberof theplant.ec.service.base.Property
                     * @instance
                     */
                    Property.prototype.values = $util.emptyArray;

                    /**
                     * Creates a new Property instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {theplant.ec.service.base.IProperty=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.Property} Property instance
                     */
                    Property.create = function create(properties) {
                        return new Property(properties);
                    };

                    /**
                     * Encodes the specified Property message. Does not implicitly {@link theplant.ec.service.base.Property.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {theplant.ec.service.base.IProperty} message Property message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Property.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                        if (message.values != null && message.values.length)
                            for (var i = 0; i < message.values.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified Property message, length delimited. Does not implicitly {@link theplant.ec.service.base.Property.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {theplant.ec.service.base.IProperty} message Property message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Property.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Property message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.Property} Property
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Property.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.Property();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = reader.string();
                                break;
                            case 2:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Property message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.Property} Property
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Property.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Property message.
                     * @function verify
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Property.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i)
                                if (!$util.isString(message.values[i]))
                                    return "values: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a Property message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.Property} Property
                     */
                    Property.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.Property)
                            return object;
                        var message = new $root.theplant.ec.service.base.Property();
                        if (object.field != null)
                            message.field = String(object.field);
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".theplant.ec.service.base.Property.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i)
                                message.values[i] = String(object.values[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Property message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.Property
                     * @static
                     * @param {theplant.ec.service.base.Property} message Property
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Property.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.values = [];
                        if (options.defaults)
                            object.field = "";
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = message.values[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this Property to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.Property
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Property.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Property;
                })();

                base.Pagination = (function() {

                    /**
                     * Properties of a Pagination.
                     * @memberof theplant.ec.service.base
                     * @interface IPagination
                     * @property {number|Long|null} [page] Pagination page
                     * @property {number|Long|null} [perPage] Pagination perPage
                     */

                    /**
                     * Constructs a new Pagination.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents a Pagination.
                     * @implements IPagination
                     * @constructor
                     * @param {theplant.ec.service.base.IPagination=} [properties] Properties to set
                     */
                    function Pagination(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Pagination page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.service.base.Pagination
                     * @instance
                     */
                    Pagination.prototype.page = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Pagination perPage.
                     * @member {number|Long} perPage
                     * @memberof theplant.ec.service.base.Pagination
                     * @instance
                     */
                    Pagination.prototype.perPage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new Pagination instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {theplant.ec.service.base.IPagination=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.Pagination} Pagination instance
                     */
                    Pagination.create = function create(properties) {
                        return new Pagination(properties);
                    };

                    /**
                     * Encodes the specified Pagination message. Does not implicitly {@link theplant.ec.service.base.Pagination.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {theplant.ec.service.base.IPagination} message Pagination message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Pagination.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.page);
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.perPage);
                        return writer;
                    };

                    /**
                     * Encodes the specified Pagination message, length delimited. Does not implicitly {@link theplant.ec.service.base.Pagination.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {theplant.ec.service.base.IPagination} message Pagination message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Pagination.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Pagination message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.Pagination} Pagination
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Pagination.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.Pagination();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.page = reader.int64();
                                break;
                            case 2:
                                message.perPage = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Pagination message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.Pagination} Pagination
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Pagination.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Pagination message.
                     * @function verify
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Pagination.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (!$util.isInteger(message.perPage) && !(message.perPage && $util.isInteger(message.perPage.low) && $util.isInteger(message.perPage.high)))
                                return "perPage: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a Pagination message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.Pagination} Pagination
                     */
                    Pagination.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.Pagination)
                            return object;
                        var message = new $root.theplant.ec.service.base.Pagination();
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = false;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber();
                        if (object.perPage != null)
                            if ($util.Long)
                                (message.perPage = $util.Long.fromValue(object.perPage)).unsigned = false;
                            else if (typeof object.perPage === "string")
                                message.perPage = parseInt(object.perPage, 10);
                            else if (typeof object.perPage === "number")
                                message.perPage = object.perPage;
                            else if (typeof object.perPage === "object")
                                message.perPage = new $util.LongBits(object.perPage.low >>> 0, object.perPage.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a Pagination message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.Pagination
                     * @static
                     * @param {theplant.ec.service.base.Pagination} message Pagination
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Pagination.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.perPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.perPage = options.longs === String ? "0" : 0;
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber() : message.page;
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (typeof message.perPage === "number")
                                object.perPage = options.longs === String ? String(message.perPage) : message.perPage;
                            else
                                object.perPage = options.longs === String ? $util.Long.prototype.toString.call(message.perPage) : options.longs === Number ? new $util.LongBits(message.perPage.low >>> 0, message.perPage.high >>> 0).toNumber() : message.perPage;
                        return object;
                    };

                    /**
                     * Converts this Pagination to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.Pagination
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Pagination.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Pagination;
                })();

                base.ImagePacket = (function() {

                    /**
                     * Properties of an ImagePacket.
                     * @memberof theplant.ec.service.base
                     * @interface IImagePacket
                     * @property {string|null} [url] ImagePacket url
                     * @property {string|null} [url_2x] ImagePacket url_2x
                     * @property {string|null} [url_3x] ImagePacket url_3x
                     */

                    /**
                     * Constructs a new ImagePacket.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents an ImagePacket.
                     * @implements IImagePacket
                     * @constructor
                     * @param {theplant.ec.service.base.IImagePacket=} [properties] Properties to set
                     */
                    function ImagePacket(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ImagePacket url.
                     * @member {string} url
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @instance
                     */
                    ImagePacket.prototype.url = "";

                    /**
                     * ImagePacket url_2x.
                     * @member {string} url_2x
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @instance
                     */
                    ImagePacket.prototype.url_2x = "";

                    /**
                     * ImagePacket url_3x.
                     * @member {string} url_3x
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @instance
                     */
                    ImagePacket.prototype.url_3x = "";

                    /**
                     * Creates a new ImagePacket instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {theplant.ec.service.base.IImagePacket=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.ImagePacket} ImagePacket instance
                     */
                    ImagePacket.create = function create(properties) {
                        return new ImagePacket(properties);
                    };

                    /**
                     * Encodes the specified ImagePacket message. Does not implicitly {@link theplant.ec.service.base.ImagePacket.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {theplant.ec.service.base.IImagePacket} message ImagePacket message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImagePacket.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.url != null && message.hasOwnProperty("url"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                        if (message.url_2x != null && message.hasOwnProperty("url_2x"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.url_2x);
                        if (message.url_3x != null && message.hasOwnProperty("url_3x"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.url_3x);
                        return writer;
                    };

                    /**
                     * Encodes the specified ImagePacket message, length delimited. Does not implicitly {@link theplant.ec.service.base.ImagePacket.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {theplant.ec.service.base.IImagePacket} message ImagePacket message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ImagePacket.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ImagePacket message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.ImagePacket} ImagePacket
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImagePacket.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.ImagePacket();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.url = reader.string();
                                break;
                            case 2:
                                message.url_2x = reader.string();
                                break;
                            case 3:
                                message.url_3x = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ImagePacket message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.ImagePacket} ImagePacket
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ImagePacket.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ImagePacket message.
                     * @function verify
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ImagePacket.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        if (message.url_2x != null && message.hasOwnProperty("url_2x"))
                            if (!$util.isString(message.url_2x))
                                return "url_2x: string expected";
                        if (message.url_3x != null && message.hasOwnProperty("url_3x"))
                            if (!$util.isString(message.url_3x))
                                return "url_3x: string expected";
                        return null;
                    };

                    /**
                     * Creates an ImagePacket message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.ImagePacket} ImagePacket
                     */
                    ImagePacket.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.ImagePacket)
                            return object;
                        var message = new $root.theplant.ec.service.base.ImagePacket();
                        if (object.url != null)
                            message.url = String(object.url);
                        if (object.url_2x != null)
                            message.url_2x = String(object.url_2x);
                        if (object.url_3x != null)
                            message.url_3x = String(object.url_3x);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ImagePacket message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @static
                     * @param {theplant.ec.service.base.ImagePacket} message ImagePacket
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ImagePacket.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.url = "";
                            object.url_2x = "";
                            object.url_3x = "";
                        }
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        if (message.url_2x != null && message.hasOwnProperty("url_2x"))
                            object.url_2x = message.url_2x;
                        if (message.url_3x != null && message.hasOwnProperty("url_3x"))
                            object.url_3x = message.url_3x;
                        return object;
                    };

                    /**
                     * Converts this ImagePacket to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.ImagePacket
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ImagePacket.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ImagePacket;
                })();

                base.Image = (function() {

                    /**
                     * Properties of an Image.
                     * @memberof theplant.ec.service.base
                     * @interface IImage
                     * @property {string|null} [style] Image style
                     * @property {theplant.ec.service.base.IImagePacket|null} [imagePacket] Image imagePacket
                     * @property {number|null} [width] Image width
                     * @property {number|null} [height] Image height
                     */

                    /**
                     * Constructs a new Image.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents an Image.
                     * @implements IImage
                     * @constructor
                     * @param {theplant.ec.service.base.IImage=} [properties] Properties to set
                     */
                    function Image(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Image style.
                     * @member {string} style
                     * @memberof theplant.ec.service.base.Image
                     * @instance
                     */
                    Image.prototype.style = "";

                    /**
                     * Image imagePacket.
                     * @member {theplant.ec.service.base.IImagePacket|null|undefined} imagePacket
                     * @memberof theplant.ec.service.base.Image
                     * @instance
                     */
                    Image.prototype.imagePacket = null;

                    /**
                     * Image width.
                     * @member {number} width
                     * @memberof theplant.ec.service.base.Image
                     * @instance
                     */
                    Image.prototype.width = 0;

                    /**
                     * Image height.
                     * @member {number} height
                     * @memberof theplant.ec.service.base.Image
                     * @instance
                     */
                    Image.prototype.height = 0;

                    /**
                     * Creates a new Image instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {theplant.ec.service.base.IImage=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.Image} Image instance
                     */
                    Image.create = function create(properties) {
                        return new Image(properties);
                    };

                    /**
                     * Encodes the specified Image message. Does not implicitly {@link theplant.ec.service.base.Image.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {theplant.ec.service.base.IImage} message Image message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Image.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.style != null && message.hasOwnProperty("style"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.style);
                        if (message.imagePacket != null && message.hasOwnProperty("imagePacket"))
                            $root.theplant.ec.service.base.ImagePacket.encode(message.imagePacket, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.width != null && message.hasOwnProperty("width"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.width);
                        if (message.height != null && message.hasOwnProperty("height"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.height);
                        return writer;
                    };

                    /**
                     * Encodes the specified Image message, length delimited. Does not implicitly {@link theplant.ec.service.base.Image.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {theplant.ec.service.base.IImage} message Image message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Image.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Image message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.Image} Image
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Image.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.Image();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.style = reader.string();
                                break;
                            case 2:
                                message.imagePacket = $root.theplant.ec.service.base.ImagePacket.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.width = reader.int32();
                                break;
                            case 4:
                                message.height = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Image message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.Image} Image
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Image.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Image message.
                     * @function verify
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Image.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.style != null && message.hasOwnProperty("style"))
                            if (!$util.isString(message.style))
                                return "style: string expected";
                        if (message.imagePacket != null && message.hasOwnProperty("imagePacket")) {
                            var error = $root.theplant.ec.service.base.ImagePacket.verify(message.imagePacket);
                            if (error)
                                return "imagePacket." + error;
                        }
                        if (message.width != null && message.hasOwnProperty("width"))
                            if (!$util.isInteger(message.width))
                                return "width: integer expected";
                        if (message.height != null && message.hasOwnProperty("height"))
                            if (!$util.isInteger(message.height))
                                return "height: integer expected";
                        return null;
                    };

                    /**
                     * Creates an Image message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.Image} Image
                     */
                    Image.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.Image)
                            return object;
                        var message = new $root.theplant.ec.service.base.Image();
                        if (object.style != null)
                            message.style = String(object.style);
                        if (object.imagePacket != null) {
                            if (typeof object.imagePacket !== "object")
                                throw TypeError(".theplant.ec.service.base.Image.imagePacket: object expected");
                            message.imagePacket = $root.theplant.ec.service.base.ImagePacket.fromObject(object.imagePacket);
                        }
                        if (object.width != null)
                            message.width = object.width | 0;
                        if (object.height != null)
                            message.height = object.height | 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from an Image message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.Image
                     * @static
                     * @param {theplant.ec.service.base.Image} message Image
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Image.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.style = "";
                            object.imagePacket = null;
                            object.width = 0;
                            object.height = 0;
                        }
                        if (message.style != null && message.hasOwnProperty("style"))
                            object.style = message.style;
                        if (message.imagePacket != null && message.hasOwnProperty("imagePacket"))
                            object.imagePacket = $root.theplant.ec.service.base.ImagePacket.toObject(message.imagePacket, options);
                        if (message.width != null && message.hasOwnProperty("width"))
                            object.width = message.width;
                        if (message.height != null && message.hasOwnProperty("height"))
                            object.height = message.height;
                        return object;
                    };

                    /**
                     * Converts this Image to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.Image
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Image.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Image;
                })();

                base.Html = (function() {

                    /**
                     * Properties of a Html.
                     * @memberof theplant.ec.service.base
                     * @interface IHtml
                     * @property {string|null} [html] Html html
                     */

                    /**
                     * Constructs a new Html.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents a Html.
                     * @implements IHtml
                     * @constructor
                     * @param {theplant.ec.service.base.IHtml=} [properties] Properties to set
                     */
                    function Html(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Html html.
                     * @member {string} html
                     * @memberof theplant.ec.service.base.Html
                     * @instance
                     */
                    Html.prototype.html = "";

                    /**
                     * Creates a new Html instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {theplant.ec.service.base.IHtml=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.Html} Html instance
                     */
                    Html.create = function create(properties) {
                        return new Html(properties);
                    };

                    /**
                     * Encodes the specified Html message. Does not implicitly {@link theplant.ec.service.base.Html.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {theplant.ec.service.base.IHtml} message Html message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Html.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.html != null && message.hasOwnProperty("html"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.html);
                        return writer;
                    };

                    /**
                     * Encodes the specified Html message, length delimited. Does not implicitly {@link theplant.ec.service.base.Html.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {theplant.ec.service.base.IHtml} message Html message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Html.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Html message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.Html} Html
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Html.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.Html();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.html = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Html message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.Html} Html
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Html.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Html message.
                     * @function verify
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Html.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.html != null && message.hasOwnProperty("html"))
                            if (!$util.isString(message.html))
                                return "html: string expected";
                        return null;
                    };

                    /**
                     * Creates a Html message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.Html} Html
                     */
                    Html.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.Html)
                            return object;
                        var message = new $root.theplant.ec.service.base.Html();
                        if (object.html != null)
                            message.html = String(object.html);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Html message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.Html
                     * @static
                     * @param {theplant.ec.service.base.Html} message Html
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Html.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.html = "";
                        if (message.html != null && message.hasOwnProperty("html"))
                            object.html = message.html;
                        return object;
                    };

                    /**
                     * Converts this Html to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.Html
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Html.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Html;
                })();

                base.CssClassName = (function() {

                    /**
                     * Properties of a CssClassName.
                     * @memberof theplant.ec.service.base
                     * @interface ICssClassName
                     * @property {string|null} [name] CssClassName name
                     */

                    /**
                     * Constructs a new CssClassName.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents a CssClassName.
                     * @implements ICssClassName
                     * @constructor
                     * @param {theplant.ec.service.base.ICssClassName=} [properties] Properties to set
                     */
                    function CssClassName(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CssClassName name.
                     * @member {string} name
                     * @memberof theplant.ec.service.base.CssClassName
                     * @instance
                     */
                    CssClassName.prototype.name = "";

                    /**
                     * Creates a new CssClassName instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {theplant.ec.service.base.ICssClassName=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.CssClassName} CssClassName instance
                     */
                    CssClassName.create = function create(properties) {
                        return new CssClassName(properties);
                    };

                    /**
                     * Encodes the specified CssClassName message. Does not implicitly {@link theplant.ec.service.base.CssClassName.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {theplant.ec.service.base.ICssClassName} message CssClassName message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CssClassName.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };

                    /**
                     * Encodes the specified CssClassName message, length delimited. Does not implicitly {@link theplant.ec.service.base.CssClassName.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {theplant.ec.service.base.ICssClassName} message CssClassName message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CssClassName.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CssClassName message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.CssClassName} CssClassName
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CssClassName.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.CssClassName();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CssClassName message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.CssClassName} CssClassName
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CssClassName.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CssClassName message.
                     * @function verify
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CssClassName.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates a CssClassName message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.CssClassName} CssClassName
                     */
                    CssClassName.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.CssClassName)
                            return object;
                        var message = new $root.theplant.ec.service.base.CssClassName();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CssClassName message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.CssClassName
                     * @static
                     * @param {theplant.ec.service.base.CssClassName} message CssClassName
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CssClassName.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this CssClassName to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.CssClassName
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CssClassName.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CssClassName;
                })();

                base.Button = (function() {

                    /**
                     * Properties of a Button.
                     * @memberof theplant.ec.service.base
                     * @interface IButton
                     * @property {string|null} [label] Button label
                     * @property {string|null} [href] Button href
                     */

                    /**
                     * Constructs a new Button.
                     * @memberof theplant.ec.service.base
                     * @classdesc Represents a Button.
                     * @implements IButton
                     * @constructor
                     * @param {theplant.ec.service.base.IButton=} [properties] Properties to set
                     */
                    function Button(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Button label.
                     * @member {string} label
                     * @memberof theplant.ec.service.base.Button
                     * @instance
                     */
                    Button.prototype.label = "";

                    /**
                     * Button href.
                     * @member {string} href
                     * @memberof theplant.ec.service.base.Button
                     * @instance
                     */
                    Button.prototype.href = "";

                    /**
                     * Creates a new Button instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {theplant.ec.service.base.IButton=} [properties] Properties to set
                     * @returns {theplant.ec.service.base.Button} Button instance
                     */
                    Button.create = function create(properties) {
                        return new Button(properties);
                    };

                    /**
                     * Encodes the specified Button message. Does not implicitly {@link theplant.ec.service.base.Button.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {theplant.ec.service.base.IButton} message Button message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Button.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.label != null && message.hasOwnProperty("label"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                        if (message.href != null && message.hasOwnProperty("href"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.href);
                        return writer;
                    };

                    /**
                     * Encodes the specified Button message, length delimited. Does not implicitly {@link theplant.ec.service.base.Button.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {theplant.ec.service.base.IButton} message Button message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Button.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Button message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.base.Button} Button
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Button.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.base.Button();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.label = reader.string();
                                break;
                            case 2:
                                message.href = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Button message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.base.Button} Button
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Button.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Button message.
                     * @function verify
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Button.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.href != null && message.hasOwnProperty("href"))
                            if (!$util.isString(message.href))
                                return "href: string expected";
                        return null;
                    };

                    /**
                     * Creates a Button message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.base.Button} Button
                     */
                    Button.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.base.Button)
                            return object;
                        var message = new $root.theplant.ec.service.base.Button();
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.href != null)
                            message.href = String(object.href);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Button message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.base.Button
                     * @static
                     * @param {theplant.ec.service.base.Button} message Button
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Button.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.label = "";
                            object.href = "";
                        }
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.href != null && message.hasOwnProperty("href"))
                            object.href = message.href;
                        return object;
                    };

                    /**
                     * Converts this Button to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.base.Button
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Button.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Button;
                })();

                return base;
            })();

            service.contact_us = (function() {

                /**
                 * Namespace contact_us.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var contact_us = {};

                contact_us.ContactUsContent = (function() {

                    /**
                     * Properties of a ContactUsContent.
                     * @memberof theplant.ec.service.contact_us
                     * @interface IContactUsContent
                     * @property {theplant.ec.service.contact_us.ContactUsContent.Title|null} [title] ContactUsContent title
                     * @property {string|null} [familyName] ContactUsContent familyName
                     * @property {string|null} [givenName] ContactUsContent givenName
                     * @property {string|null} [email] ContactUsContent email
                     * @property {string|null} [phone] ContactUsContent phone
                     * @property {theplant.ec.service.contact_us.ReasonType|null} [reasonType] ContactUsContent reasonType
                     * @property {string|null} [subject] ContactUsContent subject
                     * @property {string|null} [message] ContactUsContent message
                     * @property {string|null} [orderCode] ContactUsContent orderCode
                     */

                    /**
                     * Constructs a new ContactUsContent.
                     * @memberof theplant.ec.service.contact_us
                     * @classdesc Represents a ContactUsContent.
                     * @implements IContactUsContent
                     * @constructor
                     * @param {theplant.ec.service.contact_us.IContactUsContent=} [properties] Properties to set
                     */
                    function ContactUsContent(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ContactUsContent title.
                     * @member {theplant.ec.service.contact_us.ContactUsContent.Title} title
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.title = 0;

                    /**
                     * ContactUsContent familyName.
                     * @member {string} familyName
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.familyName = "";

                    /**
                     * ContactUsContent givenName.
                     * @member {string} givenName
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.givenName = "";

                    /**
                     * ContactUsContent email.
                     * @member {string} email
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.email = "";

                    /**
                     * ContactUsContent phone.
                     * @member {string} phone
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.phone = "";

                    /**
                     * ContactUsContent reasonType.
                     * @member {theplant.ec.service.contact_us.ReasonType} reasonType
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.reasonType = 0;

                    /**
                     * ContactUsContent subject.
                     * @member {string} subject
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.subject = "";

                    /**
                     * ContactUsContent message.
                     * @member {string} message
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.message = "";

                    /**
                     * ContactUsContent orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     */
                    ContactUsContent.prototype.orderCode = "";

                    /**
                     * Creates a new ContactUsContent instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {theplant.ec.service.contact_us.IContactUsContent=} [properties] Properties to set
                     * @returns {theplant.ec.service.contact_us.ContactUsContent} ContactUsContent instance
                     */
                    ContactUsContent.create = function create(properties) {
                        return new ContactUsContent(properties);
                    };

                    /**
                     * Encodes the specified ContactUsContent message. Does not implicitly {@link theplant.ec.service.contact_us.ContactUsContent.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {theplant.ec.service.contact_us.IContactUsContent} message ContactUsContent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ContactUsContent.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.title);
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.familyName);
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.givenName);
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
                        if (message.reasonType != null && message.hasOwnProperty("reasonType"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.reasonType);
                        if (message.subject != null && message.hasOwnProperty("subject"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.subject);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.message);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.orderCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified ContactUsContent message, length delimited. Does not implicitly {@link theplant.ec.service.contact_us.ContactUsContent.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {theplant.ec.service.contact_us.IContactUsContent} message ContactUsContent message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ContactUsContent.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ContactUsContent message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.contact_us.ContactUsContent} ContactUsContent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ContactUsContent.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.contact_us.ContactUsContent();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.title = reader.int32();
                                break;
                            case 2:
                                message.familyName = reader.string();
                                break;
                            case 3:
                                message.givenName = reader.string();
                                break;
                            case 4:
                                message.email = reader.string();
                                break;
                            case 5:
                                message.phone = reader.string();
                                break;
                            case 6:
                                message.reasonType = reader.int32();
                                break;
                            case 7:
                                message.subject = reader.string();
                                break;
                            case 8:
                                message.message = reader.string();
                                break;
                            case 9:
                                message.orderCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ContactUsContent message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.contact_us.ContactUsContent} ContactUsContent
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ContactUsContent.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ContactUsContent message.
                     * @function verify
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ContactUsContent.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            switch (message.title) {
                            default:
                                return "title: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            if (!$util.isString(message.familyName))
                                return "familyName: string expected";
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            if (!$util.isString(message.givenName))
                                return "givenName: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            if (!$util.isString(message.phone))
                                return "phone: string expected";
                        if (message.reasonType != null && message.hasOwnProperty("reasonType"))
                            switch (message.reasonType) {
                            default:
                                return "reasonType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                                break;
                            }
                        if (message.subject != null && message.hasOwnProperty("subject"))
                            if (!$util.isString(message.subject))
                                return "subject: string expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a ContactUsContent message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.contact_us.ContactUsContent} ContactUsContent
                     */
                    ContactUsContent.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.contact_us.ContactUsContent)
                            return object;
                        var message = new $root.theplant.ec.service.contact_us.ContactUsContent();
                        switch (object.title) {
                        case "UNKNOWN":
                        case 0:
                            message.title = 0;
                            break;
                        case "MRS":
                        case 1:
                            message.title = 1;
                            break;
                        case "MS":
                        case 2:
                            message.title = 2;
                            break;
                        case "MR":
                        case 3:
                            message.title = 3;
                            break;
                        }
                        if (object.familyName != null)
                            message.familyName = String(object.familyName);
                        if (object.givenName != null)
                            message.givenName = String(object.givenName);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.phone != null)
                            message.phone = String(object.phone);
                        switch (object.reasonType) {
                        case "UNKNOWN":
                        case 0:
                            message.reasonType = 0;
                            break;
                        case "ORDERS":
                        case 1:
                            message.reasonType = 1;
                            break;
                        case "SHIPMENTS":
                        case 2:
                            message.reasonType = 2;
                            break;
                        case "RETURNS_AND_REFUNDS":
                        case 3:
                            message.reasonType = 3;
                            break;
                        case "CUSTOMER_ACCOUNT":
                        case 4:
                            message.reasonType = 4;
                            break;
                        case "PRODUCT_AND_STOCK":
                        case 5:
                            message.reasonType = 5;
                            break;
                        case "STORES":
                        case 6:
                            message.reasonType = 6;
                            break;
                        case "CORPORATE_REQUESTS":
                        case 7:
                            message.reasonType = 7;
                            break;
                        case "SPONSORSHIP":
                        case 8:
                            message.reasonType = 8;
                            break;
                        case "RECRUITMENT":
                        case 9:
                            message.reasonType = 9;
                            break;
                        case "COMMERCIAL_REQUESTS":
                        case 10:
                            message.reasonType = 10;
                            break;
                        case "PERSONAL_DATA":
                        case 11:
                            message.reasonType = 11;
                            break;
                        }
                        if (object.subject != null)
                            message.subject = String(object.subject);
                        if (object.message != null)
                            message.message = String(object.message);
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ContactUsContent message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @static
                     * @param {theplant.ec.service.contact_us.ContactUsContent} message ContactUsContent
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ContactUsContent.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.title = options.enums === String ? "UNKNOWN" : 0;
                            object.familyName = "";
                            object.givenName = "";
                            object.email = "";
                            object.phone = "";
                            object.reasonType = options.enums === String ? "UNKNOWN" : 0;
                            object.subject = "";
                            object.message = "";
                            object.orderCode = "";
                        }
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = options.enums === String ? $root.theplant.ec.service.contact_us.ContactUsContent.Title[message.title] : message.title;
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            object.familyName = message.familyName;
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            object.givenName = message.givenName;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            object.phone = message.phone;
                        if (message.reasonType != null && message.hasOwnProperty("reasonType"))
                            object.reasonType = options.enums === String ? $root.theplant.ec.service.contact_us.ReasonType[message.reasonType] : message.reasonType;
                        if (message.subject != null && message.hasOwnProperty("subject"))
                            object.subject = message.subject;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        return object;
                    };

                    /**
                     * Converts this ContactUsContent to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.contact_us.ContactUsContent
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ContactUsContent.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Title enum.
                     * @name theplant.ec.service.contact_us.ContactUsContent.Title
                     * @enum {string}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} MRS=1 MRS value
                     * @property {number} MS=2 MS value
                     * @property {number} MR=3 MR value
                     */
                    ContactUsContent.Title = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "MRS"] = 1;
                        values[valuesById[2] = "MS"] = 2;
                        values[valuesById[3] = "MR"] = 3;
                        return values;
                    })();

                    return ContactUsContent;
                })();

                /**
                 * ReasonType enum.
                 * @name theplant.ec.service.contact_us.ReasonType
                 * @enum {string}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} ORDERS=1 ORDERS value
                 * @property {number} SHIPMENTS=2 SHIPMENTS value
                 * @property {number} RETURNS_AND_REFUNDS=3 RETURNS_AND_REFUNDS value
                 * @property {number} CUSTOMER_ACCOUNT=4 CUSTOMER_ACCOUNT value
                 * @property {number} PRODUCT_AND_STOCK=5 PRODUCT_AND_STOCK value
                 * @property {number} STORES=6 STORES value
                 * @property {number} CORPORATE_REQUESTS=7 CORPORATE_REQUESTS value
                 * @property {number} SPONSORSHIP=8 SPONSORSHIP value
                 * @property {number} RECRUITMENT=9 RECRUITMENT value
                 * @property {number} COMMERCIAL_REQUESTS=10 COMMERCIAL_REQUESTS value
                 * @property {number} PERSONAL_DATA=11 PERSONAL_DATA value
                 */
                contact_us.ReasonType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "ORDERS"] = 1;
                    values[valuesById[2] = "SHIPMENTS"] = 2;
                    values[valuesById[3] = "RETURNS_AND_REFUNDS"] = 3;
                    values[valuesById[4] = "CUSTOMER_ACCOUNT"] = 4;
                    values[valuesById[5] = "PRODUCT_AND_STOCK"] = 5;
                    values[valuesById[6] = "STORES"] = 6;
                    values[valuesById[7] = "CORPORATE_REQUESTS"] = 7;
                    values[valuesById[8] = "SPONSORSHIP"] = 8;
                    values[valuesById[9] = "RECRUITMENT"] = 9;
                    values[valuesById[10] = "COMMERCIAL_REQUESTS"] = 10;
                    values[valuesById[11] = "PERSONAL_DATA"] = 11;
                    return values;
                })();

                return contact_us;
            })();

            service.customization = (function() {

                /**
                 * Namespace customization.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var customization = {};

                customization.ProductPersonalizations = (function() {

                    /**
                     * Properties of a ProductPersonalizations.
                     * @memberof theplant.ec.service.customization
                     * @interface IProductPersonalizations
                     * @property {string|null} [BodyColor] ProductPersonalizations BodyColor
                     * @property {string|null} [CollarColor] ProductPersonalizations CollarColor
                     * @property {string|null} [SleeveColor] ProductPersonalizations SleeveColor
                     */

                    /**
                     * Constructs a new ProductPersonalizations.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a ProductPersonalizations.
                     * @implements IProductPersonalizations
                     * @constructor
                     * @param {theplant.ec.service.customization.IProductPersonalizations=} [properties] Properties to set
                     */
                    function ProductPersonalizations(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductPersonalizations BodyColor.
                     * @member {string} BodyColor
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @instance
                     */
                    ProductPersonalizations.prototype.BodyColor = "";

                    /**
                     * ProductPersonalizations CollarColor.
                     * @member {string} CollarColor
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @instance
                     */
                    ProductPersonalizations.prototype.CollarColor = "";

                    /**
                     * ProductPersonalizations SleeveColor.
                     * @member {string} SleeveColor
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @instance
                     */
                    ProductPersonalizations.prototype.SleeveColor = "";

                    /**
                     * Creates a new ProductPersonalizations instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.IProductPersonalizations=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.ProductPersonalizations} ProductPersonalizations instance
                     */
                    ProductPersonalizations.create = function create(properties) {
                        return new ProductPersonalizations(properties);
                    };

                    /**
                     * Encodes the specified ProductPersonalizations message. Does not implicitly {@link theplant.ec.service.customization.ProductPersonalizations.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.IProductPersonalizations} message ProductPersonalizations message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductPersonalizations.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.BodyColor != null && message.hasOwnProperty("BodyColor"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.BodyColor);
                        if (message.CollarColor != null && message.hasOwnProperty("CollarColor"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.CollarColor);
                        if (message.SleeveColor != null && message.hasOwnProperty("SleeveColor"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.SleeveColor);
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductPersonalizations message, length delimited. Does not implicitly {@link theplant.ec.service.customization.ProductPersonalizations.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.IProductPersonalizations} message ProductPersonalizations message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductPersonalizations.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductPersonalizations message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.ProductPersonalizations} ProductPersonalizations
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductPersonalizations.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.ProductPersonalizations();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.BodyColor = reader.string();
                                break;
                            case 2:
                                message.CollarColor = reader.string();
                                break;
                            case 3:
                                message.SleeveColor = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductPersonalizations message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.ProductPersonalizations} ProductPersonalizations
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductPersonalizations.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductPersonalizations message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductPersonalizations.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.BodyColor != null && message.hasOwnProperty("BodyColor"))
                            if (!$util.isString(message.BodyColor))
                                return "BodyColor: string expected";
                        if (message.CollarColor != null && message.hasOwnProperty("CollarColor"))
                            if (!$util.isString(message.CollarColor))
                                return "CollarColor: string expected";
                        if (message.SleeveColor != null && message.hasOwnProperty("SleeveColor"))
                            if (!$util.isString(message.SleeveColor))
                                return "SleeveColor: string expected";
                        return null;
                    };

                    /**
                     * Creates a ProductPersonalizations message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.ProductPersonalizations} ProductPersonalizations
                     */
                    ProductPersonalizations.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.ProductPersonalizations)
                            return object;
                        var message = new $root.theplant.ec.service.customization.ProductPersonalizations();
                        if (object.BodyColor != null)
                            message.BodyColor = String(object.BodyColor);
                        if (object.CollarColor != null)
                            message.CollarColor = String(object.CollarColor);
                        if (object.SleeveColor != null)
                            message.SleeveColor = String(object.SleeveColor);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductPersonalizations message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.ProductPersonalizations} message ProductPersonalizations
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductPersonalizations.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.BodyColor = "";
                            object.CollarColor = "";
                            object.SleeveColor = "";
                        }
                        if (message.BodyColor != null && message.hasOwnProperty("BodyColor"))
                            object.BodyColor = message.BodyColor;
                        if (message.CollarColor != null && message.hasOwnProperty("CollarColor"))
                            object.CollarColor = message.CollarColor;
                        if (message.SleeveColor != null && message.hasOwnProperty("SleeveColor"))
                            object.SleeveColor = message.SleeveColor;
                        return object;
                    };

                    /**
                     * Converts this ProductPersonalizations to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.ProductPersonalizations
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductPersonalizations.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductPersonalizations;
                })();

                customization.Product = (function() {

                    /**
                     * Properties of a Product.
                     * @memberof theplant.ec.service.customization
                     * @interface IProduct
                     * @property {string|null} [Code] Product Code
                     * @property {Array.<theplant.ec.service.customization.IProductPersonalizations>|null} [Personalizations] Product Personalizations
                     * @property {string|null} [Size] Product Size
                     */

                    /**
                     * Constructs a new Product.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a Product.
                     * @implements IProduct
                     * @constructor
                     * @param {theplant.ec.service.customization.IProduct=} [properties] Properties to set
                     */
                    function Product(properties) {
                        this.Personalizations = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Product Code.
                     * @member {string} Code
                     * @memberof theplant.ec.service.customization.Product
                     * @instance
                     */
                    Product.prototype.Code = "";

                    /**
                     * Product Personalizations.
                     * @member {Array.<theplant.ec.service.customization.IProductPersonalizations>} Personalizations
                     * @memberof theplant.ec.service.customization.Product
                     * @instance
                     */
                    Product.prototype.Personalizations = $util.emptyArray;

                    /**
                     * Product Size.
                     * @member {string} Size
                     * @memberof theplant.ec.service.customization.Product
                     * @instance
                     */
                    Product.prototype.Size = "";

                    /**
                     * Creates a new Product instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {theplant.ec.service.customization.IProduct=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.Product} Product instance
                     */
                    Product.create = function create(properties) {
                        return new Product(properties);
                    };

                    /**
                     * Encodes the specified Product message. Does not implicitly {@link theplant.ec.service.customization.Product.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {theplant.ec.service.customization.IProduct} message Product message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Product.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.Code != null && message.hasOwnProperty("Code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Code);
                        if (message.Personalizations != null && message.Personalizations.length)
                            for (var i = 0; i < message.Personalizations.length; ++i)
                                $root.theplant.ec.service.customization.ProductPersonalizations.encode(message.Personalizations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.Size != null && message.hasOwnProperty("Size"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Size);
                        return writer;
                    };

                    /**
                     * Encodes the specified Product message, length delimited. Does not implicitly {@link theplant.ec.service.customization.Product.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {theplant.ec.service.customization.IProduct} message Product message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Product.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Product message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.Product} Product
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Product.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.Product();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.Code = reader.string();
                                break;
                            case 2:
                                if (!(message.Personalizations && message.Personalizations.length))
                                    message.Personalizations = [];
                                message.Personalizations.push($root.theplant.ec.service.customization.ProductPersonalizations.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.Size = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Product message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.Product} Product
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Product.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Product message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Product.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.Code != null && message.hasOwnProperty("Code"))
                            if (!$util.isString(message.Code))
                                return "Code: string expected";
                        if (message.Personalizations != null && message.hasOwnProperty("Personalizations")) {
                            if (!Array.isArray(message.Personalizations))
                                return "Personalizations: array expected";
                            for (var i = 0; i < message.Personalizations.length; ++i) {
                                var error = $root.theplant.ec.service.customization.ProductPersonalizations.verify(message.Personalizations[i]);
                                if (error)
                                    return "Personalizations." + error;
                            }
                        }
                        if (message.Size != null && message.hasOwnProperty("Size"))
                            if (!$util.isString(message.Size))
                                return "Size: string expected";
                        return null;
                    };

                    /**
                     * Creates a Product message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.Product} Product
                     */
                    Product.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.Product)
                            return object;
                        var message = new $root.theplant.ec.service.customization.Product();
                        if (object.Code != null)
                            message.Code = String(object.Code);
                        if (object.Personalizations) {
                            if (!Array.isArray(object.Personalizations))
                                throw TypeError(".theplant.ec.service.customization.Product.Personalizations: array expected");
                            message.Personalizations = [];
                            for (var i = 0; i < object.Personalizations.length; ++i) {
                                if (typeof object.Personalizations[i] !== "object")
                                    throw TypeError(".theplant.ec.service.customization.Product.Personalizations: object expected");
                                message.Personalizations[i] = $root.theplant.ec.service.customization.ProductPersonalizations.fromObject(object.Personalizations[i]);
                            }
                        }
                        if (object.Size != null)
                            message.Size = String(object.Size);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Product message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.Product
                     * @static
                     * @param {theplant.ec.service.customization.Product} message Product
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Product.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.Personalizations = [];
                        if (options.defaults) {
                            object.Code = "";
                            object.Size = "";
                        }
                        if (message.Code != null && message.hasOwnProperty("Code"))
                            object.Code = message.Code;
                        if (message.Personalizations && message.Personalizations.length) {
                            object.Personalizations = [];
                            for (var j = 0; j < message.Personalizations.length; ++j)
                                object.Personalizations[j] = $root.theplant.ec.service.customization.ProductPersonalizations.toObject(message.Personalizations[j], options);
                        }
                        if (message.Size != null && message.hasOwnProperty("Size"))
                            object.Size = message.Size;
                        return object;
                    };

                    /**
                     * Converts this Product to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.Product
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Product.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Product;
                })();

                customization.JobPersonalizations = (function() {

                    /**
                     * Properties of a JobPersonalizations.
                     * @memberof theplant.ec.service.customization
                     * @interface IJobPersonalizations
                     * @property {string|null} [Design] JobPersonalizations Design
                     * @property {boolean|null} [IsText] JobPersonalizations IsText
                     * @property {string|null} [Text] JobPersonalizations Text
                     * @property {string|null} [Font] JobPersonalizations Font
                     * @property {string|null} [TextColor] JobPersonalizations TextColor
                     */

                    /**
                     * Constructs a new JobPersonalizations.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a JobPersonalizations.
                     * @implements IJobPersonalizations
                     * @constructor
                     * @param {theplant.ec.service.customization.IJobPersonalizations=} [properties] Properties to set
                     */
                    function JobPersonalizations(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * JobPersonalizations Design.
                     * @member {string} Design
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @instance
                     */
                    JobPersonalizations.prototype.Design = "";

                    /**
                     * JobPersonalizations IsText.
                     * @member {boolean} IsText
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @instance
                     */
                    JobPersonalizations.prototype.IsText = false;

                    /**
                     * JobPersonalizations Text.
                     * @member {string} Text
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @instance
                     */
                    JobPersonalizations.prototype.Text = "";

                    /**
                     * JobPersonalizations Font.
                     * @member {string} Font
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @instance
                     */
                    JobPersonalizations.prototype.Font = "";

                    /**
                     * JobPersonalizations TextColor.
                     * @member {string} TextColor
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @instance
                     */
                    JobPersonalizations.prototype.TextColor = "";

                    /**
                     * Creates a new JobPersonalizations instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.IJobPersonalizations=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.JobPersonalizations} JobPersonalizations instance
                     */
                    JobPersonalizations.create = function create(properties) {
                        return new JobPersonalizations(properties);
                    };

                    /**
                     * Encodes the specified JobPersonalizations message. Does not implicitly {@link theplant.ec.service.customization.JobPersonalizations.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.IJobPersonalizations} message JobPersonalizations message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    JobPersonalizations.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.Design != null && message.hasOwnProperty("Design"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Design);
                        if (message.IsText != null && message.hasOwnProperty("IsText"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsText);
                        if (message.Text != null && message.hasOwnProperty("Text"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Text);
                        if (message.Font != null && message.hasOwnProperty("Font"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Font);
                        if (message.TextColor != null && message.hasOwnProperty("TextColor"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.TextColor);
                        return writer;
                    };

                    /**
                     * Encodes the specified JobPersonalizations message, length delimited. Does not implicitly {@link theplant.ec.service.customization.JobPersonalizations.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.IJobPersonalizations} message JobPersonalizations message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    JobPersonalizations.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a JobPersonalizations message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.JobPersonalizations} JobPersonalizations
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    JobPersonalizations.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.JobPersonalizations();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.Design = reader.string();
                                break;
                            case 2:
                                message.IsText = reader.bool();
                                break;
                            case 3:
                                message.Text = reader.string();
                                break;
                            case 4:
                                message.Font = reader.string();
                                break;
                            case 5:
                                message.TextColor = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a JobPersonalizations message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.JobPersonalizations} JobPersonalizations
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    JobPersonalizations.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a JobPersonalizations message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    JobPersonalizations.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.Design != null && message.hasOwnProperty("Design"))
                            if (!$util.isString(message.Design))
                                return "Design: string expected";
                        if (message.IsText != null && message.hasOwnProperty("IsText"))
                            if (typeof message.IsText !== "boolean")
                                return "IsText: boolean expected";
                        if (message.Text != null && message.hasOwnProperty("Text"))
                            if (!$util.isString(message.Text))
                                return "Text: string expected";
                        if (message.Font != null && message.hasOwnProperty("Font"))
                            if (!$util.isString(message.Font))
                                return "Font: string expected";
                        if (message.TextColor != null && message.hasOwnProperty("TextColor"))
                            if (!$util.isString(message.TextColor))
                                return "TextColor: string expected";
                        return null;
                    };

                    /**
                     * Creates a JobPersonalizations message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.JobPersonalizations} JobPersonalizations
                     */
                    JobPersonalizations.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.JobPersonalizations)
                            return object;
                        var message = new $root.theplant.ec.service.customization.JobPersonalizations();
                        if (object.Design != null)
                            message.Design = String(object.Design);
                        if (object.IsText != null)
                            message.IsText = Boolean(object.IsText);
                        if (object.Text != null)
                            message.Text = String(object.Text);
                        if (object.Font != null)
                            message.Font = String(object.Font);
                        if (object.TextColor != null)
                            message.TextColor = String(object.TextColor);
                        return message;
                    };

                    /**
                     * Creates a plain object from a JobPersonalizations message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @static
                     * @param {theplant.ec.service.customization.JobPersonalizations} message JobPersonalizations
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    JobPersonalizations.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.Design = "";
                            object.IsText = false;
                            object.Text = "";
                            object.Font = "";
                            object.TextColor = "";
                        }
                        if (message.Design != null && message.hasOwnProperty("Design"))
                            object.Design = message.Design;
                        if (message.IsText != null && message.hasOwnProperty("IsText"))
                            object.IsText = message.IsText;
                        if (message.Text != null && message.hasOwnProperty("Text"))
                            object.Text = message.Text;
                        if (message.Font != null && message.hasOwnProperty("Font"))
                            object.Font = message.Font;
                        if (message.TextColor != null && message.hasOwnProperty("TextColor"))
                            object.TextColor = message.TextColor;
                        return object;
                    };

                    /**
                     * Converts this JobPersonalizations to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.JobPersonalizations
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    JobPersonalizations.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return JobPersonalizations;
                })();

                customization.Jobs = (function() {

                    /**
                     * Properties of a Jobs.
                     * @memberof theplant.ec.service.customization
                     * @interface IJobs
                     * @property {string|null} [Location] Jobs Location
                     * @property {string|null} [Name] Jobs Name
                     * @property {Array.<theplant.ec.service.customization.IJobPersonalizations>|null} [Personalizations] Jobs Personalizations
                     * @property {string|null} [View] Jobs View
                     */

                    /**
                     * Constructs a new Jobs.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a Jobs.
                     * @implements IJobs
                     * @constructor
                     * @param {theplant.ec.service.customization.IJobs=} [properties] Properties to set
                     */
                    function Jobs(properties) {
                        this.Personalizations = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Jobs Location.
                     * @member {string} Location
                     * @memberof theplant.ec.service.customization.Jobs
                     * @instance
                     */
                    Jobs.prototype.Location = "";

                    /**
                     * Jobs Name.
                     * @member {string} Name
                     * @memberof theplant.ec.service.customization.Jobs
                     * @instance
                     */
                    Jobs.prototype.Name = "";

                    /**
                     * Jobs Personalizations.
                     * @member {Array.<theplant.ec.service.customization.IJobPersonalizations>} Personalizations
                     * @memberof theplant.ec.service.customization.Jobs
                     * @instance
                     */
                    Jobs.prototype.Personalizations = $util.emptyArray;

                    /**
                     * Jobs View.
                     * @member {string} View
                     * @memberof theplant.ec.service.customization.Jobs
                     * @instance
                     */
                    Jobs.prototype.View = "";

                    /**
                     * Creates a new Jobs instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {theplant.ec.service.customization.IJobs=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.Jobs} Jobs instance
                     */
                    Jobs.create = function create(properties) {
                        return new Jobs(properties);
                    };

                    /**
                     * Encodes the specified Jobs message. Does not implicitly {@link theplant.ec.service.customization.Jobs.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {theplant.ec.service.customization.IJobs} message Jobs message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Jobs.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.Location != null && message.hasOwnProperty("Location"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Location);
                        if (message.Name != null && message.hasOwnProperty("Name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
                        if (message.Personalizations != null && message.Personalizations.length)
                            for (var i = 0; i < message.Personalizations.length; ++i)
                                $root.theplant.ec.service.customization.JobPersonalizations.encode(message.Personalizations[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.View != null && message.hasOwnProperty("View"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.View);
                        return writer;
                    };

                    /**
                     * Encodes the specified Jobs message, length delimited. Does not implicitly {@link theplant.ec.service.customization.Jobs.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {theplant.ec.service.customization.IJobs} message Jobs message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Jobs.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Jobs message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.Jobs} Jobs
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Jobs.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.Jobs();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.Location = reader.string();
                                break;
                            case 2:
                                message.Name = reader.string();
                                break;
                            case 3:
                                if (!(message.Personalizations && message.Personalizations.length))
                                    message.Personalizations = [];
                                message.Personalizations.push($root.theplant.ec.service.customization.JobPersonalizations.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.View = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Jobs message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.Jobs} Jobs
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Jobs.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Jobs message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Jobs.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.Location != null && message.hasOwnProperty("Location"))
                            if (!$util.isString(message.Location))
                                return "Location: string expected";
                        if (message.Name != null && message.hasOwnProperty("Name"))
                            if (!$util.isString(message.Name))
                                return "Name: string expected";
                        if (message.Personalizations != null && message.hasOwnProperty("Personalizations")) {
                            if (!Array.isArray(message.Personalizations))
                                return "Personalizations: array expected";
                            for (var i = 0; i < message.Personalizations.length; ++i) {
                                var error = $root.theplant.ec.service.customization.JobPersonalizations.verify(message.Personalizations[i]);
                                if (error)
                                    return "Personalizations." + error;
                            }
                        }
                        if (message.View != null && message.hasOwnProperty("View"))
                            if (!$util.isString(message.View))
                                return "View: string expected";
                        return null;
                    };

                    /**
                     * Creates a Jobs message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.Jobs} Jobs
                     */
                    Jobs.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.Jobs)
                            return object;
                        var message = new $root.theplant.ec.service.customization.Jobs();
                        if (object.Location != null)
                            message.Location = String(object.Location);
                        if (object.Name != null)
                            message.Name = String(object.Name);
                        if (object.Personalizations) {
                            if (!Array.isArray(object.Personalizations))
                                throw TypeError(".theplant.ec.service.customization.Jobs.Personalizations: array expected");
                            message.Personalizations = [];
                            for (var i = 0; i < object.Personalizations.length; ++i) {
                                if (typeof object.Personalizations[i] !== "object")
                                    throw TypeError(".theplant.ec.service.customization.Jobs.Personalizations: object expected");
                                message.Personalizations[i] = $root.theplant.ec.service.customization.JobPersonalizations.fromObject(object.Personalizations[i]);
                            }
                        }
                        if (object.View != null)
                            message.View = String(object.View);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Jobs message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.Jobs
                     * @static
                     * @param {theplant.ec.service.customization.Jobs} message Jobs
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Jobs.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.Personalizations = [];
                        if (options.defaults) {
                            object.Location = "";
                            object.Name = "";
                            object.View = "";
                        }
                        if (message.Location != null && message.hasOwnProperty("Location"))
                            object.Location = message.Location;
                        if (message.Name != null && message.hasOwnProperty("Name"))
                            object.Name = message.Name;
                        if (message.Personalizations && message.Personalizations.length) {
                            object.Personalizations = [];
                            for (var j = 0; j < message.Personalizations.length; ++j)
                                object.Personalizations[j] = $root.theplant.ec.service.customization.JobPersonalizations.toObject(message.Personalizations[j], options);
                        }
                        if (message.View != null && message.hasOwnProperty("View"))
                            object.View = message.View;
                        return object;
                    };

                    /**
                     * Converts this Jobs to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.Jobs
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Jobs.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Jobs;
                })();

                customization.Customization = (function() {

                    /**
                     * Properties of a Customization.
                     * @memberof theplant.ec.service.customization
                     * @interface ICustomization
                     * @property {string|null} [Session] Customization Session
                     * @property {theplant.ec.service.customization.IProduct|null} [Product] Customization Product
                     * @property {string|null} [PreviewUrl] Customization PreviewUrl
                     * @property {Array.<theplant.ec.service.customization.IJobs>|null} [Jobs] Customization Jobs
                     * @property {string|null} [ThumbnailUrl] Customization ThumbnailUrl
                     */

                    /**
                     * Constructs a new Customization.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a Customization.
                     * @implements ICustomization
                     * @constructor
                     * @param {theplant.ec.service.customization.ICustomization=} [properties] Properties to set
                     */
                    function Customization(properties) {
                        this.Jobs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Customization Session.
                     * @member {string} Session
                     * @memberof theplant.ec.service.customization.Customization
                     * @instance
                     */
                    Customization.prototype.Session = "";

                    /**
                     * Customization Product.
                     * @member {theplant.ec.service.customization.IProduct|null|undefined} Product
                     * @memberof theplant.ec.service.customization.Customization
                     * @instance
                     */
                    Customization.prototype.Product = null;

                    /**
                     * Customization PreviewUrl.
                     * @member {string} PreviewUrl
                     * @memberof theplant.ec.service.customization.Customization
                     * @instance
                     */
                    Customization.prototype.PreviewUrl = "";

                    /**
                     * Customization Jobs.
                     * @member {Array.<theplant.ec.service.customization.IJobs>} Jobs
                     * @memberof theplant.ec.service.customization.Customization
                     * @instance
                     */
                    Customization.prototype.Jobs = $util.emptyArray;

                    /**
                     * Customization ThumbnailUrl.
                     * @member {string} ThumbnailUrl
                     * @memberof theplant.ec.service.customization.Customization
                     * @instance
                     */
                    Customization.prototype.ThumbnailUrl = "";

                    /**
                     * Creates a new Customization instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {theplant.ec.service.customization.ICustomization=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.Customization} Customization instance
                     */
                    Customization.create = function create(properties) {
                        return new Customization(properties);
                    };

                    /**
                     * Encodes the specified Customization message. Does not implicitly {@link theplant.ec.service.customization.Customization.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {theplant.ec.service.customization.ICustomization} message Customization message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Customization.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.Session != null && message.hasOwnProperty("Session"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Session);
                        if (message.Product != null && message.hasOwnProperty("Product"))
                            $root.theplant.ec.service.customization.Product.encode(message.Product, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.PreviewUrl != null && message.hasOwnProperty("PreviewUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.PreviewUrl);
                        if (message.Jobs != null && message.Jobs.length)
                            for (var i = 0; i < message.Jobs.length; ++i)
                                $root.theplant.ec.service.customization.Jobs.encode(message.Jobs[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.ThumbnailUrl != null && message.hasOwnProperty("ThumbnailUrl"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ThumbnailUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified Customization message, length delimited. Does not implicitly {@link theplant.ec.service.customization.Customization.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {theplant.ec.service.customization.ICustomization} message Customization message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Customization.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Customization message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.Customization} Customization
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Customization.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.Customization();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.Session = reader.string();
                                break;
                            case 2:
                                message.Product = $root.theplant.ec.service.customization.Product.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.PreviewUrl = reader.string();
                                break;
                            case 4:
                                if (!(message.Jobs && message.Jobs.length))
                                    message.Jobs = [];
                                message.Jobs.push($root.theplant.ec.service.customization.Jobs.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                message.ThumbnailUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Customization message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.Customization} Customization
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Customization.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Customization message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Customization.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.Session != null && message.hasOwnProperty("Session"))
                            if (!$util.isString(message.Session))
                                return "Session: string expected";
                        if (message.Product != null && message.hasOwnProperty("Product")) {
                            var error = $root.theplant.ec.service.customization.Product.verify(message.Product);
                            if (error)
                                return "Product." + error;
                        }
                        if (message.PreviewUrl != null && message.hasOwnProperty("PreviewUrl"))
                            if (!$util.isString(message.PreviewUrl))
                                return "PreviewUrl: string expected";
                        if (message.Jobs != null && message.hasOwnProperty("Jobs")) {
                            if (!Array.isArray(message.Jobs))
                                return "Jobs: array expected";
                            for (var i = 0; i < message.Jobs.length; ++i) {
                                var error = $root.theplant.ec.service.customization.Jobs.verify(message.Jobs[i]);
                                if (error)
                                    return "Jobs." + error;
                            }
                        }
                        if (message.ThumbnailUrl != null && message.hasOwnProperty("ThumbnailUrl"))
                            if (!$util.isString(message.ThumbnailUrl))
                                return "ThumbnailUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates a Customization message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.Customization} Customization
                     */
                    Customization.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.Customization)
                            return object;
                        var message = new $root.theplant.ec.service.customization.Customization();
                        if (object.Session != null)
                            message.Session = String(object.Session);
                        if (object.Product != null) {
                            if (typeof object.Product !== "object")
                                throw TypeError(".theplant.ec.service.customization.Customization.Product: object expected");
                            message.Product = $root.theplant.ec.service.customization.Product.fromObject(object.Product);
                        }
                        if (object.PreviewUrl != null)
                            message.PreviewUrl = String(object.PreviewUrl);
                        if (object.Jobs) {
                            if (!Array.isArray(object.Jobs))
                                throw TypeError(".theplant.ec.service.customization.Customization.Jobs: array expected");
                            message.Jobs = [];
                            for (var i = 0; i < object.Jobs.length; ++i) {
                                if (typeof object.Jobs[i] !== "object")
                                    throw TypeError(".theplant.ec.service.customization.Customization.Jobs: object expected");
                                message.Jobs[i] = $root.theplant.ec.service.customization.Jobs.fromObject(object.Jobs[i]);
                            }
                        }
                        if (object.ThumbnailUrl != null)
                            message.ThumbnailUrl = String(object.ThumbnailUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Customization message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.Customization
                     * @static
                     * @param {theplant.ec.service.customization.Customization} message Customization
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Customization.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.Jobs = [];
                        if (options.defaults) {
                            object.Session = "";
                            object.Product = null;
                            object.PreviewUrl = "";
                            object.ThumbnailUrl = "";
                        }
                        if (message.Session != null && message.hasOwnProperty("Session"))
                            object.Session = message.Session;
                        if (message.Product != null && message.hasOwnProperty("Product"))
                            object.Product = $root.theplant.ec.service.customization.Product.toObject(message.Product, options);
                        if (message.PreviewUrl != null && message.hasOwnProperty("PreviewUrl"))
                            object.PreviewUrl = message.PreviewUrl;
                        if (message.Jobs && message.Jobs.length) {
                            object.Jobs = [];
                            for (var j = 0; j < message.Jobs.length; ++j)
                                object.Jobs[j] = $root.theplant.ec.service.customization.Jobs.toObject(message.Jobs[j], options);
                        }
                        if (message.ThumbnailUrl != null && message.hasOwnProperty("ThumbnailUrl"))
                            object.ThumbnailUrl = message.ThumbnailUrl;
                        return object;
                    };

                    /**
                     * Converts this Customization to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.Customization
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Customization.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Customization;
                })();

                customization.CustomizationRecord = (function() {

                    /**
                     * Properties of a CustomizationRecord.
                     * @memberof theplant.ec.service.customization
                     * @interface ICustomizationRecord
                     * @property {string|null} [CustomizeType] CustomizationRecord CustomizeType
                     * @property {string|null} [EntranceProductCode] CustomizationRecord EntranceProductCode
                     * @property {string|null} [TargetProductCode] CustomizationRecord TargetProductCode
                     * @property {string|null} [IntroducePageUrl] CustomizationRecord IntroducePageUrl
                     * @property {Array.<string>|null} [PdpContainers] CustomizationRecord PdpContainers
                     */

                    /**
                     * Constructs a new CustomizationRecord.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a CustomizationRecord.
                     * @implements ICustomizationRecord
                     * @constructor
                     * @param {theplant.ec.service.customization.ICustomizationRecord=} [properties] Properties to set
                     */
                    function CustomizationRecord(properties) {
                        this.PdpContainers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomizationRecord CustomizeType.
                     * @member {string} CustomizeType
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @instance
                     */
                    CustomizationRecord.prototype.CustomizeType = "";

                    /**
                     * CustomizationRecord EntranceProductCode.
                     * @member {string} EntranceProductCode
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @instance
                     */
                    CustomizationRecord.prototype.EntranceProductCode = "";

                    /**
                     * CustomizationRecord TargetProductCode.
                     * @member {string} TargetProductCode
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @instance
                     */
                    CustomizationRecord.prototype.TargetProductCode = "";

                    /**
                     * CustomizationRecord IntroducePageUrl.
                     * @member {string} IntroducePageUrl
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @instance
                     */
                    CustomizationRecord.prototype.IntroducePageUrl = "";

                    /**
                     * CustomizationRecord PdpContainers.
                     * @member {Array.<string>} PdpContainers
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @instance
                     */
                    CustomizationRecord.prototype.PdpContainers = $util.emptyArray;

                    /**
                     * Creates a new CustomizationRecord instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {theplant.ec.service.customization.ICustomizationRecord=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.CustomizationRecord} CustomizationRecord instance
                     */
                    CustomizationRecord.create = function create(properties) {
                        return new CustomizationRecord(properties);
                    };

                    /**
                     * Encodes the specified CustomizationRecord message. Does not implicitly {@link theplant.ec.service.customization.CustomizationRecord.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {theplant.ec.service.customization.ICustomizationRecord} message CustomizationRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomizationRecord.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.CustomizeType != null && message.hasOwnProperty("CustomizeType"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.CustomizeType);
                        if (message.EntranceProductCode != null && message.hasOwnProperty("EntranceProductCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.EntranceProductCode);
                        if (message.TargetProductCode != null && message.hasOwnProperty("TargetProductCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.TargetProductCode);
                        if (message.IntroducePageUrl != null && message.hasOwnProperty("IntroducePageUrl"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.IntroducePageUrl);
                        if (message.PdpContainers != null && message.PdpContainers.length)
                            for (var i = 0; i < message.PdpContainers.length; ++i)
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.PdpContainers[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomizationRecord message, length delimited. Does not implicitly {@link theplant.ec.service.customization.CustomizationRecord.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {theplant.ec.service.customization.ICustomizationRecord} message CustomizationRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomizationRecord.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomizationRecord message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.CustomizationRecord} CustomizationRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomizationRecord.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.CustomizationRecord();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.CustomizeType = reader.string();
                                break;
                            case 2:
                                message.EntranceProductCode = reader.string();
                                break;
                            case 3:
                                message.TargetProductCode = reader.string();
                                break;
                            case 4:
                                message.IntroducePageUrl = reader.string();
                                break;
                            case 5:
                                if (!(message.PdpContainers && message.PdpContainers.length))
                                    message.PdpContainers = [];
                                message.PdpContainers.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomizationRecord message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.CustomizationRecord} CustomizationRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomizationRecord.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomizationRecord message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomizationRecord.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.CustomizeType != null && message.hasOwnProperty("CustomizeType"))
                            if (!$util.isString(message.CustomizeType))
                                return "CustomizeType: string expected";
                        if (message.EntranceProductCode != null && message.hasOwnProperty("EntranceProductCode"))
                            if (!$util.isString(message.EntranceProductCode))
                                return "EntranceProductCode: string expected";
                        if (message.TargetProductCode != null && message.hasOwnProperty("TargetProductCode"))
                            if (!$util.isString(message.TargetProductCode))
                                return "TargetProductCode: string expected";
                        if (message.IntroducePageUrl != null && message.hasOwnProperty("IntroducePageUrl"))
                            if (!$util.isString(message.IntroducePageUrl))
                                return "IntroducePageUrl: string expected";
                        if (message.PdpContainers != null && message.hasOwnProperty("PdpContainers")) {
                            if (!Array.isArray(message.PdpContainers))
                                return "PdpContainers: array expected";
                            for (var i = 0; i < message.PdpContainers.length; ++i)
                                if (!$util.isString(message.PdpContainers[i]))
                                    return "PdpContainers: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a CustomizationRecord message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.CustomizationRecord} CustomizationRecord
                     */
                    CustomizationRecord.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.CustomizationRecord)
                            return object;
                        var message = new $root.theplant.ec.service.customization.CustomizationRecord();
                        if (object.CustomizeType != null)
                            message.CustomizeType = String(object.CustomizeType);
                        if (object.EntranceProductCode != null)
                            message.EntranceProductCode = String(object.EntranceProductCode);
                        if (object.TargetProductCode != null)
                            message.TargetProductCode = String(object.TargetProductCode);
                        if (object.IntroducePageUrl != null)
                            message.IntroducePageUrl = String(object.IntroducePageUrl);
                        if (object.PdpContainers) {
                            if (!Array.isArray(object.PdpContainers))
                                throw TypeError(".theplant.ec.service.customization.CustomizationRecord.PdpContainers: array expected");
                            message.PdpContainers = [];
                            for (var i = 0; i < object.PdpContainers.length; ++i)
                                message.PdpContainers[i] = String(object.PdpContainers[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomizationRecord message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @static
                     * @param {theplant.ec.service.customization.CustomizationRecord} message CustomizationRecord
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomizationRecord.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.PdpContainers = [];
                        if (options.defaults) {
                            object.CustomizeType = "";
                            object.EntranceProductCode = "";
                            object.TargetProductCode = "";
                            object.IntroducePageUrl = "";
                        }
                        if (message.CustomizeType != null && message.hasOwnProperty("CustomizeType"))
                            object.CustomizeType = message.CustomizeType;
                        if (message.EntranceProductCode != null && message.hasOwnProperty("EntranceProductCode"))
                            object.EntranceProductCode = message.EntranceProductCode;
                        if (message.TargetProductCode != null && message.hasOwnProperty("TargetProductCode"))
                            object.TargetProductCode = message.TargetProductCode;
                        if (message.IntroducePageUrl != null && message.hasOwnProperty("IntroducePageUrl"))
                            object.IntroducePageUrl = message.IntroducePageUrl;
                        if (message.PdpContainers && message.PdpContainers.length) {
                            object.PdpContainers = [];
                            for (var j = 0; j < message.PdpContainers.length; ++j)
                                object.PdpContainers[j] = message.PdpContainers[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this CustomizationRecord to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.CustomizationRecord
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomizationRecord.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CustomizationRecord;
                })();

                customization.CustomizationSetting = (function() {

                    /**
                     * Properties of a CustomizationSetting.
                     * @memberof theplant.ec.service.customization
                     * @interface ICustomizationSetting
                     * @property {boolean|null} [EnableCustomization] CustomizationSetting EnableCustomization
                     * @property {Array.<theplant.ec.service.customization.ICustomizationRecord>|null} [Customizations] CustomizationSetting Customizations
                     */

                    /**
                     * Constructs a new CustomizationSetting.
                     * @memberof theplant.ec.service.customization
                     * @classdesc Represents a CustomizationSetting.
                     * @implements ICustomizationSetting
                     * @constructor
                     * @param {theplant.ec.service.customization.ICustomizationSetting=} [properties] Properties to set
                     */
                    function CustomizationSetting(properties) {
                        this.Customizations = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomizationSetting EnableCustomization.
                     * @member {boolean} EnableCustomization
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @instance
                     */
                    CustomizationSetting.prototype.EnableCustomization = false;

                    /**
                     * CustomizationSetting Customizations.
                     * @member {Array.<theplant.ec.service.customization.ICustomizationRecord>} Customizations
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @instance
                     */
                    CustomizationSetting.prototype.Customizations = $util.emptyArray;

                    /**
                     * Creates a new CustomizationSetting instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {theplant.ec.service.customization.ICustomizationSetting=} [properties] Properties to set
                     * @returns {theplant.ec.service.customization.CustomizationSetting} CustomizationSetting instance
                     */
                    CustomizationSetting.create = function create(properties) {
                        return new CustomizationSetting(properties);
                    };

                    /**
                     * Encodes the specified CustomizationSetting message. Does not implicitly {@link theplant.ec.service.customization.CustomizationSetting.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {theplant.ec.service.customization.ICustomizationSetting} message CustomizationSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomizationSetting.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.EnableCustomization != null && message.hasOwnProperty("EnableCustomization"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.EnableCustomization);
                        if (message.Customizations != null && message.Customizations.length)
                            for (var i = 0; i < message.Customizations.length; ++i)
                                $root.theplant.ec.service.customization.CustomizationRecord.encode(message.Customizations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomizationSetting message, length delimited. Does not implicitly {@link theplant.ec.service.customization.CustomizationSetting.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {theplant.ec.service.customization.ICustomizationSetting} message CustomizationSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomizationSetting.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomizationSetting message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.customization.CustomizationSetting} CustomizationSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomizationSetting.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.customization.CustomizationSetting();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.EnableCustomization = reader.bool();
                                break;
                            case 2:
                                if (!(message.Customizations && message.Customizations.length))
                                    message.Customizations = [];
                                message.Customizations.push($root.theplant.ec.service.customization.CustomizationRecord.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomizationSetting message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.customization.CustomizationSetting} CustomizationSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomizationSetting.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomizationSetting message.
                     * @function verify
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomizationSetting.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.EnableCustomization != null && message.hasOwnProperty("EnableCustomization"))
                            if (typeof message.EnableCustomization !== "boolean")
                                return "EnableCustomization: boolean expected";
                        if (message.Customizations != null && message.hasOwnProperty("Customizations")) {
                            if (!Array.isArray(message.Customizations))
                                return "Customizations: array expected";
                            for (var i = 0; i < message.Customizations.length; ++i) {
                                var error = $root.theplant.ec.service.customization.CustomizationRecord.verify(message.Customizations[i]);
                                if (error)
                                    return "Customizations." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a CustomizationSetting message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.customization.CustomizationSetting} CustomizationSetting
                     */
                    CustomizationSetting.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.customization.CustomizationSetting)
                            return object;
                        var message = new $root.theplant.ec.service.customization.CustomizationSetting();
                        if (object.EnableCustomization != null)
                            message.EnableCustomization = Boolean(object.EnableCustomization);
                        if (object.Customizations) {
                            if (!Array.isArray(object.Customizations))
                                throw TypeError(".theplant.ec.service.customization.CustomizationSetting.Customizations: array expected");
                            message.Customizations = [];
                            for (var i = 0; i < object.Customizations.length; ++i) {
                                if (typeof object.Customizations[i] !== "object")
                                    throw TypeError(".theplant.ec.service.customization.CustomizationSetting.Customizations: object expected");
                                message.Customizations[i] = $root.theplant.ec.service.customization.CustomizationRecord.fromObject(object.Customizations[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomizationSetting message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @static
                     * @param {theplant.ec.service.customization.CustomizationSetting} message CustomizationSetting
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomizationSetting.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.Customizations = [];
                        if (options.defaults)
                            object.EnableCustomization = false;
                        if (message.EnableCustomization != null && message.hasOwnProperty("EnableCustomization"))
                            object.EnableCustomization = message.EnableCustomization;
                        if (message.Customizations && message.Customizations.length) {
                            object.Customizations = [];
                            for (var j = 0; j < message.Customizations.length; ++j)
                                object.Customizations[j] = $root.theplant.ec.service.customization.CustomizationRecord.toObject(message.Customizations[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this CustomizationSetting to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.customization.CustomizationSetting
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomizationSetting.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CustomizationSetting;
                })();

                return customization;
            })();

            service.stores = (function() {

                /**
                 * Namespace stores.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var stores = {};

                stores.Store = (function() {

                    /**
                     * Properties of a Store.
                     * @memberof theplant.ec.service.stores
                     * @interface IStore
                     * @property {string|null} [id] Store id
                     * @property {boolean|null} [published] Store published
                     * @property {string|null} [externalCode] Store externalCode
                     * @property {string|null} [name] Store name
                     * @property {string|null} [businessHours] Store businessHours
                     * @property {string|null} [phone] Store phone
                     * @property {string|null} [email] Store email
                     * @property {theplant.ec.service.stores.StoreForm|null} [form] Store form
                     * @property {boolean|null} [isTaxExempt] Store isTaxExempt
                     * @property {boolean|null} [isPointsUsable] Store isPointsUsable
                     * @property {string|null} [address] Store address
                     * @property {string|null} [city] Store city
                     * @property {string|null} [region] Store region
                     * @property {string|null} [country] Store country
                     * @property {string|null} [zip] Store zip
                     * @property {number|null} [latitude] Store latitude
                     * @property {number|null} [longitude] Store longitude
                     * @property {string|null} [linproId] Store linproId
                     * @property {string|null} [posId] Store posId
                     * @property {string|null} [storeIdForEmailSubject] Store storeIdForEmailSubject
                     * @property {string|null} [storeIdForSms] Store storeIdForSms
                     * @property {string|null} [blogUrl] Store blogUrl
                     * @property {string|null} [prefecture] Store prefecture
                     * @property {boolean|null} [allowShipFromStore] Store allowShipFromStore
                     * @property {string|null} [orliId] Store orliId
                     * @property {string|null} [y2Id] Store y2Id
                     */

                    /**
                     * Constructs a new Store.
                     * @memberof theplant.ec.service.stores
                     * @classdesc Represents a Store.
                     * @implements IStore
                     * @constructor
                     * @param {theplant.ec.service.stores.IStore=} [properties] Properties to set
                     */
                    function Store(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Store id.
                     * @member {string} id
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.id = "";

                    /**
                     * Store published.
                     * @member {boolean} published
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.published = false;

                    /**
                     * Store externalCode.
                     * @member {string} externalCode
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.externalCode = "";

                    /**
                     * Store name.
                     * @member {string} name
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.name = "";

                    /**
                     * Store businessHours.
                     * @member {string} businessHours
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.businessHours = "";

                    /**
                     * Store phone.
                     * @member {string} phone
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.phone = "";

                    /**
                     * Store email.
                     * @member {string} email
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.email = "";

                    /**
                     * Store form.
                     * @member {theplant.ec.service.stores.StoreForm} form
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.form = 0;

                    /**
                     * Store isTaxExempt.
                     * @member {boolean} isTaxExempt
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.isTaxExempt = false;

                    /**
                     * Store isPointsUsable.
                     * @member {boolean} isPointsUsable
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.isPointsUsable = false;

                    /**
                     * Store address.
                     * @member {string} address
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.address = "";

                    /**
                     * Store city.
                     * @member {string} city
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.city = "";

                    /**
                     * Store region.
                     * @member {string} region
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.region = "";

                    /**
                     * Store country.
                     * @member {string} country
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.country = "";

                    /**
                     * Store zip.
                     * @member {string} zip
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.zip = "";

                    /**
                     * Store latitude.
                     * @member {number} latitude
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.latitude = 0;

                    /**
                     * Store longitude.
                     * @member {number} longitude
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.longitude = 0;

                    /**
                     * Store linproId.
                     * @member {string} linproId
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.linproId = "";

                    /**
                     * Store posId.
                     * @member {string} posId
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.posId = "";

                    /**
                     * Store storeIdForEmailSubject.
                     * @member {string} storeIdForEmailSubject
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.storeIdForEmailSubject = "";

                    /**
                     * Store storeIdForSms.
                     * @member {string} storeIdForSms
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.storeIdForSms = "";

                    /**
                     * Store blogUrl.
                     * @member {string} blogUrl
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.blogUrl = "";

                    /**
                     * Store prefecture.
                     * @member {string} prefecture
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.prefecture = "";

                    /**
                     * Store allowShipFromStore.
                     * @member {boolean} allowShipFromStore
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.allowShipFromStore = false;

                    /**
                     * Store orliId.
                     * @member {string} orliId
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.orliId = "";

                    /**
                     * Store y2Id.
                     * @member {string} y2Id
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     */
                    Store.prototype.y2Id = "";

                    /**
                     * Creates a new Store instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {theplant.ec.service.stores.IStore=} [properties] Properties to set
                     * @returns {theplant.ec.service.stores.Store} Store instance
                     */
                    Store.create = function create(properties) {
                        return new Store(properties);
                    };

                    /**
                     * Encodes the specified Store message. Does not implicitly {@link theplant.ec.service.stores.Store.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {theplant.ec.service.stores.IStore} message Store message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Store.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.externalCode != null && message.hasOwnProperty("externalCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalCode);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.businessHours);
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.phone);
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
                        if (message.form != null && message.hasOwnProperty("form"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.form);
                        if (message.isTaxExempt != null && message.hasOwnProperty("isTaxExempt"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isTaxExempt);
                        if (message.isPointsUsable != null && message.hasOwnProperty("isPointsUsable"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isPointsUsable);
                        if (message.address != null && message.hasOwnProperty("address"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.address);
                        if (message.city != null && message.hasOwnProperty("city"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.city);
                        if (message.region != null && message.hasOwnProperty("region"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.region);
                        if (message.country != null && message.hasOwnProperty("country"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.country);
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.zip);
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            writer.uint32(/* id 15, wireType 1 =*/121).double(message.latitude);
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            writer.uint32(/* id 16, wireType 1 =*/129).double(message.longitude);
                        if (message.linproId != null && message.hasOwnProperty("linproId"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.linproId);
                        if (message.posId != null && message.hasOwnProperty("posId"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.posId);
                        if (message.storeIdForEmailSubject != null && message.hasOwnProperty("storeIdForEmailSubject"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.storeIdForEmailSubject);
                        if (message.storeIdForSms != null && message.hasOwnProperty("storeIdForSms"))
                            writer.uint32(/* id 20, wireType 2 =*/162).string(message.storeIdForSms);
                        if (message.blogUrl != null && message.hasOwnProperty("blogUrl"))
                            writer.uint32(/* id 22, wireType 2 =*/178).string(message.blogUrl);
                        if (message.published != null && message.hasOwnProperty("published"))
                            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.published);
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            writer.uint32(/* id 24, wireType 2 =*/194).string(message.prefecture);
                        if (message.allowShipFromStore != null && message.hasOwnProperty("allowShipFromStore"))
                            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.allowShipFromStore);
                        if (message.orliId != null && message.hasOwnProperty("orliId"))
                            writer.uint32(/* id 26, wireType 2 =*/210).string(message.orliId);
                        if (message.y2Id != null && message.hasOwnProperty("y2Id"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.y2Id);
                        return writer;
                    };

                    /**
                     * Encodes the specified Store message, length delimited. Does not implicitly {@link theplant.ec.service.stores.Store.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {theplant.ec.service.stores.IStore} message Store message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Store.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Store message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.stores.Store} Store
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Store.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.stores.Store();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 23:
                                message.published = reader.bool();
                                break;
                            case 2:
                                message.externalCode = reader.string();
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.businessHours = reader.string();
                                break;
                            case 5:
                                message.phone = reader.string();
                                break;
                            case 6:
                                message.email = reader.string();
                                break;
                            case 7:
                                message.form = reader.int32();
                                break;
                            case 8:
                                message.isTaxExempt = reader.bool();
                                break;
                            case 9:
                                message.isPointsUsable = reader.bool();
                                break;
                            case 10:
                                message.address = reader.string();
                                break;
                            case 11:
                                message.city = reader.string();
                                break;
                            case 12:
                                message.region = reader.string();
                                break;
                            case 13:
                                message.country = reader.string();
                                break;
                            case 14:
                                message.zip = reader.string();
                                break;
                            case 15:
                                message.latitude = reader.double();
                                break;
                            case 16:
                                message.longitude = reader.double();
                                break;
                            case 17:
                                message.linproId = reader.string();
                                break;
                            case 18:
                                message.posId = reader.string();
                                break;
                            case 19:
                                message.storeIdForEmailSubject = reader.string();
                                break;
                            case 20:
                                message.storeIdForSms = reader.string();
                                break;
                            case 22:
                                message.blogUrl = reader.string();
                                break;
                            case 24:
                                message.prefecture = reader.string();
                                break;
                            case 25:
                                message.allowShipFromStore = reader.bool();
                                break;
                            case 26:
                                message.orliId = reader.string();
                                break;
                            case 27:
                                message.y2Id = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Store message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.stores.Store} Store
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Store.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Store message.
                     * @function verify
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Store.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.published != null && message.hasOwnProperty("published"))
                            if (typeof message.published !== "boolean")
                                return "published: boolean expected";
                        if (message.externalCode != null && message.hasOwnProperty("externalCode"))
                            if (!$util.isString(message.externalCode))
                                return "externalCode: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            if (!$util.isString(message.businessHours))
                                return "businessHours: string expected";
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            if (!$util.isString(message.phone))
                                return "phone: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.form != null && message.hasOwnProperty("form"))
                            switch (message.form) {
                            default:
                                return "form: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.isTaxExempt != null && message.hasOwnProperty("isTaxExempt"))
                            if (typeof message.isTaxExempt !== "boolean")
                                return "isTaxExempt: boolean expected";
                        if (message.isPointsUsable != null && message.hasOwnProperty("isPointsUsable"))
                            if (typeof message.isPointsUsable !== "boolean")
                                return "isPointsUsable: boolean expected";
                        if (message.address != null && message.hasOwnProperty("address"))
                            if (!$util.isString(message.address))
                                return "address: string expected";
                        if (message.city != null && message.hasOwnProperty("city"))
                            if (!$util.isString(message.city))
                                return "city: string expected";
                        if (message.region != null && message.hasOwnProperty("region"))
                            if (!$util.isString(message.region))
                                return "region: string expected";
                        if (message.country != null && message.hasOwnProperty("country"))
                            if (!$util.isString(message.country))
                                return "country: string expected";
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            if (!$util.isString(message.zip))
                                return "zip: string expected";
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            if (typeof message.latitude !== "number")
                                return "latitude: number expected";
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            if (typeof message.longitude !== "number")
                                return "longitude: number expected";
                        if (message.linproId != null && message.hasOwnProperty("linproId"))
                            if (!$util.isString(message.linproId))
                                return "linproId: string expected";
                        if (message.posId != null && message.hasOwnProperty("posId"))
                            if (!$util.isString(message.posId))
                                return "posId: string expected";
                        if (message.storeIdForEmailSubject != null && message.hasOwnProperty("storeIdForEmailSubject"))
                            if (!$util.isString(message.storeIdForEmailSubject))
                                return "storeIdForEmailSubject: string expected";
                        if (message.storeIdForSms != null && message.hasOwnProperty("storeIdForSms"))
                            if (!$util.isString(message.storeIdForSms))
                                return "storeIdForSms: string expected";
                        if (message.blogUrl != null && message.hasOwnProperty("blogUrl"))
                            if (!$util.isString(message.blogUrl))
                                return "blogUrl: string expected";
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            if (!$util.isString(message.prefecture))
                                return "prefecture: string expected";
                        if (message.allowShipFromStore != null && message.hasOwnProperty("allowShipFromStore"))
                            if (typeof message.allowShipFromStore !== "boolean")
                                return "allowShipFromStore: boolean expected";
                        if (message.orliId != null && message.hasOwnProperty("orliId"))
                            if (!$util.isString(message.orliId))
                                return "orliId: string expected";
                        if (message.y2Id != null && message.hasOwnProperty("y2Id"))
                            if (!$util.isString(message.y2Id))
                                return "y2Id: string expected";
                        return null;
                    };

                    /**
                     * Creates a Store message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.stores.Store} Store
                     */
                    Store.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.stores.Store)
                            return object;
                        var message = new $root.theplant.ec.service.stores.Store();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.published != null)
                            message.published = Boolean(object.published);
                        if (object.externalCode != null)
                            message.externalCode = String(object.externalCode);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.businessHours != null)
                            message.businessHours = String(object.businessHours);
                        if (object.phone != null)
                            message.phone = String(object.phone);
                        if (object.email != null)
                            message.email = String(object.email);
                        switch (object.form) {
                        case "UNKNOWN":
                        case 0:
                            message.form = 0;
                            break;
                        case "DEPARTMENT":
                        case 1:
                            message.form = 1;
                            break;
                        case "OUTLET":
                        case 2:
                            message.form = 2;
                            break;
                        case "BOUTIQUE":
                        case 3:
                            message.form = 3;
                            break;
                        }
                        if (object.isTaxExempt != null)
                            message.isTaxExempt = Boolean(object.isTaxExempt);
                        if (object.isPointsUsable != null)
                            message.isPointsUsable = Boolean(object.isPointsUsable);
                        if (object.address != null)
                            message.address = String(object.address);
                        if (object.city != null)
                            message.city = String(object.city);
                        if (object.region != null)
                            message.region = String(object.region);
                        if (object.country != null)
                            message.country = String(object.country);
                        if (object.zip != null)
                            message.zip = String(object.zip);
                        if (object.latitude != null)
                            message.latitude = Number(object.latitude);
                        if (object.longitude != null)
                            message.longitude = Number(object.longitude);
                        if (object.linproId != null)
                            message.linproId = String(object.linproId);
                        if (object.posId != null)
                            message.posId = String(object.posId);
                        if (object.storeIdForEmailSubject != null)
                            message.storeIdForEmailSubject = String(object.storeIdForEmailSubject);
                        if (object.storeIdForSms != null)
                            message.storeIdForSms = String(object.storeIdForSms);
                        if (object.blogUrl != null)
                            message.blogUrl = String(object.blogUrl);
                        if (object.prefecture != null)
                            message.prefecture = String(object.prefecture);
                        if (object.allowShipFromStore != null)
                            message.allowShipFromStore = Boolean(object.allowShipFromStore);
                        if (object.orliId != null)
                            message.orliId = String(object.orliId);
                        if (object.y2Id != null)
                            message.y2Id = String(object.y2Id);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Store message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.stores.Store
                     * @static
                     * @param {theplant.ec.service.stores.Store} message Store
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Store.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.externalCode = "";
                            object.name = "";
                            object.businessHours = "";
                            object.phone = "";
                            object.email = "";
                            object.form = options.enums === String ? "UNKNOWN" : 0;
                            object.isTaxExempt = false;
                            object.isPointsUsable = false;
                            object.address = "";
                            object.city = "";
                            object.region = "";
                            object.country = "";
                            object.zip = "";
                            object.latitude = 0;
                            object.longitude = 0;
                            object.linproId = "";
                            object.posId = "";
                            object.storeIdForEmailSubject = "";
                            object.storeIdForSms = "";
                            object.blogUrl = "";
                            object.published = false;
                            object.prefecture = "";
                            object.allowShipFromStore = false;
                            object.orliId = "";
                            object.y2Id = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.externalCode != null && message.hasOwnProperty("externalCode"))
                            object.externalCode = message.externalCode;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            object.businessHours = message.businessHours;
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            object.phone = message.phone;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.form != null && message.hasOwnProperty("form"))
                            object.form = options.enums === String ? $root.theplant.ec.service.stores.StoreForm[message.form] : message.form;
                        if (message.isTaxExempt != null && message.hasOwnProperty("isTaxExempt"))
                            object.isTaxExempt = message.isTaxExempt;
                        if (message.isPointsUsable != null && message.hasOwnProperty("isPointsUsable"))
                            object.isPointsUsable = message.isPointsUsable;
                        if (message.address != null && message.hasOwnProperty("address"))
                            object.address = message.address;
                        if (message.city != null && message.hasOwnProperty("city"))
                            object.city = message.city;
                        if (message.region != null && message.hasOwnProperty("region"))
                            object.region = message.region;
                        if (message.country != null && message.hasOwnProperty("country"))
                            object.country = message.country;
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            object.zip = message.zip;
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                        if (message.linproId != null && message.hasOwnProperty("linproId"))
                            object.linproId = message.linproId;
                        if (message.posId != null && message.hasOwnProperty("posId"))
                            object.posId = message.posId;
                        if (message.storeIdForEmailSubject != null && message.hasOwnProperty("storeIdForEmailSubject"))
                            object.storeIdForEmailSubject = message.storeIdForEmailSubject;
                        if (message.storeIdForSms != null && message.hasOwnProperty("storeIdForSms"))
                            object.storeIdForSms = message.storeIdForSms;
                        if (message.blogUrl != null && message.hasOwnProperty("blogUrl"))
                            object.blogUrl = message.blogUrl;
                        if (message.published != null && message.hasOwnProperty("published"))
                            object.published = message.published;
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            object.prefecture = message.prefecture;
                        if (message.allowShipFromStore != null && message.hasOwnProperty("allowShipFromStore"))
                            object.allowShipFromStore = message.allowShipFromStore;
                        if (message.orliId != null && message.hasOwnProperty("orliId"))
                            object.orliId = message.orliId;
                        if (message.y2Id != null && message.hasOwnProperty("y2Id"))
                            object.y2Id = message.y2Id;
                        return object;
                    };

                    /**
                     * Converts this Store to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.stores.Store
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Store.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Store;
                })();

                /**
                 * StorePrimeAttribute enum.
                 * @name theplant.ec.service.stores.StorePrimeAttribute
                 * @enum {string}
                 * @property {number} ID=0 ID value
                 * @property {number} EMAIL_SUBJECT_ID=1 EMAIL_SUBJECT_ID value
                 * @property {number} SMS_ID=2 SMS_ID value
                 * @property {number} LINPRO_ID=4 LINPRO_ID value
                 * @property {number} ORLI_ID=5 ORLI_ID value
                 */
                stores.StorePrimeAttribute = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ID"] = 0;
                    values[valuesById[1] = "EMAIL_SUBJECT_ID"] = 1;
                    values[valuesById[2] = "SMS_ID"] = 2;
                    values[valuesById[4] = "LINPRO_ID"] = 4;
                    values[valuesById[5] = "ORLI_ID"] = 5;
                    return values;
                })();

                /**
                 * StoreAttribute enum.
                 * @name theplant.ec.service.stores.StoreAttribute
                 * @enum {string}
                 * @property {number} FORM=0 FORM value
                 */
                stores.StoreAttribute = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "FORM"] = 0;
                    return values;
                })();

                /**
                 * StoreForm enum.
                 * @name theplant.ec.service.stores.StoreForm
                 * @enum {string}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} DEPARTMENT=1 DEPARTMENT value
                 * @property {number} OUTLET=2 OUTLET value
                 * @property {number} BOUTIQUE=3 BOUTIQUE value
                 */
                stores.StoreForm = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "DEPARTMENT"] = 1;
                    values[valuesById[2] = "OUTLET"] = 2;
                    values[valuesById[3] = "BOUTIQUE"] = 3;
                    return values;
                })();

                stores.UpdateMyStore = (function() {

                    /**
                     * Properties of an UpdateMyStore.
                     * @memberof theplant.ec.service.stores
                     * @interface IUpdateMyStore
                     * @property {string|null} [userId] UpdateMyStore userId
                     * @property {string|null} [storeId] UpdateMyStore storeId
                     */

                    /**
                     * Constructs a new UpdateMyStore.
                     * @memberof theplant.ec.service.stores
                     * @classdesc Represents an UpdateMyStore.
                     * @implements IUpdateMyStore
                     * @constructor
                     * @param {theplant.ec.service.stores.IUpdateMyStore=} [properties] Properties to set
                     */
                    function UpdateMyStore(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UpdateMyStore userId.
                     * @member {string} userId
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @instance
                     */
                    UpdateMyStore.prototype.userId = "";

                    /**
                     * UpdateMyStore storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @instance
                     */
                    UpdateMyStore.prototype.storeId = "";

                    /**
                     * Creates a new UpdateMyStore instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {theplant.ec.service.stores.IUpdateMyStore=} [properties] Properties to set
                     * @returns {theplant.ec.service.stores.UpdateMyStore} UpdateMyStore instance
                     */
                    UpdateMyStore.create = function create(properties) {
                        return new UpdateMyStore(properties);
                    };

                    /**
                     * Encodes the specified UpdateMyStore message. Does not implicitly {@link theplant.ec.service.stores.UpdateMyStore.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {theplant.ec.service.stores.IUpdateMyStore} message UpdateMyStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateMyStore.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userId != null && message.hasOwnProperty("userId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.storeId);
                        return writer;
                    };

                    /**
                     * Encodes the specified UpdateMyStore message, length delimited. Does not implicitly {@link theplant.ec.service.stores.UpdateMyStore.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {theplant.ec.service.stores.IUpdateMyStore} message UpdateMyStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UpdateMyStore.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an UpdateMyStore message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.stores.UpdateMyStore} UpdateMyStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateMyStore.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.stores.UpdateMyStore();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.userId = reader.string();
                                break;
                            case 2:
                                message.storeId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an UpdateMyStore message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.stores.UpdateMyStore} UpdateMyStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UpdateMyStore.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an UpdateMyStore message.
                     * @function verify
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UpdateMyStore.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userId != null && message.hasOwnProperty("userId"))
                            if (!$util.isString(message.userId))
                                return "userId: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        return null;
                    };

                    /**
                     * Creates an UpdateMyStore message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.stores.UpdateMyStore} UpdateMyStore
                     */
                    UpdateMyStore.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.stores.UpdateMyStore)
                            return object;
                        var message = new $root.theplant.ec.service.stores.UpdateMyStore();
                        if (object.userId != null)
                            message.userId = String(object.userId);
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        return message;
                    };

                    /**
                     * Creates a plain object from an UpdateMyStore message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @static
                     * @param {theplant.ec.service.stores.UpdateMyStore} message UpdateMyStore
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UpdateMyStore.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.userId = "";
                            object.storeId = "";
                        }
                        if (message.userId != null && message.hasOwnProperty("userId"))
                            object.userId = message.userId;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        return object;
                    };

                    /**
                     * Converts this UpdateMyStore to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.stores.UpdateMyStore
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UpdateMyStore.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UpdateMyStore;
                })();

                stores.Prefecture = (function() {

                    /**
                     * Properties of a Prefecture.
                     * @memberof theplant.ec.service.stores
                     * @interface IPrefecture
                     * @property {string|null} [name] Prefecture name
                     */

                    /**
                     * Constructs a new Prefecture.
                     * @memberof theplant.ec.service.stores
                     * @classdesc Represents a Prefecture.
                     * @implements IPrefecture
                     * @constructor
                     * @param {theplant.ec.service.stores.IPrefecture=} [properties] Properties to set
                     */
                    function Prefecture(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Prefecture name.
                     * @member {string} name
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @instance
                     */
                    Prefecture.prototype.name = "";

                    /**
                     * Creates a new Prefecture instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {theplant.ec.service.stores.IPrefecture=} [properties] Properties to set
                     * @returns {theplant.ec.service.stores.Prefecture} Prefecture instance
                     */
                    Prefecture.create = function create(properties) {
                        return new Prefecture(properties);
                    };

                    /**
                     * Encodes the specified Prefecture message. Does not implicitly {@link theplant.ec.service.stores.Prefecture.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {theplant.ec.service.stores.IPrefecture} message Prefecture message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Prefecture.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        return writer;
                    };

                    /**
                     * Encodes the specified Prefecture message, length delimited. Does not implicitly {@link theplant.ec.service.stores.Prefecture.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {theplant.ec.service.stores.IPrefecture} message Prefecture message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Prefecture.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Prefecture message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.stores.Prefecture} Prefecture
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Prefecture.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.stores.Prefecture();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Prefecture message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.stores.Prefecture} Prefecture
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Prefecture.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Prefecture message.
                     * @function verify
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Prefecture.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates a Prefecture message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.stores.Prefecture} Prefecture
                     */
                    Prefecture.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.stores.Prefecture)
                            return object;
                        var message = new $root.theplant.ec.service.stores.Prefecture();
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Prefecture message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @static
                     * @param {theplant.ec.service.stores.Prefecture} message Prefecture
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Prefecture.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this Prefecture to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.stores.Prefecture
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Prefecture.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Prefecture;
                })();

                stores.StoreInfoWithStock = (function() {

                    /**
                     * Properties of a StoreInfoWithStock.
                     * @memberof theplant.ec.service.stores
                     * @interface IStoreInfoWithStock
                     * @property {string|null} [storeId] StoreInfoWithStock storeId
                     * @property {string|null} [name] StoreInfoWithStock name
                     * @property {string|null} [phone] StoreInfoWithStock phone
                     * @property {string|null} [email] StoreInfoWithStock email
                     * @property {string|null} [businessHours] StoreInfoWithStock businessHours
                     * @property {string|null} [address] StoreInfoWithStock address
                     * @property {string|null} [city] StoreInfoWithStock city
                     * @property {string|null} [region] StoreInfoWithStock region
                     * @property {string|null} [country] StoreInfoWithStock country
                     * @property {string|null} [zip] StoreInfoWithStock zip
                     * @property {number|null} [latitude] StoreInfoWithStock latitude
                     * @property {number|null} [longitude] StoreInfoWithStock longitude
                     * @property {string|null} [prefecture] StoreInfoWithStock prefecture
                     * @property {string|null} [articleCode] StoreInfoWithStock articleCode
                     * @property {number|Long|null} [stock] StoreInfoWithStock stock
                     * @property {number|Long|null} [position] StoreInfoWithStock position
                     */

                    /**
                     * Constructs a new StoreInfoWithStock.
                     * @memberof theplant.ec.service.stores
                     * @classdesc Represents a StoreInfoWithStock.
                     * @implements IStoreInfoWithStock
                     * @constructor
                     * @param {theplant.ec.service.stores.IStoreInfoWithStock=} [properties] Properties to set
                     */
                    function StoreInfoWithStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreInfoWithStock storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.storeId = "";

                    /**
                     * StoreInfoWithStock name.
                     * @member {string} name
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.name = "";

                    /**
                     * StoreInfoWithStock phone.
                     * @member {string} phone
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.phone = "";

                    /**
                     * StoreInfoWithStock email.
                     * @member {string} email
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.email = "";

                    /**
                     * StoreInfoWithStock businessHours.
                     * @member {string} businessHours
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.businessHours = "";

                    /**
                     * StoreInfoWithStock address.
                     * @member {string} address
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.address = "";

                    /**
                     * StoreInfoWithStock city.
                     * @member {string} city
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.city = "";

                    /**
                     * StoreInfoWithStock region.
                     * @member {string} region
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.region = "";

                    /**
                     * StoreInfoWithStock country.
                     * @member {string} country
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.country = "";

                    /**
                     * StoreInfoWithStock zip.
                     * @member {string} zip
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.zip = "";

                    /**
                     * StoreInfoWithStock latitude.
                     * @member {number} latitude
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.latitude = 0;

                    /**
                     * StoreInfoWithStock longitude.
                     * @member {number} longitude
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.longitude = 0;

                    /**
                     * StoreInfoWithStock prefecture.
                     * @member {string} prefecture
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.prefecture = "";

                    /**
                     * StoreInfoWithStock articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.articleCode = "";

                    /**
                     * StoreInfoWithStock stock.
                     * @member {number|Long} stock
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.stock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StoreInfoWithStock position.
                     * @member {number|Long} position
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     */
                    StoreInfoWithStock.prototype.position = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new StoreInfoWithStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.service.stores.IStoreInfoWithStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.stores.StoreInfoWithStock} StoreInfoWithStock instance
                     */
                    StoreInfoWithStock.create = function create(properties) {
                        return new StoreInfoWithStock(properties);
                    };

                    /**
                     * Encodes the specified StoreInfoWithStock message. Does not implicitly {@link theplant.ec.service.stores.StoreInfoWithStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.service.stores.IStoreInfoWithStock} message StoreInfoWithStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfoWithStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.storeId);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.phone);
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.businessHours);
                        if (message.address != null && message.hasOwnProperty("address"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.address);
                        if (message.city != null && message.hasOwnProperty("city"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.city);
                        if (message.region != null && message.hasOwnProperty("region"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.region);
                        if (message.country != null && message.hasOwnProperty("country"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.country);
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.zip);
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            writer.uint32(/* id 11, wireType 1 =*/89).double(message.latitude);
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            writer.uint32(/* id 12, wireType 1 =*/97).double(message.longitude);
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.prefecture);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.articleCode);
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int64(message.stock);
                        if (message.position != null && message.hasOwnProperty("position"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int64(message.position);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreInfoWithStock message, length delimited. Does not implicitly {@link theplant.ec.service.stores.StoreInfoWithStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.service.stores.IStoreInfoWithStock} message StoreInfoWithStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfoWithStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreInfoWithStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.stores.StoreInfoWithStock} StoreInfoWithStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfoWithStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.stores.StoreInfoWithStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.storeId = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.phone = reader.string();
                                break;
                            case 4:
                                message.email = reader.string();
                                break;
                            case 5:
                                message.businessHours = reader.string();
                                break;
                            case 6:
                                message.address = reader.string();
                                break;
                            case 7:
                                message.city = reader.string();
                                break;
                            case 8:
                                message.region = reader.string();
                                break;
                            case 9:
                                message.country = reader.string();
                                break;
                            case 10:
                                message.zip = reader.string();
                                break;
                            case 11:
                                message.latitude = reader.double();
                                break;
                            case 12:
                                message.longitude = reader.double();
                                break;
                            case 13:
                                message.prefecture = reader.string();
                                break;
                            case 14:
                                message.articleCode = reader.string();
                                break;
                            case 15:
                                message.stock = reader.int64();
                                break;
                            case 16:
                                message.position = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreInfoWithStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.stores.StoreInfoWithStock} StoreInfoWithStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfoWithStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreInfoWithStock message.
                     * @function verify
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreInfoWithStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            if (!$util.isString(message.phone))
                                return "phone: string expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            if (!$util.isString(message.businessHours))
                                return "businessHours: string expected";
                        if (message.address != null && message.hasOwnProperty("address"))
                            if (!$util.isString(message.address))
                                return "address: string expected";
                        if (message.city != null && message.hasOwnProperty("city"))
                            if (!$util.isString(message.city))
                                return "city: string expected";
                        if (message.region != null && message.hasOwnProperty("region"))
                            if (!$util.isString(message.region))
                                return "region: string expected";
                        if (message.country != null && message.hasOwnProperty("country"))
                            if (!$util.isString(message.country))
                                return "country: string expected";
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            if (!$util.isString(message.zip))
                                return "zip: string expected";
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            if (typeof message.latitude !== "number")
                                return "latitude: number expected";
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            if (typeof message.longitude !== "number")
                                return "longitude: number expected";
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            if (!$util.isString(message.prefecture))
                                return "prefecture: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (!$util.isInteger(message.stock) && !(message.stock && $util.isInteger(message.stock.low) && $util.isInteger(message.stock.high)))
                                return "stock: integer|Long expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isInteger(message.position) && !(message.position && $util.isInteger(message.position.low) && $util.isInteger(message.position.high)))
                                return "position: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a StoreInfoWithStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.stores.StoreInfoWithStock} StoreInfoWithStock
                     */
                    StoreInfoWithStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.stores.StoreInfoWithStock)
                            return object;
                        var message = new $root.theplant.ec.service.stores.StoreInfoWithStock();
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.phone != null)
                            message.phone = String(object.phone);
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.businessHours != null)
                            message.businessHours = String(object.businessHours);
                        if (object.address != null)
                            message.address = String(object.address);
                        if (object.city != null)
                            message.city = String(object.city);
                        if (object.region != null)
                            message.region = String(object.region);
                        if (object.country != null)
                            message.country = String(object.country);
                        if (object.zip != null)
                            message.zip = String(object.zip);
                        if (object.latitude != null)
                            message.latitude = Number(object.latitude);
                        if (object.longitude != null)
                            message.longitude = Number(object.longitude);
                        if (object.prefecture != null)
                            message.prefecture = String(object.prefecture);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.stock != null)
                            if ($util.Long)
                                (message.stock = $util.Long.fromValue(object.stock)).unsigned = false;
                            else if (typeof object.stock === "string")
                                message.stock = parseInt(object.stock, 10);
                            else if (typeof object.stock === "number")
                                message.stock = object.stock;
                            else if (typeof object.stock === "object")
                                message.stock = new $util.LongBits(object.stock.low >>> 0, object.stock.high >>> 0).toNumber();
                        if (object.position != null)
                            if ($util.Long)
                                (message.position = $util.Long.fromValue(object.position)).unsigned = false;
                            else if (typeof object.position === "string")
                                message.position = parseInt(object.position, 10);
                            else if (typeof object.position === "number")
                                message.position = object.position;
                            else if (typeof object.position === "object")
                                message.position = new $util.LongBits(object.position.low >>> 0, object.position.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreInfoWithStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @static
                     * @param {theplant.ec.service.stores.StoreInfoWithStock} message StoreInfoWithStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreInfoWithStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.storeId = "";
                            object.name = "";
                            object.phone = "";
                            object.email = "";
                            object.businessHours = "";
                            object.address = "";
                            object.city = "";
                            object.region = "";
                            object.country = "";
                            object.zip = "";
                            object.latitude = 0;
                            object.longitude = 0;
                            object.prefecture = "";
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.stock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.stock = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.position = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.position = options.longs === String ? "0" : 0;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            object.phone = message.phone;
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            object.businessHours = message.businessHours;
                        if (message.address != null && message.hasOwnProperty("address"))
                            object.address = message.address;
                        if (message.city != null && message.hasOwnProperty("city"))
                            object.city = message.city;
                        if (message.region != null && message.hasOwnProperty("region"))
                            object.region = message.region;
                        if (message.country != null && message.hasOwnProperty("country"))
                            object.country = message.country;
                        if (message.zip != null && message.hasOwnProperty("zip"))
                            object.zip = message.zip;
                        if (message.latitude != null && message.hasOwnProperty("latitude"))
                            object.latitude = options.json && !isFinite(message.latitude) ? String(message.latitude) : message.latitude;
                        if (message.longitude != null && message.hasOwnProperty("longitude"))
                            object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            object.prefecture = message.prefecture;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (typeof message.stock === "number")
                                object.stock = options.longs === String ? String(message.stock) : message.stock;
                            else
                                object.stock = options.longs === String ? $util.Long.prototype.toString.call(message.stock) : options.longs === Number ? new $util.LongBits(message.stock.low >>> 0, message.stock.high >>> 0).toNumber() : message.stock;
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (typeof message.position === "number")
                                object.position = options.longs === String ? String(message.position) : message.position;
                            else
                                object.position = options.longs === String ? $util.Long.prototype.toString.call(message.position) : options.longs === Number ? new $util.LongBits(message.position.low >>> 0, message.position.high >>> 0).toNumber() : message.position;
                        return object;
                    };

                    /**
                     * Converts this StoreInfoWithStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.stores.StoreInfoWithStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreInfoWithStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreInfoWithStock;
                })();

                return stores;
            })();

            service.orders = (function() {

                /**
                 * Namespace orders.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var orders = {};

                orders.Cart = (function() {

                    /**
                     * Properties of a Cart.
                     * @memberof theplant.ec.service.orders
                     * @interface ICart
                     * @property {Array.<theplant.ec.service.orders.ICartItem>|null} [cartItems] Cart cartItems
                     * @property {string|null} [couponCode] Cart couponCode
                     */

                    /**
                     * Constructs a new Cart.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a Cart.
                     * @implements ICart
                     * @constructor
                     * @param {theplant.ec.service.orders.ICart=} [properties] Properties to set
                     */
                    function Cart(properties) {
                        this.cartItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Cart cartItems.
                     * @member {Array.<theplant.ec.service.orders.ICartItem>} cartItems
                     * @memberof theplant.ec.service.orders.Cart
                     * @instance
                     */
                    Cart.prototype.cartItems = $util.emptyArray;

                    /**
                     * Cart couponCode.
                     * @member {string} couponCode
                     * @memberof theplant.ec.service.orders.Cart
                     * @instance
                     */
                    Cart.prototype.couponCode = "";

                    /**
                     * Creates a new Cart instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {theplant.ec.service.orders.ICart=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.Cart} Cart instance
                     */
                    Cart.create = function create(properties) {
                        return new Cart(properties);
                    };

                    /**
                     * Encodes the specified Cart message. Does not implicitly {@link theplant.ec.service.orders.Cart.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {theplant.ec.service.orders.ICart} message Cart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Cart.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cartItems != null && message.cartItems.length)
                            for (var i = 0; i < message.cartItems.length; ++i)
                                $root.theplant.ec.service.orders.CartItem.encode(message.cartItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.couponCode);
                        return writer;
                    };

                    /**
                     * Encodes the specified Cart message, length delimited. Does not implicitly {@link theplant.ec.service.orders.Cart.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {theplant.ec.service.orders.ICart} message Cart message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Cart.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Cart message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.Cart} Cart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Cart.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.Cart();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                if (!(message.cartItems && message.cartItems.length))
                                    message.cartItems = [];
                                message.cartItems.push($root.theplant.ec.service.orders.CartItem.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.couponCode = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Cart message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.Cart} Cart
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Cart.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Cart message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Cart.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cartItems != null && message.hasOwnProperty("cartItems")) {
                            if (!Array.isArray(message.cartItems))
                                return "cartItems: array expected";
                            for (var i = 0; i < message.cartItems.length; ++i) {
                                var error = $root.theplant.ec.service.orders.CartItem.verify(message.cartItems[i]);
                                if (error)
                                    return "cartItems." + error;
                            }
                        }
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            if (!$util.isString(message.couponCode))
                                return "couponCode: string expected";
                        return null;
                    };

                    /**
                     * Creates a Cart message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.Cart} Cart
                     */
                    Cart.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.Cart)
                            return object;
                        var message = new $root.theplant.ec.service.orders.Cart();
                        if (object.cartItems) {
                            if (!Array.isArray(object.cartItems))
                                throw TypeError(".theplant.ec.service.orders.Cart.cartItems: array expected");
                            message.cartItems = [];
                            for (var i = 0; i < object.cartItems.length; ++i) {
                                if (typeof object.cartItems[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.Cart.cartItems: object expected");
                                message.cartItems[i] = $root.theplant.ec.service.orders.CartItem.fromObject(object.cartItems[i]);
                            }
                        }
                        if (object.couponCode != null)
                            message.couponCode = String(object.couponCode);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Cart message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.Cart
                     * @static
                     * @param {theplant.ec.service.orders.Cart} message Cart
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Cart.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cartItems = [];
                        if (options.defaults)
                            object.couponCode = "";
                        if (message.cartItems && message.cartItems.length) {
                            object.cartItems = [];
                            for (var j = 0; j < message.cartItems.length; ++j)
                                object.cartItems[j] = $root.theplant.ec.service.orders.CartItem.toObject(message.cartItems[j], options);
                        }
                        if (message.couponCode != null && message.hasOwnProperty("couponCode"))
                            object.couponCode = message.couponCode;
                        return object;
                    };

                    /**
                     * Converts this Cart to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.Cart
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Cart.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Cart;
                })();

                orders.CartItem = (function() {

                    /**
                     * Properties of a CartItem.
                     * @memberof theplant.ec.service.orders
                     * @interface ICartItem
                     * @property {string|null} [articleCode] CartItem articleCode
                     * @property {number|Long|null} [quantity] CartItem quantity
                     * @property {number|Long|null} [quantityInStock] CartItem quantityInStock
                     * @property {string|null} [productCode] CartItem productCode
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [properties] CartItem properties
                     * @property {string|null} [dimensionAfterHemming] CartItem dimensionAfterHemming
                     * @property {boolean|null} [isShipFromStore] CartItem isShipFromStore
                     * @property {theplant.ec.service.customization.ICustomization|null} [customization] CartItem customization
                     */

                    /**
                     * Constructs a new CartItem.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a CartItem.
                     * @implements ICartItem
                     * @constructor
                     * @param {theplant.ec.service.orders.ICartItem=} [properties] Properties to set
                     */
                    function CartItem(properties) {
                        this.properties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CartItem articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.articleCode = "";

                    /**
                     * CartItem quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CartItem quantityInStock.
                     * @member {number|Long} quantityInStock
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.quantityInStock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CartItem productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.productCode = "";

                    /**
                     * CartItem properties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} properties
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.properties = $util.emptyArray;

                    /**
                     * CartItem dimensionAfterHemming.
                     * @member {string} dimensionAfterHemming
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.dimensionAfterHemming = "";

                    /**
                     * CartItem isShipFromStore.
                     * @member {boolean} isShipFromStore
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.isShipFromStore = false;

                    /**
                     * CartItem customization.
                     * @member {theplant.ec.service.customization.ICustomization|null|undefined} customization
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     */
                    CartItem.prototype.customization = null;

                    /**
                     * Creates a new CartItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {theplant.ec.service.orders.ICartItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.CartItem} CartItem instance
                     */
                    CartItem.create = function create(properties) {
                        return new CartItem(properties);
                    };

                    /**
                     * Encodes the specified CartItem message. Does not implicitly {@link theplant.ec.service.orders.CartItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {theplant.ec.service.orders.ICartItem} message CartItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CartItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.quantity);
                        if (message.quantityInStock != null && message.hasOwnProperty("quantityInStock"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.quantityInStock);
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.productCode);
                        if (message.properties != null && message.properties.length)
                            for (var i = 0; i < message.properties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.properties[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.dimensionAfterHemming);
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isShipFromStore);
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            $root.theplant.ec.service.customization.Customization.encode(message.customization, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CartItem message, length delimited. Does not implicitly {@link theplant.ec.service.orders.CartItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {theplant.ec.service.orders.ICartItem} message CartItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CartItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CartItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.CartItem} CartItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CartItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.CartItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.quantity = reader.int64();
                                break;
                            case 3:
                                message.quantityInStock = reader.int64();
                                break;
                            case 4:
                                message.productCode = reader.string();
                                break;
                            case 5:
                                if (!(message.properties && message.properties.length))
                                    message.properties = [];
                                message.properties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 6:
                                message.dimensionAfterHemming = reader.string();
                                break;
                            case 7:
                                message.isShipFromStore = reader.bool();
                                break;
                            case 8:
                                message.customization = $root.theplant.ec.service.customization.Customization.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CartItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.CartItem} CartItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CartItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CartItem message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CartItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.quantityInStock != null && message.hasOwnProperty("quantityInStock"))
                            if (!$util.isInteger(message.quantityInStock) && !(message.quantityInStock && $util.isInteger(message.quantityInStock.low) && $util.isInteger(message.quantityInStock.high)))
                                return "quantityInStock: integer|Long expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.properties != null && message.hasOwnProperty("properties")) {
                            if (!Array.isArray(message.properties))
                                return "properties: array expected";
                            for (var i = 0; i < message.properties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.properties[i]);
                                if (error)
                                    return "properties." + error;
                            }
                        }
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            if (!$util.isString(message.dimensionAfterHemming))
                                return "dimensionAfterHemming: string expected";
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            if (typeof message.isShipFromStore !== "boolean")
                                return "isShipFromStore: boolean expected";
                        if (message.customization != null && message.hasOwnProperty("customization")) {
                            var error = $root.theplant.ec.service.customization.Customization.verify(message.customization);
                            if (error)
                                return "customization." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CartItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.CartItem} CartItem
                     */
                    CartItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.CartItem)
                            return object;
                        var message = new $root.theplant.ec.service.orders.CartItem();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        if (object.quantityInStock != null)
                            if ($util.Long)
                                (message.quantityInStock = $util.Long.fromValue(object.quantityInStock)).unsigned = false;
                            else if (typeof object.quantityInStock === "string")
                                message.quantityInStock = parseInt(object.quantityInStock, 10);
                            else if (typeof object.quantityInStock === "number")
                                message.quantityInStock = object.quantityInStock;
                            else if (typeof object.quantityInStock === "object")
                                message.quantityInStock = new $util.LongBits(object.quantityInStock.low >>> 0, object.quantityInStock.high >>> 0).toNumber();
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.properties) {
                            if (!Array.isArray(object.properties))
                                throw TypeError(".theplant.ec.service.orders.CartItem.properties: array expected");
                            message.properties = [];
                            for (var i = 0; i < object.properties.length; ++i) {
                                if (typeof object.properties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.CartItem.properties: object expected");
                                message.properties[i] = $root.theplant.ec.service.base.Property.fromObject(object.properties[i]);
                            }
                        }
                        if (object.dimensionAfterHemming != null)
                            message.dimensionAfterHemming = String(object.dimensionAfterHemming);
                        if (object.isShipFromStore != null)
                            message.isShipFromStore = Boolean(object.isShipFromStore);
                        if (object.customization != null) {
                            if (typeof object.customization !== "object")
                                throw TypeError(".theplant.ec.service.orders.CartItem.customization: object expected");
                            message.customization = $root.theplant.ec.service.customization.Customization.fromObject(object.customization);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CartItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.CartItem
                     * @static
                     * @param {theplant.ec.service.orders.CartItem} message CartItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CartItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.properties = [];
                        if (options.defaults) {
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantityInStock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantityInStock = options.longs === String ? "0" : 0;
                            object.productCode = "";
                            object.dimensionAfterHemming = "";
                            object.isShipFromStore = false;
                            object.customization = null;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        if (message.quantityInStock != null && message.hasOwnProperty("quantityInStock"))
                            if (typeof message.quantityInStock === "number")
                                object.quantityInStock = options.longs === String ? String(message.quantityInStock) : message.quantityInStock;
                            else
                                object.quantityInStock = options.longs === String ? $util.Long.prototype.toString.call(message.quantityInStock) : options.longs === Number ? new $util.LongBits(message.quantityInStock.low >>> 0, message.quantityInStock.high >>> 0).toNumber() : message.quantityInStock;
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.properties && message.properties.length) {
                            object.properties = [];
                            for (var j = 0; j < message.properties.length; ++j)
                                object.properties[j] = $root.theplant.ec.service.base.Property.toObject(message.properties[j], options);
                        }
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            object.dimensionAfterHemming = message.dimensionAfterHemming;
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            object.isShipFromStore = message.isShipFromStore;
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            object.customization = $root.theplant.ec.service.customization.Customization.toObject(message.customization, options);
                        return object;
                    };

                    /**
                     * Converts this CartItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.CartItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CartItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CartItem;
                })();

                /**
                 * OrderState enum.
                 * @name theplant.ec.service.orders.OrderState
                 * @enum {string}
                 * @property {number} DRAFT=0 DRAFT value
                 * @property {number} CONFIRMED=1 CONFIRMED value
                 * @property {number} FREEZED=3 FREEZED value
                 * @property {number} READY_FOR_SHIPPING=4 READY_FOR_SHIPPING value
                 * @property {number} SHIPPED=5 SHIPPED value
                 * @property {number} PARTIALLY_SHIPPED=6 PARTIALLY_SHIPPED value
                 * @property {number} CANCELED=7 CANCELED value
                 * @property {number} PARTIALLY_RETURNED=8 PARTIALLY_RETURNED value
                 * @property {number} RETURNED=9 RETURNED value
                 * @property {number} REQUEST_RETURNED=10 REQUEST_RETURNED value
                 * @property {number} WAITING_PAY=11 WAITING_PAY value
                 * @property {number} WAITING_PAY_CANCELED=12 WAITING_PAY_CANCELED value
                 */
                orders.OrderState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "DRAFT"] = 0;
                    values[valuesById[1] = "CONFIRMED"] = 1;
                    values[valuesById[3] = "FREEZED"] = 3;
                    values[valuesById[4] = "READY_FOR_SHIPPING"] = 4;
                    values[valuesById[5] = "SHIPPED"] = 5;
                    values[valuesById[6] = "PARTIALLY_SHIPPED"] = 6;
                    values[valuesById[7] = "CANCELED"] = 7;
                    values[valuesById[8] = "PARTIALLY_RETURNED"] = 8;
                    values[valuesById[9] = "RETURNED"] = 9;
                    values[valuesById[10] = "REQUEST_RETURNED"] = 10;
                    values[valuesById[11] = "WAITING_PAY"] = 11;
                    values[valuesById[12] = "WAITING_PAY_CANCELED"] = 12;
                    return values;
                })();

                orders.OrderMainState = (function() {

                    /**
                     * Properties of an OrderMainState.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderMainState
                     * @property {theplant.ec.service.orders.OrderMainState.INullOrderState|null} [orderState] OrderMainState orderState
                     * @property {theplant.ec.service.orders.OrderMainState.INullShipFromStoreItemState|null} [shipFormStoreItemState] OrderMainState shipFormStoreItemState
                     */

                    /**
                     * Constructs a new OrderMainState.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderMainState.
                     * @implements IOrderMainState
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderMainState=} [properties] Properties to set
                     */
                    function OrderMainState(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderMainState orderState.
                     * @member {theplant.ec.service.orders.OrderMainState.INullOrderState|null|undefined} orderState
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @instance
                     */
                    OrderMainState.prototype.orderState = null;

                    /**
                     * OrderMainState shipFormStoreItemState.
                     * @member {theplant.ec.service.orders.OrderMainState.INullShipFromStoreItemState|null|undefined} shipFormStoreItemState
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @instance
                     */
                    OrderMainState.prototype.shipFormStoreItemState = null;

                    /**
                     * Creates a new OrderMainState instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {theplant.ec.service.orders.IOrderMainState=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderMainState} OrderMainState instance
                     */
                    OrderMainState.create = function create(properties) {
                        return new OrderMainState(properties);
                    };

                    /**
                     * Encodes the specified OrderMainState message. Does not implicitly {@link theplant.ec.service.orders.OrderMainState.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {theplant.ec.service.orders.IOrderMainState} message OrderMainState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderMainState.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderState != null && message.hasOwnProperty("orderState"))
                            $root.theplant.ec.service.orders.OrderMainState.NullOrderState.encode(message.orderState, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.shipFormStoreItemState != null && message.hasOwnProperty("shipFormStoreItemState"))
                            $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.encode(message.shipFormStoreItemState, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderMainState message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderMainState.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {theplant.ec.service.orders.IOrderMainState} message OrderMainState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderMainState.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderMainState message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderMainState} OrderMainState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderMainState.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderMainState();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderState = $root.theplant.ec.service.orders.OrderMainState.NullOrderState.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.shipFormStoreItemState = $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderMainState message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderMainState} OrderMainState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderMainState.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderMainState message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderMainState.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderState != null && message.hasOwnProperty("orderState")) {
                            var error = $root.theplant.ec.service.orders.OrderMainState.NullOrderState.verify(message.orderState);
                            if (error)
                                return "orderState." + error;
                        }
                        if (message.shipFormStoreItemState != null && message.hasOwnProperty("shipFormStoreItemState")) {
                            var error = $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.verify(message.shipFormStoreItemState);
                            if (error)
                                return "shipFormStoreItemState." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderMainState message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderMainState} OrderMainState
                     */
                    OrderMainState.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderMainState)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderMainState();
                        if (object.orderState != null) {
                            if (typeof object.orderState !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderMainState.orderState: object expected");
                            message.orderState = $root.theplant.ec.service.orders.OrderMainState.NullOrderState.fromObject(object.orderState);
                        }
                        if (object.shipFormStoreItemState != null) {
                            if (typeof object.shipFormStoreItemState !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderMainState.shipFormStoreItemState: object expected");
                            message.shipFormStoreItemState = $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.fromObject(object.shipFormStoreItemState);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderMainState message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @static
                     * @param {theplant.ec.service.orders.OrderMainState} message OrderMainState
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderMainState.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderState = null;
                            object.shipFormStoreItemState = null;
                        }
                        if (message.orderState != null && message.hasOwnProperty("orderState"))
                            object.orderState = $root.theplant.ec.service.orders.OrderMainState.NullOrderState.toObject(message.orderState, options);
                        if (message.shipFormStoreItemState != null && message.hasOwnProperty("shipFormStoreItemState"))
                            object.shipFormStoreItemState = $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.toObject(message.shipFormStoreItemState, options);
                        return object;
                    };

                    /**
                     * Converts this OrderMainState to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderMainState
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderMainState.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    OrderMainState.NullOrderState = (function() {

                        /**
                         * Properties of a NullOrderState.
                         * @memberof theplant.ec.service.orders.OrderMainState
                         * @interface INullOrderState
                         * @property {boolean|null} [valid] NullOrderState valid
                         * @property {theplant.ec.service.orders.OrderState|null} [state] NullOrderState state
                         */

                        /**
                         * Constructs a new NullOrderState.
                         * @memberof theplant.ec.service.orders.OrderMainState
                         * @classdesc Represents a NullOrderState.
                         * @implements INullOrderState
                         * @constructor
                         * @param {theplant.ec.service.orders.OrderMainState.INullOrderState=} [properties] Properties to set
                         */
                        function NullOrderState(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NullOrderState valid.
                         * @member {boolean} valid
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @instance
                         */
                        NullOrderState.prototype.valid = false;

                        /**
                         * NullOrderState state.
                         * @member {theplant.ec.service.orders.OrderState} state
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @instance
                         */
                        NullOrderState.prototype.state = 0;

                        /**
                         * Creates a new NullOrderState instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.INullOrderState=} [properties] Properties to set
                         * @returns {theplant.ec.service.orders.OrderMainState.NullOrderState} NullOrderState instance
                         */
                        NullOrderState.create = function create(properties) {
                            return new NullOrderState(properties);
                        };

                        /**
                         * Encodes the specified NullOrderState message. Does not implicitly {@link theplant.ec.service.orders.OrderMainState.NullOrderState.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.INullOrderState} message NullOrderState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NullOrderState.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.valid != null && message.hasOwnProperty("valid"))
                                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.valid);
                            if (message.state != null && message.hasOwnProperty("state"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
                            return writer;
                        };

                        /**
                         * Encodes the specified NullOrderState message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderMainState.NullOrderState.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.INullOrderState} message NullOrderState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NullOrderState.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NullOrderState message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.orders.OrderMainState.NullOrderState} NullOrderState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NullOrderState.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderMainState.NullOrderState();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.valid = reader.bool();
                                    break;
                                case 2:
                                    message.state = reader.int32();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NullOrderState message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.orders.OrderMainState.NullOrderState} NullOrderState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NullOrderState.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NullOrderState message.
                         * @function verify
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NullOrderState.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.valid != null && message.hasOwnProperty("valid"))
                                if (typeof message.valid !== "boolean")
                                    return "valid: boolean expected";
                            if (message.state != null && message.hasOwnProperty("state"))
                                switch (message.state) {
                                default:
                                    return "state: enum value expected";
                                case 0:
                                case 1:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                case 9:
                                case 10:
                                case 11:
                                case 12:
                                    break;
                                }
                            return null;
                        };

                        /**
                         * Creates a NullOrderState message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.orders.OrderMainState.NullOrderState} NullOrderState
                         */
                        NullOrderState.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.orders.OrderMainState.NullOrderState)
                                return object;
                            var message = new $root.theplant.ec.service.orders.OrderMainState.NullOrderState();
                            if (object.valid != null)
                                message.valid = Boolean(object.valid);
                            switch (object.state) {
                            case "DRAFT":
                            case 0:
                                message.state = 0;
                                break;
                            case "CONFIRMED":
                            case 1:
                                message.state = 1;
                                break;
                            case "FREEZED":
                            case 3:
                                message.state = 3;
                                break;
                            case "READY_FOR_SHIPPING":
                            case 4:
                                message.state = 4;
                                break;
                            case "SHIPPED":
                            case 5:
                                message.state = 5;
                                break;
                            case "PARTIALLY_SHIPPED":
                            case 6:
                                message.state = 6;
                                break;
                            case "CANCELED":
                            case 7:
                                message.state = 7;
                                break;
                            case "PARTIALLY_RETURNED":
                            case 8:
                                message.state = 8;
                                break;
                            case "RETURNED":
                            case 9:
                                message.state = 9;
                                break;
                            case "REQUEST_RETURNED":
                            case 10:
                                message.state = 10;
                                break;
                            case "WAITING_PAY":
                            case 11:
                                message.state = 11;
                                break;
                            case "WAITING_PAY_CANCELED":
                            case 12:
                                message.state = 12;
                                break;
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a NullOrderState message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.NullOrderState} message NullOrderState
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NullOrderState.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.valid = false;
                                object.state = options.enums === String ? "DRAFT" : 0;
                            }
                            if (message.valid != null && message.hasOwnProperty("valid"))
                                object.valid = message.valid;
                            if (message.state != null && message.hasOwnProperty("state"))
                                object.state = options.enums === String ? $root.theplant.ec.service.orders.OrderState[message.state] : message.state;
                            return object;
                        };

                        /**
                         * Converts this NullOrderState to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.orders.OrderMainState.NullOrderState
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NullOrderState.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NullOrderState;
                    })();

                    OrderMainState.NullShipFromStoreItemState = (function() {

                        /**
                         * Properties of a NullShipFromStoreItemState.
                         * @memberof theplant.ec.service.orders.OrderMainState
                         * @interface INullShipFromStoreItemState
                         * @property {boolean|null} [valid] NullShipFromStoreItemState valid
                         * @property {string|null} [state] NullShipFromStoreItemState state
                         */

                        /**
                         * Constructs a new NullShipFromStoreItemState.
                         * @memberof theplant.ec.service.orders.OrderMainState
                         * @classdesc Represents a NullShipFromStoreItemState.
                         * @implements INullShipFromStoreItemState
                         * @constructor
                         * @param {theplant.ec.service.orders.OrderMainState.INullShipFromStoreItemState=} [properties] Properties to set
                         */
                        function NullShipFromStoreItemState(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * NullShipFromStoreItemState valid.
                         * @member {boolean} valid
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @instance
                         */
                        NullShipFromStoreItemState.prototype.valid = false;

                        /**
                         * NullShipFromStoreItemState state.
                         * @member {string} state
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @instance
                         */
                        NullShipFromStoreItemState.prototype.state = "";

                        /**
                         * Creates a new NullShipFromStoreItemState instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.INullShipFromStoreItemState=} [properties] Properties to set
                         * @returns {theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState} NullShipFromStoreItemState instance
                         */
                        NullShipFromStoreItemState.create = function create(properties) {
                            return new NullShipFromStoreItemState(properties);
                        };

                        /**
                         * Encodes the specified NullShipFromStoreItemState message. Does not implicitly {@link theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.INullShipFromStoreItemState} message NullShipFromStoreItemState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NullShipFromStoreItemState.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.valid != null && message.hasOwnProperty("valid"))
                                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.valid);
                            if (message.state != null && message.hasOwnProperty("state"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.state);
                            return writer;
                        };

                        /**
                         * Encodes the specified NullShipFromStoreItemState message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.INullShipFromStoreItemState} message NullShipFromStoreItemState message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        NullShipFromStoreItemState.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a NullShipFromStoreItemState message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState} NullShipFromStoreItemState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NullShipFromStoreItemState.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.valid = reader.bool();
                                    break;
                                case 2:
                                    message.state = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a NullShipFromStoreItemState message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState} NullShipFromStoreItemState
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        NullShipFromStoreItemState.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a NullShipFromStoreItemState message.
                         * @function verify
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        NullShipFromStoreItemState.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.valid != null && message.hasOwnProperty("valid"))
                                if (typeof message.valid !== "boolean")
                                    return "valid: boolean expected";
                            if (message.state != null && message.hasOwnProperty("state"))
                                if (!$util.isString(message.state))
                                    return "state: string expected";
                            return null;
                        };

                        /**
                         * Creates a NullShipFromStoreItemState message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState} NullShipFromStoreItemState
                         */
                        NullShipFromStoreItemState.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState)
                                return object;
                            var message = new $root.theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState();
                            if (object.valid != null)
                                message.valid = Boolean(object.valid);
                            if (object.state != null)
                                message.state = String(object.state);
                            return message;
                        };

                        /**
                         * Creates a plain object from a NullShipFromStoreItemState message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @static
                         * @param {theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState} message NullShipFromStoreItemState
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        NullShipFromStoreItemState.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.valid = false;
                                object.state = "";
                            }
                            if (message.valid != null && message.hasOwnProperty("valid"))
                                object.valid = message.valid;
                            if (message.state != null && message.hasOwnProperty("state"))
                                object.state = message.state;
                            return object;
                        };

                        /**
                         * Converts this NullShipFromStoreItemState to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.orders.OrderMainState.NullShipFromStoreItemState
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        NullShipFromStoreItemState.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return NullShipFromStoreItemState;
                    })();

                    return OrderMainState;
                })();

                orders.Order = (function() {

                    /**
                     * Properties of an Order.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrder
                     * @property {string|null} [code] Order code
                     * @property {string|null} [customerId] Order customerId
                     * @property {theplant.ec.service.orders.IOrderCustomer|null} [customer] Order customer
                     * @property {Array.<theplant.ec.service.orders.IOrderItem>|null} [orderItems] Order orderItems
                     * @property {theplant.ec.service.orders.IGiftWrapping|null} [giftWrapping] Order giftWrapping
                     * @property {theplant.ec.service.users.IAddress|null} [shippingAddress] Order shippingAddress
                     * @property {theplant.ec.service.users.IAddress|null} [billingAddress] Order billingAddress
                     * @property {theplant.ec.service.orders.OrderState|null} [state] Order state
                     * @property {Array.<string>|null} [couponsUsed] Order couponsUsed
                     * @property {Array.<string>|null} [notMeetConditionsCoupons] Order notMeetConditionsCoupons
                     * @property {number|Long|null} [pointsUsed] Order pointsUsed
                     * @property {string|null} [confirmedAt] Order confirmedAt
                     * @property {string|null} [cancelledAt] Order cancelledAt
                     * @property {theplant.ec.service.orders.IPaymentInfo|null} [paymentInfo] Order paymentInfo
                     * @property {Array.<theplant.ec.service.orders.IPaymentTransaction>|null} [paymentTransactions] Order paymentTransactions
                     * @property {theplant.ec.service.orders.IDeliveryInfo|null} [deliveryInfo] Order deliveryInfo
                     * @property {theplant.ec.service.accounting.ISumUp|null} [sumUp] Order sumUp
                     * @property {theplant.ec.service.accounting.ISumUp|null} [patchedSumUp] Order patchedSumUp
                     * @property {boolean|null} [userCanCancel] Order userCanCancel
                     * @property {theplant.ec.service.orders.IOrderShipment|null} [shipment] Order shipment
                     * @property {theplant.ec.service.store_orders.IStoreOrder|null} [storeOrderInfo] Order storeOrderInfo
                     * @property {theplant.ec.service.orders.INoveltyGift|null} [noveltyGiftInfo] Order noveltyGiftInfo
                     * @property {theplant.ec.service.orders.IOrderFee|null} [fee] Order fee
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [rdGroups] Order rdGroups
                     * @property {string|null} [cancelReason] Order cancelReason
                     * @property {theplant.ec.service.convenience_pickup.IConveniencePickup|null} [conveniencePickupInfo] Order conveniencePickupInfo
                     * @property {theplant.ec.service.accounting.IFeeSetting|null} [feeSetting] Order feeSetting
                     * @property {Array.<string>|null} [messages] Order messages
                     * @property {boolean|null} [ableToCancel] Order ableToCancel
                     * @property {boolean|null} [hasShipfsItem] Order hasShipfsItem
                     * @property {boolean|null} [captured] Order captured
                     * @property {boolean|null} [isMixed] Order isMixed
                     * @property {theplant.ec.service.orders.IOrderMainState|null} [mainState] Order mainState
                     * @property {string|null} [stateUpdateAt] Order stateUpdateAt
                     * @property {string|null} [opluxEventId] Order opluxEventId
                     * @property {string|null} [opluxEventResult] Order opluxEventResult
                     * @property {string|null} [opluxError] Order opluxError
                     * @property {Array.<theplant.ec.service.orders.IOrderShipmentPackage>|null} [shipmentPackages] Order shipmentPackages
                     * @property {string|null} [channel] Order channel
                     * @property {string|null} [waitingPayAt] Order waitingPayAt
                     * @property {number|Long|null} [givePoints] Order givePoints
                     */

                    /**
                     * Constructs a new Order.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an Order.
                     * @implements IOrder
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrder=} [properties] Properties to set
                     */
                    function Order(properties) {
                        this.orderItems = [];
                        this.couponsUsed = [];
                        this.notMeetConditionsCoupons = [];
                        this.paymentTransactions = [];
                        this.rdGroups = [];
                        this.messages = [];
                        this.shipmentPackages = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Order code.
                     * @member {string} code
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.code = "";

                    /**
                     * Order customerId.
                     * @member {string} customerId
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.customerId = "";

                    /**
                     * Order customer.
                     * @member {theplant.ec.service.orders.IOrderCustomer|null|undefined} customer
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.customer = null;

                    /**
                     * Order orderItems.
                     * @member {Array.<theplant.ec.service.orders.IOrderItem>} orderItems
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.orderItems = $util.emptyArray;

                    /**
                     * Order giftWrapping.
                     * @member {theplant.ec.service.orders.IGiftWrapping|null|undefined} giftWrapping
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.giftWrapping = null;

                    /**
                     * Order shippingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} shippingAddress
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.shippingAddress = null;

                    /**
                     * Order billingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} billingAddress
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.billingAddress = null;

                    /**
                     * Order state.
                     * @member {theplant.ec.service.orders.OrderState} state
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.state = 0;

                    /**
                     * Order couponsUsed.
                     * @member {Array.<string>} couponsUsed
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.couponsUsed = $util.emptyArray;

                    /**
                     * Order notMeetConditionsCoupons.
                     * @member {Array.<string>} notMeetConditionsCoupons
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.notMeetConditionsCoupons = $util.emptyArray;

                    /**
                     * Order pointsUsed.
                     * @member {number|Long} pointsUsed
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.pointsUsed = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Order confirmedAt.
                     * @member {string} confirmedAt
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.confirmedAt = "";

                    /**
                     * Order cancelledAt.
                     * @member {string} cancelledAt
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.cancelledAt = "";

                    /**
                     * Order paymentInfo.
                     * @member {theplant.ec.service.orders.IPaymentInfo|null|undefined} paymentInfo
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.paymentInfo = null;

                    /**
                     * Order paymentTransactions.
                     * @member {Array.<theplant.ec.service.orders.IPaymentTransaction>} paymentTransactions
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.paymentTransactions = $util.emptyArray;

                    /**
                     * Order deliveryInfo.
                     * @member {theplant.ec.service.orders.IDeliveryInfo|null|undefined} deliveryInfo
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.deliveryInfo = null;

                    /**
                     * Order sumUp.
                     * @member {theplant.ec.service.accounting.ISumUp|null|undefined} sumUp
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.sumUp = null;

                    /**
                     * Order patchedSumUp.
                     * @member {theplant.ec.service.accounting.ISumUp|null|undefined} patchedSumUp
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.patchedSumUp = null;

                    /**
                     * Order userCanCancel.
                     * @member {boolean} userCanCancel
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.userCanCancel = false;

                    /**
                     * Order shipment.
                     * @member {theplant.ec.service.orders.IOrderShipment|null|undefined} shipment
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.shipment = null;

                    /**
                     * Order storeOrderInfo.
                     * @member {theplant.ec.service.store_orders.IStoreOrder|null|undefined} storeOrderInfo
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.storeOrderInfo = null;

                    /**
                     * Order noveltyGiftInfo.
                     * @member {theplant.ec.service.orders.INoveltyGift|null|undefined} noveltyGiftInfo
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.noveltyGiftInfo = null;

                    /**
                     * Order fee.
                     * @member {theplant.ec.service.orders.IOrderFee|null|undefined} fee
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.fee = null;

                    /**
                     * Order rdGroups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} rdGroups
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.rdGroups = $util.emptyArray;

                    /**
                     * Order cancelReason.
                     * @member {string} cancelReason
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.cancelReason = "";

                    /**
                     * Order conveniencePickupInfo.
                     * @member {theplant.ec.service.convenience_pickup.IConveniencePickup|null|undefined} conveniencePickupInfo
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.conveniencePickupInfo = null;

                    /**
                     * Order feeSetting.
                     * @member {theplant.ec.service.accounting.IFeeSetting|null|undefined} feeSetting
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.feeSetting = null;

                    /**
                     * Order messages.
                     * @member {Array.<string>} messages
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.messages = $util.emptyArray;

                    /**
                     * Order ableToCancel.
                     * @member {boolean} ableToCancel
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.ableToCancel = false;

                    /**
                     * Order hasShipfsItem.
                     * @member {boolean} hasShipfsItem
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.hasShipfsItem = false;

                    /**
                     * Order captured.
                     * @member {boolean} captured
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.captured = false;

                    /**
                     * Order isMixed.
                     * @member {boolean} isMixed
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.isMixed = false;

                    /**
                     * Order mainState.
                     * @member {theplant.ec.service.orders.IOrderMainState|null|undefined} mainState
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.mainState = null;

                    /**
                     * Order stateUpdateAt.
                     * @member {string} stateUpdateAt
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.stateUpdateAt = "";

                    /**
                     * Order opluxEventId.
                     * @member {string} opluxEventId
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.opluxEventId = "";

                    /**
                     * Order opluxEventResult.
                     * @member {string} opluxEventResult
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.opluxEventResult = "";

                    /**
                     * Order opluxError.
                     * @member {string} opluxError
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.opluxError = "";

                    /**
                     * Order shipmentPackages.
                     * @member {Array.<theplant.ec.service.orders.IOrderShipmentPackage>} shipmentPackages
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.shipmentPackages = $util.emptyArray;

                    /**
                     * Order channel.
                     * @member {string} channel
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.channel = "";

                    /**
                     * Order waitingPayAt.
                     * @member {string} waitingPayAt
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.waitingPayAt = "";

                    /**
                     * Order givePoints.
                     * @member {number|Long} givePoints
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     */
                    Order.prototype.givePoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new Order instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {theplant.ec.service.orders.IOrder=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.Order} Order instance
                     */
                    Order.create = function create(properties) {
                        return new Order(properties);
                    };

                    /**
                     * Encodes the specified Order message. Does not implicitly {@link theplant.ec.service.orders.Order.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {theplant.ec.service.orders.IOrder} message Order message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Order.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerId);
                        if (message.customer != null && message.hasOwnProperty("customer"))
                            $root.theplant.ec.service.orders.OrderCustomer.encode(message.customer, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.orderItems != null && message.orderItems.length)
                            for (var i = 0; i < message.orderItems.length; ++i)
                                $root.theplant.ec.service.orders.OrderItem.encode(message.orderItems[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            $root.theplant.ec.service.orders.GiftWrapping.encode(message.giftWrapping, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.shippingAddress, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.billingAddress, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.state);
                        if (message.couponsUsed != null && message.couponsUsed.length)
                            for (var i = 0; i < message.couponsUsed.length; ++i)
                                writer.uint32(/* id 9, wireType 2 =*/74).string(message.couponsUsed[i]);
                        if (message.pointsUsed != null && message.hasOwnProperty("pointsUsed"))
                            writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.pointsUsed);
                        if (message.confirmedAt != null && message.hasOwnProperty("confirmedAt"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.confirmedAt);
                        if (message.paymentInfo != null && message.hasOwnProperty("paymentInfo"))
                            $root.theplant.ec.service.orders.PaymentInfo.encode(message.paymentInfo, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        if (message.paymentTransactions != null && message.paymentTransactions.length)
                            for (var i = 0; i < message.paymentTransactions.length; ++i)
                                $root.theplant.ec.service.orders.PaymentTransaction.encode(message.paymentTransactions[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            $root.theplant.ec.service.orders.DeliveryInfo.encode(message.deliveryInfo, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                        if (message.sumUp != null && message.hasOwnProperty("sumUp"))
                            $root.theplant.ec.service.accounting.SumUp.encode(message.sumUp, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                        if (message.patchedSumUp != null && message.hasOwnProperty("patchedSumUp"))
                            $root.theplant.ec.service.accounting.SumUp.encode(message.patchedSumUp, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        if (message.userCanCancel != null && message.hasOwnProperty("userCanCancel"))
                            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.userCanCancel);
                        if (message.shipment != null && message.hasOwnProperty("shipment"))
                            $root.theplant.ec.service.orders.OrderShipment.encode(message.shipment, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                        if (message.cancelledAt != null && message.hasOwnProperty("cancelledAt"))
                            writer.uint32(/* id 22, wireType 2 =*/178).string(message.cancelledAt);
                        if (message.storeOrderInfo != null && message.hasOwnProperty("storeOrderInfo"))
                            $root.theplant.ec.service.store_orders.StoreOrder.encode(message.storeOrderInfo, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                        if (message.noveltyGiftInfo != null && message.hasOwnProperty("noveltyGiftInfo"))
                            $root.theplant.ec.service.orders.NoveltyGift.encode(message.noveltyGiftInfo, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                        if (message.fee != null && message.hasOwnProperty("fee"))
                            $root.theplant.ec.service.orders.OrderFee.encode(message.fee, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                        if (message.rdGroups != null && message.rdGroups.length)
                            for (var i = 0; i < message.rdGroups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.rdGroups[i], writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            writer.uint32(/* id 27, wireType 2 =*/218).string(message.cancelReason);
                        if (message.conveniencePickupInfo != null && message.hasOwnProperty("conveniencePickupInfo"))
                            $root.theplant.ec.service.convenience_pickup.ConveniencePickup.encode(message.conveniencePickupInfo, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                        if (message.feeSetting != null && message.hasOwnProperty("feeSetting"))
                            $root.theplant.ec.service.accounting.FeeSetting.encode(message.feeSetting, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                        if (message.messages != null && message.messages.length)
                            for (var i = 0; i < message.messages.length; ++i)
                                writer.uint32(/* id 30, wireType 2 =*/242).string(message.messages[i]);
                        if (message.ableToCancel != null && message.hasOwnProperty("ableToCancel"))
                            writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ableToCancel);
                        if (message.stateUpdateAt != null && message.hasOwnProperty("stateUpdateAt"))
                            writer.uint32(/* id 32, wireType 2 =*/258).string(message.stateUpdateAt);
                        if (message.hasShipfsItem != null && message.hasOwnProperty("hasShipfsItem"))
                            writer.uint32(/* id 33, wireType 0 =*/264).bool(message.hasShipfsItem);
                        if (message.captured != null && message.hasOwnProperty("captured"))
                            writer.uint32(/* id 34, wireType 0 =*/272).bool(message.captured);
                        if (message.isMixed != null && message.hasOwnProperty("isMixed"))
                            writer.uint32(/* id 35, wireType 0 =*/280).bool(message.isMixed);
                        if (message.mainState != null && message.hasOwnProperty("mainState"))
                            $root.theplant.ec.service.orders.OrderMainState.encode(message.mainState, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
                        if (message.opluxEventId != null && message.hasOwnProperty("opluxEventId"))
                            writer.uint32(/* id 37, wireType 2 =*/298).string(message.opluxEventId);
                        if (message.opluxEventResult != null && message.hasOwnProperty("opluxEventResult"))
                            writer.uint32(/* id 38, wireType 2 =*/306).string(message.opluxEventResult);
                        if (message.opluxError != null && message.hasOwnProperty("opluxError"))
                            writer.uint32(/* id 39, wireType 2 =*/314).string(message.opluxError);
                        if (message.notMeetConditionsCoupons != null && message.notMeetConditionsCoupons.length)
                            for (var i = 0; i < message.notMeetConditionsCoupons.length; ++i)
                                writer.uint32(/* id 40, wireType 2 =*/322).string(message.notMeetConditionsCoupons[i]);
                        if (message.shipmentPackages != null && message.shipmentPackages.length)
                            for (var i = 0; i < message.shipmentPackages.length; ++i)
                                $root.theplant.ec.service.orders.OrderShipmentPackage.encode(message.shipmentPackages[i], writer.uint32(/* id 41, wireType 2 =*/330).fork()).ldelim();
                        if (message.channel != null && message.hasOwnProperty("channel"))
                            writer.uint32(/* id 42, wireType 2 =*/338).string(message.channel);
                        if (message.waitingPayAt != null && message.hasOwnProperty("waitingPayAt"))
                            writer.uint32(/* id 43, wireType 2 =*/346).string(message.waitingPayAt);
                        if (message.givePoints != null && message.hasOwnProperty("givePoints"))
                            writer.uint32(/* id 44, wireType 0 =*/352).int64(message.givePoints);
                        return writer;
                    };

                    /**
                     * Encodes the specified Order message, length delimited. Does not implicitly {@link theplant.ec.service.orders.Order.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {theplant.ec.service.orders.IOrder} message Order message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Order.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Order message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.Order} Order
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Order.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.Order();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.string();
                                break;
                            case 2:
                                message.customerId = reader.string();
                                break;
                            case 3:
                                message.customer = $root.theplant.ec.service.orders.OrderCustomer.decode(reader, reader.uint32());
                                break;
                            case 4:
                                if (!(message.orderItems && message.orderItems.length))
                                    message.orderItems = [];
                                message.orderItems.push($root.theplant.ec.service.orders.OrderItem.decode(reader, reader.uint32()));
                                break;
                            case 5:
                                message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.shippingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.billingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.state = reader.int32();
                                break;
                            case 9:
                                if (!(message.couponsUsed && message.couponsUsed.length))
                                    message.couponsUsed = [];
                                message.couponsUsed.push(reader.string());
                                break;
                            case 40:
                                if (!(message.notMeetConditionsCoupons && message.notMeetConditionsCoupons.length))
                                    message.notMeetConditionsCoupons = [];
                                message.notMeetConditionsCoupons.push(reader.string());
                                break;
                            case 10:
                                message.pointsUsed = reader.uint64();
                                break;
                            case 11:
                                message.confirmedAt = reader.string();
                                break;
                            case 22:
                                message.cancelledAt = reader.string();
                                break;
                            case 12:
                                message.paymentInfo = $root.theplant.ec.service.orders.PaymentInfo.decode(reader, reader.uint32());
                                break;
                            case 13:
                                if (!(message.paymentTransactions && message.paymentTransactions.length))
                                    message.paymentTransactions = [];
                                message.paymentTransactions.push($root.theplant.ec.service.orders.PaymentTransaction.decode(reader, reader.uint32()));
                                break;
                            case 15:
                                message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.sumUp = $root.theplant.ec.service.accounting.SumUp.decode(reader, reader.uint32());
                                break;
                            case 19:
                                message.patchedSumUp = $root.theplant.ec.service.accounting.SumUp.decode(reader, reader.uint32());
                                break;
                            case 20:
                                message.userCanCancel = reader.bool();
                                break;
                            case 21:
                                message.shipment = $root.theplant.ec.service.orders.OrderShipment.decode(reader, reader.uint32());
                                break;
                            case 23:
                                message.storeOrderInfo = $root.theplant.ec.service.store_orders.StoreOrder.decode(reader, reader.uint32());
                                break;
                            case 24:
                                message.noveltyGiftInfo = $root.theplant.ec.service.orders.NoveltyGift.decode(reader, reader.uint32());
                                break;
                            case 25:
                                message.fee = $root.theplant.ec.service.orders.OrderFee.decode(reader, reader.uint32());
                                break;
                            case 26:
                                if (!(message.rdGroups && message.rdGroups.length))
                                    message.rdGroups = [];
                                message.rdGroups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            case 27:
                                message.cancelReason = reader.string();
                                break;
                            case 28:
                                message.conveniencePickupInfo = $root.theplant.ec.service.convenience_pickup.ConveniencePickup.decode(reader, reader.uint32());
                                break;
                            case 29:
                                message.feeSetting = $root.theplant.ec.service.accounting.FeeSetting.decode(reader, reader.uint32());
                                break;
                            case 30:
                                if (!(message.messages && message.messages.length))
                                    message.messages = [];
                                message.messages.push(reader.string());
                                break;
                            case 31:
                                message.ableToCancel = reader.bool();
                                break;
                            case 33:
                                message.hasShipfsItem = reader.bool();
                                break;
                            case 34:
                                message.captured = reader.bool();
                                break;
                            case 35:
                                message.isMixed = reader.bool();
                                break;
                            case 36:
                                message.mainState = $root.theplant.ec.service.orders.OrderMainState.decode(reader, reader.uint32());
                                break;
                            case 32:
                                message.stateUpdateAt = reader.string();
                                break;
                            case 37:
                                message.opluxEventId = reader.string();
                                break;
                            case 38:
                                message.opluxEventResult = reader.string();
                                break;
                            case 39:
                                message.opluxError = reader.string();
                                break;
                            case 41:
                                if (!(message.shipmentPackages && message.shipmentPackages.length))
                                    message.shipmentPackages = [];
                                message.shipmentPackages.push($root.theplant.ec.service.orders.OrderShipmentPackage.decode(reader, reader.uint32()));
                                break;
                            case 42:
                                message.channel = reader.string();
                                break;
                            case 43:
                                message.waitingPayAt = reader.string();
                                break;
                            case 44:
                                message.givePoints = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Order message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.Order} Order
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Order.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Order message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Order.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            if (!$util.isString(message.customerId))
                                return "customerId: string expected";
                        if (message.customer != null && message.hasOwnProperty("customer")) {
                            var error = $root.theplant.ec.service.orders.OrderCustomer.verify(message.customer);
                            if (error)
                                return "customer." + error;
                        }
                        if (message.orderItems != null && message.hasOwnProperty("orderItems")) {
                            if (!Array.isArray(message.orderItems))
                                return "orderItems: array expected";
                            for (var i = 0; i < message.orderItems.length; ++i) {
                                var error = $root.theplant.ec.service.orders.OrderItem.verify(message.orderItems[i]);
                                if (error)
                                    return "orderItems." + error;
                            }
                        }
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping")) {
                            var error = $root.theplant.ec.service.orders.GiftWrapping.verify(message.giftWrapping);
                            if (error)
                                return "giftWrapping." + error;
                        }
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.shippingAddress);
                            if (error)
                                return "shippingAddress." + error;
                        }
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.billingAddress);
                            if (error)
                                return "billingAddress." + error;
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                                break;
                            }
                        if (message.couponsUsed != null && message.hasOwnProperty("couponsUsed")) {
                            if (!Array.isArray(message.couponsUsed))
                                return "couponsUsed: array expected";
                            for (var i = 0; i < message.couponsUsed.length; ++i)
                                if (!$util.isString(message.couponsUsed[i]))
                                    return "couponsUsed: string[] expected";
                        }
                        if (message.notMeetConditionsCoupons != null && message.hasOwnProperty("notMeetConditionsCoupons")) {
                            if (!Array.isArray(message.notMeetConditionsCoupons))
                                return "notMeetConditionsCoupons: array expected";
                            for (var i = 0; i < message.notMeetConditionsCoupons.length; ++i)
                                if (!$util.isString(message.notMeetConditionsCoupons[i]))
                                    return "notMeetConditionsCoupons: string[] expected";
                        }
                        if (message.pointsUsed != null && message.hasOwnProperty("pointsUsed"))
                            if (!$util.isInteger(message.pointsUsed) && !(message.pointsUsed && $util.isInteger(message.pointsUsed.low) && $util.isInteger(message.pointsUsed.high)))
                                return "pointsUsed: integer|Long expected";
                        if (message.confirmedAt != null && message.hasOwnProperty("confirmedAt"))
                            if (!$util.isString(message.confirmedAt))
                                return "confirmedAt: string expected";
                        if (message.cancelledAt != null && message.hasOwnProperty("cancelledAt"))
                            if (!$util.isString(message.cancelledAt))
                                return "cancelledAt: string expected";
                        if (message.paymentInfo != null && message.hasOwnProperty("paymentInfo")) {
                            var error = $root.theplant.ec.service.orders.PaymentInfo.verify(message.paymentInfo);
                            if (error)
                                return "paymentInfo." + error;
                        }
                        if (message.paymentTransactions != null && message.hasOwnProperty("paymentTransactions")) {
                            if (!Array.isArray(message.paymentTransactions))
                                return "paymentTransactions: array expected";
                            for (var i = 0; i < message.paymentTransactions.length; ++i) {
                                var error = $root.theplant.ec.service.orders.PaymentTransaction.verify(message.paymentTransactions[i]);
                                if (error)
                                    return "paymentTransactions." + error;
                            }
                        }
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo")) {
                            var error = $root.theplant.ec.service.orders.DeliveryInfo.verify(message.deliveryInfo);
                            if (error)
                                return "deliveryInfo." + error;
                        }
                        if (message.sumUp != null && message.hasOwnProperty("sumUp")) {
                            var error = $root.theplant.ec.service.accounting.SumUp.verify(message.sumUp);
                            if (error)
                                return "sumUp." + error;
                        }
                        if (message.patchedSumUp != null && message.hasOwnProperty("patchedSumUp")) {
                            var error = $root.theplant.ec.service.accounting.SumUp.verify(message.patchedSumUp);
                            if (error)
                                return "patchedSumUp." + error;
                        }
                        if (message.userCanCancel != null && message.hasOwnProperty("userCanCancel"))
                            if (typeof message.userCanCancel !== "boolean")
                                return "userCanCancel: boolean expected";
                        if (message.shipment != null && message.hasOwnProperty("shipment")) {
                            var error = $root.theplant.ec.service.orders.OrderShipment.verify(message.shipment);
                            if (error)
                                return "shipment." + error;
                        }
                        if (message.storeOrderInfo != null && message.hasOwnProperty("storeOrderInfo")) {
                            var error = $root.theplant.ec.service.store_orders.StoreOrder.verify(message.storeOrderInfo);
                            if (error)
                                return "storeOrderInfo." + error;
                        }
                        if (message.noveltyGiftInfo != null && message.hasOwnProperty("noveltyGiftInfo")) {
                            var error = $root.theplant.ec.service.orders.NoveltyGift.verify(message.noveltyGiftInfo);
                            if (error)
                                return "noveltyGiftInfo." + error;
                        }
                        if (message.fee != null && message.hasOwnProperty("fee")) {
                            var error = $root.theplant.ec.service.orders.OrderFee.verify(message.fee);
                            if (error)
                                return "fee." + error;
                        }
                        if (message.rdGroups != null && message.hasOwnProperty("rdGroups")) {
                            if (!Array.isArray(message.rdGroups))
                                return "rdGroups: array expected";
                            for (var i = 0; i < message.rdGroups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.rdGroups[i]);
                                if (error)
                                    return "rdGroups." + error;
                            }
                        }
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            if (!$util.isString(message.cancelReason))
                                return "cancelReason: string expected";
                        if (message.conveniencePickupInfo != null && message.hasOwnProperty("conveniencePickupInfo")) {
                            var error = $root.theplant.ec.service.convenience_pickup.ConveniencePickup.verify(message.conveniencePickupInfo);
                            if (error)
                                return "conveniencePickupInfo." + error;
                        }
                        if (message.feeSetting != null && message.hasOwnProperty("feeSetting")) {
                            var error = $root.theplant.ec.service.accounting.FeeSetting.verify(message.feeSetting);
                            if (error)
                                return "feeSetting." + error;
                        }
                        if (message.messages != null && message.hasOwnProperty("messages")) {
                            if (!Array.isArray(message.messages))
                                return "messages: array expected";
                            for (var i = 0; i < message.messages.length; ++i)
                                if (!$util.isString(message.messages[i]))
                                    return "messages: string[] expected";
                        }
                        if (message.ableToCancel != null && message.hasOwnProperty("ableToCancel"))
                            if (typeof message.ableToCancel !== "boolean")
                                return "ableToCancel: boolean expected";
                        if (message.hasShipfsItem != null && message.hasOwnProperty("hasShipfsItem"))
                            if (typeof message.hasShipfsItem !== "boolean")
                                return "hasShipfsItem: boolean expected";
                        if (message.captured != null && message.hasOwnProperty("captured"))
                            if (typeof message.captured !== "boolean")
                                return "captured: boolean expected";
                        if (message.isMixed != null && message.hasOwnProperty("isMixed"))
                            if (typeof message.isMixed !== "boolean")
                                return "isMixed: boolean expected";
                        if (message.mainState != null && message.hasOwnProperty("mainState")) {
                            var error = $root.theplant.ec.service.orders.OrderMainState.verify(message.mainState);
                            if (error)
                                return "mainState." + error;
                        }
                        if (message.stateUpdateAt != null && message.hasOwnProperty("stateUpdateAt"))
                            if (!$util.isString(message.stateUpdateAt))
                                return "stateUpdateAt: string expected";
                        if (message.opluxEventId != null && message.hasOwnProperty("opluxEventId"))
                            if (!$util.isString(message.opluxEventId))
                                return "opluxEventId: string expected";
                        if (message.opluxEventResult != null && message.hasOwnProperty("opluxEventResult"))
                            if (!$util.isString(message.opluxEventResult))
                                return "opluxEventResult: string expected";
                        if (message.opluxError != null && message.hasOwnProperty("opluxError"))
                            if (!$util.isString(message.opluxError))
                                return "opluxError: string expected";
                        if (message.shipmentPackages != null && message.hasOwnProperty("shipmentPackages")) {
                            if (!Array.isArray(message.shipmentPackages))
                                return "shipmentPackages: array expected";
                            for (var i = 0; i < message.shipmentPackages.length; ++i) {
                                var error = $root.theplant.ec.service.orders.OrderShipmentPackage.verify(message.shipmentPackages[i]);
                                if (error)
                                    return "shipmentPackages." + error;
                            }
                        }
                        if (message.channel != null && message.hasOwnProperty("channel"))
                            if (!$util.isString(message.channel))
                                return "channel: string expected";
                        if (message.waitingPayAt != null && message.hasOwnProperty("waitingPayAt"))
                            if (!$util.isString(message.waitingPayAt))
                                return "waitingPayAt: string expected";
                        if (message.givePoints != null && message.hasOwnProperty("givePoints"))
                            if (!$util.isInteger(message.givePoints) && !(message.givePoints && $util.isInteger(message.givePoints.low) && $util.isInteger(message.givePoints.high)))
                                return "givePoints: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an Order message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.Order} Order
                     */
                    Order.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.Order)
                            return object;
                        var message = new $root.theplant.ec.service.orders.Order();
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.customerId != null)
                            message.customerId = String(object.customerId);
                        if (object.customer != null) {
                            if (typeof object.customer !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.customer: object expected");
                            message.customer = $root.theplant.ec.service.orders.OrderCustomer.fromObject(object.customer);
                        }
                        if (object.orderItems) {
                            if (!Array.isArray(object.orderItems))
                                throw TypeError(".theplant.ec.service.orders.Order.orderItems: array expected");
                            message.orderItems = [];
                            for (var i = 0; i < object.orderItems.length; ++i) {
                                if (typeof object.orderItems[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.Order.orderItems: object expected");
                                message.orderItems[i] = $root.theplant.ec.service.orders.OrderItem.fromObject(object.orderItems[i]);
                            }
                        }
                        if (object.giftWrapping != null) {
                            if (typeof object.giftWrapping !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.giftWrapping: object expected");
                            message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.fromObject(object.giftWrapping);
                        }
                        if (object.shippingAddress != null) {
                            if (typeof object.shippingAddress !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.shippingAddress: object expected");
                            message.shippingAddress = $root.theplant.ec.service.users.Address.fromObject(object.shippingAddress);
                        }
                        if (object.billingAddress != null) {
                            if (typeof object.billingAddress !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.billingAddress: object expected");
                            message.billingAddress = $root.theplant.ec.service.users.Address.fromObject(object.billingAddress);
                        }
                        switch (object.state) {
                        case "DRAFT":
                        case 0:
                            message.state = 0;
                            break;
                        case "CONFIRMED":
                        case 1:
                            message.state = 1;
                            break;
                        case "FREEZED":
                        case 3:
                            message.state = 3;
                            break;
                        case "READY_FOR_SHIPPING":
                        case 4:
                            message.state = 4;
                            break;
                        case "SHIPPED":
                        case 5:
                            message.state = 5;
                            break;
                        case "PARTIALLY_SHIPPED":
                        case 6:
                            message.state = 6;
                            break;
                        case "CANCELED":
                        case 7:
                            message.state = 7;
                            break;
                        case "PARTIALLY_RETURNED":
                        case 8:
                            message.state = 8;
                            break;
                        case "RETURNED":
                        case 9:
                            message.state = 9;
                            break;
                        case "REQUEST_RETURNED":
                        case 10:
                            message.state = 10;
                            break;
                        case "WAITING_PAY":
                        case 11:
                            message.state = 11;
                            break;
                        case "WAITING_PAY_CANCELED":
                        case 12:
                            message.state = 12;
                            break;
                        }
                        if (object.couponsUsed) {
                            if (!Array.isArray(object.couponsUsed))
                                throw TypeError(".theplant.ec.service.orders.Order.couponsUsed: array expected");
                            message.couponsUsed = [];
                            for (var i = 0; i < object.couponsUsed.length; ++i)
                                message.couponsUsed[i] = String(object.couponsUsed[i]);
                        }
                        if (object.notMeetConditionsCoupons) {
                            if (!Array.isArray(object.notMeetConditionsCoupons))
                                throw TypeError(".theplant.ec.service.orders.Order.notMeetConditionsCoupons: array expected");
                            message.notMeetConditionsCoupons = [];
                            for (var i = 0; i < object.notMeetConditionsCoupons.length; ++i)
                                message.notMeetConditionsCoupons[i] = String(object.notMeetConditionsCoupons[i]);
                        }
                        if (object.pointsUsed != null)
                            if ($util.Long)
                                (message.pointsUsed = $util.Long.fromValue(object.pointsUsed)).unsigned = true;
                            else if (typeof object.pointsUsed === "string")
                                message.pointsUsed = parseInt(object.pointsUsed, 10);
                            else if (typeof object.pointsUsed === "number")
                                message.pointsUsed = object.pointsUsed;
                            else if (typeof object.pointsUsed === "object")
                                message.pointsUsed = new $util.LongBits(object.pointsUsed.low >>> 0, object.pointsUsed.high >>> 0).toNumber(true);
                        if (object.confirmedAt != null)
                            message.confirmedAt = String(object.confirmedAt);
                        if (object.cancelledAt != null)
                            message.cancelledAt = String(object.cancelledAt);
                        if (object.paymentInfo != null) {
                            if (typeof object.paymentInfo !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.paymentInfo: object expected");
                            message.paymentInfo = $root.theplant.ec.service.orders.PaymentInfo.fromObject(object.paymentInfo);
                        }
                        if (object.paymentTransactions) {
                            if (!Array.isArray(object.paymentTransactions))
                                throw TypeError(".theplant.ec.service.orders.Order.paymentTransactions: array expected");
                            message.paymentTransactions = [];
                            for (var i = 0; i < object.paymentTransactions.length; ++i) {
                                if (typeof object.paymentTransactions[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.Order.paymentTransactions: object expected");
                                message.paymentTransactions[i] = $root.theplant.ec.service.orders.PaymentTransaction.fromObject(object.paymentTransactions[i]);
                            }
                        }
                        if (object.deliveryInfo != null) {
                            if (typeof object.deliveryInfo !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.deliveryInfo: object expected");
                            message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.fromObject(object.deliveryInfo);
                        }
                        if (object.sumUp != null) {
                            if (typeof object.sumUp !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.sumUp: object expected");
                            message.sumUp = $root.theplant.ec.service.accounting.SumUp.fromObject(object.sumUp);
                        }
                        if (object.patchedSumUp != null) {
                            if (typeof object.patchedSumUp !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.patchedSumUp: object expected");
                            message.patchedSumUp = $root.theplant.ec.service.accounting.SumUp.fromObject(object.patchedSumUp);
                        }
                        if (object.userCanCancel != null)
                            message.userCanCancel = Boolean(object.userCanCancel);
                        if (object.shipment != null) {
                            if (typeof object.shipment !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.shipment: object expected");
                            message.shipment = $root.theplant.ec.service.orders.OrderShipment.fromObject(object.shipment);
                        }
                        if (object.storeOrderInfo != null) {
                            if (typeof object.storeOrderInfo !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.storeOrderInfo: object expected");
                            message.storeOrderInfo = $root.theplant.ec.service.store_orders.StoreOrder.fromObject(object.storeOrderInfo);
                        }
                        if (object.noveltyGiftInfo != null) {
                            if (typeof object.noveltyGiftInfo !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.noveltyGiftInfo: object expected");
                            message.noveltyGiftInfo = $root.theplant.ec.service.orders.NoveltyGift.fromObject(object.noveltyGiftInfo);
                        }
                        if (object.fee != null) {
                            if (typeof object.fee !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.fee: object expected");
                            message.fee = $root.theplant.ec.service.orders.OrderFee.fromObject(object.fee);
                        }
                        if (object.rdGroups) {
                            if (!Array.isArray(object.rdGroups))
                                throw TypeError(".theplant.ec.service.orders.Order.rdGroups: array expected");
                            message.rdGroups = [];
                            for (var i = 0; i < object.rdGroups.length; ++i) {
                                if (typeof object.rdGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.Order.rdGroups: object expected");
                                message.rdGroups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.rdGroups[i]);
                            }
                        }
                        if (object.cancelReason != null)
                            message.cancelReason = String(object.cancelReason);
                        if (object.conveniencePickupInfo != null) {
                            if (typeof object.conveniencePickupInfo !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.conveniencePickupInfo: object expected");
                            message.conveniencePickupInfo = $root.theplant.ec.service.convenience_pickup.ConveniencePickup.fromObject(object.conveniencePickupInfo);
                        }
                        if (object.feeSetting != null) {
                            if (typeof object.feeSetting !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.feeSetting: object expected");
                            message.feeSetting = $root.theplant.ec.service.accounting.FeeSetting.fromObject(object.feeSetting);
                        }
                        if (object.messages) {
                            if (!Array.isArray(object.messages))
                                throw TypeError(".theplant.ec.service.orders.Order.messages: array expected");
                            message.messages = [];
                            for (var i = 0; i < object.messages.length; ++i)
                                message.messages[i] = String(object.messages[i]);
                        }
                        if (object.ableToCancel != null)
                            message.ableToCancel = Boolean(object.ableToCancel);
                        if (object.hasShipfsItem != null)
                            message.hasShipfsItem = Boolean(object.hasShipfsItem);
                        if (object.captured != null)
                            message.captured = Boolean(object.captured);
                        if (object.isMixed != null)
                            message.isMixed = Boolean(object.isMixed);
                        if (object.mainState != null) {
                            if (typeof object.mainState !== "object")
                                throw TypeError(".theplant.ec.service.orders.Order.mainState: object expected");
                            message.mainState = $root.theplant.ec.service.orders.OrderMainState.fromObject(object.mainState);
                        }
                        if (object.stateUpdateAt != null)
                            message.stateUpdateAt = String(object.stateUpdateAt);
                        if (object.opluxEventId != null)
                            message.opluxEventId = String(object.opluxEventId);
                        if (object.opluxEventResult != null)
                            message.opluxEventResult = String(object.opluxEventResult);
                        if (object.opluxError != null)
                            message.opluxError = String(object.opluxError);
                        if (object.shipmentPackages) {
                            if (!Array.isArray(object.shipmentPackages))
                                throw TypeError(".theplant.ec.service.orders.Order.shipmentPackages: array expected");
                            message.shipmentPackages = [];
                            for (var i = 0; i < object.shipmentPackages.length; ++i) {
                                if (typeof object.shipmentPackages[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.Order.shipmentPackages: object expected");
                                message.shipmentPackages[i] = $root.theplant.ec.service.orders.OrderShipmentPackage.fromObject(object.shipmentPackages[i]);
                            }
                        }
                        if (object.channel != null)
                            message.channel = String(object.channel);
                        if (object.waitingPayAt != null)
                            message.waitingPayAt = String(object.waitingPayAt);
                        if (object.givePoints != null)
                            if ($util.Long)
                                (message.givePoints = $util.Long.fromValue(object.givePoints)).unsigned = false;
                            else if (typeof object.givePoints === "string")
                                message.givePoints = parseInt(object.givePoints, 10);
                            else if (typeof object.givePoints === "number")
                                message.givePoints = object.givePoints;
                            else if (typeof object.givePoints === "object")
                                message.givePoints = new $util.LongBits(object.givePoints.low >>> 0, object.givePoints.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from an Order message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.Order
                     * @static
                     * @param {theplant.ec.service.orders.Order} message Order
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Order.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.orderItems = [];
                            object.couponsUsed = [];
                            object.paymentTransactions = [];
                            object.rdGroups = [];
                            object.messages = [];
                            object.notMeetConditionsCoupons = [];
                            object.shipmentPackages = [];
                        }
                        if (options.defaults) {
                            object.code = "";
                            object.customerId = "";
                            object.customer = null;
                            object.giftWrapping = null;
                            object.shippingAddress = null;
                            object.billingAddress = null;
                            object.state = options.enums === String ? "DRAFT" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.pointsUsed = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.pointsUsed = options.longs === String ? "0" : 0;
                            object.confirmedAt = "";
                            object.paymentInfo = null;
                            object.deliveryInfo = null;
                            object.sumUp = null;
                            object.patchedSumUp = null;
                            object.userCanCancel = false;
                            object.shipment = null;
                            object.cancelledAt = "";
                            object.storeOrderInfo = null;
                            object.noveltyGiftInfo = null;
                            object.fee = null;
                            object.cancelReason = "";
                            object.conveniencePickupInfo = null;
                            object.feeSetting = null;
                            object.ableToCancel = false;
                            object.stateUpdateAt = "";
                            object.hasShipfsItem = false;
                            object.captured = false;
                            object.isMixed = false;
                            object.mainState = null;
                            object.opluxEventId = "";
                            object.opluxEventResult = "";
                            object.opluxError = "";
                            object.channel = "";
                            object.waitingPayAt = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.givePoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.givePoints = options.longs === String ? "0" : 0;
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            object.customerId = message.customerId;
                        if (message.customer != null && message.hasOwnProperty("customer"))
                            object.customer = $root.theplant.ec.service.orders.OrderCustomer.toObject(message.customer, options);
                        if (message.orderItems && message.orderItems.length) {
                            object.orderItems = [];
                            for (var j = 0; j < message.orderItems.length; ++j)
                                object.orderItems[j] = $root.theplant.ec.service.orders.OrderItem.toObject(message.orderItems[j], options);
                        }
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            object.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.toObject(message.giftWrapping, options);
                        if (message.shippingAddress != null && message.hasOwnProperty("shippingAddress"))
                            object.shippingAddress = $root.theplant.ec.service.users.Address.toObject(message.shippingAddress, options);
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            object.billingAddress = $root.theplant.ec.service.users.Address.toObject(message.billingAddress, options);
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.theplant.ec.service.orders.OrderState[message.state] : message.state;
                        if (message.couponsUsed && message.couponsUsed.length) {
                            object.couponsUsed = [];
                            for (var j = 0; j < message.couponsUsed.length; ++j)
                                object.couponsUsed[j] = message.couponsUsed[j];
                        }
                        if (message.pointsUsed != null && message.hasOwnProperty("pointsUsed"))
                            if (typeof message.pointsUsed === "number")
                                object.pointsUsed = options.longs === String ? String(message.pointsUsed) : message.pointsUsed;
                            else
                                object.pointsUsed = options.longs === String ? $util.Long.prototype.toString.call(message.pointsUsed) : options.longs === Number ? new $util.LongBits(message.pointsUsed.low >>> 0, message.pointsUsed.high >>> 0).toNumber(true) : message.pointsUsed;
                        if (message.confirmedAt != null && message.hasOwnProperty("confirmedAt"))
                            object.confirmedAt = message.confirmedAt;
                        if (message.paymentInfo != null && message.hasOwnProperty("paymentInfo"))
                            object.paymentInfo = $root.theplant.ec.service.orders.PaymentInfo.toObject(message.paymentInfo, options);
                        if (message.paymentTransactions && message.paymentTransactions.length) {
                            object.paymentTransactions = [];
                            for (var j = 0; j < message.paymentTransactions.length; ++j)
                                object.paymentTransactions[j] = $root.theplant.ec.service.orders.PaymentTransaction.toObject(message.paymentTransactions[j], options);
                        }
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            object.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.toObject(message.deliveryInfo, options);
                        if (message.sumUp != null && message.hasOwnProperty("sumUp"))
                            object.sumUp = $root.theplant.ec.service.accounting.SumUp.toObject(message.sumUp, options);
                        if (message.patchedSumUp != null && message.hasOwnProperty("patchedSumUp"))
                            object.patchedSumUp = $root.theplant.ec.service.accounting.SumUp.toObject(message.patchedSumUp, options);
                        if (message.userCanCancel != null && message.hasOwnProperty("userCanCancel"))
                            object.userCanCancel = message.userCanCancel;
                        if (message.shipment != null && message.hasOwnProperty("shipment"))
                            object.shipment = $root.theplant.ec.service.orders.OrderShipment.toObject(message.shipment, options);
                        if (message.cancelledAt != null && message.hasOwnProperty("cancelledAt"))
                            object.cancelledAt = message.cancelledAt;
                        if (message.storeOrderInfo != null && message.hasOwnProperty("storeOrderInfo"))
                            object.storeOrderInfo = $root.theplant.ec.service.store_orders.StoreOrder.toObject(message.storeOrderInfo, options);
                        if (message.noveltyGiftInfo != null && message.hasOwnProperty("noveltyGiftInfo"))
                            object.noveltyGiftInfo = $root.theplant.ec.service.orders.NoveltyGift.toObject(message.noveltyGiftInfo, options);
                        if (message.fee != null && message.hasOwnProperty("fee"))
                            object.fee = $root.theplant.ec.service.orders.OrderFee.toObject(message.fee, options);
                        if (message.rdGroups && message.rdGroups.length) {
                            object.rdGroups = [];
                            for (var j = 0; j < message.rdGroups.length; ++j)
                                object.rdGroups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.rdGroups[j], options);
                        }
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            object.cancelReason = message.cancelReason;
                        if (message.conveniencePickupInfo != null && message.hasOwnProperty("conveniencePickupInfo"))
                            object.conveniencePickupInfo = $root.theplant.ec.service.convenience_pickup.ConveniencePickup.toObject(message.conveniencePickupInfo, options);
                        if (message.feeSetting != null && message.hasOwnProperty("feeSetting"))
                            object.feeSetting = $root.theplant.ec.service.accounting.FeeSetting.toObject(message.feeSetting, options);
                        if (message.messages && message.messages.length) {
                            object.messages = [];
                            for (var j = 0; j < message.messages.length; ++j)
                                object.messages[j] = message.messages[j];
                        }
                        if (message.ableToCancel != null && message.hasOwnProperty("ableToCancel"))
                            object.ableToCancel = message.ableToCancel;
                        if (message.stateUpdateAt != null && message.hasOwnProperty("stateUpdateAt"))
                            object.stateUpdateAt = message.stateUpdateAt;
                        if (message.hasShipfsItem != null && message.hasOwnProperty("hasShipfsItem"))
                            object.hasShipfsItem = message.hasShipfsItem;
                        if (message.captured != null && message.hasOwnProperty("captured"))
                            object.captured = message.captured;
                        if (message.isMixed != null && message.hasOwnProperty("isMixed"))
                            object.isMixed = message.isMixed;
                        if (message.mainState != null && message.hasOwnProperty("mainState"))
                            object.mainState = $root.theplant.ec.service.orders.OrderMainState.toObject(message.mainState, options);
                        if (message.opluxEventId != null && message.hasOwnProperty("opluxEventId"))
                            object.opluxEventId = message.opluxEventId;
                        if (message.opluxEventResult != null && message.hasOwnProperty("opluxEventResult"))
                            object.opluxEventResult = message.opluxEventResult;
                        if (message.opluxError != null && message.hasOwnProperty("opluxError"))
                            object.opluxError = message.opluxError;
                        if (message.notMeetConditionsCoupons && message.notMeetConditionsCoupons.length) {
                            object.notMeetConditionsCoupons = [];
                            for (var j = 0; j < message.notMeetConditionsCoupons.length; ++j)
                                object.notMeetConditionsCoupons[j] = message.notMeetConditionsCoupons[j];
                        }
                        if (message.shipmentPackages && message.shipmentPackages.length) {
                            object.shipmentPackages = [];
                            for (var j = 0; j < message.shipmentPackages.length; ++j)
                                object.shipmentPackages[j] = $root.theplant.ec.service.orders.OrderShipmentPackage.toObject(message.shipmentPackages[j], options);
                        }
                        if (message.channel != null && message.hasOwnProperty("channel"))
                            object.channel = message.channel;
                        if (message.waitingPayAt != null && message.hasOwnProperty("waitingPayAt"))
                            object.waitingPayAt = message.waitingPayAt;
                        if (message.givePoints != null && message.hasOwnProperty("givePoints"))
                            if (typeof message.givePoints === "number")
                                object.givePoints = options.longs === String ? String(message.givePoints) : message.givePoints;
                            else
                                object.givePoints = options.longs === String ? $util.Long.prototype.toString.call(message.givePoints) : options.longs === Number ? new $util.LongBits(message.givePoints.low >>> 0, message.givePoints.high >>> 0).toNumber() : message.givePoints;
                        return object;
                    };

                    /**
                     * Converts this Order to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.Order
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Order.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Order;
                })();

                orders.OrderFee = (function() {

                    /**
                     * Properties of an OrderFee.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderFee
                     * @property {boolean|null} [giftWrappingFeeSpecified] OrderFee giftWrappingFeeSpecified
                     * @property {number|Long|null} [giftWrappingFee] OrderFee giftWrappingFee
                     * @property {boolean|null} [deliveryFeeSpecified] OrderFee deliveryFeeSpecified
                     * @property {number|Long|null} [deliveryFee] OrderFee deliveryFee
                     * @property {boolean|null} [codFeeSpecified] OrderFee codFeeSpecified
                     * @property {number|Long|null} [codFee] OrderFee codFee
                     * @property {boolean|null} [hemmingPantsFeeSpecified] OrderFee hemmingPantsFeeSpecified
                     * @property {number|Long|null} [hemmingPantsFee] OrderFee hemmingPantsFee
                     * @property {boolean|null} [discountGiftWrappingFeeSpecified] OrderFee discountGiftWrappingFeeSpecified
                     * @property {number|Long|null} [discountGiftWrappingFee] OrderFee discountGiftWrappingFee
                     * @property {boolean|null} [discountDeliveryFeeSpecified] OrderFee discountDeliveryFeeSpecified
                     * @property {number|Long|null} [discountDeliveryFee] OrderFee discountDeliveryFee
                     * @property {boolean|null} [discountHemmingPantsFeeSpecified] OrderFee discountHemmingPantsFeeSpecified
                     * @property {number|Long|null} [discountHemmingPantsFee] OrderFee discountHemmingPantsFee
                     */

                    /**
                     * Constructs a new OrderFee.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderFee.
                     * @implements IOrderFee
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderFee=} [properties] Properties to set
                     */
                    function OrderFee(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderFee giftWrappingFeeSpecified.
                     * @member {boolean} giftWrappingFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.giftWrappingFeeSpecified = false;

                    /**
                     * OrderFee giftWrappingFee.
                     * @member {number|Long} giftWrappingFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.giftWrappingFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderFee deliveryFeeSpecified.
                     * @member {boolean} deliveryFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.deliveryFeeSpecified = false;

                    /**
                     * OrderFee deliveryFee.
                     * @member {number|Long} deliveryFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.deliveryFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderFee codFeeSpecified.
                     * @member {boolean} codFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.codFeeSpecified = false;

                    /**
                     * OrderFee codFee.
                     * @member {number|Long} codFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.codFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderFee hemmingPantsFeeSpecified.
                     * @member {boolean} hemmingPantsFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.hemmingPantsFeeSpecified = false;

                    /**
                     * OrderFee hemmingPantsFee.
                     * @member {number|Long} hemmingPantsFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.hemmingPantsFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderFee discountGiftWrappingFeeSpecified.
                     * @member {boolean} discountGiftWrappingFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.discountGiftWrappingFeeSpecified = false;

                    /**
                     * OrderFee discountGiftWrappingFee.
                     * @member {number|Long} discountGiftWrappingFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.discountGiftWrappingFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderFee discountDeliveryFeeSpecified.
                     * @member {boolean} discountDeliveryFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.discountDeliveryFeeSpecified = false;

                    /**
                     * OrderFee discountDeliveryFee.
                     * @member {number|Long} discountDeliveryFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.discountDeliveryFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderFee discountHemmingPantsFeeSpecified.
                     * @member {boolean} discountHemmingPantsFeeSpecified
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.discountHemmingPantsFeeSpecified = false;

                    /**
                     * OrderFee discountHemmingPantsFee.
                     * @member {number|Long} discountHemmingPantsFee
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     */
                    OrderFee.prototype.discountHemmingPantsFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new OrderFee instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {theplant.ec.service.orders.IOrderFee=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderFee} OrderFee instance
                     */
                    OrderFee.create = function create(properties) {
                        return new OrderFee(properties);
                    };

                    /**
                     * Encodes the specified OrderFee message. Does not implicitly {@link theplant.ec.service.orders.OrderFee.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {theplant.ec.service.orders.IOrderFee} message OrderFee message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderFee.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.giftWrappingFeeSpecified != null && message.hasOwnProperty("giftWrappingFeeSpecified"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.giftWrappingFeeSpecified);
                        if (message.giftWrappingFee != null && message.hasOwnProperty("giftWrappingFee"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.giftWrappingFee);
                        if (message.deliveryFeeSpecified != null && message.hasOwnProperty("deliveryFeeSpecified"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.deliveryFeeSpecified);
                        if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.deliveryFee);
                        if (message.codFeeSpecified != null && message.hasOwnProperty("codFeeSpecified"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.codFeeSpecified);
                        if (message.codFee != null && message.hasOwnProperty("codFee"))
                            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.codFee);
                        if (message.hemmingPantsFeeSpecified != null && message.hasOwnProperty("hemmingPantsFeeSpecified"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.hemmingPantsFeeSpecified);
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.hemmingPantsFee);
                        if (message.discountGiftWrappingFeeSpecified != null && message.hasOwnProperty("discountGiftWrappingFeeSpecified"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.discountGiftWrappingFeeSpecified);
                        if (message.discountGiftWrappingFee != null && message.hasOwnProperty("discountGiftWrappingFee"))
                            writer.uint32(/* id 10, wireType 0 =*/80).uint64(message.discountGiftWrappingFee);
                        if (message.discountDeliveryFeeSpecified != null && message.hasOwnProperty("discountDeliveryFeeSpecified"))
                            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.discountDeliveryFeeSpecified);
                        if (message.discountDeliveryFee != null && message.hasOwnProperty("discountDeliveryFee"))
                            writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.discountDeliveryFee);
                        if (message.discountHemmingPantsFeeSpecified != null && message.hasOwnProperty("discountHemmingPantsFeeSpecified"))
                            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.discountHemmingPantsFeeSpecified);
                        if (message.discountHemmingPantsFee != null && message.hasOwnProperty("discountHemmingPantsFee"))
                            writer.uint32(/* id 14, wireType 0 =*/112).uint64(message.discountHemmingPantsFee);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderFee message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderFee.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {theplant.ec.service.orders.IOrderFee} message OrderFee message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderFee.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderFee message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderFee} OrderFee
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderFee.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderFee();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.giftWrappingFeeSpecified = reader.bool();
                                break;
                            case 2:
                                message.giftWrappingFee = reader.uint64();
                                break;
                            case 3:
                                message.deliveryFeeSpecified = reader.bool();
                                break;
                            case 4:
                                message.deliveryFee = reader.uint64();
                                break;
                            case 5:
                                message.codFeeSpecified = reader.bool();
                                break;
                            case 6:
                                message.codFee = reader.uint64();
                                break;
                            case 7:
                                message.hemmingPantsFeeSpecified = reader.bool();
                                break;
                            case 8:
                                message.hemmingPantsFee = reader.uint64();
                                break;
                            case 9:
                                message.discountGiftWrappingFeeSpecified = reader.bool();
                                break;
                            case 10:
                                message.discountGiftWrappingFee = reader.uint64();
                                break;
                            case 11:
                                message.discountDeliveryFeeSpecified = reader.bool();
                                break;
                            case 12:
                                message.discountDeliveryFee = reader.uint64();
                                break;
                            case 13:
                                message.discountHemmingPantsFeeSpecified = reader.bool();
                                break;
                            case 14:
                                message.discountHemmingPantsFee = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderFee message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderFee} OrderFee
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderFee.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderFee message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderFee.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.giftWrappingFeeSpecified != null && message.hasOwnProperty("giftWrappingFeeSpecified"))
                            if (typeof message.giftWrappingFeeSpecified !== "boolean")
                                return "giftWrappingFeeSpecified: boolean expected";
                        if (message.giftWrappingFee != null && message.hasOwnProperty("giftWrappingFee"))
                            if (!$util.isInteger(message.giftWrappingFee) && !(message.giftWrappingFee && $util.isInteger(message.giftWrappingFee.low) && $util.isInteger(message.giftWrappingFee.high)))
                                return "giftWrappingFee: integer|Long expected";
                        if (message.deliveryFeeSpecified != null && message.hasOwnProperty("deliveryFeeSpecified"))
                            if (typeof message.deliveryFeeSpecified !== "boolean")
                                return "deliveryFeeSpecified: boolean expected";
                        if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                            if (!$util.isInteger(message.deliveryFee) && !(message.deliveryFee && $util.isInteger(message.deliveryFee.low) && $util.isInteger(message.deliveryFee.high)))
                                return "deliveryFee: integer|Long expected";
                        if (message.codFeeSpecified != null && message.hasOwnProperty("codFeeSpecified"))
                            if (typeof message.codFeeSpecified !== "boolean")
                                return "codFeeSpecified: boolean expected";
                        if (message.codFee != null && message.hasOwnProperty("codFee"))
                            if (!$util.isInteger(message.codFee) && !(message.codFee && $util.isInteger(message.codFee.low) && $util.isInteger(message.codFee.high)))
                                return "codFee: integer|Long expected";
                        if (message.hemmingPantsFeeSpecified != null && message.hasOwnProperty("hemmingPantsFeeSpecified"))
                            if (typeof message.hemmingPantsFeeSpecified !== "boolean")
                                return "hemmingPantsFeeSpecified: boolean expected";
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            if (!$util.isInteger(message.hemmingPantsFee) && !(message.hemmingPantsFee && $util.isInteger(message.hemmingPantsFee.low) && $util.isInteger(message.hemmingPantsFee.high)))
                                return "hemmingPantsFee: integer|Long expected";
                        if (message.discountGiftWrappingFeeSpecified != null && message.hasOwnProperty("discountGiftWrappingFeeSpecified"))
                            if (typeof message.discountGiftWrappingFeeSpecified !== "boolean")
                                return "discountGiftWrappingFeeSpecified: boolean expected";
                        if (message.discountGiftWrappingFee != null && message.hasOwnProperty("discountGiftWrappingFee"))
                            if (!$util.isInteger(message.discountGiftWrappingFee) && !(message.discountGiftWrappingFee && $util.isInteger(message.discountGiftWrappingFee.low) && $util.isInteger(message.discountGiftWrappingFee.high)))
                                return "discountGiftWrappingFee: integer|Long expected";
                        if (message.discountDeliveryFeeSpecified != null && message.hasOwnProperty("discountDeliveryFeeSpecified"))
                            if (typeof message.discountDeliveryFeeSpecified !== "boolean")
                                return "discountDeliveryFeeSpecified: boolean expected";
                        if (message.discountDeliveryFee != null && message.hasOwnProperty("discountDeliveryFee"))
                            if (!$util.isInteger(message.discountDeliveryFee) && !(message.discountDeliveryFee && $util.isInteger(message.discountDeliveryFee.low) && $util.isInteger(message.discountDeliveryFee.high)))
                                return "discountDeliveryFee: integer|Long expected";
                        if (message.discountHemmingPantsFeeSpecified != null && message.hasOwnProperty("discountHemmingPantsFeeSpecified"))
                            if (typeof message.discountHemmingPantsFeeSpecified !== "boolean")
                                return "discountHemmingPantsFeeSpecified: boolean expected";
                        if (message.discountHemmingPantsFee != null && message.hasOwnProperty("discountHemmingPantsFee"))
                            if (!$util.isInteger(message.discountHemmingPantsFee) && !(message.discountHemmingPantsFee && $util.isInteger(message.discountHemmingPantsFee.low) && $util.isInteger(message.discountHemmingPantsFee.high)))
                                return "discountHemmingPantsFee: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an OrderFee message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderFee} OrderFee
                     */
                    OrderFee.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderFee)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderFee();
                        if (object.giftWrappingFeeSpecified != null)
                            message.giftWrappingFeeSpecified = Boolean(object.giftWrappingFeeSpecified);
                        if (object.giftWrappingFee != null)
                            if ($util.Long)
                                (message.giftWrappingFee = $util.Long.fromValue(object.giftWrappingFee)).unsigned = true;
                            else if (typeof object.giftWrappingFee === "string")
                                message.giftWrappingFee = parseInt(object.giftWrappingFee, 10);
                            else if (typeof object.giftWrappingFee === "number")
                                message.giftWrappingFee = object.giftWrappingFee;
                            else if (typeof object.giftWrappingFee === "object")
                                message.giftWrappingFee = new $util.LongBits(object.giftWrappingFee.low >>> 0, object.giftWrappingFee.high >>> 0).toNumber(true);
                        if (object.deliveryFeeSpecified != null)
                            message.deliveryFeeSpecified = Boolean(object.deliveryFeeSpecified);
                        if (object.deliveryFee != null)
                            if ($util.Long)
                                (message.deliveryFee = $util.Long.fromValue(object.deliveryFee)).unsigned = true;
                            else if (typeof object.deliveryFee === "string")
                                message.deliveryFee = parseInt(object.deliveryFee, 10);
                            else if (typeof object.deliveryFee === "number")
                                message.deliveryFee = object.deliveryFee;
                            else if (typeof object.deliveryFee === "object")
                                message.deliveryFee = new $util.LongBits(object.deliveryFee.low >>> 0, object.deliveryFee.high >>> 0).toNumber(true);
                        if (object.codFeeSpecified != null)
                            message.codFeeSpecified = Boolean(object.codFeeSpecified);
                        if (object.codFee != null)
                            if ($util.Long)
                                (message.codFee = $util.Long.fromValue(object.codFee)).unsigned = true;
                            else if (typeof object.codFee === "string")
                                message.codFee = parseInt(object.codFee, 10);
                            else if (typeof object.codFee === "number")
                                message.codFee = object.codFee;
                            else if (typeof object.codFee === "object")
                                message.codFee = new $util.LongBits(object.codFee.low >>> 0, object.codFee.high >>> 0).toNumber(true);
                        if (object.hemmingPantsFeeSpecified != null)
                            message.hemmingPantsFeeSpecified = Boolean(object.hemmingPantsFeeSpecified);
                        if (object.hemmingPantsFee != null)
                            if ($util.Long)
                                (message.hemmingPantsFee = $util.Long.fromValue(object.hemmingPantsFee)).unsigned = true;
                            else if (typeof object.hemmingPantsFee === "string")
                                message.hemmingPantsFee = parseInt(object.hemmingPantsFee, 10);
                            else if (typeof object.hemmingPantsFee === "number")
                                message.hemmingPantsFee = object.hemmingPantsFee;
                            else if (typeof object.hemmingPantsFee === "object")
                                message.hemmingPantsFee = new $util.LongBits(object.hemmingPantsFee.low >>> 0, object.hemmingPantsFee.high >>> 0).toNumber(true);
                        if (object.discountGiftWrappingFeeSpecified != null)
                            message.discountGiftWrappingFeeSpecified = Boolean(object.discountGiftWrappingFeeSpecified);
                        if (object.discountGiftWrappingFee != null)
                            if ($util.Long)
                                (message.discountGiftWrappingFee = $util.Long.fromValue(object.discountGiftWrappingFee)).unsigned = true;
                            else if (typeof object.discountGiftWrappingFee === "string")
                                message.discountGiftWrappingFee = parseInt(object.discountGiftWrappingFee, 10);
                            else if (typeof object.discountGiftWrappingFee === "number")
                                message.discountGiftWrappingFee = object.discountGiftWrappingFee;
                            else if (typeof object.discountGiftWrappingFee === "object")
                                message.discountGiftWrappingFee = new $util.LongBits(object.discountGiftWrappingFee.low >>> 0, object.discountGiftWrappingFee.high >>> 0).toNumber(true);
                        if (object.discountDeliveryFeeSpecified != null)
                            message.discountDeliveryFeeSpecified = Boolean(object.discountDeliveryFeeSpecified);
                        if (object.discountDeliveryFee != null)
                            if ($util.Long)
                                (message.discountDeliveryFee = $util.Long.fromValue(object.discountDeliveryFee)).unsigned = true;
                            else if (typeof object.discountDeliveryFee === "string")
                                message.discountDeliveryFee = parseInt(object.discountDeliveryFee, 10);
                            else if (typeof object.discountDeliveryFee === "number")
                                message.discountDeliveryFee = object.discountDeliveryFee;
                            else if (typeof object.discountDeliveryFee === "object")
                                message.discountDeliveryFee = new $util.LongBits(object.discountDeliveryFee.low >>> 0, object.discountDeliveryFee.high >>> 0).toNumber(true);
                        if (object.discountHemmingPantsFeeSpecified != null)
                            message.discountHemmingPantsFeeSpecified = Boolean(object.discountHemmingPantsFeeSpecified);
                        if (object.discountHemmingPantsFee != null)
                            if ($util.Long)
                                (message.discountHemmingPantsFee = $util.Long.fromValue(object.discountHemmingPantsFee)).unsigned = true;
                            else if (typeof object.discountHemmingPantsFee === "string")
                                message.discountHemmingPantsFee = parseInt(object.discountHemmingPantsFee, 10);
                            else if (typeof object.discountHemmingPantsFee === "number")
                                message.discountHemmingPantsFee = object.discountHemmingPantsFee;
                            else if (typeof object.discountHemmingPantsFee === "object")
                                message.discountHemmingPantsFee = new $util.LongBits(object.discountHemmingPantsFee.low >>> 0, object.discountHemmingPantsFee.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderFee message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @static
                     * @param {theplant.ec.service.orders.OrderFee} message OrderFee
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderFee.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.giftWrappingFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.giftWrappingFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.giftWrappingFee = options.longs === String ? "0" : 0;
                            object.deliveryFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.deliveryFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.deliveryFee = options.longs === String ? "0" : 0;
                            object.codFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.codFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.codFee = options.longs === String ? "0" : 0;
                            object.hemmingPantsFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.hemmingPantsFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.hemmingPantsFee = options.longs === String ? "0" : 0;
                            object.discountGiftWrappingFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.discountGiftWrappingFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.discountGiftWrappingFee = options.longs === String ? "0" : 0;
                            object.discountDeliveryFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.discountDeliveryFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.discountDeliveryFee = options.longs === String ? "0" : 0;
                            object.discountHemmingPantsFeeSpecified = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.discountHemmingPantsFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.discountHemmingPantsFee = options.longs === String ? "0" : 0;
                        }
                        if (message.giftWrappingFeeSpecified != null && message.hasOwnProperty("giftWrappingFeeSpecified"))
                            object.giftWrappingFeeSpecified = message.giftWrappingFeeSpecified;
                        if (message.giftWrappingFee != null && message.hasOwnProperty("giftWrappingFee"))
                            if (typeof message.giftWrappingFee === "number")
                                object.giftWrappingFee = options.longs === String ? String(message.giftWrappingFee) : message.giftWrappingFee;
                            else
                                object.giftWrappingFee = options.longs === String ? $util.Long.prototype.toString.call(message.giftWrappingFee) : options.longs === Number ? new $util.LongBits(message.giftWrappingFee.low >>> 0, message.giftWrappingFee.high >>> 0).toNumber(true) : message.giftWrappingFee;
                        if (message.deliveryFeeSpecified != null && message.hasOwnProperty("deliveryFeeSpecified"))
                            object.deliveryFeeSpecified = message.deliveryFeeSpecified;
                        if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                            if (typeof message.deliveryFee === "number")
                                object.deliveryFee = options.longs === String ? String(message.deliveryFee) : message.deliveryFee;
                            else
                                object.deliveryFee = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryFee) : options.longs === Number ? new $util.LongBits(message.deliveryFee.low >>> 0, message.deliveryFee.high >>> 0).toNumber(true) : message.deliveryFee;
                        if (message.codFeeSpecified != null && message.hasOwnProperty("codFeeSpecified"))
                            object.codFeeSpecified = message.codFeeSpecified;
                        if (message.codFee != null && message.hasOwnProperty("codFee"))
                            if (typeof message.codFee === "number")
                                object.codFee = options.longs === String ? String(message.codFee) : message.codFee;
                            else
                                object.codFee = options.longs === String ? $util.Long.prototype.toString.call(message.codFee) : options.longs === Number ? new $util.LongBits(message.codFee.low >>> 0, message.codFee.high >>> 0).toNumber(true) : message.codFee;
                        if (message.hemmingPantsFeeSpecified != null && message.hasOwnProperty("hemmingPantsFeeSpecified"))
                            object.hemmingPantsFeeSpecified = message.hemmingPantsFeeSpecified;
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            if (typeof message.hemmingPantsFee === "number")
                                object.hemmingPantsFee = options.longs === String ? String(message.hemmingPantsFee) : message.hemmingPantsFee;
                            else
                                object.hemmingPantsFee = options.longs === String ? $util.Long.prototype.toString.call(message.hemmingPantsFee) : options.longs === Number ? new $util.LongBits(message.hemmingPantsFee.low >>> 0, message.hemmingPantsFee.high >>> 0).toNumber(true) : message.hemmingPantsFee;
                        if (message.discountGiftWrappingFeeSpecified != null && message.hasOwnProperty("discountGiftWrappingFeeSpecified"))
                            object.discountGiftWrappingFeeSpecified = message.discountGiftWrappingFeeSpecified;
                        if (message.discountGiftWrappingFee != null && message.hasOwnProperty("discountGiftWrappingFee"))
                            if (typeof message.discountGiftWrappingFee === "number")
                                object.discountGiftWrappingFee = options.longs === String ? String(message.discountGiftWrappingFee) : message.discountGiftWrappingFee;
                            else
                                object.discountGiftWrappingFee = options.longs === String ? $util.Long.prototype.toString.call(message.discountGiftWrappingFee) : options.longs === Number ? new $util.LongBits(message.discountGiftWrappingFee.low >>> 0, message.discountGiftWrappingFee.high >>> 0).toNumber(true) : message.discountGiftWrappingFee;
                        if (message.discountDeliveryFeeSpecified != null && message.hasOwnProperty("discountDeliveryFeeSpecified"))
                            object.discountDeliveryFeeSpecified = message.discountDeliveryFeeSpecified;
                        if (message.discountDeliveryFee != null && message.hasOwnProperty("discountDeliveryFee"))
                            if (typeof message.discountDeliveryFee === "number")
                                object.discountDeliveryFee = options.longs === String ? String(message.discountDeliveryFee) : message.discountDeliveryFee;
                            else
                                object.discountDeliveryFee = options.longs === String ? $util.Long.prototype.toString.call(message.discountDeliveryFee) : options.longs === Number ? new $util.LongBits(message.discountDeliveryFee.low >>> 0, message.discountDeliveryFee.high >>> 0).toNumber(true) : message.discountDeliveryFee;
                        if (message.discountHemmingPantsFeeSpecified != null && message.hasOwnProperty("discountHemmingPantsFeeSpecified"))
                            object.discountHemmingPantsFeeSpecified = message.discountHemmingPantsFeeSpecified;
                        if (message.discountHemmingPantsFee != null && message.hasOwnProperty("discountHemmingPantsFee"))
                            if (typeof message.discountHemmingPantsFee === "number")
                                object.discountHemmingPantsFee = options.longs === String ? String(message.discountHemmingPantsFee) : message.discountHemmingPantsFee;
                            else
                                object.discountHemmingPantsFee = options.longs === String ? $util.Long.prototype.toString.call(message.discountHemmingPantsFee) : options.longs === Number ? new $util.LongBits(message.discountHemmingPantsFee.low >>> 0, message.discountHemmingPantsFee.high >>> 0).toNumber(true) : message.discountHemmingPantsFee;
                        return object;
                    };

                    /**
                     * Converts this OrderFee to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderFee
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderFee.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderFee;
                })();

                orders.OrderItem = (function() {

                    /**
                     * Properties of an OrderItem.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderItem
                     * @property {string|null} [articleCode] OrderItem articleCode
                     * @property {string|null} [productCode] OrderItem productCode
                     * @property {number|Long|null} [price] OrderItem price
                     * @property {number|Long|null} [sellingPrice] OrderItem sellingPrice
                     * @property {number|Long|null} [quantity] OrderItem quantity
                     * @property {number|Long|null} [quantityInStock] OrderItem quantityInStock
                     * @property {string|null} [productName] OrderItem productName
                     * @property {string|null} [productImageUrl] OrderItem productImageUrl
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [discountContexts] OrderItem discountContexts
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [extraProperties] OrderItem extraProperties
                     * @property {theplant.ec.service.accounting.IItemSumUp|null} [sumUp] OrderItem sumUp
                     * @property {theplant.ec.service.orders.OrderItem.IAfterSaleChanged|null} [afterSaleChanged] OrderItem afterSaleChanged
                     * @property {Array.<theplant.ec.service.orders.IRestrictedReason>|null} [restrictedReasons] OrderItem restrictedReasons
                     * @property {number|Long|null} [volumeCoefficient] OrderItem volumeCoefficient
                     * @property {string|null} [dimensionAfterHemming] OrderItem dimensionAfterHemming
                     * @property {boolean|null} [isShipFromStore] OrderItem isShipFromStore
                     * @property {string|null} [shipFromStoreStoreId] OrderItem shipFromStoreStoreId
                     * @property {string|null} [shipFromStoreState] OrderItem shipFromStoreState
                     * @property {string|null} [shipFromStoreShipmentId] OrderItem shipFromStoreShipmentId
                     * @property {string|null} [shipFromStoreShippedAt] OrderItem shipFromStoreShippedAt
                     * @property {theplant.ec.service.orders.IReturnReason|null} [returnReason] OrderItem returnReason
                     * @property {boolean|null} [requestReturn] OrderItem requestReturn
                     * @property {string|null} [cancelReason] OrderItem cancelReason
                     * @property {boolean|null} [isEcReturnExported] OrderItem isEcReturnExported
                     * @property {string|null} [productDisplayCode] OrderItem productDisplayCode
                     * @property {theplant.ec.service.customization.ICustomization|null} [customization] OrderItem customization
                     */

                    /**
                     * Constructs a new OrderItem.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderItem.
                     * @implements IOrderItem
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderItem=} [properties] Properties to set
                     */
                    function OrderItem(properties) {
                        this.discountContexts = [];
                        this.extraProperties = [];
                        this.restrictedReasons = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderItem articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.articleCode = "";

                    /**
                     * OrderItem productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.productCode = "";

                    /**
                     * OrderItem price.
                     * @member {number|Long} price
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderItem sellingPrice.
                     * @member {number|Long} sellingPrice
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.sellingPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderItem quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderItem quantityInStock.
                     * @member {number|Long} quantityInStock
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.quantityInStock = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * OrderItem productName.
                     * @member {string} productName
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.productName = "";

                    /**
                     * OrderItem productImageUrl.
                     * @member {string} productImageUrl
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.productImageUrl = "";

                    /**
                     * OrderItem discountContexts.
                     * @member {Array.<theplant.ec.service.base.IProperty>} discountContexts
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.discountContexts = $util.emptyArray;

                    /**
                     * OrderItem extraProperties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} extraProperties
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.extraProperties = $util.emptyArray;

                    /**
                     * OrderItem sumUp.
                     * @member {theplant.ec.service.accounting.IItemSumUp|null|undefined} sumUp
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.sumUp = null;

                    /**
                     * OrderItem afterSaleChanged.
                     * @member {theplant.ec.service.orders.OrderItem.IAfterSaleChanged|null|undefined} afterSaleChanged
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.afterSaleChanged = null;

                    /**
                     * OrderItem restrictedReasons.
                     * @member {Array.<theplant.ec.service.orders.IRestrictedReason>} restrictedReasons
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.restrictedReasons = $util.emptyArray;

                    /**
                     * OrderItem volumeCoefficient.
                     * @member {number|Long} volumeCoefficient
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.volumeCoefficient = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * OrderItem dimensionAfterHemming.
                     * @member {string} dimensionAfterHemming
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.dimensionAfterHemming = "";

                    /**
                     * OrderItem isShipFromStore.
                     * @member {boolean} isShipFromStore
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.isShipFromStore = false;

                    /**
                     * OrderItem shipFromStoreStoreId.
                     * @member {string} shipFromStoreStoreId
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.shipFromStoreStoreId = "";

                    /**
                     * OrderItem shipFromStoreState.
                     * @member {string} shipFromStoreState
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.shipFromStoreState = "";

                    /**
                     * OrderItem shipFromStoreShipmentId.
                     * @member {string} shipFromStoreShipmentId
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.shipFromStoreShipmentId = "";

                    /**
                     * OrderItem shipFromStoreShippedAt.
                     * @member {string} shipFromStoreShippedAt
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.shipFromStoreShippedAt = "";

                    /**
                     * OrderItem returnReason.
                     * @member {theplant.ec.service.orders.IReturnReason|null|undefined} returnReason
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.returnReason = null;

                    /**
                     * OrderItem requestReturn.
                     * @member {boolean} requestReturn
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.requestReturn = false;

                    /**
                     * OrderItem cancelReason.
                     * @member {string} cancelReason
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.cancelReason = "";

                    /**
                     * OrderItem isEcReturnExported.
                     * @member {boolean} isEcReturnExported
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.isEcReturnExported = false;

                    /**
                     * OrderItem productDisplayCode.
                     * @member {string} productDisplayCode
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.productDisplayCode = "";

                    /**
                     * OrderItem customization.
                     * @member {theplant.ec.service.customization.ICustomization|null|undefined} customization
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     */
                    OrderItem.prototype.customization = null;

                    /**
                     * Creates a new OrderItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {theplant.ec.service.orders.IOrderItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderItem} OrderItem instance
                     */
                    OrderItem.create = function create(properties) {
                        return new OrderItem(properties);
                    };

                    /**
                     * Encodes the specified OrderItem message. Does not implicitly {@link theplant.ec.service.orders.OrderItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {theplant.ec.service.orders.IOrderItem} message OrderItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.productCode);
                        if (message.price != null && message.hasOwnProperty("price"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.price);
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.sellingPrice);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.quantity);
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.productName);
                        if (message.productImageUrl != null && message.hasOwnProperty("productImageUrl"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.productImageUrl);
                        if (message.discountContexts != null && message.discountContexts.length)
                            for (var i = 0; i < message.discountContexts.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.discountContexts[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.extraProperties != null && message.extraProperties.length)
                            for (var i = 0; i < message.extraProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.extraProperties[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.sumUp != null && message.hasOwnProperty("sumUp"))
                            $root.theplant.ec.service.accounting.ItemSumUp.encode(message.sumUp, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.afterSaleChanged != null && message.hasOwnProperty("afterSaleChanged"))
                            $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged.encode(message.afterSaleChanged, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.quantityInStock != null && message.hasOwnProperty("quantityInStock"))
                            writer.uint32(/* id 12, wireType 0 =*/96).uint64(message.quantityInStock);
                        if (message.restrictedReasons != null && message.restrictedReasons.length)
                            for (var i = 0; i < message.restrictedReasons.length; ++i)
                                $root.theplant.ec.service.orders.RestrictedReason.encode(message.restrictedReasons[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.volumeCoefficient != null && message.hasOwnProperty("volumeCoefficient"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.volumeCoefficient);
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.dimensionAfterHemming);
                        if (message.returnReason != null && message.hasOwnProperty("returnReason"))
                            $root.theplant.ec.service.orders.ReturnReason.encode(message.returnReason, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.requestReturn != null && message.hasOwnProperty("requestReturn"))
                            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.requestReturn);
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isShipFromStore);
                        if (message.shipFromStoreStoreId != null && message.hasOwnProperty("shipFromStoreStoreId"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.shipFromStoreStoreId);
                        if (message.shipFromStoreState != null && message.hasOwnProperty("shipFromStoreState"))
                            writer.uint32(/* id 20, wireType 2 =*/162).string(message.shipFromStoreState);
                        if (message.shipFromStoreShipmentId != null && message.hasOwnProperty("shipFromStoreShipmentId"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.shipFromStoreShipmentId);
                        if (message.shipFromStoreShippedAt != null && message.hasOwnProperty("shipFromStoreShippedAt"))
                            writer.uint32(/* id 22, wireType 2 =*/178).string(message.shipFromStoreShippedAt);
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.cancelReason);
                        if (message.isEcReturnExported != null && message.hasOwnProperty("isEcReturnExported"))
                            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.isEcReturnExported);
                        if (message.productDisplayCode != null && message.hasOwnProperty("productDisplayCode"))
                            writer.uint32(/* id 25, wireType 2 =*/202).string(message.productDisplayCode);
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            $root.theplant.ec.service.customization.Customization.encode(message.customization, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderItem message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {theplant.ec.service.orders.IOrderItem} message OrderItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderItem} OrderItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.productCode = reader.string();
                                break;
                            case 3:
                                message.price = reader.uint64();
                                break;
                            case 4:
                                message.sellingPrice = reader.uint64();
                                break;
                            case 5:
                                message.quantity = reader.uint64();
                                break;
                            case 12:
                                message.quantityInStock = reader.uint64();
                                break;
                            case 6:
                                message.productName = reader.string();
                                break;
                            case 7:
                                message.productImageUrl = reader.string();
                                break;
                            case 8:
                                if (!(message.discountContexts && message.discountContexts.length))
                                    message.discountContexts = [];
                                message.discountContexts.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                if (!(message.extraProperties && message.extraProperties.length))
                                    message.extraProperties = [];
                                message.extraProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                message.sumUp = $root.theplant.ec.service.accounting.ItemSumUp.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.afterSaleChanged = $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged.decode(reader, reader.uint32());
                                break;
                            case 13:
                                if (!(message.restrictedReasons && message.restrictedReasons.length))
                                    message.restrictedReasons = [];
                                message.restrictedReasons.push($root.theplant.ec.service.orders.RestrictedReason.decode(reader, reader.uint32()));
                                break;
                            case 14:
                                message.volumeCoefficient = reader.int64();
                                break;
                            case 15:
                                message.dimensionAfterHemming = reader.string();
                                break;
                            case 18:
                                message.isShipFromStore = reader.bool();
                                break;
                            case 19:
                                message.shipFromStoreStoreId = reader.string();
                                break;
                            case 20:
                                message.shipFromStoreState = reader.string();
                                break;
                            case 21:
                                message.shipFromStoreShipmentId = reader.string();
                                break;
                            case 22:
                                message.shipFromStoreShippedAt = reader.string();
                                break;
                            case 16:
                                message.returnReason = $root.theplant.ec.service.orders.ReturnReason.decode(reader, reader.uint32());
                                break;
                            case 17:
                                message.requestReturn = reader.bool();
                                break;
                            case 23:
                                message.cancelReason = reader.string();
                                break;
                            case 24:
                                message.isEcReturnExported = reader.bool();
                                break;
                            case 25:
                                message.productDisplayCode = reader.string();
                                break;
                            case 26:
                                message.customization = $root.theplant.ec.service.customization.Customization.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderItem} OrderItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderItem message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                return "price: integer|Long expected";
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (!$util.isInteger(message.sellingPrice) && !(message.sellingPrice && $util.isInteger(message.sellingPrice.low) && $util.isInteger(message.sellingPrice.high)))
                                return "sellingPrice: integer|Long expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.quantityInStock != null && message.hasOwnProperty("quantityInStock"))
                            if (!$util.isInteger(message.quantityInStock) && !(message.quantityInStock && $util.isInteger(message.quantityInStock.low) && $util.isInteger(message.quantityInStock.high)))
                                return "quantityInStock: integer|Long expected";
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            if (!$util.isString(message.productName))
                                return "productName: string expected";
                        if (message.productImageUrl != null && message.hasOwnProperty("productImageUrl"))
                            if (!$util.isString(message.productImageUrl))
                                return "productImageUrl: string expected";
                        if (message.discountContexts != null && message.hasOwnProperty("discountContexts")) {
                            if (!Array.isArray(message.discountContexts))
                                return "discountContexts: array expected";
                            for (var i = 0; i < message.discountContexts.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.discountContexts[i]);
                                if (error)
                                    return "discountContexts." + error;
                            }
                        }
                        if (message.extraProperties != null && message.hasOwnProperty("extraProperties")) {
                            if (!Array.isArray(message.extraProperties))
                                return "extraProperties: array expected";
                            for (var i = 0; i < message.extraProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.extraProperties[i]);
                                if (error)
                                    return "extraProperties." + error;
                            }
                        }
                        if (message.sumUp != null && message.hasOwnProperty("sumUp")) {
                            var error = $root.theplant.ec.service.accounting.ItemSumUp.verify(message.sumUp);
                            if (error)
                                return "sumUp." + error;
                        }
                        if (message.afterSaleChanged != null && message.hasOwnProperty("afterSaleChanged")) {
                            var error = $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged.verify(message.afterSaleChanged);
                            if (error)
                                return "afterSaleChanged." + error;
                        }
                        if (message.restrictedReasons != null && message.hasOwnProperty("restrictedReasons")) {
                            if (!Array.isArray(message.restrictedReasons))
                                return "restrictedReasons: array expected";
                            for (var i = 0; i < message.restrictedReasons.length; ++i) {
                                var error = $root.theplant.ec.service.orders.RestrictedReason.verify(message.restrictedReasons[i]);
                                if (error)
                                    return "restrictedReasons." + error;
                            }
                        }
                        if (message.volumeCoefficient != null && message.hasOwnProperty("volumeCoefficient"))
                            if (!$util.isInteger(message.volumeCoefficient) && !(message.volumeCoefficient && $util.isInteger(message.volumeCoefficient.low) && $util.isInteger(message.volumeCoefficient.high)))
                                return "volumeCoefficient: integer|Long expected";
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            if (!$util.isString(message.dimensionAfterHemming))
                                return "dimensionAfterHemming: string expected";
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            if (typeof message.isShipFromStore !== "boolean")
                                return "isShipFromStore: boolean expected";
                        if (message.shipFromStoreStoreId != null && message.hasOwnProperty("shipFromStoreStoreId"))
                            if (!$util.isString(message.shipFromStoreStoreId))
                                return "shipFromStoreStoreId: string expected";
                        if (message.shipFromStoreState != null && message.hasOwnProperty("shipFromStoreState"))
                            if (!$util.isString(message.shipFromStoreState))
                                return "shipFromStoreState: string expected";
                        if (message.shipFromStoreShipmentId != null && message.hasOwnProperty("shipFromStoreShipmentId"))
                            if (!$util.isString(message.shipFromStoreShipmentId))
                                return "shipFromStoreShipmentId: string expected";
                        if (message.shipFromStoreShippedAt != null && message.hasOwnProperty("shipFromStoreShippedAt"))
                            if (!$util.isString(message.shipFromStoreShippedAt))
                                return "shipFromStoreShippedAt: string expected";
                        if (message.returnReason != null && message.hasOwnProperty("returnReason")) {
                            var error = $root.theplant.ec.service.orders.ReturnReason.verify(message.returnReason);
                            if (error)
                                return "returnReason." + error;
                        }
                        if (message.requestReturn != null && message.hasOwnProperty("requestReturn"))
                            if (typeof message.requestReturn !== "boolean")
                                return "requestReturn: boolean expected";
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            if (!$util.isString(message.cancelReason))
                                return "cancelReason: string expected";
                        if (message.isEcReturnExported != null && message.hasOwnProperty("isEcReturnExported"))
                            if (typeof message.isEcReturnExported !== "boolean")
                                return "isEcReturnExported: boolean expected";
                        if (message.productDisplayCode != null && message.hasOwnProperty("productDisplayCode"))
                            if (!$util.isString(message.productDisplayCode))
                                return "productDisplayCode: string expected";
                        if (message.customization != null && message.hasOwnProperty("customization")) {
                            var error = $root.theplant.ec.service.customization.Customization.verify(message.customization);
                            if (error)
                                return "customization." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderItem} OrderItem
                     */
                    OrderItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderItem)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderItem();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.price != null)
                            if ($util.Long)
                                (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                            else if (typeof object.price === "string")
                                message.price = parseInt(object.price, 10);
                            else if (typeof object.price === "number")
                                message.price = object.price;
                            else if (typeof object.price === "object")
                                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                        if (object.sellingPrice != null)
                            if ($util.Long)
                                (message.sellingPrice = $util.Long.fromValue(object.sellingPrice)).unsigned = true;
                            else if (typeof object.sellingPrice === "string")
                                message.sellingPrice = parseInt(object.sellingPrice, 10);
                            else if (typeof object.sellingPrice === "number")
                                message.sellingPrice = object.sellingPrice;
                            else if (typeof object.sellingPrice === "object")
                                message.sellingPrice = new $util.LongBits(object.sellingPrice.low >>> 0, object.sellingPrice.high >>> 0).toNumber(true);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = true;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber(true);
                        if (object.quantityInStock != null)
                            if ($util.Long)
                                (message.quantityInStock = $util.Long.fromValue(object.quantityInStock)).unsigned = true;
                            else if (typeof object.quantityInStock === "string")
                                message.quantityInStock = parseInt(object.quantityInStock, 10);
                            else if (typeof object.quantityInStock === "number")
                                message.quantityInStock = object.quantityInStock;
                            else if (typeof object.quantityInStock === "object")
                                message.quantityInStock = new $util.LongBits(object.quantityInStock.low >>> 0, object.quantityInStock.high >>> 0).toNumber(true);
                        if (object.productName != null)
                            message.productName = String(object.productName);
                        if (object.productImageUrl != null)
                            message.productImageUrl = String(object.productImageUrl);
                        if (object.discountContexts) {
                            if (!Array.isArray(object.discountContexts))
                                throw TypeError(".theplant.ec.service.orders.OrderItem.discountContexts: array expected");
                            message.discountContexts = [];
                            for (var i = 0; i < object.discountContexts.length; ++i) {
                                if (typeof object.discountContexts[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.OrderItem.discountContexts: object expected");
                                message.discountContexts[i] = $root.theplant.ec.service.base.Property.fromObject(object.discountContexts[i]);
                            }
                        }
                        if (object.extraProperties) {
                            if (!Array.isArray(object.extraProperties))
                                throw TypeError(".theplant.ec.service.orders.OrderItem.extraProperties: array expected");
                            message.extraProperties = [];
                            for (var i = 0; i < object.extraProperties.length; ++i) {
                                if (typeof object.extraProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.OrderItem.extraProperties: object expected");
                                message.extraProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.extraProperties[i]);
                            }
                        }
                        if (object.sumUp != null) {
                            if (typeof object.sumUp !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderItem.sumUp: object expected");
                            message.sumUp = $root.theplant.ec.service.accounting.ItemSumUp.fromObject(object.sumUp);
                        }
                        if (object.afterSaleChanged != null) {
                            if (typeof object.afterSaleChanged !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderItem.afterSaleChanged: object expected");
                            message.afterSaleChanged = $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged.fromObject(object.afterSaleChanged);
                        }
                        if (object.restrictedReasons) {
                            if (!Array.isArray(object.restrictedReasons))
                                throw TypeError(".theplant.ec.service.orders.OrderItem.restrictedReasons: array expected");
                            message.restrictedReasons = [];
                            for (var i = 0; i < object.restrictedReasons.length; ++i) {
                                if (typeof object.restrictedReasons[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.OrderItem.restrictedReasons: object expected");
                                message.restrictedReasons[i] = $root.theplant.ec.service.orders.RestrictedReason.fromObject(object.restrictedReasons[i]);
                            }
                        }
                        if (object.volumeCoefficient != null)
                            if ($util.Long)
                                (message.volumeCoefficient = $util.Long.fromValue(object.volumeCoefficient)).unsigned = false;
                            else if (typeof object.volumeCoefficient === "string")
                                message.volumeCoefficient = parseInt(object.volumeCoefficient, 10);
                            else if (typeof object.volumeCoefficient === "number")
                                message.volumeCoefficient = object.volumeCoefficient;
                            else if (typeof object.volumeCoefficient === "object")
                                message.volumeCoefficient = new $util.LongBits(object.volumeCoefficient.low >>> 0, object.volumeCoefficient.high >>> 0).toNumber();
                        if (object.dimensionAfterHemming != null)
                            message.dimensionAfterHemming = String(object.dimensionAfterHemming);
                        if (object.isShipFromStore != null)
                            message.isShipFromStore = Boolean(object.isShipFromStore);
                        if (object.shipFromStoreStoreId != null)
                            message.shipFromStoreStoreId = String(object.shipFromStoreStoreId);
                        if (object.shipFromStoreState != null)
                            message.shipFromStoreState = String(object.shipFromStoreState);
                        if (object.shipFromStoreShipmentId != null)
                            message.shipFromStoreShipmentId = String(object.shipFromStoreShipmentId);
                        if (object.shipFromStoreShippedAt != null)
                            message.shipFromStoreShippedAt = String(object.shipFromStoreShippedAt);
                        if (object.returnReason != null) {
                            if (typeof object.returnReason !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderItem.returnReason: object expected");
                            message.returnReason = $root.theplant.ec.service.orders.ReturnReason.fromObject(object.returnReason);
                        }
                        if (object.requestReturn != null)
                            message.requestReturn = Boolean(object.requestReturn);
                        if (object.cancelReason != null)
                            message.cancelReason = String(object.cancelReason);
                        if (object.isEcReturnExported != null)
                            message.isEcReturnExported = Boolean(object.isEcReturnExported);
                        if (object.productDisplayCode != null)
                            message.productDisplayCode = String(object.productDisplayCode);
                        if (object.customization != null) {
                            if (typeof object.customization !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderItem.customization: object expected");
                            message.customization = $root.theplant.ec.service.customization.Customization.fromObject(object.customization);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @static
                     * @param {theplant.ec.service.orders.OrderItem} message OrderItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.discountContexts = [];
                            object.extraProperties = [];
                            object.restrictedReasons = [];
                        }
                        if (options.defaults) {
                            object.articleCode = "";
                            object.productCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.price = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.sellingPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sellingPrice = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            object.productName = "";
                            object.productImageUrl = "";
                            object.sumUp = null;
                            object.afterSaleChanged = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quantityInStock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantityInStock = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.volumeCoefficient = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.volumeCoefficient = options.longs === String ? "0" : 0;
                            object.dimensionAfterHemming = "";
                            object.returnReason = null;
                            object.requestReturn = false;
                            object.isShipFromStore = false;
                            object.shipFromStoreStoreId = "";
                            object.shipFromStoreState = "";
                            object.shipFromStoreShipmentId = "";
                            object.shipFromStoreShippedAt = "";
                            object.cancelReason = "";
                            object.isEcReturnExported = false;
                            object.productDisplayCode = "";
                            object.customization = null;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (typeof message.price === "number")
                                object.price = options.longs === String ? String(message.price) : message.price;
                            else
                                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (typeof message.sellingPrice === "number")
                                object.sellingPrice = options.longs === String ? String(message.sellingPrice) : message.sellingPrice;
                            else
                                object.sellingPrice = options.longs === String ? $util.Long.prototype.toString.call(message.sellingPrice) : options.longs === Number ? new $util.LongBits(message.sellingPrice.low >>> 0, message.sellingPrice.high >>> 0).toNumber(true) : message.sellingPrice;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber(true) : message.quantity;
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            object.productName = message.productName;
                        if (message.productImageUrl != null && message.hasOwnProperty("productImageUrl"))
                            object.productImageUrl = message.productImageUrl;
                        if (message.discountContexts && message.discountContexts.length) {
                            object.discountContexts = [];
                            for (var j = 0; j < message.discountContexts.length; ++j)
                                object.discountContexts[j] = $root.theplant.ec.service.base.Property.toObject(message.discountContexts[j], options);
                        }
                        if (message.extraProperties && message.extraProperties.length) {
                            object.extraProperties = [];
                            for (var j = 0; j < message.extraProperties.length; ++j)
                                object.extraProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.extraProperties[j], options);
                        }
                        if (message.sumUp != null && message.hasOwnProperty("sumUp"))
                            object.sumUp = $root.theplant.ec.service.accounting.ItemSumUp.toObject(message.sumUp, options);
                        if (message.afterSaleChanged != null && message.hasOwnProperty("afterSaleChanged"))
                            object.afterSaleChanged = $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged.toObject(message.afterSaleChanged, options);
                        if (message.quantityInStock != null && message.hasOwnProperty("quantityInStock"))
                            if (typeof message.quantityInStock === "number")
                                object.quantityInStock = options.longs === String ? String(message.quantityInStock) : message.quantityInStock;
                            else
                                object.quantityInStock = options.longs === String ? $util.Long.prototype.toString.call(message.quantityInStock) : options.longs === Number ? new $util.LongBits(message.quantityInStock.low >>> 0, message.quantityInStock.high >>> 0).toNumber(true) : message.quantityInStock;
                        if (message.restrictedReasons && message.restrictedReasons.length) {
                            object.restrictedReasons = [];
                            for (var j = 0; j < message.restrictedReasons.length; ++j)
                                object.restrictedReasons[j] = $root.theplant.ec.service.orders.RestrictedReason.toObject(message.restrictedReasons[j], options);
                        }
                        if (message.volumeCoefficient != null && message.hasOwnProperty("volumeCoefficient"))
                            if (typeof message.volumeCoefficient === "number")
                                object.volumeCoefficient = options.longs === String ? String(message.volumeCoefficient) : message.volumeCoefficient;
                            else
                                object.volumeCoefficient = options.longs === String ? $util.Long.prototype.toString.call(message.volumeCoefficient) : options.longs === Number ? new $util.LongBits(message.volumeCoefficient.low >>> 0, message.volumeCoefficient.high >>> 0).toNumber() : message.volumeCoefficient;
                        if (message.dimensionAfterHemming != null && message.hasOwnProperty("dimensionAfterHemming"))
                            object.dimensionAfterHemming = message.dimensionAfterHemming;
                        if (message.returnReason != null && message.hasOwnProperty("returnReason"))
                            object.returnReason = $root.theplant.ec.service.orders.ReturnReason.toObject(message.returnReason, options);
                        if (message.requestReturn != null && message.hasOwnProperty("requestReturn"))
                            object.requestReturn = message.requestReturn;
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            object.isShipFromStore = message.isShipFromStore;
                        if (message.shipFromStoreStoreId != null && message.hasOwnProperty("shipFromStoreStoreId"))
                            object.shipFromStoreStoreId = message.shipFromStoreStoreId;
                        if (message.shipFromStoreState != null && message.hasOwnProperty("shipFromStoreState"))
                            object.shipFromStoreState = message.shipFromStoreState;
                        if (message.shipFromStoreShipmentId != null && message.hasOwnProperty("shipFromStoreShipmentId"))
                            object.shipFromStoreShipmentId = message.shipFromStoreShipmentId;
                        if (message.shipFromStoreShippedAt != null && message.hasOwnProperty("shipFromStoreShippedAt"))
                            object.shipFromStoreShippedAt = message.shipFromStoreShippedAt;
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            object.cancelReason = message.cancelReason;
                        if (message.isEcReturnExported != null && message.hasOwnProperty("isEcReturnExported"))
                            object.isEcReturnExported = message.isEcReturnExported;
                        if (message.productDisplayCode != null && message.hasOwnProperty("productDisplayCode"))
                            object.productDisplayCode = message.productDisplayCode;
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            object.customization = $root.theplant.ec.service.customization.Customization.toObject(message.customization, options);
                        return object;
                    };

                    /**
                     * Converts this OrderItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    OrderItem.AfterSaleChanged = (function() {

                        /**
                         * Properties of an AfterSaleChanged.
                         * @memberof theplant.ec.service.orders.OrderItem
                         * @interface IAfterSaleChanged
                         * @property {number|Long|null} [returnedQuantity] AfterSaleChanged returnedQuantity
                         * @property {number|Long|null} [cancelledQuantity] AfterSaleChanged cancelledQuantity
                         * @property {theplant.ec.service.accounting.IItemSumUp|null} [patchedSumUp] AfterSaleChanged patchedSumUp
                         */

                        /**
                         * Constructs a new AfterSaleChanged.
                         * @memberof theplant.ec.service.orders.OrderItem
                         * @classdesc Represents an AfterSaleChanged.
                         * @implements IAfterSaleChanged
                         * @constructor
                         * @param {theplant.ec.service.orders.OrderItem.IAfterSaleChanged=} [properties] Properties to set
                         */
                        function AfterSaleChanged(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AfterSaleChanged returnedQuantity.
                         * @member {number|Long} returnedQuantity
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @instance
                         */
                        AfterSaleChanged.prototype.returnedQuantity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * AfterSaleChanged cancelledQuantity.
                         * @member {number|Long} cancelledQuantity
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @instance
                         */
                        AfterSaleChanged.prototype.cancelledQuantity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * AfterSaleChanged patchedSumUp.
                         * @member {theplant.ec.service.accounting.IItemSumUp|null|undefined} patchedSumUp
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @instance
                         */
                        AfterSaleChanged.prototype.patchedSumUp = null;

                        /**
                         * Creates a new AfterSaleChanged instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {theplant.ec.service.orders.OrderItem.IAfterSaleChanged=} [properties] Properties to set
                         * @returns {theplant.ec.service.orders.OrderItem.AfterSaleChanged} AfterSaleChanged instance
                         */
                        AfterSaleChanged.create = function create(properties) {
                            return new AfterSaleChanged(properties);
                        };

                        /**
                         * Encodes the specified AfterSaleChanged message. Does not implicitly {@link theplant.ec.service.orders.OrderItem.AfterSaleChanged.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {theplant.ec.service.orders.OrderItem.IAfterSaleChanged} message AfterSaleChanged message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AfterSaleChanged.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.returnedQuantity != null && message.hasOwnProperty("returnedQuantity"))
                                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.returnedQuantity);
                            if (message.cancelledQuantity != null && message.hasOwnProperty("cancelledQuantity"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.cancelledQuantity);
                            if (message.patchedSumUp != null && message.hasOwnProperty("patchedSumUp"))
                                $root.theplant.ec.service.accounting.ItemSumUp.encode(message.patchedSumUp, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified AfterSaleChanged message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderItem.AfterSaleChanged.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {theplant.ec.service.orders.OrderItem.IAfterSaleChanged} message AfterSaleChanged message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AfterSaleChanged.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AfterSaleChanged message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.orders.OrderItem.AfterSaleChanged} AfterSaleChanged
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AfterSaleChanged.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.returnedQuantity = reader.uint64();
                                    break;
                                case 2:
                                    message.cancelledQuantity = reader.uint64();
                                    break;
                                case 10:
                                    message.patchedSumUp = $root.theplant.ec.service.accounting.ItemSumUp.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AfterSaleChanged message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.orders.OrderItem.AfterSaleChanged} AfterSaleChanged
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AfterSaleChanged.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AfterSaleChanged message.
                         * @function verify
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AfterSaleChanged.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.returnedQuantity != null && message.hasOwnProperty("returnedQuantity"))
                                if (!$util.isInteger(message.returnedQuantity) && !(message.returnedQuantity && $util.isInteger(message.returnedQuantity.low) && $util.isInteger(message.returnedQuantity.high)))
                                    return "returnedQuantity: integer|Long expected";
                            if (message.cancelledQuantity != null && message.hasOwnProperty("cancelledQuantity"))
                                if (!$util.isInteger(message.cancelledQuantity) && !(message.cancelledQuantity && $util.isInteger(message.cancelledQuantity.low) && $util.isInteger(message.cancelledQuantity.high)))
                                    return "cancelledQuantity: integer|Long expected";
                            if (message.patchedSumUp != null && message.hasOwnProperty("patchedSumUp")) {
                                var error = $root.theplant.ec.service.accounting.ItemSumUp.verify(message.patchedSumUp);
                                if (error)
                                    return "patchedSumUp." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an AfterSaleChanged message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.orders.OrderItem.AfterSaleChanged} AfterSaleChanged
                         */
                        AfterSaleChanged.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged)
                                return object;
                            var message = new $root.theplant.ec.service.orders.OrderItem.AfterSaleChanged();
                            if (object.returnedQuantity != null)
                                if ($util.Long)
                                    (message.returnedQuantity = $util.Long.fromValue(object.returnedQuantity)).unsigned = true;
                                else if (typeof object.returnedQuantity === "string")
                                    message.returnedQuantity = parseInt(object.returnedQuantity, 10);
                                else if (typeof object.returnedQuantity === "number")
                                    message.returnedQuantity = object.returnedQuantity;
                                else if (typeof object.returnedQuantity === "object")
                                    message.returnedQuantity = new $util.LongBits(object.returnedQuantity.low >>> 0, object.returnedQuantity.high >>> 0).toNumber(true);
                            if (object.cancelledQuantity != null)
                                if ($util.Long)
                                    (message.cancelledQuantity = $util.Long.fromValue(object.cancelledQuantity)).unsigned = true;
                                else if (typeof object.cancelledQuantity === "string")
                                    message.cancelledQuantity = parseInt(object.cancelledQuantity, 10);
                                else if (typeof object.cancelledQuantity === "number")
                                    message.cancelledQuantity = object.cancelledQuantity;
                                else if (typeof object.cancelledQuantity === "object")
                                    message.cancelledQuantity = new $util.LongBits(object.cancelledQuantity.low >>> 0, object.cancelledQuantity.high >>> 0).toNumber(true);
                            if (object.patchedSumUp != null) {
                                if (typeof object.patchedSumUp !== "object")
                                    throw TypeError(".theplant.ec.service.orders.OrderItem.AfterSaleChanged.patchedSumUp: object expected");
                                message.patchedSumUp = $root.theplant.ec.service.accounting.ItemSumUp.fromObject(object.patchedSumUp);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an AfterSaleChanged message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @static
                         * @param {theplant.ec.service.orders.OrderItem.AfterSaleChanged} message AfterSaleChanged
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AfterSaleChanged.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.returnedQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.returnedQuantity = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.cancelledQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.cancelledQuantity = options.longs === String ? "0" : 0;
                                object.patchedSumUp = null;
                            }
                            if (message.returnedQuantity != null && message.hasOwnProperty("returnedQuantity"))
                                if (typeof message.returnedQuantity === "number")
                                    object.returnedQuantity = options.longs === String ? String(message.returnedQuantity) : message.returnedQuantity;
                                else
                                    object.returnedQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.returnedQuantity) : options.longs === Number ? new $util.LongBits(message.returnedQuantity.low >>> 0, message.returnedQuantity.high >>> 0).toNumber(true) : message.returnedQuantity;
                            if (message.cancelledQuantity != null && message.hasOwnProperty("cancelledQuantity"))
                                if (typeof message.cancelledQuantity === "number")
                                    object.cancelledQuantity = options.longs === String ? String(message.cancelledQuantity) : message.cancelledQuantity;
                                else
                                    object.cancelledQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.cancelledQuantity) : options.longs === Number ? new $util.LongBits(message.cancelledQuantity.low >>> 0, message.cancelledQuantity.high >>> 0).toNumber(true) : message.cancelledQuantity;
                            if (message.patchedSumUp != null && message.hasOwnProperty("patchedSumUp"))
                                object.patchedSumUp = $root.theplant.ec.service.accounting.ItemSumUp.toObject(message.patchedSumUp, options);
                            return object;
                        };

                        /**
                         * Converts this AfterSaleChanged to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.orders.OrderItem.AfterSaleChanged
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AfterSaleChanged.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return AfterSaleChanged;
                    })();

                    return OrderItem;
                })();

                orders.RequestReturn = (function() {

                    /**
                     * Properties of a RequestReturn.
                     * @memberof theplant.ec.service.orders
                     * @interface IRequestReturn
                     * @property {string|null} [articleCode] RequestReturn articleCode
                     * @property {theplant.ec.service.orders.IReturnReason|null} [reason] RequestReturn reason
                     */

                    /**
                     * Constructs a new RequestReturn.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a RequestReturn.
                     * @implements IRequestReturn
                     * @constructor
                     * @param {theplant.ec.service.orders.IRequestReturn=} [properties] Properties to set
                     */
                    function RequestReturn(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RequestReturn articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @instance
                     */
                    RequestReturn.prototype.articleCode = "";

                    /**
                     * RequestReturn reason.
                     * @member {theplant.ec.service.orders.IReturnReason|null|undefined} reason
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @instance
                     */
                    RequestReturn.prototype.reason = null;

                    /**
                     * Creates a new RequestReturn instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {theplant.ec.service.orders.IRequestReturn=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.RequestReturn} RequestReturn instance
                     */
                    RequestReturn.create = function create(properties) {
                        return new RequestReturn(properties);
                    };

                    /**
                     * Encodes the specified RequestReturn message. Does not implicitly {@link theplant.ec.service.orders.RequestReturn.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {theplant.ec.service.orders.IRequestReturn} message RequestReturn message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestReturn.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            $root.theplant.ec.service.orders.ReturnReason.encode(message.reason, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RequestReturn message, length delimited. Does not implicitly {@link theplant.ec.service.orders.RequestReturn.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {theplant.ec.service.orders.IRequestReturn} message RequestReturn message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RequestReturn.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RequestReturn message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.RequestReturn} RequestReturn
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestReturn.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.RequestReturn();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.reason = $root.theplant.ec.service.orders.ReturnReason.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RequestReturn message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.RequestReturn} RequestReturn
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RequestReturn.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RequestReturn message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RequestReturn.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.reason != null && message.hasOwnProperty("reason")) {
                            var error = $root.theplant.ec.service.orders.ReturnReason.verify(message.reason);
                            if (error)
                                return "reason." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a RequestReturn message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.RequestReturn} RequestReturn
                     */
                    RequestReturn.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.RequestReturn)
                            return object;
                        var message = new $root.theplant.ec.service.orders.RequestReturn();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.reason != null) {
                            if (typeof object.reason !== "object")
                                throw TypeError(".theplant.ec.service.orders.RequestReturn.reason: object expected");
                            message.reason = $root.theplant.ec.service.orders.ReturnReason.fromObject(object.reason);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RequestReturn message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @static
                     * @param {theplant.ec.service.orders.RequestReturn} message RequestReturn
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RequestReturn.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            object.reason = null;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            object.reason = $root.theplant.ec.service.orders.ReturnReason.toObject(message.reason, options);
                        return object;
                    };

                    /**
                     * Converts this RequestReturn to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.RequestReturn
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RequestReturn.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RequestReturn;
                })();

                orders.ReturnReason = (function() {

                    /**
                     * Properties of a ReturnReason.
                     * @memberof theplant.ec.service.orders
                     * @interface IReturnReason
                     * @property {string|null} [reason_1] ReturnReason reason_1
                     * @property {string|null} [reason_2] ReturnReason reason_2
                     * @property {string|null} [reason_3] ReturnReason reason_3
                     * @property {string|null} [reason_2Detail] ReturnReason reason_2Detail
                     */

                    /**
                     * Constructs a new ReturnReason.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a ReturnReason.
                     * @implements IReturnReason
                     * @constructor
                     * @param {theplant.ec.service.orders.IReturnReason=} [properties] Properties to set
                     */
                    function ReturnReason(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ReturnReason reason_1.
                     * @member {string} reason_1
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @instance
                     */
                    ReturnReason.prototype.reason_1 = "";

                    /**
                     * ReturnReason reason_2.
                     * @member {string} reason_2
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @instance
                     */
                    ReturnReason.prototype.reason_2 = "";

                    /**
                     * ReturnReason reason_3.
                     * @member {string} reason_3
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @instance
                     */
                    ReturnReason.prototype.reason_3 = "";

                    /**
                     * ReturnReason reason_2Detail.
                     * @member {string} reason_2Detail
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @instance
                     */
                    ReturnReason.prototype.reason_2Detail = "";

                    /**
                     * Creates a new ReturnReason instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {theplant.ec.service.orders.IReturnReason=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.ReturnReason} ReturnReason instance
                     */
                    ReturnReason.create = function create(properties) {
                        return new ReturnReason(properties);
                    };

                    /**
                     * Encodes the specified ReturnReason message. Does not implicitly {@link theplant.ec.service.orders.ReturnReason.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {theplant.ec.service.orders.IReturnReason} message ReturnReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReturnReason.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reason_1 != null && message.hasOwnProperty("reason_1"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.reason_1);
                        if (message.reason_2 != null && message.hasOwnProperty("reason_2"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason_2);
                        if (message.reason_3 != null && message.hasOwnProperty("reason_3"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason_3);
                        if (message.reason_2Detail != null && message.hasOwnProperty("reason_2Detail"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.reason_2Detail);
                        return writer;
                    };

                    /**
                     * Encodes the specified ReturnReason message, length delimited. Does not implicitly {@link theplant.ec.service.orders.ReturnReason.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {theplant.ec.service.orders.IReturnReason} message ReturnReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ReturnReason.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ReturnReason message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.ReturnReason} ReturnReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReturnReason.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.ReturnReason();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reason_1 = reader.string();
                                break;
                            case 2:
                                message.reason_2 = reader.string();
                                break;
                            case 3:
                                message.reason_3 = reader.string();
                                break;
                            case 4:
                                message.reason_2Detail = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ReturnReason message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.ReturnReason} ReturnReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ReturnReason.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ReturnReason message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ReturnReason.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reason_1 != null && message.hasOwnProperty("reason_1"))
                            if (!$util.isString(message.reason_1))
                                return "reason_1: string expected";
                        if (message.reason_2 != null && message.hasOwnProperty("reason_2"))
                            if (!$util.isString(message.reason_2))
                                return "reason_2: string expected";
                        if (message.reason_3 != null && message.hasOwnProperty("reason_3"))
                            if (!$util.isString(message.reason_3))
                                return "reason_3: string expected";
                        if (message.reason_2Detail != null && message.hasOwnProperty("reason_2Detail"))
                            if (!$util.isString(message.reason_2Detail))
                                return "reason_2Detail: string expected";
                        return null;
                    };

                    /**
                     * Creates a ReturnReason message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.ReturnReason} ReturnReason
                     */
                    ReturnReason.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.ReturnReason)
                            return object;
                        var message = new $root.theplant.ec.service.orders.ReturnReason();
                        if (object.reason_1 != null)
                            message.reason_1 = String(object.reason_1);
                        if (object.reason_2 != null)
                            message.reason_2 = String(object.reason_2);
                        if (object.reason_3 != null)
                            message.reason_3 = String(object.reason_3);
                        if (object.reason_2Detail != null)
                            message.reason_2Detail = String(object.reason_2Detail);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ReturnReason message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @static
                     * @param {theplant.ec.service.orders.ReturnReason} message ReturnReason
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ReturnReason.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.reason_1 = "";
                            object.reason_2 = "";
                            object.reason_3 = "";
                            object.reason_2Detail = "";
                        }
                        if (message.reason_1 != null && message.hasOwnProperty("reason_1"))
                            object.reason_1 = message.reason_1;
                        if (message.reason_2 != null && message.hasOwnProperty("reason_2"))
                            object.reason_2 = message.reason_2;
                        if (message.reason_3 != null && message.hasOwnProperty("reason_3"))
                            object.reason_3 = message.reason_3;
                        if (message.reason_2Detail != null && message.hasOwnProperty("reason_2Detail"))
                            object.reason_2Detail = message.reason_2Detail;
                        return object;
                    };

                    /**
                     * Converts this ReturnReason to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.ReturnReason
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ReturnReason.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ReturnReason;
                })();

                orders.RestrictedReason = (function() {

                    /**
                     * Properties of a RestrictedReason.
                     * @memberof theplant.ec.service.orders
                     * @interface IRestrictedReason
                     * @property {string|null} [info] RestrictedReason info
                     */

                    /**
                     * Constructs a new RestrictedReason.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a RestrictedReason.
                     * @implements IRestrictedReason
                     * @constructor
                     * @param {theplant.ec.service.orders.IRestrictedReason=} [properties] Properties to set
                     */
                    function RestrictedReason(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RestrictedReason info.
                     * @member {string} info
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @instance
                     */
                    RestrictedReason.prototype.info = "";

                    /**
                     * Creates a new RestrictedReason instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {theplant.ec.service.orders.IRestrictedReason=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.RestrictedReason} RestrictedReason instance
                     */
                    RestrictedReason.create = function create(properties) {
                        return new RestrictedReason(properties);
                    };

                    /**
                     * Encodes the specified RestrictedReason message. Does not implicitly {@link theplant.ec.service.orders.RestrictedReason.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {theplant.ec.service.orders.IRestrictedReason} message RestrictedReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RestrictedReason.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.info != null && message.hasOwnProperty("info"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.info);
                        return writer;
                    };

                    /**
                     * Encodes the specified RestrictedReason message, length delimited. Does not implicitly {@link theplant.ec.service.orders.RestrictedReason.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {theplant.ec.service.orders.IRestrictedReason} message RestrictedReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RestrictedReason.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RestrictedReason message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.RestrictedReason} RestrictedReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RestrictedReason.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.RestrictedReason();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.info = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RestrictedReason message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.RestrictedReason} RestrictedReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RestrictedReason.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RestrictedReason message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RestrictedReason.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.info != null && message.hasOwnProperty("info"))
                            if (!$util.isString(message.info))
                                return "info: string expected";
                        return null;
                    };

                    /**
                     * Creates a RestrictedReason message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.RestrictedReason} RestrictedReason
                     */
                    RestrictedReason.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.RestrictedReason)
                            return object;
                        var message = new $root.theplant.ec.service.orders.RestrictedReason();
                        if (object.info != null)
                            message.info = String(object.info);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RestrictedReason message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @static
                     * @param {theplant.ec.service.orders.RestrictedReason} message RestrictedReason
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RestrictedReason.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.info = "";
                        if (message.info != null && message.hasOwnProperty("info"))
                            object.info = message.info;
                        return object;
                    };

                    /**
                     * Converts this RestrictedReason to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.RestrictedReason
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RestrictedReason.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RestrictedReason;
                })();

                orders.OrderCustomer = (function() {

                    /**
                     * Properties of an OrderCustomer.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderCustomer
                     * @property {string|null} [email] OrderCustomer email
                     * @property {boolean|null} [newsletterSubscribed] OrderCustomer newsletterSubscribed
                     * @property {boolean|null} [firstPurchase] OrderCustomer firstPurchase
                     * @property {string|null} [dateOfBirth] OrderCustomer dateOfBirth
                     * @property {theplant.ec.service.users.UserGender|null} [gender] OrderCustomer gender
                     * @property {string|null} [memberNumber] OrderCustomer memberNumber
                     * @property {string|null} [externalCustomerId] OrderCustomer externalCustomerId
                     * @property {boolean|null} [guestOrder] OrderCustomer guestOrder
                     * @property {string|null} [segmentationIdentifiers] OrderCustomer segmentationIdentifiers
                     * @property {string|null} [familyName] OrderCustomer familyName
                     * @property {string|null} [givenName] OrderCustomer givenName
                     * @property {string|null} [katakanaFamilyName] OrderCustomer katakanaFamilyName
                     * @property {string|null} [katakanaGivenName] OrderCustomer katakanaGivenName
                     * @property {boolean|null} [returningCustomer] OrderCustomer returningCustomer
                     */

                    /**
                     * Constructs a new OrderCustomer.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderCustomer.
                     * @implements IOrderCustomer
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderCustomer=} [properties] Properties to set
                     */
                    function OrderCustomer(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderCustomer email.
                     * @member {string} email
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.email = "";

                    /**
                     * OrderCustomer newsletterSubscribed.
                     * @member {boolean} newsletterSubscribed
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.newsletterSubscribed = false;

                    /**
                     * OrderCustomer firstPurchase.
                     * @member {boolean} firstPurchase
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.firstPurchase = false;

                    /**
                     * OrderCustomer dateOfBirth.
                     * @member {string} dateOfBirth
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.dateOfBirth = "";

                    /**
                     * OrderCustomer gender.
                     * @member {theplant.ec.service.users.UserGender} gender
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.gender = 0;

                    /**
                     * OrderCustomer memberNumber.
                     * @member {string} memberNumber
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.memberNumber = "";

                    /**
                     * OrderCustomer externalCustomerId.
                     * @member {string} externalCustomerId
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.externalCustomerId = "";

                    /**
                     * OrderCustomer guestOrder.
                     * @member {boolean} guestOrder
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.guestOrder = false;

                    /**
                     * OrderCustomer segmentationIdentifiers.
                     * @member {string} segmentationIdentifiers
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.segmentationIdentifiers = "";

                    /**
                     * OrderCustomer familyName.
                     * @member {string} familyName
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.familyName = "";

                    /**
                     * OrderCustomer givenName.
                     * @member {string} givenName
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.givenName = "";

                    /**
                     * OrderCustomer katakanaFamilyName.
                     * @member {string} katakanaFamilyName
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.katakanaFamilyName = "";

                    /**
                     * OrderCustomer katakanaGivenName.
                     * @member {string} katakanaGivenName
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.katakanaGivenName = "";

                    /**
                     * OrderCustomer returningCustomer.
                     * @member {boolean} returningCustomer
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     */
                    OrderCustomer.prototype.returningCustomer = false;

                    /**
                     * Creates a new OrderCustomer instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {theplant.ec.service.orders.IOrderCustomer=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderCustomer} OrderCustomer instance
                     */
                    OrderCustomer.create = function create(properties) {
                        return new OrderCustomer(properties);
                    };

                    /**
                     * Encodes the specified OrderCustomer message. Does not implicitly {@link theplant.ec.service.orders.OrderCustomer.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {theplant.ec.service.orders.IOrderCustomer} message OrderCustomer message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCustomer.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.newsletterSubscribed != null && message.hasOwnProperty("newsletterSubscribed"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.newsletterSubscribed);
                        if (message.firstPurchase != null && message.hasOwnProperty("firstPurchase"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.firstPurchase);
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.dateOfBirth);
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.gender);
                        if (message.memberNumber != null && message.hasOwnProperty("memberNumber"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.memberNumber);
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.externalCustomerId);
                        if (message.guestOrder != null && message.hasOwnProperty("guestOrder"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.guestOrder);
                        if (message.segmentationIdentifiers != null && message.hasOwnProperty("segmentationIdentifiers"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.segmentationIdentifiers);
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.familyName);
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.givenName);
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.katakanaFamilyName);
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.katakanaGivenName);
                        if (message.returningCustomer != null && message.hasOwnProperty("returningCustomer"))
                            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.returningCustomer);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderCustomer message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderCustomer.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {theplant.ec.service.orders.IOrderCustomer} message OrderCustomer message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCustomer.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderCustomer message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderCustomer} OrderCustomer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCustomer.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderCustomer();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.newsletterSubscribed = reader.bool();
                                break;
                            case 3:
                                message.firstPurchase = reader.bool();
                                break;
                            case 4:
                                message.dateOfBirth = reader.string();
                                break;
                            case 5:
                                message.gender = reader.int32();
                                break;
                            case 6:
                                message.memberNumber = reader.string();
                                break;
                            case 7:
                                message.externalCustomerId = reader.string();
                                break;
                            case 8:
                                message.guestOrder = reader.bool();
                                break;
                            case 9:
                                message.segmentationIdentifiers = reader.string();
                                break;
                            case 10:
                                message.familyName = reader.string();
                                break;
                            case 11:
                                message.givenName = reader.string();
                                break;
                            case 12:
                                message.katakanaFamilyName = reader.string();
                                break;
                            case 13:
                                message.katakanaGivenName = reader.string();
                                break;
                            case 14:
                                message.returningCustomer = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderCustomer message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderCustomer} OrderCustomer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCustomer.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderCustomer message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderCustomer.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.newsletterSubscribed != null && message.hasOwnProperty("newsletterSubscribed"))
                            if (typeof message.newsletterSubscribed !== "boolean")
                                return "newsletterSubscribed: boolean expected";
                        if (message.firstPurchase != null && message.hasOwnProperty("firstPurchase"))
                            if (typeof message.firstPurchase !== "boolean")
                                return "firstPurchase: boolean expected";
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            if (!$util.isString(message.dateOfBirth))
                                return "dateOfBirth: string expected";
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            switch (message.gender) {
                            default:
                                return "gender: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.memberNumber != null && message.hasOwnProperty("memberNumber"))
                            if (!$util.isString(message.memberNumber))
                                return "memberNumber: string expected";
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            if (!$util.isString(message.externalCustomerId))
                                return "externalCustomerId: string expected";
                        if (message.guestOrder != null && message.hasOwnProperty("guestOrder"))
                            if (typeof message.guestOrder !== "boolean")
                                return "guestOrder: boolean expected";
                        if (message.segmentationIdentifiers != null && message.hasOwnProperty("segmentationIdentifiers"))
                            if (!$util.isString(message.segmentationIdentifiers))
                                return "segmentationIdentifiers: string expected";
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            if (!$util.isString(message.familyName))
                                return "familyName: string expected";
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            if (!$util.isString(message.givenName))
                                return "givenName: string expected";
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            if (!$util.isString(message.katakanaFamilyName))
                                return "katakanaFamilyName: string expected";
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            if (!$util.isString(message.katakanaGivenName))
                                return "katakanaGivenName: string expected";
                        if (message.returningCustomer != null && message.hasOwnProperty("returningCustomer"))
                            if (typeof message.returningCustomer !== "boolean")
                                return "returningCustomer: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an OrderCustomer message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderCustomer} OrderCustomer
                     */
                    OrderCustomer.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderCustomer)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderCustomer();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.newsletterSubscribed != null)
                            message.newsletterSubscribed = Boolean(object.newsletterSubscribed);
                        if (object.firstPurchase != null)
                            message.firstPurchase = Boolean(object.firstPurchase);
                        if (object.dateOfBirth != null)
                            message.dateOfBirth = String(object.dateOfBirth);
                        switch (object.gender) {
                        case "UNKNOWN":
                        case 0:
                            message.gender = 0;
                            break;
                        case "MALE":
                        case 1:
                            message.gender = 1;
                            break;
                        case "FEMALE":
                        case 2:
                            message.gender = 2;
                            break;
                        }
                        if (object.memberNumber != null)
                            message.memberNumber = String(object.memberNumber);
                        if (object.externalCustomerId != null)
                            message.externalCustomerId = String(object.externalCustomerId);
                        if (object.guestOrder != null)
                            message.guestOrder = Boolean(object.guestOrder);
                        if (object.segmentationIdentifiers != null)
                            message.segmentationIdentifiers = String(object.segmentationIdentifiers);
                        if (object.familyName != null)
                            message.familyName = String(object.familyName);
                        if (object.givenName != null)
                            message.givenName = String(object.givenName);
                        if (object.katakanaFamilyName != null)
                            message.katakanaFamilyName = String(object.katakanaFamilyName);
                        if (object.katakanaGivenName != null)
                            message.katakanaGivenName = String(object.katakanaGivenName);
                        if (object.returningCustomer != null)
                            message.returningCustomer = Boolean(object.returningCustomer);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderCustomer message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @static
                     * @param {theplant.ec.service.orders.OrderCustomer} message OrderCustomer
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderCustomer.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.email = "";
                            object.newsletterSubscribed = false;
                            object.firstPurchase = false;
                            object.dateOfBirth = "";
                            object.gender = options.enums === String ? "UNKNOWN" : 0;
                            object.memberNumber = "";
                            object.externalCustomerId = "";
                            object.guestOrder = false;
                            object.segmentationIdentifiers = "";
                            object.familyName = "";
                            object.givenName = "";
                            object.katakanaFamilyName = "";
                            object.katakanaGivenName = "";
                            object.returningCustomer = false;
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.newsletterSubscribed != null && message.hasOwnProperty("newsletterSubscribed"))
                            object.newsletterSubscribed = message.newsletterSubscribed;
                        if (message.firstPurchase != null && message.hasOwnProperty("firstPurchase"))
                            object.firstPurchase = message.firstPurchase;
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            object.dateOfBirth = message.dateOfBirth;
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = options.enums === String ? $root.theplant.ec.service.users.UserGender[message.gender] : message.gender;
                        if (message.memberNumber != null && message.hasOwnProperty("memberNumber"))
                            object.memberNumber = message.memberNumber;
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            object.externalCustomerId = message.externalCustomerId;
                        if (message.guestOrder != null && message.hasOwnProperty("guestOrder"))
                            object.guestOrder = message.guestOrder;
                        if (message.segmentationIdentifiers != null && message.hasOwnProperty("segmentationIdentifiers"))
                            object.segmentationIdentifiers = message.segmentationIdentifiers;
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            object.familyName = message.familyName;
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            object.givenName = message.givenName;
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            object.katakanaFamilyName = message.katakanaFamilyName;
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            object.katakanaGivenName = message.katakanaGivenName;
                        if (message.returningCustomer != null && message.hasOwnProperty("returningCustomer"))
                            object.returningCustomer = message.returningCustomer;
                        return object;
                    };

                    /**
                     * Converts this OrderCustomer to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderCustomer
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderCustomer.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderCustomer;
                })();

                orders.OrderShipment = (function() {

                    /**
                     * Properties of an OrderShipment.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderShipment
                     * @property {string|null} [id] OrderShipment id
                     * @property {string|null} [date] OrderShipment date
                     * @property {string|null} [provider] OrderShipment provider
                     */

                    /**
                     * Constructs a new OrderShipment.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderShipment.
                     * @implements IOrderShipment
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderShipment=} [properties] Properties to set
                     */
                    function OrderShipment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderShipment id.
                     * @member {string} id
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @instance
                     */
                    OrderShipment.prototype.id = "";

                    /**
                     * OrderShipment date.
                     * @member {string} date
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @instance
                     */
                    OrderShipment.prototype.date = "";

                    /**
                     * OrderShipment provider.
                     * @member {string} provider
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @instance
                     */
                    OrderShipment.prototype.provider = "";

                    /**
                     * Creates a new OrderShipment instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {theplant.ec.service.orders.IOrderShipment=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderShipment} OrderShipment instance
                     */
                    OrderShipment.create = function create(properties) {
                        return new OrderShipment(properties);
                    };

                    /**
                     * Encodes the specified OrderShipment message. Does not implicitly {@link theplant.ec.service.orders.OrderShipment.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {theplant.ec.service.orders.IOrderShipment} message OrderShipment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderShipment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.date != null && message.hasOwnProperty("date"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.provider);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderShipment message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderShipment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {theplant.ec.service.orders.IOrderShipment} message OrderShipment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderShipment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderShipment message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderShipment} OrderShipment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderShipment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderShipment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.date = reader.string();
                                break;
                            case 3:
                                message.provider = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderShipment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderShipment} OrderShipment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderShipment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderShipment message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderShipment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.date != null && message.hasOwnProperty("date"))
                            if (!$util.isString(message.date))
                                return "date: string expected";
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            if (!$util.isString(message.provider))
                                return "provider: string expected";
                        return null;
                    };

                    /**
                     * Creates an OrderShipment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderShipment} OrderShipment
                     */
                    OrderShipment.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderShipment)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderShipment();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.date != null)
                            message.date = String(object.date);
                        if (object.provider != null)
                            message.provider = String(object.provider);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderShipment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @static
                     * @param {theplant.ec.service.orders.OrderShipment} message OrderShipment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderShipment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.date = "";
                            object.provider = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.date != null && message.hasOwnProperty("date"))
                            object.date = message.date;
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            object.provider = message.provider;
                        return object;
                    };

                    /**
                     * Converts this OrderShipment to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderShipment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderShipment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderShipment;
                })();

                orders.OrderShipmentPackage = (function() {

                    /**
                     * Properties of an OrderShipmentPackage.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderShipmentPackage
                     * @property {Array.<theplant.ec.service.orders.IOrderItemShipment>|null} [orderItems] OrderShipmentPackage orderItems
                     * @property {string|null} [state] OrderShipmentPackage state
                     * @property {theplant.ec.service.orders.ShipmentProvider|null} [provider] OrderShipmentPackage provider
                     * @property {string|null} [trackingNumber] OrderShipmentPackage trackingNumber
                     * @property {string|null} [trackingUrl] OrderShipmentPackage trackingUrl
                     * @property {boolean|null} [isShipFromStore] OrderShipmentPackage isShipFromStore
                     */

                    /**
                     * Constructs a new OrderShipmentPackage.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderShipmentPackage.
                     * @implements IOrderShipmentPackage
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderShipmentPackage=} [properties] Properties to set
                     */
                    function OrderShipmentPackage(properties) {
                        this.orderItems = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderShipmentPackage orderItems.
                     * @member {Array.<theplant.ec.service.orders.IOrderItemShipment>} orderItems
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     */
                    OrderShipmentPackage.prototype.orderItems = $util.emptyArray;

                    /**
                     * OrderShipmentPackage state.
                     * @member {string} state
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     */
                    OrderShipmentPackage.prototype.state = "";

                    /**
                     * OrderShipmentPackage provider.
                     * @member {theplant.ec.service.orders.ShipmentProvider} provider
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     */
                    OrderShipmentPackage.prototype.provider = 0;

                    /**
                     * OrderShipmentPackage trackingNumber.
                     * @member {string} trackingNumber
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     */
                    OrderShipmentPackage.prototype.trackingNumber = "";

                    /**
                     * OrderShipmentPackage trackingUrl.
                     * @member {string} trackingUrl
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     */
                    OrderShipmentPackage.prototype.trackingUrl = "";

                    /**
                     * OrderShipmentPackage isShipFromStore.
                     * @member {boolean} isShipFromStore
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     */
                    OrderShipmentPackage.prototype.isShipFromStore = false;

                    /**
                     * Creates a new OrderShipmentPackage instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {theplant.ec.service.orders.IOrderShipmentPackage=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderShipmentPackage} OrderShipmentPackage instance
                     */
                    OrderShipmentPackage.create = function create(properties) {
                        return new OrderShipmentPackage(properties);
                    };

                    /**
                     * Encodes the specified OrderShipmentPackage message. Does not implicitly {@link theplant.ec.service.orders.OrderShipmentPackage.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {theplant.ec.service.orders.IOrderShipmentPackage} message OrderShipmentPackage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderShipmentPackage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderItems != null && message.orderItems.length)
                            for (var i = 0; i < message.orderItems.length; ++i)
                                $root.theplant.ec.service.orders.OrderItemShipment.encode(message.orderItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.state);
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.provider);
                        if (message.trackingNumber != null && message.hasOwnProperty("trackingNumber"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.trackingNumber);
                        if (message.trackingUrl != null && message.hasOwnProperty("trackingUrl"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.trackingUrl);
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isShipFromStore);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderShipmentPackage message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderShipmentPackage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {theplant.ec.service.orders.IOrderShipmentPackage} message OrderShipmentPackage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderShipmentPackage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderShipmentPackage message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderShipmentPackage} OrderShipmentPackage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderShipmentPackage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderShipmentPackage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.orderItems && message.orderItems.length))
                                    message.orderItems = [];
                                message.orderItems.push($root.theplant.ec.service.orders.OrderItemShipment.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.state = reader.string();
                                break;
                            case 3:
                                message.provider = reader.int32();
                                break;
                            case 4:
                                message.trackingNumber = reader.string();
                                break;
                            case 5:
                                message.trackingUrl = reader.string();
                                break;
                            case 6:
                                message.isShipFromStore = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderShipmentPackage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderShipmentPackage} OrderShipmentPackage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderShipmentPackage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderShipmentPackage message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderShipmentPackage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderItems != null && message.hasOwnProperty("orderItems")) {
                            if (!Array.isArray(message.orderItems))
                                return "orderItems: array expected";
                            for (var i = 0; i < message.orderItems.length; ++i) {
                                var error = $root.theplant.ec.service.orders.OrderItemShipment.verify(message.orderItems[i]);
                                if (error)
                                    return "orderItems." + error;
                            }
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            if (!$util.isString(message.state))
                                return "state: string expected";
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            switch (message.provider) {
                            default:
                                return "provider: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.trackingNumber != null && message.hasOwnProperty("trackingNumber"))
                            if (!$util.isString(message.trackingNumber))
                                return "trackingNumber: string expected";
                        if (message.trackingUrl != null && message.hasOwnProperty("trackingUrl"))
                            if (!$util.isString(message.trackingUrl))
                                return "trackingUrl: string expected";
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            if (typeof message.isShipFromStore !== "boolean")
                                return "isShipFromStore: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an OrderShipmentPackage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderShipmentPackage} OrderShipmentPackage
                     */
                    OrderShipmentPackage.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderShipmentPackage)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderShipmentPackage();
                        if (object.orderItems) {
                            if (!Array.isArray(object.orderItems))
                                throw TypeError(".theplant.ec.service.orders.OrderShipmentPackage.orderItems: array expected");
                            message.orderItems = [];
                            for (var i = 0; i < object.orderItems.length; ++i) {
                                if (typeof object.orderItems[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.OrderShipmentPackage.orderItems: object expected");
                                message.orderItems[i] = $root.theplant.ec.service.orders.OrderItemShipment.fromObject(object.orderItems[i]);
                            }
                        }
                        if (object.state != null)
                            message.state = String(object.state);
                        switch (object.provider) {
                        case "NoShipmentProvider":
                        case 0:
                            message.provider = 0;
                            break;
                        case "SAGAWA":
                        case 1:
                            message.provider = 1;
                            break;
                        case "YAMATO":
                        case 2:
                            message.provider = 2;
                            break;
                        }
                        if (object.trackingNumber != null)
                            message.trackingNumber = String(object.trackingNumber);
                        if (object.trackingUrl != null)
                            message.trackingUrl = String(object.trackingUrl);
                        if (object.isShipFromStore != null)
                            message.isShipFromStore = Boolean(object.isShipFromStore);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderShipmentPackage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @static
                     * @param {theplant.ec.service.orders.OrderShipmentPackage} message OrderShipmentPackage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderShipmentPackage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.orderItems = [];
                        if (options.defaults) {
                            object.state = "";
                            object.provider = options.enums === String ? "NoShipmentProvider" : 0;
                            object.trackingNumber = "";
                            object.trackingUrl = "";
                            object.isShipFromStore = false;
                        }
                        if (message.orderItems && message.orderItems.length) {
                            object.orderItems = [];
                            for (var j = 0; j < message.orderItems.length; ++j)
                                object.orderItems[j] = $root.theplant.ec.service.orders.OrderItemShipment.toObject(message.orderItems[j], options);
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = message.state;
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            object.provider = options.enums === String ? $root.theplant.ec.service.orders.ShipmentProvider[message.provider] : message.provider;
                        if (message.trackingNumber != null && message.hasOwnProperty("trackingNumber"))
                            object.trackingNumber = message.trackingNumber;
                        if (message.trackingUrl != null && message.hasOwnProperty("trackingUrl"))
                            object.trackingUrl = message.trackingUrl;
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            object.isShipFromStore = message.isShipFromStore;
                        return object;
                    };

                    /**
                     * Converts this OrderShipmentPackage to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderShipmentPackage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderShipmentPackage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderShipmentPackage;
                })();

                /**
                 * ShipmentProvider enum.
                 * @name theplant.ec.service.orders.ShipmentProvider
                 * @enum {string}
                 * @property {number} NoShipmentProvider=0 NoShipmentProvider value
                 * @property {number} SAGAWA=1 SAGAWA value
                 * @property {number} YAMATO=2 YAMATO value
                 */
                orders.ShipmentProvider = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NoShipmentProvider"] = 0;
                    values[valuesById[1] = "SAGAWA"] = 1;
                    values[valuesById[2] = "YAMATO"] = 2;
                    return values;
                })();

                orders.OrderItemShipment = (function() {

                    /**
                     * Properties of an OrderItemShipment.
                     * @memberof theplant.ec.service.orders
                     * @interface IOrderItemShipment
                     * @property {string|null} [id] OrderItemShipment id
                     * @property {string|null} [date] OrderItemShipment date
                     * @property {string|null} [articleCode] OrderItemShipment articleCode
                     * @property {string|null} [state] OrderItemShipment state
                     * @property {theplant.ec.service.customization.ICustomization|null} [customization] OrderItemShipment customization
                     */

                    /**
                     * Constructs a new OrderItemShipment.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an OrderItemShipment.
                     * @implements IOrderItemShipment
                     * @constructor
                     * @param {theplant.ec.service.orders.IOrderItemShipment=} [properties] Properties to set
                     */
                    function OrderItemShipment(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderItemShipment id.
                     * @member {string} id
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @instance
                     */
                    OrderItemShipment.prototype.id = "";

                    /**
                     * OrderItemShipment date.
                     * @member {string} date
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @instance
                     */
                    OrderItemShipment.prototype.date = "";

                    /**
                     * OrderItemShipment articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @instance
                     */
                    OrderItemShipment.prototype.articleCode = "";

                    /**
                     * OrderItemShipment state.
                     * @member {string} state
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @instance
                     */
                    OrderItemShipment.prototype.state = "";

                    /**
                     * OrderItemShipment customization.
                     * @member {theplant.ec.service.customization.ICustomization|null|undefined} customization
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @instance
                     */
                    OrderItemShipment.prototype.customization = null;

                    /**
                     * Creates a new OrderItemShipment instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {theplant.ec.service.orders.IOrderItemShipment=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.OrderItemShipment} OrderItemShipment instance
                     */
                    OrderItemShipment.create = function create(properties) {
                        return new OrderItemShipment(properties);
                    };

                    /**
                     * Encodes the specified OrderItemShipment message. Does not implicitly {@link theplant.ec.service.orders.OrderItemShipment.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {theplant.ec.service.orders.IOrderItemShipment} message OrderItemShipment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItemShipment.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.date != null && message.hasOwnProperty("date"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.date);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.articleCode);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.state);
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            $root.theplant.ec.service.customization.Customization.encode(message.customization, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderItemShipment message, length delimited. Does not implicitly {@link theplant.ec.service.orders.OrderItemShipment.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {theplant.ec.service.orders.IOrderItemShipment} message OrderItemShipment message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItemShipment.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderItemShipment message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.OrderItemShipment} OrderItemShipment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItemShipment.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.OrderItemShipment();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.date = reader.string();
                                break;
                            case 3:
                                message.articleCode = reader.string();
                                break;
                            case 4:
                                message.state = reader.string();
                                break;
                            case 5:
                                message.customization = $root.theplant.ec.service.customization.Customization.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderItemShipment message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.OrderItemShipment} OrderItemShipment
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItemShipment.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderItemShipment message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderItemShipment.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.date != null && message.hasOwnProperty("date"))
                            if (!$util.isString(message.date))
                                return "date: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            if (!$util.isString(message.state))
                                return "state: string expected";
                        if (message.customization != null && message.hasOwnProperty("customization")) {
                            var error = $root.theplant.ec.service.customization.Customization.verify(message.customization);
                            if (error)
                                return "customization." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderItemShipment message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.OrderItemShipment} OrderItemShipment
                     */
                    OrderItemShipment.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.OrderItemShipment)
                            return object;
                        var message = new $root.theplant.ec.service.orders.OrderItemShipment();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.date != null)
                            message.date = String(object.date);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.state != null)
                            message.state = String(object.state);
                        if (object.customization != null) {
                            if (typeof object.customization !== "object")
                                throw TypeError(".theplant.ec.service.orders.OrderItemShipment.customization: object expected");
                            message.customization = $root.theplant.ec.service.customization.Customization.fromObject(object.customization);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderItemShipment message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @static
                     * @param {theplant.ec.service.orders.OrderItemShipment} message OrderItemShipment
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderItemShipment.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.date = "";
                            object.articleCode = "";
                            object.state = "";
                            object.customization = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.date != null && message.hasOwnProperty("date"))
                            object.date = message.date;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = message.state;
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            object.customization = $root.theplant.ec.service.customization.Customization.toObject(message.customization, options);
                        return object;
                    };

                    /**
                     * Converts this OrderItemShipment to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.OrderItemShipment
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderItemShipment.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderItemShipment;
                })();

                orders.PaymentInfo = (function() {

                    /**
                     * Properties of a PaymentInfo.
                     * @memberof theplant.ec.service.orders
                     * @interface IPaymentInfo
                     * @property {theplant.ec.service.orders.PaymentType|null} [ptype] PaymentInfo ptype
                     * @property {string|null} [creditCardId] PaymentInfo creditCardId
                     * @property {string|null} [creditCardName] PaymentInfo creditCardName
                     * @property {string|null} [creditCardCustomerId] PaymentInfo creditCardCustomerId
                     * @property {string|null} [creditCardNumber] PaymentInfo creditCardNumber
                     * @property {string|null} [creditCardBrand] PaymentInfo creditCardBrand
                     * @property {string|null} [amazonAccessToken] PaymentInfo amazonAccessToken
                     * @property {string|null} [amazonOrderReferenceId] PaymentInfo amazonOrderReferenceId
                     * @property {string|null} [amazonSessionId] PaymentInfo amazonSessionId
                     * @property {string|null} [amazonChargeId] PaymentInfo amazonChargeId
                     * @property {string|null} [amazonResultReturnUrl] PaymentInfo amazonResultReturnUrl
                     * @property {string|null} [creditCardCvc] PaymentInfo creditCardCvc
                     * @property {string|null} [transactionId] PaymentInfo transactionId
                     * @property {string|null} [smartPaySessionId] PaymentInfo smartPaySessionId
                     * @property {string|null} [smartPayOrderId] PaymentInfo smartPayOrderId
                     * @property {string|null} [smartPayPaymentId] PaymentInfo smartPayPaymentId
                     * @property {string|null} [smartPayCancelId] PaymentInfo smartPayCancelId
                     * @property {string|null} [smartPayRefundId] PaymentInfo smartPayRefundId
                     * @property {string|null} [smartPayRedirectUrl] PaymentInfo smartPayRedirectUrl
                     */

                    /**
                     * Constructs a new PaymentInfo.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a PaymentInfo.
                     * @implements IPaymentInfo
                     * @constructor
                     * @param {theplant.ec.service.orders.IPaymentInfo=} [properties] Properties to set
                     */
                    function PaymentInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaymentInfo ptype.
                     * @member {theplant.ec.service.orders.PaymentType} ptype
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.ptype = 0;

                    /**
                     * PaymentInfo creditCardId.
                     * @member {string} creditCardId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.creditCardId = "";

                    /**
                     * PaymentInfo creditCardName.
                     * @member {string} creditCardName
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.creditCardName = "";

                    /**
                     * PaymentInfo creditCardCustomerId.
                     * @member {string} creditCardCustomerId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.creditCardCustomerId = "";

                    /**
                     * PaymentInfo creditCardNumber.
                     * @member {string} creditCardNumber
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.creditCardNumber = "";

                    /**
                     * PaymentInfo creditCardBrand.
                     * @member {string} creditCardBrand
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.creditCardBrand = "";

                    /**
                     * PaymentInfo amazonAccessToken.
                     * @member {string} amazonAccessToken
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.amazonAccessToken = "";

                    /**
                     * PaymentInfo amazonOrderReferenceId.
                     * @member {string} amazonOrderReferenceId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.amazonOrderReferenceId = "";

                    /**
                     * PaymentInfo amazonSessionId.
                     * @member {string} amazonSessionId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.amazonSessionId = "";

                    /**
                     * PaymentInfo amazonChargeId.
                     * @member {string} amazonChargeId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.amazonChargeId = "";

                    /**
                     * PaymentInfo amazonResultReturnUrl.
                     * @member {string} amazonResultReturnUrl
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.amazonResultReturnUrl = "";

                    /**
                     * PaymentInfo creditCardCvc.
                     * @member {string} creditCardCvc
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.creditCardCvc = "";

                    /**
                     * PaymentInfo transactionId.
                     * @member {string} transactionId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.transactionId = "";

                    /**
                     * PaymentInfo smartPaySessionId.
                     * @member {string} smartPaySessionId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.smartPaySessionId = "";

                    /**
                     * PaymentInfo smartPayOrderId.
                     * @member {string} smartPayOrderId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.smartPayOrderId = "";

                    /**
                     * PaymentInfo smartPayPaymentId.
                     * @member {string} smartPayPaymentId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.smartPayPaymentId = "";

                    /**
                     * PaymentInfo smartPayCancelId.
                     * @member {string} smartPayCancelId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.smartPayCancelId = "";

                    /**
                     * PaymentInfo smartPayRefundId.
                     * @member {string} smartPayRefundId
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.smartPayRefundId = "";

                    /**
                     * PaymentInfo smartPayRedirectUrl.
                     * @member {string} smartPayRedirectUrl
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     */
                    PaymentInfo.prototype.smartPayRedirectUrl = "";

                    /**
                     * Creates a new PaymentInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {theplant.ec.service.orders.IPaymentInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.PaymentInfo} PaymentInfo instance
                     */
                    PaymentInfo.create = function create(properties) {
                        return new PaymentInfo(properties);
                    };

                    /**
                     * Encodes the specified PaymentInfo message. Does not implicitly {@link theplant.ec.service.orders.PaymentInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {theplant.ec.service.orders.IPaymentInfo} message PaymentInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ptype != null && message.hasOwnProperty("ptype"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ptype);
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.creditCardId);
                        if (message.creditCardName != null && message.hasOwnProperty("creditCardName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.creditCardName);
                        if (message.creditCardCustomerId != null && message.hasOwnProperty("creditCardCustomerId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.creditCardCustomerId);
                        if (message.creditCardNumber != null && message.hasOwnProperty("creditCardNumber"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.creditCardNumber);
                        if (message.creditCardBrand != null && message.hasOwnProperty("creditCardBrand"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.creditCardBrand);
                        if (message.amazonAccessToken != null && message.hasOwnProperty("amazonAccessToken"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.amazonAccessToken);
                        if (message.amazonOrderReferenceId != null && message.hasOwnProperty("amazonOrderReferenceId"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.amazonOrderReferenceId);
                        if (message.amazonSessionId != null && message.hasOwnProperty("amazonSessionId"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.amazonSessionId);
                        if (message.amazonChargeId != null && message.hasOwnProperty("amazonChargeId"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.amazonChargeId);
                        if (message.amazonResultReturnUrl != null && message.hasOwnProperty("amazonResultReturnUrl"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.amazonResultReturnUrl);
                        if (message.creditCardCvc != null && message.hasOwnProperty("creditCardCvc"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.creditCardCvc);
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.transactionId);
                        if (message.smartPaySessionId != null && message.hasOwnProperty("smartPaySessionId"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.smartPaySessionId);
                        if (message.smartPayOrderId != null && message.hasOwnProperty("smartPayOrderId"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.smartPayOrderId);
                        if (message.smartPayPaymentId != null && message.hasOwnProperty("smartPayPaymentId"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.smartPayPaymentId);
                        if (message.smartPayCancelId != null && message.hasOwnProperty("smartPayCancelId"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.smartPayCancelId);
                        if (message.smartPayRefundId != null && message.hasOwnProperty("smartPayRefundId"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.smartPayRefundId);
                        if (message.smartPayRedirectUrl != null && message.hasOwnProperty("smartPayRedirectUrl"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.smartPayRedirectUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified PaymentInfo message, length delimited. Does not implicitly {@link theplant.ec.service.orders.PaymentInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {theplant.ec.service.orders.IPaymentInfo} message PaymentInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaymentInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.PaymentInfo} PaymentInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.PaymentInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.ptype = reader.int32();
                                break;
                            case 2:
                                message.creditCardId = reader.string();
                                break;
                            case 3:
                                message.creditCardName = reader.string();
                                break;
                            case 4:
                                message.creditCardCustomerId = reader.string();
                                break;
                            case 5:
                                message.creditCardNumber = reader.string();
                                break;
                            case 6:
                                message.creditCardBrand = reader.string();
                                break;
                            case 7:
                                message.amazonAccessToken = reader.string();
                                break;
                            case 8:
                                message.amazonOrderReferenceId = reader.string();
                                break;
                            case 9:
                                message.amazonSessionId = reader.string();
                                break;
                            case 10:
                                message.amazonChargeId = reader.string();
                                break;
                            case 11:
                                message.amazonResultReturnUrl = reader.string();
                                break;
                            case 12:
                                message.creditCardCvc = reader.string();
                                break;
                            case 13:
                                message.transactionId = reader.string();
                                break;
                            case 14:
                                message.smartPaySessionId = reader.string();
                                break;
                            case 15:
                                message.smartPayOrderId = reader.string();
                                break;
                            case 16:
                                message.smartPayPaymentId = reader.string();
                                break;
                            case 17:
                                message.smartPayCancelId = reader.string();
                                break;
                            case 18:
                                message.smartPayRefundId = reader.string();
                                break;
                            case 19:
                                message.smartPayRedirectUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaymentInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.PaymentInfo} PaymentInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaymentInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaymentInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.ptype != null && message.hasOwnProperty("ptype"))
                            switch (message.ptype) {
                            default:
                                return "ptype: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 10:
                                break;
                            }
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            if (!$util.isString(message.creditCardId))
                                return "creditCardId: string expected";
                        if (message.creditCardName != null && message.hasOwnProperty("creditCardName"))
                            if (!$util.isString(message.creditCardName))
                                return "creditCardName: string expected";
                        if (message.creditCardCustomerId != null && message.hasOwnProperty("creditCardCustomerId"))
                            if (!$util.isString(message.creditCardCustomerId))
                                return "creditCardCustomerId: string expected";
                        if (message.creditCardNumber != null && message.hasOwnProperty("creditCardNumber"))
                            if (!$util.isString(message.creditCardNumber))
                                return "creditCardNumber: string expected";
                        if (message.creditCardBrand != null && message.hasOwnProperty("creditCardBrand"))
                            if (!$util.isString(message.creditCardBrand))
                                return "creditCardBrand: string expected";
                        if (message.amazonAccessToken != null && message.hasOwnProperty("amazonAccessToken"))
                            if (!$util.isString(message.amazonAccessToken))
                                return "amazonAccessToken: string expected";
                        if (message.amazonOrderReferenceId != null && message.hasOwnProperty("amazonOrderReferenceId"))
                            if (!$util.isString(message.amazonOrderReferenceId))
                                return "amazonOrderReferenceId: string expected";
                        if (message.amazonSessionId != null && message.hasOwnProperty("amazonSessionId"))
                            if (!$util.isString(message.amazonSessionId))
                                return "amazonSessionId: string expected";
                        if (message.amazonChargeId != null && message.hasOwnProperty("amazonChargeId"))
                            if (!$util.isString(message.amazonChargeId))
                                return "amazonChargeId: string expected";
                        if (message.amazonResultReturnUrl != null && message.hasOwnProperty("amazonResultReturnUrl"))
                            if (!$util.isString(message.amazonResultReturnUrl))
                                return "amazonResultReturnUrl: string expected";
                        if (message.creditCardCvc != null && message.hasOwnProperty("creditCardCvc"))
                            if (!$util.isString(message.creditCardCvc))
                                return "creditCardCvc: string expected";
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            if (!$util.isString(message.transactionId))
                                return "transactionId: string expected";
                        if (message.smartPaySessionId != null && message.hasOwnProperty("smartPaySessionId"))
                            if (!$util.isString(message.smartPaySessionId))
                                return "smartPaySessionId: string expected";
                        if (message.smartPayOrderId != null && message.hasOwnProperty("smartPayOrderId"))
                            if (!$util.isString(message.smartPayOrderId))
                                return "smartPayOrderId: string expected";
                        if (message.smartPayPaymentId != null && message.hasOwnProperty("smartPayPaymentId"))
                            if (!$util.isString(message.smartPayPaymentId))
                                return "smartPayPaymentId: string expected";
                        if (message.smartPayCancelId != null && message.hasOwnProperty("smartPayCancelId"))
                            if (!$util.isString(message.smartPayCancelId))
                                return "smartPayCancelId: string expected";
                        if (message.smartPayRefundId != null && message.hasOwnProperty("smartPayRefundId"))
                            if (!$util.isString(message.smartPayRefundId))
                                return "smartPayRefundId: string expected";
                        if (message.smartPayRedirectUrl != null && message.hasOwnProperty("smartPayRedirectUrl"))
                            if (!$util.isString(message.smartPayRedirectUrl))
                                return "smartPayRedirectUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates a PaymentInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.PaymentInfo} PaymentInfo
                     */
                    PaymentInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.PaymentInfo)
                            return object;
                        var message = new $root.theplant.ec.service.orders.PaymentInfo();
                        switch (object.ptype) {
                        case "NO_PAYMENT":
                        case 0:
                            message.ptype = 0;
                            break;
                        case "COD":
                        case 1:
                            message.ptype = 1;
                            break;
                        case "CREDIT_CARD":
                        case 2:
                            message.ptype = 2;
                            break;
                        case "PAY_ALL_WITH_POINT":
                        case 3:
                            message.ptype = 3;
                            break;
                        case "PAY_ALL_WITH_GIFT_CARD":
                        case 4:
                            message.ptype = 4;
                            break;
                        case "OTHER":
                        case 5:
                            message.ptype = 5;
                            break;
                        case "AMAZON_PAY":
                        case 6:
                            message.ptype = 6;
                            break;
                        case "RAKUTEN_PAY":
                        case 7:
                            message.ptype = 7;
                            break;
                        case "PAY_PAY":
                        case 8:
                            message.ptype = 8;
                            break;
                        case "SMART_PAY":
                        case 10:
                            message.ptype = 10;
                            break;
                        }
                        if (object.creditCardId != null)
                            message.creditCardId = String(object.creditCardId);
                        if (object.creditCardName != null)
                            message.creditCardName = String(object.creditCardName);
                        if (object.creditCardCustomerId != null)
                            message.creditCardCustomerId = String(object.creditCardCustomerId);
                        if (object.creditCardNumber != null)
                            message.creditCardNumber = String(object.creditCardNumber);
                        if (object.creditCardBrand != null)
                            message.creditCardBrand = String(object.creditCardBrand);
                        if (object.amazonAccessToken != null)
                            message.amazonAccessToken = String(object.amazonAccessToken);
                        if (object.amazonOrderReferenceId != null)
                            message.amazonOrderReferenceId = String(object.amazonOrderReferenceId);
                        if (object.amazonSessionId != null)
                            message.amazonSessionId = String(object.amazonSessionId);
                        if (object.amazonChargeId != null)
                            message.amazonChargeId = String(object.amazonChargeId);
                        if (object.amazonResultReturnUrl != null)
                            message.amazonResultReturnUrl = String(object.amazonResultReturnUrl);
                        if (object.creditCardCvc != null)
                            message.creditCardCvc = String(object.creditCardCvc);
                        if (object.transactionId != null)
                            message.transactionId = String(object.transactionId);
                        if (object.smartPaySessionId != null)
                            message.smartPaySessionId = String(object.smartPaySessionId);
                        if (object.smartPayOrderId != null)
                            message.smartPayOrderId = String(object.smartPayOrderId);
                        if (object.smartPayPaymentId != null)
                            message.smartPayPaymentId = String(object.smartPayPaymentId);
                        if (object.smartPayCancelId != null)
                            message.smartPayCancelId = String(object.smartPayCancelId);
                        if (object.smartPayRefundId != null)
                            message.smartPayRefundId = String(object.smartPayRefundId);
                        if (object.smartPayRedirectUrl != null)
                            message.smartPayRedirectUrl = String(object.smartPayRedirectUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaymentInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @static
                     * @param {theplant.ec.service.orders.PaymentInfo} message PaymentInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.ptype = options.enums === String ? "NO_PAYMENT" : 0;
                            object.creditCardId = "";
                            object.creditCardName = "";
                            object.creditCardCustomerId = "";
                            object.creditCardNumber = "";
                            object.creditCardBrand = "";
                            object.amazonAccessToken = "";
                            object.amazonOrderReferenceId = "";
                            object.amazonSessionId = "";
                            object.amazonChargeId = "";
                            object.amazonResultReturnUrl = "";
                            object.creditCardCvc = "";
                            object.transactionId = "";
                            object.smartPaySessionId = "";
                            object.smartPayOrderId = "";
                            object.smartPayPaymentId = "";
                            object.smartPayCancelId = "";
                            object.smartPayRefundId = "";
                            object.smartPayRedirectUrl = "";
                        }
                        if (message.ptype != null && message.hasOwnProperty("ptype"))
                            object.ptype = options.enums === String ? $root.theplant.ec.service.orders.PaymentType[message.ptype] : message.ptype;
                        if (message.creditCardId != null && message.hasOwnProperty("creditCardId"))
                            object.creditCardId = message.creditCardId;
                        if (message.creditCardName != null && message.hasOwnProperty("creditCardName"))
                            object.creditCardName = message.creditCardName;
                        if (message.creditCardCustomerId != null && message.hasOwnProperty("creditCardCustomerId"))
                            object.creditCardCustomerId = message.creditCardCustomerId;
                        if (message.creditCardNumber != null && message.hasOwnProperty("creditCardNumber"))
                            object.creditCardNumber = message.creditCardNumber;
                        if (message.creditCardBrand != null && message.hasOwnProperty("creditCardBrand"))
                            object.creditCardBrand = message.creditCardBrand;
                        if (message.amazonAccessToken != null && message.hasOwnProperty("amazonAccessToken"))
                            object.amazonAccessToken = message.amazonAccessToken;
                        if (message.amazonOrderReferenceId != null && message.hasOwnProperty("amazonOrderReferenceId"))
                            object.amazonOrderReferenceId = message.amazonOrderReferenceId;
                        if (message.amazonSessionId != null && message.hasOwnProperty("amazonSessionId"))
                            object.amazonSessionId = message.amazonSessionId;
                        if (message.amazonChargeId != null && message.hasOwnProperty("amazonChargeId"))
                            object.amazonChargeId = message.amazonChargeId;
                        if (message.amazonResultReturnUrl != null && message.hasOwnProperty("amazonResultReturnUrl"))
                            object.amazonResultReturnUrl = message.amazonResultReturnUrl;
                        if (message.creditCardCvc != null && message.hasOwnProperty("creditCardCvc"))
                            object.creditCardCvc = message.creditCardCvc;
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            object.transactionId = message.transactionId;
                        if (message.smartPaySessionId != null && message.hasOwnProperty("smartPaySessionId"))
                            object.smartPaySessionId = message.smartPaySessionId;
                        if (message.smartPayOrderId != null && message.hasOwnProperty("smartPayOrderId"))
                            object.smartPayOrderId = message.smartPayOrderId;
                        if (message.smartPayPaymentId != null && message.hasOwnProperty("smartPayPaymentId"))
                            object.smartPayPaymentId = message.smartPayPaymentId;
                        if (message.smartPayCancelId != null && message.hasOwnProperty("smartPayCancelId"))
                            object.smartPayCancelId = message.smartPayCancelId;
                        if (message.smartPayRefundId != null && message.hasOwnProperty("smartPayRefundId"))
                            object.smartPayRefundId = message.smartPayRefundId;
                        if (message.smartPayRedirectUrl != null && message.hasOwnProperty("smartPayRedirectUrl"))
                            object.smartPayRedirectUrl = message.smartPayRedirectUrl;
                        return object;
                    };

                    /**
                     * Converts this PaymentInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.PaymentInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaymentInfo;
                })();

                /**
                 * PaymentTransactionType enum.
                 * @name theplant.ec.service.orders.PaymentTransactionType
                 * @enum {string}
                 * @property {number} NO_PAYMENT_TRANSACTION_TYPE=0 NO_PAYMENT_TRANSACTION_TYPE value
                 * @property {number} AUTHORIZE=1 AUTHORIZE value
                 * @property {number} COMPLETE_AUTHORIZE=2 COMPLETE_AUTHORIZE value
                 * @property {number} CAPTURE=3 CAPTURE value
                 * @property {number} REFUND=4 REFUND value
                 * @property {number} VOID=5 VOID value
                 */
                orders.PaymentTransactionType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_PAYMENT_TRANSACTION_TYPE"] = 0;
                    values[valuesById[1] = "AUTHORIZE"] = 1;
                    values[valuesById[2] = "COMPLETE_AUTHORIZE"] = 2;
                    values[valuesById[3] = "CAPTURE"] = 3;
                    values[valuesById[4] = "REFUND"] = 4;
                    values[valuesById[5] = "VOID"] = 5;
                    return values;
                })();

                /**
                 * PaymentTransactionState enum.
                 * @name theplant.ec.service.orders.PaymentTransactionState
                 * @enum {string}
                 * @property {number} SUCCESS=0 SUCCESS value
                 * @property {number} PENDDING=1 PENDDING value
                 * @property {number} FAILED=2 FAILED value
                 */
                orders.PaymentTransactionState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SUCCESS"] = 0;
                    values[valuesById[1] = "PENDDING"] = 1;
                    values[valuesById[2] = "FAILED"] = 2;
                    return values;
                })();

                orders.PaymentTransaction = (function() {

                    /**
                     * Properties of a PaymentTransaction.
                     * @memberof theplant.ec.service.orders
                     * @interface IPaymentTransaction
                     * @property {theplant.ec.service.orders.PaymentTransactionType|null} [ptype] PaymentTransaction ptype
                     * @property {number|Long|null} [fromAmount] PaymentTransaction fromAmount
                     * @property {number|Long|null} [toAmount] PaymentTransaction toAmount
                     * @property {string|null} [createdAt] PaymentTransaction createdAt
                     * @property {string|null} [transactionId] PaymentTransaction transactionId
                     * @property {string|null} [approvalCode] PaymentTransaction approvalCode
                     * @property {string|null} [changedBy] PaymentTransaction changedBy
                     * @property {string|null} [comment] PaymentTransaction comment
                     * @property {string|null} [orderCode] PaymentTransaction orderCode
                     * @property {google.protobuf.ITimestamp|null} [callbackConfirmedAt] PaymentTransaction callbackConfirmedAt
                     * @property {google.protobuf.ITimestamp|null} [callbackFailedAt] PaymentTransaction callbackFailedAt
                     * @property {string|null} [callbackRequest] PaymentTransaction callbackRequest
                     * @property {theplant.ec.service.orders.PaymentTransactionState|null} [state] PaymentTransaction state
                     * @property {theplant.ec.service.orders.PaymentType|null} [paymentType] PaymentTransaction paymentType
                     */

                    /**
                     * Constructs a new PaymentTransaction.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a PaymentTransaction.
                     * @implements IPaymentTransaction
                     * @constructor
                     * @param {theplant.ec.service.orders.IPaymentTransaction=} [properties] Properties to set
                     */
                    function PaymentTransaction(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaymentTransaction ptype.
                     * @member {theplant.ec.service.orders.PaymentTransactionType} ptype
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.ptype = 0;

                    /**
                     * PaymentTransaction fromAmount.
                     * @member {number|Long} fromAmount
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.fromAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * PaymentTransaction toAmount.
                     * @member {number|Long} toAmount
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.toAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * PaymentTransaction createdAt.
                     * @member {string} createdAt
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.createdAt = "";

                    /**
                     * PaymentTransaction transactionId.
                     * @member {string} transactionId
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.transactionId = "";

                    /**
                     * PaymentTransaction approvalCode.
                     * @member {string} approvalCode
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.approvalCode = "";

                    /**
                     * PaymentTransaction changedBy.
                     * @member {string} changedBy
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.changedBy = "";

                    /**
                     * PaymentTransaction comment.
                     * @member {string} comment
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.comment = "";

                    /**
                     * PaymentTransaction orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.orderCode = "";

                    /**
                     * PaymentTransaction callbackConfirmedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} callbackConfirmedAt
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.callbackConfirmedAt = null;

                    /**
                     * PaymentTransaction callbackFailedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} callbackFailedAt
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.callbackFailedAt = null;

                    /**
                     * PaymentTransaction callbackRequest.
                     * @member {string} callbackRequest
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.callbackRequest = "";

                    /**
                     * PaymentTransaction state.
                     * @member {theplant.ec.service.orders.PaymentTransactionState} state
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.state = 0;

                    /**
                     * PaymentTransaction paymentType.
                     * @member {theplant.ec.service.orders.PaymentType} paymentType
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     */
                    PaymentTransaction.prototype.paymentType = 0;

                    /**
                     * Creates a new PaymentTransaction instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {theplant.ec.service.orders.IPaymentTransaction=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.PaymentTransaction} PaymentTransaction instance
                     */
                    PaymentTransaction.create = function create(properties) {
                        return new PaymentTransaction(properties);
                    };

                    /**
                     * Encodes the specified PaymentTransaction message. Does not implicitly {@link theplant.ec.service.orders.PaymentTransaction.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {theplant.ec.service.orders.IPaymentTransaction} message PaymentTransaction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentTransaction.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.ptype != null && message.hasOwnProperty("ptype"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ptype);
                        if (message.fromAmount != null && message.hasOwnProperty("fromAmount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.fromAmount);
                        if (message.toAmount != null && message.hasOwnProperty("toAmount"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.toAmount);
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.createdAt);
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.transactionId);
                        if (message.approvalCode != null && message.hasOwnProperty("approvalCode"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.approvalCode);
                        if (message.changedBy != null && message.hasOwnProperty("changedBy"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.changedBy);
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.comment);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.orderCode);
                        if (message.callbackConfirmedAt != null && message.hasOwnProperty("callbackConfirmedAt"))
                            $root.google.protobuf.Timestamp.encode(message.callbackConfirmedAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.callbackFailedAt != null && message.hasOwnProperty("callbackFailedAt"))
                            $root.google.protobuf.Timestamp.encode(message.callbackFailedAt, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.callbackRequest != null && message.hasOwnProperty("callbackRequest"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.callbackRequest);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.state);
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.paymentType);
                        return writer;
                    };

                    /**
                     * Encodes the specified PaymentTransaction message, length delimited. Does not implicitly {@link theplant.ec.service.orders.PaymentTransaction.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {theplant.ec.service.orders.IPaymentTransaction} message PaymentTransaction message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaymentTransaction.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaymentTransaction message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.PaymentTransaction} PaymentTransaction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentTransaction.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.PaymentTransaction();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.ptype = reader.int32();
                                break;
                            case 2:
                                message.fromAmount = reader.int64();
                                break;
                            case 3:
                                message.toAmount = reader.int64();
                                break;
                            case 4:
                                message.createdAt = reader.string();
                                break;
                            case 5:
                                message.transactionId = reader.string();
                                break;
                            case 6:
                                message.approvalCode = reader.string();
                                break;
                            case 7:
                                message.changedBy = reader.string();
                                break;
                            case 8:
                                message.comment = reader.string();
                                break;
                            case 9:
                                message.orderCode = reader.string();
                                break;
                            case 10:
                                message.callbackConfirmedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.callbackFailedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 12:
                                message.callbackRequest = reader.string();
                                break;
                            case 13:
                                message.state = reader.int32();
                                break;
                            case 14:
                                message.paymentType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaymentTransaction message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.PaymentTransaction} PaymentTransaction
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaymentTransaction.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaymentTransaction message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaymentTransaction.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.ptype != null && message.hasOwnProperty("ptype"))
                            switch (message.ptype) {
                            default:
                                return "ptype: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        if (message.fromAmount != null && message.hasOwnProperty("fromAmount"))
                            if (!$util.isInteger(message.fromAmount) && !(message.fromAmount && $util.isInteger(message.fromAmount.low) && $util.isInteger(message.fromAmount.high)))
                                return "fromAmount: integer|Long expected";
                        if (message.toAmount != null && message.hasOwnProperty("toAmount"))
                            if (!$util.isInteger(message.toAmount) && !(message.toAmount && $util.isInteger(message.toAmount.low) && $util.isInteger(message.toAmount.high)))
                                return "toAmount: integer|Long expected";
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            if (!$util.isString(message.createdAt))
                                return "createdAt: string expected";
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            if (!$util.isString(message.transactionId))
                                return "transactionId: string expected";
                        if (message.approvalCode != null && message.hasOwnProperty("approvalCode"))
                            if (!$util.isString(message.approvalCode))
                                return "approvalCode: string expected";
                        if (message.changedBy != null && message.hasOwnProperty("changedBy"))
                            if (!$util.isString(message.changedBy))
                                return "changedBy: string expected";
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            if (!$util.isString(message.comment))
                                return "comment: string expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.callbackConfirmedAt != null && message.hasOwnProperty("callbackConfirmedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.callbackConfirmedAt);
                            if (error)
                                return "callbackConfirmedAt." + error;
                        }
                        if (message.callbackFailedAt != null && message.hasOwnProperty("callbackFailedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.callbackFailedAt);
                            if (error)
                                return "callbackFailedAt." + error;
                        }
                        if (message.callbackRequest != null && message.hasOwnProperty("callbackRequest"))
                            if (!$util.isString(message.callbackRequest))
                                return "callbackRequest: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            switch (message.paymentType) {
                            default:
                                return "paymentType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 10:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a PaymentTransaction message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.PaymentTransaction} PaymentTransaction
                     */
                    PaymentTransaction.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.PaymentTransaction)
                            return object;
                        var message = new $root.theplant.ec.service.orders.PaymentTransaction();
                        switch (object.ptype) {
                        case "NO_PAYMENT_TRANSACTION_TYPE":
                        case 0:
                            message.ptype = 0;
                            break;
                        case "AUTHORIZE":
                        case 1:
                            message.ptype = 1;
                            break;
                        case "COMPLETE_AUTHORIZE":
                        case 2:
                            message.ptype = 2;
                            break;
                        case "CAPTURE":
                        case 3:
                            message.ptype = 3;
                            break;
                        case "REFUND":
                        case 4:
                            message.ptype = 4;
                            break;
                        case "VOID":
                        case 5:
                            message.ptype = 5;
                            break;
                        }
                        if (object.fromAmount != null)
                            if ($util.Long)
                                (message.fromAmount = $util.Long.fromValue(object.fromAmount)).unsigned = false;
                            else if (typeof object.fromAmount === "string")
                                message.fromAmount = parseInt(object.fromAmount, 10);
                            else if (typeof object.fromAmount === "number")
                                message.fromAmount = object.fromAmount;
                            else if (typeof object.fromAmount === "object")
                                message.fromAmount = new $util.LongBits(object.fromAmount.low >>> 0, object.fromAmount.high >>> 0).toNumber();
                        if (object.toAmount != null)
                            if ($util.Long)
                                (message.toAmount = $util.Long.fromValue(object.toAmount)).unsigned = false;
                            else if (typeof object.toAmount === "string")
                                message.toAmount = parseInt(object.toAmount, 10);
                            else if (typeof object.toAmount === "number")
                                message.toAmount = object.toAmount;
                            else if (typeof object.toAmount === "object")
                                message.toAmount = new $util.LongBits(object.toAmount.low >>> 0, object.toAmount.high >>> 0).toNumber();
                        if (object.createdAt != null)
                            message.createdAt = String(object.createdAt);
                        if (object.transactionId != null)
                            message.transactionId = String(object.transactionId);
                        if (object.approvalCode != null)
                            message.approvalCode = String(object.approvalCode);
                        if (object.changedBy != null)
                            message.changedBy = String(object.changedBy);
                        if (object.comment != null)
                            message.comment = String(object.comment);
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.callbackConfirmedAt != null) {
                            if (typeof object.callbackConfirmedAt !== "object")
                                throw TypeError(".theplant.ec.service.orders.PaymentTransaction.callbackConfirmedAt: object expected");
                            message.callbackConfirmedAt = $root.google.protobuf.Timestamp.fromObject(object.callbackConfirmedAt);
                        }
                        if (object.callbackFailedAt != null) {
                            if (typeof object.callbackFailedAt !== "object")
                                throw TypeError(".theplant.ec.service.orders.PaymentTransaction.callbackFailedAt: object expected");
                            message.callbackFailedAt = $root.google.protobuf.Timestamp.fromObject(object.callbackFailedAt);
                        }
                        if (object.callbackRequest != null)
                            message.callbackRequest = String(object.callbackRequest);
                        switch (object.state) {
                        case "SUCCESS":
                        case 0:
                            message.state = 0;
                            break;
                        case "PENDDING":
                        case 1:
                            message.state = 1;
                            break;
                        case "FAILED":
                        case 2:
                            message.state = 2;
                            break;
                        }
                        switch (object.paymentType) {
                        case "NO_PAYMENT":
                        case 0:
                            message.paymentType = 0;
                            break;
                        case "COD":
                        case 1:
                            message.paymentType = 1;
                            break;
                        case "CREDIT_CARD":
                        case 2:
                            message.paymentType = 2;
                            break;
                        case "PAY_ALL_WITH_POINT":
                        case 3:
                            message.paymentType = 3;
                            break;
                        case "PAY_ALL_WITH_GIFT_CARD":
                        case 4:
                            message.paymentType = 4;
                            break;
                        case "OTHER":
                        case 5:
                            message.paymentType = 5;
                            break;
                        case "AMAZON_PAY":
                        case 6:
                            message.paymentType = 6;
                            break;
                        case "RAKUTEN_PAY":
                        case 7:
                            message.paymentType = 7;
                            break;
                        case "PAY_PAY":
                        case 8:
                            message.paymentType = 8;
                            break;
                        case "SMART_PAY":
                        case 10:
                            message.paymentType = 10;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaymentTransaction message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @static
                     * @param {theplant.ec.service.orders.PaymentTransaction} message PaymentTransaction
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaymentTransaction.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.ptype = options.enums === String ? "NO_PAYMENT_TRANSACTION_TYPE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.fromAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.fromAmount = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.toAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.toAmount = options.longs === String ? "0" : 0;
                            object.createdAt = "";
                            object.transactionId = "";
                            object.approvalCode = "";
                            object.changedBy = "";
                            object.comment = "";
                            object.orderCode = "";
                            object.callbackConfirmedAt = null;
                            object.callbackFailedAt = null;
                            object.callbackRequest = "";
                            object.state = options.enums === String ? "SUCCESS" : 0;
                            object.paymentType = options.enums === String ? "NO_PAYMENT" : 0;
                        }
                        if (message.ptype != null && message.hasOwnProperty("ptype"))
                            object.ptype = options.enums === String ? $root.theplant.ec.service.orders.PaymentTransactionType[message.ptype] : message.ptype;
                        if (message.fromAmount != null && message.hasOwnProperty("fromAmount"))
                            if (typeof message.fromAmount === "number")
                                object.fromAmount = options.longs === String ? String(message.fromAmount) : message.fromAmount;
                            else
                                object.fromAmount = options.longs === String ? $util.Long.prototype.toString.call(message.fromAmount) : options.longs === Number ? new $util.LongBits(message.fromAmount.low >>> 0, message.fromAmount.high >>> 0).toNumber() : message.fromAmount;
                        if (message.toAmount != null && message.hasOwnProperty("toAmount"))
                            if (typeof message.toAmount === "number")
                                object.toAmount = options.longs === String ? String(message.toAmount) : message.toAmount;
                            else
                                object.toAmount = options.longs === String ? $util.Long.prototype.toString.call(message.toAmount) : options.longs === Number ? new $util.LongBits(message.toAmount.low >>> 0, message.toAmount.high >>> 0).toNumber() : message.toAmount;
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            object.createdAt = message.createdAt;
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            object.transactionId = message.transactionId;
                        if (message.approvalCode != null && message.hasOwnProperty("approvalCode"))
                            object.approvalCode = message.approvalCode;
                        if (message.changedBy != null && message.hasOwnProperty("changedBy"))
                            object.changedBy = message.changedBy;
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            object.comment = message.comment;
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.callbackConfirmedAt != null && message.hasOwnProperty("callbackConfirmedAt"))
                            object.callbackConfirmedAt = $root.google.protobuf.Timestamp.toObject(message.callbackConfirmedAt, options);
                        if (message.callbackFailedAt != null && message.hasOwnProperty("callbackFailedAt"))
                            object.callbackFailedAt = $root.google.protobuf.Timestamp.toObject(message.callbackFailedAt, options);
                        if (message.callbackRequest != null && message.hasOwnProperty("callbackRequest"))
                            object.callbackRequest = message.callbackRequest;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.theplant.ec.service.orders.PaymentTransactionState[message.state] : message.state;
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            object.paymentType = options.enums === String ? $root.theplant.ec.service.orders.PaymentType[message.paymentType] : message.paymentType;
                        return object;
                    };

                    /**
                     * Converts this PaymentTransaction to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.PaymentTransaction
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaymentTransaction.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaymentTransaction;
                })();

                orders.DeliveryInfo = (function() {

                    /**
                     * Properties of a DeliveryInfo.
                     * @memberof theplant.ec.service.orders
                     * @interface IDeliveryInfo
                     * @property {boolean|null} [specified] DeliveryInfo specified
                     * @property {string|null} [specifiedDate] DeliveryInfo specifiedDate
                     * @property {string|null} [specifiedHours] DeliveryInfo specifiedHours
                     * @property {string|null} [specifiedHoursName] DeliveryInfo specifiedHoursName
                     */

                    /**
                     * Constructs a new DeliveryInfo.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a DeliveryInfo.
                     * @implements IDeliveryInfo
                     * @constructor
                     * @param {theplant.ec.service.orders.IDeliveryInfo=} [properties] Properties to set
                     */
                    function DeliveryInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeliveryInfo specified.
                     * @member {boolean} specified
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @instance
                     */
                    DeliveryInfo.prototype.specified = false;

                    /**
                     * DeliveryInfo specifiedDate.
                     * @member {string} specifiedDate
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @instance
                     */
                    DeliveryInfo.prototype.specifiedDate = "";

                    /**
                     * DeliveryInfo specifiedHours.
                     * @member {string} specifiedHours
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @instance
                     */
                    DeliveryInfo.prototype.specifiedHours = "";

                    /**
                     * DeliveryInfo specifiedHoursName.
                     * @member {string} specifiedHoursName
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @instance
                     */
                    DeliveryInfo.prototype.specifiedHoursName = "";

                    /**
                     * Creates a new DeliveryInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.DeliveryInfo} DeliveryInfo instance
                     */
                    DeliveryInfo.create = function create(properties) {
                        return new DeliveryInfo(properties);
                    };

                    /**
                     * Encodes the specified DeliveryInfo message. Does not implicitly {@link theplant.ec.service.orders.DeliveryInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryInfo} message DeliveryInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.specifiedDate != null && message.hasOwnProperty("specifiedDate"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.specifiedDate);
                        if (message.specifiedHours != null && message.hasOwnProperty("specifiedHours"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.specifiedHours);
                        if (message.specified != null && message.hasOwnProperty("specified"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.specified);
                        if (message.specifiedHoursName != null && message.hasOwnProperty("specifiedHoursName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.specifiedHoursName);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeliveryInfo message, length delimited. Does not implicitly {@link theplant.ec.service.orders.DeliveryInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryInfo} message DeliveryInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeliveryInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.DeliveryInfo} DeliveryInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.DeliveryInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 3:
                                message.specified = reader.bool();
                                break;
                            case 1:
                                message.specifiedDate = reader.string();
                                break;
                            case 2:
                                message.specifiedHours = reader.string();
                                break;
                            case 4:
                                message.specifiedHoursName = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeliveryInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.DeliveryInfo} DeliveryInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeliveryInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeliveryInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.specified != null && message.hasOwnProperty("specified"))
                            if (typeof message.specified !== "boolean")
                                return "specified: boolean expected";
                        if (message.specifiedDate != null && message.hasOwnProperty("specifiedDate"))
                            if (!$util.isString(message.specifiedDate))
                                return "specifiedDate: string expected";
                        if (message.specifiedHours != null && message.hasOwnProperty("specifiedHours"))
                            if (!$util.isString(message.specifiedHours))
                                return "specifiedHours: string expected";
                        if (message.specifiedHoursName != null && message.hasOwnProperty("specifiedHoursName"))
                            if (!$util.isString(message.specifiedHoursName))
                                return "specifiedHoursName: string expected";
                        return null;
                    };

                    /**
                     * Creates a DeliveryInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.DeliveryInfo} DeliveryInfo
                     */
                    DeliveryInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.DeliveryInfo)
                            return object;
                        var message = new $root.theplant.ec.service.orders.DeliveryInfo();
                        if (object.specified != null)
                            message.specified = Boolean(object.specified);
                        if (object.specifiedDate != null)
                            message.specifiedDate = String(object.specifiedDate);
                        if (object.specifiedHours != null)
                            message.specifiedHours = String(object.specifiedHours);
                        if (object.specifiedHoursName != null)
                            message.specifiedHoursName = String(object.specifiedHoursName);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeliveryInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @static
                     * @param {theplant.ec.service.orders.DeliveryInfo} message DeliveryInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeliveryInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.specifiedDate = "";
                            object.specifiedHours = "";
                            object.specified = false;
                            object.specifiedHoursName = "";
                        }
                        if (message.specifiedDate != null && message.hasOwnProperty("specifiedDate"))
                            object.specifiedDate = message.specifiedDate;
                        if (message.specifiedHours != null && message.hasOwnProperty("specifiedHours"))
                            object.specifiedHours = message.specifiedHours;
                        if (message.specified != null && message.hasOwnProperty("specified"))
                            object.specified = message.specified;
                        if (message.specifiedHoursName != null && message.hasOwnProperty("specifiedHoursName"))
                            object.specifiedHoursName = message.specifiedHoursName;
                        return object;
                    };

                    /**
                     * Converts this DeliveryInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.DeliveryInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeliveryInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeliveryInfo;
                })();

                orders.ChangeOrderAmount = (function() {

                    /**
                     * Properties of a ChangeOrderAmount.
                     * @memberof theplant.ec.service.orders
                     * @interface IChangeOrderAmount
                     * @property {number|Long|null} [toAmount] ChangeOrderAmount toAmount
                     * @property {string|null} [comment] ChangeOrderAmount comment
                     * @property {string|null} [changedBy] ChangeOrderAmount changedBy
                     */

                    /**
                     * Constructs a new ChangeOrderAmount.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a ChangeOrderAmount.
                     * @implements IChangeOrderAmount
                     * @constructor
                     * @param {theplant.ec.service.orders.IChangeOrderAmount=} [properties] Properties to set
                     */
                    function ChangeOrderAmount(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ChangeOrderAmount toAmount.
                     * @member {number|Long} toAmount
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @instance
                     */
                    ChangeOrderAmount.prototype.toAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * ChangeOrderAmount comment.
                     * @member {string} comment
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @instance
                     */
                    ChangeOrderAmount.prototype.comment = "";

                    /**
                     * ChangeOrderAmount changedBy.
                     * @member {string} changedBy
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @instance
                     */
                    ChangeOrderAmount.prototype.changedBy = "";

                    /**
                     * Creates a new ChangeOrderAmount instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {theplant.ec.service.orders.IChangeOrderAmount=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.ChangeOrderAmount} ChangeOrderAmount instance
                     */
                    ChangeOrderAmount.create = function create(properties) {
                        return new ChangeOrderAmount(properties);
                    };

                    /**
                     * Encodes the specified ChangeOrderAmount message. Does not implicitly {@link theplant.ec.service.orders.ChangeOrderAmount.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {theplant.ec.service.orders.IChangeOrderAmount} message ChangeOrderAmount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangeOrderAmount.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.changedBy != null && message.hasOwnProperty("changedBy"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.changedBy);
                        if (message.toAmount != null && message.hasOwnProperty("toAmount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.toAmount);
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.comment);
                        return writer;
                    };

                    /**
                     * Encodes the specified ChangeOrderAmount message, length delimited. Does not implicitly {@link theplant.ec.service.orders.ChangeOrderAmount.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {theplant.ec.service.orders.IChangeOrderAmount} message ChangeOrderAmount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ChangeOrderAmount.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ChangeOrderAmount message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.ChangeOrderAmount} ChangeOrderAmount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangeOrderAmount.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.ChangeOrderAmount();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                message.toAmount = reader.uint64();
                                break;
                            case 4:
                                message.comment = reader.string();
                                break;
                            case 1:
                                message.changedBy = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ChangeOrderAmount message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.ChangeOrderAmount} ChangeOrderAmount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ChangeOrderAmount.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ChangeOrderAmount message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ChangeOrderAmount.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.toAmount != null && message.hasOwnProperty("toAmount"))
                            if (!$util.isInteger(message.toAmount) && !(message.toAmount && $util.isInteger(message.toAmount.low) && $util.isInteger(message.toAmount.high)))
                                return "toAmount: integer|Long expected";
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            if (!$util.isString(message.comment))
                                return "comment: string expected";
                        if (message.changedBy != null && message.hasOwnProperty("changedBy"))
                            if (!$util.isString(message.changedBy))
                                return "changedBy: string expected";
                        return null;
                    };

                    /**
                     * Creates a ChangeOrderAmount message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.ChangeOrderAmount} ChangeOrderAmount
                     */
                    ChangeOrderAmount.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.ChangeOrderAmount)
                            return object;
                        var message = new $root.theplant.ec.service.orders.ChangeOrderAmount();
                        if (object.toAmount != null)
                            if ($util.Long)
                                (message.toAmount = $util.Long.fromValue(object.toAmount)).unsigned = true;
                            else if (typeof object.toAmount === "string")
                                message.toAmount = parseInt(object.toAmount, 10);
                            else if (typeof object.toAmount === "number")
                                message.toAmount = object.toAmount;
                            else if (typeof object.toAmount === "object")
                                message.toAmount = new $util.LongBits(object.toAmount.low >>> 0, object.toAmount.high >>> 0).toNumber(true);
                        if (object.comment != null)
                            message.comment = String(object.comment);
                        if (object.changedBy != null)
                            message.changedBy = String(object.changedBy);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ChangeOrderAmount message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @static
                     * @param {theplant.ec.service.orders.ChangeOrderAmount} message ChangeOrderAmount
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ChangeOrderAmount.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.changedBy = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.toAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.toAmount = options.longs === String ? "0" : 0;
                            object.comment = "";
                        }
                        if (message.changedBy != null && message.hasOwnProperty("changedBy"))
                            object.changedBy = message.changedBy;
                        if (message.toAmount != null && message.hasOwnProperty("toAmount"))
                            if (typeof message.toAmount === "number")
                                object.toAmount = options.longs === String ? String(message.toAmount) : message.toAmount;
                            else
                                object.toAmount = options.longs === String ? $util.Long.prototype.toString.call(message.toAmount) : options.longs === Number ? new $util.LongBits(message.toAmount.low >>> 0, message.toAmount.high >>> 0).toNumber(true) : message.toAmount;
                        if (message.comment != null && message.hasOwnProperty("comment"))
                            object.comment = message.comment;
                        return object;
                    };

                    /**
                     * Converts this ChangeOrderAmount to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.ChangeOrderAmount
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ChangeOrderAmount.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ChangeOrderAmount;
                })();

                orders.AppliedOrderItem = (function() {

                    /**
                     * Properties of an AppliedOrderItem.
                     * @memberof theplant.ec.service.orders
                     * @interface IAppliedOrderItem
                     * @property {string|null} [articleCode] AppliedOrderItem articleCode
                     * @property {number|Long|null} [quantity] AppliedOrderItem quantity
                     */

                    /**
                     * Constructs a new AppliedOrderItem.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an AppliedOrderItem.
                     * @implements IAppliedOrderItem
                     * @constructor
                     * @param {theplant.ec.service.orders.IAppliedOrderItem=} [properties] Properties to set
                     */
                    function AppliedOrderItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AppliedOrderItem articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @instance
                     */
                    AppliedOrderItem.prototype.articleCode = "";

                    /**
                     * AppliedOrderItem quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @instance
                     */
                    AppliedOrderItem.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new AppliedOrderItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {theplant.ec.service.orders.IAppliedOrderItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.AppliedOrderItem} AppliedOrderItem instance
                     */
                    AppliedOrderItem.create = function create(properties) {
                        return new AppliedOrderItem(properties);
                    };

                    /**
                     * Encodes the specified AppliedOrderItem message. Does not implicitly {@link theplant.ec.service.orders.AppliedOrderItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {theplant.ec.service.orders.IAppliedOrderItem} message AppliedOrderItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AppliedOrderItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.quantity);
                        return writer;
                    };

                    /**
                     * Encodes the specified AppliedOrderItem message, length delimited. Does not implicitly {@link theplant.ec.service.orders.AppliedOrderItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {theplant.ec.service.orders.IAppliedOrderItem} message AppliedOrderItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AppliedOrderItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AppliedOrderItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.AppliedOrderItem} AppliedOrderItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AppliedOrderItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.AppliedOrderItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.quantity = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AppliedOrderItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.AppliedOrderItem} AppliedOrderItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AppliedOrderItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AppliedOrderItem message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AppliedOrderItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an AppliedOrderItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.AppliedOrderItem} AppliedOrderItem
                     */
                    AppliedOrderItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.AppliedOrderItem)
                            return object;
                        var message = new $root.theplant.ec.service.orders.AppliedOrderItem();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = true;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AppliedOrderItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @static
                     * @param {theplant.ec.service.orders.AppliedOrderItem} message AppliedOrderItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AppliedOrderItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber(true) : message.quantity;
                        return object;
                    };

                    /**
                     * Converts this AppliedOrderItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.AppliedOrderItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AppliedOrderItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AppliedOrderItem;
                })();

                /**
                 * PaymentType enum.
                 * @name theplant.ec.service.orders.PaymentType
                 * @enum {string}
                 * @property {number} NO_PAYMENT=0 NO_PAYMENT value
                 * @property {number} COD=1 COD value
                 * @property {number} CREDIT_CARD=2 CREDIT_CARD value
                 * @property {number} PAY_ALL_WITH_POINT=3 PAY_ALL_WITH_POINT value
                 * @property {number} PAY_ALL_WITH_GIFT_CARD=4 PAY_ALL_WITH_GIFT_CARD value
                 * @property {number} OTHER=5 OTHER value
                 * @property {number} AMAZON_PAY=6 AMAZON_PAY value
                 * @property {number} RAKUTEN_PAY=7 RAKUTEN_PAY value
                 * @property {number} PAY_PAY=8 PAY_PAY value
                 * @property {number} SMART_PAY=10 SMART_PAY value
                 */
                orders.PaymentType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_PAYMENT"] = 0;
                    values[valuesById[1] = "COD"] = 1;
                    values[valuesById[2] = "CREDIT_CARD"] = 2;
                    values[valuesById[3] = "PAY_ALL_WITH_POINT"] = 3;
                    values[valuesById[4] = "PAY_ALL_WITH_GIFT_CARD"] = 4;
                    values[valuesById[5] = "OTHER"] = 5;
                    values[valuesById[6] = "AMAZON_PAY"] = 6;
                    values[valuesById[7] = "RAKUTEN_PAY"] = 7;
                    values[valuesById[8] = "PAY_PAY"] = 8;
                    values[valuesById[10] = "SMART_PAY"] = 10;
                    return values;
                })();

                orders.GiftWrapping = (function() {

                    /**
                     * Properties of a GiftWrapping.
                     * @memberof theplant.ec.service.orders
                     * @interface IGiftWrapping
                     * @property {boolean|null} [specified] GiftWrapping specified
                     * @property {string|null} [message] GiftWrapping message
                     * @property {string|null} [message2] GiftWrapping message2
                     */

                    /**
                     * Constructs a new GiftWrapping.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a GiftWrapping.
                     * @implements IGiftWrapping
                     * @constructor
                     * @param {theplant.ec.service.orders.IGiftWrapping=} [properties] Properties to set
                     */
                    function GiftWrapping(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * GiftWrapping specified.
                     * @member {boolean} specified
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @instance
                     */
                    GiftWrapping.prototype.specified = false;

                    /**
                     * GiftWrapping message.
                     * @member {string} message
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @instance
                     */
                    GiftWrapping.prototype.message = "";

                    /**
                     * GiftWrapping message2.
                     * @member {string} message2
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @instance
                     */
                    GiftWrapping.prototype.message2 = "";

                    /**
                     * Creates a new GiftWrapping instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {theplant.ec.service.orders.IGiftWrapping=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.GiftWrapping} GiftWrapping instance
                     */
                    GiftWrapping.create = function create(properties) {
                        return new GiftWrapping(properties);
                    };

                    /**
                     * Encodes the specified GiftWrapping message. Does not implicitly {@link theplant.ec.service.orders.GiftWrapping.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {theplant.ec.service.orders.IGiftWrapping} message GiftWrapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GiftWrapping.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.specified != null && message.hasOwnProperty("specified"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.specified);
                        if (message.message != null && message.hasOwnProperty("message"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                        if (message.message2 != null && message.hasOwnProperty("message2"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.message2);
                        return writer;
                    };

                    /**
                     * Encodes the specified GiftWrapping message, length delimited. Does not implicitly {@link theplant.ec.service.orders.GiftWrapping.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {theplant.ec.service.orders.IGiftWrapping} message GiftWrapping message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    GiftWrapping.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a GiftWrapping message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.GiftWrapping} GiftWrapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GiftWrapping.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.GiftWrapping();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.specified = reader.bool();
                                break;
                            case 2:
                                message.message = reader.string();
                                break;
                            case 4:
                                message.message2 = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a GiftWrapping message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.GiftWrapping} GiftWrapping
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    GiftWrapping.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a GiftWrapping message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    GiftWrapping.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.specified != null && message.hasOwnProperty("specified"))
                            if (typeof message.specified !== "boolean")
                                return "specified: boolean expected";
                        if (message.message != null && message.hasOwnProperty("message"))
                            if (!$util.isString(message.message))
                                return "message: string expected";
                        if (message.message2 != null && message.hasOwnProperty("message2"))
                            if (!$util.isString(message.message2))
                                return "message2: string expected";
                        return null;
                    };

                    /**
                     * Creates a GiftWrapping message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.GiftWrapping} GiftWrapping
                     */
                    GiftWrapping.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.GiftWrapping)
                            return object;
                        var message = new $root.theplant.ec.service.orders.GiftWrapping();
                        if (object.specified != null)
                            message.specified = Boolean(object.specified);
                        if (object.message != null)
                            message.message = String(object.message);
                        if (object.message2 != null)
                            message.message2 = String(object.message2);
                        return message;
                    };

                    /**
                     * Creates a plain object from a GiftWrapping message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @static
                     * @param {theplant.ec.service.orders.GiftWrapping} message GiftWrapping
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    GiftWrapping.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.specified = false;
                            object.message = "";
                            object.message2 = "";
                        }
                        if (message.specified != null && message.hasOwnProperty("specified"))
                            object.specified = message.specified;
                        if (message.message != null && message.hasOwnProperty("message"))
                            object.message = message.message;
                        if (message.message2 != null && message.hasOwnProperty("message2"))
                            object.message2 = message.message2;
                        return object;
                    };

                    /**
                     * Converts this GiftWrapping to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.GiftWrapping
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    GiftWrapping.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return GiftWrapping;
                })();

                orders.NoveltyGift = (function() {

                    /**
                     * Properties of a NoveltyGift.
                     * @memberof theplant.ec.service.orders
                     * @interface INoveltyGift
                     * @property {boolean|null} [include] NoveltyGift include
                     * @property {string|null} [noveltyType] NoveltyGift noveltyType
                     * @property {number|Long|null} [discountId] NoveltyGift discountId
                     */

                    /**
                     * Constructs a new NoveltyGift.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a NoveltyGift.
                     * @implements INoveltyGift
                     * @constructor
                     * @param {theplant.ec.service.orders.INoveltyGift=} [properties] Properties to set
                     */
                    function NoveltyGift(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * NoveltyGift include.
                     * @member {boolean} include
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @instance
                     */
                    NoveltyGift.prototype.include = false;

                    /**
                     * NoveltyGift noveltyType.
                     * @member {string} noveltyType
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @instance
                     */
                    NoveltyGift.prototype.noveltyType = "";

                    /**
                     * NoveltyGift discountId.
                     * @member {number|Long} discountId
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @instance
                     */
                    NoveltyGift.prototype.discountId = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new NoveltyGift instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {theplant.ec.service.orders.INoveltyGift=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.NoveltyGift} NoveltyGift instance
                     */
                    NoveltyGift.create = function create(properties) {
                        return new NoveltyGift(properties);
                    };

                    /**
                     * Encodes the specified NoveltyGift message. Does not implicitly {@link theplant.ec.service.orders.NoveltyGift.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {theplant.ec.service.orders.INoveltyGift} message NoveltyGift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NoveltyGift.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.include != null && message.hasOwnProperty("include"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.include);
                        if (message.noveltyType != null && message.hasOwnProperty("noveltyType"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.noveltyType);
                        if (message.discountId != null && message.hasOwnProperty("discountId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.discountId);
                        return writer;
                    };

                    /**
                     * Encodes the specified NoveltyGift message, length delimited. Does not implicitly {@link theplant.ec.service.orders.NoveltyGift.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {theplant.ec.service.orders.INoveltyGift} message NoveltyGift message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    NoveltyGift.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a NoveltyGift message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.NoveltyGift} NoveltyGift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NoveltyGift.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.NoveltyGift();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.include = reader.bool();
                                break;
                            case 2:
                                message.noveltyType = reader.string();
                                break;
                            case 3:
                                message.discountId = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a NoveltyGift message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.NoveltyGift} NoveltyGift
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    NoveltyGift.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a NoveltyGift message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    NoveltyGift.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.include != null && message.hasOwnProperty("include"))
                            if (typeof message.include !== "boolean")
                                return "include: boolean expected";
                        if (message.noveltyType != null && message.hasOwnProperty("noveltyType"))
                            if (!$util.isString(message.noveltyType))
                                return "noveltyType: string expected";
                        if (message.discountId != null && message.hasOwnProperty("discountId"))
                            if (!$util.isInteger(message.discountId) && !(message.discountId && $util.isInteger(message.discountId.low) && $util.isInteger(message.discountId.high)))
                                return "discountId: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a NoveltyGift message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.NoveltyGift} NoveltyGift
                     */
                    NoveltyGift.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.NoveltyGift)
                            return object;
                        var message = new $root.theplant.ec.service.orders.NoveltyGift();
                        if (object.include != null)
                            message.include = Boolean(object.include);
                        if (object.noveltyType != null)
                            message.noveltyType = String(object.noveltyType);
                        if (object.discountId != null)
                            if ($util.Long)
                                (message.discountId = $util.Long.fromValue(object.discountId)).unsigned = true;
                            else if (typeof object.discountId === "string")
                                message.discountId = parseInt(object.discountId, 10);
                            else if (typeof object.discountId === "number")
                                message.discountId = object.discountId;
                            else if (typeof object.discountId === "object")
                                message.discountId = new $util.LongBits(object.discountId.low >>> 0, object.discountId.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a NoveltyGift message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @static
                     * @param {theplant.ec.service.orders.NoveltyGift} message NoveltyGift
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    NoveltyGift.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.include = false;
                            object.noveltyType = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.discountId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.discountId = options.longs === String ? "0" : 0;
                        }
                        if (message.include != null && message.hasOwnProperty("include"))
                            object.include = message.include;
                        if (message.noveltyType != null && message.hasOwnProperty("noveltyType"))
                            object.noveltyType = message.noveltyType;
                        if (message.discountId != null && message.hasOwnProperty("discountId"))
                            if (typeof message.discountId === "number")
                                object.discountId = options.longs === String ? String(message.discountId) : message.discountId;
                            else
                                object.discountId = options.longs === String ? $util.Long.prototype.toString.call(message.discountId) : options.longs === Number ? new $util.LongBits(message.discountId.low >>> 0, message.discountId.high >>> 0).toNumber(true) : message.discountId;
                        return object;
                    };

                    /**
                     * Converts this NoveltyGift to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.NoveltyGift
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    NoveltyGift.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NoveltyGift;
                })();

                orders.CreditCardInput = (function() {

                    /**
                     * Properties of a CreditCardInput.
                     * @memberof theplant.ec.service.orders
                     * @interface ICreditCardInput
                     * @property {string|null} [name] CreditCardInput name
                     * @property {string|null} [number] CreditCardInput number
                     * @property {number|Long|null} [expiryYear] CreditCardInput expiryYear
                     * @property {number|Long|null} [expiryMonth] CreditCardInput expiryMonth
                     * @property {string|null} [cvc] CreditCardInput cvc
                     */

                    /**
                     * Constructs a new CreditCardInput.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a CreditCardInput.
                     * @implements ICreditCardInput
                     * @constructor
                     * @param {theplant.ec.service.orders.ICreditCardInput=} [properties] Properties to set
                     */
                    function CreditCardInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreditCardInput name.
                     * @member {string} name
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @instance
                     */
                    CreditCardInput.prototype.name = "";

                    /**
                     * CreditCardInput number.
                     * @member {string} number
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @instance
                     */
                    CreditCardInput.prototype.number = "";

                    /**
                     * CreditCardInput expiryYear.
                     * @member {number|Long} expiryYear
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @instance
                     */
                    CreditCardInput.prototype.expiryYear = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * CreditCardInput expiryMonth.
                     * @member {number|Long} expiryMonth
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @instance
                     */
                    CreditCardInput.prototype.expiryMonth = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * CreditCardInput cvc.
                     * @member {string} cvc
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @instance
                     */
                    CreditCardInput.prototype.cvc = "";

                    /**
                     * Creates a new CreditCardInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {theplant.ec.service.orders.ICreditCardInput=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.CreditCardInput} CreditCardInput instance
                     */
                    CreditCardInput.create = function create(properties) {
                        return new CreditCardInput(properties);
                    };

                    /**
                     * Encodes the specified CreditCardInput message. Does not implicitly {@link theplant.ec.service.orders.CreditCardInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {theplant.ec.service.orders.ICreditCardInput} message CreditCardInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreditCardInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.number != null && message.hasOwnProperty("number"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.number);
                        if (message.expiryYear != null && message.hasOwnProperty("expiryYear"))
                            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.expiryYear);
                        if (message.expiryMonth != null && message.hasOwnProperty("expiryMonth"))
                            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.expiryMonth);
                        if (message.cvc != null && message.hasOwnProperty("cvc"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.cvc);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreditCardInput message, length delimited. Does not implicitly {@link theplant.ec.service.orders.CreditCardInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {theplant.ec.service.orders.ICreditCardInput} message CreditCardInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreditCardInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreditCardInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.CreditCardInput} CreditCardInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreditCardInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.CreditCardInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.number = reader.string();
                                break;
                            case 5:
                                message.expiryYear = reader.uint64();
                                break;
                            case 6:
                                message.expiryMonth = reader.uint64();
                                break;
                            case 7:
                                message.cvc = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreditCardInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.CreditCardInput} CreditCardInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreditCardInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreditCardInput message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreditCardInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.number != null && message.hasOwnProperty("number"))
                            if (!$util.isString(message.number))
                                return "number: string expected";
                        if (message.expiryYear != null && message.hasOwnProperty("expiryYear"))
                            if (!$util.isInteger(message.expiryYear) && !(message.expiryYear && $util.isInteger(message.expiryYear.low) && $util.isInteger(message.expiryYear.high)))
                                return "expiryYear: integer|Long expected";
                        if (message.expiryMonth != null && message.hasOwnProperty("expiryMonth"))
                            if (!$util.isInteger(message.expiryMonth) && !(message.expiryMonth && $util.isInteger(message.expiryMonth.low) && $util.isInteger(message.expiryMonth.high)))
                                return "expiryMonth: integer|Long expected";
                        if (message.cvc != null && message.hasOwnProperty("cvc"))
                            if (!$util.isString(message.cvc))
                                return "cvc: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreditCardInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.CreditCardInput} CreditCardInput
                     */
                    CreditCardInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.CreditCardInput)
                            return object;
                        var message = new $root.theplant.ec.service.orders.CreditCardInput();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.number != null)
                            message.number = String(object.number);
                        if (object.expiryYear != null)
                            if ($util.Long)
                                (message.expiryYear = $util.Long.fromValue(object.expiryYear)).unsigned = true;
                            else if (typeof object.expiryYear === "string")
                                message.expiryYear = parseInt(object.expiryYear, 10);
                            else if (typeof object.expiryYear === "number")
                                message.expiryYear = object.expiryYear;
                            else if (typeof object.expiryYear === "object")
                                message.expiryYear = new $util.LongBits(object.expiryYear.low >>> 0, object.expiryYear.high >>> 0).toNumber(true);
                        if (object.expiryMonth != null)
                            if ($util.Long)
                                (message.expiryMonth = $util.Long.fromValue(object.expiryMonth)).unsigned = true;
                            else if (typeof object.expiryMonth === "string")
                                message.expiryMonth = parseInt(object.expiryMonth, 10);
                            else if (typeof object.expiryMonth === "number")
                                message.expiryMonth = object.expiryMonth;
                            else if (typeof object.expiryMonth === "object")
                                message.expiryMonth = new $util.LongBits(object.expiryMonth.low >>> 0, object.expiryMonth.high >>> 0).toNumber(true);
                        if (object.cvc != null)
                            message.cvc = String(object.cvc);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreditCardInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @static
                     * @param {theplant.ec.service.orders.CreditCardInput} message CreditCardInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreditCardInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.number = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.expiryYear = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.expiryYear = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.expiryMonth = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.expiryMonth = options.longs === String ? "0" : 0;
                            object.cvc = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.number != null && message.hasOwnProperty("number"))
                            object.number = message.number;
                        if (message.expiryYear != null && message.hasOwnProperty("expiryYear"))
                            if (typeof message.expiryYear === "number")
                                object.expiryYear = options.longs === String ? String(message.expiryYear) : message.expiryYear;
                            else
                                object.expiryYear = options.longs === String ? $util.Long.prototype.toString.call(message.expiryYear) : options.longs === Number ? new $util.LongBits(message.expiryYear.low >>> 0, message.expiryYear.high >>> 0).toNumber(true) : message.expiryYear;
                        if (message.expiryMonth != null && message.hasOwnProperty("expiryMonth"))
                            if (typeof message.expiryMonth === "number")
                                object.expiryMonth = options.longs === String ? String(message.expiryMonth) : message.expiryMonth;
                            else
                                object.expiryMonth = options.longs === String ? $util.Long.prototype.toString.call(message.expiryMonth) : options.longs === Number ? new $util.LongBits(message.expiryMonth.low >>> 0, message.expiryMonth.high >>> 0).toNumber(true) : message.expiryMonth;
                        if (message.cvc != null && message.hasOwnProperty("cvc"))
                            object.cvc = message.cvc;
                        return object;
                    };

                    /**
                     * Converts this CreditCardInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.CreditCardInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreditCardInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreditCardInput;
                })();

                orders.CreditCard = (function() {

                    /**
                     * Properties of a CreditCard.
                     * @memberof theplant.ec.service.orders
                     * @interface ICreditCard
                     * @property {string|null} [id] CreditCard id
                     * @property {string|null} [customerId] CreditCard customerId
                     * @property {string|null} [brand] CreditCard brand
                     * @property {string|null} [number] CreditCard number
                     * @property {string|null} [name] CreditCard name
                     * @property {string|null} [expiryDate] CreditCard expiryDate
                     */

                    /**
                     * Constructs a new CreditCard.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a CreditCard.
                     * @implements ICreditCard
                     * @constructor
                     * @param {theplant.ec.service.orders.ICreditCard=} [properties] Properties to set
                     */
                    function CreditCard(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreditCard id.
                     * @member {string} id
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     */
                    CreditCard.prototype.id = "";

                    /**
                     * CreditCard customerId.
                     * @member {string} customerId
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     */
                    CreditCard.prototype.customerId = "";

                    /**
                     * CreditCard brand.
                     * @member {string} brand
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     */
                    CreditCard.prototype.brand = "";

                    /**
                     * CreditCard number.
                     * @member {string} number
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     */
                    CreditCard.prototype.number = "";

                    /**
                     * CreditCard name.
                     * @member {string} name
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     */
                    CreditCard.prototype.name = "";

                    /**
                     * CreditCard expiryDate.
                     * @member {string} expiryDate
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     */
                    CreditCard.prototype.expiryDate = "";

                    /**
                     * Creates a new CreditCard instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {theplant.ec.service.orders.ICreditCard=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.CreditCard} CreditCard instance
                     */
                    CreditCard.create = function create(properties) {
                        return new CreditCard(properties);
                    };

                    /**
                     * Encodes the specified CreditCard message. Does not implicitly {@link theplant.ec.service.orders.CreditCard.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {theplant.ec.service.orders.ICreditCard} message CreditCard message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreditCard.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.customerId);
                        if (message.brand != null && message.hasOwnProperty("brand"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.brand);
                        if (message.number != null && message.hasOwnProperty("number"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.number);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                        if (message.expiryDate != null && message.hasOwnProperty("expiryDate"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.expiryDate);
                        return writer;
                    };

                    /**
                     * Encodes the specified CreditCard message, length delimited. Does not implicitly {@link theplant.ec.service.orders.CreditCard.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {theplant.ec.service.orders.ICreditCard} message CreditCard message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreditCard.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreditCard message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.CreditCard} CreditCard
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreditCard.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.CreditCard();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.customerId = reader.string();
                                break;
                            case 3:
                                message.brand = reader.string();
                                break;
                            case 4:
                                message.number = reader.string();
                                break;
                            case 5:
                                message.name = reader.string();
                                break;
                            case 6:
                                message.expiryDate = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreditCard message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.CreditCard} CreditCard
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreditCard.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreditCard message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreditCard.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            if (!$util.isString(message.customerId))
                                return "customerId: string expected";
                        if (message.brand != null && message.hasOwnProperty("brand"))
                            if (!$util.isString(message.brand))
                                return "brand: string expected";
                        if (message.number != null && message.hasOwnProperty("number"))
                            if (!$util.isString(message.number))
                                return "number: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.expiryDate != null && message.hasOwnProperty("expiryDate"))
                            if (!$util.isString(message.expiryDate))
                                return "expiryDate: string expected";
                        return null;
                    };

                    /**
                     * Creates a CreditCard message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.CreditCard} CreditCard
                     */
                    CreditCard.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.CreditCard)
                            return object;
                        var message = new $root.theplant.ec.service.orders.CreditCard();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.customerId != null)
                            message.customerId = String(object.customerId);
                        if (object.brand != null)
                            message.brand = String(object.brand);
                        if (object.number != null)
                            message.number = String(object.number);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.expiryDate != null)
                            message.expiryDate = String(object.expiryDate);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreditCard message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @static
                     * @param {theplant.ec.service.orders.CreditCard} message CreditCard
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreditCard.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.customerId = "";
                            object.brand = "";
                            object.number = "";
                            object.name = "";
                            object.expiryDate = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            object.customerId = message.customerId;
                        if (message.brand != null && message.hasOwnProperty("brand"))
                            object.brand = message.brand;
                        if (message.number != null && message.hasOwnProperty("number"))
                            object.number = message.number;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.expiryDate != null && message.hasOwnProperty("expiryDate"))
                            object.expiryDate = message.expiryDate;
                        return object;
                    };

                    /**
                     * Converts this CreditCard to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.CreditCard
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreditCard.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreditCard;
                })();

                orders.DeliveryDateRange = (function() {

                    /**
                     * Properties of a DeliveryDateRange.
                     * @memberof theplant.ec.service.orders
                     * @interface IDeliveryDateRange
                     * @property {Array.<string>|null} [dates] DeliveryDateRange dates
                     * @property {Array.<theplant.ec.service.orders.IDeliveryHour>|null} [hours] DeliveryDateRange hours
                     * @property {string|null} [normalDeliveryInfo] DeliveryDateRange normalDeliveryInfo
                     * @property {boolean|null} [cannotSpecify] DeliveryDateRange cannotSpecify
                     * @property {theplant.ec.service.orders.IDeliveryCannotSpecifyReason|null} [reason] DeliveryDateRange reason
                     */

                    /**
                     * Constructs a new DeliveryDateRange.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a DeliveryDateRange.
                     * @implements IDeliveryDateRange
                     * @constructor
                     * @param {theplant.ec.service.orders.IDeliveryDateRange=} [properties] Properties to set
                     */
                    function DeliveryDateRange(properties) {
                        this.dates = [];
                        this.hours = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeliveryDateRange dates.
                     * @member {Array.<string>} dates
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @instance
                     */
                    DeliveryDateRange.prototype.dates = $util.emptyArray;

                    /**
                     * DeliveryDateRange hours.
                     * @member {Array.<theplant.ec.service.orders.IDeliveryHour>} hours
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @instance
                     */
                    DeliveryDateRange.prototype.hours = $util.emptyArray;

                    /**
                     * DeliveryDateRange normalDeliveryInfo.
                     * @member {string} normalDeliveryInfo
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @instance
                     */
                    DeliveryDateRange.prototype.normalDeliveryInfo = "";

                    /**
                     * DeliveryDateRange cannotSpecify.
                     * @member {boolean} cannotSpecify
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @instance
                     */
                    DeliveryDateRange.prototype.cannotSpecify = false;

                    /**
                     * DeliveryDateRange reason.
                     * @member {theplant.ec.service.orders.IDeliveryCannotSpecifyReason|null|undefined} reason
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @instance
                     */
                    DeliveryDateRange.prototype.reason = null;

                    /**
                     * Creates a new DeliveryDateRange instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryDateRange=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.DeliveryDateRange} DeliveryDateRange instance
                     */
                    DeliveryDateRange.create = function create(properties) {
                        return new DeliveryDateRange(properties);
                    };

                    /**
                     * Encodes the specified DeliveryDateRange message. Does not implicitly {@link theplant.ec.service.orders.DeliveryDateRange.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryDateRange} message DeliveryDateRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryDateRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.dates != null && message.dates.length)
                            for (var i = 0; i < message.dates.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.dates[i]);
                        if (message.hours != null && message.hours.length)
                            for (var i = 0; i < message.hours.length; ++i)
                                $root.theplant.ec.service.orders.DeliveryHour.encode(message.hours[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.normalDeliveryInfo != null && message.hasOwnProperty("normalDeliveryInfo"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.normalDeliveryInfo);
                        if (message.cannotSpecify != null && message.hasOwnProperty("cannotSpecify"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.cannotSpecify);
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason.encode(message.reason, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified DeliveryDateRange message, length delimited. Does not implicitly {@link theplant.ec.service.orders.DeliveryDateRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryDateRange} message DeliveryDateRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryDateRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeliveryDateRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.DeliveryDateRange} DeliveryDateRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryDateRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.DeliveryDateRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.dates && message.dates.length))
                                    message.dates = [];
                                message.dates.push(reader.string());
                                break;
                            case 2:
                                if (!(message.hours && message.hours.length))
                                    message.hours = [];
                                message.hours.push($root.theplant.ec.service.orders.DeliveryHour.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.normalDeliveryInfo = reader.string();
                                break;
                            case 4:
                                message.cannotSpecify = reader.bool();
                                break;
                            case 5:
                                message.reason = $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeliveryDateRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.DeliveryDateRange} DeliveryDateRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryDateRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeliveryDateRange message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeliveryDateRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.dates != null && message.hasOwnProperty("dates")) {
                            if (!Array.isArray(message.dates))
                                return "dates: array expected";
                            for (var i = 0; i < message.dates.length; ++i)
                                if (!$util.isString(message.dates[i]))
                                    return "dates: string[] expected";
                        }
                        if (message.hours != null && message.hasOwnProperty("hours")) {
                            if (!Array.isArray(message.hours))
                                return "hours: array expected";
                            for (var i = 0; i < message.hours.length; ++i) {
                                var error = $root.theplant.ec.service.orders.DeliveryHour.verify(message.hours[i]);
                                if (error)
                                    return "hours." + error;
                            }
                        }
                        if (message.normalDeliveryInfo != null && message.hasOwnProperty("normalDeliveryInfo"))
                            if (!$util.isString(message.normalDeliveryInfo))
                                return "normalDeliveryInfo: string expected";
                        if (message.cannotSpecify != null && message.hasOwnProperty("cannotSpecify"))
                            if (typeof message.cannotSpecify !== "boolean")
                                return "cannotSpecify: boolean expected";
                        if (message.reason != null && message.hasOwnProperty("reason")) {
                            var error = $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason.verify(message.reason);
                            if (error)
                                return "reason." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a DeliveryDateRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.DeliveryDateRange} DeliveryDateRange
                     */
                    DeliveryDateRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.DeliveryDateRange)
                            return object;
                        var message = new $root.theplant.ec.service.orders.DeliveryDateRange();
                        if (object.dates) {
                            if (!Array.isArray(object.dates))
                                throw TypeError(".theplant.ec.service.orders.DeliveryDateRange.dates: array expected");
                            message.dates = [];
                            for (var i = 0; i < object.dates.length; ++i)
                                message.dates[i] = String(object.dates[i]);
                        }
                        if (object.hours) {
                            if (!Array.isArray(object.hours))
                                throw TypeError(".theplant.ec.service.orders.DeliveryDateRange.hours: array expected");
                            message.hours = [];
                            for (var i = 0; i < object.hours.length; ++i) {
                                if (typeof object.hours[i] !== "object")
                                    throw TypeError(".theplant.ec.service.orders.DeliveryDateRange.hours: object expected");
                                message.hours[i] = $root.theplant.ec.service.orders.DeliveryHour.fromObject(object.hours[i]);
                            }
                        }
                        if (object.normalDeliveryInfo != null)
                            message.normalDeliveryInfo = String(object.normalDeliveryInfo);
                        if (object.cannotSpecify != null)
                            message.cannotSpecify = Boolean(object.cannotSpecify);
                        if (object.reason != null) {
                            if (typeof object.reason !== "object")
                                throw TypeError(".theplant.ec.service.orders.DeliveryDateRange.reason: object expected");
                            message.reason = $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason.fromObject(object.reason);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeliveryDateRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @static
                     * @param {theplant.ec.service.orders.DeliveryDateRange} message DeliveryDateRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeliveryDateRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.dates = [];
                            object.hours = [];
                        }
                        if (options.defaults) {
                            object.normalDeliveryInfo = "";
                            object.cannotSpecify = false;
                            object.reason = null;
                        }
                        if (message.dates && message.dates.length) {
                            object.dates = [];
                            for (var j = 0; j < message.dates.length; ++j)
                                object.dates[j] = message.dates[j];
                        }
                        if (message.hours && message.hours.length) {
                            object.hours = [];
                            for (var j = 0; j < message.hours.length; ++j)
                                object.hours[j] = $root.theplant.ec.service.orders.DeliveryHour.toObject(message.hours[j], options);
                        }
                        if (message.normalDeliveryInfo != null && message.hasOwnProperty("normalDeliveryInfo"))
                            object.normalDeliveryInfo = message.normalDeliveryInfo;
                        if (message.cannotSpecify != null && message.hasOwnProperty("cannotSpecify"))
                            object.cannotSpecify = message.cannotSpecify;
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            object.reason = $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason.toObject(message.reason, options);
                        return object;
                    };

                    /**
                     * Converts this DeliveryDateRange to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.DeliveryDateRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeliveryDateRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeliveryDateRange;
                })();

                orders.DeliveryCannotSpecifyReason = (function() {

                    /**
                     * Properties of a DeliveryCannotSpecifyReason.
                     * @memberof theplant.ec.service.orders
                     * @interface IDeliveryCannotSpecifyReason
                     * @property {string|null} [noticeInfo] DeliveryCannotSpecifyReason noticeInfo
                     */

                    /**
                     * Constructs a new DeliveryCannotSpecifyReason.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a DeliveryCannotSpecifyReason.
                     * @implements IDeliveryCannotSpecifyReason
                     * @constructor
                     * @param {theplant.ec.service.orders.IDeliveryCannotSpecifyReason=} [properties] Properties to set
                     */
                    function DeliveryCannotSpecifyReason(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeliveryCannotSpecifyReason noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @instance
                     */
                    DeliveryCannotSpecifyReason.prototype.noticeInfo = "";

                    /**
                     * Creates a new DeliveryCannotSpecifyReason instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryCannotSpecifyReason=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.DeliveryCannotSpecifyReason} DeliveryCannotSpecifyReason instance
                     */
                    DeliveryCannotSpecifyReason.create = function create(properties) {
                        return new DeliveryCannotSpecifyReason(properties);
                    };

                    /**
                     * Encodes the specified DeliveryCannotSpecifyReason message. Does not implicitly {@link theplant.ec.service.orders.DeliveryCannotSpecifyReason.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryCannotSpecifyReason} message DeliveryCannotSpecifyReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryCannotSpecifyReason.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.noticeInfo);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeliveryCannotSpecifyReason message, length delimited. Does not implicitly {@link theplant.ec.service.orders.DeliveryCannotSpecifyReason.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryCannotSpecifyReason} message DeliveryCannotSpecifyReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryCannotSpecifyReason.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeliveryCannotSpecifyReason message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.DeliveryCannotSpecifyReason} DeliveryCannotSpecifyReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryCannotSpecifyReason.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.noticeInfo = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeliveryCannotSpecifyReason message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.DeliveryCannotSpecifyReason} DeliveryCannotSpecifyReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryCannotSpecifyReason.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeliveryCannotSpecifyReason message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeliveryCannotSpecifyReason.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        return null;
                    };

                    /**
                     * Creates a DeliveryCannotSpecifyReason message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.DeliveryCannotSpecifyReason} DeliveryCannotSpecifyReason
                     */
                    DeliveryCannotSpecifyReason.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason)
                            return object;
                        var message = new $root.theplant.ec.service.orders.DeliveryCannotSpecifyReason();
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeliveryCannotSpecifyReason message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @static
                     * @param {theplant.ec.service.orders.DeliveryCannotSpecifyReason} message DeliveryCannotSpecifyReason
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeliveryCannotSpecifyReason.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.noticeInfo = "";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        return object;
                    };

                    /**
                     * Converts this DeliveryCannotSpecifyReason to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.DeliveryCannotSpecifyReason
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeliveryCannotSpecifyReason.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeliveryCannotSpecifyReason;
                })();

                orders.DeliveryHour = (function() {

                    /**
                     * Properties of a DeliveryHour.
                     * @memberof theplant.ec.service.orders
                     * @interface IDeliveryHour
                     * @property {string|null} [name] DeliveryHour name
                     * @property {string|null} [value] DeliveryHour value
                     */

                    /**
                     * Constructs a new DeliveryHour.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a DeliveryHour.
                     * @implements IDeliveryHour
                     * @constructor
                     * @param {theplant.ec.service.orders.IDeliveryHour=} [properties] Properties to set
                     */
                    function DeliveryHour(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeliveryHour name.
                     * @member {string} name
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @instance
                     */
                    DeliveryHour.prototype.name = "";

                    /**
                     * DeliveryHour value.
                     * @member {string} value
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @instance
                     */
                    DeliveryHour.prototype.value = "";

                    /**
                     * Creates a new DeliveryHour instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryHour=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.DeliveryHour} DeliveryHour instance
                     */
                    DeliveryHour.create = function create(properties) {
                        return new DeliveryHour(properties);
                    };

                    /**
                     * Encodes the specified DeliveryHour message. Does not implicitly {@link theplant.ec.service.orders.DeliveryHour.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryHour} message DeliveryHour message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryHour.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.value != null && message.hasOwnProperty("value"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeliveryHour message, length delimited. Does not implicitly {@link theplant.ec.service.orders.DeliveryHour.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {theplant.ec.service.orders.IDeliveryHour} message DeliveryHour message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeliveryHour.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeliveryHour message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.DeliveryHour} DeliveryHour
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryHour.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.DeliveryHour();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.value = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeliveryHour message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.DeliveryHour} DeliveryHour
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeliveryHour.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeliveryHour message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeliveryHour.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        return null;
                    };

                    /**
                     * Creates a DeliveryHour message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.DeliveryHour} DeliveryHour
                     */
                    DeliveryHour.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.DeliveryHour)
                            return object;
                        var message = new $root.theplant.ec.service.orders.DeliveryHour();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.value != null)
                            message.value = String(object.value);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeliveryHour message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @static
                     * @param {theplant.ec.service.orders.DeliveryHour} message DeliveryHour
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeliveryHour.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.value = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        return object;
                    };

                    /**
                     * Converts this DeliveryHour to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.DeliveryHour
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeliveryHour.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeliveryHour;
                })();

                orders.ExclusionReason = (function() {

                    /**
                     * Properties of an ExclusionReason.
                     * @memberof theplant.ec.service.orders
                     * @interface IExclusionReason
                     * @property {string|null} [noticeInfo] ExclusionReason noticeInfo
                     */

                    /**
                     * Constructs a new ExclusionReason.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an ExclusionReason.
                     * @implements IExclusionReason
                     * @constructor
                     * @param {theplant.ec.service.orders.IExclusionReason=} [properties] Properties to set
                     */
                    function ExclusionReason(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ExclusionReason noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @instance
                     */
                    ExclusionReason.prototype.noticeInfo = "";

                    /**
                     * Creates a new ExclusionReason instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.orders.IExclusionReason=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.ExclusionReason} ExclusionReason instance
                     */
                    ExclusionReason.create = function create(properties) {
                        return new ExclusionReason(properties);
                    };

                    /**
                     * Encodes the specified ExclusionReason message. Does not implicitly {@link theplant.ec.service.orders.ExclusionReason.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.orders.IExclusionReason} message ExclusionReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExclusionReason.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.noticeInfo);
                        return writer;
                    };

                    /**
                     * Encodes the specified ExclusionReason message, length delimited. Does not implicitly {@link theplant.ec.service.orders.ExclusionReason.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.orders.IExclusionReason} message ExclusionReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExclusionReason.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ExclusionReason message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.ExclusionReason} ExclusionReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExclusionReason.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.ExclusionReason();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.noticeInfo = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ExclusionReason message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.ExclusionReason} ExclusionReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExclusionReason.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ExclusionReason message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExclusionReason.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        return null;
                    };

                    /**
                     * Creates an ExclusionReason message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.ExclusionReason} ExclusionReason
                     */
                    ExclusionReason.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.ExclusionReason)
                            return object;
                        var message = new $root.theplant.ec.service.orders.ExclusionReason();
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ExclusionReason message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.orders.ExclusionReason} message ExclusionReason
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExclusionReason.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.noticeInfo = "";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        return object;
                    };

                    /**
                     * Converts this ExclusionReason to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.ExclusionReason
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExclusionReason.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ExclusionReason;
                })();

                /**
                 * OrderChannel enum.
                 * @name theplant.ec.service.orders.OrderChannel
                 * @enum {string}
                 * @property {number} EC_WEB=0 EC_WEB value
                 * @property {number} IN_STORE=1 IN_STORE value
                 */
                orders.OrderChannel = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "EC_WEB"] = 0;
                    values[valuesById[1] = "IN_STORE"] = 1;
                    return values;
                })();

                orders.AmazonPayInput = (function() {

                    /**
                     * Properties of an AmazonPayInput.
                     * @memberof theplant.ec.service.orders
                     * @interface IAmazonPayInput
                     * @property {string|null} [amazonPayToken] AmazonPayInput amazonPayToken
                     * @property {string|null} [amazonPayReferenceId] AmazonPayInput amazonPayReferenceId
                     * @property {string|null} [amazonPaySessionId] AmazonPayInput amazonPaySessionId
                     * @property {string|null} [amazonPayResultReturnUrl] AmazonPayInput amazonPayResultReturnUrl
                     */

                    /**
                     * Constructs a new AmazonPayInput.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an AmazonPayInput.
                     * @implements IAmazonPayInput
                     * @constructor
                     * @param {theplant.ec.service.orders.IAmazonPayInput=} [properties] Properties to set
                     */
                    function AmazonPayInput(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AmazonPayInput amazonPayToken.
                     * @member {string} amazonPayToken
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @instance
                     */
                    AmazonPayInput.prototype.amazonPayToken = "";

                    /**
                     * AmazonPayInput amazonPayReferenceId.
                     * @member {string} amazonPayReferenceId
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @instance
                     */
                    AmazonPayInput.prototype.amazonPayReferenceId = "";

                    /**
                     * AmazonPayInput amazonPaySessionId.
                     * @member {string} amazonPaySessionId
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @instance
                     */
                    AmazonPayInput.prototype.amazonPaySessionId = "";

                    /**
                     * AmazonPayInput amazonPayResultReturnUrl.
                     * @member {string} amazonPayResultReturnUrl
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @instance
                     */
                    AmazonPayInput.prototype.amazonPayResultReturnUrl = "";

                    /**
                     * Creates a new AmazonPayInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayInput=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.AmazonPayInput} AmazonPayInput instance
                     */
                    AmazonPayInput.create = function create(properties) {
                        return new AmazonPayInput(properties);
                    };

                    /**
                     * Encodes the specified AmazonPayInput message. Does not implicitly {@link theplant.ec.service.orders.AmazonPayInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayInput} message AmazonPayInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AmazonPayInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.amazonPayToken != null && message.hasOwnProperty("amazonPayToken"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.amazonPayToken);
                        if (message.amazonPayReferenceId != null && message.hasOwnProperty("amazonPayReferenceId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.amazonPayReferenceId);
                        if (message.amazonPaySessionId != null && message.hasOwnProperty("amazonPaySessionId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.amazonPaySessionId);
                        if (message.amazonPayResultReturnUrl != null && message.hasOwnProperty("amazonPayResultReturnUrl"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.amazonPayResultReturnUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified AmazonPayInput message, length delimited. Does not implicitly {@link theplant.ec.service.orders.AmazonPayInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayInput} message AmazonPayInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AmazonPayInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AmazonPayInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.AmazonPayInput} AmazonPayInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AmazonPayInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.AmazonPayInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.amazonPayToken = reader.string();
                                break;
                            case 2:
                                message.amazonPayReferenceId = reader.string();
                                break;
                            case 3:
                                message.amazonPaySessionId = reader.string();
                                break;
                            case 4:
                                message.amazonPayResultReturnUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AmazonPayInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.AmazonPayInput} AmazonPayInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AmazonPayInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AmazonPayInput message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AmazonPayInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.amazonPayToken != null && message.hasOwnProperty("amazonPayToken"))
                            if (!$util.isString(message.amazonPayToken))
                                return "amazonPayToken: string expected";
                        if (message.amazonPayReferenceId != null && message.hasOwnProperty("amazonPayReferenceId"))
                            if (!$util.isString(message.amazonPayReferenceId))
                                return "amazonPayReferenceId: string expected";
                        if (message.amazonPaySessionId != null && message.hasOwnProperty("amazonPaySessionId"))
                            if (!$util.isString(message.amazonPaySessionId))
                                return "amazonPaySessionId: string expected";
                        if (message.amazonPayResultReturnUrl != null && message.hasOwnProperty("amazonPayResultReturnUrl"))
                            if (!$util.isString(message.amazonPayResultReturnUrl))
                                return "amazonPayResultReturnUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates an AmazonPayInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.AmazonPayInput} AmazonPayInput
                     */
                    AmazonPayInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.AmazonPayInput)
                            return object;
                        var message = new $root.theplant.ec.service.orders.AmazonPayInput();
                        if (object.amazonPayToken != null)
                            message.amazonPayToken = String(object.amazonPayToken);
                        if (object.amazonPayReferenceId != null)
                            message.amazonPayReferenceId = String(object.amazonPayReferenceId);
                        if (object.amazonPaySessionId != null)
                            message.amazonPaySessionId = String(object.amazonPaySessionId);
                        if (object.amazonPayResultReturnUrl != null)
                            message.amazonPayResultReturnUrl = String(object.amazonPayResultReturnUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AmazonPayInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @static
                     * @param {theplant.ec.service.orders.AmazonPayInput} message AmazonPayInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AmazonPayInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.amazonPayToken = "";
                            object.amazonPayReferenceId = "";
                            object.amazonPaySessionId = "";
                            object.amazonPayResultReturnUrl = "";
                        }
                        if (message.amazonPayToken != null && message.hasOwnProperty("amazonPayToken"))
                            object.amazonPayToken = message.amazonPayToken;
                        if (message.amazonPayReferenceId != null && message.hasOwnProperty("amazonPayReferenceId"))
                            object.amazonPayReferenceId = message.amazonPayReferenceId;
                        if (message.amazonPaySessionId != null && message.hasOwnProperty("amazonPaySessionId"))
                            object.amazonPaySessionId = message.amazonPaySessionId;
                        if (message.amazonPayResultReturnUrl != null && message.hasOwnProperty("amazonPayResultReturnUrl"))
                            object.amazonPayResultReturnUrl = message.amazonPayResultReturnUrl;
                        return object;
                    };

                    /**
                     * Converts this AmazonPayInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.AmazonPayInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AmazonPayInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AmazonPayInput;
                })();

                orders.CartItemQuantityLimitInfo = (function() {

                    /**
                     * Properties of a CartItemQuantityLimitInfo.
                     * @memberof theplant.ec.service.orders
                     * @interface ICartItemQuantityLimitInfo
                     * @property {string|null} [noticeInfo] CartItemQuantityLimitInfo noticeInfo
                     * @property {number|Long|null} [maxQuantity] CartItemQuantityLimitInfo maxQuantity
                     */

                    /**
                     * Constructs a new CartItemQuantityLimitInfo.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a CartItemQuantityLimitInfo.
                     * @implements ICartItemQuantityLimitInfo
                     * @constructor
                     * @param {theplant.ec.service.orders.ICartItemQuantityLimitInfo=} [properties] Properties to set
                     */
                    function CartItemQuantityLimitInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CartItemQuantityLimitInfo noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @instance
                     */
                    CartItemQuantityLimitInfo.prototype.noticeInfo = "";

                    /**
                     * CartItemQuantityLimitInfo maxQuantity.
                     * @member {number|Long} maxQuantity
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @instance
                     */
                    CartItemQuantityLimitInfo.prototype.maxQuantity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new CartItemQuantityLimitInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.ICartItemQuantityLimitInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.CartItemQuantityLimitInfo} CartItemQuantityLimitInfo instance
                     */
                    CartItemQuantityLimitInfo.create = function create(properties) {
                        return new CartItemQuantityLimitInfo(properties);
                    };

                    /**
                     * Encodes the specified CartItemQuantityLimitInfo message. Does not implicitly {@link theplant.ec.service.orders.CartItemQuantityLimitInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.ICartItemQuantityLimitInfo} message CartItemQuantityLimitInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CartItemQuantityLimitInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.noticeInfo);
                        if (message.maxQuantity != null && message.hasOwnProperty("maxQuantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.maxQuantity);
                        return writer;
                    };

                    /**
                     * Encodes the specified CartItemQuantityLimitInfo message, length delimited. Does not implicitly {@link theplant.ec.service.orders.CartItemQuantityLimitInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.ICartItemQuantityLimitInfo} message CartItemQuantityLimitInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CartItemQuantityLimitInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CartItemQuantityLimitInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.CartItemQuantityLimitInfo} CartItemQuantityLimitInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CartItemQuantityLimitInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.CartItemQuantityLimitInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.noticeInfo = reader.string();
                                break;
                            case 2:
                                message.maxQuantity = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CartItemQuantityLimitInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.CartItemQuantityLimitInfo} CartItemQuantityLimitInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CartItemQuantityLimitInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CartItemQuantityLimitInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CartItemQuantityLimitInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        if (message.maxQuantity != null && message.hasOwnProperty("maxQuantity"))
                            if (!$util.isInteger(message.maxQuantity) && !(message.maxQuantity && $util.isInteger(message.maxQuantity.low) && $util.isInteger(message.maxQuantity.high)))
                                return "maxQuantity: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a CartItemQuantityLimitInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.CartItemQuantityLimitInfo} CartItemQuantityLimitInfo
                     */
                    CartItemQuantityLimitInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.CartItemQuantityLimitInfo)
                            return object;
                        var message = new $root.theplant.ec.service.orders.CartItemQuantityLimitInfo();
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        if (object.maxQuantity != null)
                            if ($util.Long)
                                (message.maxQuantity = $util.Long.fromValue(object.maxQuantity)).unsigned = true;
                            else if (typeof object.maxQuantity === "string")
                                message.maxQuantity = parseInt(object.maxQuantity, 10);
                            else if (typeof object.maxQuantity === "number")
                                message.maxQuantity = object.maxQuantity;
                            else if (typeof object.maxQuantity === "object")
                                message.maxQuantity = new $util.LongBits(object.maxQuantity.low >>> 0, object.maxQuantity.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CartItemQuantityLimitInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.CartItemQuantityLimitInfo} message CartItemQuantityLimitInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CartItemQuantityLimitInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.noticeInfo = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.maxQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.maxQuantity = options.longs === String ? "0" : 0;
                        }
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        if (message.maxQuantity != null && message.hasOwnProperty("maxQuantity"))
                            if (typeof message.maxQuantity === "number")
                                object.maxQuantity = options.longs === String ? String(message.maxQuantity) : message.maxQuantity;
                            else
                                object.maxQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.maxQuantity) : options.longs === Number ? new $util.LongBits(message.maxQuantity.low >>> 0, message.maxQuantity.high >>> 0).toNumber(true) : message.maxQuantity;
                        return object;
                    };

                    /**
                     * Converts this CartItemQuantityLimitInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.CartItemQuantityLimitInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CartItemQuantityLimitInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CartItemQuantityLimitInfo;
                })();

                orders.CartAmountLimitInfo = (function() {

                    /**
                     * Properties of a CartAmountLimitInfo.
                     * @memberof theplant.ec.service.orders
                     * @interface ICartAmountLimitInfo
                     * @property {string|null} [noticeInfo] CartAmountLimitInfo noticeInfo
                     * @property {number|Long|null} [maxAmount] CartAmountLimitInfo maxAmount
                     */

                    /**
                     * Constructs a new CartAmountLimitInfo.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a CartAmountLimitInfo.
                     * @implements ICartAmountLimitInfo
                     * @constructor
                     * @param {theplant.ec.service.orders.ICartAmountLimitInfo=} [properties] Properties to set
                     */
                    function CartAmountLimitInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CartAmountLimitInfo noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @instance
                     */
                    CartAmountLimitInfo.prototype.noticeInfo = "";

                    /**
                     * CartAmountLimitInfo maxAmount.
                     * @member {number|Long} maxAmount
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @instance
                     */
                    CartAmountLimitInfo.prototype.maxAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new CartAmountLimitInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.ICartAmountLimitInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.CartAmountLimitInfo} CartAmountLimitInfo instance
                     */
                    CartAmountLimitInfo.create = function create(properties) {
                        return new CartAmountLimitInfo(properties);
                    };

                    /**
                     * Encodes the specified CartAmountLimitInfo message. Does not implicitly {@link theplant.ec.service.orders.CartAmountLimitInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.ICartAmountLimitInfo} message CartAmountLimitInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CartAmountLimitInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.noticeInfo);
                        if (message.maxAmount != null && message.hasOwnProperty("maxAmount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.maxAmount);
                        return writer;
                    };

                    /**
                     * Encodes the specified CartAmountLimitInfo message, length delimited. Does not implicitly {@link theplant.ec.service.orders.CartAmountLimitInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.ICartAmountLimitInfo} message CartAmountLimitInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CartAmountLimitInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CartAmountLimitInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.CartAmountLimitInfo} CartAmountLimitInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CartAmountLimitInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.CartAmountLimitInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.noticeInfo = reader.string();
                                break;
                            case 2:
                                message.maxAmount = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CartAmountLimitInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.CartAmountLimitInfo} CartAmountLimitInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CartAmountLimitInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CartAmountLimitInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CartAmountLimitInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        if (message.maxAmount != null && message.hasOwnProperty("maxAmount"))
                            if (!$util.isInteger(message.maxAmount) && !(message.maxAmount && $util.isInteger(message.maxAmount.low) && $util.isInteger(message.maxAmount.high)))
                                return "maxAmount: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a CartAmountLimitInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.CartAmountLimitInfo} CartAmountLimitInfo
                     */
                    CartAmountLimitInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.CartAmountLimitInfo)
                            return object;
                        var message = new $root.theplant.ec.service.orders.CartAmountLimitInfo();
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        if (object.maxAmount != null)
                            if ($util.Long)
                                (message.maxAmount = $util.Long.fromValue(object.maxAmount)).unsigned = true;
                            else if (typeof object.maxAmount === "string")
                                message.maxAmount = parseInt(object.maxAmount, 10);
                            else if (typeof object.maxAmount === "number")
                                message.maxAmount = object.maxAmount;
                            else if (typeof object.maxAmount === "object")
                                message.maxAmount = new $util.LongBits(object.maxAmount.low >>> 0, object.maxAmount.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a CartAmountLimitInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @static
                     * @param {theplant.ec.service.orders.CartAmountLimitInfo} message CartAmountLimitInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CartAmountLimitInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.noticeInfo = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.maxAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.maxAmount = options.longs === String ? "0" : 0;
                        }
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        if (message.maxAmount != null && message.hasOwnProperty("maxAmount"))
                            if (typeof message.maxAmount === "number")
                                object.maxAmount = options.longs === String ? String(message.maxAmount) : message.maxAmount;
                            else
                                object.maxAmount = options.longs === String ? $util.Long.prototype.toString.call(message.maxAmount) : options.longs === Number ? new $util.LongBits(message.maxAmount.low >>> 0, message.maxAmount.high >>> 0).toNumber(true) : message.maxAmount;
                        return object;
                    };

                    /**
                     * Converts this CartAmountLimitInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.CartAmountLimitInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CartAmountLimitInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CartAmountLimitInfo;
                })();

                orders.AmazonPayCreateSessionInput = (function() {

                    /**
                     * Properties of an AmazonPayCreateSessionInput.
                     * @memberof theplant.ec.service.orders
                     * @interface IAmazonPayCreateSessionInput
                     * @property {theplant.ec.service.orders.IWebCheckoutDetails|null} [webCheckoutDetails] AmazonPayCreateSessionInput webCheckoutDetails
                     * @property {string|null} [storeId] AmazonPayCreateSessionInput storeId
                     * @property {Array.<string>|null} [scopes] AmazonPayCreateSessionInput scopes
                     */

                    /**
                     * Constructs a new AmazonPayCreateSessionInput.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an AmazonPayCreateSessionInput.
                     * @implements IAmazonPayCreateSessionInput
                     * @constructor
                     * @param {theplant.ec.service.orders.IAmazonPayCreateSessionInput=} [properties] Properties to set
                     */
                    function AmazonPayCreateSessionInput(properties) {
                        this.scopes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AmazonPayCreateSessionInput webCheckoutDetails.
                     * @member {theplant.ec.service.orders.IWebCheckoutDetails|null|undefined} webCheckoutDetails
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @instance
                     */
                    AmazonPayCreateSessionInput.prototype.webCheckoutDetails = null;

                    /**
                     * AmazonPayCreateSessionInput storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @instance
                     */
                    AmazonPayCreateSessionInput.prototype.storeId = "";

                    /**
                     * AmazonPayCreateSessionInput scopes.
                     * @member {Array.<string>} scopes
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @instance
                     */
                    AmazonPayCreateSessionInput.prototype.scopes = $util.emptyArray;

                    /**
                     * Creates a new AmazonPayCreateSessionInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayCreateSessionInput=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.AmazonPayCreateSessionInput} AmazonPayCreateSessionInput instance
                     */
                    AmazonPayCreateSessionInput.create = function create(properties) {
                        return new AmazonPayCreateSessionInput(properties);
                    };

                    /**
                     * Encodes the specified AmazonPayCreateSessionInput message. Does not implicitly {@link theplant.ec.service.orders.AmazonPayCreateSessionInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayCreateSessionInput} message AmazonPayCreateSessionInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AmazonPayCreateSessionInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.webCheckoutDetails != null && message.hasOwnProperty("webCheckoutDetails"))
                            $root.theplant.ec.service.orders.WebCheckoutDetails.encode(message.webCheckoutDetails, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.storeId);
                        if (message.scopes != null && message.scopes.length)
                            for (var i = 0; i < message.scopes.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.scopes[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified AmazonPayCreateSessionInput message, length delimited. Does not implicitly {@link theplant.ec.service.orders.AmazonPayCreateSessionInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayCreateSessionInput} message AmazonPayCreateSessionInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AmazonPayCreateSessionInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AmazonPayCreateSessionInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.AmazonPayCreateSessionInput} AmazonPayCreateSessionInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AmazonPayCreateSessionInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.AmazonPayCreateSessionInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.webCheckoutDetails = $root.theplant.ec.service.orders.WebCheckoutDetails.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.storeId = reader.string();
                                break;
                            case 3:
                                if (!(message.scopes && message.scopes.length))
                                    message.scopes = [];
                                message.scopes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AmazonPayCreateSessionInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.AmazonPayCreateSessionInput} AmazonPayCreateSessionInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AmazonPayCreateSessionInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AmazonPayCreateSessionInput message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AmazonPayCreateSessionInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.webCheckoutDetails != null && message.hasOwnProperty("webCheckoutDetails")) {
                            var error = $root.theplant.ec.service.orders.WebCheckoutDetails.verify(message.webCheckoutDetails);
                            if (error)
                                return "webCheckoutDetails." + error;
                        }
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.scopes != null && message.hasOwnProperty("scopes")) {
                            if (!Array.isArray(message.scopes))
                                return "scopes: array expected";
                            for (var i = 0; i < message.scopes.length; ++i)
                                if (!$util.isString(message.scopes[i]))
                                    return "scopes: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates an AmazonPayCreateSessionInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.AmazonPayCreateSessionInput} AmazonPayCreateSessionInput
                     */
                    AmazonPayCreateSessionInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.AmazonPayCreateSessionInput)
                            return object;
                        var message = new $root.theplant.ec.service.orders.AmazonPayCreateSessionInput();
                        if (object.webCheckoutDetails != null) {
                            if (typeof object.webCheckoutDetails !== "object")
                                throw TypeError(".theplant.ec.service.orders.AmazonPayCreateSessionInput.webCheckoutDetails: object expected");
                            message.webCheckoutDetails = $root.theplant.ec.service.orders.WebCheckoutDetails.fromObject(object.webCheckoutDetails);
                        }
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.scopes) {
                            if (!Array.isArray(object.scopes))
                                throw TypeError(".theplant.ec.service.orders.AmazonPayCreateSessionInput.scopes: array expected");
                            message.scopes = [];
                            for (var i = 0; i < object.scopes.length; ++i)
                                message.scopes[i] = String(object.scopes[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AmazonPayCreateSessionInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @static
                     * @param {theplant.ec.service.orders.AmazonPayCreateSessionInput} message AmazonPayCreateSessionInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AmazonPayCreateSessionInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.scopes = [];
                        if (options.defaults) {
                            object.webCheckoutDetails = null;
                            object.storeId = "";
                        }
                        if (message.webCheckoutDetails != null && message.hasOwnProperty("webCheckoutDetails"))
                            object.webCheckoutDetails = $root.theplant.ec.service.orders.WebCheckoutDetails.toObject(message.webCheckoutDetails, options);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.scopes && message.scopes.length) {
                            object.scopes = [];
                            for (var j = 0; j < message.scopes.length; ++j)
                                object.scopes[j] = message.scopes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this AmazonPayCreateSessionInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.AmazonPayCreateSessionInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AmazonPayCreateSessionInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AmazonPayCreateSessionInput;
                })();

                orders.WebCheckoutDetails = (function() {

                    /**
                     * Properties of a WebCheckoutDetails.
                     * @memberof theplant.ec.service.orders
                     * @interface IWebCheckoutDetails
                     * @property {string|null} [checkoutReviewReturnUrl] WebCheckoutDetails checkoutReviewReturnUrl
                     * @property {string|null} [checkoutResultReturnUrl] WebCheckoutDetails checkoutResultReturnUrl
                     * @property {string|null} [amazonPayRedirectUrl] WebCheckoutDetails amazonPayRedirectUrl
                     */

                    /**
                     * Constructs a new WebCheckoutDetails.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a WebCheckoutDetails.
                     * @implements IWebCheckoutDetails
                     * @constructor
                     * @param {theplant.ec.service.orders.IWebCheckoutDetails=} [properties] Properties to set
                     */
                    function WebCheckoutDetails(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * WebCheckoutDetails checkoutReviewReturnUrl.
                     * @member {string} checkoutReviewReturnUrl
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @instance
                     */
                    WebCheckoutDetails.prototype.checkoutReviewReturnUrl = "";

                    /**
                     * WebCheckoutDetails checkoutResultReturnUrl.
                     * @member {string} checkoutResultReturnUrl
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @instance
                     */
                    WebCheckoutDetails.prototype.checkoutResultReturnUrl = "";

                    /**
                     * WebCheckoutDetails amazonPayRedirectUrl.
                     * @member {string} amazonPayRedirectUrl
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @instance
                     */
                    WebCheckoutDetails.prototype.amazonPayRedirectUrl = "";

                    /**
                     * Creates a new WebCheckoutDetails instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {theplant.ec.service.orders.IWebCheckoutDetails=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.WebCheckoutDetails} WebCheckoutDetails instance
                     */
                    WebCheckoutDetails.create = function create(properties) {
                        return new WebCheckoutDetails(properties);
                    };

                    /**
                     * Encodes the specified WebCheckoutDetails message. Does not implicitly {@link theplant.ec.service.orders.WebCheckoutDetails.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {theplant.ec.service.orders.IWebCheckoutDetails} message WebCheckoutDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WebCheckoutDetails.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.checkoutReviewReturnUrl != null && message.hasOwnProperty("checkoutReviewReturnUrl"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.checkoutReviewReturnUrl);
                        if (message.checkoutResultReturnUrl != null && message.hasOwnProperty("checkoutResultReturnUrl"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.checkoutResultReturnUrl);
                        if (message.amazonPayRedirectUrl != null && message.hasOwnProperty("amazonPayRedirectUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.amazonPayRedirectUrl);
                        return writer;
                    };

                    /**
                     * Encodes the specified WebCheckoutDetails message, length delimited. Does not implicitly {@link theplant.ec.service.orders.WebCheckoutDetails.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {theplant.ec.service.orders.IWebCheckoutDetails} message WebCheckoutDetails message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WebCheckoutDetails.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WebCheckoutDetails message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.WebCheckoutDetails} WebCheckoutDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WebCheckoutDetails.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.WebCheckoutDetails();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.checkoutReviewReturnUrl = reader.string();
                                break;
                            case 2:
                                message.checkoutResultReturnUrl = reader.string();
                                break;
                            case 3:
                                message.amazonPayRedirectUrl = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WebCheckoutDetails message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.WebCheckoutDetails} WebCheckoutDetails
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WebCheckoutDetails.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WebCheckoutDetails message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WebCheckoutDetails.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.checkoutReviewReturnUrl != null && message.hasOwnProperty("checkoutReviewReturnUrl"))
                            if (!$util.isString(message.checkoutReviewReturnUrl))
                                return "checkoutReviewReturnUrl: string expected";
                        if (message.checkoutResultReturnUrl != null && message.hasOwnProperty("checkoutResultReturnUrl"))
                            if (!$util.isString(message.checkoutResultReturnUrl))
                                return "checkoutResultReturnUrl: string expected";
                        if (message.amazonPayRedirectUrl != null && message.hasOwnProperty("amazonPayRedirectUrl"))
                            if (!$util.isString(message.amazonPayRedirectUrl))
                                return "amazonPayRedirectUrl: string expected";
                        return null;
                    };

                    /**
                     * Creates a WebCheckoutDetails message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.WebCheckoutDetails} WebCheckoutDetails
                     */
                    WebCheckoutDetails.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.WebCheckoutDetails)
                            return object;
                        var message = new $root.theplant.ec.service.orders.WebCheckoutDetails();
                        if (object.checkoutReviewReturnUrl != null)
                            message.checkoutReviewReturnUrl = String(object.checkoutReviewReturnUrl);
                        if (object.checkoutResultReturnUrl != null)
                            message.checkoutResultReturnUrl = String(object.checkoutResultReturnUrl);
                        if (object.amazonPayRedirectUrl != null)
                            message.amazonPayRedirectUrl = String(object.amazonPayRedirectUrl);
                        return message;
                    };

                    /**
                     * Creates a plain object from a WebCheckoutDetails message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @static
                     * @param {theplant.ec.service.orders.WebCheckoutDetails} message WebCheckoutDetails
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WebCheckoutDetails.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.checkoutReviewReturnUrl = "";
                            object.checkoutResultReturnUrl = "";
                            object.amazonPayRedirectUrl = "";
                        }
                        if (message.checkoutReviewReturnUrl != null && message.hasOwnProperty("checkoutReviewReturnUrl"))
                            object.checkoutReviewReturnUrl = message.checkoutReviewReturnUrl;
                        if (message.checkoutResultReturnUrl != null && message.hasOwnProperty("checkoutResultReturnUrl"))
                            object.checkoutResultReturnUrl = message.checkoutResultReturnUrl;
                        if (message.amazonPayRedirectUrl != null && message.hasOwnProperty("amazonPayRedirectUrl"))
                            object.amazonPayRedirectUrl = message.amazonPayRedirectUrl;
                        return object;
                    };

                    /**
                     * Converts this WebCheckoutDetails to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.WebCheckoutDetails
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WebCheckoutDetails.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return WebCheckoutDetails;
                })();

                orders.AmazonPayReferenceInfo = (function() {

                    /**
                     * Properties of an AmazonPayReferenceInfo.
                     * @memberof theplant.ec.service.orders
                     * @interface IAmazonPayReferenceInfo
                     * @property {string|null} [email] AmazonPayReferenceInfo email
                     * @property {theplant.ec.service.users.IAddress|null} [address] AmazonPayReferenceInfo address
                     * @property {Array.<string>|null} [paymentReference] AmazonPayReferenceInfo paymentReference
                     * @property {theplant.ec.service.orders.IGiftWrapping|null} [giftWrapping] AmazonPayReferenceInfo giftWrapping
                     * @property {theplant.ec.service.orders.IDeliveryInfo|null} [deliveryInfo] AmazonPayReferenceInfo deliveryInfo
                     * @property {number|Long|null} [points] AmazonPayReferenceInfo points
                     * @property {theplant.ec.service.users.IAddress|null} [billingAddress] AmazonPayReferenceInfo billingAddress
                     */

                    /**
                     * Constructs a new AmazonPayReferenceInfo.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents an AmazonPayReferenceInfo.
                     * @implements IAmazonPayReferenceInfo
                     * @constructor
                     * @param {theplant.ec.service.orders.IAmazonPayReferenceInfo=} [properties] Properties to set
                     */
                    function AmazonPayReferenceInfo(properties) {
                        this.paymentReference = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AmazonPayReferenceInfo email.
                     * @member {string} email
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.email = "";

                    /**
                     * AmazonPayReferenceInfo address.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} address
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.address = null;

                    /**
                     * AmazonPayReferenceInfo paymentReference.
                     * @member {Array.<string>} paymentReference
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.paymentReference = $util.emptyArray;

                    /**
                     * AmazonPayReferenceInfo giftWrapping.
                     * @member {theplant.ec.service.orders.IGiftWrapping|null|undefined} giftWrapping
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.giftWrapping = null;

                    /**
                     * AmazonPayReferenceInfo deliveryInfo.
                     * @member {theplant.ec.service.orders.IDeliveryInfo|null|undefined} deliveryInfo
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.deliveryInfo = null;

                    /**
                     * AmazonPayReferenceInfo points.
                     * @member {number|Long} points
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.points = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * AmazonPayReferenceInfo billingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} billingAddress
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     */
                    AmazonPayReferenceInfo.prototype.billingAddress = null;

                    /**
                     * Creates a new AmazonPayReferenceInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayReferenceInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.AmazonPayReferenceInfo} AmazonPayReferenceInfo instance
                     */
                    AmazonPayReferenceInfo.create = function create(properties) {
                        return new AmazonPayReferenceInfo(properties);
                    };

                    /**
                     * Encodes the specified AmazonPayReferenceInfo message. Does not implicitly {@link theplant.ec.service.orders.AmazonPayReferenceInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayReferenceInfo} message AmazonPayReferenceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AmazonPayReferenceInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.email != null && message.hasOwnProperty("email"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                        if (message.address != null && message.hasOwnProperty("address"))
                            $root.theplant.ec.service.users.Address.encode(message.address, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.paymentReference != null && message.paymentReference.length)
                            for (var i = 0; i < message.paymentReference.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.paymentReference[i]);
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            $root.theplant.ec.service.orders.GiftWrapping.encode(message.giftWrapping, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            $root.theplant.ec.service.orders.DeliveryInfo.encode(message.deliveryInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.points != null && message.hasOwnProperty("points"))
                            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.points);
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.billingAddress, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AmazonPayReferenceInfo message, length delimited. Does not implicitly {@link theplant.ec.service.orders.AmazonPayReferenceInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {theplant.ec.service.orders.IAmazonPayReferenceInfo} message AmazonPayReferenceInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AmazonPayReferenceInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AmazonPayReferenceInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.AmazonPayReferenceInfo} AmazonPayReferenceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AmazonPayReferenceInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.AmazonPayReferenceInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.email = reader.string();
                                break;
                            case 2:
                                message.address = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 3:
                                if (!(message.paymentReference && message.paymentReference.length))
                                    message.paymentReference = [];
                                message.paymentReference.push(reader.string());
                                break;
                            case 4:
                                message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.points = reader.uint64();
                                break;
                            case 7:
                                message.billingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AmazonPayReferenceInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.AmazonPayReferenceInfo} AmazonPayReferenceInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AmazonPayReferenceInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AmazonPayReferenceInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AmazonPayReferenceInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.email != null && message.hasOwnProperty("email"))
                            if (!$util.isString(message.email))
                                return "email: string expected";
                        if (message.address != null && message.hasOwnProperty("address")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.address);
                            if (error)
                                return "address." + error;
                        }
                        if (message.paymentReference != null && message.hasOwnProperty("paymentReference")) {
                            if (!Array.isArray(message.paymentReference))
                                return "paymentReference: array expected";
                            for (var i = 0; i < message.paymentReference.length; ++i)
                                if (!$util.isString(message.paymentReference[i]))
                                    return "paymentReference: string[] expected";
                        }
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping")) {
                            var error = $root.theplant.ec.service.orders.GiftWrapping.verify(message.giftWrapping);
                            if (error)
                                return "giftWrapping." + error;
                        }
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo")) {
                            var error = $root.theplant.ec.service.orders.DeliveryInfo.verify(message.deliveryInfo);
                            if (error)
                                return "deliveryInfo." + error;
                        }
                        if (message.points != null && message.hasOwnProperty("points"))
                            if (!$util.isInteger(message.points) && !(message.points && $util.isInteger(message.points.low) && $util.isInteger(message.points.high)))
                                return "points: integer|Long expected";
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.billingAddress);
                            if (error)
                                return "billingAddress." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an AmazonPayReferenceInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.AmazonPayReferenceInfo} AmazonPayReferenceInfo
                     */
                    AmazonPayReferenceInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.AmazonPayReferenceInfo)
                            return object;
                        var message = new $root.theplant.ec.service.orders.AmazonPayReferenceInfo();
                        if (object.email != null)
                            message.email = String(object.email);
                        if (object.address != null) {
                            if (typeof object.address !== "object")
                                throw TypeError(".theplant.ec.service.orders.AmazonPayReferenceInfo.address: object expected");
                            message.address = $root.theplant.ec.service.users.Address.fromObject(object.address);
                        }
                        if (object.paymentReference) {
                            if (!Array.isArray(object.paymentReference))
                                throw TypeError(".theplant.ec.service.orders.AmazonPayReferenceInfo.paymentReference: array expected");
                            message.paymentReference = [];
                            for (var i = 0; i < object.paymentReference.length; ++i)
                                message.paymentReference[i] = String(object.paymentReference[i]);
                        }
                        if (object.giftWrapping != null) {
                            if (typeof object.giftWrapping !== "object")
                                throw TypeError(".theplant.ec.service.orders.AmazonPayReferenceInfo.giftWrapping: object expected");
                            message.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.fromObject(object.giftWrapping);
                        }
                        if (object.deliveryInfo != null) {
                            if (typeof object.deliveryInfo !== "object")
                                throw TypeError(".theplant.ec.service.orders.AmazonPayReferenceInfo.deliveryInfo: object expected");
                            message.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.fromObject(object.deliveryInfo);
                        }
                        if (object.points != null)
                            if ($util.Long)
                                (message.points = $util.Long.fromValue(object.points)).unsigned = true;
                            else if (typeof object.points === "string")
                                message.points = parseInt(object.points, 10);
                            else if (typeof object.points === "number")
                                message.points = object.points;
                            else if (typeof object.points === "object")
                                message.points = new $util.LongBits(object.points.low >>> 0, object.points.high >>> 0).toNumber(true);
                        if (object.billingAddress != null) {
                            if (typeof object.billingAddress !== "object")
                                throw TypeError(".theplant.ec.service.orders.AmazonPayReferenceInfo.billingAddress: object expected");
                            message.billingAddress = $root.theplant.ec.service.users.Address.fromObject(object.billingAddress);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AmazonPayReferenceInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @static
                     * @param {theplant.ec.service.orders.AmazonPayReferenceInfo} message AmazonPayReferenceInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AmazonPayReferenceInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.paymentReference = [];
                        if (options.defaults) {
                            object.email = "";
                            object.address = null;
                            object.giftWrapping = null;
                            object.deliveryInfo = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.points = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.points = options.longs === String ? "0" : 0;
                            object.billingAddress = null;
                        }
                        if (message.email != null && message.hasOwnProperty("email"))
                            object.email = message.email;
                        if (message.address != null && message.hasOwnProperty("address"))
                            object.address = $root.theplant.ec.service.users.Address.toObject(message.address, options);
                        if (message.paymentReference && message.paymentReference.length) {
                            object.paymentReference = [];
                            for (var j = 0; j < message.paymentReference.length; ++j)
                                object.paymentReference[j] = message.paymentReference[j];
                        }
                        if (message.giftWrapping != null && message.hasOwnProperty("giftWrapping"))
                            object.giftWrapping = $root.theplant.ec.service.orders.GiftWrapping.toObject(message.giftWrapping, options);
                        if (message.deliveryInfo != null && message.hasOwnProperty("deliveryInfo"))
                            object.deliveryInfo = $root.theplant.ec.service.orders.DeliveryInfo.toObject(message.deliveryInfo, options);
                        if (message.points != null && message.hasOwnProperty("points"))
                            if (typeof message.points === "number")
                                object.points = options.longs === String ? String(message.points) : message.points;
                            else
                                object.points = options.longs === String ? $util.Long.prototype.toString.call(message.points) : options.longs === Number ? new $util.LongBits(message.points.low >>> 0, message.points.high >>> 0).toNumber(true) : message.points;
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            object.billingAddress = $root.theplant.ec.service.users.Address.toObject(message.billingAddress, options);
                        return object;
                    };

                    /**
                     * Converts this AmazonPayReferenceInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.AmazonPayReferenceInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AmazonPayReferenceInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AmazonPayReferenceInfo;
                })();

                orders.PaygentNotification = (function() {

                    /**
                     * Properties of a PaygentNotification.
                     * @memberof theplant.ec.service.orders
                     * @interface IPaygentNotification
                     * @property {string|null} [transactionId] PaygentNotification transactionId
                     * @property {string|null} [orderCode] PaygentNotification orderCode
                     * @property {number|Long|null} [paymentNoticeId] PaygentNotification paymentNoticeId
                     * @property {string|null} [paymentInitDate] PaygentNotification paymentInitDate
                     * @property {string|null} [paymentChangeDate] PaygentNotification paymentChangeDate
                     * @property {string|null} [paymentAmount] PaygentNotification paymentAmount
                     * @property {string|null} [baseTransactionId] PaygentNotification baseTransactionId
                     * @property {string|null} [paymentStatus] PaygentNotification paymentStatus
                     * @property {string|null} [responseData] PaygentNotification responseData
                     * @property {boolean|null} [isChecked] PaygentNotification isChecked
                     * @property {string|null} [paymentType] PaygentNotification paymentType
                     */

                    /**
                     * Constructs a new PaygentNotification.
                     * @memberof theplant.ec.service.orders
                     * @classdesc Represents a PaygentNotification.
                     * @implements IPaygentNotification
                     * @constructor
                     * @param {theplant.ec.service.orders.IPaygentNotification=} [properties] Properties to set
                     */
                    function PaygentNotification(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PaygentNotification transactionId.
                     * @member {string} transactionId
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.transactionId = "";

                    /**
                     * PaygentNotification orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.orderCode = "";

                    /**
                     * PaygentNotification paymentNoticeId.
                     * @member {number|Long} paymentNoticeId
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.paymentNoticeId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * PaygentNotification paymentInitDate.
                     * @member {string} paymentInitDate
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.paymentInitDate = "";

                    /**
                     * PaygentNotification paymentChangeDate.
                     * @member {string} paymentChangeDate
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.paymentChangeDate = "";

                    /**
                     * PaygentNotification paymentAmount.
                     * @member {string} paymentAmount
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.paymentAmount = "";

                    /**
                     * PaygentNotification baseTransactionId.
                     * @member {string} baseTransactionId
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.baseTransactionId = "";

                    /**
                     * PaygentNotification paymentStatus.
                     * @member {string} paymentStatus
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.paymentStatus = "";

                    /**
                     * PaygentNotification responseData.
                     * @member {string} responseData
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.responseData = "";

                    /**
                     * PaygentNotification isChecked.
                     * @member {boolean} isChecked
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.isChecked = false;

                    /**
                     * PaygentNotification paymentType.
                     * @member {string} paymentType
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     */
                    PaygentNotification.prototype.paymentType = "";

                    /**
                     * Creates a new PaygentNotification instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {theplant.ec.service.orders.IPaygentNotification=} [properties] Properties to set
                     * @returns {theplant.ec.service.orders.PaygentNotification} PaygentNotification instance
                     */
                    PaygentNotification.create = function create(properties) {
                        return new PaygentNotification(properties);
                    };

                    /**
                     * Encodes the specified PaygentNotification message. Does not implicitly {@link theplant.ec.service.orders.PaygentNotification.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {theplant.ec.service.orders.IPaygentNotification} message PaygentNotification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaygentNotification.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.transactionId);
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.orderCode);
                        if (message.paymentNoticeId != null && message.hasOwnProperty("paymentNoticeId"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.paymentNoticeId);
                        if (message.paymentInitDate != null && message.hasOwnProperty("paymentInitDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.paymentInitDate);
                        if (message.paymentChangeDate != null && message.hasOwnProperty("paymentChangeDate"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.paymentChangeDate);
                        if (message.paymentAmount != null && message.hasOwnProperty("paymentAmount"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.paymentAmount);
                        if (message.baseTransactionId != null && message.hasOwnProperty("baseTransactionId"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.baseTransactionId);
                        if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.paymentStatus);
                        if (message.responseData != null && message.hasOwnProperty("responseData"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.responseData);
                        if (message.isChecked != null && message.hasOwnProperty("isChecked"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isChecked);
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.paymentType);
                        return writer;
                    };

                    /**
                     * Encodes the specified PaygentNotification message, length delimited. Does not implicitly {@link theplant.ec.service.orders.PaygentNotification.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {theplant.ec.service.orders.IPaygentNotification} message PaygentNotification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PaygentNotification.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PaygentNotification message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.orders.PaygentNotification} PaygentNotification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaygentNotification.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.orders.PaygentNotification();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.transactionId = reader.string();
                                break;
                            case 2:
                                message.orderCode = reader.string();
                                break;
                            case 3:
                                message.paymentNoticeId = reader.int64();
                                break;
                            case 4:
                                message.paymentInitDate = reader.string();
                                break;
                            case 5:
                                message.paymentChangeDate = reader.string();
                                break;
                            case 6:
                                message.paymentAmount = reader.string();
                                break;
                            case 7:
                                message.baseTransactionId = reader.string();
                                break;
                            case 8:
                                message.paymentStatus = reader.string();
                                break;
                            case 9:
                                message.responseData = reader.string();
                                break;
                            case 10:
                                message.isChecked = reader.bool();
                                break;
                            case 11:
                                message.paymentType = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PaygentNotification message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.orders.PaygentNotification} PaygentNotification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PaygentNotification.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PaygentNotification message.
                     * @function verify
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PaygentNotification.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            if (!$util.isString(message.transactionId))
                                return "transactionId: string expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.paymentNoticeId != null && message.hasOwnProperty("paymentNoticeId"))
                            if (!$util.isInteger(message.paymentNoticeId) && !(message.paymentNoticeId && $util.isInteger(message.paymentNoticeId.low) && $util.isInteger(message.paymentNoticeId.high)))
                                return "paymentNoticeId: integer|Long expected";
                        if (message.paymentInitDate != null && message.hasOwnProperty("paymentInitDate"))
                            if (!$util.isString(message.paymentInitDate))
                                return "paymentInitDate: string expected";
                        if (message.paymentChangeDate != null && message.hasOwnProperty("paymentChangeDate"))
                            if (!$util.isString(message.paymentChangeDate))
                                return "paymentChangeDate: string expected";
                        if (message.paymentAmount != null && message.hasOwnProperty("paymentAmount"))
                            if (!$util.isString(message.paymentAmount))
                                return "paymentAmount: string expected";
                        if (message.baseTransactionId != null && message.hasOwnProperty("baseTransactionId"))
                            if (!$util.isString(message.baseTransactionId))
                                return "baseTransactionId: string expected";
                        if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                            if (!$util.isString(message.paymentStatus))
                                return "paymentStatus: string expected";
                        if (message.responseData != null && message.hasOwnProperty("responseData"))
                            if (!$util.isString(message.responseData))
                                return "responseData: string expected";
                        if (message.isChecked != null && message.hasOwnProperty("isChecked"))
                            if (typeof message.isChecked !== "boolean")
                                return "isChecked: boolean expected";
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            if (!$util.isString(message.paymentType))
                                return "paymentType: string expected";
                        return null;
                    };

                    /**
                     * Creates a PaygentNotification message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.orders.PaygentNotification} PaygentNotification
                     */
                    PaygentNotification.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.orders.PaygentNotification)
                            return object;
                        var message = new $root.theplant.ec.service.orders.PaygentNotification();
                        if (object.transactionId != null)
                            message.transactionId = String(object.transactionId);
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.paymentNoticeId != null)
                            if ($util.Long)
                                (message.paymentNoticeId = $util.Long.fromValue(object.paymentNoticeId)).unsigned = false;
                            else if (typeof object.paymentNoticeId === "string")
                                message.paymentNoticeId = parseInt(object.paymentNoticeId, 10);
                            else if (typeof object.paymentNoticeId === "number")
                                message.paymentNoticeId = object.paymentNoticeId;
                            else if (typeof object.paymentNoticeId === "object")
                                message.paymentNoticeId = new $util.LongBits(object.paymentNoticeId.low >>> 0, object.paymentNoticeId.high >>> 0).toNumber();
                        if (object.paymentInitDate != null)
                            message.paymentInitDate = String(object.paymentInitDate);
                        if (object.paymentChangeDate != null)
                            message.paymentChangeDate = String(object.paymentChangeDate);
                        if (object.paymentAmount != null)
                            message.paymentAmount = String(object.paymentAmount);
                        if (object.baseTransactionId != null)
                            message.baseTransactionId = String(object.baseTransactionId);
                        if (object.paymentStatus != null)
                            message.paymentStatus = String(object.paymentStatus);
                        if (object.responseData != null)
                            message.responseData = String(object.responseData);
                        if (object.isChecked != null)
                            message.isChecked = Boolean(object.isChecked);
                        if (object.paymentType != null)
                            message.paymentType = String(object.paymentType);
                        return message;
                    };

                    /**
                     * Creates a plain object from a PaygentNotification message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @static
                     * @param {theplant.ec.service.orders.PaygentNotification} message PaygentNotification
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PaygentNotification.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.transactionId = "";
                            object.orderCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.paymentNoticeId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.paymentNoticeId = options.longs === String ? "0" : 0;
                            object.paymentInitDate = "";
                            object.paymentChangeDate = "";
                            object.paymentAmount = "";
                            object.baseTransactionId = "";
                            object.paymentStatus = "";
                            object.responseData = "";
                            object.isChecked = false;
                            object.paymentType = "";
                        }
                        if (message.transactionId != null && message.hasOwnProperty("transactionId"))
                            object.transactionId = message.transactionId;
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.paymentNoticeId != null && message.hasOwnProperty("paymentNoticeId"))
                            if (typeof message.paymentNoticeId === "number")
                                object.paymentNoticeId = options.longs === String ? String(message.paymentNoticeId) : message.paymentNoticeId;
                            else
                                object.paymentNoticeId = options.longs === String ? $util.Long.prototype.toString.call(message.paymentNoticeId) : options.longs === Number ? new $util.LongBits(message.paymentNoticeId.low >>> 0, message.paymentNoticeId.high >>> 0).toNumber() : message.paymentNoticeId;
                        if (message.paymentInitDate != null && message.hasOwnProperty("paymentInitDate"))
                            object.paymentInitDate = message.paymentInitDate;
                        if (message.paymentChangeDate != null && message.hasOwnProperty("paymentChangeDate"))
                            object.paymentChangeDate = message.paymentChangeDate;
                        if (message.paymentAmount != null && message.hasOwnProperty("paymentAmount"))
                            object.paymentAmount = message.paymentAmount;
                        if (message.baseTransactionId != null && message.hasOwnProperty("baseTransactionId"))
                            object.baseTransactionId = message.baseTransactionId;
                        if (message.paymentStatus != null && message.hasOwnProperty("paymentStatus"))
                            object.paymentStatus = message.paymentStatus;
                        if (message.responseData != null && message.hasOwnProperty("responseData"))
                            object.responseData = message.responseData;
                        if (message.isChecked != null && message.hasOwnProperty("isChecked"))
                            object.isChecked = message.isChecked;
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            object.paymentType = message.paymentType;
                        return object;
                    };

                    /**
                     * Converts this PaygentNotification to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.orders.PaygentNotification
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PaygentNotification.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PaygentNotification;
                })();

                return orders;
            })();

            service.store_orders = (function() {

                /**
                 * Namespace store_orders.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var store_orders = {};

                /**
                 * StoreOrderState enum.
                 * @name theplant.ec.service.store_orders.StoreOrderState
                 * @enum {string}
                 * @property {number} NO_STORE_ORDER_STATE=0 NO_STORE_ORDER_STATE value
                 * @property {number} STORE_ORDER_CONFIRMED=1 STORE_ORDER_CONFIRMED value
                 * @property {number} ENROUTE=2 ENROUTE value
                 * @property {number} ARRIVED_IN_STORE=3 ARRIVED_IN_STORE value
                 * @property {number} PICKED_UP=4 PICKED_UP value
                 * @property {number} CLOSED=5 CLOSED value
                 * @property {number} STORE_ORDER_CANCELED=6 STORE_ORDER_CANCELED value
                 * @property {number} RETURNED=7 RETURNED value
                 */
                store_orders.StoreOrderState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_STORE_ORDER_STATE"] = 0;
                    values[valuesById[1] = "STORE_ORDER_CONFIRMED"] = 1;
                    values[valuesById[2] = "ENROUTE"] = 2;
                    values[valuesById[3] = "ARRIVED_IN_STORE"] = 3;
                    values[valuesById[4] = "PICKED_UP"] = 4;
                    values[valuesById[5] = "CLOSED"] = 5;
                    values[valuesById[6] = "STORE_ORDER_CANCELED"] = 6;
                    values[valuesById[7] = "RETURNED"] = 7;
                    return values;
                })();

                /**
                 * StoreOrderPaymentType enum.
                 * @name theplant.ec.service.store_orders.StoreOrderPaymentType
                 * @enum {string}
                 * @property {number} NO_STORE_ORDER_PAYMENT_TYPE=0 NO_STORE_ORDER_PAYMENT_TYPE value
                 * @property {number} PAID_IN_STORE=1 PAID_IN_STORE value
                 * @property {number} WILL_PAY_IN_STORE=2 WILL_PAY_IN_STORE value
                 */
                store_orders.StoreOrderPaymentType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_STORE_ORDER_PAYMENT_TYPE"] = 0;
                    values[valuesById[1] = "PAID_IN_STORE"] = 1;
                    values[valuesById[2] = "WILL_PAY_IN_STORE"] = 2;
                    return values;
                })();

                /**
                 * ShippingType enum.
                 * @name theplant.ec.service.store_orders.ShippingType
                 * @enum {string}
                 * @property {number} NO_SHIPPING=0 NO_SHIPPING value
                 * @property {number} TO_ADDRESS=1 TO_ADDRESS value
                 * @property {number} TO_STORE=2 TO_STORE value
                 * @property {number} TO_OTHER_ADDRESS=3 TO_OTHER_ADDRESS value
                 */
                store_orders.ShippingType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_SHIPPING"] = 0;
                    values[valuesById[1] = "TO_ADDRESS"] = 1;
                    values[valuesById[2] = "TO_STORE"] = 2;
                    values[valuesById[3] = "TO_OTHER_ADDRESS"] = 3;
                    return values;
                })();

                /**
                 * StoreOrderMoveStockToWhenClosingType enum.
                 * @name theplant.ec.service.store_orders.StoreOrderMoveStockToWhenClosingType
                 * @enum {string}
                 * @property {number} NO_MOVE_STOCK_TO_WHEN_CLOSING=0 NO_MOVE_STOCK_TO_WHEN_CLOSING value
                 * @property {number} EC_WAREHOUSE=1 EC_WAREHOUSE value
                 * @property {number} STORE_FOR_RESALE=2 STORE_FOR_RESALE value
                 */
                store_orders.StoreOrderMoveStockToWhenClosingType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_MOVE_STOCK_TO_WHEN_CLOSING"] = 0;
                    values[valuesById[1] = "EC_WAREHOUSE"] = 1;
                    values[valuesById[2] = "STORE_FOR_RESALE"] = 2;
                    return values;
                })();

                /**
                 * StoreOrderType enum.
                 * @name theplant.ec.service.store_orders.StoreOrderType
                 * @enum {string}
                 * @property {number} NO_STORE_ORDER_TYPE=0 NO_STORE_ORDER_TYPE value
                 * @property {number} IN_STORE=1 IN_STORE value
                 * @property {number} EC_WEB=2 EC_WEB value
                 */
                store_orders.StoreOrderType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_STORE_ORDER_TYPE"] = 0;
                    values[valuesById[1] = "IN_STORE"] = 1;
                    values[valuesById[2] = "EC_WEB"] = 2;
                    return values;
                })();

                store_orders.StoreOrder = (function() {

                    /**
                     * Properties of a StoreOrder.
                     * @memberof theplant.ec.service.store_orders
                     * @interface IStoreOrder
                     * @property {string|null} [orderCode] StoreOrder orderCode
                     * @property {string|null} [storeCode] StoreOrder storeCode
                     * @property {theplant.ec.service.store_orders.IOrderStore|null} [pickupStore] StoreOrder pickupStore
                     * @property {theplant.ec.service.store_orders.IOrderStore|null} [orderInStore] StoreOrder orderInStore
                     * @property {theplant.ec.service.store_orders.StoreOrderState|null} [state] StoreOrder state
                     * @property {theplant.ec.service.store_orders.IStoreOrderPaymentInfo|null} [paymentInfo] StoreOrder paymentInfo
                     * @property {theplant.ec.service.store_orders.ShippingType|null} [shippingType] StoreOrder shippingType
                     * @property {theplant.ec.service.store_orders.IStoreOrderClosingInfo|null} [closingInfo] StoreOrder closingInfo
                     * @property {string|null} [arrivedAt] StoreOrder arrivedAt
                     * @property {string|null} [pickedUpAt] StoreOrder pickedUpAt
                     * @property {theplant.ec.service.store_orders.StoreOrderType|null} [type] StoreOrder type
                     * @property {boolean|null} [isUserCancelUnallowed] StoreOrder isUserCancelUnallowed
                     */

                    /**
                     * Constructs a new StoreOrder.
                     * @memberof theplant.ec.service.store_orders
                     * @classdesc Represents a StoreOrder.
                     * @implements IStoreOrder
                     * @constructor
                     * @param {theplant.ec.service.store_orders.IStoreOrder=} [properties] Properties to set
                     */
                    function StoreOrder(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreOrder orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.orderCode = "";

                    /**
                     * StoreOrder storeCode.
                     * @member {string} storeCode
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.storeCode = "";

                    /**
                     * StoreOrder pickupStore.
                     * @member {theplant.ec.service.store_orders.IOrderStore|null|undefined} pickupStore
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.pickupStore = null;

                    /**
                     * StoreOrder orderInStore.
                     * @member {theplant.ec.service.store_orders.IOrderStore|null|undefined} orderInStore
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.orderInStore = null;

                    /**
                     * StoreOrder state.
                     * @member {theplant.ec.service.store_orders.StoreOrderState} state
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.state = 0;

                    /**
                     * StoreOrder paymentInfo.
                     * @member {theplant.ec.service.store_orders.IStoreOrderPaymentInfo|null|undefined} paymentInfo
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.paymentInfo = null;

                    /**
                     * StoreOrder shippingType.
                     * @member {theplant.ec.service.store_orders.ShippingType} shippingType
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.shippingType = 0;

                    /**
                     * StoreOrder closingInfo.
                     * @member {theplant.ec.service.store_orders.IStoreOrderClosingInfo|null|undefined} closingInfo
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.closingInfo = null;

                    /**
                     * StoreOrder arrivedAt.
                     * @member {string} arrivedAt
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.arrivedAt = "";

                    /**
                     * StoreOrder pickedUpAt.
                     * @member {string} pickedUpAt
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.pickedUpAt = "";

                    /**
                     * StoreOrder type.
                     * @member {theplant.ec.service.store_orders.StoreOrderType} type
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.type = 0;

                    /**
                     * StoreOrder isUserCancelUnallowed.
                     * @member {boolean} isUserCancelUnallowed
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     */
                    StoreOrder.prototype.isUserCancelUnallowed = false;

                    /**
                     * Creates a new StoreOrder instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrder=} [properties] Properties to set
                     * @returns {theplant.ec.service.store_orders.StoreOrder} StoreOrder instance
                     */
                    StoreOrder.create = function create(properties) {
                        return new StoreOrder(properties);
                    };

                    /**
                     * Encodes the specified StoreOrder message. Does not implicitly {@link theplant.ec.service.store_orders.StoreOrder.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrder} message StoreOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOrder.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.storeCode != null && message.hasOwnProperty("storeCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.storeCode);
                        if (message.pickupStore != null && message.hasOwnProperty("pickupStore"))
                            $root.theplant.ec.service.store_orders.OrderStore.encode(message.pickupStore, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.orderInStore != null && message.hasOwnProperty("orderInStore"))
                            $root.theplant.ec.service.store_orders.OrderStore.encode(message.orderInStore, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.state);
                        if (message.paymentInfo != null && message.hasOwnProperty("paymentInfo"))
                            $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo.encode(message.paymentInfo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.shippingType != null && message.hasOwnProperty("shippingType"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.shippingType);
                        if (message.closingInfo != null && message.hasOwnProperty("closingInfo"))
                            $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.encode(message.closingInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.arrivedAt != null && message.hasOwnProperty("arrivedAt"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.arrivedAt);
                        if (message.pickedUpAt != null && message.hasOwnProperty("pickedUpAt"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.pickedUpAt);
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.type);
                        if (message.isUserCancelUnallowed != null && message.hasOwnProperty("isUserCancelUnallowed"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isUserCancelUnallowed);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreOrder message, length delimited. Does not implicitly {@link theplant.ec.service.store_orders.StoreOrder.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrder} message StoreOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOrder.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreOrder message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.store_orders.StoreOrder} StoreOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOrder.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.store_orders.StoreOrder();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.storeCode = reader.string();
                                break;
                            case 3:
                                message.pickupStore = $root.theplant.ec.service.store_orders.OrderStore.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.orderInStore = $root.theplant.ec.service.store_orders.OrderStore.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.state = reader.int32();
                                break;
                            case 6:
                                message.paymentInfo = $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.shippingType = reader.int32();
                                break;
                            case 8:
                                message.closingInfo = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.arrivedAt = reader.string();
                                break;
                            case 10:
                                message.pickedUpAt = reader.string();
                                break;
                            case 11:
                                message.type = reader.int32();
                                break;
                            case 12:
                                message.isUserCancelUnallowed = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreOrder message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.store_orders.StoreOrder} StoreOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOrder.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreOrder message.
                     * @function verify
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreOrder.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.storeCode != null && message.hasOwnProperty("storeCode"))
                            if (!$util.isString(message.storeCode))
                                return "storeCode: string expected";
                        if (message.pickupStore != null && message.hasOwnProperty("pickupStore")) {
                            var error = $root.theplant.ec.service.store_orders.OrderStore.verify(message.pickupStore);
                            if (error)
                                return "pickupStore." + error;
                        }
                        if (message.orderInStore != null && message.hasOwnProperty("orderInStore")) {
                            var error = $root.theplant.ec.service.store_orders.OrderStore.verify(message.orderInStore);
                            if (error)
                                return "orderInStore." + error;
                        }
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                                break;
                            }
                        if (message.paymentInfo != null && message.hasOwnProperty("paymentInfo")) {
                            var error = $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo.verify(message.paymentInfo);
                            if (error)
                                return "paymentInfo." + error;
                        }
                        if (message.shippingType != null && message.hasOwnProperty("shippingType"))
                            switch (message.shippingType) {
                            default:
                                return "shippingType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.closingInfo != null && message.hasOwnProperty("closingInfo")) {
                            var error = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.verify(message.closingInfo);
                            if (error)
                                return "closingInfo." + error;
                        }
                        if (message.arrivedAt != null && message.hasOwnProperty("arrivedAt"))
                            if (!$util.isString(message.arrivedAt))
                                return "arrivedAt: string expected";
                        if (message.pickedUpAt != null && message.hasOwnProperty("pickedUpAt"))
                            if (!$util.isString(message.pickedUpAt))
                                return "pickedUpAt: string expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.isUserCancelUnallowed != null && message.hasOwnProperty("isUserCancelUnallowed"))
                            if (typeof message.isUserCancelUnallowed !== "boolean")
                                return "isUserCancelUnallowed: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a StoreOrder message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.store_orders.StoreOrder} StoreOrder
                     */
                    StoreOrder.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.store_orders.StoreOrder)
                            return object;
                        var message = new $root.theplant.ec.service.store_orders.StoreOrder();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.storeCode != null)
                            message.storeCode = String(object.storeCode);
                        if (object.pickupStore != null) {
                            if (typeof object.pickupStore !== "object")
                                throw TypeError(".theplant.ec.service.store_orders.StoreOrder.pickupStore: object expected");
                            message.pickupStore = $root.theplant.ec.service.store_orders.OrderStore.fromObject(object.pickupStore);
                        }
                        if (object.orderInStore != null) {
                            if (typeof object.orderInStore !== "object")
                                throw TypeError(".theplant.ec.service.store_orders.StoreOrder.orderInStore: object expected");
                            message.orderInStore = $root.theplant.ec.service.store_orders.OrderStore.fromObject(object.orderInStore);
                        }
                        switch (object.state) {
                        case "NO_STORE_ORDER_STATE":
                        case 0:
                            message.state = 0;
                            break;
                        case "STORE_ORDER_CONFIRMED":
                        case 1:
                            message.state = 1;
                            break;
                        case "ENROUTE":
                        case 2:
                            message.state = 2;
                            break;
                        case "ARRIVED_IN_STORE":
                        case 3:
                            message.state = 3;
                            break;
                        case "PICKED_UP":
                        case 4:
                            message.state = 4;
                            break;
                        case "CLOSED":
                        case 5:
                            message.state = 5;
                            break;
                        case "STORE_ORDER_CANCELED":
                        case 6:
                            message.state = 6;
                            break;
                        case "RETURNED":
                        case 7:
                            message.state = 7;
                            break;
                        }
                        if (object.paymentInfo != null) {
                            if (typeof object.paymentInfo !== "object")
                                throw TypeError(".theplant.ec.service.store_orders.StoreOrder.paymentInfo: object expected");
                            message.paymentInfo = $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo.fromObject(object.paymentInfo);
                        }
                        switch (object.shippingType) {
                        case "NO_SHIPPING":
                        case 0:
                            message.shippingType = 0;
                            break;
                        case "TO_ADDRESS":
                        case 1:
                            message.shippingType = 1;
                            break;
                        case "TO_STORE":
                        case 2:
                            message.shippingType = 2;
                            break;
                        case "TO_OTHER_ADDRESS":
                        case 3:
                            message.shippingType = 3;
                            break;
                        }
                        if (object.closingInfo != null) {
                            if (typeof object.closingInfo !== "object")
                                throw TypeError(".theplant.ec.service.store_orders.StoreOrder.closingInfo: object expected");
                            message.closingInfo = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.fromObject(object.closingInfo);
                        }
                        if (object.arrivedAt != null)
                            message.arrivedAt = String(object.arrivedAt);
                        if (object.pickedUpAt != null)
                            message.pickedUpAt = String(object.pickedUpAt);
                        switch (object.type) {
                        case "NO_STORE_ORDER_TYPE":
                        case 0:
                            message.type = 0;
                            break;
                        case "IN_STORE":
                        case 1:
                            message.type = 1;
                            break;
                        case "EC_WEB":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.isUserCancelUnallowed != null)
                            message.isUserCancelUnallowed = Boolean(object.isUserCancelUnallowed);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreOrder message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @static
                     * @param {theplant.ec.service.store_orders.StoreOrder} message StoreOrder
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreOrder.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.storeCode = "";
                            object.pickupStore = null;
                            object.orderInStore = null;
                            object.state = options.enums === String ? "NO_STORE_ORDER_STATE" : 0;
                            object.paymentInfo = null;
                            object.shippingType = options.enums === String ? "NO_SHIPPING" : 0;
                            object.closingInfo = null;
                            object.arrivedAt = "";
                            object.pickedUpAt = "";
                            object.type = options.enums === String ? "NO_STORE_ORDER_TYPE" : 0;
                            object.isUserCancelUnallowed = false;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.storeCode != null && message.hasOwnProperty("storeCode"))
                            object.storeCode = message.storeCode;
                        if (message.pickupStore != null && message.hasOwnProperty("pickupStore"))
                            object.pickupStore = $root.theplant.ec.service.store_orders.OrderStore.toObject(message.pickupStore, options);
                        if (message.orderInStore != null && message.hasOwnProperty("orderInStore"))
                            object.orderInStore = $root.theplant.ec.service.store_orders.OrderStore.toObject(message.orderInStore, options);
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.theplant.ec.service.store_orders.StoreOrderState[message.state] : message.state;
                        if (message.paymentInfo != null && message.hasOwnProperty("paymentInfo"))
                            object.paymentInfo = $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo.toObject(message.paymentInfo, options);
                        if (message.shippingType != null && message.hasOwnProperty("shippingType"))
                            object.shippingType = options.enums === String ? $root.theplant.ec.service.store_orders.ShippingType[message.shippingType] : message.shippingType;
                        if (message.closingInfo != null && message.hasOwnProperty("closingInfo"))
                            object.closingInfo = $root.theplant.ec.service.store_orders.StoreOrderClosingInfo.toObject(message.closingInfo, options);
                        if (message.arrivedAt != null && message.hasOwnProperty("arrivedAt"))
                            object.arrivedAt = message.arrivedAt;
                        if (message.pickedUpAt != null && message.hasOwnProperty("pickedUpAt"))
                            object.pickedUpAt = message.pickedUpAt;
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.theplant.ec.service.store_orders.StoreOrderType[message.type] : message.type;
                        if (message.isUserCancelUnallowed != null && message.hasOwnProperty("isUserCancelUnallowed"))
                            object.isUserCancelUnallowed = message.isUserCancelUnallowed;
                        return object;
                    };

                    /**
                     * Converts this StoreOrder to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.store_orders.StoreOrder
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreOrder.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreOrder;
                })();

                store_orders.OrderStore = (function() {

                    /**
                     * Properties of an OrderStore.
                     * @memberof theplant.ec.service.store_orders
                     * @interface IOrderStore
                     * @property {string|null} [externalCode] OrderStore externalCode
                     * @property {theplant.ec.service.users.IAddress|null} [address] OrderStore address
                     */

                    /**
                     * Constructs a new OrderStore.
                     * @memberof theplant.ec.service.store_orders
                     * @classdesc Represents an OrderStore.
                     * @implements IOrderStore
                     * @constructor
                     * @param {theplant.ec.service.store_orders.IOrderStore=} [properties] Properties to set
                     */
                    function OrderStore(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderStore externalCode.
                     * @member {string} externalCode
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @instance
                     */
                    OrderStore.prototype.externalCode = "";

                    /**
                     * OrderStore address.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} address
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @instance
                     */
                    OrderStore.prototype.address = null;

                    /**
                     * Creates a new OrderStore instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {theplant.ec.service.store_orders.IOrderStore=} [properties] Properties to set
                     * @returns {theplant.ec.service.store_orders.OrderStore} OrderStore instance
                     */
                    OrderStore.create = function create(properties) {
                        return new OrderStore(properties);
                    };

                    /**
                     * Encodes the specified OrderStore message. Does not implicitly {@link theplant.ec.service.store_orders.OrderStore.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {theplant.ec.service.store_orders.IOrderStore} message OrderStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderStore.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.externalCode != null && message.hasOwnProperty("externalCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.externalCode);
                        if (message.address != null && message.hasOwnProperty("address"))
                            $root.theplant.ec.service.users.Address.encode(message.address, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderStore message, length delimited. Does not implicitly {@link theplant.ec.service.store_orders.OrderStore.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {theplant.ec.service.store_orders.IOrderStore} message OrderStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderStore.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderStore message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.store_orders.OrderStore} OrderStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderStore.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.store_orders.OrderStore();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.externalCode = reader.string();
                                break;
                            case 2:
                                message.address = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderStore message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.store_orders.OrderStore} OrderStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderStore.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderStore message.
                     * @function verify
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderStore.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.externalCode != null && message.hasOwnProperty("externalCode"))
                            if (!$util.isString(message.externalCode))
                                return "externalCode: string expected";
                        if (message.address != null && message.hasOwnProperty("address")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.address);
                            if (error)
                                return "address." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderStore message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.store_orders.OrderStore} OrderStore
                     */
                    OrderStore.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.store_orders.OrderStore)
                            return object;
                        var message = new $root.theplant.ec.service.store_orders.OrderStore();
                        if (object.externalCode != null)
                            message.externalCode = String(object.externalCode);
                        if (object.address != null) {
                            if (typeof object.address !== "object")
                                throw TypeError(".theplant.ec.service.store_orders.OrderStore.address: object expected");
                            message.address = $root.theplant.ec.service.users.Address.fromObject(object.address);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderStore message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @static
                     * @param {theplant.ec.service.store_orders.OrderStore} message OrderStore
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderStore.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.externalCode = "";
                            object.address = null;
                        }
                        if (message.externalCode != null && message.hasOwnProperty("externalCode"))
                            object.externalCode = message.externalCode;
                        if (message.address != null && message.hasOwnProperty("address"))
                            object.address = $root.theplant.ec.service.users.Address.toObject(message.address, options);
                        return object;
                    };

                    /**
                     * Converts this OrderStore to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.store_orders.OrderStore
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderStore.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderStore;
                })();

                store_orders.StoreOrderPaymentInfo = (function() {

                    /**
                     * Properties of a StoreOrderPaymentInfo.
                     * @memberof theplant.ec.service.store_orders
                     * @interface IStoreOrderPaymentInfo
                     * @property {theplant.ec.service.store_orders.StoreOrderPaymentType|null} [paymentType] StoreOrderPaymentInfo paymentType
                     * @property {string|null} [externalPaymentId] StoreOrderPaymentInfo externalPaymentId
                     * @property {string|null} [paidAt] StoreOrderPaymentInfo paidAt
                     */

                    /**
                     * Constructs a new StoreOrderPaymentInfo.
                     * @memberof theplant.ec.service.store_orders
                     * @classdesc Represents a StoreOrderPaymentInfo.
                     * @implements IStoreOrderPaymentInfo
                     * @constructor
                     * @param {theplant.ec.service.store_orders.IStoreOrderPaymentInfo=} [properties] Properties to set
                     */
                    function StoreOrderPaymentInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreOrderPaymentInfo paymentType.
                     * @member {theplant.ec.service.store_orders.StoreOrderPaymentType} paymentType
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @instance
                     */
                    StoreOrderPaymentInfo.prototype.paymentType = 0;

                    /**
                     * StoreOrderPaymentInfo externalPaymentId.
                     * @member {string} externalPaymentId
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @instance
                     */
                    StoreOrderPaymentInfo.prototype.externalPaymentId = "";

                    /**
                     * StoreOrderPaymentInfo paidAt.
                     * @member {string} paidAt
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @instance
                     */
                    StoreOrderPaymentInfo.prototype.paidAt = "";

                    /**
                     * Creates a new StoreOrderPaymentInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrderPaymentInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.store_orders.StoreOrderPaymentInfo} StoreOrderPaymentInfo instance
                     */
                    StoreOrderPaymentInfo.create = function create(properties) {
                        return new StoreOrderPaymentInfo(properties);
                    };

                    /**
                     * Encodes the specified StoreOrderPaymentInfo message. Does not implicitly {@link theplant.ec.service.store_orders.StoreOrderPaymentInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrderPaymentInfo} message StoreOrderPaymentInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOrderPaymentInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.paymentType);
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.externalPaymentId);
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.paidAt);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreOrderPaymentInfo message, length delimited. Does not implicitly {@link theplant.ec.service.store_orders.StoreOrderPaymentInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrderPaymentInfo} message StoreOrderPaymentInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOrderPaymentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreOrderPaymentInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.store_orders.StoreOrderPaymentInfo} StoreOrderPaymentInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOrderPaymentInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.paymentType = reader.int32();
                                break;
                            case 2:
                                message.externalPaymentId = reader.string();
                                break;
                            case 3:
                                message.paidAt = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreOrderPaymentInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.store_orders.StoreOrderPaymentInfo} StoreOrderPaymentInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOrderPaymentInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreOrderPaymentInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreOrderPaymentInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            switch (message.paymentType) {
                            default:
                                return "paymentType: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            if (!$util.isString(message.externalPaymentId))
                                return "externalPaymentId: string expected";
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            if (!$util.isString(message.paidAt))
                                return "paidAt: string expected";
                        return null;
                    };

                    /**
                     * Creates a StoreOrderPaymentInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.store_orders.StoreOrderPaymentInfo} StoreOrderPaymentInfo
                     */
                    StoreOrderPaymentInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo)
                            return object;
                        var message = new $root.theplant.ec.service.store_orders.StoreOrderPaymentInfo();
                        switch (object.paymentType) {
                        case "NO_STORE_ORDER_PAYMENT_TYPE":
                        case 0:
                            message.paymentType = 0;
                            break;
                        case "PAID_IN_STORE":
                        case 1:
                            message.paymentType = 1;
                            break;
                        case "WILL_PAY_IN_STORE":
                        case 2:
                            message.paymentType = 2;
                            break;
                        }
                        if (object.externalPaymentId != null)
                            message.externalPaymentId = String(object.externalPaymentId);
                        if (object.paidAt != null)
                            message.paidAt = String(object.paidAt);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreOrderPaymentInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.StoreOrderPaymentInfo} message StoreOrderPaymentInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreOrderPaymentInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.paymentType = options.enums === String ? "NO_STORE_ORDER_PAYMENT_TYPE" : 0;
                            object.externalPaymentId = "";
                            object.paidAt = "";
                        }
                        if (message.paymentType != null && message.hasOwnProperty("paymentType"))
                            object.paymentType = options.enums === String ? $root.theplant.ec.service.store_orders.StoreOrderPaymentType[message.paymentType] : message.paymentType;
                        if (message.externalPaymentId != null && message.hasOwnProperty("externalPaymentId"))
                            object.externalPaymentId = message.externalPaymentId;
                        if (message.paidAt != null && message.hasOwnProperty("paidAt"))
                            object.paidAt = message.paidAt;
                        return object;
                    };

                    /**
                     * Converts this StoreOrderPaymentInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.store_orders.StoreOrderPaymentInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreOrderPaymentInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreOrderPaymentInfo;
                })();

                store_orders.StoreOrderClosingInfo = (function() {

                    /**
                     * Properties of a StoreOrderClosingInfo.
                     * @memberof theplant.ec.service.store_orders
                     * @interface IStoreOrderClosingInfo
                     * @property {string|null} [reason] StoreOrderClosingInfo reason
                     * @property {theplant.ec.service.store_orders.StoreOrderMoveStockToWhenClosingType|null} [moveStockTo] StoreOrderClosingInfo moveStockTo
                     */

                    /**
                     * Constructs a new StoreOrderClosingInfo.
                     * @memberof theplant.ec.service.store_orders
                     * @classdesc Represents a StoreOrderClosingInfo.
                     * @implements IStoreOrderClosingInfo
                     * @constructor
                     * @param {theplant.ec.service.store_orders.IStoreOrderClosingInfo=} [properties] Properties to set
                     */
                    function StoreOrderClosingInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreOrderClosingInfo reason.
                     * @member {string} reason
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @instance
                     */
                    StoreOrderClosingInfo.prototype.reason = "";

                    /**
                     * StoreOrderClosingInfo moveStockTo.
                     * @member {theplant.ec.service.store_orders.StoreOrderMoveStockToWhenClosingType} moveStockTo
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @instance
                     */
                    StoreOrderClosingInfo.prototype.moveStockTo = 0;

                    /**
                     * Creates a new StoreOrderClosingInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrderClosingInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.store_orders.StoreOrderClosingInfo} StoreOrderClosingInfo instance
                     */
                    StoreOrderClosingInfo.create = function create(properties) {
                        return new StoreOrderClosingInfo(properties);
                    };

                    /**
                     * Encodes the specified StoreOrderClosingInfo message. Does not implicitly {@link theplant.ec.service.store_orders.StoreOrderClosingInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrderClosingInfo} message StoreOrderClosingInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOrderClosingInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.reason);
                        if (message.moveStockTo != null && message.hasOwnProperty("moveStockTo"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.moveStockTo);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreOrderClosingInfo message, length delimited. Does not implicitly {@link theplant.ec.service.store_orders.StoreOrderClosingInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.IStoreOrderClosingInfo} message StoreOrderClosingInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreOrderClosingInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreOrderClosingInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.store_orders.StoreOrderClosingInfo} StoreOrderClosingInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOrderClosingInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.store_orders.StoreOrderClosingInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.reason = reader.string();
                                break;
                            case 2:
                                message.moveStockTo = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreOrderClosingInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.store_orders.StoreOrderClosingInfo} StoreOrderClosingInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreOrderClosingInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreOrderClosingInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreOrderClosingInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            if (!$util.isString(message.reason))
                                return "reason: string expected";
                        if (message.moveStockTo != null && message.hasOwnProperty("moveStockTo"))
                            switch (message.moveStockTo) {
                            default:
                                return "moveStockTo: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a StoreOrderClosingInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.store_orders.StoreOrderClosingInfo} StoreOrderClosingInfo
                     */
                    StoreOrderClosingInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.store_orders.StoreOrderClosingInfo)
                            return object;
                        var message = new $root.theplant.ec.service.store_orders.StoreOrderClosingInfo();
                        if (object.reason != null)
                            message.reason = String(object.reason);
                        switch (object.moveStockTo) {
                        case "NO_MOVE_STOCK_TO_WHEN_CLOSING":
                        case 0:
                            message.moveStockTo = 0;
                            break;
                        case "EC_WAREHOUSE":
                        case 1:
                            message.moveStockTo = 1;
                            break;
                        case "STORE_FOR_RESALE":
                        case 2:
                            message.moveStockTo = 2;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreOrderClosingInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @static
                     * @param {theplant.ec.service.store_orders.StoreOrderClosingInfo} message StoreOrderClosingInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreOrderClosingInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.reason = "";
                            object.moveStockTo = options.enums === String ? "NO_MOVE_STOCK_TO_WHEN_CLOSING" : 0;
                        }
                        if (message.reason != null && message.hasOwnProperty("reason"))
                            object.reason = message.reason;
                        if (message.moveStockTo != null && message.hasOwnProperty("moveStockTo"))
                            object.moveStockTo = options.enums === String ? $root.theplant.ec.service.store_orders.StoreOrderMoveStockToWhenClosingType[message.moveStockTo] : message.moveStockTo;
                        return object;
                    };

                    /**
                     * Converts this StoreOrderClosingInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.store_orders.StoreOrderClosingInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreOrderClosingInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreOrderClosingInfo;
                })();

                store_orders.AppliedStoreOrderItem = (function() {

                    /**
                     * Properties of an AppliedStoreOrderItem.
                     * @memberof theplant.ec.service.store_orders
                     * @interface IAppliedStoreOrderItem
                     * @property {string|null} [articleCode] AppliedStoreOrderItem articleCode
                     * @property {number|Long|null} [quantity] AppliedStoreOrderItem quantity
                     */

                    /**
                     * Constructs a new AppliedStoreOrderItem.
                     * @memberof theplant.ec.service.store_orders
                     * @classdesc Represents an AppliedStoreOrderItem.
                     * @implements IAppliedStoreOrderItem
                     * @constructor
                     * @param {theplant.ec.service.store_orders.IAppliedStoreOrderItem=} [properties] Properties to set
                     */
                    function AppliedStoreOrderItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AppliedStoreOrderItem articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @instance
                     */
                    AppliedStoreOrderItem.prototype.articleCode = "";

                    /**
                     * AppliedStoreOrderItem quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @instance
                     */
                    AppliedStoreOrderItem.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new AppliedStoreOrderItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {theplant.ec.service.store_orders.IAppliedStoreOrderItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.store_orders.AppliedStoreOrderItem} AppliedStoreOrderItem instance
                     */
                    AppliedStoreOrderItem.create = function create(properties) {
                        return new AppliedStoreOrderItem(properties);
                    };

                    /**
                     * Encodes the specified AppliedStoreOrderItem message. Does not implicitly {@link theplant.ec.service.store_orders.AppliedStoreOrderItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {theplant.ec.service.store_orders.IAppliedStoreOrderItem} message AppliedStoreOrderItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AppliedStoreOrderItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.quantity);
                        return writer;
                    };

                    /**
                     * Encodes the specified AppliedStoreOrderItem message, length delimited. Does not implicitly {@link theplant.ec.service.store_orders.AppliedStoreOrderItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {theplant.ec.service.store_orders.IAppliedStoreOrderItem} message AppliedStoreOrderItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AppliedStoreOrderItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AppliedStoreOrderItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.store_orders.AppliedStoreOrderItem} AppliedStoreOrderItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AppliedStoreOrderItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.store_orders.AppliedStoreOrderItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.quantity = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AppliedStoreOrderItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.store_orders.AppliedStoreOrderItem} AppliedStoreOrderItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AppliedStoreOrderItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AppliedStoreOrderItem message.
                     * @function verify
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AppliedStoreOrderItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an AppliedStoreOrderItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.store_orders.AppliedStoreOrderItem} AppliedStoreOrderItem
                     */
                    AppliedStoreOrderItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.store_orders.AppliedStoreOrderItem)
                            return object;
                        var message = new $root.theplant.ec.service.store_orders.AppliedStoreOrderItem();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = true;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AppliedStoreOrderItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @static
                     * @param {theplant.ec.service.store_orders.AppliedStoreOrderItem} message AppliedStoreOrderItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AppliedStoreOrderItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber(true) : message.quantity;
                        return object;
                    };

                    /**
                     * Converts this AppliedStoreOrderItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.store_orders.AppliedStoreOrderItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AppliedStoreOrderItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AppliedStoreOrderItem;
                })();

                store_orders.ExclusionReason = (function() {

                    /**
                     * Properties of an ExclusionReason.
                     * @memberof theplant.ec.service.store_orders
                     * @interface IExclusionReason
                     * @property {string|null} [noticeInfo] ExclusionReason noticeInfo
                     */

                    /**
                     * Constructs a new ExclusionReason.
                     * @memberof theplant.ec.service.store_orders
                     * @classdesc Represents an ExclusionReason.
                     * @implements IExclusionReason
                     * @constructor
                     * @param {theplant.ec.service.store_orders.IExclusionReason=} [properties] Properties to set
                     */
                    function ExclusionReason(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ExclusionReason noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @instance
                     */
                    ExclusionReason.prototype.noticeInfo = "";

                    /**
                     * Creates a new ExclusionReason instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.store_orders.IExclusionReason=} [properties] Properties to set
                     * @returns {theplant.ec.service.store_orders.ExclusionReason} ExclusionReason instance
                     */
                    ExclusionReason.create = function create(properties) {
                        return new ExclusionReason(properties);
                    };

                    /**
                     * Encodes the specified ExclusionReason message. Does not implicitly {@link theplant.ec.service.store_orders.ExclusionReason.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.store_orders.IExclusionReason} message ExclusionReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExclusionReason.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.noticeInfo);
                        return writer;
                    };

                    /**
                     * Encodes the specified ExclusionReason message, length delimited. Does not implicitly {@link theplant.ec.service.store_orders.ExclusionReason.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.store_orders.IExclusionReason} message ExclusionReason message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExclusionReason.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ExclusionReason message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.store_orders.ExclusionReason} ExclusionReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExclusionReason.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.store_orders.ExclusionReason();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.noticeInfo = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ExclusionReason message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.store_orders.ExclusionReason} ExclusionReason
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExclusionReason.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ExclusionReason message.
                     * @function verify
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExclusionReason.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        return null;
                    };

                    /**
                     * Creates an ExclusionReason message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.store_orders.ExclusionReason} ExclusionReason
                     */
                    ExclusionReason.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.store_orders.ExclusionReason)
                            return object;
                        var message = new $root.theplant.ec.service.store_orders.ExclusionReason();
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ExclusionReason message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @static
                     * @param {theplant.ec.service.store_orders.ExclusionReason} message ExclusionReason
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExclusionReason.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.noticeInfo = "";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        return object;
                    };

                    /**
                     * Converts this ExclusionReason to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.store_orders.ExclusionReason
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExclusionReason.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ExclusionReason;
                })();

                return store_orders;
            })();

            service.users = (function() {

                /**
                 * Namespace users.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var users = {};

                users.User = (function() {

                    /**
                     * Properties of a User.
                     * @memberof theplant.ec.service.users
                     * @interface IUser
                     * @property {string|null} [id] User id
                     * @property {Array.<theplant.ec.service.users.ILogin>|null} [logins] User logins
                     * @property {boolean|null} [isConfirmed] User isConfirmed
                     * @property {boolean|null} [isNoPassword] User isNoPassword
                     * @property {string|null} [registeredDate] User registeredDate
                     * @property {boolean|null} [isBlocked] User isBlocked
                     * @property {theplant.ec.service.users.IProfile|null} [profile] User profile
                     * @property {theplant.ec.service.users.ICustomer|null} [customer] User customer
                     * @property {string|null} [userType] User userType
                     */

                    /**
                     * Constructs a new User.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a User.
                     * @implements IUser
                     * @constructor
                     * @param {theplant.ec.service.users.IUser=} [properties] Properties to set
                     */
                    function User(properties) {
                        this.logins = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * User id.
                     * @member {string} id
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.id = "";

                    /**
                     * User logins.
                     * @member {Array.<theplant.ec.service.users.ILogin>} logins
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.logins = $util.emptyArray;

                    /**
                     * User isConfirmed.
                     * @member {boolean} isConfirmed
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.isConfirmed = false;

                    /**
                     * User isNoPassword.
                     * @member {boolean} isNoPassword
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.isNoPassword = false;

                    /**
                     * User registeredDate.
                     * @member {string} registeredDate
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.registeredDate = "";

                    /**
                     * User isBlocked.
                     * @member {boolean} isBlocked
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.isBlocked = false;

                    /**
                     * User profile.
                     * @member {theplant.ec.service.users.IProfile|null|undefined} profile
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.profile = null;

                    /**
                     * User customer.
                     * @member {theplant.ec.service.users.ICustomer|null|undefined} customer
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.customer = null;

                    /**
                     * User userType.
                     * @member {string} userType
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     */
                    User.prototype.userType = "";

                    /**
                     * Creates a new User instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {theplant.ec.service.users.IUser=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.User} User instance
                     */
                    User.create = function create(properties) {
                        return new User(properties);
                    };

                    /**
                     * Encodes the specified User message. Does not implicitly {@link theplant.ec.service.users.User.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {theplant.ec.service.users.IUser} message User message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    User.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.logins != null && message.logins.length)
                            for (var i = 0; i < message.logins.length; ++i)
                                $root.theplant.ec.service.users.Login.encode(message.logins[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.isConfirmed != null && message.hasOwnProperty("isConfirmed"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isConfirmed);
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            $root.theplant.ec.service.users.Profile.encode(message.profile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.customer != null && message.hasOwnProperty("customer"))
                            $root.theplant.ec.service.users.Customer.encode(message.customer, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.registeredDate != null && message.hasOwnProperty("registeredDate"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.registeredDate);
                        if (message.isNoPassword != null && message.hasOwnProperty("isNoPassword"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isNoPassword);
                        if (message.isBlocked != null && message.hasOwnProperty("isBlocked"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isBlocked);
                        if (message.userType != null && message.hasOwnProperty("userType"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.userType);
                        return writer;
                    };

                    /**
                     * Encodes the specified User message, length delimited. Does not implicitly {@link theplant.ec.service.users.User.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {theplant.ec.service.users.IUser} message User message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    User.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a User message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.User} User
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    User.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.User();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                if (!(message.logins && message.logins.length))
                                    message.logins = [];
                                message.logins.push($root.theplant.ec.service.users.Login.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.isConfirmed = reader.bool();
                                break;
                            case 7:
                                message.isNoPassword = reader.bool();
                                break;
                            case 6:
                                message.registeredDate = reader.string();
                                break;
                            case 8:
                                message.isBlocked = reader.bool();
                                break;
                            case 4:
                                message.profile = $root.theplant.ec.service.users.Profile.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.customer = $root.theplant.ec.service.users.Customer.decode(reader, reader.uint32());
                                break;
                            case 9:
                                message.userType = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a User message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.User} User
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    User.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a User message.
                     * @function verify
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    User.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.logins != null && message.hasOwnProperty("logins")) {
                            if (!Array.isArray(message.logins))
                                return "logins: array expected";
                            for (var i = 0; i < message.logins.length; ++i) {
                                var error = $root.theplant.ec.service.users.Login.verify(message.logins[i]);
                                if (error)
                                    return "logins." + error;
                            }
                        }
                        if (message.isConfirmed != null && message.hasOwnProperty("isConfirmed"))
                            if (typeof message.isConfirmed !== "boolean")
                                return "isConfirmed: boolean expected";
                        if (message.isNoPassword != null && message.hasOwnProperty("isNoPassword"))
                            if (typeof message.isNoPassword !== "boolean")
                                return "isNoPassword: boolean expected";
                        if (message.registeredDate != null && message.hasOwnProperty("registeredDate"))
                            if (!$util.isString(message.registeredDate))
                                return "registeredDate: string expected";
                        if (message.isBlocked != null && message.hasOwnProperty("isBlocked"))
                            if (typeof message.isBlocked !== "boolean")
                                return "isBlocked: boolean expected";
                        if (message.profile != null && message.hasOwnProperty("profile")) {
                            var error = $root.theplant.ec.service.users.Profile.verify(message.profile);
                            if (error)
                                return "profile." + error;
                        }
                        if (message.customer != null && message.hasOwnProperty("customer")) {
                            var error = $root.theplant.ec.service.users.Customer.verify(message.customer);
                            if (error)
                                return "customer." + error;
                        }
                        if (message.userType != null && message.hasOwnProperty("userType"))
                            if (!$util.isString(message.userType))
                                return "userType: string expected";
                        return null;
                    };

                    /**
                     * Creates a User message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.User} User
                     */
                    User.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.User)
                            return object;
                        var message = new $root.theplant.ec.service.users.User();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.logins) {
                            if (!Array.isArray(object.logins))
                                throw TypeError(".theplant.ec.service.users.User.logins: array expected");
                            message.logins = [];
                            for (var i = 0; i < object.logins.length; ++i) {
                                if (typeof object.logins[i] !== "object")
                                    throw TypeError(".theplant.ec.service.users.User.logins: object expected");
                                message.logins[i] = $root.theplant.ec.service.users.Login.fromObject(object.logins[i]);
                            }
                        }
                        if (object.isConfirmed != null)
                            message.isConfirmed = Boolean(object.isConfirmed);
                        if (object.isNoPassword != null)
                            message.isNoPassword = Boolean(object.isNoPassword);
                        if (object.registeredDate != null)
                            message.registeredDate = String(object.registeredDate);
                        if (object.isBlocked != null)
                            message.isBlocked = Boolean(object.isBlocked);
                        if (object.profile != null) {
                            if (typeof object.profile !== "object")
                                throw TypeError(".theplant.ec.service.users.User.profile: object expected");
                            message.profile = $root.theplant.ec.service.users.Profile.fromObject(object.profile);
                        }
                        if (object.customer != null) {
                            if (typeof object.customer !== "object")
                                throw TypeError(".theplant.ec.service.users.User.customer: object expected");
                            message.customer = $root.theplant.ec.service.users.Customer.fromObject(object.customer);
                        }
                        if (object.userType != null)
                            message.userType = String(object.userType);
                        return message;
                    };

                    /**
                     * Creates a plain object from a User message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.User
                     * @static
                     * @param {theplant.ec.service.users.User} message User
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    User.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.logins = [];
                        if (options.defaults) {
                            object.id = "";
                            object.isConfirmed = false;
                            object.profile = null;
                            object.customer = null;
                            object.registeredDate = "";
                            object.isNoPassword = false;
                            object.isBlocked = false;
                            object.userType = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.logins && message.logins.length) {
                            object.logins = [];
                            for (var j = 0; j < message.logins.length; ++j)
                                object.logins[j] = $root.theplant.ec.service.users.Login.toObject(message.logins[j], options);
                        }
                        if (message.isConfirmed != null && message.hasOwnProperty("isConfirmed"))
                            object.isConfirmed = message.isConfirmed;
                        if (message.profile != null && message.hasOwnProperty("profile"))
                            object.profile = $root.theplant.ec.service.users.Profile.toObject(message.profile, options);
                        if (message.customer != null && message.hasOwnProperty("customer"))
                            object.customer = $root.theplant.ec.service.users.Customer.toObject(message.customer, options);
                        if (message.registeredDate != null && message.hasOwnProperty("registeredDate"))
                            object.registeredDate = message.registeredDate;
                        if (message.isNoPassword != null && message.hasOwnProperty("isNoPassword"))
                            object.isNoPassword = message.isNoPassword;
                        if (message.isBlocked != null && message.hasOwnProperty("isBlocked"))
                            object.isBlocked = message.isBlocked;
                        if (message.userType != null && message.hasOwnProperty("userType"))
                            object.userType = message.userType;
                        return object;
                    };

                    /**
                     * Converts this User to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.User
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    User.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return User;
                })();

                users.Login = (function() {

                    /**
                     * Properties of a Login.
                     * @memberof theplant.ec.service.users
                     * @interface ILogin
                     * @property {theplant.ec.service.users.LoginType|null} [type] Login type
                     * @property {string|null} [key] Login key
                     */

                    /**
                     * Constructs a new Login.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a Login.
                     * @implements ILogin
                     * @constructor
                     * @param {theplant.ec.service.users.ILogin=} [properties] Properties to set
                     */
                    function Login(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Login type.
                     * @member {theplant.ec.service.users.LoginType} type
                     * @memberof theplant.ec.service.users.Login
                     * @instance
                     */
                    Login.prototype.type = 0;

                    /**
                     * Login key.
                     * @member {string} key
                     * @memberof theplant.ec.service.users.Login
                     * @instance
                     */
                    Login.prototype.key = "";

                    /**
                     * Creates a new Login instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {theplant.ec.service.users.ILogin=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.Login} Login instance
                     */
                    Login.create = function create(properties) {
                        return new Login(properties);
                    };

                    /**
                     * Encodes the specified Login message. Does not implicitly {@link theplant.ec.service.users.Login.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {theplant.ec.service.users.ILogin} message Login message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Login.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.type != null && message.hasOwnProperty("type"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.type);
                        if (message.key != null && message.hasOwnProperty("key"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
                        return writer;
                    };

                    /**
                     * Encodes the specified Login message, length delimited. Does not implicitly {@link theplant.ec.service.users.Login.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {theplant.ec.service.users.ILogin} message Login message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Login.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Login message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.Login} Login
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Login.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.Login();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.type = reader.int32();
                                break;
                            case 2:
                                message.key = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Login message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.Login} Login
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Login.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Login message.
                     * @function verify
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Login.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.type != null && message.hasOwnProperty("type"))
                            switch (message.type) {
                            default:
                                return "type: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.key != null && message.hasOwnProperty("key"))
                            if (!$util.isString(message.key))
                                return "key: string expected";
                        return null;
                    };

                    /**
                     * Creates a Login message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.Login} Login
                     */
                    Login.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.Login)
                            return object;
                        var message = new $root.theplant.ec.service.users.Login();
                        switch (object.type) {
                        case "EMAIL":
                        case 0:
                            message.type = 0;
                            break;
                        case "PHONE":
                        case 1:
                            message.type = 1;
                            break;
                        case "NAME":
                        case 2:
                            message.type = 2;
                            break;
                        }
                        if (object.key != null)
                            message.key = String(object.key);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Login message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.Login
                     * @static
                     * @param {theplant.ec.service.users.Login} message Login
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Login.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.type = options.enums === String ? "EMAIL" : 0;
                            object.key = "";
                        }
                        if (message.type != null && message.hasOwnProperty("type"))
                            object.type = options.enums === String ? $root.theplant.ec.service.users.LoginType[message.type] : message.type;
                        if (message.key != null && message.hasOwnProperty("key"))
                            object.key = message.key;
                        return object;
                    };

                    /**
                     * Converts this Login to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.Login
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Login.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Login;
                })();

                /**
                 * LoginType enum.
                 * @name theplant.ec.service.users.LoginType
                 * @enum {string}
                 * @property {number} EMAIL=0 EMAIL value
                 * @property {number} PHONE=1 PHONE value
                 * @property {number} NAME=2 NAME value
                 */
                users.LoginType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "EMAIL"] = 0;
                    values[valuesById[1] = "PHONE"] = 1;
                    values[valuesById[2] = "NAME"] = 2;
                    return values;
                })();

                /**
                 * UserPrimeAttribute enum.
                 * @name theplant.ec.service.users.UserPrimeAttribute
                 * @enum {string}
                 * @property {number} ID=0 ID value
                 * @property {number} LOGIN_KEY=1 LOGIN_KEY value
                 * @property {number} MEMBER_NUMBER=2 MEMBER_NUMBER value
                 * @property {number} EXTERNAL_CUSTOMER_ID=3 EXTERNAL_CUSTOMER_ID value
                 */
                users.UserPrimeAttribute = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ID"] = 0;
                    values[valuesById[1] = "LOGIN_KEY"] = 1;
                    values[valuesById[2] = "MEMBER_NUMBER"] = 2;
                    values[valuesById[3] = "EXTERNAL_CUSTOMER_ID"] = 3;
                    return values;
                })();

                /**
                 * NewsletterType enum.
                 * @name theplant.ec.service.users.NewsletterType
                 * @enum {string}
                 * @property {number} HTML=0 HTML value
                 * @property {number} TEXT=1 TEXT value
                 */
                users.NewsletterType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "HTML"] = 0;
                    values[valuesById[1] = "TEXT"] = 1;
                    return values;
                })();

                users.Profile = (function() {

                    /**
                     * Properties of a Profile.
                     * @memberof theplant.ec.service.users
                     * @interface IProfile
                     * @property {boolean|null} [isSubscribed] Profile isSubscribed
                     * @property {theplant.ec.service.users.NewsletterType|null} [newsletterType] Profile newsletterType
                     * @property {string|null} [dateOfBirth] Profile dateOfBirth
                     * @property {theplant.ec.service.users.UserGender|null} [gender] Profile gender
                     * @property {theplant.ec.service.users.IAddress|null} [billingAddress] Profile billingAddress
                     * @property {boolean|null} [dmBySms] Profile dmBySms
                     * @property {boolean|null} [dmByMail] Profile dmByMail
                     * @property {boolean|null} [dmByPhone] Profile dmByPhone
                     * @property {string|null} [registerStore] Profile registerStore
                     * @property {boolean|null} [storeStaff] Profile storeStaff
                     */

                    /**
                     * Constructs a new Profile.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a Profile.
                     * @implements IProfile
                     * @constructor
                     * @param {theplant.ec.service.users.IProfile=} [properties] Properties to set
                     */
                    function Profile(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Profile isSubscribed.
                     * @member {boolean} isSubscribed
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.isSubscribed = false;

                    /**
                     * Profile newsletterType.
                     * @member {theplant.ec.service.users.NewsletterType} newsletterType
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.newsletterType = 0;

                    /**
                     * Profile dateOfBirth.
                     * @member {string} dateOfBirth
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.dateOfBirth = "";

                    /**
                     * Profile gender.
                     * @member {theplant.ec.service.users.UserGender} gender
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.gender = 0;

                    /**
                     * Profile billingAddress.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} billingAddress
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.billingAddress = null;

                    /**
                     * Profile dmBySms.
                     * @member {boolean} dmBySms
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.dmBySms = false;

                    /**
                     * Profile dmByMail.
                     * @member {boolean} dmByMail
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.dmByMail = false;

                    /**
                     * Profile dmByPhone.
                     * @member {boolean} dmByPhone
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.dmByPhone = false;

                    /**
                     * Profile registerStore.
                     * @member {string} registerStore
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.registerStore = "";

                    /**
                     * Profile storeStaff.
                     * @member {boolean} storeStaff
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     */
                    Profile.prototype.storeStaff = false;

                    /**
                     * Creates a new Profile instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {theplant.ec.service.users.IProfile=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.Profile} Profile instance
                     */
                    Profile.create = function create(properties) {
                        return new Profile(properties);
                    };

                    /**
                     * Encodes the specified Profile message. Does not implicitly {@link theplant.ec.service.users.Profile.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {theplant.ec.service.users.IProfile} message Profile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Profile.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isSubscribed != null && message.hasOwnProperty("isSubscribed"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSubscribed);
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.dateOfBirth);
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.gender);
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            $root.theplant.ec.service.users.Address.encode(message.billingAddress, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.dmBySms != null && message.hasOwnProperty("dmBySms"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.dmBySms);
                        if (message.newsletterType != null && message.hasOwnProperty("newsletterType"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.newsletterType);
                        if (message.dmByMail != null && message.hasOwnProperty("dmByMail"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.dmByMail);
                        if (message.dmByPhone != null && message.hasOwnProperty("dmByPhone"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.dmByPhone);
                        if (message.registerStore != null && message.hasOwnProperty("registerStore"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.registerStore);
                        if (message.storeStaff != null && message.hasOwnProperty("storeStaff"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.storeStaff);
                        return writer;
                    };

                    /**
                     * Encodes the specified Profile message, length delimited. Does not implicitly {@link theplant.ec.service.users.Profile.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {theplant.ec.service.users.IProfile} message Profile message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Profile.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Profile message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.Profile} Profile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Profile.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.Profile();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.isSubscribed = reader.bool();
                                break;
                            case 6:
                                message.newsletterType = reader.int32();
                                break;
                            case 2:
                                message.dateOfBirth = reader.string();
                                break;
                            case 3:
                                message.gender = reader.int32();
                                break;
                            case 4:
                                message.billingAddress = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.dmBySms = reader.bool();
                                break;
                            case 7:
                                message.dmByMail = reader.bool();
                                break;
                            case 8:
                                message.dmByPhone = reader.bool();
                                break;
                            case 9:
                                message.registerStore = reader.string();
                                break;
                            case 10:
                                message.storeStaff = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Profile message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.Profile} Profile
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Profile.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Profile message.
                     * @function verify
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Profile.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isSubscribed != null && message.hasOwnProperty("isSubscribed"))
                            if (typeof message.isSubscribed !== "boolean")
                                return "isSubscribed: boolean expected";
                        if (message.newsletterType != null && message.hasOwnProperty("newsletterType"))
                            switch (message.newsletterType) {
                            default:
                                return "newsletterType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            if (!$util.isString(message.dateOfBirth))
                                return "dateOfBirth: string expected";
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            switch (message.gender) {
                            default:
                                return "gender: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.billingAddress);
                            if (error)
                                return "billingAddress." + error;
                        }
                        if (message.dmBySms != null && message.hasOwnProperty("dmBySms"))
                            if (typeof message.dmBySms !== "boolean")
                                return "dmBySms: boolean expected";
                        if (message.dmByMail != null && message.hasOwnProperty("dmByMail"))
                            if (typeof message.dmByMail !== "boolean")
                                return "dmByMail: boolean expected";
                        if (message.dmByPhone != null && message.hasOwnProperty("dmByPhone"))
                            if (typeof message.dmByPhone !== "boolean")
                                return "dmByPhone: boolean expected";
                        if (message.registerStore != null && message.hasOwnProperty("registerStore"))
                            if (!$util.isString(message.registerStore))
                                return "registerStore: string expected";
                        if (message.storeStaff != null && message.hasOwnProperty("storeStaff"))
                            if (typeof message.storeStaff !== "boolean")
                                return "storeStaff: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Profile message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.Profile} Profile
                     */
                    Profile.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.Profile)
                            return object;
                        var message = new $root.theplant.ec.service.users.Profile();
                        if (object.isSubscribed != null)
                            message.isSubscribed = Boolean(object.isSubscribed);
                        switch (object.newsletterType) {
                        case "HTML":
                        case 0:
                            message.newsletterType = 0;
                            break;
                        case "TEXT":
                        case 1:
                            message.newsletterType = 1;
                            break;
                        }
                        if (object.dateOfBirth != null)
                            message.dateOfBirth = String(object.dateOfBirth);
                        switch (object.gender) {
                        case "UNKNOWN":
                        case 0:
                            message.gender = 0;
                            break;
                        case "MALE":
                        case 1:
                            message.gender = 1;
                            break;
                        case "FEMALE":
                        case 2:
                            message.gender = 2;
                            break;
                        }
                        if (object.billingAddress != null) {
                            if (typeof object.billingAddress !== "object")
                                throw TypeError(".theplant.ec.service.users.Profile.billingAddress: object expected");
                            message.billingAddress = $root.theplant.ec.service.users.Address.fromObject(object.billingAddress);
                        }
                        if (object.dmBySms != null)
                            message.dmBySms = Boolean(object.dmBySms);
                        if (object.dmByMail != null)
                            message.dmByMail = Boolean(object.dmByMail);
                        if (object.dmByPhone != null)
                            message.dmByPhone = Boolean(object.dmByPhone);
                        if (object.registerStore != null)
                            message.registerStore = String(object.registerStore);
                        if (object.storeStaff != null)
                            message.storeStaff = Boolean(object.storeStaff);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Profile message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.Profile
                     * @static
                     * @param {theplant.ec.service.users.Profile} message Profile
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Profile.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isSubscribed = false;
                            object.dateOfBirth = "";
                            object.gender = options.enums === String ? "UNKNOWN" : 0;
                            object.billingAddress = null;
                            object.dmBySms = false;
                            object.newsletterType = options.enums === String ? "HTML" : 0;
                            object.dmByMail = false;
                            object.dmByPhone = false;
                            object.registerStore = "";
                            object.storeStaff = false;
                        }
                        if (message.isSubscribed != null && message.hasOwnProperty("isSubscribed"))
                            object.isSubscribed = message.isSubscribed;
                        if (message.dateOfBirth != null && message.hasOwnProperty("dateOfBirth"))
                            object.dateOfBirth = message.dateOfBirth;
                        if (message.gender != null && message.hasOwnProperty("gender"))
                            object.gender = options.enums === String ? $root.theplant.ec.service.users.UserGender[message.gender] : message.gender;
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            object.billingAddress = $root.theplant.ec.service.users.Address.toObject(message.billingAddress, options);
                        if (message.dmBySms != null && message.hasOwnProperty("dmBySms"))
                            object.dmBySms = message.dmBySms;
                        if (message.newsletterType != null && message.hasOwnProperty("newsletterType"))
                            object.newsletterType = options.enums === String ? $root.theplant.ec.service.users.NewsletterType[message.newsletterType] : message.newsletterType;
                        if (message.dmByMail != null && message.hasOwnProperty("dmByMail"))
                            object.dmByMail = message.dmByMail;
                        if (message.dmByPhone != null && message.hasOwnProperty("dmByPhone"))
                            object.dmByPhone = message.dmByPhone;
                        if (message.registerStore != null && message.hasOwnProperty("registerStore"))
                            object.registerStore = message.registerStore;
                        if (message.storeStaff != null && message.hasOwnProperty("storeStaff"))
                            object.storeStaff = message.storeStaff;
                        return object;
                    };

                    /**
                     * Converts this Profile to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.Profile
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Profile.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Profile;
                })();

                users.Customer = (function() {

                    /**
                     * Properties of a Customer.
                     * @memberof theplant.ec.service.users
                     * @interface ICustomer
                     * @property {string|null} [memberNumber] Customer memberNumber
                     * @property {string|null} [memberType] Customer memberType
                     * @property {string|null} [externalCustomerId] Customer externalCustomerId
                     * @property {string|null} [registeredStoreId] Customer registeredStoreId
                     * @property {string|null} [employeeId] Customer employeeId
                     * @property {string|null} [registeredTrack] Customer registeredTrack
                     * @property {google.protobuf.ITimestamp|null} [termsAndPrivacyAgreementAt] Customer termsAndPrivacyAgreementAt
                     * @property {boolean|null} [returningCustomer] Customer returningCustomer
                     */

                    /**
                     * Constructs a new Customer.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a Customer.
                     * @implements ICustomer
                     * @constructor
                     * @param {theplant.ec.service.users.ICustomer=} [properties] Properties to set
                     */
                    function Customer(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Customer memberNumber.
                     * @member {string} memberNumber
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.memberNumber = "";

                    /**
                     * Customer memberType.
                     * @member {string} memberType
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.memberType = "";

                    /**
                     * Customer externalCustomerId.
                     * @member {string} externalCustomerId
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.externalCustomerId = "";

                    /**
                     * Customer registeredStoreId.
                     * @member {string} registeredStoreId
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.registeredStoreId = "";

                    /**
                     * Customer employeeId.
                     * @member {string} employeeId
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.employeeId = "";

                    /**
                     * Customer registeredTrack.
                     * @member {string} registeredTrack
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.registeredTrack = "";

                    /**
                     * Customer termsAndPrivacyAgreementAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} termsAndPrivacyAgreementAt
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.termsAndPrivacyAgreementAt = null;

                    /**
                     * Customer returningCustomer.
                     * @member {boolean} returningCustomer
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     */
                    Customer.prototype.returningCustomer = false;

                    /**
                     * Creates a new Customer instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {theplant.ec.service.users.ICustomer=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.Customer} Customer instance
                     */
                    Customer.create = function create(properties) {
                        return new Customer(properties);
                    };

                    /**
                     * Encodes the specified Customer message. Does not implicitly {@link theplant.ec.service.users.Customer.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {theplant.ec.service.users.ICustomer} message Customer message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Customer.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.memberNumber != null && message.hasOwnProperty("memberNumber"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.memberNumber);
                        if (message.memberType != null && message.hasOwnProperty("memberType"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.memberType);
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.externalCustomerId);
                        if (message.registeredStoreId != null && message.hasOwnProperty("registeredStoreId"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.registeredStoreId);
                        if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.employeeId);
                        if (message.registeredTrack != null && message.hasOwnProperty("registeredTrack"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.registeredTrack);
                        if (message.termsAndPrivacyAgreementAt != null && message.hasOwnProperty("termsAndPrivacyAgreementAt"))
                            $root.google.protobuf.Timestamp.encode(message.termsAndPrivacyAgreementAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.returningCustomer != null && message.hasOwnProperty("returningCustomer"))
                            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.returningCustomer);
                        return writer;
                    };

                    /**
                     * Encodes the specified Customer message, length delimited. Does not implicitly {@link theplant.ec.service.users.Customer.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {theplant.ec.service.users.ICustomer} message Customer message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Customer.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Customer message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.Customer} Customer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Customer.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.Customer();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.memberNumber = reader.string();
                                break;
                            case 2:
                                message.memberType = reader.string();
                                break;
                            case 3:
                                message.externalCustomerId = reader.string();
                                break;
                            case 4:
                                message.registeredStoreId = reader.string();
                                break;
                            case 5:
                                message.employeeId = reader.string();
                                break;
                            case 6:
                                message.registeredTrack = reader.string();
                                break;
                            case 7:
                                message.termsAndPrivacyAgreementAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.returningCustomer = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Customer message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.Customer} Customer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Customer.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Customer message.
                     * @function verify
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Customer.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.memberNumber != null && message.hasOwnProperty("memberNumber"))
                            if (!$util.isString(message.memberNumber))
                                return "memberNumber: string expected";
                        if (message.memberType != null && message.hasOwnProperty("memberType"))
                            if (!$util.isString(message.memberType))
                                return "memberType: string expected";
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            if (!$util.isString(message.externalCustomerId))
                                return "externalCustomerId: string expected";
                        if (message.registeredStoreId != null && message.hasOwnProperty("registeredStoreId"))
                            if (!$util.isString(message.registeredStoreId))
                                return "registeredStoreId: string expected";
                        if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                            if (!$util.isString(message.employeeId))
                                return "employeeId: string expected";
                        if (message.registeredTrack != null && message.hasOwnProperty("registeredTrack"))
                            if (!$util.isString(message.registeredTrack))
                                return "registeredTrack: string expected";
                        if (message.termsAndPrivacyAgreementAt != null && message.hasOwnProperty("termsAndPrivacyAgreementAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.termsAndPrivacyAgreementAt);
                            if (error)
                                return "termsAndPrivacyAgreementAt." + error;
                        }
                        if (message.returningCustomer != null && message.hasOwnProperty("returningCustomer"))
                            if (typeof message.returningCustomer !== "boolean")
                                return "returningCustomer: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Customer message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.Customer} Customer
                     */
                    Customer.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.Customer)
                            return object;
                        var message = new $root.theplant.ec.service.users.Customer();
                        if (object.memberNumber != null)
                            message.memberNumber = String(object.memberNumber);
                        if (object.memberType != null)
                            message.memberType = String(object.memberType);
                        if (object.externalCustomerId != null)
                            message.externalCustomerId = String(object.externalCustomerId);
                        if (object.registeredStoreId != null)
                            message.registeredStoreId = String(object.registeredStoreId);
                        if (object.employeeId != null)
                            message.employeeId = String(object.employeeId);
                        if (object.registeredTrack != null)
                            message.registeredTrack = String(object.registeredTrack);
                        if (object.termsAndPrivacyAgreementAt != null) {
                            if (typeof object.termsAndPrivacyAgreementAt !== "object")
                                throw TypeError(".theplant.ec.service.users.Customer.termsAndPrivacyAgreementAt: object expected");
                            message.termsAndPrivacyAgreementAt = $root.google.protobuf.Timestamp.fromObject(object.termsAndPrivacyAgreementAt);
                        }
                        if (object.returningCustomer != null)
                            message.returningCustomer = Boolean(object.returningCustomer);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Customer message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.Customer
                     * @static
                     * @param {theplant.ec.service.users.Customer} message Customer
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Customer.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.memberNumber = "";
                            object.memberType = "";
                            object.externalCustomerId = "";
                            object.registeredStoreId = "";
                            object.employeeId = "";
                            object.registeredTrack = "";
                            object.termsAndPrivacyAgreementAt = null;
                            object.returningCustomer = false;
                        }
                        if (message.memberNumber != null && message.hasOwnProperty("memberNumber"))
                            object.memberNumber = message.memberNumber;
                        if (message.memberType != null && message.hasOwnProperty("memberType"))
                            object.memberType = message.memberType;
                        if (message.externalCustomerId != null && message.hasOwnProperty("externalCustomerId"))
                            object.externalCustomerId = message.externalCustomerId;
                        if (message.registeredStoreId != null && message.hasOwnProperty("registeredStoreId"))
                            object.registeredStoreId = message.registeredStoreId;
                        if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                            object.employeeId = message.employeeId;
                        if (message.registeredTrack != null && message.hasOwnProperty("registeredTrack"))
                            object.registeredTrack = message.registeredTrack;
                        if (message.termsAndPrivacyAgreementAt != null && message.hasOwnProperty("termsAndPrivacyAgreementAt"))
                            object.termsAndPrivacyAgreementAt = $root.google.protobuf.Timestamp.toObject(message.termsAndPrivacyAgreementAt, options);
                        if (message.returningCustomer != null && message.hasOwnProperty("returningCustomer"))
                            object.returningCustomer = message.returningCustomer;
                        return object;
                    };

                    /**
                     * Converts this Customer to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.Customer
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Customer.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Customer;
                })();

                /**
                 * UserGender enum.
                 * @name theplant.ec.service.users.UserGender
                 * @enum {string}
                 * @property {number} UNKNOWN=0 UNKNOWN value
                 * @property {number} MALE=1 MALE value
                 * @property {number} FEMALE=2 FEMALE value
                 */
                users.UserGender = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN"] = 0;
                    values[valuesById[1] = "MALE"] = 1;
                    values[valuesById[2] = "FEMALE"] = 2;
                    return values;
                })();

                users.Address = (function() {

                    /**
                     * Properties of an Address.
                     * @memberof theplant.ec.service.users
                     * @interface IAddress
                     * @property {string|null} [name] Address name
                     * @property {string|null} [familyName] Address familyName
                     * @property {string|null} [givenName] Address givenName
                     * @property {string|null} [katakanaFamilyName] Address katakanaFamilyName
                     * @property {string|null} [katakanaGivenName] Address katakanaGivenName
                     * @property {string|null} [zipcode] Address zipcode
                     * @property {string|null} [address1] Address address1
                     * @property {string|null} [address2] Address address2
                     * @property {string|null} [city] Address city
                     * @property {string|null} [prefecture] Address prefecture
                     * @property {string|null} [phone] Address phone
                     * @property {string|null} [phone2] Address phone2
                     * @property {string|null} [country] Address country
                     */

                    /**
                     * Constructs a new Address.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents an Address.
                     * @implements IAddress
                     * @constructor
                     * @param {theplant.ec.service.users.IAddress=} [properties] Properties to set
                     */
                    function Address(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Address name.
                     * @member {string} name
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.name = "";

                    /**
                     * Address familyName.
                     * @member {string} familyName
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.familyName = "";

                    /**
                     * Address givenName.
                     * @member {string} givenName
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.givenName = "";

                    /**
                     * Address katakanaFamilyName.
                     * @member {string} katakanaFamilyName
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.katakanaFamilyName = "";

                    /**
                     * Address katakanaGivenName.
                     * @member {string} katakanaGivenName
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.katakanaGivenName = "";

                    /**
                     * Address zipcode.
                     * @member {string} zipcode
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.zipcode = "";

                    /**
                     * Address address1.
                     * @member {string} address1
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.address1 = "";

                    /**
                     * Address address2.
                     * @member {string} address2
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.address2 = "";

                    /**
                     * Address city.
                     * @member {string} city
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.city = "";

                    /**
                     * Address prefecture.
                     * @member {string} prefecture
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.prefecture = "";

                    /**
                     * Address phone.
                     * @member {string} phone
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.phone = "";

                    /**
                     * Address phone2.
                     * @member {string} phone2
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.phone2 = "";

                    /**
                     * Address country.
                     * @member {string} country
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     */
                    Address.prototype.country = "";

                    /**
                     * Creates a new Address instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {theplant.ec.service.users.IAddress=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.Address} Address instance
                     */
                    Address.create = function create(properties) {
                        return new Address(properties);
                    };

                    /**
                     * Encodes the specified Address message. Does not implicitly {@link theplant.ec.service.users.Address.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {theplant.ec.service.users.IAddress} message Address message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Address.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.familyName);
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.givenName);
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.katakanaFamilyName);
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.katakanaGivenName);
                        if (message.zipcode != null && message.hasOwnProperty("zipcode"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.zipcode);
                        if (message.address1 != null && message.hasOwnProperty("address1"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.address1);
                        if (message.address2 != null && message.hasOwnProperty("address2"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.address2);
                        if (message.city != null && message.hasOwnProperty("city"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.city);
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.prefecture);
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.phone);
                        if (message.phone2 != null && message.hasOwnProperty("phone2"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.phone2);
                        if (message.country != null && message.hasOwnProperty("country"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.country);
                        return writer;
                    };

                    /**
                     * Encodes the specified Address message, length delimited. Does not implicitly {@link theplant.ec.service.users.Address.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {theplant.ec.service.users.IAddress} message Address message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Address.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Address message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.Address} Address
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Address.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.Address();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.familyName = reader.string();
                                break;
                            case 3:
                                message.givenName = reader.string();
                                break;
                            case 4:
                                message.katakanaFamilyName = reader.string();
                                break;
                            case 5:
                                message.katakanaGivenName = reader.string();
                                break;
                            case 6:
                                message.zipcode = reader.string();
                                break;
                            case 7:
                                message.address1 = reader.string();
                                break;
                            case 8:
                                message.address2 = reader.string();
                                break;
                            case 9:
                                message.city = reader.string();
                                break;
                            case 10:
                                message.prefecture = reader.string();
                                break;
                            case 11:
                                message.phone = reader.string();
                                break;
                            case 12:
                                message.phone2 = reader.string();
                                break;
                            case 13:
                                message.country = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Address message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.Address} Address
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Address.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Address message.
                     * @function verify
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Address.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            if (!$util.isString(message.familyName))
                                return "familyName: string expected";
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            if (!$util.isString(message.givenName))
                                return "givenName: string expected";
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            if (!$util.isString(message.katakanaFamilyName))
                                return "katakanaFamilyName: string expected";
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            if (!$util.isString(message.katakanaGivenName))
                                return "katakanaGivenName: string expected";
                        if (message.zipcode != null && message.hasOwnProperty("zipcode"))
                            if (!$util.isString(message.zipcode))
                                return "zipcode: string expected";
                        if (message.address1 != null && message.hasOwnProperty("address1"))
                            if (!$util.isString(message.address1))
                                return "address1: string expected";
                        if (message.address2 != null && message.hasOwnProperty("address2"))
                            if (!$util.isString(message.address2))
                                return "address2: string expected";
                        if (message.city != null && message.hasOwnProperty("city"))
                            if (!$util.isString(message.city))
                                return "city: string expected";
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            if (!$util.isString(message.prefecture))
                                return "prefecture: string expected";
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            if (!$util.isString(message.phone))
                                return "phone: string expected";
                        if (message.phone2 != null && message.hasOwnProperty("phone2"))
                            if (!$util.isString(message.phone2))
                                return "phone2: string expected";
                        if (message.country != null && message.hasOwnProperty("country"))
                            if (!$util.isString(message.country))
                                return "country: string expected";
                        return null;
                    };

                    /**
                     * Creates an Address message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.Address} Address
                     */
                    Address.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.Address)
                            return object;
                        var message = new $root.theplant.ec.service.users.Address();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.familyName != null)
                            message.familyName = String(object.familyName);
                        if (object.givenName != null)
                            message.givenName = String(object.givenName);
                        if (object.katakanaFamilyName != null)
                            message.katakanaFamilyName = String(object.katakanaFamilyName);
                        if (object.katakanaGivenName != null)
                            message.katakanaGivenName = String(object.katakanaGivenName);
                        if (object.zipcode != null)
                            message.zipcode = String(object.zipcode);
                        if (object.address1 != null)
                            message.address1 = String(object.address1);
                        if (object.address2 != null)
                            message.address2 = String(object.address2);
                        if (object.city != null)
                            message.city = String(object.city);
                        if (object.prefecture != null)
                            message.prefecture = String(object.prefecture);
                        if (object.phone != null)
                            message.phone = String(object.phone);
                        if (object.phone2 != null)
                            message.phone2 = String(object.phone2);
                        if (object.country != null)
                            message.country = String(object.country);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Address message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.Address
                     * @static
                     * @param {theplant.ec.service.users.Address} message Address
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Address.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.familyName = "";
                            object.givenName = "";
                            object.katakanaFamilyName = "";
                            object.katakanaGivenName = "";
                            object.zipcode = "";
                            object.address1 = "";
                            object.address2 = "";
                            object.city = "";
                            object.prefecture = "";
                            object.phone = "";
                            object.phone2 = "";
                            object.country = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.familyName != null && message.hasOwnProperty("familyName"))
                            object.familyName = message.familyName;
                        if (message.givenName != null && message.hasOwnProperty("givenName"))
                            object.givenName = message.givenName;
                        if (message.katakanaFamilyName != null && message.hasOwnProperty("katakanaFamilyName"))
                            object.katakanaFamilyName = message.katakanaFamilyName;
                        if (message.katakanaGivenName != null && message.hasOwnProperty("katakanaGivenName"))
                            object.katakanaGivenName = message.katakanaGivenName;
                        if (message.zipcode != null && message.hasOwnProperty("zipcode"))
                            object.zipcode = message.zipcode;
                        if (message.address1 != null && message.hasOwnProperty("address1"))
                            object.address1 = message.address1;
                        if (message.address2 != null && message.hasOwnProperty("address2"))
                            object.address2 = message.address2;
                        if (message.city != null && message.hasOwnProperty("city"))
                            object.city = message.city;
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            object.prefecture = message.prefecture;
                        if (message.phone != null && message.hasOwnProperty("phone"))
                            object.phone = message.phone;
                        if (message.phone2 != null && message.hasOwnProperty("phone2"))
                            object.phone2 = message.phone2;
                        if (message.country != null && message.hasOwnProperty("country"))
                            object.country = message.country;
                        return object;
                    };

                    /**
                     * Converts this Address to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.Address
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Address.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Address;
                })();

                users.ShippingAddress = (function() {

                    /**
                     * Properties of a ShippingAddress.
                     * @memberof theplant.ec.service.users
                     * @interface IShippingAddress
                     * @property {string|null} [id] ShippingAddress id
                     * @property {boolean|null} [isDefault] ShippingAddress isDefault
                     * @property {theplant.ec.service.users.IAddress|null} [address] ShippingAddress address
                     */

                    /**
                     * Constructs a new ShippingAddress.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a ShippingAddress.
                     * @implements IShippingAddress
                     * @constructor
                     * @param {theplant.ec.service.users.IShippingAddress=} [properties] Properties to set
                     */
                    function ShippingAddress(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShippingAddress id.
                     * @member {string} id
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @instance
                     */
                    ShippingAddress.prototype.id = "";

                    /**
                     * ShippingAddress isDefault.
                     * @member {boolean} isDefault
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @instance
                     */
                    ShippingAddress.prototype.isDefault = false;

                    /**
                     * ShippingAddress address.
                     * @member {theplant.ec.service.users.IAddress|null|undefined} address
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @instance
                     */
                    ShippingAddress.prototype.address = null;

                    /**
                     * Creates a new ShippingAddress instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {theplant.ec.service.users.IShippingAddress=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.ShippingAddress} ShippingAddress instance
                     */
                    ShippingAddress.create = function create(properties) {
                        return new ShippingAddress(properties);
                    };

                    /**
                     * Encodes the specified ShippingAddress message. Does not implicitly {@link theplant.ec.service.users.ShippingAddress.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {theplant.ec.service.users.IShippingAddress} message ShippingAddress message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddress.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isDefault);
                        if (message.address != null && message.hasOwnProperty("address"))
                            $root.theplant.ec.service.users.Address.encode(message.address, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShippingAddress message, length delimited. Does not implicitly {@link theplant.ec.service.users.ShippingAddress.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {theplant.ec.service.users.IShippingAddress} message ShippingAddress message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShippingAddress.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShippingAddress message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.ShippingAddress} ShippingAddress
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddress.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.ShippingAddress();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.isDefault = reader.bool();
                                break;
                            case 3:
                                message.address = $root.theplant.ec.service.users.Address.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShippingAddress message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.ShippingAddress} ShippingAddress
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShippingAddress.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShippingAddress message.
                     * @function verify
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShippingAddress.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            if (typeof message.isDefault !== "boolean")
                                return "isDefault: boolean expected";
                        if (message.address != null && message.hasOwnProperty("address")) {
                            var error = $root.theplant.ec.service.users.Address.verify(message.address);
                            if (error)
                                return "address." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ShippingAddress message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.ShippingAddress} ShippingAddress
                     */
                    ShippingAddress.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.ShippingAddress)
                            return object;
                        var message = new $root.theplant.ec.service.users.ShippingAddress();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.isDefault != null)
                            message.isDefault = Boolean(object.isDefault);
                        if (object.address != null) {
                            if (typeof object.address !== "object")
                                throw TypeError(".theplant.ec.service.users.ShippingAddress.address: object expected");
                            message.address = $root.theplant.ec.service.users.Address.fromObject(object.address);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShippingAddress message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @static
                     * @param {theplant.ec.service.users.ShippingAddress} message ShippingAddress
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShippingAddress.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.isDefault = false;
                            object.address = null;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.isDefault != null && message.hasOwnProperty("isDefault"))
                            object.isDefault = message.isDefault;
                        if (message.address != null && message.hasOwnProperty("address"))
                            object.address = $root.theplant.ec.service.users.Address.toObject(message.address, options);
                        return object;
                    };

                    /**
                     * Converts this ShippingAddress to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.ShippingAddress
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShippingAddress.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShippingAddress;
                })();

                users.UserSearchConfig = (function() {

                    /**
                     * Properties of a UserSearchConfig.
                     * @memberof theplant.ec.service.users
                     * @interface IUserSearchConfig
                     * @property {string|null} [project] UserSearchConfig project
                     * @property {string|null} [locale] UserSearchConfig locale
                     * @property {theplant.ec.service.search.IndexFreshness|null} [freshness] UserSearchConfig freshness
                     */

                    /**
                     * Constructs a new UserSearchConfig.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a UserSearchConfig.
                     * @implements IUserSearchConfig
                     * @constructor
                     * @param {theplant.ec.service.users.IUserSearchConfig=} [properties] Properties to set
                     */
                    function UserSearchConfig(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserSearchConfig project.
                     * @member {string} project
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @instance
                     */
                    UserSearchConfig.prototype.project = "";

                    /**
                     * UserSearchConfig locale.
                     * @member {string} locale
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @instance
                     */
                    UserSearchConfig.prototype.locale = "";

                    /**
                     * UserSearchConfig freshness.
                     * @member {theplant.ec.service.search.IndexFreshness} freshness
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @instance
                     */
                    UserSearchConfig.prototype.freshness = 0;

                    /**
                     * Creates a new UserSearchConfig instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {theplant.ec.service.users.IUserSearchConfig=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.UserSearchConfig} UserSearchConfig instance
                     */
                    UserSearchConfig.create = function create(properties) {
                        return new UserSearchConfig(properties);
                    };

                    /**
                     * Encodes the specified UserSearchConfig message. Does not implicitly {@link theplant.ec.service.users.UserSearchConfig.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {theplant.ec.service.users.IUserSearchConfig} message UserSearchConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserSearchConfig.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.project != null && message.hasOwnProperty("project"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.project);
                        if (message.locale != null && message.hasOwnProperty("locale"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.locale);
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.freshness);
                        return writer;
                    };

                    /**
                     * Encodes the specified UserSearchConfig message, length delimited. Does not implicitly {@link theplant.ec.service.users.UserSearchConfig.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {theplant.ec.service.users.IUserSearchConfig} message UserSearchConfig message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserSearchConfig.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a UserSearchConfig message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.UserSearchConfig} UserSearchConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserSearchConfig.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.UserSearchConfig();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.project = reader.string();
                                break;
                            case 2:
                                message.locale = reader.string();
                                break;
                            case 3:
                                message.freshness = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a UserSearchConfig message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.UserSearchConfig} UserSearchConfig
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserSearchConfig.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a UserSearchConfig message.
                     * @function verify
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserSearchConfig.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.project != null && message.hasOwnProperty("project"))
                            if (!$util.isString(message.project))
                                return "project: string expected";
                        if (message.locale != null && message.hasOwnProperty("locale"))
                            if (!$util.isString(message.locale))
                                return "locale: string expected";
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            switch (message.freshness) {
                            default:
                                return "freshness: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a UserSearchConfig message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.UserSearchConfig} UserSearchConfig
                     */
                    UserSearchConfig.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.UserSearchConfig)
                            return object;
                        var message = new $root.theplant.ec.service.users.UserSearchConfig();
                        if (object.project != null)
                            message.project = String(object.project);
                        if (object.locale != null)
                            message.locale = String(object.locale);
                        switch (object.freshness) {
                        case "WHEN_CONVENIENT":
                        case 0:
                            message.freshness = 0;
                            break;
                        case "SYNCHRONOUSLY":
                        case 1:
                            message.freshness = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserSearchConfig message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @static
                     * @param {theplant.ec.service.users.UserSearchConfig} message UserSearchConfig
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserSearchConfig.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.project = "";
                            object.locale = "";
                            object.freshness = options.enums === String ? "WHEN_CONVENIENT" : 0;
                        }
                        if (message.project != null && message.hasOwnProperty("project"))
                            object.project = message.project;
                        if (message.locale != null && message.hasOwnProperty("locale"))
                            object.locale = message.locale;
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            object.freshness = options.enums === String ? $root.theplant.ec.service.search.IndexFreshness[message.freshness] : message.freshness;
                        return object;
                    };

                    /**
                     * Converts this UserSearchConfig to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.UserSearchConfig
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserSearchConfig.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UserSearchConfig;
                })();

                /**
                 * UserRegisteredTrack enum.
                 * @name theplant.ec.service.users.UserRegisteredTrack
                 * @enum {string}
                 * @property {number} UNKNOWN_TRACK=0 UNKNOWN_TRACK value
                 * @property {number} EC_WEB=1 EC_WEB value
                 * @property {number} EC_WEB_FROM_EMAIL=2 EC_WEB_FROM_EMAIL value
                 * @property {number} STORE_ADMIN=3 STORE_ADMIN value
                 * @property {number} IN_STORE=4 IN_STORE value
                 * @property {number} EXTERNAL_API=5 EXTERNAL_API value
                 */
                users.UserRegisteredTrack = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_TRACK"] = 0;
                    values[valuesById[1] = "EC_WEB"] = 1;
                    values[valuesById[2] = "EC_WEB_FROM_EMAIL"] = 2;
                    values[valuesById[3] = "STORE_ADMIN"] = 3;
                    values[valuesById[4] = "IN_STORE"] = 4;
                    values[valuesById[5] = "EXTERNAL_API"] = 5;
                    return values;
                })();

                /**
                 * DeleteAttribute enum.
                 * @name theplant.ec.service.users.DeleteAttribute
                 * @enum {string}
                 * @property {number} Delete_UserID=0 Delete_UserID value
                 * @property {number} Delete_LoginKey=1 Delete_LoginKey value
                 */
                users.DeleteAttribute = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "Delete_UserID"] = 0;
                    values[valuesById[1] = "Delete_LoginKey"] = 1;
                    return values;
                })();

                /**
                 * MemberStatus enum.
                 * @name theplant.ec.service.users.MemberStatus
                 * @enum {string}
                 * @property {number} UNKNOWN_MEMBER_STATUS=0 UNKNOWN_MEMBER_STATUS value
                 * @property {number} INACTIVE=1 INACTIVE value
                 * @property {number} NORMAL=2 NORMAL value
                 * @property {number} SILVER=3 SILVER value
                 * @property {number} GOLD=4 GOLD value
                 * @property {number} PLATINUM=5 PLATINUM value
                 */
                users.MemberStatus = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "UNKNOWN_MEMBER_STATUS"] = 0;
                    values[valuesById[1] = "INACTIVE"] = 1;
                    values[valuesById[2] = "NORMAL"] = 2;
                    values[valuesById[3] = "SILVER"] = 3;
                    values[valuesById[4] = "GOLD"] = 4;
                    values[valuesById[5] = "PLATINUM"] = 5;
                    return values;
                })();

                users.UserSegmentation = (function() {

                    /**
                     * Properties of a UserSegmentation.
                     * @memberof theplant.ec.service.users
                     * @interface IUserSegmentation
                     * @property {string|null} [identifier] UserSegmentation identifier
                     * @property {number|Long|null} [usersNumber] UserSegmentation usersNumber
                     * @property {Array.<theplant.ec.service.users.MemberStatus>|null} [ruleMemberStatusList] UserSegmentation ruleMemberStatusList
                     * @property {theplant.ec.service.users.UserGender|null} [ruleGender] UserSegmentation ruleGender
                     * @property {string|null} [ruleAgeFrom] UserSegmentation ruleAgeFrom
                     * @property {string|null} [ruleAgeTo] UserSegmentation ruleAgeTo
                     * @property {string|null} [rulePrefecture] UserSegmentation rulePrefecture
                     * @property {theplant.ec.service.users.UserSegmentation.IsOrderActive|null} [ruleIsOrderActive] UserSegmentation ruleIsOrderActive
                     * @property {string|null} [ruleMemberNumbers] UserSegmentation ruleMemberNumbers
                     * @property {google.protobuf.ITimestamp|null} [ruleRegisteredDateFrom] UserSegmentation ruleRegisteredDateFrom
                     * @property {google.protobuf.ITimestamp|null} [ruleRegisteredDateTo] UserSegmentation ruleRegisteredDateTo
                     */

                    /**
                     * Constructs a new UserSegmentation.
                     * @memberof theplant.ec.service.users
                     * @classdesc Represents a UserSegmentation.
                     * @implements IUserSegmentation
                     * @constructor
                     * @param {theplant.ec.service.users.IUserSegmentation=} [properties] Properties to set
                     */
                    function UserSegmentation(properties) {
                        this.ruleMemberStatusList = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UserSegmentation identifier.
                     * @member {string} identifier
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.identifier = "";

                    /**
                     * UserSegmentation usersNumber.
                     * @member {number|Long} usersNumber
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.usersNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UserSegmentation ruleMemberStatusList.
                     * @member {Array.<theplant.ec.service.users.MemberStatus>} ruleMemberStatusList
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleMemberStatusList = $util.emptyArray;

                    /**
                     * UserSegmentation ruleGender.
                     * @member {theplant.ec.service.users.UserGender} ruleGender
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleGender = 0;

                    /**
                     * UserSegmentation ruleAgeFrom.
                     * @member {string} ruleAgeFrom
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleAgeFrom = "";

                    /**
                     * UserSegmentation ruleAgeTo.
                     * @member {string} ruleAgeTo
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleAgeTo = "";

                    /**
                     * UserSegmentation rulePrefecture.
                     * @member {string} rulePrefecture
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.rulePrefecture = "";

                    /**
                     * UserSegmentation ruleIsOrderActive.
                     * @member {theplant.ec.service.users.UserSegmentation.IsOrderActive} ruleIsOrderActive
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleIsOrderActive = 0;

                    /**
                     * UserSegmentation ruleMemberNumbers.
                     * @member {string} ruleMemberNumbers
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleMemberNumbers = "";

                    /**
                     * UserSegmentation ruleRegisteredDateFrom.
                     * @member {google.protobuf.ITimestamp|null|undefined} ruleRegisteredDateFrom
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleRegisteredDateFrom = null;

                    /**
                     * UserSegmentation ruleRegisteredDateTo.
                     * @member {google.protobuf.ITimestamp|null|undefined} ruleRegisteredDateTo
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     */
                    UserSegmentation.prototype.ruleRegisteredDateTo = null;

                    /**
                     * Creates a new UserSegmentation instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {theplant.ec.service.users.IUserSegmentation=} [properties] Properties to set
                     * @returns {theplant.ec.service.users.UserSegmentation} UserSegmentation instance
                     */
                    UserSegmentation.create = function create(properties) {
                        return new UserSegmentation(properties);
                    };

                    /**
                     * Encodes the specified UserSegmentation message. Does not implicitly {@link theplant.ec.service.users.UserSegmentation.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {theplant.ec.service.users.IUserSegmentation} message UserSegmentation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserSegmentation.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.identifier != null && message.hasOwnProperty("identifier"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.identifier);
                        if (message.usersNumber != null && message.hasOwnProperty("usersNumber"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.usersNumber);
                        if (message.ruleMemberStatusList != null && message.ruleMemberStatusList.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.ruleMemberStatusList.length; ++i)
                                writer.int32(message.ruleMemberStatusList[i]);
                            writer.ldelim();
                        }
                        if (message.ruleGender != null && message.hasOwnProperty("ruleGender"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ruleGender);
                        if (message.ruleAgeFrom != null && message.hasOwnProperty("ruleAgeFrom"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ruleAgeFrom);
                        if (message.ruleAgeTo != null && message.hasOwnProperty("ruleAgeTo"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.ruleAgeTo);
                        if (message.rulePrefecture != null && message.hasOwnProperty("rulePrefecture"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.rulePrefecture);
                        if (message.ruleIsOrderActive != null && message.hasOwnProperty("ruleIsOrderActive"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ruleIsOrderActive);
                        if (message.ruleMemberNumbers != null && message.hasOwnProperty("ruleMemberNumbers"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.ruleMemberNumbers);
                        if (message.ruleRegisteredDateFrom != null && message.hasOwnProperty("ruleRegisteredDateFrom"))
                            $root.google.protobuf.Timestamp.encode(message.ruleRegisteredDateFrom, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.ruleRegisteredDateTo != null && message.hasOwnProperty("ruleRegisteredDateTo"))
                            $root.google.protobuf.Timestamp.encode(message.ruleRegisteredDateTo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified UserSegmentation message, length delimited. Does not implicitly {@link theplant.ec.service.users.UserSegmentation.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {theplant.ec.service.users.IUserSegmentation} message UserSegmentation message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UserSegmentation.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a UserSegmentation message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.users.UserSegmentation} UserSegmentation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserSegmentation.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.users.UserSegmentation();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.identifier = reader.string();
                                break;
                            case 2:
                                message.usersNumber = reader.int64();
                                break;
                            case 3:
                                if (!(message.ruleMemberStatusList && message.ruleMemberStatusList.length))
                                    message.ruleMemberStatusList = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.ruleMemberStatusList.push(reader.int32());
                                } else
                                    message.ruleMemberStatusList.push(reader.int32());
                                break;
                            case 4:
                                message.ruleGender = reader.int32();
                                break;
                            case 5:
                                message.ruleAgeFrom = reader.string();
                                break;
                            case 6:
                                message.ruleAgeTo = reader.string();
                                break;
                            case 7:
                                message.rulePrefecture = reader.string();
                                break;
                            case 8:
                                message.ruleIsOrderActive = reader.int32();
                                break;
                            case 9:
                                message.ruleMemberNumbers = reader.string();
                                break;
                            case 10:
                                message.ruleRegisteredDateFrom = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.ruleRegisteredDateTo = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a UserSegmentation message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.users.UserSegmentation} UserSegmentation
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UserSegmentation.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a UserSegmentation message.
                     * @function verify
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UserSegmentation.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.identifier != null && message.hasOwnProperty("identifier"))
                            if (!$util.isString(message.identifier))
                                return "identifier: string expected";
                        if (message.usersNumber != null && message.hasOwnProperty("usersNumber"))
                            if (!$util.isInteger(message.usersNumber) && !(message.usersNumber && $util.isInteger(message.usersNumber.low) && $util.isInteger(message.usersNumber.high)))
                                return "usersNumber: integer|Long expected";
                        if (message.ruleMemberStatusList != null && message.hasOwnProperty("ruleMemberStatusList")) {
                            if (!Array.isArray(message.ruleMemberStatusList))
                                return "ruleMemberStatusList: array expected";
                            for (var i = 0; i < message.ruleMemberStatusList.length; ++i)
                                switch (message.ruleMemberStatusList[i]) {
                                default:
                                    return "ruleMemberStatusList: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                    break;
                                }
                        }
                        if (message.ruleGender != null && message.hasOwnProperty("ruleGender"))
                            switch (message.ruleGender) {
                            default:
                                return "ruleGender: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.ruleAgeFrom != null && message.hasOwnProperty("ruleAgeFrom"))
                            if (!$util.isString(message.ruleAgeFrom))
                                return "ruleAgeFrom: string expected";
                        if (message.ruleAgeTo != null && message.hasOwnProperty("ruleAgeTo"))
                            if (!$util.isString(message.ruleAgeTo))
                                return "ruleAgeTo: string expected";
                        if (message.rulePrefecture != null && message.hasOwnProperty("rulePrefecture"))
                            if (!$util.isString(message.rulePrefecture))
                                return "rulePrefecture: string expected";
                        if (message.ruleIsOrderActive != null && message.hasOwnProperty("ruleIsOrderActive"))
                            switch (message.ruleIsOrderActive) {
                            default:
                                return "ruleIsOrderActive: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.ruleMemberNumbers != null && message.hasOwnProperty("ruleMemberNumbers"))
                            if (!$util.isString(message.ruleMemberNumbers))
                                return "ruleMemberNumbers: string expected";
                        if (message.ruleRegisteredDateFrom != null && message.hasOwnProperty("ruleRegisteredDateFrom")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.ruleRegisteredDateFrom);
                            if (error)
                                return "ruleRegisteredDateFrom." + error;
                        }
                        if (message.ruleRegisteredDateTo != null && message.hasOwnProperty("ruleRegisteredDateTo")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.ruleRegisteredDateTo);
                            if (error)
                                return "ruleRegisteredDateTo." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a UserSegmentation message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.users.UserSegmentation} UserSegmentation
                     */
                    UserSegmentation.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.users.UserSegmentation)
                            return object;
                        var message = new $root.theplant.ec.service.users.UserSegmentation();
                        if (object.identifier != null)
                            message.identifier = String(object.identifier);
                        if (object.usersNumber != null)
                            if ($util.Long)
                                (message.usersNumber = $util.Long.fromValue(object.usersNumber)).unsigned = false;
                            else if (typeof object.usersNumber === "string")
                                message.usersNumber = parseInt(object.usersNumber, 10);
                            else if (typeof object.usersNumber === "number")
                                message.usersNumber = object.usersNumber;
                            else if (typeof object.usersNumber === "object")
                                message.usersNumber = new $util.LongBits(object.usersNumber.low >>> 0, object.usersNumber.high >>> 0).toNumber();
                        if (object.ruleMemberStatusList) {
                            if (!Array.isArray(object.ruleMemberStatusList))
                                throw TypeError(".theplant.ec.service.users.UserSegmentation.ruleMemberStatusList: array expected");
                            message.ruleMemberStatusList = [];
                            for (var i = 0; i < object.ruleMemberStatusList.length; ++i)
                                switch (object.ruleMemberStatusList[i]) {
                                default:
                                case "UNKNOWN_MEMBER_STATUS":
                                case 0:
                                    message.ruleMemberStatusList[i] = 0;
                                    break;
                                case "INACTIVE":
                                case 1:
                                    message.ruleMemberStatusList[i] = 1;
                                    break;
                                case "NORMAL":
                                case 2:
                                    message.ruleMemberStatusList[i] = 2;
                                    break;
                                case "SILVER":
                                case 3:
                                    message.ruleMemberStatusList[i] = 3;
                                    break;
                                case "GOLD":
                                case 4:
                                    message.ruleMemberStatusList[i] = 4;
                                    break;
                                case "PLATINUM":
                                case 5:
                                    message.ruleMemberStatusList[i] = 5;
                                    break;
                                }
                        }
                        switch (object.ruleGender) {
                        case "UNKNOWN":
                        case 0:
                            message.ruleGender = 0;
                            break;
                        case "MALE":
                        case 1:
                            message.ruleGender = 1;
                            break;
                        case "FEMALE":
                        case 2:
                            message.ruleGender = 2;
                            break;
                        }
                        if (object.ruleAgeFrom != null)
                            message.ruleAgeFrom = String(object.ruleAgeFrom);
                        if (object.ruleAgeTo != null)
                            message.ruleAgeTo = String(object.ruleAgeTo);
                        if (object.rulePrefecture != null)
                            message.rulePrefecture = String(object.rulePrefecture);
                        switch (object.ruleIsOrderActive) {
                        case "UNKNOWN":
                        case 0:
                            message.ruleIsOrderActive = 0;
                            break;
                        case "TRUE":
                        case 1:
                            message.ruleIsOrderActive = 1;
                            break;
                        case "FALSE":
                        case 2:
                            message.ruleIsOrderActive = 2;
                            break;
                        }
                        if (object.ruleMemberNumbers != null)
                            message.ruleMemberNumbers = String(object.ruleMemberNumbers);
                        if (object.ruleRegisteredDateFrom != null) {
                            if (typeof object.ruleRegisteredDateFrom !== "object")
                                throw TypeError(".theplant.ec.service.users.UserSegmentation.ruleRegisteredDateFrom: object expected");
                            message.ruleRegisteredDateFrom = $root.google.protobuf.Timestamp.fromObject(object.ruleRegisteredDateFrom);
                        }
                        if (object.ruleRegisteredDateTo != null) {
                            if (typeof object.ruleRegisteredDateTo !== "object")
                                throw TypeError(".theplant.ec.service.users.UserSegmentation.ruleRegisteredDateTo: object expected");
                            message.ruleRegisteredDateTo = $root.google.protobuf.Timestamp.fromObject(object.ruleRegisteredDateTo);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a UserSegmentation message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @static
                     * @param {theplant.ec.service.users.UserSegmentation} message UserSegmentation
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UserSegmentation.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.ruleMemberStatusList = [];
                        if (options.defaults) {
                            object.identifier = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.usersNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.usersNumber = options.longs === String ? "0" : 0;
                            object.ruleGender = options.enums === String ? "UNKNOWN" : 0;
                            object.ruleAgeFrom = "";
                            object.ruleAgeTo = "";
                            object.rulePrefecture = "";
                            object.ruleIsOrderActive = options.enums === String ? "UNKNOWN" : 0;
                            object.ruleMemberNumbers = "";
                            object.ruleRegisteredDateFrom = null;
                            object.ruleRegisteredDateTo = null;
                        }
                        if (message.identifier != null && message.hasOwnProperty("identifier"))
                            object.identifier = message.identifier;
                        if (message.usersNumber != null && message.hasOwnProperty("usersNumber"))
                            if (typeof message.usersNumber === "number")
                                object.usersNumber = options.longs === String ? String(message.usersNumber) : message.usersNumber;
                            else
                                object.usersNumber = options.longs === String ? $util.Long.prototype.toString.call(message.usersNumber) : options.longs === Number ? new $util.LongBits(message.usersNumber.low >>> 0, message.usersNumber.high >>> 0).toNumber() : message.usersNumber;
                        if (message.ruleMemberStatusList && message.ruleMemberStatusList.length) {
                            object.ruleMemberStatusList = [];
                            for (var j = 0; j < message.ruleMemberStatusList.length; ++j)
                                object.ruleMemberStatusList[j] = options.enums === String ? $root.theplant.ec.service.users.MemberStatus[message.ruleMemberStatusList[j]] : message.ruleMemberStatusList[j];
                        }
                        if (message.ruleGender != null && message.hasOwnProperty("ruleGender"))
                            object.ruleGender = options.enums === String ? $root.theplant.ec.service.users.UserGender[message.ruleGender] : message.ruleGender;
                        if (message.ruleAgeFrom != null && message.hasOwnProperty("ruleAgeFrom"))
                            object.ruleAgeFrom = message.ruleAgeFrom;
                        if (message.ruleAgeTo != null && message.hasOwnProperty("ruleAgeTo"))
                            object.ruleAgeTo = message.ruleAgeTo;
                        if (message.rulePrefecture != null && message.hasOwnProperty("rulePrefecture"))
                            object.rulePrefecture = message.rulePrefecture;
                        if (message.ruleIsOrderActive != null && message.hasOwnProperty("ruleIsOrderActive"))
                            object.ruleIsOrderActive = options.enums === String ? $root.theplant.ec.service.users.UserSegmentation.IsOrderActive[message.ruleIsOrderActive] : message.ruleIsOrderActive;
                        if (message.ruleMemberNumbers != null && message.hasOwnProperty("ruleMemberNumbers"))
                            object.ruleMemberNumbers = message.ruleMemberNumbers;
                        if (message.ruleRegisteredDateFrom != null && message.hasOwnProperty("ruleRegisteredDateFrom"))
                            object.ruleRegisteredDateFrom = $root.google.protobuf.Timestamp.toObject(message.ruleRegisteredDateFrom, options);
                        if (message.ruleRegisteredDateTo != null && message.hasOwnProperty("ruleRegisteredDateTo"))
                            object.ruleRegisteredDateTo = $root.google.protobuf.Timestamp.toObject(message.ruleRegisteredDateTo, options);
                        return object;
                    };

                    /**
                     * Converts this UserSegmentation to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.users.UserSegmentation
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UserSegmentation.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * IsOrderActive enum.
                     * @name theplant.ec.service.users.UserSegmentation.IsOrderActive
                     * @enum {string}
                     * @property {number} UNKNOWN=0 UNKNOWN value
                     * @property {number} TRUE=1 TRUE value
                     * @property {number} FALSE=2 FALSE value
                     */
                    UserSegmentation.IsOrderActive = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "UNKNOWN"] = 0;
                        values[valuesById[1] = "TRUE"] = 1;
                        values[valuesById[2] = "FALSE"] = 2;
                        return values;
                    })();

                    return UserSegmentation;
                })();

                return users;
            })();

            service.search = (function() {

                /**
                 * Namespace search.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var search = {};

                search.PutTemplateParams = (function() {

                    /**
                     * Properties of a PutTemplateParams.
                     * @memberof theplant.ec.service.search
                     * @interface IPutTemplateParams
                     * @property {Array.<theplant.ec.service.search.IWordbook>|null} [wordbooks] PutTemplateParams wordbooks
                     */

                    /**
                     * Constructs a new PutTemplateParams.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a PutTemplateParams.
                     * @implements IPutTemplateParams
                     * @constructor
                     * @param {theplant.ec.service.search.IPutTemplateParams=} [properties] Properties to set
                     */
                    function PutTemplateParams(properties) {
                        this.wordbooks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PutTemplateParams wordbooks.
                     * @member {Array.<theplant.ec.service.search.IWordbook>} wordbooks
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @instance
                     */
                    PutTemplateParams.prototype.wordbooks = $util.emptyArray;

                    /**
                     * Creates a new PutTemplateParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {theplant.ec.service.search.IPutTemplateParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.PutTemplateParams} PutTemplateParams instance
                     */
                    PutTemplateParams.create = function create(properties) {
                        return new PutTemplateParams(properties);
                    };

                    /**
                     * Encodes the specified PutTemplateParams message. Does not implicitly {@link theplant.ec.service.search.PutTemplateParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {theplant.ec.service.search.IPutTemplateParams} message PutTemplateParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PutTemplateParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.wordbooks != null && message.wordbooks.length)
                            for (var i = 0; i < message.wordbooks.length; ++i)
                                $root.theplant.ec.service.search.Wordbook.encode(message.wordbooks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PutTemplateParams message, length delimited. Does not implicitly {@link theplant.ec.service.search.PutTemplateParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {theplant.ec.service.search.IPutTemplateParams} message PutTemplateParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PutTemplateParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PutTemplateParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.PutTemplateParams} PutTemplateParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PutTemplateParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.PutTemplateParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                if (!(message.wordbooks && message.wordbooks.length))
                                    message.wordbooks = [];
                                message.wordbooks.push($root.theplant.ec.service.search.Wordbook.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PutTemplateParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.PutTemplateParams} PutTemplateParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PutTemplateParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PutTemplateParams message.
                     * @function verify
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PutTemplateParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.wordbooks != null && message.hasOwnProperty("wordbooks")) {
                            if (!Array.isArray(message.wordbooks))
                                return "wordbooks: array expected";
                            for (var i = 0; i < message.wordbooks.length; ++i) {
                                var error = $root.theplant.ec.service.search.Wordbook.verify(message.wordbooks[i]);
                                if (error)
                                    return "wordbooks." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a PutTemplateParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.PutTemplateParams} PutTemplateParams
                     */
                    PutTemplateParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.PutTemplateParams)
                            return object;
                        var message = new $root.theplant.ec.service.search.PutTemplateParams();
                        if (object.wordbooks) {
                            if (!Array.isArray(object.wordbooks))
                                throw TypeError(".theplant.ec.service.search.PutTemplateParams.wordbooks: array expected");
                            message.wordbooks = [];
                            for (var i = 0; i < object.wordbooks.length; ++i) {
                                if (typeof object.wordbooks[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.PutTemplateParams.wordbooks: object expected");
                                message.wordbooks[i] = $root.theplant.ec.service.search.Wordbook.fromObject(object.wordbooks[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PutTemplateParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @static
                     * @param {theplant.ec.service.search.PutTemplateParams} message PutTemplateParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PutTemplateParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.wordbooks = [];
                        if (message.wordbooks && message.wordbooks.length) {
                            object.wordbooks = [];
                            for (var j = 0; j < message.wordbooks.length; ++j)
                                object.wordbooks[j] = $root.theplant.ec.service.search.Wordbook.toObject(message.wordbooks[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this PutTemplateParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.PutTemplateParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PutTemplateParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PutTemplateParams;
                })();

                search.DeleteIndexParams = (function() {

                    /**
                     * Properties of a DeleteIndexParams.
                     * @memberof theplant.ec.service.search
                     * @interface IDeleteIndexParams
                     * @property {theplant.ec.service.search.IIndexSpec|null} [indexSpec] DeleteIndexParams indexSpec
                     */

                    /**
                     * Constructs a new DeleteIndexParams.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a DeleteIndexParams.
                     * @implements IDeleteIndexParams
                     * @constructor
                     * @param {theplant.ec.service.search.IDeleteIndexParams=} [properties] Properties to set
                     */
                    function DeleteIndexParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeleteIndexParams indexSpec.
                     * @member {theplant.ec.service.search.IIndexSpec|null|undefined} indexSpec
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @instance
                     */
                    DeleteIndexParams.prototype.indexSpec = null;

                    /**
                     * Creates a new DeleteIndexParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {theplant.ec.service.search.IDeleteIndexParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.DeleteIndexParams} DeleteIndexParams instance
                     */
                    DeleteIndexParams.create = function create(properties) {
                        return new DeleteIndexParams(properties);
                    };

                    /**
                     * Encodes the specified DeleteIndexParams message. Does not implicitly {@link theplant.ec.service.search.DeleteIndexParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {theplant.ec.service.search.IDeleteIndexParams} message DeleteIndexParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteIndexParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            $root.theplant.ec.service.search.IndexSpec.encode(message.indexSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified DeleteIndexParams message, length delimited. Does not implicitly {@link theplant.ec.service.search.DeleteIndexParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {theplant.ec.service.search.IDeleteIndexParams} message DeleteIndexParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteIndexParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeleteIndexParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.DeleteIndexParams} DeleteIndexParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteIndexParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.DeleteIndexParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.indexSpec = $root.theplant.ec.service.search.IndexSpec.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeleteIndexParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.DeleteIndexParams} DeleteIndexParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteIndexParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeleteIndexParams message.
                     * @function verify
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteIndexParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec")) {
                            var error = $root.theplant.ec.service.search.IndexSpec.verify(message.indexSpec);
                            if (error)
                                return "indexSpec." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a DeleteIndexParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.DeleteIndexParams} DeleteIndexParams
                     */
                    DeleteIndexParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.DeleteIndexParams)
                            return object;
                        var message = new $root.theplant.ec.service.search.DeleteIndexParams();
                        if (object.indexSpec != null) {
                            if (typeof object.indexSpec !== "object")
                                throw TypeError(".theplant.ec.service.search.DeleteIndexParams.indexSpec: object expected");
                            message.indexSpec = $root.theplant.ec.service.search.IndexSpec.fromObject(object.indexSpec);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeleteIndexParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @static
                     * @param {theplant.ec.service.search.DeleteIndexParams} message DeleteIndexParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteIndexParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.indexSpec = null;
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            object.indexSpec = $root.theplant.ec.service.search.IndexSpec.toObject(message.indexSpec, options);
                        return object;
                    };

                    /**
                     * Converts this DeleteIndexParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.DeleteIndexParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteIndexParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeleteIndexParams;
                })();

                search.Wordbook = (function() {

                    /**
                     * Properties of a Wordbook.
                     * @memberof theplant.ec.service.search
                     * @interface IWordbook
                     * @property {theplant.ec.service.search.Wordbook.Language|null} [language] Wordbook language
                     * @property {Array.<string>|null} [keywords] Wordbook keywords
                     * @property {Array.<string>|null} [stopwords] Wordbook stopwords
                     * @property {Array.<string>|null} [synonyms] Wordbook synonyms
                     */

                    /**
                     * Constructs a new Wordbook.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a Wordbook.
                     * @implements IWordbook
                     * @constructor
                     * @param {theplant.ec.service.search.IWordbook=} [properties] Properties to set
                     */
                    function Wordbook(properties) {
                        this.keywords = [];
                        this.stopwords = [];
                        this.synonyms = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Wordbook language.
                     * @member {theplant.ec.service.search.Wordbook.Language} language
                     * @memberof theplant.ec.service.search.Wordbook
                     * @instance
                     */
                    Wordbook.prototype.language = 0;

                    /**
                     * Wordbook keywords.
                     * @member {Array.<string>} keywords
                     * @memberof theplant.ec.service.search.Wordbook
                     * @instance
                     */
                    Wordbook.prototype.keywords = $util.emptyArray;

                    /**
                     * Wordbook stopwords.
                     * @member {Array.<string>} stopwords
                     * @memberof theplant.ec.service.search.Wordbook
                     * @instance
                     */
                    Wordbook.prototype.stopwords = $util.emptyArray;

                    /**
                     * Wordbook synonyms.
                     * @member {Array.<string>} synonyms
                     * @memberof theplant.ec.service.search.Wordbook
                     * @instance
                     */
                    Wordbook.prototype.synonyms = $util.emptyArray;

                    /**
                     * Creates a new Wordbook instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {theplant.ec.service.search.IWordbook=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.Wordbook} Wordbook instance
                     */
                    Wordbook.create = function create(properties) {
                        return new Wordbook(properties);
                    };

                    /**
                     * Encodes the specified Wordbook message. Does not implicitly {@link theplant.ec.service.search.Wordbook.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {theplant.ec.service.search.IWordbook} message Wordbook message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Wordbook.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.language != null && message.hasOwnProperty("language"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.language);
                        if (message.keywords != null && message.keywords.length)
                            for (var i = 0; i < message.keywords.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.keywords[i]);
                        if (message.stopwords != null && message.stopwords.length)
                            for (var i = 0; i < message.stopwords.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.stopwords[i]);
                        if (message.synonyms != null && message.synonyms.length)
                            for (var i = 0; i < message.synonyms.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.synonyms[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified Wordbook message, length delimited. Does not implicitly {@link theplant.ec.service.search.Wordbook.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {theplant.ec.service.search.IWordbook} message Wordbook message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Wordbook.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Wordbook message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.Wordbook} Wordbook
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Wordbook.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.Wordbook();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.language = reader.int32();
                                break;
                            case 2:
                                if (!(message.keywords && message.keywords.length))
                                    message.keywords = [];
                                message.keywords.push(reader.string());
                                break;
                            case 3:
                                if (!(message.stopwords && message.stopwords.length))
                                    message.stopwords = [];
                                message.stopwords.push(reader.string());
                                break;
                            case 4:
                                if (!(message.synonyms && message.synonyms.length))
                                    message.synonyms = [];
                                message.synonyms.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Wordbook message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.Wordbook} Wordbook
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Wordbook.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Wordbook message.
                     * @function verify
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Wordbook.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.language != null && message.hasOwnProperty("language"))
                            switch (message.language) {
                            default:
                                return "language: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                        if (message.keywords != null && message.hasOwnProperty("keywords")) {
                            if (!Array.isArray(message.keywords))
                                return "keywords: array expected";
                            for (var i = 0; i < message.keywords.length; ++i)
                                if (!$util.isString(message.keywords[i]))
                                    return "keywords: string[] expected";
                        }
                        if (message.stopwords != null && message.hasOwnProperty("stopwords")) {
                            if (!Array.isArray(message.stopwords))
                                return "stopwords: array expected";
                            for (var i = 0; i < message.stopwords.length; ++i)
                                if (!$util.isString(message.stopwords[i]))
                                    return "stopwords: string[] expected";
                        }
                        if (message.synonyms != null && message.hasOwnProperty("synonyms")) {
                            if (!Array.isArray(message.synonyms))
                                return "synonyms: array expected";
                            for (var i = 0; i < message.synonyms.length; ++i)
                                if (!$util.isString(message.synonyms[i]))
                                    return "synonyms: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a Wordbook message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.Wordbook} Wordbook
                     */
                    Wordbook.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.Wordbook)
                            return object;
                        var message = new $root.theplant.ec.service.search.Wordbook();
                        switch (object.language) {
                        case "EN":
                        case 0:
                            message.language = 0;
                            break;
                        case "JA":
                        case 1:
                            message.language = 1;
                            break;
                        case "RU":
                        case 2:
                            message.language = 2;
                            break;
                        case "ZH":
                        case 3:
                            message.language = 3;
                            break;
                        case "KO":
                        case 4:
                            message.language = 4;
                            break;
                        }
                        if (object.keywords) {
                            if (!Array.isArray(object.keywords))
                                throw TypeError(".theplant.ec.service.search.Wordbook.keywords: array expected");
                            message.keywords = [];
                            for (var i = 0; i < object.keywords.length; ++i)
                                message.keywords[i] = String(object.keywords[i]);
                        }
                        if (object.stopwords) {
                            if (!Array.isArray(object.stopwords))
                                throw TypeError(".theplant.ec.service.search.Wordbook.stopwords: array expected");
                            message.stopwords = [];
                            for (var i = 0; i < object.stopwords.length; ++i)
                                message.stopwords[i] = String(object.stopwords[i]);
                        }
                        if (object.synonyms) {
                            if (!Array.isArray(object.synonyms))
                                throw TypeError(".theplant.ec.service.search.Wordbook.synonyms: array expected");
                            message.synonyms = [];
                            for (var i = 0; i < object.synonyms.length; ++i)
                                message.synonyms[i] = String(object.synonyms[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Wordbook message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.Wordbook
                     * @static
                     * @param {theplant.ec.service.search.Wordbook} message Wordbook
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Wordbook.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.keywords = [];
                            object.stopwords = [];
                            object.synonyms = [];
                        }
                        if (options.defaults)
                            object.language = options.enums === String ? "EN" : 0;
                        if (message.language != null && message.hasOwnProperty("language"))
                            object.language = options.enums === String ? $root.theplant.ec.service.search.Wordbook.Language[message.language] : message.language;
                        if (message.keywords && message.keywords.length) {
                            object.keywords = [];
                            for (var j = 0; j < message.keywords.length; ++j)
                                object.keywords[j] = message.keywords[j];
                        }
                        if (message.stopwords && message.stopwords.length) {
                            object.stopwords = [];
                            for (var j = 0; j < message.stopwords.length; ++j)
                                object.stopwords[j] = message.stopwords[j];
                        }
                        if (message.synonyms && message.synonyms.length) {
                            object.synonyms = [];
                            for (var j = 0; j < message.synonyms.length; ++j)
                                object.synonyms[j] = message.synonyms[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this Wordbook to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.Wordbook
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Wordbook.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Language enum.
                     * @name theplant.ec.service.search.Wordbook.Language
                     * @enum {string}
                     * @property {number} EN=0 EN value
                     * @property {number} JA=1 JA value
                     * @property {number} RU=2 RU value
                     * @property {number} ZH=3 ZH value
                     * @property {number} KO=4 KO value
                     */
                    Wordbook.Language = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "EN"] = 0;
                        values[valuesById[1] = "JA"] = 1;
                        values[valuesById[2] = "RU"] = 2;
                        values[valuesById[3] = "ZH"] = 3;
                        values[valuesById[4] = "KO"] = 4;
                        return values;
                    })();

                    return Wordbook;
                })();

                /**
                 * IndexFreshness enum.
                 * @name theplant.ec.service.search.IndexFreshness
                 * @enum {string}
                 * @property {number} WHEN_CONVENIENT=0 WHEN_CONVENIENT value
                 * @property {number} SYNCHRONOUSLY=1 SYNCHRONOUSLY value
                 */
                search.IndexFreshness = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "WHEN_CONVENIENT"] = 0;
                    values[valuesById[1] = "SYNCHRONOUSLY"] = 1;
                    return values;
                })();

                search.IndexDocumentParams = (function() {

                    /**
                     * Properties of an IndexDocumentParams.
                     * @memberof theplant.ec.service.search
                     * @interface IIndexDocumentParams
                     * @property {theplant.ec.service.search.IIndexSpec|null} [indexSpec] IndexDocumentParams indexSpec
                     * @property {Array.<theplant.ec.service.search.IDocument>|null} [docs] IndexDocumentParams docs
                     * @property {theplant.ec.service.search.IndexFreshness|null} [freshness] IndexDocumentParams freshness
                     */

                    /**
                     * Constructs a new IndexDocumentParams.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents an IndexDocumentParams.
                     * @implements IIndexDocumentParams
                     * @constructor
                     * @param {theplant.ec.service.search.IIndexDocumentParams=} [properties] Properties to set
                     */
                    function IndexDocumentParams(properties) {
                        this.docs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * IndexDocumentParams indexSpec.
                     * @member {theplant.ec.service.search.IIndexSpec|null|undefined} indexSpec
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @instance
                     */
                    IndexDocumentParams.prototype.indexSpec = null;

                    /**
                     * IndexDocumentParams docs.
                     * @member {Array.<theplant.ec.service.search.IDocument>} docs
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @instance
                     */
                    IndexDocumentParams.prototype.docs = $util.emptyArray;

                    /**
                     * IndexDocumentParams freshness.
                     * @member {theplant.ec.service.search.IndexFreshness} freshness
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @instance
                     */
                    IndexDocumentParams.prototype.freshness = 0;

                    /**
                     * Creates a new IndexDocumentParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IIndexDocumentParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.IndexDocumentParams} IndexDocumentParams instance
                     */
                    IndexDocumentParams.create = function create(properties) {
                        return new IndexDocumentParams(properties);
                    };

                    /**
                     * Encodes the specified IndexDocumentParams message. Does not implicitly {@link theplant.ec.service.search.IndexDocumentParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IIndexDocumentParams} message IndexDocumentParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IndexDocumentParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            $root.theplant.ec.service.search.IndexSpec.encode(message.indexSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.docs != null && message.docs.length)
                            for (var i = 0; i < message.docs.length; ++i)
                                $root.theplant.ec.service.search.Document.encode(message.docs[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.freshness);
                        return writer;
                    };

                    /**
                     * Encodes the specified IndexDocumentParams message, length delimited. Does not implicitly {@link theplant.ec.service.search.IndexDocumentParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IIndexDocumentParams} message IndexDocumentParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IndexDocumentParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an IndexDocumentParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.IndexDocumentParams} IndexDocumentParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IndexDocumentParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.IndexDocumentParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.indexSpec = $root.theplant.ec.service.search.IndexSpec.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.docs && message.docs.length))
                                    message.docs = [];
                                message.docs.push($root.theplant.ec.service.search.Document.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.freshness = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an IndexDocumentParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.IndexDocumentParams} IndexDocumentParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IndexDocumentParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an IndexDocumentParams message.
                     * @function verify
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IndexDocumentParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec")) {
                            var error = $root.theplant.ec.service.search.IndexSpec.verify(message.indexSpec);
                            if (error)
                                return "indexSpec." + error;
                        }
                        if (message.docs != null && message.hasOwnProperty("docs")) {
                            if (!Array.isArray(message.docs))
                                return "docs: array expected";
                            for (var i = 0; i < message.docs.length; ++i) {
                                var error = $root.theplant.ec.service.search.Document.verify(message.docs[i]);
                                if (error)
                                    return "docs." + error;
                            }
                        }
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            switch (message.freshness) {
                            default:
                                return "freshness: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates an IndexDocumentParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.IndexDocumentParams} IndexDocumentParams
                     */
                    IndexDocumentParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.IndexDocumentParams)
                            return object;
                        var message = new $root.theplant.ec.service.search.IndexDocumentParams();
                        if (object.indexSpec != null) {
                            if (typeof object.indexSpec !== "object")
                                throw TypeError(".theplant.ec.service.search.IndexDocumentParams.indexSpec: object expected");
                            message.indexSpec = $root.theplant.ec.service.search.IndexSpec.fromObject(object.indexSpec);
                        }
                        if (object.docs) {
                            if (!Array.isArray(object.docs))
                                throw TypeError(".theplant.ec.service.search.IndexDocumentParams.docs: array expected");
                            message.docs = [];
                            for (var i = 0; i < object.docs.length; ++i) {
                                if (typeof object.docs[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.IndexDocumentParams.docs: object expected");
                                message.docs[i] = $root.theplant.ec.service.search.Document.fromObject(object.docs[i]);
                            }
                        }
                        switch (object.freshness) {
                        case "WHEN_CONVENIENT":
                        case 0:
                            message.freshness = 0;
                            break;
                        case "SYNCHRONOUSLY":
                        case 1:
                            message.freshness = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an IndexDocumentParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IndexDocumentParams} message IndexDocumentParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IndexDocumentParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.docs = [];
                        if (options.defaults) {
                            object.indexSpec = null;
                            object.freshness = options.enums === String ? "WHEN_CONVENIENT" : 0;
                        }
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            object.indexSpec = $root.theplant.ec.service.search.IndexSpec.toObject(message.indexSpec, options);
                        if (message.docs && message.docs.length) {
                            object.docs = [];
                            for (var j = 0; j < message.docs.length; ++j)
                                object.docs[j] = $root.theplant.ec.service.search.Document.toObject(message.docs[j], options);
                        }
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            object.freshness = options.enums === String ? $root.theplant.ec.service.search.IndexFreshness[message.freshness] : message.freshness;
                        return object;
                    };

                    /**
                     * Converts this IndexDocumentParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.IndexDocumentParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IndexDocumentParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return IndexDocumentParams;
                })();

                search.DeleteDocumentParams = (function() {

                    /**
                     * Properties of a DeleteDocumentParams.
                     * @memberof theplant.ec.service.search
                     * @interface IDeleteDocumentParams
                     * @property {theplant.ec.service.search.IIndexSpec|null} [indexSpec] DeleteDocumentParams indexSpec
                     * @property {Array.<string>|null} [ids] DeleteDocumentParams ids
                     * @property {theplant.ec.service.search.IndexFreshness|null} [freshness] DeleteDocumentParams freshness
                     */

                    /**
                     * Constructs a new DeleteDocumentParams.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a DeleteDocumentParams.
                     * @implements IDeleteDocumentParams
                     * @constructor
                     * @param {theplant.ec.service.search.IDeleteDocumentParams=} [properties] Properties to set
                     */
                    function DeleteDocumentParams(properties) {
                        this.ids = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DeleteDocumentParams indexSpec.
                     * @member {theplant.ec.service.search.IIndexSpec|null|undefined} indexSpec
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @instance
                     */
                    DeleteDocumentParams.prototype.indexSpec = null;

                    /**
                     * DeleteDocumentParams ids.
                     * @member {Array.<string>} ids
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @instance
                     */
                    DeleteDocumentParams.prototype.ids = $util.emptyArray;

                    /**
                     * DeleteDocumentParams freshness.
                     * @member {theplant.ec.service.search.IndexFreshness} freshness
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @instance
                     */
                    DeleteDocumentParams.prototype.freshness = 0;

                    /**
                     * Creates a new DeleteDocumentParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IDeleteDocumentParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.DeleteDocumentParams} DeleteDocumentParams instance
                     */
                    DeleteDocumentParams.create = function create(properties) {
                        return new DeleteDocumentParams(properties);
                    };

                    /**
                     * Encodes the specified DeleteDocumentParams message. Does not implicitly {@link theplant.ec.service.search.DeleteDocumentParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IDeleteDocumentParams} message DeleteDocumentParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDocumentParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            $root.theplant.ec.service.search.IndexSpec.encode(message.indexSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.ids != null && message.ids.length)
                            for (var i = 0; i < message.ids.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ids[i]);
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.freshness);
                        return writer;
                    };

                    /**
                     * Encodes the specified DeleteDocumentParams message, length delimited. Does not implicitly {@link theplant.ec.service.search.DeleteDocumentParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.IDeleteDocumentParams} message DeleteDocumentParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DeleteDocumentParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DeleteDocumentParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.DeleteDocumentParams} DeleteDocumentParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDocumentParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.DeleteDocumentParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.indexSpec = $root.theplant.ec.service.search.IndexSpec.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.ids && message.ids.length))
                                    message.ids = [];
                                message.ids.push(reader.string());
                                break;
                            case 3:
                                message.freshness = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DeleteDocumentParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.DeleteDocumentParams} DeleteDocumentParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DeleteDocumentParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DeleteDocumentParams message.
                     * @function verify
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DeleteDocumentParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec")) {
                            var error = $root.theplant.ec.service.search.IndexSpec.verify(message.indexSpec);
                            if (error)
                                return "indexSpec." + error;
                        }
                        if (message.ids != null && message.hasOwnProperty("ids")) {
                            if (!Array.isArray(message.ids))
                                return "ids: array expected";
                            for (var i = 0; i < message.ids.length; ++i)
                                if (!$util.isString(message.ids[i]))
                                    return "ids: string[] expected";
                        }
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            switch (message.freshness) {
                            default:
                                return "freshness: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a DeleteDocumentParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.DeleteDocumentParams} DeleteDocumentParams
                     */
                    DeleteDocumentParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.DeleteDocumentParams)
                            return object;
                        var message = new $root.theplant.ec.service.search.DeleteDocumentParams();
                        if (object.indexSpec != null) {
                            if (typeof object.indexSpec !== "object")
                                throw TypeError(".theplant.ec.service.search.DeleteDocumentParams.indexSpec: object expected");
                            message.indexSpec = $root.theplant.ec.service.search.IndexSpec.fromObject(object.indexSpec);
                        }
                        if (object.ids) {
                            if (!Array.isArray(object.ids))
                                throw TypeError(".theplant.ec.service.search.DeleteDocumentParams.ids: array expected");
                            message.ids = [];
                            for (var i = 0; i < object.ids.length; ++i)
                                message.ids[i] = String(object.ids[i]);
                        }
                        switch (object.freshness) {
                        case "WHEN_CONVENIENT":
                        case 0:
                            message.freshness = 0;
                            break;
                        case "SYNCHRONOUSLY":
                        case 1:
                            message.freshness = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DeleteDocumentParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @static
                     * @param {theplant.ec.service.search.DeleteDocumentParams} message DeleteDocumentParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DeleteDocumentParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.ids = [];
                        if (options.defaults) {
                            object.indexSpec = null;
                            object.freshness = options.enums === String ? "WHEN_CONVENIENT" : 0;
                        }
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            object.indexSpec = $root.theplant.ec.service.search.IndexSpec.toObject(message.indexSpec, options);
                        if (message.ids && message.ids.length) {
                            object.ids = [];
                            for (var j = 0; j < message.ids.length; ++j)
                                object.ids[j] = message.ids[j];
                        }
                        if (message.freshness != null && message.hasOwnProperty("freshness"))
                            object.freshness = options.enums === String ? $root.theplant.ec.service.search.IndexFreshness[message.freshness] : message.freshness;
                        return object;
                    };

                    /**
                     * Converts this DeleteDocumentParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.DeleteDocumentParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DeleteDocumentParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DeleteDocumentParams;
                })();

                search.IndexSpec = (function() {

                    /**
                     * Properties of an IndexSpec.
                     * @memberof theplant.ec.service.search
                     * @interface IIndexSpec
                     * @property {string|null} [namespace] IndexSpec namespace
                     * @property {string|null} [name] IndexSpec name
                     * @property {theplant.ec.service.search.IndexSpec.Mode|null} [mode] IndexSpec mode
                     */

                    /**
                     * Constructs a new IndexSpec.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents an IndexSpec.
                     * @implements IIndexSpec
                     * @constructor
                     * @param {theplant.ec.service.search.IIndexSpec=} [properties] Properties to set
                     */
                    function IndexSpec(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * IndexSpec namespace.
                     * @member {string} namespace
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @instance
                     */
                    IndexSpec.prototype.namespace = "";

                    /**
                     * IndexSpec name.
                     * @member {string} name
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @instance
                     */
                    IndexSpec.prototype.name = "";

                    /**
                     * IndexSpec mode.
                     * @member {theplant.ec.service.search.IndexSpec.Mode} mode
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @instance
                     */
                    IndexSpec.prototype.mode = 0;

                    /**
                     * Creates a new IndexSpec instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {theplant.ec.service.search.IIndexSpec=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.IndexSpec} IndexSpec instance
                     */
                    IndexSpec.create = function create(properties) {
                        return new IndexSpec(properties);
                    };

                    /**
                     * Encodes the specified IndexSpec message. Does not implicitly {@link theplant.ec.service.search.IndexSpec.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {theplant.ec.service.search.IIndexSpec} message IndexSpec message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IndexSpec.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.namespace != null && message.hasOwnProperty("namespace"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.namespace);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.mode);
                        return writer;
                    };

                    /**
                     * Encodes the specified IndexSpec message, length delimited. Does not implicitly {@link theplant.ec.service.search.IndexSpec.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {theplant.ec.service.search.IIndexSpec} message IndexSpec message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    IndexSpec.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an IndexSpec message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.IndexSpec} IndexSpec
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IndexSpec.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.IndexSpec();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.namespace = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.mode = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an IndexSpec message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.IndexSpec} IndexSpec
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    IndexSpec.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an IndexSpec message.
                     * @function verify
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    IndexSpec.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.namespace != null && message.hasOwnProperty("namespace"))
                            if (!$util.isString(message.namespace))
                                return "namespace: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            switch (message.mode) {
                            default:
                                return "mode: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates an IndexSpec message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.IndexSpec} IndexSpec
                     */
                    IndexSpec.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.IndexSpec)
                            return object;
                        var message = new $root.theplant.ec.service.search.IndexSpec();
                        if (object.namespace != null)
                            message.namespace = String(object.namespace);
                        if (object.name != null)
                            message.name = String(object.name);
                        switch (object.mode) {
                        case "PRIORITY":
                        case 0:
                            message.mode = 0;
                            break;
                        case "BACKGROUND":
                        case 1:
                            message.mode = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an IndexSpec message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @static
                     * @param {theplant.ec.service.search.IndexSpec} message IndexSpec
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    IndexSpec.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.namespace = "";
                            object.name = "";
                            object.mode = options.enums === String ? "PRIORITY" : 0;
                        }
                        if (message.namespace != null && message.hasOwnProperty("namespace"))
                            object.namespace = message.namespace;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            object.mode = options.enums === String ? $root.theplant.ec.service.search.IndexSpec.Mode[message.mode] : message.mode;
                        return object;
                    };

                    /**
                     * Converts this IndexSpec to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.IndexSpec
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    IndexSpec.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Mode enum.
                     * @name theplant.ec.service.search.IndexSpec.Mode
                     * @enum {string}
                     * @property {number} PRIORITY=0 PRIORITY value
                     * @property {number} BACKGROUND=1 BACKGROUND value
                     */
                    IndexSpec.Mode = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "PRIORITY"] = 0;
                        values[valuesById[1] = "BACKGROUND"] = 1;
                        return values;
                    })();

                    return IndexSpec;
                })();

                search.Document = (function() {

                    /**
                     * Properties of a Document.
                     * @memberof theplant.ec.service.search
                     * @interface IDocument
                     * @property {string|null} [id] Document id
                     * @property {Array.<theplant.ec.service.search.IField>|null} [fields] Document fields
                     * @property {Array.<theplant.ec.service.search.ISubDocument>|null} [subDocs] Document subDocs
                     */

                    /**
                     * Constructs a new Document.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a Document.
                     * @implements IDocument
                     * @constructor
                     * @param {theplant.ec.service.search.IDocument=} [properties] Properties to set
                     */
                    function Document(properties) {
                        this.fields = [];
                        this.subDocs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Document id.
                     * @member {string} id
                     * @memberof theplant.ec.service.search.Document
                     * @instance
                     */
                    Document.prototype.id = "";

                    /**
                     * Document fields.
                     * @member {Array.<theplant.ec.service.search.IField>} fields
                     * @memberof theplant.ec.service.search.Document
                     * @instance
                     */
                    Document.prototype.fields = $util.emptyArray;

                    /**
                     * Document subDocs.
                     * @member {Array.<theplant.ec.service.search.ISubDocument>} subDocs
                     * @memberof theplant.ec.service.search.Document
                     * @instance
                     */
                    Document.prototype.subDocs = $util.emptyArray;

                    /**
                     * Creates a new Document instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {theplant.ec.service.search.IDocument=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.Document} Document instance
                     */
                    Document.create = function create(properties) {
                        return new Document(properties);
                    };

                    /**
                     * Encodes the specified Document message. Does not implicitly {@link theplant.ec.service.search.Document.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {theplant.ec.service.search.IDocument} message Document message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Document.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.fields != null && message.fields.length)
                            for (var i = 0; i < message.fields.length; ++i)
                                $root.theplant.ec.service.search.Field.encode(message.fields[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.subDocs != null && message.subDocs.length)
                            for (var i = 0; i < message.subDocs.length; ++i)
                                $root.theplant.ec.service.search.SubDocument.encode(message.subDocs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Document message, length delimited. Does not implicitly {@link theplant.ec.service.search.Document.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {theplant.ec.service.search.IDocument} message Document message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Document.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Document message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.Document} Document
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Document.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.Document();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                if (!(message.fields && message.fields.length))
                                    message.fields = [];
                                message.fields.push($root.theplant.ec.service.search.Field.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.subDocs && message.subDocs.length))
                                    message.subDocs = [];
                                message.subDocs.push($root.theplant.ec.service.search.SubDocument.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Document message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.Document} Document
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Document.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Document message.
                     * @function verify
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Document.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.fields != null && message.hasOwnProperty("fields")) {
                            if (!Array.isArray(message.fields))
                                return "fields: array expected";
                            for (var i = 0; i < message.fields.length; ++i) {
                                var error = $root.theplant.ec.service.search.Field.verify(message.fields[i]);
                                if (error)
                                    return "fields." + error;
                            }
                        }
                        if (message.subDocs != null && message.hasOwnProperty("subDocs")) {
                            if (!Array.isArray(message.subDocs))
                                return "subDocs: array expected";
                            for (var i = 0; i < message.subDocs.length; ++i) {
                                var error = $root.theplant.ec.service.search.SubDocument.verify(message.subDocs[i]);
                                if (error)
                                    return "subDocs." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Document message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.Document} Document
                     */
                    Document.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.Document)
                            return object;
                        var message = new $root.theplant.ec.service.search.Document();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.fields) {
                            if (!Array.isArray(object.fields))
                                throw TypeError(".theplant.ec.service.search.Document.fields: array expected");
                            message.fields = [];
                            for (var i = 0; i < object.fields.length; ++i) {
                                if (typeof object.fields[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.Document.fields: object expected");
                                message.fields[i] = $root.theplant.ec.service.search.Field.fromObject(object.fields[i]);
                            }
                        }
                        if (object.subDocs) {
                            if (!Array.isArray(object.subDocs))
                                throw TypeError(".theplant.ec.service.search.Document.subDocs: array expected");
                            message.subDocs = [];
                            for (var i = 0; i < object.subDocs.length; ++i) {
                                if (typeof object.subDocs[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.Document.subDocs: object expected");
                                message.subDocs[i] = $root.theplant.ec.service.search.SubDocument.fromObject(object.subDocs[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Document message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.Document
                     * @static
                     * @param {theplant.ec.service.search.Document} message Document
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Document.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.fields = [];
                            object.subDocs = [];
                        }
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.fields && message.fields.length) {
                            object.fields = [];
                            for (var j = 0; j < message.fields.length; ++j)
                                object.fields[j] = $root.theplant.ec.service.search.Field.toObject(message.fields[j], options);
                        }
                        if (message.subDocs && message.subDocs.length) {
                            object.subDocs = [];
                            for (var j = 0; j < message.subDocs.length; ++j)
                                object.subDocs[j] = $root.theplant.ec.service.search.SubDocument.toObject(message.subDocs[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Document to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.Document
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Document.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Document;
                })();

                search.SubDocument = (function() {

                    /**
                     * Properties of a SubDocument.
                     * @memberof theplant.ec.service.search
                     * @interface ISubDocument
                     * @property {string|null} [id] SubDocument id
                     * @property {Array.<theplant.ec.service.search.IField>|null} [fields] SubDocument fields
                     */

                    /**
                     * Constructs a new SubDocument.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SubDocument.
                     * @implements ISubDocument
                     * @constructor
                     * @param {theplant.ec.service.search.ISubDocument=} [properties] Properties to set
                     */
                    function SubDocument(properties) {
                        this.fields = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SubDocument id.
                     * @member {string} id
                     * @memberof theplant.ec.service.search.SubDocument
                     * @instance
                     */
                    SubDocument.prototype.id = "";

                    /**
                     * SubDocument fields.
                     * @member {Array.<theplant.ec.service.search.IField>} fields
                     * @memberof theplant.ec.service.search.SubDocument
                     * @instance
                     */
                    SubDocument.prototype.fields = $util.emptyArray;

                    /**
                     * Creates a new SubDocument instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {theplant.ec.service.search.ISubDocument=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SubDocument} SubDocument instance
                     */
                    SubDocument.create = function create(properties) {
                        return new SubDocument(properties);
                    };

                    /**
                     * Encodes the specified SubDocument message. Does not implicitly {@link theplant.ec.service.search.SubDocument.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {theplant.ec.service.search.ISubDocument} message SubDocument message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubDocument.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.fields != null && message.fields.length)
                            for (var i = 0; i < message.fields.length; ++i)
                                $root.theplant.ec.service.search.Field.encode(message.fields[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SubDocument message, length delimited. Does not implicitly {@link theplant.ec.service.search.SubDocument.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {theplant.ec.service.search.ISubDocument} message SubDocument message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubDocument.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SubDocument message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SubDocument} SubDocument
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubDocument.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SubDocument();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                if (!(message.fields && message.fields.length))
                                    message.fields = [];
                                message.fields.push($root.theplant.ec.service.search.Field.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SubDocument message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SubDocument} SubDocument
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubDocument.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SubDocument message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SubDocument.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.fields != null && message.hasOwnProperty("fields")) {
                            if (!Array.isArray(message.fields))
                                return "fields: array expected";
                            for (var i = 0; i < message.fields.length; ++i) {
                                var error = $root.theplant.ec.service.search.Field.verify(message.fields[i]);
                                if (error)
                                    return "fields." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SubDocument message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SubDocument} SubDocument
                     */
                    SubDocument.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SubDocument)
                            return object;
                        var message = new $root.theplant.ec.service.search.SubDocument();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.fields) {
                            if (!Array.isArray(object.fields))
                                throw TypeError(".theplant.ec.service.search.SubDocument.fields: array expected");
                            message.fields = [];
                            for (var i = 0; i < object.fields.length; ++i) {
                                if (typeof object.fields[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SubDocument.fields: object expected");
                                message.fields[i] = $root.theplant.ec.service.search.Field.fromObject(object.fields[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SubDocument message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SubDocument
                     * @static
                     * @param {theplant.ec.service.search.SubDocument} message SubDocument
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SubDocument.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.fields = [];
                        if (options.defaults)
                            object.id = "";
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.fields && message.fields.length) {
                            object.fields = [];
                            for (var j = 0; j < message.fields.length; ++j)
                                object.fields[j] = $root.theplant.ec.service.search.Field.toObject(message.fields[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SubDocument to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SubDocument
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SubDocument.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SubDocument;
                })();

                search.Field = (function() {

                    /**
                     * Properties of a Field.
                     * @memberof theplant.ec.service.search
                     * @interface IField
                     * @property {string|null} [name] Field name
                     * @property {Array.<string>|null} [values] Field values
                     */

                    /**
                     * Constructs a new Field.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a Field.
                     * @implements IField
                     * @constructor
                     * @param {theplant.ec.service.search.IField=} [properties] Properties to set
                     */
                    function Field(properties) {
                        this.values = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Field name.
                     * @member {string} name
                     * @memberof theplant.ec.service.search.Field
                     * @instance
                     */
                    Field.prototype.name = "";

                    /**
                     * Field values.
                     * @member {Array.<string>} values
                     * @memberof theplant.ec.service.search.Field
                     * @instance
                     */
                    Field.prototype.values = $util.emptyArray;

                    /**
                     * Creates a new Field instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {theplant.ec.service.search.IField=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.Field} Field instance
                     */
                    Field.create = function create(properties) {
                        return new Field(properties);
                    };

                    /**
                     * Encodes the specified Field message. Does not implicitly {@link theplant.ec.service.search.Field.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {theplant.ec.service.search.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.values != null && message.values.length)
                            for (var i = 0; i < message.values.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.values[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified Field message, length delimited. Does not implicitly {@link theplant.ec.service.search.Field.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {theplant.ec.service.search.IField} message Field message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Field.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.Field();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                message.name = reader.string();
                                break;
                            case 4:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Field message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.Field} Field
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Field.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Field message.
                     * @function verify
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Field.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i)
                                if (!$util.isString(message.values[i]))
                                    return "values: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a Field message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.Field} Field
                     */
                    Field.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.Field)
                            return object;
                        var message = new $root.theplant.ec.service.search.Field();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".theplant.ec.service.search.Field.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i)
                                message.values[i] = String(object.values[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Field message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.Field
                     * @static
                     * @param {theplant.ec.service.search.Field} message Field
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Field.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.values = [];
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = message.values[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this Field to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.Field
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Field.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Field;
                })();

                search.SearchParams = (function() {

                    /**
                     * Properties of a SearchParams.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchParams
                     * @property {theplant.ec.service.search.IIndexSpec|null} [indexSpec] SearchParams indexSpec
                     * @property {string|null} [query] SearchParams query
                     * @property {number|null} [offset] SearchParams offset
                     * @property {number|null} [limit] SearchParams limit
                     * @property {boolean|null} [idsOnly] SearchParams idsOnly
                     * @property {Array.<theplant.ec.service.search.ISearchField>|null} [fields] SearchParams fields
                     * @property {Array.<theplant.ec.service.search.IFieldRefinement>|null} [fieldRefinement] SearchParams fieldRefinement
                     * @property {theplant.ec.service.search.SearchParams.FacetMode|null} [facetMode] SearchParams facetMode
                     * @property {Array.<theplant.ec.service.search.IFacet>|null} [facets] SearchParams facets
                     * @property {Array.<theplant.ec.service.search.ISortSpec>|null} [sortSpec] SearchParams sortSpec
                     * @property {Array.<theplant.ec.service.search.ISearchScorer>|null} [scorers] SearchParams scorers
                     */

                    /**
                     * Constructs a new SearchParams.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchParams.
                     * @implements ISearchParams
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchParams=} [properties] Properties to set
                     */
                    function SearchParams(properties) {
                        this.fields = [];
                        this.fieldRefinement = [];
                        this.facets = [];
                        this.sortSpec = [];
                        this.scorers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchParams indexSpec.
                     * @member {theplant.ec.service.search.IIndexSpec|null|undefined} indexSpec
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.indexSpec = null;

                    /**
                     * SearchParams query.
                     * @member {string} query
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.query = "";

                    /**
                     * SearchParams offset.
                     * @member {number} offset
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.offset = 0;

                    /**
                     * SearchParams limit.
                     * @member {number} limit
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.limit = 0;

                    /**
                     * SearchParams idsOnly.
                     * @member {boolean} idsOnly
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.idsOnly = false;

                    /**
                     * SearchParams fields.
                     * @member {Array.<theplant.ec.service.search.ISearchField>} fields
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.fields = $util.emptyArray;

                    /**
                     * SearchParams fieldRefinement.
                     * @member {Array.<theplant.ec.service.search.IFieldRefinement>} fieldRefinement
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.fieldRefinement = $util.emptyArray;

                    /**
                     * SearchParams facetMode.
                     * @member {theplant.ec.service.search.SearchParams.FacetMode} facetMode
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.facetMode = 0;

                    /**
                     * SearchParams facets.
                     * @member {Array.<theplant.ec.service.search.IFacet>} facets
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.facets = $util.emptyArray;

                    /**
                     * SearchParams sortSpec.
                     * @member {Array.<theplant.ec.service.search.ISortSpec>} sortSpec
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.sortSpec = $util.emptyArray;

                    /**
                     * SearchParams scorers.
                     * @member {Array.<theplant.ec.service.search.ISearchScorer>} scorers
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     */
                    SearchParams.prototype.scorers = $util.emptyArray;

                    /**
                     * Creates a new SearchParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {theplant.ec.service.search.ISearchParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchParams} SearchParams instance
                     */
                    SearchParams.create = function create(properties) {
                        return new SearchParams(properties);
                    };

                    /**
                     * Encodes the specified SearchParams message. Does not implicitly {@link theplant.ec.service.search.SearchParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {theplant.ec.service.search.ISearchParams} message SearchParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            $root.theplant.ec.service.search.IndexSpec.encode(message.indexSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.query != null && message.hasOwnProperty("query"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
                        if (message.offset != null && message.hasOwnProperty("offset"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.offset);
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.limit);
                        if (message.idsOnly != null && message.hasOwnProperty("idsOnly"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.idsOnly);
                        if (message.fields != null && message.fields.length)
                            for (var i = 0; i < message.fields.length; ++i)
                                $root.theplant.ec.service.search.SearchField.encode(message.fields[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.facetMode != null && message.hasOwnProperty("facetMode"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.facetMode);
                        if (message.facets != null && message.facets.length)
                            for (var i = 0; i < message.facets.length; ++i)
                                $root.theplant.ec.service.search.Facet.encode(message.facets[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.fieldRefinement != null && message.fieldRefinement.length)
                            for (var i = 0; i < message.fieldRefinement.length; ++i)
                                $root.theplant.ec.service.search.FieldRefinement.encode(message.fieldRefinement[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.sortSpec != null && message.sortSpec.length)
                            for (var i = 0; i < message.sortSpec.length; ++i)
                                $root.theplant.ec.service.search.SortSpec.encode(message.sortSpec[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.scorers != null && message.scorers.length)
                            for (var i = 0; i < message.scorers.length; ++i)
                                $root.theplant.ec.service.search.SearchScorer.encode(message.scorers[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchParams message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {theplant.ec.service.search.ISearchParams} message SearchParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchParams} SearchParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.indexSpec = $root.theplant.ec.service.search.IndexSpec.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.query = reader.string();
                                break;
                            case 3:
                                message.offset = reader.int32();
                                break;
                            case 4:
                                message.limit = reader.int32();
                                break;
                            case 5:
                                message.idsOnly = reader.bool();
                                break;
                            case 6:
                                if (!(message.fields && message.fields.length))
                                    message.fields = [];
                                message.fields.push($root.theplant.ec.service.search.SearchField.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                if (!(message.fieldRefinement && message.fieldRefinement.length))
                                    message.fieldRefinement = [];
                                message.fieldRefinement.push($root.theplant.ec.service.search.FieldRefinement.decode(reader, reader.uint32()));
                                break;
                            case 7:
                                message.facetMode = reader.int32();
                                break;
                            case 8:
                                if (!(message.facets && message.facets.length))
                                    message.facets = [];
                                message.facets.push($root.theplant.ec.service.search.Facet.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                if (!(message.sortSpec && message.sortSpec.length))
                                    message.sortSpec = [];
                                message.sortSpec.push($root.theplant.ec.service.search.SortSpec.decode(reader, reader.uint32()));
                                break;
                            case 11:
                                if (!(message.scorers && message.scorers.length))
                                    message.scorers = [];
                                message.scorers.push($root.theplant.ec.service.search.SearchScorer.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchParams} SearchParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchParams message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec")) {
                            var error = $root.theplant.ec.service.search.IndexSpec.verify(message.indexSpec);
                            if (error)
                                return "indexSpec." + error;
                        }
                        if (message.query != null && message.hasOwnProperty("query"))
                            if (!$util.isString(message.query))
                                return "query: string expected";
                        if (message.offset != null && message.hasOwnProperty("offset"))
                            if (!$util.isInteger(message.offset))
                                return "offset: integer expected";
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            if (!$util.isInteger(message.limit))
                                return "limit: integer expected";
                        if (message.idsOnly != null && message.hasOwnProperty("idsOnly"))
                            if (typeof message.idsOnly !== "boolean")
                                return "idsOnly: boolean expected";
                        if (message.fields != null && message.hasOwnProperty("fields")) {
                            if (!Array.isArray(message.fields))
                                return "fields: array expected";
                            for (var i = 0; i < message.fields.length; ++i) {
                                var error = $root.theplant.ec.service.search.SearchField.verify(message.fields[i]);
                                if (error)
                                    return "fields." + error;
                            }
                        }
                        if (message.fieldRefinement != null && message.hasOwnProperty("fieldRefinement")) {
                            if (!Array.isArray(message.fieldRefinement))
                                return "fieldRefinement: array expected";
                            for (var i = 0; i < message.fieldRefinement.length; ++i) {
                                var error = $root.theplant.ec.service.search.FieldRefinement.verify(message.fieldRefinement[i]);
                                if (error)
                                    return "fieldRefinement." + error;
                            }
                        }
                        if (message.facetMode != null && message.hasOwnProperty("facetMode"))
                            switch (message.facetMode) {
                            default:
                                return "facetMode: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.facets != null && message.hasOwnProperty("facets")) {
                            if (!Array.isArray(message.facets))
                                return "facets: array expected";
                            for (var i = 0; i < message.facets.length; ++i) {
                                var error = $root.theplant.ec.service.search.Facet.verify(message.facets[i]);
                                if (error)
                                    return "facets." + error;
                            }
                        }
                        if (message.sortSpec != null && message.hasOwnProperty("sortSpec")) {
                            if (!Array.isArray(message.sortSpec))
                                return "sortSpec: array expected";
                            for (var i = 0; i < message.sortSpec.length; ++i) {
                                var error = $root.theplant.ec.service.search.SortSpec.verify(message.sortSpec[i]);
                                if (error)
                                    return "sortSpec." + error;
                            }
                        }
                        if (message.scorers != null && message.hasOwnProperty("scorers")) {
                            if (!Array.isArray(message.scorers))
                                return "scorers: array expected";
                            for (var i = 0; i < message.scorers.length; ++i) {
                                var error = $root.theplant.ec.service.search.SearchScorer.verify(message.scorers[i]);
                                if (error)
                                    return "scorers." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchParams} SearchParams
                     */
                    SearchParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchParams)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchParams();
                        if (object.indexSpec != null) {
                            if (typeof object.indexSpec !== "object")
                                throw TypeError(".theplant.ec.service.search.SearchParams.indexSpec: object expected");
                            message.indexSpec = $root.theplant.ec.service.search.IndexSpec.fromObject(object.indexSpec);
                        }
                        if (object.query != null)
                            message.query = String(object.query);
                        if (object.offset != null)
                            message.offset = object.offset | 0;
                        if (object.limit != null)
                            message.limit = object.limit | 0;
                        if (object.idsOnly != null)
                            message.idsOnly = Boolean(object.idsOnly);
                        if (object.fields) {
                            if (!Array.isArray(object.fields))
                                throw TypeError(".theplant.ec.service.search.SearchParams.fields: array expected");
                            message.fields = [];
                            for (var i = 0; i < object.fields.length; ++i) {
                                if (typeof object.fields[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchParams.fields: object expected");
                                message.fields[i] = $root.theplant.ec.service.search.SearchField.fromObject(object.fields[i]);
                            }
                        }
                        if (object.fieldRefinement) {
                            if (!Array.isArray(object.fieldRefinement))
                                throw TypeError(".theplant.ec.service.search.SearchParams.fieldRefinement: array expected");
                            message.fieldRefinement = [];
                            for (var i = 0; i < object.fieldRefinement.length; ++i) {
                                if (typeof object.fieldRefinement[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchParams.fieldRefinement: object expected");
                                message.fieldRefinement[i] = $root.theplant.ec.service.search.FieldRefinement.fromObject(object.fieldRefinement[i]);
                            }
                        }
                        switch (object.facetMode) {
                        case "STRICT":
                        case 0:
                            message.facetMode = 0;
                            break;
                        case "COMPOSITE":
                        case 1:
                            message.facetMode = 1;
                            break;
                        }
                        if (object.facets) {
                            if (!Array.isArray(object.facets))
                                throw TypeError(".theplant.ec.service.search.SearchParams.facets: array expected");
                            message.facets = [];
                            for (var i = 0; i < object.facets.length; ++i) {
                                if (typeof object.facets[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchParams.facets: object expected");
                                message.facets[i] = $root.theplant.ec.service.search.Facet.fromObject(object.facets[i]);
                            }
                        }
                        if (object.sortSpec) {
                            if (!Array.isArray(object.sortSpec))
                                throw TypeError(".theplant.ec.service.search.SearchParams.sortSpec: array expected");
                            message.sortSpec = [];
                            for (var i = 0; i < object.sortSpec.length; ++i) {
                                if (typeof object.sortSpec[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchParams.sortSpec: object expected");
                                message.sortSpec[i] = $root.theplant.ec.service.search.SortSpec.fromObject(object.sortSpec[i]);
                            }
                        }
                        if (object.scorers) {
                            if (!Array.isArray(object.scorers))
                                throw TypeError(".theplant.ec.service.search.SearchParams.scorers: array expected");
                            message.scorers = [];
                            for (var i = 0; i < object.scorers.length; ++i) {
                                if (typeof object.scorers[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchParams.scorers: object expected");
                                message.scorers[i] = $root.theplant.ec.service.search.SearchScorer.fromObject(object.scorers[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchParams
                     * @static
                     * @param {theplant.ec.service.search.SearchParams} message SearchParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.fields = [];
                            object.facets = [];
                            object.fieldRefinement = [];
                            object.sortSpec = [];
                            object.scorers = [];
                        }
                        if (options.defaults) {
                            object.indexSpec = null;
                            object.query = "";
                            object.offset = 0;
                            object.limit = 0;
                            object.idsOnly = false;
                            object.facetMode = options.enums === String ? "STRICT" : 0;
                        }
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            object.indexSpec = $root.theplant.ec.service.search.IndexSpec.toObject(message.indexSpec, options);
                        if (message.query != null && message.hasOwnProperty("query"))
                            object.query = message.query;
                        if (message.offset != null && message.hasOwnProperty("offset"))
                            object.offset = message.offset;
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            object.limit = message.limit;
                        if (message.idsOnly != null && message.hasOwnProperty("idsOnly"))
                            object.idsOnly = message.idsOnly;
                        if (message.fields && message.fields.length) {
                            object.fields = [];
                            for (var j = 0; j < message.fields.length; ++j)
                                object.fields[j] = $root.theplant.ec.service.search.SearchField.toObject(message.fields[j], options);
                        }
                        if (message.facetMode != null && message.hasOwnProperty("facetMode"))
                            object.facetMode = options.enums === String ? $root.theplant.ec.service.search.SearchParams.FacetMode[message.facetMode] : message.facetMode;
                        if (message.facets && message.facets.length) {
                            object.facets = [];
                            for (var j = 0; j < message.facets.length; ++j)
                                object.facets[j] = $root.theplant.ec.service.search.Facet.toObject(message.facets[j], options);
                        }
                        if (message.fieldRefinement && message.fieldRefinement.length) {
                            object.fieldRefinement = [];
                            for (var j = 0; j < message.fieldRefinement.length; ++j)
                                object.fieldRefinement[j] = $root.theplant.ec.service.search.FieldRefinement.toObject(message.fieldRefinement[j], options);
                        }
                        if (message.sortSpec && message.sortSpec.length) {
                            object.sortSpec = [];
                            for (var j = 0; j < message.sortSpec.length; ++j)
                                object.sortSpec[j] = $root.theplant.ec.service.search.SortSpec.toObject(message.sortSpec[j], options);
                        }
                        if (message.scorers && message.scorers.length) {
                            object.scorers = [];
                            for (var j = 0; j < message.scorers.length; ++j)
                                object.scorers[j] = $root.theplant.ec.service.search.SearchScorer.toObject(message.scorers[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SearchParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * FacetMode enum.
                     * @name theplant.ec.service.search.SearchParams.FacetMode
                     * @enum {string}
                     * @property {number} STRICT=0 STRICT value
                     * @property {number} COMPOSITE=1 COMPOSITE value
                     */
                    SearchParams.FacetMode = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "STRICT"] = 0;
                        values[valuesById[1] = "COMPOSITE"] = 1;
                        return values;
                    })();

                    return SearchParams;
                })();

                search.AggregateDocumentCountParams = (function() {

                    /**
                     * Properties of an AggregateDocumentCountParams.
                     * @memberof theplant.ec.service.search
                     * @interface IAggregateDocumentCountParams
                     * @property {theplant.ec.service.search.IIndexSpec|null} [indexSpec] AggregateDocumentCountParams indexSpec
                     * @property {Array.<theplant.ec.service.search.IAggregateDocumentCountCondition>|null} [conds] AggregateDocumentCountParams conds
                     */

                    /**
                     * Constructs a new AggregateDocumentCountParams.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents an AggregateDocumentCountParams.
                     * @implements IAggregateDocumentCountParams
                     * @constructor
                     * @param {theplant.ec.service.search.IAggregateDocumentCountParams=} [properties] Properties to set
                     */
                    function AggregateDocumentCountParams(properties) {
                        this.conds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AggregateDocumentCountParams indexSpec.
                     * @member {theplant.ec.service.search.IIndexSpec|null|undefined} indexSpec
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @instance
                     */
                    AggregateDocumentCountParams.prototype.indexSpec = null;

                    /**
                     * AggregateDocumentCountParams conds.
                     * @member {Array.<theplant.ec.service.search.IAggregateDocumentCountCondition>} conds
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @instance
                     */
                    AggregateDocumentCountParams.prototype.conds = $util.emptyArray;

                    /**
                     * Creates a new AggregateDocumentCountParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {theplant.ec.service.search.IAggregateDocumentCountParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.AggregateDocumentCountParams} AggregateDocumentCountParams instance
                     */
                    AggregateDocumentCountParams.create = function create(properties) {
                        return new AggregateDocumentCountParams(properties);
                    };

                    /**
                     * Encodes the specified AggregateDocumentCountParams message. Does not implicitly {@link theplant.ec.service.search.AggregateDocumentCountParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {theplant.ec.service.search.IAggregateDocumentCountParams} message AggregateDocumentCountParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDocumentCountParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            $root.theplant.ec.service.search.IndexSpec.encode(message.indexSpec, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.conds != null && message.conds.length)
                            for (var i = 0; i < message.conds.length; ++i)
                                $root.theplant.ec.service.search.AggregateDocumentCountCondition.encode(message.conds[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AggregateDocumentCountParams message, length delimited. Does not implicitly {@link theplant.ec.service.search.AggregateDocumentCountParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {theplant.ec.service.search.IAggregateDocumentCountParams} message AggregateDocumentCountParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDocumentCountParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AggregateDocumentCountParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.AggregateDocumentCountParams} AggregateDocumentCountParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDocumentCountParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.AggregateDocumentCountParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.indexSpec = $root.theplant.ec.service.search.IndexSpec.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.conds && message.conds.length))
                                    message.conds = [];
                                message.conds.push($root.theplant.ec.service.search.AggregateDocumentCountCondition.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AggregateDocumentCountParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.AggregateDocumentCountParams} AggregateDocumentCountParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDocumentCountParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AggregateDocumentCountParams message.
                     * @function verify
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateDocumentCountParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec")) {
                            var error = $root.theplant.ec.service.search.IndexSpec.verify(message.indexSpec);
                            if (error)
                                return "indexSpec." + error;
                        }
                        if (message.conds != null && message.hasOwnProperty("conds")) {
                            if (!Array.isArray(message.conds))
                                return "conds: array expected";
                            for (var i = 0; i < message.conds.length; ++i) {
                                var error = $root.theplant.ec.service.search.AggregateDocumentCountCondition.verify(message.conds[i]);
                                if (error)
                                    return "conds." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an AggregateDocumentCountParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.AggregateDocumentCountParams} AggregateDocumentCountParams
                     */
                    AggregateDocumentCountParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.AggregateDocumentCountParams)
                            return object;
                        var message = new $root.theplant.ec.service.search.AggregateDocumentCountParams();
                        if (object.indexSpec != null) {
                            if (typeof object.indexSpec !== "object")
                                throw TypeError(".theplant.ec.service.search.AggregateDocumentCountParams.indexSpec: object expected");
                            message.indexSpec = $root.theplant.ec.service.search.IndexSpec.fromObject(object.indexSpec);
                        }
                        if (object.conds) {
                            if (!Array.isArray(object.conds))
                                throw TypeError(".theplant.ec.service.search.AggregateDocumentCountParams.conds: array expected");
                            message.conds = [];
                            for (var i = 0; i < object.conds.length; ++i) {
                                if (typeof object.conds[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.AggregateDocumentCountParams.conds: object expected");
                                message.conds[i] = $root.theplant.ec.service.search.AggregateDocumentCountCondition.fromObject(object.conds[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AggregateDocumentCountParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @static
                     * @param {theplant.ec.service.search.AggregateDocumentCountParams} message AggregateDocumentCountParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateDocumentCountParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.conds = [];
                        if (options.defaults)
                            object.indexSpec = null;
                        if (message.indexSpec != null && message.hasOwnProperty("indexSpec"))
                            object.indexSpec = $root.theplant.ec.service.search.IndexSpec.toObject(message.indexSpec, options);
                        if (message.conds && message.conds.length) {
                            object.conds = [];
                            for (var j = 0; j < message.conds.length; ++j)
                                object.conds[j] = $root.theplant.ec.service.search.AggregateDocumentCountCondition.toObject(message.conds[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this AggregateDocumentCountParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.AggregateDocumentCountParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateDocumentCountParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AggregateDocumentCountParams;
                })();

                search.AggregateDocumentCountCondition = (function() {

                    /**
                     * Properties of an AggregateDocumentCountCondition.
                     * @memberof theplant.ec.service.search
                     * @interface IAggregateDocumentCountCondition
                     * @property {string|null} [query] AggregateDocumentCountCondition query
                     * @property {Array.<theplant.ec.service.search.IFieldRefinement>|null} [fieldRefinement] AggregateDocumentCountCondition fieldRefinement
                     */

                    /**
                     * Constructs a new AggregateDocumentCountCondition.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents an AggregateDocumentCountCondition.
                     * @implements IAggregateDocumentCountCondition
                     * @constructor
                     * @param {theplant.ec.service.search.IAggregateDocumentCountCondition=} [properties] Properties to set
                     */
                    function AggregateDocumentCountCondition(properties) {
                        this.fieldRefinement = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AggregateDocumentCountCondition query.
                     * @member {string} query
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @instance
                     */
                    AggregateDocumentCountCondition.prototype.query = "";

                    /**
                     * AggregateDocumentCountCondition fieldRefinement.
                     * @member {Array.<theplant.ec.service.search.IFieldRefinement>} fieldRefinement
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @instance
                     */
                    AggregateDocumentCountCondition.prototype.fieldRefinement = $util.emptyArray;

                    /**
                     * Creates a new AggregateDocumentCountCondition instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {theplant.ec.service.search.IAggregateDocumentCountCondition=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.AggregateDocumentCountCondition} AggregateDocumentCountCondition instance
                     */
                    AggregateDocumentCountCondition.create = function create(properties) {
                        return new AggregateDocumentCountCondition(properties);
                    };

                    /**
                     * Encodes the specified AggregateDocumentCountCondition message. Does not implicitly {@link theplant.ec.service.search.AggregateDocumentCountCondition.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {theplant.ec.service.search.IAggregateDocumentCountCondition} message AggregateDocumentCountCondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDocumentCountCondition.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.query != null && message.hasOwnProperty("query"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.query);
                        if (message.fieldRefinement != null && message.fieldRefinement.length)
                            for (var i = 0; i < message.fieldRefinement.length; ++i)
                                $root.theplant.ec.service.search.FieldRefinement.encode(message.fieldRefinement[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AggregateDocumentCountCondition message, length delimited. Does not implicitly {@link theplant.ec.service.search.AggregateDocumentCountCondition.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {theplant.ec.service.search.IAggregateDocumentCountCondition} message AggregateDocumentCountCondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateDocumentCountCondition.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AggregateDocumentCountCondition message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.AggregateDocumentCountCondition} AggregateDocumentCountCondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDocumentCountCondition.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.AggregateDocumentCountCondition();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                message.query = reader.string();
                                break;
                            case 9:
                                if (!(message.fieldRefinement && message.fieldRefinement.length))
                                    message.fieldRefinement = [];
                                message.fieldRefinement.push($root.theplant.ec.service.search.FieldRefinement.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AggregateDocumentCountCondition message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.AggregateDocumentCountCondition} AggregateDocumentCountCondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateDocumentCountCondition.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AggregateDocumentCountCondition message.
                     * @function verify
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateDocumentCountCondition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.query != null && message.hasOwnProperty("query"))
                            if (!$util.isString(message.query))
                                return "query: string expected";
                        if (message.fieldRefinement != null && message.hasOwnProperty("fieldRefinement")) {
                            if (!Array.isArray(message.fieldRefinement))
                                return "fieldRefinement: array expected";
                            for (var i = 0; i < message.fieldRefinement.length; ++i) {
                                var error = $root.theplant.ec.service.search.FieldRefinement.verify(message.fieldRefinement[i]);
                                if (error)
                                    return "fieldRefinement." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an AggregateDocumentCountCondition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.AggregateDocumentCountCondition} AggregateDocumentCountCondition
                     */
                    AggregateDocumentCountCondition.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.AggregateDocumentCountCondition)
                            return object;
                        var message = new $root.theplant.ec.service.search.AggregateDocumentCountCondition();
                        if (object.query != null)
                            message.query = String(object.query);
                        if (object.fieldRefinement) {
                            if (!Array.isArray(object.fieldRefinement))
                                throw TypeError(".theplant.ec.service.search.AggregateDocumentCountCondition.fieldRefinement: array expected");
                            message.fieldRefinement = [];
                            for (var i = 0; i < object.fieldRefinement.length; ++i) {
                                if (typeof object.fieldRefinement[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.AggregateDocumentCountCondition.fieldRefinement: object expected");
                                message.fieldRefinement[i] = $root.theplant.ec.service.search.FieldRefinement.fromObject(object.fieldRefinement[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AggregateDocumentCountCondition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @static
                     * @param {theplant.ec.service.search.AggregateDocumentCountCondition} message AggregateDocumentCountCondition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateDocumentCountCondition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.fieldRefinement = [];
                        if (options.defaults)
                            object.query = "";
                        if (message.query != null && message.hasOwnProperty("query"))
                            object.query = message.query;
                        if (message.fieldRefinement && message.fieldRefinement.length) {
                            object.fieldRefinement = [];
                            for (var j = 0; j < message.fieldRefinement.length; ++j)
                                object.fieldRefinement[j] = $root.theplant.ec.service.search.FieldRefinement.toObject(message.fieldRefinement[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this AggregateDocumentCountCondition to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.AggregateDocumentCountCondition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateDocumentCountCondition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AggregateDocumentCountCondition;
                })();

                search.SearchScorer = (function() {

                    /**
                     * Properties of a SearchScorer.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchScorer
                     * @property {theplant.ec.service.search.SearchScorer.Mode|null} [mode] SearchScorer mode
                     * @property {theplant.ec.service.search.ISearchField|null} [field] SearchScorer field
                     * @property {Array.<string>|null} [values] SearchScorer values
                     * @property {theplant.ec.service.search.IFieldRange|null} [range] SearchScorer range
                     * @property {number|null} [weight] SearchScorer weight
                     * @property {theplant.ec.service.search.SearchScorer.Modifier|null} [modifier] SearchScorer modifier
                     * @property {number|null} [missing] SearchScorer missing
                     * @property {Array.<theplant.ec.service.search.IFieldRefinement>|null} [fieldRefinement] SearchScorer fieldRefinement
                     */

                    /**
                     * Constructs a new SearchScorer.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchScorer.
                     * @implements ISearchScorer
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchScorer=} [properties] Properties to set
                     */
                    function SearchScorer(properties) {
                        this.values = [];
                        this.fieldRefinement = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchScorer mode.
                     * @member {theplant.ec.service.search.SearchScorer.Mode} mode
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.mode = 0;

                    /**
                     * SearchScorer field.
                     * @member {theplant.ec.service.search.ISearchField|null|undefined} field
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.field = null;

                    /**
                     * SearchScorer values.
                     * @member {Array.<string>} values
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.values = $util.emptyArray;

                    /**
                     * SearchScorer range.
                     * @member {theplant.ec.service.search.IFieldRange|null|undefined} range
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.range = null;

                    /**
                     * SearchScorer weight.
                     * @member {number} weight
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.weight = 0;

                    /**
                     * SearchScorer modifier.
                     * @member {theplant.ec.service.search.SearchScorer.Modifier} modifier
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.modifier = 0;

                    /**
                     * SearchScorer missing.
                     * @member {number} missing
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.missing = 0;

                    /**
                     * SearchScorer fieldRefinement.
                     * @member {Array.<theplant.ec.service.search.IFieldRefinement>} fieldRefinement
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     */
                    SearchScorer.prototype.fieldRefinement = $util.emptyArray;

                    /**
                     * Creates a new SearchScorer instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {theplant.ec.service.search.ISearchScorer=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchScorer} SearchScorer instance
                     */
                    SearchScorer.create = function create(properties) {
                        return new SearchScorer(properties);
                    };

                    /**
                     * Encodes the specified SearchScorer message. Does not implicitly {@link theplant.ec.service.search.SearchScorer.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {theplant.ec.service.search.ISearchScorer} message SearchScorer message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchScorer.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.mode);
                        if (message.field != null && message.hasOwnProperty("field"))
                            $root.theplant.ec.service.search.SearchField.encode(message.field, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.values != null && message.values.length)
                            for (var i = 0; i < message.values.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.values[i]);
                        if (message.range != null && message.hasOwnProperty("range"))
                            $root.theplant.ec.service.search.FieldRange.encode(message.range, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.weight != null && message.hasOwnProperty("weight"))
                            writer.uint32(/* id 5, wireType 1 =*/41).double(message.weight);
                        if (message.modifier != null && message.hasOwnProperty("modifier"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.modifier);
                        if (message.missing != null && message.hasOwnProperty("missing"))
                            writer.uint32(/* id 7, wireType 1 =*/57).double(message.missing);
                        if (message.fieldRefinement != null && message.fieldRefinement.length)
                            for (var i = 0; i < message.fieldRefinement.length; ++i)
                                $root.theplant.ec.service.search.FieldRefinement.encode(message.fieldRefinement[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchScorer message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchScorer.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {theplant.ec.service.search.ISearchScorer} message SearchScorer message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchScorer.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchScorer message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchScorer} SearchScorer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchScorer.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchScorer();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.mode = reader.int32();
                                break;
                            case 2:
                                message.field = $root.theplant.ec.service.search.SearchField.decode(reader, reader.uint32());
                                break;
                            case 3:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            case 4:
                                message.range = $root.theplant.ec.service.search.FieldRange.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.weight = reader.double();
                                break;
                            case 6:
                                message.modifier = reader.int32();
                                break;
                            case 7:
                                message.missing = reader.double();
                                break;
                            case 8:
                                if (!(message.fieldRefinement && message.fieldRefinement.length))
                                    message.fieldRefinement = [];
                                message.fieldRefinement.push($root.theplant.ec.service.search.FieldRefinement.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchScorer message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchScorer} SearchScorer
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchScorer.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchScorer message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchScorer.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            switch (message.mode) {
                            default:
                                return "mode: enum value expected";
                            case 0:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.field != null && message.hasOwnProperty("field")) {
                            var error = $root.theplant.ec.service.search.SearchField.verify(message.field);
                            if (error)
                                return "field." + error;
                        }
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i)
                                if (!$util.isString(message.values[i]))
                                    return "values: string[] expected";
                        }
                        if (message.range != null && message.hasOwnProperty("range")) {
                            var error = $root.theplant.ec.service.search.FieldRange.verify(message.range);
                            if (error)
                                return "range." + error;
                        }
                        if (message.weight != null && message.hasOwnProperty("weight"))
                            if (typeof message.weight !== "number")
                                return "weight: number expected";
                        if (message.modifier != null && message.hasOwnProperty("modifier"))
                            switch (message.modifier) {
                            default:
                                return "modifier: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                                break;
                            }
                        if (message.missing != null && message.hasOwnProperty("missing"))
                            if (typeof message.missing !== "number")
                                return "missing: number expected";
                        if (message.fieldRefinement != null && message.hasOwnProperty("fieldRefinement")) {
                            if (!Array.isArray(message.fieldRefinement))
                                return "fieldRefinement: array expected";
                            for (var i = 0; i < message.fieldRefinement.length; ++i) {
                                var error = $root.theplant.ec.service.search.FieldRefinement.verify(message.fieldRefinement[i]);
                                if (error)
                                    return "fieldRefinement." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchScorer message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchScorer} SearchScorer
                     */
                    SearchScorer.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchScorer)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchScorer();
                        switch (object.mode) {
                        case "MATCHED":
                        case 0:
                            message.mode = 0;
                            break;
                        case "FACTOR":
                        case 2:
                            message.mode = 2;
                            break;
                        case "FieldRefinement":
                        case 3:
                            message.mode = 3;
                            break;
                        }
                        if (object.field != null) {
                            if (typeof object.field !== "object")
                                throw TypeError(".theplant.ec.service.search.SearchScorer.field: object expected");
                            message.field = $root.theplant.ec.service.search.SearchField.fromObject(object.field);
                        }
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".theplant.ec.service.search.SearchScorer.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i)
                                message.values[i] = String(object.values[i]);
                        }
                        if (object.range != null) {
                            if (typeof object.range !== "object")
                                throw TypeError(".theplant.ec.service.search.SearchScorer.range: object expected");
                            message.range = $root.theplant.ec.service.search.FieldRange.fromObject(object.range);
                        }
                        if (object.weight != null)
                            message.weight = Number(object.weight);
                        switch (object.modifier) {
                        case "NONE":
                        case 0:
                            message.modifier = 0;
                            break;
                        case "LOG":
                        case 1:
                            message.modifier = 1;
                            break;
                        case "LOG1P":
                        case 2:
                            message.modifier = 2;
                            break;
                        case "LOG2P":
                        case 3:
                            message.modifier = 3;
                            break;
                        case "LN":
                        case 4:
                            message.modifier = 4;
                            break;
                        case "LN1P":
                        case 5:
                            message.modifier = 5;
                            break;
                        case "LN2P":
                        case 6:
                            message.modifier = 6;
                            break;
                        case "SQUARE":
                        case 7:
                            message.modifier = 7;
                            break;
                        case "SQRT":
                        case 8:
                            message.modifier = 8;
                            break;
                        case "RECIPROCAL":
                        case 9:
                            message.modifier = 9;
                            break;
                        }
                        if (object.missing != null)
                            message.missing = Number(object.missing);
                        if (object.fieldRefinement) {
                            if (!Array.isArray(object.fieldRefinement))
                                throw TypeError(".theplant.ec.service.search.SearchScorer.fieldRefinement: array expected");
                            message.fieldRefinement = [];
                            for (var i = 0; i < object.fieldRefinement.length; ++i) {
                                if (typeof object.fieldRefinement[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchScorer.fieldRefinement: object expected");
                                message.fieldRefinement[i] = $root.theplant.ec.service.search.FieldRefinement.fromObject(object.fieldRefinement[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchScorer message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @static
                     * @param {theplant.ec.service.search.SearchScorer} message SearchScorer
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchScorer.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.values = [];
                            object.fieldRefinement = [];
                        }
                        if (options.defaults) {
                            object.mode = options.enums === String ? "MATCHED" : 0;
                            object.field = null;
                            object.range = null;
                            object.weight = 0;
                            object.modifier = options.enums === String ? "NONE" : 0;
                            object.missing = 0;
                        }
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            object.mode = options.enums === String ? $root.theplant.ec.service.search.SearchScorer.Mode[message.mode] : message.mode;
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = $root.theplant.ec.service.search.SearchField.toObject(message.field, options);
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = message.values[j];
                        }
                        if (message.range != null && message.hasOwnProperty("range"))
                            object.range = $root.theplant.ec.service.search.FieldRange.toObject(message.range, options);
                        if (message.weight != null && message.hasOwnProperty("weight"))
                            object.weight = options.json && !isFinite(message.weight) ? String(message.weight) : message.weight;
                        if (message.modifier != null && message.hasOwnProperty("modifier"))
                            object.modifier = options.enums === String ? $root.theplant.ec.service.search.SearchScorer.Modifier[message.modifier] : message.modifier;
                        if (message.missing != null && message.hasOwnProperty("missing"))
                            object.missing = options.json && !isFinite(message.missing) ? String(message.missing) : message.missing;
                        if (message.fieldRefinement && message.fieldRefinement.length) {
                            object.fieldRefinement = [];
                            for (var j = 0; j < message.fieldRefinement.length; ++j)
                                object.fieldRefinement[j] = $root.theplant.ec.service.search.FieldRefinement.toObject(message.fieldRefinement[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SearchScorer to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchScorer
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchScorer.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Mode enum.
                     * @name theplant.ec.service.search.SearchScorer.Mode
                     * @enum {string}
                     * @property {number} MATCHED=0 MATCHED value
                     * @property {number} FACTOR=2 FACTOR value
                     * @property {number} FieldRefinement=3 FieldRefinement value
                     */
                    SearchScorer.Mode = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "MATCHED"] = 0;
                        values[valuesById[2] = "FACTOR"] = 2;
                        values[valuesById[3] = "FieldRefinement"] = 3;
                        return values;
                    })();

                    /**
                     * Modifier enum.
                     * @name theplant.ec.service.search.SearchScorer.Modifier
                     * @enum {string}
                     * @property {number} NONE=0 NONE value
                     * @property {number} LOG=1 LOG value
                     * @property {number} LOG1P=2 LOG1P value
                     * @property {number} LOG2P=3 LOG2P value
                     * @property {number} LN=4 LN value
                     * @property {number} LN1P=5 LN1P value
                     * @property {number} LN2P=6 LN2P value
                     * @property {number} SQUARE=7 SQUARE value
                     * @property {number} SQRT=8 SQRT value
                     * @property {number} RECIPROCAL=9 RECIPROCAL value
                     */
                    SearchScorer.Modifier = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "NONE"] = 0;
                        values[valuesById[1] = "LOG"] = 1;
                        values[valuesById[2] = "LOG1P"] = 2;
                        values[valuesById[3] = "LOG2P"] = 3;
                        values[valuesById[4] = "LN"] = 4;
                        values[valuesById[5] = "LN1P"] = 5;
                        values[valuesById[6] = "LN2P"] = 6;
                        values[valuesById[7] = "SQUARE"] = 7;
                        values[valuesById[8] = "SQRT"] = 8;
                        values[valuesById[9] = "RECIPROCAL"] = 9;
                        return values;
                    })();

                    return SearchScorer;
                })();

                /**
                 * SortSpec_Mode enum.
                 * @name theplant.ec.service.search.SortSpec_Mode
                 * @enum {string}
                 * @property {number} NONE=0 NONE value
                 * @property {number} MIN=1 MIN value
                 * @property {number} MAX=2 MAX value
                 * @property {number} SUM=3 SUM value
                 * @property {number} AVG=4 AVG value
                 * @property {number} MEDIAN=5 MEDIAN value
                 */
                search.SortSpec_Mode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NONE"] = 0;
                    values[valuesById[1] = "MIN"] = 1;
                    values[valuesById[2] = "MAX"] = 2;
                    values[valuesById[3] = "SUM"] = 3;
                    values[valuesById[4] = "AVG"] = 4;
                    values[valuesById[5] = "MEDIAN"] = 5;
                    return values;
                })();

                search.SortSpec = (function() {

                    /**
                     * Properties of a SortSpec.
                     * @memberof theplant.ec.service.search
                     * @interface ISortSpec
                     * @property {theplant.ec.service.search.ISearchField|null} [field] SortSpec field
                     * @property {boolean|null} [sortDescending] SortSpec sortDescending
                     * @property {theplant.ec.service.search.SortSpec_Mode|null} [mode] SortSpec mode
                     */

                    /**
                     * Constructs a new SortSpec.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SortSpec.
                     * @implements ISortSpec
                     * @constructor
                     * @param {theplant.ec.service.search.ISortSpec=} [properties] Properties to set
                     */
                    function SortSpec(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SortSpec field.
                     * @member {theplant.ec.service.search.ISearchField|null|undefined} field
                     * @memberof theplant.ec.service.search.SortSpec
                     * @instance
                     */
                    SortSpec.prototype.field = null;

                    /**
                     * SortSpec sortDescending.
                     * @member {boolean} sortDescending
                     * @memberof theplant.ec.service.search.SortSpec
                     * @instance
                     */
                    SortSpec.prototype.sortDescending = false;

                    /**
                     * SortSpec mode.
                     * @member {theplant.ec.service.search.SortSpec_Mode} mode
                     * @memberof theplant.ec.service.search.SortSpec
                     * @instance
                     */
                    SortSpec.prototype.mode = 0;

                    /**
                     * Creates a new SortSpec instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {theplant.ec.service.search.ISortSpec=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SortSpec} SortSpec instance
                     */
                    SortSpec.create = function create(properties) {
                        return new SortSpec(properties);
                    };

                    /**
                     * Encodes the specified SortSpec message. Does not implicitly {@link theplant.ec.service.search.SortSpec.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {theplant.ec.service.search.ISortSpec} message SortSpec message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SortSpec.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            $root.theplant.ec.service.search.SearchField.encode(message.field, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.sortDescending != null && message.hasOwnProperty("sortDescending"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.sortDescending);
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.mode);
                        return writer;
                    };

                    /**
                     * Encodes the specified SortSpec message, length delimited. Does not implicitly {@link theplant.ec.service.search.SortSpec.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {theplant.ec.service.search.ISortSpec} message SortSpec message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SortSpec.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SortSpec message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SortSpec} SortSpec
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SortSpec.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SortSpec();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = $root.theplant.ec.service.search.SearchField.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.sortDescending = reader.bool();
                                break;
                            case 3:
                                message.mode = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SortSpec message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SortSpec} SortSpec
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SortSpec.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SortSpec message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SortSpec.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field")) {
                            var error = $root.theplant.ec.service.search.SearchField.verify(message.field);
                            if (error)
                                return "field." + error;
                        }
                        if (message.sortDescending != null && message.hasOwnProperty("sortDescending"))
                            if (typeof message.sortDescending !== "boolean")
                                return "sortDescending: boolean expected";
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            switch (message.mode) {
                            default:
                                return "mode: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a SortSpec message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SortSpec} SortSpec
                     */
                    SortSpec.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SortSpec)
                            return object;
                        var message = new $root.theplant.ec.service.search.SortSpec();
                        if (object.field != null) {
                            if (typeof object.field !== "object")
                                throw TypeError(".theplant.ec.service.search.SortSpec.field: object expected");
                            message.field = $root.theplant.ec.service.search.SearchField.fromObject(object.field);
                        }
                        if (object.sortDescending != null)
                            message.sortDescending = Boolean(object.sortDescending);
                        switch (object.mode) {
                        case "NONE":
                        case 0:
                            message.mode = 0;
                            break;
                        case "MIN":
                        case 1:
                            message.mode = 1;
                            break;
                        case "MAX":
                        case 2:
                            message.mode = 2;
                            break;
                        case "SUM":
                        case 3:
                            message.mode = 3;
                            break;
                        case "AVG":
                        case 4:
                            message.mode = 4;
                            break;
                        case "MEDIAN":
                        case 5:
                            message.mode = 5;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SortSpec message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SortSpec
                     * @static
                     * @param {theplant.ec.service.search.SortSpec} message SortSpec
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SortSpec.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.field = null;
                            object.sortDescending = false;
                            object.mode = options.enums === String ? "NONE" : 0;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = $root.theplant.ec.service.search.SearchField.toObject(message.field, options);
                        if (message.sortDescending != null && message.hasOwnProperty("sortDescending"))
                            object.sortDescending = message.sortDescending;
                        if (message.mode != null && message.hasOwnProperty("mode"))
                            object.mode = options.enums === String ? $root.theplant.ec.service.search.SortSpec_Mode[message.mode] : message.mode;
                        return object;
                    };

                    /**
                     * Converts this SortSpec to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SortSpec
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SortSpec.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SortSpec;
                })();

                search.SearchField = (function() {

                    /**
                     * Properties of a SearchField.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchField
                     * @property {string|null} [name] SearchField name
                     * @property {boolean|null} [isSub] SearchField isSub
                     */

                    /**
                     * Constructs a new SearchField.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchField.
                     * @implements ISearchField
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchField=} [properties] Properties to set
                     */
                    function SearchField(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchField name.
                     * @member {string} name
                     * @memberof theplant.ec.service.search.SearchField
                     * @instance
                     */
                    SearchField.prototype.name = "";

                    /**
                     * SearchField isSub.
                     * @member {boolean} isSub
                     * @memberof theplant.ec.service.search.SearchField
                     * @instance
                     */
                    SearchField.prototype.isSub = false;

                    /**
                     * Creates a new SearchField instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {theplant.ec.service.search.ISearchField=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchField} SearchField instance
                     */
                    SearchField.create = function create(properties) {
                        return new SearchField(properties);
                    };

                    /**
                     * Encodes the specified SearchField message. Does not implicitly {@link theplant.ec.service.search.SearchField.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {theplant.ec.service.search.ISearchField} message SearchField message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchField.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.isSub != null && message.hasOwnProperty("isSub"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isSub);
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchField message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchField.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {theplant.ec.service.search.ISearchField} message SearchField message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchField.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchField message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchField} SearchField
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchField.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchField();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.isSub = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchField message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchField} SearchField
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchField.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchField message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchField.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.isSub != null && message.hasOwnProperty("isSub"))
                            if (typeof message.isSub !== "boolean")
                                return "isSub: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a SearchField message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchField} SearchField
                     */
                    SearchField.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchField)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchField();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.isSub != null)
                            message.isSub = Boolean(object.isSub);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchField message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchField
                     * @static
                     * @param {theplant.ec.service.search.SearchField} message SearchField
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchField.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.isSub = false;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.isSub != null && message.hasOwnProperty("isSub"))
                            object.isSub = message.isSub;
                        return object;
                    };

                    /**
                     * Converts this SearchField to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchField
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchField.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchField;
                })();

                search.Facet = (function() {

                    /**
                     * Properties of a Facet.
                     * @memberof theplant.ec.service.search
                     * @interface IFacet
                     * @property {theplant.ec.service.search.ISearchField|null} [field] Facet field
                     * @property {number|null} [size] Facet size
                     * @property {Array.<string>|null} [includeValues] Facet includeValues
                     * @property {Array.<theplant.ec.service.search.IFieldRange>|null} [ranges] Facet ranges
                     */

                    /**
                     * Constructs a new Facet.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a Facet.
                     * @implements IFacet
                     * @constructor
                     * @param {theplant.ec.service.search.IFacet=} [properties] Properties to set
                     */
                    function Facet(properties) {
                        this.includeValues = [];
                        this.ranges = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Facet field.
                     * @member {theplant.ec.service.search.ISearchField|null|undefined} field
                     * @memberof theplant.ec.service.search.Facet
                     * @instance
                     */
                    Facet.prototype.field = null;

                    /**
                     * Facet size.
                     * @member {number} size
                     * @memberof theplant.ec.service.search.Facet
                     * @instance
                     */
                    Facet.prototype.size = 0;

                    /**
                     * Facet includeValues.
                     * @member {Array.<string>} includeValues
                     * @memberof theplant.ec.service.search.Facet
                     * @instance
                     */
                    Facet.prototype.includeValues = $util.emptyArray;

                    /**
                     * Facet ranges.
                     * @member {Array.<theplant.ec.service.search.IFieldRange>} ranges
                     * @memberof theplant.ec.service.search.Facet
                     * @instance
                     */
                    Facet.prototype.ranges = $util.emptyArray;

                    /**
                     * Creates a new Facet instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {theplant.ec.service.search.IFacet=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.Facet} Facet instance
                     */
                    Facet.create = function create(properties) {
                        return new Facet(properties);
                    };

                    /**
                     * Encodes the specified Facet message. Does not implicitly {@link theplant.ec.service.search.Facet.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {theplant.ec.service.search.IFacet} message Facet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Facet.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            $root.theplant.ec.service.search.SearchField.encode(message.field, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.size != null && message.hasOwnProperty("size"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.size);
                        if (message.includeValues != null && message.includeValues.length)
                            for (var i = 0; i < message.includeValues.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.includeValues[i]);
                        if (message.ranges != null && message.ranges.length)
                            for (var i = 0; i < message.ranges.length; ++i)
                                $root.theplant.ec.service.search.FieldRange.encode(message.ranges[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Facet message, length delimited. Does not implicitly {@link theplant.ec.service.search.Facet.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {theplant.ec.service.search.IFacet} message Facet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Facet.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Facet message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.Facet} Facet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Facet.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.Facet();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = $root.theplant.ec.service.search.SearchField.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.size = reader.int32();
                                break;
                            case 3:
                                if (!(message.includeValues && message.includeValues.length))
                                    message.includeValues = [];
                                message.includeValues.push(reader.string());
                                break;
                            case 4:
                                if (!(message.ranges && message.ranges.length))
                                    message.ranges = [];
                                message.ranges.push($root.theplant.ec.service.search.FieldRange.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Facet message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.Facet} Facet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Facet.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Facet message.
                     * @function verify
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Facet.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field")) {
                            var error = $root.theplant.ec.service.search.SearchField.verify(message.field);
                            if (error)
                                return "field." + error;
                        }
                        if (message.size != null && message.hasOwnProperty("size"))
                            if (!$util.isInteger(message.size))
                                return "size: integer expected";
                        if (message.includeValues != null && message.hasOwnProperty("includeValues")) {
                            if (!Array.isArray(message.includeValues))
                                return "includeValues: array expected";
                            for (var i = 0; i < message.includeValues.length; ++i)
                                if (!$util.isString(message.includeValues[i]))
                                    return "includeValues: string[] expected";
                        }
                        if (message.ranges != null && message.hasOwnProperty("ranges")) {
                            if (!Array.isArray(message.ranges))
                                return "ranges: array expected";
                            for (var i = 0; i < message.ranges.length; ++i) {
                                var error = $root.theplant.ec.service.search.FieldRange.verify(message.ranges[i]);
                                if (error)
                                    return "ranges." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Facet message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.Facet} Facet
                     */
                    Facet.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.Facet)
                            return object;
                        var message = new $root.theplant.ec.service.search.Facet();
                        if (object.field != null) {
                            if (typeof object.field !== "object")
                                throw TypeError(".theplant.ec.service.search.Facet.field: object expected");
                            message.field = $root.theplant.ec.service.search.SearchField.fromObject(object.field);
                        }
                        if (object.size != null)
                            message.size = object.size | 0;
                        if (object.includeValues) {
                            if (!Array.isArray(object.includeValues))
                                throw TypeError(".theplant.ec.service.search.Facet.includeValues: array expected");
                            message.includeValues = [];
                            for (var i = 0; i < object.includeValues.length; ++i)
                                message.includeValues[i] = String(object.includeValues[i]);
                        }
                        if (object.ranges) {
                            if (!Array.isArray(object.ranges))
                                throw TypeError(".theplant.ec.service.search.Facet.ranges: array expected");
                            message.ranges = [];
                            for (var i = 0; i < object.ranges.length; ++i) {
                                if (typeof object.ranges[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.Facet.ranges: object expected");
                                message.ranges[i] = $root.theplant.ec.service.search.FieldRange.fromObject(object.ranges[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Facet message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.Facet
                     * @static
                     * @param {theplant.ec.service.search.Facet} message Facet
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Facet.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.includeValues = [];
                            object.ranges = [];
                        }
                        if (options.defaults) {
                            object.field = null;
                            object.size = 0;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = $root.theplant.ec.service.search.SearchField.toObject(message.field, options);
                        if (message.size != null && message.hasOwnProperty("size"))
                            object.size = message.size;
                        if (message.includeValues && message.includeValues.length) {
                            object.includeValues = [];
                            for (var j = 0; j < message.includeValues.length; ++j)
                                object.includeValues[j] = message.includeValues[j];
                        }
                        if (message.ranges && message.ranges.length) {
                            object.ranges = [];
                            for (var j = 0; j < message.ranges.length; ++j)
                                object.ranges[j] = $root.theplant.ec.service.search.FieldRange.toObject(message.ranges[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Facet to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.Facet
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Facet.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Facet;
                })();

                search.FieldRange = (function() {

                    /**
                     * Properties of a FieldRange.
                     * @memberof theplant.ec.service.search
                     * @interface IFieldRange
                     * @property {string|null} [start] FieldRange start
                     * @property {string|null} [end] FieldRange end
                     * @property {boolean|null} [includeLower] FieldRange includeLower
                     * @property {boolean|null} [includeUpper] FieldRange includeUpper
                     */

                    /**
                     * Constructs a new FieldRange.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a FieldRange.
                     * @implements IFieldRange
                     * @constructor
                     * @param {theplant.ec.service.search.IFieldRange=} [properties] Properties to set
                     */
                    function FieldRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FieldRange start.
                     * @member {string} start
                     * @memberof theplant.ec.service.search.FieldRange
                     * @instance
                     */
                    FieldRange.prototype.start = "";

                    /**
                     * FieldRange end.
                     * @member {string} end
                     * @memberof theplant.ec.service.search.FieldRange
                     * @instance
                     */
                    FieldRange.prototype.end = "";

                    /**
                     * FieldRange includeLower.
                     * @member {boolean} includeLower
                     * @memberof theplant.ec.service.search.FieldRange
                     * @instance
                     */
                    FieldRange.prototype.includeLower = false;

                    /**
                     * FieldRange includeUpper.
                     * @member {boolean} includeUpper
                     * @memberof theplant.ec.service.search.FieldRange
                     * @instance
                     */
                    FieldRange.prototype.includeUpper = false;

                    /**
                     * Creates a new FieldRange instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {theplant.ec.service.search.IFieldRange=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.FieldRange} FieldRange instance
                     */
                    FieldRange.create = function create(properties) {
                        return new FieldRange(properties);
                    };

                    /**
                     * Encodes the specified FieldRange message. Does not implicitly {@link theplant.ec.service.search.FieldRange.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {theplant.ec.service.search.IFieldRange} message FieldRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FieldRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && message.hasOwnProperty("start"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.start);
                        if (message.end != null && message.hasOwnProperty("end"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.end);
                        if (message.includeLower != null && message.hasOwnProperty("includeLower"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeLower);
                        if (message.includeUpper != null && message.hasOwnProperty("includeUpper"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeUpper);
                        return writer;
                    };

                    /**
                     * Encodes the specified FieldRange message, length delimited. Does not implicitly {@link theplant.ec.service.search.FieldRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {theplant.ec.service.search.IFieldRange} message FieldRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FieldRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FieldRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.FieldRange} FieldRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FieldRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.FieldRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.string();
                                break;
                            case 2:
                                message.end = reader.string();
                                break;
                            case 3:
                                message.includeLower = reader.bool();
                                break;
                            case 4:
                                message.includeUpper = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FieldRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.FieldRange} FieldRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FieldRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FieldRange message.
                     * @function verify
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FieldRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isString(message.start))
                                return "start: string expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isString(message.end))
                                return "end: string expected";
                        if (message.includeLower != null && message.hasOwnProperty("includeLower"))
                            if (typeof message.includeLower !== "boolean")
                                return "includeLower: boolean expected";
                        if (message.includeUpper != null && message.hasOwnProperty("includeUpper"))
                            if (typeof message.includeUpper !== "boolean")
                                return "includeUpper: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a FieldRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.FieldRange} FieldRange
                     */
                    FieldRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.FieldRange)
                            return object;
                        var message = new $root.theplant.ec.service.search.FieldRange();
                        if (object.start != null)
                            message.start = String(object.start);
                        if (object.end != null)
                            message.end = String(object.end);
                        if (object.includeLower != null)
                            message.includeLower = Boolean(object.includeLower);
                        if (object.includeUpper != null)
                            message.includeUpper = Boolean(object.includeUpper);
                        return message;
                    };

                    /**
                     * Creates a plain object from a FieldRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.FieldRange
                     * @static
                     * @param {theplant.ec.service.search.FieldRange} message FieldRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FieldRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = "";
                            object.end = "";
                            object.includeLower = false;
                            object.includeUpper = false;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        if (message.includeLower != null && message.hasOwnProperty("includeLower"))
                            object.includeLower = message.includeLower;
                        if (message.includeUpper != null && message.hasOwnProperty("includeUpper"))
                            object.includeUpper = message.includeUpper;
                        return object;
                    };

                    /**
                     * Converts this FieldRange to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.FieldRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FieldRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FieldRange;
                })();

                search.FieldRefinement = (function() {

                    /**
                     * Properties of a FieldRefinement.
                     * @memberof theplant.ec.service.search
                     * @interface IFieldRefinement
                     * @property {theplant.ec.service.search.ISearchField|null} [field] FieldRefinement field
                     * @property {Array.<string>|null} [values] FieldRefinement values
                     * @property {theplant.ec.service.search.IFieldRange|null} [range] FieldRefinement range
                     * @property {boolean|null} [isNegative] FieldRefinement isNegative
                     */

                    /**
                     * Constructs a new FieldRefinement.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a FieldRefinement.
                     * @implements IFieldRefinement
                     * @constructor
                     * @param {theplant.ec.service.search.IFieldRefinement=} [properties] Properties to set
                     */
                    function FieldRefinement(properties) {
                        this.values = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FieldRefinement field.
                     * @member {theplant.ec.service.search.ISearchField|null|undefined} field
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @instance
                     */
                    FieldRefinement.prototype.field = null;

                    /**
                     * FieldRefinement values.
                     * @member {Array.<string>} values
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @instance
                     */
                    FieldRefinement.prototype.values = $util.emptyArray;

                    /**
                     * FieldRefinement range.
                     * @member {theplant.ec.service.search.IFieldRange|null|undefined} range
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @instance
                     */
                    FieldRefinement.prototype.range = null;

                    /**
                     * FieldRefinement isNegative.
                     * @member {boolean} isNegative
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @instance
                     */
                    FieldRefinement.prototype.isNegative = false;

                    /**
                     * Creates a new FieldRefinement instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {theplant.ec.service.search.IFieldRefinement=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.FieldRefinement} FieldRefinement instance
                     */
                    FieldRefinement.create = function create(properties) {
                        return new FieldRefinement(properties);
                    };

                    /**
                     * Encodes the specified FieldRefinement message. Does not implicitly {@link theplant.ec.service.search.FieldRefinement.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {theplant.ec.service.search.IFieldRefinement} message FieldRefinement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FieldRefinement.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            $root.theplant.ec.service.search.SearchField.encode(message.field, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.values != null && message.values.length)
                            for (var i = 0; i < message.values.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.values[i]);
                        if (message.range != null && message.hasOwnProperty("range"))
                            $root.theplant.ec.service.search.FieldRange.encode(message.range, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isNegative);
                        return writer;
                    };

                    /**
                     * Encodes the specified FieldRefinement message, length delimited. Does not implicitly {@link theplant.ec.service.search.FieldRefinement.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {theplant.ec.service.search.IFieldRefinement} message FieldRefinement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FieldRefinement.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FieldRefinement message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.FieldRefinement} FieldRefinement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FieldRefinement.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.FieldRefinement();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = $root.theplant.ec.service.search.SearchField.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push(reader.string());
                                break;
                            case 3:
                                message.range = $root.theplant.ec.service.search.FieldRange.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.isNegative = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FieldRefinement message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.FieldRefinement} FieldRefinement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FieldRefinement.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FieldRefinement message.
                     * @function verify
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FieldRefinement.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field")) {
                            var error = $root.theplant.ec.service.search.SearchField.verify(message.field);
                            if (error)
                                return "field." + error;
                        }
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i)
                                if (!$util.isString(message.values[i]))
                                    return "values: string[] expected";
                        }
                        if (message.range != null && message.hasOwnProperty("range")) {
                            var error = $root.theplant.ec.service.search.FieldRange.verify(message.range);
                            if (error)
                                return "range." + error;
                        }
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            if (typeof message.isNegative !== "boolean")
                                return "isNegative: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a FieldRefinement message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.FieldRefinement} FieldRefinement
                     */
                    FieldRefinement.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.FieldRefinement)
                            return object;
                        var message = new $root.theplant.ec.service.search.FieldRefinement();
                        if (object.field != null) {
                            if (typeof object.field !== "object")
                                throw TypeError(".theplant.ec.service.search.FieldRefinement.field: object expected");
                            message.field = $root.theplant.ec.service.search.SearchField.fromObject(object.field);
                        }
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".theplant.ec.service.search.FieldRefinement.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i)
                                message.values[i] = String(object.values[i]);
                        }
                        if (object.range != null) {
                            if (typeof object.range !== "object")
                                throw TypeError(".theplant.ec.service.search.FieldRefinement.range: object expected");
                            message.range = $root.theplant.ec.service.search.FieldRange.fromObject(object.range);
                        }
                        if (object.isNegative != null)
                            message.isNegative = Boolean(object.isNegative);
                        return message;
                    };

                    /**
                     * Creates a plain object from a FieldRefinement message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @static
                     * @param {theplant.ec.service.search.FieldRefinement} message FieldRefinement
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FieldRefinement.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.values = [];
                        if (options.defaults) {
                            object.field = null;
                            object.range = null;
                            object.isNegative = false;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = $root.theplant.ec.service.search.SearchField.toObject(message.field, options);
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = message.values[j];
                        }
                        if (message.range != null && message.hasOwnProperty("range"))
                            object.range = $root.theplant.ec.service.search.FieldRange.toObject(message.range, options);
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            object.isNegative = message.isNegative;
                        return object;
                    };

                    /**
                     * Converts this FieldRefinement to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.FieldRefinement
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FieldRefinement.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FieldRefinement;
                })();

                search.SearchResult = (function() {

                    /**
                     * Properties of a SearchResult.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchResult
                     * @property {Array.<theplant.ec.service.search.ISearchDocument>|null} [docs] SearchResult docs
                     * @property {number|null} [matchedCount] SearchResult matchedCount
                     * @property {Array.<theplant.ec.service.search.ISearchFacet>|null} [searchFacets] SearchResult searchFacets
                     */

                    /**
                     * Constructs a new SearchResult.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchResult.
                     * @implements ISearchResult
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchResult=} [properties] Properties to set
                     */
                    function SearchResult(properties) {
                        this.docs = [];
                        this.searchFacets = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchResult docs.
                     * @member {Array.<theplant.ec.service.search.ISearchDocument>} docs
                     * @memberof theplant.ec.service.search.SearchResult
                     * @instance
                     */
                    SearchResult.prototype.docs = $util.emptyArray;

                    /**
                     * SearchResult matchedCount.
                     * @member {number} matchedCount
                     * @memberof theplant.ec.service.search.SearchResult
                     * @instance
                     */
                    SearchResult.prototype.matchedCount = 0;

                    /**
                     * SearchResult searchFacets.
                     * @member {Array.<theplant.ec.service.search.ISearchFacet>} searchFacets
                     * @memberof theplant.ec.service.search.SearchResult
                     * @instance
                     */
                    SearchResult.prototype.searchFacets = $util.emptyArray;

                    /**
                     * Creates a new SearchResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {theplant.ec.service.search.ISearchResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchResult} SearchResult instance
                     */
                    SearchResult.create = function create(properties) {
                        return new SearchResult(properties);
                    };

                    /**
                     * Encodes the specified SearchResult message. Does not implicitly {@link theplant.ec.service.search.SearchResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {theplant.ec.service.search.ISearchResult} message SearchResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.docs != null && message.docs.length)
                            for (var i = 0; i < message.docs.length; ++i)
                                $root.theplant.ec.service.search.SearchDocument.encode(message.docs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.matchedCount != null && message.hasOwnProperty("matchedCount"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.matchedCount);
                        if (message.searchFacets != null && message.searchFacets.length)
                            for (var i = 0; i < message.searchFacets.length; ++i)
                                $root.theplant.ec.service.search.SearchFacet.encode(message.searchFacets[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchResult message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {theplant.ec.service.search.ISearchResult} message SearchResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchResult} SearchResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.docs && message.docs.length))
                                    message.docs = [];
                                message.docs.push($root.theplant.ec.service.search.SearchDocument.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.matchedCount = reader.int32();
                                break;
                            case 3:
                                if (!(message.searchFacets && message.searchFacets.length))
                                    message.searchFacets = [];
                                message.searchFacets.push($root.theplant.ec.service.search.SearchFacet.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchResult} SearchResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchResult message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.docs != null && message.hasOwnProperty("docs")) {
                            if (!Array.isArray(message.docs))
                                return "docs: array expected";
                            for (var i = 0; i < message.docs.length; ++i) {
                                var error = $root.theplant.ec.service.search.SearchDocument.verify(message.docs[i]);
                                if (error)
                                    return "docs." + error;
                            }
                        }
                        if (message.matchedCount != null && message.hasOwnProperty("matchedCount"))
                            if (!$util.isInteger(message.matchedCount))
                                return "matchedCount: integer expected";
                        if (message.searchFacets != null && message.hasOwnProperty("searchFacets")) {
                            if (!Array.isArray(message.searchFacets))
                                return "searchFacets: array expected";
                            for (var i = 0; i < message.searchFacets.length; ++i) {
                                var error = $root.theplant.ec.service.search.SearchFacet.verify(message.searchFacets[i]);
                                if (error)
                                    return "searchFacets." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchResult} SearchResult
                     */
                    SearchResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchResult)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchResult();
                        if (object.docs) {
                            if (!Array.isArray(object.docs))
                                throw TypeError(".theplant.ec.service.search.SearchResult.docs: array expected");
                            message.docs = [];
                            for (var i = 0; i < object.docs.length; ++i) {
                                if (typeof object.docs[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchResult.docs: object expected");
                                message.docs[i] = $root.theplant.ec.service.search.SearchDocument.fromObject(object.docs[i]);
                            }
                        }
                        if (object.matchedCount != null)
                            message.matchedCount = object.matchedCount | 0;
                        if (object.searchFacets) {
                            if (!Array.isArray(object.searchFacets))
                                throw TypeError(".theplant.ec.service.search.SearchResult.searchFacets: array expected");
                            message.searchFacets = [];
                            for (var i = 0; i < object.searchFacets.length; ++i) {
                                if (typeof object.searchFacets[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchResult.searchFacets: object expected");
                                message.searchFacets[i] = $root.theplant.ec.service.search.SearchFacet.fromObject(object.searchFacets[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchResult
                     * @static
                     * @param {theplant.ec.service.search.SearchResult} message SearchResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.docs = [];
                            object.searchFacets = [];
                        }
                        if (options.defaults)
                            object.matchedCount = 0;
                        if (message.docs && message.docs.length) {
                            object.docs = [];
                            for (var j = 0; j < message.docs.length; ++j)
                                object.docs[j] = $root.theplant.ec.service.search.SearchDocument.toObject(message.docs[j], options);
                        }
                        if (message.matchedCount != null && message.hasOwnProperty("matchedCount"))
                            object.matchedCount = message.matchedCount;
                        if (message.searchFacets && message.searchFacets.length) {
                            object.searchFacets = [];
                            for (var j = 0; j < message.searchFacets.length; ++j)
                                object.searchFacets[j] = $root.theplant.ec.service.search.SearchFacet.toObject(message.searchFacets[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SearchResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchResult;
                })();

                search.SearchDocument = (function() {

                    /**
                     * Properties of a SearchDocument.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchDocument
                     * @property {theplant.ec.service.search.IDocument|null} [doc] SearchDocument doc
                     * @property {number|null} [score] SearchDocument score
                     * @property {string|null} [qualifiedSubDocId] SearchDocument qualifiedSubDocId
                     */

                    /**
                     * Constructs a new SearchDocument.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchDocument.
                     * @implements ISearchDocument
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchDocument=} [properties] Properties to set
                     */
                    function SearchDocument(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchDocument doc.
                     * @member {theplant.ec.service.search.IDocument|null|undefined} doc
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @instance
                     */
                    SearchDocument.prototype.doc = null;

                    /**
                     * SearchDocument score.
                     * @member {number} score
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @instance
                     */
                    SearchDocument.prototype.score = 0;

                    /**
                     * SearchDocument qualifiedSubDocId.
                     * @member {string} qualifiedSubDocId
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @instance
                     */
                    SearchDocument.prototype.qualifiedSubDocId = "";

                    /**
                     * Creates a new SearchDocument instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {theplant.ec.service.search.ISearchDocument=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchDocument} SearchDocument instance
                     */
                    SearchDocument.create = function create(properties) {
                        return new SearchDocument(properties);
                    };

                    /**
                     * Encodes the specified SearchDocument message. Does not implicitly {@link theplant.ec.service.search.SearchDocument.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {theplant.ec.service.search.ISearchDocument} message SearchDocument message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchDocument.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.doc != null && message.hasOwnProperty("doc"))
                            $root.theplant.ec.service.search.Document.encode(message.doc, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.score != null && message.hasOwnProperty("score"))
                            writer.uint32(/* id 2, wireType 1 =*/17).double(message.score);
                        if (message.qualifiedSubDocId != null && message.hasOwnProperty("qualifiedSubDocId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.qualifiedSubDocId);
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchDocument message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchDocument.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {theplant.ec.service.search.ISearchDocument} message SearchDocument message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchDocument.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchDocument message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchDocument} SearchDocument
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchDocument.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchDocument();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.doc = $root.theplant.ec.service.search.Document.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.score = reader.double();
                                break;
                            case 3:
                                message.qualifiedSubDocId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchDocument message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchDocument} SearchDocument
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchDocument.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchDocument message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchDocument.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.doc != null && message.hasOwnProperty("doc")) {
                            var error = $root.theplant.ec.service.search.Document.verify(message.doc);
                            if (error)
                                return "doc." + error;
                        }
                        if (message.score != null && message.hasOwnProperty("score"))
                            if (typeof message.score !== "number")
                                return "score: number expected";
                        if (message.qualifiedSubDocId != null && message.hasOwnProperty("qualifiedSubDocId"))
                            if (!$util.isString(message.qualifiedSubDocId))
                                return "qualifiedSubDocId: string expected";
                        return null;
                    };

                    /**
                     * Creates a SearchDocument message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchDocument} SearchDocument
                     */
                    SearchDocument.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchDocument)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchDocument();
                        if (object.doc != null) {
                            if (typeof object.doc !== "object")
                                throw TypeError(".theplant.ec.service.search.SearchDocument.doc: object expected");
                            message.doc = $root.theplant.ec.service.search.Document.fromObject(object.doc);
                        }
                        if (object.score != null)
                            message.score = Number(object.score);
                        if (object.qualifiedSubDocId != null)
                            message.qualifiedSubDocId = String(object.qualifiedSubDocId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchDocument message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @static
                     * @param {theplant.ec.service.search.SearchDocument} message SearchDocument
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchDocument.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.doc = null;
                            object.score = 0;
                            object.qualifiedSubDocId = "";
                        }
                        if (message.doc != null && message.hasOwnProperty("doc"))
                            object.doc = $root.theplant.ec.service.search.Document.toObject(message.doc, options);
                        if (message.score != null && message.hasOwnProperty("score"))
                            object.score = options.json && !isFinite(message.score) ? String(message.score) : message.score;
                        if (message.qualifiedSubDocId != null && message.hasOwnProperty("qualifiedSubDocId"))
                            object.qualifiedSubDocId = message.qualifiedSubDocId;
                        return object;
                    };

                    /**
                     * Converts this SearchDocument to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchDocument
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchDocument.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchDocument;
                })();

                search.SearchFacet = (function() {

                    /**
                     * Properties of a SearchFacet.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchFacet
                     * @property {theplant.ec.service.search.ISearchField|null} [field] SearchFacet field
                     * @property {Array.<theplant.ec.service.search.ISearchFacetValue>|null} [values] SearchFacet values
                     */

                    /**
                     * Constructs a new SearchFacet.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchFacet.
                     * @implements ISearchFacet
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchFacet=} [properties] Properties to set
                     */
                    function SearchFacet(properties) {
                        this.values = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchFacet field.
                     * @member {theplant.ec.service.search.ISearchField|null|undefined} field
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @instance
                     */
                    SearchFacet.prototype.field = null;

                    /**
                     * SearchFacet values.
                     * @member {Array.<theplant.ec.service.search.ISearchFacetValue>} values
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @instance
                     */
                    SearchFacet.prototype.values = $util.emptyArray;

                    /**
                     * Creates a new SearchFacet instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {theplant.ec.service.search.ISearchFacet=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchFacet} SearchFacet instance
                     */
                    SearchFacet.create = function create(properties) {
                        return new SearchFacet(properties);
                    };

                    /**
                     * Encodes the specified SearchFacet message. Does not implicitly {@link theplant.ec.service.search.SearchFacet.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {theplant.ec.service.search.ISearchFacet} message SearchFacet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchFacet.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            $root.theplant.ec.service.search.SearchField.encode(message.field, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.values != null && message.values.length)
                            for (var i = 0; i < message.values.length; ++i)
                                $root.theplant.ec.service.search.SearchFacetValue.encode(message.values[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchFacet message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchFacet.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {theplant.ec.service.search.ISearchFacet} message SearchFacet message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchFacet.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchFacet message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchFacet} SearchFacet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchFacet.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchFacet();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = $root.theplant.ec.service.search.SearchField.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.values && message.values.length))
                                    message.values = [];
                                message.values.push($root.theplant.ec.service.search.SearchFacetValue.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchFacet message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchFacet} SearchFacet
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchFacet.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchFacet message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchFacet.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field")) {
                            var error = $root.theplant.ec.service.search.SearchField.verify(message.field);
                            if (error)
                                return "field." + error;
                        }
                        if (message.values != null && message.hasOwnProperty("values")) {
                            if (!Array.isArray(message.values))
                                return "values: array expected";
                            for (var i = 0; i < message.values.length; ++i) {
                                var error = $root.theplant.ec.service.search.SearchFacetValue.verify(message.values[i]);
                                if (error)
                                    return "values." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchFacet message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchFacet} SearchFacet
                     */
                    SearchFacet.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchFacet)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchFacet();
                        if (object.field != null) {
                            if (typeof object.field !== "object")
                                throw TypeError(".theplant.ec.service.search.SearchFacet.field: object expected");
                            message.field = $root.theplant.ec.service.search.SearchField.fromObject(object.field);
                        }
                        if (object.values) {
                            if (!Array.isArray(object.values))
                                throw TypeError(".theplant.ec.service.search.SearchFacet.values: array expected");
                            message.values = [];
                            for (var i = 0; i < object.values.length; ++i) {
                                if (typeof object.values[i] !== "object")
                                    throw TypeError(".theplant.ec.service.search.SearchFacet.values: object expected");
                                message.values[i] = $root.theplant.ec.service.search.SearchFacetValue.fromObject(object.values[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchFacet message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @static
                     * @param {theplant.ec.service.search.SearchFacet} message SearchFacet
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchFacet.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.values = [];
                        if (options.defaults)
                            object.field = null;
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = $root.theplant.ec.service.search.SearchField.toObject(message.field, options);
                        if (message.values && message.values.length) {
                            object.values = [];
                            for (var j = 0; j < message.values.length; ++j)
                                object.values[j] = $root.theplant.ec.service.search.SearchFacetValue.toObject(message.values[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SearchFacet to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchFacet
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchFacet.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchFacet;
                })();

                search.SearchFacetValue = (function() {

                    /**
                     * Properties of a SearchFacetValue.
                     * @memberof theplant.ec.service.search
                     * @interface ISearchFacetValue
                     * @property {string|null} [name] SearchFacetValue name
                     * @property {number|null} [count] SearchFacetValue count
                     * @property {theplant.ec.service.search.IFieldRange|null} [range] SearchFacetValue range
                     */

                    /**
                     * Constructs a new SearchFacetValue.
                     * @memberof theplant.ec.service.search
                     * @classdesc Represents a SearchFacetValue.
                     * @implements ISearchFacetValue
                     * @constructor
                     * @param {theplant.ec.service.search.ISearchFacetValue=} [properties] Properties to set
                     */
                    function SearchFacetValue(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchFacetValue name.
                     * @member {string} name
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @instance
                     */
                    SearchFacetValue.prototype.name = "";

                    /**
                     * SearchFacetValue count.
                     * @member {number} count
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @instance
                     */
                    SearchFacetValue.prototype.count = 0;

                    /**
                     * SearchFacetValue range.
                     * @member {theplant.ec.service.search.IFieldRange|null|undefined} range
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @instance
                     */
                    SearchFacetValue.prototype.range = null;

                    /**
                     * Creates a new SearchFacetValue instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {theplant.ec.service.search.ISearchFacetValue=} [properties] Properties to set
                     * @returns {theplant.ec.service.search.SearchFacetValue} SearchFacetValue instance
                     */
                    SearchFacetValue.create = function create(properties) {
                        return new SearchFacetValue(properties);
                    };

                    /**
                     * Encodes the specified SearchFacetValue message. Does not implicitly {@link theplant.ec.service.search.SearchFacetValue.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {theplant.ec.service.search.ISearchFacetValue} message SearchFacetValue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchFacetValue.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.count != null && message.hasOwnProperty("count"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.count);
                        if (message.range != null && message.hasOwnProperty("range"))
                            $root.theplant.ec.service.search.FieldRange.encode(message.range, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchFacetValue message, length delimited. Does not implicitly {@link theplant.ec.service.search.SearchFacetValue.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {theplant.ec.service.search.ISearchFacetValue} message SearchFacetValue message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchFacetValue.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchFacetValue message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.search.SearchFacetValue} SearchFacetValue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchFacetValue.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.search.SearchFacetValue();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.count = reader.int32();
                                break;
                            case 3:
                                message.range = $root.theplant.ec.service.search.FieldRange.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchFacetValue message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.search.SearchFacetValue} SearchFacetValue
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchFacetValue.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchFacetValue message.
                     * @function verify
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchFacetValue.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (!$util.isInteger(message.count))
                                return "count: integer expected";
                        if (message.range != null && message.hasOwnProperty("range")) {
                            var error = $root.theplant.ec.service.search.FieldRange.verify(message.range);
                            if (error)
                                return "range." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchFacetValue message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.search.SearchFacetValue} SearchFacetValue
                     */
                    SearchFacetValue.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.search.SearchFacetValue)
                            return object;
                        var message = new $root.theplant.ec.service.search.SearchFacetValue();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.count != null)
                            message.count = object.count | 0;
                        if (object.range != null) {
                            if (typeof object.range !== "object")
                                throw TypeError(".theplant.ec.service.search.SearchFacetValue.range: object expected");
                            message.range = $root.theplant.ec.service.search.FieldRange.fromObject(object.range);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchFacetValue message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @static
                     * @param {theplant.ec.service.search.SearchFacetValue} message SearchFacetValue
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchFacetValue.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.count = 0;
                            object.range = null;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.count != null && message.hasOwnProperty("count"))
                            object.count = message.count;
                        if (message.range != null && message.hasOwnProperty("range"))
                            object.range = $root.theplant.ec.service.search.FieldRange.toObject(message.range, options);
                        return object;
                    };

                    /**
                     * Converts this SearchFacetValue to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.search.SearchFacetValue
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchFacetValue.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchFacetValue;
                })();

                return search;
            })();

            service.referencedata = (function() {

                /**
                 * Namespace referencedata.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var referencedata = {};

                referencedata.RDItem = (function() {

                    /**
                     * Properties of a RDItem.
                     * @memberof theplant.ec.service.referencedata
                     * @interface IRDItem
                     * @property {string|null} [itemType] RDItem itemType
                     * @property {string|null} [code] RDItem code
                     * @property {string|null} [slug] RDItem slug
                     * @property {string|null} [name] RDItem name
                     * @property {string|null} [longName] RDItem longName
                     * @property {string|null} [description] RDItem description
                     * @property {Array.<theplant.ec.service.referencedata.IRDImage>|null} [images] RDItem images
                     * @property {number|Long|null} [position] RDItem position
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [props] for filter products
                     * @property {boolean|null} [selected] RDItem selected
                     * @property {number|Long|null} [count] RDItem count
                     */

                    /**
                     * Constructs a new RDItem.
                     * @memberof theplant.ec.service.referencedata
                     * @classdesc Represents a RDItem.
                     * @implements IRDItem
                     * @constructor
                     * @param {theplant.ec.service.referencedata.IRDItem=} [properties] Properties to set
                     */
                    function RDItem(properties) {
                        this.images = [];
                        this.props = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDItem itemType.
                     * @member {string} itemType
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.itemType = "";

                    /**
                     * RDItem code.
                     * @member {string} code
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.code = "";

                    /**
                     * RDItem slug.
                     * @member {string} slug
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.slug = "";

                    /**
                     * RDItem name.
                     * @member {string} name
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.name = "";

                    /**
                     * RDItem longName.
                     * @member {string} longName
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.longName = "";

                    /**
                     * RDItem description.
                     * @member {string} description
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.description = "";

                    /**
                     * RDItem images.
                     * @member {Array.<theplant.ec.service.referencedata.IRDImage>} images
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.images = $util.emptyArray;

                    /**
                     * RDItem position.
                     * @member {number|Long} position
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.position = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * for filter products
                     * @member {Array.<theplant.ec.service.base.IProperty>} props
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.props = $util.emptyArray;

                    /**
                     * RDItem selected.
                     * @member {boolean} selected
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.selected = false;

                    /**
                     * RDItem count.
                     * @member {number|Long} count
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     */
                    RDItem.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new RDItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.referencedata.RDItem} RDItem instance
                     */
                    RDItem.create = function create(properties) {
                        return new RDItem(properties);
                    };

                    /**
                     * Encodes the specified RDItem message. Does not implicitly {@link theplant.ec.service.referencedata.RDItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDItem} message RDItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemType);
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                        if (message.slug != null && message.hasOwnProperty("slug"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.slug);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                        if (message.longName != null && message.hasOwnProperty("longName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.longName);
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.description);
                        if (message.images != null && message.images.length)
                            for (var i = 0; i < message.images.length; ++i)
                                $root.theplant.ec.service.referencedata.RDImage.encode(message.images[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.position != null && message.hasOwnProperty("position"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.position);
                        if (message.props != null && message.props.length)
                            for (var i = 0; i < message.props.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.props[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.selected);
                        if (message.count != null && message.hasOwnProperty("count"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.count);
                        return writer;
                    };

                    /**
                     * Encodes the specified RDItem message, length delimited. Does not implicitly {@link theplant.ec.service.referencedata.RDItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDItem} message RDItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.referencedata.RDItem} RDItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.referencedata.RDItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.itemType = reader.string();
                                break;
                            case 2:
                                message.code = reader.string();
                                break;
                            case 3:
                                message.slug = reader.string();
                                break;
                            case 4:
                                message.name = reader.string();
                                break;
                            case 5:
                                message.longName = reader.string();
                                break;
                            case 6:
                                message.description = reader.string();
                                break;
                            case 7:
                                if (!(message.images && message.images.length))
                                    message.images = [];
                                message.images.push($root.theplant.ec.service.referencedata.RDImage.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                message.position = reader.int64();
                                break;
                            case 9:
                                if (!(message.props && message.props.length))
                                    message.props = [];
                                message.props.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                message.selected = reader.bool();
                                break;
                            case 11:
                                message.count = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.referencedata.RDItem} RDItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDItem message.
                     * @function verify
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            if (!$util.isString(message.itemType))
                                return "itemType: string expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.slug != null && message.hasOwnProperty("slug"))
                            if (!$util.isString(message.slug))
                                return "slug: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.longName != null && message.hasOwnProperty("longName"))
                            if (!$util.isString(message.longName))
                                return "longName: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.images != null && message.hasOwnProperty("images")) {
                            if (!Array.isArray(message.images))
                                return "images: array expected";
                            for (var i = 0; i < message.images.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDImage.verify(message.images[i]);
                                if (error)
                                    return "images." + error;
                            }
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isInteger(message.position) && !(message.position && $util.isInteger(message.position.low) && $util.isInteger(message.position.high)))
                                return "position: integer|Long expected";
                        if (message.props != null && message.hasOwnProperty("props")) {
                            if (!Array.isArray(message.props))
                                return "props: array expected";
                            for (var i = 0; i < message.props.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.props[i]);
                                if (error)
                                    return "props." + error;
                            }
                        }
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            if (typeof message.selected !== "boolean")
                                return "selected: boolean expected";
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                                return "count: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a RDItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.referencedata.RDItem} RDItem
                     */
                    RDItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.referencedata.RDItem)
                            return object;
                        var message = new $root.theplant.ec.service.referencedata.RDItem();
                        if (object.itemType != null)
                            message.itemType = String(object.itemType);
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.slug != null)
                            message.slug = String(object.slug);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.longName != null)
                            message.longName = String(object.longName);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.images) {
                            if (!Array.isArray(object.images))
                                throw TypeError(".theplant.ec.service.referencedata.RDItem.images: array expected");
                            message.images = [];
                            for (var i = 0; i < object.images.length; ++i) {
                                if (typeof object.images[i] !== "object")
                                    throw TypeError(".theplant.ec.service.referencedata.RDItem.images: object expected");
                                message.images[i] = $root.theplant.ec.service.referencedata.RDImage.fromObject(object.images[i]);
                            }
                        }
                        if (object.position != null)
                            if ($util.Long)
                                (message.position = $util.Long.fromValue(object.position)).unsigned = false;
                            else if (typeof object.position === "string")
                                message.position = parseInt(object.position, 10);
                            else if (typeof object.position === "number")
                                message.position = object.position;
                            else if (typeof object.position === "object")
                                message.position = new $util.LongBits(object.position.low >>> 0, object.position.high >>> 0).toNumber();
                        if (object.props) {
                            if (!Array.isArray(object.props))
                                throw TypeError(".theplant.ec.service.referencedata.RDItem.props: array expected");
                            message.props = [];
                            for (var i = 0; i < object.props.length; ++i) {
                                if (typeof object.props[i] !== "object")
                                    throw TypeError(".theplant.ec.service.referencedata.RDItem.props: object expected");
                                message.props[i] = $root.theplant.ec.service.base.Property.fromObject(object.props[i]);
                            }
                        }
                        if (object.selected != null)
                            message.selected = Boolean(object.selected);
                        if (object.count != null)
                            if ($util.Long)
                                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                            else if (typeof object.count === "string")
                                message.count = parseInt(object.count, 10);
                            else if (typeof object.count === "number")
                                message.count = object.count;
                            else if (typeof object.count === "object")
                                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @static
                     * @param {theplant.ec.service.referencedata.RDItem} message RDItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.images = [];
                            object.props = [];
                        }
                        if (options.defaults) {
                            object.itemType = "";
                            object.code = "";
                            object.slug = "";
                            object.name = "";
                            object.longName = "";
                            object.description = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.position = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.position = options.longs === String ? "0" : 0;
                            object.selected = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.count = options.longs === String ? "0" : 0;
                        }
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            object.itemType = message.itemType;
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.slug != null && message.hasOwnProperty("slug"))
                            object.slug = message.slug;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.longName != null && message.hasOwnProperty("longName"))
                            object.longName = message.longName;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.images && message.images.length) {
                            object.images = [];
                            for (var j = 0; j < message.images.length; ++j)
                                object.images[j] = $root.theplant.ec.service.referencedata.RDImage.toObject(message.images[j], options);
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (typeof message.position === "number")
                                object.position = options.longs === String ? String(message.position) : message.position;
                            else
                                object.position = options.longs === String ? $util.Long.prototype.toString.call(message.position) : options.longs === Number ? new $util.LongBits(message.position.low >>> 0, message.position.high >>> 0).toNumber() : message.position;
                        if (message.props && message.props.length) {
                            object.props = [];
                            for (var j = 0; j < message.props.length; ++j)
                                object.props[j] = $root.theplant.ec.service.base.Property.toObject(message.props[j], options);
                        }
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            object.selected = message.selected;
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (typeof message.count === "number")
                                object.count = options.longs === String ? String(message.count) : message.count;
                            else
                                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                        return object;
                    };

                    /**
                     * Converts this RDItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.referencedata.RDItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDItem;
                })();

                /**
                 * RDFilterField enum.
                 * @name theplant.ec.service.referencedata.RDFilterField
                 * @enum {string}
                 * @property {number} RD_CODE=0 RD_CODE value
                 * @property {number} RD_SLUG=1 RD_SLUG value
                 * @property {number} RD_NAME=2 RD_NAME value
                 */
                referencedata.RDFilterField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "RD_CODE"] = 0;
                    values[valuesById[1] = "RD_SLUG"] = 1;
                    values[valuesById[2] = "RD_NAME"] = 2;
                    return values;
                })();

                /**
                 * RDItemOrder enum.
                 * @name theplant.ec.service.referencedata.RDItemOrder
                 * @enum {string}
                 * @property {number} POSITION_ORDER=0 POSITION_ORDER value
                 * @property {number} VALUES_ORDER=1 VALUES_ORDER value
                 */
                referencedata.RDItemOrder = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "POSITION_ORDER"] = 0;
                    values[valuesById[1] = "VALUES_ORDER"] = 1;
                    return values;
                })();

                referencedata.RDFilterCondition = (function() {

                    /**
                     * Properties of a RDFilterCondition.
                     * @memberof theplant.ec.service.referencedata
                     * @interface IRDFilterCondition
                     * @property {string|null} [itemType] RDFilterCondition itemType
                     * @property {boolean|null} [fetchAll] RDFilterCondition fetchAll
                     * @property {theplant.ec.service.referencedata.RDFilterField|null} [filterField] RDFilterCondition filterField
                     * @property {Array.<string>|null} [inValues] RDFilterCondition inValues
                     * @property {boolean|null} [ignoreCase] RDFilterCondition ignoreCase
                     * @property {theplant.ec.service.referencedata.RDItemOrder|null} [order] RDFilterCondition order
                     */

                    /**
                     * Constructs a new RDFilterCondition.
                     * @memberof theplant.ec.service.referencedata
                     * @classdesc Represents a RDFilterCondition.
                     * @implements IRDFilterCondition
                     * @constructor
                     * @param {theplant.ec.service.referencedata.IRDFilterCondition=} [properties] Properties to set
                     */
                    function RDFilterCondition(properties) {
                        this.inValues = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDFilterCondition itemType.
                     * @member {string} itemType
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     */
                    RDFilterCondition.prototype.itemType = "";

                    /**
                     * RDFilterCondition fetchAll.
                     * @member {boolean} fetchAll
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     */
                    RDFilterCondition.prototype.fetchAll = false;

                    /**
                     * RDFilterCondition filterField.
                     * @member {theplant.ec.service.referencedata.RDFilterField} filterField
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     */
                    RDFilterCondition.prototype.filterField = 0;

                    /**
                     * RDFilterCondition inValues.
                     * @member {Array.<string>} inValues
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     */
                    RDFilterCondition.prototype.inValues = $util.emptyArray;

                    /**
                     * RDFilterCondition ignoreCase.
                     * @member {boolean} ignoreCase
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     */
                    RDFilterCondition.prototype.ignoreCase = false;

                    /**
                     * RDFilterCondition order.
                     * @member {theplant.ec.service.referencedata.RDItemOrder} order
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     */
                    RDFilterCondition.prototype.order = 0;

                    /**
                     * Creates a new RDFilterCondition instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDFilterCondition=} [properties] Properties to set
                     * @returns {theplant.ec.service.referencedata.RDFilterCondition} RDFilterCondition instance
                     */
                    RDFilterCondition.create = function create(properties) {
                        return new RDFilterCondition(properties);
                    };

                    /**
                     * Encodes the specified RDFilterCondition message. Does not implicitly {@link theplant.ec.service.referencedata.RDFilterCondition.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDFilterCondition} message RDFilterCondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDFilterCondition.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.itemType);
                        if (message.fetchAll != null && message.hasOwnProperty("fetchAll"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.fetchAll);
                        if (message.filterField != null && message.hasOwnProperty("filterField"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.filterField);
                        if (message.inValues != null && message.inValues.length)
                            for (var i = 0; i < message.inValues.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.inValues[i]);
                        if (message.ignoreCase != null && message.hasOwnProperty("ignoreCase"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.ignoreCase);
                        if (message.order != null && message.hasOwnProperty("order"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.order);
                        return writer;
                    };

                    /**
                     * Encodes the specified RDFilterCondition message, length delimited. Does not implicitly {@link theplant.ec.service.referencedata.RDFilterCondition.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDFilterCondition} message RDFilterCondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDFilterCondition.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDFilterCondition message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.referencedata.RDFilterCondition} RDFilterCondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDFilterCondition.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.referencedata.RDFilterCondition();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.itemType = reader.string();
                                break;
                            case 2:
                                message.fetchAll = reader.bool();
                                break;
                            case 3:
                                message.filterField = reader.int32();
                                break;
                            case 4:
                                if (!(message.inValues && message.inValues.length))
                                    message.inValues = [];
                                message.inValues.push(reader.string());
                                break;
                            case 5:
                                message.ignoreCase = reader.bool();
                                break;
                            case 6:
                                message.order = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDFilterCondition message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.referencedata.RDFilterCondition} RDFilterCondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDFilterCondition.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDFilterCondition message.
                     * @function verify
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDFilterCondition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            if (!$util.isString(message.itemType))
                                return "itemType: string expected";
                        if (message.fetchAll != null && message.hasOwnProperty("fetchAll"))
                            if (typeof message.fetchAll !== "boolean")
                                return "fetchAll: boolean expected";
                        if (message.filterField != null && message.hasOwnProperty("filterField"))
                            switch (message.filterField) {
                            default:
                                return "filterField: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.inValues != null && message.hasOwnProperty("inValues")) {
                            if (!Array.isArray(message.inValues))
                                return "inValues: array expected";
                            for (var i = 0; i < message.inValues.length; ++i)
                                if (!$util.isString(message.inValues[i]))
                                    return "inValues: string[] expected";
                        }
                        if (message.ignoreCase != null && message.hasOwnProperty("ignoreCase"))
                            if (typeof message.ignoreCase !== "boolean")
                                return "ignoreCase: boolean expected";
                        if (message.order != null && message.hasOwnProperty("order"))
                            switch (message.order) {
                            default:
                                return "order: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a RDFilterCondition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.referencedata.RDFilterCondition} RDFilterCondition
                     */
                    RDFilterCondition.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.referencedata.RDFilterCondition)
                            return object;
                        var message = new $root.theplant.ec.service.referencedata.RDFilterCondition();
                        if (object.itemType != null)
                            message.itemType = String(object.itemType);
                        if (object.fetchAll != null)
                            message.fetchAll = Boolean(object.fetchAll);
                        switch (object.filterField) {
                        case "RD_CODE":
                        case 0:
                            message.filterField = 0;
                            break;
                        case "RD_SLUG":
                        case 1:
                            message.filterField = 1;
                            break;
                        case "RD_NAME":
                        case 2:
                            message.filterField = 2;
                            break;
                        }
                        if (object.inValues) {
                            if (!Array.isArray(object.inValues))
                                throw TypeError(".theplant.ec.service.referencedata.RDFilterCondition.inValues: array expected");
                            message.inValues = [];
                            for (var i = 0; i < object.inValues.length; ++i)
                                message.inValues[i] = String(object.inValues[i]);
                        }
                        if (object.ignoreCase != null)
                            message.ignoreCase = Boolean(object.ignoreCase);
                        switch (object.order) {
                        case "POSITION_ORDER":
                        case 0:
                            message.order = 0;
                            break;
                        case "VALUES_ORDER":
                        case 1:
                            message.order = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDFilterCondition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @static
                     * @param {theplant.ec.service.referencedata.RDFilterCondition} message RDFilterCondition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDFilterCondition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.inValues = [];
                        if (options.defaults) {
                            object.itemType = "";
                            object.fetchAll = false;
                            object.filterField = options.enums === String ? "RD_CODE" : 0;
                            object.ignoreCase = false;
                            object.order = options.enums === String ? "POSITION_ORDER" : 0;
                        }
                        if (message.itemType != null && message.hasOwnProperty("itemType"))
                            object.itemType = message.itemType;
                        if (message.fetchAll != null && message.hasOwnProperty("fetchAll"))
                            object.fetchAll = message.fetchAll;
                        if (message.filterField != null && message.hasOwnProperty("filterField"))
                            object.filterField = options.enums === String ? $root.theplant.ec.service.referencedata.RDFilterField[message.filterField] : message.filterField;
                        if (message.inValues && message.inValues.length) {
                            object.inValues = [];
                            for (var j = 0; j < message.inValues.length; ++j)
                                object.inValues[j] = message.inValues[j];
                        }
                        if (message.ignoreCase != null && message.hasOwnProperty("ignoreCase"))
                            object.ignoreCase = message.ignoreCase;
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = options.enums === String ? $root.theplant.ec.service.referencedata.RDItemOrder[message.order] : message.order;
                        return object;
                    };

                    /**
                     * Converts this RDFilterCondition to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.referencedata.RDFilterCondition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDFilterCondition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDFilterCondition;
                })();

                referencedata.RDGroup = (function() {

                    /**
                     * Properties of a RDGroup.
                     * @memberof theplant.ec.service.referencedata
                     * @interface IRDGroup
                     * @property {theplant.ec.service.referencedata.IRDItem|null} [info] RDGroup info
                     * @property {Array.<theplant.ec.service.referencedata.IRDItem>|null} [items] RDGroup items
                     */

                    /**
                     * Constructs a new RDGroup.
                     * @memberof theplant.ec.service.referencedata
                     * @classdesc Represents a RDGroup.
                     * @implements IRDGroup
                     * @constructor
                     * @param {theplant.ec.service.referencedata.IRDGroup=} [properties] Properties to set
                     */
                    function RDGroup(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDGroup info.
                     * @member {theplant.ec.service.referencedata.IRDItem|null|undefined} info
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @instance
                     */
                    RDGroup.prototype.info = null;

                    /**
                     * RDGroup items.
                     * @member {Array.<theplant.ec.service.referencedata.IRDItem>} items
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @instance
                     */
                    RDGroup.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new RDGroup instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDGroup=} [properties] Properties to set
                     * @returns {theplant.ec.service.referencedata.RDGroup} RDGroup instance
                     */
                    RDGroup.create = function create(properties) {
                        return new RDGroup(properties);
                    };

                    /**
                     * Encodes the specified RDGroup message. Does not implicitly {@link theplant.ec.service.referencedata.RDGroup.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDGroup} message RDGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDGroup.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.info != null && message.hasOwnProperty("info"))
                            $root.theplant.ec.service.referencedata.RDItem.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.referencedata.RDItem.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RDGroup message, length delimited. Does not implicitly {@link theplant.ec.service.referencedata.RDGroup.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDGroup} message RDGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDGroup.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDGroup message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.referencedata.RDGroup} RDGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDGroup.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.referencedata.RDGroup();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.info = $root.theplant.ec.service.referencedata.RDItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.referencedata.RDItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDGroup message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.referencedata.RDGroup} RDGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDGroup.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDGroup message.
                     * @function verify
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDGroup.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.info != null && message.hasOwnProperty("info")) {
                            var error = $root.theplant.ec.service.referencedata.RDItem.verify(message.info);
                            if (error)
                                return "info." + error;
                        }
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a RDGroup message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.referencedata.RDGroup} RDGroup
                     */
                    RDGroup.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.referencedata.RDGroup)
                            return object;
                        var message = new $root.theplant.ec.service.referencedata.RDGroup();
                        if (object.info != null) {
                            if (typeof object.info !== "object")
                                throw TypeError(".theplant.ec.service.referencedata.RDGroup.info: object expected");
                            message.info = $root.theplant.ec.service.referencedata.RDItem.fromObject(object.info);
                        }
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.referencedata.RDGroup.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.referencedata.RDGroup.items: object expected");
                                message.items[i] = $root.theplant.ec.service.referencedata.RDItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDGroup message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @static
                     * @param {theplant.ec.service.referencedata.RDGroup} message RDGroup
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDGroup.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults)
                            object.info = null;
                        if (message.info != null && message.hasOwnProperty("info"))
                            object.info = $root.theplant.ec.service.referencedata.RDItem.toObject(message.info, options);
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.referencedata.RDItem.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this RDGroup to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.referencedata.RDGroup
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDGroup.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDGroup;
                })();

                referencedata.RDImage = (function() {

                    /**
                     * Properties of a RDImage.
                     * @memberof theplant.ec.service.referencedata
                     * @interface IRDImage
                     * @property {string|null} [usingSize] RDImage usingSize
                     * @property {string|null} [purpose] RDImage purpose
                     * @property {string|null} [url] RDImage url
                     */

                    /**
                     * Constructs a new RDImage.
                     * @memberof theplant.ec.service.referencedata
                     * @classdesc Represents a RDImage.
                     * @implements IRDImage
                     * @constructor
                     * @param {theplant.ec.service.referencedata.IRDImage=} [properties] Properties to set
                     */
                    function RDImage(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RDImage usingSize.
                     * @member {string} usingSize
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @instance
                     */
                    RDImage.prototype.usingSize = "";

                    /**
                     * RDImage purpose.
                     * @member {string} purpose
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @instance
                     */
                    RDImage.prototype.purpose = "";

                    /**
                     * RDImage url.
                     * @member {string} url
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @instance
                     */
                    RDImage.prototype.url = "";

                    /**
                     * Creates a new RDImage instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDImage=} [properties] Properties to set
                     * @returns {theplant.ec.service.referencedata.RDImage} RDImage instance
                     */
                    RDImage.create = function create(properties) {
                        return new RDImage(properties);
                    };

                    /**
                     * Encodes the specified RDImage message. Does not implicitly {@link theplant.ec.service.referencedata.RDImage.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDImage} message RDImage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDImage.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.usingSize != null && message.hasOwnProperty("usingSize"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.usingSize);
                        if (message.url != null && message.hasOwnProperty("url"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                        if (message.purpose != null && message.hasOwnProperty("purpose"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.purpose);
                        return writer;
                    };

                    /**
                     * Encodes the specified RDImage message, length delimited. Does not implicitly {@link theplant.ec.service.referencedata.RDImage.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {theplant.ec.service.referencedata.IRDImage} message RDImage message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RDImage.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RDImage message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.referencedata.RDImage} RDImage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDImage.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.referencedata.RDImage();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.usingSize = reader.string();
                                break;
                            case 3:
                                message.purpose = reader.string();
                                break;
                            case 2:
                                message.url = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RDImage message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.referencedata.RDImage} RDImage
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RDImage.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RDImage message.
                     * @function verify
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RDImage.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.usingSize != null && message.hasOwnProperty("usingSize"))
                            if (!$util.isString(message.usingSize))
                                return "usingSize: string expected";
                        if (message.purpose != null && message.hasOwnProperty("purpose"))
                            if (!$util.isString(message.purpose))
                                return "purpose: string expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        return null;
                    };

                    /**
                     * Creates a RDImage message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.referencedata.RDImage} RDImage
                     */
                    RDImage.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.referencedata.RDImage)
                            return object;
                        var message = new $root.theplant.ec.service.referencedata.RDImage();
                        if (object.usingSize != null)
                            message.usingSize = String(object.usingSize);
                        if (object.purpose != null)
                            message.purpose = String(object.purpose);
                        if (object.url != null)
                            message.url = String(object.url);
                        return message;
                    };

                    /**
                     * Creates a plain object from a RDImage message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @static
                     * @param {theplant.ec.service.referencedata.RDImage} message RDImage
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RDImage.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.usingSize = "";
                            object.url = "";
                            object.purpose = "";
                        }
                        if (message.usingSize != null && message.hasOwnProperty("usingSize"))
                            object.usingSize = message.usingSize;
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        if (message.purpose != null && message.hasOwnProperty("purpose"))
                            object.purpose = message.purpose;
                        return object;
                    };

                    /**
                     * Converts this RDImage to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.referencedata.RDImage
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RDImage.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RDImage;
                })();

                return referencedata;
            })();

            service.accounting = (function() {

                /**
                 * Namespace accounting.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var accounting = {};

                accounting.FeeSetting = (function() {

                    /**
                     * Properties of a FeeSetting.
                     * @memberof theplant.ec.service.accounting
                     * @interface IFeeSetting
                     * @property {number|Long|null} [taxRate] FeeSetting taxRate
                     * @property {number|Long|null} [giftWrappingFee] FeeSetting giftWrappingFee
                     * @property {number|Long|null} [deliveryFee] FeeSetting deliveryFee
                     * @property {number|Long|null} [codFee] FeeSetting codFee
                     * @property {number|Long|null} [hemmingPantsFee] FeeSetting hemmingPantsFee
                     * @property {string|null} [currency] FeeSetting currency
                     */

                    /**
                     * Constructs a new FeeSetting.
                     * @memberof theplant.ec.service.accounting
                     * @classdesc Represents a FeeSetting.
                     * @implements IFeeSetting
                     * @constructor
                     * @param {theplant.ec.service.accounting.IFeeSetting=} [properties] Properties to set
                     */
                    function FeeSetting(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FeeSetting taxRate.
                     * @member {number|Long} taxRate
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     */
                    FeeSetting.prototype.taxRate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * FeeSetting giftWrappingFee.
                     * @member {number|Long} giftWrappingFee
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     */
                    FeeSetting.prototype.giftWrappingFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * FeeSetting deliveryFee.
                     * @member {number|Long} deliveryFee
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     */
                    FeeSetting.prototype.deliveryFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * FeeSetting codFee.
                     * @member {number|Long} codFee
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     */
                    FeeSetting.prototype.codFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * FeeSetting hemmingPantsFee.
                     * @member {number|Long} hemmingPantsFee
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     */
                    FeeSetting.prototype.hemmingPantsFee = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * FeeSetting currency.
                     * @member {string} currency
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     */
                    FeeSetting.prototype.currency = "";

                    /**
                     * Creates a new FeeSetting instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {theplant.ec.service.accounting.IFeeSetting=} [properties] Properties to set
                     * @returns {theplant.ec.service.accounting.FeeSetting} FeeSetting instance
                     */
                    FeeSetting.create = function create(properties) {
                        return new FeeSetting(properties);
                    };

                    /**
                     * Encodes the specified FeeSetting message. Does not implicitly {@link theplant.ec.service.accounting.FeeSetting.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {theplant.ec.service.accounting.IFeeSetting} message FeeSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FeeSetting.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.taxRate);
                        if (message.giftWrappingFee != null && message.hasOwnProperty("giftWrappingFee"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.giftWrappingFee);
                        if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.deliveryFee);
                        if (message.codFee != null && message.hasOwnProperty("codFee"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.codFee);
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.currency);
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.hemmingPantsFee);
                        return writer;
                    };

                    /**
                     * Encodes the specified FeeSetting message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.FeeSetting.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {theplant.ec.service.accounting.IFeeSetting} message FeeSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FeeSetting.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FeeSetting message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.accounting.FeeSetting} FeeSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FeeSetting.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.FeeSetting();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.taxRate = reader.uint64();
                                break;
                            case 2:
                                message.giftWrappingFee = reader.uint64();
                                break;
                            case 3:
                                message.deliveryFee = reader.uint64();
                                break;
                            case 4:
                                message.codFee = reader.uint64();
                                break;
                            case 6:
                                message.hemmingPantsFee = reader.uint64();
                                break;
                            case 5:
                                message.currency = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FeeSetting message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.accounting.FeeSetting} FeeSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FeeSetting.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FeeSetting message.
                     * @function verify
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FeeSetting.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                            if (!$util.isInteger(message.taxRate) && !(message.taxRate && $util.isInteger(message.taxRate.low) && $util.isInteger(message.taxRate.high)))
                                return "taxRate: integer|Long expected";
                        if (message.giftWrappingFee != null && message.hasOwnProperty("giftWrappingFee"))
                            if (!$util.isInteger(message.giftWrappingFee) && !(message.giftWrappingFee && $util.isInteger(message.giftWrappingFee.low) && $util.isInteger(message.giftWrappingFee.high)))
                                return "giftWrappingFee: integer|Long expected";
                        if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                            if (!$util.isInteger(message.deliveryFee) && !(message.deliveryFee && $util.isInteger(message.deliveryFee.low) && $util.isInteger(message.deliveryFee.high)))
                                return "deliveryFee: integer|Long expected";
                        if (message.codFee != null && message.hasOwnProperty("codFee"))
                            if (!$util.isInteger(message.codFee) && !(message.codFee && $util.isInteger(message.codFee.low) && $util.isInteger(message.codFee.high)))
                                return "codFee: integer|Long expected";
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            if (!$util.isInteger(message.hemmingPantsFee) && !(message.hemmingPantsFee && $util.isInteger(message.hemmingPantsFee.low) && $util.isInteger(message.hemmingPantsFee.high)))
                                return "hemmingPantsFee: integer|Long expected";
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            if (!$util.isString(message.currency))
                                return "currency: string expected";
                        return null;
                    };

                    /**
                     * Creates a FeeSetting message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.accounting.FeeSetting} FeeSetting
                     */
                    FeeSetting.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.accounting.FeeSetting)
                            return object;
                        var message = new $root.theplant.ec.service.accounting.FeeSetting();
                        if (object.taxRate != null)
                            if ($util.Long)
                                (message.taxRate = $util.Long.fromValue(object.taxRate)).unsigned = true;
                            else if (typeof object.taxRate === "string")
                                message.taxRate = parseInt(object.taxRate, 10);
                            else if (typeof object.taxRate === "number")
                                message.taxRate = object.taxRate;
                            else if (typeof object.taxRate === "object")
                                message.taxRate = new $util.LongBits(object.taxRate.low >>> 0, object.taxRate.high >>> 0).toNumber(true);
                        if (object.giftWrappingFee != null)
                            if ($util.Long)
                                (message.giftWrappingFee = $util.Long.fromValue(object.giftWrappingFee)).unsigned = true;
                            else if (typeof object.giftWrappingFee === "string")
                                message.giftWrappingFee = parseInt(object.giftWrappingFee, 10);
                            else if (typeof object.giftWrappingFee === "number")
                                message.giftWrappingFee = object.giftWrappingFee;
                            else if (typeof object.giftWrappingFee === "object")
                                message.giftWrappingFee = new $util.LongBits(object.giftWrappingFee.low >>> 0, object.giftWrappingFee.high >>> 0).toNumber(true);
                        if (object.deliveryFee != null)
                            if ($util.Long)
                                (message.deliveryFee = $util.Long.fromValue(object.deliveryFee)).unsigned = true;
                            else if (typeof object.deliveryFee === "string")
                                message.deliveryFee = parseInt(object.deliveryFee, 10);
                            else if (typeof object.deliveryFee === "number")
                                message.deliveryFee = object.deliveryFee;
                            else if (typeof object.deliveryFee === "object")
                                message.deliveryFee = new $util.LongBits(object.deliveryFee.low >>> 0, object.deliveryFee.high >>> 0).toNumber(true);
                        if (object.codFee != null)
                            if ($util.Long)
                                (message.codFee = $util.Long.fromValue(object.codFee)).unsigned = true;
                            else if (typeof object.codFee === "string")
                                message.codFee = parseInt(object.codFee, 10);
                            else if (typeof object.codFee === "number")
                                message.codFee = object.codFee;
                            else if (typeof object.codFee === "object")
                                message.codFee = new $util.LongBits(object.codFee.low >>> 0, object.codFee.high >>> 0).toNumber(true);
                        if (object.hemmingPantsFee != null)
                            if ($util.Long)
                                (message.hemmingPantsFee = $util.Long.fromValue(object.hemmingPantsFee)).unsigned = true;
                            else if (typeof object.hemmingPantsFee === "string")
                                message.hemmingPantsFee = parseInt(object.hemmingPantsFee, 10);
                            else if (typeof object.hemmingPantsFee === "number")
                                message.hemmingPantsFee = object.hemmingPantsFee;
                            else if (typeof object.hemmingPantsFee === "object")
                                message.hemmingPantsFee = new $util.LongBits(object.hemmingPantsFee.low >>> 0, object.hemmingPantsFee.high >>> 0).toNumber(true);
                        if (object.currency != null)
                            message.currency = String(object.currency);
                        return message;
                    };

                    /**
                     * Creates a plain object from a FeeSetting message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @static
                     * @param {theplant.ec.service.accounting.FeeSetting} message FeeSetting
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FeeSetting.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.taxRate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.taxRate = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.giftWrappingFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.giftWrappingFee = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.deliveryFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.deliveryFee = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.codFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.codFee = options.longs === String ? "0" : 0;
                            object.currency = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.hemmingPantsFee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.hemmingPantsFee = options.longs === String ? "0" : 0;
                        }
                        if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                            if (typeof message.taxRate === "number")
                                object.taxRate = options.longs === String ? String(message.taxRate) : message.taxRate;
                            else
                                object.taxRate = options.longs === String ? $util.Long.prototype.toString.call(message.taxRate) : options.longs === Number ? new $util.LongBits(message.taxRate.low >>> 0, message.taxRate.high >>> 0).toNumber(true) : message.taxRate;
                        if (message.giftWrappingFee != null && message.hasOwnProperty("giftWrappingFee"))
                            if (typeof message.giftWrappingFee === "number")
                                object.giftWrappingFee = options.longs === String ? String(message.giftWrappingFee) : message.giftWrappingFee;
                            else
                                object.giftWrappingFee = options.longs === String ? $util.Long.prototype.toString.call(message.giftWrappingFee) : options.longs === Number ? new $util.LongBits(message.giftWrappingFee.low >>> 0, message.giftWrappingFee.high >>> 0).toNumber(true) : message.giftWrappingFee;
                        if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                            if (typeof message.deliveryFee === "number")
                                object.deliveryFee = options.longs === String ? String(message.deliveryFee) : message.deliveryFee;
                            else
                                object.deliveryFee = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryFee) : options.longs === Number ? new $util.LongBits(message.deliveryFee.low >>> 0, message.deliveryFee.high >>> 0).toNumber(true) : message.deliveryFee;
                        if (message.codFee != null && message.hasOwnProperty("codFee"))
                            if (typeof message.codFee === "number")
                                object.codFee = options.longs === String ? String(message.codFee) : message.codFee;
                            else
                                object.codFee = options.longs === String ? $util.Long.prototype.toString.call(message.codFee) : options.longs === Number ? new $util.LongBits(message.codFee.low >>> 0, message.codFee.high >>> 0).toNumber(true) : message.codFee;
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            object.currency = message.currency;
                        if (message.hemmingPantsFee != null && message.hasOwnProperty("hemmingPantsFee"))
                            if (typeof message.hemmingPantsFee === "number")
                                object.hemmingPantsFee = options.longs === String ? String(message.hemmingPantsFee) : message.hemmingPantsFee;
                            else
                                object.hemmingPantsFee = options.longs === String ? $util.Long.prototype.toString.call(message.hemmingPantsFee) : options.longs === Number ? new $util.LongBits(message.hemmingPantsFee.low >>> 0, message.hemmingPantsFee.high >>> 0).toNumber(true) : message.hemmingPantsFee;
                        return object;
                    };

                    /**
                     * Converts this FeeSetting to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.accounting.FeeSetting
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FeeSetting.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FeeSetting;
                })();

                accounting.ItemSumUp = (function() {

                    /**
                     * Properties of an ItemSumUp.
                     * @memberof theplant.ec.service.accounting
                     * @interface IItemSumUp
                     * @property {Array.<theplant.ec.service.accounting.IDiscount>|null} [discounts] ItemSumUp discounts
                     * @property {Array.<theplant.ec.service.accounting.IDiscount>|null} [relatedDiscounts] ItemSumUp relatedDiscounts
                     * @property {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount|null} [prime] ItemSumUp prime
                     * @property {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount|null} [selling] ItemSumUp selling
                     * @property {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount|null} [discounted] ItemSumUp discounted
                     * @property {number|Long|null} [calculatedDiscountRate] ItemSumUp calculatedDiscountRate
                     * @property {theplant.ec.service.accounting.ItemSumUp.IHemmingPants|null} [hemmingPants] ItemSumUp hemmingPants
                     */

                    /**
                     * Constructs a new ItemSumUp.
                     * @memberof theplant.ec.service.accounting
                     * @classdesc Represents an ItemSumUp.
                     * @implements IItemSumUp
                     * @constructor
                     * @param {theplant.ec.service.accounting.IItemSumUp=} [properties] Properties to set
                     */
                    function ItemSumUp(properties) {
                        this.discounts = [];
                        this.relatedDiscounts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ItemSumUp discounts.
                     * @member {Array.<theplant.ec.service.accounting.IDiscount>} discounts
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.discounts = $util.emptyArray;

                    /**
                     * ItemSumUp relatedDiscounts.
                     * @member {Array.<theplant.ec.service.accounting.IDiscount>} relatedDiscounts
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.relatedDiscounts = $util.emptyArray;

                    /**
                     * ItemSumUp prime.
                     * @member {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount|null|undefined} prime
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.prime = null;

                    /**
                     * ItemSumUp selling.
                     * @member {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount|null|undefined} selling
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.selling = null;

                    /**
                     * ItemSumUp discounted.
                     * @member {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount|null|undefined} discounted
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.discounted = null;

                    /**
                     * ItemSumUp calculatedDiscountRate.
                     * @member {number|Long} calculatedDiscountRate
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.calculatedDiscountRate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * ItemSumUp hemmingPants.
                     * @member {theplant.ec.service.accounting.ItemSumUp.IHemmingPants|null|undefined} hemmingPants
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     */
                    ItemSumUp.prototype.hemmingPants = null;

                    /**
                     * Creates a new ItemSumUp instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {theplant.ec.service.accounting.IItemSumUp=} [properties] Properties to set
                     * @returns {theplant.ec.service.accounting.ItemSumUp} ItemSumUp instance
                     */
                    ItemSumUp.create = function create(properties) {
                        return new ItemSumUp(properties);
                    };

                    /**
                     * Encodes the specified ItemSumUp message. Does not implicitly {@link theplant.ec.service.accounting.ItemSumUp.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {theplant.ec.service.accounting.IItemSumUp} message ItemSumUp message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ItemSumUp.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.discounts != null && message.discounts.length)
                            for (var i = 0; i < message.discounts.length; ++i)
                                $root.theplant.ec.service.accounting.Discount.encode(message.discounts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.prime != null && message.hasOwnProperty("prime"))
                            $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.encode(message.prime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.selling != null && message.hasOwnProperty("selling"))
                            $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.encode(message.selling, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.discounted != null && message.hasOwnProperty("discounted"))
                            $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.encode(message.discounted, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.calculatedDiscountRate != null && message.hasOwnProperty("calculatedDiscountRate"))
                            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.calculatedDiscountRate);
                        if (message.relatedDiscounts != null && message.relatedDiscounts.length)
                            for (var i = 0; i < message.relatedDiscounts.length; ++i)
                                $root.theplant.ec.service.accounting.Discount.encode(message.relatedDiscounts[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.hemmingPants != null && message.hasOwnProperty("hemmingPants"))
                            $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants.encode(message.hemmingPants, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ItemSumUp message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.ItemSumUp.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {theplant.ec.service.accounting.IItemSumUp} message ItemSumUp message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ItemSumUp.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ItemSumUp message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.accounting.ItemSumUp} ItemSumUp
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ItemSumUp.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.ItemSumUp();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.discounts && message.discounts.length))
                                    message.discounts = [];
                                message.discounts.push($root.theplant.ec.service.accounting.Discount.decode(reader, reader.uint32()));
                                break;
                            case 6:
                                if (!(message.relatedDiscounts && message.relatedDiscounts.length))
                                    message.relatedDiscounts = [];
                                message.relatedDiscounts.push($root.theplant.ec.service.accounting.Discount.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.prime = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.selling = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.discounted = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.calculatedDiscountRate = reader.uint64();
                                break;
                            case 7:
                                message.hemmingPants = $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ItemSumUp message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.accounting.ItemSumUp} ItemSumUp
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ItemSumUp.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ItemSumUp message.
                     * @function verify
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ItemSumUp.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.discounts != null && message.hasOwnProperty("discounts")) {
                            if (!Array.isArray(message.discounts))
                                return "discounts: array expected";
                            for (var i = 0; i < message.discounts.length; ++i) {
                                var error = $root.theplant.ec.service.accounting.Discount.verify(message.discounts[i]);
                                if (error)
                                    return "discounts." + error;
                            }
                        }
                        if (message.relatedDiscounts != null && message.hasOwnProperty("relatedDiscounts")) {
                            if (!Array.isArray(message.relatedDiscounts))
                                return "relatedDiscounts: array expected";
                            for (var i = 0; i < message.relatedDiscounts.length; ++i) {
                                var error = $root.theplant.ec.service.accounting.Discount.verify(message.relatedDiscounts[i]);
                                if (error)
                                    return "relatedDiscounts." + error;
                            }
                        }
                        if (message.prime != null && message.hasOwnProperty("prime")) {
                            var error = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.verify(message.prime);
                            if (error)
                                return "prime." + error;
                        }
                        if (message.selling != null && message.hasOwnProperty("selling")) {
                            var error = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.verify(message.selling);
                            if (error)
                                return "selling." + error;
                        }
                        if (message.discounted != null && message.hasOwnProperty("discounted")) {
                            var error = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.verify(message.discounted);
                            if (error)
                                return "discounted." + error;
                        }
                        if (message.calculatedDiscountRate != null && message.hasOwnProperty("calculatedDiscountRate"))
                            if (!$util.isInteger(message.calculatedDiscountRate) && !(message.calculatedDiscountRate && $util.isInteger(message.calculatedDiscountRate.low) && $util.isInteger(message.calculatedDiscountRate.high)))
                                return "calculatedDiscountRate: integer|Long expected";
                        if (message.hemmingPants != null && message.hasOwnProperty("hemmingPants")) {
                            var error = $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants.verify(message.hemmingPants);
                            if (error)
                                return "hemmingPants." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an ItemSumUp message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.accounting.ItemSumUp} ItemSumUp
                     */
                    ItemSumUp.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.accounting.ItemSumUp)
                            return object;
                        var message = new $root.theplant.ec.service.accounting.ItemSumUp();
                        if (object.discounts) {
                            if (!Array.isArray(object.discounts))
                                throw TypeError(".theplant.ec.service.accounting.ItemSumUp.discounts: array expected");
                            message.discounts = [];
                            for (var i = 0; i < object.discounts.length; ++i) {
                                if (typeof object.discounts[i] !== "object")
                                    throw TypeError(".theplant.ec.service.accounting.ItemSumUp.discounts: object expected");
                                message.discounts[i] = $root.theplant.ec.service.accounting.Discount.fromObject(object.discounts[i]);
                            }
                        }
                        if (object.relatedDiscounts) {
                            if (!Array.isArray(object.relatedDiscounts))
                                throw TypeError(".theplant.ec.service.accounting.ItemSumUp.relatedDiscounts: array expected");
                            message.relatedDiscounts = [];
                            for (var i = 0; i < object.relatedDiscounts.length; ++i) {
                                if (typeof object.relatedDiscounts[i] !== "object")
                                    throw TypeError(".theplant.ec.service.accounting.ItemSumUp.relatedDiscounts: object expected");
                                message.relatedDiscounts[i] = $root.theplant.ec.service.accounting.Discount.fromObject(object.relatedDiscounts[i]);
                            }
                        }
                        if (object.prime != null) {
                            if (typeof object.prime !== "object")
                                throw TypeError(".theplant.ec.service.accounting.ItemSumUp.prime: object expected");
                            message.prime = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.fromObject(object.prime);
                        }
                        if (object.selling != null) {
                            if (typeof object.selling !== "object")
                                throw TypeError(".theplant.ec.service.accounting.ItemSumUp.selling: object expected");
                            message.selling = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.fromObject(object.selling);
                        }
                        if (object.discounted != null) {
                            if (typeof object.discounted !== "object")
                                throw TypeError(".theplant.ec.service.accounting.ItemSumUp.discounted: object expected");
                            message.discounted = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.fromObject(object.discounted);
                        }
                        if (object.calculatedDiscountRate != null)
                            if ($util.Long)
                                (message.calculatedDiscountRate = $util.Long.fromValue(object.calculatedDiscountRate)).unsigned = true;
                            else if (typeof object.calculatedDiscountRate === "string")
                                message.calculatedDiscountRate = parseInt(object.calculatedDiscountRate, 10);
                            else if (typeof object.calculatedDiscountRate === "number")
                                message.calculatedDiscountRate = object.calculatedDiscountRate;
                            else if (typeof object.calculatedDiscountRate === "object")
                                message.calculatedDiscountRate = new $util.LongBits(object.calculatedDiscountRate.low >>> 0, object.calculatedDiscountRate.high >>> 0).toNumber(true);
                        if (object.hemmingPants != null) {
                            if (typeof object.hemmingPants !== "object")
                                throw TypeError(".theplant.ec.service.accounting.ItemSumUp.hemmingPants: object expected");
                            message.hemmingPants = $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants.fromObject(object.hemmingPants);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an ItemSumUp message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @static
                     * @param {theplant.ec.service.accounting.ItemSumUp} message ItemSumUp
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ItemSumUp.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.discounts = [];
                            object.relatedDiscounts = [];
                        }
                        if (options.defaults) {
                            object.prime = null;
                            object.selling = null;
                            object.discounted = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.calculatedDiscountRate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.calculatedDiscountRate = options.longs === String ? "0" : 0;
                            object.hemmingPants = null;
                        }
                        if (message.discounts && message.discounts.length) {
                            object.discounts = [];
                            for (var j = 0; j < message.discounts.length; ++j)
                                object.discounts[j] = $root.theplant.ec.service.accounting.Discount.toObject(message.discounts[j], options);
                        }
                        if (message.prime != null && message.hasOwnProperty("prime"))
                            object.prime = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.toObject(message.prime, options);
                        if (message.selling != null && message.hasOwnProperty("selling"))
                            object.selling = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.toObject(message.selling, options);
                        if (message.discounted != null && message.hasOwnProperty("discounted"))
                            object.discounted = $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.toObject(message.discounted, options);
                        if (message.calculatedDiscountRate != null && message.hasOwnProperty("calculatedDiscountRate"))
                            if (typeof message.calculatedDiscountRate === "number")
                                object.calculatedDiscountRate = options.longs === String ? String(message.calculatedDiscountRate) : message.calculatedDiscountRate;
                            else
                                object.calculatedDiscountRate = options.longs === String ? $util.Long.prototype.toString.call(message.calculatedDiscountRate) : options.longs === Number ? new $util.LongBits(message.calculatedDiscountRate.low >>> 0, message.calculatedDiscountRate.high >>> 0).toNumber(true) : message.calculatedDiscountRate;
                        if (message.relatedDiscounts && message.relatedDiscounts.length) {
                            object.relatedDiscounts = [];
                            for (var j = 0; j < message.relatedDiscounts.length; ++j)
                                object.relatedDiscounts[j] = $root.theplant.ec.service.accounting.Discount.toObject(message.relatedDiscounts[j], options);
                        }
                        if (message.hemmingPants != null && message.hasOwnProperty("hemmingPants"))
                            object.hemmingPants = $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants.toObject(message.hemmingPants, options);
                        return object;
                    };

                    /**
                     * Converts this ItemSumUp to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.accounting.ItemSumUp
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ItemSumUp.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    ItemSumUp.PriceWithAmount = (function() {

                        /**
                         * Properties of a PriceWithAmount.
                         * @memberof theplant.ec.service.accounting.ItemSumUp
                         * @interface IPriceWithAmount
                         * @property {number|Long|null} [price] PriceWithAmount price
                         * @property {number|Long|null} [priceTax] PriceWithAmount priceTax
                         * @property {number|Long|null} [priceWithTax] PriceWithAmount priceWithTax
                         * @property {number|Long|null} [amount] PriceWithAmount amount
                         * @property {number|Long|null} [amountTax] PriceWithAmount amountTax
                         * @property {number|Long|null} [amountWithTax] PriceWithAmount amountWithTax
                         */

                        /**
                         * Constructs a new PriceWithAmount.
                         * @memberof theplant.ec.service.accounting.ItemSumUp
                         * @classdesc Represents a PriceWithAmount.
                         * @implements IPriceWithAmount
                         * @constructor
                         * @param {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount=} [properties] Properties to set
                         */
                        function PriceWithAmount(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * PriceWithAmount price.
                         * @member {number|Long} price
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         */
                        PriceWithAmount.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * PriceWithAmount priceTax.
                         * @member {number|Long} priceTax
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         */
                        PriceWithAmount.prototype.priceTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * PriceWithAmount priceWithTax.
                         * @member {number|Long} priceWithTax
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         */
                        PriceWithAmount.prototype.priceWithTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * PriceWithAmount amount.
                         * @member {number|Long} amount
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         */
                        PriceWithAmount.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * PriceWithAmount amountTax.
                         * @member {number|Long} amountTax
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         */
                        PriceWithAmount.prototype.amountTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * PriceWithAmount amountWithTax.
                         * @member {number|Long} amountWithTax
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         */
                        PriceWithAmount.prototype.amountWithTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * Creates a new PriceWithAmount instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount=} [properties] Properties to set
                         * @returns {theplant.ec.service.accounting.ItemSumUp.PriceWithAmount} PriceWithAmount instance
                         */
                        PriceWithAmount.create = function create(properties) {
                            return new PriceWithAmount(properties);
                        };

                        /**
                         * Encodes the specified PriceWithAmount message. Does not implicitly {@link theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount} message PriceWithAmount message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PriceWithAmount.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.price != null && message.hasOwnProperty("price"))
                                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.price);
                            if (message.priceTax != null && message.hasOwnProperty("priceTax"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.priceTax);
                            if (message.priceWithTax != null && message.hasOwnProperty("priceWithTax"))
                                writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.priceWithTax);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.amount);
                            if (message.amountTax != null && message.hasOwnProperty("amountTax"))
                                writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.amountTax);
                            if (message.amountWithTax != null && message.hasOwnProperty("amountWithTax"))
                                writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.amountWithTax);
                            return writer;
                        };

                        /**
                         * Encodes the specified PriceWithAmount message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.ItemSumUp.PriceWithAmount.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.IPriceWithAmount} message PriceWithAmount message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        PriceWithAmount.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a PriceWithAmount message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.accounting.ItemSumUp.PriceWithAmount} PriceWithAmount
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PriceWithAmount.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.price = reader.uint64();
                                    break;
                                case 2:
                                    message.priceTax = reader.uint64();
                                    break;
                                case 3:
                                    message.priceWithTax = reader.uint64();
                                    break;
                                case 4:
                                    message.amount = reader.uint64();
                                    break;
                                case 5:
                                    message.amountTax = reader.uint64();
                                    break;
                                case 6:
                                    message.amountWithTax = reader.uint64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a PriceWithAmount message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.accounting.ItemSumUp.PriceWithAmount} PriceWithAmount
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        PriceWithAmount.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a PriceWithAmount message.
                         * @function verify
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        PriceWithAmount.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.price != null && message.hasOwnProperty("price"))
                                if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                    return "price: integer|Long expected";
                            if (message.priceTax != null && message.hasOwnProperty("priceTax"))
                                if (!$util.isInteger(message.priceTax) && !(message.priceTax && $util.isInteger(message.priceTax.low) && $util.isInteger(message.priceTax.high)))
                                    return "priceTax: integer|Long expected";
                            if (message.priceWithTax != null && message.hasOwnProperty("priceWithTax"))
                                if (!$util.isInteger(message.priceWithTax) && !(message.priceWithTax && $util.isInteger(message.priceWithTax.low) && $util.isInteger(message.priceWithTax.high)))
                                    return "priceWithTax: integer|Long expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                                    return "amount: integer|Long expected";
                            if (message.amountTax != null && message.hasOwnProperty("amountTax"))
                                if (!$util.isInteger(message.amountTax) && !(message.amountTax && $util.isInteger(message.amountTax.low) && $util.isInteger(message.amountTax.high)))
                                    return "amountTax: integer|Long expected";
                            if (message.amountWithTax != null && message.hasOwnProperty("amountWithTax"))
                                if (!$util.isInteger(message.amountWithTax) && !(message.amountWithTax && $util.isInteger(message.amountWithTax.low) && $util.isInteger(message.amountWithTax.high)))
                                    return "amountWithTax: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a PriceWithAmount message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.accounting.ItemSumUp.PriceWithAmount} PriceWithAmount
                         */
                        PriceWithAmount.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount)
                                return object;
                            var message = new $root.theplant.ec.service.accounting.ItemSumUp.PriceWithAmount();
                            if (object.price != null)
                                if ($util.Long)
                                    (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                                else if (typeof object.price === "string")
                                    message.price = parseInt(object.price, 10);
                                else if (typeof object.price === "number")
                                    message.price = object.price;
                                else if (typeof object.price === "object")
                                    message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                            if (object.priceTax != null)
                                if ($util.Long)
                                    (message.priceTax = $util.Long.fromValue(object.priceTax)).unsigned = true;
                                else if (typeof object.priceTax === "string")
                                    message.priceTax = parseInt(object.priceTax, 10);
                                else if (typeof object.priceTax === "number")
                                    message.priceTax = object.priceTax;
                                else if (typeof object.priceTax === "object")
                                    message.priceTax = new $util.LongBits(object.priceTax.low >>> 0, object.priceTax.high >>> 0).toNumber(true);
                            if (object.priceWithTax != null)
                                if ($util.Long)
                                    (message.priceWithTax = $util.Long.fromValue(object.priceWithTax)).unsigned = true;
                                else if (typeof object.priceWithTax === "string")
                                    message.priceWithTax = parseInt(object.priceWithTax, 10);
                                else if (typeof object.priceWithTax === "number")
                                    message.priceWithTax = object.priceWithTax;
                                else if (typeof object.priceWithTax === "object")
                                    message.priceWithTax = new $util.LongBits(object.priceWithTax.low >>> 0, object.priceWithTax.high >>> 0).toNumber(true);
                            if (object.amount != null)
                                if ($util.Long)
                                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = true;
                                else if (typeof object.amount === "string")
                                    message.amount = parseInt(object.amount, 10);
                                else if (typeof object.amount === "number")
                                    message.amount = object.amount;
                                else if (typeof object.amount === "object")
                                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber(true);
                            if (object.amountTax != null)
                                if ($util.Long)
                                    (message.amountTax = $util.Long.fromValue(object.amountTax)).unsigned = true;
                                else if (typeof object.amountTax === "string")
                                    message.amountTax = parseInt(object.amountTax, 10);
                                else if (typeof object.amountTax === "number")
                                    message.amountTax = object.amountTax;
                                else if (typeof object.amountTax === "object")
                                    message.amountTax = new $util.LongBits(object.amountTax.low >>> 0, object.amountTax.high >>> 0).toNumber(true);
                            if (object.amountWithTax != null)
                                if ($util.Long)
                                    (message.amountWithTax = $util.Long.fromValue(object.amountWithTax)).unsigned = true;
                                else if (typeof object.amountWithTax === "string")
                                    message.amountWithTax = parseInt(object.amountWithTax, 10);
                                else if (typeof object.amountWithTax === "number")
                                    message.amountWithTax = object.amountWithTax;
                                else if (typeof object.amountWithTax === "object")
                                    message.amountWithTax = new $util.LongBits(object.amountWithTax.low >>> 0, object.amountWithTax.high >>> 0).toNumber(true);
                            return message;
                        };

                        /**
                         * Creates a plain object from a PriceWithAmount message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.PriceWithAmount} message PriceWithAmount
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        PriceWithAmount.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.price = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.priceTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.priceTax = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.priceWithTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.priceWithTax = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.amount = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.amountTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.amountTax = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.amountWithTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.amountWithTax = options.longs === String ? "0" : 0;
                            }
                            if (message.price != null && message.hasOwnProperty("price"))
                                if (typeof message.price === "number")
                                    object.price = options.longs === String ? String(message.price) : message.price;
                                else
                                    object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                            if (message.priceTax != null && message.hasOwnProperty("priceTax"))
                                if (typeof message.priceTax === "number")
                                    object.priceTax = options.longs === String ? String(message.priceTax) : message.priceTax;
                                else
                                    object.priceTax = options.longs === String ? $util.Long.prototype.toString.call(message.priceTax) : options.longs === Number ? new $util.LongBits(message.priceTax.low >>> 0, message.priceTax.high >>> 0).toNumber(true) : message.priceTax;
                            if (message.priceWithTax != null && message.hasOwnProperty("priceWithTax"))
                                if (typeof message.priceWithTax === "number")
                                    object.priceWithTax = options.longs === String ? String(message.priceWithTax) : message.priceWithTax;
                                else
                                    object.priceWithTax = options.longs === String ? $util.Long.prototype.toString.call(message.priceWithTax) : options.longs === Number ? new $util.LongBits(message.priceWithTax.low >>> 0, message.priceWithTax.high >>> 0).toNumber(true) : message.priceWithTax;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount === "number")
                                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                                else
                                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber(true) : message.amount;
                            if (message.amountTax != null && message.hasOwnProperty("amountTax"))
                                if (typeof message.amountTax === "number")
                                    object.amountTax = options.longs === String ? String(message.amountTax) : message.amountTax;
                                else
                                    object.amountTax = options.longs === String ? $util.Long.prototype.toString.call(message.amountTax) : options.longs === Number ? new $util.LongBits(message.amountTax.low >>> 0, message.amountTax.high >>> 0).toNumber(true) : message.amountTax;
                            if (message.amountWithTax != null && message.hasOwnProperty("amountWithTax"))
                                if (typeof message.amountWithTax === "number")
                                    object.amountWithTax = options.longs === String ? String(message.amountWithTax) : message.amountWithTax;
                                else
                                    object.amountWithTax = options.longs === String ? $util.Long.prototype.toString.call(message.amountWithTax) : options.longs === Number ? new $util.LongBits(message.amountWithTax.low >>> 0, message.amountWithTax.high >>> 0).toNumber(true) : message.amountWithTax;
                            return object;
                        };

                        /**
                         * Converts this PriceWithAmount to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.accounting.ItemSumUp.PriceWithAmount
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        PriceWithAmount.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return PriceWithAmount;
                    })();

                    ItemSumUp.HemmingPants = (function() {

                        /**
                         * Properties of a HemmingPants.
                         * @memberof theplant.ec.service.accounting.ItemSumUp
                         * @interface IHemmingPants
                         * @property {number|Long|null} [price] HemmingPants price
                         * @property {number|Long|null} [amount] HemmingPants amount
                         */

                        /**
                         * Constructs a new HemmingPants.
                         * @memberof theplant.ec.service.accounting.ItemSumUp
                         * @classdesc Represents a HemmingPants.
                         * @implements IHemmingPants
                         * @constructor
                         * @param {theplant.ec.service.accounting.ItemSumUp.IHemmingPants=} [properties] Properties to set
                         */
                        function HemmingPants(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * HemmingPants price.
                         * @member {number|Long} price
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @instance
                         */
                        HemmingPants.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * HemmingPants amount.
                         * @member {number|Long} amount
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @instance
                         */
                        HemmingPants.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                        /**
                         * Creates a new HemmingPants instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.IHemmingPants=} [properties] Properties to set
                         * @returns {theplant.ec.service.accounting.ItemSumUp.HemmingPants} HemmingPants instance
                         */
                        HemmingPants.create = function create(properties) {
                            return new HemmingPants(properties);
                        };

                        /**
                         * Encodes the specified HemmingPants message. Does not implicitly {@link theplant.ec.service.accounting.ItemSumUp.HemmingPants.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.IHemmingPants} message HemmingPants message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HemmingPants.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.price != null && message.hasOwnProperty("price"))
                                writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.price);
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.amount);
                            return writer;
                        };

                        /**
                         * Encodes the specified HemmingPants message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.ItemSumUp.HemmingPants.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.IHemmingPants} message HemmingPants message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        HemmingPants.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a HemmingPants message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.accounting.ItemSumUp.HemmingPants} HemmingPants
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HemmingPants.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.price = reader.uint64();
                                    break;
                                case 2:
                                    message.amount = reader.uint64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a HemmingPants message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.accounting.ItemSumUp.HemmingPants} HemmingPants
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        HemmingPants.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a HemmingPants message.
                         * @function verify
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        HemmingPants.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.price != null && message.hasOwnProperty("price"))
                                if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                    return "price: integer|Long expected";
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                                    return "amount: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates a HemmingPants message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.accounting.ItemSumUp.HemmingPants} HemmingPants
                         */
                        HemmingPants.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants)
                                return object;
                            var message = new $root.theplant.ec.service.accounting.ItemSumUp.HemmingPants();
                            if (object.price != null)
                                if ($util.Long)
                                    (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                                else if (typeof object.price === "string")
                                    message.price = parseInt(object.price, 10);
                                else if (typeof object.price === "number")
                                    message.price = object.price;
                                else if (typeof object.price === "object")
                                    message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                            if (object.amount != null)
                                if ($util.Long)
                                    (message.amount = $util.Long.fromValue(object.amount)).unsigned = true;
                                else if (typeof object.amount === "string")
                                    message.amount = parseInt(object.amount, 10);
                                else if (typeof object.amount === "number")
                                    message.amount = object.amount;
                                else if (typeof object.amount === "object")
                                    message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber(true);
                            return message;
                        };

                        /**
                         * Creates a plain object from a HemmingPants message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @static
                         * @param {theplant.ec.service.accounting.ItemSumUp.HemmingPants} message HemmingPants
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        HemmingPants.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.price = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, true);
                                    object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.amount = options.longs === String ? "0" : 0;
                            }
                            if (message.price != null && message.hasOwnProperty("price"))
                                if (typeof message.price === "number")
                                    object.price = options.longs === String ? String(message.price) : message.price;
                                else
                                    object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                            if (message.amount != null && message.hasOwnProperty("amount"))
                                if (typeof message.amount === "number")
                                    object.amount = options.longs === String ? String(message.amount) : message.amount;
                                else
                                    object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber(true) : message.amount;
                            return object;
                        };

                        /**
                         * Converts this HemmingPants to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.accounting.ItemSumUp.HemmingPants
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        HemmingPants.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return HemmingPants;
                    })();

                    return ItemSumUp;
                })();

                accounting.Discount = (function() {

                    /**
                     * Properties of a Discount.
                     * @memberof theplant.ec.service.accounting
                     * @interface IDiscount
                     * @property {number|Long|null} [id] Discount id
                     * @property {number|Long|null} [discountRate] Discount discountRate
                     * @property {number|Long|null} [discountValue] Discount discountValue
                     * @property {number|Long|null} [maxDiscountItemsCount] Discount maxDiscountItemsCount
                     * @property {string|null} [discountLabel] Discount discountLabel
                     * @property {boolean|null} [applyToSellingPrice] Discount applyToSellingPrice
                     * @property {boolean|null} [isCoupon] Discount isCoupon
                     * @property {theplant.ec.service.accounting.SubItemType|null} [freeFeeType] Discount freeFeeType
                     * @property {number|Long|null} [amount] Discount amount
                     * @property {Array.<string>|null} [couponCodes] Discount couponCodes
                     */

                    /**
                     * Constructs a new Discount.
                     * @memberof theplant.ec.service.accounting
                     * @classdesc Represents a Discount.
                     * @implements IDiscount
                     * @constructor
                     * @param {theplant.ec.service.accounting.IDiscount=} [properties] Properties to set
                     */
                    function Discount(properties) {
                        this.couponCodes = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Discount id.
                     * @member {number|Long} id
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.id = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Discount discountRate.
                     * @member {number|Long} discountRate
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.discountRate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Discount discountValue.
                     * @member {number|Long} discountValue
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.discountValue = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Discount maxDiscountItemsCount.
                     * @member {number|Long} maxDiscountItemsCount
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.maxDiscountItemsCount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Discount discountLabel.
                     * @member {string} discountLabel
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.discountLabel = "";

                    /**
                     * Discount applyToSellingPrice.
                     * @member {boolean} applyToSellingPrice
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.applyToSellingPrice = false;

                    /**
                     * Discount isCoupon.
                     * @member {boolean} isCoupon
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.isCoupon = false;

                    /**
                     * Discount freeFeeType.
                     * @member {theplant.ec.service.accounting.SubItemType} freeFeeType
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.freeFeeType = 0;

                    /**
                     * Discount amount.
                     * @member {number|Long} amount
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Discount couponCodes.
                     * @member {Array.<string>} couponCodes
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     */
                    Discount.prototype.couponCodes = $util.emptyArray;

                    /**
                     * Creates a new Discount instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {theplant.ec.service.accounting.IDiscount=} [properties] Properties to set
                     * @returns {theplant.ec.service.accounting.Discount} Discount instance
                     */
                    Discount.create = function create(properties) {
                        return new Discount(properties);
                    };

                    /**
                     * Encodes the specified Discount message. Does not implicitly {@link theplant.ec.service.accounting.Discount.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {theplant.ec.service.accounting.IDiscount} message Discount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Discount.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.id);
                        if (message.discountRate != null && message.hasOwnProperty("discountRate"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.discountRate);
                        if (message.discountValue != null && message.hasOwnProperty("discountValue"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.discountValue);
                        if (message.maxDiscountItemsCount != null && message.hasOwnProperty("maxDiscountItemsCount"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.maxDiscountItemsCount);
                        if (message.discountLabel != null && message.hasOwnProperty("discountLabel"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.discountLabel);
                        if (message.applyToSellingPrice != null && message.hasOwnProperty("applyToSellingPrice"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.applyToSellingPrice);
                        if (message.isCoupon != null && message.hasOwnProperty("isCoupon"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isCoupon);
                        if (message.freeFeeType != null && message.hasOwnProperty("freeFeeType"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.freeFeeType);
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.amount);
                        if (message.couponCodes != null && message.couponCodes.length)
                            for (var i = 0; i < message.couponCodes.length; ++i)
                                writer.uint32(/* id 10, wireType 2 =*/82).string(message.couponCodes[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified Discount message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.Discount.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {theplant.ec.service.accounting.IDiscount} message Discount message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Discount.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Discount message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.accounting.Discount} Discount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Discount.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.Discount();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.uint64();
                                break;
                            case 2:
                                message.discountRate = reader.uint64();
                                break;
                            case 3:
                                message.discountValue = reader.uint64();
                                break;
                            case 4:
                                message.maxDiscountItemsCount = reader.uint64();
                                break;
                            case 5:
                                message.discountLabel = reader.string();
                                break;
                            case 6:
                                message.applyToSellingPrice = reader.bool();
                                break;
                            case 7:
                                message.isCoupon = reader.bool();
                                break;
                            case 8:
                                message.freeFeeType = reader.int32();
                                break;
                            case 9:
                                message.amount = reader.uint64();
                                break;
                            case 10:
                                if (!(message.couponCodes && message.couponCodes.length))
                                    message.couponCodes = [];
                                message.couponCodes.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Discount message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.accounting.Discount} Discount
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Discount.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Discount message.
                     * @function verify
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Discount.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.discountRate != null && message.hasOwnProperty("discountRate"))
                            if (!$util.isInteger(message.discountRate) && !(message.discountRate && $util.isInteger(message.discountRate.low) && $util.isInteger(message.discountRate.high)))
                                return "discountRate: integer|Long expected";
                        if (message.discountValue != null && message.hasOwnProperty("discountValue"))
                            if (!$util.isInteger(message.discountValue) && !(message.discountValue && $util.isInteger(message.discountValue.low) && $util.isInteger(message.discountValue.high)))
                                return "discountValue: integer|Long expected";
                        if (message.maxDiscountItemsCount != null && message.hasOwnProperty("maxDiscountItemsCount"))
                            if (!$util.isInteger(message.maxDiscountItemsCount) && !(message.maxDiscountItemsCount && $util.isInteger(message.maxDiscountItemsCount.low) && $util.isInteger(message.maxDiscountItemsCount.high)))
                                return "maxDiscountItemsCount: integer|Long expected";
                        if (message.discountLabel != null && message.hasOwnProperty("discountLabel"))
                            if (!$util.isString(message.discountLabel))
                                return "discountLabel: string expected";
                        if (message.applyToSellingPrice != null && message.hasOwnProperty("applyToSellingPrice"))
                            if (typeof message.applyToSellingPrice !== "boolean")
                                return "applyToSellingPrice: boolean expected";
                        if (message.isCoupon != null && message.hasOwnProperty("isCoupon"))
                            if (typeof message.isCoupon !== "boolean")
                                return "isCoupon: boolean expected";
                        if (message.freeFeeType != null && message.hasOwnProperty("freeFeeType"))
                            switch (message.freeFeeType) {
                            default:
                                return "freeFeeType: enum value expected";
                            case 0:
                            case 10:
                            case 2:
                            case 1:
                            case 3:
                            case 4:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 5:
                            case 11:
                                break;
                            }
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                                return "amount: integer|Long expected";
                        if (message.couponCodes != null && message.hasOwnProperty("couponCodes")) {
                            if (!Array.isArray(message.couponCodes))
                                return "couponCodes: array expected";
                            for (var i = 0; i < message.couponCodes.length; ++i)
                                if (!$util.isString(message.couponCodes[i]))
                                    return "couponCodes: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a Discount message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.accounting.Discount} Discount
                     */
                    Discount.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.accounting.Discount)
                            return object;
                        var message = new $root.theplant.ec.service.accounting.Discount();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = true;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
                        if (object.discountRate != null)
                            if ($util.Long)
                                (message.discountRate = $util.Long.fromValue(object.discountRate)).unsigned = true;
                            else if (typeof object.discountRate === "string")
                                message.discountRate = parseInt(object.discountRate, 10);
                            else if (typeof object.discountRate === "number")
                                message.discountRate = object.discountRate;
                            else if (typeof object.discountRate === "object")
                                message.discountRate = new $util.LongBits(object.discountRate.low >>> 0, object.discountRate.high >>> 0).toNumber(true);
                        if (object.discountValue != null)
                            if ($util.Long)
                                (message.discountValue = $util.Long.fromValue(object.discountValue)).unsigned = true;
                            else if (typeof object.discountValue === "string")
                                message.discountValue = parseInt(object.discountValue, 10);
                            else if (typeof object.discountValue === "number")
                                message.discountValue = object.discountValue;
                            else if (typeof object.discountValue === "object")
                                message.discountValue = new $util.LongBits(object.discountValue.low >>> 0, object.discountValue.high >>> 0).toNumber(true);
                        if (object.maxDiscountItemsCount != null)
                            if ($util.Long)
                                (message.maxDiscountItemsCount = $util.Long.fromValue(object.maxDiscountItemsCount)).unsigned = true;
                            else if (typeof object.maxDiscountItemsCount === "string")
                                message.maxDiscountItemsCount = parseInt(object.maxDiscountItemsCount, 10);
                            else if (typeof object.maxDiscountItemsCount === "number")
                                message.maxDiscountItemsCount = object.maxDiscountItemsCount;
                            else if (typeof object.maxDiscountItemsCount === "object")
                                message.maxDiscountItemsCount = new $util.LongBits(object.maxDiscountItemsCount.low >>> 0, object.maxDiscountItemsCount.high >>> 0).toNumber(true);
                        if (object.discountLabel != null)
                            message.discountLabel = String(object.discountLabel);
                        if (object.applyToSellingPrice != null)
                            message.applyToSellingPrice = Boolean(object.applyToSellingPrice);
                        if (object.isCoupon != null)
                            message.isCoupon = Boolean(object.isCoupon);
                        switch (object.freeFeeType) {
                        case "NO_SUBITEM_TYPE":
                        case 0:
                            message.freeFeeType = 0;
                            break;
                        case "TOTAL_DISCOUNT":
                        case 10:
                            message.freeFeeType = 10;
                            break;
                        case "GIFT_WRAPPING_FEE":
                        case 2:
                            message.freeFeeType = 2;
                            break;
                        case "DELIVERY_FEE":
                        case 1:
                            message.freeFeeType = 1;
                            break;
                        case "COD_FEE":
                        case 3:
                            message.freeFeeType = 3;
                            break;
                        case "HEMMING_PANTS_FEE":
                        case 4:
                            message.freeFeeType = 4;
                            break;
                        case "POINTS_DEDUCTION":
                        case 6:
                            message.freeFeeType = 6;
                            break;
                        case "GIFT_CARD_DEDUCTION":
                        case 7:
                            message.freeFeeType = 7;
                            break;
                        case "PROMOTION_DISCOUNT":
                        case 8:
                            message.freeFeeType = 8;
                            break;
                        case "COUPON_DISCOUNT":
                        case 9:
                            message.freeFeeType = 9;
                            break;
                        case "TOTAL_AMOUNT":
                        case 5:
                            message.freeFeeType = 5;
                            break;
                        case "POINTS_TAX_DEDUCTION":
                        case 11:
                            message.freeFeeType = 11;
                            break;
                        }
                        if (object.amount != null)
                            if ($util.Long)
                                (message.amount = $util.Long.fromValue(object.amount)).unsigned = true;
                            else if (typeof object.amount === "string")
                                message.amount = parseInt(object.amount, 10);
                            else if (typeof object.amount === "number")
                                message.amount = object.amount;
                            else if (typeof object.amount === "object")
                                message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber(true);
                        if (object.couponCodes) {
                            if (!Array.isArray(object.couponCodes))
                                throw TypeError(".theplant.ec.service.accounting.Discount.couponCodes: array expected");
                            message.couponCodes = [];
                            for (var i = 0; i < object.couponCodes.length; ++i)
                                message.couponCodes[i] = String(object.couponCodes[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Discount message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.accounting.Discount
                     * @static
                     * @param {theplant.ec.service.accounting.Discount} message Discount
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Discount.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.couponCodes = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.discountRate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.discountRate = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.discountValue = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.discountValue = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.maxDiscountItemsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.maxDiscountItemsCount = options.longs === String ? "0" : 0;
                            object.discountLabel = "";
                            object.applyToSellingPrice = false;
                            object.isCoupon = false;
                            object.freeFeeType = options.enums === String ? "NO_SUBITEM_TYPE" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.amount = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true) : message.id;
                        if (message.discountRate != null && message.hasOwnProperty("discountRate"))
                            if (typeof message.discountRate === "number")
                                object.discountRate = options.longs === String ? String(message.discountRate) : message.discountRate;
                            else
                                object.discountRate = options.longs === String ? $util.Long.prototype.toString.call(message.discountRate) : options.longs === Number ? new $util.LongBits(message.discountRate.low >>> 0, message.discountRate.high >>> 0).toNumber(true) : message.discountRate;
                        if (message.discountValue != null && message.hasOwnProperty("discountValue"))
                            if (typeof message.discountValue === "number")
                                object.discountValue = options.longs === String ? String(message.discountValue) : message.discountValue;
                            else
                                object.discountValue = options.longs === String ? $util.Long.prototype.toString.call(message.discountValue) : options.longs === Number ? new $util.LongBits(message.discountValue.low >>> 0, message.discountValue.high >>> 0).toNumber(true) : message.discountValue;
                        if (message.maxDiscountItemsCount != null && message.hasOwnProperty("maxDiscountItemsCount"))
                            if (typeof message.maxDiscountItemsCount === "number")
                                object.maxDiscountItemsCount = options.longs === String ? String(message.maxDiscountItemsCount) : message.maxDiscountItemsCount;
                            else
                                object.maxDiscountItemsCount = options.longs === String ? $util.Long.prototype.toString.call(message.maxDiscountItemsCount) : options.longs === Number ? new $util.LongBits(message.maxDiscountItemsCount.low >>> 0, message.maxDiscountItemsCount.high >>> 0).toNumber(true) : message.maxDiscountItemsCount;
                        if (message.discountLabel != null && message.hasOwnProperty("discountLabel"))
                            object.discountLabel = message.discountLabel;
                        if (message.applyToSellingPrice != null && message.hasOwnProperty("applyToSellingPrice"))
                            object.applyToSellingPrice = message.applyToSellingPrice;
                        if (message.isCoupon != null && message.hasOwnProperty("isCoupon"))
                            object.isCoupon = message.isCoupon;
                        if (message.freeFeeType != null && message.hasOwnProperty("freeFeeType"))
                            object.freeFeeType = options.enums === String ? $root.theplant.ec.service.accounting.SubItemType[message.freeFeeType] : message.freeFeeType;
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            if (typeof message.amount === "number")
                                object.amount = options.longs === String ? String(message.amount) : message.amount;
                            else
                                object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber(true) : message.amount;
                        if (message.couponCodes && message.couponCodes.length) {
                            object.couponCodes = [];
                            for (var j = 0; j < message.couponCodes.length; ++j)
                                object.couponCodes[j] = message.couponCodes[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this Discount to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.accounting.Discount
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Discount.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Discount;
                })();

                accounting.SubItem = (function() {

                    /**
                     * Properties of a SubItem.
                     * @memberof theplant.ec.service.accounting
                     * @interface ISubItem
                     * @property {theplant.ec.service.accounting.SubItemType|null} [subType] SubItem subType
                     * @property {string|null} [name] SubItem name
                     * @property {number|Long|null} [amount] SubItem amount
                     */

                    /**
                     * Constructs a new SubItem.
                     * @memberof theplant.ec.service.accounting
                     * @classdesc Represents a SubItem.
                     * @implements ISubItem
                     * @constructor
                     * @param {theplant.ec.service.accounting.ISubItem=} [properties] Properties to set
                     */
                    function SubItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SubItem subType.
                     * @member {theplant.ec.service.accounting.SubItemType} subType
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @instance
                     */
                    SubItem.prototype.subType = 0;

                    /**
                     * SubItem name.
                     * @member {string} name
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @instance
                     */
                    SubItem.prototype.name = "";

                    /**
                     * SubItem amount.
                     * @member {number|Long} amount
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @instance
                     */
                    SubItem.prototype.amount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new SubItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {theplant.ec.service.accounting.ISubItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.accounting.SubItem} SubItem instance
                     */
                    SubItem.create = function create(properties) {
                        return new SubItem(properties);
                    };

                    /**
                     * Encodes the specified SubItem message. Does not implicitly {@link theplant.ec.service.accounting.SubItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {theplant.ec.service.accounting.ISubItem} message SubItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.subType != null && message.hasOwnProperty("subType"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.subType);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amount);
                        return writer;
                    };

                    /**
                     * Encodes the specified SubItem message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.SubItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {theplant.ec.service.accounting.ISubItem} message SubItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SubItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SubItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.accounting.SubItem} SubItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.SubItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.subType = reader.int32();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            case 3:
                                message.amount = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SubItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.accounting.SubItem} SubItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SubItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SubItem message.
                     * @function verify
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SubItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.subType != null && message.hasOwnProperty("subType"))
                            switch (message.subType) {
                            default:
                                return "subType: enum value expected";
                            case 0:
                            case 10:
                            case 2:
                            case 1:
                            case 3:
                            case 4:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 5:
                            case 11:
                                break;
                            }
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            if (!$util.isInteger(message.amount) && !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high)))
                                return "amount: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a SubItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.accounting.SubItem} SubItem
                     */
                    SubItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.accounting.SubItem)
                            return object;
                        var message = new $root.theplant.ec.service.accounting.SubItem();
                        switch (object.subType) {
                        case "NO_SUBITEM_TYPE":
                        case 0:
                            message.subType = 0;
                            break;
                        case "TOTAL_DISCOUNT":
                        case 10:
                            message.subType = 10;
                            break;
                        case "GIFT_WRAPPING_FEE":
                        case 2:
                            message.subType = 2;
                            break;
                        case "DELIVERY_FEE":
                        case 1:
                            message.subType = 1;
                            break;
                        case "COD_FEE":
                        case 3:
                            message.subType = 3;
                            break;
                        case "HEMMING_PANTS_FEE":
                        case 4:
                            message.subType = 4;
                            break;
                        case "POINTS_DEDUCTION":
                        case 6:
                            message.subType = 6;
                            break;
                        case "GIFT_CARD_DEDUCTION":
                        case 7:
                            message.subType = 7;
                            break;
                        case "PROMOTION_DISCOUNT":
                        case 8:
                            message.subType = 8;
                            break;
                        case "COUPON_DISCOUNT":
                        case 9:
                            message.subType = 9;
                            break;
                        case "TOTAL_AMOUNT":
                        case 5:
                            message.subType = 5;
                            break;
                        case "POINTS_TAX_DEDUCTION":
                        case 11:
                            message.subType = 11;
                            break;
                        }
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.amount != null)
                            if ($util.Long)
                                (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
                            else if (typeof object.amount === "string")
                                message.amount = parseInt(object.amount, 10);
                            else if (typeof object.amount === "number")
                                message.amount = object.amount;
                            else if (typeof object.amount === "object")
                                message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a SubItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @static
                     * @param {theplant.ec.service.accounting.SubItem} message SubItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SubItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.subType = options.enums === String ? "NO_SUBITEM_TYPE" : 0;
                            object.name = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.amount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.amount = options.longs === String ? "0" : 0;
                        }
                        if (message.subType != null && message.hasOwnProperty("subType"))
                            object.subType = options.enums === String ? $root.theplant.ec.service.accounting.SubItemType[message.subType] : message.subType;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.amount != null && message.hasOwnProperty("amount"))
                            if (typeof message.amount === "number")
                                object.amount = options.longs === String ? String(message.amount) : message.amount;
                            else
                                object.amount = options.longs === String ? $util.Long.prototype.toString.call(message.amount) : options.longs === Number ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber() : message.amount;
                        return object;
                    };

                    /**
                     * Converts this SubItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.accounting.SubItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SubItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SubItem;
                })();

                /**
                 * SubItemType enum.
                 * @name theplant.ec.service.accounting.SubItemType
                 * @enum {string}
                 * @property {number} NO_SUBITEM_TYPE=0 NO_SUBITEM_TYPE value
                 * @property {number} TOTAL_DISCOUNT=10 TOTAL_DISCOUNT value
                 * @property {number} GIFT_WRAPPING_FEE=2 GIFT_WRAPPING_FEE value
                 * @property {number} DELIVERY_FEE=1 DELIVERY_FEE value
                 * @property {number} COD_FEE=3 COD_FEE value
                 * @property {number} HEMMING_PANTS_FEE=4 HEMMING_PANTS_FEE value
                 * @property {number} POINTS_DEDUCTION=6 POINTS_DEDUCTION value
                 * @property {number} GIFT_CARD_DEDUCTION=7 GIFT_CARD_DEDUCTION value
                 * @property {number} PROMOTION_DISCOUNT=8 PROMOTION_DISCOUNT value
                 * @property {number} COUPON_DISCOUNT=9 COUPON_DISCOUNT value
                 * @property {number} TOTAL_AMOUNT=5 TOTAL_AMOUNT value
                 * @property {number} POINTS_TAX_DEDUCTION=11 POINTS_TAX_DEDUCTION value
                 */
                accounting.SubItemType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NO_SUBITEM_TYPE"] = 0;
                    values[valuesById[10] = "TOTAL_DISCOUNT"] = 10;
                    values[valuesById[2] = "GIFT_WRAPPING_FEE"] = 2;
                    values[valuesById[1] = "DELIVERY_FEE"] = 1;
                    values[valuesById[3] = "COD_FEE"] = 3;
                    values[valuesById[4] = "HEMMING_PANTS_FEE"] = 4;
                    values[valuesById[6] = "POINTS_DEDUCTION"] = 6;
                    values[valuesById[7] = "GIFT_CARD_DEDUCTION"] = 7;
                    values[valuesById[8] = "PROMOTION_DISCOUNT"] = 8;
                    values[valuesById[9] = "COUPON_DISCOUNT"] = 9;
                    values[valuesById[5] = "TOTAL_AMOUNT"] = 5;
                    values[valuesById[11] = "POINTS_TAX_DEDUCTION"] = 11;
                    return values;
                })();

                accounting.SumUp = (function() {

                    /**
                     * Properties of a SumUp.
                     * @memberof theplant.ec.service.accounting
                     * @interface ISumUp
                     * @property {Array.<theplant.ec.service.accounting.ISubItem>|null} [subItems] SumUp subItems
                     * @property {string|null} [currency] SumUp currency
                     * @property {number|Long|null} [taxRate] SumUp taxRate
                     * @property {number|Long|null} [quoteAmount] SumUp quoteAmount
                     * @property {number|Long|null} [quoteAmountTax] SumUp quoteAmountTax
                     * @property {number|Long|null} [quoteAmountWithTax] SumUp quoteAmountWithTax
                     * @property {number|Long|null} [billingAmount] SumUp billingAmount
                     * @property {number|Long|null} [billingAmountTax] SumUp billingAmountTax
                     * @property {number|Long|null} [billingAmountWithTax] SumUp billingAmountWithTax
                     * @property {number|Long|null} [rawBillingAmount] SumUp rawBillingAmount
                     * @property {number|Long|null} [customerSupportAdjustment] SumUp customerSupportAdjustment
                     * @property {Array.<theplant.ec.service.accounting.IDiscount>|null} [discounts] SumUp discounts
                     * @property {number|Long|null} [billingAmountWithTaxWithoutSfs] SumUp billingAmountWithTaxWithoutSfs
                     */

                    /**
                     * Constructs a new SumUp.
                     * @memberof theplant.ec.service.accounting
                     * @classdesc Represents a SumUp.
                     * @implements ISumUp
                     * @constructor
                     * @param {theplant.ec.service.accounting.ISumUp=} [properties] Properties to set
                     */
                    function SumUp(properties) {
                        this.subItems = [];
                        this.discounts = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SumUp subItems.
                     * @member {Array.<theplant.ec.service.accounting.ISubItem>} subItems
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.subItems = $util.emptyArray;

                    /**
                     * SumUp currency.
                     * @member {string} currency
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.currency = "";

                    /**
                     * SumUp taxRate.
                     * @member {number|Long} taxRate
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.taxRate = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp quoteAmount.
                     * @member {number|Long} quoteAmount
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.quoteAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp quoteAmountTax.
                     * @member {number|Long} quoteAmountTax
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.quoteAmountTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp quoteAmountWithTax.
                     * @member {number|Long} quoteAmountWithTax
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.quoteAmountWithTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp billingAmount.
                     * @member {number|Long} billingAmount
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.billingAmount = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp billingAmountTax.
                     * @member {number|Long} billingAmountTax
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.billingAmountTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp billingAmountWithTax.
                     * @member {number|Long} billingAmountWithTax
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.billingAmountWithTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * SumUp rawBillingAmount.
                     * @member {number|Long} rawBillingAmount
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.rawBillingAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SumUp customerSupportAdjustment.
                     * @member {number|Long} customerSupportAdjustment
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.customerSupportAdjustment = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SumUp discounts.
                     * @member {Array.<theplant.ec.service.accounting.IDiscount>} discounts
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.discounts = $util.emptyArray;

                    /**
                     * SumUp billingAmountWithTaxWithoutSfs.
                     * @member {number|Long} billingAmountWithTaxWithoutSfs
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     */
                    SumUp.prototype.billingAmountWithTaxWithoutSfs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new SumUp instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {theplant.ec.service.accounting.ISumUp=} [properties] Properties to set
                     * @returns {theplant.ec.service.accounting.SumUp} SumUp instance
                     */
                    SumUp.create = function create(properties) {
                        return new SumUp(properties);
                    };

                    /**
                     * Encodes the specified SumUp message. Does not implicitly {@link theplant.ec.service.accounting.SumUp.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {theplant.ec.service.accounting.ISumUp} message SumUp message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SumUp.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.subItems != null && message.subItems.length)
                            for (var i = 0; i < message.subItems.length; ++i)
                                $root.theplant.ec.service.accounting.SubItem.encode(message.subItems[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.currency);
                        if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.taxRate);
                        if (message.quoteAmount != null && message.hasOwnProperty("quoteAmount"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.quoteAmount);
                        if (message.quoteAmountTax != null && message.hasOwnProperty("quoteAmountTax"))
                            writer.uint32(/* id 5, wireType 0 =*/40).uint64(message.quoteAmountTax);
                        if (message.quoteAmountWithTax != null && message.hasOwnProperty("quoteAmountWithTax"))
                            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.quoteAmountWithTax);
                        if (message.billingAmount != null && message.hasOwnProperty("billingAmount"))
                            writer.uint32(/* id 7, wireType 0 =*/56).uint64(message.billingAmount);
                        if (message.billingAmountTax != null && message.hasOwnProperty("billingAmountTax"))
                            writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.billingAmountTax);
                        if (message.billingAmountWithTax != null && message.hasOwnProperty("billingAmountWithTax"))
                            writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.billingAmountWithTax);
                        if (message.customerSupportAdjustment != null && message.hasOwnProperty("customerSupportAdjustment"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.customerSupportAdjustment);
                        if (message.discounts != null && message.discounts.length)
                            for (var i = 0; i < message.discounts.length; ++i)
                                $root.theplant.ec.service.accounting.Discount.encode(message.discounts[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        if (message.rawBillingAmount != null && message.hasOwnProperty("rawBillingAmount"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.rawBillingAmount);
                        if (message.billingAmountWithTaxWithoutSfs != null && message.hasOwnProperty("billingAmountWithTaxWithoutSfs"))
                            writer.uint32(/* id 13, wireType 0 =*/104).int64(message.billingAmountWithTaxWithoutSfs);
                        return writer;
                    };

                    /**
                     * Encodes the specified SumUp message, length delimited. Does not implicitly {@link theplant.ec.service.accounting.SumUp.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {theplant.ec.service.accounting.ISumUp} message SumUp message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SumUp.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SumUp message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.accounting.SumUp} SumUp
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SumUp.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.accounting.SumUp();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.subItems && message.subItems.length))
                                    message.subItems = [];
                                message.subItems.push($root.theplant.ec.service.accounting.SubItem.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.currency = reader.string();
                                break;
                            case 3:
                                message.taxRate = reader.uint64();
                                break;
                            case 4:
                                message.quoteAmount = reader.uint64();
                                break;
                            case 5:
                                message.quoteAmountTax = reader.uint64();
                                break;
                            case 6:
                                message.quoteAmountWithTax = reader.uint64();
                                break;
                            case 7:
                                message.billingAmount = reader.uint64();
                                break;
                            case 8:
                                message.billingAmountTax = reader.uint64();
                                break;
                            case 9:
                                message.billingAmountWithTax = reader.uint64();
                                break;
                            case 12:
                                message.rawBillingAmount = reader.int64();
                                break;
                            case 10:
                                message.customerSupportAdjustment = reader.int64();
                                break;
                            case 11:
                                if (!(message.discounts && message.discounts.length))
                                    message.discounts = [];
                                message.discounts.push($root.theplant.ec.service.accounting.Discount.decode(reader, reader.uint32()));
                                break;
                            case 13:
                                message.billingAmountWithTaxWithoutSfs = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SumUp message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.accounting.SumUp} SumUp
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SumUp.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SumUp message.
                     * @function verify
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SumUp.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.subItems != null && message.hasOwnProperty("subItems")) {
                            if (!Array.isArray(message.subItems))
                                return "subItems: array expected";
                            for (var i = 0; i < message.subItems.length; ++i) {
                                var error = $root.theplant.ec.service.accounting.SubItem.verify(message.subItems[i]);
                                if (error)
                                    return "subItems." + error;
                            }
                        }
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            if (!$util.isString(message.currency))
                                return "currency: string expected";
                        if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                            if (!$util.isInteger(message.taxRate) && !(message.taxRate && $util.isInteger(message.taxRate.low) && $util.isInteger(message.taxRate.high)))
                                return "taxRate: integer|Long expected";
                        if (message.quoteAmount != null && message.hasOwnProperty("quoteAmount"))
                            if (!$util.isInteger(message.quoteAmount) && !(message.quoteAmount && $util.isInteger(message.quoteAmount.low) && $util.isInteger(message.quoteAmount.high)))
                                return "quoteAmount: integer|Long expected";
                        if (message.quoteAmountTax != null && message.hasOwnProperty("quoteAmountTax"))
                            if (!$util.isInteger(message.quoteAmountTax) && !(message.quoteAmountTax && $util.isInteger(message.quoteAmountTax.low) && $util.isInteger(message.quoteAmountTax.high)))
                                return "quoteAmountTax: integer|Long expected";
                        if (message.quoteAmountWithTax != null && message.hasOwnProperty("quoteAmountWithTax"))
                            if (!$util.isInteger(message.quoteAmountWithTax) && !(message.quoteAmountWithTax && $util.isInteger(message.quoteAmountWithTax.low) && $util.isInteger(message.quoteAmountWithTax.high)))
                                return "quoteAmountWithTax: integer|Long expected";
                        if (message.billingAmount != null && message.hasOwnProperty("billingAmount"))
                            if (!$util.isInteger(message.billingAmount) && !(message.billingAmount && $util.isInteger(message.billingAmount.low) && $util.isInteger(message.billingAmount.high)))
                                return "billingAmount: integer|Long expected";
                        if (message.billingAmountTax != null && message.hasOwnProperty("billingAmountTax"))
                            if (!$util.isInteger(message.billingAmountTax) && !(message.billingAmountTax && $util.isInteger(message.billingAmountTax.low) && $util.isInteger(message.billingAmountTax.high)))
                                return "billingAmountTax: integer|Long expected";
                        if (message.billingAmountWithTax != null && message.hasOwnProperty("billingAmountWithTax"))
                            if (!$util.isInteger(message.billingAmountWithTax) && !(message.billingAmountWithTax && $util.isInteger(message.billingAmountWithTax.low) && $util.isInteger(message.billingAmountWithTax.high)))
                                return "billingAmountWithTax: integer|Long expected";
                        if (message.rawBillingAmount != null && message.hasOwnProperty("rawBillingAmount"))
                            if (!$util.isInteger(message.rawBillingAmount) && !(message.rawBillingAmount && $util.isInteger(message.rawBillingAmount.low) && $util.isInteger(message.rawBillingAmount.high)))
                                return "rawBillingAmount: integer|Long expected";
                        if (message.customerSupportAdjustment != null && message.hasOwnProperty("customerSupportAdjustment"))
                            if (!$util.isInteger(message.customerSupportAdjustment) && !(message.customerSupportAdjustment && $util.isInteger(message.customerSupportAdjustment.low) && $util.isInteger(message.customerSupportAdjustment.high)))
                                return "customerSupportAdjustment: integer|Long expected";
                        if (message.discounts != null && message.hasOwnProperty("discounts")) {
                            if (!Array.isArray(message.discounts))
                                return "discounts: array expected";
                            for (var i = 0; i < message.discounts.length; ++i) {
                                var error = $root.theplant.ec.service.accounting.Discount.verify(message.discounts[i]);
                                if (error)
                                    return "discounts." + error;
                            }
                        }
                        if (message.billingAmountWithTaxWithoutSfs != null && message.hasOwnProperty("billingAmountWithTaxWithoutSfs"))
                            if (!$util.isInteger(message.billingAmountWithTaxWithoutSfs) && !(message.billingAmountWithTaxWithoutSfs && $util.isInteger(message.billingAmountWithTaxWithoutSfs.low) && $util.isInteger(message.billingAmountWithTaxWithoutSfs.high)))
                                return "billingAmountWithTaxWithoutSfs: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a SumUp message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.accounting.SumUp} SumUp
                     */
                    SumUp.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.accounting.SumUp)
                            return object;
                        var message = new $root.theplant.ec.service.accounting.SumUp();
                        if (object.subItems) {
                            if (!Array.isArray(object.subItems))
                                throw TypeError(".theplant.ec.service.accounting.SumUp.subItems: array expected");
                            message.subItems = [];
                            for (var i = 0; i < object.subItems.length; ++i) {
                                if (typeof object.subItems[i] !== "object")
                                    throw TypeError(".theplant.ec.service.accounting.SumUp.subItems: object expected");
                                message.subItems[i] = $root.theplant.ec.service.accounting.SubItem.fromObject(object.subItems[i]);
                            }
                        }
                        if (object.currency != null)
                            message.currency = String(object.currency);
                        if (object.taxRate != null)
                            if ($util.Long)
                                (message.taxRate = $util.Long.fromValue(object.taxRate)).unsigned = true;
                            else if (typeof object.taxRate === "string")
                                message.taxRate = parseInt(object.taxRate, 10);
                            else if (typeof object.taxRate === "number")
                                message.taxRate = object.taxRate;
                            else if (typeof object.taxRate === "object")
                                message.taxRate = new $util.LongBits(object.taxRate.low >>> 0, object.taxRate.high >>> 0).toNumber(true);
                        if (object.quoteAmount != null)
                            if ($util.Long)
                                (message.quoteAmount = $util.Long.fromValue(object.quoteAmount)).unsigned = true;
                            else if (typeof object.quoteAmount === "string")
                                message.quoteAmount = parseInt(object.quoteAmount, 10);
                            else if (typeof object.quoteAmount === "number")
                                message.quoteAmount = object.quoteAmount;
                            else if (typeof object.quoteAmount === "object")
                                message.quoteAmount = new $util.LongBits(object.quoteAmount.low >>> 0, object.quoteAmount.high >>> 0).toNumber(true);
                        if (object.quoteAmountTax != null)
                            if ($util.Long)
                                (message.quoteAmountTax = $util.Long.fromValue(object.quoteAmountTax)).unsigned = true;
                            else if (typeof object.quoteAmountTax === "string")
                                message.quoteAmountTax = parseInt(object.quoteAmountTax, 10);
                            else if (typeof object.quoteAmountTax === "number")
                                message.quoteAmountTax = object.quoteAmountTax;
                            else if (typeof object.quoteAmountTax === "object")
                                message.quoteAmountTax = new $util.LongBits(object.quoteAmountTax.low >>> 0, object.quoteAmountTax.high >>> 0).toNumber(true);
                        if (object.quoteAmountWithTax != null)
                            if ($util.Long)
                                (message.quoteAmountWithTax = $util.Long.fromValue(object.quoteAmountWithTax)).unsigned = true;
                            else if (typeof object.quoteAmountWithTax === "string")
                                message.quoteAmountWithTax = parseInt(object.quoteAmountWithTax, 10);
                            else if (typeof object.quoteAmountWithTax === "number")
                                message.quoteAmountWithTax = object.quoteAmountWithTax;
                            else if (typeof object.quoteAmountWithTax === "object")
                                message.quoteAmountWithTax = new $util.LongBits(object.quoteAmountWithTax.low >>> 0, object.quoteAmountWithTax.high >>> 0).toNumber(true);
                        if (object.billingAmount != null)
                            if ($util.Long)
                                (message.billingAmount = $util.Long.fromValue(object.billingAmount)).unsigned = true;
                            else if (typeof object.billingAmount === "string")
                                message.billingAmount = parseInt(object.billingAmount, 10);
                            else if (typeof object.billingAmount === "number")
                                message.billingAmount = object.billingAmount;
                            else if (typeof object.billingAmount === "object")
                                message.billingAmount = new $util.LongBits(object.billingAmount.low >>> 0, object.billingAmount.high >>> 0).toNumber(true);
                        if (object.billingAmountTax != null)
                            if ($util.Long)
                                (message.billingAmountTax = $util.Long.fromValue(object.billingAmountTax)).unsigned = true;
                            else if (typeof object.billingAmountTax === "string")
                                message.billingAmountTax = parseInt(object.billingAmountTax, 10);
                            else if (typeof object.billingAmountTax === "number")
                                message.billingAmountTax = object.billingAmountTax;
                            else if (typeof object.billingAmountTax === "object")
                                message.billingAmountTax = new $util.LongBits(object.billingAmountTax.low >>> 0, object.billingAmountTax.high >>> 0).toNumber(true);
                        if (object.billingAmountWithTax != null)
                            if ($util.Long)
                                (message.billingAmountWithTax = $util.Long.fromValue(object.billingAmountWithTax)).unsigned = true;
                            else if (typeof object.billingAmountWithTax === "string")
                                message.billingAmountWithTax = parseInt(object.billingAmountWithTax, 10);
                            else if (typeof object.billingAmountWithTax === "number")
                                message.billingAmountWithTax = object.billingAmountWithTax;
                            else if (typeof object.billingAmountWithTax === "object")
                                message.billingAmountWithTax = new $util.LongBits(object.billingAmountWithTax.low >>> 0, object.billingAmountWithTax.high >>> 0).toNumber(true);
                        if (object.rawBillingAmount != null)
                            if ($util.Long)
                                (message.rawBillingAmount = $util.Long.fromValue(object.rawBillingAmount)).unsigned = false;
                            else if (typeof object.rawBillingAmount === "string")
                                message.rawBillingAmount = parseInt(object.rawBillingAmount, 10);
                            else if (typeof object.rawBillingAmount === "number")
                                message.rawBillingAmount = object.rawBillingAmount;
                            else if (typeof object.rawBillingAmount === "object")
                                message.rawBillingAmount = new $util.LongBits(object.rawBillingAmount.low >>> 0, object.rawBillingAmount.high >>> 0).toNumber();
                        if (object.customerSupportAdjustment != null)
                            if ($util.Long)
                                (message.customerSupportAdjustment = $util.Long.fromValue(object.customerSupportAdjustment)).unsigned = false;
                            else if (typeof object.customerSupportAdjustment === "string")
                                message.customerSupportAdjustment = parseInt(object.customerSupportAdjustment, 10);
                            else if (typeof object.customerSupportAdjustment === "number")
                                message.customerSupportAdjustment = object.customerSupportAdjustment;
                            else if (typeof object.customerSupportAdjustment === "object")
                                message.customerSupportAdjustment = new $util.LongBits(object.customerSupportAdjustment.low >>> 0, object.customerSupportAdjustment.high >>> 0).toNumber();
                        if (object.discounts) {
                            if (!Array.isArray(object.discounts))
                                throw TypeError(".theplant.ec.service.accounting.SumUp.discounts: array expected");
                            message.discounts = [];
                            for (var i = 0; i < object.discounts.length; ++i) {
                                if (typeof object.discounts[i] !== "object")
                                    throw TypeError(".theplant.ec.service.accounting.SumUp.discounts: object expected");
                                message.discounts[i] = $root.theplant.ec.service.accounting.Discount.fromObject(object.discounts[i]);
                            }
                        }
                        if (object.billingAmountWithTaxWithoutSfs != null)
                            if ($util.Long)
                                (message.billingAmountWithTaxWithoutSfs = $util.Long.fromValue(object.billingAmountWithTaxWithoutSfs)).unsigned = false;
                            else if (typeof object.billingAmountWithTaxWithoutSfs === "string")
                                message.billingAmountWithTaxWithoutSfs = parseInt(object.billingAmountWithTaxWithoutSfs, 10);
                            else if (typeof object.billingAmountWithTaxWithoutSfs === "number")
                                message.billingAmountWithTaxWithoutSfs = object.billingAmountWithTaxWithoutSfs;
                            else if (typeof object.billingAmountWithTaxWithoutSfs === "object")
                                message.billingAmountWithTaxWithoutSfs = new $util.LongBits(object.billingAmountWithTaxWithoutSfs.low >>> 0, object.billingAmountWithTaxWithoutSfs.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a SumUp message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @static
                     * @param {theplant.ec.service.accounting.SumUp} message SumUp
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SumUp.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.subItems = [];
                            object.discounts = [];
                        }
                        if (options.defaults) {
                            object.currency = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.taxRate = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.taxRate = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quoteAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quoteAmount = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quoteAmountTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quoteAmountTax = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.quoteAmountWithTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quoteAmountWithTax = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.billingAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.billingAmount = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.billingAmountTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.billingAmountTax = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.billingAmountWithTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.billingAmountWithTax = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.customerSupportAdjustment = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.customerSupportAdjustment = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rawBillingAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rawBillingAmount = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.billingAmountWithTaxWithoutSfs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.billingAmountWithTaxWithoutSfs = options.longs === String ? "0" : 0;
                        }
                        if (message.subItems && message.subItems.length) {
                            object.subItems = [];
                            for (var j = 0; j < message.subItems.length; ++j)
                                object.subItems[j] = $root.theplant.ec.service.accounting.SubItem.toObject(message.subItems[j], options);
                        }
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            object.currency = message.currency;
                        if (message.taxRate != null && message.hasOwnProperty("taxRate"))
                            if (typeof message.taxRate === "number")
                                object.taxRate = options.longs === String ? String(message.taxRate) : message.taxRate;
                            else
                                object.taxRate = options.longs === String ? $util.Long.prototype.toString.call(message.taxRate) : options.longs === Number ? new $util.LongBits(message.taxRate.low >>> 0, message.taxRate.high >>> 0).toNumber(true) : message.taxRate;
                        if (message.quoteAmount != null && message.hasOwnProperty("quoteAmount"))
                            if (typeof message.quoteAmount === "number")
                                object.quoteAmount = options.longs === String ? String(message.quoteAmount) : message.quoteAmount;
                            else
                                object.quoteAmount = options.longs === String ? $util.Long.prototype.toString.call(message.quoteAmount) : options.longs === Number ? new $util.LongBits(message.quoteAmount.low >>> 0, message.quoteAmount.high >>> 0).toNumber(true) : message.quoteAmount;
                        if (message.quoteAmountTax != null && message.hasOwnProperty("quoteAmountTax"))
                            if (typeof message.quoteAmountTax === "number")
                                object.quoteAmountTax = options.longs === String ? String(message.quoteAmountTax) : message.quoteAmountTax;
                            else
                                object.quoteAmountTax = options.longs === String ? $util.Long.prototype.toString.call(message.quoteAmountTax) : options.longs === Number ? new $util.LongBits(message.quoteAmountTax.low >>> 0, message.quoteAmountTax.high >>> 0).toNumber(true) : message.quoteAmountTax;
                        if (message.quoteAmountWithTax != null && message.hasOwnProperty("quoteAmountWithTax"))
                            if (typeof message.quoteAmountWithTax === "number")
                                object.quoteAmountWithTax = options.longs === String ? String(message.quoteAmountWithTax) : message.quoteAmountWithTax;
                            else
                                object.quoteAmountWithTax = options.longs === String ? $util.Long.prototype.toString.call(message.quoteAmountWithTax) : options.longs === Number ? new $util.LongBits(message.quoteAmountWithTax.low >>> 0, message.quoteAmountWithTax.high >>> 0).toNumber(true) : message.quoteAmountWithTax;
                        if (message.billingAmount != null && message.hasOwnProperty("billingAmount"))
                            if (typeof message.billingAmount === "number")
                                object.billingAmount = options.longs === String ? String(message.billingAmount) : message.billingAmount;
                            else
                                object.billingAmount = options.longs === String ? $util.Long.prototype.toString.call(message.billingAmount) : options.longs === Number ? new $util.LongBits(message.billingAmount.low >>> 0, message.billingAmount.high >>> 0).toNumber(true) : message.billingAmount;
                        if (message.billingAmountTax != null && message.hasOwnProperty("billingAmountTax"))
                            if (typeof message.billingAmountTax === "number")
                                object.billingAmountTax = options.longs === String ? String(message.billingAmountTax) : message.billingAmountTax;
                            else
                                object.billingAmountTax = options.longs === String ? $util.Long.prototype.toString.call(message.billingAmountTax) : options.longs === Number ? new $util.LongBits(message.billingAmountTax.low >>> 0, message.billingAmountTax.high >>> 0).toNumber(true) : message.billingAmountTax;
                        if (message.billingAmountWithTax != null && message.hasOwnProperty("billingAmountWithTax"))
                            if (typeof message.billingAmountWithTax === "number")
                                object.billingAmountWithTax = options.longs === String ? String(message.billingAmountWithTax) : message.billingAmountWithTax;
                            else
                                object.billingAmountWithTax = options.longs === String ? $util.Long.prototype.toString.call(message.billingAmountWithTax) : options.longs === Number ? new $util.LongBits(message.billingAmountWithTax.low >>> 0, message.billingAmountWithTax.high >>> 0).toNumber(true) : message.billingAmountWithTax;
                        if (message.customerSupportAdjustment != null && message.hasOwnProperty("customerSupportAdjustment"))
                            if (typeof message.customerSupportAdjustment === "number")
                                object.customerSupportAdjustment = options.longs === String ? String(message.customerSupportAdjustment) : message.customerSupportAdjustment;
                            else
                                object.customerSupportAdjustment = options.longs === String ? $util.Long.prototype.toString.call(message.customerSupportAdjustment) : options.longs === Number ? new $util.LongBits(message.customerSupportAdjustment.low >>> 0, message.customerSupportAdjustment.high >>> 0).toNumber() : message.customerSupportAdjustment;
                        if (message.discounts && message.discounts.length) {
                            object.discounts = [];
                            for (var j = 0; j < message.discounts.length; ++j)
                                object.discounts[j] = $root.theplant.ec.service.accounting.Discount.toObject(message.discounts[j], options);
                        }
                        if (message.rawBillingAmount != null && message.hasOwnProperty("rawBillingAmount"))
                            if (typeof message.rawBillingAmount === "number")
                                object.rawBillingAmount = options.longs === String ? String(message.rawBillingAmount) : message.rawBillingAmount;
                            else
                                object.rawBillingAmount = options.longs === String ? $util.Long.prototype.toString.call(message.rawBillingAmount) : options.longs === Number ? new $util.LongBits(message.rawBillingAmount.low >>> 0, message.rawBillingAmount.high >>> 0).toNumber() : message.rawBillingAmount;
                        if (message.billingAmountWithTaxWithoutSfs != null && message.hasOwnProperty("billingAmountWithTaxWithoutSfs"))
                            if (typeof message.billingAmountWithTaxWithoutSfs === "number")
                                object.billingAmountWithTaxWithoutSfs = options.longs === String ? String(message.billingAmountWithTaxWithoutSfs) : message.billingAmountWithTaxWithoutSfs;
                            else
                                object.billingAmountWithTaxWithoutSfs = options.longs === String ? $util.Long.prototype.toString.call(message.billingAmountWithTaxWithoutSfs) : options.longs === Number ? new $util.LongBits(message.billingAmountWithTaxWithoutSfs.low >>> 0, message.billingAmountWithTaxWithoutSfs.high >>> 0).toNumber() : message.billingAmountWithTaxWithoutSfs;
                        return object;
                    };

                    /**
                     * Converts this SumUp to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.accounting.SumUp
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SumUp.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SumUp;
                })();

                return accounting;
            })();

            service.convenience_pickup = (function() {

                /**
                 * Namespace convenience_pickup.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var convenience_pickup = {};

                convenience_pickup.ConveniencePickup = (function() {

                    /**
                     * Properties of a ConveniencePickup.
                     * @memberof theplant.ec.service.convenience_pickup
                     * @interface IConveniencePickup
                     * @property {string|null} [orderCode] ConveniencePickup orderCode
                     * @property {string|null} [secretCode] ConveniencePickup secretCode
                     * @property {theplant.ec.service.convenience_pickup.IStoreInfo|null} [storeInfo] ConveniencePickup storeInfo
                     */

                    /**
                     * Constructs a new ConveniencePickup.
                     * @memberof theplant.ec.service.convenience_pickup
                     * @classdesc Represents a ConveniencePickup.
                     * @implements IConveniencePickup
                     * @constructor
                     * @param {theplant.ec.service.convenience_pickup.IConveniencePickup=} [properties] Properties to set
                     */
                    function ConveniencePickup(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ConveniencePickup orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @instance
                     */
                    ConveniencePickup.prototype.orderCode = "";

                    /**
                     * ConveniencePickup secretCode.
                     * @member {string} secretCode
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @instance
                     */
                    ConveniencePickup.prototype.secretCode = "";

                    /**
                     * ConveniencePickup storeInfo.
                     * @member {theplant.ec.service.convenience_pickup.IStoreInfo|null|undefined} storeInfo
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @instance
                     */
                    ConveniencePickup.prototype.storeInfo = null;

                    /**
                     * Creates a new ConveniencePickup instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.IConveniencePickup=} [properties] Properties to set
                     * @returns {theplant.ec.service.convenience_pickup.ConveniencePickup} ConveniencePickup instance
                     */
                    ConveniencePickup.create = function create(properties) {
                        return new ConveniencePickup(properties);
                    };

                    /**
                     * Encodes the specified ConveniencePickup message. Does not implicitly {@link theplant.ec.service.convenience_pickup.ConveniencePickup.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.IConveniencePickup} message ConveniencePickup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConveniencePickup.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.secretCode != null && message.hasOwnProperty("secretCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.secretCode);
                        if (message.storeInfo != null && message.hasOwnProperty("storeInfo"))
                            $root.theplant.ec.service.convenience_pickup.StoreInfo.encode(message.storeInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ConveniencePickup message, length delimited. Does not implicitly {@link theplant.ec.service.convenience_pickup.ConveniencePickup.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.IConveniencePickup} message ConveniencePickup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ConveniencePickup.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ConveniencePickup message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.convenience_pickup.ConveniencePickup} ConveniencePickup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConveniencePickup.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.convenience_pickup.ConveniencePickup();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.secretCode = reader.string();
                                break;
                            case 3:
                                message.storeInfo = $root.theplant.ec.service.convenience_pickup.StoreInfo.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ConveniencePickup message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.convenience_pickup.ConveniencePickup} ConveniencePickup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ConveniencePickup.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ConveniencePickup message.
                     * @function verify
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ConveniencePickup.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.secretCode != null && message.hasOwnProperty("secretCode"))
                            if (!$util.isString(message.secretCode))
                                return "secretCode: string expected";
                        if (message.storeInfo != null && message.hasOwnProperty("storeInfo")) {
                            var error = $root.theplant.ec.service.convenience_pickup.StoreInfo.verify(message.storeInfo);
                            if (error)
                                return "storeInfo." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ConveniencePickup message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.convenience_pickup.ConveniencePickup} ConveniencePickup
                     */
                    ConveniencePickup.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.convenience_pickup.ConveniencePickup)
                            return object;
                        var message = new $root.theplant.ec.service.convenience_pickup.ConveniencePickup();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.secretCode != null)
                            message.secretCode = String(object.secretCode);
                        if (object.storeInfo != null) {
                            if (typeof object.storeInfo !== "object")
                                throw TypeError(".theplant.ec.service.convenience_pickup.ConveniencePickup.storeInfo: object expected");
                            message.storeInfo = $root.theplant.ec.service.convenience_pickup.StoreInfo.fromObject(object.storeInfo);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ConveniencePickup message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.ConveniencePickup} message ConveniencePickup
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ConveniencePickup.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.secretCode = "";
                            object.storeInfo = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.secretCode != null && message.hasOwnProperty("secretCode"))
                            object.secretCode = message.secretCode;
                        if (message.storeInfo != null && message.hasOwnProperty("storeInfo"))
                            object.storeInfo = $root.theplant.ec.service.convenience_pickup.StoreInfo.toObject(message.storeInfo, options);
                        return object;
                    };

                    /**
                     * Converts this ConveniencePickup to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.convenience_pickup.ConveniencePickup
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ConveniencePickup.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ConveniencePickup;
                })();

                convenience_pickup.StoreInfo = (function() {

                    /**
                     * Properties of a StoreInfo.
                     * @memberof theplant.ec.service.convenience_pickup
                     * @interface IStoreInfo
                     * @property {string|null} [sid] StoreInfo sid
                     * @property {string|null} [sname] StoreInfo sname
                     * @property {string|null} [saddr] StoreInfo saddr
                     * @property {string|null} [spt] StoreInfo spt
                     * @property {string|null} [stflg] StoreInfo stflg
                     * @property {string|null} [uid] StoreInfo uid
                     * @property {string|null} [sjarea] StoreInfo sjarea
                     * @property {string|null} [szcd] StoreInfo szcd
                     * @property {string|null} [stel] StoreInfo stel
                     * @property {string|null} [scode] StoreInfo scode
                     * @property {string|null} [spare3] StoreInfo spare3
                     * @property {string|null} [spare4] StoreInfo spare4
                     * @property {string|null} [spare5] StoreInfo spare5
                     * @property {string|null} [spare6] StoreInfo spare6
                     * @property {string|null} [spare7] StoreInfo spare7
                     * @property {string|null} [spare8] StoreInfo spare8
                     * @property {string|null} [spare9] StoreInfo spare9
                     * @property {string|null} [spare10] StoreInfo spare10
                     */

                    /**
                     * Constructs a new StoreInfo.
                     * @memberof theplant.ec.service.convenience_pickup
                     * @classdesc Represents a StoreInfo.
                     * @implements IStoreInfo
                     * @constructor
                     * @param {theplant.ec.service.convenience_pickup.IStoreInfo=} [properties] Properties to set
                     */
                    function StoreInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreInfo sid.
                     * @member {string} sid
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.sid = "";

                    /**
                     * StoreInfo sname.
                     * @member {string} sname
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.sname = "";

                    /**
                     * StoreInfo saddr.
                     * @member {string} saddr
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.saddr = "";

                    /**
                     * StoreInfo spt.
                     * @member {string} spt
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spt = "";

                    /**
                     * StoreInfo stflg.
                     * @member {string} stflg
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.stflg = "";

                    /**
                     * StoreInfo uid.
                     * @member {string} uid
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.uid = "";

                    /**
                     * StoreInfo sjarea.
                     * @member {string} sjarea
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.sjarea = "";

                    /**
                     * StoreInfo szcd.
                     * @member {string} szcd
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.szcd = "";

                    /**
                     * StoreInfo stel.
                     * @member {string} stel
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.stel = "";

                    /**
                     * StoreInfo scode.
                     * @member {string} scode
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.scode = "";

                    /**
                     * StoreInfo spare3.
                     * @member {string} spare3
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare3 = "";

                    /**
                     * StoreInfo spare4.
                     * @member {string} spare4
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare4 = "";

                    /**
                     * StoreInfo spare5.
                     * @member {string} spare5
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare5 = "";

                    /**
                     * StoreInfo spare6.
                     * @member {string} spare6
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare6 = "";

                    /**
                     * StoreInfo spare7.
                     * @member {string} spare7
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare7 = "";

                    /**
                     * StoreInfo spare8.
                     * @member {string} spare8
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare8 = "";

                    /**
                     * StoreInfo spare9.
                     * @member {string} spare9
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare9 = "";

                    /**
                     * StoreInfo spare10.
                     * @member {string} spare10
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     */
                    StoreInfo.prototype.spare10 = "";

                    /**
                     * Creates a new StoreInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.IStoreInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.convenience_pickup.StoreInfo} StoreInfo instance
                     */
                    StoreInfo.create = function create(properties) {
                        return new StoreInfo(properties);
                    };

                    /**
                     * Encodes the specified StoreInfo message. Does not implicitly {@link theplant.ec.service.convenience_pickup.StoreInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.IStoreInfo} message StoreInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sid != null && message.hasOwnProperty("sid"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sid);
                        if (message.sname != null && message.hasOwnProperty("sname"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sname);
                        if (message.saddr != null && message.hasOwnProperty("saddr"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.saddr);
                        if (message.spt != null && message.hasOwnProperty("spt"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.spt);
                        if (message.stflg != null && message.hasOwnProperty("stflg"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.stflg);
                        if (message.uid != null && message.hasOwnProperty("uid"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.uid);
                        if (message.sjarea != null && message.hasOwnProperty("sjarea"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.sjarea);
                        if (message.szcd != null && message.hasOwnProperty("szcd"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.szcd);
                        if (message.stel != null && message.hasOwnProperty("stel"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.stel);
                        if (message.scode != null && message.hasOwnProperty("scode"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.scode);
                        if (message.spare3 != null && message.hasOwnProperty("spare3"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.spare3);
                        if (message.spare4 != null && message.hasOwnProperty("spare4"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.spare4);
                        if (message.spare5 != null && message.hasOwnProperty("spare5"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.spare5);
                        if (message.spare6 != null && message.hasOwnProperty("spare6"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.spare6);
                        if (message.spare7 != null && message.hasOwnProperty("spare7"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.spare7);
                        if (message.spare8 != null && message.hasOwnProperty("spare8"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.spare8);
                        if (message.spare9 != null && message.hasOwnProperty("spare9"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.spare9);
                        if (message.spare10 != null && message.hasOwnProperty("spare10"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.spare10);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreInfo message, length delimited. Does not implicitly {@link theplant.ec.service.convenience_pickup.StoreInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.IStoreInfo} message StoreInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.convenience_pickup.StoreInfo} StoreInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.convenience_pickup.StoreInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sid = reader.string();
                                break;
                            case 2:
                                message.sname = reader.string();
                                break;
                            case 3:
                                message.saddr = reader.string();
                                break;
                            case 4:
                                message.spt = reader.string();
                                break;
                            case 5:
                                message.stflg = reader.string();
                                break;
                            case 6:
                                message.uid = reader.string();
                                break;
                            case 7:
                                message.sjarea = reader.string();
                                break;
                            case 8:
                                message.szcd = reader.string();
                                break;
                            case 9:
                                message.stel = reader.string();
                                break;
                            case 10:
                                message.scode = reader.string();
                                break;
                            case 11:
                                message.spare3 = reader.string();
                                break;
                            case 12:
                                message.spare4 = reader.string();
                                break;
                            case 13:
                                message.spare5 = reader.string();
                                break;
                            case 14:
                                message.spare6 = reader.string();
                                break;
                            case 15:
                                message.spare7 = reader.string();
                                break;
                            case 16:
                                message.spare8 = reader.string();
                                break;
                            case 17:
                                message.spare9 = reader.string();
                                break;
                            case 18:
                                message.spare10 = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.convenience_pickup.StoreInfo} StoreInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sid != null && message.hasOwnProperty("sid"))
                            if (!$util.isString(message.sid))
                                return "sid: string expected";
                        if (message.sname != null && message.hasOwnProperty("sname"))
                            if (!$util.isString(message.sname))
                                return "sname: string expected";
                        if (message.saddr != null && message.hasOwnProperty("saddr"))
                            if (!$util.isString(message.saddr))
                                return "saddr: string expected";
                        if (message.spt != null && message.hasOwnProperty("spt"))
                            if (!$util.isString(message.spt))
                                return "spt: string expected";
                        if (message.stflg != null && message.hasOwnProperty("stflg"))
                            if (!$util.isString(message.stflg))
                                return "stflg: string expected";
                        if (message.uid != null && message.hasOwnProperty("uid"))
                            if (!$util.isString(message.uid))
                                return "uid: string expected";
                        if (message.sjarea != null && message.hasOwnProperty("sjarea"))
                            if (!$util.isString(message.sjarea))
                                return "sjarea: string expected";
                        if (message.szcd != null && message.hasOwnProperty("szcd"))
                            if (!$util.isString(message.szcd))
                                return "szcd: string expected";
                        if (message.stel != null && message.hasOwnProperty("stel"))
                            if (!$util.isString(message.stel))
                                return "stel: string expected";
                        if (message.scode != null && message.hasOwnProperty("scode"))
                            if (!$util.isString(message.scode))
                                return "scode: string expected";
                        if (message.spare3 != null && message.hasOwnProperty("spare3"))
                            if (!$util.isString(message.spare3))
                                return "spare3: string expected";
                        if (message.spare4 != null && message.hasOwnProperty("spare4"))
                            if (!$util.isString(message.spare4))
                                return "spare4: string expected";
                        if (message.spare5 != null && message.hasOwnProperty("spare5"))
                            if (!$util.isString(message.spare5))
                                return "spare5: string expected";
                        if (message.spare6 != null && message.hasOwnProperty("spare6"))
                            if (!$util.isString(message.spare6))
                                return "spare6: string expected";
                        if (message.spare7 != null && message.hasOwnProperty("spare7"))
                            if (!$util.isString(message.spare7))
                                return "spare7: string expected";
                        if (message.spare8 != null && message.hasOwnProperty("spare8"))
                            if (!$util.isString(message.spare8))
                                return "spare8: string expected";
                        if (message.spare9 != null && message.hasOwnProperty("spare9"))
                            if (!$util.isString(message.spare9))
                                return "spare9: string expected";
                        if (message.spare10 != null && message.hasOwnProperty("spare10"))
                            if (!$util.isString(message.spare10))
                                return "spare10: string expected";
                        return null;
                    };

                    /**
                     * Creates a StoreInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.convenience_pickup.StoreInfo} StoreInfo
                     */
                    StoreInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.convenience_pickup.StoreInfo)
                            return object;
                        var message = new $root.theplant.ec.service.convenience_pickup.StoreInfo();
                        if (object.sid != null)
                            message.sid = String(object.sid);
                        if (object.sname != null)
                            message.sname = String(object.sname);
                        if (object.saddr != null)
                            message.saddr = String(object.saddr);
                        if (object.spt != null)
                            message.spt = String(object.spt);
                        if (object.stflg != null)
                            message.stflg = String(object.stflg);
                        if (object.uid != null)
                            message.uid = String(object.uid);
                        if (object.sjarea != null)
                            message.sjarea = String(object.sjarea);
                        if (object.szcd != null)
                            message.szcd = String(object.szcd);
                        if (object.stel != null)
                            message.stel = String(object.stel);
                        if (object.scode != null)
                            message.scode = String(object.scode);
                        if (object.spare3 != null)
                            message.spare3 = String(object.spare3);
                        if (object.spare4 != null)
                            message.spare4 = String(object.spare4);
                        if (object.spare5 != null)
                            message.spare5 = String(object.spare5);
                        if (object.spare6 != null)
                            message.spare6 = String(object.spare6);
                        if (object.spare7 != null)
                            message.spare7 = String(object.spare7);
                        if (object.spare8 != null)
                            message.spare8 = String(object.spare8);
                        if (object.spare9 != null)
                            message.spare9 = String(object.spare9);
                        if (object.spare10 != null)
                            message.spare10 = String(object.spare10);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @static
                     * @param {theplant.ec.service.convenience_pickup.StoreInfo} message StoreInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sid = "";
                            object.sname = "";
                            object.saddr = "";
                            object.spt = "";
                            object.stflg = "";
                            object.uid = "";
                            object.sjarea = "";
                            object.szcd = "";
                            object.stel = "";
                            object.scode = "";
                            object.spare3 = "";
                            object.spare4 = "";
                            object.spare5 = "";
                            object.spare6 = "";
                            object.spare7 = "";
                            object.spare8 = "";
                            object.spare9 = "";
                            object.spare10 = "";
                        }
                        if (message.sid != null && message.hasOwnProperty("sid"))
                            object.sid = message.sid;
                        if (message.sname != null && message.hasOwnProperty("sname"))
                            object.sname = message.sname;
                        if (message.saddr != null && message.hasOwnProperty("saddr"))
                            object.saddr = message.saddr;
                        if (message.spt != null && message.hasOwnProperty("spt"))
                            object.spt = message.spt;
                        if (message.stflg != null && message.hasOwnProperty("stflg"))
                            object.stflg = message.stflg;
                        if (message.uid != null && message.hasOwnProperty("uid"))
                            object.uid = message.uid;
                        if (message.sjarea != null && message.hasOwnProperty("sjarea"))
                            object.sjarea = message.sjarea;
                        if (message.szcd != null && message.hasOwnProperty("szcd"))
                            object.szcd = message.szcd;
                        if (message.stel != null && message.hasOwnProperty("stel"))
                            object.stel = message.stel;
                        if (message.scode != null && message.hasOwnProperty("scode"))
                            object.scode = message.scode;
                        if (message.spare3 != null && message.hasOwnProperty("spare3"))
                            object.spare3 = message.spare3;
                        if (message.spare4 != null && message.hasOwnProperty("spare4"))
                            object.spare4 = message.spare4;
                        if (message.spare5 != null && message.hasOwnProperty("spare5"))
                            object.spare5 = message.spare5;
                        if (message.spare6 != null && message.hasOwnProperty("spare6"))
                            object.spare6 = message.spare6;
                        if (message.spare7 != null && message.hasOwnProperty("spare7"))
                            object.spare7 = message.spare7;
                        if (message.spare8 != null && message.hasOwnProperty("spare8"))
                            object.spare8 = message.spare8;
                        if (message.spare9 != null && message.hasOwnProperty("spare9"))
                            object.spare9 = message.spare9;
                        if (message.spare10 != null && message.hasOwnProperty("spare10"))
                            object.spare10 = message.spare10;
                        return object;
                    };

                    /**
                     * Converts this StoreInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.convenience_pickup.StoreInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreInfo;
                })();

                return convenience_pickup;
            })();

            service.tls = (function() {

                /**
                 * Namespace tls.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var tls = {};

                /**
                 * CertificateFormat enum.
                 * @name theplant.ec.service.tls.CertificateFormat
                 * @enum {string}
                 * @property {number} PEM=0 PEM value
                 * @property {number} DER=1 DER value
                 * @property {number} PEM_BUNDLE=2 PEM_BUNDLE value
                 */
                tls.CertificateFormat = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PEM"] = 0;
                    values[valuesById[1] = "DER"] = 1;
                    values[valuesById[2] = "PEM_BUNDLE"] = 2;
                    return values;
                })();

                tls.Device = (function() {

                    /**
                     * Properties of a Device.
                     * @memberof theplant.ec.service.tls
                     * @interface IDevice
                     * @property {string|null} [id] Device id
                     * @property {string|null} [name] Device name
                     */

                    /**
                     * Constructs a new Device.
                     * @memberof theplant.ec.service.tls
                     * @classdesc Represents a Device.
                     * @implements IDevice
                     * @constructor
                     * @param {theplant.ec.service.tls.IDevice=} [properties] Properties to set
                     */
                    function Device(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Device id.
                     * @member {string} id
                     * @memberof theplant.ec.service.tls.Device
                     * @instance
                     */
                    Device.prototype.id = "";

                    /**
                     * Device name.
                     * @member {string} name
                     * @memberof theplant.ec.service.tls.Device
                     * @instance
                     */
                    Device.prototype.name = "";

                    /**
                     * Creates a new Device instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {theplant.ec.service.tls.IDevice=} [properties] Properties to set
                     * @returns {theplant.ec.service.tls.Device} Device instance
                     */
                    Device.create = function create(properties) {
                        return new Device(properties);
                    };

                    /**
                     * Encodes the specified Device message. Does not implicitly {@link theplant.ec.service.tls.Device.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {theplant.ec.service.tls.IDevice} message Device message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Device.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                        return writer;
                    };

                    /**
                     * Encodes the specified Device message, length delimited. Does not implicitly {@link theplant.ec.service.tls.Device.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {theplant.ec.service.tls.IDevice} message Device message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Device.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Device message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.tls.Device} Device
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Device.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.tls.Device();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.name = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Device message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.tls.Device} Device
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Device.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Device message.
                     * @function verify
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Device.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        return null;
                    };

                    /**
                     * Creates a Device message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.tls.Device} Device
                     */
                    Device.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.tls.Device)
                            return object;
                        var message = new $root.theplant.ec.service.tls.Device();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.name != null)
                            message.name = String(object.name);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Device message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.tls.Device
                     * @static
                     * @param {theplant.ec.service.tls.Device} message Device
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Device.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.name = "";
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        return object;
                    };

                    /**
                     * Converts this Device to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.tls.Device
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Device.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Device;
                })();

                return tls;
            })();

            service.inventory = (function() {

                /**
                 * Namespace inventory.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var inventory = {};

                inventory.ArticleStock = (function() {

                    /**
                     * Properties of an ArticleStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IArticleStock
                     * @property {string|null} [articleCode] The Product SKU code
                     * @property {number|Long|null} [quantity] ArticleStock quantity
                     */

                    /**
                     * Constructs a new ArticleStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an ArticleStock.
                     * @implements IArticleStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IArticleStock=} [properties] Properties to set
                     */
                    function ArticleStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * The Product SKU code
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @instance
                     */
                    ArticleStock.prototype.articleCode = "";

                    /**
                     * ArticleStock quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @instance
                     */
                    ArticleStock.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new ArticleStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {theplant.ec.service.inventory.IArticleStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ArticleStock} ArticleStock instance
                     */
                    ArticleStock.create = function create(properties) {
                        return new ArticleStock(properties);
                    };

                    /**
                     * Encodes the specified ArticleStock message. Does not implicitly {@link theplant.ec.service.inventory.ArticleStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {theplant.ec.service.inventory.IArticleStock} message ArticleStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.quantity);
                        return writer;
                    };

                    /**
                     * Encodes the specified ArticleStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ArticleStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {theplant.ec.service.inventory.IArticleStock} message ArticleStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ArticleStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ArticleStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ArticleStock} ArticleStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ArticleStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.quantity = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ArticleStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ArticleStock} ArticleStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ArticleStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ArticleStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ArticleStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an ArticleStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ArticleStock} ArticleStock
                     */
                    ArticleStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ArticleStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ArticleStock();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from an ArticleStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @static
                     * @param {theplant.ec.service.inventory.ArticleStock} message ArticleStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ArticleStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        return object;
                    };

                    /**
                     * Converts this ArticleStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ArticleStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ArticleStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ArticleStock;
                })();

                inventory.Stocks = (function() {

                    /**
                     * Properties of a Stocks.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStocks
                     * @property {Array.<theplant.ec.service.inventory.IArticleStock>|null} [articleStocks] Stocks articleStocks
                     */

                    /**
                     * Constructs a new Stocks.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a Stocks.
                     * @implements IStocks
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStocks=} [properties] Properties to set
                     */
                    function Stocks(properties) {
                        this.articleStocks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Stocks articleStocks.
                     * @member {Array.<theplant.ec.service.inventory.IArticleStock>} articleStocks
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @instance
                     */
                    Stocks.prototype.articleStocks = $util.emptyArray;

                    /**
                     * Creates a new Stocks instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {theplant.ec.service.inventory.IStocks=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.Stocks} Stocks instance
                     */
                    Stocks.create = function create(properties) {
                        return new Stocks(properties);
                    };

                    /**
                     * Encodes the specified Stocks message. Does not implicitly {@link theplant.ec.service.inventory.Stocks.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {theplant.ec.service.inventory.IStocks} message Stocks message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Stocks.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleStocks != null && message.articleStocks.length)
                            for (var i = 0; i < message.articleStocks.length; ++i)
                                $root.theplant.ec.service.inventory.ArticleStock.encode(message.articleStocks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Stocks message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.Stocks.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {theplant.ec.service.inventory.IStocks} message Stocks message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Stocks.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Stocks message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.Stocks} Stocks
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Stocks.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.Stocks();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.articleStocks && message.articleStocks.length))
                                    message.articleStocks = [];
                                message.articleStocks.push($root.theplant.ec.service.inventory.ArticleStock.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Stocks message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.Stocks} Stocks
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Stocks.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Stocks message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Stocks.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleStocks != null && message.hasOwnProperty("articleStocks")) {
                            if (!Array.isArray(message.articleStocks))
                                return "articleStocks: array expected";
                            for (var i = 0; i < message.articleStocks.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.ArticleStock.verify(message.articleStocks[i]);
                                if (error)
                                    return "articleStocks." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Stocks message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.Stocks} Stocks
                     */
                    Stocks.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.Stocks)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.Stocks();
                        if (object.articleStocks) {
                            if (!Array.isArray(object.articleStocks))
                                throw TypeError(".theplant.ec.service.inventory.Stocks.articleStocks: array expected");
                            message.articleStocks = [];
                            for (var i = 0; i < object.articleStocks.length; ++i) {
                                if (typeof object.articleStocks[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.Stocks.articleStocks: object expected");
                                message.articleStocks[i] = $root.theplant.ec.service.inventory.ArticleStock.fromObject(object.articleStocks[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Stocks message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @static
                     * @param {theplant.ec.service.inventory.Stocks} message Stocks
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Stocks.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.articleStocks = [];
                        if (message.articleStocks && message.articleStocks.length) {
                            object.articleStocks = [];
                            for (var j = 0; j < message.articleStocks.length; ++j)
                                object.articleStocks[j] = $root.theplant.ec.service.inventory.ArticleStock.toObject(message.articleStocks[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Stocks to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.Stocks
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Stocks.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Stocks;
                })();

                /**
                 * InventoryItemState enum.
                 * @name theplant.ec.service.inventory.InventoryItemState
                 * @enum {string}
                 * @property {number} ITEM_NO_INVENTORY_STATE=0 ITEM_NO_INVENTORY_STATE value
                 * @property {number} ITEM_TEMPORARY_OUT_OF_STOCK=1 ITEM_TEMPORARY_OUT_OF_STOCK value
                 * @property {number} ITEM_READY_FOR_SHIPPING=2 ITEM_READY_FOR_SHIPPING value
                 * @property {number} ITEM_SHIPPED=3 ITEM_SHIPPED value
                 * @property {number} ITEM_CANCELLED=4 ITEM_CANCELLED value
                 * @property {number} ITEM_CUSTOMER_CANCELLED=5 ITEM_CUSTOMER_CANCELLED value
                 * @property {number} ITEM_SOLD_OUT=6 ITEM_SOLD_OUT value
                 * @property {number} ITEM_RECEPTION_REFUSED=7 ITEM_RECEPTION_REFUSED value
                 * @property {number} ITEM_RETURNED=8 ITEM_RETURNED value
                 */
                inventory.InventoryItemState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ITEM_NO_INVENTORY_STATE"] = 0;
                    values[valuesById[1] = "ITEM_TEMPORARY_OUT_OF_STOCK"] = 1;
                    values[valuesById[2] = "ITEM_READY_FOR_SHIPPING"] = 2;
                    values[valuesById[3] = "ITEM_SHIPPED"] = 3;
                    values[valuesById[4] = "ITEM_CANCELLED"] = 4;
                    values[valuesById[5] = "ITEM_CUSTOMER_CANCELLED"] = 5;
                    values[valuesById[6] = "ITEM_SOLD_OUT"] = 6;
                    values[valuesById[7] = "ITEM_RECEPTION_REFUSED"] = 7;
                    values[valuesById[8] = "ITEM_RETURNED"] = 8;
                    return values;
                })();

                inventory.OrderDeliveryInfo = (function() {

                    /**
                     * Properties of an OrderDeliveryInfo.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderDeliveryInfo
                     * @property {string|null} [orderCode] OrderDeliveryInfo orderCode
                     * @property {theplant.ec.service.orders.OrderState|null} [state] OrderDeliveryInfo state
                     * @property {theplant.ec.service.inventory.IOrderCancelled|null} [cancelledInfo] OrderDeliveryInfo cancelledInfo
                     * @property {theplant.ec.service.inventory.IOrderShipped|null} [shippedInfo] OrderDeliveryInfo shippedInfo
                     * @property {theplant.ec.service.inventory.IOrderReturned|null} [returnedInfo] OrderDeliveryInfo returnedInfo
                     */

                    /**
                     * Constructs a new OrderDeliveryInfo.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderDeliveryInfo.
                     * @implements IOrderDeliveryInfo
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderDeliveryInfo=} [properties] Properties to set
                     */
                    function OrderDeliveryInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderDeliveryInfo orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @instance
                     */
                    OrderDeliveryInfo.prototype.orderCode = "";

                    /**
                     * OrderDeliveryInfo state.
                     * @member {theplant.ec.service.orders.OrderState} state
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @instance
                     */
                    OrderDeliveryInfo.prototype.state = 0;

                    /**
                     * OrderDeliveryInfo cancelledInfo.
                     * @member {theplant.ec.service.inventory.IOrderCancelled|null|undefined} cancelledInfo
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @instance
                     */
                    OrderDeliveryInfo.prototype.cancelledInfo = null;

                    /**
                     * OrderDeliveryInfo shippedInfo.
                     * @member {theplant.ec.service.inventory.IOrderShipped|null|undefined} shippedInfo
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @instance
                     */
                    OrderDeliveryInfo.prototype.shippedInfo = null;

                    /**
                     * OrderDeliveryInfo returnedInfo.
                     * @member {theplant.ec.service.inventory.IOrderReturned|null|undefined} returnedInfo
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @instance
                     */
                    OrderDeliveryInfo.prototype.returnedInfo = null;

                    /**
                     * Creates a new OrderDeliveryInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderDeliveryInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderDeliveryInfo} OrderDeliveryInfo instance
                     */
                    OrderDeliveryInfo.create = function create(properties) {
                        return new OrderDeliveryInfo(properties);
                    };

                    /**
                     * Encodes the specified OrderDeliveryInfo message. Does not implicitly {@link theplant.ec.service.inventory.OrderDeliveryInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderDeliveryInfo} message OrderDeliveryInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderDeliveryInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.state);
                        if (message.cancelledInfo != null && message.hasOwnProperty("cancelledInfo"))
                            $root.theplant.ec.service.inventory.OrderCancelled.encode(message.cancelledInfo, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.shippedInfo != null && message.hasOwnProperty("shippedInfo"))
                            $root.theplant.ec.service.inventory.OrderShipped.encode(message.shippedInfo, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.returnedInfo != null && message.hasOwnProperty("returnedInfo"))
                            $root.theplant.ec.service.inventory.OrderReturned.encode(message.returnedInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderDeliveryInfo message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderDeliveryInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderDeliveryInfo} message OrderDeliveryInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderDeliveryInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderDeliveryInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderDeliveryInfo} OrderDeliveryInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderDeliveryInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderDeliveryInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.state = reader.int32();
                                break;
                            case 3:
                                message.cancelledInfo = $root.theplant.ec.service.inventory.OrderCancelled.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.shippedInfo = $root.theplant.ec.service.inventory.OrderShipped.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.returnedInfo = $root.theplant.ec.service.inventory.OrderReturned.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderDeliveryInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderDeliveryInfo} OrderDeliveryInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderDeliveryInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderDeliveryInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderDeliveryInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            switch (message.state) {
                            default:
                                return "state: enum value expected";
                            case 0:
                            case 1:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                                break;
                            }
                        if (message.cancelledInfo != null && message.hasOwnProperty("cancelledInfo")) {
                            var error = $root.theplant.ec.service.inventory.OrderCancelled.verify(message.cancelledInfo);
                            if (error)
                                return "cancelledInfo." + error;
                        }
                        if (message.shippedInfo != null && message.hasOwnProperty("shippedInfo")) {
                            var error = $root.theplant.ec.service.inventory.OrderShipped.verify(message.shippedInfo);
                            if (error)
                                return "shippedInfo." + error;
                        }
                        if (message.returnedInfo != null && message.hasOwnProperty("returnedInfo")) {
                            var error = $root.theplant.ec.service.inventory.OrderReturned.verify(message.returnedInfo);
                            if (error)
                                return "returnedInfo." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderDeliveryInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderDeliveryInfo} OrderDeliveryInfo
                     */
                    OrderDeliveryInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderDeliveryInfo)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderDeliveryInfo();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        switch (object.state) {
                        case "DRAFT":
                        case 0:
                            message.state = 0;
                            break;
                        case "CONFIRMED":
                        case 1:
                            message.state = 1;
                            break;
                        case "FREEZED":
                        case 3:
                            message.state = 3;
                            break;
                        case "READY_FOR_SHIPPING":
                        case 4:
                            message.state = 4;
                            break;
                        case "SHIPPED":
                        case 5:
                            message.state = 5;
                            break;
                        case "PARTIALLY_SHIPPED":
                        case 6:
                            message.state = 6;
                            break;
                        case "CANCELED":
                        case 7:
                            message.state = 7;
                            break;
                        case "PARTIALLY_RETURNED":
                        case 8:
                            message.state = 8;
                            break;
                        case "RETURNED":
                        case 9:
                            message.state = 9;
                            break;
                        case "REQUEST_RETURNED":
                        case 10:
                            message.state = 10;
                            break;
                        case "WAITING_PAY":
                        case 11:
                            message.state = 11;
                            break;
                        case "WAITING_PAY_CANCELED":
                        case 12:
                            message.state = 12;
                            break;
                        }
                        if (object.cancelledInfo != null) {
                            if (typeof object.cancelledInfo !== "object")
                                throw TypeError(".theplant.ec.service.inventory.OrderDeliveryInfo.cancelledInfo: object expected");
                            message.cancelledInfo = $root.theplant.ec.service.inventory.OrderCancelled.fromObject(object.cancelledInfo);
                        }
                        if (object.shippedInfo != null) {
                            if (typeof object.shippedInfo !== "object")
                                throw TypeError(".theplant.ec.service.inventory.OrderDeliveryInfo.shippedInfo: object expected");
                            message.shippedInfo = $root.theplant.ec.service.inventory.OrderShipped.fromObject(object.shippedInfo);
                        }
                        if (object.returnedInfo != null) {
                            if (typeof object.returnedInfo !== "object")
                                throw TypeError(".theplant.ec.service.inventory.OrderDeliveryInfo.returnedInfo: object expected");
                            message.returnedInfo = $root.theplant.ec.service.inventory.OrderReturned.fromObject(object.returnedInfo);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderDeliveryInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @static
                     * @param {theplant.ec.service.inventory.OrderDeliveryInfo} message OrderDeliveryInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderDeliveryInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.state = options.enums === String ? "DRAFT" : 0;
                            object.cancelledInfo = null;
                            object.shippedInfo = null;
                            object.returnedInfo = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = options.enums === String ? $root.theplant.ec.service.orders.OrderState[message.state] : message.state;
                        if (message.cancelledInfo != null && message.hasOwnProperty("cancelledInfo"))
                            object.cancelledInfo = $root.theplant.ec.service.inventory.OrderCancelled.toObject(message.cancelledInfo, options);
                        if (message.shippedInfo != null && message.hasOwnProperty("shippedInfo"))
                            object.shippedInfo = $root.theplant.ec.service.inventory.OrderShipped.toObject(message.shippedInfo, options);
                        if (message.returnedInfo != null && message.hasOwnProperty("returnedInfo"))
                            object.returnedInfo = $root.theplant.ec.service.inventory.OrderReturned.toObject(message.returnedInfo, options);
                        return object;
                    };

                    /**
                     * Converts this OrderDeliveryInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderDeliveryInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderDeliveryInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderDeliveryInfo;
                })();

                inventory.OrderStockInfo = (function() {

                    /**
                     * Properties of an OrderStockInfo.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderStockInfo
                     * @property {string|null} [orderCode] OrderStockInfo orderCode
                     * @property {Array.<theplant.ec.service.inventory.IArticleStock>|null} [items] OrderStockInfo items
                     */

                    /**
                     * Constructs a new OrderStockInfo.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderStockInfo.
                     * @implements IOrderStockInfo
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderStockInfo=} [properties] Properties to set
                     */
                    function OrderStockInfo(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderStockInfo orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @instance
                     */
                    OrderStockInfo.prototype.orderCode = "";

                    /**
                     * OrderStockInfo items.
                     * @member {Array.<theplant.ec.service.inventory.IArticleStock>} items
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @instance
                     */
                    OrderStockInfo.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new OrderStockInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderStockInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderStockInfo} OrderStockInfo instance
                     */
                    OrderStockInfo.create = function create(properties) {
                        return new OrderStockInfo(properties);
                    };

                    /**
                     * Encodes the specified OrderStockInfo message. Does not implicitly {@link theplant.ec.service.inventory.OrderStockInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderStockInfo} message OrderStockInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderStockInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.inventory.ArticleStock.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderStockInfo message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderStockInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderStockInfo} message OrderStockInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderStockInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderStockInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderStockInfo} OrderStockInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderStockInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderStockInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.inventory.ArticleStock.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderStockInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderStockInfo} OrderStockInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderStockInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderStockInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderStockInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.ArticleStock.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderStockInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderStockInfo} OrderStockInfo
                     */
                    OrderStockInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderStockInfo)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderStockInfo();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.inventory.OrderStockInfo.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.OrderStockInfo.items: object expected");
                                message.items[i] = $root.theplant.ec.service.inventory.ArticleStock.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderStockInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @static
                     * @param {theplant.ec.service.inventory.OrderStockInfo} message OrderStockInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderStockInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.inventory.ArticleStock.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this OrderStockInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderStockInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderStockInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderStockInfo;
                })();

                inventory.OrderShipped = (function() {

                    /**
                     * Properties of an OrderShipped.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderShipped
                     * @property {string|null} [orderCode] OrderShipped orderCode
                     * @property {string|null} [shipmentId] OrderShipped shipmentId
                     * @property {string|null} [shipmentDate] OrderShipped shipmentDate
                     * @property {boolean|null} [isShipFromStore] OrderShipped isShipFromStore
                     */

                    /**
                     * Constructs a new OrderShipped.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderShipped.
                     * @implements IOrderShipped
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderShipped=} [properties] Properties to set
                     */
                    function OrderShipped(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderShipped orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @instance
                     */
                    OrderShipped.prototype.orderCode = "";

                    /**
                     * OrderShipped shipmentId.
                     * @member {string} shipmentId
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @instance
                     */
                    OrderShipped.prototype.shipmentId = "";

                    /**
                     * OrderShipped shipmentDate.
                     * @member {string} shipmentDate
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @instance
                     */
                    OrderShipped.prototype.shipmentDate = "";

                    /**
                     * OrderShipped isShipFromStore.
                     * @member {boolean} isShipFromStore
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @instance
                     */
                    OrderShipped.prototype.isShipFromStore = false;

                    /**
                     * Creates a new OrderShipped instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderShipped=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderShipped} OrderShipped instance
                     */
                    OrderShipped.create = function create(properties) {
                        return new OrderShipped(properties);
                    };

                    /**
                     * Encodes the specified OrderShipped message. Does not implicitly {@link theplant.ec.service.inventory.OrderShipped.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderShipped} message OrderShipped message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderShipped.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.shipmentId != null && message.hasOwnProperty("shipmentId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.shipmentId);
                        if (message.shipmentDate != null && message.hasOwnProperty("shipmentDate"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.shipmentDate);
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isShipFromStore);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderShipped message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderShipped.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderShipped} message OrderShipped message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderShipped.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderShipped message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderShipped} OrderShipped
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderShipped.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderShipped();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.shipmentId = reader.string();
                                break;
                            case 3:
                                message.shipmentDate = reader.string();
                                break;
                            case 4:
                                message.isShipFromStore = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderShipped message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderShipped} OrderShipped
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderShipped.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderShipped message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderShipped.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.shipmentId != null && message.hasOwnProperty("shipmentId"))
                            if (!$util.isString(message.shipmentId))
                                return "shipmentId: string expected";
                        if (message.shipmentDate != null && message.hasOwnProperty("shipmentDate"))
                            if (!$util.isString(message.shipmentDate))
                                return "shipmentDate: string expected";
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            if (typeof message.isShipFromStore !== "boolean")
                                return "isShipFromStore: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an OrderShipped message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderShipped} OrderShipped
                     */
                    OrderShipped.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderShipped)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderShipped();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.shipmentId != null)
                            message.shipmentId = String(object.shipmentId);
                        if (object.shipmentDate != null)
                            message.shipmentDate = String(object.shipmentDate);
                        if (object.isShipFromStore != null)
                            message.isShipFromStore = Boolean(object.isShipFromStore);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderShipped message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @static
                     * @param {theplant.ec.service.inventory.OrderShipped} message OrderShipped
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderShipped.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.shipmentId = "";
                            object.shipmentDate = "";
                            object.isShipFromStore = false;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.shipmentId != null && message.hasOwnProperty("shipmentId"))
                            object.shipmentId = message.shipmentId;
                        if (message.shipmentDate != null && message.hasOwnProperty("shipmentDate"))
                            object.shipmentDate = message.shipmentDate;
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            object.isShipFromStore = message.isShipFromStore;
                        return object;
                    };

                    /**
                     * Converts this OrderShipped to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderShipped
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderShipped.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderShipped;
                })();

                inventory.OrderItemStateChange = (function() {

                    /**
                     * Properties of an OrderItemStateChange.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderItemStateChange
                     * @property {string|null} [orderCode] OrderItemStateChange orderCode
                     * @property {string|null} [articleCode] OrderItemStateChange articleCode
                     * @property {number|Long|null} [toQuantity] OrderItemStateChange toQuantity
                     * @property {theplant.ec.service.inventory.InventoryItemState|null} [toState] OrderItemStateChange toState
                     * @property {string|null} [vendorState] OrderItemStateChange vendorState
                     * @property {boolean|null} [isImported] OrderItemStateChange isImported
                     */

                    /**
                     * Constructs a new OrderItemStateChange.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderItemStateChange.
                     * @implements IOrderItemStateChange
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderItemStateChange=} [properties] Properties to set
                     */
                    function OrderItemStateChange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderItemStateChange orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     */
                    OrderItemStateChange.prototype.orderCode = "";

                    /**
                     * OrderItemStateChange articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     */
                    OrderItemStateChange.prototype.articleCode = "";

                    /**
                     * OrderItemStateChange toQuantity.
                     * @member {number|Long} toQuantity
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     */
                    OrderItemStateChange.prototype.toQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * OrderItemStateChange toState.
                     * @member {theplant.ec.service.inventory.InventoryItemState} toState
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     */
                    OrderItemStateChange.prototype.toState = 0;

                    /**
                     * OrderItemStateChange vendorState.
                     * @member {string} vendorState
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     */
                    OrderItemStateChange.prototype.vendorState = "";

                    /**
                     * OrderItemStateChange isImported.
                     * @member {boolean} isImported
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     */
                    OrderItemStateChange.prototype.isImported = false;

                    /**
                     * Creates a new OrderItemStateChange instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderItemStateChange=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderItemStateChange} OrderItemStateChange instance
                     */
                    OrderItemStateChange.create = function create(properties) {
                        return new OrderItemStateChange(properties);
                    };

                    /**
                     * Encodes the specified OrderItemStateChange message. Does not implicitly {@link theplant.ec.service.inventory.OrderItemStateChange.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderItemStateChange} message OrderItemStateChange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItemStateChange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.toQuantity != null && message.hasOwnProperty("toQuantity"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.toQuantity);
                        if (message.toState != null && message.hasOwnProperty("toState"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.toState);
                        if (message.vendorState != null && message.hasOwnProperty("vendorState"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.vendorState);
                        if (message.isImported != null && message.hasOwnProperty("isImported"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isImported);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderItemStateChange message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderItemStateChange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderItemStateChange} message OrderItemStateChange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItemStateChange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderItemStateChange message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderItemStateChange} OrderItemStateChange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItemStateChange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderItemStateChange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.toQuantity = reader.int64();
                                break;
                            case 4:
                                message.toState = reader.int32();
                                break;
                            case 5:
                                message.vendorState = reader.string();
                                break;
                            case 6:
                                message.isImported = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderItemStateChange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderItemStateChange} OrderItemStateChange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItemStateChange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderItemStateChange message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderItemStateChange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.toQuantity != null && message.hasOwnProperty("toQuantity"))
                            if (!$util.isInteger(message.toQuantity) && !(message.toQuantity && $util.isInteger(message.toQuantity.low) && $util.isInteger(message.toQuantity.high)))
                                return "toQuantity: integer|Long expected";
                        if (message.toState != null && message.hasOwnProperty("toState"))
                            switch (message.toState) {
                            default:
                                return "toState: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                                break;
                            }
                        if (message.vendorState != null && message.hasOwnProperty("vendorState"))
                            if (!$util.isString(message.vendorState))
                                return "vendorState: string expected";
                        if (message.isImported != null && message.hasOwnProperty("isImported"))
                            if (typeof message.isImported !== "boolean")
                                return "isImported: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an OrderItemStateChange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderItemStateChange} OrderItemStateChange
                     */
                    OrderItemStateChange.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderItemStateChange)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderItemStateChange();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.toQuantity != null)
                            if ($util.Long)
                                (message.toQuantity = $util.Long.fromValue(object.toQuantity)).unsigned = false;
                            else if (typeof object.toQuantity === "string")
                                message.toQuantity = parseInt(object.toQuantity, 10);
                            else if (typeof object.toQuantity === "number")
                                message.toQuantity = object.toQuantity;
                            else if (typeof object.toQuantity === "object")
                                message.toQuantity = new $util.LongBits(object.toQuantity.low >>> 0, object.toQuantity.high >>> 0).toNumber();
                        switch (object.toState) {
                        case "ITEM_NO_INVENTORY_STATE":
                        case 0:
                            message.toState = 0;
                            break;
                        case "ITEM_TEMPORARY_OUT_OF_STOCK":
                        case 1:
                            message.toState = 1;
                            break;
                        case "ITEM_READY_FOR_SHIPPING":
                        case 2:
                            message.toState = 2;
                            break;
                        case "ITEM_SHIPPED":
                        case 3:
                            message.toState = 3;
                            break;
                        case "ITEM_CANCELLED":
                        case 4:
                            message.toState = 4;
                            break;
                        case "ITEM_CUSTOMER_CANCELLED":
                        case 5:
                            message.toState = 5;
                            break;
                        case "ITEM_SOLD_OUT":
                        case 6:
                            message.toState = 6;
                            break;
                        case "ITEM_RECEPTION_REFUSED":
                        case 7:
                            message.toState = 7;
                            break;
                        case "ITEM_RETURNED":
                        case 8:
                            message.toState = 8;
                            break;
                        }
                        if (object.vendorState != null)
                            message.vendorState = String(object.vendorState);
                        if (object.isImported != null)
                            message.isImported = Boolean(object.isImported);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderItemStateChange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @static
                     * @param {theplant.ec.service.inventory.OrderItemStateChange} message OrderItemStateChange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderItemStateChange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.toQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.toQuantity = options.longs === String ? "0" : 0;
                            object.toState = options.enums === String ? "ITEM_NO_INVENTORY_STATE" : 0;
                            object.vendorState = "";
                            object.isImported = false;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.toQuantity != null && message.hasOwnProperty("toQuantity"))
                            if (typeof message.toQuantity === "number")
                                object.toQuantity = options.longs === String ? String(message.toQuantity) : message.toQuantity;
                            else
                                object.toQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.toQuantity) : options.longs === Number ? new $util.LongBits(message.toQuantity.low >>> 0, message.toQuantity.high >>> 0).toNumber() : message.toQuantity;
                        if (message.toState != null && message.hasOwnProperty("toState"))
                            object.toState = options.enums === String ? $root.theplant.ec.service.inventory.InventoryItemState[message.toState] : message.toState;
                        if (message.vendorState != null && message.hasOwnProperty("vendorState"))
                            object.vendorState = message.vendorState;
                        if (message.isImported != null && message.hasOwnProperty("isImported"))
                            object.isImported = message.isImported;
                        return object;
                    };

                    /**
                     * Converts this OrderItemStateChange to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderItemStateChange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderItemStateChange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderItemStateChange;
                })();

                inventory.OrderItemState = (function() {

                    /**
                     * Properties of an OrderItemState.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderItemState
                     * @property {string|null} [orderCode] OrderItemState orderCode
                     * @property {string|null} [articleCode] OrderItemState articleCode
                     * @property {number|Long|null} [quantity] OrderItemState quantity
                     * @property {string|null} [state] OrderItemState state
                     */

                    /**
                     * Constructs a new OrderItemState.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderItemState.
                     * @implements IOrderItemState
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderItemState=} [properties] Properties to set
                     */
                    function OrderItemState(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderItemState orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @instance
                     */
                    OrderItemState.prototype.orderCode = "";

                    /**
                     * OrderItemState articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @instance
                     */
                    OrderItemState.prototype.articleCode = "";

                    /**
                     * OrderItemState quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @instance
                     */
                    OrderItemState.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * OrderItemState state.
                     * @member {string} state
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @instance
                     */
                    OrderItemState.prototype.state = "";

                    /**
                     * Creates a new OrderItemState instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderItemState=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderItemState} OrderItemState instance
                     */
                    OrderItemState.create = function create(properties) {
                        return new OrderItemState(properties);
                    };

                    /**
                     * Encodes the specified OrderItemState message. Does not implicitly {@link theplant.ec.service.inventory.OrderItemState.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderItemState} message OrderItemState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItemState.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.quantity);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.state);
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderItemState message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderItemState.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderItemState} message OrderItemState message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderItemState.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderItemState message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderItemState} OrderItemState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItemState.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderItemState();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.quantity = reader.int64();
                                break;
                            case 4:
                                message.state = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderItemState message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderItemState} OrderItemState
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderItemState.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderItemState message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderItemState.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            if (!$util.isString(message.state))
                                return "state: string expected";
                        return null;
                    };

                    /**
                     * Creates an OrderItemState message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderItemState} OrderItemState
                     */
                    OrderItemState.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderItemState)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderItemState();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        if (object.state != null)
                            message.state = String(object.state);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderItemState message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @static
                     * @param {theplant.ec.service.inventory.OrderItemState} message OrderItemState
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderItemState.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            object.state = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = message.state;
                        return object;
                    };

                    /**
                     * Converts this OrderItemState to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderItemState
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderItemState.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OrderItemState;
                })();

                inventory.OrderCancelled = (function() {

                    /**
                     * Properties of an OrderCancelled.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderCancelled
                     * @property {string|null} [orderCode] OrderCancelled orderCode
                     * @property {Array.<theplant.ec.service.inventory.OrderCancelled.IItem>|null} [items] OrderCancelled items
                     */

                    /**
                     * Constructs a new OrderCancelled.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderCancelled.
                     * @implements IOrderCancelled
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderCancelled=} [properties] Properties to set
                     */
                    function OrderCancelled(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderCancelled orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @instance
                     */
                    OrderCancelled.prototype.orderCode = "";

                    /**
                     * OrderCancelled items.
                     * @member {Array.<theplant.ec.service.inventory.OrderCancelled.IItem>} items
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @instance
                     */
                    OrderCancelled.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new OrderCancelled instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderCancelled=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderCancelled} OrderCancelled instance
                     */
                    OrderCancelled.create = function create(properties) {
                        return new OrderCancelled(properties);
                    };

                    /**
                     * Encodes the specified OrderCancelled message. Does not implicitly {@link theplant.ec.service.inventory.OrderCancelled.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderCancelled} message OrderCancelled message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCancelled.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.inventory.OrderCancelled.Item.encode(message.items[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderCancelled message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderCancelled.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderCancelled} message OrderCancelled message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderCancelled.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderCancelled message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderCancelled} OrderCancelled
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCancelled.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderCancelled();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 5:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.inventory.OrderCancelled.Item.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderCancelled message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderCancelled} OrderCancelled
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderCancelled.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderCancelled message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderCancelled.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.OrderCancelled.Item.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderCancelled message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderCancelled} OrderCancelled
                     */
                    OrderCancelled.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderCancelled)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderCancelled();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.inventory.OrderCancelled.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.OrderCancelled.items: object expected");
                                message.items[i] = $root.theplant.ec.service.inventory.OrderCancelled.Item.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderCancelled message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @static
                     * @param {theplant.ec.service.inventory.OrderCancelled} message OrderCancelled
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderCancelled.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.inventory.OrderCancelled.Item.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this OrderCancelled to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderCancelled
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderCancelled.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    OrderCancelled.Item = (function() {

                        /**
                         * Properties of an Item.
                         * @memberof theplant.ec.service.inventory.OrderCancelled
                         * @interface IItem
                         * @property {string|null} [articleCode] Item articleCode
                         * @property {number|Long|null} [cancelledQuantity] Item cancelledQuantity
                         * @property {number|Long|null} [shippedQuantity] Item shippedQuantity
                         * @property {theplant.ec.service.inventory.InventoryItemState|null} [state] Item state
                         * @property {string|null} [id] Item id
                         */

                        /**
                         * Constructs a new Item.
                         * @memberof theplant.ec.service.inventory.OrderCancelled
                         * @classdesc Represents an Item.
                         * @implements IItem
                         * @constructor
                         * @param {theplant.ec.service.inventory.OrderCancelled.IItem=} [properties] Properties to set
                         */
                        function Item(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Item articleCode.
                         * @member {string} articleCode
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @instance
                         */
                        Item.prototype.articleCode = "";

                        /**
                         * Item cancelledQuantity.
                         * @member {number|Long} cancelledQuantity
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @instance
                         */
                        Item.prototype.cancelledQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Item shippedQuantity.
                         * @member {number|Long} shippedQuantity
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @instance
                         */
                        Item.prototype.shippedQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Item state.
                         * @member {theplant.ec.service.inventory.InventoryItemState} state
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @instance
                         */
                        Item.prototype.state = 0;

                        /**
                         * Item id.
                         * @member {string} id
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @instance
                         */
                        Item.prototype.id = "";

                        /**
                         * Creates a new Item instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderCancelled.IItem=} [properties] Properties to set
                         * @returns {theplant.ec.service.inventory.OrderCancelled.Item} Item instance
                         */
                        Item.create = function create(properties) {
                            return new Item(properties);
                        };

                        /**
                         * Encodes the specified Item message. Does not implicitly {@link theplant.ec.service.inventory.OrderCancelled.Item.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderCancelled.IItem} message Item message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Item.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                            if (message.cancelledQuantity != null && message.hasOwnProperty("cancelledQuantity"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cancelledQuantity);
                            if (message.shippedQuantity != null && message.hasOwnProperty("shippedQuantity"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.shippedQuantity);
                            if (message.state != null && message.hasOwnProperty("state"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified Item message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderCancelled.Item.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderCancelled.IItem} message Item message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Item.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Item message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.inventory.OrderCancelled.Item} Item
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Item.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderCancelled.Item();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.articleCode = reader.string();
                                    break;
                                case 2:
                                    message.cancelledQuantity = reader.int64();
                                    break;
                                case 3:
                                    message.shippedQuantity = reader.int64();
                                    break;
                                case 4:
                                    message.state = reader.int32();
                                    break;
                                case 5:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Item message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.inventory.OrderCancelled.Item} Item
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Item.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Item message.
                         * @function verify
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Item.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                                if (!$util.isString(message.articleCode))
                                    return "articleCode: string expected";
                            if (message.cancelledQuantity != null && message.hasOwnProperty("cancelledQuantity"))
                                if (!$util.isInteger(message.cancelledQuantity) && !(message.cancelledQuantity && $util.isInteger(message.cancelledQuantity.low) && $util.isInteger(message.cancelledQuantity.high)))
                                    return "cancelledQuantity: integer|Long expected";
                            if (message.shippedQuantity != null && message.hasOwnProperty("shippedQuantity"))
                                if (!$util.isInteger(message.shippedQuantity) && !(message.shippedQuantity && $util.isInteger(message.shippedQuantity.low) && $util.isInteger(message.shippedQuantity.high)))
                                    return "shippedQuantity: integer|Long expected";
                            if (message.state != null && message.hasOwnProperty("state"))
                                switch (message.state) {
                                default:
                                    return "state: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                    break;
                                }
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates an Item message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.inventory.OrderCancelled.Item} Item
                         */
                        Item.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.inventory.OrderCancelled.Item)
                                return object;
                            var message = new $root.theplant.ec.service.inventory.OrderCancelled.Item();
                            if (object.articleCode != null)
                                message.articleCode = String(object.articleCode);
                            if (object.cancelledQuantity != null)
                                if ($util.Long)
                                    (message.cancelledQuantity = $util.Long.fromValue(object.cancelledQuantity)).unsigned = false;
                                else if (typeof object.cancelledQuantity === "string")
                                    message.cancelledQuantity = parseInt(object.cancelledQuantity, 10);
                                else if (typeof object.cancelledQuantity === "number")
                                    message.cancelledQuantity = object.cancelledQuantity;
                                else if (typeof object.cancelledQuantity === "object")
                                    message.cancelledQuantity = new $util.LongBits(object.cancelledQuantity.low >>> 0, object.cancelledQuantity.high >>> 0).toNumber();
                            if (object.shippedQuantity != null)
                                if ($util.Long)
                                    (message.shippedQuantity = $util.Long.fromValue(object.shippedQuantity)).unsigned = false;
                                else if (typeof object.shippedQuantity === "string")
                                    message.shippedQuantity = parseInt(object.shippedQuantity, 10);
                                else if (typeof object.shippedQuantity === "number")
                                    message.shippedQuantity = object.shippedQuantity;
                                else if (typeof object.shippedQuantity === "object")
                                    message.shippedQuantity = new $util.LongBits(object.shippedQuantity.low >>> 0, object.shippedQuantity.high >>> 0).toNumber();
                            switch (object.state) {
                            case "ITEM_NO_INVENTORY_STATE":
                            case 0:
                                message.state = 0;
                                break;
                            case "ITEM_TEMPORARY_OUT_OF_STOCK":
                            case 1:
                                message.state = 1;
                                break;
                            case "ITEM_READY_FOR_SHIPPING":
                            case 2:
                                message.state = 2;
                                break;
                            case "ITEM_SHIPPED":
                            case 3:
                                message.state = 3;
                                break;
                            case "ITEM_CANCELLED":
                            case 4:
                                message.state = 4;
                                break;
                            case "ITEM_CUSTOMER_CANCELLED":
                            case 5:
                                message.state = 5;
                                break;
                            case "ITEM_SOLD_OUT":
                            case 6:
                                message.state = 6;
                                break;
                            case "ITEM_RECEPTION_REFUSED":
                            case 7:
                                message.state = 7;
                                break;
                            case "ITEM_RETURNED":
                            case 8:
                                message.state = 8;
                                break;
                            }
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from an Item message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderCancelled.Item} message Item
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Item.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.articleCode = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.cancelledQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.cancelledQuantity = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.shippedQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.shippedQuantity = options.longs === String ? "0" : 0;
                                object.state = options.enums === String ? "ITEM_NO_INVENTORY_STATE" : 0;
                                object.id = "";
                            }
                            if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                                object.articleCode = message.articleCode;
                            if (message.cancelledQuantity != null && message.hasOwnProperty("cancelledQuantity"))
                                if (typeof message.cancelledQuantity === "number")
                                    object.cancelledQuantity = options.longs === String ? String(message.cancelledQuantity) : message.cancelledQuantity;
                                else
                                    object.cancelledQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.cancelledQuantity) : options.longs === Number ? new $util.LongBits(message.cancelledQuantity.low >>> 0, message.cancelledQuantity.high >>> 0).toNumber() : message.cancelledQuantity;
                            if (message.shippedQuantity != null && message.hasOwnProperty("shippedQuantity"))
                                if (typeof message.shippedQuantity === "number")
                                    object.shippedQuantity = options.longs === String ? String(message.shippedQuantity) : message.shippedQuantity;
                                else
                                    object.shippedQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.shippedQuantity) : options.longs === Number ? new $util.LongBits(message.shippedQuantity.low >>> 0, message.shippedQuantity.high >>> 0).toNumber() : message.shippedQuantity;
                            if (message.state != null && message.hasOwnProperty("state"))
                                object.state = options.enums === String ? $root.theplant.ec.service.inventory.InventoryItemState[message.state] : message.state;
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this Item to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.inventory.OrderCancelled.Item
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Item.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Item;
                    })();

                    return OrderCancelled;
                })();

                inventory.OrderReturned = (function() {

                    /**
                     * Properties of an OrderReturned.
                     * @memberof theplant.ec.service.inventory
                     * @interface IOrderReturned
                     * @property {string|null} [orderCode] OrderReturned orderCode
                     * @property {Array.<theplant.ec.service.inventory.OrderReturned.IItem>|null} [items] OrderReturned items
                     */

                    /**
                     * Constructs a new OrderReturned.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an OrderReturned.
                     * @implements IOrderReturned
                     * @constructor
                     * @param {theplant.ec.service.inventory.IOrderReturned=} [properties] Properties to set
                     */
                    function OrderReturned(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OrderReturned orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @instance
                     */
                    OrderReturned.prototype.orderCode = "";

                    /**
                     * OrderReturned items.
                     * @member {Array.<theplant.ec.service.inventory.OrderReturned.IItem>} items
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @instance
                     */
                    OrderReturned.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new OrderReturned instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderReturned=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.OrderReturned} OrderReturned instance
                     */
                    OrderReturned.create = function create(properties) {
                        return new OrderReturned(properties);
                    };

                    /**
                     * Encodes the specified OrderReturned message. Does not implicitly {@link theplant.ec.service.inventory.OrderReturned.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderReturned} message OrderReturned message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderReturned.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.inventory.OrderReturned.Item.encode(message.items[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OrderReturned message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderReturned.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {theplant.ec.service.inventory.IOrderReturned} message OrderReturned message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OrderReturned.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OrderReturned message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.OrderReturned} OrderReturned
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderReturned.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderReturned();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 5:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.inventory.OrderReturned.Item.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OrderReturned message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.OrderReturned} OrderReturned
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OrderReturned.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OrderReturned message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OrderReturned.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.OrderReturned.Item.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an OrderReturned message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.OrderReturned} OrderReturned
                     */
                    OrderReturned.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.OrderReturned)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.OrderReturned();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.inventory.OrderReturned.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.OrderReturned.items: object expected");
                                message.items[i] = $root.theplant.ec.service.inventory.OrderReturned.Item.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OrderReturned message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @static
                     * @param {theplant.ec.service.inventory.OrderReturned} message OrderReturned
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OrderReturned.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults)
                            object.orderCode = "";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.inventory.OrderReturned.Item.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this OrderReturned to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.OrderReturned
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OrderReturned.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    OrderReturned.Item = (function() {

                        /**
                         * Properties of an Item.
                         * @memberof theplant.ec.service.inventory.OrderReturned
                         * @interface IItem
                         * @property {string|null} [articleCode] Item articleCode
                         * @property {number|Long|null} [returnedQuantity] Item returnedQuantity
                         * @property {number|Long|null} [shippedQuantity] Item shippedQuantity
                         * @property {theplant.ec.service.inventory.InventoryItemState|null} [state] Item state
                         * @property {string|null} [id] Item id
                         */

                        /**
                         * Constructs a new Item.
                         * @memberof theplant.ec.service.inventory.OrderReturned
                         * @classdesc Represents an Item.
                         * @implements IItem
                         * @constructor
                         * @param {theplant.ec.service.inventory.OrderReturned.IItem=} [properties] Properties to set
                         */
                        function Item(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * Item articleCode.
                         * @member {string} articleCode
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @instance
                         */
                        Item.prototype.articleCode = "";

                        /**
                         * Item returnedQuantity.
                         * @member {number|Long} returnedQuantity
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @instance
                         */
                        Item.prototype.returnedQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Item shippedQuantity.
                         * @member {number|Long} shippedQuantity
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @instance
                         */
                        Item.prototype.shippedQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Item state.
                         * @member {theplant.ec.service.inventory.InventoryItemState} state
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @instance
                         */
                        Item.prototype.state = 0;

                        /**
                         * Item id.
                         * @member {string} id
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @instance
                         */
                        Item.prototype.id = "";

                        /**
                         * Creates a new Item instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderReturned.IItem=} [properties] Properties to set
                         * @returns {theplant.ec.service.inventory.OrderReturned.Item} Item instance
                         */
                        Item.create = function create(properties) {
                            return new Item(properties);
                        };

                        /**
                         * Encodes the specified Item message. Does not implicitly {@link theplant.ec.service.inventory.OrderReturned.Item.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderReturned.IItem} message Item message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Item.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                            if (message.returnedQuantity != null && message.hasOwnProperty("returnedQuantity"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.returnedQuantity);
                            if (message.shippedQuantity != null && message.hasOwnProperty("shippedQuantity"))
                                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.shippedQuantity);
                            if (message.state != null && message.hasOwnProperty("state"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.state);
                            if (message.id != null && message.hasOwnProperty("id"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
                            return writer;
                        };

                        /**
                         * Encodes the specified Item message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.OrderReturned.Item.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderReturned.IItem} message Item message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        Item.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an Item message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.inventory.OrderReturned.Item} Item
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Item.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.OrderReturned.Item();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.articleCode = reader.string();
                                    break;
                                case 2:
                                    message.returnedQuantity = reader.int64();
                                    break;
                                case 3:
                                    message.shippedQuantity = reader.int64();
                                    break;
                                case 4:
                                    message.state = reader.int32();
                                    break;
                                case 5:
                                    message.id = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an Item message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.inventory.OrderReturned.Item} Item
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        Item.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an Item message.
                         * @function verify
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        Item.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                                if (!$util.isString(message.articleCode))
                                    return "articleCode: string expected";
                            if (message.returnedQuantity != null && message.hasOwnProperty("returnedQuantity"))
                                if (!$util.isInteger(message.returnedQuantity) && !(message.returnedQuantity && $util.isInteger(message.returnedQuantity.low) && $util.isInteger(message.returnedQuantity.high)))
                                    return "returnedQuantity: integer|Long expected";
                            if (message.shippedQuantity != null && message.hasOwnProperty("shippedQuantity"))
                                if (!$util.isInteger(message.shippedQuantity) && !(message.shippedQuantity && $util.isInteger(message.shippedQuantity.low) && $util.isInteger(message.shippedQuantity.high)))
                                    return "shippedQuantity: integer|Long expected";
                            if (message.state != null && message.hasOwnProperty("state"))
                                switch (message.state) {
                                default:
                                    return "state: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                case 7:
                                case 8:
                                    break;
                                }
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            return null;
                        };

                        /**
                         * Creates an Item message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.inventory.OrderReturned.Item} Item
                         */
                        Item.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.inventory.OrderReturned.Item)
                                return object;
                            var message = new $root.theplant.ec.service.inventory.OrderReturned.Item();
                            if (object.articleCode != null)
                                message.articleCode = String(object.articleCode);
                            if (object.returnedQuantity != null)
                                if ($util.Long)
                                    (message.returnedQuantity = $util.Long.fromValue(object.returnedQuantity)).unsigned = false;
                                else if (typeof object.returnedQuantity === "string")
                                    message.returnedQuantity = parseInt(object.returnedQuantity, 10);
                                else if (typeof object.returnedQuantity === "number")
                                    message.returnedQuantity = object.returnedQuantity;
                                else if (typeof object.returnedQuantity === "object")
                                    message.returnedQuantity = new $util.LongBits(object.returnedQuantity.low >>> 0, object.returnedQuantity.high >>> 0).toNumber();
                            if (object.shippedQuantity != null)
                                if ($util.Long)
                                    (message.shippedQuantity = $util.Long.fromValue(object.shippedQuantity)).unsigned = false;
                                else if (typeof object.shippedQuantity === "string")
                                    message.shippedQuantity = parseInt(object.shippedQuantity, 10);
                                else if (typeof object.shippedQuantity === "number")
                                    message.shippedQuantity = object.shippedQuantity;
                                else if (typeof object.shippedQuantity === "object")
                                    message.shippedQuantity = new $util.LongBits(object.shippedQuantity.low >>> 0, object.shippedQuantity.high >>> 0).toNumber();
                            switch (object.state) {
                            case "ITEM_NO_INVENTORY_STATE":
                            case 0:
                                message.state = 0;
                                break;
                            case "ITEM_TEMPORARY_OUT_OF_STOCK":
                            case 1:
                                message.state = 1;
                                break;
                            case "ITEM_READY_FOR_SHIPPING":
                            case 2:
                                message.state = 2;
                                break;
                            case "ITEM_SHIPPED":
                            case 3:
                                message.state = 3;
                                break;
                            case "ITEM_CANCELLED":
                            case 4:
                                message.state = 4;
                                break;
                            case "ITEM_CUSTOMER_CANCELLED":
                            case 5:
                                message.state = 5;
                                break;
                            case "ITEM_SOLD_OUT":
                            case 6:
                                message.state = 6;
                                break;
                            case "ITEM_RECEPTION_REFUSED":
                            case 7:
                                message.state = 7;
                                break;
                            case "ITEM_RETURNED":
                            case 8:
                                message.state = 8;
                                break;
                            }
                            if (object.id != null)
                                message.id = String(object.id);
                            return message;
                        };

                        /**
                         * Creates a plain object from an Item message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @static
                         * @param {theplant.ec.service.inventory.OrderReturned.Item} message Item
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        Item.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.articleCode = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.returnedQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.returnedQuantity = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.shippedQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.shippedQuantity = options.longs === String ? "0" : 0;
                                object.state = options.enums === String ? "ITEM_NO_INVENTORY_STATE" : 0;
                                object.id = "";
                            }
                            if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                                object.articleCode = message.articleCode;
                            if (message.returnedQuantity != null && message.hasOwnProperty("returnedQuantity"))
                                if (typeof message.returnedQuantity === "number")
                                    object.returnedQuantity = options.longs === String ? String(message.returnedQuantity) : message.returnedQuantity;
                                else
                                    object.returnedQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.returnedQuantity) : options.longs === Number ? new $util.LongBits(message.returnedQuantity.low >>> 0, message.returnedQuantity.high >>> 0).toNumber() : message.returnedQuantity;
                            if (message.shippedQuantity != null && message.hasOwnProperty("shippedQuantity"))
                                if (typeof message.shippedQuantity === "number")
                                    object.shippedQuantity = options.longs === String ? String(message.shippedQuantity) : message.shippedQuantity;
                                else
                                    object.shippedQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.shippedQuantity) : options.longs === Number ? new $util.LongBits(message.shippedQuantity.low >>> 0, message.shippedQuantity.high >>> 0).toNumber() : message.shippedQuantity;
                            if (message.state != null && message.hasOwnProperty("state"))
                                object.state = options.enums === String ? $root.theplant.ec.service.inventory.InventoryItemState[message.state] : message.state;
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            return object;
                        };

                        /**
                         * Converts this Item to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.inventory.OrderReturned.Item
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        Item.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return Item;
                    })();

                    return OrderReturned;
                })();

                inventory.Article = (function() {

                    /**
                     * Properties of an Article.
                     * @memberof theplant.ec.service.inventory
                     * @interface IArticle
                     * @property {string|null} [articleCode] Article articleCode
                     * @property {string|null} [productCode] Article productCode
                     * @property {string|null} [colorCode] Article colorCode
                     * @property {string|null} [colorName] Article colorName
                     * @property {string|null} [sizeCode] Article sizeCode
                     * @property {string|null} [productReceiptName] Article productReceiptName
                     * @property {number|Long|null} [price] Article price
                     */

                    /**
                     * Constructs a new Article.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an Article.
                     * @implements IArticle
                     * @constructor
                     * @param {theplant.ec.service.inventory.IArticle=} [properties] Properties to set
                     */
                    function Article(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Article articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.articleCode = "";

                    /**
                     * Article productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.productCode = "";

                    /**
                     * Article colorCode.
                     * @member {string} colorCode
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.colorCode = "";

                    /**
                     * Article colorName.
                     * @member {string} colorName
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.colorName = "";

                    /**
                     * Article sizeCode.
                     * @member {string} sizeCode
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.sizeCode = "";

                    /**
                     * Article productReceiptName.
                     * @member {string} productReceiptName
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.productReceiptName = "";

                    /**
                     * Article price.
                     * @member {number|Long} price
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     */
                    Article.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new Article instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {theplant.ec.service.inventory.IArticle=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.Article} Article instance
                     */
                    Article.create = function create(properties) {
                        return new Article(properties);
                    };

                    /**
                     * Encodes the specified Article message. Does not implicitly {@link theplant.ec.service.inventory.Article.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {theplant.ec.service.inventory.IArticle} message Article message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Article.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.productCode);
                        if (message.colorCode != null && message.hasOwnProperty("colorCode"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.colorCode);
                        if (message.colorName != null && message.hasOwnProperty("colorName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.colorName);
                        if (message.sizeCode != null && message.hasOwnProperty("sizeCode"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.sizeCode);
                        if (message.productReceiptName != null && message.hasOwnProperty("productReceiptName"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.productReceiptName);
                        if (message.price != null && message.hasOwnProperty("price"))
                            writer.uint32(/* id 8, wireType 0 =*/64).uint64(message.price);
                        return writer;
                    };

                    /**
                     * Encodes the specified Article message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.Article.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {theplant.ec.service.inventory.IArticle} message Article message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Article.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Article message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.Article} Article
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Article.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.Article();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.productCode = reader.string();
                                break;
                            case 4:
                                message.colorCode = reader.string();
                                break;
                            case 5:
                                message.colorName = reader.string();
                                break;
                            case 6:
                                message.sizeCode = reader.string();
                                break;
                            case 7:
                                message.productReceiptName = reader.string();
                                break;
                            case 8:
                                message.price = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Article message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.Article} Article
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Article.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Article message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Article.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.colorCode != null && message.hasOwnProperty("colorCode"))
                            if (!$util.isString(message.colorCode))
                                return "colorCode: string expected";
                        if (message.colorName != null && message.hasOwnProperty("colorName"))
                            if (!$util.isString(message.colorName))
                                return "colorName: string expected";
                        if (message.sizeCode != null && message.hasOwnProperty("sizeCode"))
                            if (!$util.isString(message.sizeCode))
                                return "sizeCode: string expected";
                        if (message.productReceiptName != null && message.hasOwnProperty("productReceiptName"))
                            if (!$util.isString(message.productReceiptName))
                                return "productReceiptName: string expected";
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                return "price: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an Article message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.Article} Article
                     */
                    Article.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.Article)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.Article();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.colorCode != null)
                            message.colorCode = String(object.colorCode);
                        if (object.colorName != null)
                            message.colorName = String(object.colorName);
                        if (object.sizeCode != null)
                            message.sizeCode = String(object.sizeCode);
                        if (object.productReceiptName != null)
                            message.productReceiptName = String(object.productReceiptName);
                        if (object.price != null)
                            if ($util.Long)
                                (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                            else if (typeof object.price === "string")
                                message.price = parseInt(object.price, 10);
                            else if (typeof object.price === "number")
                                message.price = object.price;
                            else if (typeof object.price === "object")
                                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from an Article message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.Article
                     * @static
                     * @param {theplant.ec.service.inventory.Article} message Article
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Article.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            object.productCode = "";
                            object.colorCode = "";
                            object.colorName = "";
                            object.sizeCode = "";
                            object.productReceiptName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.price = options.longs === String ? "0" : 0;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.colorCode != null && message.hasOwnProperty("colorCode"))
                            object.colorCode = message.colorCode;
                        if (message.colorName != null && message.hasOwnProperty("colorName"))
                            object.colorName = message.colorName;
                        if (message.sizeCode != null && message.hasOwnProperty("sizeCode"))
                            object.sizeCode = message.sizeCode;
                        if (message.productReceiptName != null && message.hasOwnProperty("productReceiptName"))
                            object.productReceiptName = message.productReceiptName;
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (typeof message.price === "number")
                                object.price = options.longs === String ? String(message.price) : message.price;
                            else
                                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                        return object;
                    };

                    /**
                     * Converts this Article to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.Article
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Article.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Article;
                })();

                inventory.StockId = (function() {

                    /**
                     * Properties of a StockId.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStockId
                     * @property {string|null} [articleCode] StockId articleCode
                     * @property {string|null} [supplierId] StockId supplierId
                     */

                    /**
                     * Constructs a new StockId.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StockId.
                     * @implements IStockId
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStockId=} [properties] Properties to set
                     */
                    function StockId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StockId articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.StockId
                     * @instance
                     */
                    StockId.prototype.articleCode = "";

                    /**
                     * StockId supplierId.
                     * @member {string} supplierId
                     * @memberof theplant.ec.service.inventory.StockId
                     * @instance
                     */
                    StockId.prototype.supplierId = "";

                    /**
                     * Creates a new StockId instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {theplant.ec.service.inventory.IStockId=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StockId} StockId instance
                     */
                    StockId.create = function create(properties) {
                        return new StockId(properties);
                    };

                    /**
                     * Encodes the specified StockId message. Does not implicitly {@link theplant.ec.service.inventory.StockId.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {theplant.ec.service.inventory.IStockId} message StockId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.supplierId != null && message.hasOwnProperty("supplierId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.supplierId);
                        return writer;
                    };

                    /**
                     * Encodes the specified StockId message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StockId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {theplant.ec.service.inventory.IStockId} message StockId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StockId message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StockId} StockId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StockId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.supplierId = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StockId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StockId} StockId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StockId message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StockId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.supplierId != null && message.hasOwnProperty("supplierId"))
                            if (!$util.isString(message.supplierId))
                                return "supplierId: string expected";
                        return null;
                    };

                    /**
                     * Creates a StockId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StockId} StockId
                     */
                    StockId.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StockId)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StockId();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.supplierId != null)
                            message.supplierId = String(object.supplierId);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StockId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StockId
                     * @static
                     * @param {theplant.ec.service.inventory.StockId} message StockId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StockId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.articleCode = "";
                            object.supplierId = "";
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.supplierId != null && message.hasOwnProperty("supplierId"))
                            object.supplierId = message.supplierId;
                        return object;
                    };

                    /**
                     * Converts this StockId to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StockId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StockId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StockId;
                })();

                inventory.ResetStocks = (function() {

                    /**
                     * Properties of a ResetStocks.
                     * @memberof theplant.ec.service.inventory
                     * @interface IResetStocks
                     * @property {Array.<theplant.ec.service.inventory.IResetStock>|null} [stocks] ResetStocks stocks
                     */

                    /**
                     * Constructs a new ResetStocks.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a ResetStocks.
                     * @implements IResetStocks
                     * @constructor
                     * @param {theplant.ec.service.inventory.IResetStocks=} [properties] Properties to set
                     */
                    function ResetStocks(properties) {
                        this.stocks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ResetStocks stocks.
                     * @member {Array.<theplant.ec.service.inventory.IResetStock>} stocks
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @instance
                     */
                    ResetStocks.prototype.stocks = $util.emptyArray;

                    /**
                     * Creates a new ResetStocks instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {theplant.ec.service.inventory.IResetStocks=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ResetStocks} ResetStocks instance
                     */
                    ResetStocks.create = function create(properties) {
                        return new ResetStocks(properties);
                    };

                    /**
                     * Encodes the specified ResetStocks message. Does not implicitly {@link theplant.ec.service.inventory.ResetStocks.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {theplant.ec.service.inventory.IResetStocks} message ResetStocks message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResetStocks.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stocks != null && message.stocks.length)
                            for (var i = 0; i < message.stocks.length; ++i)
                                $root.theplant.ec.service.inventory.ResetStock.encode(message.stocks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ResetStocks message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ResetStocks.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {theplant.ec.service.inventory.IResetStocks} message ResetStocks message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResetStocks.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ResetStocks message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ResetStocks} ResetStocks
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResetStocks.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ResetStocks();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stocks && message.stocks.length))
                                    message.stocks = [];
                                message.stocks.push($root.theplant.ec.service.inventory.ResetStock.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ResetStocks message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ResetStocks} ResetStocks
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResetStocks.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ResetStocks message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ResetStocks.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stocks != null && message.hasOwnProperty("stocks")) {
                            if (!Array.isArray(message.stocks))
                                return "stocks: array expected";
                            for (var i = 0; i < message.stocks.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.ResetStock.verify(message.stocks[i]);
                                if (error)
                                    return "stocks." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a ResetStocks message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ResetStocks} ResetStocks
                     */
                    ResetStocks.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ResetStocks)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ResetStocks();
                        if (object.stocks) {
                            if (!Array.isArray(object.stocks))
                                throw TypeError(".theplant.ec.service.inventory.ResetStocks.stocks: array expected");
                            message.stocks = [];
                            for (var i = 0; i < object.stocks.length; ++i) {
                                if (typeof object.stocks[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.ResetStocks.stocks: object expected");
                                message.stocks[i] = $root.theplant.ec.service.inventory.ResetStock.fromObject(object.stocks[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ResetStocks message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @static
                     * @param {theplant.ec.service.inventory.ResetStocks} message ResetStocks
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ResetStocks.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stocks = [];
                        if (message.stocks && message.stocks.length) {
                            object.stocks = [];
                            for (var j = 0; j < message.stocks.length; ++j)
                                object.stocks[j] = $root.theplant.ec.service.inventory.ResetStock.toObject(message.stocks[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this ResetStocks to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ResetStocks
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ResetStocks.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ResetStocks;
                })();

                inventory.ResetStock = (function() {

                    /**
                     * Properties of a ResetStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IResetStock
                     * @property {theplant.ec.service.inventory.IStockId|null} [stockId] ResetStock stockId
                     * @property {number|Long|null} [quantity] ResetStock quantity
                     * @property {string|null} [source] ResetStock source
                     */

                    /**
                     * Constructs a new ResetStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a ResetStock.
                     * @implements IResetStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IResetStock=} [properties] Properties to set
                     */
                    function ResetStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ResetStock stockId.
                     * @member {theplant.ec.service.inventory.IStockId|null|undefined} stockId
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @instance
                     */
                    ResetStock.prototype.stockId = null;

                    /**
                     * ResetStock quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @instance
                     */
                    ResetStock.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ResetStock source.
                     * @member {string} source
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @instance
                     */
                    ResetStock.prototype.source = "";

                    /**
                     * Creates a new ResetStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {theplant.ec.service.inventory.IResetStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ResetStock} ResetStock instance
                     */
                    ResetStock.create = function create(properties) {
                        return new ResetStock(properties);
                    };

                    /**
                     * Encodes the specified ResetStock message. Does not implicitly {@link theplant.ec.service.inventory.ResetStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {theplant.ec.service.inventory.IResetStock} message ResetStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResetStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            $root.theplant.ec.service.inventory.StockId.encode(message.stockId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.quantity);
                        if (message.source != null && message.hasOwnProperty("source"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.source);
                        return writer;
                    };

                    /**
                     * Encodes the specified ResetStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ResetStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {theplant.ec.service.inventory.IResetStock} message ResetStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ResetStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ResetStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ResetStock} ResetStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResetStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ResetStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stockId = $root.theplant.ec.service.inventory.StockId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.quantity = reader.int64();
                                break;
                            case 4:
                                message.source = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ResetStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ResetStock} ResetStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ResetStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ResetStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ResetStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stockId != null && message.hasOwnProperty("stockId")) {
                            var error = $root.theplant.ec.service.inventory.StockId.verify(message.stockId);
                            if (error)
                                return "stockId." + error;
                        }
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.source != null && message.hasOwnProperty("source"))
                            if (!$util.isString(message.source))
                                return "source: string expected";
                        return null;
                    };

                    /**
                     * Creates a ResetStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ResetStock} ResetStock
                     */
                    ResetStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ResetStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ResetStock();
                        if (object.stockId != null) {
                            if (typeof object.stockId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.ResetStock.stockId: object expected");
                            message.stockId = $root.theplant.ec.service.inventory.StockId.fromObject(object.stockId);
                        }
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        if (object.source != null)
                            message.source = String(object.source);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ResetStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @static
                     * @param {theplant.ec.service.inventory.ResetStock} message ResetStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ResetStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.stockId = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            object.source = "";
                        }
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            object.stockId = $root.theplant.ec.service.inventory.StockId.toObject(message.stockId, options);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = message.source;
                        return object;
                    };

                    /**
                     * Converts this ResetStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ResetStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ResetStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ResetStock;
                })();

                inventory.StockOrderId = (function() {

                    /**
                     * Properties of a StockOrderId.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStockOrderId
                     * @property {string|null} [code] StockOrderId code
                     * @property {string|null} [source] StockOrderId source
                     */

                    /**
                     * Constructs a new StockOrderId.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StockOrderId.
                     * @implements IStockOrderId
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStockOrderId=} [properties] Properties to set
                     */
                    function StockOrderId(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StockOrderId code.
                     * @member {string} code
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @instance
                     */
                    StockOrderId.prototype.code = "";

                    /**
                     * StockOrderId source.
                     * @member {string} source
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @instance
                     */
                    StockOrderId.prototype.source = "";

                    /**
                     * Creates a new StockOrderId instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {theplant.ec.service.inventory.IStockOrderId=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StockOrderId} StockOrderId instance
                     */
                    StockOrderId.create = function create(properties) {
                        return new StockOrderId(properties);
                    };

                    /**
                     * Encodes the specified StockOrderId message. Does not implicitly {@link theplant.ec.service.inventory.StockOrderId.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {theplant.ec.service.inventory.IStockOrderId} message StockOrderId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockOrderId.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                        if (message.source != null && message.hasOwnProperty("source"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.source);
                        return writer;
                    };

                    /**
                     * Encodes the specified StockOrderId message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StockOrderId.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {theplant.ec.service.inventory.IStockOrderId} message StockOrderId message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockOrderId.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StockOrderId message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StockOrderId} StockOrderId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockOrderId.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StockOrderId();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.string();
                                break;
                            case 2:
                                message.source = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StockOrderId message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StockOrderId} StockOrderId
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockOrderId.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StockOrderId message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StockOrderId.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.source != null && message.hasOwnProperty("source"))
                            if (!$util.isString(message.source))
                                return "source: string expected";
                        return null;
                    };

                    /**
                     * Creates a StockOrderId message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StockOrderId} StockOrderId
                     */
                    StockOrderId.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StockOrderId)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StockOrderId();
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.source != null)
                            message.source = String(object.source);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StockOrderId message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @static
                     * @param {theplant.ec.service.inventory.StockOrderId} message StockOrderId
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StockOrderId.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.code = "";
                            object.source = "";
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = message.source;
                        return object;
                    };

                    /**
                     * Converts this StockOrderId to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StockOrderId
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StockOrderId.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StockOrderId;
                })();

                inventory.SellStockOrder = (function() {

                    /**
                     * Properties of a SellStockOrder.
                     * @memberof theplant.ec.service.inventory
                     * @interface ISellStockOrder
                     * @property {theplant.ec.service.inventory.IStockOrderId|null} [orderId] SellStockOrder orderId
                     * @property {Array.<theplant.ec.service.inventory.ISellStock>|null} [stocks] SellStockOrder stocks
                     */

                    /**
                     * Constructs a new SellStockOrder.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a SellStockOrder.
                     * @implements ISellStockOrder
                     * @constructor
                     * @param {theplant.ec.service.inventory.ISellStockOrder=} [properties] Properties to set
                     */
                    function SellStockOrder(properties) {
                        this.stocks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SellStockOrder orderId.
                     * @member {theplant.ec.service.inventory.IStockOrderId|null|undefined} orderId
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @instance
                     */
                    SellStockOrder.prototype.orderId = null;

                    /**
                     * SellStockOrder stocks.
                     * @member {Array.<theplant.ec.service.inventory.ISellStock>} stocks
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @instance
                     */
                    SellStockOrder.prototype.stocks = $util.emptyArray;

                    /**
                     * Creates a new SellStockOrder instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.ISellStockOrder=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.SellStockOrder} SellStockOrder instance
                     */
                    SellStockOrder.create = function create(properties) {
                        return new SellStockOrder(properties);
                    };

                    /**
                     * Encodes the specified SellStockOrder message. Does not implicitly {@link theplant.ec.service.inventory.SellStockOrder.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.ISellStockOrder} message SellStockOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SellStockOrder.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            $root.theplant.ec.service.inventory.StockOrderId.encode(message.orderId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.stocks != null && message.stocks.length)
                            for (var i = 0; i < message.stocks.length; ++i)
                                $root.theplant.ec.service.inventory.SellStock.encode(message.stocks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SellStockOrder message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.SellStockOrder.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.ISellStockOrder} message SellStockOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SellStockOrder.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SellStockOrder message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.SellStockOrder} SellStockOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SellStockOrder.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.SellStockOrder();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderId = $root.theplant.ec.service.inventory.StockOrderId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.stocks && message.stocks.length))
                                    message.stocks = [];
                                message.stocks.push($root.theplant.ec.service.inventory.SellStock.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SellStockOrder message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.SellStockOrder} SellStockOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SellStockOrder.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SellStockOrder message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SellStockOrder.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderId != null && message.hasOwnProperty("orderId")) {
                            var error = $root.theplant.ec.service.inventory.StockOrderId.verify(message.orderId);
                            if (error)
                                return "orderId." + error;
                        }
                        if (message.stocks != null && message.hasOwnProperty("stocks")) {
                            if (!Array.isArray(message.stocks))
                                return "stocks: array expected";
                            for (var i = 0; i < message.stocks.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.SellStock.verify(message.stocks[i]);
                                if (error)
                                    return "stocks." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SellStockOrder message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.SellStockOrder} SellStockOrder
                     */
                    SellStockOrder.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.SellStockOrder)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.SellStockOrder();
                        if (object.orderId != null) {
                            if (typeof object.orderId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SellStockOrder.orderId: object expected");
                            message.orderId = $root.theplant.ec.service.inventory.StockOrderId.fromObject(object.orderId);
                        }
                        if (object.stocks) {
                            if (!Array.isArray(object.stocks))
                                throw TypeError(".theplant.ec.service.inventory.SellStockOrder.stocks: array expected");
                            message.stocks = [];
                            for (var i = 0; i < object.stocks.length; ++i) {
                                if (typeof object.stocks[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.SellStockOrder.stocks: object expected");
                                message.stocks[i] = $root.theplant.ec.service.inventory.SellStock.fromObject(object.stocks[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SellStockOrder message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.SellStockOrder} message SellStockOrder
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SellStockOrder.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stocks = [];
                        if (options.defaults)
                            object.orderId = null;
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            object.orderId = $root.theplant.ec.service.inventory.StockOrderId.toObject(message.orderId, options);
                        if (message.stocks && message.stocks.length) {
                            object.stocks = [];
                            for (var j = 0; j < message.stocks.length; ++j)
                                object.stocks[j] = $root.theplant.ec.service.inventory.SellStock.toObject(message.stocks[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SellStockOrder to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.SellStockOrder
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SellStockOrder.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SellStockOrder;
                })();

                inventory.SellStock = (function() {

                    /**
                     * Properties of a SellStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface ISellStock
                     * @property {theplant.ec.service.inventory.IStockId|null} [stockId] SellStock stockId
                     * @property {number|Long|null} [quantity] SellStock quantity
                     */

                    /**
                     * Constructs a new SellStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a SellStock.
                     * @implements ISellStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.ISellStock=} [properties] Properties to set
                     */
                    function SellStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SellStock stockId.
                     * @member {theplant.ec.service.inventory.IStockId|null|undefined} stockId
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @instance
                     */
                    SellStock.prototype.stockId = null;

                    /**
                     * SellStock quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @instance
                     */
                    SellStock.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new SellStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISellStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.SellStock} SellStock instance
                     */
                    SellStock.create = function create(properties) {
                        return new SellStock(properties);
                    };

                    /**
                     * Encodes the specified SellStock message. Does not implicitly {@link theplant.ec.service.inventory.SellStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISellStock} message SellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SellStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            $root.theplant.ec.service.inventory.StockId.encode(message.stockId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.quantity);
                        return writer;
                    };

                    /**
                     * Encodes the specified SellStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.SellStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISellStock} message SellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SellStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SellStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.SellStock} SellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SellStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.SellStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stockId = $root.theplant.ec.service.inventory.StockId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.quantity = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SellStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.SellStock} SellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SellStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SellStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SellStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stockId != null && message.hasOwnProperty("stockId")) {
                            var error = $root.theplant.ec.service.inventory.StockId.verify(message.stockId);
                            if (error)
                                return "stockId." + error;
                        }
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a SellStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.SellStock} SellStock
                     */
                    SellStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.SellStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.SellStock();
                        if (object.stockId != null) {
                            if (typeof object.stockId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SellStock.stockId: object expected");
                            message.stockId = $root.theplant.ec.service.inventory.StockId.fromObject(object.stockId);
                        }
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a SellStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @static
                     * @param {theplant.ec.service.inventory.SellStock} message SellStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SellStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.stockId = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                        }
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            object.stockId = $root.theplant.ec.service.inventory.StockId.toObject(message.stockId, options);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        return object;
                    };

                    /**
                     * Converts this SellStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.SellStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SellStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SellStock;
                })();

                inventory.SoldStockOrder = (function() {

                    /**
                     * Properties of a SoldStockOrder.
                     * @memberof theplant.ec.service.inventory
                     * @interface ISoldStockOrder
                     * @property {theplant.ec.service.inventory.IStockOrderId|null} [orderId] SoldStockOrder orderId
                     * @property {Array.<theplant.ec.service.inventory.ISoldStock>|null} [stocks] SoldStockOrder stocks
                     * @property {google.protobuf.ITimestamp|null} [soldAt] SoldStockOrder soldAt
                     * @property {google.protobuf.ITimestamp|null} [committedAt] SoldStockOrder committedAt
                     */

                    /**
                     * Constructs a new SoldStockOrder.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a SoldStockOrder.
                     * @implements ISoldStockOrder
                     * @constructor
                     * @param {theplant.ec.service.inventory.ISoldStockOrder=} [properties] Properties to set
                     */
                    function SoldStockOrder(properties) {
                        this.stocks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SoldStockOrder orderId.
                     * @member {theplant.ec.service.inventory.IStockOrderId|null|undefined} orderId
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @instance
                     */
                    SoldStockOrder.prototype.orderId = null;

                    /**
                     * SoldStockOrder stocks.
                     * @member {Array.<theplant.ec.service.inventory.ISoldStock>} stocks
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @instance
                     */
                    SoldStockOrder.prototype.stocks = $util.emptyArray;

                    /**
                     * SoldStockOrder soldAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} soldAt
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @instance
                     */
                    SoldStockOrder.prototype.soldAt = null;

                    /**
                     * SoldStockOrder committedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} committedAt
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @instance
                     */
                    SoldStockOrder.prototype.committedAt = null;

                    /**
                     * Creates a new SoldStockOrder instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.ISoldStockOrder=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.SoldStockOrder} SoldStockOrder instance
                     */
                    SoldStockOrder.create = function create(properties) {
                        return new SoldStockOrder(properties);
                    };

                    /**
                     * Encodes the specified SoldStockOrder message. Does not implicitly {@link theplant.ec.service.inventory.SoldStockOrder.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.ISoldStockOrder} message SoldStockOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SoldStockOrder.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            $root.theplant.ec.service.inventory.StockOrderId.encode(message.orderId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.stocks != null && message.stocks.length)
                            for (var i = 0; i < message.stocks.length; ++i)
                                $root.theplant.ec.service.inventory.SoldStock.encode(message.stocks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.soldAt != null && message.hasOwnProperty("soldAt"))
                            $root.google.protobuf.Timestamp.encode(message.soldAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.committedAt != null && message.hasOwnProperty("committedAt"))
                            $root.google.protobuf.Timestamp.encode(message.committedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SoldStockOrder message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.SoldStockOrder.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.ISoldStockOrder} message SoldStockOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SoldStockOrder.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SoldStockOrder message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.SoldStockOrder} SoldStockOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SoldStockOrder.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.SoldStockOrder();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderId = $root.theplant.ec.service.inventory.StockOrderId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.stocks && message.stocks.length))
                                    message.stocks = [];
                                message.stocks.push($root.theplant.ec.service.inventory.SoldStock.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.soldAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.committedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SoldStockOrder message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.SoldStockOrder} SoldStockOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SoldStockOrder.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SoldStockOrder message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SoldStockOrder.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderId != null && message.hasOwnProperty("orderId")) {
                            var error = $root.theplant.ec.service.inventory.StockOrderId.verify(message.orderId);
                            if (error)
                                return "orderId." + error;
                        }
                        if (message.stocks != null && message.hasOwnProperty("stocks")) {
                            if (!Array.isArray(message.stocks))
                                return "stocks: array expected";
                            for (var i = 0; i < message.stocks.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.SoldStock.verify(message.stocks[i]);
                                if (error)
                                    return "stocks." + error;
                            }
                        }
                        if (message.soldAt != null && message.hasOwnProperty("soldAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.soldAt);
                            if (error)
                                return "soldAt." + error;
                        }
                        if (message.committedAt != null && message.hasOwnProperty("committedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.committedAt);
                            if (error)
                                return "committedAt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SoldStockOrder message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.SoldStockOrder} SoldStockOrder
                     */
                    SoldStockOrder.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.SoldStockOrder)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.SoldStockOrder();
                        if (object.orderId != null) {
                            if (typeof object.orderId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStockOrder.orderId: object expected");
                            message.orderId = $root.theplant.ec.service.inventory.StockOrderId.fromObject(object.orderId);
                        }
                        if (object.stocks) {
                            if (!Array.isArray(object.stocks))
                                throw TypeError(".theplant.ec.service.inventory.SoldStockOrder.stocks: array expected");
                            message.stocks = [];
                            for (var i = 0; i < object.stocks.length; ++i) {
                                if (typeof object.stocks[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.SoldStockOrder.stocks: object expected");
                                message.stocks[i] = $root.theplant.ec.service.inventory.SoldStock.fromObject(object.stocks[i]);
                            }
                        }
                        if (object.soldAt != null) {
                            if (typeof object.soldAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStockOrder.soldAt: object expected");
                            message.soldAt = $root.google.protobuf.Timestamp.fromObject(object.soldAt);
                        }
                        if (object.committedAt != null) {
                            if (typeof object.committedAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStockOrder.committedAt: object expected");
                            message.committedAt = $root.google.protobuf.Timestamp.fromObject(object.committedAt);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SoldStockOrder message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.SoldStockOrder} message SoldStockOrder
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SoldStockOrder.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stocks = [];
                        if (options.defaults) {
                            object.orderId = null;
                            object.soldAt = null;
                            object.committedAt = null;
                        }
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            object.orderId = $root.theplant.ec.service.inventory.StockOrderId.toObject(message.orderId, options);
                        if (message.stocks && message.stocks.length) {
                            object.stocks = [];
                            for (var j = 0; j < message.stocks.length; ++j)
                                object.stocks[j] = $root.theplant.ec.service.inventory.SoldStock.toObject(message.stocks[j], options);
                        }
                        if (message.soldAt != null && message.hasOwnProperty("soldAt"))
                            object.soldAt = $root.google.protobuf.Timestamp.toObject(message.soldAt, options);
                        if (message.committedAt != null && message.hasOwnProperty("committedAt"))
                            object.committedAt = $root.google.protobuf.Timestamp.toObject(message.committedAt, options);
                        return object;
                    };

                    /**
                     * Converts this SoldStockOrder to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.SoldStockOrder
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SoldStockOrder.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SoldStockOrder;
                })();

                inventory.SoldStock = (function() {

                    /**
                     * Properties of a SoldStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface ISoldStock
                     * @property {theplant.ec.service.inventory.ISellStock|null} [sell] SoldStock sell
                     * @property {google.protobuf.ITimestamp|null} [soldAt] SoldStock soldAt
                     * @property {google.protobuf.ITimestamp|null} [committedAt] SoldStock committedAt
                     * @property {google.protobuf.ITimestamp|null} [setAt] SoldStock setAt
                     * @property {number|Long|null} [plusQuantity] SoldStock plusQuantity
                     * @property {google.protobuf.ITimestamp|null} [plusAt] SoldStock plusAt
                     * @property {google.protobuf.ITimestamp|null} [moveAt] SoldStock moveAt
                     * @property {string|null} [targetSupplierId] SoldStock targetSupplierId
                     * @property {number|Long|null} [lastQuantity] SoldStock lastQuantity
                     * @property {google.protobuf.ITimestamp|null} [lastSetAt] SoldStock lastSetAt
                     * @property {number|Long|null} [lastPlusQuantity] SoldStock lastPlusQuantity
                     * @property {google.protobuf.ITimestamp|null} [lastPlusAt] SoldStock lastPlusAt
                     */

                    /**
                     * Constructs a new SoldStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a SoldStock.
                     * @implements ISoldStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.ISoldStock=} [properties] Properties to set
                     */
                    function SoldStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SoldStock sell.
                     * @member {theplant.ec.service.inventory.ISellStock|null|undefined} sell
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.sell = null;

                    /**
                     * SoldStock soldAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} soldAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.soldAt = null;

                    /**
                     * SoldStock committedAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} committedAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.committedAt = null;

                    /**
                     * SoldStock setAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} setAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.setAt = null;

                    /**
                     * SoldStock plusQuantity.
                     * @member {number|Long} plusQuantity
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.plusQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SoldStock plusAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} plusAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.plusAt = null;

                    /**
                     * SoldStock moveAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} moveAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.moveAt = null;

                    /**
                     * SoldStock targetSupplierId.
                     * @member {string} targetSupplierId
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.targetSupplierId = "";

                    /**
                     * SoldStock lastQuantity.
                     * @member {number|Long} lastQuantity
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.lastQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SoldStock lastSetAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastSetAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.lastSetAt = null;

                    /**
                     * SoldStock lastPlusQuantity.
                     * @member {number|Long} lastPlusQuantity
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.lastPlusQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SoldStock lastPlusAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastPlusAt
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     */
                    SoldStock.prototype.lastPlusAt = null;

                    /**
                     * Creates a new SoldStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISoldStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.SoldStock} SoldStock instance
                     */
                    SoldStock.create = function create(properties) {
                        return new SoldStock(properties);
                    };

                    /**
                     * Encodes the specified SoldStock message. Does not implicitly {@link theplant.ec.service.inventory.SoldStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISoldStock} message SoldStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SoldStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.sell != null && message.hasOwnProperty("sell"))
                            $root.theplant.ec.service.inventory.SellStock.encode(message.sell, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.soldAt != null && message.hasOwnProperty("soldAt"))
                            $root.google.protobuf.Timestamp.encode(message.soldAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.committedAt != null && message.hasOwnProperty("committedAt"))
                            $root.google.protobuf.Timestamp.encode(message.committedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.setAt != null && message.hasOwnProperty("setAt"))
                            $root.google.protobuf.Timestamp.encode(message.setAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.plusQuantity != null && message.hasOwnProperty("plusQuantity"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.plusQuantity);
                        if (message.plusAt != null && message.hasOwnProperty("plusAt"))
                            $root.google.protobuf.Timestamp.encode(message.plusAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.moveAt != null && message.hasOwnProperty("moveAt"))
                            $root.google.protobuf.Timestamp.encode(message.moveAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.targetSupplierId != null && message.hasOwnProperty("targetSupplierId"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.targetSupplierId);
                        if (message.lastQuantity != null && message.hasOwnProperty("lastQuantity"))
                            writer.uint32(/* id 9, wireType 0 =*/72).int64(message.lastQuantity);
                        if (message.lastSetAt != null && message.hasOwnProperty("lastSetAt"))
                            $root.google.protobuf.Timestamp.encode(message.lastSetAt, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.lastPlusQuantity != null && message.hasOwnProperty("lastPlusQuantity"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.lastPlusQuantity);
                        if (message.lastPlusAt != null && message.hasOwnProperty("lastPlusAt"))
                            $root.google.protobuf.Timestamp.encode(message.lastPlusAt, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SoldStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.SoldStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISoldStock} message SoldStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SoldStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SoldStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.SoldStock} SoldStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SoldStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.SoldStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.sell = $root.theplant.ec.service.inventory.SellStock.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.soldAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.committedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.setAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.plusQuantity = reader.int64();
                                break;
                            case 6:
                                message.plusAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.moveAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 8:
                                message.targetSupplierId = reader.string();
                                break;
                            case 9:
                                message.lastQuantity = reader.int64();
                                break;
                            case 10:
                                message.lastSetAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.lastPlusQuantity = reader.int64();
                                break;
                            case 12:
                                message.lastPlusAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SoldStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.SoldStock} SoldStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SoldStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SoldStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SoldStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.sell != null && message.hasOwnProperty("sell")) {
                            var error = $root.theplant.ec.service.inventory.SellStock.verify(message.sell);
                            if (error)
                                return "sell." + error;
                        }
                        if (message.soldAt != null && message.hasOwnProperty("soldAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.soldAt);
                            if (error)
                                return "soldAt." + error;
                        }
                        if (message.committedAt != null && message.hasOwnProperty("committedAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.committedAt);
                            if (error)
                                return "committedAt." + error;
                        }
                        if (message.setAt != null && message.hasOwnProperty("setAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.setAt);
                            if (error)
                                return "setAt." + error;
                        }
                        if (message.plusQuantity != null && message.hasOwnProperty("plusQuantity"))
                            if (!$util.isInteger(message.plusQuantity) && !(message.plusQuantity && $util.isInteger(message.plusQuantity.low) && $util.isInteger(message.plusQuantity.high)))
                                return "plusQuantity: integer|Long expected";
                        if (message.plusAt != null && message.hasOwnProperty("plusAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.plusAt);
                            if (error)
                                return "plusAt." + error;
                        }
                        if (message.moveAt != null && message.hasOwnProperty("moveAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.moveAt);
                            if (error)
                                return "moveAt." + error;
                        }
                        if (message.targetSupplierId != null && message.hasOwnProperty("targetSupplierId"))
                            if (!$util.isString(message.targetSupplierId))
                                return "targetSupplierId: string expected";
                        if (message.lastQuantity != null && message.hasOwnProperty("lastQuantity"))
                            if (!$util.isInteger(message.lastQuantity) && !(message.lastQuantity && $util.isInteger(message.lastQuantity.low) && $util.isInteger(message.lastQuantity.high)))
                                return "lastQuantity: integer|Long expected";
                        if (message.lastSetAt != null && message.hasOwnProperty("lastSetAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastSetAt);
                            if (error)
                                return "lastSetAt." + error;
                        }
                        if (message.lastPlusQuantity != null && message.hasOwnProperty("lastPlusQuantity"))
                            if (!$util.isInteger(message.lastPlusQuantity) && !(message.lastPlusQuantity && $util.isInteger(message.lastPlusQuantity.low) && $util.isInteger(message.lastPlusQuantity.high)))
                                return "lastPlusQuantity: integer|Long expected";
                        if (message.lastPlusAt != null && message.hasOwnProperty("lastPlusAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastPlusAt);
                            if (error)
                                return "lastPlusAt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SoldStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.SoldStock} SoldStock
                     */
                    SoldStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.SoldStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.SoldStock();
                        if (object.sell != null) {
                            if (typeof object.sell !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.sell: object expected");
                            message.sell = $root.theplant.ec.service.inventory.SellStock.fromObject(object.sell);
                        }
                        if (object.soldAt != null) {
                            if (typeof object.soldAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.soldAt: object expected");
                            message.soldAt = $root.google.protobuf.Timestamp.fromObject(object.soldAt);
                        }
                        if (object.committedAt != null) {
                            if (typeof object.committedAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.committedAt: object expected");
                            message.committedAt = $root.google.protobuf.Timestamp.fromObject(object.committedAt);
                        }
                        if (object.setAt != null) {
                            if (typeof object.setAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.setAt: object expected");
                            message.setAt = $root.google.protobuf.Timestamp.fromObject(object.setAt);
                        }
                        if (object.plusQuantity != null)
                            if ($util.Long)
                                (message.plusQuantity = $util.Long.fromValue(object.plusQuantity)).unsigned = false;
                            else if (typeof object.plusQuantity === "string")
                                message.plusQuantity = parseInt(object.plusQuantity, 10);
                            else if (typeof object.plusQuantity === "number")
                                message.plusQuantity = object.plusQuantity;
                            else if (typeof object.plusQuantity === "object")
                                message.plusQuantity = new $util.LongBits(object.plusQuantity.low >>> 0, object.plusQuantity.high >>> 0).toNumber();
                        if (object.plusAt != null) {
                            if (typeof object.plusAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.plusAt: object expected");
                            message.plusAt = $root.google.protobuf.Timestamp.fromObject(object.plusAt);
                        }
                        if (object.moveAt != null) {
                            if (typeof object.moveAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.moveAt: object expected");
                            message.moveAt = $root.google.protobuf.Timestamp.fromObject(object.moveAt);
                        }
                        if (object.targetSupplierId != null)
                            message.targetSupplierId = String(object.targetSupplierId);
                        if (object.lastQuantity != null)
                            if ($util.Long)
                                (message.lastQuantity = $util.Long.fromValue(object.lastQuantity)).unsigned = false;
                            else if (typeof object.lastQuantity === "string")
                                message.lastQuantity = parseInt(object.lastQuantity, 10);
                            else if (typeof object.lastQuantity === "number")
                                message.lastQuantity = object.lastQuantity;
                            else if (typeof object.lastQuantity === "object")
                                message.lastQuantity = new $util.LongBits(object.lastQuantity.low >>> 0, object.lastQuantity.high >>> 0).toNumber();
                        if (object.lastSetAt != null) {
                            if (typeof object.lastSetAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.lastSetAt: object expected");
                            message.lastSetAt = $root.google.protobuf.Timestamp.fromObject(object.lastSetAt);
                        }
                        if (object.lastPlusQuantity != null)
                            if ($util.Long)
                                (message.lastPlusQuantity = $util.Long.fromValue(object.lastPlusQuantity)).unsigned = false;
                            else if (typeof object.lastPlusQuantity === "string")
                                message.lastPlusQuantity = parseInt(object.lastPlusQuantity, 10);
                            else if (typeof object.lastPlusQuantity === "number")
                                message.lastPlusQuantity = object.lastPlusQuantity;
                            else if (typeof object.lastPlusQuantity === "object")
                                message.lastPlusQuantity = new $util.LongBits(object.lastPlusQuantity.low >>> 0, object.lastPlusQuantity.high >>> 0).toNumber();
                        if (object.lastPlusAt != null) {
                            if (typeof object.lastPlusAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SoldStock.lastPlusAt: object expected");
                            message.lastPlusAt = $root.google.protobuf.Timestamp.fromObject(object.lastPlusAt);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SoldStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.SoldStock} message SoldStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SoldStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.sell = null;
                            object.soldAt = null;
                            object.committedAt = null;
                            object.setAt = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.plusQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.plusQuantity = options.longs === String ? "0" : 0;
                            object.plusAt = null;
                            object.moveAt = null;
                            object.targetSupplierId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastQuantity = options.longs === String ? "0" : 0;
                            object.lastSetAt = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastPlusQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastPlusQuantity = options.longs === String ? "0" : 0;
                            object.lastPlusAt = null;
                        }
                        if (message.sell != null && message.hasOwnProperty("sell"))
                            object.sell = $root.theplant.ec.service.inventory.SellStock.toObject(message.sell, options);
                        if (message.soldAt != null && message.hasOwnProperty("soldAt"))
                            object.soldAt = $root.google.protobuf.Timestamp.toObject(message.soldAt, options);
                        if (message.committedAt != null && message.hasOwnProperty("committedAt"))
                            object.committedAt = $root.google.protobuf.Timestamp.toObject(message.committedAt, options);
                        if (message.setAt != null && message.hasOwnProperty("setAt"))
                            object.setAt = $root.google.protobuf.Timestamp.toObject(message.setAt, options);
                        if (message.plusQuantity != null && message.hasOwnProperty("plusQuantity"))
                            if (typeof message.plusQuantity === "number")
                                object.plusQuantity = options.longs === String ? String(message.plusQuantity) : message.plusQuantity;
                            else
                                object.plusQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.plusQuantity) : options.longs === Number ? new $util.LongBits(message.plusQuantity.low >>> 0, message.plusQuantity.high >>> 0).toNumber() : message.plusQuantity;
                        if (message.plusAt != null && message.hasOwnProperty("plusAt"))
                            object.plusAt = $root.google.protobuf.Timestamp.toObject(message.plusAt, options);
                        if (message.moveAt != null && message.hasOwnProperty("moveAt"))
                            object.moveAt = $root.google.protobuf.Timestamp.toObject(message.moveAt, options);
                        if (message.targetSupplierId != null && message.hasOwnProperty("targetSupplierId"))
                            object.targetSupplierId = message.targetSupplierId;
                        if (message.lastQuantity != null && message.hasOwnProperty("lastQuantity"))
                            if (typeof message.lastQuantity === "number")
                                object.lastQuantity = options.longs === String ? String(message.lastQuantity) : message.lastQuantity;
                            else
                                object.lastQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.lastQuantity) : options.longs === Number ? new $util.LongBits(message.lastQuantity.low >>> 0, message.lastQuantity.high >>> 0).toNumber() : message.lastQuantity;
                        if (message.lastSetAt != null && message.hasOwnProperty("lastSetAt"))
                            object.lastSetAt = $root.google.protobuf.Timestamp.toObject(message.lastSetAt, options);
                        if (message.lastPlusQuantity != null && message.hasOwnProperty("lastPlusQuantity"))
                            if (typeof message.lastPlusQuantity === "number")
                                object.lastPlusQuantity = options.longs === String ? String(message.lastPlusQuantity) : message.lastPlusQuantity;
                            else
                                object.lastPlusQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.lastPlusQuantity) : options.longs === Number ? new $util.LongBits(message.lastPlusQuantity.low >>> 0, message.lastPlusQuantity.high >>> 0).toNumber() : message.lastPlusQuantity;
                        if (message.lastPlusAt != null && message.hasOwnProperty("lastPlusAt"))
                            object.lastPlusAt = $root.google.protobuf.Timestamp.toObject(message.lastPlusAt, options);
                        return object;
                    };

                    /**
                     * Converts this SoldStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.SoldStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SoldStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SoldStock;
                })();

                inventory.AdjustSellStockOrder = (function() {

                    /**
                     * Properties of an AdjustSellStockOrder.
                     * @memberof theplant.ec.service.inventory
                     * @interface IAdjustSellStockOrder
                     * @property {theplant.ec.service.inventory.IStockOrderId|null} [orderId] AdjustSellStockOrder orderId
                     * @property {Array.<theplant.ec.service.inventory.AdjustSellStockOrder.Istep>|null} [steps] AdjustSellStockOrder steps
                     */

                    /**
                     * Constructs a new AdjustSellStockOrder.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents an AdjustSellStockOrder.
                     * @implements IAdjustSellStockOrder
                     * @constructor
                     * @param {theplant.ec.service.inventory.IAdjustSellStockOrder=} [properties] Properties to set
                     */
                    function AdjustSellStockOrder(properties) {
                        this.steps = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AdjustSellStockOrder orderId.
                     * @member {theplant.ec.service.inventory.IStockOrderId|null|undefined} orderId
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @instance
                     */
                    AdjustSellStockOrder.prototype.orderId = null;

                    /**
                     * AdjustSellStockOrder steps.
                     * @member {Array.<theplant.ec.service.inventory.AdjustSellStockOrder.Istep>} steps
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @instance
                     */
                    AdjustSellStockOrder.prototype.steps = $util.emptyArray;

                    /**
                     * Creates a new AdjustSellStockOrder instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.IAdjustSellStockOrder=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.AdjustSellStockOrder} AdjustSellStockOrder instance
                     */
                    AdjustSellStockOrder.create = function create(properties) {
                        return new AdjustSellStockOrder(properties);
                    };

                    /**
                     * Encodes the specified AdjustSellStockOrder message. Does not implicitly {@link theplant.ec.service.inventory.AdjustSellStockOrder.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.IAdjustSellStockOrder} message AdjustSellStockOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AdjustSellStockOrder.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            $root.theplant.ec.service.inventory.StockOrderId.encode(message.orderId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.steps != null && message.steps.length)
                            for (var i = 0; i < message.steps.length; ++i)
                                $root.theplant.ec.service.inventory.AdjustSellStockOrder.step.encode(message.steps[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AdjustSellStockOrder message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.AdjustSellStockOrder.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.IAdjustSellStockOrder} message AdjustSellStockOrder message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AdjustSellStockOrder.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AdjustSellStockOrder message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.AdjustSellStockOrder} AdjustSellStockOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AdjustSellStockOrder.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.AdjustSellStockOrder();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderId = $root.theplant.ec.service.inventory.StockOrderId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.steps && message.steps.length))
                                    message.steps = [];
                                message.steps.push($root.theplant.ec.service.inventory.AdjustSellStockOrder.step.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AdjustSellStockOrder message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.AdjustSellStockOrder} AdjustSellStockOrder
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AdjustSellStockOrder.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AdjustSellStockOrder message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AdjustSellStockOrder.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderId != null && message.hasOwnProperty("orderId")) {
                            var error = $root.theplant.ec.service.inventory.StockOrderId.verify(message.orderId);
                            if (error)
                                return "orderId." + error;
                        }
                        if (message.steps != null && message.hasOwnProperty("steps")) {
                            if (!Array.isArray(message.steps))
                                return "steps: array expected";
                            for (var i = 0; i < message.steps.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.AdjustSellStockOrder.step.verify(message.steps[i]);
                                if (error)
                                    return "steps." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an AdjustSellStockOrder message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.AdjustSellStockOrder} AdjustSellStockOrder
                     */
                    AdjustSellStockOrder.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.AdjustSellStockOrder)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.AdjustSellStockOrder();
                        if (object.orderId != null) {
                            if (typeof object.orderId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.orderId: object expected");
                            message.orderId = $root.theplant.ec.service.inventory.StockOrderId.fromObject(object.orderId);
                        }
                        if (object.steps) {
                            if (!Array.isArray(object.steps))
                                throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.steps: array expected");
                            message.steps = [];
                            for (var i = 0; i < object.steps.length; ++i) {
                                if (typeof object.steps[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.steps: object expected");
                                message.steps[i] = $root.theplant.ec.service.inventory.AdjustSellStockOrder.step.fromObject(object.steps[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AdjustSellStockOrder message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @static
                     * @param {theplant.ec.service.inventory.AdjustSellStockOrder} message AdjustSellStockOrder
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AdjustSellStockOrder.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.steps = [];
                        if (options.defaults)
                            object.orderId = null;
                        if (message.orderId != null && message.hasOwnProperty("orderId"))
                            object.orderId = $root.theplant.ec.service.inventory.StockOrderId.toObject(message.orderId, options);
                        if (message.steps && message.steps.length) {
                            object.steps = [];
                            for (var j = 0; j < message.steps.length; ++j)
                                object.steps[j] = $root.theplant.ec.service.inventory.AdjustSellStockOrder.step.toObject(message.steps[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this AdjustSellStockOrder to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AdjustSellStockOrder.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    AdjustSellStockOrder.step = (function() {

                        /**
                         * Properties of a step.
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                         * @interface Istep
                         * @property {theplant.ec.service.inventory.ICreateSellStock|null} [create] step create
                         * @property {theplant.ec.service.inventory.ISetSellStock|null} [set] step set
                         * @property {theplant.ec.service.inventory.IPlusSellStock|null} [plus] step plus
                         * @property {theplant.ec.service.inventory.ICommitSellStock|null} [commit] step commit
                         * @property {theplant.ec.service.inventory.IMoveSellStock|null} [move] step move
                         */

                        /**
                         * Constructs a new step.
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder
                         * @classdesc Represents a step.
                         * @implements Istep
                         * @constructor
                         * @param {theplant.ec.service.inventory.AdjustSellStockOrder.Istep=} [properties] Properties to set
                         */
                        function step(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * step create.
                         * @member {theplant.ec.service.inventory.ICreateSellStock|null|undefined} create
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         */
                        step.prototype.create = null;

                        /**
                         * step set.
                         * @member {theplant.ec.service.inventory.ISetSellStock|null|undefined} set
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         */
                        step.prototype.set = null;

                        /**
                         * step plus.
                         * @member {theplant.ec.service.inventory.IPlusSellStock|null|undefined} plus
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         */
                        step.prototype.plus = null;

                        /**
                         * step commit.
                         * @member {theplant.ec.service.inventory.ICommitSellStock|null|undefined} commit
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         */
                        step.prototype.commit = null;

                        /**
                         * step move.
                         * @member {theplant.ec.service.inventory.IMoveSellStock|null|undefined} move
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         */
                        step.prototype.move = null;

                        // OneOf field names bound to virtual getters and setters
                        var $oneOfFields;

                        /**
                         * step s.
                         * @member {"create"|"set"|"plus"|"commit"|"move"|undefined} s
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         */
                        Object.defineProperty(step.prototype, "s", {
                            get: $util.oneOfGetter($oneOfFields = ["create", "set", "plus", "commit", "move"]),
                            set: $util.oneOfSetter($oneOfFields)
                        });

                        /**
                         * Creates a new step instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {theplant.ec.service.inventory.AdjustSellStockOrder.Istep=} [properties] Properties to set
                         * @returns {theplant.ec.service.inventory.AdjustSellStockOrder.step} step instance
                         */
                        step.create = function create(properties) {
                            return new step(properties);
                        };

                        /**
                         * Encodes the specified step message. Does not implicitly {@link theplant.ec.service.inventory.AdjustSellStockOrder.step.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {theplant.ec.service.inventory.AdjustSellStockOrder.Istep} message step message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        step.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.create != null && message.hasOwnProperty("create"))
                                $root.theplant.ec.service.inventory.CreateSellStock.encode(message.create, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                            if (message.set != null && message.hasOwnProperty("set"))
                                $root.theplant.ec.service.inventory.SetSellStock.encode(message.set, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            if (message.plus != null && message.hasOwnProperty("plus"))
                                $root.theplant.ec.service.inventory.PlusSellStock.encode(message.plus, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            if (message.commit != null && message.hasOwnProperty("commit"))
                                $root.theplant.ec.service.inventory.CommitSellStock.encode(message.commit, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                            if (message.move != null && message.hasOwnProperty("move"))
                                $root.theplant.ec.service.inventory.MoveSellStock.encode(message.move, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified step message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.AdjustSellStockOrder.step.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {theplant.ec.service.inventory.AdjustSellStockOrder.Istep} message step message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        step.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a step message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.inventory.AdjustSellStockOrder.step} step
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        step.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.AdjustSellStockOrder.step();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.create = $root.theplant.ec.service.inventory.CreateSellStock.decode(reader, reader.uint32());
                                    break;
                                case 2:
                                    message.set = $root.theplant.ec.service.inventory.SetSellStock.decode(reader, reader.uint32());
                                    break;
                                case 3:
                                    message.plus = $root.theplant.ec.service.inventory.PlusSellStock.decode(reader, reader.uint32());
                                    break;
                                case 4:
                                    message.commit = $root.theplant.ec.service.inventory.CommitSellStock.decode(reader, reader.uint32());
                                    break;
                                case 5:
                                    message.move = $root.theplant.ec.service.inventory.MoveSellStock.decode(reader, reader.uint32());
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a step message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.inventory.AdjustSellStockOrder.step} step
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        step.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a step message.
                         * @function verify
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        step.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            var properties = {};
                            if (message.create != null && message.hasOwnProperty("create")) {
                                properties.s = 1;
                                {
                                    var error = $root.theplant.ec.service.inventory.CreateSellStock.verify(message.create);
                                    if (error)
                                        return "create." + error;
                                }
                            }
                            if (message.set != null && message.hasOwnProperty("set")) {
                                if (properties.s === 1)
                                    return "s: multiple values";
                                properties.s = 1;
                                {
                                    var error = $root.theplant.ec.service.inventory.SetSellStock.verify(message.set);
                                    if (error)
                                        return "set." + error;
                                }
                            }
                            if (message.plus != null && message.hasOwnProperty("plus")) {
                                if (properties.s === 1)
                                    return "s: multiple values";
                                properties.s = 1;
                                {
                                    var error = $root.theplant.ec.service.inventory.PlusSellStock.verify(message.plus);
                                    if (error)
                                        return "plus." + error;
                                }
                            }
                            if (message.commit != null && message.hasOwnProperty("commit")) {
                                if (properties.s === 1)
                                    return "s: multiple values";
                                properties.s = 1;
                                {
                                    var error = $root.theplant.ec.service.inventory.CommitSellStock.verify(message.commit);
                                    if (error)
                                        return "commit." + error;
                                }
                            }
                            if (message.move != null && message.hasOwnProperty("move")) {
                                if (properties.s === 1)
                                    return "s: multiple values";
                                properties.s = 1;
                                {
                                    var error = $root.theplant.ec.service.inventory.MoveSellStock.verify(message.move);
                                    if (error)
                                        return "move." + error;
                                }
                            }
                            return null;
                        };

                        /**
                         * Creates a step message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.inventory.AdjustSellStockOrder.step} step
                         */
                        step.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.inventory.AdjustSellStockOrder.step)
                                return object;
                            var message = new $root.theplant.ec.service.inventory.AdjustSellStockOrder.step();
                            if (object.create != null) {
                                if (typeof object.create !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.step.create: object expected");
                                message.create = $root.theplant.ec.service.inventory.CreateSellStock.fromObject(object.create);
                            }
                            if (object.set != null) {
                                if (typeof object.set !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.step.set: object expected");
                                message.set = $root.theplant.ec.service.inventory.SetSellStock.fromObject(object.set);
                            }
                            if (object.plus != null) {
                                if (typeof object.plus !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.step.plus: object expected");
                                message.plus = $root.theplant.ec.service.inventory.PlusSellStock.fromObject(object.plus);
                            }
                            if (object.commit != null) {
                                if (typeof object.commit !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.step.commit: object expected");
                                message.commit = $root.theplant.ec.service.inventory.CommitSellStock.fromObject(object.commit);
                            }
                            if (object.move != null) {
                                if (typeof object.move !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.AdjustSellStockOrder.step.move: object expected");
                                message.move = $root.theplant.ec.service.inventory.MoveSellStock.fromObject(object.move);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a step message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @static
                         * @param {theplant.ec.service.inventory.AdjustSellStockOrder.step} message step
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        step.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (message.create != null && message.hasOwnProperty("create")) {
                                object.create = $root.theplant.ec.service.inventory.CreateSellStock.toObject(message.create, options);
                                if (options.oneofs)
                                    object.s = "create";
                            }
                            if (message.set != null && message.hasOwnProperty("set")) {
                                object.set = $root.theplant.ec.service.inventory.SetSellStock.toObject(message.set, options);
                                if (options.oneofs)
                                    object.s = "set";
                            }
                            if (message.plus != null && message.hasOwnProperty("plus")) {
                                object.plus = $root.theplant.ec.service.inventory.PlusSellStock.toObject(message.plus, options);
                                if (options.oneofs)
                                    object.s = "plus";
                            }
                            if (message.commit != null && message.hasOwnProperty("commit")) {
                                object.commit = $root.theplant.ec.service.inventory.CommitSellStock.toObject(message.commit, options);
                                if (options.oneofs)
                                    object.s = "commit";
                            }
                            if (message.move != null && message.hasOwnProperty("move")) {
                                object.move = $root.theplant.ec.service.inventory.MoveSellStock.toObject(message.move, options);
                                if (options.oneofs)
                                    object.s = "move";
                            }
                            return object;
                        };

                        /**
                         * Converts this step to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.inventory.AdjustSellStockOrder.step
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        step.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return step;
                    })();

                    return AdjustSellStockOrder;
                })();

                inventory.CreateSellStock = (function() {

                    /**
                     * Properties of a CreateSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface ICreateSellStock
                     * @property {theplant.ec.service.inventory.ISellStock|null} [stock] CreateSellStock stock
                     */

                    /**
                     * Constructs a new CreateSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a CreateSellStock.
                     * @implements ICreateSellStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.ICreateSellStock=} [properties] Properties to set
                     */
                    function CreateSellStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CreateSellStock stock.
                     * @member {theplant.ec.service.inventory.ISellStock|null|undefined} stock
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @instance
                     */
                    CreateSellStock.prototype.stock = null;

                    /**
                     * Creates a new CreateSellStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ICreateSellStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.CreateSellStock} CreateSellStock instance
                     */
                    CreateSellStock.create = function create(properties) {
                        return new CreateSellStock(properties);
                    };

                    /**
                     * Encodes the specified CreateSellStock message. Does not implicitly {@link theplant.ec.service.inventory.CreateSellStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ICreateSellStock} message CreateSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSellStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            $root.theplant.ec.service.inventory.SellStock.encode(message.stock, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CreateSellStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.CreateSellStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ICreateSellStock} message CreateSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CreateSellStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CreateSellStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.CreateSellStock} CreateSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSellStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.CreateSellStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stock = $root.theplant.ec.service.inventory.SellStock.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CreateSellStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.CreateSellStock} CreateSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CreateSellStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CreateSellStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CreateSellStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stock != null && message.hasOwnProperty("stock")) {
                            var error = $root.theplant.ec.service.inventory.SellStock.verify(message.stock);
                            if (error)
                                return "stock." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CreateSellStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.CreateSellStock} CreateSellStock
                     */
                    CreateSellStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.CreateSellStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.CreateSellStock();
                        if (object.stock != null) {
                            if (typeof object.stock !== "object")
                                throw TypeError(".theplant.ec.service.inventory.CreateSellStock.stock: object expected");
                            message.stock = $root.theplant.ec.service.inventory.SellStock.fromObject(object.stock);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CreateSellStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.CreateSellStock} message CreateSellStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CreateSellStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.stock = null;
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            object.stock = $root.theplant.ec.service.inventory.SellStock.toObject(message.stock, options);
                        return object;
                    };

                    /**
                     * Converts this CreateSellStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.CreateSellStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CreateSellStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CreateSellStock;
                })();

                inventory.SetSellStock = (function() {

                    /**
                     * Properties of a SetSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface ISetSellStock
                     * @property {theplant.ec.service.inventory.ISellStock|null} [stock] SetSellStock stock
                     */

                    /**
                     * Constructs a new SetSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a SetSellStock.
                     * @implements ISetSellStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.ISetSellStock=} [properties] Properties to set
                     */
                    function SetSellStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SetSellStock stock.
                     * @member {theplant.ec.service.inventory.ISellStock|null|undefined} stock
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @instance
                     */
                    SetSellStock.prototype.stock = null;

                    /**
                     * Creates a new SetSellStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISetSellStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.SetSellStock} SetSellStock instance
                     */
                    SetSellStock.create = function create(properties) {
                        return new SetSellStock(properties);
                    };

                    /**
                     * Encodes the specified SetSellStock message. Does not implicitly {@link theplant.ec.service.inventory.SetSellStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISetSellStock} message SetSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetSellStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            $root.theplant.ec.service.inventory.SellStock.encode(message.stock, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SetSellStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.SetSellStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ISetSellStock} message SetSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SetSellStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SetSellStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.SetSellStock} SetSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetSellStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.SetSellStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stock = $root.theplant.ec.service.inventory.SellStock.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SetSellStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.SetSellStock} SetSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SetSellStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SetSellStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SetSellStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stock != null && message.hasOwnProperty("stock")) {
                            var error = $root.theplant.ec.service.inventory.SellStock.verify(message.stock);
                            if (error)
                                return "stock." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a SetSellStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.SetSellStock} SetSellStock
                     */
                    SetSellStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.SetSellStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.SetSellStock();
                        if (object.stock != null) {
                            if (typeof object.stock !== "object")
                                throw TypeError(".theplant.ec.service.inventory.SetSellStock.stock: object expected");
                            message.stock = $root.theplant.ec.service.inventory.SellStock.fromObject(object.stock);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SetSellStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.SetSellStock} message SetSellStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SetSellStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.stock = null;
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            object.stock = $root.theplant.ec.service.inventory.SellStock.toObject(message.stock, options);
                        return object;
                    };

                    /**
                     * Converts this SetSellStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.SetSellStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SetSellStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SetSellStock;
                })();

                inventory.PlusSellStock = (function() {

                    /**
                     * Properties of a PlusSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IPlusSellStock
                     * @property {theplant.ec.service.inventory.ISellStock|null} [stock] PlusSellStock stock
                     */

                    /**
                     * Constructs a new PlusSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a PlusSellStock.
                     * @implements IPlusSellStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IPlusSellStock=} [properties] Properties to set
                     */
                    function PlusSellStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PlusSellStock stock.
                     * @member {theplant.ec.service.inventory.ISellStock|null|undefined} stock
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @instance
                     */
                    PlusSellStock.prototype.stock = null;

                    /**
                     * Creates a new PlusSellStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.IPlusSellStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.PlusSellStock} PlusSellStock instance
                     */
                    PlusSellStock.create = function create(properties) {
                        return new PlusSellStock(properties);
                    };

                    /**
                     * Encodes the specified PlusSellStock message. Does not implicitly {@link theplant.ec.service.inventory.PlusSellStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.IPlusSellStock} message PlusSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PlusSellStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            $root.theplant.ec.service.inventory.SellStock.encode(message.stock, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PlusSellStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.PlusSellStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.IPlusSellStock} message PlusSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PlusSellStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PlusSellStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.PlusSellStock} PlusSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PlusSellStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.PlusSellStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stock = $root.theplant.ec.service.inventory.SellStock.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PlusSellStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.PlusSellStock} PlusSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PlusSellStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PlusSellStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PlusSellStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stock != null && message.hasOwnProperty("stock")) {
                            var error = $root.theplant.ec.service.inventory.SellStock.verify(message.stock);
                            if (error)
                                return "stock." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a PlusSellStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.PlusSellStock} PlusSellStock
                     */
                    PlusSellStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.PlusSellStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.PlusSellStock();
                        if (object.stock != null) {
                            if (typeof object.stock !== "object")
                                throw TypeError(".theplant.ec.service.inventory.PlusSellStock.stock: object expected");
                            message.stock = $root.theplant.ec.service.inventory.SellStock.fromObject(object.stock);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PlusSellStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.PlusSellStock} message PlusSellStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PlusSellStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.stock = null;
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            object.stock = $root.theplant.ec.service.inventory.SellStock.toObject(message.stock, options);
                        return object;
                    };

                    /**
                     * Converts this PlusSellStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.PlusSellStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PlusSellStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PlusSellStock;
                })();

                inventory.CommitSellStock = (function() {

                    /**
                     * Properties of a CommitSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface ICommitSellStock
                     * @property {theplant.ec.service.inventory.IStockId|null} [stockId] CommitSellStock stockId
                     */

                    /**
                     * Constructs a new CommitSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a CommitSellStock.
                     * @implements ICommitSellStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.ICommitSellStock=} [properties] Properties to set
                     */
                    function CommitSellStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CommitSellStock stockId.
                     * @member {theplant.ec.service.inventory.IStockId|null|undefined} stockId
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @instance
                     */
                    CommitSellStock.prototype.stockId = null;

                    /**
                     * Creates a new CommitSellStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ICommitSellStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.CommitSellStock} CommitSellStock instance
                     */
                    CommitSellStock.create = function create(properties) {
                        return new CommitSellStock(properties);
                    };

                    /**
                     * Encodes the specified CommitSellStock message. Does not implicitly {@link theplant.ec.service.inventory.CommitSellStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ICommitSellStock} message CommitSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CommitSellStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            $root.theplant.ec.service.inventory.StockId.encode(message.stockId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CommitSellStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.CommitSellStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.ICommitSellStock} message CommitSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CommitSellStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CommitSellStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.CommitSellStock} CommitSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CommitSellStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.CommitSellStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stockId = $root.theplant.ec.service.inventory.StockId.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CommitSellStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.CommitSellStock} CommitSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CommitSellStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CommitSellStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CommitSellStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stockId != null && message.hasOwnProperty("stockId")) {
                            var error = $root.theplant.ec.service.inventory.StockId.verify(message.stockId);
                            if (error)
                                return "stockId." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CommitSellStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.CommitSellStock} CommitSellStock
                     */
                    CommitSellStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.CommitSellStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.CommitSellStock();
                        if (object.stockId != null) {
                            if (typeof object.stockId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.CommitSellStock.stockId: object expected");
                            message.stockId = $root.theplant.ec.service.inventory.StockId.fromObject(object.stockId);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CommitSellStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.CommitSellStock} message CommitSellStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CommitSellStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.stockId = null;
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            object.stockId = $root.theplant.ec.service.inventory.StockId.toObject(message.stockId, options);
                        return object;
                    };

                    /**
                     * Converts this CommitSellStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.CommitSellStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CommitSellStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CommitSellStock;
                })();

                inventory.MoveSellStock = (function() {

                    /**
                     * Properties of a MoveSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IMoveSellStock
                     * @property {theplant.ec.service.inventory.IStockId|null} [source] MoveSellStock source
                     * @property {theplant.ec.service.inventory.IStockId|null} [target] MoveSellStock target
                     */

                    /**
                     * Constructs a new MoveSellStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a MoveSellStock.
                     * @implements IMoveSellStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IMoveSellStock=} [properties] Properties to set
                     */
                    function MoveSellStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MoveSellStock source.
                     * @member {theplant.ec.service.inventory.IStockId|null|undefined} source
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @instance
                     */
                    MoveSellStock.prototype.source = null;

                    /**
                     * MoveSellStock target.
                     * @member {theplant.ec.service.inventory.IStockId|null|undefined} target
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @instance
                     */
                    MoveSellStock.prototype.target = null;

                    /**
                     * Creates a new MoveSellStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.IMoveSellStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.MoveSellStock} MoveSellStock instance
                     */
                    MoveSellStock.create = function create(properties) {
                        return new MoveSellStock(properties);
                    };

                    /**
                     * Encodes the specified MoveSellStock message. Does not implicitly {@link theplant.ec.service.inventory.MoveSellStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.IMoveSellStock} message MoveSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MoveSellStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.source != null && message.hasOwnProperty("source"))
                            $root.theplant.ec.service.inventory.StockId.encode(message.source, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.target != null && message.hasOwnProperty("target"))
                            $root.theplant.ec.service.inventory.StockId.encode(message.target, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified MoveSellStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.MoveSellStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.IMoveSellStock} message MoveSellStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MoveSellStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MoveSellStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.MoveSellStock} MoveSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MoveSellStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.MoveSellStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.source = $root.theplant.ec.service.inventory.StockId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.target = $root.theplant.ec.service.inventory.StockId.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MoveSellStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.MoveSellStock} MoveSellStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MoveSellStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MoveSellStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MoveSellStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.source != null && message.hasOwnProperty("source")) {
                            var error = $root.theplant.ec.service.inventory.StockId.verify(message.source);
                            if (error)
                                return "source." + error;
                        }
                        if (message.target != null && message.hasOwnProperty("target")) {
                            var error = $root.theplant.ec.service.inventory.StockId.verify(message.target);
                            if (error)
                                return "target." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a MoveSellStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.MoveSellStock} MoveSellStock
                     */
                    MoveSellStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.MoveSellStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.MoveSellStock();
                        if (object.source != null) {
                            if (typeof object.source !== "object")
                                throw TypeError(".theplant.ec.service.inventory.MoveSellStock.source: object expected");
                            message.source = $root.theplant.ec.service.inventory.StockId.fromObject(object.source);
                        }
                        if (object.target != null) {
                            if (typeof object.target !== "object")
                                throw TypeError(".theplant.ec.service.inventory.MoveSellStock.target: object expected");
                            message.target = $root.theplant.ec.service.inventory.StockId.fromObject(object.target);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a MoveSellStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @static
                     * @param {theplant.ec.service.inventory.MoveSellStock} message MoveSellStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MoveSellStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.source = null;
                            object.target = null;
                        }
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = $root.theplant.ec.service.inventory.StockId.toObject(message.source, options);
                        if (message.target != null && message.hasOwnProperty("target"))
                            object.target = $root.theplant.ec.service.inventory.StockId.toObject(message.target, options);
                        return object;
                    };

                    /**
                     * Converts this MoveSellStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.MoveSellStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MoveSellStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MoveSellStock;
                })();

                inventory.StockQuery = (function() {

                    /**
                     * Properties of a StockQuery.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStockQuery
                     * @property {Array.<string>|null} [articleCodes] StockQuery articleCodes
                     * @property {Array.<string>|null} [supplierIds] StockQuery supplierIds
                     * @property {boolean|null} [hasTrace] StockQuery hasTrace
                     * @property {number|null} [offset] StockQuery offset
                     * @property {number|null} [limit] StockQuery limit
                     * @property {theplant.ec.service.inventory.IStockQuerySortSpec|null} [sort] StockQuery sort
                     */

                    /**
                     * Constructs a new StockQuery.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StockQuery.
                     * @implements IStockQuery
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStockQuery=} [properties] Properties to set
                     */
                    function StockQuery(properties) {
                        this.articleCodes = [];
                        this.supplierIds = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StockQuery articleCodes.
                     * @member {Array.<string>} articleCodes
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     */
                    StockQuery.prototype.articleCodes = $util.emptyArray;

                    /**
                     * StockQuery supplierIds.
                     * @member {Array.<string>} supplierIds
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     */
                    StockQuery.prototype.supplierIds = $util.emptyArray;

                    /**
                     * StockQuery hasTrace.
                     * @member {boolean} hasTrace
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     */
                    StockQuery.prototype.hasTrace = false;

                    /**
                     * StockQuery offset.
                     * @member {number} offset
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     */
                    StockQuery.prototype.offset = 0;

                    /**
                     * StockQuery limit.
                     * @member {number} limit
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     */
                    StockQuery.prototype.limit = 0;

                    /**
                     * StockQuery sort.
                     * @member {theplant.ec.service.inventory.IStockQuerySortSpec|null|undefined} sort
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     */
                    StockQuery.prototype.sort = null;

                    /**
                     * Creates a new StockQuery instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQuery=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StockQuery} StockQuery instance
                     */
                    StockQuery.create = function create(properties) {
                        return new StockQuery(properties);
                    };

                    /**
                     * Encodes the specified StockQuery message. Does not implicitly {@link theplant.ec.service.inventory.StockQuery.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQuery} message StockQuery message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockQuery.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCodes != null && message.articleCodes.length)
                            for (var i = 0; i < message.articleCodes.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCodes[i]);
                        if (message.supplierIds != null && message.supplierIds.length)
                            for (var i = 0; i < message.supplierIds.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.supplierIds[i]);
                        if (message.hasTrace != null && message.hasOwnProperty("hasTrace"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.hasTrace);
                        if (message.offset != null && message.hasOwnProperty("offset"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.offset);
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.limit);
                        if (message.sort != null && message.hasOwnProperty("sort"))
                            $root.theplant.ec.service.inventory.StockQuerySortSpec.encode(message.sort, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified StockQuery message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StockQuery.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQuery} message StockQuery message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockQuery.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StockQuery message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StockQuery} StockQuery
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockQuery.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StockQuery();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.articleCodes && message.articleCodes.length))
                                    message.articleCodes = [];
                                message.articleCodes.push(reader.string());
                                break;
                            case 2:
                                if (!(message.supplierIds && message.supplierIds.length))
                                    message.supplierIds = [];
                                message.supplierIds.push(reader.string());
                                break;
                            case 3:
                                message.hasTrace = reader.bool();
                                break;
                            case 4:
                                message.offset = reader.int32();
                                break;
                            case 5:
                                message.limit = reader.int32();
                                break;
                            case 6:
                                message.sort = $root.theplant.ec.service.inventory.StockQuerySortSpec.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StockQuery message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StockQuery} StockQuery
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockQuery.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StockQuery message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StockQuery.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCodes != null && message.hasOwnProperty("articleCodes")) {
                            if (!Array.isArray(message.articleCodes))
                                return "articleCodes: array expected";
                            for (var i = 0; i < message.articleCodes.length; ++i)
                                if (!$util.isString(message.articleCodes[i]))
                                    return "articleCodes: string[] expected";
                        }
                        if (message.supplierIds != null && message.hasOwnProperty("supplierIds")) {
                            if (!Array.isArray(message.supplierIds))
                                return "supplierIds: array expected";
                            for (var i = 0; i < message.supplierIds.length; ++i)
                                if (!$util.isString(message.supplierIds[i]))
                                    return "supplierIds: string[] expected";
                        }
                        if (message.hasTrace != null && message.hasOwnProperty("hasTrace"))
                            if (typeof message.hasTrace !== "boolean")
                                return "hasTrace: boolean expected";
                        if (message.offset != null && message.hasOwnProperty("offset"))
                            if (!$util.isInteger(message.offset))
                                return "offset: integer expected";
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            if (!$util.isInteger(message.limit))
                                return "limit: integer expected";
                        if (message.sort != null && message.hasOwnProperty("sort")) {
                            var error = $root.theplant.ec.service.inventory.StockQuerySortSpec.verify(message.sort);
                            if (error)
                                return "sort." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a StockQuery message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StockQuery} StockQuery
                     */
                    StockQuery.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StockQuery)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StockQuery();
                        if (object.articleCodes) {
                            if (!Array.isArray(object.articleCodes))
                                throw TypeError(".theplant.ec.service.inventory.StockQuery.articleCodes: array expected");
                            message.articleCodes = [];
                            for (var i = 0; i < object.articleCodes.length; ++i)
                                message.articleCodes[i] = String(object.articleCodes[i]);
                        }
                        if (object.supplierIds) {
                            if (!Array.isArray(object.supplierIds))
                                throw TypeError(".theplant.ec.service.inventory.StockQuery.supplierIds: array expected");
                            message.supplierIds = [];
                            for (var i = 0; i < object.supplierIds.length; ++i)
                                message.supplierIds[i] = String(object.supplierIds[i]);
                        }
                        if (object.hasTrace != null)
                            message.hasTrace = Boolean(object.hasTrace);
                        if (object.offset != null)
                            message.offset = object.offset | 0;
                        if (object.limit != null)
                            message.limit = object.limit | 0;
                        if (object.sort != null) {
                            if (typeof object.sort !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockQuery.sort: object expected");
                            message.sort = $root.theplant.ec.service.inventory.StockQuerySortSpec.fromObject(object.sort);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a StockQuery message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @static
                     * @param {theplant.ec.service.inventory.StockQuery} message StockQuery
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StockQuery.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.articleCodes = [];
                            object.supplierIds = [];
                        }
                        if (options.defaults) {
                            object.hasTrace = false;
                            object.offset = 0;
                            object.limit = 0;
                            object.sort = null;
                        }
                        if (message.articleCodes && message.articleCodes.length) {
                            object.articleCodes = [];
                            for (var j = 0; j < message.articleCodes.length; ++j)
                                object.articleCodes[j] = message.articleCodes[j];
                        }
                        if (message.supplierIds && message.supplierIds.length) {
                            object.supplierIds = [];
                            for (var j = 0; j < message.supplierIds.length; ++j)
                                object.supplierIds[j] = message.supplierIds[j];
                        }
                        if (message.hasTrace != null && message.hasOwnProperty("hasTrace"))
                            object.hasTrace = message.hasTrace;
                        if (message.offset != null && message.hasOwnProperty("offset"))
                            object.offset = message.offset;
                        if (message.limit != null && message.hasOwnProperty("limit"))
                            object.limit = message.limit;
                        if (message.sort != null && message.hasOwnProperty("sort"))
                            object.sort = $root.theplant.ec.service.inventory.StockQuerySortSpec.toObject(message.sort, options);
                        return object;
                    };

                    /**
                     * Converts this StockQuery to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StockQuery
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StockQuery.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StockQuery;
                })();

                inventory.StockQuerySortSpec = (function() {

                    /**
                     * Properties of a StockQuerySortSpec.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStockQuerySortSpec
                     * @property {theplant.ec.service.inventory.StockQuerySortField|null} [field] StockQuerySortSpec field
                     * @property {boolean|null} [sortDescending] StockQuerySortSpec sortDescending
                     */

                    /**
                     * Constructs a new StockQuerySortSpec.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StockQuerySortSpec.
                     * @implements IStockQuerySortSpec
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStockQuerySortSpec=} [properties] Properties to set
                     */
                    function StockQuerySortSpec(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StockQuerySortSpec field.
                     * @member {theplant.ec.service.inventory.StockQuerySortField} field
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @instance
                     */
                    StockQuerySortSpec.prototype.field = 0;

                    /**
                     * StockQuerySortSpec sortDescending.
                     * @member {boolean} sortDescending
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @instance
                     */
                    StockQuerySortSpec.prototype.sortDescending = false;

                    /**
                     * Creates a new StockQuerySortSpec instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQuerySortSpec=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StockQuerySortSpec} StockQuerySortSpec instance
                     */
                    StockQuerySortSpec.create = function create(properties) {
                        return new StockQuerySortSpec(properties);
                    };

                    /**
                     * Encodes the specified StockQuerySortSpec message. Does not implicitly {@link theplant.ec.service.inventory.StockQuerySortSpec.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQuerySortSpec} message StockQuerySortSpec message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockQuerySortSpec.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.field);
                        if (message.sortDescending != null && message.hasOwnProperty("sortDescending"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.sortDescending);
                        return writer;
                    };

                    /**
                     * Encodes the specified StockQuerySortSpec message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StockQuerySortSpec.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQuerySortSpec} message StockQuerySortSpec message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockQuerySortSpec.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StockQuerySortSpec message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StockQuerySortSpec} StockQuerySortSpec
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockQuerySortSpec.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StockQuerySortSpec();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = reader.int32();
                                break;
                            case 2:
                                message.sortDescending = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StockQuerySortSpec message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StockQuerySortSpec} StockQuerySortSpec
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockQuerySortSpec.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StockQuerySortSpec message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StockQuerySortSpec.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            switch (message.field) {
                            default:
                                return "field: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 13:
                                break;
                            }
                        if (message.sortDescending != null && message.hasOwnProperty("sortDescending"))
                            if (typeof message.sortDescending !== "boolean")
                                return "sortDescending: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a StockQuerySortSpec message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StockQuerySortSpec} StockQuerySortSpec
                     */
                    StockQuerySortSpec.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StockQuerySortSpec)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StockQuerySortSpec();
                        switch (object.field) {
                        case "NONE":
                        case 0:
                            message.field = 0;
                            break;
                        case "AVALIBLE_QUANTITY":
                        case 1:
                            message.field = 1;
                            break;
                        case "ARTICLE_CODE":
                        case 2:
                            message.field = 2;
                            break;
                        case "SUPPLIER_ID":
                        case 3:
                            message.field = 3;
                            break;
                        case "STOCK":
                        case 4:
                            message.field = 4;
                            break;
                        case "SELL_QUANTITY":
                        case 5:
                            message.field = 5;
                            break;
                        case "COMMITTED_STOCK":
                        case 6:
                            message.field = 6;
                            break;
                        case "RESET_STOCK_AT":
                        case 7:
                            message.field = 7;
                            break;
                        case "CHANGE_STOCK_AT":
                        case 8:
                            message.field = 8;
                            break;
                        case "RECENT_SELL_AT":
                        case 9:
                            message.field = 9;
                            break;
                        case "RECENT_CANCEL_AT":
                        case 10:
                            message.field = 10;
                            break;
                        case "RECENT_COMMIT_AT":
                        case 11:
                            message.field = 11;
                            break;
                        case "CREATED_AT":
                        case 13:
                            message.field = 13;
                            break;
                        }
                        if (object.sortDescending != null)
                            message.sortDescending = Boolean(object.sortDescending);
                        return message;
                    };

                    /**
                     * Creates a plain object from a StockQuerySortSpec message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @static
                     * @param {theplant.ec.service.inventory.StockQuerySortSpec} message StockQuerySortSpec
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StockQuerySortSpec.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.field = options.enums === String ? "NONE" : 0;
                            object.sortDescending = false;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = options.enums === String ? $root.theplant.ec.service.inventory.StockQuerySortField[message.field] : message.field;
                        if (message.sortDescending != null && message.hasOwnProperty("sortDescending"))
                            object.sortDescending = message.sortDescending;
                        return object;
                    };

                    /**
                     * Converts this StockQuerySortSpec to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StockQuerySortSpec
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StockQuerySortSpec.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StockQuerySortSpec;
                })();

                /**
                 * StockQuerySortField enum.
                 * @name theplant.ec.service.inventory.StockQuerySortField
                 * @enum {string}
                 * @property {number} NONE=0 NONE value
                 * @property {number} AVALIBLE_QUANTITY=1 AVALIBLE_QUANTITY value
                 * @property {number} ARTICLE_CODE=2 ARTICLE_CODE value
                 * @property {number} SUPPLIER_ID=3 SUPPLIER_ID value
                 * @property {number} STOCK=4 STOCK value
                 * @property {number} SELL_QUANTITY=5 SELL_QUANTITY value
                 * @property {number} COMMITTED_STOCK=6 COMMITTED_STOCK value
                 * @property {number} RESET_STOCK_AT=7 RESET_STOCK_AT value
                 * @property {number} CHANGE_STOCK_AT=8 CHANGE_STOCK_AT value
                 * @property {number} RECENT_SELL_AT=9 RECENT_SELL_AT value
                 * @property {number} RECENT_CANCEL_AT=10 RECENT_CANCEL_AT value
                 * @property {number} RECENT_COMMIT_AT=11 RECENT_COMMIT_AT value
                 * @property {number} CREATED_AT=13 CREATED_AT value
                 */
                inventory.StockQuerySortField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "NONE"] = 0;
                    values[valuesById[1] = "AVALIBLE_QUANTITY"] = 1;
                    values[valuesById[2] = "ARTICLE_CODE"] = 2;
                    values[valuesById[3] = "SUPPLIER_ID"] = 3;
                    values[valuesById[4] = "STOCK"] = 4;
                    values[valuesById[5] = "SELL_QUANTITY"] = 5;
                    values[valuesById[6] = "COMMITTED_STOCK"] = 6;
                    values[valuesById[7] = "RESET_STOCK_AT"] = 7;
                    values[valuesById[8] = "CHANGE_STOCK_AT"] = 8;
                    values[valuesById[9] = "RECENT_SELL_AT"] = 9;
                    values[valuesById[10] = "RECENT_CANCEL_AT"] = 10;
                    values[valuesById[11] = "RECENT_COMMIT_AT"] = 11;
                    values[valuesById[13] = "CREATED_AT"] = 13;
                    return values;
                })();

                inventory.StockQueryResult = (function() {

                    /**
                     * Properties of a StockQueryResult.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStockQueryResult
                     * @property {Array.<theplant.ec.service.inventory.IStock>|null} [stocks] StockQueryResult stocks
                     */

                    /**
                     * Constructs a new StockQueryResult.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StockQueryResult.
                     * @implements IStockQueryResult
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStockQueryResult=} [properties] Properties to set
                     */
                    function StockQueryResult(properties) {
                        this.stocks = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StockQueryResult stocks.
                     * @member {Array.<theplant.ec.service.inventory.IStock>} stocks
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @instance
                     */
                    StockQueryResult.prototype.stocks = $util.emptyArray;

                    /**
                     * Creates a new StockQueryResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQueryResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StockQueryResult} StockQueryResult instance
                     */
                    StockQueryResult.create = function create(properties) {
                        return new StockQueryResult(properties);
                    };

                    /**
                     * Encodes the specified StockQueryResult message. Does not implicitly {@link theplant.ec.service.inventory.StockQueryResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQueryResult} message StockQueryResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockQueryResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stocks != null && message.stocks.length)
                            for (var i = 0; i < message.stocks.length; ++i)
                                $root.theplant.ec.service.inventory.Stock.encode(message.stocks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified StockQueryResult message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StockQueryResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {theplant.ec.service.inventory.IStockQueryResult} message StockQueryResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockQueryResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StockQueryResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StockQueryResult} StockQueryResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockQueryResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StockQueryResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.stocks && message.stocks.length))
                                    message.stocks = [];
                                message.stocks.push($root.theplant.ec.service.inventory.Stock.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StockQueryResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StockQueryResult} StockQueryResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockQueryResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StockQueryResult message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StockQueryResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stocks != null && message.hasOwnProperty("stocks")) {
                            if (!Array.isArray(message.stocks))
                                return "stocks: array expected";
                            for (var i = 0; i < message.stocks.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.Stock.verify(message.stocks[i]);
                                if (error)
                                    return "stocks." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a StockQueryResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StockQueryResult} StockQueryResult
                     */
                    StockQueryResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StockQueryResult)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StockQueryResult();
                        if (object.stocks) {
                            if (!Array.isArray(object.stocks))
                                throw TypeError(".theplant.ec.service.inventory.StockQueryResult.stocks: array expected");
                            message.stocks = [];
                            for (var i = 0; i < object.stocks.length; ++i) {
                                if (typeof object.stocks[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.StockQueryResult.stocks: object expected");
                                message.stocks[i] = $root.theplant.ec.service.inventory.Stock.fromObject(object.stocks[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a StockQueryResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @static
                     * @param {theplant.ec.service.inventory.StockQueryResult} message StockQueryResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StockQueryResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.stocks = [];
                        if (message.stocks && message.stocks.length) {
                            object.stocks = [];
                            for (var j = 0; j < message.stocks.length; ++j)
                                object.stocks[j] = $root.theplant.ec.service.inventory.Stock.toObject(message.stocks[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this StockQueryResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StockQueryResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StockQueryResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StockQueryResult;
                })();

                inventory.Stock = (function() {

                    /**
                     * Properties of a Stock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStock
                     * @property {theplant.ec.service.inventory.IStockId|null} [stockId] Stock stockId
                     * @property {number|Long|null} [avalibleQuantity] Stock avalibleQuantity
                     * @property {theplant.ec.service.inventory.IStockTrace|null} [trace] Stock trace
                     */

                    /**
                     * Constructs a new Stock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a Stock.
                     * @implements IStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStock=} [properties] Properties to set
                     */
                    function Stock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Stock stockId.
                     * @member {theplant.ec.service.inventory.IStockId|null|undefined} stockId
                     * @memberof theplant.ec.service.inventory.Stock
                     * @instance
                     */
                    Stock.prototype.stockId = null;

                    /**
                     * Stock avalibleQuantity.
                     * @member {number|Long} avalibleQuantity
                     * @memberof theplant.ec.service.inventory.Stock
                     * @instance
                     */
                    Stock.prototype.avalibleQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Stock trace.
                     * @member {theplant.ec.service.inventory.IStockTrace|null|undefined} trace
                     * @memberof theplant.ec.service.inventory.Stock
                     * @instance
                     */
                    Stock.prototype.trace = null;

                    /**
                     * Creates a new Stock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {theplant.ec.service.inventory.IStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.Stock} Stock instance
                     */
                    Stock.create = function create(properties) {
                        return new Stock(properties);
                    };

                    /**
                     * Encodes the specified Stock message. Does not implicitly {@link theplant.ec.service.inventory.Stock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {theplant.ec.service.inventory.IStock} message Stock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Stock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            $root.theplant.ec.service.inventory.StockId.encode(message.stockId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.avalibleQuantity != null && message.hasOwnProperty("avalibleQuantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.avalibleQuantity);
                        if (message.trace != null && message.hasOwnProperty("trace"))
                            $root.theplant.ec.service.inventory.StockTrace.encode(message.trace, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Stock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.Stock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {theplant.ec.service.inventory.IStock} message Stock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Stock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Stock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.Stock} Stock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Stock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.Stock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stockId = $root.theplant.ec.service.inventory.StockId.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.avalibleQuantity = reader.int64();
                                break;
                            case 3:
                                message.trace = $root.theplant.ec.service.inventory.StockTrace.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Stock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.Stock} Stock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Stock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Stock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Stock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stockId != null && message.hasOwnProperty("stockId")) {
                            var error = $root.theplant.ec.service.inventory.StockId.verify(message.stockId);
                            if (error)
                                return "stockId." + error;
                        }
                        if (message.avalibleQuantity != null && message.hasOwnProperty("avalibleQuantity"))
                            if (!$util.isInteger(message.avalibleQuantity) && !(message.avalibleQuantity && $util.isInteger(message.avalibleQuantity.low) && $util.isInteger(message.avalibleQuantity.high)))
                                return "avalibleQuantity: integer|Long expected";
                        if (message.trace != null && message.hasOwnProperty("trace")) {
                            var error = $root.theplant.ec.service.inventory.StockTrace.verify(message.trace);
                            if (error)
                                return "trace." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Stock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.Stock} Stock
                     */
                    Stock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.Stock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.Stock();
                        if (object.stockId != null) {
                            if (typeof object.stockId !== "object")
                                throw TypeError(".theplant.ec.service.inventory.Stock.stockId: object expected");
                            message.stockId = $root.theplant.ec.service.inventory.StockId.fromObject(object.stockId);
                        }
                        if (object.avalibleQuantity != null)
                            if ($util.Long)
                                (message.avalibleQuantity = $util.Long.fromValue(object.avalibleQuantity)).unsigned = false;
                            else if (typeof object.avalibleQuantity === "string")
                                message.avalibleQuantity = parseInt(object.avalibleQuantity, 10);
                            else if (typeof object.avalibleQuantity === "number")
                                message.avalibleQuantity = object.avalibleQuantity;
                            else if (typeof object.avalibleQuantity === "object")
                                message.avalibleQuantity = new $util.LongBits(object.avalibleQuantity.low >>> 0, object.avalibleQuantity.high >>> 0).toNumber();
                        if (object.trace != null) {
                            if (typeof object.trace !== "object")
                                throw TypeError(".theplant.ec.service.inventory.Stock.trace: object expected");
                            message.trace = $root.theplant.ec.service.inventory.StockTrace.fromObject(object.trace);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Stock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.Stock
                     * @static
                     * @param {theplant.ec.service.inventory.Stock} message Stock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Stock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.stockId = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.avalibleQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.avalibleQuantity = options.longs === String ? "0" : 0;
                            object.trace = null;
                        }
                        if (message.stockId != null && message.hasOwnProperty("stockId"))
                            object.stockId = $root.theplant.ec.service.inventory.StockId.toObject(message.stockId, options);
                        if (message.avalibleQuantity != null && message.hasOwnProperty("avalibleQuantity"))
                            if (typeof message.avalibleQuantity === "number")
                                object.avalibleQuantity = options.longs === String ? String(message.avalibleQuantity) : message.avalibleQuantity;
                            else
                                object.avalibleQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.avalibleQuantity) : options.longs === Number ? new $util.LongBits(message.avalibleQuantity.low >>> 0, message.avalibleQuantity.high >>> 0).toNumber() : message.avalibleQuantity;
                        if (message.trace != null && message.hasOwnProperty("trace"))
                            object.trace = $root.theplant.ec.service.inventory.StockTrace.toObject(message.trace, options);
                        return object;
                    };

                    /**
                     * Converts this Stock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.Stock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Stock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Stock;
                })();

                inventory.StockTrace = (function() {

                    /**
                     * Properties of a StockTrace.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStockTrace
                     * @property {number|Long|null} [stock] StockTrace stock
                     * @property {number|Long|null} [sellQuantity] StockTrace sellQuantity
                     * @property {number|Long|null} [committedStock] StockTrace committedStock
                     * @property {google.protobuf.ITimestamp|null} [resetStockAt] StockTrace resetStockAt
                     * @property {string|null} [resetStockSource] StockTrace resetStockSource
                     * @property {google.protobuf.ITimestamp|null} [changeStockAt] StockTrace changeStockAt
                     * @property {string|null} [changeStockSource] StockTrace changeStockSource
                     * @property {number|Long|null} [beforeChangeStock] StockTrace beforeChangeStock
                     * @property {google.protobuf.ITimestamp|null} [recentSellAt] StockTrace recentSellAt
                     * @property {string|null} [recentSellCode] StockTrace recentSellCode
                     * @property {string|null} [recentSellSource] StockTrace recentSellSource
                     * @property {number|Long|null} [recentSellQuantity] StockTrace recentSellQuantity
                     * @property {google.protobuf.ITimestamp|null} [recentCancelAt] StockTrace recentCancelAt
                     * @property {string|null} [recentCancelCode] StockTrace recentCancelCode
                     * @property {string|null} [recentCancelSource] StockTrace recentCancelSource
                     * @property {number|Long|null} [recentCancelQuantity] StockTrace recentCancelQuantity
                     * @property {google.protobuf.ITimestamp|null} [recentCommitAt] StockTrace recentCommitAt
                     * @property {string|null} [recentCommitCode] StockTrace recentCommitCode
                     * @property {string|null} [recentCommitSource] StockTrace recentCommitSource
                     * @property {number|Long|null} [recentCommitQuantity] StockTrace recentCommitQuantity
                     * @property {number|Long|null} [lastStock] StockTrace lastStock
                     * @property {google.protobuf.ITimestamp|null} [lastResetStockAt] StockTrace lastResetStockAt
                     * @property {string|null} [lastResetStockSource] StockTrace lastResetStockSource
                     * @property {number|Long|null} [sellCountWhenReset] StockTrace sellCountWhenReset
                     * @property {number|Long|null} [committedStockWhenReset] StockTrace committedStockWhenReset
                     * @property {google.protobuf.ITimestamp|null} [createdAt] StockTrace createdAt
                     */

                    /**
                     * Constructs a new StockTrace.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StockTrace.
                     * @implements IStockTrace
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStockTrace=} [properties] Properties to set
                     */
                    function StockTrace(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StockTrace stock.
                     * @member {number|Long} stock
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.stock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace sellQuantity.
                     * @member {number|Long} sellQuantity
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.sellQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace committedStock.
                     * @member {number|Long} committedStock
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.committedStock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace resetStockAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} resetStockAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.resetStockAt = null;

                    /**
                     * StockTrace resetStockSource.
                     * @member {string} resetStockSource
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.resetStockSource = "";

                    /**
                     * StockTrace changeStockAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} changeStockAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.changeStockAt = null;

                    /**
                     * StockTrace changeStockSource.
                     * @member {string} changeStockSource
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.changeStockSource = "";

                    /**
                     * StockTrace beforeChangeStock.
                     * @member {number|Long} beforeChangeStock
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.beforeChangeStock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace recentSellAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} recentSellAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentSellAt = null;

                    /**
                     * StockTrace recentSellCode.
                     * @member {string} recentSellCode
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentSellCode = "";

                    /**
                     * StockTrace recentSellSource.
                     * @member {string} recentSellSource
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentSellSource = "";

                    /**
                     * StockTrace recentSellQuantity.
                     * @member {number|Long} recentSellQuantity
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentSellQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace recentCancelAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} recentCancelAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCancelAt = null;

                    /**
                     * StockTrace recentCancelCode.
                     * @member {string} recentCancelCode
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCancelCode = "";

                    /**
                     * StockTrace recentCancelSource.
                     * @member {string} recentCancelSource
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCancelSource = "";

                    /**
                     * StockTrace recentCancelQuantity.
                     * @member {number|Long} recentCancelQuantity
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCancelQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace recentCommitAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} recentCommitAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCommitAt = null;

                    /**
                     * StockTrace recentCommitCode.
                     * @member {string} recentCommitCode
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCommitCode = "";

                    /**
                     * StockTrace recentCommitSource.
                     * @member {string} recentCommitSource
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCommitSource = "";

                    /**
                     * StockTrace recentCommitQuantity.
                     * @member {number|Long} recentCommitQuantity
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.recentCommitQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace lastStock.
                     * @member {number|Long} lastStock
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.lastStock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace lastResetStockAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} lastResetStockAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.lastResetStockAt = null;

                    /**
                     * StockTrace lastResetStockSource.
                     * @member {string} lastResetStockSource
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.lastResetStockSource = "";

                    /**
                     * StockTrace sellCountWhenReset.
                     * @member {number|Long} sellCountWhenReset
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.sellCountWhenReset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace committedStockWhenReset.
                     * @member {number|Long} committedStockWhenReset
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.committedStockWhenReset = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * StockTrace createdAt.
                     * @member {google.protobuf.ITimestamp|null|undefined} createdAt
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     */
                    StockTrace.prototype.createdAt = null;

                    /**
                     * Creates a new StockTrace instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {theplant.ec.service.inventory.IStockTrace=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StockTrace} StockTrace instance
                     */
                    StockTrace.create = function create(properties) {
                        return new StockTrace(properties);
                    };

                    /**
                     * Encodes the specified StockTrace message. Does not implicitly {@link theplant.ec.service.inventory.StockTrace.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {theplant.ec.service.inventory.IStockTrace} message StockTrace message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockTrace.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.stock);
                        if (message.sellQuantity != null && message.hasOwnProperty("sellQuantity"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.sellQuantity);
                        if (message.committedStock != null && message.hasOwnProperty("committedStock"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.committedStock);
                        if (message.resetStockAt != null && message.hasOwnProperty("resetStockAt"))
                            $root.google.protobuf.Timestamp.encode(message.resetStockAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.resetStockSource != null && message.hasOwnProperty("resetStockSource"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.resetStockSource);
                        if (message.changeStockAt != null && message.hasOwnProperty("changeStockAt"))
                            $root.google.protobuf.Timestamp.encode(message.changeStockAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.changeStockSource != null && message.hasOwnProperty("changeStockSource"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.changeStockSource);
                        if (message.beforeChangeStock != null && message.hasOwnProperty("beforeChangeStock"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.beforeChangeStock);
                        if (message.recentSellAt != null && message.hasOwnProperty("recentSellAt"))
                            $root.google.protobuf.Timestamp.encode(message.recentSellAt, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.recentSellCode != null && message.hasOwnProperty("recentSellCode"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.recentSellCode);
                        if (message.recentSellSource != null && message.hasOwnProperty("recentSellSource"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.recentSellSource);
                        if (message.recentSellQuantity != null && message.hasOwnProperty("recentSellQuantity"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.recentSellQuantity);
                        if (message.recentCancelAt != null && message.hasOwnProperty("recentCancelAt"))
                            $root.google.protobuf.Timestamp.encode(message.recentCancelAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.recentCancelCode != null && message.hasOwnProperty("recentCancelCode"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.recentCancelCode);
                        if (message.recentCancelSource != null && message.hasOwnProperty("recentCancelSource"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.recentCancelSource);
                        if (message.recentCancelQuantity != null && message.hasOwnProperty("recentCancelQuantity"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int64(message.recentCancelQuantity);
                        if (message.recentCommitAt != null && message.hasOwnProperty("recentCommitAt"))
                            $root.google.protobuf.Timestamp.encode(message.recentCommitAt, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.recentCommitCode != null && message.hasOwnProperty("recentCommitCode"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.recentCommitCode);
                        if (message.recentCommitSource != null && message.hasOwnProperty("recentCommitSource"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.recentCommitSource);
                        if (message.recentCommitQuantity != null && message.hasOwnProperty("recentCommitQuantity"))
                            writer.uint32(/* id 20, wireType 0 =*/160).int64(message.recentCommitQuantity);
                        if (message.lastStock != null && message.hasOwnProperty("lastStock"))
                            writer.uint32(/* id 21, wireType 0 =*/168).int64(message.lastStock);
                        if (message.lastResetStockAt != null && message.hasOwnProperty("lastResetStockAt"))
                            $root.google.protobuf.Timestamp.encode(message.lastResetStockAt, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                        if (message.lastResetStockSource != null && message.hasOwnProperty("lastResetStockSource"))
                            writer.uint32(/* id 23, wireType 2 =*/186).string(message.lastResetStockSource);
                        if (message.sellCountWhenReset != null && message.hasOwnProperty("sellCountWhenReset"))
                            writer.uint32(/* id 24, wireType 0 =*/192).int64(message.sellCountWhenReset);
                        if (message.committedStockWhenReset != null && message.hasOwnProperty("committedStockWhenReset"))
                            writer.uint32(/* id 25, wireType 0 =*/200).int64(message.committedStockWhenReset);
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            $root.google.protobuf.Timestamp.encode(message.createdAt, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified StockTrace message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StockTrace.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {theplant.ec.service.inventory.IStockTrace} message StockTrace message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StockTrace.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StockTrace message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StockTrace} StockTrace
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockTrace.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StockTrace();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stock = reader.int64();
                                break;
                            case 2:
                                message.sellQuantity = reader.int64();
                                break;
                            case 3:
                                message.committedStock = reader.int64();
                                break;
                            case 4:
                                message.resetStockAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.resetStockSource = reader.string();
                                break;
                            case 6:
                                message.changeStockAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 7:
                                message.changeStockSource = reader.string();
                                break;
                            case 8:
                                message.beforeChangeStock = reader.int64();
                                break;
                            case 9:
                                message.recentSellAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 10:
                                message.recentSellCode = reader.string();
                                break;
                            case 11:
                                message.recentSellSource = reader.string();
                                break;
                            case 12:
                                message.recentSellQuantity = reader.int64();
                                break;
                            case 13:
                                message.recentCancelAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.recentCancelCode = reader.string();
                                break;
                            case 15:
                                message.recentCancelSource = reader.string();
                                break;
                            case 16:
                                message.recentCancelQuantity = reader.int64();
                                break;
                            case 17:
                                message.recentCommitAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 18:
                                message.recentCommitCode = reader.string();
                                break;
                            case 19:
                                message.recentCommitSource = reader.string();
                                break;
                            case 20:
                                message.recentCommitQuantity = reader.int64();
                                break;
                            case 21:
                                message.lastStock = reader.int64();
                                break;
                            case 22:
                                message.lastResetStockAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            case 23:
                                message.lastResetStockSource = reader.string();
                                break;
                            case 24:
                                message.sellCountWhenReset = reader.int64();
                                break;
                            case 25:
                                message.committedStockWhenReset = reader.int64();
                                break;
                            case 27:
                                message.createdAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StockTrace message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StockTrace} StockTrace
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StockTrace.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StockTrace message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StockTrace.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (!$util.isInteger(message.stock) && !(message.stock && $util.isInteger(message.stock.low) && $util.isInteger(message.stock.high)))
                                return "stock: integer|Long expected";
                        if (message.sellQuantity != null && message.hasOwnProperty("sellQuantity"))
                            if (!$util.isInteger(message.sellQuantity) && !(message.sellQuantity && $util.isInteger(message.sellQuantity.low) && $util.isInteger(message.sellQuantity.high)))
                                return "sellQuantity: integer|Long expected";
                        if (message.committedStock != null && message.hasOwnProperty("committedStock"))
                            if (!$util.isInteger(message.committedStock) && !(message.committedStock && $util.isInteger(message.committedStock.low) && $util.isInteger(message.committedStock.high)))
                                return "committedStock: integer|Long expected";
                        if (message.resetStockAt != null && message.hasOwnProperty("resetStockAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.resetStockAt);
                            if (error)
                                return "resetStockAt." + error;
                        }
                        if (message.resetStockSource != null && message.hasOwnProperty("resetStockSource"))
                            if (!$util.isString(message.resetStockSource))
                                return "resetStockSource: string expected";
                        if (message.changeStockAt != null && message.hasOwnProperty("changeStockAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.changeStockAt);
                            if (error)
                                return "changeStockAt." + error;
                        }
                        if (message.changeStockSource != null && message.hasOwnProperty("changeStockSource"))
                            if (!$util.isString(message.changeStockSource))
                                return "changeStockSource: string expected";
                        if (message.beforeChangeStock != null && message.hasOwnProperty("beforeChangeStock"))
                            if (!$util.isInteger(message.beforeChangeStock) && !(message.beforeChangeStock && $util.isInteger(message.beforeChangeStock.low) && $util.isInteger(message.beforeChangeStock.high)))
                                return "beforeChangeStock: integer|Long expected";
                        if (message.recentSellAt != null && message.hasOwnProperty("recentSellAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.recentSellAt);
                            if (error)
                                return "recentSellAt." + error;
                        }
                        if (message.recentSellCode != null && message.hasOwnProperty("recentSellCode"))
                            if (!$util.isString(message.recentSellCode))
                                return "recentSellCode: string expected";
                        if (message.recentSellSource != null && message.hasOwnProperty("recentSellSource"))
                            if (!$util.isString(message.recentSellSource))
                                return "recentSellSource: string expected";
                        if (message.recentSellQuantity != null && message.hasOwnProperty("recentSellQuantity"))
                            if (!$util.isInteger(message.recentSellQuantity) && !(message.recentSellQuantity && $util.isInteger(message.recentSellQuantity.low) && $util.isInteger(message.recentSellQuantity.high)))
                                return "recentSellQuantity: integer|Long expected";
                        if (message.recentCancelAt != null && message.hasOwnProperty("recentCancelAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.recentCancelAt);
                            if (error)
                                return "recentCancelAt." + error;
                        }
                        if (message.recentCancelCode != null && message.hasOwnProperty("recentCancelCode"))
                            if (!$util.isString(message.recentCancelCode))
                                return "recentCancelCode: string expected";
                        if (message.recentCancelSource != null && message.hasOwnProperty("recentCancelSource"))
                            if (!$util.isString(message.recentCancelSource))
                                return "recentCancelSource: string expected";
                        if (message.recentCancelQuantity != null && message.hasOwnProperty("recentCancelQuantity"))
                            if (!$util.isInteger(message.recentCancelQuantity) && !(message.recentCancelQuantity && $util.isInteger(message.recentCancelQuantity.low) && $util.isInteger(message.recentCancelQuantity.high)))
                                return "recentCancelQuantity: integer|Long expected";
                        if (message.recentCommitAt != null && message.hasOwnProperty("recentCommitAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.recentCommitAt);
                            if (error)
                                return "recentCommitAt." + error;
                        }
                        if (message.recentCommitCode != null && message.hasOwnProperty("recentCommitCode"))
                            if (!$util.isString(message.recentCommitCode))
                                return "recentCommitCode: string expected";
                        if (message.recentCommitSource != null && message.hasOwnProperty("recentCommitSource"))
                            if (!$util.isString(message.recentCommitSource))
                                return "recentCommitSource: string expected";
                        if (message.recentCommitQuantity != null && message.hasOwnProperty("recentCommitQuantity"))
                            if (!$util.isInteger(message.recentCommitQuantity) && !(message.recentCommitQuantity && $util.isInteger(message.recentCommitQuantity.low) && $util.isInteger(message.recentCommitQuantity.high)))
                                return "recentCommitQuantity: integer|Long expected";
                        if (message.lastStock != null && message.hasOwnProperty("lastStock"))
                            if (!$util.isInteger(message.lastStock) && !(message.lastStock && $util.isInteger(message.lastStock.low) && $util.isInteger(message.lastStock.high)))
                                return "lastStock: integer|Long expected";
                        if (message.lastResetStockAt != null && message.hasOwnProperty("lastResetStockAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.lastResetStockAt);
                            if (error)
                                return "lastResetStockAt." + error;
                        }
                        if (message.lastResetStockSource != null && message.hasOwnProperty("lastResetStockSource"))
                            if (!$util.isString(message.lastResetStockSource))
                                return "lastResetStockSource: string expected";
                        if (message.sellCountWhenReset != null && message.hasOwnProperty("sellCountWhenReset"))
                            if (!$util.isInteger(message.sellCountWhenReset) && !(message.sellCountWhenReset && $util.isInteger(message.sellCountWhenReset.low) && $util.isInteger(message.sellCountWhenReset.high)))
                                return "sellCountWhenReset: integer|Long expected";
                        if (message.committedStockWhenReset != null && message.hasOwnProperty("committedStockWhenReset"))
                            if (!$util.isInteger(message.committedStockWhenReset) && !(message.committedStockWhenReset && $util.isInteger(message.committedStockWhenReset.low) && $util.isInteger(message.committedStockWhenReset.high)))
                                return "committedStockWhenReset: integer|Long expected";
                        if (message.createdAt != null && message.hasOwnProperty("createdAt")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.createdAt);
                            if (error)
                                return "createdAt." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a StockTrace message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StockTrace} StockTrace
                     */
                    StockTrace.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StockTrace)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StockTrace();
                        if (object.stock != null)
                            if ($util.Long)
                                (message.stock = $util.Long.fromValue(object.stock)).unsigned = false;
                            else if (typeof object.stock === "string")
                                message.stock = parseInt(object.stock, 10);
                            else if (typeof object.stock === "number")
                                message.stock = object.stock;
                            else if (typeof object.stock === "object")
                                message.stock = new $util.LongBits(object.stock.low >>> 0, object.stock.high >>> 0).toNumber();
                        if (object.sellQuantity != null)
                            if ($util.Long)
                                (message.sellQuantity = $util.Long.fromValue(object.sellQuantity)).unsigned = false;
                            else if (typeof object.sellQuantity === "string")
                                message.sellQuantity = parseInt(object.sellQuantity, 10);
                            else if (typeof object.sellQuantity === "number")
                                message.sellQuantity = object.sellQuantity;
                            else if (typeof object.sellQuantity === "object")
                                message.sellQuantity = new $util.LongBits(object.sellQuantity.low >>> 0, object.sellQuantity.high >>> 0).toNumber();
                        if (object.committedStock != null)
                            if ($util.Long)
                                (message.committedStock = $util.Long.fromValue(object.committedStock)).unsigned = false;
                            else if (typeof object.committedStock === "string")
                                message.committedStock = parseInt(object.committedStock, 10);
                            else if (typeof object.committedStock === "number")
                                message.committedStock = object.committedStock;
                            else if (typeof object.committedStock === "object")
                                message.committedStock = new $util.LongBits(object.committedStock.low >>> 0, object.committedStock.high >>> 0).toNumber();
                        if (object.resetStockAt != null) {
                            if (typeof object.resetStockAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.resetStockAt: object expected");
                            message.resetStockAt = $root.google.protobuf.Timestamp.fromObject(object.resetStockAt);
                        }
                        if (object.resetStockSource != null)
                            message.resetStockSource = String(object.resetStockSource);
                        if (object.changeStockAt != null) {
                            if (typeof object.changeStockAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.changeStockAt: object expected");
                            message.changeStockAt = $root.google.protobuf.Timestamp.fromObject(object.changeStockAt);
                        }
                        if (object.changeStockSource != null)
                            message.changeStockSource = String(object.changeStockSource);
                        if (object.beforeChangeStock != null)
                            if ($util.Long)
                                (message.beforeChangeStock = $util.Long.fromValue(object.beforeChangeStock)).unsigned = false;
                            else if (typeof object.beforeChangeStock === "string")
                                message.beforeChangeStock = parseInt(object.beforeChangeStock, 10);
                            else if (typeof object.beforeChangeStock === "number")
                                message.beforeChangeStock = object.beforeChangeStock;
                            else if (typeof object.beforeChangeStock === "object")
                                message.beforeChangeStock = new $util.LongBits(object.beforeChangeStock.low >>> 0, object.beforeChangeStock.high >>> 0).toNumber();
                        if (object.recentSellAt != null) {
                            if (typeof object.recentSellAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.recentSellAt: object expected");
                            message.recentSellAt = $root.google.protobuf.Timestamp.fromObject(object.recentSellAt);
                        }
                        if (object.recentSellCode != null)
                            message.recentSellCode = String(object.recentSellCode);
                        if (object.recentSellSource != null)
                            message.recentSellSource = String(object.recentSellSource);
                        if (object.recentSellQuantity != null)
                            if ($util.Long)
                                (message.recentSellQuantity = $util.Long.fromValue(object.recentSellQuantity)).unsigned = false;
                            else if (typeof object.recentSellQuantity === "string")
                                message.recentSellQuantity = parseInt(object.recentSellQuantity, 10);
                            else if (typeof object.recentSellQuantity === "number")
                                message.recentSellQuantity = object.recentSellQuantity;
                            else if (typeof object.recentSellQuantity === "object")
                                message.recentSellQuantity = new $util.LongBits(object.recentSellQuantity.low >>> 0, object.recentSellQuantity.high >>> 0).toNumber();
                        if (object.recentCancelAt != null) {
                            if (typeof object.recentCancelAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.recentCancelAt: object expected");
                            message.recentCancelAt = $root.google.protobuf.Timestamp.fromObject(object.recentCancelAt);
                        }
                        if (object.recentCancelCode != null)
                            message.recentCancelCode = String(object.recentCancelCode);
                        if (object.recentCancelSource != null)
                            message.recentCancelSource = String(object.recentCancelSource);
                        if (object.recentCancelQuantity != null)
                            if ($util.Long)
                                (message.recentCancelQuantity = $util.Long.fromValue(object.recentCancelQuantity)).unsigned = false;
                            else if (typeof object.recentCancelQuantity === "string")
                                message.recentCancelQuantity = parseInt(object.recentCancelQuantity, 10);
                            else if (typeof object.recentCancelQuantity === "number")
                                message.recentCancelQuantity = object.recentCancelQuantity;
                            else if (typeof object.recentCancelQuantity === "object")
                                message.recentCancelQuantity = new $util.LongBits(object.recentCancelQuantity.low >>> 0, object.recentCancelQuantity.high >>> 0).toNumber();
                        if (object.recentCommitAt != null) {
                            if (typeof object.recentCommitAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.recentCommitAt: object expected");
                            message.recentCommitAt = $root.google.protobuf.Timestamp.fromObject(object.recentCommitAt);
                        }
                        if (object.recentCommitCode != null)
                            message.recentCommitCode = String(object.recentCommitCode);
                        if (object.recentCommitSource != null)
                            message.recentCommitSource = String(object.recentCommitSource);
                        if (object.recentCommitQuantity != null)
                            if ($util.Long)
                                (message.recentCommitQuantity = $util.Long.fromValue(object.recentCommitQuantity)).unsigned = false;
                            else if (typeof object.recentCommitQuantity === "string")
                                message.recentCommitQuantity = parseInt(object.recentCommitQuantity, 10);
                            else if (typeof object.recentCommitQuantity === "number")
                                message.recentCommitQuantity = object.recentCommitQuantity;
                            else if (typeof object.recentCommitQuantity === "object")
                                message.recentCommitQuantity = new $util.LongBits(object.recentCommitQuantity.low >>> 0, object.recentCommitQuantity.high >>> 0).toNumber();
                        if (object.lastStock != null)
                            if ($util.Long)
                                (message.lastStock = $util.Long.fromValue(object.lastStock)).unsigned = false;
                            else if (typeof object.lastStock === "string")
                                message.lastStock = parseInt(object.lastStock, 10);
                            else if (typeof object.lastStock === "number")
                                message.lastStock = object.lastStock;
                            else if (typeof object.lastStock === "object")
                                message.lastStock = new $util.LongBits(object.lastStock.low >>> 0, object.lastStock.high >>> 0).toNumber();
                        if (object.lastResetStockAt != null) {
                            if (typeof object.lastResetStockAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.lastResetStockAt: object expected");
                            message.lastResetStockAt = $root.google.protobuf.Timestamp.fromObject(object.lastResetStockAt);
                        }
                        if (object.lastResetStockSource != null)
                            message.lastResetStockSource = String(object.lastResetStockSource);
                        if (object.sellCountWhenReset != null)
                            if ($util.Long)
                                (message.sellCountWhenReset = $util.Long.fromValue(object.sellCountWhenReset)).unsigned = false;
                            else if (typeof object.sellCountWhenReset === "string")
                                message.sellCountWhenReset = parseInt(object.sellCountWhenReset, 10);
                            else if (typeof object.sellCountWhenReset === "number")
                                message.sellCountWhenReset = object.sellCountWhenReset;
                            else if (typeof object.sellCountWhenReset === "object")
                                message.sellCountWhenReset = new $util.LongBits(object.sellCountWhenReset.low >>> 0, object.sellCountWhenReset.high >>> 0).toNumber();
                        if (object.committedStockWhenReset != null)
                            if ($util.Long)
                                (message.committedStockWhenReset = $util.Long.fromValue(object.committedStockWhenReset)).unsigned = false;
                            else if (typeof object.committedStockWhenReset === "string")
                                message.committedStockWhenReset = parseInt(object.committedStockWhenReset, 10);
                            else if (typeof object.committedStockWhenReset === "number")
                                message.committedStockWhenReset = object.committedStockWhenReset;
                            else if (typeof object.committedStockWhenReset === "object")
                                message.committedStockWhenReset = new $util.LongBits(object.committedStockWhenReset.low >>> 0, object.committedStockWhenReset.high >>> 0).toNumber();
                        if (object.createdAt != null) {
                            if (typeof object.createdAt !== "object")
                                throw TypeError(".theplant.ec.service.inventory.StockTrace.createdAt: object expected");
                            message.createdAt = $root.google.protobuf.Timestamp.fromObject(object.createdAt);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a StockTrace message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @static
                     * @param {theplant.ec.service.inventory.StockTrace} message StockTrace
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StockTrace.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.stock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.stock = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sellQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sellQuantity = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.committedStock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.committedStock = options.longs === String ? "0" : 0;
                            object.resetStockAt = null;
                            object.resetStockSource = "";
                            object.changeStockAt = null;
                            object.changeStockSource = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.beforeChangeStock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.beforeChangeStock = options.longs === String ? "0" : 0;
                            object.recentSellAt = null;
                            object.recentSellCode = "";
                            object.recentSellSource = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.recentSellQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.recentSellQuantity = options.longs === String ? "0" : 0;
                            object.recentCancelAt = null;
                            object.recentCancelCode = "";
                            object.recentCancelSource = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.recentCancelQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.recentCancelQuantity = options.longs === String ? "0" : 0;
                            object.recentCommitAt = null;
                            object.recentCommitCode = "";
                            object.recentCommitSource = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.recentCommitQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.recentCommitQuantity = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.lastStock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.lastStock = options.longs === String ? "0" : 0;
                            object.lastResetStockAt = null;
                            object.lastResetStockSource = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sellCountWhenReset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sellCountWhenReset = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.committedStockWhenReset = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.committedStockWhenReset = options.longs === String ? "0" : 0;
                            object.createdAt = null;
                        }
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (typeof message.stock === "number")
                                object.stock = options.longs === String ? String(message.stock) : message.stock;
                            else
                                object.stock = options.longs === String ? $util.Long.prototype.toString.call(message.stock) : options.longs === Number ? new $util.LongBits(message.stock.low >>> 0, message.stock.high >>> 0).toNumber() : message.stock;
                        if (message.sellQuantity != null && message.hasOwnProperty("sellQuantity"))
                            if (typeof message.sellQuantity === "number")
                                object.sellQuantity = options.longs === String ? String(message.sellQuantity) : message.sellQuantity;
                            else
                                object.sellQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.sellQuantity) : options.longs === Number ? new $util.LongBits(message.sellQuantity.low >>> 0, message.sellQuantity.high >>> 0).toNumber() : message.sellQuantity;
                        if (message.committedStock != null && message.hasOwnProperty("committedStock"))
                            if (typeof message.committedStock === "number")
                                object.committedStock = options.longs === String ? String(message.committedStock) : message.committedStock;
                            else
                                object.committedStock = options.longs === String ? $util.Long.prototype.toString.call(message.committedStock) : options.longs === Number ? new $util.LongBits(message.committedStock.low >>> 0, message.committedStock.high >>> 0).toNumber() : message.committedStock;
                        if (message.resetStockAt != null && message.hasOwnProperty("resetStockAt"))
                            object.resetStockAt = $root.google.protobuf.Timestamp.toObject(message.resetStockAt, options);
                        if (message.resetStockSource != null && message.hasOwnProperty("resetStockSource"))
                            object.resetStockSource = message.resetStockSource;
                        if (message.changeStockAt != null && message.hasOwnProperty("changeStockAt"))
                            object.changeStockAt = $root.google.protobuf.Timestamp.toObject(message.changeStockAt, options);
                        if (message.changeStockSource != null && message.hasOwnProperty("changeStockSource"))
                            object.changeStockSource = message.changeStockSource;
                        if (message.beforeChangeStock != null && message.hasOwnProperty("beforeChangeStock"))
                            if (typeof message.beforeChangeStock === "number")
                                object.beforeChangeStock = options.longs === String ? String(message.beforeChangeStock) : message.beforeChangeStock;
                            else
                                object.beforeChangeStock = options.longs === String ? $util.Long.prototype.toString.call(message.beforeChangeStock) : options.longs === Number ? new $util.LongBits(message.beforeChangeStock.low >>> 0, message.beforeChangeStock.high >>> 0).toNumber() : message.beforeChangeStock;
                        if (message.recentSellAt != null && message.hasOwnProperty("recentSellAt"))
                            object.recentSellAt = $root.google.protobuf.Timestamp.toObject(message.recentSellAt, options);
                        if (message.recentSellCode != null && message.hasOwnProperty("recentSellCode"))
                            object.recentSellCode = message.recentSellCode;
                        if (message.recentSellSource != null && message.hasOwnProperty("recentSellSource"))
                            object.recentSellSource = message.recentSellSource;
                        if (message.recentSellQuantity != null && message.hasOwnProperty("recentSellQuantity"))
                            if (typeof message.recentSellQuantity === "number")
                                object.recentSellQuantity = options.longs === String ? String(message.recentSellQuantity) : message.recentSellQuantity;
                            else
                                object.recentSellQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.recentSellQuantity) : options.longs === Number ? new $util.LongBits(message.recentSellQuantity.low >>> 0, message.recentSellQuantity.high >>> 0).toNumber() : message.recentSellQuantity;
                        if (message.recentCancelAt != null && message.hasOwnProperty("recentCancelAt"))
                            object.recentCancelAt = $root.google.protobuf.Timestamp.toObject(message.recentCancelAt, options);
                        if (message.recentCancelCode != null && message.hasOwnProperty("recentCancelCode"))
                            object.recentCancelCode = message.recentCancelCode;
                        if (message.recentCancelSource != null && message.hasOwnProperty("recentCancelSource"))
                            object.recentCancelSource = message.recentCancelSource;
                        if (message.recentCancelQuantity != null && message.hasOwnProperty("recentCancelQuantity"))
                            if (typeof message.recentCancelQuantity === "number")
                                object.recentCancelQuantity = options.longs === String ? String(message.recentCancelQuantity) : message.recentCancelQuantity;
                            else
                                object.recentCancelQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.recentCancelQuantity) : options.longs === Number ? new $util.LongBits(message.recentCancelQuantity.low >>> 0, message.recentCancelQuantity.high >>> 0).toNumber() : message.recentCancelQuantity;
                        if (message.recentCommitAt != null && message.hasOwnProperty("recentCommitAt"))
                            object.recentCommitAt = $root.google.protobuf.Timestamp.toObject(message.recentCommitAt, options);
                        if (message.recentCommitCode != null && message.hasOwnProperty("recentCommitCode"))
                            object.recentCommitCode = message.recentCommitCode;
                        if (message.recentCommitSource != null && message.hasOwnProperty("recentCommitSource"))
                            object.recentCommitSource = message.recentCommitSource;
                        if (message.recentCommitQuantity != null && message.hasOwnProperty("recentCommitQuantity"))
                            if (typeof message.recentCommitQuantity === "number")
                                object.recentCommitQuantity = options.longs === String ? String(message.recentCommitQuantity) : message.recentCommitQuantity;
                            else
                                object.recentCommitQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.recentCommitQuantity) : options.longs === Number ? new $util.LongBits(message.recentCommitQuantity.low >>> 0, message.recentCommitQuantity.high >>> 0).toNumber() : message.recentCommitQuantity;
                        if (message.lastStock != null && message.hasOwnProperty("lastStock"))
                            if (typeof message.lastStock === "number")
                                object.lastStock = options.longs === String ? String(message.lastStock) : message.lastStock;
                            else
                                object.lastStock = options.longs === String ? $util.Long.prototype.toString.call(message.lastStock) : options.longs === Number ? new $util.LongBits(message.lastStock.low >>> 0, message.lastStock.high >>> 0).toNumber() : message.lastStock;
                        if (message.lastResetStockAt != null && message.hasOwnProperty("lastResetStockAt"))
                            object.lastResetStockAt = $root.google.protobuf.Timestamp.toObject(message.lastResetStockAt, options);
                        if (message.lastResetStockSource != null && message.hasOwnProperty("lastResetStockSource"))
                            object.lastResetStockSource = message.lastResetStockSource;
                        if (message.sellCountWhenReset != null && message.hasOwnProperty("sellCountWhenReset"))
                            if (typeof message.sellCountWhenReset === "number")
                                object.sellCountWhenReset = options.longs === String ? String(message.sellCountWhenReset) : message.sellCountWhenReset;
                            else
                                object.sellCountWhenReset = options.longs === String ? $util.Long.prototype.toString.call(message.sellCountWhenReset) : options.longs === Number ? new $util.LongBits(message.sellCountWhenReset.low >>> 0, message.sellCountWhenReset.high >>> 0).toNumber() : message.sellCountWhenReset;
                        if (message.committedStockWhenReset != null && message.hasOwnProperty("committedStockWhenReset"))
                            if (typeof message.committedStockWhenReset === "number")
                                object.committedStockWhenReset = options.longs === String ? String(message.committedStockWhenReset) : message.committedStockWhenReset;
                            else
                                object.committedStockWhenReset = options.longs === String ? $util.Long.prototype.toString.call(message.committedStockWhenReset) : options.longs === Number ? new $util.LongBits(message.committedStockWhenReset.low >>> 0, message.committedStockWhenReset.high >>> 0).toNumber() : message.committedStockWhenReset;
                        if (message.createdAt != null && message.hasOwnProperty("createdAt"))
                            object.createdAt = $root.google.protobuf.Timestamp.toObject(message.createdAt, options);
                        return object;
                    };

                    /**
                     * Converts this StockTrace to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StockTrace
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StockTrace.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StockTrace;
                })();

                inventory.DiffSoldStocks = (function() {

                    /**
                     * Properties of a DiffSoldStocks.
                     * @memberof theplant.ec.service.inventory
                     * @interface IDiffSoldStocks
                     * @property {Array.<theplant.ec.service.inventory.IDiffSoldStock>|null} [diffs] DiffSoldStocks diffs
                     * @property {google.protobuf.ITimestamp|null} [when] DiffSoldStocks when
                     */

                    /**
                     * Constructs a new DiffSoldStocks.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a DiffSoldStocks.
                     * @implements IDiffSoldStocks
                     * @constructor
                     * @param {theplant.ec.service.inventory.IDiffSoldStocks=} [properties] Properties to set
                     */
                    function DiffSoldStocks(properties) {
                        this.diffs = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DiffSoldStocks diffs.
                     * @member {Array.<theplant.ec.service.inventory.IDiffSoldStock>} diffs
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @instance
                     */
                    DiffSoldStocks.prototype.diffs = $util.emptyArray;

                    /**
                     * DiffSoldStocks when.
                     * @member {google.protobuf.ITimestamp|null|undefined} when
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @instance
                     */
                    DiffSoldStocks.prototype.when = null;

                    /**
                     * Creates a new DiffSoldStocks instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {theplant.ec.service.inventory.IDiffSoldStocks=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.DiffSoldStocks} DiffSoldStocks instance
                     */
                    DiffSoldStocks.create = function create(properties) {
                        return new DiffSoldStocks(properties);
                    };

                    /**
                     * Encodes the specified DiffSoldStocks message. Does not implicitly {@link theplant.ec.service.inventory.DiffSoldStocks.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {theplant.ec.service.inventory.IDiffSoldStocks} message DiffSoldStocks message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiffSoldStocks.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.diffs != null && message.diffs.length)
                            for (var i = 0; i < message.diffs.length; ++i)
                                $root.theplant.ec.service.inventory.DiffSoldStock.encode(message.diffs[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.when != null && message.hasOwnProperty("when"))
                            $root.google.protobuf.Timestamp.encode(message.when, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified DiffSoldStocks message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.DiffSoldStocks.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {theplant.ec.service.inventory.IDiffSoldStocks} message DiffSoldStocks message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiffSoldStocks.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DiffSoldStocks message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.DiffSoldStocks} DiffSoldStocks
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiffSoldStocks.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.DiffSoldStocks();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.diffs && message.diffs.length))
                                    message.diffs = [];
                                message.diffs.push($root.theplant.ec.service.inventory.DiffSoldStock.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                message.when = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DiffSoldStocks message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.DiffSoldStocks} DiffSoldStocks
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiffSoldStocks.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DiffSoldStocks message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DiffSoldStocks.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.diffs != null && message.hasOwnProperty("diffs")) {
                            if (!Array.isArray(message.diffs))
                                return "diffs: array expected";
                            for (var i = 0; i < message.diffs.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.DiffSoldStock.verify(message.diffs[i]);
                                if (error)
                                    return "diffs." + error;
                            }
                        }
                        if (message.when != null && message.hasOwnProperty("when")) {
                            var error = $root.google.protobuf.Timestamp.verify(message.when);
                            if (error)
                                return "when." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a DiffSoldStocks message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.DiffSoldStocks} DiffSoldStocks
                     */
                    DiffSoldStocks.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.DiffSoldStocks)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.DiffSoldStocks();
                        if (object.diffs) {
                            if (!Array.isArray(object.diffs))
                                throw TypeError(".theplant.ec.service.inventory.DiffSoldStocks.diffs: array expected");
                            message.diffs = [];
                            for (var i = 0; i < object.diffs.length; ++i) {
                                if (typeof object.diffs[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.DiffSoldStocks.diffs: object expected");
                                message.diffs[i] = $root.theplant.ec.service.inventory.DiffSoldStock.fromObject(object.diffs[i]);
                            }
                        }
                        if (object.when != null) {
                            if (typeof object.when !== "object")
                                throw TypeError(".theplant.ec.service.inventory.DiffSoldStocks.when: object expected");
                            message.when = $root.google.protobuf.Timestamp.fromObject(object.when);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a DiffSoldStocks message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @static
                     * @param {theplant.ec.service.inventory.DiffSoldStocks} message DiffSoldStocks
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DiffSoldStocks.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.diffs = [];
                        if (options.defaults)
                            object.when = null;
                        if (message.diffs && message.diffs.length) {
                            object.diffs = [];
                            for (var j = 0; j < message.diffs.length; ++j)
                                object.diffs[j] = $root.theplant.ec.service.inventory.DiffSoldStock.toObject(message.diffs[j], options);
                        }
                        if (message.when != null && message.hasOwnProperty("when"))
                            object.when = $root.google.protobuf.Timestamp.toObject(message.when, options);
                        return object;
                    };

                    /**
                     * Converts this DiffSoldStocks to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.DiffSoldStocks
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DiffSoldStocks.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DiffSoldStocks;
                })();

                inventory.DiffSoldStock = (function() {

                    /**
                     * Properties of a DiffSoldStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IDiffSoldStock
                     * @property {theplant.ec.service.inventory.IStock|null} [stock] DiffSoldStock stock
                     * @property {Array.<theplant.ec.service.inventory.ISoldStockOrder>|null} [orders] DiffSoldStock orders
                     * @property {string|null} [msg] DiffSoldStock msg
                     */

                    /**
                     * Constructs a new DiffSoldStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a DiffSoldStock.
                     * @implements IDiffSoldStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IDiffSoldStock=} [properties] Properties to set
                     */
                    function DiffSoldStock(properties) {
                        this.orders = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * DiffSoldStock stock.
                     * @member {theplant.ec.service.inventory.IStock|null|undefined} stock
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @instance
                     */
                    DiffSoldStock.prototype.stock = null;

                    /**
                     * DiffSoldStock orders.
                     * @member {Array.<theplant.ec.service.inventory.ISoldStockOrder>} orders
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @instance
                     */
                    DiffSoldStock.prototype.orders = $util.emptyArray;

                    /**
                     * DiffSoldStock msg.
                     * @member {string} msg
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @instance
                     */
                    DiffSoldStock.prototype.msg = "";

                    /**
                     * Creates a new DiffSoldStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.IDiffSoldStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.DiffSoldStock} DiffSoldStock instance
                     */
                    DiffSoldStock.create = function create(properties) {
                        return new DiffSoldStock(properties);
                    };

                    /**
                     * Encodes the specified DiffSoldStock message. Does not implicitly {@link theplant.ec.service.inventory.DiffSoldStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.IDiffSoldStock} message DiffSoldStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiffSoldStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            $root.theplant.ec.service.inventory.Stock.encode(message.stock, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.orders != null && message.orders.length)
                            for (var i = 0; i < message.orders.length; ++i)
                                $root.theplant.ec.service.inventory.SoldStockOrder.encode(message.orders[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.msg != null && message.hasOwnProperty("msg"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.msg);
                        return writer;
                    };

                    /**
                     * Encodes the specified DiffSoldStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.DiffSoldStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.IDiffSoldStock} message DiffSoldStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    DiffSoldStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a DiffSoldStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.DiffSoldStock} DiffSoldStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiffSoldStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.DiffSoldStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.stock = $root.theplant.ec.service.inventory.Stock.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.orders && message.orders.length))
                                    message.orders = [];
                                message.orders.push($root.theplant.ec.service.inventory.SoldStockOrder.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.msg = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a DiffSoldStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.DiffSoldStock} DiffSoldStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    DiffSoldStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a DiffSoldStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    DiffSoldStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.stock != null && message.hasOwnProperty("stock")) {
                            var error = $root.theplant.ec.service.inventory.Stock.verify(message.stock);
                            if (error)
                                return "stock." + error;
                        }
                        if (message.orders != null && message.hasOwnProperty("orders")) {
                            if (!Array.isArray(message.orders))
                                return "orders: array expected";
                            for (var i = 0; i < message.orders.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.SoldStockOrder.verify(message.orders[i]);
                                if (error)
                                    return "orders." + error;
                            }
                        }
                        if (message.msg != null && message.hasOwnProperty("msg"))
                            if (!$util.isString(message.msg))
                                return "msg: string expected";
                        return null;
                    };

                    /**
                     * Creates a DiffSoldStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.DiffSoldStock} DiffSoldStock
                     */
                    DiffSoldStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.DiffSoldStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.DiffSoldStock();
                        if (object.stock != null) {
                            if (typeof object.stock !== "object")
                                throw TypeError(".theplant.ec.service.inventory.DiffSoldStock.stock: object expected");
                            message.stock = $root.theplant.ec.service.inventory.Stock.fromObject(object.stock);
                        }
                        if (object.orders) {
                            if (!Array.isArray(object.orders))
                                throw TypeError(".theplant.ec.service.inventory.DiffSoldStock.orders: array expected");
                            message.orders = [];
                            for (var i = 0; i < object.orders.length; ++i) {
                                if (typeof object.orders[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.DiffSoldStock.orders: object expected");
                                message.orders[i] = $root.theplant.ec.service.inventory.SoldStockOrder.fromObject(object.orders[i]);
                            }
                        }
                        if (object.msg != null)
                            message.msg = String(object.msg);
                        return message;
                    };

                    /**
                     * Creates a plain object from a DiffSoldStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @static
                     * @param {theplant.ec.service.inventory.DiffSoldStock} message DiffSoldStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    DiffSoldStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.orders = [];
                        if (options.defaults) {
                            object.stock = null;
                            object.msg = "";
                        }
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            object.stock = $root.theplant.ec.service.inventory.Stock.toObject(message.stock, options);
                        if (message.orders && message.orders.length) {
                            object.orders = [];
                            for (var j = 0; j < message.orders.length; ++j)
                                object.orders[j] = $root.theplant.ec.service.inventory.SoldStockOrder.toObject(message.orders[j], options);
                        }
                        if (message.msg != null && message.hasOwnProperty("msg"))
                            object.msg = message.msg;
                        return object;
                    };

                    /**
                     * Converts this DiffSoldStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.DiffSoldStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    DiffSoldStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return DiffSoldStock;
                })();

                inventory.StoreStock = (function() {

                    /**
                     * Properties of a StoreStock.
                     * @memberof theplant.ec.service.inventory
                     * @interface IStoreStock
                     * @property {string|null} [id] StoreStock id
                     * @property {string|null} [storeId] StoreStock storeId
                     * @property {string|null} [articleCode] StoreStock articleCode
                     * @property {number|Long|null} [stock] StoreStock stock
                     */

                    /**
                     * Constructs a new StoreStock.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a StoreStock.
                     * @implements IStoreStock
                     * @constructor
                     * @param {theplant.ec.service.inventory.IStoreStock=} [properties] Properties to set
                     */
                    function StoreStock(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * StoreStock id.
                     * @member {string} id
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @instance
                     */
                    StoreStock.prototype.id = "";

                    /**
                     * StoreStock storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @instance
                     */
                    StoreStock.prototype.storeId = "";

                    /**
                     * StoreStock articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @instance
                     */
                    StoreStock.prototype.articleCode = "";

                    /**
                     * StoreStock stock.
                     * @member {number|Long} stock
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @instance
                     */
                    StoreStock.prototype.stock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new StoreStock instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {theplant.ec.service.inventory.IStoreStock=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.StoreStock} StoreStock instance
                     */
                    StoreStock.create = function create(properties) {
                        return new StoreStock(properties);
                    };

                    /**
                     * Encodes the specified StoreStock message. Does not implicitly {@link theplant.ec.service.inventory.StoreStock.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {theplant.ec.service.inventory.IStoreStock} message StoreStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreStock.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.storeId);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.articleCode);
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.stock);
                        return writer;
                    };

                    /**
                     * Encodes the specified StoreStock message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.StoreStock.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {theplant.ec.service.inventory.IStoreStock} message StoreStock message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    StoreStock.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a StoreStock message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.StoreStock} StoreStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreStock.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.StoreStock();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.storeId = reader.string();
                                break;
                            case 3:
                                message.articleCode = reader.string();
                                break;
                            case 4:
                                message.stock = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a StoreStock message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.StoreStock} StoreStock
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    StoreStock.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a StoreStock message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    StoreStock.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (!$util.isInteger(message.stock) && !(message.stock && $util.isInteger(message.stock.low) && $util.isInteger(message.stock.high)))
                                return "stock: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a StoreStock message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.StoreStock} StoreStock
                     */
                    StoreStock.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.StoreStock)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.StoreStock();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.stock != null)
                            if ($util.Long)
                                (message.stock = $util.Long.fromValue(object.stock)).unsigned = false;
                            else if (typeof object.stock === "string")
                                message.stock = parseInt(object.stock, 10);
                            else if (typeof object.stock === "number")
                                message.stock = object.stock;
                            else if (typeof object.stock === "object")
                                message.stock = new $util.LongBits(object.stock.low >>> 0, object.stock.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a StoreStock message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @static
                     * @param {theplant.ec.service.inventory.StoreStock} message StoreStock
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    StoreStock.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.id = "";
                            object.storeId = "";
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.stock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.stock = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (typeof message.stock === "number")
                                object.stock = options.longs === String ? String(message.stock) : message.stock;
                            else
                                object.stock = options.longs === String ? $util.Long.prototype.toString.call(message.stock) : options.longs === Number ? new $util.LongBits(message.stock.low >>> 0, message.stock.high >>> 0).toNumber() : message.stock;
                        return object;
                    };

                    /**
                     * Converts this StoreStock to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.StoreStock
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    StoreStock.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return StoreStock;
                })();

                /**
                 * ShipFromStoreItemState enum.
                 * @name theplant.ec.service.inventory.ShipFromStoreItemState
                 * @enum {string}
                 * @property {number} SFS_ITEM_WAITING_FOR_STORE_CONFIRM=0 SFS_ITEM_WAITING_FOR_STORE_CONFIRM value
                 * @property {number} SFS_ITEM_CONFIRMED_BY_STORE=1 SFS_ITEM_CONFIRMED_BY_STORE value
                 * @property {number} SFS_ITEM_SHIPPING=2 SFS_ITEM_SHIPPING value
                 * @property {number} SFS_ITEM_SHIPPED=3 SFS_ITEM_SHIPPED value
                 * @property {number} SFS_ITEM_CANCELLED=4 SFS_ITEM_CANCELLED value
                 * @property {number} SFS_ITEM_CUSTOMER_CANCELLED=5 SFS_ITEM_CUSTOMER_CANCELLED value
                 * @property {number} SFS_ITEM_SOLD_OUT=6 SFS_ITEM_SOLD_OUT value
                 * @property {number} SFS_ITEM_RECEPTION_REFUSED=7 SFS_ITEM_RECEPTION_REFUSED value
                 * @property {number} SFS_ITEM_RETURNED=8 SFS_ITEM_RETURNED value
                 * @property {number} SFS_ITEM_REQUEST_RETURNED=9 SFS_ITEM_REQUEST_RETURNED value
                 */
                inventory.ShipFromStoreItemState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SFS_ITEM_WAITING_FOR_STORE_CONFIRM"] = 0;
                    values[valuesById[1] = "SFS_ITEM_CONFIRMED_BY_STORE"] = 1;
                    values[valuesById[2] = "SFS_ITEM_SHIPPING"] = 2;
                    values[valuesById[3] = "SFS_ITEM_SHIPPED"] = 3;
                    values[valuesById[4] = "SFS_ITEM_CANCELLED"] = 4;
                    values[valuesById[5] = "SFS_ITEM_CUSTOMER_CANCELLED"] = 5;
                    values[valuesById[6] = "SFS_ITEM_SOLD_OUT"] = 6;
                    values[valuesById[7] = "SFS_ITEM_RECEPTION_REFUSED"] = 7;
                    values[valuesById[8] = "SFS_ITEM_RETURNED"] = 8;
                    values[valuesById[9] = "SFS_ITEM_REQUEST_RETURNED"] = 9;
                    return values;
                })();

                inventory.ShipFromStore = (function() {

                    /**
                     * Properties of a ShipFromStore.
                     * @memberof theplant.ec.service.inventory
                     * @interface IShipFromStore
                     * @property {string|null} [orderCode] ShipFromStore orderCode
                     * @property {string|null} [articleCode] ShipFromStore articleCode
                     * @property {number|Long|null} [quantity] ShipFromStore quantity
                     * @property {string|null} [state] ShipFromStore state
                     * @property {string|null} [storeId] ShipFromStore storeId
                     * @property {string|null} [storeName] ShipFromStore storeName
                     * @property {string|null} [storePhone] ShipFromStore storePhone
                     * @property {string|null} [storeHistory] ShipFromStore storeHistory
                     * @property {string|null} [addressPrefecture] ShipFromStore addressPrefecture
                     * @property {string|null} [addressCity] ShipFromStore addressCity
                     * @property {string|null} [orderConfirmedAt] ShipFromStore orderConfirmedAt
                     * @property {string|null} [acceptedAt] ShipFromStore acceptedAt
                     * @property {string|null} [canceledAt] ShipFromStore canceledAt
                     * @property {string|null} [expiredAt] ShipFromStore expiredAt
                     * @property {string|null} [shippedAt] ShipFromStore shippedAt
                     * @property {string|null} [returnedAt] ShipFromStore returnedAt
                     * @property {string|null} [shippingAt] ShipFromStore shippingAt
                     * @property {number|Long|null} [priority] ShipFromStore priority
                     * @property {theplant.ec.service.inventory.IShipmentInfo|null} [shipmentInfo] ShipFromStore shipmentInfo
                     * @property {string|null} [cancelReason] ShipFromStore cancelReason
                     * @property {boolean|null} [isIncludeNovelty] ShipFromStore isIncludeNovelty
                     * @property {theplant.ec.service.inventory.INovelties|null} [Novelties] ShipFromStore Novelties
                     */

                    /**
                     * Constructs a new ShipFromStore.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a ShipFromStore.
                     * @implements IShipFromStore
                     * @constructor
                     * @param {theplant.ec.service.inventory.IShipFromStore=} [properties] Properties to set
                     */
                    function ShipFromStore(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShipFromStore orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.orderCode = "";

                    /**
                     * ShipFromStore articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.articleCode = "";

                    /**
                     * ShipFromStore quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStore state.
                     * @member {string} state
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.state = "";

                    /**
                     * ShipFromStore storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.storeId = "";

                    /**
                     * ShipFromStore storeName.
                     * @member {string} storeName
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.storeName = "";

                    /**
                     * ShipFromStore storePhone.
                     * @member {string} storePhone
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.storePhone = "";

                    /**
                     * ShipFromStore storeHistory.
                     * @member {string} storeHistory
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.storeHistory = "";

                    /**
                     * ShipFromStore addressPrefecture.
                     * @member {string} addressPrefecture
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.addressPrefecture = "";

                    /**
                     * ShipFromStore addressCity.
                     * @member {string} addressCity
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.addressCity = "";

                    /**
                     * ShipFromStore orderConfirmedAt.
                     * @member {string} orderConfirmedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.orderConfirmedAt = "";

                    /**
                     * ShipFromStore acceptedAt.
                     * @member {string} acceptedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.acceptedAt = "";

                    /**
                     * ShipFromStore canceledAt.
                     * @member {string} canceledAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.canceledAt = "";

                    /**
                     * ShipFromStore expiredAt.
                     * @member {string} expiredAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.expiredAt = "";

                    /**
                     * ShipFromStore shippedAt.
                     * @member {string} shippedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.shippedAt = "";

                    /**
                     * ShipFromStore returnedAt.
                     * @member {string} returnedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.returnedAt = "";

                    /**
                     * ShipFromStore shippingAt.
                     * @member {string} shippingAt
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.shippingAt = "";

                    /**
                     * ShipFromStore priority.
                     * @member {number|Long} priority
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.priority = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStore shipmentInfo.
                     * @member {theplant.ec.service.inventory.IShipmentInfo|null|undefined} shipmentInfo
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.shipmentInfo = null;

                    /**
                     * ShipFromStore cancelReason.
                     * @member {string} cancelReason
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.cancelReason = "";

                    /**
                     * ShipFromStore isIncludeNovelty.
                     * @member {boolean} isIncludeNovelty
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.isIncludeNovelty = false;

                    /**
                     * ShipFromStore Novelties.
                     * @member {theplant.ec.service.inventory.INovelties|null|undefined} Novelties
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     */
                    ShipFromStore.prototype.Novelties = null;

                    /**
                     * Creates a new ShipFromStore instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStore=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ShipFromStore} ShipFromStore instance
                     */
                    ShipFromStore.create = function create(properties) {
                        return new ShipFromStore(properties);
                    };

                    /**
                     * Encodes the specified ShipFromStore message. Does not implicitly {@link theplant.ec.service.inventory.ShipFromStore.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStore} message ShipFromStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStore.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.quantity);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.state);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.storeId);
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.storeName);
                        if (message.storePhone != null && message.hasOwnProperty("storePhone"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.storePhone);
                        if (message.storeHistory != null && message.hasOwnProperty("storeHistory"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.storeHistory);
                        if (message.addressPrefecture != null && message.hasOwnProperty("addressPrefecture"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.addressPrefecture);
                        if (message.addressCity != null && message.hasOwnProperty("addressCity"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.addressCity);
                        if (message.orderConfirmedAt != null && message.hasOwnProperty("orderConfirmedAt"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.orderConfirmedAt);
                        if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.acceptedAt);
                        if (message.canceledAt != null && message.hasOwnProperty("canceledAt"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.canceledAt);
                        if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.expiredAt);
                        if (message.shippedAt != null && message.hasOwnProperty("shippedAt"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.shippedAt);
                        if (message.returnedAt != null && message.hasOwnProperty("returnedAt"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.returnedAt);
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int64(message.priority);
                        if (message.shippingAt != null && message.hasOwnProperty("shippingAt"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.shippingAt);
                        if (message.shipmentInfo != null && message.hasOwnProperty("shipmentInfo"))
                            $root.theplant.ec.service.inventory.ShipmentInfo.encode(message.shipmentInfo, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            writer.uint32(/* id 21, wireType 2 =*/170).string(message.cancelReason);
                        if (message.isIncludeNovelty != null && message.hasOwnProperty("isIncludeNovelty"))
                            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.isIncludeNovelty);
                        if (message.Novelties != null && message.hasOwnProperty("Novelties"))
                            $root.theplant.ec.service.inventory.Novelties.encode(message.Novelties, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShipFromStore message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ShipFromStore.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStore} message ShipFromStore message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStore.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShipFromStore message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ShipFromStore} ShipFromStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStore.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ShipFromStore();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.quantity = reader.int64();
                                break;
                            case 4:
                                message.state = reader.string();
                                break;
                            case 6:
                                message.storeId = reader.string();
                                break;
                            case 7:
                                message.storeName = reader.string();
                                break;
                            case 8:
                                message.storePhone = reader.string();
                                break;
                            case 9:
                                message.storeHistory = reader.string();
                                break;
                            case 10:
                                message.addressPrefecture = reader.string();
                                break;
                            case 11:
                                message.addressCity = reader.string();
                                break;
                            case 12:
                                message.orderConfirmedAt = reader.string();
                                break;
                            case 13:
                                message.acceptedAt = reader.string();
                                break;
                            case 14:
                                message.canceledAt = reader.string();
                                break;
                            case 15:
                                message.expiredAt = reader.string();
                                break;
                            case 16:
                                message.shippedAt = reader.string();
                                break;
                            case 17:
                                message.returnedAt = reader.string();
                                break;
                            case 19:
                                message.shippingAt = reader.string();
                                break;
                            case 18:
                                message.priority = reader.int64();
                                break;
                            case 20:
                                message.shipmentInfo = $root.theplant.ec.service.inventory.ShipmentInfo.decode(reader, reader.uint32());
                                break;
                            case 21:
                                message.cancelReason = reader.string();
                                break;
                            case 22:
                                message.isIncludeNovelty = reader.bool();
                                break;
                            case 23:
                                message.Novelties = $root.theplant.ec.service.inventory.Novelties.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShipFromStore message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ShipFromStore} ShipFromStore
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStore.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShipFromStore message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShipFromStore.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            if (!$util.isString(message.state))
                                return "state: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            if (!$util.isString(message.storeName))
                                return "storeName: string expected";
                        if (message.storePhone != null && message.hasOwnProperty("storePhone"))
                            if (!$util.isString(message.storePhone))
                                return "storePhone: string expected";
                        if (message.storeHistory != null && message.hasOwnProperty("storeHistory"))
                            if (!$util.isString(message.storeHistory))
                                return "storeHistory: string expected";
                        if (message.addressPrefecture != null && message.hasOwnProperty("addressPrefecture"))
                            if (!$util.isString(message.addressPrefecture))
                                return "addressPrefecture: string expected";
                        if (message.addressCity != null && message.hasOwnProperty("addressCity"))
                            if (!$util.isString(message.addressCity))
                                return "addressCity: string expected";
                        if (message.orderConfirmedAt != null && message.hasOwnProperty("orderConfirmedAt"))
                            if (!$util.isString(message.orderConfirmedAt))
                                return "orderConfirmedAt: string expected";
                        if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                            if (!$util.isString(message.acceptedAt))
                                return "acceptedAt: string expected";
                        if (message.canceledAt != null && message.hasOwnProperty("canceledAt"))
                            if (!$util.isString(message.canceledAt))
                                return "canceledAt: string expected";
                        if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                            if (!$util.isString(message.expiredAt))
                                return "expiredAt: string expected";
                        if (message.shippedAt != null && message.hasOwnProperty("shippedAt"))
                            if (!$util.isString(message.shippedAt))
                                return "shippedAt: string expected";
                        if (message.returnedAt != null && message.hasOwnProperty("returnedAt"))
                            if (!$util.isString(message.returnedAt))
                                return "returnedAt: string expected";
                        if (message.shippingAt != null && message.hasOwnProperty("shippingAt"))
                            if (!$util.isString(message.shippingAt))
                                return "shippingAt: string expected";
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            if (!$util.isInteger(message.priority) && !(message.priority && $util.isInteger(message.priority.low) && $util.isInteger(message.priority.high)))
                                return "priority: integer|Long expected";
                        if (message.shipmentInfo != null && message.hasOwnProperty("shipmentInfo")) {
                            var error = $root.theplant.ec.service.inventory.ShipmentInfo.verify(message.shipmentInfo);
                            if (error)
                                return "shipmentInfo." + error;
                        }
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            if (!$util.isString(message.cancelReason))
                                return "cancelReason: string expected";
                        if (message.isIncludeNovelty != null && message.hasOwnProperty("isIncludeNovelty"))
                            if (typeof message.isIncludeNovelty !== "boolean")
                                return "isIncludeNovelty: boolean expected";
                        if (message.Novelties != null && message.hasOwnProperty("Novelties")) {
                            var error = $root.theplant.ec.service.inventory.Novelties.verify(message.Novelties);
                            if (error)
                                return "Novelties." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ShipFromStore message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ShipFromStore} ShipFromStore
                     */
                    ShipFromStore.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ShipFromStore)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ShipFromStore();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        if (object.state != null)
                            message.state = String(object.state);
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.storeName != null)
                            message.storeName = String(object.storeName);
                        if (object.storePhone != null)
                            message.storePhone = String(object.storePhone);
                        if (object.storeHistory != null)
                            message.storeHistory = String(object.storeHistory);
                        if (object.addressPrefecture != null)
                            message.addressPrefecture = String(object.addressPrefecture);
                        if (object.addressCity != null)
                            message.addressCity = String(object.addressCity);
                        if (object.orderConfirmedAt != null)
                            message.orderConfirmedAt = String(object.orderConfirmedAt);
                        if (object.acceptedAt != null)
                            message.acceptedAt = String(object.acceptedAt);
                        if (object.canceledAt != null)
                            message.canceledAt = String(object.canceledAt);
                        if (object.expiredAt != null)
                            message.expiredAt = String(object.expiredAt);
                        if (object.shippedAt != null)
                            message.shippedAt = String(object.shippedAt);
                        if (object.returnedAt != null)
                            message.returnedAt = String(object.returnedAt);
                        if (object.shippingAt != null)
                            message.shippingAt = String(object.shippingAt);
                        if (object.priority != null)
                            if ($util.Long)
                                (message.priority = $util.Long.fromValue(object.priority)).unsigned = false;
                            else if (typeof object.priority === "string")
                                message.priority = parseInt(object.priority, 10);
                            else if (typeof object.priority === "number")
                                message.priority = object.priority;
                            else if (typeof object.priority === "object")
                                message.priority = new $util.LongBits(object.priority.low >>> 0, object.priority.high >>> 0).toNumber();
                        if (object.shipmentInfo != null) {
                            if (typeof object.shipmentInfo !== "object")
                                throw TypeError(".theplant.ec.service.inventory.ShipFromStore.shipmentInfo: object expected");
                            message.shipmentInfo = $root.theplant.ec.service.inventory.ShipmentInfo.fromObject(object.shipmentInfo);
                        }
                        if (object.cancelReason != null)
                            message.cancelReason = String(object.cancelReason);
                        if (object.isIncludeNovelty != null)
                            message.isIncludeNovelty = Boolean(object.isIncludeNovelty);
                        if (object.Novelties != null) {
                            if (typeof object.Novelties !== "object")
                                throw TypeError(".theplant.ec.service.inventory.ShipFromStore.Novelties: object expected");
                            message.Novelties = $root.theplant.ec.service.inventory.Novelties.fromObject(object.Novelties);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShipFromStore message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @static
                     * @param {theplant.ec.service.inventory.ShipFromStore} message ShipFromStore
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShipFromStore.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            object.state = "";
                            object.storeId = "";
                            object.storeName = "";
                            object.storePhone = "";
                            object.storeHistory = "";
                            object.addressPrefecture = "";
                            object.addressCity = "";
                            object.orderConfirmedAt = "";
                            object.acceptedAt = "";
                            object.canceledAt = "";
                            object.expiredAt = "";
                            object.shippedAt = "";
                            object.returnedAt = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.priority = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.priority = options.longs === String ? "0" : 0;
                            object.shippingAt = "";
                            object.shipmentInfo = null;
                            object.cancelReason = "";
                            object.isIncludeNovelty = false;
                            object.Novelties = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = message.state;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            object.storeName = message.storeName;
                        if (message.storePhone != null && message.hasOwnProperty("storePhone"))
                            object.storePhone = message.storePhone;
                        if (message.storeHistory != null && message.hasOwnProperty("storeHistory"))
                            object.storeHistory = message.storeHistory;
                        if (message.addressPrefecture != null && message.hasOwnProperty("addressPrefecture"))
                            object.addressPrefecture = message.addressPrefecture;
                        if (message.addressCity != null && message.hasOwnProperty("addressCity"))
                            object.addressCity = message.addressCity;
                        if (message.orderConfirmedAt != null && message.hasOwnProperty("orderConfirmedAt"))
                            object.orderConfirmedAt = message.orderConfirmedAt;
                        if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                            object.acceptedAt = message.acceptedAt;
                        if (message.canceledAt != null && message.hasOwnProperty("canceledAt"))
                            object.canceledAt = message.canceledAt;
                        if (message.expiredAt != null && message.hasOwnProperty("expiredAt"))
                            object.expiredAt = message.expiredAt;
                        if (message.shippedAt != null && message.hasOwnProperty("shippedAt"))
                            object.shippedAt = message.shippedAt;
                        if (message.returnedAt != null && message.hasOwnProperty("returnedAt"))
                            object.returnedAt = message.returnedAt;
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            if (typeof message.priority === "number")
                                object.priority = options.longs === String ? String(message.priority) : message.priority;
                            else
                                object.priority = options.longs === String ? $util.Long.prototype.toString.call(message.priority) : options.longs === Number ? new $util.LongBits(message.priority.low >>> 0, message.priority.high >>> 0).toNumber() : message.priority;
                        if (message.shippingAt != null && message.hasOwnProperty("shippingAt"))
                            object.shippingAt = message.shippingAt;
                        if (message.shipmentInfo != null && message.hasOwnProperty("shipmentInfo"))
                            object.shipmentInfo = $root.theplant.ec.service.inventory.ShipmentInfo.toObject(message.shipmentInfo, options);
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            object.cancelReason = message.cancelReason;
                        if (message.isIncludeNovelty != null && message.hasOwnProperty("isIncludeNovelty"))
                            object.isIncludeNovelty = message.isIncludeNovelty;
                        if (message.Novelties != null && message.hasOwnProperty("Novelties"))
                            object.Novelties = $root.theplant.ec.service.inventory.Novelties.toObject(message.Novelties, options);
                        return object;
                    };

                    /**
                     * Converts this ShipFromStore to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ShipFromStore
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShipFromStore.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShipFromStore;
                })();

                inventory.ShipmentInfo = (function() {

                    /**
                     * Properties of a ShipmentInfo.
                     * @memberof theplant.ec.service.inventory
                     * @interface IShipmentInfo
                     * @property {string|null} [employeeId] ShipmentInfo employeeId
                     * @property {string|null} [roNumber] ShipmentInfo roNumber
                     * @property {string|null} [shippingInfo] ShipmentInfo shippingInfo
                     * @property {string|null} [provider] ShipmentInfo provider
                     */

                    /**
                     * Constructs a new ShipmentInfo.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a ShipmentInfo.
                     * @implements IShipmentInfo
                     * @constructor
                     * @param {theplant.ec.service.inventory.IShipmentInfo=} [properties] Properties to set
                     */
                    function ShipmentInfo(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShipmentInfo employeeId.
                     * @member {string} employeeId
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @instance
                     */
                    ShipmentInfo.prototype.employeeId = "";

                    /**
                     * ShipmentInfo roNumber.
                     * @member {string} roNumber
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @instance
                     */
                    ShipmentInfo.prototype.roNumber = "";

                    /**
                     * ShipmentInfo shippingInfo.
                     * @member {string} shippingInfo
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @instance
                     */
                    ShipmentInfo.prototype.shippingInfo = "";

                    /**
                     * ShipmentInfo provider.
                     * @member {string} provider
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @instance
                     */
                    ShipmentInfo.prototype.provider = "";

                    /**
                     * Creates a new ShipmentInfo instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IShipmentInfo=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ShipmentInfo} ShipmentInfo instance
                     */
                    ShipmentInfo.create = function create(properties) {
                        return new ShipmentInfo(properties);
                    };

                    /**
                     * Encodes the specified ShipmentInfo message. Does not implicitly {@link theplant.ec.service.inventory.ShipmentInfo.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IShipmentInfo} message ShipmentInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipmentInfo.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.employeeId);
                        if (message.roNumber != null && message.hasOwnProperty("roNumber"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.roNumber);
                        if (message.shippingInfo != null && message.hasOwnProperty("shippingInfo"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.shippingInfo);
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.provider);
                        return writer;
                    };

                    /**
                     * Encodes the specified ShipmentInfo message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ShipmentInfo.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {theplant.ec.service.inventory.IShipmentInfo} message ShipmentInfo message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipmentInfo.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShipmentInfo message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ShipmentInfo} ShipmentInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipmentInfo.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ShipmentInfo();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.employeeId = reader.string();
                                break;
                            case 2:
                                message.roNumber = reader.string();
                                break;
                            case 3:
                                message.shippingInfo = reader.string();
                                break;
                            case 4:
                                message.provider = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShipmentInfo message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ShipmentInfo} ShipmentInfo
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipmentInfo.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShipmentInfo message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShipmentInfo.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                            if (!$util.isString(message.employeeId))
                                return "employeeId: string expected";
                        if (message.roNumber != null && message.hasOwnProperty("roNumber"))
                            if (!$util.isString(message.roNumber))
                                return "roNumber: string expected";
                        if (message.shippingInfo != null && message.hasOwnProperty("shippingInfo"))
                            if (!$util.isString(message.shippingInfo))
                                return "shippingInfo: string expected";
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            if (!$util.isString(message.provider))
                                return "provider: string expected";
                        return null;
                    };

                    /**
                     * Creates a ShipmentInfo message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ShipmentInfo} ShipmentInfo
                     */
                    ShipmentInfo.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ShipmentInfo)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ShipmentInfo();
                        if (object.employeeId != null)
                            message.employeeId = String(object.employeeId);
                        if (object.roNumber != null)
                            message.roNumber = String(object.roNumber);
                        if (object.shippingInfo != null)
                            message.shippingInfo = String(object.shippingInfo);
                        if (object.provider != null)
                            message.provider = String(object.provider);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShipmentInfo message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @static
                     * @param {theplant.ec.service.inventory.ShipmentInfo} message ShipmentInfo
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShipmentInfo.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.employeeId = "";
                            object.roNumber = "";
                            object.shippingInfo = "";
                            object.provider = "";
                        }
                        if (message.employeeId != null && message.hasOwnProperty("employeeId"))
                            object.employeeId = message.employeeId;
                        if (message.roNumber != null && message.hasOwnProperty("roNumber"))
                            object.roNumber = message.roNumber;
                        if (message.shippingInfo != null && message.hasOwnProperty("shippingInfo"))
                            object.shippingInfo = message.shippingInfo;
                        if (message.provider != null && message.hasOwnProperty("provider"))
                            object.provider = message.provider;
                        return object;
                    };

                    /**
                     * Converts this ShipmentInfo to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ShipmentInfo
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShipmentInfo.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShipmentInfo;
                })();

                inventory.ShipFromStoreSetting = (function() {

                    /**
                     * Properties of a ShipFromStoreSetting.
                     * @memberof theplant.ec.service.inventory
                     * @interface IShipFromStoreSetting
                     * @property {boolean|null} [enableShipFromStore] ShipFromStoreSetting enableShipFromStore
                     * @property {number|Long|null} [threshold] ShipFromStoreSetting threshold
                     * @property {number|Long|null} [autoCancelTime] ShipFromStoreSetting autoCancelTime
                     * @property {number|Long|null} [sendNotificationToStoreNumber] ShipFromStoreSetting sendNotificationToStoreNumber
                     * @property {boolean|null} [disableSendingNotificationToRefusedStore] ShipFromStoreSetting disableSendingNotificationToRefusedStore
                     */

                    /**
                     * Constructs a new ShipFromStoreSetting.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a ShipFromStoreSetting.
                     * @implements IShipFromStoreSetting
                     * @constructor
                     * @param {theplant.ec.service.inventory.IShipFromStoreSetting=} [properties] Properties to set
                     */
                    function ShipFromStoreSetting(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShipFromStoreSetting enableShipFromStore.
                     * @member {boolean} enableShipFromStore
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @instance
                     */
                    ShipFromStoreSetting.prototype.enableShipFromStore = false;

                    /**
                     * ShipFromStoreSetting threshold.
                     * @member {number|Long} threshold
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @instance
                     */
                    ShipFromStoreSetting.prototype.threshold = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStoreSetting autoCancelTime.
                     * @member {number|Long} autoCancelTime
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @instance
                     */
                    ShipFromStoreSetting.prototype.autoCancelTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStoreSetting sendNotificationToStoreNumber.
                     * @member {number|Long} sendNotificationToStoreNumber
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @instance
                     */
                    ShipFromStoreSetting.prototype.sendNotificationToStoreNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStoreSetting disableSendingNotificationToRefusedStore.
                     * @member {boolean} disableSendingNotificationToRefusedStore
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @instance
                     */
                    ShipFromStoreSetting.prototype.disableSendingNotificationToRefusedStore = false;

                    /**
                     * Creates a new ShipFromStoreSetting instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStoreSetting=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ShipFromStoreSetting} ShipFromStoreSetting instance
                     */
                    ShipFromStoreSetting.create = function create(properties) {
                        return new ShipFromStoreSetting(properties);
                    };

                    /**
                     * Encodes the specified ShipFromStoreSetting message. Does not implicitly {@link theplant.ec.service.inventory.ShipFromStoreSetting.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStoreSetting} message ShipFromStoreSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStoreSetting.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.enableShipFromStore != null && message.hasOwnProperty("enableShipFromStore"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.enableShipFromStore);
                        if (message.threshold != null && message.hasOwnProperty("threshold"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.threshold);
                        if (message.autoCancelTime != null && message.hasOwnProperty("autoCancelTime"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.autoCancelTime);
                        if (message.sendNotificationToStoreNumber != null && message.hasOwnProperty("sendNotificationToStoreNumber"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.sendNotificationToStoreNumber);
                        if (message.disableSendingNotificationToRefusedStore != null && message.hasOwnProperty("disableSendingNotificationToRefusedStore"))
                            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.disableSendingNotificationToRefusedStore);
                        return writer;
                    };

                    /**
                     * Encodes the specified ShipFromStoreSetting message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ShipFromStoreSetting.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStoreSetting} message ShipFromStoreSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStoreSetting.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShipFromStoreSetting message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ShipFromStoreSetting} ShipFromStoreSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStoreSetting.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ShipFromStoreSetting();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.enableShipFromStore = reader.bool();
                                break;
                            case 2:
                                message.threshold = reader.int64();
                                break;
                            case 3:
                                message.autoCancelTime = reader.int64();
                                break;
                            case 4:
                                message.sendNotificationToStoreNumber = reader.int64();
                                break;
                            case 5:
                                message.disableSendingNotificationToRefusedStore = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShipFromStoreSetting message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ShipFromStoreSetting} ShipFromStoreSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStoreSetting.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShipFromStoreSetting message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShipFromStoreSetting.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.enableShipFromStore != null && message.hasOwnProperty("enableShipFromStore"))
                            if (typeof message.enableShipFromStore !== "boolean")
                                return "enableShipFromStore: boolean expected";
                        if (message.threshold != null && message.hasOwnProperty("threshold"))
                            if (!$util.isInteger(message.threshold) && !(message.threshold && $util.isInteger(message.threshold.low) && $util.isInteger(message.threshold.high)))
                                return "threshold: integer|Long expected";
                        if (message.autoCancelTime != null && message.hasOwnProperty("autoCancelTime"))
                            if (!$util.isInteger(message.autoCancelTime) && !(message.autoCancelTime && $util.isInteger(message.autoCancelTime.low) && $util.isInteger(message.autoCancelTime.high)))
                                return "autoCancelTime: integer|Long expected";
                        if (message.sendNotificationToStoreNumber != null && message.hasOwnProperty("sendNotificationToStoreNumber"))
                            if (!$util.isInteger(message.sendNotificationToStoreNumber) && !(message.sendNotificationToStoreNumber && $util.isInteger(message.sendNotificationToStoreNumber.low) && $util.isInteger(message.sendNotificationToStoreNumber.high)))
                                return "sendNotificationToStoreNumber: integer|Long expected";
                        if (message.disableSendingNotificationToRefusedStore != null && message.hasOwnProperty("disableSendingNotificationToRefusedStore"))
                            if (typeof message.disableSendingNotificationToRefusedStore !== "boolean")
                                return "disableSendingNotificationToRefusedStore: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a ShipFromStoreSetting message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ShipFromStoreSetting} ShipFromStoreSetting
                     */
                    ShipFromStoreSetting.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ShipFromStoreSetting)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ShipFromStoreSetting();
                        if (object.enableShipFromStore != null)
                            message.enableShipFromStore = Boolean(object.enableShipFromStore);
                        if (object.threshold != null)
                            if ($util.Long)
                                (message.threshold = $util.Long.fromValue(object.threshold)).unsigned = false;
                            else if (typeof object.threshold === "string")
                                message.threshold = parseInt(object.threshold, 10);
                            else if (typeof object.threshold === "number")
                                message.threshold = object.threshold;
                            else if (typeof object.threshold === "object")
                                message.threshold = new $util.LongBits(object.threshold.low >>> 0, object.threshold.high >>> 0).toNumber();
                        if (object.autoCancelTime != null)
                            if ($util.Long)
                                (message.autoCancelTime = $util.Long.fromValue(object.autoCancelTime)).unsigned = false;
                            else if (typeof object.autoCancelTime === "string")
                                message.autoCancelTime = parseInt(object.autoCancelTime, 10);
                            else if (typeof object.autoCancelTime === "number")
                                message.autoCancelTime = object.autoCancelTime;
                            else if (typeof object.autoCancelTime === "object")
                                message.autoCancelTime = new $util.LongBits(object.autoCancelTime.low >>> 0, object.autoCancelTime.high >>> 0).toNumber();
                        if (object.sendNotificationToStoreNumber != null)
                            if ($util.Long)
                                (message.sendNotificationToStoreNumber = $util.Long.fromValue(object.sendNotificationToStoreNumber)).unsigned = false;
                            else if (typeof object.sendNotificationToStoreNumber === "string")
                                message.sendNotificationToStoreNumber = parseInt(object.sendNotificationToStoreNumber, 10);
                            else if (typeof object.sendNotificationToStoreNumber === "number")
                                message.sendNotificationToStoreNumber = object.sendNotificationToStoreNumber;
                            else if (typeof object.sendNotificationToStoreNumber === "object")
                                message.sendNotificationToStoreNumber = new $util.LongBits(object.sendNotificationToStoreNumber.low >>> 0, object.sendNotificationToStoreNumber.high >>> 0).toNumber();
                        if (object.disableSendingNotificationToRefusedStore != null)
                            message.disableSendingNotificationToRefusedStore = Boolean(object.disableSendingNotificationToRefusedStore);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShipFromStoreSetting message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @static
                     * @param {theplant.ec.service.inventory.ShipFromStoreSetting} message ShipFromStoreSetting
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShipFromStoreSetting.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.enableShipFromStore = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.threshold = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.threshold = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.autoCancelTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.autoCancelTime = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sendNotificationToStoreNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sendNotificationToStoreNumber = options.longs === String ? "0" : 0;
                            object.disableSendingNotificationToRefusedStore = false;
                        }
                        if (message.enableShipFromStore != null && message.hasOwnProperty("enableShipFromStore"))
                            object.enableShipFromStore = message.enableShipFromStore;
                        if (message.threshold != null && message.hasOwnProperty("threshold"))
                            if (typeof message.threshold === "number")
                                object.threshold = options.longs === String ? String(message.threshold) : message.threshold;
                            else
                                object.threshold = options.longs === String ? $util.Long.prototype.toString.call(message.threshold) : options.longs === Number ? new $util.LongBits(message.threshold.low >>> 0, message.threshold.high >>> 0).toNumber() : message.threshold;
                        if (message.autoCancelTime != null && message.hasOwnProperty("autoCancelTime"))
                            if (typeof message.autoCancelTime === "number")
                                object.autoCancelTime = options.longs === String ? String(message.autoCancelTime) : message.autoCancelTime;
                            else
                                object.autoCancelTime = options.longs === String ? $util.Long.prototype.toString.call(message.autoCancelTime) : options.longs === Number ? new $util.LongBits(message.autoCancelTime.low >>> 0, message.autoCancelTime.high >>> 0).toNumber() : message.autoCancelTime;
                        if (message.sendNotificationToStoreNumber != null && message.hasOwnProperty("sendNotificationToStoreNumber"))
                            if (typeof message.sendNotificationToStoreNumber === "number")
                                object.sendNotificationToStoreNumber = options.longs === String ? String(message.sendNotificationToStoreNumber) : message.sendNotificationToStoreNumber;
                            else
                                object.sendNotificationToStoreNumber = options.longs === String ? $util.Long.prototype.toString.call(message.sendNotificationToStoreNumber) : options.longs === Number ? new $util.LongBits(message.sendNotificationToStoreNumber.low >>> 0, message.sendNotificationToStoreNumber.high >>> 0).toNumber() : message.sendNotificationToStoreNumber;
                        if (message.disableSendingNotificationToRefusedStore != null && message.hasOwnProperty("disableSendingNotificationToRefusedStore"))
                            object.disableSendingNotificationToRefusedStore = message.disableSendingNotificationToRefusedStore;
                        return object;
                    };

                    /**
                     * Converts this ShipFromStoreSetting to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ShipFromStoreSetting
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShipFromStoreSetting.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShipFromStoreSetting;
                })();

                inventory.ShipFromStoreNotification = (function() {

                    /**
                     * Properties of a ShipFromStoreNotification.
                     * @memberof theplant.ec.service.inventory
                     * @interface IShipFromStoreNotification
                     * @property {string|null} [orderCode] ShipFromStoreNotification orderCode
                     * @property {string|null} [articleCode] ShipFromStoreNotification articleCode
                     * @property {string|null} [storeId] ShipFromStoreNotification storeId
                     * @property {number|Long|null} [quantity] ShipFromStoreNotification quantity
                     * @property {string|null} [sendNotificationAt] ShipFromStoreNotification sendNotificationAt
                     * @property {string|null} [acceptedAt] ShipFromStoreNotification acceptedAt
                     * @property {string|null} [refusedAt] ShipFromStoreNotification refusedAt
                     * @property {string|null} [cancelAt] ShipFromStoreNotification cancelAt
                     * @property {string|null} [expireAt] ShipFromStoreNotification expireAt
                     * @property {number|Long|null} [priority] ShipFromStoreNotification priority
                     * @property {string|null} [addressPrefecture] ShipFromStoreNotification addressPrefecture
                     * @property {string|null} [orderConfirmedAt] ShipFromStoreNotification orderConfirmedAt
                     * @property {string|null} [state] ShipFromStoreNotification state
                     * @property {string|null} [acceptedStoreId] ShipFromStoreNotification acceptedStoreId
                     * @property {number|Long|null} [notificationId] ShipFromStoreNotification notificationId
                     * @property {string|null} [storeName] ShipFromStoreNotification storeName
                     * @property {string|null} [refuseReason] ShipFromStoreNotification refuseReason
                     * @property {string|null} [addressCity] ShipFromStoreNotification addressCity
                     * @property {string|null} [cancelReason] ShipFromStoreNotification cancelReason
                     * @property {boolean|null} [isIncludeNovelty] ShipFromStoreNotification isIncludeNovelty
                     * @property {theplant.ec.service.inventory.INovelties|null} [Novelties] ShipFromStoreNotification Novelties
                     */

                    /**
                     * Constructs a new ShipFromStoreNotification.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a ShipFromStoreNotification.
                     * @implements IShipFromStoreNotification
                     * @constructor
                     * @param {theplant.ec.service.inventory.IShipFromStoreNotification=} [properties] Properties to set
                     */
                    function ShipFromStoreNotification(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ShipFromStoreNotification orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.orderCode = "";

                    /**
                     * ShipFromStoreNotification articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.articleCode = "";

                    /**
                     * ShipFromStoreNotification storeId.
                     * @member {string} storeId
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.storeId = "";

                    /**
                     * ShipFromStoreNotification quantity.
                     * @member {number|Long} quantity
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.quantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStoreNotification sendNotificationAt.
                     * @member {string} sendNotificationAt
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.sendNotificationAt = "";

                    /**
                     * ShipFromStoreNotification acceptedAt.
                     * @member {string} acceptedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.acceptedAt = "";

                    /**
                     * ShipFromStoreNotification refusedAt.
                     * @member {string} refusedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.refusedAt = "";

                    /**
                     * ShipFromStoreNotification cancelAt.
                     * @member {string} cancelAt
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.cancelAt = "";

                    /**
                     * ShipFromStoreNotification expireAt.
                     * @member {string} expireAt
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.expireAt = "";

                    /**
                     * ShipFromStoreNotification priority.
                     * @member {number|Long} priority
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.priority = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStoreNotification addressPrefecture.
                     * @member {string} addressPrefecture
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.addressPrefecture = "";

                    /**
                     * ShipFromStoreNotification orderConfirmedAt.
                     * @member {string} orderConfirmedAt
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.orderConfirmedAt = "";

                    /**
                     * ShipFromStoreNotification state.
                     * @member {string} state
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.state = "";

                    /**
                     * ShipFromStoreNotification acceptedStoreId.
                     * @member {string} acceptedStoreId
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.acceptedStoreId = "";

                    /**
                     * ShipFromStoreNotification notificationId.
                     * @member {number|Long} notificationId
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.notificationId = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * ShipFromStoreNotification storeName.
                     * @member {string} storeName
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.storeName = "";

                    /**
                     * ShipFromStoreNotification refuseReason.
                     * @member {string} refuseReason
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.refuseReason = "";

                    /**
                     * ShipFromStoreNotification addressCity.
                     * @member {string} addressCity
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.addressCity = "";

                    /**
                     * ShipFromStoreNotification cancelReason.
                     * @member {string} cancelReason
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.cancelReason = "";

                    /**
                     * ShipFromStoreNotification isIncludeNovelty.
                     * @member {boolean} isIncludeNovelty
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.isIncludeNovelty = false;

                    /**
                     * ShipFromStoreNotification Novelties.
                     * @member {theplant.ec.service.inventory.INovelties|null|undefined} Novelties
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     */
                    ShipFromStoreNotification.prototype.Novelties = null;

                    /**
                     * Creates a new ShipFromStoreNotification instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStoreNotification=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.ShipFromStoreNotification} ShipFromStoreNotification instance
                     */
                    ShipFromStoreNotification.create = function create(properties) {
                        return new ShipFromStoreNotification(properties);
                    };

                    /**
                     * Encodes the specified ShipFromStoreNotification message. Does not implicitly {@link theplant.ec.service.inventory.ShipFromStoreNotification.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStoreNotification} message ShipFromStoreNotification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStoreNotification.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.articleCode);
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.storeId);
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.quantity);
                        if (message.sendNotificationAt != null && message.hasOwnProperty("sendNotificationAt"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.sendNotificationAt);
                        if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.acceptedAt);
                        if (message.refusedAt != null && message.hasOwnProperty("refusedAt"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.refusedAt);
                        if (message.cancelAt != null && message.hasOwnProperty("cancelAt"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.cancelAt);
                        if (message.expireAt != null && message.hasOwnProperty("expireAt"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.expireAt);
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            writer.uint32(/* id 10, wireType 0 =*/80).int64(message.priority);
                        if (message.addressPrefecture != null && message.hasOwnProperty("addressPrefecture"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.addressPrefecture);
                        if (message.orderConfirmedAt != null && message.hasOwnProperty("orderConfirmedAt"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.orderConfirmedAt);
                        if (message.state != null && message.hasOwnProperty("state"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.state);
                        if (message.acceptedStoreId != null && message.hasOwnProperty("acceptedStoreId"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.acceptedStoreId);
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            writer.uint32(/* id 15, wireType 0 =*/120).int64(message.notificationId);
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            writer.uint32(/* id 16, wireType 2 =*/130).string(message.storeName);
                        if (message.refuseReason != null && message.hasOwnProperty("refuseReason"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.refuseReason);
                        if (message.addressCity != null && message.hasOwnProperty("addressCity"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.addressCity);
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.cancelReason);
                        if (message.isIncludeNovelty != null && message.hasOwnProperty("isIncludeNovelty"))
                            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.isIncludeNovelty);
                        if (message.Novelties != null && message.hasOwnProperty("Novelties"))
                            $root.theplant.ec.service.inventory.Novelties.encode(message.Novelties, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ShipFromStoreNotification message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.ShipFromStoreNotification.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {theplant.ec.service.inventory.IShipFromStoreNotification} message ShipFromStoreNotification message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ShipFromStoreNotification.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ShipFromStoreNotification message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.ShipFromStoreNotification} ShipFromStoreNotification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStoreNotification.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.ShipFromStoreNotification();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.articleCode = reader.string();
                                break;
                            case 3:
                                message.storeId = reader.string();
                                break;
                            case 4:
                                message.quantity = reader.int64();
                                break;
                            case 5:
                                message.sendNotificationAt = reader.string();
                                break;
                            case 6:
                                message.acceptedAt = reader.string();
                                break;
                            case 7:
                                message.refusedAt = reader.string();
                                break;
                            case 8:
                                message.cancelAt = reader.string();
                                break;
                            case 9:
                                message.expireAt = reader.string();
                                break;
                            case 10:
                                message.priority = reader.int64();
                                break;
                            case 11:
                                message.addressPrefecture = reader.string();
                                break;
                            case 12:
                                message.orderConfirmedAt = reader.string();
                                break;
                            case 13:
                                message.state = reader.string();
                                break;
                            case 14:
                                message.acceptedStoreId = reader.string();
                                break;
                            case 15:
                                message.notificationId = reader.int64();
                                break;
                            case 16:
                                message.storeName = reader.string();
                                break;
                            case 17:
                                message.refuseReason = reader.string();
                                break;
                            case 18:
                                message.addressCity = reader.string();
                                break;
                            case 19:
                                message.cancelReason = reader.string();
                                break;
                            case 20:
                                message.isIncludeNovelty = reader.bool();
                                break;
                            case 21:
                                message.Novelties = $root.theplant.ec.service.inventory.Novelties.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ShipFromStoreNotification message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.ShipFromStoreNotification} ShipFromStoreNotification
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ShipFromStoreNotification.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ShipFromStoreNotification message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ShipFromStoreNotification.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            if (!$util.isString(message.storeId))
                                return "storeId: string expected";
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (!$util.isInteger(message.quantity) && !(message.quantity && $util.isInteger(message.quantity.low) && $util.isInteger(message.quantity.high)))
                                return "quantity: integer|Long expected";
                        if (message.sendNotificationAt != null && message.hasOwnProperty("sendNotificationAt"))
                            if (!$util.isString(message.sendNotificationAt))
                                return "sendNotificationAt: string expected";
                        if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                            if (!$util.isString(message.acceptedAt))
                                return "acceptedAt: string expected";
                        if (message.refusedAt != null && message.hasOwnProperty("refusedAt"))
                            if (!$util.isString(message.refusedAt))
                                return "refusedAt: string expected";
                        if (message.cancelAt != null && message.hasOwnProperty("cancelAt"))
                            if (!$util.isString(message.cancelAt))
                                return "cancelAt: string expected";
                        if (message.expireAt != null && message.hasOwnProperty("expireAt"))
                            if (!$util.isString(message.expireAt))
                                return "expireAt: string expected";
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            if (!$util.isInteger(message.priority) && !(message.priority && $util.isInteger(message.priority.low) && $util.isInteger(message.priority.high)))
                                return "priority: integer|Long expected";
                        if (message.addressPrefecture != null && message.hasOwnProperty("addressPrefecture"))
                            if (!$util.isString(message.addressPrefecture))
                                return "addressPrefecture: string expected";
                        if (message.orderConfirmedAt != null && message.hasOwnProperty("orderConfirmedAt"))
                            if (!$util.isString(message.orderConfirmedAt))
                                return "orderConfirmedAt: string expected";
                        if (message.state != null && message.hasOwnProperty("state"))
                            if (!$util.isString(message.state))
                                return "state: string expected";
                        if (message.acceptedStoreId != null && message.hasOwnProperty("acceptedStoreId"))
                            if (!$util.isString(message.acceptedStoreId))
                                return "acceptedStoreId: string expected";
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (!$util.isInteger(message.notificationId) && !(message.notificationId && $util.isInteger(message.notificationId.low) && $util.isInteger(message.notificationId.high)))
                                return "notificationId: integer|Long expected";
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            if (!$util.isString(message.storeName))
                                return "storeName: string expected";
                        if (message.refuseReason != null && message.hasOwnProperty("refuseReason"))
                            if (!$util.isString(message.refuseReason))
                                return "refuseReason: string expected";
                        if (message.addressCity != null && message.hasOwnProperty("addressCity"))
                            if (!$util.isString(message.addressCity))
                                return "addressCity: string expected";
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            if (!$util.isString(message.cancelReason))
                                return "cancelReason: string expected";
                        if (message.isIncludeNovelty != null && message.hasOwnProperty("isIncludeNovelty"))
                            if (typeof message.isIncludeNovelty !== "boolean")
                                return "isIncludeNovelty: boolean expected";
                        if (message.Novelties != null && message.hasOwnProperty("Novelties")) {
                            var error = $root.theplant.ec.service.inventory.Novelties.verify(message.Novelties);
                            if (error)
                                return "Novelties." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ShipFromStoreNotification message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.ShipFromStoreNotification} ShipFromStoreNotification
                     */
                    ShipFromStoreNotification.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.ShipFromStoreNotification)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.ShipFromStoreNotification();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.storeId != null)
                            message.storeId = String(object.storeId);
                        if (object.quantity != null)
                            if ($util.Long)
                                (message.quantity = $util.Long.fromValue(object.quantity)).unsigned = false;
                            else if (typeof object.quantity === "string")
                                message.quantity = parseInt(object.quantity, 10);
                            else if (typeof object.quantity === "number")
                                message.quantity = object.quantity;
                            else if (typeof object.quantity === "object")
                                message.quantity = new $util.LongBits(object.quantity.low >>> 0, object.quantity.high >>> 0).toNumber();
                        if (object.sendNotificationAt != null)
                            message.sendNotificationAt = String(object.sendNotificationAt);
                        if (object.acceptedAt != null)
                            message.acceptedAt = String(object.acceptedAt);
                        if (object.refusedAt != null)
                            message.refusedAt = String(object.refusedAt);
                        if (object.cancelAt != null)
                            message.cancelAt = String(object.cancelAt);
                        if (object.expireAt != null)
                            message.expireAt = String(object.expireAt);
                        if (object.priority != null)
                            if ($util.Long)
                                (message.priority = $util.Long.fromValue(object.priority)).unsigned = false;
                            else if (typeof object.priority === "string")
                                message.priority = parseInt(object.priority, 10);
                            else if (typeof object.priority === "number")
                                message.priority = object.priority;
                            else if (typeof object.priority === "object")
                                message.priority = new $util.LongBits(object.priority.low >>> 0, object.priority.high >>> 0).toNumber();
                        if (object.addressPrefecture != null)
                            message.addressPrefecture = String(object.addressPrefecture);
                        if (object.orderConfirmedAt != null)
                            message.orderConfirmedAt = String(object.orderConfirmedAt);
                        if (object.state != null)
                            message.state = String(object.state);
                        if (object.acceptedStoreId != null)
                            message.acceptedStoreId = String(object.acceptedStoreId);
                        if (object.notificationId != null)
                            if ($util.Long)
                                (message.notificationId = $util.Long.fromValue(object.notificationId)).unsigned = false;
                            else if (typeof object.notificationId === "string")
                                message.notificationId = parseInt(object.notificationId, 10);
                            else if (typeof object.notificationId === "number")
                                message.notificationId = object.notificationId;
                            else if (typeof object.notificationId === "object")
                                message.notificationId = new $util.LongBits(object.notificationId.low >>> 0, object.notificationId.high >>> 0).toNumber();
                        if (object.storeName != null)
                            message.storeName = String(object.storeName);
                        if (object.refuseReason != null)
                            message.refuseReason = String(object.refuseReason);
                        if (object.addressCity != null)
                            message.addressCity = String(object.addressCity);
                        if (object.cancelReason != null)
                            message.cancelReason = String(object.cancelReason);
                        if (object.isIncludeNovelty != null)
                            message.isIncludeNovelty = Boolean(object.isIncludeNovelty);
                        if (object.Novelties != null) {
                            if (typeof object.Novelties !== "object")
                                throw TypeError(".theplant.ec.service.inventory.ShipFromStoreNotification.Novelties: object expected");
                            message.Novelties = $root.theplant.ec.service.inventory.Novelties.fromObject(object.Novelties);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ShipFromStoreNotification message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @static
                     * @param {theplant.ec.service.inventory.ShipFromStoreNotification} message ShipFromStoreNotification
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ShipFromStoreNotification.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            object.articleCode = "";
                            object.storeId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.quantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.quantity = options.longs === String ? "0" : 0;
                            object.sendNotificationAt = "";
                            object.acceptedAt = "";
                            object.refusedAt = "";
                            object.cancelAt = "";
                            object.expireAt = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.priority = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.priority = options.longs === String ? "0" : 0;
                            object.addressPrefecture = "";
                            object.orderConfirmedAt = "";
                            object.state = "";
                            object.acceptedStoreId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notificationId = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notificationId = options.longs === String ? "0" : 0;
                            object.storeName = "";
                            object.refuseReason = "";
                            object.addressCity = "";
                            object.cancelReason = "";
                            object.isIncludeNovelty = false;
                            object.Novelties = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.storeId != null && message.hasOwnProperty("storeId"))
                            object.storeId = message.storeId;
                        if (message.quantity != null && message.hasOwnProperty("quantity"))
                            if (typeof message.quantity === "number")
                                object.quantity = options.longs === String ? String(message.quantity) : message.quantity;
                            else
                                object.quantity = options.longs === String ? $util.Long.prototype.toString.call(message.quantity) : options.longs === Number ? new $util.LongBits(message.quantity.low >>> 0, message.quantity.high >>> 0).toNumber() : message.quantity;
                        if (message.sendNotificationAt != null && message.hasOwnProperty("sendNotificationAt"))
                            object.sendNotificationAt = message.sendNotificationAt;
                        if (message.acceptedAt != null && message.hasOwnProperty("acceptedAt"))
                            object.acceptedAt = message.acceptedAt;
                        if (message.refusedAt != null && message.hasOwnProperty("refusedAt"))
                            object.refusedAt = message.refusedAt;
                        if (message.cancelAt != null && message.hasOwnProperty("cancelAt"))
                            object.cancelAt = message.cancelAt;
                        if (message.expireAt != null && message.hasOwnProperty("expireAt"))
                            object.expireAt = message.expireAt;
                        if (message.priority != null && message.hasOwnProperty("priority"))
                            if (typeof message.priority === "number")
                                object.priority = options.longs === String ? String(message.priority) : message.priority;
                            else
                                object.priority = options.longs === String ? $util.Long.prototype.toString.call(message.priority) : options.longs === Number ? new $util.LongBits(message.priority.low >>> 0, message.priority.high >>> 0).toNumber() : message.priority;
                        if (message.addressPrefecture != null && message.hasOwnProperty("addressPrefecture"))
                            object.addressPrefecture = message.addressPrefecture;
                        if (message.orderConfirmedAt != null && message.hasOwnProperty("orderConfirmedAt"))
                            object.orderConfirmedAt = message.orderConfirmedAt;
                        if (message.state != null && message.hasOwnProperty("state"))
                            object.state = message.state;
                        if (message.acceptedStoreId != null && message.hasOwnProperty("acceptedStoreId"))
                            object.acceptedStoreId = message.acceptedStoreId;
                        if (message.notificationId != null && message.hasOwnProperty("notificationId"))
                            if (typeof message.notificationId === "number")
                                object.notificationId = options.longs === String ? String(message.notificationId) : message.notificationId;
                            else
                                object.notificationId = options.longs === String ? $util.Long.prototype.toString.call(message.notificationId) : options.longs === Number ? new $util.LongBits(message.notificationId.low >>> 0, message.notificationId.high >>> 0).toNumber() : message.notificationId;
                        if (message.storeName != null && message.hasOwnProperty("storeName"))
                            object.storeName = message.storeName;
                        if (message.refuseReason != null && message.hasOwnProperty("refuseReason"))
                            object.refuseReason = message.refuseReason;
                        if (message.addressCity != null && message.hasOwnProperty("addressCity"))
                            object.addressCity = message.addressCity;
                        if (message.cancelReason != null && message.hasOwnProperty("cancelReason"))
                            object.cancelReason = message.cancelReason;
                        if (message.isIncludeNovelty != null && message.hasOwnProperty("isIncludeNovelty"))
                            object.isIncludeNovelty = message.isIncludeNovelty;
                        if (message.Novelties != null && message.hasOwnProperty("Novelties"))
                            object.Novelties = $root.theplant.ec.service.inventory.Novelties.toObject(message.Novelties, options);
                        return object;
                    };

                    /**
                     * Converts this ShipFromStoreNotification to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.ShipFromStoreNotification
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ShipFromStoreNotification.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ShipFromStoreNotification;
                })();

                /**
                 * ShipFromStoreNotificationState enum.
                 * @name theplant.ec.service.inventory.ShipFromStoreNotificationState
                 * @enum {string}
                 * @property {number} SFS_NOTIFICATION_WAITING_FOR_STORE_ACCEPT=0 SFS_NOTIFICATION_WAITING_FOR_STORE_ACCEPT value
                 * @property {number} SFS_NOTIFICATION_ACCEPTED=1 SFS_NOTIFICATION_ACCEPTED value
                 * @property {number} SFS_NOTIFICATION_CANCELLED=2 SFS_NOTIFICATION_CANCELLED value
                 * @property {number} SFS_NOTIFICATION_REFUSED=3 SFS_NOTIFICATION_REFUSED value
                 * @property {number} SFS_NOTIFICATION_EXPIRED=4 SFS_NOTIFICATION_EXPIRED value
                 * @property {number} SFS_NOTIFICATION_WAITING_FOR_SEND=5 SFS_NOTIFICATION_WAITING_FOR_SEND value
                 */
                inventory.ShipFromStoreNotificationState = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SFS_NOTIFICATION_WAITING_FOR_STORE_ACCEPT"] = 0;
                    values[valuesById[1] = "SFS_NOTIFICATION_ACCEPTED"] = 1;
                    values[valuesById[2] = "SFS_NOTIFICATION_CANCELLED"] = 2;
                    values[valuesById[3] = "SFS_NOTIFICATION_REFUSED"] = 3;
                    values[valuesById[4] = "SFS_NOTIFICATION_EXPIRED"] = 4;
                    values[valuesById[5] = "SFS_NOTIFICATION_WAITING_FOR_SEND"] = 5;
                    return values;
                })();

                /**
                 * ShipFromStoreNotificationListSort enum.
                 * @name theplant.ec.service.inventory.ShipFromStoreNotificationListSort
                 * @enum {string}
                 * @property {number} PURCHASE_DATE_ASC=0 PURCHASE_DATE_ASC value
                 * @property {number} PURCHASE_DATE_DESC=1 PURCHASE_DATE_DESC value
                 * @property {number} PRIORITY_ASC=2 PRIORITY_ASC value
                 * @property {number} PRIORITY_DESC=3 PRIORITY_DESC value
                 * @property {number} ORDER_CANCEL_COUNTDOWN_TIME_ASC=4 ORDER_CANCEL_COUNTDOWN_TIME_ASC value
                 * @property {number} ORDER_CANCEL_COUNTDOWN_TIME_DESC=5 ORDER_CANCEL_COUNTDOWN_TIME_DESC value
                 * @property {number} DELIVERY_DATE_ASC=6 DELIVERY_DATE_ASC value
                 * @property {number} DELIVERY_DATE_DESC=7 DELIVERY_DATE_DESC value
                 * @property {number} ACCEPTED_AT_DESC=8 ACCEPTED_AT_DESC value
                 * @property {number} ACCEPTED_AT_ASC=9 ACCEPTED_AT_ASC value
                 */
                inventory.ShipFromStoreNotificationListSort = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PURCHASE_DATE_ASC"] = 0;
                    values[valuesById[1] = "PURCHASE_DATE_DESC"] = 1;
                    values[valuesById[2] = "PRIORITY_ASC"] = 2;
                    values[valuesById[3] = "PRIORITY_DESC"] = 3;
                    values[valuesById[4] = "ORDER_CANCEL_COUNTDOWN_TIME_ASC"] = 4;
                    values[valuesById[5] = "ORDER_CANCEL_COUNTDOWN_TIME_DESC"] = 5;
                    values[valuesById[6] = "DELIVERY_DATE_ASC"] = 6;
                    values[valuesById[7] = "DELIVERY_DATE_DESC"] = 7;
                    values[valuesById[8] = "ACCEPTED_AT_DESC"] = 8;
                    values[valuesById[9] = "ACCEPTED_AT_ASC"] = 9;
                    return values;
                })();

                inventory.MonthData = (function() {

                    /**
                     * Properties of a MonthData.
                     * @memberof theplant.ec.service.inventory
                     * @interface IMonthData
                     * @property {string|null} [date] MonthData date
                     * @property {number|Long|null} [deliveredNumber] MonthData deliveredNumber
                     * @property {number|Long|null} [deliveredItemNumber] MonthData deliveredItemNumber
                     * @property {number|Long|null} [salesAmountWithOutTax] MonthData salesAmountWithOutTax
                     */

                    /**
                     * Constructs a new MonthData.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a MonthData.
                     * @implements IMonthData
                     * @constructor
                     * @param {theplant.ec.service.inventory.IMonthData=} [properties] Properties to set
                     */
                    function MonthData(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MonthData date.
                     * @member {string} date
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @instance
                     */
                    MonthData.prototype.date = "";

                    /**
                     * MonthData deliveredNumber.
                     * @member {number|Long} deliveredNumber
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @instance
                     */
                    MonthData.prototype.deliveredNumber = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * MonthData deliveredItemNumber.
                     * @member {number|Long} deliveredItemNumber
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @instance
                     */
                    MonthData.prototype.deliveredItemNumber = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * MonthData salesAmountWithOutTax.
                     * @member {number|Long} salesAmountWithOutTax
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @instance
                     */
                    MonthData.prototype.salesAmountWithOutTax = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * Creates a new MonthData instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {theplant.ec.service.inventory.IMonthData=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.MonthData} MonthData instance
                     */
                    MonthData.create = function create(properties) {
                        return new MonthData(properties);
                    };

                    /**
                     * Encodes the specified MonthData message. Does not implicitly {@link theplant.ec.service.inventory.MonthData.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {theplant.ec.service.inventory.IMonthData} message MonthData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MonthData.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.date != null && message.hasOwnProperty("date"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.date);
                        if (message.deliveredNumber != null && message.hasOwnProperty("deliveredNumber"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.deliveredNumber);
                        if (message.deliveredItemNumber != null && message.hasOwnProperty("deliveredItemNumber"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.deliveredItemNumber);
                        if (message.salesAmountWithOutTax != null && message.hasOwnProperty("salesAmountWithOutTax"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.salesAmountWithOutTax);
                        return writer;
                    };

                    /**
                     * Encodes the specified MonthData message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.MonthData.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {theplant.ec.service.inventory.IMonthData} message MonthData message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MonthData.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MonthData message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.MonthData} MonthData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MonthData.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.MonthData();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.date = reader.string();
                                break;
                            case 2:
                                message.deliveredNumber = reader.uint64();
                                break;
                            case 3:
                                message.deliveredItemNumber = reader.uint64();
                                break;
                            case 4:
                                message.salesAmountWithOutTax = reader.uint64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MonthData message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.MonthData} MonthData
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MonthData.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MonthData message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MonthData.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.date != null && message.hasOwnProperty("date"))
                            if (!$util.isString(message.date))
                                return "date: string expected";
                        if (message.deliveredNumber != null && message.hasOwnProperty("deliveredNumber"))
                            if (!$util.isInteger(message.deliveredNumber) && !(message.deliveredNumber && $util.isInteger(message.deliveredNumber.low) && $util.isInteger(message.deliveredNumber.high)))
                                return "deliveredNumber: integer|Long expected";
                        if (message.deliveredItemNumber != null && message.hasOwnProperty("deliveredItemNumber"))
                            if (!$util.isInteger(message.deliveredItemNumber) && !(message.deliveredItemNumber && $util.isInteger(message.deliveredItemNumber.low) && $util.isInteger(message.deliveredItemNumber.high)))
                                return "deliveredItemNumber: integer|Long expected";
                        if (message.salesAmountWithOutTax != null && message.hasOwnProperty("salesAmountWithOutTax"))
                            if (!$util.isInteger(message.salesAmountWithOutTax) && !(message.salesAmountWithOutTax && $util.isInteger(message.salesAmountWithOutTax.low) && $util.isInteger(message.salesAmountWithOutTax.high)))
                                return "salesAmountWithOutTax: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a MonthData message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.MonthData} MonthData
                     */
                    MonthData.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.MonthData)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.MonthData();
                        if (object.date != null)
                            message.date = String(object.date);
                        if (object.deliveredNumber != null)
                            if ($util.Long)
                                (message.deliveredNumber = $util.Long.fromValue(object.deliveredNumber)).unsigned = true;
                            else if (typeof object.deliveredNumber === "string")
                                message.deliveredNumber = parseInt(object.deliveredNumber, 10);
                            else if (typeof object.deliveredNumber === "number")
                                message.deliveredNumber = object.deliveredNumber;
                            else if (typeof object.deliveredNumber === "object")
                                message.deliveredNumber = new $util.LongBits(object.deliveredNumber.low >>> 0, object.deliveredNumber.high >>> 0).toNumber(true);
                        if (object.deliveredItemNumber != null)
                            if ($util.Long)
                                (message.deliveredItemNumber = $util.Long.fromValue(object.deliveredItemNumber)).unsigned = true;
                            else if (typeof object.deliveredItemNumber === "string")
                                message.deliveredItemNumber = parseInt(object.deliveredItemNumber, 10);
                            else if (typeof object.deliveredItemNumber === "number")
                                message.deliveredItemNumber = object.deliveredItemNumber;
                            else if (typeof object.deliveredItemNumber === "object")
                                message.deliveredItemNumber = new $util.LongBits(object.deliveredItemNumber.low >>> 0, object.deliveredItemNumber.high >>> 0).toNumber(true);
                        if (object.salesAmountWithOutTax != null)
                            if ($util.Long)
                                (message.salesAmountWithOutTax = $util.Long.fromValue(object.salesAmountWithOutTax)).unsigned = true;
                            else if (typeof object.salesAmountWithOutTax === "string")
                                message.salesAmountWithOutTax = parseInt(object.salesAmountWithOutTax, 10);
                            else if (typeof object.salesAmountWithOutTax === "number")
                                message.salesAmountWithOutTax = object.salesAmountWithOutTax;
                            else if (typeof object.salesAmountWithOutTax === "object")
                                message.salesAmountWithOutTax = new $util.LongBits(object.salesAmountWithOutTax.low >>> 0, object.salesAmountWithOutTax.high >>> 0).toNumber(true);
                        return message;
                    };

                    /**
                     * Creates a plain object from a MonthData message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @static
                     * @param {theplant.ec.service.inventory.MonthData} message MonthData
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MonthData.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.date = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.deliveredNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.deliveredNumber = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.deliveredItemNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.deliveredItemNumber = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.salesAmountWithOutTax = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.salesAmountWithOutTax = options.longs === String ? "0" : 0;
                        }
                        if (message.date != null && message.hasOwnProperty("date"))
                            object.date = message.date;
                        if (message.deliveredNumber != null && message.hasOwnProperty("deliveredNumber"))
                            if (typeof message.deliveredNumber === "number")
                                object.deliveredNumber = options.longs === String ? String(message.deliveredNumber) : message.deliveredNumber;
                            else
                                object.deliveredNumber = options.longs === String ? $util.Long.prototype.toString.call(message.deliveredNumber) : options.longs === Number ? new $util.LongBits(message.deliveredNumber.low >>> 0, message.deliveredNumber.high >>> 0).toNumber(true) : message.deliveredNumber;
                        if (message.deliveredItemNumber != null && message.hasOwnProperty("deliveredItemNumber"))
                            if (typeof message.deliveredItemNumber === "number")
                                object.deliveredItemNumber = options.longs === String ? String(message.deliveredItemNumber) : message.deliveredItemNumber;
                            else
                                object.deliveredItemNumber = options.longs === String ? $util.Long.prototype.toString.call(message.deliveredItemNumber) : options.longs === Number ? new $util.LongBits(message.deliveredItemNumber.low >>> 0, message.deliveredItemNumber.high >>> 0).toNumber(true) : message.deliveredItemNumber;
                        if (message.salesAmountWithOutTax != null && message.hasOwnProperty("salesAmountWithOutTax"))
                            if (typeof message.salesAmountWithOutTax === "number")
                                object.salesAmountWithOutTax = options.longs === String ? String(message.salesAmountWithOutTax) : message.salesAmountWithOutTax;
                            else
                                object.salesAmountWithOutTax = options.longs === String ? $util.Long.prototype.toString.call(message.salesAmountWithOutTax) : options.longs === Number ? new $util.LongBits(message.salesAmountWithOutTax.low >>> 0, message.salesAmountWithOutTax.high >>> 0).toNumber(true) : message.salesAmountWithOutTax;
                        return object;
                    };

                    /**
                     * Converts this MonthData to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.MonthData
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MonthData.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MonthData;
                })();

                inventory.HistoryList = (function() {

                    /**
                     * Properties of a HistoryList.
                     * @memberof theplant.ec.service.inventory
                     * @interface IHistoryList
                     * @property {number|Long|null} [page] HistoryList page
                     * @property {number|Long|null} [perPage] HistoryList perPage
                     * @property {number|Long|null} [total] HistoryList total
                     * @property {Array.<theplant.ec.service.inventory.IMonthData>|null} [list] HistoryList list
                     */

                    /**
                     * Constructs a new HistoryList.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a HistoryList.
                     * @implements IHistoryList
                     * @constructor
                     * @param {theplant.ec.service.inventory.IHistoryList=} [properties] Properties to set
                     */
                    function HistoryList(properties) {
                        this.list = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * HistoryList page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @instance
                     */
                    HistoryList.prototype.page = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * HistoryList perPage.
                     * @member {number|Long} perPage
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @instance
                     */
                    HistoryList.prototype.perPage = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * HistoryList total.
                     * @member {number|Long} total
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @instance
                     */
                    HistoryList.prototype.total = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * HistoryList list.
                     * @member {Array.<theplant.ec.service.inventory.IMonthData>} list
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @instance
                     */
                    HistoryList.prototype.list = $util.emptyArray;

                    /**
                     * Creates a new HistoryList instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {theplant.ec.service.inventory.IHistoryList=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.HistoryList} HistoryList instance
                     */
                    HistoryList.create = function create(properties) {
                        return new HistoryList(properties);
                    };

                    /**
                     * Encodes the specified HistoryList message. Does not implicitly {@link theplant.ec.service.inventory.HistoryList.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {theplant.ec.service.inventory.IHistoryList} message HistoryList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HistoryList.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 1, wireType 0 =*/8).uint64(message.page);
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            writer.uint32(/* id 2, wireType 0 =*/16).uint64(message.perPage);
                        if (message.total != null && message.hasOwnProperty("total"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.total);
                        if (message.list != null && message.list.length)
                            for (var i = 0; i < message.list.length; ++i)
                                $root.theplant.ec.service.inventory.MonthData.encode(message.list[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified HistoryList message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.HistoryList.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {theplant.ec.service.inventory.IHistoryList} message HistoryList message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    HistoryList.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a HistoryList message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.HistoryList} HistoryList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HistoryList.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.HistoryList();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.page = reader.uint64();
                                break;
                            case 2:
                                message.perPage = reader.uint64();
                                break;
                            case 3:
                                message.total = reader.uint64();
                                break;
                            case 4:
                                if (!(message.list && message.list.length))
                                    message.list = [];
                                message.list.push($root.theplant.ec.service.inventory.MonthData.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a HistoryList message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.HistoryList} HistoryList
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    HistoryList.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a HistoryList message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    HistoryList.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (!$util.isInteger(message.perPage) && !(message.perPage && $util.isInteger(message.perPage.low) && $util.isInteger(message.perPage.high)))
                                return "perPage: integer|Long expected";
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                                return "total: integer|Long expected";
                        if (message.list != null && message.hasOwnProperty("list")) {
                            if (!Array.isArray(message.list))
                                return "list: array expected";
                            for (var i = 0; i < message.list.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.MonthData.verify(message.list[i]);
                                if (error)
                                    return "list." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a HistoryList message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.HistoryList} HistoryList
                     */
                    HistoryList.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.HistoryList)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.HistoryList();
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = true;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber(true);
                        if (object.perPage != null)
                            if ($util.Long)
                                (message.perPage = $util.Long.fromValue(object.perPage)).unsigned = true;
                            else if (typeof object.perPage === "string")
                                message.perPage = parseInt(object.perPage, 10);
                            else if (typeof object.perPage === "number")
                                message.perPage = object.perPage;
                            else if (typeof object.perPage === "object")
                                message.perPage = new $util.LongBits(object.perPage.low >>> 0, object.perPage.high >>> 0).toNumber(true);
                        if (object.total != null)
                            if ($util.Long)
                                (message.total = $util.Long.fromValue(object.total)).unsigned = true;
                            else if (typeof object.total === "string")
                                message.total = parseInt(object.total, 10);
                            else if (typeof object.total === "number")
                                message.total = object.total;
                            else if (typeof object.total === "object")
                                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber(true);
                        if (object.list) {
                            if (!Array.isArray(object.list))
                                throw TypeError(".theplant.ec.service.inventory.HistoryList.list: array expected");
                            message.list = [];
                            for (var i = 0; i < object.list.length; ++i) {
                                if (typeof object.list[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.HistoryList.list: object expected");
                                message.list[i] = $root.theplant.ec.service.inventory.MonthData.fromObject(object.list[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a HistoryList message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @static
                     * @param {theplant.ec.service.inventory.HistoryList} message HistoryList
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    HistoryList.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.list = [];
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.perPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.perPage = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.total = options.longs === String ? "0" : 0;
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber(true) : message.page;
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (typeof message.perPage === "number")
                                object.perPage = options.longs === String ? String(message.perPage) : message.perPage;
                            else
                                object.perPage = options.longs === String ? $util.Long.prototype.toString.call(message.perPage) : options.longs === Number ? new $util.LongBits(message.perPage.low >>> 0, message.perPage.high >>> 0).toNumber(true) : message.perPage;
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (typeof message.total === "number")
                                object.total = options.longs === String ? String(message.total) : message.total;
                            else
                                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber(true) : message.total;
                        if (message.list && message.list.length) {
                            object.list = [];
                            for (var j = 0; j < message.list.length; ++j)
                                object.list[j] = $root.theplant.ec.service.inventory.MonthData.toObject(message.list[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this HistoryList to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.HistoryList
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    HistoryList.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return HistoryList;
                })();

                inventory.Novelty = (function() {

                    /**
                     * Properties of a Novelty.
                     * @memberof theplant.ec.service.inventory
                     * @interface INovelty
                     * @property {string|null} [noveltyType] Novelty noveltyType
                     * @property {string|null} [noveltyName] Novelty noveltyName
                     * @property {boolean|null} [allowShipFromStore] Novelty allowShipFromStore
                     */

                    /**
                     * Constructs a new Novelty.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a Novelty.
                     * @implements INovelty
                     * @constructor
                     * @param {theplant.ec.service.inventory.INovelty=} [properties] Properties to set
                     */
                    function Novelty(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Novelty noveltyType.
                     * @member {string} noveltyType
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @instance
                     */
                    Novelty.prototype.noveltyType = "";

                    /**
                     * Novelty noveltyName.
                     * @member {string} noveltyName
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @instance
                     */
                    Novelty.prototype.noveltyName = "";

                    /**
                     * Novelty allowShipFromStore.
                     * @member {boolean} allowShipFromStore
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @instance
                     */
                    Novelty.prototype.allowShipFromStore = false;

                    /**
                     * Creates a new Novelty instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {theplant.ec.service.inventory.INovelty=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.Novelty} Novelty instance
                     */
                    Novelty.create = function create(properties) {
                        return new Novelty(properties);
                    };

                    /**
                     * Encodes the specified Novelty message. Does not implicitly {@link theplant.ec.service.inventory.Novelty.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {theplant.ec.service.inventory.INovelty} message Novelty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Novelty.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noveltyType != null && message.hasOwnProperty("noveltyType"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.noveltyType);
                        if (message.noveltyName != null && message.hasOwnProperty("noveltyName"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.noveltyName);
                        if (message.allowShipFromStore != null && message.hasOwnProperty("allowShipFromStore"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.allowShipFromStore);
                        return writer;
                    };

                    /**
                     * Encodes the specified Novelty message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.Novelty.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {theplant.ec.service.inventory.INovelty} message Novelty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Novelty.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Novelty message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.Novelty} Novelty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Novelty.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.Novelty();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.noveltyType = reader.string();
                                break;
                            case 2:
                                message.noveltyName = reader.string();
                                break;
                            case 3:
                                message.allowShipFromStore = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Novelty message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.Novelty} Novelty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Novelty.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Novelty message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Novelty.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noveltyType != null && message.hasOwnProperty("noveltyType"))
                            if (!$util.isString(message.noveltyType))
                                return "noveltyType: string expected";
                        if (message.noveltyName != null && message.hasOwnProperty("noveltyName"))
                            if (!$util.isString(message.noveltyName))
                                return "noveltyName: string expected";
                        if (message.allowShipFromStore != null && message.hasOwnProperty("allowShipFromStore"))
                            if (typeof message.allowShipFromStore !== "boolean")
                                return "allowShipFromStore: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Novelty message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.Novelty} Novelty
                     */
                    Novelty.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.Novelty)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.Novelty();
                        if (object.noveltyType != null)
                            message.noveltyType = String(object.noveltyType);
                        if (object.noveltyName != null)
                            message.noveltyName = String(object.noveltyName);
                        if (object.allowShipFromStore != null)
                            message.allowShipFromStore = Boolean(object.allowShipFromStore);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Novelty message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @static
                     * @param {theplant.ec.service.inventory.Novelty} message Novelty
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Novelty.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.noveltyType = "";
                            object.noveltyName = "";
                            object.allowShipFromStore = false;
                        }
                        if (message.noveltyType != null && message.hasOwnProperty("noveltyType"))
                            object.noveltyType = message.noveltyType;
                        if (message.noveltyName != null && message.hasOwnProperty("noveltyName"))
                            object.noveltyName = message.noveltyName;
                        if (message.allowShipFromStore != null && message.hasOwnProperty("allowShipFromStore"))
                            object.allowShipFromStore = message.allowShipFromStore;
                        return object;
                    };

                    /**
                     * Converts this Novelty to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.Novelty
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Novelty.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Novelty;
                })();

                inventory.Novelties = (function() {

                    /**
                     * Properties of a Novelties.
                     * @memberof theplant.ec.service.inventory
                     * @interface INovelties
                     * @property {Array.<theplant.ec.service.inventory.INovelty>|null} [noveltyInfos] Novelties noveltyInfos
                     */

                    /**
                     * Constructs a new Novelties.
                     * @memberof theplant.ec.service.inventory
                     * @classdesc Represents a Novelties.
                     * @implements INovelties
                     * @constructor
                     * @param {theplant.ec.service.inventory.INovelties=} [properties] Properties to set
                     */
                    function Novelties(properties) {
                        this.noveltyInfos = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Novelties noveltyInfos.
                     * @member {Array.<theplant.ec.service.inventory.INovelty>} noveltyInfos
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @instance
                     */
                    Novelties.prototype.noveltyInfos = $util.emptyArray;

                    /**
                     * Creates a new Novelties instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {theplant.ec.service.inventory.INovelties=} [properties] Properties to set
                     * @returns {theplant.ec.service.inventory.Novelties} Novelties instance
                     */
                    Novelties.create = function create(properties) {
                        return new Novelties(properties);
                    };

                    /**
                     * Encodes the specified Novelties message. Does not implicitly {@link theplant.ec.service.inventory.Novelties.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {theplant.ec.service.inventory.INovelties} message Novelties message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Novelties.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.noveltyInfos != null && message.noveltyInfos.length)
                            for (var i = 0; i < message.noveltyInfos.length; ++i)
                                $root.theplant.ec.service.inventory.Novelty.encode(message.noveltyInfos[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Novelties message, length delimited. Does not implicitly {@link theplant.ec.service.inventory.Novelties.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {theplant.ec.service.inventory.INovelties} message Novelties message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Novelties.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Novelties message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.inventory.Novelties} Novelties
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Novelties.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.inventory.Novelties();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.noveltyInfos && message.noveltyInfos.length))
                                    message.noveltyInfos = [];
                                message.noveltyInfos.push($root.theplant.ec.service.inventory.Novelty.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Novelties message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.inventory.Novelties} Novelties
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Novelties.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Novelties message.
                     * @function verify
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Novelties.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.noveltyInfos != null && message.hasOwnProperty("noveltyInfos")) {
                            if (!Array.isArray(message.noveltyInfos))
                                return "noveltyInfos: array expected";
                            for (var i = 0; i < message.noveltyInfos.length; ++i) {
                                var error = $root.theplant.ec.service.inventory.Novelty.verify(message.noveltyInfos[i]);
                                if (error)
                                    return "noveltyInfos." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Novelties message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.inventory.Novelties} Novelties
                     */
                    Novelties.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.inventory.Novelties)
                            return object;
                        var message = new $root.theplant.ec.service.inventory.Novelties();
                        if (object.noveltyInfos) {
                            if (!Array.isArray(object.noveltyInfos))
                                throw TypeError(".theplant.ec.service.inventory.Novelties.noveltyInfos: array expected");
                            message.noveltyInfos = [];
                            for (var i = 0; i < object.noveltyInfos.length; ++i) {
                                if (typeof object.noveltyInfos[i] !== "object")
                                    throw TypeError(".theplant.ec.service.inventory.Novelties.noveltyInfos: object expected");
                                message.noveltyInfos[i] = $root.theplant.ec.service.inventory.Novelty.fromObject(object.noveltyInfos[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Novelties message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @static
                     * @param {theplant.ec.service.inventory.Novelties} message Novelties
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Novelties.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.noveltyInfos = [];
                        if (message.noveltyInfos && message.noveltyInfos.length) {
                            object.noveltyInfos = [];
                            for (var j = 0; j < message.noveltyInfos.length; ++j)
                                object.noveltyInfos[j] = $root.theplant.ec.service.inventory.Novelty.toObject(message.noveltyInfos[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Novelties to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.inventory.Novelties
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Novelties.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Novelties;
                })();

                return inventory;
            })();

            service.menu = (function() {

                /**
                 * Namespace menu.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var menu = {};

                menu.MenuItem = (function() {

                    /**
                     * Properties of a MenuItem.
                     * @memberof theplant.ec.service.menu
                     * @interface IMenuItem
                     * @property {number|Long|null} [id] MenuItem id
                     * @property {string|null} [code] MenuItem code
                     * @property {string|null} [slug] MenuItem slug
                     * @property {string|null} [title] MenuItem title
                     * @property {string|null} [description] MenuItem description
                     * @property {string|null} [imageUrl] MenuItem imageUrl
                     * @property {number|null} [position] MenuItem position
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [extraProps] for extra properties attached to menu item
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [productFilterProps] MenuItem productFilterProps
                     * @property {string|null} [onSale] MenuItem onSale
                     * @property {boolean|null} [isInInventory] MenuItem isInInventory
                     * @property {boolean|null} [manuallyDisabled] MenuItem manuallyDisabled
                     * @property {boolean|null} [isProductFilter] MenuItem isProductFilter
                     * @property {number|Long|null} [productsCount] MenuItem productsCount
                     * @property {string|null} [linkUrl] MenuItem linkUrl
                     * @property {boolean|null} [hasDividerBelow] MenuItem hasDividerBelow
                     * @property {Array.<theplant.ec.service.menu.IMenuItem>|null} [items] MenuItem items
                     * @property {string|null} [imageLinkUrl] MenuItem imageLinkUrl
                     * @property {Array.<theplant.ec.service.base.IImage>|null} [images] MenuItem images
                     * @property {boolean|null} [hasSpaceBelow] MenuItem hasSpaceBelow
                     */

                    /**
                     * Constructs a new MenuItem.
                     * @memberof theplant.ec.service.menu
                     * @classdesc Represents a MenuItem.
                     * @implements IMenuItem
                     * @constructor
                     * @param {theplant.ec.service.menu.IMenuItem=} [properties] Properties to set
                     */
                    function MenuItem(properties) {
                        this.extraProps = [];
                        this.productFilterProps = [];
                        this.items = [];
                        this.images = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * MenuItem id.
                     * @member {number|Long} id
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.id = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * MenuItem code.
                     * @member {string} code
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.code = "";

                    /**
                     * MenuItem slug.
                     * @member {string} slug
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.slug = "";

                    /**
                     * MenuItem title.
                     * @member {string} title
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.title = "";

                    /**
                     * MenuItem description.
                     * @member {string} description
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.description = "";

                    /**
                     * MenuItem imageUrl.
                     * @member {string} imageUrl
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.imageUrl = "";

                    /**
                     * MenuItem position.
                     * @member {number} position
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.position = 0;

                    /**
                     * for extra properties attached to menu item
                     * @member {Array.<theplant.ec.service.base.IProperty>} extraProps
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.extraProps = $util.emptyArray;

                    /**
                     * MenuItem productFilterProps.
                     * @member {Array.<theplant.ec.service.base.IProperty>} productFilterProps
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.productFilterProps = $util.emptyArray;

                    /**
                     * MenuItem onSale.
                     * @member {string} onSale
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.onSale = "";

                    /**
                     * MenuItem isInInventory.
                     * @member {boolean} isInInventory
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.isInInventory = false;

                    /**
                     * MenuItem manuallyDisabled.
                     * @member {boolean} manuallyDisabled
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.manuallyDisabled = false;

                    /**
                     * MenuItem isProductFilter.
                     * @member {boolean} isProductFilter
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.isProductFilter = false;

                    /**
                     * MenuItem productsCount.
                     * @member {number|Long} productsCount
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.productsCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * MenuItem linkUrl.
                     * @member {string} linkUrl
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.linkUrl = "";

                    /**
                     * MenuItem hasDividerBelow.
                     * @member {boolean} hasDividerBelow
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.hasDividerBelow = false;

                    /**
                     * MenuItem items.
                     * @member {Array.<theplant.ec.service.menu.IMenuItem>} items
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.items = $util.emptyArray;

                    /**
                     * MenuItem imageLinkUrl.
                     * @member {string} imageLinkUrl
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.imageLinkUrl = "";

                    /**
                     * MenuItem images.
                     * @member {Array.<theplant.ec.service.base.IImage>} images
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.images = $util.emptyArray;

                    /**
                     * MenuItem hasSpaceBelow.
                     * @member {boolean} hasSpaceBelow
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     */
                    MenuItem.prototype.hasSpaceBelow = false;

                    /**
                     * Creates a new MenuItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {theplant.ec.service.menu.IMenuItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.menu.MenuItem} MenuItem instance
                     */
                    MenuItem.create = function create(properties) {
                        return new MenuItem(properties);
                    };

                    /**
                     * Encodes the specified MenuItem message. Does not implicitly {@link theplant.ec.service.menu.MenuItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {theplant.ec.service.menu.IMenuItem} message MenuItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MenuItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.id);
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                        if (message.slug != null && message.hasOwnProperty("slug"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.slug);
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.title);
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.description);
                        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.imageUrl);
                        if (message.position != null && message.hasOwnProperty("position"))
                            writer.uint32(/* id 7, wireType 5 =*/61).float(message.position);
                        if (message.extraProps != null && message.extraProps.length)
                            for (var i = 0; i < message.extraProps.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.extraProps[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.productFilterProps != null && message.productFilterProps.length)
                            for (var i = 0; i < message.productFilterProps.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.productFilterProps[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        if (message.onSale != null && message.hasOwnProperty("onSale"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.onSale);
                        if (message.isInInventory != null && message.hasOwnProperty("isInInventory"))
                            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isInInventory);
                        if (message.manuallyDisabled != null && message.hasOwnProperty("manuallyDisabled"))
                            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.manuallyDisabled);
                        if (message.isProductFilter != null && message.hasOwnProperty("isProductFilter"))
                            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isProductFilter);
                        if (message.productsCount != null && message.hasOwnProperty("productsCount"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.productsCount);
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.linkUrl);
                        if (message.hasDividerBelow != null && message.hasOwnProperty("hasDividerBelow"))
                            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.hasDividerBelow);
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.menu.MenuItem.encode(message.items[i], writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                        if (message.imageLinkUrl != null && message.hasOwnProperty("imageLinkUrl"))
                            writer.uint32(/* id 18, wireType 2 =*/146).string(message.imageLinkUrl);
                        if (message.images != null && message.images.length)
                            for (var i = 0; i < message.images.length; ++i)
                                $root.theplant.ec.service.base.Image.encode(message.images[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                        if (message.hasSpaceBelow != null && message.hasOwnProperty("hasSpaceBelow"))
                            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.hasSpaceBelow);
                        return writer;
                    };

                    /**
                     * Encodes the specified MenuItem message, length delimited. Does not implicitly {@link theplant.ec.service.menu.MenuItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {theplant.ec.service.menu.IMenuItem} message MenuItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    MenuItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a MenuItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.menu.MenuItem} MenuItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MenuItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.menu.MenuItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.int64();
                                break;
                            case 2:
                                message.code = reader.string();
                                break;
                            case 3:
                                message.slug = reader.string();
                                break;
                            case 4:
                                message.title = reader.string();
                                break;
                            case 5:
                                message.description = reader.string();
                                break;
                            case 6:
                                message.imageUrl = reader.string();
                                break;
                            case 7:
                                message.position = reader.float();
                                break;
                            case 8:
                                if (!(message.extraProps && message.extraProps.length))
                                    message.extraProps = [];
                                message.extraProps.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                if (!(message.productFilterProps && message.productFilterProps.length))
                                    message.productFilterProps = [];
                                message.productFilterProps.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                message.onSale = reader.string();
                                break;
                            case 11:
                                message.isInInventory = reader.bool();
                                break;
                            case 12:
                                message.manuallyDisabled = reader.bool();
                                break;
                            case 13:
                                message.isProductFilter = reader.bool();
                                break;
                            case 14:
                                message.productsCount = reader.int64();
                                break;
                            case 15:
                                message.linkUrl = reader.string();
                                break;
                            case 16:
                                message.hasDividerBelow = reader.bool();
                                break;
                            case 17:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.menu.MenuItem.decode(reader, reader.uint32()));
                                break;
                            case 18:
                                message.imageLinkUrl = reader.string();
                                break;
                            case 19:
                                if (!(message.images && message.images.length))
                                    message.images = [];
                                message.images.push($root.theplant.ec.service.base.Image.decode(reader, reader.uint32()));
                                break;
                            case 20:
                                message.hasSpaceBelow = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a MenuItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.menu.MenuItem} MenuItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    MenuItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a MenuItem message.
                     * @function verify
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    MenuItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isInteger(message.id) && !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high)))
                                return "id: integer|Long expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.slug != null && message.hasOwnProperty("slug"))
                            if (!$util.isString(message.slug))
                                return "slug: string expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                            if (!$util.isString(message.imageUrl))
                                return "imageUrl: string expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (typeof message.position !== "number")
                                return "position: number expected";
                        if (message.extraProps != null && message.hasOwnProperty("extraProps")) {
                            if (!Array.isArray(message.extraProps))
                                return "extraProps: array expected";
                            for (var i = 0; i < message.extraProps.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.extraProps[i]);
                                if (error)
                                    return "extraProps." + error;
                            }
                        }
                        if (message.productFilterProps != null && message.hasOwnProperty("productFilterProps")) {
                            if (!Array.isArray(message.productFilterProps))
                                return "productFilterProps: array expected";
                            for (var i = 0; i < message.productFilterProps.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.productFilterProps[i]);
                                if (error)
                                    return "productFilterProps." + error;
                            }
                        }
                        if (message.onSale != null && message.hasOwnProperty("onSale"))
                            if (!$util.isString(message.onSale))
                                return "onSale: string expected";
                        if (message.isInInventory != null && message.hasOwnProperty("isInInventory"))
                            if (typeof message.isInInventory !== "boolean")
                                return "isInInventory: boolean expected";
                        if (message.manuallyDisabled != null && message.hasOwnProperty("manuallyDisabled"))
                            if (typeof message.manuallyDisabled !== "boolean")
                                return "manuallyDisabled: boolean expected";
                        if (message.isProductFilter != null && message.hasOwnProperty("isProductFilter"))
                            if (typeof message.isProductFilter !== "boolean")
                                return "isProductFilter: boolean expected";
                        if (message.productsCount != null && message.hasOwnProperty("productsCount"))
                            if (!$util.isInteger(message.productsCount) && !(message.productsCount && $util.isInteger(message.productsCount.low) && $util.isInteger(message.productsCount.high)))
                                return "productsCount: integer|Long expected";
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            if (!$util.isString(message.linkUrl))
                                return "linkUrl: string expected";
                        if (message.hasDividerBelow != null && message.hasOwnProperty("hasDividerBelow"))
                            if (typeof message.hasDividerBelow !== "boolean")
                                return "hasDividerBelow: boolean expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.menu.MenuItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        if (message.imageLinkUrl != null && message.hasOwnProperty("imageLinkUrl"))
                            if (!$util.isString(message.imageLinkUrl))
                                return "imageLinkUrl: string expected";
                        if (message.images != null && message.hasOwnProperty("images")) {
                            if (!Array.isArray(message.images))
                                return "images: array expected";
                            for (var i = 0; i < message.images.length; ++i) {
                                var error = $root.theplant.ec.service.base.Image.verify(message.images[i]);
                                if (error)
                                    return "images." + error;
                            }
                        }
                        if (message.hasSpaceBelow != null && message.hasOwnProperty("hasSpaceBelow"))
                            if (typeof message.hasSpaceBelow !== "boolean")
                                return "hasSpaceBelow: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a MenuItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.menu.MenuItem} MenuItem
                     */
                    MenuItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.menu.MenuItem)
                            return object;
                        var message = new $root.theplant.ec.service.menu.MenuItem();
                        if (object.id != null)
                            if ($util.Long)
                                (message.id = $util.Long.fromValue(object.id)).unsigned = false;
                            else if (typeof object.id === "string")
                                message.id = parseInt(object.id, 10);
                            else if (typeof object.id === "number")
                                message.id = object.id;
                            else if (typeof object.id === "object")
                                message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber();
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.slug != null)
                            message.slug = String(object.slug);
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.imageUrl != null)
                            message.imageUrl = String(object.imageUrl);
                        if (object.position != null)
                            message.position = Number(object.position);
                        if (object.extraProps) {
                            if (!Array.isArray(object.extraProps))
                                throw TypeError(".theplant.ec.service.menu.MenuItem.extraProps: array expected");
                            message.extraProps = [];
                            for (var i = 0; i < object.extraProps.length; ++i) {
                                if (typeof object.extraProps[i] !== "object")
                                    throw TypeError(".theplant.ec.service.menu.MenuItem.extraProps: object expected");
                                message.extraProps[i] = $root.theplant.ec.service.base.Property.fromObject(object.extraProps[i]);
                            }
                        }
                        if (object.productFilterProps) {
                            if (!Array.isArray(object.productFilterProps))
                                throw TypeError(".theplant.ec.service.menu.MenuItem.productFilterProps: array expected");
                            message.productFilterProps = [];
                            for (var i = 0; i < object.productFilterProps.length; ++i) {
                                if (typeof object.productFilterProps[i] !== "object")
                                    throw TypeError(".theplant.ec.service.menu.MenuItem.productFilterProps: object expected");
                                message.productFilterProps[i] = $root.theplant.ec.service.base.Property.fromObject(object.productFilterProps[i]);
                            }
                        }
                        if (object.onSale != null)
                            message.onSale = String(object.onSale);
                        if (object.isInInventory != null)
                            message.isInInventory = Boolean(object.isInInventory);
                        if (object.manuallyDisabled != null)
                            message.manuallyDisabled = Boolean(object.manuallyDisabled);
                        if (object.isProductFilter != null)
                            message.isProductFilter = Boolean(object.isProductFilter);
                        if (object.productsCount != null)
                            if ($util.Long)
                                (message.productsCount = $util.Long.fromValue(object.productsCount)).unsigned = false;
                            else if (typeof object.productsCount === "string")
                                message.productsCount = parseInt(object.productsCount, 10);
                            else if (typeof object.productsCount === "number")
                                message.productsCount = object.productsCount;
                            else if (typeof object.productsCount === "object")
                                message.productsCount = new $util.LongBits(object.productsCount.low >>> 0, object.productsCount.high >>> 0).toNumber();
                        if (object.linkUrl != null)
                            message.linkUrl = String(object.linkUrl);
                        if (object.hasDividerBelow != null)
                            message.hasDividerBelow = Boolean(object.hasDividerBelow);
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.menu.MenuItem.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.menu.MenuItem.items: object expected");
                                message.items[i] = $root.theplant.ec.service.menu.MenuItem.fromObject(object.items[i]);
                            }
                        }
                        if (object.imageLinkUrl != null)
                            message.imageLinkUrl = String(object.imageLinkUrl);
                        if (object.images) {
                            if (!Array.isArray(object.images))
                                throw TypeError(".theplant.ec.service.menu.MenuItem.images: array expected");
                            message.images = [];
                            for (var i = 0; i < object.images.length; ++i) {
                                if (typeof object.images[i] !== "object")
                                    throw TypeError(".theplant.ec.service.menu.MenuItem.images: object expected");
                                message.images[i] = $root.theplant.ec.service.base.Image.fromObject(object.images[i]);
                            }
                        }
                        if (object.hasSpaceBelow != null)
                            message.hasSpaceBelow = Boolean(object.hasSpaceBelow);
                        return message;
                    };

                    /**
                     * Creates a plain object from a MenuItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @static
                     * @param {theplant.ec.service.menu.MenuItem} message MenuItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    MenuItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.extraProps = [];
                            object.productFilterProps = [];
                            object.items = [];
                            object.images = [];
                        }
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.id = options.longs === String ? "0" : 0;
                            object.code = "";
                            object.slug = "";
                            object.title = "";
                            object.description = "";
                            object.imageUrl = "";
                            object.position = 0;
                            object.onSale = "";
                            object.isInInventory = false;
                            object.manuallyDisabled = false;
                            object.isProductFilter = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.productsCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.productsCount = options.longs === String ? "0" : 0;
                            object.linkUrl = "";
                            object.hasDividerBelow = false;
                            object.imageLinkUrl = "";
                            object.hasSpaceBelow = false;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (typeof message.id === "number")
                                object.id = options.longs === String ? String(message.id) : message.id;
                            else
                                object.id = options.longs === String ? $util.Long.prototype.toString.call(message.id) : options.longs === Number ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber() : message.id;
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.slug != null && message.hasOwnProperty("slug"))
                            object.slug = message.slug;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                            object.imageUrl = message.imageUrl;
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = options.json && !isFinite(message.position) ? String(message.position) : message.position;
                        if (message.extraProps && message.extraProps.length) {
                            object.extraProps = [];
                            for (var j = 0; j < message.extraProps.length; ++j)
                                object.extraProps[j] = $root.theplant.ec.service.base.Property.toObject(message.extraProps[j], options);
                        }
                        if (message.productFilterProps && message.productFilterProps.length) {
                            object.productFilterProps = [];
                            for (var j = 0; j < message.productFilterProps.length; ++j)
                                object.productFilterProps[j] = $root.theplant.ec.service.base.Property.toObject(message.productFilterProps[j], options);
                        }
                        if (message.onSale != null && message.hasOwnProperty("onSale"))
                            object.onSale = message.onSale;
                        if (message.isInInventory != null && message.hasOwnProperty("isInInventory"))
                            object.isInInventory = message.isInInventory;
                        if (message.manuallyDisabled != null && message.hasOwnProperty("manuallyDisabled"))
                            object.manuallyDisabled = message.manuallyDisabled;
                        if (message.isProductFilter != null && message.hasOwnProperty("isProductFilter"))
                            object.isProductFilter = message.isProductFilter;
                        if (message.productsCount != null && message.hasOwnProperty("productsCount"))
                            if (typeof message.productsCount === "number")
                                object.productsCount = options.longs === String ? String(message.productsCount) : message.productsCount;
                            else
                                object.productsCount = options.longs === String ? $util.Long.prototype.toString.call(message.productsCount) : options.longs === Number ? new $util.LongBits(message.productsCount.low >>> 0, message.productsCount.high >>> 0).toNumber() : message.productsCount;
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            object.linkUrl = message.linkUrl;
                        if (message.hasDividerBelow != null && message.hasOwnProperty("hasDividerBelow"))
                            object.hasDividerBelow = message.hasDividerBelow;
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.menu.MenuItem.toObject(message.items[j], options);
                        }
                        if (message.imageLinkUrl != null && message.hasOwnProperty("imageLinkUrl"))
                            object.imageLinkUrl = message.imageLinkUrl;
                        if (message.images && message.images.length) {
                            object.images = [];
                            for (var j = 0; j < message.images.length; ++j)
                                object.images[j] = $root.theplant.ec.service.base.Image.toObject(message.images[j], options);
                        }
                        if (message.hasSpaceBelow != null && message.hasOwnProperty("hasSpaceBelow"))
                            object.hasSpaceBelow = message.hasSpaceBelow;
                        return object;
                    };

                    /**
                     * Converts this MenuItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.menu.MenuItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    MenuItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return MenuItem;
                })();

                return menu;
            })();

            service.points = (function() {

                /**
                 * Namespace points.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var points = {};

                /**
                 * OrderMarketingChannel enum.
                 * @name theplant.ec.service.points.OrderMarketingChannel
                 * @enum {string}
                 * @property {number} ORDER_AT_EC=0 ORDER_AT_EC value
                 * @property {number} ORDER_AT_SHOP=1 ORDER_AT_SHOP value
                 */
                points.OrderMarketingChannel = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ORDER_AT_EC"] = 0;
                    values[valuesById[1] = "ORDER_AT_SHOP"] = 1;
                    return values;
                })();

                points.RegisterOrderResult = (function() {

                    /**
                     * Properties of a RegisterOrderResult.
                     * @memberof theplant.ec.service.points
                     * @interface IRegisterOrderResult
                     * @property {string|null} [orderCode] RegisterOrderResult orderCode
                     * @property {number|Long|null} [givePoint] RegisterOrderResult givePoint
                     * @property {theplant.ec.service.points.ICustomerPointBase|null} [base] RegisterOrderResult base
                     */

                    /**
                     * Constructs a new RegisterOrderResult.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents a RegisterOrderResult.
                     * @implements IRegisterOrderResult
                     * @constructor
                     * @param {theplant.ec.service.points.IRegisterOrderResult=} [properties] Properties to set
                     */
                    function RegisterOrderResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RegisterOrderResult orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @instance
                     */
                    RegisterOrderResult.prototype.orderCode = "";

                    /**
                     * RegisterOrderResult givePoint.
                     * @member {number|Long} givePoint
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @instance
                     */
                    RegisterOrderResult.prototype.givePoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * RegisterOrderResult base.
                     * @member {theplant.ec.service.points.ICustomerPointBase|null|undefined} base
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @instance
                     */
                    RegisterOrderResult.prototype.base = null;

                    /**
                     * Creates a new RegisterOrderResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IRegisterOrderResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.RegisterOrderResult} RegisterOrderResult instance
                     */
                    RegisterOrderResult.create = function create(properties) {
                        return new RegisterOrderResult(properties);
                    };

                    /**
                     * Encodes the specified RegisterOrderResult message. Does not implicitly {@link theplant.ec.service.points.RegisterOrderResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IRegisterOrderResult} message RegisterOrderResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterOrderResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.givePoint);
                        if (message.base != null && message.hasOwnProperty("base"))
                            $root.theplant.ec.service.points.CustomerPointBase.encode(message.base, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RegisterOrderResult message, length delimited. Does not implicitly {@link theplant.ec.service.points.RegisterOrderResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IRegisterOrderResult} message RegisterOrderResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RegisterOrderResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RegisterOrderResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.RegisterOrderResult} RegisterOrderResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterOrderResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.RegisterOrderResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.givePoint = reader.int64();
                                break;
                            case 3:
                                message.base = $root.theplant.ec.service.points.CustomerPointBase.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RegisterOrderResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.RegisterOrderResult} RegisterOrderResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RegisterOrderResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RegisterOrderResult message.
                     * @function verify
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RegisterOrderResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                            if (!$util.isInteger(message.givePoint) && !(message.givePoint && $util.isInteger(message.givePoint.low) && $util.isInteger(message.givePoint.high)))
                                return "givePoint: integer|Long expected";
                        if (message.base != null && message.hasOwnProperty("base")) {
                            var error = $root.theplant.ec.service.points.CustomerPointBase.verify(message.base);
                            if (error)
                                return "base." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a RegisterOrderResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.RegisterOrderResult} RegisterOrderResult
                     */
                    RegisterOrderResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.RegisterOrderResult)
                            return object;
                        var message = new $root.theplant.ec.service.points.RegisterOrderResult();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.givePoint != null)
                            if ($util.Long)
                                (message.givePoint = $util.Long.fromValue(object.givePoint)).unsigned = false;
                            else if (typeof object.givePoint === "string")
                                message.givePoint = parseInt(object.givePoint, 10);
                            else if (typeof object.givePoint === "number")
                                message.givePoint = object.givePoint;
                            else if (typeof object.givePoint === "object")
                                message.givePoint = new $util.LongBits(object.givePoint.low >>> 0, object.givePoint.high >>> 0).toNumber();
                        if (object.base != null) {
                            if (typeof object.base !== "object")
                                throw TypeError(".theplant.ec.service.points.RegisterOrderResult.base: object expected");
                            message.base = $root.theplant.ec.service.points.CustomerPointBase.fromObject(object.base);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RegisterOrderResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.RegisterOrderResult} message RegisterOrderResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RegisterOrderResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.givePoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.givePoint = options.longs === String ? "0" : 0;
                            object.base = null;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                            if (typeof message.givePoint === "number")
                                object.givePoint = options.longs === String ? String(message.givePoint) : message.givePoint;
                            else
                                object.givePoint = options.longs === String ? $util.Long.prototype.toString.call(message.givePoint) : options.longs === Number ? new $util.LongBits(message.givePoint.low >>> 0, message.givePoint.high >>> 0).toNumber() : message.givePoint;
                        if (message.base != null && message.hasOwnProperty("base"))
                            object.base = $root.theplant.ec.service.points.CustomerPointBase.toObject(message.base, options);
                        return object;
                    };

                    /**
                     * Converts this RegisterOrderResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.RegisterOrderResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RegisterOrderResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RegisterOrderResult;
                })();

                points.UnregisterOrderResult = (function() {

                    /**
                     * Properties of an UnregisterOrderResult.
                     * @memberof theplant.ec.service.points
                     * @interface IUnregisterOrderResult
                     * @property {string|null} [orderCode] UnregisterOrderResult orderCode
                     * @property {number|Long|null} [cancelConfirmPoints] UnregisterOrderResult cancelConfirmPoints
                     * @property {number|Long|null} [cancelTemporaryPoints] UnregisterOrderResult cancelTemporaryPoints
                     * @property {number|Long|null} [cancelUsingPoints] UnregisterOrderResult cancelUsingPoints
                     * @property {number|Long|null} [notCancelPoints] UnregisterOrderResult notCancelPoints
                     * @property {number|Long|null} [returnPoints] UnregisterOrderResult returnPoints
                     * @property {number|Long|null} [cancelUsingCoupons] UnregisterOrderResult cancelUsingCoupons
                     * @property {number|Long|null} [periodFlg] UnregisterOrderResult periodFlg
                     */

                    /**
                     * Constructs a new UnregisterOrderResult.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents an UnregisterOrderResult.
                     * @implements IUnregisterOrderResult
                     * @constructor
                     * @param {theplant.ec.service.points.IUnregisterOrderResult=} [properties] Properties to set
                     */
                    function UnregisterOrderResult(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * UnregisterOrderResult orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.orderCode = "";

                    /**
                     * UnregisterOrderResult cancelConfirmPoints.
                     * @member {number|Long} cancelConfirmPoints
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.cancelConfirmPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UnregisterOrderResult cancelTemporaryPoints.
                     * @member {number|Long} cancelTemporaryPoints
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.cancelTemporaryPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UnregisterOrderResult cancelUsingPoints.
                     * @member {number|Long} cancelUsingPoints
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.cancelUsingPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UnregisterOrderResult notCancelPoints.
                     * @member {number|Long} notCancelPoints
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.notCancelPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UnregisterOrderResult returnPoints.
                     * @member {number|Long} returnPoints
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.returnPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UnregisterOrderResult cancelUsingCoupons.
                     * @member {number|Long} cancelUsingCoupons
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.cancelUsingCoupons = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * UnregisterOrderResult periodFlg.
                     * @member {number|Long} periodFlg
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     */
                    UnregisterOrderResult.prototype.periodFlg = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new UnregisterOrderResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IUnregisterOrderResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.UnregisterOrderResult} UnregisterOrderResult instance
                     */
                    UnregisterOrderResult.create = function create(properties) {
                        return new UnregisterOrderResult(properties);
                    };

                    /**
                     * Encodes the specified UnregisterOrderResult message. Does not implicitly {@link theplant.ec.service.points.UnregisterOrderResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IUnregisterOrderResult} message UnregisterOrderResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnregisterOrderResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.cancelConfirmPoints != null && message.hasOwnProperty("cancelConfirmPoints"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.cancelConfirmPoints);
                        if (message.cancelTemporaryPoints != null && message.hasOwnProperty("cancelTemporaryPoints"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.cancelTemporaryPoints);
                        if (message.cancelUsingPoints != null && message.hasOwnProperty("cancelUsingPoints"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.cancelUsingPoints);
                        if (message.notCancelPoints != null && message.hasOwnProperty("notCancelPoints"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.notCancelPoints);
                        if (message.returnPoints != null && message.hasOwnProperty("returnPoints"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.returnPoints);
                        if (message.cancelUsingCoupons != null && message.hasOwnProperty("cancelUsingCoupons"))
                            writer.uint32(/* id 7, wireType 0 =*/56).int64(message.cancelUsingCoupons);
                        if (message.periodFlg != null && message.hasOwnProperty("periodFlg"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.periodFlg);
                        return writer;
                    };

                    /**
                     * Encodes the specified UnregisterOrderResult message, length delimited. Does not implicitly {@link theplant.ec.service.points.UnregisterOrderResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IUnregisterOrderResult} message UnregisterOrderResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    UnregisterOrderResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an UnregisterOrderResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.UnregisterOrderResult} UnregisterOrderResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnregisterOrderResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.UnregisterOrderResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.cancelConfirmPoints = reader.int64();
                                break;
                            case 3:
                                message.cancelTemporaryPoints = reader.int64();
                                break;
                            case 4:
                                message.cancelUsingPoints = reader.int64();
                                break;
                            case 5:
                                message.notCancelPoints = reader.int64();
                                break;
                            case 6:
                                message.returnPoints = reader.int64();
                                break;
                            case 7:
                                message.cancelUsingCoupons = reader.int64();
                                break;
                            case 8:
                                message.periodFlg = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an UnregisterOrderResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.UnregisterOrderResult} UnregisterOrderResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    UnregisterOrderResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an UnregisterOrderResult message.
                     * @function verify
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    UnregisterOrderResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.cancelConfirmPoints != null && message.hasOwnProperty("cancelConfirmPoints"))
                            if (!$util.isInteger(message.cancelConfirmPoints) && !(message.cancelConfirmPoints && $util.isInteger(message.cancelConfirmPoints.low) && $util.isInteger(message.cancelConfirmPoints.high)))
                                return "cancelConfirmPoints: integer|Long expected";
                        if (message.cancelTemporaryPoints != null && message.hasOwnProperty("cancelTemporaryPoints"))
                            if (!$util.isInteger(message.cancelTemporaryPoints) && !(message.cancelTemporaryPoints && $util.isInteger(message.cancelTemporaryPoints.low) && $util.isInteger(message.cancelTemporaryPoints.high)))
                                return "cancelTemporaryPoints: integer|Long expected";
                        if (message.cancelUsingPoints != null && message.hasOwnProperty("cancelUsingPoints"))
                            if (!$util.isInteger(message.cancelUsingPoints) && !(message.cancelUsingPoints && $util.isInteger(message.cancelUsingPoints.low) && $util.isInteger(message.cancelUsingPoints.high)))
                                return "cancelUsingPoints: integer|Long expected";
                        if (message.notCancelPoints != null && message.hasOwnProperty("notCancelPoints"))
                            if (!$util.isInteger(message.notCancelPoints) && !(message.notCancelPoints && $util.isInteger(message.notCancelPoints.low) && $util.isInteger(message.notCancelPoints.high)))
                                return "notCancelPoints: integer|Long expected";
                        if (message.returnPoints != null && message.hasOwnProperty("returnPoints"))
                            if (!$util.isInteger(message.returnPoints) && !(message.returnPoints && $util.isInteger(message.returnPoints.low) && $util.isInteger(message.returnPoints.high)))
                                return "returnPoints: integer|Long expected";
                        if (message.cancelUsingCoupons != null && message.hasOwnProperty("cancelUsingCoupons"))
                            if (!$util.isInteger(message.cancelUsingCoupons) && !(message.cancelUsingCoupons && $util.isInteger(message.cancelUsingCoupons.low) && $util.isInteger(message.cancelUsingCoupons.high)))
                                return "cancelUsingCoupons: integer|Long expected";
                        if (message.periodFlg != null && message.hasOwnProperty("periodFlg"))
                            if (!$util.isInteger(message.periodFlg) && !(message.periodFlg && $util.isInteger(message.periodFlg.low) && $util.isInteger(message.periodFlg.high)))
                                return "periodFlg: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates an UnregisterOrderResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.UnregisterOrderResult} UnregisterOrderResult
                     */
                    UnregisterOrderResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.UnregisterOrderResult)
                            return object;
                        var message = new $root.theplant.ec.service.points.UnregisterOrderResult();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.cancelConfirmPoints != null)
                            if ($util.Long)
                                (message.cancelConfirmPoints = $util.Long.fromValue(object.cancelConfirmPoints)).unsigned = false;
                            else if (typeof object.cancelConfirmPoints === "string")
                                message.cancelConfirmPoints = parseInt(object.cancelConfirmPoints, 10);
                            else if (typeof object.cancelConfirmPoints === "number")
                                message.cancelConfirmPoints = object.cancelConfirmPoints;
                            else if (typeof object.cancelConfirmPoints === "object")
                                message.cancelConfirmPoints = new $util.LongBits(object.cancelConfirmPoints.low >>> 0, object.cancelConfirmPoints.high >>> 0).toNumber();
                        if (object.cancelTemporaryPoints != null)
                            if ($util.Long)
                                (message.cancelTemporaryPoints = $util.Long.fromValue(object.cancelTemporaryPoints)).unsigned = false;
                            else if (typeof object.cancelTemporaryPoints === "string")
                                message.cancelTemporaryPoints = parseInt(object.cancelTemporaryPoints, 10);
                            else if (typeof object.cancelTemporaryPoints === "number")
                                message.cancelTemporaryPoints = object.cancelTemporaryPoints;
                            else if (typeof object.cancelTemporaryPoints === "object")
                                message.cancelTemporaryPoints = new $util.LongBits(object.cancelTemporaryPoints.low >>> 0, object.cancelTemporaryPoints.high >>> 0).toNumber();
                        if (object.cancelUsingPoints != null)
                            if ($util.Long)
                                (message.cancelUsingPoints = $util.Long.fromValue(object.cancelUsingPoints)).unsigned = false;
                            else if (typeof object.cancelUsingPoints === "string")
                                message.cancelUsingPoints = parseInt(object.cancelUsingPoints, 10);
                            else if (typeof object.cancelUsingPoints === "number")
                                message.cancelUsingPoints = object.cancelUsingPoints;
                            else if (typeof object.cancelUsingPoints === "object")
                                message.cancelUsingPoints = new $util.LongBits(object.cancelUsingPoints.low >>> 0, object.cancelUsingPoints.high >>> 0).toNumber();
                        if (object.notCancelPoints != null)
                            if ($util.Long)
                                (message.notCancelPoints = $util.Long.fromValue(object.notCancelPoints)).unsigned = false;
                            else if (typeof object.notCancelPoints === "string")
                                message.notCancelPoints = parseInt(object.notCancelPoints, 10);
                            else if (typeof object.notCancelPoints === "number")
                                message.notCancelPoints = object.notCancelPoints;
                            else if (typeof object.notCancelPoints === "object")
                                message.notCancelPoints = new $util.LongBits(object.notCancelPoints.low >>> 0, object.notCancelPoints.high >>> 0).toNumber();
                        if (object.returnPoints != null)
                            if ($util.Long)
                                (message.returnPoints = $util.Long.fromValue(object.returnPoints)).unsigned = false;
                            else if (typeof object.returnPoints === "string")
                                message.returnPoints = parseInt(object.returnPoints, 10);
                            else if (typeof object.returnPoints === "number")
                                message.returnPoints = object.returnPoints;
                            else if (typeof object.returnPoints === "object")
                                message.returnPoints = new $util.LongBits(object.returnPoints.low >>> 0, object.returnPoints.high >>> 0).toNumber();
                        if (object.cancelUsingCoupons != null)
                            if ($util.Long)
                                (message.cancelUsingCoupons = $util.Long.fromValue(object.cancelUsingCoupons)).unsigned = false;
                            else if (typeof object.cancelUsingCoupons === "string")
                                message.cancelUsingCoupons = parseInt(object.cancelUsingCoupons, 10);
                            else if (typeof object.cancelUsingCoupons === "number")
                                message.cancelUsingCoupons = object.cancelUsingCoupons;
                            else if (typeof object.cancelUsingCoupons === "object")
                                message.cancelUsingCoupons = new $util.LongBits(object.cancelUsingCoupons.low >>> 0, object.cancelUsingCoupons.high >>> 0).toNumber();
                        if (object.periodFlg != null)
                            if ($util.Long)
                                (message.periodFlg = $util.Long.fromValue(object.periodFlg)).unsigned = false;
                            else if (typeof object.periodFlg === "string")
                                message.periodFlg = parseInt(object.periodFlg, 10);
                            else if (typeof object.periodFlg === "number")
                                message.periodFlg = object.periodFlg;
                            else if (typeof object.periodFlg === "object")
                                message.periodFlg = new $util.LongBits(object.periodFlg.low >>> 0, object.periodFlg.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from an UnregisterOrderResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @static
                     * @param {theplant.ec.service.points.UnregisterOrderResult} message UnregisterOrderResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    UnregisterOrderResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.orderCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cancelConfirmPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cancelConfirmPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cancelTemporaryPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cancelTemporaryPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cancelUsingPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cancelUsingPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.notCancelPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.notCancelPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.returnPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.returnPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.cancelUsingCoupons = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.cancelUsingCoupons = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.periodFlg = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.periodFlg = options.longs === String ? "0" : 0;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.cancelConfirmPoints != null && message.hasOwnProperty("cancelConfirmPoints"))
                            if (typeof message.cancelConfirmPoints === "number")
                                object.cancelConfirmPoints = options.longs === String ? String(message.cancelConfirmPoints) : message.cancelConfirmPoints;
                            else
                                object.cancelConfirmPoints = options.longs === String ? $util.Long.prototype.toString.call(message.cancelConfirmPoints) : options.longs === Number ? new $util.LongBits(message.cancelConfirmPoints.low >>> 0, message.cancelConfirmPoints.high >>> 0).toNumber() : message.cancelConfirmPoints;
                        if (message.cancelTemporaryPoints != null && message.hasOwnProperty("cancelTemporaryPoints"))
                            if (typeof message.cancelTemporaryPoints === "number")
                                object.cancelTemporaryPoints = options.longs === String ? String(message.cancelTemporaryPoints) : message.cancelTemporaryPoints;
                            else
                                object.cancelTemporaryPoints = options.longs === String ? $util.Long.prototype.toString.call(message.cancelTemporaryPoints) : options.longs === Number ? new $util.LongBits(message.cancelTemporaryPoints.low >>> 0, message.cancelTemporaryPoints.high >>> 0).toNumber() : message.cancelTemporaryPoints;
                        if (message.cancelUsingPoints != null && message.hasOwnProperty("cancelUsingPoints"))
                            if (typeof message.cancelUsingPoints === "number")
                                object.cancelUsingPoints = options.longs === String ? String(message.cancelUsingPoints) : message.cancelUsingPoints;
                            else
                                object.cancelUsingPoints = options.longs === String ? $util.Long.prototype.toString.call(message.cancelUsingPoints) : options.longs === Number ? new $util.LongBits(message.cancelUsingPoints.low >>> 0, message.cancelUsingPoints.high >>> 0).toNumber() : message.cancelUsingPoints;
                        if (message.notCancelPoints != null && message.hasOwnProperty("notCancelPoints"))
                            if (typeof message.notCancelPoints === "number")
                                object.notCancelPoints = options.longs === String ? String(message.notCancelPoints) : message.notCancelPoints;
                            else
                                object.notCancelPoints = options.longs === String ? $util.Long.prototype.toString.call(message.notCancelPoints) : options.longs === Number ? new $util.LongBits(message.notCancelPoints.low >>> 0, message.notCancelPoints.high >>> 0).toNumber() : message.notCancelPoints;
                        if (message.returnPoints != null && message.hasOwnProperty("returnPoints"))
                            if (typeof message.returnPoints === "number")
                                object.returnPoints = options.longs === String ? String(message.returnPoints) : message.returnPoints;
                            else
                                object.returnPoints = options.longs === String ? $util.Long.prototype.toString.call(message.returnPoints) : options.longs === Number ? new $util.LongBits(message.returnPoints.low >>> 0, message.returnPoints.high >>> 0).toNumber() : message.returnPoints;
                        if (message.cancelUsingCoupons != null && message.hasOwnProperty("cancelUsingCoupons"))
                            if (typeof message.cancelUsingCoupons === "number")
                                object.cancelUsingCoupons = options.longs === String ? String(message.cancelUsingCoupons) : message.cancelUsingCoupons;
                            else
                                object.cancelUsingCoupons = options.longs === String ? $util.Long.prototype.toString.call(message.cancelUsingCoupons) : options.longs === Number ? new $util.LongBits(message.cancelUsingCoupons.low >>> 0, message.cancelUsingCoupons.high >>> 0).toNumber() : message.cancelUsingCoupons;
                        if (message.periodFlg != null && message.hasOwnProperty("periodFlg"))
                            if (typeof message.periodFlg === "number")
                                object.periodFlg = options.longs === String ? String(message.periodFlg) : message.periodFlg;
                            else
                                object.periodFlg = options.longs === String ? $util.Long.prototype.toString.call(message.periodFlg) : options.longs === Number ? new $util.LongBits(message.periodFlg.low >>> 0, message.periodFlg.high >>> 0).toNumber() : message.periodFlg;
                        return object;
                    };

                    /**
                     * Converts this UnregisterOrderResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.UnregisterOrderResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    UnregisterOrderResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return UnregisterOrderResult;
                })();

                points.InquireOrderResult = (function() {

                    /**
                     * Properties of an InquireOrderResult.
                     * @memberof theplant.ec.service.points
                     * @interface IInquireOrderResult
                     * @property {string|null} [orderCode] InquireOrderResult orderCode
                     * @property {number|Long|null} [givePoint] InquireOrderResult givePoint
                     * @property {number|Long|null} [usedPoint] InquireOrderResult usedPoint
                     * @property {Array.<theplant.ec.service.points.InquireOrderResult.IInquireOrderItem>|null} [items] InquireOrderResult items
                     */

                    /**
                     * Constructs a new InquireOrderResult.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents an InquireOrderResult.
                     * @implements IInquireOrderResult
                     * @constructor
                     * @param {theplant.ec.service.points.IInquireOrderResult=} [properties] Properties to set
                     */
                    function InquireOrderResult(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InquireOrderResult orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @instance
                     */
                    InquireOrderResult.prototype.orderCode = "";

                    /**
                     * InquireOrderResult givePoint.
                     * @member {number|Long} givePoint
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @instance
                     */
                    InquireOrderResult.prototype.givePoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * InquireOrderResult usedPoint.
                     * @member {number|Long} usedPoint
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @instance
                     */
                    InquireOrderResult.prototype.usedPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * InquireOrderResult items.
                     * @member {Array.<theplant.ec.service.points.InquireOrderResult.IInquireOrderItem>} items
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @instance
                     */
                    InquireOrderResult.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new InquireOrderResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IInquireOrderResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.InquireOrderResult} InquireOrderResult instance
                     */
                    InquireOrderResult.create = function create(properties) {
                        return new InquireOrderResult(properties);
                    };

                    /**
                     * Encodes the specified InquireOrderResult message. Does not implicitly {@link theplant.ec.service.points.InquireOrderResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IInquireOrderResult} message InquireOrderResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InquireOrderResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.givePoint);
                        if (message.usedPoint != null && message.hasOwnProperty("usedPoint"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.usedPoint);
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem.encode(message.items[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified InquireOrderResult message, length delimited. Does not implicitly {@link theplant.ec.service.points.InquireOrderResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {theplant.ec.service.points.IInquireOrderResult} message InquireOrderResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InquireOrderResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InquireOrderResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.InquireOrderResult} InquireOrderResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InquireOrderResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.InquireOrderResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.givePoint = reader.int64();
                                break;
                            case 3:
                                message.usedPoint = reader.int64();
                                break;
                            case 4:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InquireOrderResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.InquireOrderResult} InquireOrderResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InquireOrderResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InquireOrderResult message.
                     * @function verify
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InquireOrderResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                            if (!$util.isInteger(message.givePoint) && !(message.givePoint && $util.isInteger(message.givePoint.low) && $util.isInteger(message.givePoint.high)))
                                return "givePoint: integer|Long expected";
                        if (message.usedPoint != null && message.hasOwnProperty("usedPoint"))
                            if (!$util.isInteger(message.usedPoint) && !(message.usedPoint && $util.isInteger(message.usedPoint.low) && $util.isInteger(message.usedPoint.high)))
                                return "usedPoint: integer|Long expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an InquireOrderResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.InquireOrderResult} InquireOrderResult
                     */
                    InquireOrderResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.InquireOrderResult)
                            return object;
                        var message = new $root.theplant.ec.service.points.InquireOrderResult();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.givePoint != null)
                            if ($util.Long)
                                (message.givePoint = $util.Long.fromValue(object.givePoint)).unsigned = false;
                            else if (typeof object.givePoint === "string")
                                message.givePoint = parseInt(object.givePoint, 10);
                            else if (typeof object.givePoint === "number")
                                message.givePoint = object.givePoint;
                            else if (typeof object.givePoint === "object")
                                message.givePoint = new $util.LongBits(object.givePoint.low >>> 0, object.givePoint.high >>> 0).toNumber();
                        if (object.usedPoint != null)
                            if ($util.Long)
                                (message.usedPoint = $util.Long.fromValue(object.usedPoint)).unsigned = false;
                            else if (typeof object.usedPoint === "string")
                                message.usedPoint = parseInt(object.usedPoint, 10);
                            else if (typeof object.usedPoint === "number")
                                message.usedPoint = object.usedPoint;
                            else if (typeof object.usedPoint === "object")
                                message.usedPoint = new $util.LongBits(object.usedPoint.low >>> 0, object.usedPoint.high >>> 0).toNumber();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.points.InquireOrderResult.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.points.InquireOrderResult.items: object expected");
                                message.items[i] = $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an InquireOrderResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @static
                     * @param {theplant.ec.service.points.InquireOrderResult} message InquireOrderResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InquireOrderResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (options.defaults) {
                            object.orderCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.givePoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.givePoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.usedPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.usedPoint = options.longs === String ? "0" : 0;
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                            if (typeof message.givePoint === "number")
                                object.givePoint = options.longs === String ? String(message.givePoint) : message.givePoint;
                            else
                                object.givePoint = options.longs === String ? $util.Long.prototype.toString.call(message.givePoint) : options.longs === Number ? new $util.LongBits(message.givePoint.low >>> 0, message.givePoint.high >>> 0).toNumber() : message.givePoint;
                        if (message.usedPoint != null && message.hasOwnProperty("usedPoint"))
                            if (typeof message.usedPoint === "number")
                                object.usedPoint = options.longs === String ? String(message.usedPoint) : message.usedPoint;
                            else
                                object.usedPoint = options.longs === String ? $util.Long.prototype.toString.call(message.usedPoint) : options.longs === Number ? new $util.LongBits(message.usedPoint.low >>> 0, message.usedPoint.high >>> 0).toNumber() : message.usedPoint;
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this InquireOrderResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.InquireOrderResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InquireOrderResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    InquireOrderResult.InquireOrderItem = (function() {

                        /**
                         * Properties of an InquireOrderItem.
                         * @memberof theplant.ec.service.points.InquireOrderResult
                         * @interface IInquireOrderItem
                         * @property {number|Long|null} [cancelQuantity] InquireOrderItem cancelQuantity
                         * @property {number|Long|null} [givePoint] InquireOrderItem givePoint
                         * @property {string|null} [productId] InquireOrderItem productId
                         * @property {number|Long|null} [productQuantity] InquireOrderItem productQuantity
                         * @property {string|null} [returnDate] InquireOrderItem returnDate
                         * @property {number|Long|null} [returnPoint] InquireOrderItem returnPoint
                         */

                        /**
                         * Constructs a new InquireOrderItem.
                         * @memberof theplant.ec.service.points.InquireOrderResult
                         * @classdesc Represents an InquireOrderItem.
                         * @implements IInquireOrderItem
                         * @constructor
                         * @param {theplant.ec.service.points.InquireOrderResult.IInquireOrderItem=} [properties] Properties to set
                         */
                        function InquireOrderItem(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * InquireOrderItem cancelQuantity.
                         * @member {number|Long} cancelQuantity
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         */
                        InquireOrderItem.prototype.cancelQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * InquireOrderItem givePoint.
                         * @member {number|Long} givePoint
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         */
                        InquireOrderItem.prototype.givePoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * InquireOrderItem productId.
                         * @member {string} productId
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         */
                        InquireOrderItem.prototype.productId = "";

                        /**
                         * InquireOrderItem productQuantity.
                         * @member {number|Long} productQuantity
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         */
                        InquireOrderItem.prototype.productQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * InquireOrderItem returnDate.
                         * @member {string} returnDate
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         */
                        InquireOrderItem.prototype.returnDate = "";

                        /**
                         * InquireOrderItem returnPoint.
                         * @member {number|Long} returnPoint
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         */
                        InquireOrderItem.prototype.returnPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * Creates a new InquireOrderItem instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {theplant.ec.service.points.InquireOrderResult.IInquireOrderItem=} [properties] Properties to set
                         * @returns {theplant.ec.service.points.InquireOrderResult.InquireOrderItem} InquireOrderItem instance
                         */
                        InquireOrderItem.create = function create(properties) {
                            return new InquireOrderItem(properties);
                        };

                        /**
                         * Encodes the specified InquireOrderItem message. Does not implicitly {@link theplant.ec.service.points.InquireOrderResult.InquireOrderItem.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {theplant.ec.service.points.InquireOrderResult.IInquireOrderItem} message InquireOrderItem message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        InquireOrderItem.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.cancelQuantity != null && message.hasOwnProperty("cancelQuantity"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cancelQuantity);
                            if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.givePoint);
                            if (message.productId != null && message.hasOwnProperty("productId"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.productId);
                            if (message.productQuantity != null && message.hasOwnProperty("productQuantity"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.productQuantity);
                            if (message.returnDate != null && message.hasOwnProperty("returnDate"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.returnDate);
                            if (message.returnPoint != null && message.hasOwnProperty("returnPoint"))
                                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.returnPoint);
                            return writer;
                        };

                        /**
                         * Encodes the specified InquireOrderItem message, length delimited. Does not implicitly {@link theplant.ec.service.points.InquireOrderResult.InquireOrderItem.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {theplant.ec.service.points.InquireOrderResult.IInquireOrderItem} message InquireOrderItem message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        InquireOrderItem.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an InquireOrderItem message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.points.InquireOrderResult.InquireOrderItem} InquireOrderItem
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        InquireOrderItem.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.cancelQuantity = reader.int64();
                                    break;
                                case 2:
                                    message.givePoint = reader.int64();
                                    break;
                                case 3:
                                    message.productId = reader.string();
                                    break;
                                case 4:
                                    message.productQuantity = reader.int64();
                                    break;
                                case 5:
                                    message.returnDate = reader.string();
                                    break;
                                case 6:
                                    message.returnPoint = reader.int64();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an InquireOrderItem message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.points.InquireOrderResult.InquireOrderItem} InquireOrderItem
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        InquireOrderItem.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an InquireOrderItem message.
                         * @function verify
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        InquireOrderItem.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.cancelQuantity != null && message.hasOwnProperty("cancelQuantity"))
                                if (!$util.isInteger(message.cancelQuantity) && !(message.cancelQuantity && $util.isInteger(message.cancelQuantity.low) && $util.isInteger(message.cancelQuantity.high)))
                                    return "cancelQuantity: integer|Long expected";
                            if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                                if (!$util.isInteger(message.givePoint) && !(message.givePoint && $util.isInteger(message.givePoint.low) && $util.isInteger(message.givePoint.high)))
                                    return "givePoint: integer|Long expected";
                            if (message.productId != null && message.hasOwnProperty("productId"))
                                if (!$util.isString(message.productId))
                                    return "productId: string expected";
                            if (message.productQuantity != null && message.hasOwnProperty("productQuantity"))
                                if (!$util.isInteger(message.productQuantity) && !(message.productQuantity && $util.isInteger(message.productQuantity.low) && $util.isInteger(message.productQuantity.high)))
                                    return "productQuantity: integer|Long expected";
                            if (message.returnDate != null && message.hasOwnProperty("returnDate"))
                                if (!$util.isString(message.returnDate))
                                    return "returnDate: string expected";
                            if (message.returnPoint != null && message.hasOwnProperty("returnPoint"))
                                if (!$util.isInteger(message.returnPoint) && !(message.returnPoint && $util.isInteger(message.returnPoint.low) && $util.isInteger(message.returnPoint.high)))
                                    return "returnPoint: integer|Long expected";
                            return null;
                        };

                        /**
                         * Creates an InquireOrderItem message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.points.InquireOrderResult.InquireOrderItem} InquireOrderItem
                         */
                        InquireOrderItem.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem)
                                return object;
                            var message = new $root.theplant.ec.service.points.InquireOrderResult.InquireOrderItem();
                            if (object.cancelQuantity != null)
                                if ($util.Long)
                                    (message.cancelQuantity = $util.Long.fromValue(object.cancelQuantity)).unsigned = false;
                                else if (typeof object.cancelQuantity === "string")
                                    message.cancelQuantity = parseInt(object.cancelQuantity, 10);
                                else if (typeof object.cancelQuantity === "number")
                                    message.cancelQuantity = object.cancelQuantity;
                                else if (typeof object.cancelQuantity === "object")
                                    message.cancelQuantity = new $util.LongBits(object.cancelQuantity.low >>> 0, object.cancelQuantity.high >>> 0).toNumber();
                            if (object.givePoint != null)
                                if ($util.Long)
                                    (message.givePoint = $util.Long.fromValue(object.givePoint)).unsigned = false;
                                else if (typeof object.givePoint === "string")
                                    message.givePoint = parseInt(object.givePoint, 10);
                                else if (typeof object.givePoint === "number")
                                    message.givePoint = object.givePoint;
                                else if (typeof object.givePoint === "object")
                                    message.givePoint = new $util.LongBits(object.givePoint.low >>> 0, object.givePoint.high >>> 0).toNumber();
                            if (object.productId != null)
                                message.productId = String(object.productId);
                            if (object.productQuantity != null)
                                if ($util.Long)
                                    (message.productQuantity = $util.Long.fromValue(object.productQuantity)).unsigned = false;
                                else if (typeof object.productQuantity === "string")
                                    message.productQuantity = parseInt(object.productQuantity, 10);
                                else if (typeof object.productQuantity === "number")
                                    message.productQuantity = object.productQuantity;
                                else if (typeof object.productQuantity === "object")
                                    message.productQuantity = new $util.LongBits(object.productQuantity.low >>> 0, object.productQuantity.high >>> 0).toNumber();
                            if (object.returnDate != null)
                                message.returnDate = String(object.returnDate);
                            if (object.returnPoint != null)
                                if ($util.Long)
                                    (message.returnPoint = $util.Long.fromValue(object.returnPoint)).unsigned = false;
                                else if (typeof object.returnPoint === "string")
                                    message.returnPoint = parseInt(object.returnPoint, 10);
                                else if (typeof object.returnPoint === "number")
                                    message.returnPoint = object.returnPoint;
                                else if (typeof object.returnPoint === "object")
                                    message.returnPoint = new $util.LongBits(object.returnPoint.low >>> 0, object.returnPoint.high >>> 0).toNumber();
                            return message;
                        };

                        /**
                         * Creates a plain object from an InquireOrderItem message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @static
                         * @param {theplant.ec.service.points.InquireOrderResult.InquireOrderItem} message InquireOrderItem
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        InquireOrderItem.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.cancelQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.cancelQuantity = options.longs === String ? "0" : 0;
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.givePoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.givePoint = options.longs === String ? "0" : 0;
                                object.productId = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.productQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.productQuantity = options.longs === String ? "0" : 0;
                                object.returnDate = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.returnPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.returnPoint = options.longs === String ? "0" : 0;
                            }
                            if (message.cancelQuantity != null && message.hasOwnProperty("cancelQuantity"))
                                if (typeof message.cancelQuantity === "number")
                                    object.cancelQuantity = options.longs === String ? String(message.cancelQuantity) : message.cancelQuantity;
                                else
                                    object.cancelQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.cancelQuantity) : options.longs === Number ? new $util.LongBits(message.cancelQuantity.low >>> 0, message.cancelQuantity.high >>> 0).toNumber() : message.cancelQuantity;
                            if (message.givePoint != null && message.hasOwnProperty("givePoint"))
                                if (typeof message.givePoint === "number")
                                    object.givePoint = options.longs === String ? String(message.givePoint) : message.givePoint;
                                else
                                    object.givePoint = options.longs === String ? $util.Long.prototype.toString.call(message.givePoint) : options.longs === Number ? new $util.LongBits(message.givePoint.low >>> 0, message.givePoint.high >>> 0).toNumber() : message.givePoint;
                            if (message.productId != null && message.hasOwnProperty("productId"))
                                object.productId = message.productId;
                            if (message.productQuantity != null && message.hasOwnProperty("productQuantity"))
                                if (typeof message.productQuantity === "number")
                                    object.productQuantity = options.longs === String ? String(message.productQuantity) : message.productQuantity;
                                else
                                    object.productQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.productQuantity) : options.longs === Number ? new $util.LongBits(message.productQuantity.low >>> 0, message.productQuantity.high >>> 0).toNumber() : message.productQuantity;
                            if (message.returnDate != null && message.hasOwnProperty("returnDate"))
                                object.returnDate = message.returnDate;
                            if (message.returnPoint != null && message.hasOwnProperty("returnPoint"))
                                if (typeof message.returnPoint === "number")
                                    object.returnPoint = options.longs === String ? String(message.returnPoint) : message.returnPoint;
                                else
                                    object.returnPoint = options.longs === String ? $util.Long.prototype.toString.call(message.returnPoint) : options.longs === Number ? new $util.LongBits(message.returnPoint.low >>> 0, message.returnPoint.high >>> 0).toNumber() : message.returnPoint;
                            return object;
                        };

                        /**
                         * Converts this InquireOrderItem to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.points.InquireOrderResult.InquireOrderItem
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        InquireOrderItem.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return InquireOrderItem;
                    })();

                    return InquireOrderResult;
                })();

                points.CustomerPointBase = (function() {

                    /**
                     * Properties of a CustomerPointBase.
                     * @memberof theplant.ec.service.points
                     * @interface ICustomerPointBase
                     * @property {string|null} [customerId] CustomerPointBase customerId
                     * @property {number|Long|null} [confirmPoint] CustomerPointBase confirmPoint
                     * @property {number|Long|null} [monthAvailablePoint] CustomerPointBase monthAvailablePoint
                     * @property {number|Long|null} [monthUsingPoints] CustomerPointBase monthUsingPoints
                     * @property {number|Long|null} [temporaryPoint] CustomerPointBase temporaryPoint
                     */

                    /**
                     * Constructs a new CustomerPointBase.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents a CustomerPointBase.
                     * @implements ICustomerPointBase
                     * @constructor
                     * @param {theplant.ec.service.points.ICustomerPointBase=} [properties] Properties to set
                     */
                    function CustomerPointBase(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomerPointBase customerId.
                     * @member {string} customerId
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @instance
                     */
                    CustomerPointBase.prototype.customerId = "";

                    /**
                     * CustomerPointBase confirmPoint.
                     * @member {number|Long} confirmPoint
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @instance
                     */
                    CustomerPointBase.prototype.confirmPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointBase monthAvailablePoint.
                     * @member {number|Long} monthAvailablePoint
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @instance
                     */
                    CustomerPointBase.prototype.monthAvailablePoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointBase monthUsingPoints.
                     * @member {number|Long} monthUsingPoints
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @instance
                     */
                    CustomerPointBase.prototype.monthUsingPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointBase temporaryPoint.
                     * @member {number|Long} temporaryPoint
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @instance
                     */
                    CustomerPointBase.prototype.temporaryPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new CustomerPointBase instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointBase=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.CustomerPointBase} CustomerPointBase instance
                     */
                    CustomerPointBase.create = function create(properties) {
                        return new CustomerPointBase(properties);
                    };

                    /**
                     * Encodes the specified CustomerPointBase message. Does not implicitly {@link theplant.ec.service.points.CustomerPointBase.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointBase} message CustomerPointBase message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointBase.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.customerId);
                        if (message.confirmPoint != null && message.hasOwnProperty("confirmPoint"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.confirmPoint);
                        if (message.monthAvailablePoint != null && message.hasOwnProperty("monthAvailablePoint"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.monthAvailablePoint);
                        if (message.monthUsingPoints != null && message.hasOwnProperty("monthUsingPoints"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.monthUsingPoints);
                        if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.temporaryPoint);
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomerPointBase message, length delimited. Does not implicitly {@link theplant.ec.service.points.CustomerPointBase.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointBase} message CustomerPointBase message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointBase.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomerPointBase message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.CustomerPointBase} CustomerPointBase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointBase.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.CustomerPointBase();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.customerId = reader.string();
                                break;
                            case 2:
                                message.confirmPoint = reader.int64();
                                break;
                            case 3:
                                message.monthAvailablePoint = reader.int64();
                                break;
                            case 4:
                                message.monthUsingPoints = reader.int64();
                                break;
                            case 5:
                                message.temporaryPoint = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomerPointBase message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.CustomerPointBase} CustomerPointBase
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointBase.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomerPointBase message.
                     * @function verify
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerPointBase.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            if (!$util.isString(message.customerId))
                                return "customerId: string expected";
                        if (message.confirmPoint != null && message.hasOwnProperty("confirmPoint"))
                            if (!$util.isInteger(message.confirmPoint) && !(message.confirmPoint && $util.isInteger(message.confirmPoint.low) && $util.isInteger(message.confirmPoint.high)))
                                return "confirmPoint: integer|Long expected";
                        if (message.monthAvailablePoint != null && message.hasOwnProperty("monthAvailablePoint"))
                            if (!$util.isInteger(message.monthAvailablePoint) && !(message.monthAvailablePoint && $util.isInteger(message.monthAvailablePoint.low) && $util.isInteger(message.monthAvailablePoint.high)))
                                return "monthAvailablePoint: integer|Long expected";
                        if (message.monthUsingPoints != null && message.hasOwnProperty("monthUsingPoints"))
                            if (!$util.isInteger(message.monthUsingPoints) && !(message.monthUsingPoints && $util.isInteger(message.monthUsingPoints.low) && $util.isInteger(message.monthUsingPoints.high)))
                                return "monthUsingPoints: integer|Long expected";
                        if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                            if (!$util.isInteger(message.temporaryPoint) && !(message.temporaryPoint && $util.isInteger(message.temporaryPoint.low) && $util.isInteger(message.temporaryPoint.high)))
                                return "temporaryPoint: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a CustomerPointBase message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.CustomerPointBase} CustomerPointBase
                     */
                    CustomerPointBase.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.CustomerPointBase)
                            return object;
                        var message = new $root.theplant.ec.service.points.CustomerPointBase();
                        if (object.customerId != null)
                            message.customerId = String(object.customerId);
                        if (object.confirmPoint != null)
                            if ($util.Long)
                                (message.confirmPoint = $util.Long.fromValue(object.confirmPoint)).unsigned = false;
                            else if (typeof object.confirmPoint === "string")
                                message.confirmPoint = parseInt(object.confirmPoint, 10);
                            else if (typeof object.confirmPoint === "number")
                                message.confirmPoint = object.confirmPoint;
                            else if (typeof object.confirmPoint === "object")
                                message.confirmPoint = new $util.LongBits(object.confirmPoint.low >>> 0, object.confirmPoint.high >>> 0).toNumber();
                        if (object.monthAvailablePoint != null)
                            if ($util.Long)
                                (message.monthAvailablePoint = $util.Long.fromValue(object.monthAvailablePoint)).unsigned = false;
                            else if (typeof object.monthAvailablePoint === "string")
                                message.monthAvailablePoint = parseInt(object.monthAvailablePoint, 10);
                            else if (typeof object.monthAvailablePoint === "number")
                                message.monthAvailablePoint = object.monthAvailablePoint;
                            else if (typeof object.monthAvailablePoint === "object")
                                message.monthAvailablePoint = new $util.LongBits(object.monthAvailablePoint.low >>> 0, object.monthAvailablePoint.high >>> 0).toNumber();
                        if (object.monthUsingPoints != null)
                            if ($util.Long)
                                (message.monthUsingPoints = $util.Long.fromValue(object.monthUsingPoints)).unsigned = false;
                            else if (typeof object.monthUsingPoints === "string")
                                message.monthUsingPoints = parseInt(object.monthUsingPoints, 10);
                            else if (typeof object.monthUsingPoints === "number")
                                message.monthUsingPoints = object.monthUsingPoints;
                            else if (typeof object.monthUsingPoints === "object")
                                message.monthUsingPoints = new $util.LongBits(object.monthUsingPoints.low >>> 0, object.monthUsingPoints.high >>> 0).toNumber();
                        if (object.temporaryPoint != null)
                            if ($util.Long)
                                (message.temporaryPoint = $util.Long.fromValue(object.temporaryPoint)).unsigned = false;
                            else if (typeof object.temporaryPoint === "string")
                                message.temporaryPoint = parseInt(object.temporaryPoint, 10);
                            else if (typeof object.temporaryPoint === "number")
                                message.temporaryPoint = object.temporaryPoint;
                            else if (typeof object.temporaryPoint === "object")
                                message.temporaryPoint = new $util.LongBits(object.temporaryPoint.low >>> 0, object.temporaryPoint.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomerPointBase message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @static
                     * @param {theplant.ec.service.points.CustomerPointBase} message CustomerPointBase
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerPointBase.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.customerId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.confirmPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.confirmPoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.monthAvailablePoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.monthAvailablePoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.monthUsingPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.monthUsingPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.temporaryPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.temporaryPoint = options.longs === String ? "0" : 0;
                        }
                        if (message.customerId != null && message.hasOwnProperty("customerId"))
                            object.customerId = message.customerId;
                        if (message.confirmPoint != null && message.hasOwnProperty("confirmPoint"))
                            if (typeof message.confirmPoint === "number")
                                object.confirmPoint = options.longs === String ? String(message.confirmPoint) : message.confirmPoint;
                            else
                                object.confirmPoint = options.longs === String ? $util.Long.prototype.toString.call(message.confirmPoint) : options.longs === Number ? new $util.LongBits(message.confirmPoint.low >>> 0, message.confirmPoint.high >>> 0).toNumber() : message.confirmPoint;
                        if (message.monthAvailablePoint != null && message.hasOwnProperty("monthAvailablePoint"))
                            if (typeof message.monthAvailablePoint === "number")
                                object.monthAvailablePoint = options.longs === String ? String(message.monthAvailablePoint) : message.monthAvailablePoint;
                            else
                                object.monthAvailablePoint = options.longs === String ? $util.Long.prototype.toString.call(message.monthAvailablePoint) : options.longs === Number ? new $util.LongBits(message.monthAvailablePoint.low >>> 0, message.monthAvailablePoint.high >>> 0).toNumber() : message.monthAvailablePoint;
                        if (message.monthUsingPoints != null && message.hasOwnProperty("monthUsingPoints"))
                            if (typeof message.monthUsingPoints === "number")
                                object.monthUsingPoints = options.longs === String ? String(message.monthUsingPoints) : message.monthUsingPoints;
                            else
                                object.monthUsingPoints = options.longs === String ? $util.Long.prototype.toString.call(message.monthUsingPoints) : options.longs === Number ? new $util.LongBits(message.monthUsingPoints.low >>> 0, message.monthUsingPoints.high >>> 0).toNumber() : message.monthUsingPoints;
                        if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                            if (typeof message.temporaryPoint === "number")
                                object.temporaryPoint = options.longs === String ? String(message.temporaryPoint) : message.temporaryPoint;
                            else
                                object.temporaryPoint = options.longs === String ? $util.Long.prototype.toString.call(message.temporaryPoint) : options.longs === Number ? new $util.LongBits(message.temporaryPoint.low >>> 0, message.temporaryPoint.high >>> 0).toNumber() : message.temporaryPoint;
                        return object;
                    };

                    /**
                     * Converts this CustomerPointBase to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.CustomerPointBase
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerPointBase.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CustomerPointBase;
                })();

                points.CustomerPointRank = (function() {

                    /**
                     * Properties of a CustomerPointRank.
                     * @memberof theplant.ec.service.points
                     * @interface ICustomerPointRank
                     * @property {theplant.ec.service.points.ICustomerPointBase|null} [base] CustomerPointRank base
                     * @property {number|Long|null} [campaignPoint] CustomerPointRank campaignPoint
                     * @property {number|Long|null} [neededAmount] CustomerPointRank neededAmount
                     * @property {string|null} [expiresoonDate] CustomerPointRank expiresoonDate
                     * @property {number|Long|null} [expiresoonMonthPoint] CustomerPointRank expiresoonMonthPoint
                     * @property {number|Long|null} [expiresoonPoint] CustomerPointRank expiresoonPoint
                     * @property {string|null} [nextRankName] CustomerPointRank nextRankName
                     * @property {string|null} [oldRankName] CustomerPointRank oldRankName
                     * @property {string|null} [rankChangeTime] CustomerPointRank rankChangeTime
                     * @property {string|null} [rankName] CustomerPointRank rankName
                     * @property {number|Long|null} [rankDefaultRatios] CustomerPointRank rankDefaultRatios
                     */

                    /**
                     * Constructs a new CustomerPointRank.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents a CustomerPointRank.
                     * @implements ICustomerPointRank
                     * @constructor
                     * @param {theplant.ec.service.points.ICustomerPointRank=} [properties] Properties to set
                     */
                    function CustomerPointRank(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomerPointRank base.
                     * @member {theplant.ec.service.points.ICustomerPointBase|null|undefined} base
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.base = null;

                    /**
                     * CustomerPointRank campaignPoint.
                     * @member {number|Long} campaignPoint
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.campaignPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRank neededAmount.
                     * @member {number|Long} neededAmount
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.neededAmount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRank expiresoonDate.
                     * @member {string} expiresoonDate
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.expiresoonDate = "";

                    /**
                     * CustomerPointRank expiresoonMonthPoint.
                     * @member {number|Long} expiresoonMonthPoint
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.expiresoonMonthPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRank expiresoonPoint.
                     * @member {number|Long} expiresoonPoint
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.expiresoonPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRank nextRankName.
                     * @member {string} nextRankName
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.nextRankName = "";

                    /**
                     * CustomerPointRank oldRankName.
                     * @member {string} oldRankName
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.oldRankName = "";

                    /**
                     * CustomerPointRank rankChangeTime.
                     * @member {string} rankChangeTime
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.rankChangeTime = "";

                    /**
                     * CustomerPointRank rankName.
                     * @member {string} rankName
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.rankName = "";

                    /**
                     * CustomerPointRank rankDefaultRatios.
                     * @member {number|Long} rankDefaultRatios
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     */
                    CustomerPointRank.prototype.rankDefaultRatios = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new CustomerPointRank instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointRank=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.CustomerPointRank} CustomerPointRank instance
                     */
                    CustomerPointRank.create = function create(properties) {
                        return new CustomerPointRank(properties);
                    };

                    /**
                     * Encodes the specified CustomerPointRank message. Does not implicitly {@link theplant.ec.service.points.CustomerPointRank.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointRank} message CustomerPointRank message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointRank.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.base != null && message.hasOwnProperty("base"))
                            $root.theplant.ec.service.points.CustomerPointBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.campaignPoint != null && message.hasOwnProperty("campaignPoint"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.campaignPoint);
                        if (message.neededAmount != null && message.hasOwnProperty("neededAmount"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.neededAmount);
                        if (message.expiresoonDate != null && message.hasOwnProperty("expiresoonDate"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.expiresoonDate);
                        if (message.expiresoonMonthPoint != null && message.hasOwnProperty("expiresoonMonthPoint"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.expiresoonMonthPoint);
                        if (message.expiresoonPoint != null && message.hasOwnProperty("expiresoonPoint"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.expiresoonPoint);
                        if (message.nextRankName != null && message.hasOwnProperty("nextRankName"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.nextRankName);
                        if (message.oldRankName != null && message.hasOwnProperty("oldRankName"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.oldRankName);
                        if (message.rankChangeTime != null && message.hasOwnProperty("rankChangeTime"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.rankChangeTime);
                        if (message.rankName != null && message.hasOwnProperty("rankName"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.rankName);
                        if (message.rankDefaultRatios != null && message.hasOwnProperty("rankDefaultRatios"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.rankDefaultRatios);
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomerPointRank message, length delimited. Does not implicitly {@link theplant.ec.service.points.CustomerPointRank.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointRank} message CustomerPointRank message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointRank.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomerPointRank message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.CustomerPointRank} CustomerPointRank
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointRank.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.CustomerPointRank();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.base = $root.theplant.ec.service.points.CustomerPointBase.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.campaignPoint = reader.int64();
                                break;
                            case 3:
                                message.neededAmount = reader.int64();
                                break;
                            case 4:
                                message.expiresoonDate = reader.string();
                                break;
                            case 5:
                                message.expiresoonMonthPoint = reader.int64();
                                break;
                            case 6:
                                message.expiresoonPoint = reader.int64();
                                break;
                            case 7:
                                message.nextRankName = reader.string();
                                break;
                            case 8:
                                message.oldRankName = reader.string();
                                break;
                            case 9:
                                message.rankChangeTime = reader.string();
                                break;
                            case 10:
                                message.rankName = reader.string();
                                break;
                            case 11:
                                message.rankDefaultRatios = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomerPointRank message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.CustomerPointRank} CustomerPointRank
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointRank.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomerPointRank message.
                     * @function verify
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerPointRank.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.base != null && message.hasOwnProperty("base")) {
                            var error = $root.theplant.ec.service.points.CustomerPointBase.verify(message.base);
                            if (error)
                                return "base." + error;
                        }
                        if (message.campaignPoint != null && message.hasOwnProperty("campaignPoint"))
                            if (!$util.isInteger(message.campaignPoint) && !(message.campaignPoint && $util.isInteger(message.campaignPoint.low) && $util.isInteger(message.campaignPoint.high)))
                                return "campaignPoint: integer|Long expected";
                        if (message.neededAmount != null && message.hasOwnProperty("neededAmount"))
                            if (!$util.isInteger(message.neededAmount) && !(message.neededAmount && $util.isInteger(message.neededAmount.low) && $util.isInteger(message.neededAmount.high)))
                                return "neededAmount: integer|Long expected";
                        if (message.expiresoonDate != null && message.hasOwnProperty("expiresoonDate"))
                            if (!$util.isString(message.expiresoonDate))
                                return "expiresoonDate: string expected";
                        if (message.expiresoonMonthPoint != null && message.hasOwnProperty("expiresoonMonthPoint"))
                            if (!$util.isInteger(message.expiresoonMonthPoint) && !(message.expiresoonMonthPoint && $util.isInteger(message.expiresoonMonthPoint.low) && $util.isInteger(message.expiresoonMonthPoint.high)))
                                return "expiresoonMonthPoint: integer|Long expected";
                        if (message.expiresoonPoint != null && message.hasOwnProperty("expiresoonPoint"))
                            if (!$util.isInteger(message.expiresoonPoint) && !(message.expiresoonPoint && $util.isInteger(message.expiresoonPoint.low) && $util.isInteger(message.expiresoonPoint.high)))
                                return "expiresoonPoint: integer|Long expected";
                        if (message.nextRankName != null && message.hasOwnProperty("nextRankName"))
                            if (!$util.isString(message.nextRankName))
                                return "nextRankName: string expected";
                        if (message.oldRankName != null && message.hasOwnProperty("oldRankName"))
                            if (!$util.isString(message.oldRankName))
                                return "oldRankName: string expected";
                        if (message.rankChangeTime != null && message.hasOwnProperty("rankChangeTime"))
                            if (!$util.isString(message.rankChangeTime))
                                return "rankChangeTime: string expected";
                        if (message.rankName != null && message.hasOwnProperty("rankName"))
                            if (!$util.isString(message.rankName))
                                return "rankName: string expected";
                        if (message.rankDefaultRatios != null && message.hasOwnProperty("rankDefaultRatios"))
                            if (!$util.isInteger(message.rankDefaultRatios) && !(message.rankDefaultRatios && $util.isInteger(message.rankDefaultRatios.low) && $util.isInteger(message.rankDefaultRatios.high)))
                                return "rankDefaultRatios: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a CustomerPointRank message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.CustomerPointRank} CustomerPointRank
                     */
                    CustomerPointRank.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.CustomerPointRank)
                            return object;
                        var message = new $root.theplant.ec.service.points.CustomerPointRank();
                        if (object.base != null) {
                            if (typeof object.base !== "object")
                                throw TypeError(".theplant.ec.service.points.CustomerPointRank.base: object expected");
                            message.base = $root.theplant.ec.service.points.CustomerPointBase.fromObject(object.base);
                        }
                        if (object.campaignPoint != null)
                            if ($util.Long)
                                (message.campaignPoint = $util.Long.fromValue(object.campaignPoint)).unsigned = false;
                            else if (typeof object.campaignPoint === "string")
                                message.campaignPoint = parseInt(object.campaignPoint, 10);
                            else if (typeof object.campaignPoint === "number")
                                message.campaignPoint = object.campaignPoint;
                            else if (typeof object.campaignPoint === "object")
                                message.campaignPoint = new $util.LongBits(object.campaignPoint.low >>> 0, object.campaignPoint.high >>> 0).toNumber();
                        if (object.neededAmount != null)
                            if ($util.Long)
                                (message.neededAmount = $util.Long.fromValue(object.neededAmount)).unsigned = false;
                            else if (typeof object.neededAmount === "string")
                                message.neededAmount = parseInt(object.neededAmount, 10);
                            else if (typeof object.neededAmount === "number")
                                message.neededAmount = object.neededAmount;
                            else if (typeof object.neededAmount === "object")
                                message.neededAmount = new $util.LongBits(object.neededAmount.low >>> 0, object.neededAmount.high >>> 0).toNumber();
                        if (object.expiresoonDate != null)
                            message.expiresoonDate = String(object.expiresoonDate);
                        if (object.expiresoonMonthPoint != null)
                            if ($util.Long)
                                (message.expiresoonMonthPoint = $util.Long.fromValue(object.expiresoonMonthPoint)).unsigned = false;
                            else if (typeof object.expiresoonMonthPoint === "string")
                                message.expiresoonMonthPoint = parseInt(object.expiresoonMonthPoint, 10);
                            else if (typeof object.expiresoonMonthPoint === "number")
                                message.expiresoonMonthPoint = object.expiresoonMonthPoint;
                            else if (typeof object.expiresoonMonthPoint === "object")
                                message.expiresoonMonthPoint = new $util.LongBits(object.expiresoonMonthPoint.low >>> 0, object.expiresoonMonthPoint.high >>> 0).toNumber();
                        if (object.expiresoonPoint != null)
                            if ($util.Long)
                                (message.expiresoonPoint = $util.Long.fromValue(object.expiresoonPoint)).unsigned = false;
                            else if (typeof object.expiresoonPoint === "string")
                                message.expiresoonPoint = parseInt(object.expiresoonPoint, 10);
                            else if (typeof object.expiresoonPoint === "number")
                                message.expiresoonPoint = object.expiresoonPoint;
                            else if (typeof object.expiresoonPoint === "object")
                                message.expiresoonPoint = new $util.LongBits(object.expiresoonPoint.low >>> 0, object.expiresoonPoint.high >>> 0).toNumber();
                        if (object.nextRankName != null)
                            message.nextRankName = String(object.nextRankName);
                        if (object.oldRankName != null)
                            message.oldRankName = String(object.oldRankName);
                        if (object.rankChangeTime != null)
                            message.rankChangeTime = String(object.rankChangeTime);
                        if (object.rankName != null)
                            message.rankName = String(object.rankName);
                        if (object.rankDefaultRatios != null)
                            if ($util.Long)
                                (message.rankDefaultRatios = $util.Long.fromValue(object.rankDefaultRatios)).unsigned = false;
                            else if (typeof object.rankDefaultRatios === "string")
                                message.rankDefaultRatios = parseInt(object.rankDefaultRatios, 10);
                            else if (typeof object.rankDefaultRatios === "number")
                                message.rankDefaultRatios = object.rankDefaultRatios;
                            else if (typeof object.rankDefaultRatios === "object")
                                message.rankDefaultRatios = new $util.LongBits(object.rankDefaultRatios.low >>> 0, object.rankDefaultRatios.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomerPointRank message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @static
                     * @param {theplant.ec.service.points.CustomerPointRank} message CustomerPointRank
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerPointRank.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.base = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.campaignPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.campaignPoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.neededAmount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.neededAmount = options.longs === String ? "0" : 0;
                            object.expiresoonDate = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.expiresoonMonthPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.expiresoonMonthPoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.expiresoonPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.expiresoonPoint = options.longs === String ? "0" : 0;
                            object.nextRankName = "";
                            object.oldRankName = "";
                            object.rankChangeTime = "";
                            object.rankName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.rankDefaultRatios = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.rankDefaultRatios = options.longs === String ? "0" : 0;
                        }
                        if (message.base != null && message.hasOwnProperty("base"))
                            object.base = $root.theplant.ec.service.points.CustomerPointBase.toObject(message.base, options);
                        if (message.campaignPoint != null && message.hasOwnProperty("campaignPoint"))
                            if (typeof message.campaignPoint === "number")
                                object.campaignPoint = options.longs === String ? String(message.campaignPoint) : message.campaignPoint;
                            else
                                object.campaignPoint = options.longs === String ? $util.Long.prototype.toString.call(message.campaignPoint) : options.longs === Number ? new $util.LongBits(message.campaignPoint.low >>> 0, message.campaignPoint.high >>> 0).toNumber() : message.campaignPoint;
                        if (message.neededAmount != null && message.hasOwnProperty("neededAmount"))
                            if (typeof message.neededAmount === "number")
                                object.neededAmount = options.longs === String ? String(message.neededAmount) : message.neededAmount;
                            else
                                object.neededAmount = options.longs === String ? $util.Long.prototype.toString.call(message.neededAmount) : options.longs === Number ? new $util.LongBits(message.neededAmount.low >>> 0, message.neededAmount.high >>> 0).toNumber() : message.neededAmount;
                        if (message.expiresoonDate != null && message.hasOwnProperty("expiresoonDate"))
                            object.expiresoonDate = message.expiresoonDate;
                        if (message.expiresoonMonthPoint != null && message.hasOwnProperty("expiresoonMonthPoint"))
                            if (typeof message.expiresoonMonthPoint === "number")
                                object.expiresoonMonthPoint = options.longs === String ? String(message.expiresoonMonthPoint) : message.expiresoonMonthPoint;
                            else
                                object.expiresoonMonthPoint = options.longs === String ? $util.Long.prototype.toString.call(message.expiresoonMonthPoint) : options.longs === Number ? new $util.LongBits(message.expiresoonMonthPoint.low >>> 0, message.expiresoonMonthPoint.high >>> 0).toNumber() : message.expiresoonMonthPoint;
                        if (message.expiresoonPoint != null && message.hasOwnProperty("expiresoonPoint"))
                            if (typeof message.expiresoonPoint === "number")
                                object.expiresoonPoint = options.longs === String ? String(message.expiresoonPoint) : message.expiresoonPoint;
                            else
                                object.expiresoonPoint = options.longs === String ? $util.Long.prototype.toString.call(message.expiresoonPoint) : options.longs === Number ? new $util.LongBits(message.expiresoonPoint.low >>> 0, message.expiresoonPoint.high >>> 0).toNumber() : message.expiresoonPoint;
                        if (message.nextRankName != null && message.hasOwnProperty("nextRankName"))
                            object.nextRankName = message.nextRankName;
                        if (message.oldRankName != null && message.hasOwnProperty("oldRankName"))
                            object.oldRankName = message.oldRankName;
                        if (message.rankChangeTime != null && message.hasOwnProperty("rankChangeTime"))
                            object.rankChangeTime = message.rankChangeTime;
                        if (message.rankName != null && message.hasOwnProperty("rankName"))
                            object.rankName = message.rankName;
                        if (message.rankDefaultRatios != null && message.hasOwnProperty("rankDefaultRatios"))
                            if (typeof message.rankDefaultRatios === "number")
                                object.rankDefaultRatios = options.longs === String ? String(message.rankDefaultRatios) : message.rankDefaultRatios;
                            else
                                object.rankDefaultRatios = options.longs === String ? $util.Long.prototype.toString.call(message.rankDefaultRatios) : options.longs === Number ? new $util.LongBits(message.rankDefaultRatios.low >>> 0, message.rankDefaultRatios.high >>> 0).toNumber() : message.rankDefaultRatios;
                        return object;
                    };

                    /**
                     * Converts this CustomerPointRank to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.CustomerPointRank
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerPointRank.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CustomerPointRank;
                })();

                points.CustomerPointRecord = (function() {

                    /**
                     * Properties of a CustomerPointRecord.
                     * @memberof theplant.ec.service.points
                     * @interface ICustomerPointRecord
                     * @property {string|null} [orderCode] CustomerPointRecord orderCode
                     * @property {string|null} [shopId] CustomerPointRecord shopId
                     * @property {number|Long|null} [amountInvolved] CustomerPointRecord amountInvolved
                     * @property {number|Long|null} [confirmPoint] CustomerPointRecord confirmPoint
                     * @property {number|Long|null} [temporaryPoint] CustomerPointRecord temporaryPoint
                     * @property {number|Long|null} [usePoint] CustomerPointRecord usePoint
                     * @property {string|null} [changeDate] CustomerPointRecord changeDate
                     * @property {string|null} [changeReason] CustomerPointRecord changeReason
                     * @property {string|null} [deadline] CustomerPointRecord deadline
                     * @property {string|null} [usableDate] CustomerPointRecord usableDate
                     * @property {Array.<theplant.ec.service.points.CustomerPointRecord.IDetailReason>|null} [details] CustomerPointRecord details
                     */

                    /**
                     * Constructs a new CustomerPointRecord.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents a CustomerPointRecord.
                     * @implements ICustomerPointRecord
                     * @constructor
                     * @param {theplant.ec.service.points.ICustomerPointRecord=} [properties] Properties to set
                     */
                    function CustomerPointRecord(properties) {
                        this.details = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomerPointRecord orderCode.
                     * @member {string} orderCode
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.orderCode = "";

                    /**
                     * CustomerPointRecord shopId.
                     * @member {string} shopId
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.shopId = "";

                    /**
                     * CustomerPointRecord amountInvolved.
                     * @member {number|Long} amountInvolved
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.amountInvolved = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRecord confirmPoint.
                     * @member {number|Long} confirmPoint
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.confirmPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRecord temporaryPoint.
                     * @member {number|Long} temporaryPoint
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.temporaryPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRecord usePoint.
                     * @member {number|Long} usePoint
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.usePoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * CustomerPointRecord changeDate.
                     * @member {string} changeDate
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.changeDate = "";

                    /**
                     * CustomerPointRecord changeReason.
                     * @member {string} changeReason
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.changeReason = "";

                    /**
                     * CustomerPointRecord deadline.
                     * @member {string} deadline
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.deadline = "";

                    /**
                     * CustomerPointRecord usableDate.
                     * @member {string} usableDate
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.usableDate = "";

                    /**
                     * CustomerPointRecord details.
                     * @member {Array.<theplant.ec.service.points.CustomerPointRecord.IDetailReason>} details
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     */
                    CustomerPointRecord.prototype.details = $util.emptyArray;

                    /**
                     * Creates a new CustomerPointRecord instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointRecord=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.CustomerPointRecord} CustomerPointRecord instance
                     */
                    CustomerPointRecord.create = function create(properties) {
                        return new CustomerPointRecord(properties);
                    };

                    /**
                     * Encodes the specified CustomerPointRecord message. Does not implicitly {@link theplant.ec.service.points.CustomerPointRecord.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointRecord} message CustomerPointRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointRecord.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderCode);
                        if (message.shopId != null && message.hasOwnProperty("shopId"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.shopId);
                        if (message.amountInvolved != null && message.hasOwnProperty("amountInvolved"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.amountInvolved);
                        if (message.confirmPoint != null && message.hasOwnProperty("confirmPoint"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.confirmPoint);
                        if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.temporaryPoint);
                        if (message.usePoint != null && message.hasOwnProperty("usePoint"))
                            writer.uint32(/* id 6, wireType 0 =*/48).int64(message.usePoint);
                        if (message.changeDate != null && message.hasOwnProperty("changeDate"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.changeDate);
                        if (message.changeReason != null && message.hasOwnProperty("changeReason"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.changeReason);
                        if (message.deadline != null && message.hasOwnProperty("deadline"))
                            writer.uint32(/* id 9, wireType 2 =*/74).string(message.deadline);
                        if (message.usableDate != null && message.hasOwnProperty("usableDate"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.usableDate);
                        if (message.details != null && message.details.length)
                            for (var i = 0; i < message.details.length; ++i)
                                $root.theplant.ec.service.points.CustomerPointRecord.DetailReason.encode(message.details[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomerPointRecord message, length delimited. Does not implicitly {@link theplant.ec.service.points.CustomerPointRecord.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointRecord} message CustomerPointRecord message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointRecord.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomerPointRecord message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.CustomerPointRecord} CustomerPointRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointRecord.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.CustomerPointRecord();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.orderCode = reader.string();
                                break;
                            case 2:
                                message.shopId = reader.string();
                                break;
                            case 3:
                                message.amountInvolved = reader.int64();
                                break;
                            case 4:
                                message.confirmPoint = reader.int64();
                                break;
                            case 5:
                                message.temporaryPoint = reader.int64();
                                break;
                            case 6:
                                message.usePoint = reader.int64();
                                break;
                            case 7:
                                message.changeDate = reader.string();
                                break;
                            case 8:
                                message.changeReason = reader.string();
                                break;
                            case 9:
                                message.deadline = reader.string();
                                break;
                            case 10:
                                message.usableDate = reader.string();
                                break;
                            case 11:
                                if (!(message.details && message.details.length))
                                    message.details = [];
                                message.details.push($root.theplant.ec.service.points.CustomerPointRecord.DetailReason.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomerPointRecord message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.CustomerPointRecord} CustomerPointRecord
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointRecord.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomerPointRecord message.
                     * @function verify
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerPointRecord.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            if (!$util.isString(message.orderCode))
                                return "orderCode: string expected";
                        if (message.shopId != null && message.hasOwnProperty("shopId"))
                            if (!$util.isString(message.shopId))
                                return "shopId: string expected";
                        if (message.amountInvolved != null && message.hasOwnProperty("amountInvolved"))
                            if (!$util.isInteger(message.amountInvolved) && !(message.amountInvolved && $util.isInteger(message.amountInvolved.low) && $util.isInteger(message.amountInvolved.high)))
                                return "amountInvolved: integer|Long expected";
                        if (message.confirmPoint != null && message.hasOwnProperty("confirmPoint"))
                            if (!$util.isInteger(message.confirmPoint) && !(message.confirmPoint && $util.isInteger(message.confirmPoint.low) && $util.isInteger(message.confirmPoint.high)))
                                return "confirmPoint: integer|Long expected";
                        if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                            if (!$util.isInteger(message.temporaryPoint) && !(message.temporaryPoint && $util.isInteger(message.temporaryPoint.low) && $util.isInteger(message.temporaryPoint.high)))
                                return "temporaryPoint: integer|Long expected";
                        if (message.usePoint != null && message.hasOwnProperty("usePoint"))
                            if (!$util.isInteger(message.usePoint) && !(message.usePoint && $util.isInteger(message.usePoint.low) && $util.isInteger(message.usePoint.high)))
                                return "usePoint: integer|Long expected";
                        if (message.changeDate != null && message.hasOwnProperty("changeDate"))
                            if (!$util.isString(message.changeDate))
                                return "changeDate: string expected";
                        if (message.changeReason != null && message.hasOwnProperty("changeReason"))
                            if (!$util.isString(message.changeReason))
                                return "changeReason: string expected";
                        if (message.deadline != null && message.hasOwnProperty("deadline"))
                            if (!$util.isString(message.deadline))
                                return "deadline: string expected";
                        if (message.usableDate != null && message.hasOwnProperty("usableDate"))
                            if (!$util.isString(message.usableDate))
                                return "usableDate: string expected";
                        if (message.details != null && message.hasOwnProperty("details")) {
                            if (!Array.isArray(message.details))
                                return "details: array expected";
                            for (var i = 0; i < message.details.length; ++i) {
                                var error = $root.theplant.ec.service.points.CustomerPointRecord.DetailReason.verify(message.details[i]);
                                if (error)
                                    return "details." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a CustomerPointRecord message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.CustomerPointRecord} CustomerPointRecord
                     */
                    CustomerPointRecord.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.CustomerPointRecord)
                            return object;
                        var message = new $root.theplant.ec.service.points.CustomerPointRecord();
                        if (object.orderCode != null)
                            message.orderCode = String(object.orderCode);
                        if (object.shopId != null)
                            message.shopId = String(object.shopId);
                        if (object.amountInvolved != null)
                            if ($util.Long)
                                (message.amountInvolved = $util.Long.fromValue(object.amountInvolved)).unsigned = false;
                            else if (typeof object.amountInvolved === "string")
                                message.amountInvolved = parseInt(object.amountInvolved, 10);
                            else if (typeof object.amountInvolved === "number")
                                message.amountInvolved = object.amountInvolved;
                            else if (typeof object.amountInvolved === "object")
                                message.amountInvolved = new $util.LongBits(object.amountInvolved.low >>> 0, object.amountInvolved.high >>> 0).toNumber();
                        if (object.confirmPoint != null)
                            if ($util.Long)
                                (message.confirmPoint = $util.Long.fromValue(object.confirmPoint)).unsigned = false;
                            else if (typeof object.confirmPoint === "string")
                                message.confirmPoint = parseInt(object.confirmPoint, 10);
                            else if (typeof object.confirmPoint === "number")
                                message.confirmPoint = object.confirmPoint;
                            else if (typeof object.confirmPoint === "object")
                                message.confirmPoint = new $util.LongBits(object.confirmPoint.low >>> 0, object.confirmPoint.high >>> 0).toNumber();
                        if (object.temporaryPoint != null)
                            if ($util.Long)
                                (message.temporaryPoint = $util.Long.fromValue(object.temporaryPoint)).unsigned = false;
                            else if (typeof object.temporaryPoint === "string")
                                message.temporaryPoint = parseInt(object.temporaryPoint, 10);
                            else if (typeof object.temporaryPoint === "number")
                                message.temporaryPoint = object.temporaryPoint;
                            else if (typeof object.temporaryPoint === "object")
                                message.temporaryPoint = new $util.LongBits(object.temporaryPoint.low >>> 0, object.temporaryPoint.high >>> 0).toNumber();
                        if (object.usePoint != null)
                            if ($util.Long)
                                (message.usePoint = $util.Long.fromValue(object.usePoint)).unsigned = false;
                            else if (typeof object.usePoint === "string")
                                message.usePoint = parseInt(object.usePoint, 10);
                            else if (typeof object.usePoint === "number")
                                message.usePoint = object.usePoint;
                            else if (typeof object.usePoint === "object")
                                message.usePoint = new $util.LongBits(object.usePoint.low >>> 0, object.usePoint.high >>> 0).toNumber();
                        if (object.changeDate != null)
                            message.changeDate = String(object.changeDate);
                        if (object.changeReason != null)
                            message.changeReason = String(object.changeReason);
                        if (object.deadline != null)
                            message.deadline = String(object.deadline);
                        if (object.usableDate != null)
                            message.usableDate = String(object.usableDate);
                        if (object.details) {
                            if (!Array.isArray(object.details))
                                throw TypeError(".theplant.ec.service.points.CustomerPointRecord.details: array expected");
                            message.details = [];
                            for (var i = 0; i < object.details.length; ++i) {
                                if (typeof object.details[i] !== "object")
                                    throw TypeError(".theplant.ec.service.points.CustomerPointRecord.details: object expected");
                                message.details[i] = $root.theplant.ec.service.points.CustomerPointRecord.DetailReason.fromObject(object.details[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomerPointRecord message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @static
                     * @param {theplant.ec.service.points.CustomerPointRecord} message CustomerPointRecord
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerPointRecord.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.details = [];
                        if (options.defaults) {
                            object.orderCode = "";
                            object.shopId = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.amountInvolved = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.amountInvolved = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.confirmPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.confirmPoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.temporaryPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.temporaryPoint = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.usePoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.usePoint = options.longs === String ? "0" : 0;
                            object.changeDate = "";
                            object.changeReason = "";
                            object.deadline = "";
                            object.usableDate = "";
                        }
                        if (message.orderCode != null && message.hasOwnProperty("orderCode"))
                            object.orderCode = message.orderCode;
                        if (message.shopId != null && message.hasOwnProperty("shopId"))
                            object.shopId = message.shopId;
                        if (message.amountInvolved != null && message.hasOwnProperty("amountInvolved"))
                            if (typeof message.amountInvolved === "number")
                                object.amountInvolved = options.longs === String ? String(message.amountInvolved) : message.amountInvolved;
                            else
                                object.amountInvolved = options.longs === String ? $util.Long.prototype.toString.call(message.amountInvolved) : options.longs === Number ? new $util.LongBits(message.amountInvolved.low >>> 0, message.amountInvolved.high >>> 0).toNumber() : message.amountInvolved;
                        if (message.confirmPoint != null && message.hasOwnProperty("confirmPoint"))
                            if (typeof message.confirmPoint === "number")
                                object.confirmPoint = options.longs === String ? String(message.confirmPoint) : message.confirmPoint;
                            else
                                object.confirmPoint = options.longs === String ? $util.Long.prototype.toString.call(message.confirmPoint) : options.longs === Number ? new $util.LongBits(message.confirmPoint.low >>> 0, message.confirmPoint.high >>> 0).toNumber() : message.confirmPoint;
                        if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                            if (typeof message.temporaryPoint === "number")
                                object.temporaryPoint = options.longs === String ? String(message.temporaryPoint) : message.temporaryPoint;
                            else
                                object.temporaryPoint = options.longs === String ? $util.Long.prototype.toString.call(message.temporaryPoint) : options.longs === Number ? new $util.LongBits(message.temporaryPoint.low >>> 0, message.temporaryPoint.high >>> 0).toNumber() : message.temporaryPoint;
                        if (message.usePoint != null && message.hasOwnProperty("usePoint"))
                            if (typeof message.usePoint === "number")
                                object.usePoint = options.longs === String ? String(message.usePoint) : message.usePoint;
                            else
                                object.usePoint = options.longs === String ? $util.Long.prototype.toString.call(message.usePoint) : options.longs === Number ? new $util.LongBits(message.usePoint.low >>> 0, message.usePoint.high >>> 0).toNumber() : message.usePoint;
                        if (message.changeDate != null && message.hasOwnProperty("changeDate"))
                            object.changeDate = message.changeDate;
                        if (message.changeReason != null && message.hasOwnProperty("changeReason"))
                            object.changeReason = message.changeReason;
                        if (message.deadline != null && message.hasOwnProperty("deadline"))
                            object.deadline = message.deadline;
                        if (message.usableDate != null && message.hasOwnProperty("usableDate"))
                            object.usableDate = message.usableDate;
                        if (message.details && message.details.length) {
                            object.details = [];
                            for (var j = 0; j < message.details.length; ++j)
                                object.details[j] = $root.theplant.ec.service.points.CustomerPointRecord.DetailReason.toObject(message.details[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this CustomerPointRecord to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.CustomerPointRecord
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerPointRecord.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    CustomerPointRecord.DetailReason = (function() {

                        /**
                         * Properties of a DetailReason.
                         * @memberof theplant.ec.service.points.CustomerPointRecord
                         * @interface IDetailReason
                         * @property {number|Long|null} [ConfirmPoint] DetailReason ConfirmPoint
                         * @property {string|null} [deadline] DetailReason deadline
                         * @property {string|null} [detailReason] DetailReason detailReason
                         * @property {number|Long|null} [temporaryPoint] DetailReason temporaryPoint
                         * @property {string|null} [usableDate] DetailReason usableDate
                         */

                        /**
                         * Constructs a new DetailReason.
                         * @memberof theplant.ec.service.points.CustomerPointRecord
                         * @classdesc Represents a DetailReason.
                         * @implements IDetailReason
                         * @constructor
                         * @param {theplant.ec.service.points.CustomerPointRecord.IDetailReason=} [properties] Properties to set
                         */
                        function DetailReason(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DetailReason ConfirmPoint.
                         * @member {number|Long} ConfirmPoint
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @instance
                         */
                        DetailReason.prototype.ConfirmPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * DetailReason deadline.
                         * @member {string} deadline
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @instance
                         */
                        DetailReason.prototype.deadline = "";

                        /**
                         * DetailReason detailReason.
                         * @member {string} detailReason
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @instance
                         */
                        DetailReason.prototype.detailReason = "";

                        /**
                         * DetailReason temporaryPoint.
                         * @member {number|Long} temporaryPoint
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @instance
                         */
                        DetailReason.prototype.temporaryPoint = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                        /**
                         * DetailReason usableDate.
                         * @member {string} usableDate
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @instance
                         */
                        DetailReason.prototype.usableDate = "";

                        /**
                         * Creates a new DetailReason instance using the specified properties.
                         * @function create
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {theplant.ec.service.points.CustomerPointRecord.IDetailReason=} [properties] Properties to set
                         * @returns {theplant.ec.service.points.CustomerPointRecord.DetailReason} DetailReason instance
                         */
                        DetailReason.create = function create(properties) {
                            return new DetailReason(properties);
                        };

                        /**
                         * Encodes the specified DetailReason message. Does not implicitly {@link theplant.ec.service.points.CustomerPointRecord.DetailReason.verify|verify} messages.
                         * @function encode
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {theplant.ec.service.points.CustomerPointRecord.IDetailReason} message DetailReason message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DetailReason.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.ConfirmPoint != null && message.hasOwnProperty("ConfirmPoint"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int64(message.ConfirmPoint);
                            if (message.deadline != null && message.hasOwnProperty("deadline"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.deadline);
                            if (message.detailReason != null && message.hasOwnProperty("detailReason"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.detailReason);
                            if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.temporaryPoint);
                            if (message.usableDate != null && message.hasOwnProperty("usableDate"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.usableDate);
                            return writer;
                        };

                        /**
                         * Encodes the specified DetailReason message, length delimited. Does not implicitly {@link theplant.ec.service.points.CustomerPointRecord.DetailReason.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {theplant.ec.service.points.CustomerPointRecord.IDetailReason} message DetailReason message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DetailReason.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DetailReason message from the specified reader or buffer.
                         * @function decode
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {theplant.ec.service.points.CustomerPointRecord.DetailReason} DetailReason
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DetailReason.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.CustomerPointRecord.DetailReason();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1:
                                    message.ConfirmPoint = reader.int64();
                                    break;
                                case 2:
                                    message.deadline = reader.string();
                                    break;
                                case 3:
                                    message.detailReason = reader.string();
                                    break;
                                case 4:
                                    message.temporaryPoint = reader.int64();
                                    break;
                                case 5:
                                    message.usableDate = reader.string();
                                    break;
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DetailReason message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {theplant.ec.service.points.CustomerPointRecord.DetailReason} DetailReason
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DetailReason.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DetailReason message.
                         * @function verify
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DetailReason.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.ConfirmPoint != null && message.hasOwnProperty("ConfirmPoint"))
                                if (!$util.isInteger(message.ConfirmPoint) && !(message.ConfirmPoint && $util.isInteger(message.ConfirmPoint.low) && $util.isInteger(message.ConfirmPoint.high)))
                                    return "ConfirmPoint: integer|Long expected";
                            if (message.deadline != null && message.hasOwnProperty("deadline"))
                                if (!$util.isString(message.deadline))
                                    return "deadline: string expected";
                            if (message.detailReason != null && message.hasOwnProperty("detailReason"))
                                if (!$util.isString(message.detailReason))
                                    return "detailReason: string expected";
                            if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                                if (!$util.isInteger(message.temporaryPoint) && !(message.temporaryPoint && $util.isInteger(message.temporaryPoint.low) && $util.isInteger(message.temporaryPoint.high)))
                                    return "temporaryPoint: integer|Long expected";
                            if (message.usableDate != null && message.hasOwnProperty("usableDate"))
                                if (!$util.isString(message.usableDate))
                                    return "usableDate: string expected";
                            return null;
                        };

                        /**
                         * Creates a DetailReason message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {theplant.ec.service.points.CustomerPointRecord.DetailReason} DetailReason
                         */
                        DetailReason.fromObject = function fromObject(object) {
                            if (object instanceof $root.theplant.ec.service.points.CustomerPointRecord.DetailReason)
                                return object;
                            var message = new $root.theplant.ec.service.points.CustomerPointRecord.DetailReason();
                            if (object.ConfirmPoint != null)
                                if ($util.Long)
                                    (message.ConfirmPoint = $util.Long.fromValue(object.ConfirmPoint)).unsigned = false;
                                else if (typeof object.ConfirmPoint === "string")
                                    message.ConfirmPoint = parseInt(object.ConfirmPoint, 10);
                                else if (typeof object.ConfirmPoint === "number")
                                    message.ConfirmPoint = object.ConfirmPoint;
                                else if (typeof object.ConfirmPoint === "object")
                                    message.ConfirmPoint = new $util.LongBits(object.ConfirmPoint.low >>> 0, object.ConfirmPoint.high >>> 0).toNumber();
                            if (object.deadline != null)
                                message.deadline = String(object.deadline);
                            if (object.detailReason != null)
                                message.detailReason = String(object.detailReason);
                            if (object.temporaryPoint != null)
                                if ($util.Long)
                                    (message.temporaryPoint = $util.Long.fromValue(object.temporaryPoint)).unsigned = false;
                                else if (typeof object.temporaryPoint === "string")
                                    message.temporaryPoint = parseInt(object.temporaryPoint, 10);
                                else if (typeof object.temporaryPoint === "number")
                                    message.temporaryPoint = object.temporaryPoint;
                                else if (typeof object.temporaryPoint === "object")
                                    message.temporaryPoint = new $util.LongBits(object.temporaryPoint.low >>> 0, object.temporaryPoint.high >>> 0).toNumber();
                            if (object.usableDate != null)
                                message.usableDate = String(object.usableDate);
                            return message;
                        };

                        /**
                         * Creates a plain object from a DetailReason message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @static
                         * @param {theplant.ec.service.points.CustomerPointRecord.DetailReason} message DetailReason
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DetailReason.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.ConfirmPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.ConfirmPoint = options.longs === String ? "0" : 0;
                                object.deadline = "";
                                object.detailReason = "";
                                if ($util.Long) {
                                    var long = new $util.Long(0, 0, false);
                                    object.temporaryPoint = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                                } else
                                    object.temporaryPoint = options.longs === String ? "0" : 0;
                                object.usableDate = "";
                            }
                            if (message.ConfirmPoint != null && message.hasOwnProperty("ConfirmPoint"))
                                if (typeof message.ConfirmPoint === "number")
                                    object.ConfirmPoint = options.longs === String ? String(message.ConfirmPoint) : message.ConfirmPoint;
                                else
                                    object.ConfirmPoint = options.longs === String ? $util.Long.prototype.toString.call(message.ConfirmPoint) : options.longs === Number ? new $util.LongBits(message.ConfirmPoint.low >>> 0, message.ConfirmPoint.high >>> 0).toNumber() : message.ConfirmPoint;
                            if (message.deadline != null && message.hasOwnProperty("deadline"))
                                object.deadline = message.deadline;
                            if (message.detailReason != null && message.hasOwnProperty("detailReason"))
                                object.detailReason = message.detailReason;
                            if (message.temporaryPoint != null && message.hasOwnProperty("temporaryPoint"))
                                if (typeof message.temporaryPoint === "number")
                                    object.temporaryPoint = options.longs === String ? String(message.temporaryPoint) : message.temporaryPoint;
                                else
                                    object.temporaryPoint = options.longs === String ? $util.Long.prototype.toString.call(message.temporaryPoint) : options.longs === Number ? new $util.LongBits(message.temporaryPoint.low >>> 0, message.temporaryPoint.high >>> 0).toNumber() : message.temporaryPoint;
                            if (message.usableDate != null && message.hasOwnProperty("usableDate"))
                                object.usableDate = message.usableDate;
                            return object;
                        };

                        /**
                         * Converts this DetailReason to JSON.
                         * @function toJSON
                         * @memberof theplant.ec.service.points.CustomerPointRecord.DetailReason
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DetailReason.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        return DetailReason;
                    })();

                    return CustomerPointRecord;
                })();

                points.CustomerPointHistory = (function() {

                    /**
                     * Properties of a CustomerPointHistory.
                     * @memberof theplant.ec.service.points
                     * @interface ICustomerPointHistory
                     * @property {theplant.ec.service.points.ICustomerPointBase|null} [base] CustomerPointHistory base
                     * @property {Array.<theplant.ec.service.points.ICustomerPointRecord>|null} [pointRecords] CustomerPointHistory pointRecords
                     */

                    /**
                     * Constructs a new CustomerPointHistory.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents a CustomerPointHistory.
                     * @implements ICustomerPointHistory
                     * @constructor
                     * @param {theplant.ec.service.points.ICustomerPointHistory=} [properties] Properties to set
                     */
                    function CustomerPointHistory(properties) {
                        this.pointRecords = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomerPointHistory base.
                     * @member {theplant.ec.service.points.ICustomerPointBase|null|undefined} base
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @instance
                     */
                    CustomerPointHistory.prototype.base = null;

                    /**
                     * CustomerPointHistory pointRecords.
                     * @member {Array.<theplant.ec.service.points.ICustomerPointRecord>} pointRecords
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @instance
                     */
                    CustomerPointHistory.prototype.pointRecords = $util.emptyArray;

                    /**
                     * Creates a new CustomerPointHistory instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointHistory=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.CustomerPointHistory} CustomerPointHistory instance
                     */
                    CustomerPointHistory.create = function create(properties) {
                        return new CustomerPointHistory(properties);
                    };

                    /**
                     * Encodes the specified CustomerPointHistory message. Does not implicitly {@link theplant.ec.service.points.CustomerPointHistory.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointHistory} message CustomerPointHistory message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointHistory.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.base != null && message.hasOwnProperty("base"))
                            $root.theplant.ec.service.points.CustomerPointBase.encode(message.base, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.pointRecords != null && message.pointRecords.length)
                            for (var i = 0; i < message.pointRecords.length; ++i)
                                $root.theplant.ec.service.points.CustomerPointRecord.encode(message.pointRecords[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomerPointHistory message, length delimited. Does not implicitly {@link theplant.ec.service.points.CustomerPointHistory.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.service.points.ICustomerPointHistory} message CustomerPointHistory message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomerPointHistory.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomerPointHistory message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.CustomerPointHistory} CustomerPointHistory
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointHistory.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.CustomerPointHistory();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.base = $root.theplant.ec.service.points.CustomerPointBase.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.pointRecords && message.pointRecords.length))
                                    message.pointRecords = [];
                                message.pointRecords.push($root.theplant.ec.service.points.CustomerPointRecord.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomerPointHistory message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.CustomerPointHistory} CustomerPointHistory
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomerPointHistory.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomerPointHistory message.
                     * @function verify
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomerPointHistory.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.base != null && message.hasOwnProperty("base")) {
                            var error = $root.theplant.ec.service.points.CustomerPointBase.verify(message.base);
                            if (error)
                                return "base." + error;
                        }
                        if (message.pointRecords != null && message.hasOwnProperty("pointRecords")) {
                            if (!Array.isArray(message.pointRecords))
                                return "pointRecords: array expected";
                            for (var i = 0; i < message.pointRecords.length; ++i) {
                                var error = $root.theplant.ec.service.points.CustomerPointRecord.verify(message.pointRecords[i]);
                                if (error)
                                    return "pointRecords." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a CustomerPointHistory message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.CustomerPointHistory} CustomerPointHistory
                     */
                    CustomerPointHistory.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.CustomerPointHistory)
                            return object;
                        var message = new $root.theplant.ec.service.points.CustomerPointHistory();
                        if (object.base != null) {
                            if (typeof object.base !== "object")
                                throw TypeError(".theplant.ec.service.points.CustomerPointHistory.base: object expected");
                            message.base = $root.theplant.ec.service.points.CustomerPointBase.fromObject(object.base);
                        }
                        if (object.pointRecords) {
                            if (!Array.isArray(object.pointRecords))
                                throw TypeError(".theplant.ec.service.points.CustomerPointHistory.pointRecords: array expected");
                            message.pointRecords = [];
                            for (var i = 0; i < object.pointRecords.length; ++i) {
                                if (typeof object.pointRecords[i] !== "object")
                                    throw TypeError(".theplant.ec.service.points.CustomerPointHistory.pointRecords: object expected");
                                message.pointRecords[i] = $root.theplant.ec.service.points.CustomerPointRecord.fromObject(object.pointRecords[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomerPointHistory message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @static
                     * @param {theplant.ec.service.points.CustomerPointHistory} message CustomerPointHistory
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomerPointHistory.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.pointRecords = [];
                        if (options.defaults)
                            object.base = null;
                        if (message.base != null && message.hasOwnProperty("base"))
                            object.base = $root.theplant.ec.service.points.CustomerPointBase.toObject(message.base, options);
                        if (message.pointRecords && message.pointRecords.length) {
                            object.pointRecords = [];
                            for (var j = 0; j < message.pointRecords.length; ++j)
                                object.pointRecords[j] = $root.theplant.ec.service.points.CustomerPointRecord.toObject(message.pointRecords[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this CustomerPointHistory to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.CustomerPointHistory
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomerPointHistory.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return CustomerPointHistory;
                })();

                points.AvailableUsePoint = (function() {

                    /**
                     * Properties of an AvailableUsePoint.
                     * @memberof theplant.ec.service.points
                     * @interface IAvailableUsePoint
                     * @property {boolean|null} [cannotUsePoint] AvailableUsePoint cannotUsePoint
                     * @property {number|Long|null} [customerOwnedPoints] AvailableUsePoint customerOwnedPoints
                     * @property {number|Long|null} [maximumUsablePoints] AvailableUsePoint maximumUsablePoints
                     * @property {number|Long|null} [leftPointsDuringCurUsing] AvailableUsePoint leftPointsDuringCurUsing
                     * @property {string|null} [noticeInfo] AvailableUsePoint noticeInfo
                     */

                    /**
                     * Constructs a new AvailableUsePoint.
                     * @memberof theplant.ec.service.points
                     * @classdesc Represents an AvailableUsePoint.
                     * @implements IAvailableUsePoint
                     * @constructor
                     * @param {theplant.ec.service.points.IAvailableUsePoint=} [properties] Properties to set
                     */
                    function AvailableUsePoint(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AvailableUsePoint cannotUsePoint.
                     * @member {boolean} cannotUsePoint
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @instance
                     */
                    AvailableUsePoint.prototype.cannotUsePoint = false;

                    /**
                     * AvailableUsePoint customerOwnedPoints.
                     * @member {number|Long} customerOwnedPoints
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @instance
                     */
                    AvailableUsePoint.prototype.customerOwnedPoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * AvailableUsePoint maximumUsablePoints.
                     * @member {number|Long} maximumUsablePoints
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @instance
                     */
                    AvailableUsePoint.prototype.maximumUsablePoints = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * AvailableUsePoint leftPointsDuringCurUsing.
                     * @member {number|Long} leftPointsDuringCurUsing
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @instance
                     */
                    AvailableUsePoint.prototype.leftPointsDuringCurUsing = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * AvailableUsePoint noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @instance
                     */
                    AvailableUsePoint.prototype.noticeInfo = "";

                    /**
                     * Creates a new AvailableUsePoint instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {theplant.ec.service.points.IAvailableUsePoint=} [properties] Properties to set
                     * @returns {theplant.ec.service.points.AvailableUsePoint} AvailableUsePoint instance
                     */
                    AvailableUsePoint.create = function create(properties) {
                        return new AvailableUsePoint(properties);
                    };

                    /**
                     * Encodes the specified AvailableUsePoint message. Does not implicitly {@link theplant.ec.service.points.AvailableUsePoint.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {theplant.ec.service.points.IAvailableUsePoint} message AvailableUsePoint message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AvailableUsePoint.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cannotUsePoint != null && message.hasOwnProperty("cannotUsePoint"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.cannotUsePoint);
                        if (message.customerOwnedPoints != null && message.hasOwnProperty("customerOwnedPoints"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.customerOwnedPoints);
                        if (message.maximumUsablePoints != null && message.hasOwnProperty("maximumUsablePoints"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.maximumUsablePoints);
                        if (message.leftPointsDuringCurUsing != null && message.hasOwnProperty("leftPointsDuringCurUsing"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.leftPointsDuringCurUsing);
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.noticeInfo);
                        return writer;
                    };

                    /**
                     * Encodes the specified AvailableUsePoint message, length delimited. Does not implicitly {@link theplant.ec.service.points.AvailableUsePoint.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {theplant.ec.service.points.IAvailableUsePoint} message AvailableUsePoint message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AvailableUsePoint.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AvailableUsePoint message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.points.AvailableUsePoint} AvailableUsePoint
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AvailableUsePoint.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.points.AvailableUsePoint();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.cannotUsePoint = reader.bool();
                                break;
                            case 2:
                                message.customerOwnedPoints = reader.int64();
                                break;
                            case 3:
                                message.maximumUsablePoints = reader.int64();
                                break;
                            case 4:
                                message.leftPointsDuringCurUsing = reader.int64();
                                break;
                            case 5:
                                message.noticeInfo = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AvailableUsePoint message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.points.AvailableUsePoint} AvailableUsePoint
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AvailableUsePoint.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AvailableUsePoint message.
                     * @function verify
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AvailableUsePoint.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cannotUsePoint != null && message.hasOwnProperty("cannotUsePoint"))
                            if (typeof message.cannotUsePoint !== "boolean")
                                return "cannotUsePoint: boolean expected";
                        if (message.customerOwnedPoints != null && message.hasOwnProperty("customerOwnedPoints"))
                            if (!$util.isInteger(message.customerOwnedPoints) && !(message.customerOwnedPoints && $util.isInteger(message.customerOwnedPoints.low) && $util.isInteger(message.customerOwnedPoints.high)))
                                return "customerOwnedPoints: integer|Long expected";
                        if (message.maximumUsablePoints != null && message.hasOwnProperty("maximumUsablePoints"))
                            if (!$util.isInteger(message.maximumUsablePoints) && !(message.maximumUsablePoints && $util.isInteger(message.maximumUsablePoints.low) && $util.isInteger(message.maximumUsablePoints.high)))
                                return "maximumUsablePoints: integer|Long expected";
                        if (message.leftPointsDuringCurUsing != null && message.hasOwnProperty("leftPointsDuringCurUsing"))
                            if (!$util.isInteger(message.leftPointsDuringCurUsing) && !(message.leftPointsDuringCurUsing && $util.isInteger(message.leftPointsDuringCurUsing.low) && $util.isInteger(message.leftPointsDuringCurUsing.high)))
                                return "leftPointsDuringCurUsing: integer|Long expected";
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        return null;
                    };

                    /**
                     * Creates an AvailableUsePoint message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.points.AvailableUsePoint} AvailableUsePoint
                     */
                    AvailableUsePoint.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.points.AvailableUsePoint)
                            return object;
                        var message = new $root.theplant.ec.service.points.AvailableUsePoint();
                        if (object.cannotUsePoint != null)
                            message.cannotUsePoint = Boolean(object.cannotUsePoint);
                        if (object.customerOwnedPoints != null)
                            if ($util.Long)
                                (message.customerOwnedPoints = $util.Long.fromValue(object.customerOwnedPoints)).unsigned = false;
                            else if (typeof object.customerOwnedPoints === "string")
                                message.customerOwnedPoints = parseInt(object.customerOwnedPoints, 10);
                            else if (typeof object.customerOwnedPoints === "number")
                                message.customerOwnedPoints = object.customerOwnedPoints;
                            else if (typeof object.customerOwnedPoints === "object")
                                message.customerOwnedPoints = new $util.LongBits(object.customerOwnedPoints.low >>> 0, object.customerOwnedPoints.high >>> 0).toNumber();
                        if (object.maximumUsablePoints != null)
                            if ($util.Long)
                                (message.maximumUsablePoints = $util.Long.fromValue(object.maximumUsablePoints)).unsigned = false;
                            else if (typeof object.maximumUsablePoints === "string")
                                message.maximumUsablePoints = parseInt(object.maximumUsablePoints, 10);
                            else if (typeof object.maximumUsablePoints === "number")
                                message.maximumUsablePoints = object.maximumUsablePoints;
                            else if (typeof object.maximumUsablePoints === "object")
                                message.maximumUsablePoints = new $util.LongBits(object.maximumUsablePoints.low >>> 0, object.maximumUsablePoints.high >>> 0).toNumber();
                        if (object.leftPointsDuringCurUsing != null)
                            if ($util.Long)
                                (message.leftPointsDuringCurUsing = $util.Long.fromValue(object.leftPointsDuringCurUsing)).unsigned = false;
                            else if (typeof object.leftPointsDuringCurUsing === "string")
                                message.leftPointsDuringCurUsing = parseInt(object.leftPointsDuringCurUsing, 10);
                            else if (typeof object.leftPointsDuringCurUsing === "number")
                                message.leftPointsDuringCurUsing = object.leftPointsDuringCurUsing;
                            else if (typeof object.leftPointsDuringCurUsing === "object")
                                message.leftPointsDuringCurUsing = new $util.LongBits(object.leftPointsDuringCurUsing.low >>> 0, object.leftPointsDuringCurUsing.high >>> 0).toNumber();
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AvailableUsePoint message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @static
                     * @param {theplant.ec.service.points.AvailableUsePoint} message AvailableUsePoint
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AvailableUsePoint.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.cannotUsePoint = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.customerOwnedPoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.customerOwnedPoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.maximumUsablePoints = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.maximumUsablePoints = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.leftPointsDuringCurUsing = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.leftPointsDuringCurUsing = options.longs === String ? "0" : 0;
                            object.noticeInfo = "";
                        }
                        if (message.cannotUsePoint != null && message.hasOwnProperty("cannotUsePoint"))
                            object.cannotUsePoint = message.cannotUsePoint;
                        if (message.customerOwnedPoints != null && message.hasOwnProperty("customerOwnedPoints"))
                            if (typeof message.customerOwnedPoints === "number")
                                object.customerOwnedPoints = options.longs === String ? String(message.customerOwnedPoints) : message.customerOwnedPoints;
                            else
                                object.customerOwnedPoints = options.longs === String ? $util.Long.prototype.toString.call(message.customerOwnedPoints) : options.longs === Number ? new $util.LongBits(message.customerOwnedPoints.low >>> 0, message.customerOwnedPoints.high >>> 0).toNumber() : message.customerOwnedPoints;
                        if (message.maximumUsablePoints != null && message.hasOwnProperty("maximumUsablePoints"))
                            if (typeof message.maximumUsablePoints === "number")
                                object.maximumUsablePoints = options.longs === String ? String(message.maximumUsablePoints) : message.maximumUsablePoints;
                            else
                                object.maximumUsablePoints = options.longs === String ? $util.Long.prototype.toString.call(message.maximumUsablePoints) : options.longs === Number ? new $util.LongBits(message.maximumUsablePoints.low >>> 0, message.maximumUsablePoints.high >>> 0).toNumber() : message.maximumUsablePoints;
                        if (message.leftPointsDuringCurUsing != null && message.hasOwnProperty("leftPointsDuringCurUsing"))
                            if (typeof message.leftPointsDuringCurUsing === "number")
                                object.leftPointsDuringCurUsing = options.longs === String ? String(message.leftPointsDuringCurUsing) : message.leftPointsDuringCurUsing;
                            else
                                object.leftPointsDuringCurUsing = options.longs === String ? $util.Long.prototype.toString.call(message.leftPointsDuringCurUsing) : options.longs === Number ? new $util.LongBits(message.leftPointsDuringCurUsing.low >>> 0, message.leftPointsDuringCurUsing.high >>> 0).toNumber() : message.leftPointsDuringCurUsing;
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        return object;
                    };

                    /**
                     * Converts this AvailableUsePoint to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.points.AvailableUsePoint
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AvailableUsePoint.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AvailableUsePoint;
                })();

                return points;
            })();

            service.sizeguide = (function() {

                /**
                 * Namespace sizeguide.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var sizeguide = {};

                sizeguide.SizeGuideInput = (function() {

                    /**
                     * Properties of a SizeGuideInput.
                     * @memberof theplant.ec.service.sizeguide
                     * @interface ISizeGuideInput
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [props] SizeGuideInput props
                     */

                    /**
                     * Constructs a new SizeGuideInput.
                     * @memberof theplant.ec.service.sizeguide
                     * @classdesc Represents a SizeGuideInput.
                     * @implements ISizeGuideInput
                     * @constructor
                     * @param {theplant.ec.service.sizeguide.ISizeGuideInput=} [properties] Properties to set
                     */
                    function SizeGuideInput(properties) {
                        this.props = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeGuideInput props.
                     * @member {Array.<theplant.ec.service.base.IProperty>} props
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @instance
                     */
                    SizeGuideInput.prototype.props = $util.emptyArray;

                    /**
                     * Creates a new SizeGuideInput instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideInput=} [properties] Properties to set
                     * @returns {theplant.ec.service.sizeguide.SizeGuideInput} SizeGuideInput instance
                     */
                    SizeGuideInput.create = function create(properties) {
                        return new SizeGuideInput(properties);
                    };

                    /**
                     * Encodes the specified SizeGuideInput message. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideInput.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideInput} message SizeGuideInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideInput.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.props != null && message.props.length)
                            for (var i = 0; i < message.props.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.props[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeGuideInput message, length delimited. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideInput.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideInput} message SizeGuideInput message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideInput.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeGuideInput message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.sizeguide.SizeGuideInput} SizeGuideInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideInput.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.sizeguide.SizeGuideInput();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.props && message.props.length))
                                    message.props = [];
                                message.props.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeGuideInput message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.sizeguide.SizeGuideInput} SizeGuideInput
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideInput.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeGuideInput message.
                     * @function verify
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeGuideInput.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.props != null && message.hasOwnProperty("props")) {
                            if (!Array.isArray(message.props))
                                return "props: array expected";
                            for (var i = 0; i < message.props.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.props[i]);
                                if (error)
                                    return "props." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SizeGuideInput message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.sizeguide.SizeGuideInput} SizeGuideInput
                     */
                    SizeGuideInput.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.sizeguide.SizeGuideInput)
                            return object;
                        var message = new $root.theplant.ec.service.sizeguide.SizeGuideInput();
                        if (object.props) {
                            if (!Array.isArray(object.props))
                                throw TypeError(".theplant.ec.service.sizeguide.SizeGuideInput.props: array expected");
                            message.props = [];
                            for (var i = 0; i < object.props.length; ++i) {
                                if (typeof object.props[i] !== "object")
                                    throw TypeError(".theplant.ec.service.sizeguide.SizeGuideInput.props: object expected");
                                message.props[i] = $root.theplant.ec.service.base.Property.fromObject(object.props[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeGuideInput message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @static
                     * @param {theplant.ec.service.sizeguide.SizeGuideInput} message SizeGuideInput
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeGuideInput.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.props = [];
                        if (message.props && message.props.length) {
                            object.props = [];
                            for (var j = 0; j < message.props.length; ++j)
                                object.props[j] = $root.theplant.ec.service.base.Property.toObject(message.props[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SizeGuideInput to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.sizeguide.SizeGuideInput
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeGuideInput.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeGuideInput;
                })();

                sizeguide.SizeGuide = (function() {

                    /**
                     * Properties of a SizeGuide.
                     * @memberof theplant.ec.service.sizeguide
                     * @interface ISizeGuide
                     * @property {string|null} [name] SizeGuide name
                     * @property {Array.<theplant.ec.service.sizeguide.ISizeGuideTable>|null} [tables] SizeGuide tables
                     * @property {Array.<theplant.ec.service.sizeguide.ISizeGuideMeasurement>|null} [measurements] SizeGuide measurements
                     */

                    /**
                     * Constructs a new SizeGuide.
                     * @memberof theplant.ec.service.sizeguide
                     * @classdesc Represents a SizeGuide.
                     * @implements ISizeGuide
                     * @constructor
                     * @param {theplant.ec.service.sizeguide.ISizeGuide=} [properties] Properties to set
                     */
                    function SizeGuide(properties) {
                        this.tables = [];
                        this.measurements = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeGuide name.
                     * @member {string} name
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @instance
                     */
                    SizeGuide.prototype.name = "";

                    /**
                     * SizeGuide tables.
                     * @member {Array.<theplant.ec.service.sizeguide.ISizeGuideTable>} tables
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @instance
                     */
                    SizeGuide.prototype.tables = $util.emptyArray;

                    /**
                     * SizeGuide measurements.
                     * @member {Array.<theplant.ec.service.sizeguide.ISizeGuideMeasurement>} measurements
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @instance
                     */
                    SizeGuide.prototype.measurements = $util.emptyArray;

                    /**
                     * Creates a new SizeGuide instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuide=} [properties] Properties to set
                     * @returns {theplant.ec.service.sizeguide.SizeGuide} SizeGuide instance
                     */
                    SizeGuide.create = function create(properties) {
                        return new SizeGuide(properties);
                    };

                    /**
                     * Encodes the specified SizeGuide message. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuide.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuide} message SizeGuide message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuide.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.tables != null && message.tables.length)
                            for (var i = 0; i < message.tables.length; ++i)
                                $root.theplant.ec.service.sizeguide.SizeGuideTable.encode(message.tables[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.measurements != null && message.measurements.length)
                            for (var i = 0; i < message.measurements.length; ++i)
                                $root.theplant.ec.service.sizeguide.SizeGuideMeasurement.encode(message.measurements[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeGuide message, length delimited. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuide.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuide} message SizeGuide message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuide.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeGuide message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.sizeguide.SizeGuide} SizeGuide
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuide.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.sizeguide.SizeGuide();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                if (!(message.tables && message.tables.length))
                                    message.tables = [];
                                message.tables.push($root.theplant.ec.service.sizeguide.SizeGuideTable.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.measurements && message.measurements.length))
                                    message.measurements = [];
                                message.measurements.push($root.theplant.ec.service.sizeguide.SizeGuideMeasurement.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeGuide message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.sizeguide.SizeGuide} SizeGuide
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuide.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeGuide message.
                     * @function verify
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeGuide.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.tables != null && message.hasOwnProperty("tables")) {
                            if (!Array.isArray(message.tables))
                                return "tables: array expected";
                            for (var i = 0; i < message.tables.length; ++i) {
                                var error = $root.theplant.ec.service.sizeguide.SizeGuideTable.verify(message.tables[i]);
                                if (error)
                                    return "tables." + error;
                            }
                        }
                        if (message.measurements != null && message.hasOwnProperty("measurements")) {
                            if (!Array.isArray(message.measurements))
                                return "measurements: array expected";
                            for (var i = 0; i < message.measurements.length; ++i) {
                                var error = $root.theplant.ec.service.sizeguide.SizeGuideMeasurement.verify(message.measurements[i]);
                                if (error)
                                    return "measurements." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SizeGuide message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.sizeguide.SizeGuide} SizeGuide
                     */
                    SizeGuide.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.sizeguide.SizeGuide)
                            return object;
                        var message = new $root.theplant.ec.service.sizeguide.SizeGuide();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.tables) {
                            if (!Array.isArray(object.tables))
                                throw TypeError(".theplant.ec.service.sizeguide.SizeGuide.tables: array expected");
                            message.tables = [];
                            for (var i = 0; i < object.tables.length; ++i) {
                                if (typeof object.tables[i] !== "object")
                                    throw TypeError(".theplant.ec.service.sizeguide.SizeGuide.tables: object expected");
                                message.tables[i] = $root.theplant.ec.service.sizeguide.SizeGuideTable.fromObject(object.tables[i]);
                            }
                        }
                        if (object.measurements) {
                            if (!Array.isArray(object.measurements))
                                throw TypeError(".theplant.ec.service.sizeguide.SizeGuide.measurements: array expected");
                            message.measurements = [];
                            for (var i = 0; i < object.measurements.length; ++i) {
                                if (typeof object.measurements[i] !== "object")
                                    throw TypeError(".theplant.ec.service.sizeguide.SizeGuide.measurements: object expected");
                                message.measurements[i] = $root.theplant.ec.service.sizeguide.SizeGuideMeasurement.fromObject(object.measurements[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeGuide message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @static
                     * @param {theplant.ec.service.sizeguide.SizeGuide} message SizeGuide
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeGuide.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.tables = [];
                            object.measurements = [];
                        }
                        if (options.defaults)
                            object.name = "";
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.tables && message.tables.length) {
                            object.tables = [];
                            for (var j = 0; j < message.tables.length; ++j)
                                object.tables[j] = $root.theplant.ec.service.sizeguide.SizeGuideTable.toObject(message.tables[j], options);
                        }
                        if (message.measurements && message.measurements.length) {
                            object.measurements = [];
                            for (var j = 0; j < message.measurements.length; ++j)
                                object.measurements[j] = $root.theplant.ec.service.sizeguide.SizeGuideMeasurement.toObject(message.measurements[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SizeGuide to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.sizeguide.SizeGuide
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeGuide.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeGuide;
                })();

                sizeguide.SizeGuideMeasurement = (function() {

                    /**
                     * Properties of a SizeGuideMeasurement.
                     * @memberof theplant.ec.service.sizeguide
                     * @interface ISizeGuideMeasurement
                     * @property {string|null} [name] SizeGuideMeasurement name
                     * @property {string|null} [bodyHtml] SizeGuideMeasurement bodyHtml
                     */

                    /**
                     * Constructs a new SizeGuideMeasurement.
                     * @memberof theplant.ec.service.sizeguide
                     * @classdesc Represents a SizeGuideMeasurement.
                     * @implements ISizeGuideMeasurement
                     * @constructor
                     * @param {theplant.ec.service.sizeguide.ISizeGuideMeasurement=} [properties] Properties to set
                     */
                    function SizeGuideMeasurement(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeGuideMeasurement name.
                     * @member {string} name
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @instance
                     */
                    SizeGuideMeasurement.prototype.name = "";

                    /**
                     * SizeGuideMeasurement bodyHtml.
                     * @member {string} bodyHtml
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @instance
                     */
                    SizeGuideMeasurement.prototype.bodyHtml = "";

                    /**
                     * Creates a new SizeGuideMeasurement instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideMeasurement=} [properties] Properties to set
                     * @returns {theplant.ec.service.sizeguide.SizeGuideMeasurement} SizeGuideMeasurement instance
                     */
                    SizeGuideMeasurement.create = function create(properties) {
                        return new SizeGuideMeasurement(properties);
                    };

                    /**
                     * Encodes the specified SizeGuideMeasurement message. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideMeasurement.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideMeasurement} message SizeGuideMeasurement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideMeasurement.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.bodyHtml != null && message.hasOwnProperty("bodyHtml"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.bodyHtml);
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeGuideMeasurement message, length delimited. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideMeasurement.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideMeasurement} message SizeGuideMeasurement message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideMeasurement.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeGuideMeasurement message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.sizeguide.SizeGuideMeasurement} SizeGuideMeasurement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideMeasurement.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.sizeguide.SizeGuideMeasurement();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.bodyHtml = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeGuideMeasurement message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.sizeguide.SizeGuideMeasurement} SizeGuideMeasurement
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideMeasurement.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeGuideMeasurement message.
                     * @function verify
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeGuideMeasurement.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.bodyHtml != null && message.hasOwnProperty("bodyHtml"))
                            if (!$util.isString(message.bodyHtml))
                                return "bodyHtml: string expected";
                        return null;
                    };

                    /**
                     * Creates a SizeGuideMeasurement message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.sizeguide.SizeGuideMeasurement} SizeGuideMeasurement
                     */
                    SizeGuideMeasurement.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.sizeguide.SizeGuideMeasurement)
                            return object;
                        var message = new $root.theplant.ec.service.sizeguide.SizeGuideMeasurement();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.bodyHtml != null)
                            message.bodyHtml = String(object.bodyHtml);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeGuideMeasurement message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @static
                     * @param {theplant.ec.service.sizeguide.SizeGuideMeasurement} message SizeGuideMeasurement
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeGuideMeasurement.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.bodyHtml = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.bodyHtml != null && message.hasOwnProperty("bodyHtml"))
                            object.bodyHtml = message.bodyHtml;
                        return object;
                    };

                    /**
                     * Converts this SizeGuideMeasurement to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.sizeguide.SizeGuideMeasurement
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeGuideMeasurement.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeGuideMeasurement;
                })();

                sizeguide.SizeGuideTable = (function() {

                    /**
                     * Properties of a SizeGuideTable.
                     * @memberof theplant.ec.service.sizeguide
                     * @interface ISizeGuideTable
                     * @property {string|null} [name] SizeGuideTable name
                     * @property {Array.<string>|null} [group] SizeGuideTable group
                     * @property {Array.<theplant.ec.service.sizeguide.ISizeGuideTableRow>|null} [rows] SizeGuideTable rows
                     * @property {string|null} [description] SizeGuideTable description
                     */

                    /**
                     * Constructs a new SizeGuideTable.
                     * @memberof theplant.ec.service.sizeguide
                     * @classdesc Represents a SizeGuideTable.
                     * @implements ISizeGuideTable
                     * @constructor
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTable=} [properties] Properties to set
                     */
                    function SizeGuideTable(properties) {
                        this.group = [];
                        this.rows = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeGuideTable name.
                     * @member {string} name
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @instance
                     */
                    SizeGuideTable.prototype.name = "";

                    /**
                     * SizeGuideTable group.
                     * @member {Array.<string>} group
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @instance
                     */
                    SizeGuideTable.prototype.group = $util.emptyArray;

                    /**
                     * SizeGuideTable rows.
                     * @member {Array.<theplant.ec.service.sizeguide.ISizeGuideTableRow>} rows
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @instance
                     */
                    SizeGuideTable.prototype.rows = $util.emptyArray;

                    /**
                     * SizeGuideTable description.
                     * @member {string} description
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @instance
                     */
                    SizeGuideTable.prototype.description = "";

                    /**
                     * Creates a new SizeGuideTable instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTable=} [properties] Properties to set
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTable} SizeGuideTable instance
                     */
                    SizeGuideTable.create = function create(properties) {
                        return new SizeGuideTable(properties);
                    };

                    /**
                     * Encodes the specified SizeGuideTable message. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideTable.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTable} message SizeGuideTable message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideTable.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.group != null && message.group.length)
                            for (var i = 0; i < message.group.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.group[i]);
                        if (message.rows != null && message.rows.length)
                            for (var i = 0; i < message.rows.length; ++i)
                                $root.theplant.ec.service.sizeguide.SizeGuideTableRow.encode(message.rows[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeGuideTable message, length delimited. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideTable.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTable} message SizeGuideTable message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideTable.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeGuideTable message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTable} SizeGuideTable
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideTable.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.sizeguide.SizeGuideTable();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                if (!(message.group && message.group.length))
                                    message.group = [];
                                message.group.push(reader.string());
                                break;
                            case 3:
                                if (!(message.rows && message.rows.length))
                                    message.rows = [];
                                message.rows.push($root.theplant.ec.service.sizeguide.SizeGuideTableRow.decode(reader, reader.uint32()));
                                break;
                            case 4:
                                message.description = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeGuideTable message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTable} SizeGuideTable
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideTable.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeGuideTable message.
                     * @function verify
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeGuideTable.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.group != null && message.hasOwnProperty("group")) {
                            if (!Array.isArray(message.group))
                                return "group: array expected";
                            for (var i = 0; i < message.group.length; ++i)
                                if (!$util.isString(message.group[i]))
                                    return "group: string[] expected";
                        }
                        if (message.rows != null && message.hasOwnProperty("rows")) {
                            if (!Array.isArray(message.rows))
                                return "rows: array expected";
                            for (var i = 0; i < message.rows.length; ++i) {
                                var error = $root.theplant.ec.service.sizeguide.SizeGuideTableRow.verify(message.rows[i]);
                                if (error)
                                    return "rows." + error;
                            }
                        }
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        return null;
                    };

                    /**
                     * Creates a SizeGuideTable message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTable} SizeGuideTable
                     */
                    SizeGuideTable.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.sizeguide.SizeGuideTable)
                            return object;
                        var message = new $root.theplant.ec.service.sizeguide.SizeGuideTable();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.group) {
                            if (!Array.isArray(object.group))
                                throw TypeError(".theplant.ec.service.sizeguide.SizeGuideTable.group: array expected");
                            message.group = [];
                            for (var i = 0; i < object.group.length; ++i)
                                message.group[i] = String(object.group[i]);
                        }
                        if (object.rows) {
                            if (!Array.isArray(object.rows))
                                throw TypeError(".theplant.ec.service.sizeguide.SizeGuideTable.rows: array expected");
                            message.rows = [];
                            for (var i = 0; i < object.rows.length; ++i) {
                                if (typeof object.rows[i] !== "object")
                                    throw TypeError(".theplant.ec.service.sizeguide.SizeGuideTable.rows: object expected");
                                message.rows[i] = $root.theplant.ec.service.sizeguide.SizeGuideTableRow.fromObject(object.rows[i]);
                            }
                        }
                        if (object.description != null)
                            message.description = String(object.description);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeGuideTable message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @static
                     * @param {theplant.ec.service.sizeguide.SizeGuideTable} message SizeGuideTable
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeGuideTable.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.group = [];
                            object.rows = [];
                        }
                        if (options.defaults) {
                            object.name = "";
                            object.description = "";
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.group && message.group.length) {
                            object.group = [];
                            for (var j = 0; j < message.group.length; ++j)
                                object.group[j] = message.group[j];
                        }
                        if (message.rows && message.rows.length) {
                            object.rows = [];
                            for (var j = 0; j < message.rows.length; ++j)
                                object.rows[j] = $root.theplant.ec.service.sizeguide.SizeGuideTableRow.toObject(message.rows[j], options);
                        }
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        return object;
                    };

                    /**
                     * Converts this SizeGuideTable to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTable
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeGuideTable.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeGuideTable;
                })();

                sizeguide.SizeGuideTableRow = (function() {

                    /**
                     * Properties of a SizeGuideTableRow.
                     * @memberof theplant.ec.service.sizeguide
                     * @interface ISizeGuideTableRow
                     * @property {Array.<theplant.ec.service.sizeguide.ISizeGuideTableCell>|null} [cells] SizeGuideTableRow cells
                     */

                    /**
                     * Constructs a new SizeGuideTableRow.
                     * @memberof theplant.ec.service.sizeguide
                     * @classdesc Represents a SizeGuideTableRow.
                     * @implements ISizeGuideTableRow
                     * @constructor
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableRow=} [properties] Properties to set
                     */
                    function SizeGuideTableRow(properties) {
                        this.cells = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeGuideTableRow cells.
                     * @member {Array.<theplant.ec.service.sizeguide.ISizeGuideTableCell>} cells
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @instance
                     */
                    SizeGuideTableRow.prototype.cells = $util.emptyArray;

                    /**
                     * Creates a new SizeGuideTableRow instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableRow=} [properties] Properties to set
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableRow} SizeGuideTableRow instance
                     */
                    SizeGuideTableRow.create = function create(properties) {
                        return new SizeGuideTableRow(properties);
                    };

                    /**
                     * Encodes the specified SizeGuideTableRow message. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideTableRow.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableRow} message SizeGuideTableRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideTableRow.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.cells != null && message.cells.length)
                            for (var i = 0; i < message.cells.length; ++i)
                                $root.theplant.ec.service.sizeguide.SizeGuideTableCell.encode(message.cells[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeGuideTableRow message, length delimited. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideTableRow.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableRow} message SizeGuideTableRow message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideTableRow.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeGuideTableRow message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableRow} SizeGuideTableRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideTableRow.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.sizeguide.SizeGuideTableRow();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.cells && message.cells.length))
                                    message.cells = [];
                                message.cells.push($root.theplant.ec.service.sizeguide.SizeGuideTableCell.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeGuideTableRow message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableRow} SizeGuideTableRow
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideTableRow.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeGuideTableRow message.
                     * @function verify
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeGuideTableRow.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.cells != null && message.hasOwnProperty("cells")) {
                            if (!Array.isArray(message.cells))
                                return "cells: array expected";
                            for (var i = 0; i < message.cells.length; ++i) {
                                var error = $root.theplant.ec.service.sizeguide.SizeGuideTableCell.verify(message.cells[i]);
                                if (error)
                                    return "cells." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SizeGuideTableRow message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableRow} SizeGuideTableRow
                     */
                    SizeGuideTableRow.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.sizeguide.SizeGuideTableRow)
                            return object;
                        var message = new $root.theplant.ec.service.sizeguide.SizeGuideTableRow();
                        if (object.cells) {
                            if (!Array.isArray(object.cells))
                                throw TypeError(".theplant.ec.service.sizeguide.SizeGuideTableRow.cells: array expected");
                            message.cells = [];
                            for (var i = 0; i < object.cells.length; ++i) {
                                if (typeof object.cells[i] !== "object")
                                    throw TypeError(".theplant.ec.service.sizeguide.SizeGuideTableRow.cells: object expected");
                                message.cells[i] = $root.theplant.ec.service.sizeguide.SizeGuideTableCell.fromObject(object.cells[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeGuideTableRow message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @static
                     * @param {theplant.ec.service.sizeguide.SizeGuideTableRow} message SizeGuideTableRow
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeGuideTableRow.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.cells = [];
                        if (message.cells && message.cells.length) {
                            object.cells = [];
                            for (var j = 0; j < message.cells.length; ++j)
                                object.cells[j] = $root.theplant.ec.service.sizeguide.SizeGuideTableCell.toObject(message.cells[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SizeGuideTableRow to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableRow
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeGuideTableRow.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeGuideTableRow;
                })();

                sizeguide.SizeGuideTableCell = (function() {

                    /**
                     * Properties of a SizeGuideTableCell.
                     * @memberof theplant.ec.service.sizeguide
                     * @interface ISizeGuideTableCell
                     * @property {string|null} [value] SizeGuideTableCell value
                     */

                    /**
                     * Constructs a new SizeGuideTableCell.
                     * @memberof theplant.ec.service.sizeguide
                     * @classdesc Represents a SizeGuideTableCell.
                     * @implements ISizeGuideTableCell
                     * @constructor
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableCell=} [properties] Properties to set
                     */
                    function SizeGuideTableCell(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SizeGuideTableCell value.
                     * @member {string} value
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @instance
                     */
                    SizeGuideTableCell.prototype.value = "";

                    /**
                     * Creates a new SizeGuideTableCell instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableCell=} [properties] Properties to set
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableCell} SizeGuideTableCell instance
                     */
                    SizeGuideTableCell.create = function create(properties) {
                        return new SizeGuideTableCell(properties);
                    };

                    /**
                     * Encodes the specified SizeGuideTableCell message. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideTableCell.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableCell} message SizeGuideTableCell message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideTableCell.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.value != null && message.hasOwnProperty("value"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                        return writer;
                    };

                    /**
                     * Encodes the specified SizeGuideTableCell message, length delimited. Does not implicitly {@link theplant.ec.service.sizeguide.SizeGuideTableCell.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {theplant.ec.service.sizeguide.ISizeGuideTableCell} message SizeGuideTableCell message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SizeGuideTableCell.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SizeGuideTableCell message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableCell} SizeGuideTableCell
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideTableCell.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.sizeguide.SizeGuideTableCell();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.value = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SizeGuideTableCell message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableCell} SizeGuideTableCell
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SizeGuideTableCell.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SizeGuideTableCell message.
                     * @function verify
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SizeGuideTableCell.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        return null;
                    };

                    /**
                     * Creates a SizeGuideTableCell message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.sizeguide.SizeGuideTableCell} SizeGuideTableCell
                     */
                    SizeGuideTableCell.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.sizeguide.SizeGuideTableCell)
                            return object;
                        var message = new $root.theplant.ec.service.sizeguide.SizeGuideTableCell();
                        if (object.value != null)
                            message.value = String(object.value);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SizeGuideTableCell message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @static
                     * @param {theplant.ec.service.sizeguide.SizeGuideTableCell} message SizeGuideTableCell
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SizeGuideTableCell.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.value = "";
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        return object;
                    };

                    /**
                     * Converts this SizeGuideTableCell to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.sizeguide.SizeGuideTableCell
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SizeGuideTableCell.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SizeGuideTableCell;
                })();

                return sizeguide;
            })();

            service.products = (function() {

                /**
                 * Namespace products.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var products = {};

                products.Product = (function() {

                    /**
                     * Properties of a Product.
                     * @memberof theplant.ec.service.products
                     * @interface IProduct
                     * @property {string|null} [code] Product code
                     * @property {string|null} [name] Product name
                     * @property {string|null} [description] Product description
                     * @property {string|null} [currency] Product currency
                     * @property {string|null} [releaseDate] Product releaseDate
                     * @property {string|null} [searchText] Product searchText
                     * @property {number|Long|null} [saleVolume] Product saleVolume
                     * @property {boolean|null} [publishReady] Product publishReady
                     * @property {string|null} [scheduledStartAt] Product scheduledStartAt
                     * @property {string|null} [scheduledEndAt] Product scheduledEndAt
                     * @property {Array.<theplant.ec.service.products.IVariant>|null} [variants] Product variants
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [filterProperties] for filter products
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [extraProperties] Product extraProperties
                     * @property {Array.<theplant.ec.service.products.IImage>|null} [images] Product images
                     * @property {string|null} [qualifiedArticleCode] Product qualifiedArticleCode
                     * @property {boolean|null} [showEvenThoughOutOfStock] Product showEvenThoughOutOfStock
                     * @property {string|null} [displayCode] Product displayCode
                     * @property {theplant.ec.service.products.CustomizationProductType|null} [customizationProductType] Product customizationProductType
                     * @property {theplant.ec.service.customization.ICustomizationRecord|null} [customization] Product customization
                     */

                    /**
                     * Constructs a new Product.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a Product.
                     * @implements IProduct
                     * @constructor
                     * @param {theplant.ec.service.products.IProduct=} [properties] Properties to set
                     */
                    function Product(properties) {
                        this.variants = [];
                        this.filterProperties = [];
                        this.extraProperties = [];
                        this.images = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Product code.
                     * @member {string} code
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.code = "";

                    /**
                     * Product name.
                     * @member {string} name
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.name = "";

                    /**
                     * Product description.
                     * @member {string} description
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.description = "";

                    /**
                     * Product currency.
                     * @member {string} currency
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.currency = "";

                    /**
                     * Product releaseDate.
                     * @member {string} releaseDate
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.releaseDate = "";

                    /**
                     * Product searchText.
                     * @member {string} searchText
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.searchText = "";

                    /**
                     * Product saleVolume.
                     * @member {number|Long} saleVolume
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.saleVolume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Product publishReady.
                     * @member {boolean} publishReady
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.publishReady = false;

                    /**
                     * Product scheduledStartAt.
                     * @member {string} scheduledStartAt
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.scheduledStartAt = "";

                    /**
                     * Product scheduledEndAt.
                     * @member {string} scheduledEndAt
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.scheduledEndAt = "";

                    /**
                     * Product variants.
                     * @member {Array.<theplant.ec.service.products.IVariant>} variants
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.variants = $util.emptyArray;

                    /**
                     * for filter products
                     * @member {Array.<theplant.ec.service.base.IProperty>} filterProperties
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.filterProperties = $util.emptyArray;

                    /**
                     * Product extraProperties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} extraProperties
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.extraProperties = $util.emptyArray;

                    /**
                     * Product images.
                     * @member {Array.<theplant.ec.service.products.IImage>} images
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.images = $util.emptyArray;

                    /**
                     * Product qualifiedArticleCode.
                     * @member {string} qualifiedArticleCode
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.qualifiedArticleCode = "";

                    /**
                     * Product showEvenThoughOutOfStock.
                     * @member {boolean} showEvenThoughOutOfStock
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.showEvenThoughOutOfStock = false;

                    /**
                     * Product displayCode.
                     * @member {string} displayCode
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.displayCode = "";

                    /**
                     * Product customizationProductType.
                     * @member {theplant.ec.service.products.CustomizationProductType} customizationProductType
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.customizationProductType = 0;

                    /**
                     * Product customization.
                     * @member {theplant.ec.service.customization.ICustomizationRecord|null|undefined} customization
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     */
                    Product.prototype.customization = null;

                    /**
                     * Creates a new Product instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {theplant.ec.service.products.IProduct=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.Product} Product instance
                     */
                    Product.create = function create(properties) {
                        return new Product(properties);
                    };

                    /**
                     * Encodes the specified Product message. Does not implicitly {@link theplant.ec.service.products.Product.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {theplant.ec.service.products.IProduct} message Product message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Product.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.description);
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.currency);
                        if (message.variants != null && message.variants.length)
                            for (var i = 0; i < message.variants.length; ++i)
                                $root.theplant.ec.service.products.Variant.encode(message.variants[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.filterProperties != null && message.filterProperties.length)
                            for (var i = 0; i < message.filterProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.filterProperties[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.extraProperties != null && message.extraProperties.length)
                            for (var i = 0; i < message.extraProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.extraProperties[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.images != null && message.images.length)
                            for (var i = 0; i < message.images.length; ++i)
                                $root.theplant.ec.service.products.Image.encode(message.images[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.publishReady);
                        if (message.scheduledStartAt != null && message.hasOwnProperty("scheduledStartAt"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.scheduledStartAt);
                        if (message.scheduledEndAt != null && message.hasOwnProperty("scheduledEndAt"))
                            writer.uint32(/* id 13, wireType 2 =*/106).string(message.scheduledEndAt);
                        if (message.searchText != null && message.hasOwnProperty("searchText"))
                            writer.uint32(/* id 14, wireType 2 =*/114).string(message.searchText);
                        if (message.releaseDate != null && message.hasOwnProperty("releaseDate"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.releaseDate);
                        if (message.saleVolume != null && message.hasOwnProperty("saleVolume"))
                            writer.uint32(/* id 16, wireType 0 =*/128).int64(message.saleVolume);
                        if (message.qualifiedArticleCode != null && message.hasOwnProperty("qualifiedArticleCode"))
                            writer.uint32(/* id 17, wireType 2 =*/138).string(message.qualifiedArticleCode);
                        if (message.showEvenThoughOutOfStock != null && message.hasOwnProperty("showEvenThoughOutOfStock"))
                            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.showEvenThoughOutOfStock);
                        if (message.displayCode != null && message.hasOwnProperty("displayCode"))
                            writer.uint32(/* id 19, wireType 2 =*/154).string(message.displayCode);
                        if (message.customizationProductType != null && message.hasOwnProperty("customizationProductType"))
                            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.customizationProductType);
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            $root.theplant.ec.service.customization.CustomizationRecord.encode(message.customization, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Product message, length delimited. Does not implicitly {@link theplant.ec.service.products.Product.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {theplant.ec.service.products.IProduct} message Product message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Product.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Product message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.Product} Product
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Product.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.Product();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.code = reader.string();
                                break;
                            case 3:
                                message.name = reader.string();
                                break;
                            case 4:
                                message.description = reader.string();
                                break;
                            case 5:
                                message.currency = reader.string();
                                break;
                            case 15:
                                message.releaseDate = reader.string();
                                break;
                            case 14:
                                message.searchText = reader.string();
                                break;
                            case 16:
                                message.saleVolume = reader.int64();
                                break;
                            case 11:
                                message.publishReady = reader.bool();
                                break;
                            case 12:
                                message.scheduledStartAt = reader.string();
                                break;
                            case 13:
                                message.scheduledEndAt = reader.string();
                                break;
                            case 6:
                                if (!(message.variants && message.variants.length))
                                    message.variants = [];
                                message.variants.push($root.theplant.ec.service.products.Variant.decode(reader, reader.uint32()));
                                break;
                            case 7:
                                if (!(message.filterProperties && message.filterProperties.length))
                                    message.filterProperties = [];
                                message.filterProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                if (!(message.extraProperties && message.extraProperties.length))
                                    message.extraProperties = [];
                                message.extraProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 10:
                                if (!(message.images && message.images.length))
                                    message.images = [];
                                message.images.push($root.theplant.ec.service.products.Image.decode(reader, reader.uint32()));
                                break;
                            case 17:
                                message.qualifiedArticleCode = reader.string();
                                break;
                            case 18:
                                message.showEvenThoughOutOfStock = reader.bool();
                                break;
                            case 19:
                                message.displayCode = reader.string();
                                break;
                            case 20:
                                message.customizationProductType = reader.int32();
                                break;
                            case 21:
                                message.customization = $root.theplant.ec.service.customization.CustomizationRecord.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Product message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.Product} Product
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Product.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Product message.
                     * @function verify
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Product.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            if (!$util.isString(message.currency))
                                return "currency: string expected";
                        if (message.releaseDate != null && message.hasOwnProperty("releaseDate"))
                            if (!$util.isString(message.releaseDate))
                                return "releaseDate: string expected";
                        if (message.searchText != null && message.hasOwnProperty("searchText"))
                            if (!$util.isString(message.searchText))
                                return "searchText: string expected";
                        if (message.saleVolume != null && message.hasOwnProperty("saleVolume"))
                            if (!$util.isInteger(message.saleVolume) && !(message.saleVolume && $util.isInteger(message.saleVolume.low) && $util.isInteger(message.saleVolume.high)))
                                return "saleVolume: integer|Long expected";
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            if (typeof message.publishReady !== "boolean")
                                return "publishReady: boolean expected";
                        if (message.scheduledStartAt != null && message.hasOwnProperty("scheduledStartAt"))
                            if (!$util.isString(message.scheduledStartAt))
                                return "scheduledStartAt: string expected";
                        if (message.scheduledEndAt != null && message.hasOwnProperty("scheduledEndAt"))
                            if (!$util.isString(message.scheduledEndAt))
                                return "scheduledEndAt: string expected";
                        if (message.variants != null && message.hasOwnProperty("variants")) {
                            if (!Array.isArray(message.variants))
                                return "variants: array expected";
                            for (var i = 0; i < message.variants.length; ++i) {
                                var error = $root.theplant.ec.service.products.Variant.verify(message.variants[i]);
                                if (error)
                                    return "variants." + error;
                            }
                        }
                        if (message.filterProperties != null && message.hasOwnProperty("filterProperties")) {
                            if (!Array.isArray(message.filterProperties))
                                return "filterProperties: array expected";
                            for (var i = 0; i < message.filterProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.filterProperties[i]);
                                if (error)
                                    return "filterProperties." + error;
                            }
                        }
                        if (message.extraProperties != null && message.hasOwnProperty("extraProperties")) {
                            if (!Array.isArray(message.extraProperties))
                                return "extraProperties: array expected";
                            for (var i = 0; i < message.extraProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.extraProperties[i]);
                                if (error)
                                    return "extraProperties." + error;
                            }
                        }
                        if (message.images != null && message.hasOwnProperty("images")) {
                            if (!Array.isArray(message.images))
                                return "images: array expected";
                            for (var i = 0; i < message.images.length; ++i) {
                                var error = $root.theplant.ec.service.products.Image.verify(message.images[i]);
                                if (error)
                                    return "images." + error;
                            }
                        }
                        if (message.qualifiedArticleCode != null && message.hasOwnProperty("qualifiedArticleCode"))
                            if (!$util.isString(message.qualifiedArticleCode))
                                return "qualifiedArticleCode: string expected";
                        if (message.showEvenThoughOutOfStock != null && message.hasOwnProperty("showEvenThoughOutOfStock"))
                            if (typeof message.showEvenThoughOutOfStock !== "boolean")
                                return "showEvenThoughOutOfStock: boolean expected";
                        if (message.displayCode != null && message.hasOwnProperty("displayCode"))
                            if (!$util.isString(message.displayCode))
                                return "displayCode: string expected";
                        if (message.customizationProductType != null && message.hasOwnProperty("customizationProductType"))
                            switch (message.customizationProductType) {
                            default:
                                return "customizationProductType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.customization != null && message.hasOwnProperty("customization")) {
                            var error = $root.theplant.ec.service.customization.CustomizationRecord.verify(message.customization);
                            if (error)
                                return "customization." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a Product message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.Product} Product
                     */
                    Product.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.Product)
                            return object;
                        var message = new $root.theplant.ec.service.products.Product();
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.currency != null)
                            message.currency = String(object.currency);
                        if (object.releaseDate != null)
                            message.releaseDate = String(object.releaseDate);
                        if (object.searchText != null)
                            message.searchText = String(object.searchText);
                        if (object.saleVolume != null)
                            if ($util.Long)
                                (message.saleVolume = $util.Long.fromValue(object.saleVolume)).unsigned = false;
                            else if (typeof object.saleVolume === "string")
                                message.saleVolume = parseInt(object.saleVolume, 10);
                            else if (typeof object.saleVolume === "number")
                                message.saleVolume = object.saleVolume;
                            else if (typeof object.saleVolume === "object")
                                message.saleVolume = new $util.LongBits(object.saleVolume.low >>> 0, object.saleVolume.high >>> 0).toNumber();
                        if (object.publishReady != null)
                            message.publishReady = Boolean(object.publishReady);
                        if (object.scheduledStartAt != null)
                            message.scheduledStartAt = String(object.scheduledStartAt);
                        if (object.scheduledEndAt != null)
                            message.scheduledEndAt = String(object.scheduledEndAt);
                        if (object.variants) {
                            if (!Array.isArray(object.variants))
                                throw TypeError(".theplant.ec.service.products.Product.variants: array expected");
                            message.variants = [];
                            for (var i = 0; i < object.variants.length; ++i) {
                                if (typeof object.variants[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Product.variants: object expected");
                                message.variants[i] = $root.theplant.ec.service.products.Variant.fromObject(object.variants[i]);
                            }
                        }
                        if (object.filterProperties) {
                            if (!Array.isArray(object.filterProperties))
                                throw TypeError(".theplant.ec.service.products.Product.filterProperties: array expected");
                            message.filterProperties = [];
                            for (var i = 0; i < object.filterProperties.length; ++i) {
                                if (typeof object.filterProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Product.filterProperties: object expected");
                                message.filterProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.filterProperties[i]);
                            }
                        }
                        if (object.extraProperties) {
                            if (!Array.isArray(object.extraProperties))
                                throw TypeError(".theplant.ec.service.products.Product.extraProperties: array expected");
                            message.extraProperties = [];
                            for (var i = 0; i < object.extraProperties.length; ++i) {
                                if (typeof object.extraProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Product.extraProperties: object expected");
                                message.extraProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.extraProperties[i]);
                            }
                        }
                        if (object.images) {
                            if (!Array.isArray(object.images))
                                throw TypeError(".theplant.ec.service.products.Product.images: array expected");
                            message.images = [];
                            for (var i = 0; i < object.images.length; ++i) {
                                if (typeof object.images[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Product.images: object expected");
                                message.images[i] = $root.theplant.ec.service.products.Image.fromObject(object.images[i]);
                            }
                        }
                        if (object.qualifiedArticleCode != null)
                            message.qualifiedArticleCode = String(object.qualifiedArticleCode);
                        if (object.showEvenThoughOutOfStock != null)
                            message.showEvenThoughOutOfStock = Boolean(object.showEvenThoughOutOfStock);
                        if (object.displayCode != null)
                            message.displayCode = String(object.displayCode);
                        switch (object.customizationProductType) {
                        case "Entrance":
                        case 0:
                            message.customizationProductType = 0;
                            break;
                        case "Target":
                        case 1:
                            message.customizationProductType = 1;
                            break;
                        }
                        if (object.customization != null) {
                            if (typeof object.customization !== "object")
                                throw TypeError(".theplant.ec.service.products.Product.customization: object expected");
                            message.customization = $root.theplant.ec.service.customization.CustomizationRecord.fromObject(object.customization);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Product message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.Product
                     * @static
                     * @param {theplant.ec.service.products.Product} message Product
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Product.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.variants = [];
                            object.filterProperties = [];
                            object.extraProperties = [];
                            object.images = [];
                        }
                        if (options.defaults) {
                            object.code = "";
                            object.name = "";
                            object.description = "";
                            object.currency = "";
                            object.publishReady = false;
                            object.scheduledStartAt = "";
                            object.scheduledEndAt = "";
                            object.searchText = "";
                            object.releaseDate = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.saleVolume = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.saleVolume = options.longs === String ? "0" : 0;
                            object.qualifiedArticleCode = "";
                            object.showEvenThoughOutOfStock = false;
                            object.displayCode = "";
                            object.customizationProductType = options.enums === String ? "Entrance" : 0;
                            object.customization = null;
                        }
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.currency != null && message.hasOwnProperty("currency"))
                            object.currency = message.currency;
                        if (message.variants && message.variants.length) {
                            object.variants = [];
                            for (var j = 0; j < message.variants.length; ++j)
                                object.variants[j] = $root.theplant.ec.service.products.Variant.toObject(message.variants[j], options);
                        }
                        if (message.filterProperties && message.filterProperties.length) {
                            object.filterProperties = [];
                            for (var j = 0; j < message.filterProperties.length; ++j)
                                object.filterProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.filterProperties[j], options);
                        }
                        if (message.extraProperties && message.extraProperties.length) {
                            object.extraProperties = [];
                            for (var j = 0; j < message.extraProperties.length; ++j)
                                object.extraProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.extraProperties[j], options);
                        }
                        if (message.images && message.images.length) {
                            object.images = [];
                            for (var j = 0; j < message.images.length; ++j)
                                object.images[j] = $root.theplant.ec.service.products.Image.toObject(message.images[j], options);
                        }
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            object.publishReady = message.publishReady;
                        if (message.scheduledStartAt != null && message.hasOwnProperty("scheduledStartAt"))
                            object.scheduledStartAt = message.scheduledStartAt;
                        if (message.scheduledEndAt != null && message.hasOwnProperty("scheduledEndAt"))
                            object.scheduledEndAt = message.scheduledEndAt;
                        if (message.searchText != null && message.hasOwnProperty("searchText"))
                            object.searchText = message.searchText;
                        if (message.releaseDate != null && message.hasOwnProperty("releaseDate"))
                            object.releaseDate = message.releaseDate;
                        if (message.saleVolume != null && message.hasOwnProperty("saleVolume"))
                            if (typeof message.saleVolume === "number")
                                object.saleVolume = options.longs === String ? String(message.saleVolume) : message.saleVolume;
                            else
                                object.saleVolume = options.longs === String ? $util.Long.prototype.toString.call(message.saleVolume) : options.longs === Number ? new $util.LongBits(message.saleVolume.low >>> 0, message.saleVolume.high >>> 0).toNumber() : message.saleVolume;
                        if (message.qualifiedArticleCode != null && message.hasOwnProperty("qualifiedArticleCode"))
                            object.qualifiedArticleCode = message.qualifiedArticleCode;
                        if (message.showEvenThoughOutOfStock != null && message.hasOwnProperty("showEvenThoughOutOfStock"))
                            object.showEvenThoughOutOfStock = message.showEvenThoughOutOfStock;
                        if (message.displayCode != null && message.hasOwnProperty("displayCode"))
                            object.displayCode = message.displayCode;
                        if (message.customizationProductType != null && message.hasOwnProperty("customizationProductType"))
                            object.customizationProductType = options.enums === String ? $root.theplant.ec.service.products.CustomizationProductType[message.customizationProductType] : message.customizationProductType;
                        if (message.customization != null && message.hasOwnProperty("customization"))
                            object.customization = $root.theplant.ec.service.customization.CustomizationRecord.toObject(message.customization, options);
                        return object;
                    };

                    /**
                     * Converts this Product to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.Product
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Product.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Product;
                })();

                /**
                 * CustomizationProductType enum.
                 * @name theplant.ec.service.products.CustomizationProductType
                 * @enum {string}
                 * @property {number} Entrance=0 Entrance value
                 * @property {number} Target=1 Target value
                 */
                products.CustomizationProductType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "Entrance"] = 0;
                    values[valuesById[1] = "Target"] = 1;
                    return values;
                })();

                products.Variant = (function() {

                    /**
                     * Properties of a Variant.
                     * @memberof theplant.ec.service.products
                     * @interface IVariant
                     * @property {string|null} [articleCode] jancode of Lacoste, sku
                     * @property {string|null} [imageCode] Variant imageCode
                     * @property {number|Long|null} [price] Variant price
                     * @property {number|Long|null} [sellingPrice] Variant sellingPrice
                     * @property {boolean|null} [inInventory] Variant inInventory
                     * @property {number|Long|null} [inventoryQuantity] Variant inventoryQuantity
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [filterProperties] for select model options on product detail page
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [extraProperties] Variant extraProperties
                     * @property {string|null} [releaseDate] Variant releaseDate
                     * @property {boolean|null} [publishReady] Variant publishReady
                     * @property {string|null} [scheduledStartAt] Variant scheduledStartAt
                     * @property {string|null} [scheduledEndAt] Variant scheduledEndAt
                     * @property {theplant.ec.service.accounting.IItemSumUp|null} [sumUp] Variant sumUp
                     * @property {number|Long|null} [volumeCoefficient] Variant volumeCoefficient
                     * @property {boolean|null} [anyStoreInInventory] Variant anyStoreInInventory
                     * @property {boolean|null} [isShipFromStore] Variant isShipFromStore
                     * @property {number|Long|null} [storeInventoryQuantity] Variant storeInventoryQuantity
                     * @property {boolean|null} [storeInInventory] Variant storeInInventory
                     */

                    /**
                     * Constructs a new Variant.
                     * @memberof theplant.ec.service.products
                     * @classdesc A variant is a SKU (Stock Keeping Unit), a product of certain color and size etc. a unit for sale, ship.
                     * @implements IVariant
                     * @constructor
                     * @param {theplant.ec.service.products.IVariant=} [properties] Properties to set
                     */
                    function Variant(properties) {
                        this.filterProperties = [];
                        this.extraProperties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * jancode of Lacoste, sku
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.articleCode = "";

                    /**
                     * Variant imageCode.
                     * @member {string} imageCode
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.imageCode = "";

                    /**
                     * Variant price.
                     * @member {number|Long} price
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Variant sellingPrice.
                     * @member {number|Long} sellingPrice
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.sellingPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Variant inInventory.
                     * @member {boolean} inInventory
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.inInventory = false;

                    /**
                     * Variant inventoryQuantity.
                     * @member {number|Long} inventoryQuantity
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.inventoryQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * for select model options on product detail page
                     * @member {Array.<theplant.ec.service.base.IProperty>} filterProperties
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.filterProperties = $util.emptyArray;

                    /**
                     * Variant extraProperties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} extraProperties
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.extraProperties = $util.emptyArray;

                    /**
                     * Variant releaseDate.
                     * @member {string} releaseDate
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.releaseDate = "";

                    /**
                     * Variant publishReady.
                     * @member {boolean} publishReady
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.publishReady = false;

                    /**
                     * Variant scheduledStartAt.
                     * @member {string} scheduledStartAt
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.scheduledStartAt = "";

                    /**
                     * Variant scheduledEndAt.
                     * @member {string} scheduledEndAt
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.scheduledEndAt = "";

                    /**
                     * Variant sumUp.
                     * @member {theplant.ec.service.accounting.IItemSumUp|null|undefined} sumUp
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.sumUp = null;

                    /**
                     * Variant volumeCoefficient.
                     * @member {number|Long} volumeCoefficient
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.volumeCoefficient = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Variant anyStoreInInventory.
                     * @member {boolean} anyStoreInInventory
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.anyStoreInInventory = false;

                    /**
                     * Variant isShipFromStore.
                     * @member {boolean} isShipFromStore
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.isShipFromStore = false;

                    /**
                     * Variant storeInventoryQuantity.
                     * @member {number|Long} storeInventoryQuantity
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.storeInventoryQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Variant storeInInventory.
                     * @member {boolean} storeInInventory
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     */
                    Variant.prototype.storeInInventory = false;

                    /**
                     * Creates a new Variant instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {theplant.ec.service.products.IVariant=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.Variant} Variant instance
                     */
                    Variant.create = function create(properties) {
                        return new Variant(properties);
                    };

                    /**
                     * Encodes the specified Variant message. Does not implicitly {@link theplant.ec.service.products.Variant.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {theplant.ec.service.products.IVariant} message Variant message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Variant.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.imageCode != null && message.hasOwnProperty("imageCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageCode);
                        if (message.inInventory != null && message.hasOwnProperty("inInventory"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.inInventory);
                        if (message.price != null && message.hasOwnProperty("price"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.price);
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.sellingPrice);
                        if (message.filterProperties != null && message.filterProperties.length)
                            for (var i = 0; i < message.filterProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.filterProperties[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.extraProperties != null && message.extraProperties.length)
                            for (var i = 0; i < message.extraProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.extraProperties[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.releaseDate != null && message.hasOwnProperty("releaseDate"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.releaseDate);
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.publishReady);
                        if (message.scheduledStartAt != null && message.hasOwnProperty("scheduledStartAt"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.scheduledStartAt);
                        if (message.scheduledEndAt != null && message.hasOwnProperty("scheduledEndAt"))
                            writer.uint32(/* id 11, wireType 2 =*/90).string(message.scheduledEndAt);
                        if (message.inventoryQuantity != null && message.hasOwnProperty("inventoryQuantity"))
                            writer.uint32(/* id 12, wireType 0 =*/96).int64(message.inventoryQuantity);
                        if (message.sumUp != null && message.hasOwnProperty("sumUp"))
                            $root.theplant.ec.service.accounting.ItemSumUp.encode(message.sumUp, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.volumeCoefficient != null && message.hasOwnProperty("volumeCoefficient"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int64(message.volumeCoefficient);
                        if (message.anyStoreInInventory != null && message.hasOwnProperty("anyStoreInInventory"))
                            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.anyStoreInInventory);
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.isShipFromStore);
                        if (message.storeInventoryQuantity != null && message.hasOwnProperty("storeInventoryQuantity"))
                            writer.uint32(/* id 17, wireType 0 =*/136).int64(message.storeInventoryQuantity);
                        if (message.storeInInventory != null && message.hasOwnProperty("storeInInventory"))
                            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.storeInInventory);
                        return writer;
                    };

                    /**
                     * Encodes the specified Variant message, length delimited. Does not implicitly {@link theplant.ec.service.products.Variant.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {theplant.ec.service.products.IVariant} message Variant message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Variant.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Variant message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.Variant} Variant
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Variant.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.Variant();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.imageCode = reader.string();
                                break;
                            case 4:
                                message.price = reader.int64();
                                break;
                            case 5:
                                message.sellingPrice = reader.int64();
                                break;
                            case 3:
                                message.inInventory = reader.bool();
                                break;
                            case 12:
                                message.inventoryQuantity = reader.int64();
                                break;
                            case 6:
                                if (!(message.filterProperties && message.filterProperties.length))
                                    message.filterProperties = [];
                                message.filterProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 7:
                                if (!(message.extraProperties && message.extraProperties.length))
                                    message.extraProperties = [];
                                message.extraProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                message.releaseDate = reader.string();
                                break;
                            case 9:
                                message.publishReady = reader.bool();
                                break;
                            case 10:
                                message.scheduledStartAt = reader.string();
                                break;
                            case 11:
                                message.scheduledEndAt = reader.string();
                                break;
                            case 13:
                                message.sumUp = $root.theplant.ec.service.accounting.ItemSumUp.decode(reader, reader.uint32());
                                break;
                            case 14:
                                message.volumeCoefficient = reader.int64();
                                break;
                            case 15:
                                message.anyStoreInInventory = reader.bool();
                                break;
                            case 16:
                                message.isShipFromStore = reader.bool();
                                break;
                            case 17:
                                message.storeInventoryQuantity = reader.int64();
                                break;
                            case 18:
                                message.storeInInventory = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Variant message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.Variant} Variant
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Variant.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Variant message.
                     * @function verify
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Variant.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.imageCode != null && message.hasOwnProperty("imageCode"))
                            if (!$util.isString(message.imageCode))
                                return "imageCode: string expected";
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                return "price: integer|Long expected";
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (!$util.isInteger(message.sellingPrice) && !(message.sellingPrice && $util.isInteger(message.sellingPrice.low) && $util.isInteger(message.sellingPrice.high)))
                                return "sellingPrice: integer|Long expected";
                        if (message.inInventory != null && message.hasOwnProperty("inInventory"))
                            if (typeof message.inInventory !== "boolean")
                                return "inInventory: boolean expected";
                        if (message.inventoryQuantity != null && message.hasOwnProperty("inventoryQuantity"))
                            if (!$util.isInteger(message.inventoryQuantity) && !(message.inventoryQuantity && $util.isInteger(message.inventoryQuantity.low) && $util.isInteger(message.inventoryQuantity.high)))
                                return "inventoryQuantity: integer|Long expected";
                        if (message.filterProperties != null && message.hasOwnProperty("filterProperties")) {
                            if (!Array.isArray(message.filterProperties))
                                return "filterProperties: array expected";
                            for (var i = 0; i < message.filterProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.filterProperties[i]);
                                if (error)
                                    return "filterProperties." + error;
                            }
                        }
                        if (message.extraProperties != null && message.hasOwnProperty("extraProperties")) {
                            if (!Array.isArray(message.extraProperties))
                                return "extraProperties: array expected";
                            for (var i = 0; i < message.extraProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.extraProperties[i]);
                                if (error)
                                    return "extraProperties." + error;
                            }
                        }
                        if (message.releaseDate != null && message.hasOwnProperty("releaseDate"))
                            if (!$util.isString(message.releaseDate))
                                return "releaseDate: string expected";
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            if (typeof message.publishReady !== "boolean")
                                return "publishReady: boolean expected";
                        if (message.scheduledStartAt != null && message.hasOwnProperty("scheduledStartAt"))
                            if (!$util.isString(message.scheduledStartAt))
                                return "scheduledStartAt: string expected";
                        if (message.scheduledEndAt != null && message.hasOwnProperty("scheduledEndAt"))
                            if (!$util.isString(message.scheduledEndAt))
                                return "scheduledEndAt: string expected";
                        if (message.sumUp != null && message.hasOwnProperty("sumUp")) {
                            var error = $root.theplant.ec.service.accounting.ItemSumUp.verify(message.sumUp);
                            if (error)
                                return "sumUp." + error;
                        }
                        if (message.volumeCoefficient != null && message.hasOwnProperty("volumeCoefficient"))
                            if (!$util.isInteger(message.volumeCoefficient) && !(message.volumeCoefficient && $util.isInteger(message.volumeCoefficient.low) && $util.isInteger(message.volumeCoefficient.high)))
                                return "volumeCoefficient: integer|Long expected";
                        if (message.anyStoreInInventory != null && message.hasOwnProperty("anyStoreInInventory"))
                            if (typeof message.anyStoreInInventory !== "boolean")
                                return "anyStoreInInventory: boolean expected";
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            if (typeof message.isShipFromStore !== "boolean")
                                return "isShipFromStore: boolean expected";
                        if (message.storeInventoryQuantity != null && message.hasOwnProperty("storeInventoryQuantity"))
                            if (!$util.isInteger(message.storeInventoryQuantity) && !(message.storeInventoryQuantity && $util.isInteger(message.storeInventoryQuantity.low) && $util.isInteger(message.storeInventoryQuantity.high)))
                                return "storeInventoryQuantity: integer|Long expected";
                        if (message.storeInInventory != null && message.hasOwnProperty("storeInInventory"))
                            if (typeof message.storeInInventory !== "boolean")
                                return "storeInInventory: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Variant message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.Variant} Variant
                     */
                    Variant.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.Variant)
                            return object;
                        var message = new $root.theplant.ec.service.products.Variant();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.imageCode != null)
                            message.imageCode = String(object.imageCode);
                        if (object.price != null)
                            if ($util.Long)
                                (message.price = $util.Long.fromValue(object.price)).unsigned = false;
                            else if (typeof object.price === "string")
                                message.price = parseInt(object.price, 10);
                            else if (typeof object.price === "number")
                                message.price = object.price;
                            else if (typeof object.price === "object")
                                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
                        if (object.sellingPrice != null)
                            if ($util.Long)
                                (message.sellingPrice = $util.Long.fromValue(object.sellingPrice)).unsigned = false;
                            else if (typeof object.sellingPrice === "string")
                                message.sellingPrice = parseInt(object.sellingPrice, 10);
                            else if (typeof object.sellingPrice === "number")
                                message.sellingPrice = object.sellingPrice;
                            else if (typeof object.sellingPrice === "object")
                                message.sellingPrice = new $util.LongBits(object.sellingPrice.low >>> 0, object.sellingPrice.high >>> 0).toNumber();
                        if (object.inInventory != null)
                            message.inInventory = Boolean(object.inInventory);
                        if (object.inventoryQuantity != null)
                            if ($util.Long)
                                (message.inventoryQuantity = $util.Long.fromValue(object.inventoryQuantity)).unsigned = false;
                            else if (typeof object.inventoryQuantity === "string")
                                message.inventoryQuantity = parseInt(object.inventoryQuantity, 10);
                            else if (typeof object.inventoryQuantity === "number")
                                message.inventoryQuantity = object.inventoryQuantity;
                            else if (typeof object.inventoryQuantity === "object")
                                message.inventoryQuantity = new $util.LongBits(object.inventoryQuantity.low >>> 0, object.inventoryQuantity.high >>> 0).toNumber();
                        if (object.filterProperties) {
                            if (!Array.isArray(object.filterProperties))
                                throw TypeError(".theplant.ec.service.products.Variant.filterProperties: array expected");
                            message.filterProperties = [];
                            for (var i = 0; i < object.filterProperties.length; ++i) {
                                if (typeof object.filterProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Variant.filterProperties: object expected");
                                message.filterProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.filterProperties[i]);
                            }
                        }
                        if (object.extraProperties) {
                            if (!Array.isArray(object.extraProperties))
                                throw TypeError(".theplant.ec.service.products.Variant.extraProperties: array expected");
                            message.extraProperties = [];
                            for (var i = 0; i < object.extraProperties.length; ++i) {
                                if (typeof object.extraProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Variant.extraProperties: object expected");
                                message.extraProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.extraProperties[i]);
                            }
                        }
                        if (object.releaseDate != null)
                            message.releaseDate = String(object.releaseDate);
                        if (object.publishReady != null)
                            message.publishReady = Boolean(object.publishReady);
                        if (object.scheduledStartAt != null)
                            message.scheduledStartAt = String(object.scheduledStartAt);
                        if (object.scheduledEndAt != null)
                            message.scheduledEndAt = String(object.scheduledEndAt);
                        if (object.sumUp != null) {
                            if (typeof object.sumUp !== "object")
                                throw TypeError(".theplant.ec.service.products.Variant.sumUp: object expected");
                            message.sumUp = $root.theplant.ec.service.accounting.ItemSumUp.fromObject(object.sumUp);
                        }
                        if (object.volumeCoefficient != null)
                            if ($util.Long)
                                (message.volumeCoefficient = $util.Long.fromValue(object.volumeCoefficient)).unsigned = false;
                            else if (typeof object.volumeCoefficient === "string")
                                message.volumeCoefficient = parseInt(object.volumeCoefficient, 10);
                            else if (typeof object.volumeCoefficient === "number")
                                message.volumeCoefficient = object.volumeCoefficient;
                            else if (typeof object.volumeCoefficient === "object")
                                message.volumeCoefficient = new $util.LongBits(object.volumeCoefficient.low >>> 0, object.volumeCoefficient.high >>> 0).toNumber();
                        if (object.anyStoreInInventory != null)
                            message.anyStoreInInventory = Boolean(object.anyStoreInInventory);
                        if (object.isShipFromStore != null)
                            message.isShipFromStore = Boolean(object.isShipFromStore);
                        if (object.storeInventoryQuantity != null)
                            if ($util.Long)
                                (message.storeInventoryQuantity = $util.Long.fromValue(object.storeInventoryQuantity)).unsigned = false;
                            else if (typeof object.storeInventoryQuantity === "string")
                                message.storeInventoryQuantity = parseInt(object.storeInventoryQuantity, 10);
                            else if (typeof object.storeInventoryQuantity === "number")
                                message.storeInventoryQuantity = object.storeInventoryQuantity;
                            else if (typeof object.storeInventoryQuantity === "object")
                                message.storeInventoryQuantity = new $util.LongBits(object.storeInventoryQuantity.low >>> 0, object.storeInventoryQuantity.high >>> 0).toNumber();
                        if (object.storeInInventory != null)
                            message.storeInInventory = Boolean(object.storeInInventory);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Variant message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.Variant
                     * @static
                     * @param {theplant.ec.service.products.Variant} message Variant
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Variant.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.filterProperties = [];
                            object.extraProperties = [];
                        }
                        if (options.defaults) {
                            object.articleCode = "";
                            object.imageCode = "";
                            object.inInventory = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.price = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.sellingPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sellingPrice = options.longs === String ? "0" : 0;
                            object.releaseDate = "";
                            object.publishReady = false;
                            object.scheduledStartAt = "";
                            object.scheduledEndAt = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.inventoryQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.inventoryQuantity = options.longs === String ? "0" : 0;
                            object.sumUp = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.volumeCoefficient = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.volumeCoefficient = options.longs === String ? "0" : 0;
                            object.anyStoreInInventory = false;
                            object.isShipFromStore = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.storeInventoryQuantity = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.storeInventoryQuantity = options.longs === String ? "0" : 0;
                            object.storeInInventory = false;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.imageCode != null && message.hasOwnProperty("imageCode"))
                            object.imageCode = message.imageCode;
                        if (message.inInventory != null && message.hasOwnProperty("inInventory"))
                            object.inInventory = message.inInventory;
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (typeof message.price === "number")
                                object.price = options.longs === String ? String(message.price) : message.price;
                            else
                                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber() : message.price;
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (typeof message.sellingPrice === "number")
                                object.sellingPrice = options.longs === String ? String(message.sellingPrice) : message.sellingPrice;
                            else
                                object.sellingPrice = options.longs === String ? $util.Long.prototype.toString.call(message.sellingPrice) : options.longs === Number ? new $util.LongBits(message.sellingPrice.low >>> 0, message.sellingPrice.high >>> 0).toNumber() : message.sellingPrice;
                        if (message.filterProperties && message.filterProperties.length) {
                            object.filterProperties = [];
                            for (var j = 0; j < message.filterProperties.length; ++j)
                                object.filterProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.filterProperties[j], options);
                        }
                        if (message.extraProperties && message.extraProperties.length) {
                            object.extraProperties = [];
                            for (var j = 0; j < message.extraProperties.length; ++j)
                                object.extraProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.extraProperties[j], options);
                        }
                        if (message.releaseDate != null && message.hasOwnProperty("releaseDate"))
                            object.releaseDate = message.releaseDate;
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            object.publishReady = message.publishReady;
                        if (message.scheduledStartAt != null && message.hasOwnProperty("scheduledStartAt"))
                            object.scheduledStartAt = message.scheduledStartAt;
                        if (message.scheduledEndAt != null && message.hasOwnProperty("scheduledEndAt"))
                            object.scheduledEndAt = message.scheduledEndAt;
                        if (message.inventoryQuantity != null && message.hasOwnProperty("inventoryQuantity"))
                            if (typeof message.inventoryQuantity === "number")
                                object.inventoryQuantity = options.longs === String ? String(message.inventoryQuantity) : message.inventoryQuantity;
                            else
                                object.inventoryQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.inventoryQuantity) : options.longs === Number ? new $util.LongBits(message.inventoryQuantity.low >>> 0, message.inventoryQuantity.high >>> 0).toNumber() : message.inventoryQuantity;
                        if (message.sumUp != null && message.hasOwnProperty("sumUp"))
                            object.sumUp = $root.theplant.ec.service.accounting.ItemSumUp.toObject(message.sumUp, options);
                        if (message.volumeCoefficient != null && message.hasOwnProperty("volumeCoefficient"))
                            if (typeof message.volumeCoefficient === "number")
                                object.volumeCoefficient = options.longs === String ? String(message.volumeCoefficient) : message.volumeCoefficient;
                            else
                                object.volumeCoefficient = options.longs === String ? $util.Long.prototype.toString.call(message.volumeCoefficient) : options.longs === Number ? new $util.LongBits(message.volumeCoefficient.low >>> 0, message.volumeCoefficient.high >>> 0).toNumber() : message.volumeCoefficient;
                        if (message.anyStoreInInventory != null && message.hasOwnProperty("anyStoreInInventory"))
                            object.anyStoreInInventory = message.anyStoreInInventory;
                        if (message.isShipFromStore != null && message.hasOwnProperty("isShipFromStore"))
                            object.isShipFromStore = message.isShipFromStore;
                        if (message.storeInventoryQuantity != null && message.hasOwnProperty("storeInventoryQuantity"))
                            if (typeof message.storeInventoryQuantity === "number")
                                object.storeInventoryQuantity = options.longs === String ? String(message.storeInventoryQuantity) : message.storeInventoryQuantity;
                            else
                                object.storeInventoryQuantity = options.longs === String ? $util.Long.prototype.toString.call(message.storeInventoryQuantity) : options.longs === Number ? new $util.LongBits(message.storeInventoryQuantity.low >>> 0, message.storeInventoryQuantity.high >>> 0).toNumber() : message.storeInventoryQuantity;
                        if (message.storeInInventory != null && message.hasOwnProperty("storeInInventory"))
                            object.storeInInventory = message.storeInInventory;
                        return object;
                    };

                    /**
                     * Converts this Variant to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.Variant
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Variant.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Variant;
                })();

                products.Image = (function() {

                    /**
                     * Properties of an Image.
                     * @memberof theplant.ec.service.products
                     * @interface IImage
                     * @property {string|null} [id] Image id
                     * @property {string|null} [imageCode] Image imageCode
                     * @property {string|null} [field] Image field
                     * @property {string|null} [thumbName] Image thumbName
                     * @property {number|Long|null} [updatedAtUnix] Image updatedAtUnix
                     * @property {string|null} [source] Image source
                     * @property {number|Long|null} [position] Image position
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [extraProperties] Image extraProperties
                     */

                    /**
                     * Constructs a new Image.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents an Image.
                     * @implements IImage
                     * @constructor
                     * @param {theplant.ec.service.products.IImage=} [properties] Properties to set
                     */
                    function Image(properties) {
                        this.extraProperties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Image id.
                     * @member {string} id
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.id = "";

                    /**
                     * Image imageCode.
                     * @member {string} imageCode
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.imageCode = "";

                    /**
                     * Image field.
                     * @member {string} field
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.field = "";

                    /**
                     * Image thumbName.
                     * @member {string} thumbName
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.thumbName = "";

                    /**
                     * Image updatedAtUnix.
                     * @member {number|Long} updatedAtUnix
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.updatedAtUnix = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Image source.
                     * @member {string} source
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.source = "";

                    /**
                     * Image position.
                     * @member {number|Long} position
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.position = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Image extraProperties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} extraProperties
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     */
                    Image.prototype.extraProperties = $util.emptyArray;

                    /**
                     * Creates a new Image instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {theplant.ec.service.products.IImage=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.Image} Image instance
                     */
                    Image.create = function create(properties) {
                        return new Image(properties);
                    };

                    /**
                     * Encodes the specified Image message. Does not implicitly {@link theplant.ec.service.products.Image.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {theplant.ec.service.products.IImage} message Image message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Image.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.id != null && message.hasOwnProperty("id"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                        if (message.imageCode != null && message.hasOwnProperty("imageCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageCode);
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.field);
                        if (message.thumbName != null && message.hasOwnProperty("thumbName"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.thumbName);
                        if (message.updatedAtUnix != null && message.hasOwnProperty("updatedAtUnix"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.updatedAtUnix);
                        if (message.source != null && message.hasOwnProperty("source"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.source);
                        if (message.extraProperties != null && message.extraProperties.length)
                            for (var i = 0; i < message.extraProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.extraProperties[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.position != null && message.hasOwnProperty("position"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.position);
                        return writer;
                    };

                    /**
                     * Encodes the specified Image message, length delimited. Does not implicitly {@link theplant.ec.service.products.Image.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {theplant.ec.service.products.IImage} message Image message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Image.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an Image message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.Image} Image
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Image.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.Image();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.id = reader.string();
                                break;
                            case 2:
                                message.imageCode = reader.string();
                                break;
                            case 3:
                                message.field = reader.string();
                                break;
                            case 4:
                                message.thumbName = reader.string();
                                break;
                            case 5:
                                message.updatedAtUnix = reader.int64();
                                break;
                            case 6:
                                message.source = reader.string();
                                break;
                            case 8:
                                message.position = reader.int64();
                                break;
                            case 7:
                                if (!(message.extraProperties && message.extraProperties.length))
                                    message.extraProperties = [];
                                message.extraProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an Image message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.Image} Image
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Image.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an Image message.
                     * @function verify
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Image.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.id != null && message.hasOwnProperty("id"))
                            if (!$util.isString(message.id))
                                return "id: string expected";
                        if (message.imageCode != null && message.hasOwnProperty("imageCode"))
                            if (!$util.isString(message.imageCode))
                                return "imageCode: string expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.thumbName != null && message.hasOwnProperty("thumbName"))
                            if (!$util.isString(message.thumbName))
                                return "thumbName: string expected";
                        if (message.updatedAtUnix != null && message.hasOwnProperty("updatedAtUnix"))
                            if (!$util.isInteger(message.updatedAtUnix) && !(message.updatedAtUnix && $util.isInteger(message.updatedAtUnix.low) && $util.isInteger(message.updatedAtUnix.high)))
                                return "updatedAtUnix: integer|Long expected";
                        if (message.source != null && message.hasOwnProperty("source"))
                            if (!$util.isString(message.source))
                                return "source: string expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isInteger(message.position) && !(message.position && $util.isInteger(message.position.low) && $util.isInteger(message.position.high)))
                                return "position: integer|Long expected";
                        if (message.extraProperties != null && message.hasOwnProperty("extraProperties")) {
                            if (!Array.isArray(message.extraProperties))
                                return "extraProperties: array expected";
                            for (var i = 0; i < message.extraProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.extraProperties[i]);
                                if (error)
                                    return "extraProperties." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an Image message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.Image} Image
                     */
                    Image.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.Image)
                            return object;
                        var message = new $root.theplant.ec.service.products.Image();
                        if (object.id != null)
                            message.id = String(object.id);
                        if (object.imageCode != null)
                            message.imageCode = String(object.imageCode);
                        if (object.field != null)
                            message.field = String(object.field);
                        if (object.thumbName != null)
                            message.thumbName = String(object.thumbName);
                        if (object.updatedAtUnix != null)
                            if ($util.Long)
                                (message.updatedAtUnix = $util.Long.fromValue(object.updatedAtUnix)).unsigned = false;
                            else if (typeof object.updatedAtUnix === "string")
                                message.updatedAtUnix = parseInt(object.updatedAtUnix, 10);
                            else if (typeof object.updatedAtUnix === "number")
                                message.updatedAtUnix = object.updatedAtUnix;
                            else if (typeof object.updatedAtUnix === "object")
                                message.updatedAtUnix = new $util.LongBits(object.updatedAtUnix.low >>> 0, object.updatedAtUnix.high >>> 0).toNumber();
                        if (object.source != null)
                            message.source = String(object.source);
                        if (object.position != null)
                            if ($util.Long)
                                (message.position = $util.Long.fromValue(object.position)).unsigned = false;
                            else if (typeof object.position === "string")
                                message.position = parseInt(object.position, 10);
                            else if (typeof object.position === "number")
                                message.position = object.position;
                            else if (typeof object.position === "object")
                                message.position = new $util.LongBits(object.position.low >>> 0, object.position.high >>> 0).toNumber();
                        if (object.extraProperties) {
                            if (!Array.isArray(object.extraProperties))
                                throw TypeError(".theplant.ec.service.products.Image.extraProperties: array expected");
                            message.extraProperties = [];
                            for (var i = 0; i < object.extraProperties.length; ++i) {
                                if (typeof object.extraProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Image.extraProperties: object expected");
                                message.extraProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.extraProperties[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an Image message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.Image
                     * @static
                     * @param {theplant.ec.service.products.Image} message Image
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Image.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.extraProperties = [];
                        if (options.defaults) {
                            object.id = "";
                            object.imageCode = "";
                            object.field = "";
                            object.thumbName = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.updatedAtUnix = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.updatedAtUnix = options.longs === String ? "0" : 0;
                            object.source = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.position = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.position = options.longs === String ? "0" : 0;
                        }
                        if (message.id != null && message.hasOwnProperty("id"))
                            object.id = message.id;
                        if (message.imageCode != null && message.hasOwnProperty("imageCode"))
                            object.imageCode = message.imageCode;
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.thumbName != null && message.hasOwnProperty("thumbName"))
                            object.thumbName = message.thumbName;
                        if (message.updatedAtUnix != null && message.hasOwnProperty("updatedAtUnix"))
                            if (typeof message.updatedAtUnix === "number")
                                object.updatedAtUnix = options.longs === String ? String(message.updatedAtUnix) : message.updatedAtUnix;
                            else
                                object.updatedAtUnix = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtUnix) : options.longs === Number ? new $util.LongBits(message.updatedAtUnix.low >>> 0, message.updatedAtUnix.high >>> 0).toNumber() : message.updatedAtUnix;
                        if (message.source != null && message.hasOwnProperty("source"))
                            object.source = message.source;
                        if (message.extraProperties && message.extraProperties.length) {
                            object.extraProperties = [];
                            for (var j = 0; j < message.extraProperties.length; ++j)
                                object.extraProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.extraProperties[j], options);
                        }
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (typeof message.position === "number")
                                object.position = options.longs === String ? String(message.position) : message.position;
                            else
                                object.position = options.longs === String ? $util.Long.prototype.toString.call(message.position) : options.longs === Number ? new $util.LongBits(message.position.low >>> 0, message.position.high >>> 0).toNumber() : message.position;
                        return object;
                    };

                    /**
                     * Converts this Image to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.Image
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Image.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Image;
                })();

                products.SearchOptions = (function() {

                    /**
                     * Properties of a SearchOptions.
                     * @memberof theplant.ec.service.products
                     * @interface ISearchOptions
                     * @property {string|null} [keyword] SearchOptions keyword
                     * @property {number|Long|null} [page] SearchOptions page
                     * @property {number|Long|null} [perPage] SearchOptions perPage
                     * @property {number|Long|null} [prevPadding] SearchOptions prevPadding
                     * @property {number|Long|null} [currPadding] SearchOptions currPadding
                     * @property {Array.<theplant.ec.service.products.IFilterProperty>|null} [filterProperties] SearchOptions filterProperties
                     * @property {Array.<theplant.ec.service.products.ISorter>|null} [sorters] SearchOptions sorters
                     * @property {Array.<theplant.ec.service.products.FilterCondition>|null} [conditions] SearchOptions conditions
                     * @property {Array.<theplant.ec.service.products.ISearchFilterGroup>|null} [filterGroups] SearchOptions filterGroups
                     * @property {theplant.ec.service.products.SearchFilterGroupMode|null} [filterGroupMode] SearchOptions filterGroupMode
                     * @property {string|null} [path] SearchOptions path
                     * @property {string|null} [boostName] SearchOptions boostName
                     * @property {theplant.ec.service.products.IInventoryRange|null} [inventoryRange] SearchOptions inventoryRange
                     * @property {number|Long|null} [publishReady] SearchOptions publishReady
                     * @property {string|null} [scheduledAt] SearchOptions scheduledAt
                     * @property {Array.<string>|null} [userSegmentationIdentifiers] SearchOptions userSegmentationIdentifiers
                     */

                    /**
                     * Constructs a new SearchOptions.
                     * @memberof theplant.ec.service.products
                     * @classdesc A SearchOptions is the input filter for a product catalogue page:
                     * 
                     * - Example 1: search all products that size is 25-29, and color is white or black, order by newest, and each page display 20 products.
                     * ```go
                     * so := &SearchOptions{
                     * 
                     * }
                     * ```
                     * 
                     * - サイズ：全て 25-29 30-34 35-39 40-44 46-48
                     * - 並び順：ランキング 新着 お気に入り登録数 価格が安い 価格が高い
                     * - 表示数：30 60 90 すべて
                     * - 在庫： 在庫あり すべて
                     * @implements ISearchOptions
                     * @constructor
                     * @param {theplant.ec.service.products.ISearchOptions=} [properties] Properties to set
                     */
                    function SearchOptions(properties) {
                        this.filterProperties = [];
                        this.sorters = [];
                        this.conditions = [];
                        this.filterGroups = [];
                        this.userSegmentationIdentifiers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchOptions keyword.
                     * @member {string} keyword
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.keyword = "";

                    /**
                     * SearchOptions page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.page = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SearchOptions perPage.
                     * @member {number|Long} perPage
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.perPage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SearchOptions prevPadding.
                     * @member {number|Long} prevPadding
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.prevPadding = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SearchOptions currPadding.
                     * @member {number|Long} currPadding
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.currPadding = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SearchOptions filterProperties.
                     * @member {Array.<theplant.ec.service.products.IFilterProperty>} filterProperties
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.filterProperties = $util.emptyArray;

                    /**
                     * SearchOptions sorters.
                     * @member {Array.<theplant.ec.service.products.ISorter>} sorters
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.sorters = $util.emptyArray;

                    /**
                     * SearchOptions conditions.
                     * @member {Array.<theplant.ec.service.products.FilterCondition>} conditions
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.conditions = $util.emptyArray;

                    /**
                     * SearchOptions filterGroups.
                     * @member {Array.<theplant.ec.service.products.ISearchFilterGroup>} filterGroups
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.filterGroups = $util.emptyArray;

                    /**
                     * SearchOptions filterGroupMode.
                     * @member {theplant.ec.service.products.SearchFilterGroupMode} filterGroupMode
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.filterGroupMode = 0;

                    /**
                     * SearchOptions path.
                     * @member {string} path
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.path = "";

                    /**
                     * SearchOptions boostName.
                     * @member {string} boostName
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.boostName = "";

                    /**
                     * SearchOptions inventoryRange.
                     * @member {theplant.ec.service.products.IInventoryRange|null|undefined} inventoryRange
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.inventoryRange = null;

                    /**
                     * SearchOptions publishReady.
                     * @member {number|Long} publishReady
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.publishReady = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * SearchOptions scheduledAt.
                     * @member {string} scheduledAt
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.scheduledAt = "";

                    /**
                     * SearchOptions userSegmentationIdentifiers.
                     * @member {Array.<string>} userSegmentationIdentifiers
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     */
                    SearchOptions.prototype.userSegmentationIdentifiers = $util.emptyArray;

                    /**
                     * Creates a new SearchOptions instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {theplant.ec.service.products.ISearchOptions=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.SearchOptions} SearchOptions instance
                     */
                    SearchOptions.create = function create(properties) {
                        return new SearchOptions(properties);
                    };

                    /**
                     * Encodes the specified SearchOptions message. Does not implicitly {@link theplant.ec.service.products.SearchOptions.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {theplant.ec.service.products.ISearchOptions} message SearchOptions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchOptions.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.keyword);
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.page);
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            writer.uint32(/* id 5, wireType 0 =*/40).int64(message.perPage);
                        if (message.filterProperties != null && message.filterProperties.length)
                            for (var i = 0; i < message.filterProperties.length; ++i)
                                $root.theplant.ec.service.products.FilterProperty.encode(message.filterProperties[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.sorters != null && message.sorters.length)
                            for (var i = 0; i < message.sorters.length; ++i)
                                $root.theplant.ec.service.products.Sorter.encode(message.sorters[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.conditions != null && message.conditions.length) {
                            writer.uint32(/* id 9, wireType 2 =*/74).fork();
                            for (var i = 0; i < message.conditions.length; ++i)
                                writer.int32(message.conditions[i]);
                            writer.ldelim();
                        }
                        if (message.path != null && message.hasOwnProperty("path"))
                            writer.uint32(/* id 10, wireType 2 =*/82).string(message.path);
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            writer.uint32(/* id 11, wireType 0 =*/88).int64(message.publishReady);
                        if (message.scheduledAt != null && message.hasOwnProperty("scheduledAt"))
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.scheduledAt);
                        if (message.filterGroups != null && message.filterGroups.length)
                            for (var i = 0; i < message.filterGroups.length; ++i)
                                $root.theplant.ec.service.products.SearchFilterGroup.encode(message.filterGroups[i], writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                        if (message.filterGroupMode != null && message.hasOwnProperty("filterGroupMode"))
                            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.filterGroupMode);
                        if (message.boostName != null && message.hasOwnProperty("boostName"))
                            writer.uint32(/* id 15, wireType 2 =*/122).string(message.boostName);
                        if (message.inventoryRange != null && message.hasOwnProperty("inventoryRange"))
                            $root.theplant.ec.service.products.InventoryRange.encode(message.inventoryRange, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                        if (message.userSegmentationIdentifiers != null && message.userSegmentationIdentifiers.length)
                            for (var i = 0; i < message.userSegmentationIdentifiers.length; ++i)
                                writer.uint32(/* id 17, wireType 2 =*/138).string(message.userSegmentationIdentifiers[i]);
                        if (message.prevPadding != null && message.hasOwnProperty("prevPadding"))
                            writer.uint32(/* id 18, wireType 0 =*/144).int64(message.prevPadding);
                        if (message.currPadding != null && message.hasOwnProperty("currPadding"))
                            writer.uint32(/* id 19, wireType 0 =*/152).int64(message.currPadding);
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchOptions message, length delimited. Does not implicitly {@link theplant.ec.service.products.SearchOptions.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {theplant.ec.service.products.ISearchOptions} message SearchOptions message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchOptions.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchOptions message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.SearchOptions} SearchOptions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchOptions.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.SearchOptions();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.keyword = reader.string();
                                break;
                            case 4:
                                message.page = reader.int64();
                                break;
                            case 5:
                                message.perPage = reader.int64();
                                break;
                            case 18:
                                message.prevPadding = reader.int64();
                                break;
                            case 19:
                                message.currPadding = reader.int64();
                                break;
                            case 6:
                                if (!(message.filterProperties && message.filterProperties.length))
                                    message.filterProperties = [];
                                message.filterProperties.push($root.theplant.ec.service.products.FilterProperty.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                if (!(message.sorters && message.sorters.length))
                                    message.sorters = [];
                                message.sorters.push($root.theplant.ec.service.products.Sorter.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                if (!(message.conditions && message.conditions.length))
                                    message.conditions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.conditions.push(reader.int32());
                                } else
                                    message.conditions.push(reader.int32());
                                break;
                            case 13:
                                if (!(message.filterGroups && message.filterGroups.length))
                                    message.filterGroups = [];
                                message.filterGroups.push($root.theplant.ec.service.products.SearchFilterGroup.decode(reader, reader.uint32()));
                                break;
                            case 14:
                                message.filterGroupMode = reader.int32();
                                break;
                            case 10:
                                message.path = reader.string();
                                break;
                            case 15:
                                message.boostName = reader.string();
                                break;
                            case 16:
                                message.inventoryRange = $root.theplant.ec.service.products.InventoryRange.decode(reader, reader.uint32());
                                break;
                            case 11:
                                message.publishReady = reader.int64();
                                break;
                            case 12:
                                message.scheduledAt = reader.string();
                                break;
                            case 17:
                                if (!(message.userSegmentationIdentifiers && message.userSegmentationIdentifiers.length))
                                    message.userSegmentationIdentifiers = [];
                                message.userSegmentationIdentifiers.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchOptions message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.SearchOptions} SearchOptions
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchOptions.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchOptions message.
                     * @function verify
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchOptions.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            if (!$util.isString(message.keyword))
                                return "keyword: string expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (!$util.isInteger(message.perPage) && !(message.perPage && $util.isInteger(message.perPage.low) && $util.isInteger(message.perPage.high)))
                                return "perPage: integer|Long expected";
                        if (message.prevPadding != null && message.hasOwnProperty("prevPadding"))
                            if (!$util.isInteger(message.prevPadding) && !(message.prevPadding && $util.isInteger(message.prevPadding.low) && $util.isInteger(message.prevPadding.high)))
                                return "prevPadding: integer|Long expected";
                        if (message.currPadding != null && message.hasOwnProperty("currPadding"))
                            if (!$util.isInteger(message.currPadding) && !(message.currPadding && $util.isInteger(message.currPadding.low) && $util.isInteger(message.currPadding.high)))
                                return "currPadding: integer|Long expected";
                        if (message.filterProperties != null && message.hasOwnProperty("filterProperties")) {
                            if (!Array.isArray(message.filterProperties))
                                return "filterProperties: array expected";
                            for (var i = 0; i < message.filterProperties.length; ++i) {
                                var error = $root.theplant.ec.service.products.FilterProperty.verify(message.filterProperties[i]);
                                if (error)
                                    return "filterProperties." + error;
                            }
                        }
                        if (message.sorters != null && message.hasOwnProperty("sorters")) {
                            if (!Array.isArray(message.sorters))
                                return "sorters: array expected";
                            for (var i = 0; i < message.sorters.length; ++i) {
                                var error = $root.theplant.ec.service.products.Sorter.verify(message.sorters[i]);
                                if (error)
                                    return "sorters." + error;
                            }
                        }
                        if (message.conditions != null && message.hasOwnProperty("conditions")) {
                            if (!Array.isArray(message.conditions))
                                return "conditions: array expected";
                            for (var i = 0; i < message.conditions.length; ++i)
                                switch (message.conditions[i]) {
                                default:
                                    return "conditions: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                    break;
                                }
                        }
                        if (message.filterGroups != null && message.hasOwnProperty("filterGroups")) {
                            if (!Array.isArray(message.filterGroups))
                                return "filterGroups: array expected";
                            for (var i = 0; i < message.filterGroups.length; ++i) {
                                var error = $root.theplant.ec.service.products.SearchFilterGroup.verify(message.filterGroups[i]);
                                if (error)
                                    return "filterGroups." + error;
                            }
                        }
                        if (message.filterGroupMode != null && message.hasOwnProperty("filterGroupMode"))
                            switch (message.filterGroupMode) {
                            default:
                                return "filterGroupMode: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.path != null && message.hasOwnProperty("path"))
                            if (!$util.isString(message.path))
                                return "path: string expected";
                        if (message.boostName != null && message.hasOwnProperty("boostName"))
                            if (!$util.isString(message.boostName))
                                return "boostName: string expected";
                        if (message.inventoryRange != null && message.hasOwnProperty("inventoryRange")) {
                            var error = $root.theplant.ec.service.products.InventoryRange.verify(message.inventoryRange);
                            if (error)
                                return "inventoryRange." + error;
                        }
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            if (!$util.isInteger(message.publishReady) && !(message.publishReady && $util.isInteger(message.publishReady.low) && $util.isInteger(message.publishReady.high)))
                                return "publishReady: integer|Long expected";
                        if (message.scheduledAt != null && message.hasOwnProperty("scheduledAt"))
                            if (!$util.isString(message.scheduledAt))
                                return "scheduledAt: string expected";
                        if (message.userSegmentationIdentifiers != null && message.hasOwnProperty("userSegmentationIdentifiers")) {
                            if (!Array.isArray(message.userSegmentationIdentifiers))
                                return "userSegmentationIdentifiers: array expected";
                            for (var i = 0; i < message.userSegmentationIdentifiers.length; ++i)
                                if (!$util.isString(message.userSegmentationIdentifiers[i]))
                                    return "userSegmentationIdentifiers: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a SearchOptions message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.SearchOptions} SearchOptions
                     */
                    SearchOptions.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.SearchOptions)
                            return object;
                        var message = new $root.theplant.ec.service.products.SearchOptions();
                        if (object.keyword != null)
                            message.keyword = String(object.keyword);
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = false;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber();
                        if (object.perPage != null)
                            if ($util.Long)
                                (message.perPage = $util.Long.fromValue(object.perPage)).unsigned = false;
                            else if (typeof object.perPage === "string")
                                message.perPage = parseInt(object.perPage, 10);
                            else if (typeof object.perPage === "number")
                                message.perPage = object.perPage;
                            else if (typeof object.perPage === "object")
                                message.perPage = new $util.LongBits(object.perPage.low >>> 0, object.perPage.high >>> 0).toNumber();
                        if (object.prevPadding != null)
                            if ($util.Long)
                                (message.prevPadding = $util.Long.fromValue(object.prevPadding)).unsigned = false;
                            else if (typeof object.prevPadding === "string")
                                message.prevPadding = parseInt(object.prevPadding, 10);
                            else if (typeof object.prevPadding === "number")
                                message.prevPadding = object.prevPadding;
                            else if (typeof object.prevPadding === "object")
                                message.prevPadding = new $util.LongBits(object.prevPadding.low >>> 0, object.prevPadding.high >>> 0).toNumber();
                        if (object.currPadding != null)
                            if ($util.Long)
                                (message.currPadding = $util.Long.fromValue(object.currPadding)).unsigned = false;
                            else if (typeof object.currPadding === "string")
                                message.currPadding = parseInt(object.currPadding, 10);
                            else if (typeof object.currPadding === "number")
                                message.currPadding = object.currPadding;
                            else if (typeof object.currPadding === "object")
                                message.currPadding = new $util.LongBits(object.currPadding.low >>> 0, object.currPadding.high >>> 0).toNumber();
                        if (object.filterProperties) {
                            if (!Array.isArray(object.filterProperties))
                                throw TypeError(".theplant.ec.service.products.SearchOptions.filterProperties: array expected");
                            message.filterProperties = [];
                            for (var i = 0; i < object.filterProperties.length; ++i) {
                                if (typeof object.filterProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.SearchOptions.filterProperties: object expected");
                                message.filterProperties[i] = $root.theplant.ec.service.products.FilterProperty.fromObject(object.filterProperties[i]);
                            }
                        }
                        if (object.sorters) {
                            if (!Array.isArray(object.sorters))
                                throw TypeError(".theplant.ec.service.products.SearchOptions.sorters: array expected");
                            message.sorters = [];
                            for (var i = 0; i < object.sorters.length; ++i) {
                                if (typeof object.sorters[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.SearchOptions.sorters: object expected");
                                message.sorters[i] = $root.theplant.ec.service.products.Sorter.fromObject(object.sorters[i]);
                            }
                        }
                        if (object.conditions) {
                            if (!Array.isArray(object.conditions))
                                throw TypeError(".theplant.ec.service.products.SearchOptions.conditions: array expected");
                            message.conditions = [];
                            for (var i = 0; i < object.conditions.length; ++i)
                                switch (object.conditions[i]) {
                                default:
                                case "ON_SALE":
                                case 0:
                                    message.conditions[i] = 0;
                                    break;
                                case "MIX_SALE":
                                case 1:
                                    message.conditions[i] = 1;
                                    break;
                                case "EXCLUDE_ON_SALE":
                                case 2:
                                    message.conditions[i] = 2;
                                    break;
                                case "IN_INVENTORY":
                                case 3:
                                    message.conditions[i] = 3;
                                    break;
                                case "STORE_IN_INVENTORY":
                                case 4:
                                    message.conditions[i] = 4;
                                    break;
                                case "OUT_OF_INVENTORY":
                                case 5:
                                    message.conditions[i] = 5;
                                    break;
                                case "SCS_OR_STORE_IN_INVENTORY":
                                case 6:
                                    message.conditions[i] = 6;
                                    break;
                                }
                        }
                        if (object.filterGroups) {
                            if (!Array.isArray(object.filterGroups))
                                throw TypeError(".theplant.ec.service.products.SearchOptions.filterGroups: array expected");
                            message.filterGroups = [];
                            for (var i = 0; i < object.filterGroups.length; ++i) {
                                if (typeof object.filterGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.SearchOptions.filterGroups: object expected");
                                message.filterGroups[i] = $root.theplant.ec.service.products.SearchFilterGroup.fromObject(object.filterGroups[i]);
                            }
                        }
                        switch (object.filterGroupMode) {
                        case "COMPOSITE":
                        case 0:
                            message.filterGroupMode = 0;
                            break;
                        case "STRICT":
                        case 1:
                            message.filterGroupMode = 1;
                            break;
                        }
                        if (object.path != null)
                            message.path = String(object.path);
                        if (object.boostName != null)
                            message.boostName = String(object.boostName);
                        if (object.inventoryRange != null) {
                            if (typeof object.inventoryRange !== "object")
                                throw TypeError(".theplant.ec.service.products.SearchOptions.inventoryRange: object expected");
                            message.inventoryRange = $root.theplant.ec.service.products.InventoryRange.fromObject(object.inventoryRange);
                        }
                        if (object.publishReady != null)
                            if ($util.Long)
                                (message.publishReady = $util.Long.fromValue(object.publishReady)).unsigned = false;
                            else if (typeof object.publishReady === "string")
                                message.publishReady = parseInt(object.publishReady, 10);
                            else if (typeof object.publishReady === "number")
                                message.publishReady = object.publishReady;
                            else if (typeof object.publishReady === "object")
                                message.publishReady = new $util.LongBits(object.publishReady.low >>> 0, object.publishReady.high >>> 0).toNumber();
                        if (object.scheduledAt != null)
                            message.scheduledAt = String(object.scheduledAt);
                        if (object.userSegmentationIdentifiers) {
                            if (!Array.isArray(object.userSegmentationIdentifiers))
                                throw TypeError(".theplant.ec.service.products.SearchOptions.userSegmentationIdentifiers: array expected");
                            message.userSegmentationIdentifiers = [];
                            for (var i = 0; i < object.userSegmentationIdentifiers.length; ++i)
                                message.userSegmentationIdentifiers[i] = String(object.userSegmentationIdentifiers[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchOptions message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @static
                     * @param {theplant.ec.service.products.SearchOptions} message SearchOptions
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchOptions.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.filterProperties = [];
                            object.sorters = [];
                            object.conditions = [];
                            object.filterGroups = [];
                            object.userSegmentationIdentifiers = [];
                        }
                        if (options.defaults) {
                            object.keyword = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.perPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.perPage = options.longs === String ? "0" : 0;
                            object.path = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.publishReady = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.publishReady = options.longs === String ? "0" : 0;
                            object.scheduledAt = "";
                            object.filterGroupMode = options.enums === String ? "COMPOSITE" : 0;
                            object.boostName = "";
                            object.inventoryRange = null;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.prevPadding = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.prevPadding = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.currPadding = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.currPadding = options.longs === String ? "0" : 0;
                        }
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            object.keyword = message.keyword;
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber() : message.page;
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (typeof message.perPage === "number")
                                object.perPage = options.longs === String ? String(message.perPage) : message.perPage;
                            else
                                object.perPage = options.longs === String ? $util.Long.prototype.toString.call(message.perPage) : options.longs === Number ? new $util.LongBits(message.perPage.low >>> 0, message.perPage.high >>> 0).toNumber() : message.perPage;
                        if (message.filterProperties && message.filterProperties.length) {
                            object.filterProperties = [];
                            for (var j = 0; j < message.filterProperties.length; ++j)
                                object.filterProperties[j] = $root.theplant.ec.service.products.FilterProperty.toObject(message.filterProperties[j], options);
                        }
                        if (message.sorters && message.sorters.length) {
                            object.sorters = [];
                            for (var j = 0; j < message.sorters.length; ++j)
                                object.sorters[j] = $root.theplant.ec.service.products.Sorter.toObject(message.sorters[j], options);
                        }
                        if (message.conditions && message.conditions.length) {
                            object.conditions = [];
                            for (var j = 0; j < message.conditions.length; ++j)
                                object.conditions[j] = options.enums === String ? $root.theplant.ec.service.products.FilterCondition[message.conditions[j]] : message.conditions[j];
                        }
                        if (message.path != null && message.hasOwnProperty("path"))
                            object.path = message.path;
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            if (typeof message.publishReady === "number")
                                object.publishReady = options.longs === String ? String(message.publishReady) : message.publishReady;
                            else
                                object.publishReady = options.longs === String ? $util.Long.prototype.toString.call(message.publishReady) : options.longs === Number ? new $util.LongBits(message.publishReady.low >>> 0, message.publishReady.high >>> 0).toNumber() : message.publishReady;
                        if (message.scheduledAt != null && message.hasOwnProperty("scheduledAt"))
                            object.scheduledAt = message.scheduledAt;
                        if (message.filterGroups && message.filterGroups.length) {
                            object.filterGroups = [];
                            for (var j = 0; j < message.filterGroups.length; ++j)
                                object.filterGroups[j] = $root.theplant.ec.service.products.SearchFilterGroup.toObject(message.filterGroups[j], options);
                        }
                        if (message.filterGroupMode != null && message.hasOwnProperty("filterGroupMode"))
                            object.filterGroupMode = options.enums === String ? $root.theplant.ec.service.products.SearchFilterGroupMode[message.filterGroupMode] : message.filterGroupMode;
                        if (message.boostName != null && message.hasOwnProperty("boostName"))
                            object.boostName = message.boostName;
                        if (message.inventoryRange != null && message.hasOwnProperty("inventoryRange"))
                            object.inventoryRange = $root.theplant.ec.service.products.InventoryRange.toObject(message.inventoryRange, options);
                        if (message.userSegmentationIdentifiers && message.userSegmentationIdentifiers.length) {
                            object.userSegmentationIdentifiers = [];
                            for (var j = 0; j < message.userSegmentationIdentifiers.length; ++j)
                                object.userSegmentationIdentifiers[j] = message.userSegmentationIdentifiers[j];
                        }
                        if (message.prevPadding != null && message.hasOwnProperty("prevPadding"))
                            if (typeof message.prevPadding === "number")
                                object.prevPadding = options.longs === String ? String(message.prevPadding) : message.prevPadding;
                            else
                                object.prevPadding = options.longs === String ? $util.Long.prototype.toString.call(message.prevPadding) : options.longs === Number ? new $util.LongBits(message.prevPadding.low >>> 0, message.prevPadding.high >>> 0).toNumber() : message.prevPadding;
                        if (message.currPadding != null && message.hasOwnProperty("currPadding"))
                            if (typeof message.currPadding === "number")
                                object.currPadding = options.longs === String ? String(message.currPadding) : message.currPadding;
                            else
                                object.currPadding = options.longs === String ? $util.Long.prototype.toString.call(message.currPadding) : options.longs === Number ? new $util.LongBits(message.currPadding.low >>> 0, message.currPadding.high >>> 0).toNumber() : message.currPadding;
                        return object;
                    };

                    /**
                     * Converts this SearchOptions to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.SearchOptions
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchOptions.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchOptions;
                })();

                products.InventoryRange = (function() {

                    /**
                     * Properties of an InventoryRange.
                     * @memberof theplant.ec.service.products
                     * @interface IInventoryRange
                     * @property {string|null} [start] InventoryRange start
                     * @property {string|null} [end] InventoryRange end
                     * @property {boolean|null} [includeLower] InventoryRange includeLower
                     * @property {boolean|null} [includeUpper] InventoryRange includeUpper
                     */

                    /**
                     * Constructs a new InventoryRange.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents an InventoryRange.
                     * @implements IInventoryRange
                     * @constructor
                     * @param {theplant.ec.service.products.IInventoryRange=} [properties] Properties to set
                     */
                    function InventoryRange(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * InventoryRange start.
                     * @member {string} start
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @instance
                     */
                    InventoryRange.prototype.start = "";

                    /**
                     * InventoryRange end.
                     * @member {string} end
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @instance
                     */
                    InventoryRange.prototype.end = "";

                    /**
                     * InventoryRange includeLower.
                     * @member {boolean} includeLower
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @instance
                     */
                    InventoryRange.prototype.includeLower = false;

                    /**
                     * InventoryRange includeUpper.
                     * @member {boolean} includeUpper
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @instance
                     */
                    InventoryRange.prototype.includeUpper = false;

                    /**
                     * Creates a new InventoryRange instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {theplant.ec.service.products.IInventoryRange=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.InventoryRange} InventoryRange instance
                     */
                    InventoryRange.create = function create(properties) {
                        return new InventoryRange(properties);
                    };

                    /**
                     * Encodes the specified InventoryRange message. Does not implicitly {@link theplant.ec.service.products.InventoryRange.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {theplant.ec.service.products.IInventoryRange} message InventoryRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InventoryRange.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.start != null && message.hasOwnProperty("start"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.start);
                        if (message.end != null && message.hasOwnProperty("end"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.end);
                        if (message.includeLower != null && message.hasOwnProperty("includeLower"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeLower);
                        if (message.includeUpper != null && message.hasOwnProperty("includeUpper"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.includeUpper);
                        return writer;
                    };

                    /**
                     * Encodes the specified InventoryRange message, length delimited. Does not implicitly {@link theplant.ec.service.products.InventoryRange.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {theplant.ec.service.products.IInventoryRange} message InventoryRange message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    InventoryRange.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an InventoryRange message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.InventoryRange} InventoryRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InventoryRange.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.InventoryRange();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.start = reader.string();
                                break;
                            case 2:
                                message.end = reader.string();
                                break;
                            case 3:
                                message.includeLower = reader.bool();
                                break;
                            case 4:
                                message.includeUpper = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an InventoryRange message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.InventoryRange} InventoryRange
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    InventoryRange.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an InventoryRange message.
                     * @function verify
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    InventoryRange.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.start != null && message.hasOwnProperty("start"))
                            if (!$util.isString(message.start))
                                return "start: string expected";
                        if (message.end != null && message.hasOwnProperty("end"))
                            if (!$util.isString(message.end))
                                return "end: string expected";
                        if (message.includeLower != null && message.hasOwnProperty("includeLower"))
                            if (typeof message.includeLower !== "boolean")
                                return "includeLower: boolean expected";
                        if (message.includeUpper != null && message.hasOwnProperty("includeUpper"))
                            if (typeof message.includeUpper !== "boolean")
                                return "includeUpper: boolean expected";
                        return null;
                    };

                    /**
                     * Creates an InventoryRange message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.InventoryRange} InventoryRange
                     */
                    InventoryRange.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.InventoryRange)
                            return object;
                        var message = new $root.theplant.ec.service.products.InventoryRange();
                        if (object.start != null)
                            message.start = String(object.start);
                        if (object.end != null)
                            message.end = String(object.end);
                        if (object.includeLower != null)
                            message.includeLower = Boolean(object.includeLower);
                        if (object.includeUpper != null)
                            message.includeUpper = Boolean(object.includeUpper);
                        return message;
                    };

                    /**
                     * Creates a plain object from an InventoryRange message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @static
                     * @param {theplant.ec.service.products.InventoryRange} message InventoryRange
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    InventoryRange.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.start = "";
                            object.end = "";
                            object.includeLower = false;
                            object.includeUpper = false;
                        }
                        if (message.start != null && message.hasOwnProperty("start"))
                            object.start = message.start;
                        if (message.end != null && message.hasOwnProperty("end"))
                            object.end = message.end;
                        if (message.includeLower != null && message.hasOwnProperty("includeLower"))
                            object.includeLower = message.includeLower;
                        if (message.includeUpper != null && message.hasOwnProperty("includeUpper"))
                            object.includeUpper = message.includeUpper;
                        return object;
                    };

                    /**
                     * Converts this InventoryRange to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.InventoryRange
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    InventoryRange.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return InventoryRange;
                })();

                products.AggregateCondition = (function() {

                    /**
                     * Properties of an AggregateCondition.
                     * @memberof theplant.ec.service.products
                     * @interface IAggregateCondition
                     * @property {string|null} [keyword] AggregateCondition keyword
                     * @property {Array.<theplant.ec.service.products.IFilterProperty>|null} [filterProperties] AggregateCondition filterProperties
                     * @property {Array.<theplant.ec.service.products.FilterCondition>|null} [conditions] AggregateCondition conditions
                     * @property {number|Long|null} [publishReady] AggregateCondition publishReady
                     * @property {string|null} [scheduledAt] AggregateCondition scheduledAt
                     */

                    /**
                     * Constructs a new AggregateCondition.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents an AggregateCondition.
                     * @implements IAggregateCondition
                     * @constructor
                     * @param {theplant.ec.service.products.IAggregateCondition=} [properties] Properties to set
                     */
                    function AggregateCondition(properties) {
                        this.filterProperties = [];
                        this.conditions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AggregateCondition keyword.
                     * @member {string} keyword
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @instance
                     */
                    AggregateCondition.prototype.keyword = "";

                    /**
                     * AggregateCondition filterProperties.
                     * @member {Array.<theplant.ec.service.products.IFilterProperty>} filterProperties
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @instance
                     */
                    AggregateCondition.prototype.filterProperties = $util.emptyArray;

                    /**
                     * AggregateCondition conditions.
                     * @member {Array.<theplant.ec.service.products.FilterCondition>} conditions
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @instance
                     */
                    AggregateCondition.prototype.conditions = $util.emptyArray;

                    /**
                     * AggregateCondition publishReady.
                     * @member {number|Long} publishReady
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @instance
                     */
                    AggregateCondition.prototype.publishReady = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * AggregateCondition scheduledAt.
                     * @member {string} scheduledAt
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @instance
                     */
                    AggregateCondition.prototype.scheduledAt = "";

                    /**
                     * Creates a new AggregateCondition instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {theplant.ec.service.products.IAggregateCondition=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.AggregateCondition} AggregateCondition instance
                     */
                    AggregateCondition.create = function create(properties) {
                        return new AggregateCondition(properties);
                    };

                    /**
                     * Encodes the specified AggregateCondition message. Does not implicitly {@link theplant.ec.service.products.AggregateCondition.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {theplant.ec.service.products.IAggregateCondition} message AggregateCondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateCondition.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.keyword);
                        if (message.filterProperties != null && message.filterProperties.length)
                            for (var i = 0; i < message.filterProperties.length; ++i)
                                $root.theplant.ec.service.products.FilterProperty.encode(message.filterProperties[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.conditions != null && message.conditions.length) {
                            writer.uint32(/* id 3, wireType 2 =*/26).fork();
                            for (var i = 0; i < message.conditions.length; ++i)
                                writer.int32(message.conditions[i]);
                            writer.ldelim();
                        }
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.publishReady);
                        if (message.scheduledAt != null && message.hasOwnProperty("scheduledAt"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.scheduledAt);
                        return writer;
                    };

                    /**
                     * Encodes the specified AggregateCondition message, length delimited. Does not implicitly {@link theplant.ec.service.products.AggregateCondition.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {theplant.ec.service.products.IAggregateCondition} message AggregateCondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AggregateCondition.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AggregateCondition message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.AggregateCondition} AggregateCondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateCondition.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.AggregateCondition();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.keyword = reader.string();
                                break;
                            case 2:
                                if (!(message.filterProperties && message.filterProperties.length))
                                    message.filterProperties = [];
                                message.filterProperties.push($root.theplant.ec.service.products.FilterProperty.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                if (!(message.conditions && message.conditions.length))
                                    message.conditions = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.conditions.push(reader.int32());
                                } else
                                    message.conditions.push(reader.int32());
                                break;
                            case 4:
                                message.publishReady = reader.int64();
                                break;
                            case 5:
                                message.scheduledAt = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AggregateCondition message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.AggregateCondition} AggregateCondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AggregateCondition.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AggregateCondition message.
                     * @function verify
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AggregateCondition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            if (!$util.isString(message.keyword))
                                return "keyword: string expected";
                        if (message.filterProperties != null && message.hasOwnProperty("filterProperties")) {
                            if (!Array.isArray(message.filterProperties))
                                return "filterProperties: array expected";
                            for (var i = 0; i < message.filterProperties.length; ++i) {
                                var error = $root.theplant.ec.service.products.FilterProperty.verify(message.filterProperties[i]);
                                if (error)
                                    return "filterProperties." + error;
                            }
                        }
                        if (message.conditions != null && message.hasOwnProperty("conditions")) {
                            if (!Array.isArray(message.conditions))
                                return "conditions: array expected";
                            for (var i = 0; i < message.conditions.length; ++i)
                                switch (message.conditions[i]) {
                                default:
                                    return "conditions: enum value[] expected";
                                case 0:
                                case 1:
                                case 2:
                                case 3:
                                case 4:
                                case 5:
                                case 6:
                                    break;
                                }
                        }
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            if (!$util.isInteger(message.publishReady) && !(message.publishReady && $util.isInteger(message.publishReady.low) && $util.isInteger(message.publishReady.high)))
                                return "publishReady: integer|Long expected";
                        if (message.scheduledAt != null && message.hasOwnProperty("scheduledAt"))
                            if (!$util.isString(message.scheduledAt))
                                return "scheduledAt: string expected";
                        return null;
                    };

                    /**
                     * Creates an AggregateCondition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.AggregateCondition} AggregateCondition
                     */
                    AggregateCondition.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.AggregateCondition)
                            return object;
                        var message = new $root.theplant.ec.service.products.AggregateCondition();
                        if (object.keyword != null)
                            message.keyword = String(object.keyword);
                        if (object.filterProperties) {
                            if (!Array.isArray(object.filterProperties))
                                throw TypeError(".theplant.ec.service.products.AggregateCondition.filterProperties: array expected");
                            message.filterProperties = [];
                            for (var i = 0; i < object.filterProperties.length; ++i) {
                                if (typeof object.filterProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.AggregateCondition.filterProperties: object expected");
                                message.filterProperties[i] = $root.theplant.ec.service.products.FilterProperty.fromObject(object.filterProperties[i]);
                            }
                        }
                        if (object.conditions) {
                            if (!Array.isArray(object.conditions))
                                throw TypeError(".theplant.ec.service.products.AggregateCondition.conditions: array expected");
                            message.conditions = [];
                            for (var i = 0; i < object.conditions.length; ++i)
                                switch (object.conditions[i]) {
                                default:
                                case "ON_SALE":
                                case 0:
                                    message.conditions[i] = 0;
                                    break;
                                case "MIX_SALE":
                                case 1:
                                    message.conditions[i] = 1;
                                    break;
                                case "EXCLUDE_ON_SALE":
                                case 2:
                                    message.conditions[i] = 2;
                                    break;
                                case "IN_INVENTORY":
                                case 3:
                                    message.conditions[i] = 3;
                                    break;
                                case "STORE_IN_INVENTORY":
                                case 4:
                                    message.conditions[i] = 4;
                                    break;
                                case "OUT_OF_INVENTORY":
                                case 5:
                                    message.conditions[i] = 5;
                                    break;
                                case "SCS_OR_STORE_IN_INVENTORY":
                                case 6:
                                    message.conditions[i] = 6;
                                    break;
                                }
                        }
                        if (object.publishReady != null)
                            if ($util.Long)
                                (message.publishReady = $util.Long.fromValue(object.publishReady)).unsigned = false;
                            else if (typeof object.publishReady === "string")
                                message.publishReady = parseInt(object.publishReady, 10);
                            else if (typeof object.publishReady === "number")
                                message.publishReady = object.publishReady;
                            else if (typeof object.publishReady === "object")
                                message.publishReady = new $util.LongBits(object.publishReady.low >>> 0, object.publishReady.high >>> 0).toNumber();
                        if (object.scheduledAt != null)
                            message.scheduledAt = String(object.scheduledAt);
                        return message;
                    };

                    /**
                     * Creates a plain object from an AggregateCondition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @static
                     * @param {theplant.ec.service.products.AggregateCondition} message AggregateCondition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AggregateCondition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.filterProperties = [];
                            object.conditions = [];
                        }
                        if (options.defaults) {
                            object.keyword = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.publishReady = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.publishReady = options.longs === String ? "0" : 0;
                            object.scheduledAt = "";
                        }
                        if (message.keyword != null && message.hasOwnProperty("keyword"))
                            object.keyword = message.keyword;
                        if (message.filterProperties && message.filterProperties.length) {
                            object.filterProperties = [];
                            for (var j = 0; j < message.filterProperties.length; ++j)
                                object.filterProperties[j] = $root.theplant.ec.service.products.FilterProperty.toObject(message.filterProperties[j], options);
                        }
                        if (message.conditions && message.conditions.length) {
                            object.conditions = [];
                            for (var j = 0; j < message.conditions.length; ++j)
                                object.conditions[j] = options.enums === String ? $root.theplant.ec.service.products.FilterCondition[message.conditions[j]] : message.conditions[j];
                        }
                        if (message.publishReady != null && message.hasOwnProperty("publishReady"))
                            if (typeof message.publishReady === "number")
                                object.publishReady = options.longs === String ? String(message.publishReady) : message.publishReady;
                            else
                                object.publishReady = options.longs === String ? $util.Long.prototype.toString.call(message.publishReady) : options.longs === Number ? new $util.LongBits(message.publishReady.low >>> 0, message.publishReady.high >>> 0).toNumber() : message.publishReady;
                        if (message.scheduledAt != null && message.hasOwnProperty("scheduledAt"))
                            object.scheduledAt = message.scheduledAt;
                        return object;
                    };

                    /**
                     * Converts this AggregateCondition to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.AggregateCondition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AggregateCondition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AggregateCondition;
                })();

                products.FilterProperty = (function() {

                    /**
                     * Properties of a FilterProperty.
                     * @memberof theplant.ec.service.products
                     * @interface IFilterProperty
                     * @property {string|null} [field] FilterProperty field
                     * @property {theplant.ec.service.products.FilterGroupType|null} [groupType] FilterProperty groupType
                     * @property {Array.<string>|null} [inValues] FilterProperty inValues
                     * @property {boolean|null} [isNegative] FilterProperty isNegative
                     */

                    /**
                     * Constructs a new FilterProperty.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a FilterProperty.
                     * @implements IFilterProperty
                     * @constructor
                     * @param {theplant.ec.service.products.IFilterProperty=} [properties] Properties to set
                     */
                    function FilterProperty(properties) {
                        this.inValues = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterProperty field.
                     * @member {string} field
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @instance
                     */
                    FilterProperty.prototype.field = "";

                    /**
                     * FilterProperty groupType.
                     * @member {theplant.ec.service.products.FilterGroupType} groupType
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @instance
                     */
                    FilterProperty.prototype.groupType = 0;

                    /**
                     * FilterProperty inValues.
                     * @member {Array.<string>} inValues
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @instance
                     */
                    FilterProperty.prototype.inValues = $util.emptyArray;

                    /**
                     * FilterProperty isNegative.
                     * @member {boolean} isNegative
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @instance
                     */
                    FilterProperty.prototype.isNegative = false;

                    /**
                     * Creates a new FilterProperty instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {theplant.ec.service.products.IFilterProperty=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.FilterProperty} FilterProperty instance
                     */
                    FilterProperty.create = function create(properties) {
                        return new FilterProperty(properties);
                    };

                    /**
                     * Encodes the specified FilterProperty message. Does not implicitly {@link theplant.ec.service.products.FilterProperty.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {theplant.ec.service.products.IFilterProperty} message FilterProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterProperty.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.groupType);
                        if (message.inValues != null && message.inValues.length)
                            for (var i = 0; i < message.inValues.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.inValues[i]);
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isNegative);
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterProperty message, length delimited. Does not implicitly {@link theplant.ec.service.products.FilterProperty.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {theplant.ec.service.products.IFilterProperty} message FilterProperty message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterProperty.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterProperty message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.FilterProperty} FilterProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterProperty.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.FilterProperty();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = reader.string();
                                break;
                            case 2:
                                message.groupType = reader.int32();
                                break;
                            case 3:
                                if (!(message.inValues && message.inValues.length))
                                    message.inValues = [];
                                message.inValues.push(reader.string());
                                break;
                            case 4:
                                message.isNegative = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterProperty message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.FilterProperty} FilterProperty
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterProperty.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterProperty message.
                     * @function verify
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterProperty.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            switch (message.groupType) {
                            default:
                                return "groupType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.inValues != null && message.hasOwnProperty("inValues")) {
                            if (!Array.isArray(message.inValues))
                                return "inValues: array expected";
                            for (var i = 0; i < message.inValues.length; ++i)
                                if (!$util.isString(message.inValues[i]))
                                    return "inValues: string[] expected";
                        }
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            if (typeof message.isNegative !== "boolean")
                                return "isNegative: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a FilterProperty message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.FilterProperty} FilterProperty
                     */
                    FilterProperty.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.FilterProperty)
                            return object;
                        var message = new $root.theplant.ec.service.products.FilterProperty();
                        if (object.field != null)
                            message.field = String(object.field);
                        switch (object.groupType) {
                        case "PRODUCT":
                        case 0:
                            message.groupType = 0;
                            break;
                        case "VARIANT":
                        case 1:
                            message.groupType = 1;
                            break;
                        }
                        if (object.inValues) {
                            if (!Array.isArray(object.inValues))
                                throw TypeError(".theplant.ec.service.products.FilterProperty.inValues: array expected");
                            message.inValues = [];
                            for (var i = 0; i < object.inValues.length; ++i)
                                message.inValues[i] = String(object.inValues[i]);
                        }
                        if (object.isNegative != null)
                            message.isNegative = Boolean(object.isNegative);
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterProperty message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @static
                     * @param {theplant.ec.service.products.FilterProperty} message FilterProperty
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterProperty.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.inValues = [];
                        if (options.defaults) {
                            object.field = "";
                            object.groupType = options.enums === String ? "PRODUCT" : 0;
                            object.isNegative = false;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            object.groupType = options.enums === String ? $root.theplant.ec.service.products.FilterGroupType[message.groupType] : message.groupType;
                        if (message.inValues && message.inValues.length) {
                            object.inValues = [];
                            for (var j = 0; j < message.inValues.length; ++j)
                                object.inValues[j] = message.inValues[j];
                        }
                        if (message.isNegative != null && message.hasOwnProperty("isNegative"))
                            object.isNegative = message.isNegative;
                        return object;
                    };

                    /**
                     * Converts this FilterProperty to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.FilterProperty
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterProperty.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterProperty;
                })();

                products.Sorter = (function() {

                    /**
                     * Properties of a Sorter.
                     * @memberof theplant.ec.service.products
                     * @interface ISorter
                     * @property {string|null} [field] Sorter field
                     * @property {theplant.ec.service.products.SortByField|null} [sortField] Sorter sortField
                     * @property {boolean|null} [order] Sorter order
                     * @property {theplant.ec.service.products.FilterGroupType|null} [groupType] Sorter groupType
                     */

                    /**
                     * Constructs a new Sorter.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a Sorter.
                     * @implements ISorter
                     * @constructor
                     * @param {theplant.ec.service.products.ISorter=} [properties] Properties to set
                     */
                    function Sorter(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Sorter field.
                     * @member {string} field
                     * @memberof theplant.ec.service.products.Sorter
                     * @instance
                     */
                    Sorter.prototype.field = "";

                    /**
                     * Sorter sortField.
                     * @member {theplant.ec.service.products.SortByField} sortField
                     * @memberof theplant.ec.service.products.Sorter
                     * @instance
                     */
                    Sorter.prototype.sortField = 0;

                    /**
                     * Sorter order.
                     * @member {boolean} order
                     * @memberof theplant.ec.service.products.Sorter
                     * @instance
                     */
                    Sorter.prototype.order = false;

                    /**
                     * Sorter groupType.
                     * @member {theplant.ec.service.products.FilterGroupType} groupType
                     * @memberof theplant.ec.service.products.Sorter
                     * @instance
                     */
                    Sorter.prototype.groupType = 0;

                    /**
                     * Creates a new Sorter instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {theplant.ec.service.products.ISorter=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.Sorter} Sorter instance
                     */
                    Sorter.create = function create(properties) {
                        return new Sorter(properties);
                    };

                    /**
                     * Encodes the specified Sorter message. Does not implicitly {@link theplant.ec.service.products.Sorter.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {theplant.ec.service.products.ISorter} message Sorter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Sorter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                        if (message.sortField != null && message.hasOwnProperty("sortField"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.sortField);
                        if (message.order != null && message.hasOwnProperty("order"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.order);
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.groupType);
                        return writer;
                    };

                    /**
                     * Encodes the specified Sorter message, length delimited. Does not implicitly {@link theplant.ec.service.products.Sorter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {theplant.ec.service.products.ISorter} message Sorter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Sorter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Sorter message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.Sorter} Sorter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Sorter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.Sorter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = reader.string();
                                break;
                            case 2:
                                message.sortField = reader.int32();
                                break;
                            case 3:
                                message.order = reader.bool();
                                break;
                            case 4:
                                message.groupType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Sorter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.Sorter} Sorter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Sorter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Sorter message.
                     * @function verify
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Sorter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.sortField != null && message.hasOwnProperty("sortField"))
                            switch (message.sortField) {
                            default:
                                return "sortField: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                                break;
                            }
                        if (message.order != null && message.hasOwnProperty("order"))
                            if (typeof message.order !== "boolean")
                                return "order: boolean expected";
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            switch (message.groupType) {
                            default:
                                return "groupType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a Sorter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.Sorter} Sorter
                     */
                    Sorter.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.Sorter)
                            return object;
                        var message = new $root.theplant.ec.service.products.Sorter();
                        if (object.field != null)
                            message.field = String(object.field);
                        switch (object.sortField) {
                        case "PRICE":
                        case 0:
                            message.sortField = 0;
                            break;
                        case "SELLING_PRICE":
                        case 1:
                            message.sortField = 1;
                            break;
                        case "SALES":
                        case 2:
                            message.sortField = 2;
                            break;
                        case "RELEASE_DATE":
                        case 3:
                            message.sortField = 3;
                            break;
                        case "REVIEWS":
                        case 4:
                            message.sortField = 4;
                            break;
                        case "NAME":
                        case 5:
                            message.sortField = 5;
                            break;
                        case "DISCOUNT_RATE":
                        case 6:
                            message.sortField = 6;
                            break;
                        }
                        if (object.order != null)
                            message.order = Boolean(object.order);
                        switch (object.groupType) {
                        case "PRODUCT":
                        case 0:
                            message.groupType = 0;
                            break;
                        case "VARIANT":
                        case 1:
                            message.groupType = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Sorter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.Sorter
                     * @static
                     * @param {theplant.ec.service.products.Sorter} message Sorter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Sorter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.field = "";
                            object.sortField = options.enums === String ? "PRICE" : 0;
                            object.order = false;
                            object.groupType = options.enums === String ? "PRODUCT" : 0;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.sortField != null && message.hasOwnProperty("sortField"))
                            object.sortField = options.enums === String ? $root.theplant.ec.service.products.SortByField[message.sortField] : message.sortField;
                        if (message.order != null && message.hasOwnProperty("order"))
                            object.order = message.order;
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            object.groupType = options.enums === String ? $root.theplant.ec.service.products.FilterGroupType[message.groupType] : message.groupType;
                        return object;
                    };

                    /**
                     * Converts this Sorter to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.Sorter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Sorter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Sorter;
                })();

                products.SearchFilterGroup = (function() {

                    /**
                     * Properties of a SearchFilterGroup.
                     * @memberof theplant.ec.service.products
                     * @interface ISearchFilterGroup
                     * @property {string|null} [field] SearchFilterGroup field
                     * @property {theplant.ec.service.products.FilterGroupType|null} [groupType] SearchFilterGroup groupType
                     */

                    /**
                     * Constructs a new SearchFilterGroup.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a SearchFilterGroup.
                     * @implements ISearchFilterGroup
                     * @constructor
                     * @param {theplant.ec.service.products.ISearchFilterGroup=} [properties] Properties to set
                     */
                    function SearchFilterGroup(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SearchFilterGroup field.
                     * @member {string} field
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @instance
                     */
                    SearchFilterGroup.prototype.field = "";

                    /**
                     * SearchFilterGroup groupType.
                     * @member {theplant.ec.service.products.FilterGroupType} groupType
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @instance
                     */
                    SearchFilterGroup.prototype.groupType = 0;

                    /**
                     * Creates a new SearchFilterGroup instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {theplant.ec.service.products.ISearchFilterGroup=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.SearchFilterGroup} SearchFilterGroup instance
                     */
                    SearchFilterGroup.create = function create(properties) {
                        return new SearchFilterGroup(properties);
                    };

                    /**
                     * Encodes the specified SearchFilterGroup message. Does not implicitly {@link theplant.ec.service.products.SearchFilterGroup.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {theplant.ec.service.products.ISearchFilterGroup} message SearchFilterGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchFilterGroup.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.groupType);
                        return writer;
                    };

                    /**
                     * Encodes the specified SearchFilterGroup message, length delimited. Does not implicitly {@link theplant.ec.service.products.SearchFilterGroup.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {theplant.ec.service.products.ISearchFilterGroup} message SearchFilterGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SearchFilterGroup.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SearchFilterGroup message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.SearchFilterGroup} SearchFilterGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchFilterGroup.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.SearchFilterGroup();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.field = reader.string();
                                break;
                            case 3:
                                message.groupType = reader.int32();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SearchFilterGroup message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.SearchFilterGroup} SearchFilterGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SearchFilterGroup.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SearchFilterGroup message.
                     * @function verify
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SearchFilterGroup.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            switch (message.groupType) {
                            default:
                                return "groupType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        return null;
                    };

                    /**
                     * Creates a SearchFilterGroup message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.SearchFilterGroup} SearchFilterGroup
                     */
                    SearchFilterGroup.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.SearchFilterGroup)
                            return object;
                        var message = new $root.theplant.ec.service.products.SearchFilterGroup();
                        if (object.field != null)
                            message.field = String(object.field);
                        switch (object.groupType) {
                        case "PRODUCT":
                        case 0:
                            message.groupType = 0;
                            break;
                        case "VARIANT":
                        case 1:
                            message.groupType = 1;
                            break;
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SearchFilterGroup message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @static
                     * @param {theplant.ec.service.products.SearchFilterGroup} message SearchFilterGroup
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SearchFilterGroup.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.field = "";
                            object.groupType = options.enums === String ? "PRODUCT" : 0;
                        }
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            object.groupType = options.enums === String ? $root.theplant.ec.service.products.FilterGroupType[message.groupType] : message.groupType;
                        return object;
                    };

                    /**
                     * Converts this SearchFilterGroup to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.SearchFilterGroup
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SearchFilterGroup.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SearchFilterGroup;
                })();

                /**
                 * SearchFilterGroupMode enum.
                 * @name theplant.ec.service.products.SearchFilterGroupMode
                 * @enum {string}
                 * @property {number} COMPOSITE=0 COMPOSITE value
                 * @property {number} STRICT=1 STRICT value
                 */
                products.SearchFilterGroupMode = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "COMPOSITE"] = 0;
                    values[valuesById[1] = "STRICT"] = 1;
                    return values;
                })();

                products.FilterGroup = (function() {

                    /**
                     * Properties of a FilterGroup.
                     * @memberof theplant.ec.service.products
                     * @interface IFilterGroup
                     * @property {string|null} [label] FilterGroup label
                     * @property {string|null} [field] FilterGroup field
                     * @property {theplant.ec.service.products.FilterGroupType|null} [groupType] FilterGroup groupType
                     * @property {Array.<theplant.ec.service.products.IFilterOption>|null} [options] FilterGroup options
                     */

                    /**
                     * Constructs a new FilterGroup.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a FilterGroup.
                     * @implements IFilterGroup
                     * @constructor
                     * @param {theplant.ec.service.products.IFilterGroup=} [properties] Properties to set
                     */
                    function FilterGroup(properties) {
                        this.options = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterGroup label.
                     * @member {string} label
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @instance
                     */
                    FilterGroup.prototype.label = "";

                    /**
                     * FilterGroup field.
                     * @member {string} field
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @instance
                     */
                    FilterGroup.prototype.field = "";

                    /**
                     * FilterGroup groupType.
                     * @member {theplant.ec.service.products.FilterGroupType} groupType
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @instance
                     */
                    FilterGroup.prototype.groupType = 0;

                    /**
                     * FilterGroup options.
                     * @member {Array.<theplant.ec.service.products.IFilterOption>} options
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @instance
                     */
                    FilterGroup.prototype.options = $util.emptyArray;

                    /**
                     * Creates a new FilterGroup instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {theplant.ec.service.products.IFilterGroup=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.FilterGroup} FilterGroup instance
                     */
                    FilterGroup.create = function create(properties) {
                        return new FilterGroup(properties);
                    };

                    /**
                     * Encodes the specified FilterGroup message. Does not implicitly {@link theplant.ec.service.products.FilterGroup.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {theplant.ec.service.products.IFilterGroup} message FilterGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterGroup.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.label != null && message.hasOwnProperty("label"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                        if (message.field != null && message.hasOwnProperty("field"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.field);
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.groupType);
                        if (message.options != null && message.options.length)
                            for (var i = 0; i < message.options.length; ++i)
                                $root.theplant.ec.service.products.FilterOption.encode(message.options[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterGroup message, length delimited. Does not implicitly {@link theplant.ec.service.products.FilterGroup.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {theplant.ec.service.products.IFilterGroup} message FilterGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterGroup.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterGroup message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.FilterGroup} FilterGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterGroup.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.FilterGroup();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.label = reader.string();
                                break;
                            case 2:
                                message.field = reader.string();
                                break;
                            case 3:
                                message.groupType = reader.int32();
                                break;
                            case 4:
                                if (!(message.options && message.options.length))
                                    message.options = [];
                                message.options.push($root.theplant.ec.service.products.FilterOption.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterGroup message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.FilterGroup} FilterGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterGroup.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterGroup message.
                     * @function verify
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterGroup.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.field != null && message.hasOwnProperty("field"))
                            if (!$util.isString(message.field))
                                return "field: string expected";
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            switch (message.groupType) {
                            default:
                                return "groupType: enum value expected";
                            case 0:
                            case 1:
                                break;
                            }
                        if (message.options != null && message.hasOwnProperty("options")) {
                            if (!Array.isArray(message.options))
                                return "options: array expected";
                            for (var i = 0; i < message.options.length; ++i) {
                                var error = $root.theplant.ec.service.products.FilterOption.verify(message.options[i]);
                                if (error)
                                    return "options." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a FilterGroup message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.FilterGroup} FilterGroup
                     */
                    FilterGroup.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.FilterGroup)
                            return object;
                        var message = new $root.theplant.ec.service.products.FilterGroup();
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.field != null)
                            message.field = String(object.field);
                        switch (object.groupType) {
                        case "PRODUCT":
                        case 0:
                            message.groupType = 0;
                            break;
                        case "VARIANT":
                        case 1:
                            message.groupType = 1;
                            break;
                        }
                        if (object.options) {
                            if (!Array.isArray(object.options))
                                throw TypeError(".theplant.ec.service.products.FilterGroup.options: array expected");
                            message.options = [];
                            for (var i = 0; i < object.options.length; ++i) {
                                if (typeof object.options[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.FilterGroup.options: object expected");
                                message.options[i] = $root.theplant.ec.service.products.FilterOption.fromObject(object.options[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterGroup message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @static
                     * @param {theplant.ec.service.products.FilterGroup} message FilterGroup
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterGroup.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.options = [];
                        if (options.defaults) {
                            object.label = "";
                            object.field = "";
                            object.groupType = options.enums === String ? "PRODUCT" : 0;
                        }
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.field != null && message.hasOwnProperty("field"))
                            object.field = message.field;
                        if (message.groupType != null && message.hasOwnProperty("groupType"))
                            object.groupType = options.enums === String ? $root.theplant.ec.service.products.FilterGroupType[message.groupType] : message.groupType;
                        if (message.options && message.options.length) {
                            object.options = [];
                            for (var j = 0; j < message.options.length; ++j)
                                object.options[j] = $root.theplant.ec.service.products.FilterOption.toObject(message.options[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this FilterGroup to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.FilterGroup
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterGroup.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterGroup;
                })();

                products.FilterOption = (function() {

                    /**
                     * Properties of a FilterOption.
                     * @memberof theplant.ec.service.products
                     * @interface IFilterOption
                     * @property {string|null} [label] FilterOption label
                     * @property {string|null} [value] FilterOption value
                     * @property {number|Long|null} [count] FilterOption count
                     */

                    /**
                     * Constructs a new FilterOption.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a FilterOption.
                     * @implements IFilterOption
                     * @constructor
                     * @param {theplant.ec.service.products.IFilterOption=} [properties] Properties to set
                     */
                    function FilterOption(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterOption label.
                     * @member {string} label
                     * @memberof theplant.ec.service.products.FilterOption
                     * @instance
                     */
                    FilterOption.prototype.label = "";

                    /**
                     * FilterOption value.
                     * @member {string} value
                     * @memberof theplant.ec.service.products.FilterOption
                     * @instance
                     */
                    FilterOption.prototype.value = "";

                    /**
                     * FilterOption count.
                     * @member {number|Long} count
                     * @memberof theplant.ec.service.products.FilterOption
                     * @instance
                     */
                    FilterOption.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new FilterOption instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {theplant.ec.service.products.IFilterOption=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.FilterOption} FilterOption instance
                     */
                    FilterOption.create = function create(properties) {
                        return new FilterOption(properties);
                    };

                    /**
                     * Encodes the specified FilterOption message. Does not implicitly {@link theplant.ec.service.products.FilterOption.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {theplant.ec.service.products.IFilterOption} message FilterOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterOption.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.label != null && message.hasOwnProperty("label"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                        if (message.value != null && message.hasOwnProperty("value"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                        if (message.count != null && message.hasOwnProperty("count"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.count);
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterOption message, length delimited. Does not implicitly {@link theplant.ec.service.products.FilterOption.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {theplant.ec.service.products.IFilterOption} message FilterOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterOption.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterOption message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.FilterOption} FilterOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterOption.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.FilterOption();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.label = reader.string();
                                break;
                            case 2:
                                message.value = reader.string();
                                break;
                            case 3:
                                message.count = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterOption message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.FilterOption} FilterOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterOption.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterOption message.
                     * @function verify
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterOption.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.label != null && message.hasOwnProperty("label"))
                            if (!$util.isString(message.label))
                                return "label: string expected";
                        if (message.value != null && message.hasOwnProperty("value"))
                            if (!$util.isString(message.value))
                                return "value: string expected";
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                                return "count: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a FilterOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.FilterOption} FilterOption
                     */
                    FilterOption.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.FilterOption)
                            return object;
                        var message = new $root.theplant.ec.service.products.FilterOption();
                        if (object.label != null)
                            message.label = String(object.label);
                        if (object.value != null)
                            message.value = String(object.value);
                        if (object.count != null)
                            if ($util.Long)
                                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                            else if (typeof object.count === "string")
                                message.count = parseInt(object.count, 10);
                            else if (typeof object.count === "number")
                                message.count = object.count;
                            else if (typeof object.count === "object")
                                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.FilterOption
                     * @static
                     * @param {theplant.ec.service.products.FilterOption} message FilterOption
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterOption.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.label = "";
                            object.value = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.count = options.longs === String ? "0" : 0;
                        }
                        if (message.label != null && message.hasOwnProperty("label"))
                            object.label = message.label;
                        if (message.value != null && message.hasOwnProperty("value"))
                            object.value = message.value;
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (typeof message.count === "number")
                                object.count = options.longs === String ? String(message.count) : message.count;
                            else
                                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                        return object;
                    };

                    /**
                     * Converts this FilterOption to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.FilterOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterOption;
                })();

                products.FilterProductsResult = (function() {

                    /**
                     * Properties of a FilterProductsResult.
                     * @memberof theplant.ec.service.products
                     * @interface IFilterProductsResult
                     * @property {Array.<theplant.ec.service.products.IProduct>|null} [products] FilterProductsResult products
                     * @property {Array.<theplant.ec.service.products.IFilterGroup>|null} [filterGroups] FilterProductsResult filterGroups
                     * @property {number|Long|null} [total] FilterProductsResult total
                     */

                    /**
                     * Constructs a new FilterProductsResult.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a FilterProductsResult.
                     * @implements IFilterProductsResult
                     * @constructor
                     * @param {theplant.ec.service.products.IFilterProductsResult=} [properties] Properties to set
                     */
                    function FilterProductsResult(properties) {
                        this.products = [];
                        this.filterGroups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterProductsResult products.
                     * @member {Array.<theplant.ec.service.products.IProduct>} products
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @instance
                     */
                    FilterProductsResult.prototype.products = $util.emptyArray;

                    /**
                     * FilterProductsResult filterGroups.
                     * @member {Array.<theplant.ec.service.products.IFilterGroup>} filterGroups
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @instance
                     */
                    FilterProductsResult.prototype.filterGroups = $util.emptyArray;

                    /**
                     * FilterProductsResult total.
                     * @member {number|Long} total
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @instance
                     */
                    FilterProductsResult.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new FilterProductsResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {theplant.ec.service.products.IFilterProductsResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.FilterProductsResult} FilterProductsResult instance
                     */
                    FilterProductsResult.create = function create(properties) {
                        return new FilterProductsResult(properties);
                    };

                    /**
                     * Encodes the specified FilterProductsResult message. Does not implicitly {@link theplant.ec.service.products.FilterProductsResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {theplant.ec.service.products.IFilterProductsResult} message FilterProductsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterProductsResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.products != null && message.products.length)
                            for (var i = 0; i < message.products.length; ++i)
                                $root.theplant.ec.service.products.Product.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.filterGroups != null && message.filterGroups.length)
                            for (var i = 0; i < message.filterGroups.length; ++i)
                                $root.theplant.ec.service.products.FilterGroup.encode(message.filterGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.total != null && message.hasOwnProperty("total"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.total);
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterProductsResult message, length delimited. Does not implicitly {@link theplant.ec.service.products.FilterProductsResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {theplant.ec.service.products.IFilterProductsResult} message FilterProductsResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterProductsResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterProductsResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.FilterProductsResult} FilterProductsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterProductsResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.FilterProductsResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.products && message.products.length))
                                    message.products = [];
                                message.products.push($root.theplant.ec.service.products.Product.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.filterGroups && message.filterGroups.length))
                                    message.filterGroups = [];
                                message.filterGroups.push($root.theplant.ec.service.products.FilterGroup.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.total = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterProductsResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.FilterProductsResult} FilterProductsResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterProductsResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterProductsResult message.
                     * @function verify
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterProductsResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.products != null && message.hasOwnProperty("products")) {
                            if (!Array.isArray(message.products))
                                return "products: array expected";
                            for (var i = 0; i < message.products.length; ++i) {
                                var error = $root.theplant.ec.service.products.Product.verify(message.products[i]);
                                if (error)
                                    return "products." + error;
                            }
                        }
                        if (message.filterGroups != null && message.hasOwnProperty("filterGroups")) {
                            if (!Array.isArray(message.filterGroups))
                                return "filterGroups: array expected";
                            for (var i = 0; i < message.filterGroups.length; ++i) {
                                var error = $root.theplant.ec.service.products.FilterGroup.verify(message.filterGroups[i]);
                                if (error)
                                    return "filterGroups." + error;
                            }
                        }
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                                return "total: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a FilterProductsResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.FilterProductsResult} FilterProductsResult
                     */
                    FilterProductsResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.FilterProductsResult)
                            return object;
                        var message = new $root.theplant.ec.service.products.FilterProductsResult();
                        if (object.products) {
                            if (!Array.isArray(object.products))
                                throw TypeError(".theplant.ec.service.products.FilterProductsResult.products: array expected");
                            message.products = [];
                            for (var i = 0; i < object.products.length; ++i) {
                                if (typeof object.products[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.FilterProductsResult.products: object expected");
                                message.products[i] = $root.theplant.ec.service.products.Product.fromObject(object.products[i]);
                            }
                        }
                        if (object.filterGroups) {
                            if (!Array.isArray(object.filterGroups))
                                throw TypeError(".theplant.ec.service.products.FilterProductsResult.filterGroups: array expected");
                            message.filterGroups = [];
                            for (var i = 0; i < object.filterGroups.length; ++i) {
                                if (typeof object.filterGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.FilterProductsResult.filterGroups: object expected");
                                message.filterGroups[i] = $root.theplant.ec.service.products.FilterGroup.fromObject(object.filterGroups[i]);
                            }
                        }
                        if (object.total != null)
                            if ($util.Long)
                                (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                            else if (typeof object.total === "string")
                                message.total = parseInt(object.total, 10);
                            else if (typeof object.total === "number")
                                message.total = object.total;
                            else if (typeof object.total === "object")
                                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterProductsResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @static
                     * @param {theplant.ec.service.products.FilterProductsResult} message FilterProductsResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterProductsResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.products = [];
                            object.filterGroups = [];
                        }
                        if (options.defaults)
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.total = options.longs === String ? "0" : 0;
                        if (message.products && message.products.length) {
                            object.products = [];
                            for (var j = 0; j < message.products.length; ++j)
                                object.products[j] = $root.theplant.ec.service.products.Product.toObject(message.products[j], options);
                        }
                        if (message.filterGroups && message.filterGroups.length) {
                            object.filterGroups = [];
                            for (var j = 0; j < message.filterGroups.length; ++j)
                                object.filterGroups[j] = $root.theplant.ec.service.products.FilterGroup.toObject(message.filterGroups[j], options);
                        }
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (typeof message.total === "number")
                                object.total = options.longs === String ? String(message.total) : message.total;
                            else
                                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                        return object;
                    };

                    /**
                     * Converts this FilterProductsResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.FilterProductsResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterProductsResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterProductsResult;
                })();

                /**
                 * FilterCondition enum.
                 * @name theplant.ec.service.products.FilterCondition
                 * @enum {string}
                 * @property {number} ON_SALE=0 ON_SALE value
                 * @property {number} MIX_SALE=1 MIX_SALE value
                 * @property {number} EXCLUDE_ON_SALE=2 EXCLUDE_ON_SALE value
                 * @property {number} IN_INVENTORY=3 IN_INVENTORY value
                 * @property {number} STORE_IN_INVENTORY=4 STORE_IN_INVENTORY value
                 * @property {number} OUT_OF_INVENTORY=5 OUT_OF_INVENTORY value
                 * @property {number} SCS_OR_STORE_IN_INVENTORY=6 SCS_OR_STORE_IN_INVENTORY value
                 */
                products.FilterCondition = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "ON_SALE"] = 0;
                    values[valuesById[1] = "MIX_SALE"] = 1;
                    values[valuesById[2] = "EXCLUDE_ON_SALE"] = 2;
                    values[valuesById[3] = "IN_INVENTORY"] = 3;
                    values[valuesById[4] = "STORE_IN_INVENTORY"] = 4;
                    values[valuesById[5] = "OUT_OF_INVENTORY"] = 5;
                    values[valuesById[6] = "SCS_OR_STORE_IN_INVENTORY"] = 6;
                    return values;
                })();

                /**
                 * SortByField enum.
                 * @name theplant.ec.service.products.SortByField
                 * @enum {string}
                 * @property {number} PRICE=0 PRICE value
                 * @property {number} SELLING_PRICE=1 SELLING_PRICE value
                 * @property {number} SALES=2 SALES value
                 * @property {number} RELEASE_DATE=3 RELEASE_DATE value
                 * @property {number} REVIEWS=4 REVIEWS value
                 * @property {number} NAME=5 NAME value
                 * @property {number} DISCOUNT_RATE=6 DISCOUNT_RATE value
                 */
                products.SortByField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PRICE"] = 0;
                    values[valuesById[1] = "SELLING_PRICE"] = 1;
                    values[valuesById[2] = "SALES"] = 2;
                    values[valuesById[3] = "RELEASE_DATE"] = 3;
                    values[valuesById[4] = "REVIEWS"] = 4;
                    values[valuesById[5] = "NAME"] = 5;
                    values[valuesById[6] = "DISCOUNT_RATE"] = 6;
                    return values;
                })();

                /**
                 * FilterGroupType enum.
                 * @name theplant.ec.service.products.FilterGroupType
                 * @enum {string}
                 * @property {number} PRODUCT=0 PRODUCT value
                 * @property {number} VARIANT=1 VARIANT value
                 */
                products.FilterGroupType = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PRODUCT"] = 0;
                    values[valuesById[1] = "VARIANT"] = 1;
                    return values;
                })();

                /**
                 * ReindexAction enum.
                 * @name theplant.ec.service.products.ReindexAction
                 * @enum {string}
                 * @property {number} REINDEX=0 REINDEX value
                 * @property {number} RENEW=1 RENEW value
                 * @property {number} RENEW_REINDEX=2 RENEW_REINDEX value
                 */
                products.ReindexAction = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REINDEX"] = 0;
                    values[valuesById[1] = "RENEW"] = 1;
                    values[valuesById[2] = "RENEW_REINDEX"] = 2;
                    return values;
                })();

                products.AnalyzerSetting = (function() {

                    /**
                     * Properties of an AnalyzerSetting.
                     * @memberof theplant.ec.service.products
                     * @interface IAnalyzerSetting
                     * @property {theplant.ec.service.products.ReindexAction|null} [action] AnalyzerSetting action
                     * @property {Array.<theplant.ec.service.products.IDictionary>|null} [dictionaries] AnalyzerSetting dictionaries
                     */

                    /**
                     * Constructs a new AnalyzerSetting.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents an AnalyzerSetting.
                     * @implements IAnalyzerSetting
                     * @constructor
                     * @param {theplant.ec.service.products.IAnalyzerSetting=} [properties] Properties to set
                     */
                    function AnalyzerSetting(properties) {
                        this.dictionaries = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * AnalyzerSetting action.
                     * @member {theplant.ec.service.products.ReindexAction} action
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @instance
                     */
                    AnalyzerSetting.prototype.action = 0;

                    /**
                     * AnalyzerSetting dictionaries.
                     * @member {Array.<theplant.ec.service.products.IDictionary>} dictionaries
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @instance
                     */
                    AnalyzerSetting.prototype.dictionaries = $util.emptyArray;

                    /**
                     * Creates a new AnalyzerSetting instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {theplant.ec.service.products.IAnalyzerSetting=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.AnalyzerSetting} AnalyzerSetting instance
                     */
                    AnalyzerSetting.create = function create(properties) {
                        return new AnalyzerSetting(properties);
                    };

                    /**
                     * Encodes the specified AnalyzerSetting message. Does not implicitly {@link theplant.ec.service.products.AnalyzerSetting.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {theplant.ec.service.products.IAnalyzerSetting} message AnalyzerSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AnalyzerSetting.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.action != null && message.hasOwnProperty("action"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.action);
                        if (message.dictionaries != null && message.dictionaries.length)
                            for (var i = 0; i < message.dictionaries.length; ++i)
                                $root.theplant.ec.service.products.Dictionary.encode(message.dictionaries[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified AnalyzerSetting message, length delimited. Does not implicitly {@link theplant.ec.service.products.AnalyzerSetting.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {theplant.ec.service.products.IAnalyzerSetting} message AnalyzerSetting message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    AnalyzerSetting.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an AnalyzerSetting message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.AnalyzerSetting} AnalyzerSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AnalyzerSetting.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.AnalyzerSetting();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.action = reader.int32();
                                break;
                            case 2:
                                if (!(message.dictionaries && message.dictionaries.length))
                                    message.dictionaries = [];
                                message.dictionaries.push($root.theplant.ec.service.products.Dictionary.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an AnalyzerSetting message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.AnalyzerSetting} AnalyzerSetting
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    AnalyzerSetting.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an AnalyzerSetting message.
                     * @function verify
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    AnalyzerSetting.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.action != null && message.hasOwnProperty("action"))
                            switch (message.action) {
                            default:
                                return "action: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.dictionaries != null && message.hasOwnProperty("dictionaries")) {
                            if (!Array.isArray(message.dictionaries))
                                return "dictionaries: array expected";
                            for (var i = 0; i < message.dictionaries.length; ++i) {
                                var error = $root.theplant.ec.service.products.Dictionary.verify(message.dictionaries[i]);
                                if (error)
                                    return "dictionaries." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates an AnalyzerSetting message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.AnalyzerSetting} AnalyzerSetting
                     */
                    AnalyzerSetting.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.AnalyzerSetting)
                            return object;
                        var message = new $root.theplant.ec.service.products.AnalyzerSetting();
                        switch (object.action) {
                        case "REINDEX":
                        case 0:
                            message.action = 0;
                            break;
                        case "RENEW":
                        case 1:
                            message.action = 1;
                            break;
                        case "RENEW_REINDEX":
                        case 2:
                            message.action = 2;
                            break;
                        }
                        if (object.dictionaries) {
                            if (!Array.isArray(object.dictionaries))
                                throw TypeError(".theplant.ec.service.products.AnalyzerSetting.dictionaries: array expected");
                            message.dictionaries = [];
                            for (var i = 0; i < object.dictionaries.length; ++i) {
                                if (typeof object.dictionaries[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.AnalyzerSetting.dictionaries: object expected");
                                message.dictionaries[i] = $root.theplant.ec.service.products.Dictionary.fromObject(object.dictionaries[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an AnalyzerSetting message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @static
                     * @param {theplant.ec.service.products.AnalyzerSetting} message AnalyzerSetting
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    AnalyzerSetting.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.dictionaries = [];
                        if (options.defaults)
                            object.action = options.enums === String ? "REINDEX" : 0;
                        if (message.action != null && message.hasOwnProperty("action"))
                            object.action = options.enums === String ? $root.theplant.ec.service.products.ReindexAction[message.action] : message.action;
                        if (message.dictionaries && message.dictionaries.length) {
                            object.dictionaries = [];
                            for (var j = 0; j < message.dictionaries.length; ++j)
                                object.dictionaries[j] = $root.theplant.ec.service.products.Dictionary.toObject(message.dictionaries[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this AnalyzerSetting to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.AnalyzerSetting
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    AnalyzerSetting.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return AnalyzerSetting;
                })();

                products.Dictionary = (function() {

                    /**
                     * Properties of a Dictionary.
                     * @memberof theplant.ec.service.products
                     * @interface IDictionary
                     * @property {string|null} [lang] Dictionary lang
                     * @property {Array.<string>|null} [keywords] Dictionary keywords
                     * @property {Array.<string>|null} [synonyms] Dictionary synonyms
                     * @property {Array.<string>|null} [stops] Dictionary stops
                     */

                    /**
                     * Constructs a new Dictionary.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a Dictionary.
                     * @implements IDictionary
                     * @constructor
                     * @param {theplant.ec.service.products.IDictionary=} [properties] Properties to set
                     */
                    function Dictionary(properties) {
                        this.keywords = [];
                        this.synonyms = [];
                        this.stops = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Dictionary lang.
                     * @member {string} lang
                     * @memberof theplant.ec.service.products.Dictionary
                     * @instance
                     */
                    Dictionary.prototype.lang = "";

                    /**
                     * Dictionary keywords.
                     * @member {Array.<string>} keywords
                     * @memberof theplant.ec.service.products.Dictionary
                     * @instance
                     */
                    Dictionary.prototype.keywords = $util.emptyArray;

                    /**
                     * Dictionary synonyms.
                     * @member {Array.<string>} synonyms
                     * @memberof theplant.ec.service.products.Dictionary
                     * @instance
                     */
                    Dictionary.prototype.synonyms = $util.emptyArray;

                    /**
                     * Dictionary stops.
                     * @member {Array.<string>} stops
                     * @memberof theplant.ec.service.products.Dictionary
                     * @instance
                     */
                    Dictionary.prototype.stops = $util.emptyArray;

                    /**
                     * Creates a new Dictionary instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {theplant.ec.service.products.IDictionary=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.Dictionary} Dictionary instance
                     */
                    Dictionary.create = function create(properties) {
                        return new Dictionary(properties);
                    };

                    /**
                     * Encodes the specified Dictionary message. Does not implicitly {@link theplant.ec.service.products.Dictionary.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {theplant.ec.service.products.IDictionary} message Dictionary message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Dictionary.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.lang != null && message.hasOwnProperty("lang"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.lang);
                        if (message.keywords != null && message.keywords.length)
                            for (var i = 0; i < message.keywords.length; ++i)
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.keywords[i]);
                        if (message.synonyms != null && message.synonyms.length)
                            for (var i = 0; i < message.synonyms.length; ++i)
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.synonyms[i]);
                        if (message.stops != null && message.stops.length)
                            for (var i = 0; i < message.stops.length; ++i)
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.stops[i]);
                        return writer;
                    };

                    /**
                     * Encodes the specified Dictionary message, length delimited. Does not implicitly {@link theplant.ec.service.products.Dictionary.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {theplant.ec.service.products.IDictionary} message Dictionary message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Dictionary.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Dictionary message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.Dictionary} Dictionary
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Dictionary.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.Dictionary();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.lang = reader.string();
                                break;
                            case 2:
                                if (!(message.keywords && message.keywords.length))
                                    message.keywords = [];
                                message.keywords.push(reader.string());
                                break;
                            case 3:
                                if (!(message.synonyms && message.synonyms.length))
                                    message.synonyms = [];
                                message.synonyms.push(reader.string());
                                break;
                            case 4:
                                if (!(message.stops && message.stops.length))
                                    message.stops = [];
                                message.stops.push(reader.string());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Dictionary message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.Dictionary} Dictionary
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Dictionary.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Dictionary message.
                     * @function verify
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Dictionary.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.lang != null && message.hasOwnProperty("lang"))
                            if (!$util.isString(message.lang))
                                return "lang: string expected";
                        if (message.keywords != null && message.hasOwnProperty("keywords")) {
                            if (!Array.isArray(message.keywords))
                                return "keywords: array expected";
                            for (var i = 0; i < message.keywords.length; ++i)
                                if (!$util.isString(message.keywords[i]))
                                    return "keywords: string[] expected";
                        }
                        if (message.synonyms != null && message.hasOwnProperty("synonyms")) {
                            if (!Array.isArray(message.synonyms))
                                return "synonyms: array expected";
                            for (var i = 0; i < message.synonyms.length; ++i)
                                if (!$util.isString(message.synonyms[i]))
                                    return "synonyms: string[] expected";
                        }
                        if (message.stops != null && message.hasOwnProperty("stops")) {
                            if (!Array.isArray(message.stops))
                                return "stops: array expected";
                            for (var i = 0; i < message.stops.length; ++i)
                                if (!$util.isString(message.stops[i]))
                                    return "stops: string[] expected";
                        }
                        return null;
                    };

                    /**
                     * Creates a Dictionary message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.Dictionary} Dictionary
                     */
                    Dictionary.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.Dictionary)
                            return object;
                        var message = new $root.theplant.ec.service.products.Dictionary();
                        if (object.lang != null)
                            message.lang = String(object.lang);
                        if (object.keywords) {
                            if (!Array.isArray(object.keywords))
                                throw TypeError(".theplant.ec.service.products.Dictionary.keywords: array expected");
                            message.keywords = [];
                            for (var i = 0; i < object.keywords.length; ++i)
                                message.keywords[i] = String(object.keywords[i]);
                        }
                        if (object.synonyms) {
                            if (!Array.isArray(object.synonyms))
                                throw TypeError(".theplant.ec.service.products.Dictionary.synonyms: array expected");
                            message.synonyms = [];
                            for (var i = 0; i < object.synonyms.length; ++i)
                                message.synonyms[i] = String(object.synonyms[i]);
                        }
                        if (object.stops) {
                            if (!Array.isArray(object.stops))
                                throw TypeError(".theplant.ec.service.products.Dictionary.stops: array expected");
                            message.stops = [];
                            for (var i = 0; i < object.stops.length; ++i)
                                message.stops[i] = String(object.stops[i]);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Dictionary message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.Dictionary
                     * @static
                     * @param {theplant.ec.service.products.Dictionary} message Dictionary
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Dictionary.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.keywords = [];
                            object.synonyms = [];
                            object.stops = [];
                        }
                        if (options.defaults)
                            object.lang = "";
                        if (message.lang != null && message.hasOwnProperty("lang"))
                            object.lang = message.lang;
                        if (message.keywords && message.keywords.length) {
                            object.keywords = [];
                            for (var j = 0; j < message.keywords.length; ++j)
                                object.keywords[j] = message.keywords[j];
                        }
                        if (message.synonyms && message.synonyms.length) {
                            object.synonyms = [];
                            for (var j = 0; j < message.synonyms.length; ++j)
                                object.synonyms[j] = message.synonyms[j];
                        }
                        if (message.stops && message.stops.length) {
                            object.stops = [];
                            for (var j = 0; j < message.stops.length; ++j)
                                object.stops[j] = message.stops[j];
                        }
                        return object;
                    };

                    /**
                     * Converts this Dictionary to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.Dictionary
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Dictionary.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Dictionary;
                })();

                products.ExpandedID = (function() {

                    /**
                     * Properties of an ExpandedID.
                     * @memberof theplant.ec.service.products
                     * @interface IExpandedID
                     * @property {string|null} [productCode] ExpandedID productCode
                     * @property {theplant.ec.service.products.ExpandedID.ExpandTo|null} [to] ExpandedID to
                     * @property {string|null} [fieldValue] ExpandedID fieldValue
                     * @property {string|null} [raw] ExpandedID raw
                     */

                    /**
                     * Constructs a new ExpandedID.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents an ExpandedID.
                     * @implements IExpandedID
                     * @constructor
                     * @param {theplant.ec.service.products.IExpandedID=} [properties] Properties to set
                     */
                    function ExpandedID(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ExpandedID productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @instance
                     */
                    ExpandedID.prototype.productCode = "";

                    /**
                     * ExpandedID to.
                     * @member {theplant.ec.service.products.ExpandedID.ExpandTo} to
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @instance
                     */
                    ExpandedID.prototype.to = 0;

                    /**
                     * ExpandedID fieldValue.
                     * @member {string} fieldValue
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @instance
                     */
                    ExpandedID.prototype.fieldValue = "";

                    /**
                     * ExpandedID raw.
                     * @member {string} raw
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @instance
                     */
                    ExpandedID.prototype.raw = "";

                    /**
                     * Creates a new ExpandedID instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {theplant.ec.service.products.IExpandedID=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.ExpandedID} ExpandedID instance
                     */
                    ExpandedID.create = function create(properties) {
                        return new ExpandedID(properties);
                    };

                    /**
                     * Encodes the specified ExpandedID message. Does not implicitly {@link theplant.ec.service.products.ExpandedID.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {theplant.ec.service.products.IExpandedID} message ExpandedID message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExpandedID.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.productCode);
                        if (message.to != null && message.hasOwnProperty("to"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
                        if (message.fieldValue != null && message.hasOwnProperty("fieldValue"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fieldValue);
                        if (message.raw != null && message.hasOwnProperty("raw"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.raw);
                        return writer;
                    };

                    /**
                     * Encodes the specified ExpandedID message, length delimited. Does not implicitly {@link theplant.ec.service.products.ExpandedID.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {theplant.ec.service.products.IExpandedID} message ExpandedID message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExpandedID.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ExpandedID message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.ExpandedID} ExpandedID
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExpandedID.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.ExpandedID();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.productCode = reader.string();
                                break;
                            case 2:
                                message.to = reader.int32();
                                break;
                            case 3:
                                message.fieldValue = reader.string();
                                break;
                            case 4:
                                message.raw = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ExpandedID message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.ExpandedID} ExpandedID
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExpandedID.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ExpandedID message.
                     * @function verify
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExpandedID.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.to != null && message.hasOwnProperty("to"))
                            switch (message.to) {
                            default:
                                return "to: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                                break;
                            }
                        if (message.fieldValue != null && message.hasOwnProperty("fieldValue"))
                            if (!$util.isString(message.fieldValue))
                                return "fieldValue: string expected";
                        if (message.raw != null && message.hasOwnProperty("raw"))
                            if (!$util.isString(message.raw))
                                return "raw: string expected";
                        return null;
                    };

                    /**
                     * Creates an ExpandedID message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.ExpandedID} ExpandedID
                     */
                    ExpandedID.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.ExpandedID)
                            return object;
                        var message = new $root.theplant.ec.service.products.ExpandedID();
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        switch (object.to) {
                        case "NONE":
                        case 0:
                            message.to = 0;
                            break;
                        case "COLOR":
                        case 1:
                            message.to = 1;
                            break;
                        case "VARIANT":
                        case 2:
                            message.to = 2;
                            break;
                        case "PRICE":
                        case 3:
                            message.to = 3;
                            break;
                        }
                        if (object.fieldValue != null)
                            message.fieldValue = String(object.fieldValue);
                        if (object.raw != null)
                            message.raw = String(object.raw);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ExpandedID message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @static
                     * @param {theplant.ec.service.products.ExpandedID} message ExpandedID
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExpandedID.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.productCode = "";
                            object.to = options.enums === String ? "NONE" : 0;
                            object.fieldValue = "";
                            object.raw = "";
                        }
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.to != null && message.hasOwnProperty("to"))
                            object.to = options.enums === String ? $root.theplant.ec.service.products.ExpandedID.ExpandTo[message.to] : message.to;
                        if (message.fieldValue != null && message.hasOwnProperty("fieldValue"))
                            object.fieldValue = message.fieldValue;
                        if (message.raw != null && message.hasOwnProperty("raw"))
                            object.raw = message.raw;
                        return object;
                    };

                    /**
                     * Converts this ExpandedID to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.ExpandedID
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExpandedID.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * ExpandTo enum.
                     * @name theplant.ec.service.products.ExpandedID.ExpandTo
                     * @enum {string}
                     * @property {number} NONE=0 NONE value
                     * @property {number} COLOR=1 COLOR value
                     * @property {number} VARIANT=2 VARIANT value
                     * @property {number} PRICE=3 PRICE value
                     */
                    ExpandedID.ExpandTo = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "NONE"] = 0;
                        values[valuesById[1] = "COLOR"] = 1;
                        values[valuesById[2] = "VARIANT"] = 2;
                        values[valuesById[3] = "PRICE"] = 3;
                        return values;
                    })();

                    return ExpandedID;
                })();

                /**
                 * STColumnName enum.
                 * @name theplant.ec.service.products.STColumnName
                 * @enum {string}
                 * @property {number} Name=0 Name value
                 * @property {number} Code=1 Code value
                 * @property {number} Description=2 Description value
                 */
                products.STColumnName = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "Name"] = 0;
                    values[valuesById[1] = "Code"] = 1;
                    values[valuesById[2] = "Description"] = 2;
                    return values;
                })();

                products.Precondition = (function() {

                    /**
                     * Properties of a Precondition.
                     * @memberof theplant.ec.service.products
                     * @interface IPrecondition
                     * @property {string|null} [channelName] Precondition channelName
                     * @property {Array.<theplant.ec.service.products.IFilterProperty>|null} [filterProperties] Precondition filterProperties
                     * @property {string|null} [noticeInfo] Precondition noticeInfo
                     * @property {number|Long|null} [inventoryLimit] Precondition inventoryLimit
                     */

                    /**
                     * Constructs a new Precondition.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a Precondition.
                     * @implements IPrecondition
                     * @constructor
                     * @param {theplant.ec.service.products.IPrecondition=} [properties] Properties to set
                     */
                    function Precondition(properties) {
                        this.filterProperties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Precondition channelName.
                     * @member {string} channelName
                     * @memberof theplant.ec.service.products.Precondition
                     * @instance
                     */
                    Precondition.prototype.channelName = "";

                    /**
                     * Precondition filterProperties.
                     * @member {Array.<theplant.ec.service.products.IFilterProperty>} filterProperties
                     * @memberof theplant.ec.service.products.Precondition
                     * @instance
                     */
                    Precondition.prototype.filterProperties = $util.emptyArray;

                    /**
                     * Precondition noticeInfo.
                     * @member {string} noticeInfo
                     * @memberof theplant.ec.service.products.Precondition
                     * @instance
                     */
                    Precondition.prototype.noticeInfo = "";

                    /**
                     * Precondition inventoryLimit.
                     * @member {number|Long} inventoryLimit
                     * @memberof theplant.ec.service.products.Precondition
                     * @instance
                     */
                    Precondition.prototype.inventoryLimit = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new Precondition instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {theplant.ec.service.products.IPrecondition=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.Precondition} Precondition instance
                     */
                    Precondition.create = function create(properties) {
                        return new Precondition(properties);
                    };

                    /**
                     * Encodes the specified Precondition message. Does not implicitly {@link theplant.ec.service.products.Precondition.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {theplant.ec.service.products.IPrecondition} message Precondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Precondition.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.channelName != null && message.hasOwnProperty("channelName"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.channelName);
                        if (message.filterProperties != null && message.filterProperties.length)
                            for (var i = 0; i < message.filterProperties.length; ++i)
                                $root.theplant.ec.service.products.FilterProperty.encode(message.filterProperties[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.noticeInfo);
                        if (message.inventoryLimit != null && message.hasOwnProperty("inventoryLimit"))
                            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.inventoryLimit);
                        return writer;
                    };

                    /**
                     * Encodes the specified Precondition message, length delimited. Does not implicitly {@link theplant.ec.service.products.Precondition.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {theplant.ec.service.products.IPrecondition} message Precondition message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Precondition.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Precondition message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.Precondition} Precondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Precondition.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.Precondition();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.channelName = reader.string();
                                break;
                            case 2:
                                if (!(message.filterProperties && message.filterProperties.length))
                                    message.filterProperties = [];
                                message.filterProperties.push($root.theplant.ec.service.products.FilterProperty.decode(reader, reader.uint32()));
                                break;
                            case 3:
                                message.noticeInfo = reader.string();
                                break;
                            case 4:
                                message.inventoryLimit = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Precondition message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.Precondition} Precondition
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Precondition.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Precondition message.
                     * @function verify
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Precondition.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.channelName != null && message.hasOwnProperty("channelName"))
                            if (!$util.isString(message.channelName))
                                return "channelName: string expected";
                        if (message.filterProperties != null && message.hasOwnProperty("filterProperties")) {
                            if (!Array.isArray(message.filterProperties))
                                return "filterProperties: array expected";
                            for (var i = 0; i < message.filterProperties.length; ++i) {
                                var error = $root.theplant.ec.service.products.FilterProperty.verify(message.filterProperties[i]);
                                if (error)
                                    return "filterProperties." + error;
                            }
                        }
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            if (!$util.isString(message.noticeInfo))
                                return "noticeInfo: string expected";
                        if (message.inventoryLimit != null && message.hasOwnProperty("inventoryLimit"))
                            if (!$util.isInteger(message.inventoryLimit) && !(message.inventoryLimit && $util.isInteger(message.inventoryLimit.low) && $util.isInteger(message.inventoryLimit.high)))
                                return "inventoryLimit: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a Precondition message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.Precondition} Precondition
                     */
                    Precondition.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.Precondition)
                            return object;
                        var message = new $root.theplant.ec.service.products.Precondition();
                        if (object.channelName != null)
                            message.channelName = String(object.channelName);
                        if (object.filterProperties) {
                            if (!Array.isArray(object.filterProperties))
                                throw TypeError(".theplant.ec.service.products.Precondition.filterProperties: array expected");
                            message.filterProperties = [];
                            for (var i = 0; i < object.filterProperties.length; ++i) {
                                if (typeof object.filterProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.products.Precondition.filterProperties: object expected");
                                message.filterProperties[i] = $root.theplant.ec.service.products.FilterProperty.fromObject(object.filterProperties[i]);
                            }
                        }
                        if (object.noticeInfo != null)
                            message.noticeInfo = String(object.noticeInfo);
                        if (object.inventoryLimit != null)
                            if ($util.Long)
                                (message.inventoryLimit = $util.Long.fromValue(object.inventoryLimit)).unsigned = false;
                            else if (typeof object.inventoryLimit === "string")
                                message.inventoryLimit = parseInt(object.inventoryLimit, 10);
                            else if (typeof object.inventoryLimit === "number")
                                message.inventoryLimit = object.inventoryLimit;
                            else if (typeof object.inventoryLimit === "object")
                                message.inventoryLimit = new $util.LongBits(object.inventoryLimit.low >>> 0, object.inventoryLimit.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a Precondition message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.Precondition
                     * @static
                     * @param {theplant.ec.service.products.Precondition} message Precondition
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Precondition.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.filterProperties = [];
                        if (options.defaults) {
                            object.channelName = "";
                            object.noticeInfo = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.inventoryLimit = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.inventoryLimit = options.longs === String ? "0" : 0;
                        }
                        if (message.channelName != null && message.hasOwnProperty("channelName"))
                            object.channelName = message.channelName;
                        if (message.filterProperties && message.filterProperties.length) {
                            object.filterProperties = [];
                            for (var j = 0; j < message.filterProperties.length; ++j)
                                object.filterProperties[j] = $root.theplant.ec.service.products.FilterProperty.toObject(message.filterProperties[j], options);
                        }
                        if (message.noticeInfo != null && message.hasOwnProperty("noticeInfo"))
                            object.noticeInfo = message.noticeInfo;
                        if (message.inventoryLimit != null && message.hasOwnProperty("inventoryLimit"))
                            if (typeof message.inventoryLimit === "number")
                                object.inventoryLimit = options.longs === String ? String(message.inventoryLimit) : message.inventoryLimit;
                            else
                                object.inventoryLimit = options.longs === String ? $util.Long.prototype.toString.call(message.inventoryLimit) : options.longs === Number ? new $util.LongBits(message.inventoryLimit.low >>> 0, message.inventoryLimit.high >>> 0).toNumber() : message.inventoryLimit;
                        return object;
                    };

                    /**
                     * Converts this Precondition to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.Precondition
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Precondition.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Precondition;
                })();

                products.ProductContext = (function() {

                    /**
                     * Properties of a ProductContext.
                     * @memberof theplant.ec.service.products
                     * @interface IProductContext
                     * @property {Array.<string>|null} [userSegmentationIdentifiers] ProductContext userSegmentationIdentifiers
                     * @property {boolean|null} [isRemoveInvisibleSale] ProductContext isRemoveInvisibleSale
                     */

                    /**
                     * Constructs a new ProductContext.
                     * @memberof theplant.ec.service.products
                     * @classdesc Represents a ProductContext.
                     * @implements IProductContext
                     * @constructor
                     * @param {theplant.ec.service.products.IProductContext=} [properties] Properties to set
                     */
                    function ProductContext(properties) {
                        this.userSegmentationIdentifiers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductContext userSegmentationIdentifiers.
                     * @member {Array.<string>} userSegmentationIdentifiers
                     * @memberof theplant.ec.service.products.ProductContext
                     * @instance
                     */
                    ProductContext.prototype.userSegmentationIdentifiers = $util.emptyArray;

                    /**
                     * ProductContext isRemoveInvisibleSale.
                     * @member {boolean} isRemoveInvisibleSale
                     * @memberof theplant.ec.service.products.ProductContext
                     * @instance
                     */
                    ProductContext.prototype.isRemoveInvisibleSale = false;

                    /**
                     * Creates a new ProductContext instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {theplant.ec.service.products.IProductContext=} [properties] Properties to set
                     * @returns {theplant.ec.service.products.ProductContext} ProductContext instance
                     */
                    ProductContext.create = function create(properties) {
                        return new ProductContext(properties);
                    };

                    /**
                     * Encodes the specified ProductContext message. Does not implicitly {@link theplant.ec.service.products.ProductContext.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {theplant.ec.service.products.IProductContext} message ProductContext message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductContext.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.userSegmentationIdentifiers != null && message.userSegmentationIdentifiers.length)
                            for (var i = 0; i < message.userSegmentationIdentifiers.length; ++i)
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.userSegmentationIdentifiers[i]);
                        if (message.isRemoveInvisibleSale != null && message.hasOwnProperty("isRemoveInvisibleSale"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isRemoveInvisibleSale);
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductContext message, length delimited. Does not implicitly {@link theplant.ec.service.products.ProductContext.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {theplant.ec.service.products.IProductContext} message ProductContext message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductContext.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductContext message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.products.ProductContext} ProductContext
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductContext.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.products.ProductContext();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.userSegmentationIdentifiers && message.userSegmentationIdentifiers.length))
                                    message.userSegmentationIdentifiers = [];
                                message.userSegmentationIdentifiers.push(reader.string());
                                break;
                            case 2:
                                message.isRemoveInvisibleSale = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductContext message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.products.ProductContext} ProductContext
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductContext.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductContext message.
                     * @function verify
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductContext.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.userSegmentationIdentifiers != null && message.hasOwnProperty("userSegmentationIdentifiers")) {
                            if (!Array.isArray(message.userSegmentationIdentifiers))
                                return "userSegmentationIdentifiers: array expected";
                            for (var i = 0; i < message.userSegmentationIdentifiers.length; ++i)
                                if (!$util.isString(message.userSegmentationIdentifiers[i]))
                                    return "userSegmentationIdentifiers: string[] expected";
                        }
                        if (message.isRemoveInvisibleSale != null && message.hasOwnProperty("isRemoveInvisibleSale"))
                            if (typeof message.isRemoveInvisibleSale !== "boolean")
                                return "isRemoveInvisibleSale: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a ProductContext message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.products.ProductContext} ProductContext
                     */
                    ProductContext.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.products.ProductContext)
                            return object;
                        var message = new $root.theplant.ec.service.products.ProductContext();
                        if (object.userSegmentationIdentifiers) {
                            if (!Array.isArray(object.userSegmentationIdentifiers))
                                throw TypeError(".theplant.ec.service.products.ProductContext.userSegmentationIdentifiers: array expected");
                            message.userSegmentationIdentifiers = [];
                            for (var i = 0; i < object.userSegmentationIdentifiers.length; ++i)
                                message.userSegmentationIdentifiers[i] = String(object.userSegmentationIdentifiers[i]);
                        }
                        if (object.isRemoveInvisibleSale != null)
                            message.isRemoveInvisibleSale = Boolean(object.isRemoveInvisibleSale);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductContext message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.products.ProductContext
                     * @static
                     * @param {theplant.ec.service.products.ProductContext} message ProductContext
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductContext.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.userSegmentationIdentifiers = [];
                        if (options.defaults)
                            object.isRemoveInvisibleSale = false;
                        if (message.userSegmentationIdentifiers && message.userSegmentationIdentifiers.length) {
                            object.userSegmentationIdentifiers = [];
                            for (var j = 0; j < message.userSegmentationIdentifiers.length; ++j)
                                object.userSegmentationIdentifiers[j] = message.userSegmentationIdentifiers[j];
                        }
                        if (message.isRemoveInvisibleSale != null && message.hasOwnProperty("isRemoveInvisibleSale"))
                            object.isRemoveInvisibleSale = message.isRemoveInvisibleSale;
                        return object;
                    };

                    /**
                     * Converts this ProductContext to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.products.ProductContext
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductContext.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductContext;
                })();

                return products;
            })();

            service.breadcrumb = (function() {

                /**
                 * Namespace breadcrumb.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var breadcrumb = {};

                breadcrumb.BreadcrumbItem = (function() {

                    /**
                     * Properties of a BreadcrumbItem.
                     * @memberof theplant.ec.service.breadcrumb
                     * @interface IBreadcrumbItem
                     * @property {string|null} [title] BreadcrumbItem title
                     * @property {string|null} [url] BreadcrumbItem url
                     */

                    /**
                     * Constructs a new BreadcrumbItem.
                     * @memberof theplant.ec.service.breadcrumb
                     * @classdesc Represents a BreadcrumbItem.
                     * @implements IBreadcrumbItem
                     * @constructor
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumbItem=} [properties] Properties to set
                     */
                    function BreadcrumbItem(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * BreadcrumbItem title.
                     * @member {string} title
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @instance
                     */
                    BreadcrumbItem.prototype.title = "";

                    /**
                     * BreadcrumbItem url.
                     * @member {string} url
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @instance
                     */
                    BreadcrumbItem.prototype.url = "";

                    /**
                     * Creates a new BreadcrumbItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumbItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.breadcrumb.BreadcrumbItem} BreadcrumbItem instance
                     */
                    BreadcrumbItem.create = function create(properties) {
                        return new BreadcrumbItem(properties);
                    };

                    /**
                     * Encodes the specified BreadcrumbItem message. Does not implicitly {@link theplant.ec.service.breadcrumb.BreadcrumbItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumbItem} message BreadcrumbItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BreadcrumbItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                        if (message.url != null && message.hasOwnProperty("url"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.url);
                        return writer;
                    };

                    /**
                     * Encodes the specified BreadcrumbItem message, length delimited. Does not implicitly {@link theplant.ec.service.breadcrumb.BreadcrumbItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumbItem} message BreadcrumbItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BreadcrumbItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a BreadcrumbItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.breadcrumb.BreadcrumbItem} BreadcrumbItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BreadcrumbItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.breadcrumb.BreadcrumbItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.title = reader.string();
                                break;
                            case 2:
                                message.url = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a BreadcrumbItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.breadcrumb.BreadcrumbItem} BreadcrumbItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BreadcrumbItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a BreadcrumbItem message.
                     * @function verify
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BreadcrumbItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        return null;
                    };

                    /**
                     * Creates a BreadcrumbItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.breadcrumb.BreadcrumbItem} BreadcrumbItem
                     */
                    BreadcrumbItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.breadcrumb.BreadcrumbItem)
                            return object;
                        var message = new $root.theplant.ec.service.breadcrumb.BreadcrumbItem();
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.url != null)
                            message.url = String(object.url);
                        return message;
                    };

                    /**
                     * Creates a plain object from a BreadcrumbItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @static
                     * @param {theplant.ec.service.breadcrumb.BreadcrumbItem} message BreadcrumbItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BreadcrumbItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.title = "";
                            object.url = "";
                        }
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        return object;
                    };

                    /**
                     * Converts this BreadcrumbItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.breadcrumb.BreadcrumbItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BreadcrumbItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return BreadcrumbItem;
                })();

                breadcrumb.Breadcrumb = (function() {

                    /**
                     * Properties of a Breadcrumb.
                     * @memberof theplant.ec.service.breadcrumb
                     * @interface IBreadcrumb
                     * @property {Array.<theplant.ec.service.breadcrumb.IBreadcrumbItem>|null} [items] Breadcrumb items
                     */

                    /**
                     * Constructs a new Breadcrumb.
                     * @memberof theplant.ec.service.breadcrumb
                     * @classdesc Represents a Breadcrumb.
                     * @implements IBreadcrumb
                     * @constructor
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumb=} [properties] Properties to set
                     */
                    function Breadcrumb(properties) {
                        this.items = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Breadcrumb items.
                     * @member {Array.<theplant.ec.service.breadcrumb.IBreadcrumbItem>} items
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @instance
                     */
                    Breadcrumb.prototype.items = $util.emptyArray;

                    /**
                     * Creates a new Breadcrumb instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumb=} [properties] Properties to set
                     * @returns {theplant.ec.service.breadcrumb.Breadcrumb} Breadcrumb instance
                     */
                    Breadcrumb.create = function create(properties) {
                        return new Breadcrumb(properties);
                    };

                    /**
                     * Encodes the specified Breadcrumb message. Does not implicitly {@link theplant.ec.service.breadcrumb.Breadcrumb.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumb} message Breadcrumb message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Breadcrumb.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.items != null && message.items.length)
                            for (var i = 0; i < message.items.length; ++i)
                                $root.theplant.ec.service.breadcrumb.BreadcrumbItem.encode(message.items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Breadcrumb message, length delimited. Does not implicitly {@link theplant.ec.service.breadcrumb.Breadcrumb.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {theplant.ec.service.breadcrumb.IBreadcrumb} message Breadcrumb message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Breadcrumb.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Breadcrumb message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.breadcrumb.Breadcrumb} Breadcrumb
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Breadcrumb.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.breadcrumb.Breadcrumb();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.theplant.ec.service.breadcrumb.BreadcrumbItem.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Breadcrumb message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.breadcrumb.Breadcrumb} Breadcrumb
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Breadcrumb.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Breadcrumb message.
                     * @function verify
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Breadcrumb.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.items != null && message.hasOwnProperty("items")) {
                            if (!Array.isArray(message.items))
                                return "items: array expected";
                            for (var i = 0; i < message.items.length; ++i) {
                                var error = $root.theplant.ec.service.breadcrumb.BreadcrumbItem.verify(message.items[i]);
                                if (error)
                                    return "items." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Breadcrumb message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.breadcrumb.Breadcrumb} Breadcrumb
                     */
                    Breadcrumb.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.breadcrumb.Breadcrumb)
                            return object;
                        var message = new $root.theplant.ec.service.breadcrumb.Breadcrumb();
                        if (object.items) {
                            if (!Array.isArray(object.items))
                                throw TypeError(".theplant.ec.service.breadcrumb.Breadcrumb.items: array expected");
                            message.items = [];
                            for (var i = 0; i < object.items.length; ++i) {
                                if (typeof object.items[i] !== "object")
                                    throw TypeError(".theplant.ec.service.breadcrumb.Breadcrumb.items: object expected");
                                message.items[i] = $root.theplant.ec.service.breadcrumb.BreadcrumbItem.fromObject(object.items[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Breadcrumb message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @static
                     * @param {theplant.ec.service.breadcrumb.Breadcrumb} message Breadcrumb
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Breadcrumb.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.items = [];
                        if (message.items && message.items.length) {
                            object.items = [];
                            for (var j = 0; j < message.items.length; ++j)
                                object.items[j] = $root.theplant.ec.service.breadcrumb.BreadcrumbItem.toObject(message.items[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Breadcrumb to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.breadcrumb.Breadcrumb
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Breadcrumb.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Breadcrumb;
                })();

                return breadcrumb;
            })();

            service.productslisting = (function() {

                /**
                 * Namespace productslisting.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var productslisting = {};

                productslisting.ProductListing = (function() {

                    /**
                     * Properties of a ProductListing.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IProductListing
                     * @property {string|null} [linkUrl] ProductListing linkUrl
                     * @property {theplant.ec.service.productslisting.IHeader|null} [header] ProductListing header
                     * @property {theplant.ec.service.productslisting.IFilter|null} [filter] ProductListing filter
                     * @property {theplant.ec.service.productslisting.IList|null} [list] ProductListing list
                     * @property {theplant.ec.service.productslisting.IPagination|null} [pagination] ProductListing pagination
                     * @property {theplant.ec.service.breadcrumb.IBreadcrumb|null} [breadcrumb] ProductListing breadcrumb
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [filterProperties] ProductListing filterProperties
                     * @property {string|null} [seoDescription] ProductListing seoDescription
                     * @property {theplant.ec.service.productslisting.IPLPPromotionBannersResult|null} [plpPromotionBanner] ProductListing plpPromotionBanner
                     */

                    /**
                     * Constructs a new ProductListing.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a ProductListing.
                     * @implements IProductListing
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IProductListing=} [properties] Properties to set
                     */
                    function ProductListing(properties) {
                        this.filterProperties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ProductListing linkUrl.
                     * @member {string} linkUrl
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.linkUrl = "";

                    /**
                     * ProductListing header.
                     * @member {theplant.ec.service.productslisting.IHeader|null|undefined} header
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.header = null;

                    /**
                     * ProductListing filter.
                     * @member {theplant.ec.service.productslisting.IFilter|null|undefined} filter
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.filter = null;

                    /**
                     * ProductListing list.
                     * @member {theplant.ec.service.productslisting.IList|null|undefined} list
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.list = null;

                    /**
                     * ProductListing pagination.
                     * @member {theplant.ec.service.productslisting.IPagination|null|undefined} pagination
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.pagination = null;

                    /**
                     * ProductListing breadcrumb.
                     * @member {theplant.ec.service.breadcrumb.IBreadcrumb|null|undefined} breadcrumb
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.breadcrumb = null;

                    /**
                     * ProductListing filterProperties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} filterProperties
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.filterProperties = $util.emptyArray;

                    /**
                     * ProductListing seoDescription.
                     * @member {string} seoDescription
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.seoDescription = "";

                    /**
                     * ProductListing plpPromotionBanner.
                     * @member {theplant.ec.service.productslisting.IPLPPromotionBannersResult|null|undefined} plpPromotionBanner
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     */
                    ProductListing.prototype.plpPromotionBanner = null;

                    /**
                     * Creates a new ProductListing instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {theplant.ec.service.productslisting.IProductListing=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.ProductListing} ProductListing instance
                     */
                    ProductListing.create = function create(properties) {
                        return new ProductListing(properties);
                    };

                    /**
                     * Encodes the specified ProductListing message. Does not implicitly {@link theplant.ec.service.productslisting.ProductListing.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {theplant.ec.service.productslisting.IProductListing} message ProductListing message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductListing.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.linkUrl);
                        if (message.header != null && message.hasOwnProperty("header"))
                            $root.theplant.ec.service.productslisting.Header.encode(message.header, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            $root.theplant.ec.service.productslisting.Filter.encode(message.filter, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.list != null && message.hasOwnProperty("list"))
                            $root.theplant.ec.service.productslisting.List.encode(message.list, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.pagination != null && message.hasOwnProperty("pagination"))
                            $root.theplant.ec.service.productslisting.Pagination.encode(message.pagination, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.breadcrumb != null && message.hasOwnProperty("breadcrumb"))
                            $root.theplant.ec.service.breadcrumb.Breadcrumb.encode(message.breadcrumb, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.filterProperties != null && message.filterProperties.length)
                            for (var i = 0; i < message.filterProperties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.filterProperties[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.seoDescription != null && message.hasOwnProperty("seoDescription"))
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.seoDescription);
                        if (message.plpPromotionBanner != null && message.hasOwnProperty("plpPromotionBanner"))
                            $root.theplant.ec.service.productslisting.PLPPromotionBannersResult.encode(message.plpPromotionBanner, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ProductListing message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.ProductListing.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {theplant.ec.service.productslisting.IProductListing} message ProductListing message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ProductListing.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ProductListing message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.ProductListing} ProductListing
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductListing.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.ProductListing();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.linkUrl = reader.string();
                                break;
                            case 2:
                                message.header = $root.theplant.ec.service.productslisting.Header.decode(reader, reader.uint32());
                                break;
                            case 3:
                                message.filter = $root.theplant.ec.service.productslisting.Filter.decode(reader, reader.uint32());
                                break;
                            case 4:
                                message.list = $root.theplant.ec.service.productslisting.List.decode(reader, reader.uint32());
                                break;
                            case 5:
                                message.pagination = $root.theplant.ec.service.productslisting.Pagination.decode(reader, reader.uint32());
                                break;
                            case 6:
                                message.breadcrumb = $root.theplant.ec.service.breadcrumb.Breadcrumb.decode(reader, reader.uint32());
                                break;
                            case 7:
                                if (!(message.filterProperties && message.filterProperties.length))
                                    message.filterProperties = [];
                                message.filterProperties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                message.seoDescription = reader.string();
                                break;
                            case 9:
                                message.plpPromotionBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannersResult.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ProductListing message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.ProductListing} ProductListing
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ProductListing.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ProductListing message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ProductListing.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            if (!$util.isString(message.linkUrl))
                                return "linkUrl: string expected";
                        if (message.header != null && message.hasOwnProperty("header")) {
                            var error = $root.theplant.ec.service.productslisting.Header.verify(message.header);
                            if (error)
                                return "header." + error;
                        }
                        if (message.filter != null && message.hasOwnProperty("filter")) {
                            var error = $root.theplant.ec.service.productslisting.Filter.verify(message.filter);
                            if (error)
                                return "filter." + error;
                        }
                        if (message.list != null && message.hasOwnProperty("list")) {
                            var error = $root.theplant.ec.service.productslisting.List.verify(message.list);
                            if (error)
                                return "list." + error;
                        }
                        if (message.pagination != null && message.hasOwnProperty("pagination")) {
                            var error = $root.theplant.ec.service.productslisting.Pagination.verify(message.pagination);
                            if (error)
                                return "pagination." + error;
                        }
                        if (message.breadcrumb != null && message.hasOwnProperty("breadcrumb")) {
                            var error = $root.theplant.ec.service.breadcrumb.Breadcrumb.verify(message.breadcrumb);
                            if (error)
                                return "breadcrumb." + error;
                        }
                        if (message.filterProperties != null && message.hasOwnProperty("filterProperties")) {
                            if (!Array.isArray(message.filterProperties))
                                return "filterProperties: array expected";
                            for (var i = 0; i < message.filterProperties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.filterProperties[i]);
                                if (error)
                                    return "filterProperties." + error;
                            }
                        }
                        if (message.seoDescription != null && message.hasOwnProperty("seoDescription"))
                            if (!$util.isString(message.seoDescription))
                                return "seoDescription: string expected";
                        if (message.plpPromotionBanner != null && message.hasOwnProperty("plpPromotionBanner")) {
                            var error = $root.theplant.ec.service.productslisting.PLPPromotionBannersResult.verify(message.plpPromotionBanner);
                            if (error)
                                return "plpPromotionBanner." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ProductListing message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.ProductListing} ProductListing
                     */
                    ProductListing.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.ProductListing)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.ProductListing();
                        if (object.linkUrl != null)
                            message.linkUrl = String(object.linkUrl);
                        if (object.header != null) {
                            if (typeof object.header !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.header: object expected");
                            message.header = $root.theplant.ec.service.productslisting.Header.fromObject(object.header);
                        }
                        if (object.filter != null) {
                            if (typeof object.filter !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.filter: object expected");
                            message.filter = $root.theplant.ec.service.productslisting.Filter.fromObject(object.filter);
                        }
                        if (object.list != null) {
                            if (typeof object.list !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.list: object expected");
                            message.list = $root.theplant.ec.service.productslisting.List.fromObject(object.list);
                        }
                        if (object.pagination != null) {
                            if (typeof object.pagination !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.pagination: object expected");
                            message.pagination = $root.theplant.ec.service.productslisting.Pagination.fromObject(object.pagination);
                        }
                        if (object.breadcrumb != null) {
                            if (typeof object.breadcrumb !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.breadcrumb: object expected");
                            message.breadcrumb = $root.theplant.ec.service.breadcrumb.Breadcrumb.fromObject(object.breadcrumb);
                        }
                        if (object.filterProperties) {
                            if (!Array.isArray(object.filterProperties))
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.filterProperties: array expected");
                            message.filterProperties = [];
                            for (var i = 0; i < object.filterProperties.length; ++i) {
                                if (typeof object.filterProperties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.ProductListing.filterProperties: object expected");
                                message.filterProperties[i] = $root.theplant.ec.service.base.Property.fromObject(object.filterProperties[i]);
                            }
                        }
                        if (object.seoDescription != null)
                            message.seoDescription = String(object.seoDescription);
                        if (object.plpPromotionBanner != null) {
                            if (typeof object.plpPromotionBanner !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.ProductListing.plpPromotionBanner: object expected");
                            message.plpPromotionBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannersResult.fromObject(object.plpPromotionBanner);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ProductListing message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @static
                     * @param {theplant.ec.service.productslisting.ProductListing} message ProductListing
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ProductListing.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.filterProperties = [];
                        if (options.defaults) {
                            object.linkUrl = "";
                            object.header = null;
                            object.filter = null;
                            object.list = null;
                            object.pagination = null;
                            object.breadcrumb = null;
                            object.seoDescription = "";
                            object.plpPromotionBanner = null;
                        }
                        if (message.linkUrl != null && message.hasOwnProperty("linkUrl"))
                            object.linkUrl = message.linkUrl;
                        if (message.header != null && message.hasOwnProperty("header"))
                            object.header = $root.theplant.ec.service.productslisting.Header.toObject(message.header, options);
                        if (message.filter != null && message.hasOwnProperty("filter"))
                            object.filter = $root.theplant.ec.service.productslisting.Filter.toObject(message.filter, options);
                        if (message.list != null && message.hasOwnProperty("list"))
                            object.list = $root.theplant.ec.service.productslisting.List.toObject(message.list, options);
                        if (message.pagination != null && message.hasOwnProperty("pagination"))
                            object.pagination = $root.theplant.ec.service.productslisting.Pagination.toObject(message.pagination, options);
                        if (message.breadcrumb != null && message.hasOwnProperty("breadcrumb"))
                            object.breadcrumb = $root.theplant.ec.service.breadcrumb.Breadcrumb.toObject(message.breadcrumb, options);
                        if (message.filterProperties && message.filterProperties.length) {
                            object.filterProperties = [];
                            for (var j = 0; j < message.filterProperties.length; ++j)
                                object.filterProperties[j] = $root.theplant.ec.service.base.Property.toObject(message.filterProperties[j], options);
                        }
                        if (message.seoDescription != null && message.hasOwnProperty("seoDescription"))
                            object.seoDescription = message.seoDescription;
                        if (message.plpPromotionBanner != null && message.hasOwnProperty("plpPromotionBanner"))
                            object.plpPromotionBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannersResult.toObject(message.plpPromotionBanner, options);
                        return object;
                    };

                    /**
                     * Converts this ProductListing to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.ProductListing
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ProductListing.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ProductListing;
                })();

                productslisting.Header = (function() {

                    /**
                     * Properties of a Header.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IHeader
                     * @property {string|null} [title] Header title
                     * @property {string|null} [description] Header description
                     * @property {string|null} [topBannerUrl] Header topBannerUrl
                     * @property {string|null} [topBannerAlignment] Header topBannerAlignment
                     * @property {string|null} [topBannerMobileUrl] Header topBannerMobileUrl
                     * @property {boolean|null} [displayLoginButton] Header displayLoginButton
                     * @property {boolean|null} [displaySignupButton] Header displaySignupButton
                     */

                    /**
                     * Constructs a new Header.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a Header.
                     * @implements IHeader
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IHeader=} [properties] Properties to set
                     */
                    function Header(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Header title.
                     * @member {string} title
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.title = "";

                    /**
                     * Header description.
                     * @member {string} description
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.description = "";

                    /**
                     * Header topBannerUrl.
                     * @member {string} topBannerUrl
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.topBannerUrl = "";

                    /**
                     * Header topBannerAlignment.
                     * @member {string} topBannerAlignment
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.topBannerAlignment = "";

                    /**
                     * Header topBannerMobileUrl.
                     * @member {string} topBannerMobileUrl
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.topBannerMobileUrl = "";

                    /**
                     * Header displayLoginButton.
                     * @member {boolean} displayLoginButton
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.displayLoginButton = false;

                    /**
                     * Header displaySignupButton.
                     * @member {boolean} displaySignupButton
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     */
                    Header.prototype.displaySignupButton = false;

                    /**
                     * Creates a new Header instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {theplant.ec.service.productslisting.IHeader=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.Header} Header instance
                     */
                    Header.create = function create(properties) {
                        return new Header(properties);
                    };

                    /**
                     * Encodes the specified Header message. Does not implicitly {@link theplant.ec.service.productslisting.Header.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {theplant.ec.service.productslisting.IHeader} message Header message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Header.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                        if (message.topBannerUrl != null && message.hasOwnProperty("topBannerUrl"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.topBannerUrl);
                        if (message.topBannerAlignment != null && message.hasOwnProperty("topBannerAlignment"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.topBannerAlignment);
                        if (message.topBannerMobileUrl != null && message.hasOwnProperty("topBannerMobileUrl"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.topBannerMobileUrl);
                        if (message.displayLoginButton != null && message.hasOwnProperty("displayLoginButton"))
                            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.displayLoginButton);
                        if (message.displaySignupButton != null && message.hasOwnProperty("displaySignupButton"))
                            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.displaySignupButton);
                        return writer;
                    };

                    /**
                     * Encodes the specified Header message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.Header.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {theplant.ec.service.productslisting.IHeader} message Header message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Header.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Header message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.Header} Header
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Header.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.Header();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.title = reader.string();
                                break;
                            case 2:
                                message.description = reader.string();
                                break;
                            case 3:
                                message.topBannerUrl = reader.string();
                                break;
                            case 4:
                                message.topBannerAlignment = reader.string();
                                break;
                            case 5:
                                message.topBannerMobileUrl = reader.string();
                                break;
                            case 6:
                                message.displayLoginButton = reader.bool();
                                break;
                            case 7:
                                message.displaySignupButton = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Header message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.Header} Header
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Header.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Header message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Header.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.topBannerUrl != null && message.hasOwnProperty("topBannerUrl"))
                            if (!$util.isString(message.topBannerUrl))
                                return "topBannerUrl: string expected";
                        if (message.topBannerAlignment != null && message.hasOwnProperty("topBannerAlignment"))
                            if (!$util.isString(message.topBannerAlignment))
                                return "topBannerAlignment: string expected";
                        if (message.topBannerMobileUrl != null && message.hasOwnProperty("topBannerMobileUrl"))
                            if (!$util.isString(message.topBannerMobileUrl))
                                return "topBannerMobileUrl: string expected";
                        if (message.displayLoginButton != null && message.hasOwnProperty("displayLoginButton"))
                            if (typeof message.displayLoginButton !== "boolean")
                                return "displayLoginButton: boolean expected";
                        if (message.displaySignupButton != null && message.hasOwnProperty("displaySignupButton"))
                            if (typeof message.displaySignupButton !== "boolean")
                                return "displaySignupButton: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a Header message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.Header} Header
                     */
                    Header.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.Header)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.Header();
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.topBannerUrl != null)
                            message.topBannerUrl = String(object.topBannerUrl);
                        if (object.topBannerAlignment != null)
                            message.topBannerAlignment = String(object.topBannerAlignment);
                        if (object.topBannerMobileUrl != null)
                            message.topBannerMobileUrl = String(object.topBannerMobileUrl);
                        if (object.displayLoginButton != null)
                            message.displayLoginButton = Boolean(object.displayLoginButton);
                        if (object.displaySignupButton != null)
                            message.displaySignupButton = Boolean(object.displaySignupButton);
                        return message;
                    };

                    /**
                     * Creates a plain object from a Header message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.Header
                     * @static
                     * @param {theplant.ec.service.productslisting.Header} message Header
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Header.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.title = "";
                            object.description = "";
                            object.topBannerUrl = "";
                            object.topBannerAlignment = "";
                            object.topBannerMobileUrl = "";
                            object.displayLoginButton = false;
                            object.displaySignupButton = false;
                        }
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.topBannerUrl != null && message.hasOwnProperty("topBannerUrl"))
                            object.topBannerUrl = message.topBannerUrl;
                        if (message.topBannerAlignment != null && message.hasOwnProperty("topBannerAlignment"))
                            object.topBannerAlignment = message.topBannerAlignment;
                        if (message.topBannerMobileUrl != null && message.hasOwnProperty("topBannerMobileUrl"))
                            object.topBannerMobileUrl = message.topBannerMobileUrl;
                        if (message.displayLoginButton != null && message.hasOwnProperty("displayLoginButton"))
                            object.displayLoginButton = message.displayLoginButton;
                        if (message.displaySignupButton != null && message.hasOwnProperty("displaySignupButton"))
                            object.displaySignupButton = message.displaySignupButton;
                        return object;
                    };

                    /**
                     * Converts this Header to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.Header
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Header.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Header;
                })();

                productslisting.Filter = (function() {

                    /**
                     * Properties of a Filter.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IFilter
                     * @property {Array.<theplant.ec.service.productslisting.IFilterGroup>|null} [filterGroups] Filter filterGroups
                     * @property {Array.<theplant.ec.service.productslisting.ISorterOption>|null} [sorterOptions] Filter sorterOptions
                     */

                    /**
                     * Constructs a new Filter.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a Filter.
                     * @implements IFilter
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IFilter=} [properties] Properties to set
                     */
                    function Filter(properties) {
                        this.filterGroups = [];
                        this.sorterOptions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Filter filterGroups.
                     * @member {Array.<theplant.ec.service.productslisting.IFilterGroup>} filterGroups
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @instance
                     */
                    Filter.prototype.filterGroups = $util.emptyArray;

                    /**
                     * Filter sorterOptions.
                     * @member {Array.<theplant.ec.service.productslisting.ISorterOption>} sorterOptions
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @instance
                     */
                    Filter.prototype.sorterOptions = $util.emptyArray;

                    /**
                     * Creates a new Filter instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilter=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.Filter} Filter instance
                     */
                    Filter.create = function create(properties) {
                        return new Filter(properties);
                    };

                    /**
                     * Encodes the specified Filter message. Does not implicitly {@link theplant.ec.service.productslisting.Filter.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilter} message Filter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Filter.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.filterGroups != null && message.filterGroups.length)
                            for (var i = 0; i < message.filterGroups.length; ++i)
                                $root.theplant.ec.service.productslisting.FilterGroup.encode(message.filterGroups[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.sorterOptions != null && message.sorterOptions.length)
                            for (var i = 0; i < message.sorterOptions.length; ++i)
                                $root.theplant.ec.service.productslisting.SorterOption.encode(message.sorterOptions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified Filter message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.Filter.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilter} message Filter message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Filter.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Filter message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.Filter} Filter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Filter.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.Filter();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.filterGroups && message.filterGroups.length))
                                    message.filterGroups = [];
                                message.filterGroups.push($root.theplant.ec.service.productslisting.FilterGroup.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.sorterOptions && message.sorterOptions.length))
                                    message.sorterOptions = [];
                                message.sorterOptions.push($root.theplant.ec.service.productslisting.SorterOption.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Filter message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.Filter} Filter
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Filter.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Filter message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Filter.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.filterGroups != null && message.hasOwnProperty("filterGroups")) {
                            if (!Array.isArray(message.filterGroups))
                                return "filterGroups: array expected";
                            for (var i = 0; i < message.filterGroups.length; ++i) {
                                var error = $root.theplant.ec.service.productslisting.FilterGroup.verify(message.filterGroups[i]);
                                if (error)
                                    return "filterGroups." + error;
                            }
                        }
                        if (message.sorterOptions != null && message.hasOwnProperty("sorterOptions")) {
                            if (!Array.isArray(message.sorterOptions))
                                return "sorterOptions: array expected";
                            for (var i = 0; i < message.sorterOptions.length; ++i) {
                                var error = $root.theplant.ec.service.productslisting.SorterOption.verify(message.sorterOptions[i]);
                                if (error)
                                    return "sorterOptions." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a Filter message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.Filter} Filter
                     */
                    Filter.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.Filter)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.Filter();
                        if (object.filterGroups) {
                            if (!Array.isArray(object.filterGroups))
                                throw TypeError(".theplant.ec.service.productslisting.Filter.filterGroups: array expected");
                            message.filterGroups = [];
                            for (var i = 0; i < object.filterGroups.length; ++i) {
                                if (typeof object.filterGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.Filter.filterGroups: object expected");
                                message.filterGroups[i] = $root.theplant.ec.service.productslisting.FilterGroup.fromObject(object.filterGroups[i]);
                            }
                        }
                        if (object.sorterOptions) {
                            if (!Array.isArray(object.sorterOptions))
                                throw TypeError(".theplant.ec.service.productslisting.Filter.sorterOptions: array expected");
                            message.sorterOptions = [];
                            for (var i = 0; i < object.sorterOptions.length; ++i) {
                                if (typeof object.sorterOptions[i] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.Filter.sorterOptions: object expected");
                                message.sorterOptions[i] = $root.theplant.ec.service.productslisting.SorterOption.fromObject(object.sorterOptions[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a Filter message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @static
                     * @param {theplant.ec.service.productslisting.Filter} message Filter
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Filter.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.filterGroups = [];
                            object.sorterOptions = [];
                        }
                        if (message.filterGroups && message.filterGroups.length) {
                            object.filterGroups = [];
                            for (var j = 0; j < message.filterGroups.length; ++j)
                                object.filterGroups[j] = $root.theplant.ec.service.productslisting.FilterGroup.toObject(message.filterGroups[j], options);
                        }
                        if (message.sorterOptions && message.sorterOptions.length) {
                            object.sorterOptions = [];
                            for (var j = 0; j < message.sorterOptions.length; ++j)
                                object.sorterOptions[j] = $root.theplant.ec.service.productslisting.SorterOption.toObject(message.sorterOptions[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this Filter to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.Filter
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Filter.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Filter;
                })();

                productslisting.List = (function() {

                    /**
                     * Properties of a List.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IList
                     * @property {Array.<theplant.ec.service.products.IProduct>|null} [products] List products
                     * @property {Array.<theplant.ec.service.referencedata.IRDGroup>|null} [rdGroups] List rdGroups
                     */

                    /**
                     * Constructs a new List.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a List.
                     * @implements IList
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IList=} [properties] Properties to set
                     */
                    function List(properties) {
                        this.products = [];
                        this.rdGroups = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * List products.
                     * @member {Array.<theplant.ec.service.products.IProduct>} products
                     * @memberof theplant.ec.service.productslisting.List
                     * @instance
                     */
                    List.prototype.products = $util.emptyArray;

                    /**
                     * List rdGroups.
                     * @member {Array.<theplant.ec.service.referencedata.IRDGroup>} rdGroups
                     * @memberof theplant.ec.service.productslisting.List
                     * @instance
                     */
                    List.prototype.rdGroups = $util.emptyArray;

                    /**
                     * Creates a new List instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {theplant.ec.service.productslisting.IList=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.List} List instance
                     */
                    List.create = function create(properties) {
                        return new List(properties);
                    };

                    /**
                     * Encodes the specified List message. Does not implicitly {@link theplant.ec.service.productslisting.List.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {theplant.ec.service.productslisting.IList} message List message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    List.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.products != null && message.products.length)
                            for (var i = 0; i < message.products.length; ++i)
                                $root.theplant.ec.service.products.Product.encode(message.products[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.rdGroups != null && message.rdGroups.length)
                            for (var i = 0; i < message.rdGroups.length; ++i)
                                $root.theplant.ec.service.referencedata.RDGroup.encode(message.rdGroups[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified List message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.List.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {theplant.ec.service.productslisting.IList} message List message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    List.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a List message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.List} List
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    List.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.List();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.products && message.products.length))
                                    message.products = [];
                                message.products.push($root.theplant.ec.service.products.Product.decode(reader, reader.uint32()));
                                break;
                            case 2:
                                if (!(message.rdGroups && message.rdGroups.length))
                                    message.rdGroups = [];
                                message.rdGroups.push($root.theplant.ec.service.referencedata.RDGroup.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a List message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.List} List
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    List.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a List message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    List.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.products != null && message.hasOwnProperty("products")) {
                            if (!Array.isArray(message.products))
                                return "products: array expected";
                            for (var i = 0; i < message.products.length; ++i) {
                                var error = $root.theplant.ec.service.products.Product.verify(message.products[i]);
                                if (error)
                                    return "products." + error;
                            }
                        }
                        if (message.rdGroups != null && message.hasOwnProperty("rdGroups")) {
                            if (!Array.isArray(message.rdGroups))
                                return "rdGroups: array expected";
                            for (var i = 0; i < message.rdGroups.length; ++i) {
                                var error = $root.theplant.ec.service.referencedata.RDGroup.verify(message.rdGroups[i]);
                                if (error)
                                    return "rdGroups." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a List message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.List} List
                     */
                    List.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.List)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.List();
                        if (object.products) {
                            if (!Array.isArray(object.products))
                                throw TypeError(".theplant.ec.service.productslisting.List.products: array expected");
                            message.products = [];
                            for (var i = 0; i < object.products.length; ++i) {
                                if (typeof object.products[i] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.List.products: object expected");
                                message.products[i] = $root.theplant.ec.service.products.Product.fromObject(object.products[i]);
                            }
                        }
                        if (object.rdGroups) {
                            if (!Array.isArray(object.rdGroups))
                                throw TypeError(".theplant.ec.service.productslisting.List.rdGroups: array expected");
                            message.rdGroups = [];
                            for (var i = 0; i < object.rdGroups.length; ++i) {
                                if (typeof object.rdGroups[i] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.List.rdGroups: object expected");
                                message.rdGroups[i] = $root.theplant.ec.service.referencedata.RDGroup.fromObject(object.rdGroups[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a List message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.List
                     * @static
                     * @param {theplant.ec.service.productslisting.List} message List
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    List.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.products = [];
                            object.rdGroups = [];
                        }
                        if (message.products && message.products.length) {
                            object.products = [];
                            for (var j = 0; j < message.products.length; ++j)
                                object.products[j] = $root.theplant.ec.service.products.Product.toObject(message.products[j], options);
                        }
                        if (message.rdGroups && message.rdGroups.length) {
                            object.rdGroups = [];
                            for (var j = 0; j < message.rdGroups.length; ++j)
                                object.rdGroups[j] = $root.theplant.ec.service.referencedata.RDGroup.toObject(message.rdGroups[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this List to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.List
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    List.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return List;
                })();

                productslisting.Pagination = (function() {

                    /**
                     * Properties of a Pagination.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IPagination
                     * @property {number|Long|null} [page] Pagination page
                     * @property {number|Long|null} [perPage] Pagination perPage
                     * @property {number|Long|null} [total] Pagination total
                     */

                    /**
                     * Constructs a new Pagination.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a Pagination.
                     * @implements IPagination
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IPagination=} [properties] Properties to set
                     */
                    function Pagination(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * Pagination page.
                     * @member {number|Long} page
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @instance
                     */
                    Pagination.prototype.page = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Pagination perPage.
                     * @member {number|Long} perPage
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @instance
                     */
                    Pagination.prototype.perPage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Pagination total.
                     * @member {number|Long} total
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @instance
                     */
                    Pagination.prototype.total = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new Pagination instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {theplant.ec.service.productslisting.IPagination=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.Pagination} Pagination instance
                     */
                    Pagination.create = function create(properties) {
                        return new Pagination(properties);
                    };

                    /**
                     * Encodes the specified Pagination message. Does not implicitly {@link theplant.ec.service.productslisting.Pagination.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {theplant.ec.service.productslisting.IPagination} message Pagination message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Pagination.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.page != null && message.hasOwnProperty("page"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int64(message.page);
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.perPage);
                        if (message.total != null && message.hasOwnProperty("total"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.total);
                        return writer;
                    };

                    /**
                     * Encodes the specified Pagination message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.Pagination.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {theplant.ec.service.productslisting.IPagination} message Pagination message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    Pagination.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a Pagination message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.Pagination} Pagination
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Pagination.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.Pagination();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.page = reader.int64();
                                break;
                            case 2:
                                message.perPage = reader.int64();
                                break;
                            case 3:
                                message.total = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a Pagination message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.Pagination} Pagination
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    Pagination.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a Pagination message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    Pagination.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (!$util.isInteger(message.page) && !(message.page && $util.isInteger(message.page.low) && $util.isInteger(message.page.high)))
                                return "page: integer|Long expected";
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (!$util.isInteger(message.perPage) && !(message.perPage && $util.isInteger(message.perPage.low) && $util.isInteger(message.perPage.high)))
                                return "perPage: integer|Long expected";
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (!$util.isInteger(message.total) && !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high)))
                                return "total: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a Pagination message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.Pagination} Pagination
                     */
                    Pagination.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.Pagination)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.Pagination();
                        if (object.page != null)
                            if ($util.Long)
                                (message.page = $util.Long.fromValue(object.page)).unsigned = false;
                            else if (typeof object.page === "string")
                                message.page = parseInt(object.page, 10);
                            else if (typeof object.page === "number")
                                message.page = object.page;
                            else if (typeof object.page === "object")
                                message.page = new $util.LongBits(object.page.low >>> 0, object.page.high >>> 0).toNumber();
                        if (object.perPage != null)
                            if ($util.Long)
                                (message.perPage = $util.Long.fromValue(object.perPage)).unsigned = false;
                            else if (typeof object.perPage === "string")
                                message.perPage = parseInt(object.perPage, 10);
                            else if (typeof object.perPage === "number")
                                message.perPage = object.perPage;
                            else if (typeof object.perPage === "object")
                                message.perPage = new $util.LongBits(object.perPage.low >>> 0, object.perPage.high >>> 0).toNumber();
                        if (object.total != null)
                            if ($util.Long)
                                (message.total = $util.Long.fromValue(object.total)).unsigned = false;
                            else if (typeof object.total === "string")
                                message.total = parseInt(object.total, 10);
                            else if (typeof object.total === "number")
                                message.total = object.total;
                            else if (typeof object.total === "object")
                                message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a Pagination message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @static
                     * @param {theplant.ec.service.productslisting.Pagination} message Pagination
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    Pagination.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.page = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.page = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.perPage = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.perPage = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.total = options.longs === String ? "0" : 0;
                        }
                        if (message.page != null && message.hasOwnProperty("page"))
                            if (typeof message.page === "number")
                                object.page = options.longs === String ? String(message.page) : message.page;
                            else
                                object.page = options.longs === String ? $util.Long.prototype.toString.call(message.page) : options.longs === Number ? new $util.LongBits(message.page.low >>> 0, message.page.high >>> 0).toNumber() : message.page;
                        if (message.perPage != null && message.hasOwnProperty("perPage"))
                            if (typeof message.perPage === "number")
                                object.perPage = options.longs === String ? String(message.perPage) : message.perPage;
                            else
                                object.perPage = options.longs === String ? $util.Long.prototype.toString.call(message.perPage) : options.longs === Number ? new $util.LongBits(message.perPage.low >>> 0, message.perPage.high >>> 0).toNumber() : message.perPage;
                        if (message.total != null && message.hasOwnProperty("total"))
                            if (typeof message.total === "number")
                                object.total = options.longs === String ? String(message.total) : message.total;
                            else
                                object.total = options.longs === String ? $util.Long.prototype.toString.call(message.total) : options.longs === Number ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber() : message.total;
                        return object;
                    };

                    /**
                     * Converts this Pagination to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.Pagination
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    Pagination.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return Pagination;
                })();

                productslisting.FilterGroup = (function() {

                    /**
                     * Properties of a FilterGroup.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IFilterGroup
                     * @property {theplant.ec.service.referencedata.IRDItem|null} [info] FilterGroup info
                     * @property {Array.<theplant.ec.service.productslisting.IFilterGroupOption>|null} [filterOptions] FilterGroup filterOptions
                     */

                    /**
                     * Constructs a new FilterGroup.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a FilterGroup.
                     * @implements IFilterGroup
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IFilterGroup=} [properties] Properties to set
                     */
                    function FilterGroup(properties) {
                        this.filterOptions = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterGroup info.
                     * @member {theplant.ec.service.referencedata.IRDItem|null|undefined} info
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @instance
                     */
                    FilterGroup.prototype.info = null;

                    /**
                     * FilterGroup filterOptions.
                     * @member {Array.<theplant.ec.service.productslisting.IFilterGroupOption>} filterOptions
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @instance
                     */
                    FilterGroup.prototype.filterOptions = $util.emptyArray;

                    /**
                     * Creates a new FilterGroup instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilterGroup=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.FilterGroup} FilterGroup instance
                     */
                    FilterGroup.create = function create(properties) {
                        return new FilterGroup(properties);
                    };

                    /**
                     * Encodes the specified FilterGroup message. Does not implicitly {@link theplant.ec.service.productslisting.FilterGroup.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilterGroup} message FilterGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterGroup.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.info != null && message.hasOwnProperty("info"))
                            $root.theplant.ec.service.referencedata.RDItem.encode(message.info, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.filterOptions != null && message.filterOptions.length)
                            for (var i = 0; i < message.filterOptions.length; ++i)
                                $root.theplant.ec.service.productslisting.FilterGroupOption.encode(message.filterOptions[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterGroup message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.FilterGroup.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilterGroup} message FilterGroup message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterGroup.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterGroup message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.FilterGroup} FilterGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterGroup.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.FilterGroup();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.info = $root.theplant.ec.service.referencedata.RDItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                if (!(message.filterOptions && message.filterOptions.length))
                                    message.filterOptions = [];
                                message.filterOptions.push($root.theplant.ec.service.productslisting.FilterGroupOption.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterGroup message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.FilterGroup} FilterGroup
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterGroup.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterGroup message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterGroup.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.info != null && message.hasOwnProperty("info")) {
                            var error = $root.theplant.ec.service.referencedata.RDItem.verify(message.info);
                            if (error)
                                return "info." + error;
                        }
                        if (message.filterOptions != null && message.hasOwnProperty("filterOptions")) {
                            if (!Array.isArray(message.filterOptions))
                                return "filterOptions: array expected";
                            for (var i = 0; i < message.filterOptions.length; ++i) {
                                var error = $root.theplant.ec.service.productslisting.FilterGroupOption.verify(message.filterOptions[i]);
                                if (error)
                                    return "filterOptions." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a FilterGroup message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.FilterGroup} FilterGroup
                     */
                    FilterGroup.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.FilterGroup)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.FilterGroup();
                        if (object.info != null) {
                            if (typeof object.info !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.FilterGroup.info: object expected");
                            message.info = $root.theplant.ec.service.referencedata.RDItem.fromObject(object.info);
                        }
                        if (object.filterOptions) {
                            if (!Array.isArray(object.filterOptions))
                                throw TypeError(".theplant.ec.service.productslisting.FilterGroup.filterOptions: array expected");
                            message.filterOptions = [];
                            for (var i = 0; i < object.filterOptions.length; ++i) {
                                if (typeof object.filterOptions[i] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.FilterGroup.filterOptions: object expected");
                                message.filterOptions[i] = $root.theplant.ec.service.productslisting.FilterGroupOption.fromObject(object.filterOptions[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterGroup message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @static
                     * @param {theplant.ec.service.productslisting.FilterGroup} message FilterGroup
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterGroup.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.filterOptions = [];
                        if (options.defaults)
                            object.info = null;
                        if (message.info != null && message.hasOwnProperty("info"))
                            object.info = $root.theplant.ec.service.referencedata.RDItem.toObject(message.info, options);
                        if (message.filterOptions && message.filterOptions.length) {
                            object.filterOptions = [];
                            for (var j = 0; j < message.filterOptions.length; ++j)
                                object.filterOptions[j] = $root.theplant.ec.service.productslisting.FilterGroupOption.toObject(message.filterOptions[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this FilterGroup to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.FilterGroup
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterGroup.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterGroup;
                })();

                productslisting.FilterGroupOption = (function() {

                    /**
                     * Properties of a FilterGroupOption.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IFilterGroupOption
                     * @property {theplant.ec.service.referencedata.IRDItem|null} [item] FilterGroupOption item
                     * @property {boolean|null} [selected] FilterGroupOption selected
                     * @property {number|Long|null} [count] FilterGroupOption count
                     */

                    /**
                     * Constructs a new FilterGroupOption.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a FilterGroupOption.
                     * @implements IFilterGroupOption
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IFilterGroupOption=} [properties] Properties to set
                     */
                    function FilterGroupOption(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * FilterGroupOption item.
                     * @member {theplant.ec.service.referencedata.IRDItem|null|undefined} item
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @instance
                     */
                    FilterGroupOption.prototype.item = null;

                    /**
                     * FilterGroupOption selected.
                     * @member {boolean} selected
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @instance
                     */
                    FilterGroupOption.prototype.selected = false;

                    /**
                     * FilterGroupOption count.
                     * @member {number|Long} count
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @instance
                     */
                    FilterGroupOption.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new FilterGroupOption instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilterGroupOption=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.FilterGroupOption} FilterGroupOption instance
                     */
                    FilterGroupOption.create = function create(properties) {
                        return new FilterGroupOption(properties);
                    };

                    /**
                     * Encodes the specified FilterGroupOption message. Does not implicitly {@link theplant.ec.service.productslisting.FilterGroupOption.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilterGroupOption} message FilterGroupOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterGroupOption.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.item != null && message.hasOwnProperty("item"))
                            $root.theplant.ec.service.referencedata.RDItem.encode(message.item, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.selected);
                        if (message.count != null && message.hasOwnProperty("count"))
                            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.count);
                        return writer;
                    };

                    /**
                     * Encodes the specified FilterGroupOption message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.FilterGroupOption.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {theplant.ec.service.productslisting.IFilterGroupOption} message FilterGroupOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    FilterGroupOption.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a FilterGroupOption message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.FilterGroupOption} FilterGroupOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterGroupOption.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.FilterGroupOption();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.item = $root.theplant.ec.service.referencedata.RDItem.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.selected = reader.bool();
                                break;
                            case 3:
                                message.count = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a FilterGroupOption message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.FilterGroupOption} FilterGroupOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    FilterGroupOption.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a FilterGroupOption message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    FilterGroupOption.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.item != null && message.hasOwnProperty("item")) {
                            var error = $root.theplant.ec.service.referencedata.RDItem.verify(message.item);
                            if (error)
                                return "item." + error;
                        }
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            if (typeof message.selected !== "boolean")
                                return "selected: boolean expected";
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (!$util.isInteger(message.count) && !(message.count && $util.isInteger(message.count.low) && $util.isInteger(message.count.high)))
                                return "count: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a FilterGroupOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.FilterGroupOption} FilterGroupOption
                     */
                    FilterGroupOption.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.FilterGroupOption)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.FilterGroupOption();
                        if (object.item != null) {
                            if (typeof object.item !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.FilterGroupOption.item: object expected");
                            message.item = $root.theplant.ec.service.referencedata.RDItem.fromObject(object.item);
                        }
                        if (object.selected != null)
                            message.selected = Boolean(object.selected);
                        if (object.count != null)
                            if ($util.Long)
                                (message.count = $util.Long.fromValue(object.count)).unsigned = false;
                            else if (typeof object.count === "string")
                                message.count = parseInt(object.count, 10);
                            else if (typeof object.count === "number")
                                message.count = object.count;
                            else if (typeof object.count === "object")
                                message.count = new $util.LongBits(object.count.low >>> 0, object.count.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a FilterGroupOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @static
                     * @param {theplant.ec.service.productslisting.FilterGroupOption} message FilterGroupOption
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    FilterGroupOption.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.item = null;
                            object.selected = false;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.count = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.count = options.longs === String ? "0" : 0;
                        }
                        if (message.item != null && message.hasOwnProperty("item"))
                            object.item = $root.theplant.ec.service.referencedata.RDItem.toObject(message.item, options);
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            object.selected = message.selected;
                        if (message.count != null && message.hasOwnProperty("count"))
                            if (typeof message.count === "number")
                                object.count = options.longs === String ? String(message.count) : message.count;
                            else
                                object.count = options.longs === String ? $util.Long.prototype.toString.call(message.count) : options.longs === Number ? new $util.LongBits(message.count.low >>> 0, message.count.high >>> 0).toNumber() : message.count;
                        return object;
                    };

                    /**
                     * Converts this FilterGroupOption to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.FilterGroupOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    FilterGroupOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return FilterGroupOption;
                })();

                productslisting.SorterOption = (function() {

                    /**
                     * Properties of a SorterOption.
                     * @memberof theplant.ec.service.productslisting
                     * @interface ISorterOption
                     * @property {string|null} [name] SorterOption name
                     * @property {string|null} [code] SorterOption code
                     * @property {boolean|null} [selected] SorterOption selected
                     */

                    /**
                     * Constructs a new SorterOption.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a SorterOption.
                     * @implements ISorterOption
                     * @constructor
                     * @param {theplant.ec.service.productslisting.ISorterOption=} [properties] Properties to set
                     */
                    function SorterOption(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SorterOption name.
                     * @member {string} name
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @instance
                     */
                    SorterOption.prototype.name = "";

                    /**
                     * SorterOption code.
                     * @member {string} code
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @instance
                     */
                    SorterOption.prototype.code = "";

                    /**
                     * SorterOption selected.
                     * @member {boolean} selected
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @instance
                     */
                    SorterOption.prototype.selected = false;

                    /**
                     * Creates a new SorterOption instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {theplant.ec.service.productslisting.ISorterOption=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.SorterOption} SorterOption instance
                     */
                    SorterOption.create = function create(properties) {
                        return new SorterOption(properties);
                    };

                    /**
                     * Encodes the specified SorterOption message. Does not implicitly {@link theplant.ec.service.productslisting.SorterOption.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {theplant.ec.service.productslisting.ISorterOption} message SorterOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SorterOption.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.name != null && message.hasOwnProperty("name"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                        if (message.code != null && message.hasOwnProperty("code"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.selected);
                        return writer;
                    };

                    /**
                     * Encodes the specified SorterOption message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.SorterOption.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {theplant.ec.service.productslisting.ISorterOption} message SorterOption message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SorterOption.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SorterOption message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.SorterOption} SorterOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SorterOption.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.SorterOption();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.name = reader.string();
                                break;
                            case 2:
                                message.code = reader.string();
                                break;
                            case 3:
                                message.selected = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SorterOption message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.SorterOption} SorterOption
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SorterOption.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SorterOption message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SorterOption.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.name != null && message.hasOwnProperty("name"))
                            if (!$util.isString(message.name))
                                return "name: string expected";
                        if (message.code != null && message.hasOwnProperty("code"))
                            if (!$util.isString(message.code))
                                return "code: string expected";
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            if (typeof message.selected !== "boolean")
                                return "selected: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a SorterOption message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.SorterOption} SorterOption
                     */
                    SorterOption.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.SorterOption)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.SorterOption();
                        if (object.name != null)
                            message.name = String(object.name);
                        if (object.code != null)
                            message.code = String(object.code);
                        if (object.selected != null)
                            message.selected = Boolean(object.selected);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SorterOption message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @static
                     * @param {theplant.ec.service.productslisting.SorterOption} message SorterOption
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SorterOption.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.name = "";
                            object.code = "";
                            object.selected = false;
                        }
                        if (message.name != null && message.hasOwnProperty("name"))
                            object.name = message.name;
                        if (message.code != null && message.hasOwnProperty("code"))
                            object.code = message.code;
                        if (message.selected != null && message.hasOwnProperty("selected"))
                            object.selected = message.selected;
                        return object;
                    };

                    /**
                     * Converts this SorterOption to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.SorterOption
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SorterOption.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SorterOption;
                })();

                productslisting.PLPPromotionBannerImageBanner = (function() {

                    /**
                     * Properties of a PLPPromotionBannerImageBanner.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IPLPPromotionBannerImageBanner
                     * @property {string|null} [image] PLPPromotionBannerImageBanner image
                     * @property {string|null} [title] PLPPromotionBannerImageBanner title
                     * @property {string|null} [description] PLPPromotionBannerImageBanner description
                     * @property {string|null} [link] PLPPromotionBannerImageBanner link
                     * @property {string|null} [textColor] PLPPromotionBannerImageBanner textColor
                     * @property {string|null} [position] PLPPromotionBannerImageBanner position
                     */

                    /**
                     * Constructs a new PLPPromotionBannerImageBanner.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a PLPPromotionBannerImageBanner.
                     * @implements IPLPPromotionBannerImageBanner
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerImageBanner=} [properties] Properties to set
                     */
                    function PLPPromotionBannerImageBanner(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PLPPromotionBannerImageBanner image.
                     * @member {string} image
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     */
                    PLPPromotionBannerImageBanner.prototype.image = "";

                    /**
                     * PLPPromotionBannerImageBanner title.
                     * @member {string} title
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     */
                    PLPPromotionBannerImageBanner.prototype.title = "";

                    /**
                     * PLPPromotionBannerImageBanner description.
                     * @member {string} description
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     */
                    PLPPromotionBannerImageBanner.prototype.description = "";

                    /**
                     * PLPPromotionBannerImageBanner link.
                     * @member {string} link
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     */
                    PLPPromotionBannerImageBanner.prototype.link = "";

                    /**
                     * PLPPromotionBannerImageBanner textColor.
                     * @member {string} textColor
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     */
                    PLPPromotionBannerImageBanner.prototype.textColor = "";

                    /**
                     * PLPPromotionBannerImageBanner position.
                     * @member {string} position
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     */
                    PLPPromotionBannerImageBanner.prototype.position = "";

                    /**
                     * Creates a new PLPPromotionBannerImageBanner instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerImageBanner=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerImageBanner} PLPPromotionBannerImageBanner instance
                     */
                    PLPPromotionBannerImageBanner.create = function create(properties) {
                        return new PLPPromotionBannerImageBanner(properties);
                    };

                    /**
                     * Encodes the specified PLPPromotionBannerImageBanner message. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerImageBanner} message PLPPromotionBannerImageBanner message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBannerImageBanner.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.image != null && message.hasOwnProperty("image"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.image);
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.title);
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                        if (message.link != null && message.hasOwnProperty("link"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.link);
                        if (message.textColor != null && message.hasOwnProperty("textColor"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.textColor);
                        if (message.position != null && message.hasOwnProperty("position"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.position);
                        return writer;
                    };

                    /**
                     * Encodes the specified PLPPromotionBannerImageBanner message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerImageBanner} message PLPPromotionBannerImageBanner message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBannerImageBanner.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PLPPromotionBannerImageBanner message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerImageBanner} PLPPromotionBannerImageBanner
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBannerImageBanner.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.image = reader.string();
                                break;
                            case 2:
                                message.title = reader.string();
                                break;
                            case 3:
                                message.description = reader.string();
                                break;
                            case 4:
                                message.link = reader.string();
                                break;
                            case 5:
                                message.textColor = reader.string();
                                break;
                            case 6:
                                message.position = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PLPPromotionBannerImageBanner message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerImageBanner} PLPPromotionBannerImageBanner
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBannerImageBanner.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PLPPromotionBannerImageBanner message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PLPPromotionBannerImageBanner.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.image != null && message.hasOwnProperty("image"))
                            if (!$util.isString(message.image))
                                return "image: string expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.link != null && message.hasOwnProperty("link"))
                            if (!$util.isString(message.link))
                                return "link: string expected";
                        if (message.textColor != null && message.hasOwnProperty("textColor"))
                            if (!$util.isString(message.textColor))
                                return "textColor: string expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isString(message.position))
                                return "position: string expected";
                        return null;
                    };

                    /**
                     * Creates a PLPPromotionBannerImageBanner message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerImageBanner} PLPPromotionBannerImageBanner
                     */
                    PLPPromotionBannerImageBanner.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner();
                        if (object.image != null)
                            message.image = String(object.image);
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.link != null)
                            message.link = String(object.link);
                        if (object.textColor != null)
                            message.textColor = String(object.textColor);
                        if (object.position != null)
                            message.position = String(object.position);
                        return message;
                    };

                    /**
                     * Creates a plain object from a PLPPromotionBannerImageBanner message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.PLPPromotionBannerImageBanner} message PLPPromotionBannerImageBanner
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PLPPromotionBannerImageBanner.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.image = "";
                            object.title = "";
                            object.description = "";
                            object.link = "";
                            object.textColor = "";
                            object.position = "";
                        }
                        if (message.image != null && message.hasOwnProperty("image"))
                            object.image = message.image;
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.link != null && message.hasOwnProperty("link"))
                            object.link = message.link;
                        if (message.textColor != null && message.hasOwnProperty("textColor"))
                            object.textColor = message.textColor;
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = message.position;
                        return object;
                    };

                    /**
                     * Converts this PLPPromotionBannerImageBanner to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerImageBanner
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PLPPromotionBannerImageBanner.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PLPPromotionBannerImageBanner;
                })();

                productslisting.PLPPromotionBannerVideoBanner = (function() {

                    /**
                     * Properties of a PLPPromotionBannerVideoBanner.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IPLPPromotionBannerVideoBanner
                     * @property {string|null} [url] PLPPromotionBannerVideoBanner url
                     * @property {string|null} [position] PLPPromotionBannerVideoBanner position
                     * @property {boolean|null} [mute] PLPPromotionBannerVideoBanner mute
                     * @property {boolean|null} [autoPlay] PLPPromotionBannerVideoBanner autoPlay
                     */

                    /**
                     * Constructs a new PLPPromotionBannerVideoBanner.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a PLPPromotionBannerVideoBanner.
                     * @implements IPLPPromotionBannerVideoBanner
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerVideoBanner=} [properties] Properties to set
                     */
                    function PLPPromotionBannerVideoBanner(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PLPPromotionBannerVideoBanner url.
                     * @member {string} url
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @instance
                     */
                    PLPPromotionBannerVideoBanner.prototype.url = "";

                    /**
                     * PLPPromotionBannerVideoBanner position.
                     * @member {string} position
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @instance
                     */
                    PLPPromotionBannerVideoBanner.prototype.position = "";

                    /**
                     * PLPPromotionBannerVideoBanner mute.
                     * @member {boolean} mute
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @instance
                     */
                    PLPPromotionBannerVideoBanner.prototype.mute = false;

                    /**
                     * PLPPromotionBannerVideoBanner autoPlay.
                     * @member {boolean} autoPlay
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @instance
                     */
                    PLPPromotionBannerVideoBanner.prototype.autoPlay = false;

                    /**
                     * Creates a new PLPPromotionBannerVideoBanner instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerVideoBanner=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner} PLPPromotionBannerVideoBanner instance
                     */
                    PLPPromotionBannerVideoBanner.create = function create(properties) {
                        return new PLPPromotionBannerVideoBanner(properties);
                    };

                    /**
                     * Encodes the specified PLPPromotionBannerVideoBanner message. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerVideoBanner} message PLPPromotionBannerVideoBanner message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBannerVideoBanner.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.url != null && message.hasOwnProperty("url"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                        if (message.position != null && message.hasOwnProperty("position"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.position);
                        if (message.mute != null && message.hasOwnProperty("mute"))
                            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.mute);
                        if (message.autoPlay != null && message.hasOwnProperty("autoPlay"))
                            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.autoPlay);
                        return writer;
                    };

                    /**
                     * Encodes the specified PLPPromotionBannerVideoBanner message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannerVideoBanner} message PLPPromotionBannerVideoBanner message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBannerVideoBanner.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PLPPromotionBannerVideoBanner message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner} PLPPromotionBannerVideoBanner
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBannerVideoBanner.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.url = reader.string();
                                break;
                            case 2:
                                message.position = reader.string();
                                break;
                            case 3:
                                message.mute = reader.bool();
                                break;
                            case 4:
                                message.autoPlay = reader.bool();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PLPPromotionBannerVideoBanner message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner} PLPPromotionBannerVideoBanner
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBannerVideoBanner.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PLPPromotionBannerVideoBanner message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PLPPromotionBannerVideoBanner.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        if (message.position != null && message.hasOwnProperty("position"))
                            if (!$util.isString(message.position))
                                return "position: string expected";
                        if (message.mute != null && message.hasOwnProperty("mute"))
                            if (typeof message.mute !== "boolean")
                                return "mute: boolean expected";
                        if (message.autoPlay != null && message.hasOwnProperty("autoPlay"))
                            if (typeof message.autoPlay !== "boolean")
                                return "autoPlay: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a PLPPromotionBannerVideoBanner message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner} PLPPromotionBannerVideoBanner
                     */
                    PLPPromotionBannerVideoBanner.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner();
                        if (object.url != null)
                            message.url = String(object.url);
                        if (object.position != null)
                            message.position = String(object.position);
                        if (object.mute != null)
                            message.mute = Boolean(object.mute);
                        if (object.autoPlay != null)
                            message.autoPlay = Boolean(object.autoPlay);
                        return message;
                    };

                    /**
                     * Creates a plain object from a PLPPromotionBannerVideoBanner message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner} message PLPPromotionBannerVideoBanner
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PLPPromotionBannerVideoBanner.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.url = "";
                            object.position = "";
                            object.mute = false;
                            object.autoPlay = false;
                        }
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        if (message.position != null && message.hasOwnProperty("position"))
                            object.position = message.position;
                        if (message.mute != null && message.hasOwnProperty("mute"))
                            object.mute = message.mute;
                        if (message.autoPlay != null && message.hasOwnProperty("autoPlay"))
                            object.autoPlay = message.autoPlay;
                        return object;
                    };

                    /**
                     * Converts this PLPPromotionBannerVideoBanner to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PLPPromotionBannerVideoBanner.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PLPPromotionBannerVideoBanner;
                })();

                productslisting.PLPPromotionBanner = (function() {

                    /**
                     * Properties of a PLPPromotionBanner.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IPLPPromotionBanner
                     * @property {theplant.ec.service.productslisting.IPLPPromotionBannerImageBanner|null} [imageBanner] PLPPromotionBanner imageBanner
                     * @property {theplant.ec.service.productslisting.IPLPPromotionBannerVideoBanner|null} [videoBanner] PLPPromotionBanner videoBanner
                     */

                    /**
                     * Constructs a new PLPPromotionBanner.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a PLPPromotionBanner.
                     * @implements IPLPPromotionBanner
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBanner=} [properties] Properties to set
                     */
                    function PLPPromotionBanner(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PLPPromotionBanner imageBanner.
                     * @member {theplant.ec.service.productslisting.IPLPPromotionBannerImageBanner|null|undefined} imageBanner
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @instance
                     */
                    PLPPromotionBanner.prototype.imageBanner = null;

                    /**
                     * PLPPromotionBanner videoBanner.
                     * @member {theplant.ec.service.productslisting.IPLPPromotionBannerVideoBanner|null|undefined} videoBanner
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @instance
                     */
                    PLPPromotionBanner.prototype.videoBanner = null;

                    /**
                     * Creates a new PLPPromotionBanner instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBanner=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBanner} PLPPromotionBanner instance
                     */
                    PLPPromotionBanner.create = function create(properties) {
                        return new PLPPromotionBanner(properties);
                    };

                    /**
                     * Encodes the specified PLPPromotionBanner message. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBanner.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBanner} message PLPPromotionBanner message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBanner.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.imageBanner != null && message.hasOwnProperty("imageBanner"))
                            $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.encode(message.imageBanner, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.videoBanner != null && message.hasOwnProperty("videoBanner"))
                            $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.encode(message.videoBanner, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified PLPPromotionBanner message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBanner.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBanner} message PLPPromotionBanner message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBanner.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PLPPromotionBanner message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBanner} PLPPromotionBanner
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBanner.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.PLPPromotionBanner();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.imageBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.decode(reader, reader.uint32());
                                break;
                            case 2:
                                message.videoBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PLPPromotionBanner message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBanner} PLPPromotionBanner
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBanner.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PLPPromotionBanner message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PLPPromotionBanner.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.imageBanner != null && message.hasOwnProperty("imageBanner")) {
                            var error = $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.verify(message.imageBanner);
                            if (error)
                                return "imageBanner." + error;
                        }
                        if (message.videoBanner != null && message.hasOwnProperty("videoBanner")) {
                            var error = $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.verify(message.videoBanner);
                            if (error)
                                return "videoBanner." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a PLPPromotionBanner message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBanner} PLPPromotionBanner
                     */
                    PLPPromotionBanner.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.PLPPromotionBanner)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.PLPPromotionBanner();
                        if (object.imageBanner != null) {
                            if (typeof object.imageBanner !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.PLPPromotionBanner.imageBanner: object expected");
                            message.imageBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.fromObject(object.imageBanner);
                        }
                        if (object.videoBanner != null) {
                            if (typeof object.videoBanner !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.PLPPromotionBanner.videoBanner: object expected");
                            message.videoBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.fromObject(object.videoBanner);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PLPPromotionBanner message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @static
                     * @param {theplant.ec.service.productslisting.PLPPromotionBanner} message PLPPromotionBanner
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PLPPromotionBanner.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.imageBanner = null;
                            object.videoBanner = null;
                        }
                        if (message.imageBanner != null && message.hasOwnProperty("imageBanner"))
                            object.imageBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannerImageBanner.toObject(message.imageBanner, options);
                        if (message.videoBanner != null && message.hasOwnProperty("videoBanner"))
                            object.videoBanner = $root.theplant.ec.service.productslisting.PLPPromotionBannerVideoBanner.toObject(message.videoBanner, options);
                        return object;
                    };

                    /**
                     * Converts this PLPPromotionBanner to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBanner
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PLPPromotionBanner.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PLPPromotionBanner;
                })();

                productslisting.PLPPromotionBannersResult = (function() {

                    /**
                     * Properties of a PLPPromotionBannersResult.
                     * @memberof theplant.ec.service.productslisting
                     * @interface IPLPPromotionBannersResult
                     * @property {Object.<string,theplant.ec.service.productslisting.IPLPPromotionBanner>|null} [bannerMap] PLPPromotionBannersResult bannerMap
                     */

                    /**
                     * Constructs a new PLPPromotionBannersResult.
                     * @memberof theplant.ec.service.productslisting
                     * @classdesc Represents a PLPPromotionBannersResult.
                     * @implements IPLPPromotionBannersResult
                     * @constructor
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannersResult=} [properties] Properties to set
                     */
                    function PLPPromotionBannersResult(properties) {
                        this.bannerMap = {};
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * PLPPromotionBannersResult bannerMap.
                     * @member {Object.<string,theplant.ec.service.productslisting.IPLPPromotionBanner>} bannerMap
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @instance
                     */
                    PLPPromotionBannersResult.prototype.bannerMap = $util.emptyObject;

                    /**
                     * Creates a new PLPPromotionBannersResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannersResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannersResult} PLPPromotionBannersResult instance
                     */
                    PLPPromotionBannersResult.create = function create(properties) {
                        return new PLPPromotionBannersResult(properties);
                    };

                    /**
                     * Encodes the specified PLPPromotionBannersResult message. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBannersResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannersResult} message PLPPromotionBannersResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBannersResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.bannerMap != null && message.hasOwnProperty("bannerMap"))
                            for (var keys = Object.keys(message.bannerMap), i = 0; i < keys.length; ++i) {
                                writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 0 =*/8).int64(keys[i]);
                                $root.theplant.ec.service.productslisting.PLPPromotionBanner.encode(message.bannerMap[keys[i]], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim().ldelim();
                            }
                        return writer;
                    };

                    /**
                     * Encodes the specified PLPPromotionBannersResult message, length delimited. Does not implicitly {@link theplant.ec.service.productslisting.PLPPromotionBannersResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {theplant.ec.service.productslisting.IPLPPromotionBannersResult} message PLPPromotionBannersResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    PLPPromotionBannersResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a PLPPromotionBannersResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannersResult} PLPPromotionBannersResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBannersResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.productslisting.PLPPromotionBannersResult(), key;
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                reader.skip().pos++;
                                if (message.bannerMap === $util.emptyObject)
                                    message.bannerMap = {};
                                key = reader.int64();
                                reader.pos++;
                                message.bannerMap[typeof key === "object" ? $util.longToHash(key) : key] = $root.theplant.ec.service.productslisting.PLPPromotionBanner.decode(reader, reader.uint32());
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a PLPPromotionBannersResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannersResult} PLPPromotionBannersResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    PLPPromotionBannersResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a PLPPromotionBannersResult message.
                     * @function verify
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    PLPPromotionBannersResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.bannerMap != null && message.hasOwnProperty("bannerMap")) {
                            if (!$util.isObject(message.bannerMap))
                                return "bannerMap: object expected";
                            var key = Object.keys(message.bannerMap);
                            for (var i = 0; i < key.length; ++i) {
                                if (!$util.key64Re.test(key[i]))
                                    return "bannerMap: integer|Long key{k:int64} expected";
                                {
                                    var error = $root.theplant.ec.service.productslisting.PLPPromotionBanner.verify(message.bannerMap[key[i]]);
                                    if (error)
                                        return "bannerMap." + error;
                                }
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a PLPPromotionBannersResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.productslisting.PLPPromotionBannersResult} PLPPromotionBannersResult
                     */
                    PLPPromotionBannersResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.productslisting.PLPPromotionBannersResult)
                            return object;
                        var message = new $root.theplant.ec.service.productslisting.PLPPromotionBannersResult();
                        if (object.bannerMap) {
                            if (typeof object.bannerMap !== "object")
                                throw TypeError(".theplant.ec.service.productslisting.PLPPromotionBannersResult.bannerMap: object expected");
                            message.bannerMap = {};
                            for (var keys = Object.keys(object.bannerMap), i = 0; i < keys.length; ++i) {
                                if (typeof object.bannerMap[keys[i]] !== "object")
                                    throw TypeError(".theplant.ec.service.productslisting.PLPPromotionBannersResult.bannerMap: object expected");
                                message.bannerMap[keys[i]] = $root.theplant.ec.service.productslisting.PLPPromotionBanner.fromObject(object.bannerMap[keys[i]]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a PLPPromotionBannersResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @static
                     * @param {theplant.ec.service.productslisting.PLPPromotionBannersResult} message PLPPromotionBannersResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    PLPPromotionBannersResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.objects || options.defaults)
                            object.bannerMap = {};
                        var keys2;
                        if (message.bannerMap && (keys2 = Object.keys(message.bannerMap)).length) {
                            object.bannerMap = {};
                            for (var j = 0; j < keys2.length; ++j)
                                object.bannerMap[keys2[j]] = $root.theplant.ec.service.productslisting.PLPPromotionBanner.toObject(message.bannerMap[keys2[j]], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this PLPPromotionBannersResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.productslisting.PLPPromotionBannersResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    PLPPromotionBannersResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return PLPPromotionBannersResult;
                })();

                return productslisting;
            })();

            service.rollingtext = (function() {

                /**
                 * Namespace rollingtext.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var rollingtext = {};

                rollingtext.RollingTexts = (function() {

                    /**
                     * Properties of a RollingTexts.
                     * @memberof theplant.ec.service.rollingtext
                     * @interface IRollingTexts
                     * @property {Array.<theplant.ec.service.rollingtext.IRollingText>|null} [rollingText] RollingTexts rollingText
                     */

                    /**
                     * Constructs a new RollingTexts.
                     * @memberof theplant.ec.service.rollingtext
                     * @classdesc Represents a RollingTexts.
                     * @implements IRollingTexts
                     * @constructor
                     * @param {theplant.ec.service.rollingtext.IRollingTexts=} [properties] Properties to set
                     */
                    function RollingTexts(properties) {
                        this.rollingText = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RollingTexts rollingText.
                     * @member {Array.<theplant.ec.service.rollingtext.IRollingText>} rollingText
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @instance
                     */
                    RollingTexts.prototype.rollingText = $util.emptyArray;

                    /**
                     * Creates a new RollingTexts instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {theplant.ec.service.rollingtext.IRollingTexts=} [properties] Properties to set
                     * @returns {theplant.ec.service.rollingtext.RollingTexts} RollingTexts instance
                     */
                    RollingTexts.create = function create(properties) {
                        return new RollingTexts(properties);
                    };

                    /**
                     * Encodes the specified RollingTexts message. Does not implicitly {@link theplant.ec.service.rollingtext.RollingTexts.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {theplant.ec.service.rollingtext.IRollingTexts} message RollingTexts message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RollingTexts.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.rollingText != null && message.rollingText.length)
                            for (var i = 0; i < message.rollingText.length; ++i)
                                $root.theplant.ec.service.rollingtext.RollingText.encode(message.rollingText[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RollingTexts message, length delimited. Does not implicitly {@link theplant.ec.service.rollingtext.RollingTexts.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {theplant.ec.service.rollingtext.IRollingTexts} message RollingTexts message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RollingTexts.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RollingTexts message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.rollingtext.RollingTexts} RollingTexts
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RollingTexts.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.rollingtext.RollingTexts();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                if (!(message.rollingText && message.rollingText.length))
                                    message.rollingText = [];
                                message.rollingText.push($root.theplant.ec.service.rollingtext.RollingText.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RollingTexts message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.rollingtext.RollingTexts} RollingTexts
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RollingTexts.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RollingTexts message.
                     * @function verify
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RollingTexts.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.rollingText != null && message.hasOwnProperty("rollingText")) {
                            if (!Array.isArray(message.rollingText))
                                return "rollingText: array expected";
                            for (var i = 0; i < message.rollingText.length; ++i) {
                                var error = $root.theplant.ec.service.rollingtext.RollingText.verify(message.rollingText[i]);
                                if (error)
                                    return "rollingText." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a RollingTexts message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.rollingtext.RollingTexts} RollingTexts
                     */
                    RollingTexts.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.rollingtext.RollingTexts)
                            return object;
                        var message = new $root.theplant.ec.service.rollingtext.RollingTexts();
                        if (object.rollingText) {
                            if (!Array.isArray(object.rollingText))
                                throw TypeError(".theplant.ec.service.rollingtext.RollingTexts.rollingText: array expected");
                            message.rollingText = [];
                            for (var i = 0; i < object.rollingText.length; ++i) {
                                if (typeof object.rollingText[i] !== "object")
                                    throw TypeError(".theplant.ec.service.rollingtext.RollingTexts.rollingText: object expected");
                                message.rollingText[i] = $root.theplant.ec.service.rollingtext.RollingText.fromObject(object.rollingText[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RollingTexts message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @static
                     * @param {theplant.ec.service.rollingtext.RollingTexts} message RollingTexts
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RollingTexts.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.rollingText = [];
                        if (message.rollingText && message.rollingText.length) {
                            object.rollingText = [];
                            for (var j = 0; j < message.rollingText.length; ++j)
                                object.rollingText[j] = $root.theplant.ec.service.rollingtext.RollingText.toObject(message.rollingText[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this RollingTexts to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.rollingtext.RollingTexts
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RollingTexts.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RollingTexts;
                })();

                rollingtext.RollingText = (function() {

                    /**
                     * Properties of a RollingText.
                     * @memberof theplant.ec.service.rollingtext
                     * @interface IRollingText
                     * @property {string|null} [contentHtml] RollingText contentHtml
                     * @property {boolean|null} [isShowInPlpAndPdp] RollingText isShowInPlpAndPdp
                     * @property {Array.<theplant.ec.service.base.IImage>|null} [images] RollingText images
                     */

                    /**
                     * Constructs a new RollingText.
                     * @memberof theplant.ec.service.rollingtext
                     * @classdesc Represents a RollingText.
                     * @implements IRollingText
                     * @constructor
                     * @param {theplant.ec.service.rollingtext.IRollingText=} [properties] Properties to set
                     */
                    function RollingText(properties) {
                        this.images = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * RollingText contentHtml.
                     * @member {string} contentHtml
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @instance
                     */
                    RollingText.prototype.contentHtml = "";

                    /**
                     * RollingText isShowInPlpAndPdp.
                     * @member {boolean} isShowInPlpAndPdp
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @instance
                     */
                    RollingText.prototype.isShowInPlpAndPdp = false;

                    /**
                     * RollingText images.
                     * @member {Array.<theplant.ec.service.base.IImage>} images
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @instance
                     */
                    RollingText.prototype.images = $util.emptyArray;

                    /**
                     * Creates a new RollingText instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {theplant.ec.service.rollingtext.IRollingText=} [properties] Properties to set
                     * @returns {theplant.ec.service.rollingtext.RollingText} RollingText instance
                     */
                    RollingText.create = function create(properties) {
                        return new RollingText(properties);
                    };

                    /**
                     * Encodes the specified RollingText message. Does not implicitly {@link theplant.ec.service.rollingtext.RollingText.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {theplant.ec.service.rollingtext.IRollingText} message RollingText message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RollingText.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.contentHtml != null && message.hasOwnProperty("contentHtml"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.contentHtml);
                        if (message.isShowInPlpAndPdp != null && message.hasOwnProperty("isShowInPlpAndPdp"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isShowInPlpAndPdp);
                        if (message.images != null && message.images.length)
                            for (var i = 0; i < message.images.length; ++i)
                                $root.theplant.ec.service.base.Image.encode(message.images[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified RollingText message, length delimited. Does not implicitly {@link theplant.ec.service.rollingtext.RollingText.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {theplant.ec.service.rollingtext.IRollingText} message RollingText message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    RollingText.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a RollingText message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.rollingtext.RollingText} RollingText
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RollingText.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.rollingtext.RollingText();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.contentHtml = reader.string();
                                break;
                            case 2:
                                message.isShowInPlpAndPdp = reader.bool();
                                break;
                            case 3:
                                if (!(message.images && message.images.length))
                                    message.images = [];
                                message.images.push($root.theplant.ec.service.base.Image.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a RollingText message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.rollingtext.RollingText} RollingText
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    RollingText.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a RollingText message.
                     * @function verify
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    RollingText.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.contentHtml != null && message.hasOwnProperty("contentHtml"))
                            if (!$util.isString(message.contentHtml))
                                return "contentHtml: string expected";
                        if (message.isShowInPlpAndPdp != null && message.hasOwnProperty("isShowInPlpAndPdp"))
                            if (typeof message.isShowInPlpAndPdp !== "boolean")
                                return "isShowInPlpAndPdp: boolean expected";
                        if (message.images != null && message.hasOwnProperty("images")) {
                            if (!Array.isArray(message.images))
                                return "images: array expected";
                            for (var i = 0; i < message.images.length; ++i) {
                                var error = $root.theplant.ec.service.base.Image.verify(message.images[i]);
                                if (error)
                                    return "images." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a RollingText message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.rollingtext.RollingText} RollingText
                     */
                    RollingText.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.rollingtext.RollingText)
                            return object;
                        var message = new $root.theplant.ec.service.rollingtext.RollingText();
                        if (object.contentHtml != null)
                            message.contentHtml = String(object.contentHtml);
                        if (object.isShowInPlpAndPdp != null)
                            message.isShowInPlpAndPdp = Boolean(object.isShowInPlpAndPdp);
                        if (object.images) {
                            if (!Array.isArray(object.images))
                                throw TypeError(".theplant.ec.service.rollingtext.RollingText.images: array expected");
                            message.images = [];
                            for (var i = 0; i < object.images.length; ++i) {
                                if (typeof object.images[i] !== "object")
                                    throw TypeError(".theplant.ec.service.rollingtext.RollingText.images: object expected");
                                message.images[i] = $root.theplant.ec.service.base.Image.fromObject(object.images[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a RollingText message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @static
                     * @param {theplant.ec.service.rollingtext.RollingText} message RollingText
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    RollingText.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.images = [];
                        if (options.defaults) {
                            object.contentHtml = "";
                            object.isShowInPlpAndPdp = false;
                        }
                        if (message.contentHtml != null && message.hasOwnProperty("contentHtml"))
                            object.contentHtml = message.contentHtml;
                        if (message.isShowInPlpAndPdp != null && message.hasOwnProperty("isShowInPlpAndPdp"))
                            object.isShowInPlpAndPdp = message.isShowInPlpAndPdp;
                        if (message.images && message.images.length) {
                            object.images = [];
                            for (var j = 0; j < message.images.length; ++j)
                                object.images[j] = $root.theplant.ec.service.base.Image.toObject(message.images[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this RollingText to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.rollingtext.RollingText
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    RollingText.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return RollingText;
                })();

                return rollingtext;
            })();

            service.seo = (function() {

                /**
                 * Namespace seo.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var seo = {};

                seo.SEOResult = (function() {

                    /**
                     * Properties of a SEOResult.
                     * @memberof theplant.ec.service.seo
                     * @interface ISEOResult
                     * @property {string|null} [title] SEOResult title
                     * @property {string|null} [keywords] SEOResult keywords
                     * @property {string|null} [description] SEOResult description
                     * @property {string|null} [openGraphUrl] SEOResult openGraphUrl
                     * @property {string|null} [openGraphType] SEOResult openGraphType
                     * @property {string|null} [openGraphImageUrl] SEOResult openGraphImageUrl
                     * @property {string|null} [openGraphImage] SEOResult openGraphImage
                     * @property {Array.<theplant.ec.service.seo.IOpenGraphMetadata>|null} [openGraphMetadata] SEOResult openGraphMetadata
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [properties] SEOResult properties
                     */

                    /**
                     * Constructs a new SEOResult.
                     * @memberof theplant.ec.service.seo
                     * @classdesc Represents a SEOResult.
                     * @implements ISEOResult
                     * @constructor
                     * @param {theplant.ec.service.seo.ISEOResult=} [properties] Properties to set
                     */
                    function SEOResult(properties) {
                        this.openGraphMetadata = [];
                        this.properties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SEOResult title.
                     * @member {string} title
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.title = "";

                    /**
                     * SEOResult keywords.
                     * @member {string} keywords
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.keywords = "";

                    /**
                     * SEOResult description.
                     * @member {string} description
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.description = "";

                    /**
                     * SEOResult openGraphUrl.
                     * @member {string} openGraphUrl
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.openGraphUrl = "";

                    /**
                     * SEOResult openGraphType.
                     * @member {string} openGraphType
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.openGraphType = "";

                    /**
                     * SEOResult openGraphImageUrl.
                     * @member {string} openGraphImageUrl
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.openGraphImageUrl = "";

                    /**
                     * SEOResult openGraphImage.
                     * @member {string} openGraphImage
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.openGraphImage = "";

                    /**
                     * SEOResult openGraphMetadata.
                     * @member {Array.<theplant.ec.service.seo.IOpenGraphMetadata>} openGraphMetadata
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.openGraphMetadata = $util.emptyArray;

                    /**
                     * SEOResult properties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} properties
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     */
                    SEOResult.prototype.properties = $util.emptyArray;

                    /**
                     * Creates a new SEOResult instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {theplant.ec.service.seo.ISEOResult=} [properties] Properties to set
                     * @returns {theplant.ec.service.seo.SEOResult} SEOResult instance
                     */
                    SEOResult.create = function create(properties) {
                        return new SEOResult(properties);
                    };

                    /**
                     * Encodes the specified SEOResult message. Does not implicitly {@link theplant.ec.service.seo.SEOResult.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {theplant.ec.service.seo.ISEOResult} message SEOResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SEOResult.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.title != null && message.hasOwnProperty("title"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                        if (message.keywords != null && message.hasOwnProperty("keywords"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.keywords);
                        if (message.description != null && message.hasOwnProperty("description"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
                        if (message.openGraphUrl != null && message.hasOwnProperty("openGraphUrl"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.openGraphUrl);
                        if (message.openGraphType != null && message.hasOwnProperty("openGraphType"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.openGraphType);
                        if (message.openGraphImageUrl != null && message.hasOwnProperty("openGraphImageUrl"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.openGraphImageUrl);
                        if (message.openGraphImage != null && message.hasOwnProperty("openGraphImage"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.openGraphImage);
                        if (message.openGraphMetadata != null && message.openGraphMetadata.length)
                            for (var i = 0; i < message.openGraphMetadata.length; ++i)
                                $root.theplant.ec.service.seo.OpenGraphMetadata.encode(message.openGraphMetadata[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.properties != null && message.properties.length)
                            for (var i = 0; i < message.properties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.properties[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified SEOResult message, length delimited. Does not implicitly {@link theplant.ec.service.seo.SEOResult.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {theplant.ec.service.seo.ISEOResult} message SEOResult message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SEOResult.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SEOResult message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.seo.SEOResult} SEOResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SEOResult.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.seo.SEOResult();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.title = reader.string();
                                break;
                            case 2:
                                message.keywords = reader.string();
                                break;
                            case 3:
                                message.description = reader.string();
                                break;
                            case 4:
                                message.openGraphUrl = reader.string();
                                break;
                            case 5:
                                message.openGraphType = reader.string();
                                break;
                            case 6:
                                message.openGraphImageUrl = reader.string();
                                break;
                            case 7:
                                message.openGraphImage = reader.string();
                                break;
                            case 8:
                                if (!(message.openGraphMetadata && message.openGraphMetadata.length))
                                    message.openGraphMetadata = [];
                                message.openGraphMetadata.push($root.theplant.ec.service.seo.OpenGraphMetadata.decode(reader, reader.uint32()));
                                break;
                            case 9:
                                if (!(message.properties && message.properties.length))
                                    message.properties = [];
                                message.properties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SEOResult message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.seo.SEOResult} SEOResult
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SEOResult.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SEOResult message.
                     * @function verify
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SEOResult.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.title != null && message.hasOwnProperty("title"))
                            if (!$util.isString(message.title))
                                return "title: string expected";
                        if (message.keywords != null && message.hasOwnProperty("keywords"))
                            if (!$util.isString(message.keywords))
                                return "keywords: string expected";
                        if (message.description != null && message.hasOwnProperty("description"))
                            if (!$util.isString(message.description))
                                return "description: string expected";
                        if (message.openGraphUrl != null && message.hasOwnProperty("openGraphUrl"))
                            if (!$util.isString(message.openGraphUrl))
                                return "openGraphUrl: string expected";
                        if (message.openGraphType != null && message.hasOwnProperty("openGraphType"))
                            if (!$util.isString(message.openGraphType))
                                return "openGraphType: string expected";
                        if (message.openGraphImageUrl != null && message.hasOwnProperty("openGraphImageUrl"))
                            if (!$util.isString(message.openGraphImageUrl))
                                return "openGraphImageUrl: string expected";
                        if (message.openGraphImage != null && message.hasOwnProperty("openGraphImage"))
                            if (!$util.isString(message.openGraphImage))
                                return "openGraphImage: string expected";
                        if (message.openGraphMetadata != null && message.hasOwnProperty("openGraphMetadata")) {
                            if (!Array.isArray(message.openGraphMetadata))
                                return "openGraphMetadata: array expected";
                            for (var i = 0; i < message.openGraphMetadata.length; ++i) {
                                var error = $root.theplant.ec.service.seo.OpenGraphMetadata.verify(message.openGraphMetadata[i]);
                                if (error)
                                    return "openGraphMetadata." + error;
                            }
                        }
                        if (message.properties != null && message.hasOwnProperty("properties")) {
                            if (!Array.isArray(message.properties))
                                return "properties: array expected";
                            for (var i = 0; i < message.properties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.properties[i]);
                                if (error)
                                    return "properties." + error;
                            }
                        }
                        return null;
                    };

                    /**
                     * Creates a SEOResult message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.seo.SEOResult} SEOResult
                     */
                    SEOResult.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.seo.SEOResult)
                            return object;
                        var message = new $root.theplant.ec.service.seo.SEOResult();
                        if (object.title != null)
                            message.title = String(object.title);
                        if (object.keywords != null)
                            message.keywords = String(object.keywords);
                        if (object.description != null)
                            message.description = String(object.description);
                        if (object.openGraphUrl != null)
                            message.openGraphUrl = String(object.openGraphUrl);
                        if (object.openGraphType != null)
                            message.openGraphType = String(object.openGraphType);
                        if (object.openGraphImageUrl != null)
                            message.openGraphImageUrl = String(object.openGraphImageUrl);
                        if (object.openGraphImage != null)
                            message.openGraphImage = String(object.openGraphImage);
                        if (object.openGraphMetadata) {
                            if (!Array.isArray(object.openGraphMetadata))
                                throw TypeError(".theplant.ec.service.seo.SEOResult.openGraphMetadata: array expected");
                            message.openGraphMetadata = [];
                            for (var i = 0; i < object.openGraphMetadata.length; ++i) {
                                if (typeof object.openGraphMetadata[i] !== "object")
                                    throw TypeError(".theplant.ec.service.seo.SEOResult.openGraphMetadata: object expected");
                                message.openGraphMetadata[i] = $root.theplant.ec.service.seo.OpenGraphMetadata.fromObject(object.openGraphMetadata[i]);
                            }
                        }
                        if (object.properties) {
                            if (!Array.isArray(object.properties))
                                throw TypeError(".theplant.ec.service.seo.SEOResult.properties: array expected");
                            message.properties = [];
                            for (var i = 0; i < object.properties.length; ++i) {
                                if (typeof object.properties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.seo.SEOResult.properties: object expected");
                                message.properties[i] = $root.theplant.ec.service.base.Property.fromObject(object.properties[i]);
                            }
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a SEOResult message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @static
                     * @param {theplant.ec.service.seo.SEOResult} message SEOResult
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SEOResult.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults) {
                            object.openGraphMetadata = [];
                            object.properties = [];
                        }
                        if (options.defaults) {
                            object.title = "";
                            object.keywords = "";
                            object.description = "";
                            object.openGraphUrl = "";
                            object.openGraphType = "";
                            object.openGraphImageUrl = "";
                            object.openGraphImage = "";
                        }
                        if (message.title != null && message.hasOwnProperty("title"))
                            object.title = message.title;
                        if (message.keywords != null && message.hasOwnProperty("keywords"))
                            object.keywords = message.keywords;
                        if (message.description != null && message.hasOwnProperty("description"))
                            object.description = message.description;
                        if (message.openGraphUrl != null && message.hasOwnProperty("openGraphUrl"))
                            object.openGraphUrl = message.openGraphUrl;
                        if (message.openGraphType != null && message.hasOwnProperty("openGraphType"))
                            object.openGraphType = message.openGraphType;
                        if (message.openGraphImageUrl != null && message.hasOwnProperty("openGraphImageUrl"))
                            object.openGraphImageUrl = message.openGraphImageUrl;
                        if (message.openGraphImage != null && message.hasOwnProperty("openGraphImage"))
                            object.openGraphImage = message.openGraphImage;
                        if (message.openGraphMetadata && message.openGraphMetadata.length) {
                            object.openGraphMetadata = [];
                            for (var j = 0; j < message.openGraphMetadata.length; ++j)
                                object.openGraphMetadata[j] = $root.theplant.ec.service.seo.OpenGraphMetadata.toObject(message.openGraphMetadata[j], options);
                        }
                        if (message.properties && message.properties.length) {
                            object.properties = [];
                            for (var j = 0; j < message.properties.length; ++j)
                                object.properties[j] = $root.theplant.ec.service.base.Property.toObject(message.properties[j], options);
                        }
                        return object;
                    };

                    /**
                     * Converts this SEOResult to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.seo.SEOResult
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SEOResult.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SEOResult;
                })();

                seo.OpenGraphMetadata = (function() {

                    /**
                     * Properties of an OpenGraphMetadata.
                     * @memberof theplant.ec.service.seo
                     * @interface IOpenGraphMetadata
                     * @property {string|null} [property] OpenGraphMetadata property
                     * @property {string|null} [content] OpenGraphMetadata content
                     */

                    /**
                     * Constructs a new OpenGraphMetadata.
                     * @memberof theplant.ec.service.seo
                     * @classdesc Represents an OpenGraphMetadata.
                     * @implements IOpenGraphMetadata
                     * @constructor
                     * @param {theplant.ec.service.seo.IOpenGraphMetadata=} [properties] Properties to set
                     */
                    function OpenGraphMetadata(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OpenGraphMetadata property.
                     * @member {string} property
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @instance
                     */
                    OpenGraphMetadata.prototype.property = "";

                    /**
                     * OpenGraphMetadata content.
                     * @member {string} content
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @instance
                     */
                    OpenGraphMetadata.prototype.content = "";

                    /**
                     * Creates a new OpenGraphMetadata instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {theplant.ec.service.seo.IOpenGraphMetadata=} [properties] Properties to set
                     * @returns {theplant.ec.service.seo.OpenGraphMetadata} OpenGraphMetadata instance
                     */
                    OpenGraphMetadata.create = function create(properties) {
                        return new OpenGraphMetadata(properties);
                    };

                    /**
                     * Encodes the specified OpenGraphMetadata message. Does not implicitly {@link theplant.ec.service.seo.OpenGraphMetadata.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {theplant.ec.service.seo.IOpenGraphMetadata} message OpenGraphMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OpenGraphMetadata.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.property != null && message.hasOwnProperty("property"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.property);
                        if (message.content != null && message.hasOwnProperty("content"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.content);
                        return writer;
                    };

                    /**
                     * Encodes the specified OpenGraphMetadata message, length delimited. Does not implicitly {@link theplant.ec.service.seo.OpenGraphMetadata.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {theplant.ec.service.seo.IOpenGraphMetadata} message OpenGraphMetadata message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OpenGraphMetadata.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OpenGraphMetadata message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.seo.OpenGraphMetadata} OpenGraphMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OpenGraphMetadata.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.seo.OpenGraphMetadata();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.property = reader.string();
                                break;
                            case 2:
                                message.content = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OpenGraphMetadata message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.seo.OpenGraphMetadata} OpenGraphMetadata
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OpenGraphMetadata.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OpenGraphMetadata message.
                     * @function verify
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OpenGraphMetadata.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.property != null && message.hasOwnProperty("property"))
                            if (!$util.isString(message.property))
                                return "property: string expected";
                        if (message.content != null && message.hasOwnProperty("content"))
                            if (!$util.isString(message.content))
                                return "content: string expected";
                        return null;
                    };

                    /**
                     * Creates an OpenGraphMetadata message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.seo.OpenGraphMetadata} OpenGraphMetadata
                     */
                    OpenGraphMetadata.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.seo.OpenGraphMetadata)
                            return object;
                        var message = new $root.theplant.ec.service.seo.OpenGraphMetadata();
                        if (object.property != null)
                            message.property = String(object.property);
                        if (object.content != null)
                            message.content = String(object.content);
                        return message;
                    };

                    /**
                     * Creates a plain object from an OpenGraphMetadata message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @static
                     * @param {theplant.ec.service.seo.OpenGraphMetadata} message OpenGraphMetadata
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OpenGraphMetadata.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.property = "";
                            object.content = "";
                        }
                        if (message.property != null && message.hasOwnProperty("property"))
                            object.property = message.property;
                        if (message.content != null && message.hasOwnProperty("content"))
                            object.content = message.content;
                        return object;
                    };

                    /**
                     * Converts this OpenGraphMetadata to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.seo.OpenGraphMetadata
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OpenGraphMetadata.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return OpenGraphMetadata;
                })();

                seo.SEOParams = (function() {

                    /**
                     * Properties of a SEOParams.
                     * @memberof theplant.ec.service.seo
                     * @interface ISEOParams
                     * @property {string|null} [url] SEOParams url
                     */

                    /**
                     * Constructs a new SEOParams.
                     * @memberof theplant.ec.service.seo
                     * @classdesc Represents a SEOParams.
                     * @implements ISEOParams
                     * @constructor
                     * @param {theplant.ec.service.seo.ISEOParams=} [properties] Properties to set
                     */
                    function SEOParams(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * SEOParams url.
                     * @member {string} url
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @instance
                     */
                    SEOParams.prototype.url = "";

                    /**
                     * Creates a new SEOParams instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {theplant.ec.service.seo.ISEOParams=} [properties] Properties to set
                     * @returns {theplant.ec.service.seo.SEOParams} SEOParams instance
                     */
                    SEOParams.create = function create(properties) {
                        return new SEOParams(properties);
                    };

                    /**
                     * Encodes the specified SEOParams message. Does not implicitly {@link theplant.ec.service.seo.SEOParams.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {theplant.ec.service.seo.ISEOParams} message SEOParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SEOParams.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.url != null && message.hasOwnProperty("url"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                        return writer;
                    };

                    /**
                     * Encodes the specified SEOParams message, length delimited. Does not implicitly {@link theplant.ec.service.seo.SEOParams.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {theplant.ec.service.seo.ISEOParams} message SEOParams message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    SEOParams.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a SEOParams message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.seo.SEOParams} SEOParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SEOParams.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.seo.SEOParams();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.url = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a SEOParams message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.seo.SEOParams} SEOParams
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    SEOParams.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a SEOParams message.
                     * @function verify
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    SEOParams.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.url != null && message.hasOwnProperty("url"))
                            if (!$util.isString(message.url))
                                return "url: string expected";
                        return null;
                    };

                    /**
                     * Creates a SEOParams message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.seo.SEOParams} SEOParams
                     */
                    SEOParams.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.seo.SEOParams)
                            return object;
                        var message = new $root.theplant.ec.service.seo.SEOParams();
                        if (object.url != null)
                            message.url = String(object.url);
                        return message;
                    };

                    /**
                     * Creates a plain object from a SEOParams message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @static
                     * @param {theplant.ec.service.seo.SEOParams} message SEOParams
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    SEOParams.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.url = "";
                        if (message.url != null && message.hasOwnProperty("url"))
                            object.url = message.url;
                        return object;
                    };

                    /**
                     * Converts this SEOParams to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.seo.SEOParams
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    SEOParams.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return SEOParams;
                })();

                return seo;
            })();

            service.zipcode = (function() {

                /**
                 * Namespace zipcode.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var zipcode = {};

                zipcode.ZipcodeAddress = (function() {

                    /**
                     * Properties of a ZipcodeAddress.
                     * @memberof theplant.ec.service.zipcode
                     * @interface IZipcodeAddress
                     * @property {string|null} [prefecture] ZipcodeAddress prefecture
                     * @property {string|null} [city] ZipcodeAddress city
                     * @property {string|null} [area] ZipcodeAddress area
                     */

                    /**
                     * Constructs a new ZipcodeAddress.
                     * @memberof theplant.ec.service.zipcode
                     * @classdesc Represents a ZipcodeAddress.
                     * @implements IZipcodeAddress
                     * @constructor
                     * @param {theplant.ec.service.zipcode.IZipcodeAddress=} [properties] Properties to set
                     */
                    function ZipcodeAddress(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ZipcodeAddress prefecture.
                     * @member {string} prefecture
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @instance
                     */
                    ZipcodeAddress.prototype.prefecture = "";

                    /**
                     * ZipcodeAddress city.
                     * @member {string} city
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @instance
                     */
                    ZipcodeAddress.prototype.city = "";

                    /**
                     * ZipcodeAddress area.
                     * @member {string} area
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @instance
                     */
                    ZipcodeAddress.prototype.area = "";

                    /**
                     * Creates a new ZipcodeAddress instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {theplant.ec.service.zipcode.IZipcodeAddress=} [properties] Properties to set
                     * @returns {theplant.ec.service.zipcode.ZipcodeAddress} ZipcodeAddress instance
                     */
                    ZipcodeAddress.create = function create(properties) {
                        return new ZipcodeAddress(properties);
                    };

                    /**
                     * Encodes the specified ZipcodeAddress message. Does not implicitly {@link theplant.ec.service.zipcode.ZipcodeAddress.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {theplant.ec.service.zipcode.IZipcodeAddress} message ZipcodeAddress message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ZipcodeAddress.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.prefecture);
                        if (message.city != null && message.hasOwnProperty("city"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
                        if (message.area != null && message.hasOwnProperty("area"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.area);
                        return writer;
                    };

                    /**
                     * Encodes the specified ZipcodeAddress message, length delimited. Does not implicitly {@link theplant.ec.service.zipcode.ZipcodeAddress.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {theplant.ec.service.zipcode.IZipcodeAddress} message ZipcodeAddress message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ZipcodeAddress.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ZipcodeAddress message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.zipcode.ZipcodeAddress} ZipcodeAddress
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ZipcodeAddress.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.zipcode.ZipcodeAddress();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.prefecture = reader.string();
                                break;
                            case 2:
                                message.city = reader.string();
                                break;
                            case 3:
                                message.area = reader.string();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ZipcodeAddress message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.zipcode.ZipcodeAddress} ZipcodeAddress
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ZipcodeAddress.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ZipcodeAddress message.
                     * @function verify
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ZipcodeAddress.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            if (!$util.isString(message.prefecture))
                                return "prefecture: string expected";
                        if (message.city != null && message.hasOwnProperty("city"))
                            if (!$util.isString(message.city))
                                return "city: string expected";
                        if (message.area != null && message.hasOwnProperty("area"))
                            if (!$util.isString(message.area))
                                return "area: string expected";
                        return null;
                    };

                    /**
                     * Creates a ZipcodeAddress message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.zipcode.ZipcodeAddress} ZipcodeAddress
                     */
                    ZipcodeAddress.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.zipcode.ZipcodeAddress)
                            return object;
                        var message = new $root.theplant.ec.service.zipcode.ZipcodeAddress();
                        if (object.prefecture != null)
                            message.prefecture = String(object.prefecture);
                        if (object.city != null)
                            message.city = String(object.city);
                        if (object.area != null)
                            message.area = String(object.area);
                        return message;
                    };

                    /**
                     * Creates a plain object from a ZipcodeAddress message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @static
                     * @param {theplant.ec.service.zipcode.ZipcodeAddress} message ZipcodeAddress
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ZipcodeAddress.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.prefecture = "";
                            object.city = "";
                            object.area = "";
                        }
                        if (message.prefecture != null && message.hasOwnProperty("prefecture"))
                            object.prefecture = message.prefecture;
                        if (message.city != null && message.hasOwnProperty("city"))
                            object.city = message.city;
                        if (message.area != null && message.hasOwnProperty("area"))
                            object.area = message.area;
                        return object;
                    };

                    /**
                     * Converts this ZipcodeAddress to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.zipcode.ZipcodeAddress
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ZipcodeAddress.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return ZipcodeAddress;
                })();

                return zipcode;
            })();

            service.wishlist = (function() {

                /**
                 * Namespace wishlist.
                 * @memberof theplant.ec.service
                 * @namespace
                 */
                var wishlist = {};

                wishlist.WishItem = (function() {

                    /**
                     * Properties of a WishItem.
                     * @memberof theplant.ec.service.wishlist
                     * @interface IWishItem
                     * @property {string|null} [articleCode] WishItem articleCode
                     * @property {string|null} [productCode] WishItem productCode
                     * @property {number|Long|null} [price] WishItem price
                     * @property {number|Long|null} [sellingPrice] WishItem sellingPrice
                     * @property {string|null} [productName] WishItem productName
                     * @property {string|null} [productImageUrl] WishItem productImageUrl
                     * @property {Array.<theplant.ec.service.base.IProperty>|null} [properties] WishItem properties
                     * @property {number|Long|null} [stock] WishItem stock
                     */

                    /**
                     * Constructs a new WishItem.
                     * @memberof theplant.ec.service.wishlist
                     * @classdesc Represents a WishItem.
                     * @implements IWishItem
                     * @constructor
                     * @param {theplant.ec.service.wishlist.IWishItem=} [properties] Properties to set
                     */
                    function WishItem(properties) {
                        this.properties = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * WishItem articleCode.
                     * @member {string} articleCode
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.articleCode = "";

                    /**
                     * WishItem productCode.
                     * @member {string} productCode
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.productCode = "";

                    /**
                     * WishItem price.
                     * @member {number|Long} price
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.price = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * WishItem sellingPrice.
                     * @member {number|Long} sellingPrice
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.sellingPrice = $util.Long ? $util.Long.fromBits(0,0,true) : 0;

                    /**
                     * WishItem productName.
                     * @member {string} productName
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.productName = "";

                    /**
                     * WishItem productImageUrl.
                     * @member {string} productImageUrl
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.productImageUrl = "";

                    /**
                     * WishItem properties.
                     * @member {Array.<theplant.ec.service.base.IProperty>} properties
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.properties = $util.emptyArray;

                    /**
                     * WishItem stock.
                     * @member {number|Long} stock
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     */
                    WishItem.prototype.stock = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                    /**
                     * Creates a new WishItem instance using the specified properties.
                     * @function create
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {theplant.ec.service.wishlist.IWishItem=} [properties] Properties to set
                     * @returns {theplant.ec.service.wishlist.WishItem} WishItem instance
                     */
                    WishItem.create = function create(properties) {
                        return new WishItem(properties);
                    };

                    /**
                     * Encodes the specified WishItem message. Does not implicitly {@link theplant.ec.service.wishlist.WishItem.verify|verify} messages.
                     * @function encode
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {theplant.ec.service.wishlist.IWishItem} message WishItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WishItem.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.articleCode);
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.productCode);
                        if (message.price != null && message.hasOwnProperty("price"))
                            writer.uint32(/* id 3, wireType 0 =*/24).uint64(message.price);
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            writer.uint32(/* id 4, wireType 0 =*/32).uint64(message.sellingPrice);
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.productName);
                        if (message.productImageUrl != null && message.hasOwnProperty("productImageUrl"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.productImageUrl);
                        if (message.properties != null && message.properties.length)
                            for (var i = 0; i < message.properties.length; ++i)
                                $root.theplant.ec.service.base.Property.encode(message.properties[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            writer.uint32(/* id 8, wireType 0 =*/64).int64(message.stock);
                        return writer;
                    };

                    /**
                     * Encodes the specified WishItem message, length delimited. Does not implicitly {@link theplant.ec.service.wishlist.WishItem.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {theplant.ec.service.wishlist.IWishItem} message WishItem message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    WishItem.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a WishItem message from the specified reader or buffer.
                     * @function decode
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {theplant.ec.service.wishlist.WishItem} WishItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WishItem.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.theplant.ec.service.wishlist.WishItem();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1:
                                message.articleCode = reader.string();
                                break;
                            case 2:
                                message.productCode = reader.string();
                                break;
                            case 3:
                                message.price = reader.uint64();
                                break;
                            case 4:
                                message.sellingPrice = reader.uint64();
                                break;
                            case 5:
                                message.productName = reader.string();
                                break;
                            case 6:
                                message.productImageUrl = reader.string();
                                break;
                            case 7:
                                if (!(message.properties && message.properties.length))
                                    message.properties = [];
                                message.properties.push($root.theplant.ec.service.base.Property.decode(reader, reader.uint32()));
                                break;
                            case 8:
                                message.stock = reader.int64();
                                break;
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a WishItem message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {theplant.ec.service.wishlist.WishItem} WishItem
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    WishItem.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a WishItem message.
                     * @function verify
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    WishItem.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            if (!$util.isString(message.articleCode))
                                return "articleCode: string expected";
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            if (!$util.isString(message.productCode))
                                return "productCode: string expected";
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (!$util.isInteger(message.price) && !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high)))
                                return "price: integer|Long expected";
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (!$util.isInteger(message.sellingPrice) && !(message.sellingPrice && $util.isInteger(message.sellingPrice.low) && $util.isInteger(message.sellingPrice.high)))
                                return "sellingPrice: integer|Long expected";
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            if (!$util.isString(message.productName))
                                return "productName: string expected";
                        if (message.productImageUrl != null && message.hasOwnProperty("productImageUrl"))
                            if (!$util.isString(message.productImageUrl))
                                return "productImageUrl: string expected";
                        if (message.properties != null && message.hasOwnProperty("properties")) {
                            if (!Array.isArray(message.properties))
                                return "properties: array expected";
                            for (var i = 0; i < message.properties.length; ++i) {
                                var error = $root.theplant.ec.service.base.Property.verify(message.properties[i]);
                                if (error)
                                    return "properties." + error;
                            }
                        }
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (!$util.isInteger(message.stock) && !(message.stock && $util.isInteger(message.stock.low) && $util.isInteger(message.stock.high)))
                                return "stock: integer|Long expected";
                        return null;
                    };

                    /**
                     * Creates a WishItem message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {theplant.ec.service.wishlist.WishItem} WishItem
                     */
                    WishItem.fromObject = function fromObject(object) {
                        if (object instanceof $root.theplant.ec.service.wishlist.WishItem)
                            return object;
                        var message = new $root.theplant.ec.service.wishlist.WishItem();
                        if (object.articleCode != null)
                            message.articleCode = String(object.articleCode);
                        if (object.productCode != null)
                            message.productCode = String(object.productCode);
                        if (object.price != null)
                            if ($util.Long)
                                (message.price = $util.Long.fromValue(object.price)).unsigned = true;
                            else if (typeof object.price === "string")
                                message.price = parseInt(object.price, 10);
                            else if (typeof object.price === "number")
                                message.price = object.price;
                            else if (typeof object.price === "object")
                                message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber(true);
                        if (object.sellingPrice != null)
                            if ($util.Long)
                                (message.sellingPrice = $util.Long.fromValue(object.sellingPrice)).unsigned = true;
                            else if (typeof object.sellingPrice === "string")
                                message.sellingPrice = parseInt(object.sellingPrice, 10);
                            else if (typeof object.sellingPrice === "number")
                                message.sellingPrice = object.sellingPrice;
                            else if (typeof object.sellingPrice === "object")
                                message.sellingPrice = new $util.LongBits(object.sellingPrice.low >>> 0, object.sellingPrice.high >>> 0).toNumber(true);
                        if (object.productName != null)
                            message.productName = String(object.productName);
                        if (object.productImageUrl != null)
                            message.productImageUrl = String(object.productImageUrl);
                        if (object.properties) {
                            if (!Array.isArray(object.properties))
                                throw TypeError(".theplant.ec.service.wishlist.WishItem.properties: array expected");
                            message.properties = [];
                            for (var i = 0; i < object.properties.length; ++i) {
                                if (typeof object.properties[i] !== "object")
                                    throw TypeError(".theplant.ec.service.wishlist.WishItem.properties: object expected");
                                message.properties[i] = $root.theplant.ec.service.base.Property.fromObject(object.properties[i]);
                            }
                        }
                        if (object.stock != null)
                            if ($util.Long)
                                (message.stock = $util.Long.fromValue(object.stock)).unsigned = false;
                            else if (typeof object.stock === "string")
                                message.stock = parseInt(object.stock, 10);
                            else if (typeof object.stock === "number")
                                message.stock = object.stock;
                            else if (typeof object.stock === "object")
                                message.stock = new $util.LongBits(object.stock.low >>> 0, object.stock.high >>> 0).toNumber();
                        return message;
                    };

                    /**
                     * Creates a plain object from a WishItem message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @static
                     * @param {theplant.ec.service.wishlist.WishItem} message WishItem
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    WishItem.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.properties = [];
                        if (options.defaults) {
                            object.articleCode = "";
                            object.productCode = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.price = options.longs === String ? "0" : 0;
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, true);
                                object.sellingPrice = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.sellingPrice = options.longs === String ? "0" : 0;
                            object.productName = "";
                            object.productImageUrl = "";
                            if ($util.Long) {
                                var long = new $util.Long(0, 0, false);
                                object.stock = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                            } else
                                object.stock = options.longs === String ? "0" : 0;
                        }
                        if (message.articleCode != null && message.hasOwnProperty("articleCode"))
                            object.articleCode = message.articleCode;
                        if (message.productCode != null && message.hasOwnProperty("productCode"))
                            object.productCode = message.productCode;
                        if (message.price != null && message.hasOwnProperty("price"))
                            if (typeof message.price === "number")
                                object.price = options.longs === String ? String(message.price) : message.price;
                            else
                                object.price = options.longs === String ? $util.Long.prototype.toString.call(message.price) : options.longs === Number ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber(true) : message.price;
                        if (message.sellingPrice != null && message.hasOwnProperty("sellingPrice"))
                            if (typeof message.sellingPrice === "number")
                                object.sellingPrice = options.longs === String ? String(message.sellingPrice) : message.sellingPrice;
                            else
                                object.sellingPrice = options.longs === String ? $util.Long.prototype.toString.call(message.sellingPrice) : options.longs === Number ? new $util.LongBits(message.sellingPrice.low >>> 0, message.sellingPrice.high >>> 0).toNumber(true) : message.sellingPrice;
                        if (message.productName != null && message.hasOwnProperty("productName"))
                            object.productName = message.productName;
                        if (message.productImageUrl != null && message.hasOwnProperty("productImageUrl"))
                            object.productImageUrl = message.productImageUrl;
                        if (message.properties && message.properties.length) {
                            object.properties = [];
                            for (var j = 0; j < message.properties.length; ++j)
                                object.properties[j] = $root.theplant.ec.service.base.Property.toObject(message.properties[j], options);
                        }
                        if (message.stock != null && message.hasOwnProperty("stock"))
                            if (typeof message.stock === "number")
                                object.stock = options.longs === String ? String(message.stock) : message.stock;
                            else
                                object.stock = options.longs === String ? $util.Long.prototype.toString.call(message.stock) : options.longs === Number ? new $util.LongBits(message.stock.low >>> 0, message.stock.high >>> 0).toNumber() : message.stock;
                        return object;
                    };

                    /**
                     * Converts this WishItem to JSON.
                     * @function toJSON
                     * @memberof theplant.ec.service.wishlist.WishItem
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    WishItem.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return WishItem;
                })();

                return wishlist;
            })();

            return service;
        })();

        return ec;
    })();

    return theplant;
})();

$root.headererror = (function() {

    /**
     * Namespace headererror.
     * @exports headererror
     * @namespace
     */
    var headererror = {};

    headererror.HeaderError = (function() {

        /**
         * Properties of a HeaderError.
         * @memberof headererror
         * @interface IHeaderError
         * @property {string|null} [code] HeaderError code
         * @property {string|null} [msg] HeaderError msg
         * @property {headererror.HeaderError.IFieldViolation|null} [fieldViolation] HeaderError fieldViolation
         */

        /**
         * Constructs a new HeaderError.
         * @memberof headererror
         * @classdesc Represents a HeaderError.
         * @implements IHeaderError
         * @constructor
         * @param {headererror.IHeaderError=} [properties] Properties to set
         */
        function HeaderError(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * HeaderError code.
         * @member {string} code
         * @memberof headererror.HeaderError
         * @instance
         */
        HeaderError.prototype.code = "";

        /**
         * HeaderError msg.
         * @member {string} msg
         * @memberof headererror.HeaderError
         * @instance
         */
        HeaderError.prototype.msg = "";

        /**
         * HeaderError fieldViolation.
         * @member {headererror.HeaderError.IFieldViolation|null|undefined} fieldViolation
         * @memberof headererror.HeaderError
         * @instance
         */
        HeaderError.prototype.fieldViolation = null;

        /**
         * Creates a new HeaderError instance using the specified properties.
         * @function create
         * @memberof headererror.HeaderError
         * @static
         * @param {headererror.IHeaderError=} [properties] Properties to set
         * @returns {headererror.HeaderError} HeaderError instance
         */
        HeaderError.create = function create(properties) {
            return new HeaderError(properties);
        };

        /**
         * Encodes the specified HeaderError message. Does not implicitly {@link headererror.HeaderError.verify|verify} messages.
         * @function encode
         * @memberof headererror.HeaderError
         * @static
         * @param {headererror.IHeaderError} message HeaderError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeaderError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && message.hasOwnProperty("code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.msg != null && message.hasOwnProperty("msg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.msg);
            if (message.fieldViolation != null && message.hasOwnProperty("fieldViolation"))
                $root.headererror.HeaderError.FieldViolation.encode(message.fieldViolation, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified HeaderError message, length delimited. Does not implicitly {@link headererror.HeaderError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof headererror.HeaderError
         * @static
         * @param {headererror.IHeaderError} message HeaderError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        HeaderError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a HeaderError message from the specified reader or buffer.
         * @function decode
         * @memberof headererror.HeaderError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {headererror.HeaderError} HeaderError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeaderError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.headererror.HeaderError();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                case 2:
                    message.msg = reader.string();
                    break;
                case 3:
                    message.fieldViolation = $root.headererror.HeaderError.FieldViolation.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a HeaderError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof headererror.HeaderError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {headererror.HeaderError} HeaderError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        HeaderError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a HeaderError message.
         * @function verify
         * @memberof headererror.HeaderError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        HeaderError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!$util.isString(message.msg))
                    return "msg: string expected";
            if (message.fieldViolation != null && message.hasOwnProperty("fieldViolation")) {
                var error = $root.headererror.HeaderError.FieldViolation.verify(message.fieldViolation);
                if (error)
                    return "fieldViolation." + error;
            }
            return null;
        };

        /**
         * Creates a HeaderError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof headererror.HeaderError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {headererror.HeaderError} HeaderError
         */
        HeaderError.fromObject = function fromObject(object) {
            if (object instanceof $root.headererror.HeaderError)
                return object;
            var message = new $root.headererror.HeaderError();
            if (object.code != null)
                message.code = String(object.code);
            if (object.msg != null)
                message.msg = String(object.msg);
            if (object.fieldViolation != null) {
                if (typeof object.fieldViolation !== "object")
                    throw TypeError(".headererror.HeaderError.fieldViolation: object expected");
                message.fieldViolation = $root.headererror.HeaderError.FieldViolation.fromObject(object.fieldViolation);
            }
            return message;
        };

        /**
         * Creates a plain object from a HeaderError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof headererror.HeaderError
         * @static
         * @param {headererror.HeaderError} message HeaderError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        HeaderError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.code = "";
                object.msg = "";
                object.fieldViolation = null;
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = message.msg;
            if (message.fieldViolation != null && message.hasOwnProperty("fieldViolation"))
                object.fieldViolation = $root.headererror.HeaderError.FieldViolation.toObject(message.fieldViolation, options);
            return object;
        };

        /**
         * Converts this HeaderError to JSON.
         * @function toJSON
         * @memberof headererror.HeaderError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        HeaderError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        HeaderError.FieldViolation = (function() {

            /**
             * Properties of a FieldViolation.
             * @memberof headererror.HeaderError
             * @interface IFieldViolation
             * @property {string|null} [field] FieldViolation field
             * @property {string|null} [code] FieldViolation code
             * @property {string|null} [param] FieldViolation param
             * @property {string|null} [msg] FieldViolation msg
             * @property {number|Long|null} [statusCode] FieldViolation statusCode
             */

            /**
             * Constructs a new FieldViolation.
             * @memberof headererror.HeaderError
             * @classdesc Represents a FieldViolation.
             * @implements IFieldViolation
             * @constructor
             * @param {headererror.HeaderError.IFieldViolation=} [properties] Properties to set
             */
            function FieldViolation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldViolation field.
             * @member {string} field
             * @memberof headererror.HeaderError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.field = "";

            /**
             * FieldViolation code.
             * @member {string} code
             * @memberof headererror.HeaderError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.code = "";

            /**
             * FieldViolation param.
             * @member {string} param
             * @memberof headererror.HeaderError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.param = "";

            /**
             * FieldViolation msg.
             * @member {string} msg
             * @memberof headererror.HeaderError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.msg = "";

            /**
             * FieldViolation statusCode.
             * @member {number|Long} statusCode
             * @memberof headererror.HeaderError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.statusCode = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new FieldViolation instance using the specified properties.
             * @function create
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {headererror.HeaderError.IFieldViolation=} [properties] Properties to set
             * @returns {headererror.HeaderError.FieldViolation} FieldViolation instance
             */
            FieldViolation.create = function create(properties) {
                return new FieldViolation(properties);
            };

            /**
             * Encodes the specified FieldViolation message. Does not implicitly {@link headererror.HeaderError.FieldViolation.verify|verify} messages.
             * @function encode
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {headererror.HeaderError.IFieldViolation} message FieldViolation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldViolation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.field != null && message.hasOwnProperty("field"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                if (message.code != null && message.hasOwnProperty("code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                if (message.param != null && message.hasOwnProperty("param"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.param);
                if (message.msg != null && message.hasOwnProperty("msg"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.msg);
                if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.statusCode);
                return writer;
            };

            /**
             * Encodes the specified FieldViolation message, length delimited. Does not implicitly {@link headererror.HeaderError.FieldViolation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {headererror.HeaderError.IFieldViolation} message FieldViolation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldViolation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldViolation message from the specified reader or buffer.
             * @function decode
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {headererror.HeaderError.FieldViolation} FieldViolation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldViolation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.headererror.HeaderError.FieldViolation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.field = reader.string();
                        break;
                    case 2:
                        message.code = reader.string();
                        break;
                    case 3:
                        message.param = reader.string();
                        break;
                    case 4:
                        message.msg = reader.string();
                        break;
                    case 5:
                        message.statusCode = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldViolation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {headererror.HeaderError.FieldViolation} FieldViolation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldViolation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldViolation message.
             * @function verify
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldViolation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.field != null && message.hasOwnProperty("field"))
                    if (!$util.isString(message.field))
                        return "field: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.param != null && message.hasOwnProperty("param"))
                    if (!$util.isString(message.param))
                        return "param: string expected";
                if (message.msg != null && message.hasOwnProperty("msg"))
                    if (!$util.isString(message.msg))
                        return "msg: string expected";
                if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                    if (!$util.isInteger(message.statusCode) && !(message.statusCode && $util.isInteger(message.statusCode.low) && $util.isInteger(message.statusCode.high)))
                        return "statusCode: integer|Long expected";
                return null;
            };

            /**
             * Creates a FieldViolation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {headererror.HeaderError.FieldViolation} FieldViolation
             */
            FieldViolation.fromObject = function fromObject(object) {
                if (object instanceof $root.headererror.HeaderError.FieldViolation)
                    return object;
                var message = new $root.headererror.HeaderError.FieldViolation();
                if (object.field != null)
                    message.field = String(object.field);
                if (object.code != null)
                    message.code = String(object.code);
                if (object.param != null)
                    message.param = String(object.param);
                if (object.msg != null)
                    message.msg = String(object.msg);
                if (object.statusCode != null)
                    if ($util.Long)
                        (message.statusCode = $util.Long.fromValue(object.statusCode)).unsigned = false;
                    else if (typeof object.statusCode === "string")
                        message.statusCode = parseInt(object.statusCode, 10);
                    else if (typeof object.statusCode === "number")
                        message.statusCode = object.statusCode;
                    else if (typeof object.statusCode === "object")
                        message.statusCode = new $util.LongBits(object.statusCode.low >>> 0, object.statusCode.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a FieldViolation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof headererror.HeaderError.FieldViolation
             * @static
             * @param {headererror.HeaderError.FieldViolation} message FieldViolation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldViolation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.field = "";
                    object.code = "";
                    object.param = "";
                    object.msg = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.statusCode = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.statusCode = options.longs === String ? "0" : 0;
                }
                if (message.field != null && message.hasOwnProperty("field"))
                    object.field = message.field;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.param != null && message.hasOwnProperty("param"))
                    object.param = message.param;
                if (message.msg != null && message.hasOwnProperty("msg"))
                    object.msg = message.msg;
                if (message.statusCode != null && message.hasOwnProperty("statusCode"))
                    if (typeof message.statusCode === "number")
                        object.statusCode = options.longs === String ? String(message.statusCode) : message.statusCode;
                    else
                        object.statusCode = options.longs === String ? $util.Long.prototype.toString.call(message.statusCode) : options.longs === Number ? new $util.LongBits(message.statusCode.low >>> 0, message.statusCode.high >>> 0).toNumber() : message.statusCode;
                return object;
            };

            /**
             * Converts this FieldViolation to JSON.
             * @function toJSON
             * @memberof headererror.HeaderError.FieldViolation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldViolation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FieldViolation;
        })();

        return HeaderError;
    })();

    return headererror;
})();

$root.proto = (function() {

    /**
     * Namespace proto.
     * @exports proto
     * @namespace
     */
    var proto = {};

    proto.ValidationError = (function() {

        /**
         * Properties of a ValidationError.
         * @memberof proto
         * @interface IValidationError
         * @property {string|null} [code] ValidationError code
         * @property {string|null} [msg] ValidationError msg
         * @property {string|null} [defaultViewMsg] ValidationError defaultViewMsg
         * @property {Array.<proto.ValidationError.IFieldViolation>|null} [fieldViolations] ValidationError fieldViolations
         */

        /**
         * Constructs a new ValidationError.
         * @memberof proto
         * @classdesc Represents a ValidationError.
         * @implements IValidationError
         * @constructor
         * @param {proto.IValidationError=} [properties] Properties to set
         */
        function ValidationError(properties) {
            this.fieldViolations = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ValidationError code.
         * @member {string} code
         * @memberof proto.ValidationError
         * @instance
         */
        ValidationError.prototype.code = "";

        /**
         * ValidationError msg.
         * @member {string} msg
         * @memberof proto.ValidationError
         * @instance
         */
        ValidationError.prototype.msg = "";

        /**
         * ValidationError defaultViewMsg.
         * @member {string} defaultViewMsg
         * @memberof proto.ValidationError
         * @instance
         */
        ValidationError.prototype.defaultViewMsg = "";

        /**
         * ValidationError fieldViolations.
         * @member {Array.<proto.ValidationError.IFieldViolation>} fieldViolations
         * @memberof proto.ValidationError
         * @instance
         */
        ValidationError.prototype.fieldViolations = $util.emptyArray;

        /**
         * Creates a new ValidationError instance using the specified properties.
         * @function create
         * @memberof proto.ValidationError
         * @static
         * @param {proto.IValidationError=} [properties] Properties to set
         * @returns {proto.ValidationError} ValidationError instance
         */
        ValidationError.create = function create(properties) {
            return new ValidationError(properties);
        };

        /**
         * Encodes the specified ValidationError message. Does not implicitly {@link proto.ValidationError.verify|verify} messages.
         * @function encode
         * @memberof proto.ValidationError
         * @static
         * @param {proto.IValidationError} message ValidationError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidationError.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.code != null && message.hasOwnProperty("code"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
            if (message.msg != null && message.hasOwnProperty("msg"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.msg);
            if (message.fieldViolations != null && message.fieldViolations.length)
                for (var i = 0; i < message.fieldViolations.length; ++i)
                    $root.proto.ValidationError.FieldViolation.encode(message.fieldViolations[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.defaultViewMsg != null && message.hasOwnProperty("defaultViewMsg"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.defaultViewMsg);
            return writer;
        };

        /**
         * Encodes the specified ValidationError message, length delimited. Does not implicitly {@link proto.ValidationError.verify|verify} messages.
         * @function encodeDelimited
         * @memberof proto.ValidationError
         * @static
         * @param {proto.IValidationError} message ValidationError message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ValidationError.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ValidationError message from the specified reader or buffer.
         * @function decode
         * @memberof proto.ValidationError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {proto.ValidationError} ValidationError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidationError.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ValidationError();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.code = reader.string();
                    break;
                case 2:
                    message.msg = reader.string();
                    break;
                case 4:
                    message.defaultViewMsg = reader.string();
                    break;
                case 3:
                    if (!(message.fieldViolations && message.fieldViolations.length))
                        message.fieldViolations = [];
                    message.fieldViolations.push($root.proto.ValidationError.FieldViolation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ValidationError message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof proto.ValidationError
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {proto.ValidationError} ValidationError
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ValidationError.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ValidationError message.
         * @function verify
         * @memberof proto.ValidationError
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ValidationError.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.msg != null && message.hasOwnProperty("msg"))
                if (!$util.isString(message.msg))
                    return "msg: string expected";
            if (message.defaultViewMsg != null && message.hasOwnProperty("defaultViewMsg"))
                if (!$util.isString(message.defaultViewMsg))
                    return "defaultViewMsg: string expected";
            if (message.fieldViolations != null && message.hasOwnProperty("fieldViolations")) {
                if (!Array.isArray(message.fieldViolations))
                    return "fieldViolations: array expected";
                for (var i = 0; i < message.fieldViolations.length; ++i) {
                    var error = $root.proto.ValidationError.FieldViolation.verify(message.fieldViolations[i]);
                    if (error)
                        return "fieldViolations." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ValidationError message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof proto.ValidationError
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {proto.ValidationError} ValidationError
         */
        ValidationError.fromObject = function fromObject(object) {
            if (object instanceof $root.proto.ValidationError)
                return object;
            var message = new $root.proto.ValidationError();
            if (object.code != null)
                message.code = String(object.code);
            if (object.msg != null)
                message.msg = String(object.msg);
            if (object.defaultViewMsg != null)
                message.defaultViewMsg = String(object.defaultViewMsg);
            if (object.fieldViolations) {
                if (!Array.isArray(object.fieldViolations))
                    throw TypeError(".proto.ValidationError.fieldViolations: array expected");
                message.fieldViolations = [];
                for (var i = 0; i < object.fieldViolations.length; ++i) {
                    if (typeof object.fieldViolations[i] !== "object")
                        throw TypeError(".proto.ValidationError.fieldViolations: object expected");
                    message.fieldViolations[i] = $root.proto.ValidationError.FieldViolation.fromObject(object.fieldViolations[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ValidationError message. Also converts values to other types if specified.
         * @function toObject
         * @memberof proto.ValidationError
         * @static
         * @param {proto.ValidationError} message ValidationError
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ValidationError.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.fieldViolations = [];
            if (options.defaults) {
                object.code = "";
                object.msg = "";
                object.defaultViewMsg = "";
            }
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.msg != null && message.hasOwnProperty("msg"))
                object.msg = message.msg;
            if (message.fieldViolations && message.fieldViolations.length) {
                object.fieldViolations = [];
                for (var j = 0; j < message.fieldViolations.length; ++j)
                    object.fieldViolations[j] = $root.proto.ValidationError.FieldViolation.toObject(message.fieldViolations[j], options);
            }
            if (message.defaultViewMsg != null && message.hasOwnProperty("defaultViewMsg"))
                object.defaultViewMsg = message.defaultViewMsg;
            return object;
        };

        /**
         * Converts this ValidationError to JSON.
         * @function toJSON
         * @memberof proto.ValidationError
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ValidationError.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        ValidationError.FieldViolation = (function() {

            /**
             * Properties of a FieldViolation.
             * @memberof proto.ValidationError
             * @interface IFieldViolation
             * @property {string|null} [field] FieldViolation field
             * @property {string|null} [code] FieldViolation code
             * @property {string|null} [param] FieldViolation param
             * @property {string|null} [msg] FieldViolation msg
             * @property {string|null} [defaultViewMsg] FieldViolation defaultViewMsg
             * @property {google.protobuf.IAny|null} [payload] FieldViolation payload
             */

            /**
             * Constructs a new FieldViolation.
             * @memberof proto.ValidationError
             * @classdesc Represents a FieldViolation.
             * @implements IFieldViolation
             * @constructor
             * @param {proto.ValidationError.IFieldViolation=} [properties] Properties to set
             */
            function FieldViolation(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FieldViolation field.
             * @member {string} field
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.field = "";

            /**
             * FieldViolation code.
             * @member {string} code
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.code = "";

            /**
             * FieldViolation param.
             * @member {string} param
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.param = "";

            /**
             * FieldViolation msg.
             * @member {string} msg
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.msg = "";

            /**
             * FieldViolation defaultViewMsg.
             * @member {string} defaultViewMsg
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.defaultViewMsg = "";

            /**
             * FieldViolation payload.
             * @member {google.protobuf.IAny|null|undefined} payload
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             */
            FieldViolation.prototype.payload = null;

            /**
             * Creates a new FieldViolation instance using the specified properties.
             * @function create
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {proto.ValidationError.IFieldViolation=} [properties] Properties to set
             * @returns {proto.ValidationError.FieldViolation} FieldViolation instance
             */
            FieldViolation.create = function create(properties) {
                return new FieldViolation(properties);
            };

            /**
             * Encodes the specified FieldViolation message. Does not implicitly {@link proto.ValidationError.FieldViolation.verify|verify} messages.
             * @function encode
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {proto.ValidationError.IFieldViolation} message FieldViolation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldViolation.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.field != null && message.hasOwnProperty("field"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.field);
                if (message.code != null && message.hasOwnProperty("code"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
                if (message.param != null && message.hasOwnProperty("param"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.param);
                if (message.msg != null && message.hasOwnProperty("msg"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.msg);
                if (message.payload != null && message.hasOwnProperty("payload"))
                    $root.google.protobuf.Any.encode(message.payload, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.defaultViewMsg != null && message.hasOwnProperty("defaultViewMsg"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.defaultViewMsg);
                return writer;
            };

            /**
             * Encodes the specified FieldViolation message, length delimited. Does not implicitly {@link proto.ValidationError.FieldViolation.verify|verify} messages.
             * @function encodeDelimited
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {proto.ValidationError.IFieldViolation} message FieldViolation message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FieldViolation.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FieldViolation message from the specified reader or buffer.
             * @function decode
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {proto.ValidationError.FieldViolation} FieldViolation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldViolation.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.proto.ValidationError.FieldViolation();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.field = reader.string();
                        break;
                    case 2:
                        message.code = reader.string();
                        break;
                    case 3:
                        message.param = reader.string();
                        break;
                    case 4:
                        message.msg = reader.string();
                        break;
                    case 6:
                        message.defaultViewMsg = reader.string();
                        break;
                    case 5:
                        message.payload = $root.google.protobuf.Any.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FieldViolation message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {proto.ValidationError.FieldViolation} FieldViolation
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FieldViolation.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FieldViolation message.
             * @function verify
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FieldViolation.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.field != null && message.hasOwnProperty("field"))
                    if (!$util.isString(message.field))
                        return "field: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.param != null && message.hasOwnProperty("param"))
                    if (!$util.isString(message.param))
                        return "param: string expected";
                if (message.msg != null && message.hasOwnProperty("msg"))
                    if (!$util.isString(message.msg))
                        return "msg: string expected";
                if (message.defaultViewMsg != null && message.hasOwnProperty("defaultViewMsg"))
                    if (!$util.isString(message.defaultViewMsg))
                        return "defaultViewMsg: string expected";
                if (message.payload != null && message.hasOwnProperty("payload")) {
                    var error = $root.google.protobuf.Any.verify(message.payload);
                    if (error)
                        return "payload." + error;
                }
                return null;
            };

            /**
             * Creates a FieldViolation message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {proto.ValidationError.FieldViolation} FieldViolation
             */
            FieldViolation.fromObject = function fromObject(object) {
                if (object instanceof $root.proto.ValidationError.FieldViolation)
                    return object;
                var message = new $root.proto.ValidationError.FieldViolation();
                if (object.field != null)
                    message.field = String(object.field);
                if (object.code != null)
                    message.code = String(object.code);
                if (object.param != null)
                    message.param = String(object.param);
                if (object.msg != null)
                    message.msg = String(object.msg);
                if (object.defaultViewMsg != null)
                    message.defaultViewMsg = String(object.defaultViewMsg);
                if (object.payload != null) {
                    if (typeof object.payload !== "object")
                        throw TypeError(".proto.ValidationError.FieldViolation.payload: object expected");
                    message.payload = $root.google.protobuf.Any.fromObject(object.payload);
                }
                return message;
            };

            /**
             * Creates a plain object from a FieldViolation message. Also converts values to other types if specified.
             * @function toObject
             * @memberof proto.ValidationError.FieldViolation
             * @static
             * @param {proto.ValidationError.FieldViolation} message FieldViolation
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FieldViolation.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.field = "";
                    object.code = "";
                    object.param = "";
                    object.msg = "";
                    object.payload = null;
                    object.defaultViewMsg = "";
                }
                if (message.field != null && message.hasOwnProperty("field"))
                    object.field = message.field;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.param != null && message.hasOwnProperty("param"))
                    object.param = message.param;
                if (message.msg != null && message.hasOwnProperty("msg"))
                    object.msg = message.msg;
                if (message.payload != null && message.hasOwnProperty("payload"))
                    object.payload = $root.google.protobuf.Any.toObject(message.payload, options);
                if (message.defaultViewMsg != null && message.hasOwnProperty("defaultViewMsg"))
                    object.defaultViewMsg = message.defaultViewMsg;
                return object;
            };

            /**
             * Converts this FieldViolation to JSON.
             * @function toJSON
             * @memberof proto.ValidationError.FieldViolation
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FieldViolation.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FieldViolation;
        })();

        return ValidationError;
    })();

    return proto;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number|Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                if (message.value != null && message.hasOwnProperty("value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.type_url = reader.string();
                        break;
                    case 2:
                        message.value = reader.bytes();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                var message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Any;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
