import {
  CHECKOUT_AMAZON_PAY_PATH,
  CHECKOUT_COMPLETE_PAYMENT_PATH,
} from "../../constants/Paths";
import queryString from "query-string";
import { whichBrowser } from "../../components/helper";

const getSessionIdAndCompleteAmazonPayInURL = () => {
  const search = queryString.parse(window.location.search);

  const { amazonCheckoutSessionId } = search;

  return { amazonCheckoutSessionId };
};

const getAmazonPayloadUrl = () => {
  const { host, protocol } = window.location;

  const checkoutReviewReturnUrl = `${protocol}//${host}${CHECKOUT_AMAZON_PAY_PATH}`;

  const amazonPayResultReturnUrl = `${protocol}//${host}${CHECKOUT_COMPLETE_PAYMENT_PATH}?userAgent=${whichBrowser()}`;

  return { checkoutReviewReturnUrl, amazonPayResultReturnUrl };
};

declare global {
  interface Window {
    amazon?: {
      Pay: {
        renderButton(type: string, data?: object): void;
        bindChangeAction(
          target: string,
          params: { amazonCheckoutSessionId: string; changeAction: string }
        ): void;
      };
    };
  }
}

const downloadAmazonScriptAndRunCallback = ({
  url,
  callback,
}: {
  url: string;
  callback: () => void;
}) => {
  if (typeof window !== "undefined") {
    const $script = require("scriptjs");
    if (!window.amazon) {
      if (url) {
        $script(url, () => {
          if (window.amazon) {
            callback();
          }
        });
      }
    } else {
      callback();
    }
  }
};

export const AMAZON_BUTTON_ID = "amazonPayButton";

const renderAmazonButton = ({
  merchantId,
  signature,
  publicKeyId,
  payloadJSON,
  sandbox,
}: {
  merchantId: string;
  signature: string;
  publicKeyId: string;
  payloadJSON: string;
  sandbox: boolean;
}) => {
  if (window.amazon && window.amazon.Pay) {
    window.amazon.Pay.renderButton(`#${AMAZON_BUTTON_ID}`, {
      merchantId: merchantId,
      ledgerCurrency: "JPY",
      sandbox,
      // customize the buyer experience
      checkoutLanguage: "ja_JP",
      productType: "PayAndShip",
      placement: "Cart",
      buttonColor: "DarkGray",
      createCheckoutSessionConfig: {
        payloadJSON,
        signature,
        publicKeyId,
      },
    });
  }
};

export const CHANGE_ADDRESS_ID = "changeAddress";
export const CHANGE_PAYMENT_ID = "changePayment";

const bindAmazonButtonAction = (amazonCheckoutSessionId: string) => {
  if (window.amazon && window.amazon.Pay) {
    if (document.getElementById(CHANGE_ADDRESS_ID)) {
      window.amazon.Pay.bindChangeAction(`#${CHANGE_ADDRESS_ID}`, {
        amazonCheckoutSessionId,
        changeAction: CHANGE_ADDRESS_ID,
      });
    }

    if (document.getElementById(CHANGE_PAYMENT_ID)) {
      window.amazon.Pay.bindChangeAction(`#${CHANGE_PAYMENT_ID}`, {
        amazonCheckoutSessionId,
        changeAction: CHANGE_PAYMENT_ID,
      });
    }
  }
};

export {
  getAmazonPayloadUrl,
  downloadAmazonScriptAndRunCallback,
  renderAmazonButton,
  bindAmazonButtonAction,
  getSessionIdAndCompleteAmazonPayInURL,
};
