import $ from "jquery";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

class PLP {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".js-grid-toggle").on(
      "click",
      ".grid-toggle__button",
      this.handleClickGridToggleButton.bind(this)
    );
    $(".js-filter-bar__options-trigger").on(
      "click",
      this.handleClickOptionsTrigger.bind(this)
    );
    $(".js-filter-options-overlay").on(
      "click",
      this.handleClickOverlay.bind(this)
    );
    $(".js-close-filter-option-button").on(
      "click",
      this.closeFilterOptions.bind(this)
    );
  }

  handleClickGridToggleButton() {
    $(".js-grid-toggle").toggleClass("grid-toggle--alt-view");
    $(".js-product-list").toggleClass("product-list--alt-view");

    window._PLPLazyLoad && window._PLPLazyLoad(); // manually trigger lazy load
  }

  handleClickOptionsTrigger() {
    showFilterOptions();
  }

  handleClickOverlay(event) {
    if (event.target === event.currentTarget) {
      this.closeFilterOptions();
    }
  }

  closeFilterOptions() {
    hideFilterOptions();

    setTimeout(() => {
      $("html, body").animate({
        scrollTop: $(".js-filters-form").offset().top - 95,
      });
    }, 200);
  }
}

function showFilterOptions() {
  disableBodyScroll(document.querySelector(".js-filter-options"));

  $(".js-filter-options-overlay").fadeIn();
  $(".js-filter-options").addClass("visible");
}

function hideFilterOptions() {
  $(".js-filter-options").removeClass("visible");
  $(".js-filter-options-overlay").fadeOut();

  enableBodyScroll(document.querySelector(".js-filter-options"));
}

export default PLP;
