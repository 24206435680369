import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";
import { removeFieldErrorMessages } from "./helpers/validationError";

const initialState = {
  data: {},
  ui: {
    isOpen: false,
    fetching: false,
    error: null,
  },
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.PROFILE_FORM_OPEN:
      return { ...s, isOpen: true, error: null };
    case types.PROFILE_FORM_CLOSE:
      return { ...s, isOpen: false, error: null };
    case types.PROFILE_FORM_WILL_SUBMIT:
      return { ...s, fetching: true, error: null };
    case types.PROFILE_FORM_DID_SUBMIT_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.PROFILE_FORM_DID_SUBMIT:
      return { ...s, fetching: false, error: null, isOpen: false };
    case types.PROFILE_FORM_CHANGE_FIELD:
      const error = removeFieldErrorMessages(s.error, a.field);
      return { ...s, error };
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.PROFILE_DID_REFRESH:
      return { ...s, ...a.data };
    case types.PROFILE_FORM_CHANGE_FIELD:
      return { ...s, [a.field]: a.value };
    default:
      return s;
  }
};

const getProfileFormData = (state) => state.profileForm.data;

export default combineReducers({ ui, data });

export { initialState, getProfileFormData };
