import _ from "lodash";
import $ from "jquery";

class CategoryLanding {
    constructor() {
        this.loadVimeo();
        const win = $(window);
        win.on('resize', _.debounce(this.loadVimeo, 200));
    }

    loadVimeo() {
        const isMobile = $(window).width() < 768;
        let iframe;

        if(isMobile) {
            iframe = $("iframe.banner-sp");
        } else {
            iframe = $("iframe.banner-pc");
        }

        iframe.attr('src', iframe.data('src'));
    }
}

export default CategoryLanding;
