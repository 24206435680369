import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.ACCOUNT_VIEWED_PRODUCTS_WILL_REFRESH });

const refreshSuccess = data => ({
  type: types.ACCOUNT_VIEWED_PRODUCTS_DID_REFRESH,
  data
});

const refreshFailure = error => ({
  type: types.ACCOUNT_VIEWED_PRODUCTS_REFRESH_FAILED,
  error
});

export { refresh, refreshSuccess, refreshFailure };
