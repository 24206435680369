import React, { useRef, useState, useEffect } from "react";
import { ArrowDown } from "../atoms/svgs";

export type TabProps = {
  title: string;
  content: string;
  maxHeight?: number;
  children?: React.ReactNode;
  className?: string;
  selected?: boolean;
};

const ExpandContent = ({
  title,
  selected,
  children,
  className,
  maxHeight,
}: TabProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = React.useState(false);
  const [hideReadMore, setHideReadMore] = useState(false);

  const toggleExpand = () => setExpand(!expand);

  useEffect(() => {
    if (!ref.current) return;

    // unset maxHeight
    if (!maxHeight) return;

    // invisible content
    if (!selected) return;

    // already hidden
    if (hideReadMore) return;

    if (ref.current.clientHeight < maxHeight) {
      setHideReadMore(true);
    }
  }, [hideReadMore, maxHeight, selected]);

  useEffect(() => {
    if (ref) {
      const current = ref.current;
      if (current) {
        if (!expand) {
          current.scroll(0, 0);
        }
      }
    }
  }, [expand]);

  return (
    <div className={`content ${selected ? "expand" : ""}`}>
      <div
        ref={ref}
        id={title}
        className={`tabcontent rich-editor-content ${
          className ? className : ""
        } ${expand ? "expand" : ""}`}
      >
        {children}
      </div>
      {hideReadMore ? null : (
        <div className="more div_bgmore" onClick={toggleExpand}>
          <div className="btn btn-readmore">
            <ArrowDown />
          </div>
        </div>
      )}
    </div>
  );
};

export { ExpandContent, ArrowDown };
