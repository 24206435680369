import * as React from "react";

const Support = ({ className }: { className?: string }) => (
  <div
    className={`support-section white-panel clearfix ${
      className ? className : ""
    }`}
  >
    <h5>サポート フリーダイヤル</h5>
    <p>
      <span>受付時間：</span>
      <span>
        (月)〜(土) 9:00〜18:00
        <br />
        (日) 9:00〜17:00
        <br />
        祝日を除く
      </span>
    </p>
    <a href="tel:0120-810-378" className="outline-btn">
      0120-810-378
    </a>
  </div>
);

export { Support };
