import React from "react";
import { CartProps } from "../../orders";

type Props = {
  messages: string[];
};

const Message = ({ messages }: Props) => (
  <div dangerouslySetInnerHTML={{ __html: messages.join("") }} />
);

const CartPromotionMessage = ({ cart }: CartProps) => {
  const messages = cart.cart && cart.cart.messages;

  if (!messages || messages.length === 0) {
    return null;
  }

  return (
    <div className="cart-promotion-message-wrapper white-panel">
      {messages.map(message => (
        <div
          className="cart-promotion-message-item rich-editor-content"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      ))}
    </div>
  );
};

export { Message, CartPromotionMessage };
