import React from "react";
import { OrderItemDataPropType } from "./OrderItemDataPropType";
import ProductPrice from "../../../../shared/ProductPrice";
import { NotAvailable } from "../../../../shared/NotAvailable";

const isOutOfStock = ({
  quantity,
  quantityInStock,
}: {
  quantity: number;
  quantityInStock: number;
}) => quantity > quantityInStock || quantityInStock === 0;

// aigle in-store app
// Cart item with has a value in restricted_reasons, should display "Unavailable"
const isRestrictedInInstoreApp = ({
  restrictedReasons,
}: {
  restrictedReasons?: any[];
}) =>
  !!(window as any).__aigleinstoreapp &&
  !!restrictedReasons &&
  restrictedReasons.length > 0;

const OrderItemAmount = (props: Props) => {
  const { data, showingNotAvailableWhenOutOfStock } = props;

  return isRestrictedInInstoreApp(data as any) ||
    (showingNotAvailableWhenOutOfStock && isOutOfStock(data)) ? (
    <NotAvailable />
  ) : (
    <ProductPrice
      data={{
        price: data.amount,
        sellingPrice: data.actualAmount,
        discountRate: data.discountRate,
      }}
      className={props.className}
      smaller
    />
  );
};

type Props = {
  data: OrderItemDataPropType;
  showingNotAvailableWhenOutOfStock: boolean;
  className?: string;
};

export default OrderItemAmount;

export { isOutOfStock, isRestrictedInInstoreApp, NotAvailable };
