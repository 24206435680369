import { connect } from "react-redux";
import { Dispatch, compose } from "redux";
import { CheckoutState } from "./reducer";

import { makeService, IValidationError } from "@theplant/ecjs/prottp";
import { withContext, ContextProps, Context } from "@theplant/ecjs/context";

import { theplant } from "../proto";
type IAvailableUsePoint = theplant.ec.service.points.IAvailableUsePoint;
type ICheckoutInput = theplant.ec.api.orders.ICheckoutInput;
const CheckoutService = theplant.ec.api.orders.CheckoutService;

type State = {
  availableUsePoint: IAvailableUsePoint | null;
  pointsInput: number | null;
  state:
    | {
        type: null | "fetching";
      }
    | {
        type: "error";
        error: IValidationError;
      };
};

const initialState: State = {
  availableUsePoint: null,
  pointsInput: null,
  state: { type: null }
};

type Action =
  | { type: "WILL_FETCH_POINTS_DATA" }
  | { type: "FETCH_POINTS_DATA"; data: IAvailableUsePoint }
  | { type: "FETCH_POINTS_DATA_ERROR"; error: IValidationError }
  | { type: "UPDATE_POINTS_INPUT"; points: number | null };

const reducer = (s: State = initialState, a: Action): State => {
  switch (a.type) {
    case "WILL_FETCH_POINTS_DATA":
      return { ...s, state: { type: "fetching" } };
    case "FETCH_POINTS_DATA":
      return { ...s, availableUsePoint: a.data, state: { type: null } };
    case "FETCH_POINTS_DATA_ERROR":
      return { ...s, state: { type: "error", error: a.error } };
    case "UPDATE_POINTS_INPUT":
      return { ...s, pointsInput: a.points };
  }
  return s;
};

type DP = {
  fetchPoints: (checkoutInput: ICheckoutInput) => Promise<void>;
  updatePointsInput: (points: number | null) => void;
};

export type PointsProps = { checkoutPoints: State & DP };

const mapDispatchToProps = (
  dispatch: Dispatch<Action>,
  { requestContext }: ContextProps
): DP => ({
  fetchPoints: fetchPoints(dispatch, requestContext),
  updatePointsInput: points => dispatch({ type: "UPDATE_POINTS_INPUT", points })
});

const fetchPoints = (dispatch: Dispatch<Action>, context: Context) => (
  checkoutInput: ICheckoutInput
): Promise<void> => {
  dispatch({ type: "WILL_FETCH_POINTS_DATA" } as Action);

  return makeService(CheckoutService, context)
    .getPoints(checkoutInput)
    .then(
      data => {
        dispatch({ type: "FETCH_POINTS_DATA", data } as Action);
      },
      error => {
        dispatch({ type: "FETCH_POINTS_DATA_ERROR", error } as Action);
      }
    );
};

const withPoints = compose<React.ComponentType<any>>(
  withContext,
  connect(
    ({ checkout: { points } }: { checkout: CheckoutState }) => points,
    mapDispatchToProps,
    (s, d, o) => ({
      checkoutPoints: {
        ...s,
        ...d
      },
      ...(o as any)
    })
  )
);

const selectCannotUsePoints = (
  availableUsePoint: IAvailableUsePoint | null
): boolean => (availableUsePoint && availableUsePoint.cannotUsePoint) || false;

const selectNotice = (
  availableUsePoint: IAvailableUsePoint | null
): string | null | undefined =>
  availableUsePoint && availableUsePoint.noticeInfo;

export type PointsState = State;
export type PointsAction = Action;

export {
  initialState as pointsInitialState,
  reducer as pointsReducer,
  withPoints,
  selectCannotUsePoints,
  selectNotice
};
