import { put, call, select, takeLatest, all } from "redux-saga/effects";

import { didRefreshProfile } from "./profile";
import { getProfileFormData } from "../reducers/profileForm";
import * as actions from "../actions/profileForm";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* submitProfileForm() {
  try {
    const formData = yield select(getProfileFormData);
    const data = yield call(Api.updateProfile, formData);
    yield put(actions.submitSuccess(data));
  } catch (error) {
    yield put(actions.submitFailure(error));
  }
}

function* watchSubmitProfileForm() {
  yield all([
    takeLatest(types.PROFILE_FORM_WILL_SUBMIT, submitProfileForm),
    takeLatest(types.PROFILE_FORM_DID_SUBMIT, didRefreshProfile)
  ]);
}

export default watchSubmitProfileForm;

export { submitProfileForm };
