import React from "react";
import { connect } from "react-redux";
import { CheckoutState } from "./reducer";

type State = {
  shippingAddress: boolean;
  billingAddress: boolean;
};

const initialState: State = {
  shippingAddress: false,
  billingAddress: false
};

type Action =
  | {
      type: "SET_CHECKOUT_SHIPPING_ADDRESS_EDITOR_STATE";
      open: boolean;
    }
  | {
      type: "SET_CHECKOUT_BILLING_ADDRESS_EDITOR_STATE";
      open: boolean;
    };

const reducer = (s = initialState, a: Action): State => {
  switch (a.type) {
    case "SET_CHECKOUT_SHIPPING_ADDRESS_EDITOR_STATE":
      return {
        ...s,
        shippingAddress: a.open
      };
    case "SET_CHECKOUT_BILLING_ADDRESS_EDITOR_STATE":
      return {
        ...s,
        billingAddress: a.open
      };
    default:
      return s;
  }
};

type Dispatcher = {
  setShippingEditorState: (open: boolean) => void;
  setBillingEditorState: (open: boolean) => void;
};

export type WithInvalidAddressFormEditableProps = {
  invalidAddressFormEditable: State & Dispatcher;
};

const withInvalidAddressFormEditable = <P extends {}>(
  C: React.ComponentType<P & WithInvalidAddressFormEditableProps>
): React.ComponentType<P> =>
  connect<State, Dispatcher, any, WithInvalidAddressFormEditableProps, any>(
    ({
      checkout: { invalidAddressFormEditable }
    }: {
      checkout: CheckoutState;
    }) => invalidAddressFormEditable,
    {
      setShippingEditorState: (open: boolean) => ({
        type: "SET_CHECKOUT_SHIPPING_ADDRESS_EDITOR_STATE",
        open
      }),
      setBillingEditorState: (open: boolean) => ({
        type: "SET_CHECKOUT_BILLING_ADDRESS_EDITOR_STATE",
        open
      })
    },
    (s, d, o) => ({
      invalidAddressFormEditable: {
        ...s,
        ...d
      },
      ...(o as any)
    })
  )(C as any);

export type InvalidAddressFormEditableState = State;
export {
  reducer as invalidAddressFormEditableReducer,
  initialState as invalidAddressFormEditableInitialState,
  withInvalidAddressFormEditable
};
