import {
  _ORDER_ITEM_PROP_TO_DISCOUNT_CONTEXTS_FIELD_MAP,
  _getFirstValueOfProtoProperties
} from "./checkout";
import { parseNumberString } from "../helper";

const _WISH_ITEM_PROP_TO_PROPERTY_FIELD_MAP = {
  ..._ORDER_ITEM_PROP_TO_DISCOUNT_CONTEXTS_FIELD_MAP,
  price: "priceWithTax",
  sellingPrice: "sellingPriceWithTax",
  discountRate: "calculatedDiscountRate",
  productPath: "productPath",
  productColorImageURL: "colorImageUrl"
};

const mapProtoWishItemToWishItemDataProp = (protoWishItem = {}) => {
  const {
    article_code: articleCode,
    product_name: productName,
    product_image_url: productImageURL,
    properties
  } = protoWishItem;

  const {
    productPath,
    productSize,
    productColor,
    productColorImageURL,
    price,
    sellingPrice,
    discountRate
  } = _getFirstValueOfProtoProperties(
    properties,
    _WISH_ITEM_PROP_TO_PROPERTY_FIELD_MAP
  );

  return {
    id: articleCode,
    path: productPath,
    name: productName,
    imageURL: productImageURL,
    sizeName: productSize,
    colorName: productColor,
    colorImageURL: productColorImageURL,
    price: parseNumberString(price),
    sellingPrice: parseNumberString(sellingPrice),
    discountRate: parseNumberString(discountRate)
  };
};

export { mapProtoWishItemToWishItemDataProp };
