import * as React from "react";

import { Link } from "react-router-dom";

import {
  CHECKOUT_SHIPPING_PATH,
  CHECKOUT_LOGIN_PATH,
} from "../../constants/Paths";

const CheckoutButton = ({
  disabled,
  isGuestUser,
  onClick,
}: {
  disabled: boolean;
  isGuestUser?: boolean | null;
  onClick?: () => void;
}) =>
  disabled ? (
    <>
      <button className="checkout-button primary-btn disabled">
        ご購入手続き
      </button>
    </>
  ) : (
    <Link
      to={isGuestUser ? CHECKOUT_LOGIN_PATH : CHECKOUT_SHIPPING_PATH}
      className="primary-btn checkout-button"
      onClick={onClick}
    >
      ご購入手続き
    </Link>
  );

export { CheckoutButton };
