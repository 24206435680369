import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";

export const newCustomError = error => ({
  error: "CUSTOM_ERROR",
  value: error
});

export const formatDateString = (dateString = "") => {
  const date = parseISO(dateString);
  if (!isValid(date)) {
    return "Invalid Date";
  }
  return format(date, "yyyy-MM-dd");
};

// parseNumberString exchange a `String` type of number value to a `Number` type value.
export const parseNumberString = numberStr =>
  isNaN(Number(numberStr)) ? undefined : Number(numberStr);
