import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";
import { getId, isCancellable } from "../mappers/orders";

const initialState = {
  ids: [],
  data: {},
  ui: {
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.ORDERS_WILL_REFRESH:
    case types.ORDERS_WILL_CANCEL:
      return { ...s, fetching: true, error: null };
    case types.ORDERS_DID_REFRESH_FAILED:
    case types.ORDERS_DID_CANCEL_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.ORDERS_DID_REFRESH:
    case types.ORDERS_DID_CANCEL:
      return { ...s, fetching: false, error: null };
    default:
      return s;
  }
};

const ids = (s = initialState.ids, a) => {
  switch (a.type) {
    case types.ORDERS_DID_REFRESH:
      return (a.data || []).map(r => getId(r));
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.ORDERS_DID_REFRESH:
      return (a.data || []).reduce((result, r) => {
        result[getId(r)] = r;
        return result;
      }, s);
    case types.ORDERS_DID_CANCEL:
      return { ...s, [getId(a.data)]: { ...a.data } };
    default:
      return s;
  }
};

export default combineReducers({ ui, ids, data });

const getOrderById = (state, id) => state.orders.data[id];

const getOrderIsCancellableById = (state, id) =>
  isCancellable(getOrderById(state, id));

export { initialState, getOrderById, getOrderIsCancellableById };
