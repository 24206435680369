import React from "react";

import {
  DataProps,
  OrderServiceProps,
  checkoutForm,
  selectAbilityIsEnabled,
} from "../../../orders";
import { Form } from "../../../form";

import { theplant } from "../../../proto";
import { Selector } from "../Selector";
import { getDate } from "../helper";
type IDeliveryDateRange = theplant.ec.service.orders.IDeliveryDateRange;
const DeliveryMethod = theplant.ec.api.orders.DeliveryMethod;

const input = Form.on(checkoutForm, "form");

const deliveryInfoForm = Form.on(input, "deliveryInfo");

const specifiedForm = Form.on(deliveryInfoForm, "specified");
const specifiedDateForm = Form.on(deliveryInfoForm, "specifiedDate");
const specifiedHoursForm = Form.on(deliveryInfoForm, "specifiedHours");

interface HomeDeliveryOptionsProps {
  data: DataProps["data"]["data"];
  checkout: OrderServiceProps["checkout"];
  isAmazonPay?: boolean;
}

const NormalDeliveryInfo = ({
  deliveryDateRange,
}: {
  deliveryDateRange?: IDeliveryDateRange | null;
}) =>
  deliveryDateRange && deliveryDateRange.dates ? (
    <span>
      最短お届け日：
      {deliveryDateRange && deliveryDateRange.dates[0]}
    </span>
  ) : null;

const HomeDeliveryOptions = ({
  data,
  checkout: {
    input: {
      form: { deliveryInfo, deliveryMethod },
    },
  },
}: HomeDeliveryOptionsProps) => {
  const visible = deliveryMethod === DeliveryMethod.HOME_DELIVERY;
  if (!visible) {
    return null;
  }

  if (!data) {
    return null;
  }

  if (
    !selectAbilityIsEnabled(
      data,
      theplant.ec.api.orders.Ability.CAN_DELIVERY_DATE
    )
  ) {
    return null;
  }

  const deliveryDateRange = data && data.deliveryDateRange;

  if (!deliveryDateRange) {
    return null;
  }

  const { hours } = deliveryDateRange;
  let showDatePicker = true;

  // if admin set cannotSpecify to true, then not show DatePicker
  if (deliveryDateRange.cannotSpecify) {
    showDatePicker = false;
  }

  let filledHours: Array<theplant.ec.service.orders.IDeliveryHour | null> =
    hours || [];
  if (hours && hours.length && hours.length % 4 !== 0) {
    filledHours = filledHours.concat(
      Array.from(Array(4 - (hours.length % 4)).keys()).map(() => null)
    );
  }

  const dateOptions = (deliveryDateRange.dates || [])
    .map((item) => ({
      key: item ? item : "",
      name: item ? `${item} (${getDate(new Date(item)).youbi})` : "",
    }))
    .filter(({ key, name }) => key && name) as [{ key: string; name: string }];

  const hourOptions = (filledHours || [])
    .map((item) => ({
      key: item ? item.value : "",
      name: item ? item.name : "",
    }))
    .filter(({ key, name }) => key && name) as [{ key: string; name: string }];

  return deliveryDateRange && deliveryDateRange.cannotSpecify === true ? (
    <section className="delivery-options white-panel">
      <div className="home-delivery">
        <p>{deliveryDateRange.reason && deliveryDateRange.reason.noticeInfo}</p>
      </div>
    </section>
  ) : (
    <section className="delivery-options white-panel">
      <div className="home-delivery flex-direction-row">
        <span className="block mt-15 mb-15">配送日時指定（任意）</span>
        <NormalDeliveryInfo deliveryDateRange={deliveryDateRange} />

        <specifiedForm.field
          key={JSON.stringify(deliveryInfo)}
          component={({ updateInput: updateIsSpecified }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            React.useEffect(() => {
              if (deliveryInfo) {
                if (
                  !deliveryInfo.specifiedDate &&
                  !deliveryInfo.specifiedHours
                ) {
                  updateIsSpecified(false);
                } else {
                  updateIsSpecified(true);
                }
              }
            }, [updateIsSpecified]);

            return null;
          }}
        />

        {showDatePicker && !!deliveryInfo && (
          <div className="grid-row-2-only">
            <specifiedDateForm.field
              // fixes dates change from start 25 to 22
              key={(deliveryDateRange.dates || "").toString()}
              component={(props) => (
                <Selector
                  {...props}
                  options={dateOptions}
                  label="配送日"
                  placeholder={{
                    value: "",
                    position: 0,
                  }}
                  notUpdateOnTouch
                />
              )}
            />
            <specifiedHoursForm.field
              component={(props) => (
                <Selector
                  {...props}
                  label="配送時間"
                  options={hourOptions}
                  placeholder={{
                    value: "",
                    position: 0,
                  }}
                  notUpdateOnTouch
                />
              )}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export { HomeDeliveryOptions };
