import $ from "jquery";

class Alert {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".alert .close-button").on("click", this.handleClickCloseBtn.bind(this));
  }

  handleClickCloseBtn(event) {
    const $target = $(event.currentTarget);
    $target.parent().fadeOut();
  }
}

export default Alert;
