import React from "react";

import Summary, {
  OrderSummaryDataPropType,
  OrderSummary,
} from "./Order/Summary";
import OrderItem from "./Order/OrderItems/OrderItem";
import { OrderItemDataPropType } from "./Order/OrderItems/OrderItemDataPropType";
import { isDraft } from "../../../mappers/orders";

const Order = ({ data, cancel }: Props) => (
  <div className="order-item white-panel">
    <OrderSummary data={data} cancel={cancel} />
    <div className="cart-info-container">
      {data.orderItems.map((orderItem, idx) => (
        <OrderItem
          key={idx}
          data={orderItem}
          showingNotAvailableWhenOutOfStock={isDraft(data)}
        />
      ))}
    </div>
    <Summary data={data} cancel={cancel} />
  </div>
);

export type OrderDataPropType = OrderSummaryDataPropType & {
  orderItems: Array<OrderItemDataPropType>;
};

type Props = {
  data: OrderDataPropType;
  cancel: () => {};
};

export default Order;
