import PropTypes from "prop-types";
import React from "react";

import Form from "./Form";
import { TextInput, Address } from "../AccountApp/Personal/Common";

class AddressForm extends Form {
  renderUserInfo() {
    const {
      family_name = "",
      given_name = "",
      katakana_family_name = "",
      katakana_given_name = "",
    } = this.props.state.data;

    return (
      <div className="user-info grid-row-2-only grid-gap">
        <TextInput
          label="姓"
          placeholder="田中"
          value={family_name}
          onChange={this.changeField("family_name")}
          errors={this.filterValidationErrors("family_name")}
        />
        <TextInput
          label="名"
          placeholder="太郎"
          value={given_name}
          onChange={this.changeField("given_name")}
          errors={this.filterValidationErrors("given_name")}
        />
        <TextInput
          label="セイ"
          placeholder="タナカ"
          value={katakana_family_name}
          onChange={this.changeField("katakana_family_name")}
          errors={this.filterValidationErrors("katakana_family_name")}
        />
        <TextInput
          label="メイ"
          placeholder="タロウ"
          value={katakana_given_name}
          onChange={this.changeField("katakana_given_name")}
          errors={this.filterValidationErrors("katakana_given_name")}
        />
      </div>
    );
  }

  renderAddress() {
    const {
      zipcode = "",
      prefecture = "",
      city = "",
      address1 = "",
      address2 = "",
      phone = "",
      is_default,
    } = this.props.state.data;

    return (
      <Address
        value={{
          zipcode,
          prefecture,
          city,
          address1,
          address2,
          phone,
          is_default,
        }}
        errors={{
          zipcode: this.filterValidationErrors("zipcode"),
          prefecture: this.filterValidationErrors("prefecture"),
          city: this.filterValidationErrors("city"),
          address1: this.filterValidationErrors("address1"),
          address2: this.filterValidationErrors("address2"),
          phone: this.filterValidationErrors("phone"),
        }}
        onChange={this.props.changeField}
        prefectureOptions={this.props.prefectureOptions}
        newdesign="true"
        noTitle
      />
    );
  }

  // render() is for easy testing only
  render() {
    return (
      <form onSubmit={this.submit}>
        {this.renderUserInfo()}
        {this.renderAddress()}
        <input type="button" onClick={this.props.close} />
        <input type="submit" disabled={this.props.state.ui.fetching} />
      </form>
    );
  }
}

AddressForm.propTypes = {
  state: PropTypes.object,
  prefectureOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  close: PropTypes.func,
  changeField: PropTypes.func,
  submit: PropTypes.func,
};

export default AddressForm;
