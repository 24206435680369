import React from "react";
import { formatCurrency } from "../../components/helpers/currency";

const Price = ({
  price,
  discounted
}: {
  price: number;
  discounted: number;
}) => (
  <div className="pdp-info__price-wrapper">
    {price > discounted && discounted !== 0 ? (
      <p className="price">
        <span className="original" data-price={price}>
          {formatCurrency(price)}
        </span>
        <span className="sale" data-price={discounted}>
          {formatCurrency(discounted)}
          <span className="tax">&nbsp;(税込)</span>
        </span>
      </p>
    ) : (
      <p className="price" data-price={price}>
        <span>
          {formatCurrency(price)}
          <span className="tax">&nbsp;(税込)</span>
        </span>
      </p>
    )}
  </div>
);

export { Price };
