import { theplant } from "../../proto";
type IVariant = theplant.ec.service.products.IVariant;
export type IStoreInfoWithStock = theplant.ec.service.stores.IStoreInfoWithStock;

const SEPERATER = "／";

const displayStores = ({
  originalStores,
  selectedPrefectures
}: {
  originalStores: IStoreInfoWithStock[];
  selectedPrefectures: (string | null)[];
}) => {
  if (selectedPrefectures && selectedPrefectures.length > 0) {
    // filter stores by selected prefectures
    return originalStores.filter(store => {
      if (store.prefecture && selectedPrefectures) {
        if (selectedPrefectures.indexOf(store.prefecture) > -1) {
          return store;
        } else {
          return store.prefecture
            .split(SEPERATER)
            .find(p =>
              selectedPrefectures.find(
                prefecture =>
                  prefecture && prefecture.split(SEPERATER).indexOf(p) > -1
              )
            );
        }
      } else {
        return null;
      }
    });
  }

  return originalStores.filter(({ name, address }) => name && address);
};

const filterPrefecturesByStores = ({
  originalStores,
  prefectures
}: {
  originalStores: IStoreInfoWithStock[];
  prefectures: (string | null)[];
}) => {
  const filterPrefectres: string[] = [];

  originalStores.forEach(store => {
    const storePrefecture = store.prefecture;
    prefectures.forEach(prefecture => {
      if (storePrefecture && prefecture) {
        if (filterPrefectres.indexOf(storePrefecture) > -1) {
          return;
        }

        if (prefectures.indexOf(storePrefecture) === -1) {
          // there are a prefecture name `関東(東京以外)`, which include `東京`
          // refer: https://theplanttokyo.atlassian.net/browse/LAC-1849
          if (prefecture === "東京" && storePrefecture !== prefecture) {
            return;
          } else {
            return filterPrefectres.push(storePrefecture);
          }
        } else {
          if (
            prefecture
              .split(SEPERATER)
              .find(p => storePrefecture.split(SEPERATER).indexOf(p) === -1)
          ) {
            filterPrefectres.push(storePrefecture);
          }
        }
      }

      return;
    });
  });

  return filterPrefectres;
};

const groupByPrefectures = ({
  stores,
  prefectures
}: {
  stores: IStoreInfoWithStock[];
  prefectures: (string | null)[];
}) => {
  let result: Array<{ prefecture: string; stores: IStoreInfoWithStock[] }> = [];

  prefectures.forEach(prefecture => {
    const matchedStores = displayStores({
      originalStores: stores,
      selectedPrefectures: [prefecture]
    });
    if (prefecture && matchedStores && matchedStores.length > 0) {
      result.push({
        prefecture,
        stores: matchedStores
      });
    }
  });

  return result;
};

const getQuarterMinute = (_minute: number) => {
  let minute = ((Math.floor(_minute / 15) * 15) % 60).toString();

  if (minute === "0") {
    minute = "00";
  }

  return minute;
};

const anyStoreInInventory = (variants?: IVariant[] | null) =>
  variants && variants.findIndex(v => !!v.anyStoreInInventory) > -1;

export {
  displayStores,
  filterPrefecturesByStores,
  groupByPrefectures,
  getQuarterMinute,
  anyStoreInInventory
};
