import $ from "jquery";

class Anchor {
  constructor() {
    this.bindEvents();
    this.moveToAnchor();
    this.clickToMoveAnchor();
  }

  bindEvents() {
    $(window).on("hashchange", this.handleHashChange.bind(this));
  }

  scroll(hash) {
    $("html, body").animate(
      {
        scrollTop: $(`[id="${hash}"]`).offset().top - 95,
      },
      400,
      "swing"
    );
  }

  clickToMoveAnchor() {
    // avoid click pdp popup item body scroll .js-product-washing|.js-product-technology|.js-show-size-guide|.js-show-store-inventory-popup
    // refer: https://theplanttokyo.atlassian.net/browse/LAX-4178
    const buttons = document.querySelectorAll(
      `[href*='#']:not([class*='js-'])`
    );

    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];
      button.addEventListener("click", () => {
        const hash = button.getAttribute("href").split("#").pop();
        this.scroll(hash);
      });
    }
  }

  moveToAnchor() {
    const hash = window.location.hash.slice(1);

    if (hash === "") {
      return false;
    }

    if ($(`[id="${hash}"]`).length !== 0) {
      this.scroll(hash);
    }
  }

  handleHashChange() {
    const hash = window.location.hash.slice(1);

    if ($(`[id="${hash}"]`).length !== 0) {
      this.scroll(hash);
    }
  }
}

export default Anchor;
