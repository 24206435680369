import React from "react";
import ProductPrice from "../shared/ProductPrice";
import { OrderItemDataPropType } from "../AccountApp/Orders/Order/OrderItems/OrderItemDataPropType";
import {
  NotAvailable,
  isOutOfStock,
} from "../AccountApp/Orders/Order/OrderItems/OrderItemAmount";

const Price = (props: Props) => {
  const { data } = props;
  const { price, sellingPrice, discountRate } = data;

  return !isOutOfStock(data) ? (
    <ProductPrice data={{ price, sellingPrice, discountRate }} />
  ) : (
    <NotAvailable />
  );
};

const CartItem = (props: Props) => {
  const { data } = props;
  if (!data) {
    return <li className="cart-widget__item"></li>;
  }

  const {
    productName,
    productPath,
    productImageURL,
    productSize,
    productColor,
    quantity,
  } = data;

  return (
    <li className="cart-widget__item">
      <figure className="cart-widget__item-figure">
        <a href={productPath}>
          <img src={productImageURL} alt={productName} />
        </a>
      </figure>
      <div className="cart-widget__item-desc">
        <a
          className="cart-widget__item-name"
          href={productPath}
          title={productName}
        >
          <p className="paragraph">{productName}</p>
        </a>
        <div className="cart-widget__item-price paragraph">
          <Price {...props} />
        </div>
        <p className="cart-widget__item-info paragraph">
          カラー：
          <img
            src={productImageURL.replace(".mn.", ".ca.")}
            alt={productName}
            className="mr-8"
            loading="lazy"
          />
          {productColor}
        </p>
        <p className="cart-widget__item-info paragraph">
          サイズ：{productSize}
        </p>
        <p className="cart-widget__item-info paragraph">数量：{quantity}</p>
      </div>
    </li>
  );
};

type Props = {
  data: OrderItemDataPropType;
};

export default CartItem;
export { Price };
