export const GOOGLE_MAP_SCRIPT_URL_PREFIX =
  "https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=";

export const GOOGLE_MAP_KEY = "AIzaSyBUul4AwgueNpuml1R0pzh3l-K8qCJrNAg";

export const defaultMapCenter = {
  lat: 35.0778398,
  lng: 139.6557092,
};

export const defaultZoomLevel = 4;

export const MAX_RADIUS_FOR_NEARBY_A_POINT = 8000; // m

export const TIMEOUT_FOR_GET_USER_LOCATION = 5000; // milliseconds
