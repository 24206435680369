import PropTypes from "prop-types";
import React from "react";

import { connect } from "react-redux";
import {
  refresh,
  deleteShippingAddress,
} from "../../../actions/shippingAddresses";
import {
  edit,
  newShippingAddress,
  close,
  changeField,
  submit,
  selectAddressAsDefault,
} from "../../../actions/accountShippingAddressForm";
import { CheckedSVG } from "../../shared/svg";
import { initialState as accountShippingAddressFormInitialState } from "../../../reducers/accountShippingAddressForm";

import Addresses from "./ShippingAddresses/Addresses";
import AddressForm from "./ShippingAddresses/AddressForm";

import helpers from "../../helpers";
import { useAddSuccess } from "../../../components/hooks";

const ShippingAddresses = (props) => {
  const { refresh, accountShippingAddressFormState } = props;
  const [editAddress, setEditAddress] = React.useState(
    !!accountShippingAddressFormState.data.id
  );
  const { success, addSuccess } = useAddSuccess();

  React.useEffect(() => {
    refresh();
  }, [refresh]);

  const openEditForm = () => {
    setEditAddress(true);
  };

  const closeEditForm = () => {
    setEditAddress(false);
  };

  return (
    <>
      {!editAddress && (
        <Addresses
          shippingAddresses={props.shippingAddresses}
          deleteAddress={props.deleteShippingAddress}
          editAddress={props.edit}
          selectAddressAsDefault={props.selectAddressAsDefault}
          fetching={props.fetching}
          openEditForm={openEditForm}
        />
      )}
      <div className="white-panel">
        {!props.accountShippingAddressFormState.ui.isOpen ? (
          editAddress ? null : (
            <div className="add-new-panel">
              {success && (
                <div className="add-success-message">
                  <CheckedSVG color="#439F7A" />
                  <p>新しい配送先住所が追加されました。</p>
                </div>
              )}
              <button
                type="button"
                className="outline-btn add-address"
                onClick={props.newShippingAddress}
              >
                配送先を追加する
              </button>
            </div>
          )
        ) : (
          <AddressForm
            state={props.accountShippingAddressFormState}
            prefectureOptions={props.prefectureOptions}
            close={props.close}
            changeField={props.changeField}
            submit={() => {
              props.submit(() => {
                if (!editAddress) {
                  addSuccess();
                }
                closeEditForm();
              });
            }}
            closeEditForm={closeEditForm}
            title={editAddress ? "配送先情報を編集" : "配送先情報を追加"}
          />
        )}
      </div>
    </>
  );
};

ShippingAddresses.defaultProps = {
  accountShippingAddressFormState: accountShippingAddressFormInitialState,
  refresh: () => null,
};

ShippingAddresses.propTypes = {
  shippingAddresses: PropTypes.arrayOf(PropTypes.object),
  prefectureOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

const mapStateToProps = ({
  shippingAddresses,
  prefectures,
  accountShippingAddressForm,
}) => {
  const prefectureNameToJapaneseMap = helpers.mapToNameAndJapanese(
    prefectures.data
  );

  return {
    shippingAddresses: helpers.getShippingAddresses(
      shippingAddresses,
      prefectureNameToJapaneseMap
    ),
    prefectureOptions: helpers.getPrefectureOptions(prefectures.data),
    accountShippingAddressFormState: accountShippingAddressForm,
    fetching: (accountShippingAddressForm.ui || {}).fetching || false,
  };
};

export default connect(mapStateToProps, {
  refresh,
  edit,
  newShippingAddress,
  close,
  submit,
  changeField,
  deleteShippingAddress,
  selectAddressAsDefault,
})(ShippingAddresses);

export { mapStateToProps, ShippingAddresses };
