import * as React from "react";

import { theplant } from "../../proto";
import { withContext, ContextProps } from "@theplant/ecjs/context";

import { withConfig, ConfigProps } from "../../config";
import { initSmartpayMessaging } from "../../js/smartpayMessage";
import { IData } from "../../orders";

export interface IProps {
  buttonClick: (
    e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>
  ) => void;
  cart?: theplant.ec.service.orders.IOrder | null;
  data: IData | null;
  domRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const SmartpayButton = (props: ConfigProps & ContextProps & IProps) => {
  const { cart, data, domRef } = props;

  const price = cart?.sumUp?.billingAmountWithTax;
  React.useEffect(() => {
    initSmartpayMessaging(".checkout-smartpay-container", {
      amount: String(price),
      typeClass: "smartpay-osm-payment-method",
    });
  }, [price]);

  const beforeGoPay = (
    e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>
  ) => {
    e?.preventDefault();
    props.buttonClick(e);
  };

  return (
    <>
      <div className="checkout-smartpay-container"></div>
      <p className="desc">
        ※smartpayのアプリ、またはウェブサイトへ移動します。
      </p>
      <p className="desc">
        ※決済中はブラウザの戾るボタンで戾ったり、決済の途中で中断しないでください。正常にご注文をお受けすることができなくなります。{" "}
      </p>

      <div ref={domRef}>
        <a
          href={data?.smartPayRedirectUrl || ""}
          className="checkout-smartpay-link"
          onClick={beforeGoPay}
        >
          <button className="checkout-smartpay-button">
            <img src="/img/smartpay-logo.png" alt="smartpay" />
            <span>でお支払い {">"}</span>
          </button>
        </a>
      </div>
    </>
  );
};

const withConfigSmartpayButton = withConfig<IProps>(
  withContext(SmartpayButton)
);

export { withConfigSmartpayButton as SmartpayButton };
