import React from "react";
import cx from "classnames";
import { theplant, proto } from "../../proto";
import { getSize, getJapanSize, getEuropeSize } from "../productField";
import { anyStoreInInventory, getDisplaySizeVariants } from "../helper";

const matchedJapanSizes = ["XS", "S", "M", "L", "XL", "XXL"];

// "XS", "S", "M", "L", "XL", "XXL"
function isShortSizeName(variants: theplant.ec.service.products.IVariant[]) {
  return variants.every((sv) => {
    const europeSize = getEuropeSize(sv.filterProperties) || "";
    const japanSize = getJapanSize(sv.filterProperties) || "";
    return matchedJapanSizes.includes(japanSize) && europeSize === "";
  });
}

// xx.xcm(xx)
function isLongSizeName(variants: theplant.ec.service.products.IVariant[]) {
  return variants.every((sv) => {
    const europeSize = getEuropeSize(sv.filterProperties) || "";
    const japanSize = getJapanSize(sv.filterProperties) || "";
    return japanSize.indexOf("cm") > -1 && europeSize !== "";
  });
}

const formatSize = (japanSize: string | null, europeSize: string | null) => {
  let displaySize = japanSize;
  matchedJapanSizes.forEach((s) => {
    if (s === japanSize && europeSize) {
      displaySize = `${japanSize}(${europeSize})`;
      return;
    } else {
      return;
    }
  });

  if (japanSize && japanSize.indexOf("cm") > -1 && europeSize) {
    displaySize = `${japanSize}(${europeSize})`;
  }

  return displaySize;
};

const getSizeOrders = () => {
  const sizeDoms = document.querySelectorAll(
    ".pdp-info .detail-info .pdp-info__size-option"
  );
  let sizeOrders: string[] = [];
  if (sizeDoms) {
    sizeDoms.forEach((sizeDom) => {
      const size = sizeDom && sizeDom.getAttribute("data-size");
      if (size) {
        sizeOrders.push(size);
      }
    });
  }

  return sizeOrders;
};

const SizeSelection = ({
  variants,
  changeSize,
  selectedSizeCode,
  sizeError,
}: {
  variants: theplant.ec.service.products.IVariant[];
  changeSize: (size?: string | null) => void;
  selectedSizeCode?: string | null;
  sizeError?: proto.ValidationError.IFieldViolation | null;
}) => {
  const sizeOrders = getSizeOrders();
  const displaySizeVariants = getDisplaySizeVariants({ variants, sizeOrders });

  return (
    <div className="sizes pdp-info__sizes">
      <label className="title size-">
        サイズを選択：
        {sizeError &&
          (sizeError.code === "noSelectedSizeCode" ||
            sizeError.code === "noSelectedColorCode") && (
            <span className="error inline-block">
              サイズを選択してください。
            </span>
          )}
      </label>
      <ul
        className={cx("grid-row-4-only", {
          "sizes__ul--five-columns": isShortSizeName(displaySizeVariants),
          "sizes__ul--two-columns": isLongSizeName(displaySizeVariants),
        })}
      >
        {displaySizeVariants.map((sv, index) => {
          const size = getSize(sv.filterProperties);
          const europeSize = getEuropeSize(sv.filterProperties);
          const japanSize = getJapanSize(sv.filterProperties);
          const displaySize = formatSize(japanSize, europeSize);

          return (
            <li key={sv.articleCode || index}>
              <label className="size-item">
                <input
                  data-quantity-in-stock={sv.inInventory}
                  data-any-store-in-inventory={sv.anyStoreInInventory}
                  data-article-code={sv.articleCode}
                  data-size={size}
                  data-japan-size={japanSize}
                  data-europe-size={europeSize}
                  className={`size-option ${
                    size === selectedSizeCode ? "selected" : ""
                  }`}
                  name="inventory-size"
                  type="radio"
                  value={String(size)}
                  data-stock={sv.inventoryQuantity}
                  onChange={() => changeSize(size)}
                  disabled={!anyStoreInInventory([sv]) && !sv.inventoryQuantity}
                />
                {displaySize ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: displaySize,
                    }}
                  />
                ) : null}
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { SizeSelection };
