import React from "react";

import { theplant } from "../../../../proto";
type IStore = theplant.ec.service.stores.IStore;

const List = ({
  visible,
  stores,
  updateStoreId,
}: {
  visible: boolean;
  stores: IStore[];
  updateStoreId: (id: string) => void;
}) => (
  <div
    className="store-list"
    style={visible ? {} : { visibility: "hidden", opacity: 0, zIndex: -1 }}
  >
    {(stores || []).map((store, idx) => (
      <div className="shop-info" key={`store-${idx}`}>
        <h4>
          <span className="index">{idx + 1}</span>
          {store.name}
        </h4>
        <p>
          〒{store.zip}
          <br />
          {store.city}
          {store.address}
          <br />
          電話: {store.phone}
        </p>
        <button
          type="button"
          className="outline-btn"
          onClick={() => {
            if (!!store.id && store.id !== "") {
              updateStoreId(store.id);
            }
          }}
        >
          この店舗で受け取る
        </button>
      </div>
    ))}
  </div>
);

export { List };
