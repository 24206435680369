import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.PROFILE_WILL_REFRESH });

const refreshSuccess = data => ({ type: types.PROFILE_DID_REFRESH, data });

const refreshFailure = error => ({
  type: types.PROFILE_DID_REFRESH_FAILED,
  error
});

export { refresh, refreshSuccess, refreshFailure };
