import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  toggleChecked,
  deleteWishItems,
  moveToCart,
} from "../../actions/accountWishItems";
import { refresh } from "../../actions/wishItems";
import { initialState } from "../../reducers/accountWishItems";
import { getWishItems } from "../helpers/wishItems";
import { EEaddToCart } from "../../gtm/GA";

import ToolsBar from "./Wishlist/ToolsBar";
import WishItem, { WishItemDataProps } from "./Wishlist/WishItem";

type ItemProps = {
  wishItems: WishItemDataProps[];
  _isChecked: (id: string) => boolean;
  _toggleCheckedWishItem: (id: string) => () => boolean;
};

const Items = (props: ItemProps) => (
  <div className="grid-row mt-24">
    {props.wishItems.map((wishItem, idx) => (
      <WishItem
        key={idx}
        data={wishItem}
        checked={props._isChecked(wishItem.id)}
        onChange={props._toggleCheckedWishItem(wishItem.id)}
      />
    ))}
  </div>
);

const EmptyWishlistContent = ({ wishItems }: Pick<Props, "wishItems">) => {
  if (wishItems.length > 0) {
    return null;
  }

  return (
    <div className="empty-wishlist white-panel">
      <h2>お気に入りリストには何も入っていません</h2>
      <div className="grid-row-2">
        <div>
          <p>
            商品詳細ページから
            <br />
            お気に入りボタンで
            <br />
            このリストに商品を追加できます。
            <br />
            <img
              src="/img/button-pointer.png"
              alt="button pointer"
              style={{ width: 161, height: 54 }}
            />
          </p>
        </div>
        <div>
          <p>
            ご購入の商品が決まりましたら、
            <br />
            お気に入りリストから簡単に
            <br />
            カートに移動ボタンで購入できます。
            <br />
            <img
              src="/img/tocart-button.png"
              alt="to cart button"
              style={{ width: 183, height: 54 }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

const Wishlist = (props: Props) => {
  useEffect(() => {
    props.refresh();
    // eslint-disable-next-line
  }, []);

  const _isChecked = (id: string) => {
    const { checkedIds } = props.accountWishItemsState;
    return checkedIds.indexOf(id) !== -1;
  };

  const _toggleCheckedWishItem = (id: string) => {
    return () => props.toggleChecked(id);
  };

  const _hasCheckedIds = () => {
    const { checkedIds } = props.accountWishItemsState;
    return checkedIds.length !== 0;
  };

  const _onDelete = () => {
    if (_hasCheckedIds()) {
      props.deleteWishItems();
    }
  };

  const _onMoveToCart = () => {
    if (_hasCheckedIds()) {
      const moveToCartItemsUploadData = getGAUploadData(props.wishItems);

      props.moveToCart();

      EEaddToCart(moveToCartItemsUploadData);
    }
  };

  const getGAUploadData = (wishItems: Array<WishItemDataProps>) => {
    let moveToCartItemsUploadData: any = [];

    wishItems.forEach((item) => {
      if (_isChecked(item.id)) {
        moveToCartItemsUploadData.push({
          id: item.path.split("/")[2],
          name: item.name,
          price: item.sellingPrice || item.price,
          brand: "aigle",
          variant: item.id,
          quantity: 1, // hard-code to 1
        });
      }
    });

    return moveToCartItemsUploadData;
  };

  const { wishItems } = props;

  return (
    <div className="tab-content">
      <div className="wishlist-container">
        {wishItems.length > 0 ? (
          <Items
            wishItems={wishItems}
            _toggleCheckedWishItem={_toggleCheckedWishItem}
            _isChecked={_isChecked}
          />
        ) : (
          <EmptyWishlistContent wishItems={props.wishItems} />
        )}
      </div>
      {wishItems.length > 0 && (
        <ToolsBar
          checkedWishItemsCount={props.accountWishItemsState.checkedIds.length}
          onDelete={_onDelete}
          onMoveToCart={_onMoveToCart}
        />
      )}
    </div>
  );
};

Wishlist.defaultProps = {
  accountWishItemsState: { ...initialState },
  fetching: false,
  wishItems: [],
  refresh: () => null,
};

type Props = {
  accountWishItemsState: { checkedIds: string };
  fetching: boolean;
  wishItems: Array<WishItemDataProps>;
  refresh: () => {};
  toggleChecked: (id: string) => boolean;
  deleteWishItems: () => {};
  moveToCart: () => {};
};

const mapStateToProps = ({ wishItems, accountWishItems }: any) => {
  return {
    wishItems: getWishItems(wishItems),
    fetching: wishItems.ui.fetching,
    accountWishItemsState: accountWishItems,
  };
};

export default connect(mapStateToProps, {
  refresh,
  toggleChecked,
  deleteWishItems,
  moveToCart,
})(Wishlist as any);

export { Wishlist };
