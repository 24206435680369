import React from "react";
import { Errors } from "../Errors";

import { proto } from "../../../proto";

/**
 * The order is not fresh when:
 * - user confirmed the order in another browser
 * - user logout in another tab
 * - user changed the password(all the sessions are forced logout)
 *
 * As we discussed, put an error on the Field: `OrderItem` for above situation
 * ref: https://theplant.slack.com/archives/CC3BTEQ3G/p1542351782016000
 */
const OrderNotFreshError = ({ error }: { error?: proto.IValidationError }) => {
  const err =
    error && error.fieldViolations
      ? error.fieldViolations.filter(e => e.field === "Form.OrderItem")
      : [];

  if (err.length === 0) {
    return null;
  }

  return <Errors errors={err} />;
};

export { OrderNotFreshError };
