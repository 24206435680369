import $ from "jquery";
import shave from "shave";
import debounce from "lodash.debounce";
import "selectric";

class BlogPage {
  constructor() {
    this.bindEvents();
    this.showTabByURLHash();
    this.initShave();
    $(window).on("resize", debounce(this.initShave, 250));

    $(`.tab-select`).selectric({
      nativeOnMobile: false,
      disableOnMobile: false
    });
  }

  initShave() {
    const length = $(".js-blog-preview").length;
    for (let i = 0; i < length; i++) {
      const element = $(".js-blog-preview")[i];
      const articleId = $(element).data("id");

      let textlength = 50;
      if (
        $(window).width() >= 768 &&
        $(element)
          .parent()
          .parent()
          .hasClass("big-banner-article")
      ) {
        textlength = 160;
      }
      shave(element, textlength, {
        spaces: false,
        character: `<span>...&nbsp;&nbsp;&nbsp;</span><a href="/topics/article/${articleId}">続きを読む</a>`
      });

      if (!$(element).hasClass("shaved")) {
        $(element).addClass("shaved");
      }
    }
  }

  showTabByURLHash() {
    const hash = window.location.hash.slice(1);
    if (hash !== "" && $(`[name=${hash}].tab`).length !== 0) {
      $(`[name=${hash}].tab`).click();
    }
  }

  bindEvents() {
    $(".tab").on("click", this.handleClickTab.bind(this));
    $(".tab-select").on("change", this.handleSelectTab.bind(this));
    $(".blog-top-page").on(
      "click",
      ".js-load-more",
      this.handleClickLoadMore.bind(this)
    );
    $(window).on("hashchange", this.showTabByURLHash);
  }

  handleClickTab(event) {
    const $target = $(event.currentTarget);
    if ($target.hasClass("active")) return;

    $(".tab").removeClass("active");
    $target.addClass("active");

    $(".tab-select").val($target.index());

    $(".tab-content").removeClass("active");
    $(".tab-content")
      .eq($target.index())
      .addClass("active");

    this.initShave();
  }

  handleSelectTab(event) {
    const $target = $(event.currentTarget);
    const index = $target.find("option:selected").val();

    $(".tab").removeClass("active");
    $(".tab")
      .eq(index)
      .addClass("active");

    $(".tab-content").removeClass("active");
    $(".tab-content")
      .eq(index)
      .addClass("active");
  }

  handleClickLoadMore(event) {
    const $target = $(event.currentTarget);
    $target.hide();
    const self = this;

    const url = $target.data("url");
    $.ajax({
      url,
      success: function(result) {
        $(result).appendTo($target.parent().parent());
        $target.parent().remove();
        self.initShave();
      }
    });
  }
}

export default BlogPage;
