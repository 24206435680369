// check waiting pay order when is login user at cart
// when it has waiting pay order, redirect to confirm page
import { ContextProps, withContext } from "@theplant/ecjs/context";
import { IServiceError, makeService } from "@theplant/ecjs/prottp";
import React, { useEffect, useState } from "react";
import { theplant } from "../../proto";
import { Loading } from "../Loading";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CHECKOUT_CONFIRMATION_PATH } from "../../constants/Paths";
import { withConfirmResult, ConfirmResultProps } from "../../orders";

export const callCheckWaitingPayOrder = ({
  requestContext,
  resolve,
  reject,
}: ContextProps & {
  resolve: (result: theplant.ec.api.orders.ConfirmResult) => void;
  reject: () => void;
}) => {
  makeService(theplant.ec.api.orders.CheckoutService, {
    ...requestContext,
    // API error like 404 is called in context.onError
    // https://github.com/theplant/ecjs/blob/af908c30179d6280f0299e7e5beece181cb911f4/packages/ecjs/prottp/index.ts#L124
    onError: (_: IServiceError) => {
      reject();
      return null;
    },
  })
    .checkWaitingPayOrder({})
    .then(resolve)
    .catch(reject);
};

const CheckWaitingPayOrder = withRouter(
  withContext(
    withConfirmResult(
      ({
        isGuestUser = true, // passed value is undefiend at first, set default value to true
        requestContext,
        history,
        confirmResult: { onConfirmOrder },
      }: { isGuestUser?: boolean | null } & RouteComponentProps<{}> &
        ContextProps &
        ConfirmResultProps) => {
        const [fetching, setFetching] = useState(false);
        const clearFetching = () => {
          setFetching(false);
        };

        useEffect(() => {
          if (!isGuestUser) {
            // only call checkWaitingPayOrder when is login user
            setFetching(true);
            callCheckWaitingPayOrder({
              requestContext,
              resolve: (result) => {
                setFetching(false);
                if (result?.order?.code) {
                  // after call check waiting pay order api
                  // backend will mark this order as confirmed
                  // need to go to thank you page for user to see order has finished
                  onConfirmOrder(result);
                  history.replace(CHECKOUT_CONFIRMATION_PATH);
                }
              },
              reject: clearFetching,
            });
          }

          return () => {
            setFetching(false);
          };
        }, [isGuestUser, history, onConfirmOrder, requestContext]);

        if (fetching) {
          return <Loading />;
        }

        return null;
      }
    )
  )
);

export { CheckWaitingPayOrder };
