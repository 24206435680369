import { useCallback, useEffect, useState } from "react";
import { _jsonRequest as jsonRequest } from "../../../Api";
import { scrollToTop } from "./scrollToTop";

export function useFavStore() {
  const [fetching, setFetching] = useState(true);
  const [favStore, setFavStore] = useState<any>(null);

  const setFavStoreWrap = useCallback(
    (param) => {
      setFavStore(param);
      scrollToTop();
    },
    [setFavStore]
  );

  useEffect(() => {
    setFetching(true);
    jsonRequest("GET", "/account/mystore.json")
      .then((favStore) => {
        setFavStore(favStore);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  return {
    fetching,
    favStore,
    setFavStoreWrap,
  };
}
