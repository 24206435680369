import PropTypes from "prop-types";
import React from "react";
import CommonAddressForm from "../../../shared/AddressForm";
import { CancelBtn, SubmitBtn } from "../Button";
import { PanelTitle } from "../PanelTitle";

class AddressForm extends CommonAddressForm {
  render() {
    return (
      <form className="reg-form" onSubmit={this.submit}>
        <PanelTitle title={this.props.title} />
        <div>
          {this.renderUserInfo()}
          {this.renderAddress()}
        </div>
        <div className="reg-form-btn-group flex flex-end">
          <CancelBtn
            close={() => {
              this.props.close();
              this.props.closeEditForm();
            }}
          />
          <SubmitBtn disabled={this.props.state.ui.fetching} />
        </div>
      </form>
    );
  }
}

AddressForm.propTypes = {
  state: PropTypes.object,
  prefectureOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  close: PropTypes.func,
  changeField: PropTypes.func,
  submit: PropTypes.func,
  title: PropTypes.string,
};

export default AddressForm;
