import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import EditForm from "./Profile/EditForm";
import { Loading } from "../Loading";

import { changeField, submit } from "../../../actions/profileForm";

import { initialState as prefecturesInitialState } from "../../../reducers/prefectures";
import { initialState as profileFormInitialState } from "../../../reducers/profileForm";

const MustUpdateProfile = (props: any) => {
  const { showing, loading } = props;

  return showing ? (
    <div className="aigle-popup">
      <div className="aigle-popup-container">
        <div className="aigle-popup-content must-update-profile">
          <h3>登録内容をご確認ください</h3>
          {loading ? (
            <div className="inline-loading">
              <Loading />
            </div>
          ) : (
            <EditForm
              {...props}
              hiddenEmailForm={true}
              hiddenPasswordForm={true}
              hiddenCloseButton={true}
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};

MustUpdateProfile.defaultProps = {
  showing: false,
  loading: true,
  state: { ...profileFormInitialState },
  prefecturesState: { ...prefecturesInitialState },
};

MustUpdateProfile.propTypes = {
  showing: PropTypes.bool,
  loading: PropTypes.bool,
  state: PropTypes.object,
  prefecturesState: PropTypes.object,
  submit: PropTypes.func,
  changeField: PropTypes.func,
};

const mapStateToProps = ({
  mustUpdateProfile,
  prefectures,
  profileForm,
  profile,
}: any) => ({
  showing: mustUpdateProfile.ui.showing,
  loading: prefectures.ui.fetching || profile.ui.fetching,
  prefecturesState: prefectures,
  state: profileForm,
});

export default connect(mapStateToProps, {
  changeField,
  submit,
})(MustUpdateProfile);

export { MustUpdateProfile };
