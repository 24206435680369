import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const initialState = {
  ids: [],
  data: {},
  ui: {
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.WISH_ITEMS_WILL_REFRESH:
      return { ...s, fetching: true, error: null };
    case types.WISH_ITEMS_DID_REFRESH_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.WISH_ITEMS_DID_REFRESH:
      return { ...s, fetching: false, error: null };
    default:
      return s;
  }
};

const ids = (s = initialState.ids, a) => {
  switch (a.type) {
    case types.WISH_ITEMS_DID_REFRESH:
      return (a.data || []).map(r => r.id);
    case types.WISH_ITEMS_DID_DELETE:
      return s.slice().filter(id => a.ids.indexOf(id) < 0);
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.WISH_ITEMS_DID_REFRESH:
      return (a.data || []).reduce((result, r) => {
        result[r.id] = r;
        return result;
      }, s);
    default:
      return s;
  }
};

export default combineReducers({ ui, ids, data });

export { initialState };
