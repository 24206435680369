import React from "react";

import queryString from "query-string";
import Modal from "react-modal";
import urlencode from "urlencode";

import { CHECKOUT_COMBINI_PATH } from "../../../constants/Paths";
import { Errors } from "../../shared/Errors";

import { DataProps, selectAbilityIsEnabled } from "../../../orders/data";
import { OrderServiceProps, checkoutForm } from "../../../orders/order";
import { Form } from "../../../form";

import { theplant } from "../../../proto";
import { InlineLoading } from "../../Loading";
const DeliveryMethod = theplant.ec.api.orders.DeliveryMethod;
const Ability = theplant.ec.api.orders.Ability;

const input = Form.on(checkoutForm, "form");
const storeInfoForm = Form.on(input, "storeInfo");

interface Props {
  data: DataProps["data"]["data"];
  checkout: OrderServiceProps["checkout"];
}

// Ref: https://plainjs.com/javascript/styles/getting-width-and-height-of-an-element-23/
const getIsInDesktop = () =>
  (window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth) >= 768;

class CombiniDeliveryOptions extends React.Component<
  Props,
  { isModalOpen: boolean; isInDesktop: boolean }
> {
  state = { isModalOpen: false, isInDesktop: false };

  openModal = () => {
    this.setState({ isModalOpen: true, isInDesktop: getIsInDesktop() });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const {
      data,
      checkout: {
        specifyDeliveryMethod,
        input: {
          form: { deliveryMethod }
        }
      }
    } = nextProps;

    const methodSelected = deliveryMethod === DeliveryMethod.CONVENIENCE_PICKUP;
    const canCombiniPickUp = selectAbilityIsEnabled(
      data,
      Ability.CAN_CONVENIENCE_PICKUP
    );

    // combini pick up is disabled, preset home delivery
    if (methodSelected && !canCombiniPickUp) {
      specifyDeliveryMethod(DeliveryMethod.HOME_DELIVERY);
    }
  }

  handleMessage = (event: MessageEvent) => {
    if (event.origin === window.location.origin) {
      if (event.data && event.data.combiniResult) {
        // Ref: https://docs.google.com/spreadsheets/d/1ZR_JDSHnuwX8ziptH-gq2loeMucJKUcky_36M-N0t7M/edit#gid=498302548
        // PC＝EUC-JP Smartphone＝UTF-8 feature phone＝Shift-JIS
        // ※ not related to input enc
        const combiniResult = urlencode.decode(
          event.data.combiniResult,
          getIsInDesktop() ? "EUC-JP" : "UTF-8"
        );

        const combiniInfo = queryString.parse(combiniResult) as {
          [key: string]: string | undefined;
        };

        this.props.checkout.updateCombiniInfo({
          sid: combiniInfo.sid || null,
          sname: combiniInfo.sname || null,
          saddr: combiniInfo.saddr || null,
          spt: combiniInfo.spt || null,
          stflg: combiniInfo.stflg || null,
          uid: combiniInfo.uid || null,
          sjarea: combiniInfo.sjarea || null,
          szcd: combiniInfo.szcd || null,
          stel: combiniInfo.stel || null,
          scode: combiniInfo.scode || null,
          spare3: combiniInfo.spare3 || null,
          spare4: combiniInfo.spare4 || null,
          spare5: combiniInfo.spare5 || null,
          spare6: combiniInfo.spare6 || null,
          spare7: combiniInfo.spare7 || null,
          spare8: combiniInfo.spare8 || null,
          spare9: combiniInfo.spare9 || null,
          spare10: combiniInfo.spare10 || null
        });

        this.closeModal();
      }
    }
  };

  render() {
    const {
      data,
      checkout: {
        input: {
          form: { deliveryMethod, storeInfo }
        }
      }
    } = this.props;

    const methodSelected = deliveryMethod === DeliveryMethod.CONVENIENCE_PICKUP;
    const canCombiniPickUp = selectAbilityIsEnabled(
      data,
      Ability.CAN_CONVENIENCE_PICKUP
    );
    if (!methodSelected || !canCombiniPickUp) {
      return null;
    }

    const isStoreSelected = storeInfo && Object.keys(storeInfo).length > 0;

    return (
      <section className="combini-finder">
        {isStoreSelected && storeInfo ? null : (
          <button className="outline-btn" onClick={this.openModal}>
            受取りコンビニを選択
          </button>
        )}

        {isStoreSelected && storeInfo && (
          <div className="selected-store">
            <h3 className="section-title">選択中のコンビニ：</h3>
            <div className="shop-info">
              <h4>{storeInfo.sname}</h4>
              <p>
                〒{storeInfo.szcd}
                <br />
                {storeInfo.saddr}
                <br />
                電話：
                {storeInfo.stel}
              </p>
              <button
                type="button"
                className="outline-btn"
                onClick={this.openModal}
              >
                受け取りコンビニを変更
              </button>
            </div>
          </div>
        )}

        <p
          className={`desc ${
            isStoreSelected && storeInfo ? "" : "text-center"
          }`}
        >
          ※ご利用の際には
          <a href="/guide#shipping" target="_blank">
            注意事項
          </a>
          をご確認ください。
        </p>

        <storeInfoForm.field
          component={({ dirty, errors }) => (
            <Errors errors={dirty ? errors : []} />
          )}
        />

        <Modal
          isOpen={this.state.isModalOpen}
          contentLabel="Combini finder modal"
          overlayClassName="modal-overlay"
          className="combini-finder-modal modal-content-wrapper"
          onRequestClose={this.closeModal}
          closeTimeoutMS={300}
        >
          {/* eslint-disable-next-line */}
          <a
            className="close-popup-btn"
            onClick={this.closeModal}
            role="button"
            aria-label="閉じる"
          >
            閉じる
          </a>

          <InlineLoading />

          {/* Ref: https://docs.google.com/spreadsheets/d/1ZR_JDSHnuwX8ziptH-gq2loeMucJKUcky_36M-N0t7M/edit#gid=0 */}
          <iframe
            title="sagawa"
            frameBorder="0"
            src={`https://www.e-map.ne.jp/${
              this.state.isInDesktop ? "p" : "smt"
            }/sagawa02/?rurl=${window.location.origin + CHECKOUT_COMBINI_PATH}`}
          />
        </Modal>
      </section>
    );
  }
}

export { CombiniDeliveryOptions };
