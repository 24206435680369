import $ from "jquery";

class Faq {
  constructor() {
    this.bindEvents();
    this.moveToAnchor();
  }

  bindEvents() {
    $(".js-toggle-article-button").on(
      "click",
      this.handleClickToggleBtn.bind(this)
    );
    $(window).on("hashchange", this.handleHashChange.bind(this));
  }

  handleClickToggleBtn(event) {
    const $target = $(event.currentTarget);
    if ($target.parent().hasClass("opened")) {
      $target.parent().removeClass("opened");
      $target.text("詳細を見る");
      $target
        .parent()
        .next()
        .slideUp();
    } else {
      $target.parent().addClass("opened");
      $target.text("閉じる");
      $target
        .parent()
        .next()
        .slideDown();
    }
  }

  moveToAnchor() {
    const hash = window.location.hash.slice(1);

    if (hash === "") {
      return false;
    }

    showSection(hash);

    if ($(`[name="${hash}"]`).length !== 0) {
      $("html, body").animate({
        scrollTop: $(`[name="${hash}"]`).offset().top - 95
      });
    } else if ($("#" + hash).length !== 0) {
      const name = $("#" + hash).closest("article").prev().attr("name");
      showSection(name);
    }

    function showSection(name) {
      $(`[name="${name}"]`).addClass("opened");
      $(`[name="${name}"] .js-toggle-article-button`).text("閉じる");
      $(`[name="${name}"]`)
        .next()
        .show();
    }
  }

  handleHashChange() {
    const hash = window.location.hash.slice(1);

    if (!$(`[name="${hash}"]`).hasClass("opened")) {
      $(`${`[name="${hash}"]`} .js-toggle-article-button`).click();
    }
    if ($(`[name="${hash}"]`).length !== 0) {
      $("html, body").animate({
        scrollTop: $(`[name="${hash}"]`).offset().top - 95
      });
    }
  }
}

export default Faq;
