import { put, call, takeLatest, all, select } from "redux-saga/effects";

import { getCreditCardById } from "../reducers/creditCards";
import * as actions from "../actions/creditCards";
import * as types from "../constants/ActionTypes";
import Api from "../Api";
import { confirm } from "./common";
import * as messages from "../constants/Messages";

function* refresh() {
  const { response, error } = yield call(Api.getCreditCards);

  if (error) {
    yield put(actions.refreshFailure(error));
  } else {
    yield put(actions.refreshSuccess(response));
  }
}

function* deleteCreditCard({ id, cb }) {
  const confirmed = yield call(confirm, messages.CREDIT_CARD_DELETE_CONFIRM);

  if (confirmed) {
    const { error } = yield call(Api.deleteCreditCard, id);

    if (error) {
      yield put(actions.deleteFailure(error));
    } else {
      yield put(actions.deleteSuccess(id));
      if (cb) {
        cb();
      }
    }
  }
}

function* loadForm({ id }) {
  const data = yield select(getCreditCardById, id);
  yield put(actions.load(data));
}

function* watch() {
  yield all([
    takeLatest(types.CREDIT_CARDS_WILL_REFRESH, refresh),
    takeLatest(types.CREDIT_CARDS_WILL_DELETE, deleteCreditCard),
    takeLatest(types.ACCOUNT_CREDIT_CARD_FORM_EDIT, loadForm),
  ]);
}

export default watch;

export { refresh, deleteCreditCard };
