import React from "react";
import { RemoveFilter } from "../atoms/svgs";
import { theplant } from "../../proto";
import { PrefectureProps } from "./usePrefecture";
import {
  displayStores,
  filterPrefecturesByStores,
  groupByPrefectures
} from "./helper";
import { StoreItem } from "./StoreItem";
import { storeInventoryButtonClick } from "../../gtm/storeInventoryClickEvent";

export type IStoreInfoWithStock = theplant.ec.service.stores.IStoreInfoWithStock;

const phoneButtonClick = () => {
  storeInventoryButtonClick("phone");
};

const mapButtonClick = () => {
  storeInventoryButtonClick("map");
};

const StoreList = ({
  stores,
  prefectures
}: {
  stores: IStoreInfoWithStock[];
  prefectures: (string | null)[];
}) => {
  const displayStoreGroupedByPrefectures = groupByPrefectures({
    stores: stores.filter(
      store => typeof store.stock === "number" && store.stock > 0
    ),
    prefectures: prefectures
  });

  return (
    <ul className="store-with-stock-items">
      {displayStoreGroupedByPrefectures.map(({ prefecture, stores }, index) => (
        <li
          className="store-and-prefecture-wrapper"
          key={`${prefecture}-${index}`}
        >
          <p className="group-prefecture-title">{prefecture}</p>
          <ul className="store-wrapper">
            {stores.map((store, index) => (
              <StoreItem
                key={store.storeId || index}
                store={store}
                phoneButtonClick={phoneButtonClick}
                mapButtonClick={mapButtonClick}
              />
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

const PrefectureList = ({
  prefectures,
  selectedPrefectures,
  add,
  remove
}: PrefectureProps) => (
  <>
    <div className="prefecture-wrapper">
      {prefectures.map(p => (
        <span
          className="prefecture-item"
          key={String(p)}
          onClick={() => {
            if (p) {
              add(p);
            }
          }}
        >
          {p}
        </span>
      ))}
    </div>
    <div className="prefecture-wrapper">
      {selectedPrefectures.map((p: string) => (
        <span
          className="prefecture-selected-item"
          key={p}
          onClick={() => remove(p)}
        >
          {p}
          <RemoveFilter />
        </span>
      ))}
    </div>
  </>
);

const StoresAndPrefectures = ({
  stores: originalStores,
  domRef,
  prefectures,
  selectedPrefectures,
  add,
  remove,
  clearSelectedPrefecture
}: {
  stores: IStoreInfoWithStock[];
  domRef: React.RefObject<HTMLDivElement>;
} & PrefectureProps) => {
  const [stores, setStores] = React.useState<IStoreInfoWithStock[]>(
    originalStores
  );

  React.useEffect(() => {
    const filterStores = displayStores({
      originalStores,
      selectedPrefectures
    });

    setStores(filterStores);
  }, [originalStores, selectedPrefectures]);

  const displayPrefectures = filterPrefecturesByStores({
    prefectures,
    originalStores
  });

  return (
    <div ref={domRef}>
      {originalStores.length > 0 ? (
        <>
          <h3 className="store-and-prefecture-list-title">在庫状況一覧</h3>
          <PrefectureList
            prefectures={displayPrefectures}
            selectedPrefectures={selectedPrefectures}
            add={add}
            remove={remove}
            clearSelectedPrefecture={clearSelectedPrefecture}
          />
          <StoreList stores={stores} prefectures={displayPrefectures} />
        </>
      ) : null}
    </div>
  );
};

export default StoresAndPrefectures;
export { StoreList };
