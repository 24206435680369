import React from "react";
import Profile from "./Personal/Profile";
import EmailAndPassword from "./Personal/EmailAndPassword";
import ShippingAddresses from "./Personal/ShippingAddresses";
import CreditCards from "./Personal/CreditCards";
import MustUpdateProfile from "./Personal/MustUpdateProfile";
import Points from "./Points";
import { Barcode } from "./Personal/Barcode";

const Personal = () => (
  <div className="tab-content">
    <div className="white-panel">
      <Barcode />
      <Points onlySharedPart />
    </div>
    <Profile />
    <MustUpdateProfile />
  </div>
);

export default Personal;
export { EmailAndPassword, ShippingAddresses, CreditCards };
