
import _ from "lodash";
import $ from "jquery";

class HeroBanner {
    constructor() {
        this.displayMobileImage();
        const win = $(window);
        win.on('resize', _.debounce(this.displayMobileImage, 200));
    }

    displayMobileImage() {
        const pictures = document.querySelectorAll('.banner-image-picture');
        for(let i =0; i < pictures.length; i++) {
            const picture = pictures[i];
            if(picture) {
                // find mobile image under the picture
                const mobileImg = picture.querySelector('img.mobile');
                let mobileSrc;
                if(mobileImg) {
                    mobileSrc = mobileImg.getAttribute("data-src");
                }
                const desktopImg = picture.querySelector('img.default');
                let desktopSrc;
                if(desktopImg) {
                    desktopSrc = desktopImg.getAttribute("data-src");
                }
                const windowSize = window.innerWidth;
                if(mobileImg) {
                    if(windowSize < 768 && windowSize >= 320) {
                        // when mobile display, set picture padding-bottom
                        desktopImg.setAttribute('src', "");
                        desktopImg.setAttribute('style', "display: none;");
                        picture.setAttribute('style', 'padding-bottom:126.666667%;');
                        mobileImg.setAttribute('src', mobileSrc);
                        mobileImg.setAttribute('style', "display: block;");
                    } else {
                        // when mobile not display, reset set picture padding-bottom
                        mobileImg.setAttribute('src', "");
                        mobileImg.setAttribute('style', "display: none;");
                        picture.setAttribute('style', 'padding-bottom:45.138889%;'); 
                        desktopImg.setAttribute('src', desktopSrc);
                        desktopImg.setAttribute('style', "display: block;");
                    }            
                } else {
                    // when not have mobile, reset set picture padding-bottom
                    picture.setAttribute('style', 'padding-bottom:45.138889%;'); 
                    desktopImg.setAttribute('src', desktopSrc);
                    desktopImg.setAttribute('style', "display: block;");
                }
            }
        }
    }
}

export default HeroBanner;