import * as React from "react";
import { CartProps } from "../../orders/cart";
import { MAX_CART_ITEM_QUANTITY } from "../../constants/Misc";
import { uploadCartItemQuantityChangeToGTM } from "../../gtm/GA";
import { alert } from "../../js/shared/alert";

import { theplant } from "../../proto";
type IOrderItem = theplant.ec.service.orders.IOrderItem;

const QuantitySelect = ({
  orderItem,
  onChange
}: {
  orderItem: IOrderItem;
  onChange: CartProps["cart"]["updateQuantity"];
}) => {
  const quantity = Number(orderItem.quantity || 0);
  const quantityInStock = Number(orderItem.quantityInStock || 0);
  const maxQuantity =
    quantityInStock < MAX_CART_ITEM_QUANTITY
      ? quantityInStock
      : MAX_CART_ITEM_QUANTITY;

  const quantityOptions = [...Array(maxQuantity).keys()].map(i => (
    <option key={i + 1} value={i + 1}>
      {i + 1}
    </option>
  ));

  if (quantity > maxQuantity && quantity <= quantityInStock) {
    quantityOptions.push(
      <option key={quantity} value={quantity}>
        {quantity}
      </option>
    );
  }

  return (
    <select
      className="num-select"
      value={quantity}
      onChange={e => {
        const { articleCode } = orderItem;
        if (articleCode) {
          const updateQuantity = parseInt(e.target.value, 10);

          onChange(articleCode, updateQuantity)
            .then(() => {
              uploadCartItemQuantityChangeToGTM({
                orderItem,
                originalQuantity: quantity,
                updateQuantity
              });
            })
            .catch(e => {
              console.log({
                msg: "Error happen when update cart item quantity",
                api: "theplant.ec.api.orders.CartService/setVariantCount",
                error: e,
                articleCode: articleCode || "",
                variantInventory: quantityInStock,
                cartQuantity: quantity
              });

              if (
                e.errors.code ===
                theplant.ec.api.ErrorCode[
                  theplant.ec.api.ErrorCode.ADD_AMOUNT_LIMIT_PRODUCT
                ]
              ) {
                alert(e.errors.msg);
              }
            });
        }
      }}
    >
      {quantityOptions}
    </select>
  );
};

export { QuantitySelect };
