import $ from "jquery";
import "slick-carousel";
import ProductCard from "./productCard";

class Recommendations {
  constructor() {
    this.initSlick();
  }

  initSlick() {
    $(".recommendations .product-box").slick({
      infinite: true,
      swipe: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      cssEase: "ease-in-out",
      responsive: [
        {
          breakpoint: 1279,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            swipe: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            swipeToSlide: true,
            swipe: true,
            centerMode: true,
            initialSlide: 1
          }
        }
      ]
    });

    $(".recommendations .product-box").on("breakpoint", function() {
      new ProductCard();
    });
  }
}

export default Recommendations;
