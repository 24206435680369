import $ from "jquery";

class BackToTop {
  constructor() {
    $(".js-back-to-top").on("click", this.handleClick.bind(this));

    this.showOnScroll();
  }

  showOnScroll() {
    $(window).on("scroll", () => {
      if ($(window).scrollTop() <= 0) {
        $(".back-to-top").removeClass("active", { duration: 500 });
      } else if (!$(".header").hasClass("active")) {
        $(".back-to-top").addClass("active", { duration: 500 });
      }
    });
  }

  handleClick() {
    $("html, body").animate({
      scrollTop: 0,
    });
  }
}

export default BackToTop;
