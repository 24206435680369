import React, { useEffect } from "react";

import JsBarcode from "jsbarcode";
import { connect } from "react-redux";

const Barcode = connect(({ profile }: { profile: any }) => ({ profile }))(
  ({ profile }) => {
    useEffect(() => {
      const user = profile.data;
      if (user) {
        JsBarcode(".barcode", user.memberNumber, {
          fontSize: 12,
          lineColor: "#000",
          textMargin: 8,
        });
      }
    }, [profile.data]);

    const user = profile.data;

    return (
      <div className="barcode-area tab-panel grid-gap">
        {user && (
          <>
            <div className="content-title name">{`${user.familyName || ""} ${
              user.givenName || ""
            } 様`}</div>
            <svg
              className="barcode full-width mb-24"
              jsbarcode-format="upc"
              jsbarcode-textmargin="0"
            ></svg>
          </>
        )}
      </div>
    );
  }
);

export { Barcode };
