import { gtmDataLayerPush } from "./index";

const storeInventoryButtonClick = (label: string) => {
  gtmDataLayerPush({
    event: "StoreInventoryButtonClick",
    eventlabel: label
  });
};

export { storeInventoryButtonClick };
