import $ from "jquery";

class Dropdown {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".js-dropdown__btn").on("click", this.handleClickBtn.bind(this));
    $(".js-dropdown__content").on("click", this.handleClickContent.bind(this));

    $(document).click(this.handleClickOutside.bind(this));
  }

  handleClickBtn(event) {
    event.stopPropagation();

    const $target = $(event.currentTarget);
    const shouldAddClass = $target.hasClass("active") === false;

    $(".js-dropdown__btn").removeClass("active");
    if (shouldAddClass) {
      $target.addClass("active");
    }
  }

  handleClickContent(event) {
    event.stopPropagation();
  }

  handleClickOutside() {
    $(".js-dropdown__btn").removeClass("active");
  }
}

export default Dropdown;
