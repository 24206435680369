import { BRAND_TO_ISSUER_MAP } from "../constants/Misc";
import { ZERO_ORDER } from "../constants/Protos";
import { CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP } from "../constants/Texts";
import { parseNumberString } from "../helper";

// message CreditCardInput {
//     string customer_id = 1; // optional, this value will set by API, refer: https://theplant.slack.com/archives/lacoste_aigle/p1484212378000048
//     string name = 2;
//     string brand = 3;       // optional, this isn't useful field. The brand (or issuer) is auto detected by paygent.
//     string number = 4;
//     uint64 expiry_year = 5;
//     uint64 expiry_month = 6;
//     string cvc = 7;         // optional, this field is provided by paygent.
// }
//
// Refer: https://github.com/theplant/ec/blob/cd2f681b59dbd80279099e4fd910aa7f97a2f5d4/orders/proto/spec.proto#L112-L120
const mapCheckoutCreditCardFormStateToProtoCreditCardInput = (
  formData = {}
) => {
  const { name, number, cvc } = formData;
  let { expiry_year, expiry_month } = formData;

  expiry_year = parseNumberString(expiry_year);
  expiry_month = parseNumberString(expiry_month);

  return {
    name,
    number: number ? number.replace(/[^\d]/g, "") : undefined,
    expiry_year,
    expiry_month,
    cvc,
  };
};

// message PaymentInfo {
//     PaymentType ptype = 1;
//     string credit_card_id = 2;
//     string credit_card_customer_id = 3;
//     string credit_card_number = 4;
//     string credit_card_brand = 5;
// }
//
// Refer: https://github.com/theplant/ec/blob/cd2f681b59dbd80279099e4fd910aa7f97a2f5d4/orders/proto/spec.proto#L74-L80
const mapProtoPaymentInfoToState = (paymentInfo = {}) => {
  const {
    ptype: paymentType,
    credit_card_number: number,
    credit_card_brand: issuer,
  } = paymentInfo;

  return {
    paymentType,
    creditCard: { number, issuer },
  };
};

// message Order {
//     string code = 1;
//     string customer_id = 2;
//     // Cart cart = 3;
//     repeated OrderItem order_items = 4;
//     GiftWrapping gift_wrapping = 5;
//     bool need_gift_card = 6;
//     string gift_card_text_1 = 7;
//     string gift_card_text_2 = 8; Address shipping_address = 9;
//     Address billing_address = 10;
//     string delivery_date = 11;
//     string delivery_service = 12; // 8~12时; 16~18时
//     OrderState state = 13;
//     string exported_to_trinet_at = 14;
//     Coupon coupon = 15;
//     int64 used_points = 16;
//     string shipped_at = 18; // 发货时间
//     string shippment_id = 19; // 发货号
//     string checkout_at = 20; // 下单时间
//     string cancelled_at = 21;
//     PaymentInfo payment_info = 22;
//     PaymentTransaction payment_transaction = 23;
//     repeated PaymentGatewayCalls payment_gateway_calls = 24;
//     ShippingType shipping_type = 25;
//     SumUp sum_up = 26; // 订单小结
// }
//
// Refer: https://github.com/theplant/ec/blob/cd2f681b59dbd80279099e4fd910aa7f97a2f5d4/orders/proto/spec.proto#L22-L48
const mapProtoOrderToCheckoutState = (order = {}) => ({
  ...ZERO_ORDER,
  ...order,
  paymentInfo: mapProtoPaymentInfoToState(order.payment_info),
});

// message CreditCard {
//     string id = 1;
//     string customer_id = 2;
//     string brand = 3;
//     string number = 4;
//     string name = 5;
//     string expiry_date = 6;
// }
//
// Refer: https://github.com/theplant/ec/blob/cd2f681b59dbd80279099e4fd910aa7f97a2f5d4/orders/proto/spec.proto#L122-L129
const mapProtoCreditCardToState = (creditCard = {}) => ({
  ...creditCard,
  prettyNumber: creditCard.number
    ? creditCard.number.replace(/\*+/, "••••••••")
    : undefined,
  issuer: BRAND_TO_ISSUER_MAP[creditCard.brand],
});

const _getProtoProperties = (
  properties = [],
  variableNameToPropertyFieldMap = {}
) => {
  const fieldToPropertyMap = properties.reduce((mapped, property = {}) => {
    mapped[property.field] = property;
    return mapped;
  }, {});

  const variables = {};
  for (let variableName in variableNameToPropertyFieldMap) {
    if ({}.hasOwnProperty.call(variableNameToPropertyFieldMap, variableName)) {
      const field = variableNameToPropertyFieldMap[variableName];
      variables[variableName] = (fieldToPropertyMap[field] || {}).values || [];
    }
  }

  return variables;
};

const _getFirstValueOfProtoProperties = (
  properties = [],
  variableNameToPropertyFieldMap = {}
) => {
  const variables = _getProtoProperties(
    properties,
    variableNameToPropertyFieldMap
  );
  const firstValueResult = {};

  for (let name in variables) {
    if ({}.hasOwnProperty.call(variables, name)) {
      firstValueResult[name] = variables[name][0];
    }
  }

  return firstValueResult;
};

//
// "discount_contexts": [
//     { "field": "Collection", "values": [ "CL4" ] },
//     { "field": "Gender", "values": [ "レディース" ] },
//     { "field": "MadeCountry", "values": [ "バングラディッシュ" ] },
//     { "field": "Material", "values": [ "表面ナイロン100％裏面：PUコーティング" ] },
//     { "field": "Note1", "values": [ "AI" ] },
//     { "field": "Season", "values": [ "17SS" ] },
//     { "field": "Technology", "values": [ "T20/T15" ] },
//     { "field": "Type", "values": [ "F2" ] },
//     { "field": "Washing", "values": [ "110/200/300/500/600" ] },
//     { "field": "ids", "values": [ "42" ] },
//     { "field": "ColorCode", "values": [ "007" ] },
//     { "field": "ColorGroup", "values": [ "緑" ] },
//     { "field": "ColorName", "values": [ "FORET" ] },
//     { "field": "JapanSize", "values": [ "XL" ] },
//     { "field": "MainColor", "values": [ "TRUE" ] },
//     { "field": "Note1", "values": [ "ZBFG04500700L" ] },
//     { "field": "Size", "values": [ "00L" ] },
//     { "field": "SizeTable", "values": [ "筒周り: 90, ヒール: 60, 高さ: 100, 足幅: 533" ] }
// ]
const _ORDER_ITEM_PROP_TO_DISCOUNT_CONTEXTS_FIELD_MAP = {
  colorCode: "ColorCode",
  productSize: "JapanSize",
  productColor: "ColorName",
};

// ProtoOrderItem:
//
// message OrderItem {
//     string article_code = 1;
//     string product_code = 2;
//     uint64 price = 3;
//     uint64 selling_price = 4;
//     uint64 quantity = 5;
//     string product_name = 6;
//     string product_image_url = 7;
//     repeated Property discount_contexts = 8; // includes both product.FilterProperties and variant.FilterProperties
//     repeated Property extra_properties = 9;
//     OrderItemSumUp sum_up = 10; // 订单商品小结
// }
//
// message OrderItemSumUp {
//     repeated AppliedDiscount discounts = 1;
//     uint64 actual_price = 2;
//     uint64 actual_price_with_tax = 3;
//     uint64 price_with_tax = 8;
//     uint64 amount = 4;
//     uint64 actual_amount = 5;
//     uint64 tax_rate = 6;
//     uint64 calculated_discount_rate = 7;
// }
//
// Refer: https://github.com/theplant/ec/blob/2a2c1414e4081fedeb6391d07d0a0c08a1e403e5/orders/proto/spec.proto#L51-L62
//
// State refer `OrderItemDataProps` in front/src/components/CheckoutApp/OrderItems/OrderItem.js
const mapProtoOrderItemToOrderItemDataProps = (protoOrderItem = {}) => {
  const {
    article_code: articleCode,
    product_code: productCode,
    product_name: productName,
    product_image_url: productImageURL,
    quantity,
    quantity_in_stock,
    discount_contexts: discountContexts,
    restricted_reasons: restrictedReasons,
  } = protoOrderItem;

  const { colorCode, productSize, productColor } =
    _getFirstValueOfProtoProperties(
      discountContexts,
      _ORDER_ITEM_PROP_TO_DISCOUNT_CONTEXTS_FIELD_MAP
    );

  const {
    prime = {},
    discounted = {},
    calculated_discount_rate: discountRate,
  } = protoOrderItem.sum_up || {};
  const { price_with_tax: price, amount_with_tax: amount } = prime;
  const { price_with_tax: sellingPrice, amount_with_tax: actualAmount } =
    discounted;

  return {
    articleCode,
    productCode,
    productPath:
      productCode && colorCode
        ? `/products/${productCode}/${colorCode}`
        : undefined,
    productName,
    productImageURL,
    productSize,
    productColor,
    quantity: quantity || 0,
    quantityInStock: quantity_in_stock || 0,
    price,
    sellingPrice,
    discountRate,
    amount,
    actualAmount,
    restrictedReasons,
    discountContexts,
  };
};

// NOTE: The sum up item orders should be:
// const ITEMS_IN_TOP = ['QUOTE_AMOUNT', 'DELIVERY_FEE', 'GIFT_WRAPPING_FEE', 'COD_FEE', 'ADJUSTMENT_FEE', 'TOTAL_AMOUNT'];
// const ITEMS_IN_BOTTOM = ['POINTS_DEDUCTION', 'GIFT_CARD_DEDUCTION', 'TOTAL_DISCOUNT', 'BILLING_AMOUNT'];
//
// Refers:
// * ProtoSumUp: https://github.com/theplant/ec/blob/af1de3af035d2d288104628117d467f00eab2af5/orders/proto/spec.proto#L218-L233
// * SumUpSubItemDataProp: https://github.com/theplant/aigle/blob/c65e4da13907c1687e8ce505a8f3864d45f06e9a/aigleapp/front/src/components/CheckoutApp/Summary/SumUp.js#L22-L26
//
// mapProtoSumUpToSumUpSubItemDataProps accepts a ProtoSumUp object and return a list of `SumUpSubItemDataProp`s.

const mapProtoSumUpToSumUpSubItemDataProps = (sumUp = {}) => {
  const {
    quote_amount_with_tax: quoteAmount,
    billing_amount_with_tax: billingAmount,
    sub_items: subItems,
  } = sumUp;

  let displaySubItems = [];

  displaySubItems.push({ name: "QUOTE_AMOUNT", amount: quoteAmount });

  (subItems || [])
    .filter(
      // COUPON_DISCOUNT & PROMOTION_DISCOUNT are contained in TOTAL_DISCOUNT
      ({ name }) => name !== "COUPON_DISCOUNT" && name !== "PROMOTION_DISCOUNT"
    )
    .forEach(({ amount, name }) => {
      // Should not display TOTAL_DISCOUNT when the amount is 0,
      // requested at https://github.com/theplant/lacoste3/issues/1616#issuecomment-455094556.
      if (name === "TOTAL_DISCOUNT" && Number(amount || 0) === 0) {
        return;
      }

      name &&
        displaySubItems.push({
          name,
          amount,
        });
    });

  displaySubItems.push({ name: "BILLING_AMOUNT", amount: billingAmount });

  return displaySubItems
    .map((subItem, idx) => ({
      ...subItem,
      label: CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP[subItem.name],
    }))
    .filter(({ label }) => !!label);
};

const mapProtoOrderItemToIsEmployeeDiscountProps = (order_items = []) => {
  let isEmployee = false;
  order_items.forEach((item) => {
    (item.discount_contexts || []).forEach((discountContext) => {
      if (
        discountContext.field === "IsEmployee" &&
        discountContext.values[0] === "TRUE"
      ) {
        isEmployee = true;
      }
    });
  });

  return isEmployee;
};

export {
  mapCheckoutCreditCardFormStateToProtoCreditCardInput,
  mapProtoOrderToCheckoutState,
  mapProtoPaymentInfoToState,
  mapProtoCreditCardToState,
  _getProtoProperties,
  _getFirstValueOfProtoProperties,
  _ORDER_ITEM_PROP_TO_DISCOUNT_CONTEXTS_FIELD_MAP,
  mapProtoOrderItemToOrderItemDataProps,
  mapProtoSumUpToSumUpSubItemDataProps,
  mapProtoOrderItemToIsEmployeeDiscountProps,
};
