import React from "react";

import { formatCurrency } from "../../components/helpers/currency";

import { CheckoutProps } from "../.";
import { CartSummaryAndConfirmButton } from "../shared/CartSummaryAndConfirmButton";
import { Points } from "../shared/Points";
import { RadioInput } from "../shared/RadioInput";
import { Errors } from "../shared/Errors";
import { PaymentList } from "../shared/PaymentList";
import { CreditCardInput, CVCInput } from "./CreditCardInput";
import { EmptyCart } from "../Cart/EmptyCart";
import { AmazonPayRadio } from "./AmazonPayRadio";
import { SmartpayRadio } from "./PayRadio";

import { getCreditCardError } from "../shared/getCreditCardError";
import { CHECKOUT_PAYMENT_PATH } from "../../constants/Paths";
import Stepper from "../Molecules/Stepper";

import {
  checkoutForm,
  selectAbilityIsEnabled,
  selectCartIsEmpty,
  selectIsPayingAllAmountWithPoints,
  selectCODFee,
} from "../../orders";

import { Form } from "../../form";
import { UploadCheckoutProgressDataToGTM } from "../../gtm/GA";
import GA4PageView from "../ga4PageView";

import { emitQubitItemsData } from "../Cart";
import { OpluxInPayment } from "./OpluxTag";

import { theplant } from "../../proto";
import { CreditCardItem } from "./ListItem";
import { Loading } from "../Loading";
import { CheckoutErrorContext } from "./CheckoutErrorContext";
import { handlerCreditCardError } from "./helper";

const PaymentType = theplant.ec.service.orders.PaymentType;
const Ability = theplant.ec.api.orders.Ability;

const input = Form.on(checkoutForm, "form");
const creditCardIdForm = Form.on(input, "creditCardId");
const creditCardInputForm = Form.on(input, "creditCardInput");

const CreditCardCVC = () => (
  <input.on
    field="creditCardCvc"
    component={({ value, updateInput, touchInput }) => {
      const { errors: checkoutErrors, isSubmitFailed } =
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useContext(CheckoutErrorContext);

      const checkoutErrorDataMap = handlerCreditCardError(checkoutErrors);
      // CVC Input should only display conf error
      const realError = checkoutErrorDataMap.confError;
      return (
        <div className="credit-card-cvc-field">
          <CVCInput
            value={value || ""}
            updateInput={(input: string) => {
              updateInput(input);
              touchInput();
            }}
          />
          {isSubmitFailed ? <Errors errors={realError} /> : null}
          <div style={{ marginBottom: 20 }} />
        </div>
      );
    }}
  />
);

class Payment extends React.Component<CheckoutProps> {
  readonly state = {
    redirecting: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const {
      checkout: {
        validateInput,
        resetFormState,
        selectCreditCard,
        updateDataForm,
        input: {
          form: { creditCardId },
        },
      },
      data: { data },
    } = this.props;

    // LAX-5313: get paymentType from storage
    const savedPayment =
      sessionStorage.getItem("paymentType") || PaymentType.CREDIT_CARD;
    updateDataForm("paymentType", Number(savedPayment));
    sessionStorage.setItem("paymentType", "");

    // clear all dirty field onEnter shipping page,
    // reset submit state to "new"
    resetFormState();

    // select fisrt credit card by default
    const userCreditCards = data && data.userCreditCards;
    if (
      userCreditCards &&
      userCreditCards.length > 0 &&
      creditCardId === undefined
    ) {
      selectCreditCard(userCreditCards[0].id || "");
    } else {
      selectCreditCard(creditCardId || "");
    }

    validateInput();

    emitQubitItemsData(this.props.cart.cart, "checkout");

    GA4PageView();
  }

  UNSAFE_componentWillReceiveProps(nextProps: CheckoutProps) {
    if (
      this.props.confirmResult.state.type === "fetching" &&
      nextProps.confirmResult.state.type === null &&
      nextProps.confirmResult.result
    ) {
      this.setState({ redirecting: true });
    }
  }

  render() {
    const {
      confirmResult,
      cart: { cart },
      data: { data },
      checkout: {
        input: {
          form: { paymentType, deliveryMethod },
        },
        error,
        submitState,
      },
    } = this.props;

    if (this.state.redirecting) {
      return <Loading />;
    }

    if (selectCartIsEmpty(cart)) {
      return <EmptyCart />;
    }

    const codFee = selectCODFee(data);

    const userCreditCards = data && data.userCreditCards;

    const isPayingAllAmountWithPoints = selectIsPayingAllAmountWithPoints(
      cart,
      paymentType,
      codFee
    );

    const disabledWhenSwitchToAmazonPay =
      paymentType === theplant.ec.service.orders.PaymentType.AMAZON_PAY;

    return (
      <CheckoutErrorContext.Provider
        value={{
          errors: error?.fieldViolations ?? [],
          isSubmitFailed: submitState === "submit-failed",
        }}
      >
        <div className="shipping-screen layout-content relative-position">
          {confirmResult.state.type === "fetching" && <Loading />}

          <input.on
            field="opluxDeviceInfo"
            component={({ updateInput }) => (
              <OpluxInPayment setDeviceInfo={updateInput} />
            )}
          />

          <div className="row">
            <div className="col span-8 padding-md">
              <Stepper
                pathname={CHECKOUT_PAYMENT_PATH}
                disabled={disabledWhenSwitchToAmazonPay}
              />
              <Points
                data={data}
                cart={cart}
                checkout={this.props.checkout}
                disabled={disabledWhenSwitchToAmazonPay}
              />

              <div className="white-panel payment-panel">
                {isPayingAllAmountWithPoints && (
                  <div className="payment-overlay">
                    <p>
                      <span>
                        <svg viewBox="0 0 1024 1024" width={25} height={25}>
                          <path
                            d="M866.133 258.133L362.667 761.6l-204.8-204.8-59.734 61.867 264.534 262.4 563.2-563.2z"
                            fill="#6a1328"
                          />
                        </svg>
                        &nbsp;ポイントで全額お支払いが完了しました。
                      </span>
                      <br />
                      <span>注文を確定するボタンをクリックしてください。</span>
                    </p>
                  </div>
                )}
                <p className="title">お支払い方法を選択してください</p>
                <input.on
                  field="creditCardCvc"
                  component={({ updateInput: updateCreditCardCvc }) => (
                    <input.on
                      field="paymentType"
                      key={codFee.toString()}
                      component={({
                        value: paymentTypeValue,
                        updateInput: updatePaymentTypeInput,
                        touchInput: touchPaymentTypeInput,
                      }) => (
                        <input.on
                          key={(paymentTypeValue || "").toString()}
                          field="creditCardId"
                          component={({ value, updateInput, touchInput }) => {
                            const [newCreditCard, setNewCreditCard] =
                              React.useState(false);

                            React.useEffect(() => {
                              if (
                                ((data && data.userCreditCards) || [])
                                  .length === 0
                              ) {
                                setNewCreditCard(true);
                              }

                              if (value) {
                                setNewCreditCard(false);
                              }

                              // eslint-disable-next-line react-hooks/exhaustive-deps
                            }, []);

                            const onChange = () => {
                              updatePaymentTypeInput(PaymentType.CREDIT_CARD);
                              touchPaymentTypeInput();
                            };

                            return (
                              <div className="payment-methods">
                                <RadioInput
                                  checked={
                                    paymentTypeValue === PaymentType.CREDIT_CARD
                                  }
                                  onChange={onChange}
                                >
                                  <div
                                    className="expand-title"
                                    onClick={onChange}
                                  >
                                    <span>カード</span>
                                    <span className="card-icons">
                                      <PaymentList withAmazonPay={false} />
                                    </span>
                                  </div>
                                  {paymentTypeValue ===
                                    PaymentType.CREDIT_CARD && (
                                    <div className="credit-card-list">
                                      <div className="row">
                                        {(userCreditCards || []).map(
                                          (c, index) => (
                                            <div className="col span-6">
                                              <div key={c.id || ""}>
                                                <CreditCardItem
                                                  {...c}
                                                  selected={c.id === value}
                                                  onClick={(e: any) => {
                                                    e.preventDefault();
                                                    updatePaymentTypeInput(
                                                      PaymentType.CREDIT_CARD
                                                    );
                                                    touchPaymentTypeInput();

                                                    updateInput(c.id || "");
                                                    updateCreditCardCvc(
                                                      undefined
                                                    );
                                                    touchInput();
                                                    setNewCreditCard(false);
                                                  }}
                                                />
                                              </div>

                                              {c.id === value ? (
                                                <CreditCardCVC
                                                  key={c.id || index}
                                                />
                                              ) : null}

                                              {c.id === value && (
                                                <creditCardIdForm.field
                                                  component={({
                                                    errors,
                                                    dirty,
                                                  }) => (
                                                    <Errors
                                                      errors={
                                                        dirty ? errors : []
                                                      }
                                                    />
                                                  )}
                                                />
                                              )}
                                            </div>
                                          )
                                        )}
                                      </div>
                                      {newCreditCard ? null : (
                                        <span
                                          className="mt-15 block underline text-align-right"
                                          onClick={() => {
                                            setNewCreditCard(true);
                                            updateInput("");
                                            touchInput();
                                          }}
                                        >
                                          別のカードを使用する
                                        </span>
                                      )}

                                      {newCreditCard && (
                                        <CreditCardInput
                                          newCreditCard={newCreditCard}
                                          setNewCreditCard={setNewCreditCard}
                                          key={String(newCreditCard)}
                                        />
                                      )}
                                    </div>
                                  )}
                                </RadioInput>

                                {/* for saved credit card payment error */}
                                {value ? (
                                  <creditCardInputForm.field
                                    component={({ errors, dirty }) => {
                                      const { responseField, msg } =
                                        getCreditCardError(errors);

                                      return responseField !==
                                        "card_conf_number" && dirty ? (
                                        <Errors
                                          errors={errors ? errors : []}
                                          msg={msg}
                                        />
                                      ) : null;
                                    }}
                                  />
                                ) : null}
                              </div>
                            );
                          }}
                        />
                      )}
                    />
                  )}
                />

                {deliveryMethod ===
                  theplant.ec.api.orders.DeliveryMethod.HOME_DELIVERY && (
                  <div className="payment-methods">
                    <AmazonPayRadio
                      isPayingAllAmountWithPoints={isPayingAllAmountWithPoints}
                      checkoutInputForm={this.props.checkout.input.form}
                    />
                  </div>
                )}
                {/* Smartpay */}
                {selectAbilityIsEnabled(data, Ability.CAN_APPLY_SMARTPAY) && (
                  <div className="payment-methods">
                    <SmartpayRadio
                      isPayingAllAmountWithPoints={isPayingAllAmountWithPoints}
                      cart={cart}
                      redirectUrl={data?.smartPayRedirectUrl ?? ""}
                      checkoutInputForm={this.props.checkout.input.form}
                      checkout={this.props.checkout}
                      data={data}
                    />
                  </div>
                )}

                <input.on
                  key={selectCODFee(data).toString()}
                  field="paymentType"
                  component={({ value, updateInput, touchInput }) => {
                    return selectAbilityIsEnabled(
                      data,
                      Ability.CAN_APPLY_COD
                    ) ? (
                      <div className="payment-methods">
                        <RadioInput
                          checked={value === PaymentType.COD}
                          onChange={() => {
                            updateInput(PaymentType.COD);
                            touchInput();
                          }}
                        >
                          <div
                            className="expand-title"
                            onClick={() => {
                              updateInput(PaymentType.COD);
                              touchInput();
                            }}
                          >
                            <span>
                              代金引換：{formatCurrency(codFee)}
                              （税込）
                            </span>
                          </div>
                        </RadioInput>
                      </div>
                    ) : null;
                  }}
                ></input.on>
              </div>
            </div>

            <div className="col span-4 white-panel sumup-panel">
              <CartSummaryAndConfirmButton
                cart={cart}
                checkout={this.props.checkout}
                data={this.props.data}
              />
            </div>
          </div>
          <UploadCheckoutProgressDataToGTM order={cart} fieldStep={3} />
        </div>
      </CheckoutErrorContext.Provider>
    );
  }
}

export { Payment };
