// Copied from https://github.com/theplant/lacoste3/blob/master/frontend/product/helper.ts
import { getColorCode, getSize } from "./productField";
import { getProperty } from "../protoHelper";
import { theplant } from "../proto";
type IProduct = theplant.ec.service.products.IProduct;
type IVariant = theplant.ec.service.products.IVariant;
type IProperty = theplant.ec.service.base.IProperty;

// refer: https://github.com/theplant/mastani/blob/3f50a02/frontend/containers/product/helper.ts#L21-L23
const inventoryQuantity = (variant: IVariant): number => {
  return Number(variant.inventoryQuantity) || 0;
};

const calcDiscountRate = (variant: IVariant): number =>
  Number(variant.sumUp && variant.sumUp.calculatedDiscountRate) || 0;

const calcPrice = (
  variant: IVariant
): { price: number; discounted: number } => {
  let price = Number(variant.price || 0),
    discounted = Number(variant.sellingPrice || 0);

  const { extraProperties, sumUp } = variant;

  if (extraProperties) {
    price = Number(getProperty("priceWithTax", extraProperties) || 0);

    discounted = Number(
      getProperty("sellingPriceWithTax", extraProperties) || 0
    );
  }

  if (sumUp) {
    price = Number(sumUp.prime && sumUp.prime.priceWithTax) || 0;

    discounted = Number(sumUp.discounted && sumUp.discounted.priceWithTax) || 0;
  }

  return { price, discounted };
};

const calcPriceWithoutTax = (
  variant: IVariant
): { price: number; discounted: number } => {
  const price: number =
    Number(variant.sumUp && variant.sumUp.prime && variant.sumUp.prime.price) ||
    0;

  const discounted: number =
    Number(
      variant.sumUp &&
        variant.sumUp.discounted &&
        variant.sumUp.discounted.price
    ) || 0;

  return { price, discounted };
};

const calcAmount = (
  variant: IVariant
): { price: number; discounted: number } => {
  const amountWithTax: number =
    Number(
      variant.sumUp && variant.sumUp.prime && variant.sumUp.prime.amountWithTax
    ) || 0;

  const discountedAmountWithTax: number =
    Number(
      variant.sumUp &&
        variant.sumUp.discounted &&
        variant.sumUp.discounted.amountWithTax
    ) || 0;

  return { price: amountWithTax, discounted: discountedAmountWithTax };
};

const getVariantsByColorCode = (
  colorCode: string,
  variants: IVariant[]
): IVariant[] =>
  variants.filter(
    v => v.filterProperties && getColorCode(v.filterProperties) === colorCode
  );

//  copy from https://github.com/theplant/ecjs/blob/cf3ac57bb5d0ba51310431fc68b20500be8db54e/packages/lacoste/product/helper.ts#L70-L99
const getProductAndVariantExtraProperties = ({
  product,
  variants
}: {
  product: IProduct;
  variants?: IVariant[] | null;
}) => {
  let properties: theplant.ec.service.base.IProperty[] = [];

  const { extraProperties } = product;

  if (extraProperties) {
    properties = properties.concat(extraProperties);
  }

  let variantExtraProperties: IProperty[] = [];
  if (variants) {
    variants.forEach(({ extraProperties }) => {
      if (extraProperties) {
        variantExtraProperties = variantExtraProperties.concat(extraProperties);
      }
    });
  }

  if (variantExtraProperties) {
    properties = properties.concat(variantExtraProperties);
  }

  return properties;
};

const isVariantsOutOfStock = (variants?: IVariant[] | null) => {
  if (!variants || variants.length === 0) {
    return true;
  }

  return variants.every(v => {
    if (
      (!v.inInventory && !v.inventoryQuantity) ||
      Number(v.inventoryQuantity) === 0
    ) {
      return true;
    }

    return false;
  });
};

type IImage = theplant.ec.service.products.IImage;

const productImage = (
  colorCode: string | null,
  images?: IImage[] | null
): IImage[] | null =>
  (images &&
    images.filter(
      image =>
        image && image.imageCode && image.imageCode.split("-")[1] === colorCode
    )) ||
  [];

const orderedColorCodes = (
  variants?: IVariant[] | null,
  sortOption?: {
    defaultOrder?: boolean;
  }
): string[] => {
  let colorOrders =
    (variants &&
      variants.reduce((colorOrders: string[], v: IVariant) => {
        const colorCode =
          v.filterProperties && getColorCode(v.filterProperties);

        if (colorCode && !colorOrders.includes(colorCode)) {
          colorOrders.push(colorCode);
        }

        return colorOrders;
      }, [])) ||
    [];

  if (sortOption && sortOption.defaultOrder) {
    colorOrders = colorOrders.sort();
  }

  return colorOrders;
};

const variantsGroupedAndOrderedByColorCode = (
  variants?: IVariant[] | null,
  sortOption?: {
    defaultOrder?: boolean;
  }
): {
  colorCode: string;
  variants: IVariant[];
}[] =>
  variants
    ? orderedColorCodes(variants, sortOption).reduce(
        (
          result: {
            colorCode: string;
            variants: IVariant[];
          }[],
          colorCode: string
        ) => {
          result.push({
            colorCode,
            variants: getVariantsByColorCode(colorCode, variants)
          });

          return result;
        },
        []
      )
    : [];

const getMatchVariants = ({
  variants,
  colorCode
}: {
  variants?: theplant.ec.service.products.IVariant[] | null;
  colorCode: string;
}) => {
  const matched = variantsGroupedAndOrderedByColorCode(variants).find(
    ({ colorCode: _colorCode }) => _colorCode === colorCode
  );

  return matched && matched.variants;
};

const getDefaultSizeCode = ({
  variants,
  colorCode
}: {
  variants?: theplant.ec.service.products.IVariant[] | null;
  colorCode: string;
}): string | undefined => {
  let sizeCode;

  const matchedVariants = getMatchVariants({ variants, colorCode });

  if (matchedVariants && matchedVariants.length === 1) {
    const v = matchedVariants && matchedVariants[0];
    const properties = v && v.filterProperties;

    if (properties) {
      sizeCode = getSize(properties);
    }
  }

  return sizeCode || undefined;
};

const getVariantsBySizeCode = (
  variantsFindByColorCode: IVariant[],
  sizeCode?: string
): IVariant | undefined =>
  variantsFindByColorCode &&
  variantsFindByColorCode.find(
    v => (v.filterProperties && getSize(v.filterProperties)) === sizeCode
  );

const getImagesByImageCode = (imageCode: string, images?: IImage[] | null) =>
  images &&
  images.filter(({ imageCode: _imageCode }) => _imageCode === imageCode);

const orderedImagesAndVariants = (
  product: IProduct,
  sortOption?: {
    defaultOrder?: boolean;
  }
): {
  colorCode: string;
  images?: IImage[] | null;
  variants: IVariant[];
}[] => {
  const { code, variants, images } = product;

  return variantsGroupedAndOrderedByColorCode(variants, sortOption).reduce(
    (
      newResults: {
        colorCode: string;
        variants: IVariant[];
        images?: IImage[] | null;
      }[],
      result: {
        colorCode: string;
        variants: IVariant[];
      }
    ) => {
      const { colorCode } = result;

      newResults.push({
        ...result,
        images: getImagesByImageCode(`${code}-${colorCode}`, images)
      });

      return newResults;
    },
    []
  );
};

const isShowEvenThoughOutOfStock = (product?: IProduct | null) =>
  (product && product.showEvenThoughOutOfStock) || false;

const anyStoreInInventory = (variants?: IVariant[] | null) => {
  if (!variants) {
    return false;
  }

  return variants.find(v => !!v.anyStoreInInventory);
};

const getColorSwatchImage = (images: IImage[]) => {
  return images[0] && images[0].id;
};

const getDisplaySizeVariants = ({
  variants,
  sizeOrders
}: {
  variants: theplant.ec.service.products.IVariant[];
  sizeOrders?: string[] | null;
}) => {
  let resultVariants: theplant.ec.service.products.IVariant[] = [];

  sizeOrders &&
    sizeOrders.forEach(item => {
      variants.find(v => {
        const size = v.filterProperties && getSize(v.filterProperties);
        if (size === item) {
          resultVariants.push(v);
          return true;
        } else {
          return false;
        }
      });
    });

  return resultVariants;
};

export {
  inventoryQuantity,
  calcDiscountRate,
  calcPrice,
  calcPriceWithoutTax,
  calcAmount,
  getVariantsByColorCode,
  getProductAndVariantExtraProperties,
  isVariantsOutOfStock,
  productImage,
  orderedColorCodes,
  variantsGroupedAndOrderedByColorCode,
  getDefaultSizeCode,
  getVariantsBySizeCode,
  orderedImagesAndVariants,
  isShowEvenThoughOutOfStock,
  anyStoreInInventory,
  getColorSwatchImage,
  getDisplaySizeVariants
};
