import React from "react";
import { calcPrice } from "../product/helper";
import { ConfirmResultProps } from "../orders";
import {
  withRecordOrderSentInBrowser,
  withRecordOrderSentInBrowserProps,
} from "../withRecordOrderSentInBrowser";

declare global {
  interface Window {
    snapboard_params: {
      item_maker_code: string;
    };
    snapboard_itemData: {
      order_id: string;
      item: Array<{
        id?: string | null;
        price: number;
        quantity: number;
      }>;
    };
  }
}

const SnapboardTargetForPDP = ({
  productCode,
}: {
  productCode?: string | null;
}) => {
  const downloadScript = (src: string) => {
    const scriptDiv = document.createElement("script");
    scriptDiv.src = src;
    scriptDiv.async = true;
    document.body.appendChild(scriptDiv);
  };

  React.useEffect(() => {
    if(window.location.host.indexOf("localhost")>-1){
      return;
    }
    
    const snapboardScripts = [
      "https://d2hrw6o7z27umn.cloudfront.net/v1.0.7/85bcbb3a1e03dff5a13d.js",
      "https://d2hrw6o7z27umn.cloudfront.net/v1.0.7/733273980fc3caf3593e.js",
      "https://d2hrw6o7z27umn.cloudfront.net/v1.0.7/6606d4ce5ae734a586b0.js",
      "https://d2hrw6o7z27umn.cloudfront.net/v1.0.7/079d4135c63b9c3e11c9.js",
    ];
    if (productCode) {
      window.snapboard_params = window.snapboard_params || {};
      window.snapboard_params = {
        item_maker_code: productCode,
      };
    }

    snapboardScripts.forEach((script) => {
      downloadScript(script);
    });
  }, [productCode]);

  return (
    <div className="snap_pdp">
      <div id="__nuxt"></div>
    </div>
  );
};

const SnapboardTargetForThankYou = withRecordOrderSentInBrowser(
  ({
    confirmResult: { result },
    orderFirstSent,
  }: ConfirmResultProps & withRecordOrderSentInBrowserProps) => {
    const uploadData = React.useCallback(() => {
      if (result) {
        const { order } = result;
        if (order) {
          const orderCode = order.code || "";
          const orderItems = order.orderItems || [];

          const item: Array<{
            id?: string | null;
            price: number;
            quantity: number;
          }> = [];

          orderItems.forEach((orderItem) => {
            const { price, discounted } = calcPrice(orderItem);
            item.push({
              id: orderItem.productCode,
              price: discounted || price,
              quantity: Number(orderItem.quantity || 0),
            });
          });

          window.snapboard_itemData = window.snapboard_itemData || {};
          window.snapboard_itemData = {
            order_id: orderCode,
            item: item,
          };

          if (!document.getElementById("sb-conversion-tracker")) {
            const scriptDiv = document.createElement("script");

            scriptDiv.setAttribute("id", "sb-conversion-tracker");
            scriptDiv.src = "https://admin.snapboard.jp/js/ec_conversions.js";
            scriptDiv.async = true;
            document.body.appendChild(scriptDiv);
          }
        }
      }
    }, [result]);

    React.useEffect(() => {
      const orderCode = result?.order?.code;
      if (orderCode && orderFirstSent && orderFirstSent(orderCode)) {
        uploadData();
      }
    }, [result, orderFirstSent, uploadData]);

    return null;
  }
);

export { SnapboardTargetForPDP, SnapboardTargetForThankYou };
