import $ from "jquery";
import {
  getUniqueDiscountLabelMaps,
  labelBackgroundColorMap
} from "../../product/badge/helper";

const updateProductAndVariantLabel = (
  discountLabels,
  discountLabelBackgroundColors,
  target
) => {
  const uniqueLabelsMap = getUniqueDiscountLabelMaps({
    discountLabels,
    discountLabelBackgroundColors
  });

  const productLabelWrapper = target.find(".productLabelWrapper");

  let productAndVariantLabels = "";

  Object.entries(uniqueLabelsMap).forEach(([key, value]) => {
    if (value === "White(Red Text)") {
      productAndVariantLabels += `<div class="productLabel whiteRedText" style="color:${labelBackgroundColorMap["Red"]}; border: 1px solid ${labelBackgroundColorMap["Red"]} ;background: ${labelBackgroundColorMap[value]};">
       ${key}
      </div>`;
    } else {
      productAndVariantLabels += `<div class="productLabel" style="background: ${labelBackgroundColorMap[value]}">
        ${key} 
      </div>`;
    }
  });

  if (productLabelWrapper) {
    // clean old
    productLabelWrapper.find(".productLabel").remove();
    // append new
    productLabelWrapper.append($(productAndVariantLabels));
  }
};

export { updateProductAndVariantLabel };
