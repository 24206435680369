import React, { MouseEventHandler } from "react";
import { SHOP_FORM_MAP, workTimeDisplay } from "../helper";

interface IShopItem {
  id: string;
  lat: string;
  lng: string;
  shopName: string;
  zipcode: string;
  addresss: string;
  tel: string;
  canDutyFree: boolean;
  canPoint: boolean;
  blogUrl?: string;
  form?: string;
  workTime?: string;
  addFavStore?: (store: any) => void;
  handleClickShopItem?: MouseEventHandler<HTMLDivElement>;
  noIndex?: boolean;
}

const ShopItem = (props: IShopItem) => {
  const {
    shopName,
    zipcode,
    addresss,
    tel,
    canDutyFree,
    canPoint,
    lat,
    lng,
    id,
    form,
    workTime,
    addFavStore,
    noIndex,
  } = props;

  return (
    <div className="shop-info">
      <div className={`shop-info-header ${noIndex ? "index__disabled" : ""}`}>
        <h4 title={shopName}>{shopName}</h4>
        <div className="shop-info-status">
          {canPoint && (
            <div className="shop-info-status__green">ポイント利用</div>
          )}
          {canDutyFree && <div className="shop-info-status__black">免税店</div>}
        </div>
      </div>

      <div className="shop-info-content">
        <div className="shop-info-content-left">
          <p>
            {form &&
              `店舗形態: ${SHOP_FORM_MAP[form as keyof typeof SHOP_FORM_MAP]}`}
          </p>
          <p>
            〒{zipcode}
            <br />
            {addresss}
          </p>
          <p>{workTime && `営業時間：${workTimeDisplay(workTime)}`}</p>
        </div>
        <div className="shop-info-content-right">
          {addFavStore ? (
            <>
              <button
                className="new-primary-btn shop-info-content-fav"
                onClick={() =>
                  addFavStore({
                    shopName,
                    zipcode,
                    addresss,
                    tel,
                    canDutyFree,
                    canPoint,
                    lat,
                    lng,
                    id,
                    form,
                    workTime,
                  })
                }
              >
                この店舗を登録する
              </button>
            </>
          ) : (
            <>
              <a
                className="new-primary-btn shop-info-content-tel"
                href={`tel:${tel}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 22 22"
                >
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M17.118 13.702 13 14.5c-2.782-1.396-4.5-3-5.5-5.5l.77-4.13L6.815 1H3.064c-1.128 0-2.016.932-1.847 2.047.42 2.783 1.66 7.83 5.283 11.453 3.805 3.805 9.286 5.456 12.302 6.113 1.165.253 2.198-.655 2.198-1.848v-3.584l-3.882-1.479Z"
                  />
                </svg>
                <span>{tel}</span>
              </a>
              <a
                className="new-primary-btn"
                href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="m4.031 8.917 15.477-4.334a.5.5 0 0 1 .616.617l-4.333 15.476a.5.5 0 0 1-.94.067l-3.248-7.382a.5.5 0 0 0-.256-.257L3.965 9.856a.5.5 0 0 1 .066-.939Z"
                  />
                </svg>
                <span>MAP</span>
              </a>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShopItem;
