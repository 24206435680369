import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIsDesktop, _isDesktop } from "./hooks";
import { CloseSvg } from "../../components/shared/svg";
import PDP from "../../components/molecules/product";
import {
  useOpenMenuDisableBodyScroll,
  useClickOutSide,
} from "../../components/hooks";

const bodyScrollLock = require("body-scroll-lock");
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

const ArrowDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9.70492L7.41 8.29492L12 12.8749L16.59 8.29492L18 9.70492L12 15.7049L6 9.70492Z"
      fill="black"
    />
  </svg>
);

const PDPSticky = (_: { isOutOfStock: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [color, setColor] = useState("");
  const [selectedColorName, setSelectedColorName] = useState("");
  const [selectedColor, setSelectedColor] = useState<Element | null>(null);
  const [size, setSize] = useState("");
  const [showColor, setShowColor] = useState(false);
  const [showSize, setShowSize] = useState(false);
  const isDesktop = useIsDesktop();
  const [openPanel, setOpenPanel] = useState(false);
  const [stickyPanel, setStickyPanel] = useState(false);

  const clearOpenState = () => {
    if (showColor) {
      setShowColor(false);
    }
    if (showSize) {
      setShowSize(false);
    }
  };

  useClickOutSide(ref, clearOpenState, [showColor, showSize]);

  const cartFormBottom = () => {
    const addToCartButton = document.querySelector(".pdp-info .js-add-to-cart");
    if (addToCartButton) {
      const offsetTop =
        (addToCartButton as HTMLDivElement).getBoundingClientRect().y || 0;
      const cartFormBottom = offsetTop - 52 || addToCartButton.clientHeight;

      return cartFormBottom;
    } else {
      return 0;
    }
  };

  const toggleStickyForm = () => {
    const current = ref.current;
    var stickyFormVisible = cartFormBottom() < 0;
    if (stickyFormVisible && !stickyPanel) {
      if (current) {
        current.style.bottom = _isDesktop() ? "30px" : "0";
      }
      setStickyPanel(true);
    }

    if (!stickyFormVisible && stickyPanel) {
      if (current) {
        current.style.bottom = "-100px";
      }
      setStickyPanel(false);
    }

    clearOpenState();
  };

  const getData = useCallback(() => {
    const productName = document.querySelector(".productName")?.innerHTML;
    const priceHtml = document.querySelector(".price")?.innerHTML || "";
    const colorHtml =
      document.querySelector(".pdp-info__colors .color-list")?.innerHTML || "";
    const selectedColorHTML = document.querySelector(
      ".pdp-info__colors li.active a > span:first-child"
    );
    const colorName =
      document.querySelector(".pdp-info__colors li.active .color-name")
        ?.innerHTML || "";
    const sizeHtml =
      document.querySelector(".pdp-info__sizes ul")?.innerHTML || "";
    setProductName(productName || "");
    setPrice(priceHtml);
    setColor(colorHtml);
    setSize(sizeHtml);
    setSelectedColor(selectedColorHTML);
    setSelectedColorName(colorName);
  }, []);

  useEffect(() => {
    getData();
  }, [isDesktop, getData]);

  useEffect(() => {
    window.addEventListener("scroll", toggleStickyForm);
    return () => {
      window.removeEventListener("scroll", toggleStickyForm);
    };
    // eslint-disable-next-line
  }, [showSize, showColor, stickyPanel]);

  const toggleColor = () => {
    setShowColor(!showColor);
    setShowSize(false);
  };

  const toggleSize = () => {
    setShowSize(!showSize);
    setShowColor(false);
  };

  const nameAndPriceSection = (
    <div className="name-and-price flex-wrapper">
      <p className="title">{productName}</p>
      <div className="price" dangerouslySetInnerHTML={{ __html: price }} />
    </div>
  );

  const colorSection = isDesktop ? (
    <div className="color" onClick={toggleColor}>
      <button
        className={`js--sticky-open-color ${
          showColor ? "show" : ""
        } large-screen-only`}
      >
        <div className="pdp-info__colors">
          <span className="selected">
            <span
              className="swatch"
              style={{
                background:
                  selectedColor && (selectedColor as any).style.background,
              }}
            />
          </span>
          <ArrowDown />
        </div>
      </button>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`color-list pdp-info__colors ${showColor ? "show" : ""}`}
        dangerouslySetInnerHTML={{ __html: color }}
      ></div>
    </div>
  ) : (
    <div className="color-list pdp-info__colors">
      <label className="title">
        カラー：
        <span
          className="color-swatch mr-8"
          style={{
            background:
              selectedColor && (selectedColor as any).style.background,
          }}
        />
        <span className="color-name">{selectedColorName}</span>
      </label>
    </div>
  );

  const sizeSecion = (
    <div className="size " onClick={toggleSize}>
      <button
        className={`js--sticky-open-size ${
          showSize ? "show" : ""
        } large-screen-only`}
      >
        <span className="selected init">サイズを選択</span>
        <ArrowDown />
      </button>
      <div
        className={`size-list custom-scrollbar pdp-info__sizes js-pdp-info__sizes ${
          showSize ? "show" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
        dangerouslySetInnerHTML={{
          // we can only have one size radio, so in order to sync size, this one should be another name
          __html: `
          <label class="title">サイズを選択：</label>
          <ul class="grid-row-4-only">${(size || "").replace(
            /name="size"/g,
            `name="size-fix"`
          )}</ul>`,
        }}
      ></div>
    </div>
  );

  const addToCartSection = (
    <div className="pdp-actions">
      <button
        className={`primary-btn pdp-info__add-to-cart-btn js-add-to-cart sticky-add-to-cart ${
          isDesktop ? "desktop" : "mobile"
        }`}
        type="button"
      >
        <span className="pdp-info__add-to-cart">サイズを選択してください</span>
        <span className="pdp-info__success-to-cart">追加しました</span>
      </button>
    </div>
  );

  const mobileTitle = (
    <div className="popup-title">
      <p className="title">カラーとサイズ</p>
      <div
        className="closeBtn"
        onClick={() => {
          setOpenPanel(false);
          enableBodyScroll();
          document.querySelector(".back-to-top")!.classList.add("active");
        }}
      >
        <CloseSvg />
      </div>
    </div>
  );

  const chooseColorAndSizePanel = (
    <div
      className={isDesktop ? "options" : `overlay ${openPanel ? "open" : ""}`}
    >
      <div
        className={
          isDesktop ? "flex-wrapper" : `content ${openPanel ? "open" : ""}`
        }
      >
        {!isDesktop && mobileTitle}
        {colorSection}
        {sizeSecion}
        {addToCartSection}
      </div>
    </div>
  );

  const mobileStickySection = (
    <div className="small-screen-only">
      <button
        className="primary-btn preview-btn "
        onClick={() => {
          document.querySelector(".back-to-top")!.classList.remove("active");
          setOpenPanel(true);
        }}
      >
        サイズを選択してください
      </button>
      {addToCartSection}
    </div>
  );

  useOpenMenuDisableBodyScroll(openPanel, ref.current);

  return (
    <>
      <div className="pdp-sticky" ref={ref}>
        <div className={isDesktop ? "infos flex-wrapper" : ""}>
          {isDesktop && nameAndPriceSection}
          {chooseColorAndSizePanel}
        </div>
        {mobileStickySection}
      </div>
      <PDP />
    </>
  );
};
export default PDPSticky;
