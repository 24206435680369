import React from "react";

import VISA_SVG from "../../img/VISA.svg";
import JCB_SVG from "../../img/JCB.svg";
import MasterCard_SVG from "../../img/MasterCard.svg";
import AmericanExpress_SVG from "../../img/AmericanExpress.svg";
import DinersClub_SVG from "../../img/DinersClub.svg";

import * as misc from "../../constants/Misc";

const BRAND_ISSUER_SVG_MAP = {
  [misc.VISA]: VISA_SVG,
  [misc.JCB]: JCB_SVG,
  [misc.MASTER_CARD]: MasterCard_SVG,
  [misc.AMERICAN_EXPRESS]: AmericanExpress_SVG,
  [misc.DINERS_CLUB]: DinersClub_SVG,
};

const IssuerIcon = ({ src, alt }) => (
  <figure>
    <img src={src} alt={alt} />
  </figure>
);

const renderIssuerIcon = (issuer) =>
  BRAND_ISSUER_SVG_MAP[issuer] ? (
    <IssuerIcon src={BRAND_ISSUER_SVG_MAP[issuer]} alt={issuer} />
  ) : null;

const _test = { BRAND_ISSUER_SVG_MAP };

export { renderIssuerIcon, IssuerIcon, _test };
