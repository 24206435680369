import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.POINTS_HISTORY_WILL_REFRESH });

const refreshSuccess = data => ({
  type: types.POINTS_HISTORY_DID_REFRESH,
  data
});

const refreshFailure = error => ({
  type: types.POINTS_HISTORY_DID_REFRESH_FAILED,
  error
});

export { refresh, refreshSuccess, refreshFailure };
