import React from "react";
import * as H from "history";
import { ProgressBar } from "../shared/ProgressBar";

import {
  CHECKOUT_CART_PATH,
  CHECKOUT_SHIPPING_PATH,
  CHECKOUT_PAYMENT_PATH,
  CHECKOUT_AMAZON_PAY_PATH,
  CHECKOUT_CONFIRMATION_PATH,
  CHECKOUT_LOGIN_PATH,
  CHECKOUT_COMPLETE_PAYMENT_PATH,
} from "../../constants/Paths";

const getCurrentStage = (pathname: string): number => {
  switch (pathname) {
    case CHECKOUT_CART_PATH:
      return 1;
    case CHECKOUT_LOGIN_PATH:
      return 1;
    case CHECKOUT_AMAZON_PAY_PATH:
      return 2;
    case CHECKOUT_SHIPPING_PATH:
      return 2;
    case CHECKOUT_PAYMENT_PATH:
    case CHECKOUT_COMPLETE_PAYMENT_PATH:
      return 3;
    default:
      return 0;
  }
};

const getStagesByPathname = (pathname: H.Pathname) => {
  const stages: Array<{ name: JSX.Element; pathname?: string }> = [
    { name: <>カート</>, pathname: CHECKOUT_CART_PATH },
  ];

  return stages.concat(
    pathname === CHECKOUT_AMAZON_PAY_PATH
      ? [
          {
            name: <>お届け先情報</>,
          },
        ]
      : [
          {
            name: <>お届け先情報</>,
            pathname: CHECKOUT_SHIPPING_PATH,
          },
          { name: <>お支払い方法</> },
        ]
  );
};

const Stepper = ({
  pathname,
  disabled,
}: {
  pathname: string;
  disabled?: boolean;
}) =>
  pathname === CHECKOUT_CONFIRMATION_PATH ? null : (
    <ProgressBar
      currentStage={getCurrentStage(pathname)}
      stages={getStagesByPathname(pathname)}
      disabled={disabled}
    />
  );

export default Stepper;
