import $ from "jquery";
import { scorePassword } from "../js/helper.js";

class PasswordStrengthTester {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".registration #password").on(
      "keyup",
      this.testPasswordStrength.bind(this)
    );
  }

  testPasswordStrength(event) {
    const $target = $(event.currentTarget);
    const password = $target.val();
    const score = scorePassword(password);
    this.removePrevClass();
    $(".registration .password-strength").addClass(`lvl${score}`);
  }

  removePrevClass() {
    $(".registration .password-strength").removeClass(function(index, css) {
      return (css.match(/lvl\d/g) || []).join(" ");
    });
  }
}

export default PasswordStrengthTester;
