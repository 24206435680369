// copy from https://github.com/theplant/ecjs/blob/master/packages/lacoste/product/badge/helper.ts
const getUniqueDiscountLabelMaps = ({
  discountLabels,
  discountLabelBackgroundColors
}: {
  discountLabels?: string[] | null;
  discountLabelBackgroundColors?: string[] | null;
}) => {
  let discountLabelMaps: any = {};

  (discountLabels || []).forEach((label, i) => {
    discountLabelMaps[label] =
      discountLabelBackgroundColors && discountLabelBackgroundColors[i];
  });

  return discountLabelMaps;
};

// the same as https://github.com/theplant/aigle/blob/26d12938c7aaa77effb23e50414842773b821c0b/aigleapp/helpers/getMatchedColor.go#L3-L9
const labelBackgroundColorMap: { [key: string]: string } = {
  Grey: "#373636",
  Navy: "#00499D",
  Red: "#A91B0D",
  Green: "#105A33",
  "White(Red Text)": "#fff"
};

export { getUniqueDiscountLabelMaps, labelBackgroundColorMap };
