const mapToNameAndJapanese = (prefectures = []) =>
  prefectures.reduce((mapped, prefecture) => {
    mapped[prefecture.name] = prefecture.japanese;
    return mapped;
  }, {});

const getPrefectureOptions = (prefectures = []) =>
  prefectures.map(({ name, japanese }) => [name, japanese]);

export { mapToNameAndJapanese, getPrefectureOptions };
