import $ from "jquery";
import "magnific-popup";

class USPPopup {
  constructor(element = ".usp-wrapper") {
    this._element = element;
    this.bindEvents();
  }

  bindEvents() {
    const jsUSPWrapper = $(this._element).find(".js-usp-wrapper");

    if (jsUSPWrapper.length !== 0) {
      jsUSPWrapper.magnificPopup({
        type: "inline",
        closeMarkup: ` 
        <button title="Close (Esc)" type="button" class="mfp-close">
          <svg class="mfp-close" xmlns="http://www.w3.org/2000/svg" width="24.04" height="24" viewBox="0 0 24.04 24">
            <path d="M30.548,9l-9.9,10.063L30.708,29.123a1.067,1.067,0,0,1,0,1.6,1.22,1.22,0,0,1-.8.319c-.319,0-.479-.16-.8-.319L19.048,20.657,8.984,30.72a1.22,1.22,0,0,1-.8.319c-.319,0-.479-.16-.8-.319a1.067,1.067,0,0,1,0-1.6L17.45,19.059,7.387,9a1.067,1.067,0,0,1,0-1.6,1.067,1.067,0,0,1,1.6,0L19.048,17.462,29.111,7.4a1.067,1.067,0,0,1,1.6,0A1.078,1.078,0,0,1,30.548,9Z" transform="translate(-7.028 -7.039)"/>
          </svg>
        </button>`,
      });
    }
  }
}

export default USPPopup;
