import PropTypes from "prop-types";
import React from "react";

import TextInput from "./Input/TextInput";
import Errors from "./Errors";
import {
  filterValidationErrors,
  filterOrderErrors,
  filterNormalApiErrors
} from "../../Api";

// Form component provides some common functions that used for easy
// creating a connected form.
class Form extends React.Component {
  constructor(props) {
    super(props);

    this.changeField = this.changeField.bind(this);
    this.submit = this.submit.bind(this);
    this.filterValidationErrors = this.filterValidationErrors.bind(this);
    this.filterOrderErrors = this.filterOrderErrors.bind(this);
    this.filterNormalApiErrors = this.filterNormalApiErrors.bind(this);
  }

  // changeField dispatch 'CHANGE_FIELD' action to update field value
  // on the Store.
  changeField(field) {
    return value => this.props.changeField(field, value);
  }

  // submit dispatch 'WILL_SUBMIT' action to sumit the form.
  submit(event) {
    this.props.submit();
    event.preventDefault();
  }

  // filterValidationErrors filter the field level errors for each
  // fields.
  filterValidationErrors(field) {
    const error = this.props.state.ui.error || {};
    return filterValidationErrors(error, field);
  }

  // filterOrderErrors filter the field level errors for each
  // fields.
  filterOrderErrors(field) {
    const error = this.props.state.ui.error || {};
    return filterOrderErrors(error, field);
  }

  // filterNormalApiErrors filter form level error for the form.
  filterNormalApiErrors() {
    const error = this.props.state.ui.error || {};
    return filterNormalApiErrors(error);
  }

  _renderFormError(errors = [], errorMsg) {
    return <Errors errors={errors} msg={errorMsg} />;
  }

  // renderFormError renders the form level error.
  renderFormError(errorMsg) {
    const errors =
      this.filterNormalApiErrors() || this.filterOrderErrors("global");
    return this._renderFormError(errors, errorMsg);
  }

  // render() is used for testing only.
  render() {
    const { textInput = "" } = this.props.state.data;

    return (
      <form onSubmit={this.submit}>
        <TextInput
          value={textInput}
          onChange={this.changeField("textInput")}
          errors={this.filterValidationErrors("textInput")}
        />
        {this.renderFormError()}
        <input type="submit" disabled={this.props.state.ui.fetching} />
      </form>
    );
  }
}

Form.defaultProps = {
  state: { data: {}, ui: {} }
};

Form.propTypes = {
  state: PropTypes.object,
  submit: PropTypes.func,
  changeField: PropTypes.func
};

export default Form;
