import { proto } from "../proto";

const messages: { [field: string]: { [code: string]: string } } = {
  "Form.Payment": {
    PAYMENT_FAILURE: "お支払いを処理をする際にエラーが発生しました。",
  },
  "Form.OrderItem": {
    UNAVAILABLE:
      "エラーが発生しました。リフレッシュしてもう一度お試しください。",
    NOT_ENOUGH_STOCK: "申し訳ありません。商品は在庫切れのため購入できません。",
  },
  "Form.CouponCode": {
    NOTFOUND: "特典コードが間違っています。",
  },
  "Form.Points": {
    UNAVAILABLE: "有効なポイントを入力してください。",
    INVALIDARGUMENT: "お持ちのポイントを超過しています。",
  },
  "Form.DeliveryInfo.SpecifiedDate": {
    INVALIDARGUMENT: "有効な配送希望日を入力してください。",
  },
  "Form.DeliveryInfo.SpecifiedHours": {
    INVALIDARGUMENT: "有効な配送時間を入力してください。",
  },
  "Form.AmazonPayInput": {
    INVALIDARGUMENT: "お届け先住所がありません。",
    UNAVAILABLE:
      "Amazon Payのボタンをクリックしてもう一度サインインしてください。",
  },
  AmazonPayInput: {
    UNAVAILABLE:
      "カード会社への接続に失敗しました。 しばらくお待ちいただき再度実行してください。",
  },
  "Form.AmazonPayInput.AmazonPayToken": {
    UNAVAILABLE:
      "セッションが終了しました。 Amazon Payのボタンをクリックしてもう一度サインインしてください。",
  },
  "Form.AmazonPayInput.AmazonPayReferenceId": {
    INVALIDARGUMENT:
      "ご指定のカードがご利用いただけませんでした。別のカードをご利用ください。",
  },
  "Form.AmazonPayInput.AmazonPaySessionId": {
    UNIMPLEMENTED:
      "ご指定のカードはご利用いただけませんでした。 お買い物カートに戻り、再度お試しください。",
  },
  "Form.Email": {
    INVALIDARGUMENT:
      "このアカウントは現在ご利用いただけません。カスタマーサポートにお問い合わせください。",
    INVALID_EMAIL_FORMAT:
      "エラーが発生しました。リフレッシュしてもう一度お試しください。",
  },
  "Form.Password": {
    GTE: "6 文字から 20 文字までの値を入力してください。",
    PASSWORD_INCORRECT: "無効なメールアドレスまたはパスワード。",
  },
  "Form.DateOfBirth": { INVALID_DATE_OF_BIRTH: "無効な生年月日。" },
  "Form.Gender": { INVALID_GENDER: "性別を選択してください。" },
  "Form.StoreId": {
    UNAVAILABLE: "店舗を選択してください。",
  },
  "Form.StoreInfo": {
    CAN_NOT_CONVENIENCE_PICKUP:
      "エラーが発生しました。リフレッシュしてもう一度お試しください。",
    UNAVAILABLE: "コンビニを選択してください。",
    INVALIDARGUMENT: "無効なコンビニ。",
  },
  "Form.CreditCardInput": {
    UNAVAILABLE: "無効なクレジットカード。",
    PAYMENT_FAILURE: "お支払いを処理をする際にエラーが発生しました。",
    CREDIT_CARD_UNAVAILABLE_ERROR_FROM_CREDIT_CARD_SERVICE:
      "こちらのカードは現在ご利用できません。カード発行会社にお問い合わせください。",
    CREDIT_CARD_SYSTEM_ERROR_FROM_CREDIT_CARD_SERVICE:
      "システムエラーです。しばらくたってから再度お試しください。",
    CREDIT_CARD_WRONG_INFO_FROM_CREDIT_CARD_SERVICE:
      "カード情報をご確認の上、再度ご入力ください。",
  },
  "Form.CreditCardInput.Number": {
    STRICT_REQUIRED: "必須項目が入力されていません。",
  },
  "Form.CreditCardInput.ExpiryYear": {
    STRICT_REQUIRED: "入力してください。",
  },
  "Form.CreditCardInput.ExpiryMonth": {
    STRICT_REQUIRED: "入力してください。",
  },
  "Form.CreditCardInput.Cvc": {
    GTE: "暗証番号が間違っています。ご確認の上、再度ご入力ください。",
  },
  "Form.CreditCardId": {
    PAYMENT_FAILURE: "お支払いを処理をする際にエラーが発生しました。",
  },
  "Form.ShippingAddressId": {
    INVALIDARGUMENT:
      "住所が未登録、または不備がございます。「編集」ボタンよりご確認ください。",
  },
  "Form.BillingAddressId": {
    INVALIDARGUMENT:
      "住所が未登録、または不備がございます。「編集」ボタンよりご確認ください。",
  },
  "Form.ShippingAddress.FamilyName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.ShippingAddress.GivenName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.ShippingAddress.KatakanaFamilyName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.ShippingAddress.KatakanaGivenName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.ShippingAddress.Zipcode": {
    STRICT_REQUIRED: "このフィールドは必須です。",
    INVALID_ZIPCODE: "入力内容を確認してください。",
    NOTFOUND_ZIPCODE: "入力内容を確認してください。",
  },
  "Form.ShippingAddress.Address1": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.ShippingAddress.City": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.ShippingAddress.Prefecture": {
    STRICT_REQUIRED: "このフィールドは必須です。",
    INVALID_PREFECTURE: "入力内容を確認してください。",
  },
  "Form.ShippingAddress.Email": {
    EMAIL_ALREADY_EXISTS: `このメールアドレスは登録済みです。`,
  },
  "Form.ShippingAddress.Phone": {
    STRICT_REQUIRED: "このフィールドは必須です。",
    INVALID_PHONE: "入力内容を確認してください。",
  },
  "Form.BillingAddress.FamilyName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.BillingAddress.GivenName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.BillingAddress.KatakanaFamilyName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.BillingAddress.KatakanaGivenName": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.BillingAddress.Zipcode": {
    STRICT_REQUIRED: "このフィールドは必須です。",
    INVALID_ZIPCODE: "入力内容を確認してください。",
    NOTFOUND_ZIPCODE: "入力内容を確認してください。",
  },
  "Form.BillingAddress.Address1": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.BillingAddress.City": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.BillingAddress.Prefecture": {
    STRICT_REQUIRED: "このフィールドは必須です。",
    INVALID_PREFECTURE: "入力内容を確認してください。",
  },
  "Form.BillingAddress.Phone": {
    STRICT_REQUIRED: "このフィールドは必須です。",
    INVALID_PHONE: "入力内容を確認してください。",
  },
  "LoginForm.Email": {
    INVALID_EMAIL_FORMAT: "有効なEメールアドレスを入力してください。",
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "LoginForm.Password": {
    GTE: "6 文字から 20 文字までの値を入力してください。",
    INVALID_EMAIL_OR_PASSWORD: "無効なメールアドレスまたはパスワード。",
  },
  "RegisterForm.Password": {
    GTE: "6 文字から 20 文字までの値を入力してください。",
  },
  "RegisterForm.PasswordConfirmation": {
    PASSWORD_CONFIRMATION_NOT_MATCH: "同じ値をもう一度入力してください。",
  },
  "RegisterForm.Gender": {
    INVALID_GENDER: "性別を選択してください。",
  },
  "RegisterForm.DateOfBirth": { INVALID_DATE_OF_BIRTH: "無効な生年月日。" },
  "RegisterForm.Profile.DateOfBirth": {
    INVALID_DATE_OF_BIRTH: "無効な生年月日。",
  },
  "RegisterForm.IsTermsAndPrivacyAgreed": {
    STRICT_REQUIRED: "このフィールドは必須です。",
  },
  "Form.Checkout" :{
    UNAVAILABLE: "現在サービスがご利用頂けません。しばらくしてから、もう一度ご利用お試しください。",
  }
};

const translate = (
  errors: proto.ValidationError.IFieldViolation[]
): proto.ValidationError.IFieldViolation[] =>
  errors.map((error) => {
    if (typeof error === "string") {
      return { msg: error };
    }
    return {
      ...error,
      msg:
        (error.field &&
          error.code &&
          messages[error.field] &&
          messages[error.field][error.code]) ||
        error.msg,
    };
  });

export { translate };
