import React from "react";
import Input from "./TextInput";
import { BaseProps } from "./BaseProps";

const PhoneInput = (props: BaseProps) => (
  <Input
    {...props}
    placeholder="例）012 3456 7890"
    label="電話番号"
    name="phone"
    maxLength={13}
  />
);

export default PhoneInput;
