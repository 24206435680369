import { theplant } from "../../proto";
import { _getURL } from "../../Api";
import $ from "jquery";
import { toCamel } from "../dataTransfer";

const getStores = ({
  articleCode,
  setLoading,
  setStores,
  clearSelectedPrefecture,
  storeRef,
  stores
}: {
  articleCode: string;
  setLoading: (loading: boolean) => void;
  setStores: (stores: theplant.ec.service.stores.IStoreInfoWithStock[]) => void;
  clearSelectedPrefecture: () => void;
  storeRef: React.RefObject<HTMLDivElement>;
  stores: theplant.ec.service.stores.IStoreInfoWithStock[];
}) => {
  // clear old stores before fetch new stores
  if (stores.length > 0) {
    setStores([]);
  }

  setLoading(true);

  $.ajax({
    url: _getURL("/store-stocks"),
    data: JSON.stringify({ article_code: articleCode }),
    type: "POST",
    xhrFields: {
      withCredentials: true
    },
    success: stores => {
      setLoading(false);
      stores = stores.map((s: Object) => toCamel(s));

      if (stores && stores.length > 0) {
        clearSelectedPrefecture();
        setStores(stores);

        const { current } = storeRef;
        if (storeRef && current) {
          current.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    error: e => {
      setLoading(false);
      console.log("get store failed, ", e);
    }
  });
};

export { getStores };
