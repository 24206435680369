import React from "react";

import { alert } from "../../js/shared/alert.ts";

import { _jsonRequest as jsonRequest } from "../../Api.js";
import { requireAuth } from "../../js/helper.js";
import Authn from "../../js/authn.js";
import { SHOP_FORM_MAP_OPTION } from "../helper.ts";
import { withConfig } from "../../config.ts";
import ShopItem from "./ShopItem.tsx";
import ShopRegion, { allRegionKey } from "./ShopRegion.tsx";
import SelectInput from "../shared/Input/SelectInput.tsx";
import TextInput from "../shared/Input/TextInput.tsx";

const pageSize = 10;
class SearchAndShopList extends React.Component {
  state = {
    // allFilteredStores
    stores: [],
    // offset of stores list
    offset: pageSize,
    storeType: 0,
    region: allRegionKey,
  };

  searchFieldElement = React.createRef();

  componentDidMount() {
    this.initMap();
  }

  initMap = () => {
    jsonRequest("GET", "/stores.json").then((stores) => {
      let filteredStores = (stores || []).filter((store) => {
        if (store.latitude !== undefined && store.longitude !== undefined) {
          return true;
        } else {
          return false;
        }
      });
      this.stores = filteredStores;

      const specificStoreID = Authn.Utils.getUrlParameter("id");
      let specificStore = null;

      if (!!specificStoreID) {
        this.stores.forEach((store) => {
          if (store.id === specificStoreID) {
            specificStore = store;
          }
        });
      }

      if (!!specificStore) {
        filteredStores = [specificStore];
        this.searchFieldElement.current.value = specificStore.name;
      }

      this.updateMap(filteredStores);
    });
  };

  //  interface IFilter {
  //  region: string
  //  }
  updateSearchResult = (filter) => {
    const region = filter?.region ? filter.region : this.state.region;
    const storeType = this.state.storeType;

    let filteredTypeStores = [];
    let sourceStores = this.stores;
    if (region) {
      sourceStores = this.filterStoresByRegion(region);
    }

    sourceStores?.forEach((store) => {
      // no type filtered
      if (storeType === 0 || store.form === storeType) {
        filteredTypeStores.push(store);
      }
    });

    const inputValue = this.searchFieldElement.current?.value?.trim();
    let matchNameStores = [];

    if (inputValue === "") {
      this.updateMap(filteredTypeStores);
      return;
    } else {
      filteredTypeStores.forEach((store) => {
        if (store.name.indexOf(inputValue) !== -1) {
          matchNameStores.push(store);
        }
      });
    }

    this.updateMap(matchNameStores);
  };

  updateMap = (stores) => {
    this.setState({ stores, offset: pageSize });
  };

  addFavStore = (store) => {
    requireAuth(() => {
      jsonRequest("PUT", "/account/mystore.json", store.id)
        .then((data) => {
          alert(`登録店舗: ${store.name}`);
        })
        .catch((ex) => {
          console.log("add fav store failed: " + ex);
        });
    });
  };

  handleChangeStoreType = (value) => {
    const newValue = Number(value);
    if (isNaN(newValue)) return;

    this.setState(
      {
        storeType: newValue,
      },
      this.updateSearchResult
    );
  };

  updateRegionFilter = (region) => {
    this.setState({
      region,
    });
    this.updateSearchResult({ region });
  };

  filterStoresByRegion = (selectedRegion) => {
    if (selectedRegion === allRegionKey) {
      return this.stores;
    }

    const filterStores = this.stores.filter((store) => {
      return selectedRegion === store.prefecture;
    });
    return filterStores;
  };

  textInputSearchOnChange = (keywords) => {
    this.updateSearchResult();
  };

  loadMore = () => {
    this.setState({
      offset: this.state.offset + pageSize,
    });
  };

  render() {
    const { stores, offset } = this.state;
    const { addToFav } = this.props;

    return (
      <div className="search-shop-list">
        <div className="shop-finder-wrapper  clearfix">
          <div className="shop-finder-regions">
            <ShopRegion updateFilter={this.updateRegionFilter}></ShopRegion>
          </div>
          <div className="search-field clearfix">
            <SelectInput
              className="shop-search-select"
              disableSearch={true}
              label="店舗形態"
              blankLabel="店舗形態"
              name="storeType"
              options={SHOP_FORM_MAP_OPTION}
              onChange={this.handleChangeStoreType}
            />

            <TextInput
              label="店舗名または住所の一部を入力"
              wrapperClassName="shop-search-input"
              type="text"
              id="shop-related-text"
              name="shop-related-text"
              inputRef={this.searchFieldElement}
              onChange={this.textInputSearchOnChange}
            />

            {/* search button */}
            <input
              type="submit"
              className="new-primary-btn shop-search-btn"
              value="店舗検索"
              onClick={this.updateSearchResult}
            />
          </div>
          <div
            style={{ display: "none" }}
            className="map"
            ref={(ele) => {
              this.mapElement = ele;
            }}
          />
          <div className="shop-list-container">
            <div className="shop-list">
              {stores.length === 0 && (
                <div className="shop-list-empty">該当なし</div>
              )}
              {stores.slice(0, offset).map((store, idx) => (
                <ShopItem
                  key={`shopItem-${idx}`}
                  canDutyFree={store.is_tax_exempt}
                  canPoint={store.is_points_usable}
                  shopName={store.name}
                  lat={store.latitude}
                  lng={store.longitude}
                  zipcode={store.zip}
                  addresss={store.city + store.address}
                  tel={store.phone}
                  addFavStore={addToFav}
                  id={store.id}
                  form={store.form}
                  workTime={store.business_hours}
                  // handleClickShopItem={this.handleClickShopItem.bind(
                  //   this,
                  //   store.latitude,
                  //   store.longitude
                  // )}
                  blogUrl={store.blog_url}
                />
              ))}
            </div>
            {offset >= stores.length ? null : (
              <button
                className="new-primary-btn shop-list-more"
                onClick={this.loadMore}
              >
                もっと見る
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withConfig(SearchAndShopList);
