import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const initialState = {
  data: {},
  ui: {
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.PROFILE_WILL_REFRESH:
      return { ...s, fetching: true, error: null };
    case types.PROFILE_DID_REFRESH_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.PROFILE_DID_REFRESH:
      return { ...s, fetching: false, error: null };
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.PROFILE_DID_REFRESH:
      return { ...s, ...a.data };
    default:
      return s;
  }
};

export default combineReducers({ ui, data });

export { initialState };
