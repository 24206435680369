import React from "react";
import {
  CheckboxInput,
  CheckboxInputProps,
} from "../../../CheckoutApp/shared/CheckboxInput";
import ProductPrice from "../../shared/ProductPrice";
import Divider from "../../molecules/Divider";

const WishItem = (props: Props) => {
  const { data, onChange, checked } = props;
  const {
    name,
    path,
    imageURL,
    sizeName,
    colorName,
    price,
    sellingPrice,
    discountRate,
  } = data;

  return (
    <div className="wishitem-card">
      <figure>
        <a href={path}>
          <img src={imageURL} alt={name} loading="lazy" />
        </a>
      </figure>
      <a href={path} title={name}>
        <h6 className="line-ellipsis" title={name}>
          {name}
        </h6>
      </a>
      <ProductPrice data={{ price, sellingPrice, discountRate }} />
      {onChange && (
        <>
          <Divider />
          <div className="select-bar">
            <div className="color-and-size-wrapper">
              <div className="color-and-size-column">
                <span className="color-and-size-label">カラー：</span>

                <img
                  src={imageURL.replaceAll(".mn", ".ca")}
                  alt={name}
                  loading="lazy"
                />
                <span className="color-and-size-value line-ellipsis">
                  {colorName}
                </span>
              </div>
              <div className="color-and-size-column">
                <span className="color-and-size-label">サイズ：</span>
                <span className="color-and-size-value line-ellipsis">
                  {sizeName}
                </span>
              </div>
            </div>
            <div className="checkbox-wrapper">
              <CheckboxInput onChange={onChange} checked={checked} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export type WishItemDataProps = {
  id: string;
  name: string;
  path: string;
  imageURL: string;
  sizeName: string;
  colorName: string;
  price: number;
  sellingPrice: number;
  discountRate: number;
};

export type Props = CheckboxInputProps & {
  data: WishItemDataProps;
};

export default WishItem;
