const leftArrowSVGString = `
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="2" fill="white" fillOpacity="0.5" />
    <path
      d="M32.1304 29.835L33.9004 28.055L24.0004 18.165L14.1004 28.065L15.8704 29.835L24.0004 21.705L32.1304 29.835Z"
      fill="black"
    />
  </svg> 
  `;

const rightArrowSvgString = `
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="2" fill="white" fillOpacity="0.5" />
    <path
      d="M15.8696 18.165L14.0996 19.945L23.9996 29.835L33.8996 19.935L32.1296 18.165L23.9996 26.295L15.8696 18.165Z"
      fill="black"
    />
  </svg>
`;

export { rightArrowSvgString, leftArrowSVGString };
