import mappers from "./mappers";
import * as messages from "./constants/Messages";
import { ZERO_VALIDATES_EMAIL_RESPONSE } from "./constants/Protos";
import { ORDER_ERROR, VALIDATION_ERROR } from "./constants/Errors";

const isValidationError = ({ error }) => error === VALIDATION_ERROR;
const filterValidationErrors = (error = { value: { errors: {} } }, field) => {
  if (isValidationError(error)) {
    return error.value.errors[field];
  }

  return null;
};

const isOrderError = ({ error }) => error === ORDER_ERROR;
const filterOrderErrors = (error = { value: {} }, field) => {
  if (error && isOrderError(error)) {
    return error.value[field];
  }

  return null;
};

const filterNormalApiErrors = (errorResponse = {}) => {
  if (
    errorResponse &&
    !isValidationError(errorResponse) &&
    !isOrderError(errorResponse) &&
    errorResponse.error
  ) {
    const { error, value } = errorResponse;
    if (value && typeof value === "string") {
      return [value];
    } else if (value && Array.isArray(value)) {
      return value;
    } else {
      return [error];
    }
  }
  return null;
};

// _getURL is a hacker for testing purpose. Because we're using
// `nock` and `isomorphic-fetch` to testing the API and it
// requests absolute URLs.
const _getURL = path =>
  process.env.NODE_ENV === "test" ? `http://localhost:4000${path}` : path;

const _jsonRequest = (method, path, ...params) => {
  return fetch(_getURL(path), {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: method !== "GET" ? JSON.stringify({ params }) : undefined,
    credentials: "same-origin"
  })
    .then(res => {
      let error;

      if (res.status === 500) {
        error = { error: "http error", value: messages.INTERNAL_SERVER_ERROR };
        throw error;
      } else if (res.status === 404) {
        error = { error: "http error", value: messages.INTERNAL_SERVER_ERROR };
        throw error;
      }

      return res;
    })
    .then(res => {
      try {
        return res.json();
      } catch (e) {
        const error = {
          error: `parse error: ${e}`,
          value: messages.INTERNAL_SERVER_ERROR
        };
        throw error;
      }
    })
    .then(data => {
      if (typeof data !== "object") {
        let error = {
          error: `unexpected response format: ${typeof data}`,
          value: messages.INTERNAL_SERVER_ERROR
        };
        throw error;
      }

      const results = data.results || [];

      let error = results[results.length - 1];

      if (error) {
        throw error;
      }

      return results[0];
    });
};

const getPrefectures = () => {
  return _jsonRequest("GET", "/prefectures.json");
};

const getProfile = () => {
  return _jsonRequest("GET", "/account/user.json").then(
    mappers.mapProtoAccountUserToProfileState
  );
};

const validatesEmail = email => {
  return _jsonRequest(
    "GET",
    `/account/validates-email.json?email=${encodeURIComponent(email)}`
  )
    .then(data => ({
      response: { data: { ...ZERO_VALIDATES_EMAIL_RESPONSE, ...data } }
    }))
    .catch(error => ({ error }));
};

const updateProfile = data => {
  return _jsonRequest(
    "PATCH",
    "/account/profile.json",
    mappers.mapProfileStateToProtoProfile(data)
  )
    .then(mappers.mapProtoProfileToProfileState)
    .catch(error => {
      if (isValidationError(error)) {
        throw mappers.mapProtoProfileValidationErrorToProfileState(error);
      }
      throw error;
    });
};

const getZipcode = zipcode => {
  return _jsonRequest("GET", `/zipcode-finder.json?zipcode=${zipcode}`);
};

const updatePassword = data => {
  return _jsonRequest("PATCH", "/authn/update-password.json", data)
    .then(data => ({ response: { ...data } }))
    .catch(error => ({ error }));
};

const changeEmail = data => {
  return _jsonRequest("PATCH", "/authn/change-email.json", data)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const getShippingAddresses = () => {
  return _jsonRequest("GET", "/account/shipping-addresses.json")
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const postShippingAddress = data => {
  const message = data.id
    ? messages.ADDRESS_UPDATE_SUCCESSFULLY
    : messages.ADDRESS_CREATE_SUCCESSFULLY;
  return _jsonRequest("POST", "/account/shipping-address.json", data)
    .then(data => ({ response: { data, message } }))
    .catch(error => ({ error }));
};

const deleteShippingAddress = id => {
  return _jsonRequest("DELETE", "/account/shipping-address.json", id)
    .then(() => ({
      response: { message: messages.ADDRESS_DELETE_SUCCESSFULLY }
    }))
    .catch(error => ({ error }));
};

const getWishItems = () => {
  return _jsonRequest("GET", `/account/wish-items.json?_t=${Date.now()}`)
    .then(data => ({
      response: {
        data: (data || []).map(mappers.mapProtoWishItemToWishItemDataProp)
      }
    }))
    .catch(error => ({ error }));
};

const deleteWishItems = (ids = []) => {
  return _jsonRequest("DELETE", "/account/wish-items.json", ids)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const moveWishItemsToCart = (ids = []) => {
  return _jsonRequest("POST", "/account/move-wish-items-to-cart.json", ids)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const moveCartItemsToWishlist = (articleCodes = []) => {
  return _jsonRequest("POST", "/carts-to-wish-items.json", articleCodes)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const _parseOrder = (order = {}) =>
  mappers.mapProtoOrderToCheckoutState(order || {});

const getOrders = () => {
  return _jsonRequest("GET", "/account/orders.json")
    .then(orders => orders.map(_parseOrder))
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const checkWaitingPayOrder = () => {
  return _jsonRequest("POST", "/account/checkwaitingpayorder.json", {})
    .then(() => {})
    .catch(error => ({ error }));
};

const cancelOrder = id => {
  return _jsonRequest("DELETE", "/account/orders.json", id)
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const getCart = () => {
  return _jsonRequest("GET", "/carts.json")
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const getCartItemsQuantity = () => {
  return _jsonRequest("GET", "/carts/total_quantity.json")
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const changeCartItemQuantity = (articleCode, newQuantity) => {
  return _jsonRequest("PUT", "/carts.json", articleCode, newQuantity)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const removeCartItem = articleCode => {
  return changeCartItemQuantity(articleCode, 0);
};

const clearCart = () => {
  return _jsonRequest("DELETE", "/clear_cart.json")
    .then(() => ({}))
    .catch(error => ({ error }));
};

// TODO this is a dummy API. Double confirm these after backend API is implemented.
//
// Response please prefer: https://github.com/theplant/aigle/blob/8ea98e027e15125436eeb9bbde9a6f344a2285d3/orders/proto/spec.proto#L23-L48
const getCheckout = () => {
  return _jsonRequest("GET", "/orders/checkout.json")
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const applyShippingAddress = (orderCode, shippingAddress = {}) => {
  return _jsonRequest(
    "POST",
    "/orders/apply_shipping_address.json",
    orderCode,
    shippingAddress
  )
    .then(_parseOrder)
    .then(data => ({
      response: {
        data,
        message: messages.CHECKOUT_APPLY_SHIPPING_ADDRESS_SUCCESSFULLY
      }
    }))
    .catch(error => ({ error }));
};

const applyBillingAddress = (orderCode, billingAddress = {}) => {
  return _jsonRequest(
    "POST",
    "/orders/apply_billing_address.json",
    orderCode,
    billingAddress
  )
    .then(_parseOrder)
    .then(data => ({
      response: {
        data,
        message: messages.CHECKOUT_APPLY_BILLING_ADDRESS_SUCCESSFULLY
      }
    }))
    .catch(error => ({ error }));
};

const applyCod = orderCode => {
  return _jsonRequest("POST", "/orders/apply_cod.json", orderCode)
    .then(_parseOrder)
    .then(data => ({
      response: { data, message: messages.CHECKOUT_APPLY_COD_SUCCESSFULLY }
    }))
    .catch(error => ({ error }));
};

const applyNewCreditCard = (
  orderCode,
  formData = {},
  isSavingCreditCard = false
) => {
  const creditCardInput = mappers.mapCheckoutCreditCardFormStateToProtoCreditCardInput(
    formData
  );
  return _jsonRequest(
    "POST",
    "/orders/apply_new_credit_card.json",
    orderCode,
    creditCardInput,
    isSavingCreditCard
  )
    .then(_parseOrder)
    .then(data => ({
      response: {
        data,
        message: messages.CHECKOUT_APPLY_NEW_CREDIT_CARD_SUCCESSFULLY
      }
    }))
    .catch(error => ({ error }));
};

const applyExistingCreditCard = (orderCode, creditCardID) => {
  return _jsonRequest(
    "POST",
    "/orders/apply_existing_credit_card.json",
    orderCode,
    creditCardID
  )
    .then(_parseOrder)
    .then(data => ({
      response: {
        data,
        message: messages.CHECKOUT_APPLY_EXISTING_CREDIT_CARD_SUCCESSFULLY
      }
    }))
    .catch(error => ({ error }));
};

const getCreditCards = () => {
  return _jsonRequest("GET", "/orders/my_credit_cards.json")
    .then(data => (data || []).map(mappers.mapProtoCreditCardToState))
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const createCreditCard = (formData = {}) => {
  const creditCardInput = mappers.mapCheckoutCreditCardFormStateToProtoCreditCardInput(
    formData
  );
  return _jsonRequest(
    "POST",
    "/orders/create_credit_card.json",
    creditCardInput
  )
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const deleteCreditCard = id => {
  return _jsonRequest("POST", "/orders/delete_credit_card.json", id)
    .then(() => ({
      response: { message: messages.DELETE_CREDIT_CARD_SUCCESSFULLY }
    }))
    .catch(error => ({ error }));
};

const getPoints = () => {
  return _jsonRequest("GET", "/account/points/rank.json")
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const getPointsHistory = () => {
  return _jsonRequest("GET", "/account/points/history.json")
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const getOrdersAvailablePoints = orderCode => {
  return _jsonRequest(
    "GET",
    `/orders/available_points.json?order_code=${orderCode}`
  )
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const getDeliveryDates = orderCode => {
  return _jsonRequest(
    "GET",
    `/orders/get_delivery_dates.json?order_code=${orderCode}`
  )
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const applyDeliveryInfo = (orderCode, deliveryInfo = {}) => {
  return _jsonRequest(
    "POST",
    `/orders/apply_delivery_info.json`,
    orderCode,
    deliveryInfo
  )
    .then(_parseOrder)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const applyCoupon = (orderCode, couponCode) => {
  return _jsonRequest(
    "POST",
    `/orders/apply_coupon.json`,
    orderCode,
    couponCode
  )
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const deleteAppliedCoupon = (orderCode, couponCode) => {
  return _jsonRequest(
    "POST",
    `/orders/remove_coupon.json`,
    orderCode,
    couponCode
  )
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const applyPoints = (orderCode, points) => {
  return _jsonRequest("POST", `/orders/apply_points.json`, orderCode, points)
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const removeAppliedPoints = orderCode => {
  return applyPoints(orderCode, 0);
};

const getFees = () => {
  return _jsonRequest("GET", "/orders/fees.json")
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const applyGiftWrapping = (orderCode, giftWrapping = {}) => {
  return _jsonRequest(
    "POST",
    "/orders/apply_gift_wrapping.json",
    orderCode,
    giftWrapping
  )
    .then(_parseOrder)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const confirmOrder = orderCode => {
  return _jsonRequest("POST", `/orders/confirm.json`, orderCode)
    .then(_parseOrder)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const redirect = location => (window.location = location);

const getViewedProducts = () => {
  return _jsonRequest("GET", `/account/viewed-products.json?size=50`)
    .then(data => ({
      response: {
        data: (data || []).map(mappers.mapProtoWishItemToWishItemDataProp)
      }
    }))
    .catch(error => ({ error }));
};

const getStorePickUpConfig = () => {
  return _jsonRequest("GET", `/orders/get-store-pick-up-config.json`)
    .then(data => ({ response: { data } }))
    .catch(error => ({ error }));
};

const applyStorePickUpOrder = orderCode => {
  return _jsonRequest("POST", `/orders/apply-store-pick-up.json`, orderCode)
    .then(_parseOrder)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const cancelStorePickUpOrder = orderCode => {
  return _jsonRequest("POST", `/orders/cancel-store-pick-up.json`, orderCode)
    .then(_parseOrder)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

const applyStoreForStorePickUpOrder = (storeCode, orderCode) => {
  return _jsonRequest("POST", `/orders/apply-store.json`, storeCode, orderCode)
    .then(_parseOrder)
    .then(response => ({ response }))
    .catch(error => ({ error }));
};

// eslint-disable-next-line 
export default {
  redirect,
  getPrefectures,
  getZipcode,
  validatesEmail,
  getProfile,
  updateProfile,
  updatePassword,
  changeEmail,
  getShippingAddresses,
  postShippingAddress,
  deleteShippingAddress,

  getWishItems,
  deleteWishItems,
  moveWishItemsToCart,
  moveCartItemsToWishlist,

  getCreditCards,
  createCreditCard,
  deleteCreditCard,

  getPoints,
  getPointsHistory,

  getOrders,
  checkWaitingPayOrder,
  cancelOrder,

  getCart,
  getCartItemsQuantity,
  changeCartItemQuantity,
  removeCartItem,

  getCheckout,
  getDeliveryDates,
  getOrdersAvailablePoints,
  applyDeliveryInfo,
  applyShippingAddress,
  applyBillingAddress,
  applyCod,
  applyNewCreditCard,
  applyExistingCreditCard,
  applyCoupon,
  deleteAppliedCoupon,
  applyPoints,
  removeAppliedPoints,
  getStorePickUpConfig,
  applyStorePickUpOrder,
  cancelStorePickUpOrder,
  applyStoreForStorePickUpOrder,

  getFees,
  applyGiftWrapping,

  confirmOrder,

  getViewedProducts,

  clearCart
};

export {
  filterNormalApiErrors,
  filterValidationErrors,
  filterOrderErrors,
  isValidationError,
  isOrderError,
  _parseOrder,
  _jsonRequest,
  _getURL
};
