const searchStores = (
  value: string
): Promise<google.maps.GeocoderResult | undefined> => {
  const googleMapsGeocoder = new google.maps.Geocoder();
  return new Promise((resolve) => {
    googleMapsGeocoder.geocode({ address: value }, (result, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // > Addresses are returned in the order of best to least matches.
        //
        // So we can just use the first item in `result`.
        // Ref: https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding
        resolve(result[0]);
      } else {
        resolve(undefined);
      }
    });
  });
};

export { searchStores };
