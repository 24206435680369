import React, { ComponentType } from "react";
import { SmartpayButton, IProps } from "../shared/SmartpayButton";
import { RadioInput } from "../shared/RadioInput";
import { Form } from "../../form";
import { checkoutForm, OrderServiceProps } from "../../orders/order";
import { theplant } from "../../proto";
import { saveInLocalStorage } from "../shared/PrefillInputFromLocalStorage";
import { initSmartpayMessaging } from "../../js/smartpayMessage";
import {
  CHECKOUT_CART_PATH,
  CHECKOUT_COMPLETE_PAYMENT_PATH,
} from "../../constants/Paths";
import { IData } from "../../orders";
import { InlineLoading } from "../Loading";
import { SmartpayType } from "../CompletePayment/type";
import { UploadConfirmOrderToGTM } from "../../gtm/GA";
import { ConfirmOrderError } from "../shared/CartSummaryAndConfirmButton";
import { whichBrowser } from "../../components/helper";

const form = Form.on(checkoutForm, "form");
const PaymentType = theplant.ec.service.orders.PaymentType;

const PAYMENT_CONFIG = {
  [PaymentType.SMART_PAY]: {
    title: "Smartpay",
  },
};

const WithPayRadio = (
  InsidePayButton: ComponentType<IProps>,
  type: theplant.ec.service.orders.PaymentType
) => {
  const PayRadio = ({
    isPayingAllAmountWithPoints,
    checkoutInputForm,
    cart,
    checkout,
    data,
    redirectUrl,
  }: {
    isPayingAllAmountWithPoints?: boolean;
    checkoutInputForm: theplant.ec.api.orders.ICheckoutInput;
    cart: theplant.ec.service.orders.IOrder | null;
    checkout: OrderServiceProps["checkout"];
    redirectUrl?: string;
    data: IData | null;
  }) => {
    const domRef = React.useRef<HTMLDivElement | null>(null);
    const clicked = React.useRef(false);
    const currentConfig =
      PAYMENT_CONFIG[type as keyof typeof PAYMENT_CONFIG] || {};

    const { validateInput, updateDataForm } = checkout;

    const price = cart?.sumUp?.billingAmountWithTax;
    React.useEffect(() => {
      if (type !== PaymentType.SMART_PAY) return;
      initSmartpayMessaging(".checkout-smartpay-container", {
        amount: String(price),
        typeClass: "smartpay-osm-payment-method",
        // set true here because the switch will control the PayRadio
        enable: true,
      });
    }, [price, redirectUrl]);

    const buttonClick = (
      e?:
        | React.MouseEvent<HTMLDivElement | HTMLAnchorElement, MouseEvent>
        | undefined
    ) => {
      e?.stopPropagation();
      if (!clicked.current) {
        e?.preventDefault(); // prevent default submit, wait till confirm api success
      }

      if (checkoutInputForm) {
        UploadConfirmOrderToGTM({
          order: cart,
          data: data,
          checkout: checkout.input.form,
        });

        if (type === PaymentType.SMART_PAY) {
          updateDataForm("smartPayInput", {
            sessionId: data?.smartPaySessionId,
            orderId: data?.smartPayOrderId,
            redirectUrl: redirectUrl,
          });
        }

        checkout.confirm(() => {
          clicked.current = true;
          if (type === PaymentType.SMART_PAY) {
            if (redirectUrl) {
              window.location.replace(redirectUrl);
            }
          } else {
            domRef.current?.querySelector("form")?.submit();
          }
          saveInLocalStorage(checkoutInputForm, true);
        });
      }
    };

    const contentRender = (selected: boolean) => {
      if (!selected) return null;
      if (!redirectUrl) return <InlineLoading />;
      return (
        <div className="expand-content">
          <InsidePayButton
            domRef={domRef}
            buttonClick={buttonClick}
            cart={cart}
            data={data}
          ></InsidePayButton>
          {/* 
          why set confirm order error here? 
            because thrid-party payment call confirm api when click their own button.
            confirm error close to button is easy for user to understand
            
            ui also have other benefit:
              1. can reuse selected state
              2. only show error when selected and has redirectUrl and third-party confirm button
              3.can reuse expand-content padding
          */}
          <ConfirmOrderError
            checkout={checkout}
            isAmazonPayInComplete={false}
          />
        </div>
      );
    };

    return (
      <form.on
        key={
          (!!isPayingAllAmountWithPoints).toString() +
          checkoutInputForm?.points +
          type +
          redirectUrl
        }
        field="paymentType"
        component={({ value, updateInput }) => {
          const selected = value === type;

          const changePayment = () => {
            if (selected) return;
            updateInput(type);

            if (type === PaymentType.SMART_PAY) {
              updateDataForm("smartPayInput", {
                cancelUrl: `${origin}${CHECKOUT_CART_PATH}`,
                successUrl: `${origin}${CHECKOUT_COMPLETE_PAYMENT_PATH}?type=${SmartpayType}&userAgent=${whichBrowser()}`,
              });
            }
            validateInput();
          };
          return (
            <RadioInput checked={selected} onChange={changePayment}>
              <div className="expand-title" onClick={changePayment}>
                <span>{currentConfig.title}</span>
              </div>
              {contentRender(selected)}
            </RadioInput>
          );
        }}
      />
    );
  };
  return PayRadio;
};

export const SmartpayRadio = WithPayRadio(
  SmartpayButton,
  PaymentType.SMART_PAY
);
