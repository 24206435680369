import React from "react";

import { connect } from "react-redux";
import {
  refresh as refreshProfile,
  refreshSuccess as refreshProfileSuccess,
} from "../../../actions/profile";
import {
  open as openForm,
  close as closeForm,
  changeField,
  submit,
} from "../../../actions/profileForm";
import {
  open as passwordFormOpen,
  close as passwordFormClose,
  changeField as passwordFormChangeField,
  submit as passwordFormSubmit,
} from "../../../actions/passwordForm";
import {
  open as emailFormOpen,
  close as emailFormClose,
  changeField as emailFormChangeField,
  submit as emailFormSubmit,
} from "../../../actions/emailForm";

import { EditEmailAndPassword } from "./Profile/EditForm";

const EmailAndPassword = (props: any) => {
  const { profileState, profileFormState, closeForm, refreshProfileSuccess } =
    props;
  React.useEffect(() => {
    refreshProfile();
  }, []);

  return (
    <EditEmailAndPassword
      {...props}
      state={profileFormState}
      closeForm={() => {
        closeForm();
        refreshProfileSuccess(profileState.data); // reset profile form state
      }}
    />
  );
};

export default connect(
  ({ prefectures, profile, profileForm, passwordForm, emailForm }: any) => ({
    prefecturesState: prefectures,
    profileState: profile,
    profileFormState: profileForm,
    passwordFormState: passwordForm,
    emailFormState: emailForm,
  }),
  {
    refreshProfile,
    openForm,
    closeForm,
    refreshProfileSuccess,
    changeField,
    submit,
    passwordFormOpen,
    passwordFormClose,
    passwordFormChangeField,
    passwordFormSubmit,
    emailFormOpen,
    emailFormClose,
    emailFormChangeField,
    emailFormSubmit,
  }
)(EmailAndPassword);
