import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { scrollToFirstError } from "../shared/scrollToFirstError";

import { CheckoutProps } from "../.";
import {
  DeliveryMethods,
  HomeDeliveryOptions,
  StorePickUp,
  CombiniDeliveryOptions,
} from "../shared/Delivery";
import { CartSummary } from "../shared/CartSummary";
import { GiftWrapping } from "../shared/GiftWrapping";
import { PrefillField } from "../shared/PrefillField";
import { SpecifyBillingAddressOption } from "./SpecifyBillingAddressOption";
import { AddressSelector } from "./AddressSelector";
import { NameAndAddress } from "./NameAndAddress";
import { EmptyCart } from "../Cart/EmptyCart";
import { emitQubitItemsData } from "../Cart";
import { CHECKOUT_PAYMENT_PATH } from "../../constants/Paths";
import {
  withInvalidAddressFormEditable,
  InvalidAddressFormEditableProps,
} from "./withInvalidAddressFormEditable";
import { CHECKOUT_SHIPPING_PATH } from "../../constants/Paths";
import Stepper from "../Molecules/Stepper";
import { Email } from "./Email";
import {
  checkoutForm,
  selectCouponsUsed,
  selectCartIsEmpty,
} from "../../orders";
import { Form } from "../../form";
import {
  UploadCheckoutProgressDataToGTM,
  UploadCheckoutEventDataToGTM,
} from "../../gtm/GA";
import GA4PageView from "../ga4PageView";
import { PaymentList } from "../shared/PaymentList";
import { OrderServiceProps } from "../../orders/order";
import { Errors } from "../shared/Errors";

import { theplant, proto } from "../../proto";
import { Loading } from "../Loading";
import { getDataFromLocalStorage } from "../shared/PrefillInputFromLocalStorage";
const PaymentType = theplant.ec.service.orders.PaymentType;
const DeliveryMethod = theplant.ec.api.orders.DeliveryMethod;

type IOrder = theplant.ec.service.orders.IOrder;

const input = Form.on(checkoutForm, "form");
const shippingAddressForm = Form.on(input, "shippingAddress");
const billingAddressForm = Form.on(input, "billingAddress");

class ShippingView extends React.Component<
  CheckoutProps & InvalidAddressFormEditableProps & RouteComponentProps<{}>,
  {
    loading: boolean;
    emailExistError?: proto.ValidationError.IFieldViolation[];
  }
> {
  readonly state = {
    loading: true,
    emailExistError: undefined,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // fix: [LAX-5417] cancel payment not show user input in normal shipping page
    // set old user-input store in browser to redux form
    getDataFromLocalStorage(this.props, true);

    const {
      checkout: {
        validateInput,
        resetFormState,
        input: {
          form: { paymentType },
        },
        updateDataForm,
      },
    } = this.props;

    // LAX-5313: save paymentType to storage, and call validateInput without payment
    if (paymentType !== PaymentType.NO_PAYMENT) {
      sessionStorage.setItem("paymentType", String(paymentType));
    }
    updateDataForm("paymentType", PaymentType.NO_PAYMENT);

    // clear all dirty field onEnter shipping page,
    // reset submit state to "new"
    resetFormState();

    // fetch data: abilities, user's addresses, credit cards
    validateInput();

    emitQubitItemsData(this.props.cart.cart, "checkout");

    GA4PageView();
  }

  UNSAFE_componentWillReceiveProps(nextProps: CheckoutProps) {
    // logged in user enter this page through login page,
    // loaded user addresses data
    if (
      this.state.loading &&
      this.props.data.state.type === "fetching" &&
      nextProps.data.state.type !== "fetching"
    ) {
      this.selectDefaultAddressIfNoneSelected(nextProps);

      this.setState({
        loading: false,
      });
    }
  }

  selectDefaultAddressIfNoneSelected = (props: CheckoutProps) => {
    const {
      checkout: {
        selectAddress,
        input: {
          form: {
            shippingAddressId,
            billingAddressId,
            newShippingAddress,
            newBillingAddress,
          },
        },
      },
      data: { data },
    } = props;

    const addresses = data && data.userShippingAddress;
    if (addresses && addresses.length > 0) {
      const defaultAddress =
        addresses.find((a) => a.isDefault === true) || addresses[0];
      // only when has selected id and not open new address form, then set address id to checkout
      // fix [LAX-5568] and [LAX-5588]
      if (!shippingAddressId && !newShippingAddress) {
        selectAddress("shippingAddress", defaultAddress.id || "");
      }
      if (!billingAddressId && !newBillingAddress) {
        selectAddress("billingAddress", defaultAddress.id || "");
      }
    }
  };

  checkEmailAndConfirm: OrderServiceProps["checkout"]["validateShippingInput"] =
    (onResolve, onReject) => {
      this.setState({
        emailExistError: undefined,
      });

      const {
        users: { checkEmail },
        cart: { isGuestUser },
        checkout: {
          input: { form },
        },
        onConfirm,
      } = this.props;

      if (isGuestUser) {
        const checkoutEmail = form.email;
        if (checkoutEmail) {
          checkEmail(checkoutEmail).then((isEmailExists) => {
            if (typeof isEmailExists === "boolean" && isEmailExists) {
              this.setState({
                emailExistError: [
                  {
                    field: "Form.ShippingAddress.Email",
                    code: "EMAIL_ALREADY_EXISTS",
                    msg: "email exist, go to login",
                  },
                ],
              });
              scrollToFirstError();
            } else {
              onConfirm(onResolve, onReject);
            }
          });
        } else {
          onConfirm(onResolve, onReject);
        }
      } else {
        onConfirm(onResolve, onReject);
      }
    };

  render() {
    const {
      checkout: {
        input: {
          form: {
            deliveryMethod,
            shippingAddressId,
            billingAddressId,
            specifyBillingAddress,
            newBillingAddress,
            newShippingAddress,
            email,
          },
        },
        selectAddress,
        toSetNewAddress,
      },
      data: { data },
      cart: { cart, isGuestUser },
      showAddressForm,
      onChangeShowAddressForm,
    } = this.props;

    const userShippingAddress = data && data.userShippingAddress;

    const couponsUsed = selectCouponsUsed(cart);

    if (this.state.loading) {
      return <Loading />;
    }

    if (selectCartIsEmpty(cart)) {
      return <EmptyCart />;
    }

    const buttonOnclickToGTM = (cart: IOrder, eventName: string) => () => {
      UploadCheckoutEventDataToGTM({
        order: cart,
        eventName,
        deliveryMethod,
        giftWrapping: this.props.checkout.input.form?.giftWrapping,
      });
      this.checkEmailAndConfirm(() => {
        this.props.history.push(CHECKOUT_PAYMENT_PATH);
      }, scrollToFirstError);
    };

    return (
      <div className="shipping-screen layout-content">
        <PrefillField field="isTermsAgreed" value={true} />
        <PrefillField field="email" value={email || ""} />
        <PrefillField
          key={(couponsUsed || "").toString()}
          field="couponCode"
          value={couponsUsed}
        />

        <div className="row">
          <div className="col span-8 padding-md">
            <Stepper pathname={CHECKOUT_SHIPPING_PATH} />

            <div className="white-panel">
              <DeliveryMethods data={data} isGuestUser={isGuestUser} />

              <CombiniDeliveryOptions
                data={data}
                checkout={this.props.checkout}
              />

              <StorePickUp data={data} checkout={this.props.checkout} />

              <div className="delivery-addresses">
                {deliveryMethod === DeliveryMethod.STORE_PICKUP ||
                deliveryMethod === DeliveryMethod.CONVENIENCE_PICKUP ? null : (
                  <>
                    <h3 className="mb-0">配送先情報</h3>
                    {userShippingAddress && userShippingAddress.length > 0 ? (
                      <AddressSelector
                        addresses={userShippingAddress}
                        form={shippingAddressForm}
                        addressId={shippingAddressId}
                        selectAddress={selectAddress}
                        isShowAddressForm={showAddressForm.shippingAddress}
                        onChangeShowAddressForm={onChangeShowAddressForm}
                        newShippingAddress={newShippingAddress}
                        toSetNewAddress={toSetNewAddress}
                      />
                    ) : (
                      <NameAndAddress
                        form={shippingAddressForm}
                        EmailField={
                          <Email
                            isGuestUser={isGuestUser}
                            emailExistError={this.state.emailExistError}
                          />
                        }
                      />
                    )}

                    <SpecifyBillingAddressOption />
                  </>
                )}

                {specifyBillingAddress ? (
                  <>
                    <h3 className="mb-0">ご請求先住所</h3>
                    {userShippingAddress && userShippingAddress.length > 0 ? (
                      <AddressSelector
                        addresses={userShippingAddress}
                        form={billingAddressForm}
                        addressId={billingAddressId}
                        selectAddress={selectAddress}
                        isShowAddressForm={showAddressForm.billingAddress}
                        onChangeShowAddressForm={onChangeShowAddressForm}
                        newBillingAddress={newBillingAddress}
                        toSetNewAddress={toSetNewAddress}
                      />
                    ) : (
                      <NameAndAddress
                        form={billingAddressForm}
                        EmailField={
                          deliveryMethod ===
                          theplant.ec.api.orders.DeliveryMethod
                            .HOME_DELIVERY ? null : (
                            <Email isGuestUser={isGuestUser} />
                          )
                        }
                      />
                    )}
                  </>
                ) : null}
              </div>

              {deliveryMethod === DeliveryMethod.STORE_PICKUP && (
                <p className="desc">
                  ※店舗受け取りの場合のお支払い方法は、クレジットカードのみです。
                </p>
              )}
            </div>

            <HomeDeliveryOptions data={data} checkout={this.props.checkout} />

            <GiftWrapping
              data={data}
              form={this.props.checkout.input.form}
              cart={cart}
              state={this.props.checkout.input.state}
            />
          </div>

          <div className="col span-4 white-panel sumup-panel">
            <div>
              <CartSummary cart={cart} />

              <div className="confirm-btn-wrapper">
                <button
                  className="primary-btn"
                  onClick={buttonOnclickToGTM(cart || {}, "add_shipping_info")}
                >
                  次へ進む
                </button>
              </div>

              {this.state.emailExistError && (
                <div className="text-center">
                  <Errors errors={this.state.emailExistError || []} />
                </div>
              )}
            </div>

            <PaymentList withAmazonPay={true} />

            <UploadCheckoutProgressDataToGTM order={cart} fieldStep={2} />
          </div>
        </div>
      </div>
    );
  }
}

const Shipping = withInvalidAddressFormEditable(withRouter(ShippingView));

export { Shipping };
