// pad a number with leading zeros in JavaScript
// Copied from http://stackoverflow.com/a/10073788
const pad = (n, width, z) => {
  z = z || "0";
  // eslint-disable-next-line
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const combineMultipleUiErrors = (key = "value", ...states) =>
  states
    .map(state => (state.ui.error || {})[key])
    .reduce((errors, errorValue) => {
      if (errorValue) {
        errors.push(errorValue);
      }
      return errors;
    }, []);

export { pad, combineMultipleUiErrors };
