import { connect } from "react-redux";
import { CheckoutState } from "./reducer";
import { theplant } from "../proto";
import { IServiceError } from "@theplant/ecjs/prottp";
type IValidationResult = theplant.ec.api.orders.IValidationResult;

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type IData = Omit<IValidationResult, "order" | "error">;

type State = {
  data: IData | null;
  state:
    | {
        type: null | "fetching";
      }
    | {
        type: "error";
        error: IServiceError;
      };
};

const initialState: State = { data: null, state: { type: null } };

type Action =
  | { type: "WILL_FETCH_CHECKOUT_DATA" }
  | { type: "FETCH_CHECKOUT_DATA"; data: IData }
  | { type: "FETCH_CHECKOUT_DATA_ERROR"; data: IData; error: IServiceError };

const reducer = (s: State = initialState, a: Action): State => {
  switch (a.type) {
    case "WILL_FETCH_CHECKOUT_DATA":
      return { ...s, state: { type: "fetching" } };
    case "FETCH_CHECKOUT_DATA":
      return { data: a.data, state: { type: null } };
    case "FETCH_CHECKOUT_DATA_ERROR":
      return { ...s, data: a.data, state: { type: "error", error: a.error } };
  }
  return s;
};

type DP = {
  refreshData: (data: IData) => void;
  willRefreshData: () => void;
  refreshDataError: (data: IData, error: IServiceError) => void;
};

export type DataProps = { data: State & DP };

const refreshData = (data: IData): Action => ({
  type: "FETCH_CHECKOUT_DATA",
  data
});

const refreshDataError = (data: IData, error: IServiceError): Action => ({
  type: "FETCH_CHECKOUT_DATA_ERROR",
  data,
  error
});

const mapDispatchToProps: DP = {
  refreshData,
  willRefreshData: () => ({ type: "WILL_FETCH_CHECKOUT_DATA" }),
  refreshDataError
};

const withData = connect(
  ({ checkout: { data } }: { checkout: CheckoutState }) => data,
  mapDispatchToProps,
  (s, d, o) => ({
    data: {
      ...s,
      ...d
    },
    ...(o as any)
  })
);

const selectAbilityIsEnabled = (
  data: IData | null,
  ability: theplant.ec.api.orders.Ability
): boolean =>
  !!data && !!data.Abilities && data.Abilities.indexOf(ability) !== -1;

const selectGiftWrappingFee = (data: IData | null): number =>
  Number((data && data.feeSetting && data.feeSetting.giftWrappingFee) || 0);

const selectCODFee = (data: IData | null): number =>
  Number((data && data.feeSetting && data.feeSetting.codFee) || 0);

export type DataState = State;
export type DataAction = Action;

export {
  reducer as dataReducer,
  initialState as dataInitialState,
  withData,
  selectAbilityIsEnabled,
  selectGiftWrappingFee,
  selectCODFee
};
