import * as React from "react";
import { AmazonPayButton } from "../shared/AmazonPayButton";
import { RadioInput } from "../shared/RadioInput";
import { Form } from "../../form";
import { checkoutForm } from "../../orders/order";
import { theplant } from "../../proto";
import { saveInLocalStorage } from "../shared/PrefillInputFromLocalStorage";
const form = Form.on(checkoutForm, "form");

const PaymentType = theplant.ec.service.orders.PaymentType;

const AmazonPayRadio = ({
  isPayingAllAmountWithPoints,
  checkoutInputForm,
}: {
  isPayingAllAmountWithPoints?: boolean;
  checkoutInputForm: theplant.ec.api.orders.ICheckoutInput;
}) => (
  <form.on
    key={
      (!!isPayingAllAmountWithPoints).toString() +
      checkoutInputForm?.points +
      PaymentType.AMAZON_PAY
    }
    field="paymentType"
    component={({ value, updateInput, touchInput }) => {
      const selected = value === PaymentType.AMAZON_PAY;
      const chooseAmazonPay = () => {
        updateInput(PaymentType.AMAZON_PAY);
        touchInput();
      };

      return (
        <RadioInput
          checked={value === PaymentType.AMAZON_PAY}
          onChange={chooseAmazonPay}
        >
          <div className="expand-title" onClick={chooseAmazonPay}>
            <span>Amazon Pay</span>
          </div>
          {selected && (
            <div className="expand-content">
              <p>Amazonのログインページに移動します</p>
              <p className="desc">
                Amazonアカウントにご登録の住所・クレジットカード情報を利用して、簡単にご注文が出来ます。
              </p>
              <AmazonPayButton
                onClickAmazonButton={() => {
                  if (checkoutInputForm) {
                    saveInLocalStorage(checkoutInputForm);
                  }
                }}
              />
            </div>
          )}
        </RadioInput>
      );
    }}
  />
);

export { AmazonPayRadio };
