import React, { useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import { withConfig, ConfigProps } from "../../config";

const OpluxInPayment = withConfig(
  ({
    setDeviceInfo,
    config
  }: {
    setDeviceInfo: (deviceInfo: string | null | undefined) => void;
  } & ConfigProps) => {
    const ref = useRef(null);

    useEffect(() => {
      if (typeof window !== "undefined") {
        const opluxScript = config.OPLUX_SERVICE_SCRIPTURL;

        if (opluxScript) {
          const $script = require("scriptjs");

          // download script
          $script(opluxScript, () => {
            if (ref.current) {
              const dom = findDOMNode(ref.current);
              if (dom) {
                const value = (dom as Element).getAttribute("value");
                if (value) {
                  setDeviceInfo(value);
                }
              }
            }
          });
        }
      }
    }, [config.OPLUX_SERVICE_SCRIPTURL, setDeviceInfo]);

    if (!config.OPLUX_SERVICE_SCRIPTURL) {
      return null;
    }

    return (
      <>
        <input type="hidden" id="fraudbuster" name="fraudbuster" ref={ref} />
        <script src={config.OPLUX_SERVICE_SCRIPTURL} />
      </>
    );
  }
);

export { OpluxInPayment };
