export const VALIDATION_ERROR = "validation error";

export const ORDER_ERROR = "order error";

export const ORDER_ERRORS_GLOBAL = "global";
export const ORDER_ERRORS_BILLING_ADDRESS = "billing_address";
export const ORDER_ERRORS_SHIPPING_ADDRESS = "shipping_address";
export const ORDER_ERRORS_COUPON = "coupon";
export const ORDER_ERRORS_POINTS = "points";
export const ORDER_ERRORS_DELIVERY = "delivery";
export const ORDER_ERRORS_CREDIT_CARD = "credit_card";
export const ORDER_ERRORS_GIFT_WRAPPING = "gift_wrapping";

export const CHECKOUT_ERROR_INVALID_USER_BILLING_ADDRESS =
  "個人情報の内容が不十分です。";
