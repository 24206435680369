import * as common from "./common";
import * as address from "./address";
import * as prefectures from "./prefectures";
import * as creditCards from "./creditCards";

// eslint-disable-next-line
export default {
  ...common,
  ...address,
  ...prefectures,
  ...creditCards
};
