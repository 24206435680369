import React from "react";
import cx from "classnames";

import { Errors } from "../../../shared/Errors";
import {
  GOOGLE_MAP_SCRIPT_URL_PREFIX,
  GOOGLE_MAP_KEY,
  defaultMapCenter,
  defaultZoomLevel,
  TIMEOUT_FOR_GET_USER_LOCATION,
  MAX_RADIUS_FOR_NEARBY_A_POINT,
} from "../../../../constants/GoogleMap";
import aigleMapPin from "../../../../img/location-aigle-new-shad.png";
import { StoreFinderManager } from "../../../../storeFinder";
import { List } from "./List";
import { Map } from "./Map";
import { Input } from "../../Input";

import { checkoutForm, OrderServiceProps } from "../../../../orders/order";
import { DataProps, selectAbilityIsEnabled } from "../../../../orders/data";
import { Form } from "../../../../form";

import { theplant } from "../../../../proto";
import { InlineLoading } from "../../../Loading";
import { withConfig, ConfigProps } from "../../../../config";
type IStore = theplant.ec.service.stores.IStore;
const DeliveryMethod = theplant.ec.api.orders.DeliveryMethod;
const Ability = theplant.ec.api.orders.Ability;

const input = Form.on(checkoutForm, "form");
const storeIdInput = Form.on(input, "storeId");

interface StorePickUpProps {
  data: DataProps["data"]["data"];
  checkout: OrderServiceProps["checkout"];
}

const SelectedStore = ({
  selectedStore,
  onChange,
}: {
  selectedStore: IStore;
  onChange: () => void;
}) => (
  <div className="selected-store">
    <h3 className="section-title">選択中の店舗：</h3>
    <div className="shop-info">
      <h4>{selectedStore.name || ""}</h4>
      <p>
        〒{selectedStore.zip}
        <br />
        {selectedStore.city}
        {selectedStore.address}
        <br />
        電話：
        {selectedStore.phone}
      </p>
      {/* eslint-disable-next-line */}
      <button type="button" className="outline-btn" onClick={onChange}>
        受け取り店舗を変更する
      </button>
    </div>
  </div>
);

class StorePickUp extends React.Component<StorePickUpProps & ConfigProps> {
  UNSAFE_componentWillReceiveProps(nextProps: StorePickUpProps) {
    const {
      data,
      checkout: {
        specifyDeliveryMethod,
        input: {
          form: { deliveryMethod },
        },
      },
    } = nextProps;

    const methodSelected = deliveryMethod === DeliveryMethod.STORE_PICKUP;
    const canStorePickUp = selectAbilityIsEnabled(
      data,
      Ability.CAN_STORE_PICKUP
    );

    // store pick up is disabled, preset home delivery
    if (methodSelected && !canStorePickUp) {
      specifyDeliveryMethod(DeliveryMethod.HOME_DELIVERY);
    }
  }
  render() {
    const {
      data,
      checkout: {
        input: {
          form: { deliveryMethod, storeId },
        },
        updateStoreId,
      },
      config,
    } = this.props;

    const methodSelected = deliveryMethod === DeliveryMethod.STORE_PICKUP;
    const canStorePickUp = selectAbilityIsEnabled(
      data,
      Ability.CAN_STORE_PICKUP
    );
    if (!methodSelected || !canStorePickUp) {
      return null;
    }

    return (
      <section className="delivery-options">
        <StoreFinderManager
          googleMapURL={`${GOOGLE_MAP_SCRIPT_URL_PREFIX}${
            config.GOOGLE_MAP_KEY || GOOGLE_MAP_KEY
          }`}
          defaultMapCenter={defaultMapCenter}
          defaultZoomLevel={defaultZoomLevel}
          timeoutForGetUserLocation={TIMEOUT_FOR_GET_USER_LOCATION}
          maxRadiusForNearbyAPoint={MAX_RADIUS_FOR_NEARBY_A_POINT}
          markerIcon={aigleMapPin}
          markerSize={{ width: 32, height: 39 }}
          renderInfoWindowContent={(store) => `
            <div class="shop-info clearfix">
                <p class="form-divider"></p>
                <h4>${store.name}</h4>
                <p class="shop-address">
                    〒${store.zip}<br/>
                    ${store.city}${store.address}<br/>
                    電話: ${store.phone}
                </p>
                <a href="javascript:;" class="add-shop primary-btn">この店舗で受け取る</a>
            </div>`}
          bindInfoWindowEvents={(storeId) => {
            const button = document.querySelector(".add-shop.primary-btn");

            button &&
              button.addEventListener("click", () => {
                if (storeId && storeId !== "") {
                  updateStoreId(storeId);
                }
              });
          }}
          onSelectStore={(id) => {
            if (id && id !== "") {
              updateStoreId(id);
            }
          }}
          onFetchMyStore={(id) => {
            // pre-select my store on select store pick up
            if (id && !storeId) {
              updateStoreId(id);
            }
          }}
        >
          {(
            {
              state,
              allStores,
              myStore,
              storesResults,
              handleSearchStores,
              handleSearchInputKeyPress,
            },
            mapRef,
            searchInputRef
          ) => {
            return (
              <div className="store-finder-wrapper">
                {state.type === "loading" ? <InlineLoading /> : null}

                {!!storeId && state.type !== "loading" ? (
                  <SelectedStore
                    selectedStore={
                      allStores.find((s) => s.id === storeId) || {}
                    }
                    onChange={() => {
                      updateStoreId(null);
                    }}
                  />
                ) : null}

                <div
                  className={cx("store-finder mb-15", {
                    hidden: !!storeId || state.type === "loading",
                  })}
                >
                  {myStore &&
                  storeId &&
                  allStores.find((s) => s.id === myStore.id) !== undefined ? (
                    <div className="shop-info my-store">
                      <h3>選択中の店舗：</h3>
                      <h4>{myStore.name}</h4>
                      <p>
                        〒{myStore.zip}
                        <br />
                        {myStore.city}
                        {myStore.address}
                        <br />
                        電話: {myStore.phone}
                      </p>
                      <button
                        type="button"
                        className="outline-btn"
                        onClick={() => {
                          if (!!myStore.id && myStore.id !== "") {
                            updateStoreId(myStore.id);
                          }
                        }}
                      >
                        この店舗で受け取る
                      </button>
                    </div>
                  ) : null}

                  <div className="form-input-group">
                    <Input
                      type="text"
                      id="search-shop-field"
                      inputRef={searchInputRef}
                      placeholder="店舗名または住所の一部を入力"
                      onKeyPress={handleSearchInputKeyPress}
                    />
                    <button
                      className="outline-btn"
                      type="button"
                      onClick={handleSearchStores}
                    >
                      店舗検索
                    </button>
                  </div>

                  <div className="map-list-wrapper">
                    {state.type === "searching" ? <InlineLoading /> : null}
                    <Map mapRef={mapRef} visible={false} />

                    <List
                      visible={true}
                      stores={storesResults}
                      updateStoreId={updateStoreId}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </StoreFinderManager>

        <storeIdInput.field
          component={({ errors, dirty }) => (
            <Errors errors={dirty ? errors : []} />
          )}
        />
      </section>
    );
  }
}

const WithConfigStorePickUp = withConfig(StorePickUp);
export { WithConfigStorePickUp as StorePickUp };
