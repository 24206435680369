import * as React from "react";

import { StoreFinderProps, withStoreFinder, GeocoderResult } from "./stores";
import $script from "scriptjs";

import { theplant } from "../proto";
type IStore = theplant.ec.service.stores.IStore;

// https://stackoverflow.com/questions/43714895/google-is-not-defined-in-react-app-using-create-react-app#answer-43718073
let google = (window as any).google;

export interface StoreFinderMangerProps {
  googleMapURL: string;
  defaultMapCenter?: google.maps.LatLngLiteral;
  defaultZoomLevel?: number;
  timeoutForGetUserLocation: number;
  maxRadiusForNearbyAPoint: number;
  markerIcon?: string;
  markerSize?: { width: number; height: number };
  renderInfoWindowContent: (store: IStore) => string;
  bindInfoWindowEvents?: (storeId: string | null | undefined) => void;
  onSelectStore: (storeId?: string | null) => void;
  onFetchMyStore?: (storeId?: string | null) => void;
  children: (
    storeFinder: StoreFinderProps["storeFinder"] & {
      handleSearchStores: () => void;
      handleSearchInputKeyPress: (
        evt: React.KeyboardEvent<HTMLInputElement>
      ) => void;
    },
    mapRef: (ele: HTMLDivElement) => void,
    searchInputRef: (ele: HTMLInputElement) => void
  ) => React.ReactNode;
}

class _StoreFinderManager extends React.Component<
  StoreFinderProps & StoreFinderMangerProps
> {
  private searchInputRef: HTMLInputElement | null = null;
  private searchBox: google.maps.places.SearchBox | null = null;

  private mapRef: HTMLDivElement | null = null;
  private map: google.maps.Map | null = null;

  private prevMapCenter: google.maps.LatLngLiteral | null = null;
  private prevMapZoom: number | null = null;

  private markers: (google.maps.Marker | null)[] = [];
  private openedInfoWindow: google.maps.InfoWindow | null = null;

  componentDidMount() {
    const { defaultMapCenter, defaultZoomLevel } = this.props;
    this.prevMapCenter = defaultMapCenter || null;
    this.prevMapZoom = defaultZoomLevel || null;

    this.props.storeFinder.fetchBoutiqueStores().then(() => {
      const {
        storeFinder: { allStores, myStore },
        onFetchMyStore,
        googleMapURL,
      } = this.props;

      if (
        onFetchMyStore !== undefined &&
        myStore &&
        allStores.find((s) => s.id === myStore.id) !== undefined
      ) {
        onFetchMyStore(myStore.id);
      }

      if (!!google) {
        this.initMap(allStores || []);
      } else {
        $script(googleMapURL, () => {
          google = (window as any).google;

          this.initMap(allStores || []);
        });
      }
    });
  }

  initMap = (stores: IStore[]) => {
    // filter out invalid stores(without lat, lng data)
    const filteredStores = (stores || []).filter(
      (store) => store.latitude && store.longitude
    );
    this.props.storeFinder.refreshStores(filteredStores);

    // init map

    if (this.mapRef) {
      this.map = new google.maps.Map(this.mapRef as HTMLDivElement, {
        zoom: this.prevMapZoom || undefined,
        center: this.prevMapCenter || undefined,
      });
    }

    // init searchBox
    this.searchBox = new google.maps.places.SearchBox(
      this.searchInputRef as HTMLInputElement
    );
    this.searchBox &&
      this.searchBox.addListener("places_changed", this.handleSearchStores);

    // get user position, sort stores by the distance to user location(from near to far)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const posLatLng = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );

          // sort store list by distance
          const sortedStores = this.props.storeFinder.allStores.sort(
            (storeA, storeB) => {
              const storeALatLng = new google.maps.LatLng(
                storeA.latitude || 0,
                storeA.longitude || 0
              );
              const storeBLatLng = new google.maps.LatLng(
                storeB.latitude || 0,
                storeB.longitude || 0
              );

              return (
                google.maps.geometry.spherical.computeDistanceBetween(
                  posLatLng,
                  storeALatLng
                ) -
                google.maps.geometry.spherical.computeDistanceBetween(
                  posLatLng,
                  storeBLatLng
                )
              );
            }
          );

          this.updateMap(sortedStores);
        },
        () => {
          this.handleLocationError();
        },
        {
          timeout: this.props.timeoutForGetUserLocation,
        }
      );
    } else {
      this.handleLocationError();
    }
  };

  // display stores with default order
  handleLocationError = () => {
    this.updateMap(this.props.storeFinder.allStores);
  };

  // press 'Enter' key on the searchBox should trigger search
  handleSearchInputKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      google.maps.event.trigger(this.searchBox, "place_changed");
    }
  };

  // create marker for stores, and set best viewport for stores' markers
  updateMap = (stores: IStore[]) => {
    if (this.map === null) {
      return;
    }

    // cannot find any matched stores, should show all stores
    if (stores.length === 0) {
      stores = this.props.storeFinder.allStores;
    }

    // create marker for stores
    this.markers = this.createMarkers(stores);

    // create viewport include all stores
    const bounds = new google.maps.LatLngBounds();

    stores.forEach((store) => {
      const storePosition = new google.maps.LatLng(
        store.latitude || 0,
        store.longitude || 0
      );
      bounds.extend(storePosition);
    });

    // if only one store, then zoom the store, and set it center
    if (stores.length === 1) {
      const store = stores[0];
      const storePosition = new google.maps.LatLng(
        store.latitude || 0,
        store.longitude || 0
      );

      this.map.setCenter(storePosition);
      this.map.setZoom(12);
    } else {
      this.map.fitBounds(bounds);
    }

    this.props.storeFinder.refreshStoresResults(stores);
  };

  handleSearchStores = () => {
    const allStores = this.props.storeFinder.allStores;
    const matchNameStores: IStore[] = [];

    const inputValue = (this.searchInputRef as HTMLInputElement).value || "";

    if (inputValue.trim() === "") {
      this.updateMap(allStores);
      return;
    } else {
      // find stores by store name, stores matched name stores in 'matchNameStores'
      allStores.forEach((store) => {
        if (store.name && store.name.indexOf(inputValue.trim()) !== -1) {
          matchNameStores.push(store);
        }
      });
    }

    this.props.storeFinder.searchStores(inputValue).then((result) => {
      let nearbyStores: IStore[] = [];
      // cannot geocode the input address
      // or cannot get the geometry info from the result
      if (!result || !result.geometry) {
        // but can find matched name stores,
        // return matched name stores
        if (matchNameStores.length > 0) {
          this.updateMap(matchNameStores);
        } else {
          // also cannnot find matched name stores,
          // return allStores
          this.updateMap(allStores);
        }

        return;
      }

      const geometry = result.geometry;

      // if search result matches an area,
      // then draw a reactagle by result's viewport, filter stores inside it
      if (geometry.bounds) {
        nearbyStores = this.searchNearbyStoresInRectangle(geometry);
      } else {
        // draw a circle with radius = MAX_RADIUS_FOR_NEARBY_A_POINT,
        // then filter stores inside it
        allStores.forEach((store) => {
          const resultLatLng = new google.maps.LatLng(
            geometry.location.lat(),
            geometry.location.lng()
          );
          const storeLatLng = new google.maps.LatLng(
            store.latitude || 0,
            store.longitude || 0
          );

          if (
            google.maps.geometry.spherical.computeDistanceBetween(
              resultLatLng,
              storeLatLng
            ) <= this.props.maxRadiusForNearbyAPoint
          ) {
            nearbyStores.push(store);
          }
        });
      }

      const stores = [...nearbyStores];
      // add name matched stores into the results if the result does't include them
      for (let i = 0; i < matchNameStores.length; i++) {
        const matchNameStore = matchNameStores[i];
        if (stores.indexOf(matchNameStore) === -1) {
          stores.push(matchNameStore);
        }
      }
      this.updateMap(stores);
    });
  };

  searchNearbyStoresInRectangle = (geometry: GeocoderResult["geometry"]) => {
    const resultBounds: google.maps.LatLngBoundsLiteral = {
      north: geometry.viewport.getNorthEast().lat(),
      east: geometry.viewport.getNorthEast().lng(),
      south: geometry.viewport.getSouthWest().lat(),
      west: geometry.viewport.getSouthWest().lng(),
    };

    const rectangle = new google.maps.Rectangle({ bounds: resultBounds });
    const rectangleBounds = rectangle.getBounds();

    const nearbyStores: IStore[] = [];
    this.props.storeFinder.allStores.forEach((store) => {
      const storePosition = new google.maps.LatLng(
        store.latitude || 0,
        store.longitude || 0
      );
      if (rectangleBounds.contains(storePosition)) {
        nearbyStores.push(store);
      }
    });

    return nearbyStores;
  };

  createStoreMarker = (store: IStore) => {
    if (this.map === null) {
      return null;
    }

    const { markerIcon, markerSize, renderInfoWindowContent } = this.props;

    const marker = new google.maps.Marker({
      position: { lat: store.latitude || 0, lng: store.longitude || 0 },
      map: this.map,
      icon: {
        url: markerIcon || "",
        scaledSize:
          markerSize &&
          new google.maps.Size(markerSize.width, markerSize.height),
      },
      title: store.name || "",
    });

    const contentString = renderInfoWindowContent(store);

    this.addInfoWindow(marker, contentString, store.id);

    return marker;
  };

  clearMarkers = () => {
    if (this.markers && this.markers.length !== 0) {
      this.markers.forEach((marker) => {
        if (marker !== null) {
          marker.setMap(null);
        }
      });
    }
  };

  createMarkers = (stores: IStore[]) => {
    this.clearMarkers();

    return stores.map(this.createStoreMarker);
  };

  addInfoWindow = (
    marker: google.maps.Marker,
    content: string,
    storeId?: string | null
  ) => {
    const infoWindow = new google.maps.InfoWindow({ content });

    google.maps.event.addListener(marker, "click", () => {
      if (this.map === null) {
        return;
      }

      // close previous info window
      if (this.openedInfoWindow) {
        this.openedInfoWindow.close();
      }

      infoWindow.open(this.map, marker);
      this.openedInfoWindow = infoWindow;

      google.maps.event.addListener(infoWindow, "domready", () => {
        this.props.bindInfoWindowEvents &&
          this.props.bindInfoWindowEvents(storeId);
      });
    });
  };

  render() {
    return (
      <>
        {this.props.children(
          {
            ...this.props.storeFinder,
            handleSearchStores: this.handleSearchStores,
            handleSearchInputKeyPress: this.handleSearchInputKeyPress,
          },
          (ele) => (this.mapRef = ele),
          (ele) => (this.searchInputRef = ele)
        )}
      </>
    );
  }
}

const StoreFinderManager = withStoreFinder(
  _StoreFinderManager
) as React.ComponentClass<StoreFinderMangerProps>;

export { StoreFinderManager };
