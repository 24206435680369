import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";
import { removeFieldErrorMessages } from "./helpers/validationError";

const initialState = {
  data: {},
  ui: {
    isOpen: false,
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_CLOSE:
      return { ...s, isOpen: false };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_WILL_SUBMIT:
      return { ...s, fetching: true, error: null };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT:
      return { ...s, fetching: false, error: null, isOpen: false };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_LOAD:
      return { ...s, fetching: false, error: null, isOpen: true };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_NEW:
      return { ...s, fetching: false, error: null, isOpen: true };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_CHANGE_FIELD:
      const error = removeFieldErrorMessages(s.error, a.field);
      return { ...s, error };
    case types.ACCOUNT_SHIPPING_ADDRESS_WILL_SELECT_AS_DEFAULT:
      return { ...s, fetching: true, error: null };
    case types.ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT:
      return { ...s, fetching: false, error: null };
    case types.ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT_FAILED:
      return { ...s, fetching: false, error: a.error };
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_CHANGE_FIELD:
      return { ...s, [a.field]: a.value };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_LOAD:
      return { ...(a.data || {}) };
    case types.ACCOUNT_SHIPPING_ADDRESS_FORM_NEW:
      return {};
    default:
      return s;
  }
};

const getFormData = state => state.accountShippingAddressForm.data;

export default combineReducers({ ui, data });

export { initialState, getFormData };
