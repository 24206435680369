// Copied from https://github.com/theplant/lacoste3/blob/master/frontend/orders/helper.tsx
import { theplant, proto } from "../proto";
type ISubItem = theplant.ec.service.accounting.ISubItem;
type ISumUp = theplant.ec.service.accounting.ISumUp;

const fee = (
  SubItemType: theplant.ec.service.accounting.SubItemType,
  subItems: ISubItem[]
): number | Long => {
  const subItem = subItems.find(({ subType }) => subType === SubItemType);

  if (!subItem || !subItem.amount) {
    return 0;
  }

  return subItem.amount;
};

const deliveryFee = (subItems: ISubItem[]) =>
  fee(theplant.ec.service.accounting.SubItemType.DELIVERY_FEE, subItems);

const usedPoints = (subItems: ISubItem[]) =>
  fee(theplant.ec.service.accounting.SubItemType.POINTS_DEDUCTION, subItems);

const totalAmount = (sumUp: ISumUp): number =>
  Number(sumUp.quoteAmountWithTax || 0);

const totalDiscount = (subItems: ISubItem[]) =>
  fee(theplant.ec.service.accounting.SubItemType.TOTAL_DISCOUNT, subItems);

const hasAddressFieldError = (
  type: "shipping" | "billing",
  error: proto.IValidationError
): boolean =>
  (error.fieldViolations &&
    error.fieldViolations.some(
      ({ field }) =>
        field &&
        field
          .toLowerCase()
          .startsWith(
            (type === "shipping"
              ? "Form.ShippingAddress"
              : "Form.BillingAddress"
            ).toLowerCase()
          )
    )) ||
  false;

export {
  fee,
  deliveryFee,
  usedPoints,
  totalAmount,
  totalDiscount,
  hasAddressFieldError
};
