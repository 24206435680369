import * as React from "react";

import { KeyedProjection } from "@theplant/ecjs/projection";
import { makeService, IServiceError } from "@theplant/ecjs/prottp";
import { withContext, ContextProps, Context } from "@theplant/ecjs/context";
import { takeLatest, put, call, select } from "redux-saga/effects";
import { CHECKOUT_CART_PATH } from "../constants/Paths";
import {
  setErrors,
  Form,
  State as FormState,
  reducer as formReducer,
  updateInput,
  touchInput,
  addError,
  submitError,
  resetFormState,
} from "../form";

import { IData } from "./data";

import { prefecturesByKey } from "./prefectureData";

import { theplant, proto } from "../proto";
type IOrder = theplant.ec.service.orders.IOrder;
type ICheckoutInput = theplant.ec.api.orders.ICheckoutInput;
type ICreditCardInput = theplant.ec.service.orders.ICreditCardInput;
type IStoreInfo = theplant.ec.service.convenience_pickup.IStoreInfo;
type IAddress = theplant.ec.service.users.IAddress;
const CheckoutService = theplant.ec.api.orders.CheckoutService;
const ZipcodeService = theplant.ec.api.users.ZipcodeService;
type ValidationResult = theplant.ec.api.orders.ValidationResult;
type ZipcodeAddress = theplant.ec.service.zipcode.ZipcodeAddress;
type ConfirmResult = theplant.ec.api.orders.ConfirmResult;
type IGiftWrapping = theplant.ec.service.orders.IGiftWrapping;
type IPoints = number | Long | null;
type IDeliveryInfo = theplant.ec.service.orders.IDeliveryInfo;
type ISmartpayInput = theplant.ec.api.orders.ISmartPayInput;
type IAmazonPayInput = theplant.ec.service.orders.IAmazonPayInput;
const ORDER_CODE_FOR_FINISH_ORDER = "ORDER_CODE_FOR_FINISH_ORDER";

const silenceErrors = (context: Context): Context => ({
  ...context,
  onError: (error: IServiceError): null => {
    context.logger
      .warn()
      .log({ msg: "ignoring prottp error when validating input", error });
    return null;
  },
});

type InternalState = {
  form: ICheckoutInput & {
    newShippingAddress?: boolean;
    newBillingAddress?: boolean;
  };
  state: "validating" | null;
};

type State = FormState<InternalState>;

const formInitialState: ICheckoutInput = {
  specifyBillingAddress: false,
  deliveryMethod: theplant.ec.api.orders.DeliveryMethod.HOME_DELIVERY,
  deliveryInfo: { specified: false },
  paymentType: theplant.ec.service.orders.PaymentType.CREDIT_CARD,
  isSubscribed: true,
  isSubscribedSms: true,
};

const reducer = formReducer<InternalState>({
  form: formInitialState,
  state: null,
});

type UpdateStoreIdAction = {
  type: "UPDATE_STORE_ID";
  storeId: string | null;
};

type UpdateCombiniInfoAction = {
  type: "UPDATE_COMBINI_INFO";
  storeInfo: IStoreInfo;
};

type ClearAmazonAddressErrorAction = {
  type: "CLEAR_AMAZON_ADDRESS_ERROR";
};

type ClearGiftCardMessagesAction = {
  type: "CLEAR_GIFT_CARD_MESSAGES";
};

type ClearAppliedPointsAction = {
  type: "CLEAR_APPLIED_POINTS";
};

export type PrefillAddressAction = {
  type: "PREFILL_FROM_POSTAL_CODE";
  path: string[];
  address: IAddress;
  usingEnglishNameForPrefecture?: boolean;
};

type WillCheckoutAction = {
  type: "WILL_CHECKOUT";
  onResolve?: (order: ConfirmResult["order"]) => void;
  onReject?: () => void;
  useFinishOrder?: boolean;
};

type SelectAddressAction = {
  type: "SELECT_ADDRESS";
  id: string;
  prefix: "shippingAddress" | "billingAddress";
};

type ToSetNewAddressAction = {
  type: "TO_SET_NEW_ADDRESS";
  newAddress: boolean;
  prefix: "shippingAddress" | "billingAddress";
};

type SelectCreditCardAction = {
  type: "SELECT_CREDIT_CARD";
  id: string;
};

type SpecifyPaymentTypeAction = {
  type: "SPECIFY_PAYMENT_TYPE";
  paymentType: theplant.ec.service.orders.PaymentType;
};

type SpecifyDeliveryMethodAction = {
  type: "SPECIFY_DELIVERY_METHOD";
  method: theplant.ec.api.orders.DeliveryMethod;
};

type Action =
  | PrefillAddressAction
  | UpdateStoreIdAction
  | UpdateCombiniInfoAction
  | WillCheckoutAction
  | ClearAmazonAddressErrorAction
  | SelectAddressAction
  | SelectCreditCardAction
  | SpecifyPaymentTypeAction
  | SpecifyDeliveryMethodAction
  | ClearGiftCardMessagesAction
  | ClearAppliedPointsAction
  | {
      type: "WILL_VALIDATE_SHIPPING_PAGE";
      onResolve?: () => void;
      onReject?: () => void;
    }
  | { type: "SPECIFY_BILLING_ADDRESS"; specify: boolean }
  | { type: "DID_CHECKOUT"; result: ConfirmResult }
  | { type: "DID_CHECKOUT_ERROR"; error: proto.IValidationError }
  | { type: "WILL_VALIDATE_INPUT"; input: ICheckoutInput };

type StateProps = State;

type DispatchProps = {
  confirm: (
    onResolve?: (order: ConfirmResult["order"]) => void,
    onReject?: () => void,
    useFinishOrder?: boolean
  ) => void;
  validateInput: () => void;
  updateStoreId: (storeId: string | null) => void;
  updateCombiniInfo: (storeInfo: IStoreInfo) => void;
  resetFormState: () => void;
  validateShippingInput: (
    onResolve?: () => void,
    onReject?: (error: proto.IValidationError) => void
  ) => void;
  validateAmazonPayInput: (
    onResolve?: () => void,
    onReject?: (error: proto.IValidationError) => void
  ) => void;
  selectAddress: (
    prefix: "shippingAddress" | "billingAddress",
    id: string
  ) => void;
  toSetNewAddress: (
    prefix: "shippingAddress" | "billingAddress",
    newAddress: boolean
  ) => void;
  selectCreditCard: (id: string) => void;
  specifyPaymentType: (
    paymentType?: theplant.ec.service.orders.PaymentType | null
  ) => void;
  specifyDeliveryMethod: (
    method: theplant.ec.api.orders.DeliveryMethod
  ) => void;
  clearAppliedPoints: () => void;
  updateAddressForm: (
    type: "shippingAddress" | "billingAddress",
    address: IAddress | undefined
  ) => void;
  updateDataForm: (
    type:
      | "giftWrapping"
      | "points"
      | "deliveryInfo"
      | "specifyBillingAddress"
      | "smartPayInput"
      | "newShippingAddress"
      | "newBillingAddress"
      | "shippingAddressId"
      | "billingAddressId"
      | "email"
      | "storeId"
      | "deliveryMethod"
      | "specifyBillingAddress"
      | "paymentType"
      | "isTermsAgreed"
      | "amazonPayInput",
    data:
      | IDeliveryInfo
      | IGiftWrapping
      | IPoints
      | boolean
      | string
      | undefined
      | ISmartpayInput
      | IAmazonPayInput
  ) => void;
  updateCouponInput: (type: "couponCode", coupon: string) => void;
};

export type OrderServiceProps = { checkout: StateProps & DispatchProps };

const confirm = (
  onResolve?: (order: ConfirmResult["order"]) => void,
  onReject?: () => void,
  useFinishOrder?: boolean
): Action => ({
  type: "WILL_CHECKOUT",
  onResolve,
  onReject,
  useFinishOrder,
});

const mapDispatchToProps: DispatchProps = {
  confirm,
  validateInput: () => ({ type: "PRE_VALIDATE" }),
  updateStoreId: (storeId) => ({ type: "UPDATE_STORE_ID", storeId }),
  updateCombiniInfo: (storeInfo) => ({
    type: "UPDATE_COMBINI_INFO",
    storeInfo,
  }),
  resetFormState: () => resetFormState(),
  validateShippingInput: (
    onResolve?: () => void,
    onReject?: (error: proto.IValidationError) => void
  ) => ({
    type: "WILL_VALIDATE_SHIPPING_PAGE",
    onResolve,
    onReject,
  }),
  validateAmazonPayInput: (
    onResolve?: () => void,
    onReject?: (error: proto.IValidationError) => void
  ) => ({
    type: "WILL_VALIDATE_AMAZONPAY_PAGE",
    onResolve,
    onReject,
  }),
  selectAddress: (prefix, id) => ({
    type: "SELECT_ADDRESS",
    prefix,
    id,
  }),
  toSetNewAddress: (prefix, newAddress) => ({
    type: "TO_SET_NEW_ADDRESS",
    prefix,
    newAddress,
  }),
  selectCreditCard: (id) => ({
    type: "SELECT_CREDIT_CARD",
    id,
  }),
  specifyPaymentType: (paymentType) => ({
    type: "SPECIFY_PAYMENT_TYPE",
    paymentType,
  }),
  specifyDeliveryMethod: (method) => ({
    type: "SPECIFY_DELIVERY_METHOD",
    method,
  }),
  clearAppliedPoints: () => ({
    type: "CLEAR_APPLIED_POINTS",
  }),
  updateAddressForm: (type, address) => updateInput(["form", type])(address),
  updateDataForm: (type, data) => updateInput(["form", type])(data),
  updateCouponInput: (type, data) => updateInput(["form", type])(data),
};

const Projection = KeyedProjection("order");

type OrderProps = {
  cart: { refreshCart: (order: IOrder) => void };
  data: {
    refreshData: (data: IData) => void;
    willRefreshData: () => void;
    refreshDataError: (data: IData, error: IServiceError) => void;
  };
  confirmResult: {
    onConfirmOrder: (result?: ConfirmResult) => void;
    willConfirmOrder: () => void;
  };
};

// remove all spaces in creditCardInput.number
const processCreditCardInput = (
  c: ICreditCardInput | null | undefined
): ICreditCardInput | null | undefined =>
  c && { ...c, number: c.number && c.number.replace(/-/g, "") };

function* validate(
  context: ContextProps["requestContext"],
  refreshCart: OrderProps["cart"]["refreshCart"],
  willRefreshData: OrderProps["data"]["willRefreshData"],
  refreshData: OrderProps["data"]["refreshData"],
  refreshDataError: OrderProps["data"]["refreshDataError"],
  action:
    | { type: "PRE_VALIDATE" }
    | { type: "TOUCH_INPUT"; path: string[] }
    | {
        type: "WILL_VALIDATE_SHIPPING_PAGE";
        onResolve?: () => void;
        onReject?: (error: proto.IValidationError) => void;
      }
    | {
        type: "WILL_VALIDATE_AMAZONPAY_PAGE";
        onResolve?: () => void;
        onReject?: (error: proto.IValidationError) => void;
      }
) {
  yield put(updateInput(["state"])("validating"));

  yield call(willRefreshData);

  let input: ICheckoutInput = yield select<State>((state) => state.input.form);

  input = {
    ...input,
    creditCardInput:
      action.type === "WILL_VALIDATE_SHIPPING_PAGE"
        ? null // not pass credit card infos when in shipping page
        : processCreditCardInput(input.creditCardInput),
  };

  yield put({ type: "WILL_VALIDATE_INPUT", input } as Action);

  // Silenced, no expected errors will come out of here, so we don't
  // try/catch
  const service = makeService(CheckoutService, silenceErrors(context));

  const result: ValidationResult = yield call(
    {
      context: service,
      fn: service.validateInput,
    },
    input
  );

  // API returns form errors at top level, but our form is at
  // `state.input.form`, so we need to remap the fields.
  const error = new proto.ValidationError(result.error || {});
  error.fieldViolations = error.fieldViolations
    // When validating, we don't care about credit card ID errors,
    // because we won't get a credit card ID until checkout.
    .filter(({ field }) => field !== "CreditCardId")
    .map((error) => ({
      ...error,
      field: "Form." + error.field,
    }));

  if (
    action.type === "WILL_VALIDATE_SHIPPING_PAGE" ||
    action.type === "WILL_VALIDATE_AMAZONPAY_PAGE"
  ) {
    if (error.fieldViolations.length === 0) {
      if (action.onResolve) {
        yield call(action.onResolve);
      }
    } else {
      yield put(submitError(error));

      if (action.onReject) {
        yield call(() => action.onReject && action.onReject(error));
      }
    }
  } else {
    yield put(setErrors(error));
  }

  yield call(refreshCart, result.order || {});

  const { order, error: _, ...data } = result;

  if (result.error) {
    yield call(refreshDataError, data, {
      errors: result.error,
    } as IServiceError);
  } else {
    yield call(refreshData, data);
  }

  yield put(updateInput(["state"])(null));
}

function* prefill(
  context: ContextProps["requestContext"],
  action: PrefillAddressAction
) {
  const service = makeService(ZipcodeService, context);

  try {
    const result: ZipcodeAddress = yield call(
      {
        context: service,
        fn: service.find,
      },
      { zipcode: action.address.zipcode }
    );

    const { prefecture, city, area } = result;

    // Aigle is using the englist name for the prefecture field
    const { usingEnglishNameForPrefecture } = action;

    const newAddress = {
      ...action.address,
      prefecture:
        usingEnglishNameForPrefecture === true
          ? prefecturesByKey[prefecture].english
          : prefecture,
      city: city + area,
    };

    yield put(updateInput(action.path)(newAddress));
    // will trigger validation... hmmm...
    yield put(touchInput(action.path.concat("zipcode"))());
  } catch (error) {
    // We should only get validation errors here, other network errors
    // will be handled by the prottp error handler. TODO this will
    // result in "dead" sagas that can't proceed as the service call
    // will never resolve *or* reject?
    const field = action.path
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .concat("Zipcode")
      .join(".");

    let err: proto.ValidationError.IFieldViolation = {
      field,
      code: "INVALID_ZIPCODE",
      msg: "invalid zip code",
    };

    if (
      (error as any).type === "validation-error" &&
      (error as any).errors.fieldViolations &&
      (error as any).errors.fieldViolations.length > 0
    ) {
      err = {
        ...(error as any).errors.fieldViolations[0],
        field,
      };
    }

    yield put(addError(err));
  }
}

function* checkout(
  context: ContextProps["requestContext"],
  refreshCart: OrderProps["cart"]["refreshCart"],
  willConfirmOrder: OrderProps["confirmResult"]["willConfirmOrder"],
  onConfirmOrder: OrderProps["confirmResult"]["onConfirmOrder"],
  action: WillCheckoutAction
) {
  yield call(willConfirmOrder);

  const form: State["input"]["form"] = yield select<State>(
    (state) => state.input.form
  );

  const service = makeService(CheckoutService, context);

  const orderCodeInLocalStorage = localStorage.getItem(
    ORDER_CODE_FOR_FINISH_ORDER
  );

  if (action.useFinishOrder) {
    if (!orderCodeInLocalStorage) {
      // redirect to cart page if no checkout session id
      window.location.replace(CHECKOUT_CART_PATH);
      return;
    }
  }
  try {
    const result: ConfirmResult = action.useFinishOrder
      ? yield call(
          {
            context: service,
            fn: service.finishOrder,
          },
          {
            orderCode: orderCodeInLocalStorage,
          }
        )
      : yield call(
          {
            context: service,
            fn: service.confirm,
          },
          {
            ...form,
            creditCardInput: processCreditCardInput(form.creditCardInput),
          }
        );

    yield call(refreshCart, { orderItems: [] });
    yield call(onConfirmOrder, result);
    // set and clear localstorage before call on resolve
    if (action.useFinishOrder) {
      localStorage.removeItem(ORDER_CODE_FOR_FINISH_ORDER);
    } else {
      const orderCode = result!.order!.code;
      if (orderCode) {
        // store the orderCode in frontend, because if it's guest order, backend cannot get the orderCode to call finishOrder
        localStorage.setItem(ORDER_CODE_FOR_FINISH_ORDER, orderCode);
      }
    }
    if (action.onResolve) {
      yield call(action.onResolve, result.order);
    }
  } catch (e) {
    // Similar to `validate`, we should only get validation errors
    // here, other network errors will be handled by the prottp error
    // handler.

    // API returns form errors at top level, but our form is at
    // `state.input.form`, so we need to remap the fields.

    // close the loading screen
    yield call(onConfirmOrder);

    if (e && (e as any).errors && (e as any).errors.fieldViolations) {
      const error: proto.ValidationError = (e as any).errors;
      error.fieldViolations = error.fieldViolations.map((error) => ({
        ...error,
        field: "Form." + error.field,
      }));

      yield put(submitError(error));
    }

    if (action.onReject) {
      yield call(action.onReject);
    }
  }
}

function* updateStoreInput({ storeId }: UpdateStoreIdAction) {
  const path = ["form", "storeId"];
  yield put(updateInput(path)(storeId));
  yield put(touchInput(path)());
}

function* updateCombiniInfo({ storeInfo }: UpdateCombiniInfoAction) {
  const path = ["form", "storeInfo"];
  yield put(updateInput(path)(storeInfo));
  yield put(touchInput(path)());
}

const errorSelector = (state: State) => state.error;

function* clearAmazonAddressError() {
  const error: proto.IValidationError | undefined = yield select<State>(
    errorSelector
  );

  if (
    error &&
    error.fieldViolations &&
    error.fieldViolations.find(
      (e) => e.field === "Form.AmazonPayInput.AmazonPayShippingAddress"
    ) !== undefined
  ) {
    error.fieldViolations = error.fieldViolations.filter(
      (err) => err.field !== "Form.AmazonPayInput.AmazonPayShippingAddress"
    );
    yield put(submitError(error as proto.ValidationError));
  }
}

type SpecifyBillingAddressAction = {
  specify: boolean;
  type: "SPECIFY_BILLING_ADDRESS";
};

function* specifyBillingAddress({ specify }: SpecifyBillingAddressAction) {
  const path = ["form", "specifyBillingAddress"];
  yield put(updateInput(path)(specify));
  yield put(touchInput(path)());
}

function* selectAddress({ prefix, id }: SelectAddressAction) {
  const path = [
    "form",
    prefix === "shippingAddress" ? "shippingAddressId" : "billingAddressId",
  ];
  yield put(updateInput(path)(id));
  yield put(touchInput(path)());
}

function* toSetNewAddress({ prefix, newAddress }: ToSetNewAddressAction) {
  const path = [
    "form",
    prefix === "shippingAddress" ? "newShippingAddress" : "newBillingAddress",
  ];

  console.log("prefix", prefix, "path", path, newAddress);

  yield put(updateInput(path)(newAddress));
  yield put(touchInput(path)());
}

function* selectCreditCard({ id }: SelectCreditCardAction) {
  const path = ["form", "creditCardId"];
  yield put(updateInput(path)(id));
  yield put(touchInput(path)());
}

function* specifyPaymentType({ paymentType }: SpecifyPaymentTypeAction) {
  const path = ["form", "paymentType"];
  yield put(updateInput(path)(paymentType));
}

function* specifyDeliveryMethod({ method }: SpecifyDeliveryMethodAction) {
  const path = ["form", "deliveryMethod"];
  yield put(updateInput(path)(method));
  yield put(touchInput(path)());
}

function* clearGiftCardMessages() {
  const path = ["form", "giftWrapping"];
  yield put(updateInput(path.concat("message"))(""));
  yield put(updateInput(path.concat("message2"))(""));
  yield put(touchInput(path)());
}

function* clearAppliedPoints() {
  const path = ["form", "points"];
  yield put(updateInput(path)(0));
  yield put(touchInput(path)());
}

function* checkoutSaga({
  context,
  refreshCart,
  willRefreshData,
  refreshData,
  refreshDataError,
  onConfirmOrder,
  willConfirmOrder,
}: {
  context: ContextProps["requestContext"];
  willConfirmOrder: OrderProps["confirmResult"]["willConfirmOrder"];
  onConfirmOrder: OrderProps["confirmResult"]["onConfirmOrder"];
  refreshCart: OrderProps["cart"]["refreshCart"];
  willRefreshData: OrderProps["data"]["willRefreshData"];
  refreshData: OrderProps["data"]["refreshData"];
  refreshDataError: OrderProps["data"]["refreshDataError"];
}) {
  yield put({ type: "START_ORDER_SERVICE" });
  yield takeLatest(
    [
      "TOUCH_INPUT",
      "PRE_VALIDATE",
      "WILL_VALIDATE_SHIPPING_PAGE",
      "WILL_VALIDATE_AMAZONPAY_PAGE",
    ],
    validate,
    context,
    refreshCart,
    willRefreshData,
    refreshData,
    refreshDataError
  );
  yield takeLatest("PREFILL_FROM_POSTAL_CODE", prefill, context);
  yield takeLatest("UPDATE_STORE_ID", updateStoreInput);
  yield takeLatest("UPDATE_COMBINI_INFO", updateCombiniInfo);
  yield takeLatest("CLEAR_AMAZON_ADDRESS_ERROR", clearAmazonAddressError);
  yield takeLatest("SPECIFY_BILLING_ADDRESS", specifyBillingAddress);
  yield takeLatest("SELECT_ADDRESS", selectAddress);
  yield takeLatest("TO_SET_NEW_ADDRESS", toSetNewAddress);
  yield takeLatest("SELECT_CREDIT_CARD", selectCreditCard);
  yield takeLatest("SPECIFY_PAYMENT_TYPE", specifyPaymentType);
  yield takeLatest("SPECIFY_DELIVERY_METHOD", specifyDeliveryMethod);
  yield takeLatest("CLEAR_GIFT_CARD_MESSAGES", clearGiftCardMessages);
  yield takeLatest("CLEAR_APPLIED_POINTS", clearAppliedPoints);
  yield takeLatest(
    "WILL_CHECKOUT",
    checkout,
    context,
    refreshCart,
    willConfirmOrder,
    onConfirmOrder
  );
}

const OrderSaga = Projection.saga(checkoutSaga);

// This is almost identical to withCartService
const withOrder = <Props extends {}>(
  ns: string,
  C: React.ComponentType<Props & OrderServiceProps>
): React.ComponentType<Props & OrderProps> => {
  const Connected = Projection.connect(
    (s: State) => s,
    mapDispatchToProps,
    (s, d, o): Props & OrderServiceProps => ({
      checkout: {
        ...s,
        ...d,
      },
      ...(o as any),
    })
  )(C);

  // FIXME any here is because composition of higher-order components
  // (withContext . Projection.connect) results in a type that is
  // unworkable.
  return withContext((props: Props & ContextProps & OrderProps) => (
    <Projection ns="checkout">
      <Projection ns={ns}>
        <OrderSaga
          context={props.requestContext}
          refreshCart={props.cart.refreshCart}
          willRefreshData={props.data.willRefreshData}
          refreshData={props.data.refreshData}
          refreshDataError={props.data.refreshDataError}
          willConfirmOrder={props.confirmResult.willConfirmOrder}
          onConfirmOrder={props.confirmResult.onConfirmOrder}
        />
        <Connected {...(props as any)} />
      </Projection>
    </Projection>
  ));
};

const actions = {
  prefillFromPostalCode: (
    path: string[],
    address: IAddress,
    usingEnglishNameForPrefecture?: boolean
  ) => ({
    type: "PREFILL_FROM_POSTAL_CODE",
    path,
    address,
    usingEnglishNameForPrefecture,
  }),
  checkout: () => ({
    type: "WILL_CHECKOUT",
  }),
  clearAmazonAddressError: () => ({
    type: "CLEAR_AMAZON_ADDRESS_ERROR",
  }),
  specifyBillingAddress: (specify: boolean) => ({
    type: "SPECIFY_BILLING_ADDRESS",
    specify,
  }),
  clearGiftCardMessages: () => ({
    type: "CLEAR_GIFT_CARD_MESSAGES",
  }),
  toSetNewAddress: (
    prefix: "shippingAddress" | "billingAddress",
    newAddress: boolean
  ) => ({
    type: "TO_SET_NEW_ADDRESS",
    prefix,
    newAddress,
  }),
};

const checkoutForm = new Form<InternalState, typeof actions>(
  [],
  actions,
  Projection
);

export const _test = {
  updateStoreInput,
  specifyBillingAddress,
  clearAmazonAddressError,
  errorSelector,
  selectAddress,
  selectCreditCard,
};

const orderInitialState = reducer(undefined, { type: "" } as any);

export {
  reducer as orderReducer,
  orderInitialState,
  withOrder,
  checkoutForm,
  actions,
  prefill,
};
export type OrderState = State;
export type OrderAction = Action;
