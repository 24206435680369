import React, { useEffect } from "react";
import { connect } from "react-redux";

import Popover from "@material-ui/core/Popover";

import { refresh } from "../../../actions/pointsHistory";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { InlineLoading } from "../Loading";

const AiglePopover = (props) => {
  const [visible, setVisible] = React.useState(false);

  const { content, appendix } = props;
  if (!content && !appendix) {
    return null;
  }

  return (
    <Popover
      content={
        <div>
          {content ? <p className="content">{content}</p> : null}
          {appendix ? (
            <div>
              <p className="appendix">備考</p>
              <p>{appendix}</p>
            </div>
          ) : null}
        </div>
      }
      trigger="click"
      placement="topRight"
      arrowPointAtCenter
      visible={visible}
      open={visible}
      onVisibleChange={setVisible}
    >
      <button
        className="popover-trigger"
        style={{
          fontSize: 16,
          lineHeight: 1.75,
          textDecoration: "underline",
          width: "100%",
          textAlign: "center",
          backgroundColor: "transparent",
        }}
      >
        内容
      </button>
    </Popover>
  );
};

const History = (props) => {
  useEffect(() => {
    props.refresh();
    //eslint-disable-next-line
  }, []);

  if (props.fetching) {
    return (
      <div className="content points-sub-tab points-history-sub-tab">
        <InlineLoading className="js-react-app-loading" />
      </div>
    );
  }

  return (
    <div className="content points-sub-tab points-history-sub-tab mt-24">
      <div className="history-table-section">
        <table>
          <tbody>
            <tr>
              <th>利用日・獲得日</th>
              <th>店舗</th>
              <th>内容</th>
              <th>ポイント利用・獲得</th>
              <th className="text-align-right">備考</th>
            </tr>
            {(props.pointRecords || []).map((record, idx) => (
              <tr key={idx}>
                <td>{record.ChargeDate}</td>
                <td>{record.ShopID === "900" ? "EC" : "-"}</td>
                <td>{record.ChangeReason}</td>
                <td
                  className={cx(
                    "balance",
                    record.IsGain === true ? "gain" : "loss"
                  )}
                >
                  <strong>{record.PointText.split(" ")[1]}</strong>
                  {record.DeadLine ? <span>期間限定</span> : null}
                  <AiglePopover
                    content={record.ChangeReason}
                    appendix={record.PointRemark}
                  />
                </td>
                <td className="text-align-right">
                  {record.PointRemark || "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    ({
      pointsHistory: {
        ui: { fetching },
        data: { PointRecords },
      },
      points: { data },
    }) => ({
      fetching,
      pointRecords: PointRecords,
      confirmPoint: data.base && data.base.confirm_point,
      temporaryPoint: data.base && data.base.temporary_point,
      campaignPoint: data.campaign_point,
      expiresoonMonthPoint: data.expiresoon_month_point,
      rankName: data.rank_name,
      nextRankName: data.next_rank_name,
      neededAmount: data.needed_amount,
    }),
    {
      refresh,
    }
  )(History)
);
