import React from "react";
import { formatCurrency } from "../../../helpers/currency";
import { toCamel } from "../../../../product/dataTransfer";
import AddressDetail from "../../../../CheckoutApp/shared/AddressDetail";
import { theplant } from "../../../../proto";
import Popup from "../../../molecules/Popup";
import CannotCancel from "../CannotCancel";

type IAddress = theplant.ec.service.users.IAddress;

const SumUpItem = ({ label, amount, isBold }: OrderSumUpItemDataPropType) => (
  <p className={`flex space-between ${isBold ? "bold" : "sub-item"}`}>
    <label>{label}</label>
    <span>{formatCurrency(amount)}</span>
  </p>
);

const OrderSummary = (props: Props) => {
  const { id, formattedState, formattedOrderDate } = props.data;

  return (
    <div className="order-summary-title wrap-flex space-between mb-flex-column">
      <span className="id-and-state">
        注文番号：{id}・{formattedState}
      </span>
      <span className="date">
        注文日：
        {formattedOrderDate}
      </span>
    </div>
  );
};
const getIsAmount = (data: OrderSumUpItemDataPropType) =>
  data.name === "BILLING_AMOUNT";

const getQuantity = (items: { quantity?: number }[]) => {
  let sum = 0;
  (items || []).forEach(({ quantity }) => {
    sum += quantity || 0;
  });
  return sum;
};

const CancelLink = (props: Props) => {
  const {
    cancel,
    data: {
      isCancelled,
      isUserCancelUnallowed,
      isCancellable,
      sumUpItems,
      orderItems,
      id: orderId,
    },
  } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const amount = sumUpItems.find((item) => getIsAmount(item))?.amount;

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return isCancelled || isUserCancelUnallowed ? null : (
    <>
      {/* eslint-disable-next-line */}
      <a
        role="button"
        tabIndex={0}
        className="cancel-link"
        onClick={open}
        onKeyPress={(event) => (event.key === "Enter" ? cancel() : () => null)}
      >
        注文を取り消す
      </a>
      <Popup
        isOpen={isOpen}
        close={close}
        isConfirmPopup={isCancellable}
        onConfirm={cancel}
        confirmText="削除する"
        title="注文取り消し"
        confirmTitle={
          isCancellable
            ? "この注文をキャンセルしてもよろしいですか？"
            : undefined
        }
      >
        {isCancellable ? (
          <div className="item-card">
            <div className="info content-in-popup">
              <div className="order-detail">
                <p>
                  <label>注文番号：</label>
                  {orderId}
                </p>
                <p>
                  <label>商品数量：</label>
                  {getQuantity(orderItems)}点
                </p>
                <p>
                  <label>合計（税込）</label>
                  {formatCurrency(amount)}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <CannotCancel />
        )}
      </Popup>
    </>
  );
};

const SumItems = (props: Props) => {
  const {
    data: { sumUpItems },
  } = props;
  return (
    <>
      {sumUpItems.map((data, idx) => {
        const isAmount = getIsAmount(data);
        const isQuoteAmount = data.name === "QUOTE_AMOUNT";
        return (
          <SumUpItem
            key={idx}
            {...data}
            isBold={isAmount || isQuoteAmount}
            label={isAmount ? "合計（税込）：" : data.label}
          />
        );
      })}
    </>
  );
};

const Summary = (props: Props) => {
  const {
    storeOrderAddress,
    storeOrderTel,
    combiniAddress,
    combiniTel,
    secretCode,
    // shipmentId,
    shipmentPackages,
    shipping_address,
  } = props.data;

  const tel = storeOrderTel || combiniTel;

  return (
    <div className="order-summary-section grid-row">
      <div className="info">
        <p className="bold">配送先情報</p>
        {shipping_address && (
          <AddressDetail
            address={toCamel(shipping_address) as IAddress}
            noDetailName
          />
        )}
        <p>{combiniAddress || storeOrderAddress}</p>
        {tel && (
          // <> not working in js file, only jsx ???
          <p>
            電話：
            <a style={{ color: "#787878" }} href={`tel: ${tel}`}>
              {tel}
            </a>
          </p>
        )}
        {/* show shipment id when order is shipped for combini pick up order */}
        {/* {shipmentId && secretCode && (
            <React.Fragment>
              <span>お問合せ番号：{shipmentId}</span>
              <br />
            </React.Fragment>
          )}
          {secretCode && (
            <React.Fragment>
              <span>認証番号：{secretCode}</span>
              <br />
            </React.Fragment>
          )}
          {secretCode && (
            <React.Fragment>
              <br />
              <span>
                佐川急便お荷物問い合わせサービスは
                <a
                  href="http://k2k.sagawa-exp.co.jp/p/sagawa/web/okurijoinput.jsp"
                  style={{
                    color: "rgb(120, 120, 120)",
                    textDecoration: "underline",
                    marginLeft: 5,
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  こちら
                </a>
              </span>
            </React.Fragment>
          )} */}
        {(shipmentPackages || []).map(
          (shipment, index) =>
            shipment &&
            // [LAX-5390] there are 2 shipping order state
            // SHIPPED or PARTIALLY_SHIPPED
            (shipment.state || "").indexOf("SHIPPED") > -1 &&
            shipment.tracking_number && (
              <>
                <p className="green-text">出荷の追跡</p>
                <p key={index}>
                  <span>
                    {shipment.provider === 1
                      ? "佐川宅急便 送り状番号："
                      : "ヤマト運輸 送り状番号："}
                    <a
                      href={
                        shipment.provider === 1
                          ? `${shipment.tracking_url}${shipment.tracking_number}`
                          : `${shipment.tracking_url}?id=${shipment.tracking_number}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link color-inherit"
                    >
                      {shipment.tracking_number}
                    </a>
                  </span>
                  {/* show serect code when order is shipped for combini pick up order */}
                  {secretCode && (
                    <>
                      <br />
                      <span>認証番号：{secretCode}</span>
                    </>
                  )}
                </p>
              </>
            )
        )}
        <CancelLink {...props} />
      </div>
      <div className="summary-amount-container">
        <SumItems {...props} />
      </div>
    </div>
  );
};

export type OrderSumUpItemDataPropType = {
  label?: String;
  amount?: Number;
  isBold?: Boolean;
  name?: String;
  quantity?: number;
};

export type OrderSummaryDataPropType = {
  id: String;
  formattedState: String;
  formattedOrderDate: String;
  shippingAddressInfo: String;
  storeOrderAddress: String;
  storeOrderTel: String;
  combiniAddress: String;
  combiniTel: String;
  secretCode: String;
  shipmentId: String;
  amount: Number;
  isCancelled: Boolean;
  isUserCancelUnallowed: Boolean;
  sumUpItems: Array<OrderSumUpItemDataPropType>;
  shipping_address?: Object;
  shipmentPackages?: Array<{
    state?: String;
    tracking_number?: String;
    tracking_url?: String;
    provider?: number;
  }>;
};

type Props = {
  data: OrderSummaryDataPropType & {
    orderItems: OrderSumUpItemDataPropType[];
    isCancellable?: boolean;
  };
  cancel: () => {};
};

export default Summary;

export { SumUpItem, OrderSummary, CancelLink };
