// Copied from https://github.com/theplant/mastani/blob/master/frontend/containers/checkout/prefectureData.ts
// Derived from https://github.com/theplant/prefecturejp

export type PrefectureCode =
  | "JP-01"
  | "JP-02"
  | "JP-03"
  | "JP-04"
  | "JP-05"
  | "JP-06"
  | "JP-07"
  | "JP-08"
  | "JP-09"
  | "JP-10"
  | "JP-11"
  | "JP-12"
  | "JP-13"
  | "JP-14"
  | "JP-15"
  | "JP-16"
  | "JP-17"
  | "JP-18"
  | "JP-19"
  | "JP-20"
  | "JP-21"
  | "JP-22"
  | "JP-23"
  | "JP-24"
  | "JP-25"
  | "JP-26"
  | "JP-27"
  | "JP-28"
  | "JP-29"
  | "JP-30"
  | "JP-31"
  | "JP-32"
  | "JP-33"
  | "JP-34"
  | "JP-35"
  | "JP-36"
  | "JP-37"
  | "JP-38"
  | "JP-39"
  | "JP-40"
  | "JP-41"
  | "JP-42"
  | "JP-43"
  | "JP-44"
  | "JP-45"
  | "JP-46"
  | "JP-47";

type Region =
  | "北海道"
  | "東北"
  | "関東"
  | "中部"
  | "近畿"
  | "中国"
  | "四国"
  | "九州";

export type Prefecture = {
  key: PrefectureCode;
  name: string;
  english: string;

  // Unused
  ShortName: string;
  Region: Region;
};

const prefectures: Prefecture[] = [
  {
    key: "JP-01",
    english: "Hokkaido",
    name: "北海道",
    ShortName: "北海道",
    Region: "北海道"
  },
  {
    key: "JP-02",
    english: "Aomori",
    name: "青森県",
    ShortName: "青森",
    Region: "東北"
  },
  {
    key: "JP-03",
    english: "Iwate",
    name: "岩手県",
    ShortName: "岩手",
    Region: "東北"
  },
  {
    key: "JP-04",
    english: "Miyagi",
    name: "宮城県",
    ShortName: "宮城",
    Region: "東北"
  },
  {
    key: "JP-05",
    english: "Akita",
    name: "秋田県",
    ShortName: "秋田",
    Region: "東北"
  },
  {
    key: "JP-06",
    english: "Yamagata",
    name: "山形県",
    ShortName: "山形",
    Region: "東北"
  },
  {
    key: "JP-07",
    english: "Fukushima",
    name: "福島県",
    ShortName: "福島",
    Region: "東北"
  },
  {
    key: "JP-08",
    english: "Ibaraki",
    name: "茨城県",
    ShortName: "茨城",
    Region: "関東"
  },
  {
    key: "JP-09",
    english: "Tochigi",
    name: "栃木県",
    ShortName: "栃木",
    Region: "関東"
  },
  {
    key: "JP-10",
    english: "Gunma",
    name: "群馬県",
    ShortName: "群馬",
    Region: "関東"
  },
  {
    key: "JP-11",
    english: "Saitama",
    name: "埼玉県",
    ShortName: "埼玉",
    Region: "関東"
  },
  {
    key: "JP-12",
    english: "Chiba",
    name: "千葉県",
    ShortName: "千葉",
    Region: "関東"
  },
  {
    key: "JP-13",
    english: "Tokyo",
    name: "東京都",
    ShortName: "東京",
    Region: "関東"
  },
  {
    key: "JP-14",
    english: "Kanagawa",
    name: "神奈川県",
    ShortName: "神奈川",
    Region: "関東"
  },
  {
    key: "JP-15",
    english: "Niigata",
    name: "新潟県",
    ShortName: "新潟",
    Region: "中部"
  },
  {
    key: "JP-16",
    english: "Toyama",
    name: "富山県",
    ShortName: "富山",
    Region: "関東"
  },
  {
    key: "JP-17",
    english: "Ishikawa",
    name: "石川県",
    ShortName: "石川",
    Region: "関東"
  },
  {
    key: "JP-18",
    english: "Fukui",
    name: "福井県",
    ShortName: "福井",
    Region: "関東"
  },
  {
    key: "JP-19",
    english: "Yamanashi",
    name: "山梨県",
    ShortName: "山梨",
    Region: "関東"
  },
  {
    key: "JP-20",
    english: "Nagano",
    name: "長野県",
    ShortName: "長野",
    Region: "関東"
  },
  {
    key: "JP-21",
    english: "Gifu",
    name: "岐阜県",
    ShortName: "岐阜",
    Region: "関東"
  },
  {
    key: "JP-22",
    english: "Shizuoka",
    name: "静岡県",
    ShortName: "静岡",
    Region: "関東"
  },
  {
    key: "JP-23",
    english: "Aichi",
    name: "愛知県",
    ShortName: "愛知",
    Region: "関東"
  },
  {
    key: "JP-24",
    english: "Mie",
    name: "三重県",
    ShortName: "三重",
    Region: "関東"
  },
  {
    key: "JP-25",
    english: "Shiga",
    name: "滋賀県",
    ShortName: "滋賀",
    Region: "近畿"
  },
  {
    key: "JP-26",
    english: "Kyoto",
    name: "京都府",
    ShortName: "京都",
    Region: "近畿"
  },
  {
    key: "JP-27",
    english: "Osaka",
    name: "大阪府",
    ShortName: "大阪",
    Region: "近畿"
  },
  {
    key: "JP-28",
    english: "Hyogo",
    name: "兵庫県",
    ShortName: "兵庫",
    Region: "近畿"
  },
  {
    key: "JP-29",
    english: "Nara",
    name: "奈良県",
    ShortName: "奈良",
    Region: "近畿"
  },
  {
    key: "JP-30",
    english: "Wakayama",
    name: "和歌山県",
    ShortName: "和歌山",
    Region: "近畿"
  },
  {
    key: "JP-31",
    english: "Tottori",
    name: "鳥取県",
    ShortName: "鳥取",
    Region: "中国"
  },
  {
    key: "JP-32",
    english: "Shimane",
    name: "島根県",
    ShortName: "島根",
    Region: "中国"
  },
  {
    key: "JP-33",
    english: "Okayama",
    name: "岡山県",
    ShortName: "岡山",
    Region: "中国"
  },
  {
    key: "JP-34",
    english: "Hiroshima",
    name: "広島県",
    ShortName: "広島",
    Region: "中国"
  },
  {
    key: "JP-35",
    english: "Yamaguchi",
    name: "山口県",
    ShortName: "山口",
    Region: "中国"
  },
  {
    key: "JP-36",
    english: "Tokushima",
    name: "徳島県",
    ShortName: "徳島",
    Region: "四国"
  },
  {
    key: "JP-37",
    english: "Kagawa",
    name: "香川県",
    ShortName: "香川",
    Region: "四国"
  },
  {
    key: "JP-38",
    english: "Ehime",
    name: "愛媛県",
    ShortName: "愛媛",
    Region: "四国"
  },
  {
    key: "JP-39",
    english: "Kochi",
    name: "高知県",
    ShortName: "高知",
    Region: "四国"
  },
  {
    key: "JP-40",
    english: "Fukuoka",
    name: "福岡県",
    ShortName: "福岡",
    Region: "九州"
  },
  {
    key: "JP-41",
    english: "Saga",
    name: "佐賀県",
    ShortName: "佐賀",
    Region: "九州"
  },
  {
    key: "JP-42",
    english: "Nagasaki",
    name: "長崎県",
    ShortName: "長崎",
    Region: "九州"
  },
  {
    key: "JP-43",
    english: "Kumamoto",
    name: "熊本県",
    ShortName: "熊本",
    Region: "九州"
  },
  {
    key: "JP-44",
    english: "Oita",
    name: "大分県",
    ShortName: "大分",
    Region: "九州"
  },
  {
    key: "JP-45",
    english: "Miyazaki",
    name: "宮崎県",
    ShortName: "宮崎",
    Region: "九州"
  },
  {
    key: "JP-46",
    english: "Kagoshima",
    name: "鹿児島県",
    ShortName: "鹿児島",
    Region: "九州"
  },
  {
    key: "JP-47",
    english: "Okinawa",
    name: "沖縄県",
    ShortName: "沖縄",
    Region: "九州"
  }
];

const prefecturesByKey = prefectures.reduce((acc, p) => {
  acc[p.key] = p;
  return acc;
}, {} as { [k: string]: Prefecture });

const prefecturesByEnglish = prefectures.reduce((acc, { key, english }) => {
  acc[english] = key;
  return acc;
}, {} as { [k: string]: PrefectureCode });

const prefecturesByEnglishName = prefectures.reduce((acc, p) => {
  acc[p.english] = p;
  return acc;
}, {} as { [k: string]: Prefecture });

const prefecturesByRegion = Object.values(
  prefectures.reduce((acc, p) => {
    acc[p.Region] = acc[p.Region] || {
      key: p.Region,
      data: []
    };
    acc[p.Region].data.push(p);
    return acc;
  }, {} as { [k in Region]: { key: Region; data: Prefecture[] } })
);

const inPrefectureList = ({
  value,
  prefectures
}: {
  value: string;
  prefectures: Prefecture[];
}) => prefectures.findIndex(p => p.english === value) > -1;

export {
  prefectures,
  prefecturesByKey,
  prefecturesByEnglish,
  prefecturesByEnglishName,
  prefecturesByRegion,
  inPrefectureList
};
