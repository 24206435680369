import { delay } from "redux-saga";
import { put, call, select, takeLatest } from "redux-saga/effects";

import { getPasswordFormData } from "../reducers/passwordForm";
import * as actions from "../actions/passwordForm";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

import { redirect } from "./common";

function* submit() {
  const formData = yield select(getPasswordFormData);
  const { response, error } = yield call(Api.updatePassword, formData);

  if (response) {
    yield put(actions.submitSuccess(response));
    yield call(delay, 3000);
    yield call(redirect, response.location);
  } else {
    yield put(actions.submitFailure(error));
  }
}

function* watch() {
  yield takeLatest(types.PASSWORD_FORM_WILL_SUBMIT, submit);
}

export default watch;

export { submit };
