import $ from "jquery";
import Validator from "./validator.js";

class Newsletter {
  constructor() {
    this.$newsletterForm = $(".js-newsletter-form");

    Validator.addCustomValidate();
    this.addNewsletterValidation();
  }

  addNewsletterValidation() {
    this.$newsletterForm.validate({
      errorClass: "error",
      errorElement: "span",
      rules: {
        EMAIL: {
          required: true,
          emailCustom: true,
          maxlength: 50
        }
      }
    });
  }
}

export default Newsletter;
