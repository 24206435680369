import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const initialState = {
  data: {},
  ui: {
    isOpen: false,
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.ACCOUNT_CREDIT_CARD_FORM_CLOSE:
      return { ...s, isOpen: false };
    case types.ACCOUNT_CREDIT_CARD_FORM_WILL_SUBMIT:
      return { ...s, fetching: true, error: null };
    case types.ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT:
      return { ...s, fetching: false, error: null, isOpen: false };
    case types.ACCOUNT_CREDIT_CARD_FORM_NEW:
    case types.ACCOUNT_CREDIT_CARD_FORM_EDIT:
      return { ...s, fetching: false, error: null, isOpen: true };
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.ACCOUNT_CREDIT_CARD_FORM_CHANGE_FIELD:
      return { ...s, [a.field]: a.value };
    case types.ACCOUNT_CREDIT_CARD_FORM_NEW:
      return {};
    case types.ACCOUNT_CREDIT_CARDS_DID_FORM_LOAD:
      const expiry_date =  a.data?.expiry_date;
      const [expiry_year, expiry_month] = (expiry_date||"").split("/")
      return { ...(a.data || {}), expiry_year, expiry_month };
    default:
      return s;
  }
};

const getFormData = state => state.accountCreditCardForm.data;

export default combineReducers({ ui, data });

export { initialState, getFormData };
