import PropTypes from "prop-types";
import React from "react";
import Base from "./Base";

class RadioInput extends Base {
  render() {
    const { checkedValue, value } = this.props;

    return (
      <label name="radio" data-checked={checkedValue === value}>
        <input
          type="radio"
          value={checkedValue}
          checked={checkedValue === value}
          onChange={this.props.newDesign ? this.props.onChange : this.onChange}
          name={this.props.name}
        />
        <span>{this.props.children}</span>
      </label>
    );
  }
}

RadioInput.propTypes = {
  ...Base.propTypes,
  checkedValue: PropTypes.string
};

export default RadioInput;
