import { uploadPDPData } from "../GA";
import { toCamelCase } from "./helper";

export const uploadPDPDetailData = () => {
  const $productPage = document.querySelector(".js-product-detail");

  if ($productPage) {
    const $product = $productPage.querySelector("div[data-tracing-source]");

    if ($product) {
      const product = $product.dataset.tracingSource;
      // use the pathname instead of href,
      // otherwise can't get the colorCode when set query in url
      const pathname = window && window.location && window.location.pathname;
      const pdpPath = pathname.split("/") && pathname.split("/");
      let colorCode;

      if (pdpPath.length === 4) {
        // eg: /products/ZZF8404/001
        colorCode = pdpPath.pop();
      }

      uploadPDPData({
        product: JSON.parse(product, toCamelCase),
        colorCode: colorCode
      });
    }
  }
};

class UploadProductsData {
  constructor() {
    this.init();
  }

  init() {
    uploadPDPDetailData();
  }
}

export default UploadProductsData;
