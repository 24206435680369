import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.CREDIT_CARDS_WILL_REFRESH });

const refreshSuccess = (data) => ({
  type: types.CREDIT_CARDS_DID_REFRESH,
  data,
});

const refreshFailure = (error) => ({
  type: types.CREDIT_CARDS_DID_REFRESH_FAILED,
  error,
});

// can't use `delete` as the function name.
const deleteCreditCard = (id, cb) => ({
  type: types.CREDIT_CARDS_WILL_DELETE,
  id,
  cb,
});

const deleteSuccess = (id) => ({ type: types.CREDIT_CARDS_DID_DELETE, id });

const deleteFailure = (error) => ({
  type: types.CREDIT_CARDS_DID_DELETE_FAILED,
  error,
});

const load = (data) => ({
  type: types.ACCOUNT_CREDIT_CARDS_DID_FORM_LOAD,
  data,
});

export {
  refresh,
  refreshSuccess,
  refreshFailure,
  deleteCreditCard,
  deleteSuccess,
  deleteFailure,
  load,
};
