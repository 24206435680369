import React from "react";
import Input from "./TextInput";
import { BaseProps } from "./BaseProps";

const ZipcodeInput = (props: Props) => (
  <>
    <div className="form-group zipcode grid-row-8-and-4 mb-24">
      <div className="form-input-group">
        <Input {...props} label="郵便番号" name="zipcode" />
        {/* eslint-disable-next-line */}
        <button
          className={`${props.searchLinkClassName} outline-btn`}
          onClick={props.onSearch}
        >
          住所検索
        </button>
      </div>
      <div className=""></div>
    </div>
  </>
);

type Props = BaseProps & {
  onSearch: () => {};
  searchLinkClassName: string;
};

export default ZipcodeInput;
