import {theplant} from '../../proto';

const getSizeGroup = ({rows, index}: {
  rows: theplant.ec.service.sizeguide.ISizeGuideTableRow[]; index: number;
}) => {
  const rowsLength = (rows && rows.length) || 0;

  let sizesGroup: string[] = [];

  Array.from(Array(rowsLength).keys()).map(i => {
    const currentRow = rows && rows[i];
    const currentCell = currentRow && currentRow.cells &&
        currentRow.cells.every(cell => cell && cell.value !== '') &&
        currentRow.cells[index];
    const sizeValue = currentCell && currentCell.value;

    if (sizeValue) {
      return sizesGroup.push(sizeValue);
    } else {
      return sizesGroup
    }
  });

  return sizesGroup;
};

const getTables = ({rows, specificSizeColumns}: {
  rows: theplant.ec.service.sizeguide.ISizeGuideTableRow[];
  specificSizeColumns?: Array<string>;
}) => {
  let specificSizeColumnIndexes: Array<number> = [];

  const titles =
      getSizeGroup({rows, index: 0}).filter(size => size).map((size, i) => {
        if (specificSizeColumns && specificSizeColumns.indexOf(size) > -1) {
          specificSizeColumnIndexes.push(i);
        }
        return size;
      });

  const firstRow = rows && rows[0];
  const cellLength = (firstRow && firstRow.cells && firstRow.cells.length) || 0;
  const contents: Array<Array<{size: string; bold?: boolean}>> = [];

  Array.from(Array(cellLength - 1).keys()).map(i => i + 1).map(i => {
    const sizes =
        getSizeGroup({rows, index: i}).filter(size => size).map((size, j) => {
          if (specificSizeColumnIndexes.indexOf(j) > -1) {
            return {size, bold: true};
          }

          return {size};
        });

    return contents.push(sizes);
  });

  return {titles, contents};
};

export {getTables};
