import $ from "jquery";

class Validator {
  static addCustomValidate() {
    // Sync backend email validation: https://github.com/theplant/aigle/blob/6efe2cbcc0949126fef549adf9d0cc0684e82289/customers/validator/validator.go#L200-L208
    $.validator.addMethod(
      "emailCustom",
      function (value, element) {
        return (
          this.optional(element) ||
          /^([\w\.%\+\-]+)@([\w\-]+\.)+([\w]{2,})$/i.test(value) // eslint-disable-line
        );
      },
      $.validator.messages.email
    );

    $.validator.addMethod(
      "isZip",
      function (value, element) {
        return this.optional(element) || /^\d{3}-?\d{4}$/.test(value);
      },
      "郵便番号をご確認ください。"
    );

    $.validator.addMethod(
      "isPhone",
      function (value, element) {
        return this.optional(element) || /^0\d{1,4}-\d{1,4}-\d{4}$/.test(value);
      },
      "この電話番号は無効です。"
    );
  }
}

export default Validator;
