import PropTypes from "prop-types";
import React from "react";

import TextInput from "../../../shared/Input/TextInput";
import BirthdayInput from "../../../shared/Input/BirthdayInput";
import GenderInput from "../../../shared/Input/GenderInput";
import { CheckboxInput } from "../../../../CheckoutApp/shared/CheckboxInput";
import Address from "../../../shared/Address";
import Form from "../../../shared/Form";
import PasswordForm from "./PasswordForm";
import EmailForm from "./EmailForm";

import { initialState as prefecturesInitialState } from "../../../../reducers/prefectures";
import { initialState as profileFormInitialState } from "../../../../reducers/profileForm";
import { initialState as emailFormInitialState } from "../../../../reducers/emailForm";
import { initialState as passwordFormInitialState } from "../../../../reducers/passwordForm";

import { CancelBtn, SubmitBtn } from "../Button";
import { PanelTitle } from "../PanelTitle";
import Divider from "../../../molecules/Divider";

const EditEmailAndPassword = (props) => {
  const {
    emailFormState,
    emailFormOpen,
    emailFormClose,
    emailFormSubmit,
    emailFormChangeField,
    passwordFormState,
    passwordFormOpen,
    passwordFormClose,
    passwordFormSubmit,
    passwordFormChangeField,
  } = props;
  const { email } = props.state.data;

  return (
    <>
      <div className="white-panel">
        <EmailForm
          email={email}
          state={emailFormState}
          open={emailFormOpen}
          close={emailFormClose}
          submit={emailFormSubmit}
          changeField={emailFormChangeField}
        />
      </div>
      <div className="white-panel">
        <PasswordForm
          state={passwordFormState}
          open={passwordFormOpen}
          close={passwordFormClose}
          submit={passwordFormSubmit}
          changeField={passwordFormChangeField}
        />
      </div>
    </>
  );
};

class EditForm extends Form {
  _renderCloseButton() {
    const { hiddenCloseButton, closeForm } = this.props;
    return hiddenCloseButton ? null : <CancelBtn close={closeForm} />;
  }

  render() {
    let prefectureOptions = this.props.prefecturesState.data.map(
      ({ name, japanese }) => [name, japanese]
    );

    let {
      dateOfBirth,
      phone,
      gender,
      familyName,
      givenName,
      katakanaFamilyName,
      katakanaGivenName,
      zipcode,
      prefecture,
      city,
      address1,
      address2,
      isSubscribed,
      dmByMail,
      dmBySms,
      dmByPhone,
    } = this.props.state.data;

    const disabled = this.props.state.ui.fetching;

    return (
      <form className="reg-form" onSubmit={this.submit}>
        <div>
          <PanelTitle title="個人情報を編集" />
          <div className="user-info grid-gap">
            <div className="grid-row-2-only">
              <TextInput
                label="姓"
                placeholder="田中"
                value={familyName}
                name="family_name"
                onChange={this.changeField("familyName")}
                errors={this.filterValidationErrors("familyName")}
              />
              <TextInput
                label="名"
                placeholder="太郎"
                value={givenName}
                name="given_name"
                onChange={this.changeField("givenName")}
                errors={this.filterValidationErrors("givenName")}
              />
              <TextInput
                label="セイ"
                placeholder="タナカ"
                value={katakanaFamilyName}
                name="katakana_family_name"
                onChange={this.changeField("katakanaFamilyName")}
                errors={this.filterValidationErrors("katakanaFamilyName")}
              />
              <TextInput
                label="メイ"
                placeholder="タロウ"
                value={katakanaGivenName}
                name="katakana_given_name"
                onChange={this.changeField("katakanaGivenName")}
                errors={this.filterValidationErrors("katakanaGivenName")}
              />
            </div>
            <div className="birthday-and-gender-panel grid-row">
              <BirthdayInput
                value={dateOfBirth}
                onChange={this.changeField("dateOfBirth")}
                errors={this.filterValidationErrors("dateOfBirth")}
              />
              <GenderInput
                name="gender"
                value={gender}
                onChange={this.changeField("gender")}
                errors={this.filterValidationErrors("gender")}
                newdesign="true"
              />
            </div>
          </div>
          <Divider />
          <Address
            value={{ zipcode, prefecture, city, address1, address2, phone }}
            errors={{
              zipcode: this.filterValidationErrors("zipcode"),
              prefecture: this.filterValidationErrors("prefecture"),
              city: this.filterValidationErrors("city"),
              address1: this.filterValidationErrors("address1"),
              address2: this.filterValidationErrors("address2"),
              phone: this.filterValidationErrors("phone"),
            }}
            onChange={this.props.changeField}
            prefectureOptions={prefectureOptions}
            hideSelectDefault
            addressTitle="住所を変更"
          />
          <Divider />
          <div className="account-info clearfix">
            <div className="subscription clearfix">
              <div className="title">オプション</div>
              <p className="desc mt-16">
                新着アイテム、特別割引キャンペーン、限定ストアイベントへの招待状などを、いち早くお届けします
              </p>
              <div className="grid-row-4 grid-gap">
                <CheckboxInput
                  checked={isSubscribed}
                  onChange={this.changeField("isSubscribed")}
                  label={"メールマガジンで受け取る"}
                />
                <CheckboxInput
                  checked={dmByMail}
                  onChange={this.changeField("dmByMail")}
                  label={"ハガキを受け取る"}
                />

                <CheckboxInput
                  checked={dmBySms}
                  onChange={this.changeField("dmBySms")}
                  label={"SMSで受け取る"}
                />
                <CheckboxInput
                  checked={dmByPhone}
                  onChange={this.changeField("dmByPhone")}
                  label={"電話で受け取る"}
                />
              </div>
              <div className="grid-row-1 grid-gap">
                <CheckboxInput
                  checked={!isSubscribed && !dmByMail && !dmBySms && !dmByPhone}
                  onChange={(checked) => {
                    if (checked) {
                      this.changeField("isSubscribed")(false);
                      this.changeField("dmByMail")(false);
                      this.changeField("dmBySms")(false);
                      this.changeField("dmByPhone")(false);
                    } else {
                      return false;
                    }
                  }}
                  label={
                    "注文関連のお知らせのみ（セールやキャンペーン情報をお届けしません）"
                  }
                  className="col"
                />
              </div>
              <Divider />
            </div>
          </div>
        </div>
        <div className="row">{this.renderFormError()}</div>
        <div className="row mt-15 flex-end">
          {this.props.hiddenCloseButton ? null : (
            <CancelBtn close={this.props.closeForm} />
          )}
          <SubmitBtn disabled={disabled} />
        </div>
      </form>
    );
  }
}

EditForm.defaultProps = {
  state: { ...profileFormInitialState },
  prefecturesState: { ...prefecturesInitialState },
  hiddenCloseButton: false,
  hiddenPasswordForm: false,
  passwordFormState: { ...passwordFormInitialState },
  hiddenEmailForm: false,
  emailFormState: { ...emailFormInitialState },
};

EditForm.propTypes = {
  state: PropTypes.object,
  prefecturesState: PropTypes.object,
  closeForm: PropTypes.func,
  submit: PropTypes.func,
  changeField: PropTypes.func,

  hiddenCloseButton: PropTypes.bool,

  hiddenPasswordForm: PropTypes.bool,
  passwordFormState: PropTypes.object,
  passwordFormOpen: PropTypes.func,
  passwordFormClose: PropTypes.func,
  passwordFormChangeField: PropTypes.func,
  passwordFormSubmit: PropTypes.func,

  hiddenEmailForm: PropTypes.bool,
  emailFormState: PropTypes.object,
  emailFormOpen: PropTypes.func,
  emailFormClose: PropTypes.func,
  emailFormChangeField: PropTypes.func,
  emailFormSubmit: PropTypes.func,
};

export default EditForm;
export { EditEmailAndPassword };
