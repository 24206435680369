import React, { useState } from "react";
import { useFavStore } from "./MyStoreComponent/useFavStore";
import SearchShop from "./MyStoreComponent/SearchShop";
import FavStore from "./MyStoreComponent/FavStore";
import { InlineLoading } from "./Loading";

const MyStore = () => {
  const [showList, setShowList] = useState(false);
  const { favStore, setFavStoreWrap, fetching } = useFavStore();

  return (
    <div className="tab-content">
      <div className="new-my-store clearfix">
        <div className="white-panel">
          {fetching ? (
            <InlineLoading />
          ) : (
            <>
              {showList || !favStore ? (
                <SearchShop
                  setFavStore={setFavStoreWrap}
                  setShowList={setShowList}
                ></SearchShop>
              ) : (
                <FavStore store={favStore} setShowList={setShowList}></FavStore>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyStore;
