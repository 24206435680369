import $ from "jquery";
import Validator from "./validator.js";

class Contact {
  constructor() {
    this.$contactForm = $(".js-contact-form");

    Validator.addCustomValidate();
    this.addContactValidation();
  }

  addContactValidation() {
    this.$contactForm.validate({
      errorClass: "error",
      errorElement: "span",
      errorPlacement: function(error, element) {
        if (element.attr("name") === "agree_privacy_policy") {
          $(element)
            .siblings("span")
            .append(error);
        } else {
          error.insertAfter(element);
        }
      },
      rules: {
        family_name: {
          required: true,
          maxlength: 20
        },
        given_name: {
          required: true,
          maxlength: 20
        },
        email: {
          required: true,
          emailCustom: true,
          maxlength: 50
        },
        content: {
          required: true,
          minlength: 10
        },
        agree_privacy_policy: {
          required: true
        }
      }
    });
  }
}

export default Contact;
