import Swiper from "swiper";
import { Navigation } from "swiper/modules";

class Tabs {
  constructor() {
    new Swiper(".js-flexible-tabs-container", {
      slidesPerView: "auto",
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // Optional parameters
      watchOverflow: true,
      simulateTouch: false,
      modules: [Navigation],
    });
  }
}

export default Tabs;
