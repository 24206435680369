import React from "react";
import { Input } from "../../../CheckoutApp/shared/Input";
import { BaseProps } from "./BaseProps";

const TextInput = (props: BaseProps) => {
  const { onChange, ...rest } = props;
  const hasError = (props.errors || []).length > 0;

  return <Input {...rest} updateInput={onChange} dirty={hasError} />;
};

export default TextInput;
