import React from "react";
import { formatPoints } from "../helpers/currency";
import cx from "classnames";
type Props = {
  confirmPoint: number;
  temporaryPoint: number;
  campaignPoint?: number;
  rankName?: string;
  rankDefaultRatios?: number;
  expiresoonMonthPoint?: number;
  nextRankName?: string;
  neededAmount?: number;
  onlySharedPart?: boolean;
};

// See: https://github.com/theplant/aigle/blob/656742dcf4683de4e20818e960b189581565b4dc/aigleapp/mail/cmds/syncsubscribed/app/utils.go#L20-L26
const MEMBER_TYPES_MAP = {
  "": "アクティブ",
  REGULAR: "アクティブ",
  SILVER: "シルバー",
  GOLD: "ゴールド",
  PLATINUM: "プラチナ",
};

const PointsDetails = ({
  confirmPoint,
  temporaryPoint,
  campaignPoint,
  expiresoonMonthPoint,
}: Props) => (
  <ul className="point-details">
    <li>
      確定ポイント：{confirmPoint === 0 ? 0 : `${formatPoints(confirmPoint)}pt`}
    </li>
    <li>仮ポイント：{formatPoints(temporaryPoint)}</li>
    <li>期間限定ポイント：{formatPoints(campaignPoint)}</li>
    <li>
      30 日以内失効ポイント：
      {formatPoints(expiresoonMonthPoint)}
    </li>
  </ul>
);

const SharedPoint = ({
  confirmPoint,
  temporaryPoint,
  rankName,
  rankDefaultRatios,
  nextRankName,
  neededAmount,
  onlySharedPart,
}: Props) => {
  const currentPoint = confirmPoint + temporaryPoint;
  return (
    <div className="grid-row">
      <div>
        <div className="tab-panel">
          <div className="content-title">保有ポイント</div>
          <div className="content">
            <p>
              {currentPoint === 0
                ? 0
                : formatPoints(confirmPoint + temporaryPoint) + `pt`}
            </p>
            <p className="desc">（仮ポイント含む）</p>
          </div>
        </div>
        {!onlySharedPart && (
          <PointsDetails
            confirmPoint={confirmPoint}
            temporaryPoint={temporaryPoint}
          />
        )}
      </div>
      <div>
        <div className="tab-panel">
          <div className="content-title">ステータス</div>
          <div className="content">
            {rankName ? (
              <p className={cx("member-status", rankName.toLowerCase())}>
                <span>{rankName}</span>
              </p>
            ) : null}
            {nextRankName &&
            neededAmount &&
            (MEMBER_TYPES_MAP as any)[nextRankName.toUpperCase()] ? (
              <p className="next-rank desc">
                {`${(MEMBER_TYPES_MAP as any)[nextRankName.toUpperCase()]}`}
                まで:　あと
                <span>¥{formatPoints(neededAmount)}</span>
              </p>
            ) : null}
            {onlySharedPart && rankName && (
              <p className="desc">
                <a href="/member" className="detail-link">
                  詳しくはこちらへ
                </a>
              </p>
            )}
          </div>
        </div>
        {!onlySharedPart && (
          <ul className="point-details">
            <li>
              ポイント対象商品本体価格の
              {rankDefaultRatios}
              ％をポイント還元
            </li>
            <li>1ポイント＝￥1</li>
            <li>ポイント有効期限は1年間</li>
            <li>
              <a href="/member">詳しくはこちらへ</a>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default SharedPoint;
export { PointsDetails };
