import React from "react";
import { OrderItemDataPropType } from "./OrderItemDataPropType";
import OrderItemAmount from "./OrderItemAmount";

type Props = {
  data: OrderItemDataPropType;
  showingNotAvailableWhenOutOfStock: boolean;
};

const OrderItem = (props: Props) => {
  const {
    productName,
    productPath,
    productImageURL,
    productSize,
    productColor,
    quantity,
  } = props.data;

  return (
    <div className="cart-item flex space-between">
      <div className="product-info-col flex">
        <figure>
          <a href={productPath} title={productName}>
            <img src={productImageURL} alt={productName} loading="lazy" />
          </a>
        </figure>
        <div className="cart-item-info">
          <a href={productPath} title={productName}>
            <h6>{productName}</h6>
          </a>
          <OrderItemAmount
            data={props.data}
            showingNotAvailableWhenOutOfStock={
              props.showingNotAvailableWhenOutOfStock
            }
            className="sp-only"
          />
          <p>
            <label>サイズ：</label>
            {productSize}
          </p>
          <p>
            <label>色：</label>
            {productColor}
          </p>
          <p className="count">
            <label>数量：</label>
            {quantity}
          </p>
        </div>
      </div>
      <OrderItemAmount
        data={props.data}
        showingNotAvailableWhenOutOfStock={
          props.showingNotAvailableWhenOutOfStock
        }
        className="desktop-only"
      />
    </div>
  );
};

OrderItem.defaultProps = {
  data: {},
  showingNotAvailableWhenOutOfStock: false,
};

export default OrderItem;
