import PropTypes from "prop-types";
import React, { useEffect } from "react";

import CreditCard from "./CreditCards/CreditCard";
import CreditCardForm, {
  CreditCardIssuers,
} from "./CreditCards/CreditCardForm";
import { CheckedSVG } from "../../shared/svg";
import { connect } from "react-redux";
import { refresh, deleteCreditCard } from "../../../actions/creditCards";
import {
  newCreditCard,
  close,
  changeField,
  submit,
  editCreditCard,
} from "../../../actions/accountCreditCardForm";
import { initialState } from "../../../reducers/creditCards";
// import { fakeCreditCards } from "./Common/fakeCreditCard";
import { useAddSuccess } from "../../../components/hooks";

import helpers from "../../helpers";
import { PanelTitle } from "./PanelTitle";

const CreditCards = (props) => {
  const { refresh, creditCards } = props;
  const { success, addSuccess } = useAddSuccess();
  const [edit, setEdit] = React.useState(false);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const openEditForm = () => {
    setEdit(true);
  };

  const closeEditForm = () => {
    setEdit(false);
  };

  // let creditCards = fakeCreditCards; // for local test
  const hasExistCards = (creditCards || []).length > 0;

  return (
    <div className="credit-card-wrapper">
      {edit ? null : (
        <div className="white-panel">
          {hasExistCards ? (
            <>
              <div className="grid-row grid-gap">
                <PanelTitle title="お客様のクレジットカード" />
                <CreditCardIssuers />
              </div>
              <div className="grid-row">
                {(creditCards || []).map((creditCard, idx) => (
                  <CreditCard
                    key={idx}
                    item={creditCard}
                    onDelete={props.deleteCreditCard}
                    onEdit={props.editCreditCard}
                    openEditForm={openEditForm}
                  />
                ))}
              </div>
            </>
          ) : (
            <CreditCardForm
              state={props.formState}
              submit={props.submit}
              changeField={props.changeField}
              withIsSavingCheckbox={false}
              addMore={hasExistCards}
              title={"お客様のクレジットカード"}
            />
          )}
        </div>
      )}
      {hasExistCards && (
        <div
          className={`white-panel ${
            !props.formState.ui.isOpen && "add-new-panel"
          }`}
        >
          {props.formState.ui.isOpen ? (
            <CreditCardForm
              state={props.formState}
              submit={() => {
                props.submit(() => {
                  if (!edit) {
                    addSuccess();
                  }
                  props.close();
                  closeEditForm();
                });
              }}
              addMore={hasExistCards}
              title="クレジットカード情報を追加"
              close={
                creditCards.length > 0
                  ? () => {
                      props.close();
                      closeEditForm();
                    }
                  : undefined
              }
              changeField={props.changeField}
              withIsSavingCheckbox={false}
            />
          ) : (
            <>
              {!edit && success && (
                <div className="add-success-message">
                  <CheckedSVG color="#439F7A" />
                  <p>新しいクレジットカードが追加されました。</p>
                </div>
              )}
              <button
                type="button"
                className="outline-btn add-credit-card"
                onClick={() => {
                  // clear old fields
                  props.newCreditCard();
                }}
              >
                {edit ? "クレジットカードを編集" : "クレジットカードを追加する"}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

CreditCards.defaultProps = {
  refresh: () => null,
  creditCards: [],
  formState: { ...initialState },
};

CreditCards.propTypes = {
  creditCards: PropTypes.arrayOf(PropTypes.object),
  formState: PropTypes.object,
  newCreditCard: PropTypes.func,
  close: PropTypes.func,
  submit: PropTypes.func,
  changeField: PropTypes.func,
};

const mapStateToProps = ({ creditCards, accountCreditCardForm }) => {
  return {
    creditCards: helpers.getCreditCards(creditCards),
    formState: accountCreditCardForm,
  };
};

export default connect(mapStateToProps, {
  refresh,
  newCreditCard,
  close,
  changeField,
  submit,
  deleteCreditCard,
  editCreditCard,
})(CreditCards);

export { CreditCards, mapStateToProps };
