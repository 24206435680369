import React from "react";

const PanelTitle = ({
  title,
  subTitle,
  htmlFor,
}: {
  title?: string;
  subTitle?: string;
  htmlFor?: string;
}) => (
  <label className="panel-title" htmlFor={htmlFor}>
    <h3 className="title">{title}</h3>
    {subTitle && <p className="sub-title grid-gap">{subTitle}</p>}
  </label>
);

export { PanelTitle };
