import React from "react";
import { Selector, Props } from "../../../CheckoutApp/shared/Selector";

const SelectInput = (props: Props) => {
  const hasError = (props.errors || []).length > 0;
  const { name } = props;
  const isPrefecture = name === "prefecture";
  const options = props.options.map(
    ([key, value]: [key: string, value: string]) => ({
      key: key,
      name: value || key,
      english: isPrefecture ? key : undefined,
    })
  );

  return (
    <Selector
      {...props}
      options={options}
      updateInput={props.onChange}
      dirty={hasError}
    />
  );
};

export default SelectInput;
