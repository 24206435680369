import $ from "jquery";

// Sample popup HTML
//
// <div class="aigle-popup" id="{wrapperEelmentID}">
//     <div class="aigle-popup-container">
//         <div class="aigle-popup-content login-message">
//             {contentHtml}
//             <a role="button" class="aigle-popup-close-btn"></a>
//             <h3>大切なお知らせ</h3>
//             <p>旧サイト（2017 年 3 月 17 日以前）から会員登録いただきましたお客様は、パスワードが引き継がれません。</p>
//             <a href="#">こちらよりリセットしてください</a>
//         </div>
//     </div>
// </div>

class Popup {
  constructor({
    wrapperElementID = "",
    contentHtml = "",
    contentClassName = ""
  }) {
    this.wrapperElementID = wrapperElementID;
    this.contentHtml = contentHtml;
    this.contentClassName = contentClassName;

    this._closePopup = this._closePopup.bind(this);
    this._renderHtmlString = this._renderHtmlString.bind(this);

    this._render();
    this._bindEvents();

    return this;
  }

  _bindEvents() {
    $("body").on("click", `.aigle-popup-close-btn`, this._closePopup);
  }

  _closePopup(event) {
    this.hide();
  }

  _renderHtmlString() {
    return `<div class="aigle-popup" id="${
      this.wrapperElementID
    }" style="display: none;">
    <div class="aigle-popup-container">
        <div class="aigle-popup-content ${this.contentClassName}">
            <a role="button" class="aigle-popup-close-btn"></a>
            ${this.contentHtml}
        </div>
    </div>
</div>`;
  }

  _render() {
    const $targetElement = $(`${this.wrapperElementID}`);
    if ($targetElement.length > 0) {
      $targetElement.html(
        $(this._renderHtmlString())
          .find(".aigle-popup-container")
          .html()
      );
      return;
    }
    $("body").append(this._renderHtmlString());
  }

  show() {
    $(`#${this.wrapperElementID}`).show();
  }

  hide() {
    $(`#${this.wrapperElementID}`).hide();
  }
}

export default Popup;
