import React, { useEffect } from "react";
import $ from "jquery";

import { connect } from "react-redux";
import {
  refresh as refreshProfile,
  refreshSuccess as refreshProfileSuccess,
} from "../../../actions/profile";
import {
  open as openForm,
  close as closeForm,
  changeField,
  submit,
} from "../../../actions/profileForm";
import {
  open as passwordFormOpen,
  close as passwordFormClose,
  changeField as passwordFormChangeField,
  submit as passwordFormSubmit,
} from "../../../actions/passwordForm";
import {
  open as emailFormOpen,
  close as emailFormClose,
  changeField as emailFormChangeField,
  submit as emailFormSubmit,
} from "../../../actions/emailForm";

import Brief from "./Profile/Brief";
import EditForm from "./Profile/EditForm";

const Profile = (props: any) => {
  const {
    profileState,
    profileFormState,
    prefecturesState,
    openForm,
    closeForm,
    refreshProfileSuccess,
  } = props;
  React.useEffect(() => {
    refreshProfile();
  }, []);

  const scrollToForm = () => {
    $("html, body").animate({
      scrollTop: ($(".profile-tab") as any).offset().top - 95,
    });
  };

  useEffect(() => {
    if (profileFormState.ui.isOpen) {
      scrollToForm();
    }
  }, [profileFormState.ui.isOpen]);

  return (
    <div className="content white-panel profile-tab">
      {profileFormState.ui.isOpen ? (
        <EditForm
          {...props}
          state={profileFormState}
          closeForm={() => {
            closeForm();
            scrollToForm();
            refreshProfileSuccess(profileState.data); // reset profile form state
          }}
        />
      ) : (
        <Brief
          profileState={profileState}
          prefecturesState={prefecturesState}
          openForm={openForm}
        />
      )}
    </div>
  );
};

export default connect(
  ({ prefectures, profile, profileForm, passwordForm, emailForm }: any) => ({
    prefecturesState: prefectures,
    profileState: profile,
    profileFormState: profileForm,
    passwordFormState: passwordForm,
    emailFormState: emailForm,
  }),
  {
    refreshProfile,
    openForm,
    closeForm,
    refreshProfileSuccess,
    changeField,
    submit,
    passwordFormOpen,
    passwordFormClose,
    passwordFormChangeField,
    passwordFormSubmit,
    emailFormOpen,
    emailFormClose,
    emailFormChangeField,
    emailFormSubmit,
  }
)(Profile);
