import React, { useState } from "react";
import { ItemCard } from "../Common/ItemCard";
import { Item } from "../../../../CheckoutApp/Payment/ListItem";
import Popup from "../../../molecules/Popup";

const CreditCard = ({ item, onDelete }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const close = () => {
    setOpenModal(false);
  };

  const open = () => {
    setOpenModal(true);
  };

  return (
    <>
      <ItemCard
        onDelete={open}
        content={
          <Item
            {...item}
            expiryDate={item.expiry_date}
            className="white-bg no-border"
          />
        }
        selectedLabel="ご利用中のカード"
        // we didn't support edit credit card for now
        // onEdit={() => {
        //   onEdit(item.id);
        //   if (openEditForm) {
        //     openEditForm();
        //   }}
        // }
      />
      <Popup
        isOpen={openModal}
        close={close}
        isConfirmPopup
        onConfirm={() => onDelete(item.id, close)}
        confirmText="削除する"
        title="カードの削除確認"
        confirmTitle="このクレジットカードを削除してもよろしいですか？"
      >
        <ItemCard
          className="credit-card-in-popup"
          content={<Item {...item} expiryDate={item.expiry_date} />}
          selectedLabel="ご利用中のカード"
        />
      </Popup>
    </>
  );
};

type Props = {
  item: {
    number: string;
    brand: string;
    name?: string;
    expiry_date: string;
    id: string;
  };
  onDelete: (id: string, cb?: () => void) => void;
  onEdit: (id: string) => void;
  openEditForm?: () => void;
};

export default CreditCard;
