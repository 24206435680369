import React from "react";
import { theplant, proto } from "../../proto";
import StoresAndPrefectures, { IStoreInfoWithStock } from "./Stores";
import { calcPrice } from "../helper";
import { usePrefecture } from "./usePrefecture";

import { anyStoreInInventory } from "./helper";
import {
  getVariantsByColorCode,
  getVariantsBySizeCode,
  productImage,
  getDefaultSizeCode,
} from "../helper";
import { Price } from "../molecules/Price";
import { getStores } from "./getStore";
import { ProductImage } from "../molecules/ProductImage";
import { ProductName } from "../molecules/ProductName";
import { ProductID } from "../molecules/ProductID";
import { ColorSwatch } from "../molecules/ColorSwatch";
import { SizeSelection } from "../molecules/SizeSelection";
import { storeInventoryButtonClick } from "../../gtm/storeInventoryClickEvent";
import { Description } from "./Description";

export type ContentProps = {
  colorCode: string;
  selectedSizeCode?: string | null;
  product: theplant.ec.service.products.IProduct;
};

const ControlArea = ({
  changeColor,
  changeSize,
  sizeError,
  searchStoresByConditions,
  loading,
  color,
  size,
  variantsByColorCode,
  ...props
}: ContentProps & {
  changeColor: (color: string) => void;
  changeSize: (size?: string | null) => void;
  color: string;
  size?: string | null;
  colorError: proto.ValidationError.IFieldViolation | null;
  sizeError: proto.ValidationError.IFieldViolation | null;
  loading?: boolean;
  searchStoresByConditions: () => void;
  variantsByColorCode: theplant.ec.service.products.IVariant[];
}) => {
  const onSearch = () => {
    searchStoresByConditions();

    storeInventoryButtonClick("search");
  };

  return (
    <>
      <ColorSwatch
        {...props}
        selectedColorCode={color}
        changeColor={changeColor}
        inStoreInventoryPanel
      />
      <SizeSelection
        variants={variantsByColorCode}
        changeSize={changeSize}
        selectedSizeCode={size}
        sizeError={sizeError}
      />
      <div className="button-wrapper mt-24">
        <button
          className={`primary-btn search ${loading ? "loading" : ""}`}
          onClick={onSearch}
        >
          {loading ? "検索..." : "この条件で検索"}
        </button>
      </div>
    </>
  );
};

const Content = (props: {
  product: ContentProps["product"];
  colorCode: ContentProps["colorCode"];
  selectedSizeCode: ContentProps["selectedSizeCode"];
  display: boolean;
}) => {
  const { product, display } = props;

  const [color, setColor] = React.useState(props.colorCode);
  const [size, setSize] = React.useState<string | null | undefined>(
    props.selectedSizeCode
  );

  const { code, name, images } = product;

  const imgs = images && images.length > 0 && productImage(color, images);

  const img = imgs && imgs.length > 0 && imgs[0];
  const imgSrc = img && img.id;

  const variants = product.variants || [];

  const variantsByColorCode = getVariantsByColorCode(color, variants);

  const selectedVariant =
    variantsByColorCode &&
    size &&
    getVariantsBySizeCode(variantsByColorCode, size);

  const currentVariant = selectedVariant || variantsByColorCode[0];

  const { price, discounted } = calcPrice(currentVariant);

  const [colorError, setColorError] =
    React.useState<proto.ValidationError.IFieldViolation | null>(null);

  const [sizeError, setSizeError] =
    React.useState<proto.ValidationError.IFieldViolation | null>(null);

  const [loading, setLoading] = React.useState(false);

  const storeRef = React.useRef(null);

  const [stores, setStores] = React.useState<IStoreInfoWithStock[]>([]);

  const timer = React.useRef<NodeJS.Timeout | null>(null);

  const {
    prefectures,
    selectedPrefectures,
    add,
    remove,
    clearSelectedPrefecture,
  } = usePrefecture(display);

  const searchStoresByConditions = () => {
    if (!color) {
      setColorError({ code: "noSelectedColorCode" });
      if (timer.current) {
        clearInterval(timer.current);
      }
      return;
    }

    if (!size) {
      setColorError(null);
      setSizeError({ code: "noSelectedSizeCode" });
      if (timer.current) {
        clearInterval(timer.current);
      }
      return;
    }

    setColorError(null);
    setSizeError(null);
    if (timer.current) {
      clearInterval(timer.current);
    }

    if (
      document.querySelectorAll(".size-option.selected:not(:disabled)")
        .length === 0
    ) {
      setSizeError({ code: "noSelectedSizeCode" });
    }

    const articleCode = selectedVariant && selectedVariant.articleCode;
    if (articleCode) {
      getStores({
        articleCode,
        setLoading,
        setStores,
        clearSelectedPrefecture,
        storeRef,
        stores,
      });

      timer.current = setInterval(() => {
        // call get store api
        getStores({
          articleCode,
          setLoading,
          setStores,
          clearSelectedPrefecture,
          storeRef,
          stores,
        });
      }, 15 * 60 * 1000);
    }
  };

  const changeColor = (code: string) => {
    setColor(code);
    setStores([]);
    clearSelectedPrefecture();
    setColorError(null);
  };

  const changeSize = React.useCallback(
    (size?: string | null) => {
      setSize(size);
      if (size) {
        setStores([]);
        clearSelectedPrefecture();
        setSizeError(null);
      }
    },
    [clearSelectedPrefecture]
  );

  React.useEffect(() => {
    if (color && product.variants) {
      const variantsUnderTheSameColor = getVariantsByColorCode(
        color,
        product.variants
      );

      const hasStoreInventory = anyStoreInInventory(variantsUnderTheSameColor);

      if (hasStoreInventory) {
        const defaultSizeCode = getDefaultSizeCode({
          variants: product.variants,
          colorCode: color,
        });

        // set default size code in did mount when only have 1 size
        if (defaultSizeCode) {
          setSize(defaultSizeCode);
        }
      }
    }
  }, [product.variants, color, setSize]);

  return (
    <>
      <div className="product-wrapper desktop">
        <div>
          <ProductImage imgSrc={imgSrc} />
        </div>
        <div className="content">
          <ProductName name={name} />
          <div className="pdp-info_gender_price_wrapper">
            <ProductID code={code} />
            <Price price={price} discounted={discounted} />
          </div>
          <ControlArea
            {...props}
            changeColor={changeColor}
            changeSize={changeSize}
            color={color}
            size={size}
            colorError={colorError}
            sizeError={sizeError}
            loading={loading}
            searchStoresByConditions={searchStoresByConditions}
            variantsByColorCode={variantsByColorCode}
          />
        </div>
      </div>

      <div className="product-wrapper mobile">
        <div className="product-image-wrapper">
          <ProductImage imgSrc={imgSrc} />
          <div className="content">
            <ProductName name={name} />
            <div className="pdp-info_gender_price_wrapper">
              <ProductID code={code} />
              <Price price={price} discounted={discounted} />
            </div>
          </div>
        </div>
        <ControlArea
          {...props}
          changeColor={changeColor}
          changeSize={changeSize}
          color={color}
          size={size}
          colorError={colorError}
          sizeError={sizeError}
          loading={loading}
          searchStoresByConditions={searchStoresByConditions}
          variantsByColorCode={variantsByColorCode}
        />
      </div>

      <Description />

      <StoresAndPrefectures
        stores={stores}
        key={`${color}-${size}-${stores.length}`}
        domRef={storeRef}
        prefectures={prefectures}
        selectedPrefectures={selectedPrefectures}
        add={add}
        remove={remove}
        clearSelectedPrefecture={clearSelectedPrefecture}
      />
    </>
  );
};

export default Content;
