import React from "react";

import {
  ConfirmResultProps,
  DataProps,
  UsersProps,
  OrderServiceProps,
} from "../../orders";
import { RedirectToCart } from "../shared/RedirectToCart";

import { RegisterForm } from "../shared/RegisterForm";
import { LoginForm } from "./LoginForm";
import { getSize } from "../../product/productField";

import { selectCartItemProperty } from "../../orders";
import { UploadConfirmDataToGTM } from "../../gtm/GA";
import GA4PageView from "../ga4PageView";
import { SnapboardTargetForThankYou } from "../../snapboard";

import {
  withRecordOrderSentInBrowser,
  withRecordOrderSentInBrowserProps,
} from "../../withRecordOrderSentInBrowser";
import { getExpectPoint } from "../helper";

import { theplant } from "../../proto";
type IOrderItem = theplant.ec.service.orders.IOrderItem;

const OrderHasSent = React.memo(
  withRecordOrderSentInBrowser(
    ({
      orderHadSent,
      orderFirstSent,
      orderAlreadySent,
      orderCode,
    }: withRecordOrderSentInBrowserProps & {
      orderCode?: string | null;
    }) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useEffect(() => {
        if (orderCode && orderFirstSent && orderHadSent && orderAlreadySent) {
          const isFirstSent = orderFirstSent(orderCode);
          const alreadySent = orderAlreadySent(orderCode);

          if (isFirstSent) {
            orderHadSent(orderCode);
          } else if (alreadySent) {
            const host = window.location.host;
            if (
              host.indexOf("localhost:9009") > -1 ||
              host.indexOf("storybook") > -1
            ) {
              return;
            }

            window.location.reload();
          }
        }
      }, [orderCode, orderFirstSent, orderHadSent, orderAlreadySent]);

      return null;
    }
  )
);

const Confirmation = (
  props: ConfirmResultProps & DataProps & UsersProps & OrderServiceProps
) => {
  React.useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }

    GA4PageView();

    const order = ((props.confirmResult || {}).result || {}).order || {};
    const orderItems = order.orderItems || [];
    const orderQuoteAmountWithTax = Number(
      (order.sumUp || {}).quoteAmountWithTax || 0
    );

    // ref: ThankYou.gohtml#L84-L130
    const transactionSummary = {
      id: order.code,
    };

    const countQuantity = (a: number, c: IOrderItem): number =>
      a + Number(c.quantity || 0);

    const basketSummary = {
      subtotal: {
        currency: "JPY",
        value: orderQuoteAmountWithTax,
      },
      total: {
        currency: "JPY",
        value: orderQuoteAmountWithTax,
      },
      quantity: orderItems.reduce(countQuantity, 0),
    };

    const be = (window as any).be;
    if (be) {
      be.emit("ecView", {
        type: "checkout",
        currency: "JPY",
        language: "ja-jp",
      });
      be.emit("ecUser", {
        user: (window as any).user,
      });

      be.emit("ecBasketTransactionSummary", {
        transaction: transactionSummary,
        basket: basketSummary,
      });

      orderItems.forEach((item) => {
        const productCode = item.productCode;
        const colorCode = selectCartItemProperty(item, "ColorCode");
        const productPath = `/products/${productCode}/${colorCode}`;
        const size = getSize(item.discountContexts);

        be.emit("ecBasketItemTransaction", {
          transaction: transactionSummary,
          basket: basketSummary,
          product: {
            sku: item.articleCode,
            productId: item.productCode,
            name: item.productName,
            stock: Number(item.quantityInStock || 0),
            price: {
              currency: "JPY",
              value: Number(item.sellingPrice || 0),
            },
            originalPrice: {
              currency: "JPY",
              value: Number(item.price || 0),
            },
            url: productPath,
            images: [item.productImageUrl],
            sizeName: size,
            size,
          },
          quantity: Number(item.quantity || 0),
          subtotal: {
            currency: "JPY",
            value:
              item.sumUp && item.sumUp.discounted
                ? Number(item.sumUp.discounted.amount || 0)
                : 0,
          },
          subtotalIncludingTax: {
            currency: "JPY",
            value:
              item.sumUp && item.sumUp.discounted
                ? Number(item.sumUp.discounted.amountWithTax || 0)
                : 0,
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    confirmResult: { result },
    data,
    users: {
      login,
      register,
      input: { registerForm, loginForm },
    },
  } = props;

  if (!result || !result.order || !result.order.code) {
    return <RedirectToCart />;
  }

  const {
    setGuestUserInitialPasswordToken,
    setInitialPasswordToken,
    canMergeGuestUser,
    amazonpayEmail,
    order: { code, shippingAddress, billingAddress, customer, givePoints },
    isGuestUserEmailExisting,
  } = result;

  const hasInitialPasswordToken =
    !!setGuestUserInitialPasswordToken || !!setInitialPasswordToken;

  const shouldDisplayRegisterForm =
    canMergeGuestUser && hasInitialPasswordToken;

  return (
    <>
      <div className="confirmation-screen">
        <div className="confirmation-wrapper">
          <section className="thank-you-section mb-15">
            <h2>ご注文ありがとうございました</h2>
            <h4>ご注文番号： {result && result.order && result.order.code}</h4>
            {!hasInitialPasswordToken &&
            !amazonpayEmail &&
            !isGuestUserEmailExisting ? (
              <p>
                ご登録のメールアドレスに「購入完了メール」をお送りしておりますのでご確認ください。
                ご注文情報は
                <a href="/account/orders" target="_blank">
                  購入履歴
                </a>
                からもご確認いただけます。
              </p>
            ) : (
              <p>
                この度はAIGLEオンラインショップをご利用いただき誠にありがとうございました。またのご利用をお待ちしております。
              </p>
            )}
          </section>
          {!!amazonpayEmail ? (
            <section className="forms-wrapper">
              <LoginForm
                email={amazonpayEmail}
                orderCode={code}
                loginForm={loginForm}
                login={login}
              />
            </section>
          ) : isGuestUserEmailExisting && customer && customer.email ? (
            <section className="forms-wrapper">
              <LoginForm
                email={customer.email}
                orderCode={code}
                loginForm={loginForm}
                login={login}
              />
            </section>
          ) : !!shouldDisplayRegisterForm ? (
            <div className="given-point-info">
              <p className="sub-title">
                AIGLEオンラインショップのアカウントを登録しよう！
              </p>
              <p className="red-text">
                {getExpectPoint(result.order) || 0}+ 2,000ポイント取得できます
              </p>
            </div>
          ) : (
            <div className="given-point-info white-panel">
              <p className="sub-title">
                獲得ポイント：{givePoints || 0}
                ポイント
              </p>
              <p className="desc">
                上記のポイントは、本日から10日以降に付与されます。
              </p>
              <a
                className="outline-btn"
                href="/account/points/history"
                target="_blank"
              >
                保有ポイントを確認する
              </a>
              <p className="desc">
                この度はAIGLEオンラインショップを
                <br />
                ご利用いただき誠にありがとうございました。
                <br />
                またのご利用をお待ちしております。
              </p>
            </div>
          )}

          {shouldDisplayRegisterForm ? (
            <section className="forms-wrapper">
              <RegisterForm
                token={
                  setGuestUserInitialPasswordToken || setInitialPasswordToken
                }
                address={billingAddress || shippingAddress}
                orderCode={code}
                email={customer && customer.email}
                register={register}
                registerForm={registerForm}
              />
            </section>
          ) : null}
          {result && (
            <UploadConfirmDataToGTM
              order={result.order}
              data={data.data}
              checkout={props.checkout.input.form}
            />
          )}
          <SnapboardTargetForThankYou {...props} />
          {result && result.order && (
            <OrderHasSent orderCode={result.order.code} />
          )}
        </div>
      </div>
    </>
  );
};

const StableConfirmation = React.memo(Confirmation);

export { StableConfirmation as Confirmation };
