import { combineReducers } from "redux";
import prefectures from "./prefectures";
import profile from "./profile";
import profileForm from "./profileForm";
import passwordForm from "./passwordForm";
import emailForm from "./emailForm";
import mustUpdateProfile from "./mustUpdateProfile";
import shippingAddresses from "./shippingAddresses";
import creditCards from "./creditCards";
import points from "./points";
import pointsHistory from "./pointsHistory";
import wishItems from "./wishItems";
import accountWishItems from "./accountWishItems";
import orders from "./orders";
import accountShippingAddressForm from "./accountShippingAddressForm";
import accountCreditCardForm from "./accountCreditCardForm";
import cartItems from "./cartItems";
import accountViewedProducts from "./accountViewedProducts";
import { reducer as configReducer } from "../config";
import { cartReducer, checkoutReducer } from "../orders";
import { storeFinderReducer } from "../storeFinder";

import { keyed } from "@theplant/ecjs/projection";

const topReducer = combineReducers({
  prefectures,
  profile,
  profileForm,
  passwordForm,
  emailForm,
  mustUpdateProfile,
  shippingAddresses,
  creditCards,
  points,
  pointsHistory,
  wishItems,
  accountWishItems,
  orders,
  accountShippingAddressForm,
  accountCreditCardForm,
  accountViewedProducts,
  cartItems,
  config: configReducer,
  cart: keyed("cart", cartReducer),
  checkout: keyed("checkout", checkoutReducer),
  storeFinder: storeFinderReducer,
});

export default topReducer;
