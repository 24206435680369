import React, { useEffect } from "react";
import { theplant } from "../../proto";
import { _getURL } from "../../Api";
import $ from "jquery";
import { anyStoreInInventory } from "../helper";
import { toCamelCase } from "../../gtm/uploadDataComponent/helper";

const getProductWithStoreStock = ({
  code,
  callback
}: {
  code: string;
  callback: ({
    product,
    hasStoreStock
  }: {
    product: theplant.ec.service.products.IProduct;
    hasStoreStock: boolean;
  }) => void;
}) => {
  $.ajax({
    url: _getURL("/get-products-with-store-stock"),
    data: JSON.stringify({ codes: [code] }),
    type: "POST",
    xhrFields: {
      withCredentials: true
    },
    success: _products => {
      const products = JSON.parse(
        _products,
        toCamelCase
      ) as Array<theplant.ec.service.products.IProduct>;

      if (products && products.length > 0) {
        const product = products[0];
        const variants = product.variants;

        const hasStoreStock = anyStoreInInventory(variants);

        callback({ product, hasStoreStock: !!hasStoreStock });
      }
    },
    error: e => {
      console.log("get product with store stock failed, ", e);
    }
  });
};

const useProductHasAnyStoreInventory = (code?: string | null) => {
  const [hasStoreStock, setHasStoreStock] = React.useState(false);
  const [
    product,
    setProduct
  ] = React.useState<theplant.ec.service.products.IProduct | null>(null);

  const callback = ({
    product,
    hasStoreStock
  }: {
    product: theplant.ec.service.products.IProduct;
    hasStoreStock: boolean;
  }) => {
    setHasStoreStock(hasStoreStock);
    if (hasStoreStock) {
      const storeInventoryButton = document.querySelector(
        ".store-inventory-button"
      );
      if (storeInventoryButton) {
        storeInventoryButton.setAttribute("style", "display: block;");
      }
    }
    setProduct(product);
  };

  useEffect(() => {
    if (code && !hasStoreStock) {
      getProductWithStoreStock({ code, callback });
    }
  }, [code, hasStoreStock]);

  return { hasStoreStock, product };
};

export { getProductWithStoreStock, useProductHasAnyStoreInventory };
