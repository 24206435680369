import $ from "jquery";
import Cookie from "js-cookie";
import "slick-carousel";
import { alignMobileMenuTopToHeader } from "./header";

class TextBanner {
  constructor() {
    this.initTextBanner();
    this.bindEvents();
  }

  initTextBanner() {
    const isRollingTextHidden = Cookie.get("isRollingTextHidden");
    if (isRollingTextHidden === "true") {
      return;
    }

    this.initSlick();
  }

  initSlick() {
    $(".text-banner").slick({
      vertical: true,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 600
    });
  }

  bindEvents() {
    $(".text-banner-container .close-button").on(
      "click",
      this.handleClickCloseBtn.bind(this)
    );
  }

  handleClickCloseBtn(event) {
    const $target = $(event.currentTarget);
    Cookie.set("isRollingTextHidden", "true");

    const $textBannerContainer = $target.closest(".text-banner-container");
    $textBannerContainer.animate({ height: 0 }, 300, () => {
      $("body").addClass("without-text-banner");
      alignMobileMenuTopToHeader();
    });

    $(".text-banner").slick("slickPause");
  }
}

export default TextBanner;
