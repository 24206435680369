import PropTypes from "prop-types";
import React from "react";
import { BaseInput } from "./Base";

// Input is a replacement of default <input /> component.
// In order to pass the changed value to the onChange
// callback instead of the event object.
class Input extends BaseInput {
  render() {
    const { value, ...props } = this.props;

    return <input {...props} value={value} onChange={this.onChange} />;
  }
}

Input.defaultProps = {
  ...BaseInput.defaultProps,
  type: "text"
};

Input.propTypes = {
  ...BaseInput.propTypes,
  type: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string
};

export default Input;
