import $ from "jquery";

// hero banner or video banner closest to the header can set the theme.
const validThemeContainerInOnline = $("#header-container + div");

export function setHeaderColor(container, isWhite) {
  let validThemeContainer = validThemeContainerInOnline;
  if (validThemeContainer.length === 0) {
    // get validThemeContainerInCMS every time since the context is updated
    validThemeContainer = $("#header-container + main>div:first-child");
  }
  if (
    validThemeContainer.length !== 0 &&
    container &&
    container.closest(validThemeContainer).length !== 0
  ) {
    $("#header-container .header").toggleClass("header--white-text", isWhite);
  }
}
