import React from "react";
import { ItemTel, DirectionIcon } from "./StoreItemSvgs";

import { theplant } from "../../proto";
type IStoreInfoWithStock = theplant.ec.service.stores.IStoreInfoWithStock;

export interface StoreItemProps<T = React.ReactInstance>
  extends React.RefAttributes<T> {
  store: IStoreInfoWithStock;
  isSelected?: boolean;
  phoneButtonClick?: () => void;
  mapButtonClick?: () => void;
}

const ChevronDown = () => (
  <svg viewBox="0 0 6.557 11.707">
    <path
      stroke="#797979"
      transform="rotate(90 3.2385 5.8345)"
      fill="none"
      d="M.354 11.354l5.5-5.5l-5.5-5.5"
      strokeLinejoin="miter"
    />
  </svg>
);

const StockText = ({ stock }: { stock: number }) => (
  <div className="stock-wrapper">
    {stock > 0 ? (
      stock >= 2 ? (
        <span className="few-stock">在庫あり</span>
      ) : stock >= 1 ? (
        <span className="has-stock">残りわずか</span>
      ) : null
    ) : (
      <span className="out-of-stock">out of stock</span>
    )}
  </div>
);

const StoreContent = ({ store }: { store: StoreItemProps["store"] }) => (
  <div className="content-wrapper">
    {(store.zip || store.address) && <p>{`〒${store.zip} ${store.address}`}</p>}
    {store.businessHours && <p>{"営業時間 " + store.businessHours}</p>}
  </div>
);

const StoreItem = ({
  store,
  isSelected,
  phoneButtonClick,
  mapButtonClick,
}: StoreItemProps) => {
  const [show, setShow] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const onClickPhone = React.useCallback(
    (e) => {
      // prevent click phone will bubble up to close toggle effect
      e.preventDefault();
      e.stopPropagation();
      window.location.href = `tel: ${store.phone}`;
      if (phoneButtonClick) {
        phoneButtonClick();
      }
    },
    [store, phoneButtonClick]
  );

  const onClickMap = React.useCallback(
    (e) => {
      // prevent click phone will bubble up to close toggle effect
      e.preventDefault();
      e.stopPropagation();
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${store.latitude},${store.longitude}`,
        "_blank"
      );

      if (mapButtonClick) {
        mapButtonClick();
      }
    },
    [store, mapButtonClick]
  );

  const toggle = React.useCallback(() => {
    setShow(!show);
  }, [show]);

  React.useEffect(() => {
    if (show) {
      const { current } = divRef;
      if (divRef && current) {
        setTimeout(() => {
          current.scrollIntoView({ behavior: "smooth" });
        }, 340); // 0.6s ease-in-out, 0.6*0.58=340ms
      }
    }
  }, [show]);

  return (
    <li
      className={`store-item-with-stock ${isSelected ? "selected" : ""} ${
        show ? "opened" : ""
      }`}
      onClick={toggle}
    >
      <div className="store-item-wrapper" ref={divRef}>
        <div className="name-and-stock-wrapper">
          {store.name && <h3 className="shop-name">{store.name}</h3>}
          <StockText stock={Number(store.stock || 0)} />
        </div>
        <div className={`content-and-button-wrapper ${show ? "opened" : ""}`}>
          <StoreContent store={store} />
          <div className="button-wrapper">
            {store.phone && (
              <button className="outline-btn inline" onClick={onClickPhone}>
                <ItemTel />
                {store.phone}
              </button>
            )}
            {Boolean(store.latitude && store.longitude) && (
              <button className="outline-btn inline" onClick={onClickMap}>
                <DirectionIcon />
                アクセス
              </button>
            )}
          </div>
        </div>
      </div>
      <ChevronDown />
    </li>
  );
};

export { StoreItem };
