import React, { useMemo, useState, useEffect } from "react";

interface IShopRegionProps {
  updateFilter: (newFilter: string) => void;
}

export const allRegionKey = "すべて";

const ShopRegion = ({ updateFilter }: IShopRegionProps) => {
  // default all
  const [selected, setSelected] = useState<string>(allRegionKey);

  const [regionList, setRegionList] = useState(null);

  const toggleSelected = (item: string) => {
    if (selected === item) return;

    updateFilter(item);
    setSelected(item);
  };

  useEffect(() => {
    fetch("/prefectures")
      .then((res) => res.json())
      .then((response) => {
        setRegionList(response);
      });
  }, []);

  const regionOptionList = useMemo(() => {
    if (!regionList) return [];
    return [{ name: allRegionKey }, ...regionList].map((item) => ({
      value: item.name,
      // show name use ・ ; value use /
      name: item.name.replace(/／/g, "・"),
    }));
  }, [regionList]);

  return (
    <div className="shop-region-wrap">
      {regionOptionList.map((item) => {
        return (
          <button
            className={`new-primary-btn new-primary-btn__default ${
              selected === item.value ? "shop-region__active" : ""
            }`}
            key={item.value}
            onClick={() => toggleSelected(item.value)}
          >
            {item.name}
          </button>
        );
      })}
    </div>
  );
};

export default ShopRegion;
