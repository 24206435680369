import React from "react";
import { withContext } from "@theplant/ecjs/context";
import { theplant } from "../../proto";
import { renderIssuerIcon } from "../../components/shared/CreditCardIssuer";
type ICreditCard = theplant.ec.service.orders.ICreditCard;

const Item = ({
  brand,
  number,
  expiryDate,
  name,
  selected,
  onClick,
  className,
}: ICreditCard & {
  selected?: boolean;
  onClick?: (e: any) => void;
  className?: string;
}) => {
  return (
    <>
      <div
        className={`credit-card-item ${className ? className : ""}`}
        data-selected={selected}
        onClick={onClick}
      >
        {renderIssuerIcon(brand)}
        <div className="text-wrapper">
          <p className="large">
            {brand} {number}
          </p>
          <p className="small">
            {name} {expiryDate}
          </p>
        </div>
      </div>
    </>
  );
};

const CreditCardItem = withContext(Item);

export { CreditCardItem, Item };
