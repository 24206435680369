import $ from "jquery";
import * as paths from "../constants/Paths";
import { filterNormalApiErrors } from "../Api";
import { confirm } from "../js/shared/alert";

export const scorePassword = function (password) {
  let score = 0;
  const upperCase = new RegExp("[A-Z]");
  const lowerCase = new RegExp("[a-z]");
  const numbers = new RegExp("[0-9]");
  const specialchars = new RegExp("([!,%,&,@,#,$,^,*,?,_,~])");

  if (password.length >= 6) score++;
  if (password.match(upperCase)) score++;
  if (password.match(lowerCase)) score++;
  if (password.match(numbers)) score++;
  if (password.match(specialchars)) score++;
  return score;
};

export const isLogin = () => {
  return window._aigleGlobal_isLogin || false;
};

// Refer about the authboss redirection here: https://github.com/go-authboss/authboss#-redirecting-after-authboss-routes
const _goToLoginPage = () => {
  window.location = `${paths.LOGIN_PATH}?redir=${window.location.pathname}`;
};

export const requireAuth = (callback = () => { }) => {
  isLogin() ? callback() : _goToLoginPage();
};

// parseJSONHandlerFuncResponseForAjax accepts the responseData from the jQuery.Ajax
// function. And returns a object that contains:
// * `error`: a error object that returned by jsonhandlerfunc
// * `response`: an array of response objects that returned by jsonhandlerfunc
// * `errorMessages`: an array of string for displaying
export const parseJSONHandlerFuncResponseForAjax = (responseData = "") => {
  try {
    const json = JSON.parse(responseData);
    const error = json.results[json.results.length - 1];
    const response = json.results.slice(0, json.results.length - 1);
    const errorMessages = filterNormalApiErrors(error);
    return { error, response, errorMessages };
  } catch (e) {
    return {
      error: { error: `parse response failed: ${e}`, value: {} },
      errorMessages: ["内部サーバーエラー"]
    };
  }
};

export const requireConfirm = () => confirm("本当によろしいですか？");

export const didLoadedReactApp = () => {
  $(".js-react-app-loading").remove();
};
