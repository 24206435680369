import * as React from "react";

import { scrollToFirstError } from "../shared/scrollToFirstError";

import { connect } from "react-redux";
import { refresh as refreshProfile } from "../../actions/profile";

import { withRouter, RouteComponentProps } from "react-router-dom";

import {
  CHECKOUT_REGISTER_PATH,
  CHECKOUT_SHIPPING_PATH,
} from "../../constants/Paths";

import { CheckoutProps } from "../.";
import { Input } from "../shared/Input";

import { usersForm } from "../../orders";
import { Form } from "../../form";

import { theplant } from "../../proto";
import {
  UploadCheckoutEmail,
  UploadCheckoutEventDataToGTM,
} from "../../gtm/GA";
import { AmazonPayButton } from "../shared/AmazonPayButton";

import GA4PageView from "../ga4PageView";

const loginForm = Form.on(usersForm, "loginForm");
const emailForm = Form.on(loginForm, "email");
const passwordForm = Form.on(loginForm, "password");
type IOrder = theplant.ec.service.orders.IOrder;

const buttonOnclickToGTM =
  (cart: IOrder, eventName: string, checkoutType: string) => () => {
    UploadCheckoutEventDataToGTM({ order: cart, eventName, checkoutType });
  };

class LoginView extends React.Component<
  RouteComponentProps<{}> &
    CheckoutProps & {
      refreshProfile: () => any;
    }
> {
  componentDidMount() {
    window.scrollTo(0, 0);

    GA4PageView();
  }

  componentWillUnmount() {
    // set isEmailExists to false, so when user back to this page can start from input email
    this.props.users.reset();
  }

  loginAndRedirectToShippingPage = () => {
    const {
      input: {
        loginForm: { email, password },
      },
      login,
    } = this.props.users;

    login(email || "", password || "").then((user) => {
      if (user) {
        this.props.history.replace(CHECKOUT_SHIPPING_PATH);
        // fetch profile data when user logged in to refresh the AccountPopup App
        this.props.refreshProfile();
        // fetch cart, reset the isGuestUser flag
        this.props.cart.fetchCart();

        // For server side render pages, it'll put a `user` on the window when the user logged in.
        // So it adds the user to window to make it consistent with SSR pages.
        // It makes the `silveregg` tag can access to the user memberNumber when entering thank you page
        (window as any).user = {
          memberNumber: user.customer && user.customer.memberNumber,
        };
        return;
      }
      scrollToFirstError();
    });
  };

  // guest user flow: if email doesn't exist redirect to Shipping page, remember user email
  checkEmailIsExisted = () => {
    const {
      input: {
        loginForm: { email },
      },
      checkEmail,
    } = this.props.users;

    checkEmail(email || "").then((isEmailExists) => {
      if (typeof isEmailExists === "boolean" && !isEmailExists) {
        this.props.history.replace(CHECKOUT_SHIPPING_PATH);
        return;
      }
      scrollToFirstError();
    });

    if (email !== "") {
      UploadCheckoutEmail();
    }
  };

  render() {
    return (
      <div className="login-screen layout-content">
        <div className="row padding-md">
          <div className="col span-3"></div>
          <div className="col span-6">
            <div className="white-panel">
              <h2 className="login-title">ログイン</h2>
              <div className="section">
                <p className="title mb-16">ご登録済みのお客様</p>
                <emailForm.field
                  component={(props) => (
                    <Input
                      {...props}
                      label="メールアドレス"
                      placeholder="例）taro@aigle.jp"
                      type="email"
                    />
                  )}
                />
                <passwordForm.field
                  component={(props) => (
                    <Input
                      {...props}
                      label="パスワード"
                      name="password"
                      maxLength={20}
                      onKeyPressEnter={this.loginAndRedirectToShippingPage}
                      className="mt-16"
                    />
                  )}
                />

                <div className="forget-password">
                  <a href="/auth/recover">パスワードを忘れた方はこちら</a>
                </div>

                <button
                  type="button"
                  className="primary-btn"
                  onClick={this.loginAndRedirectToShippingPage}
                >
                  ログイン
                </button>
              </div>
            </div>
            <div className="white-panel">
              <h2 className="register-title">新規会員登録</h2>
              <div className="section">
                <p className="title">
                  ご入会いただくと、ポイントが貯められたり、会員限定セールなどの情報をいち早く入手できます。
                </p>

                <button
                  type="button"
                  className="primary-btn"
                  onClick={() => {
                    this.props.history.replace(CHECKOUT_REGISTER_PATH);
                  }}
                >
                  新規アカウントを作成
                </button>

                <button
                  className="link"
                  onClick={() => {
                    this.props.history.replace(CHECKOUT_SHIPPING_PATH);
                  }}
                >
                  登録せずに購入
                </button>
              </div>
            </div>
            <div className="white-panel">
              <div className="section">
                <p className="title">Amazonアカウントで購入</p>
                <div
                  className="mb-15"
                  onClick={buttonOnclickToGTM(
                    this.props.cart.cart || {},
                    "begin_checkout",
                    "amazon-pay"
                  )}
                >
                  <AmazonPayButton />
                </div>
                <p className="desc mb-0 red">
                  Amazon
                  Payで購入される場合、ご自身以外の住所を商品のお届け先に指定されますと、ご依頼主様の情報が納品書に掲載されません。贈り物としてご自身以外にご注文される場合は、通常の決済方法よりお進みください。
                </p>
              </div>
            </div>
          </div>
          <div className="col span-3"></div>
        </div>
      </div>
    );
  }
}

const Login = withRouter(connect(null, { refreshProfile })(LoginView));

export { Login };
