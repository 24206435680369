import * as React from "react";
import TextField from "@material-ui/core/TextField";

import { Errors } from "./Errors";

import { pure } from "recompose";

import { FieldProps } from "../../form";

import { templateFormatter } from "input-format";

import { AsYouType, format } from "libphonenumber-js";
import { CloseEyeSvg, OpenEyeSvg } from "../../components/shared/svg";

const TEMPLATE = "xxx-xxxxx";
const formatZipcode = templateFormatter(TEMPLATE);

const CREDIT_CARD_NUMBER_TEMPLATE = "xxxx-xxxxx-xxxxx-xxxxx";
const formatCreditCard = templateFormatter(CREDIT_CARD_NUMBER_TEMPLATE);

const formatPhoneNumber = (value: string) => {
  const asYouType = new AsYouType("JP");
  const text = asYouType.input(value);
  return { text: text, template: asYouType.getTemplate() };
};
const formatPhoneNumberOnBlur = (handler: Function, value: string) => () =>
  handler(format(value, "JP", "National"));

const Input = pure(
  ({
    value,
    errors,
    updateInput,
    touchInput,
    dirty,
    type,
    placeholder,
    maxLength,
    onKeyPressEnter,
    autoFocus,
    msg,
    label,
    name,
    onlyNumberInput,
    style,
    inputRef,
    disabled,
    className,
    wrapperClassName,
    enableError,
  }: FieldProps<string, any> & {
    type?: "text" | "password" | "tel";
    placeholder?: string;
    maxLength?: number;
    onKeyPressEnter?: () => void;
    autoFocus?: boolean;
    msg?: string;
    label?: string;
    name: string;
    onlyNumberInput?: string;
    style?: any;
    inputRef?: any;
    enableError?: boolean;
  }) => {
    const [passwordInputType, setPasswordInputType] =
      React.useState("password");
    const isPasswordField = (name || "").indexOf("password") > -1;

    const [shrink, setShrink] = React.useState<boolean | undefined>(undefined);

    React.useEffect(() => {
      if (!!value) {
        setShrink(true);
      }
    }, [value]);

    const changeType = () => {
      setPasswordInputType(
        passwordInputType === "password" ? "text" : "password"
      );
    };

    const props = {
      autoFocus: autoFocus,
      className: wrapperClassName,
      type: isPasswordField
        ? passwordInputType
        : type
        ? type
        : name === "zipcode" || name === "credit-card" || name === "phone"
        ? "tel"
        : "text",
      placeholder: placeholder || "",
      maxLength,
      value,
      onChange: ({
        target: { value },
      }: React.ChangeEvent<HTMLInputElement>) => {
        if (value && value.length > maxLength) {
          return;
        }

        if (!updateInput) {
          return;
        }
        if (name === "phone") {
          updateInput(formatPhoneNumber(value).text);
        } else if (name === "zipcode" || name === "credit-card") {
          let text = "";
          if (name === "zipcode") {
            text = formatZipcode(value).text;
          } else if (name === "credit-card") {
            text = formatCreditCard(value).text;
          }
          const newValue =
            text.indexOf("-") > 0
              ? text
                  .split("-")
                  .filter((i) => i !== "")
                  .join("-")
              : text;
          updateInput(newValue);
        } else if (name === "point") {
          if (!isNaN(Number(value))) {
            updateInput(Number(value));
          }
        } else {
          updateInput(value);
        }
      },
      onBlur: () => {
        if (name === "phone") {
          formatPhoneNumberOnBlur(updateInput, value)();
        }
        touchInput && touchInput();
      },
      onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onlyNumberInput) {
          if (
            e.charCode < 48 || // 0
            e.charCode > 57 // 9
          ) {
            e.preventDefault();
          }
        } else if (e.key === "Enter" && onKeyPressEnter !== undefined) {
          onKeyPressEnter(e);
        }
      },
      label,
      name,
      disabled,
    };

    const hasError = dirty ? (errors || []).length > 0 : false;

    const content = (
      <TextField
        {...props}
        error={hasError || enableError}
        inputRef={inputRef}
        helperText={
          hasError && <Errors errors={dirty ? errors : []} msg={msg} />
        }
        InputLabelProps={{
          shrink:
            props.name === "email"
              ? undefined
              : (props.name || "").indexOf("name") > -1
              ? true
              : shrink || undefined,
        }}
        InputProps={{
          inputProps: {
            autoCapitalize: "none",
          },
        }}
      />
    );

    if (isPasswordField) {
      return (
        <div
          className={`${className ? className : ""} ${
            isPasswordField ? "relativePosition" : ""
          }`}
          style={style}
        >
          {content}
          {name === "password" && (
            <div className="clickToSeePassword" onClick={changeType}>
              {passwordInputType === "password" ? (
                <OpenEyeSvg />
              ) : (
                <CloseEyeSvg />
              )}
            </div>
          )}
        </div>
      );
    }

    return content;
  }
);

export { Input };
