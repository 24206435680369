import * as React from "react";

const Map = ({
  mapRef,
  visible
}: {
  mapRef: (e: HTMLDivElement) => void;
  visible: boolean;
}) => (
  <div
    className="map"
    ref={mapRef}
    style={visible ? {} : { visibility: "hidden", opacity: 0, zIndex: -1 }}
  />
);

export { Map };
