import * as React from "react";

interface RadioInputProps {
  children?: React.ReactNode;
  checked: boolean;
  onChange?: () => void;
}

const RadioInput = ({ children, checked, onChange }: RadioInputProps) => (
  <div className="radio-wrapper">
    <label className="radio-label">
      <input type="radio" checked={checked} onChange={onChange} />
      {checked ? (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12.5" r="8" stroke="black" strokeWidth="2" />
          <circle cx="12" cy="12.5" r="8" stroke="black" strokeWidth="2" />
          <circle cx="12" cy="12.5" r="5" fill="black" />
        </svg>
      ) : (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="12" cy="12.5" r="8" stroke="black" strokeWidth="2" />
          <circle cx="12" cy="12.5" r="8" stroke="black" strokeWidth="2" />
        </svg>
      )}
    </label>
    <span>{children}</span>
  </div>
);

export { RadioInput };
