import * as types from "../constants/ActionTypes";

const close = () => ({ type: types.ACCOUNT_CREDIT_CARD_FORM_CLOSE });

const changeField = (field, value) => ({
  type: types.ACCOUNT_CREDIT_CARD_FORM_CHANGE_FIELD,
  field,
  value,
});

const newCreditCard = () => ({ type: types.ACCOUNT_CREDIT_CARD_FORM_NEW });

const editCreditCard = (id) => ({
  type: types.ACCOUNT_CREDIT_CARD_FORM_EDIT,
  id,
});

const submit = (callback) => ({
  type: types.ACCOUNT_CREDIT_CARD_FORM_WILL_SUBMIT,
  callback,
});

const submitSuccess = (data) => ({
  type: types.ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT,
  data,
});

const submitFailure = (error) => ({
  type: types.ACCOUNT_CREDIT_CARD_FORM_DID_SUBMIT_FAILED,
  error,
});

export {
  close,
  changeField,
  newCreditCard,
  submit,
  submitSuccess,
  submitFailure,
  editCreditCard,
};
