import React from "react";
import { connect } from "react-redux";
import { refresh } from "../../actions/accountViewedProducts";
import { initialState } from "../../reducers/accountViewedProducts";

import ProductItem from "./Wishlist/WishItem";

const EmptyContent = (props: Props) => {
  if (props.accountViewedProducts.length > 0) {
    return null;
  }

  return (
    <div className="empty-viewed-products white-panel">
      <h2>閲覧履歴リストには何も入っていません</h2>
    </div>
  );
};

const ViewedProductsList = (props: Props) => (
  <div className="grid-row">
    {props.accountViewedProducts.map((accountViewedProduct, idx) => (
      <ProductItem key={idx} data={accountViewedProduct} />
    ))}
  </div>
);

const RecentlyViewed = (props: Props) => {
  React.useEffect(() => {
    props.refresh();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="tab-content viewed-products">
      <div className="wishlist-container">
        <EmptyContent {...props} />
        <ViewedProductsList {...props} />
      </div>
    </div>
  );
};

RecentlyViewed.defaultProps = {
  fetching: false,
  refresh: () => null,
  accountViewedProducts: [...initialState.data],
};

type Props = {
  fetching: boolean;
  refresh: () => {};
  accountViewedProducts: [];
};

const mapStateToProps = ({ accountViewedProducts }: any) => {
  return {
    accountViewedProducts: accountViewedProducts.data,
    fetching: accountViewedProducts.ui.fetching,
  };
};

export default connect(mapStateToProps, { refresh })(RecentlyViewed as any);

export { RecentlyViewed };
