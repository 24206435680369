import $ from "jquery";
import { updateProductAndVariantLabel } from "./shared/updateProductAndVariantLabel";

class ProductCard {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $(".product-card").on(
      "mouseenter",
      ".color-list li",
      this.handleMouseEnter.bind(this)
    );
    $(".product-card").on(
      "mouseenter touchstart",
      ".product-card-images-wrapper",
      this.handleMouseOverImage.bind(this)
    );
    $(".product-card").on(
      "mouseleave touchend",
      ".product-card-images-wrapper",
      this.handleMouseLeaveImage.bind(this)
    );
  }

  handleMouseOverImage(event) {
    const $target = $(event.currentTarget);
    $target.addClass("mouse-over");
  }

  handleMouseLeaveImage(event) {
    const $target = $(event.currentTarget);
    $target.removeClass("mouse-over");
  }

  handleMouseEnter(event) {
    const $target = $(event.currentTarget);
    const imgURL = $target.data("image-url");
    const secondImgURL = $target.data("second-img-src");
    const productURL = $target.find("a").attr("href");
    const price = $target.data("price");
    const rate = $target.data("rate");
    const _discountLabels = $target.data("discountlabel");
    const _discountLabelBackgroundColors = $target.data(
      "discountlabelbackgroundcolor"
    );

    const discountLabels = _discountLabels && _discountLabels.split(",");
    const discountLabelBackgroundColors =
      _discountLabelBackgroundColors &&
      _discountLabelBackgroundColors.split(",");

    updateProductAndVariantLabel(
      discountLabels,
      discountLabelBackgroundColors,
      $target.parent().parent().parent()
    );

    $target
      .parents(".product-card")
      .find(".js-current-url")
      .attr("href", productURL);
    $target
      .parents(".product-card")
      .find(".product-card-primary-image")
      .prop("src", imgURL);

    if (secondImgURL) {
      if (
        $target.parents(".product-card").find(".product-card-secondary-image")
          .length !== 0
      ) {
        $target
          .parents(".product-card")
          .find(".product-card-secondary-image")
          .prop("src", secondImgURL);
      } else {
        $(
          `<img class="product-card-secondary-image" src=${secondImgURL}/>`
        ).insertBefore(
          $target.parents(".product-card").find(".product-card-primary-image")
        );
      }
    } else {
      $target
        .parents(".product-card")
        .find(".product-card-secondary-image")
        .remove();
    }

    $target
      .parents(".product-card")
      .find(".price")
      .replaceWith(price);
    if (rate > 0) {
      $target
        .parents(".product-card")
        .find(".off-tag")
        .replaceWith('<span class="off-tag">' + rate + "% OFF</span>");
    } else {
      $target
        .parents(".product-card")
        .find(".off-tag")
        .hide();
    }
  }
}

// https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
export const lazyLoadProductCardImages = () => {
  let lazyImages = [].slice.call(
    document.querySelectorAll(".product-card img[data-src]")
  );
  let active = false;

  const lazyLoad = () => {
    if (active === false) {
      active = true;

      setTimeout(() => {
        lazyImages.forEach(lazyImage => {
          if (
            lazyImage.getBoundingClientRect().top <= window.innerHeight &&
            lazyImage.getBoundingClientRect().bottom >= 0 &&
            getComputedStyle(lazyImage).display !== "none"
          ) {
            lazyImage.src = lazyImage.dataset.src;
            lazyImage.removeAttribute("data-src");

            lazyImages = lazyImages.filter(image => image !== lazyImage);

            if (lazyImages.length === 0) {
              document.removeEventListener("scroll", lazyLoad);
              window.removeEventListener("resize", lazyLoad);
              window.removeEventListener("orientationchange", lazyLoad);
            }
          }
        });

        active = false;
      }, 200);
    }
  };

  lazyLoad();
  document.addEventListener("scroll", lazyLoad);
  window.addEventListener("resize", lazyLoad);
  window.addEventListener("orientationchange", lazyLoad);

  return lazyLoad;
};

export default ProductCard;
