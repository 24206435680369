import * as React from "react";

import { confirm } from "../../js/shared/alert";

import {
  ProductTile,
  REMOVE_FROM_CART_CONFIRMATION_MESSAGE
} from "./ProductTile";

import {
  selectItemIsRestricted,
  selectOrderHasRestrictedItems,
  CartProps
} from "../../orders";
import { isInStoreApp } from "./helper";

import { theplant } from "../../proto";
type IOrder = theplant.ec.service.orders.IOrder;

const selectOrderHasRestrictedItemsInInStoreApp = (s: IOrder | null): boolean =>
  isInStoreApp && selectOrderHasRestrictedItems(s);

const Products = ({
  cart,
  clearCart,
  updateQuantity
}: {
  cart: IOrder | null;
  clearCart: () => void;
  updateQuantity: CartProps["cart"]["updateQuantity"];
}) => (
  <div className="white-panel">
    {selectOrderHasRestrictedItemsInInStoreApp(cart) ? (
      <span className="error restricted">
        現在アプリでご購入いただけない商品「
        {cart &&
          (cart.orderItems || [])
            .filter(selectItemIsRestricted)
            .map(i => i.productName)
            .join("、")}
        」がカートに含まれています。ご注文を完了するにはこちらの商品を削除してください。
        システムエラーが疑われる場合は、お手数ですがシステム管理者にご連絡ください。
      </span>
    ) : null}

    {isInStoreApp ? (
      <button
        className="button red-theme"
        onClick={() => {
          confirm(REMOVE_FROM_CART_CONFIRMATION_MESSAGE).then(isConfirmed => {
            if (isConfirmed) {
              clearCart();
            }
          });
        }}
      >
        カートの中身を削除する
      </button>
    ) : null}

    <div className="product-tiles-wrapper">
      {cart &&
        (cart.orderItems || []).map(orderItem => (
          <ProductTile
            orderItem={orderItem}
            key={`${orderItem.articleCode}`}
            onChangeQuantity={updateQuantity}
          />
        ))}
    </div>
  </div>
);

export { Products, selectOrderHasRestrictedItemsInInStoreApp };
