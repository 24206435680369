import * as React from "react";

import { theplant } from "../../proto";

import {
  DataProps,
  selectAbilityIsEnabled,
  selectGiftWrappingFee,
  withPoints,
  PointsProps,
  OrderServiceProps,
} from "../../orders";

import { CheckboxInput } from "./CheckboxInput";
import { Errors } from "./Errors";
import Modal from "react-modal";
import { formatCurrency } from "../../components/helpers/currency";

import { checkoutForm } from "../../orders";
import { Form } from "../../form";

import { UploadGiftBox } from "../../gtm/GA";

const input = Form.on(checkoutForm, "form");
const giftWrapping = Form.on(input, "giftWrapping");

const Ability = theplant.ec.api.orders.Ability;

type IOrder = theplant.ec.service.orders.IOrder;

const getGiftWrappingFeeInSpecificPromotion = (fee?: IOrder["fee"]) => {
  let giftWrappingFeeInSpecificPromotion = null;

  if (!fee) {
    return null;
  }

  const { discountGiftWrappingFeeSpecified, discountGiftWrappingFee } = fee;

  if (discountGiftWrappingFeeSpecified) {
    // discountGiftWrappingFee is int, when it is 0, protobuf will ignore it, so need to set 0 as default in frontend
    giftWrappingFeeInSpecificPromotion = Number(discountGiftWrappingFee || 0);
  }

  return giftWrappingFeeInSpecificPromotion;
};

const GiftWrappingModal = ({
  isModalOpen,
  closeModal,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
}) => (
  <Modal
    isOpen={isModalOpen}
    contentLabel="Gift Wrapping Modal"
    overlayClassName="modal-overlay"
    className="gift-wrapping-modal modal-content-wrapper"
    onRequestClose={closeModal}
    closeTimeoutMS={300}
  >
    <div className="image-wrapper flex-column align-center">
      <img src="/img/gift-wrapping-sample.jpg" alt="gift wrapping sample" />
      <div className="desc mt-15">
        ※季節によりラッピングの仕様が異なる場合がございます。
        <br />
        ※ご注文いただいた商品をすべてまとめてラッピングいたします。
        <ul>
          <li>個別のラッピングはできません。</li>
        </ul>
        ※明細書は金額の明記されない贈答用が同梱されます。
      </div>
    </div>
  </Modal>
);

const _GiftWrapping = (
  props: {
    form: OrderServiceProps["checkout"]["input"]["form"];
    data: DataProps["data"]["data"];
    isAmazonPay?: boolean;
    cart?: IOrder | null;
    state?: OrderServiceProps["checkout"]["input"]['state']
  } & PointsProps
) => {
  const {
    isAmazonPay,
    checkoutPoints: { fetchPoints },
    data,
    cart,
    state
  } = props;
  const form = props.form;

  const fetching = state === "validating";

  const isFreeGiftWrappingFee = data && data.isFreeGiftWrappingFee;

  const giftWrappingFeeInSpecificPromotion =
    cart && getGiftWrappingFeeInSpecificPromotion(cart.fee);

  const fee = isFreeGiftWrappingFee
    ? 0
    : typeof giftWrappingFeeInSpecificPromotion === "number" // 0?0:1 => 0, so need to add this typeof validation
    ? giftWrappingFeeInSpecificPromotion
    : selectGiftWrappingFee(data);

  return selectAbilityIsEnabled(props.data, Ability.CAN_GIFT_WRAPPING) ? (
    <div className="gift-wrapping white-panel">
      <giftWrapping.field
        // inside of component depends on the state isModalOpen
        key={String(form.giftWrapping && form.giftWrapping.specified) + fetching }
        component={({ value, updateInput, touchInput, dirty, errors }) => (
          <div className="flex-wrapper">
            <CheckboxInput
              className={fetching ? `disabled`: ""}
              checked={!!value && value.specified === true}
              onChange={(checked) => {
                // fix: [LAX-5610] not allow update gift when updating
                if(fetching){
                  return;
                }
                // Need to call get points when user select or unselect the giftwrapping option on Amazon pay page.
                // For normal checkout, giftwrapping section is on the previous page of the points section, no need to get points.
                isAmazonPay &&
                  fetchPoints({
                    ...form,
                    giftWrapping: { ...form.giftWrapping, specified: checked },
                  });

                updateInput({ ...value, specified: checked });
                touchInput();

                UploadGiftBox(checked);
              }}
            >
              <span>
                ギフトラッピングを希望する （ラッピング代：
                {fee === 0 ? "無料" : formatCurrency(fee)}）
              </span>
            </CheckboxInput>
            <p>
              <Errors errors={dirty ? errors : []} />
            </p>
          </div>
        )}
      />
      <div className="image-wrapper flex-column">
        <img src="/img/gift-wrapping-sample.jpg" alt="gift wrapping sample" />
        <div className="desc mt-15">
          ※季節によりラッピングの仕様が異なる場合がございます。
          <br />
          ※ご注文いただいた商品をすべてまとめてラッピングいたします。
          <ul>
            <li>個別のラッピングはできません。</li>
          </ul>
          ※明細書は金額の明記されない贈答用が同梱されます。
        </div>
      </div>
    </div>
  ) : null;
};

const GiftWrapping = withPoints(_GiftWrapping);

export { GiftWrapping, GiftWrappingModal };
