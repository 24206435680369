import React, { useState } from "react";
import cx from "classnames";

import { CheckboxInput } from "./CheckboxInput";
import { Input } from "./Input";
import { Errors } from "./Errors";
import GenderInput from "../../components/shared/Input/GenderInput";

import { usersForm, UsersProps } from "../../orders";
import { Form, FieldProps } from "../../form";

import { ACCOUNT_PERSONAL_PATH } from "../../constants/Paths";

import BirthdayInput from "../../components/shared/Input/BirthdayInput";

import { theplant } from "../../proto";

import { registerSuccess } from "../../gtm/GA";
import { AddressForm, NamesForm } from "../Shipping/NameAndAddress";
import { scrollToFirstError } from "./scrollToFirstError";
import AddressDetail from "./AddressDetail";
import { InlineLoading } from "../Loading";

const registerForm = Form.on(usersForm, "registerForm");

const passwordForm = Form.on(registerForm, "password");
const profileForm = Form.on(registerForm, "profile");
const genderForm = Form.on(profileForm, "gender");
const dateOfBirthForm = Form.on(profileForm, "dateOfBirth");
const isTermsAndPrivacyAgreedForm = Form.on(
  registerForm,
  "isTermsAndPrivacyAgreed"
);
const addressForm = Form.on(profileForm, "billingAddress");

type IAddress = theplant.ec.service.users.IAddress;

const SelectAllOptions = (
  props: FieldProps<theplant.ec.service.users.IProfile, {}>
) => {
  React.useEffect(() => {
    const { updateInput, value } = props;
    updateInput({
      ...value,
      isSubscribed: true,
      dmByMail: true,
      dmBySms: true,
      dmByPhone: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const Subscription = ({ noWhitePanel }: { noWhitePanel?: boolean }) => (
  <>
    <div
      className={`subscription-wrapper ${noWhitePanel ? "" : "white-panel"}`}
    >
      <p className="title mb-16">お知らせ受取</p>
      <p className="desc">
        新着アイテム、会員限定セール、特別イベントへの招待状などを、いち早くお届けします。
        <span className="desc__red">
          <br />
          初回購入時、メールマガジン配信希望の方に2000ポイントプレゼント！
        </span>
      </p>
      <div className="grid-row-3">
        <profileForm.field
          component={
            // Select all options by default.
            // (It will throw an error 'React Hook "React.useEffect" cannot be called inside a callback'
            // if use React hooks: React.useEffect )
            SelectAllOptions
          }
        />

        <profileForm.on
          field="isSubscribed"
          component={({ value, updateInput, touchInput, errors }) => (
            <>
              <CheckboxInput
                checked={value}
                name="is_subscribed_field"
                onChange={(checked) => {
                  updateInput(checked);
                  touchInput();
                }}
              >
                メールマガジンを受け取る
              </CheckboxInput>
              <Errors errors={errors} />
            </>
          )}
        />
        <profileForm.on
          field="dmByMail"
          component={({ value, updateInput, touchInput, errors }) => (
            <>
              <CheckboxInput
                name="dm_by_sms_field"
                checked={value}
                onChange={(checked) => {
                  updateInput(checked);
                  touchInput();
                }}
              >
                ハガキを受け取る
              </CheckboxInput>
              <Errors errors={errors} />
            </>
          )}
        />
        <profileForm.on
          field="dmBySms"
          component={({ value, updateInput, errors }) => (
            <>
              <CheckboxInput
                checked={value}
                onChange={updateInput}
                name="dm_by_sms_field"
              >
                SMSを受け取る
              </CheckboxInput>
              <Errors errors={errors} />
            </>
          )}
        />
        <profileForm.on
          field="dmByPhone"
          component={({ value, updateInput, errors }) => (
            <>
              <CheckboxInput
                checked={value}
                onChange={updateInput}
                name="dm_by_phone_field"
                className="col span-4 hidden"
              >
                Phoneで受け取る
              </CheckboxInput>
              <Errors errors={errors} />
            </>
          )}
        />
      </div>
    </div>
    <div
      className={`agree-condition-wrapper ${noWhitePanel ? "" : "white-panel"}`}
    >
      <p className="title">個人情報の取り扱いについて</p>
      <p className="desc">
        当社が収集する情報の利用方法等について、プライバシーポリシーを定めております。AIGLEオンラインショップをご利用されるにあたり、プライバシーポリシーをご確認のうえ「同意する」をチェックしてください。
      </p>
      <isTermsAndPrivacyAgreedForm.field
        component={({ value, touchInput, updateInput, errors }) => (
          <div>
            <CheckboxInput
              checked={value || false}
              name="is_terms_and_privacy_agreed"
              onChange={(checked) => {
                updateInput(checked);
                touchInput();
              }}
            >
              同意する
            </CheckboxInput>
            <Errors errors={errors || []} />
          </div>
        )}
      />
      <div className="term-and-condition-error-container"></div>
    </div>
  </>
);

const BirthdayAndGender = () => (
  <div className="birthday-and-gender-panel grid-row">
    <dateOfBirthForm.field
      component={(props) => (
        <BirthdayInput
          value={props.value}
          onChange={(value: string) => {
            props.updateInput(value);
            props.touchInput();
          }}
          errors={props.errors}
          newdesign
        />
      )}
    />

    <genderForm.field
      component={({ value, updateInput, errors }) => (
        <GenderInput
          name="gender_field"
          value={value}
          onChange={updateInput}
          errors={errors}
          newdesign
        />
      )}
    />
  </div>
);

const RegisterForm = (props: {
  token?: string | null;
  orderCode: string;
  address?: IAddress | null;
  email?: string | null;
  register: UsersProps["users"]["register"];
  registerForm: UsersProps["users"]["input"]["registerForm"];
}) => {
  const [fetching, setFetching] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const {
    token,
    register,
    registerForm: form,
    orderCode,
    address,
    email,
  } = props;

  if (redirecting) {
    return <InlineLoading />;
  }

  if (!token) {
    return null;
  }

  return (
    <div className="checkout-form section-wrapper">
      <registerForm.on
        field="profile"
        component={({ value: profileValue, updateInput }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [edit, setEdit] = React.useState(false);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          React.useEffect(() => {
            // Default check all subscription options
            let value = {
              ...profileValue,
              isSubscribed: true,
              dmByMail: true,
              dmBySms: true,
              dmByPhone: true,
              billingAddress: address,
            };

            updateInput(value);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, []);

          return (
            <div className="white-panel">
              <p className="title">登録情報</p>
              {edit ? (
                <div className="name-wrapper">
                  <NamesForm form={addressForm} />
                </div>
              ) : (
                <>
                  <div className="names-panel flex-wrapper">
                    <AddressDetail address={address} />
                    <button
                      className="outline-btn edit-btn"
                      onClick={() => setEdit(true)}
                    >
                      変更する
                    </button>
                  </div>
                  <hr />
                </>
              )}
              <BirthdayAndGender />
              {edit && <AddressForm form={addressForm} />}
              <div className="grid-row-2-only email-and-password-panel">
                <Input
                  {...props}
                  value={email}
                  type="email"
                  placeholder="Eメールを入力"
                  label="登録用メールアドレス"
                  disabled
                />
                <passwordForm.field
                  component={(props) => (
                    <>
                      <Input
                        {...props}
                        type="password"
                        placeholder="例） Aiglepass123"
                        maxLength={20}
                        label="パスワード"
                        name="password"
                      />
                      <Input
                        {...props}
                        type="password"
                        name="confirm_password"
                        label="新しいパスワード"
                        placeholder="パスワードを入力"
                        className="confirm-password"
                      />
                    </>
                  )}
                />
              </div>
              <Subscription noWhitePanel />
            </div>
          );
        }}
      />

      <div className="form-group btn-wrapper">
        <button
          type="button"
          className={cx("primary-btn", { disabled: fetching })}
          onClick={() => {
            if (fetching) {
              return;
            }

            // disable the button
            setFetching(true);

            register({
              ...form,
              passwordConfirmation: form.password,
              token,
              orderCode,
            })
              .then((isRegistered) => {
                setFetching(false);

                if (typeof isRegistered === "boolean" && isRegistered) {
                  // send event to GTM, to
                  registerSuccess();

                  setRedirecting(true);

                  (window as any).location = ACCOUNT_PERSONAL_PATH;
                } else {
                  setFetching(false);
                  scrollToFirstError();
                }
              })
              .catch(() => {
                setFetching(false);
                scrollToFirstError();
              });
          }}
        >
          登録する
        </button>
      </div>
      <registerForm.on
        field="token"
        component={({ errors }) => <Errors errors={errors} />}
      />
    </div>
  );
};

export { RegisterForm, Subscription, BirthdayAndGender };
