import React from "react";
import { toCamel } from "../../../../product/dataTransfer";
import AddressDetail, {
  IAddress,
} from "../../../../CheckoutApp/shared/AddressDetail";
import { PanelTitle } from "../PanelTitle";
import { ItemCard } from "../Common/ItemCard";
import Popup from "../../../molecules/Popup";

const AddressInfo = ({
  address,
  onEdit,
  onDelete,
  disabledDelete,
  selectAddressAsDefault,
  fetching,
}: Pick<Props, "selectAddressAsDefault"> & {
  address: AddressProps;
  onEdit: () => void;
  onDelete: (cb: () => void) => void;
  disabledDelete: boolean;
  fetching: boolean;
}) => {
  const { is_default } = address;

  const [openModal, setOpenModal] = React.useState(false);

  const close = () => {
    setOpenModal(false);
  };

  const open = () => {
    setOpenModal(true);
  };

  return (
    <>
      <ItemCard
        fetching={fetching}
        is_default={is_default}
        selectedLabel={"通常の配送先"}
        onClick={() => {
          if (is_default) {
            return;
          } else {
            selectAddressAsDefault({
              ...address,
              is_default: !is_default,
            });
          }
        }}
        onEdit={onEdit}
        disabled={disabledDelete}
        onDelete={open}
        content={
          <AddressDetail address={toCamel(address) as IAddress} noDetailName />
        }
      />
      <Popup
        isOpen={openModal}
        close={close}
        isConfirmPopup
        onConfirm={() => {
          onDelete(close);
        }}
        confirmText="削除する"
        title="配送先住所の削除確認"
        confirmTitle="この配送先住所を削除してもよろしいですか？"
      >
        <ItemCard
          className="content-in-popup"
          content={
            <AddressDetail
              address={toCamel(address) as IAddress}
              noDetailName
            />
          }
        />
      </Popup>
    </>
  );
};

const Addresses = (props: Props) =>
  (props.shippingAddresses || []).length > 0 ? (
    <div className="address-list white-panel">
      <PanelTitle htmlFor="addresses" title="登録した配送先住所" />
      <div className="grid-row grid-gap">
        {(props.shippingAddresses || []).map((address, idx) => (
          <AddressInfo
            key={idx}
            address={address}
            onEdit={() => {
              props.editAddress(address.id);
              if (props.openEditForm) {
                props.openEditForm();
              }
            }}
            onDelete={(cb) => props.deleteAddress(address.id, cb)}
            disabledDelete={props.shippingAddresses.length === 1}
            selectAddressAsDefault={props.selectAddressAsDefault}
            fetching={props.fetching}
          />
        ))}
      </div>
    </div>
  ) : null;

type AddressProps = { id: string; is_default?: boolean };

type Props = {
  shippingAddresses: AddressProps[];
  deleteAddress: (id: string, callback: () => void) => {};
  editAddress: (id: string) => {};
  selectAddressAsDefault: (address: AddressProps) => {};
  fetching: boolean;
  openEditForm?: () => void;
};

export default Addresses;

export { AddressInfo };
