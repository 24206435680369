import $ from "jquery";
import { insertBeforeViewedProductsTo, animatedInsertAfter } from "./pdp";
import ProductCard, { lazyLoadProductCardImages } from "./productCard";
import Recommendations from "./recommendations";
import {
  UploadProductCardData,
  UploadRecommendsData
} from "../gtm/uploadDataComponent";

class TopPage {
  constructor() {
    if (window.location.pathname !== "/") return;

    this.insertPopularProductsAndViewedProducts();
    insertBeforeViewedProductsTo("footer");
    this.emitDataToBoost();
  }

  emitDataToBoost() {
    const be = window.be;
    if (be) {
      be.emit("ecView", {
        type: "home"
      });
      be.emit("ecUser", {
        user: window.user
      });
    }
  }

  insertPopularProductsAndViewedProducts() {
    $.ajax({
      url: `recom/popularity_products.json`,
      type: "GET",
      success: data => {
        try {
          const json = JSON.parse(data);

          if (json && json.results && json.results[0]) {
            const topSlider = $(".js-full-width-slider.top-page").first();
            if (topSlider.length === 0) {
              return;
            }

            animatedInsertAfter(topSlider, json.results[0]);
            // init slider
            new Recommendations();

            new ProductCard();
            lazyLoadProductCardImages();
            // init UploadRecommendsData
            new UploadRecommendsData();
            // init UplaodProductCardData
            new UploadProductCardData();
          }
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
}

export default TopPage;
