import { put, call, takeLatest } from "redux-saga/effects";

import * as actions from "../actions/cartItems";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* refresh() {
  const { response, error } = yield call(Api.getCart);

  if (error) {
    yield put(actions.refreshFailure(error));
  } else {
    yield put(actions.refreshSuccess(response.data));
  }
}

function* refreshQuantity() {
  const { response, error } = yield call(Api.getCartItemsQuantity);

  if (error) {
    yield put(actions.refreshQuantityFailure(error));
  } else {
    yield put(actions.refreshQuantitySuccess(response.data));
  }
}

function* watch() {
  yield takeLatest(types.CART_ITEMS_WILL_REFRESH, refresh);
  yield takeLatest(
    types.CART_ITEMS_TOTAL_QUANTITY_WILL_REFRESH,
    refreshQuantity
  );
}

export default watch;

export { refresh, refreshQuantity };
