import { put, call, takeLatest, all } from "redux-saga/effects";

import * as actions from "../actions/shippingAddresses";
import * as types from "../constants/ActionTypes";
import Api from "../Api";
import { confirm } from "./common";
import * as messages from "../constants/Messages";

function* refresh() {
  const { response, error } = yield call(Api.getShippingAddresses);

  if (error) {
    yield put(actions.refreshFailure(error));
  } else {
    yield put(actions.refreshSuccess(response));
  }
}

function* deleteShippingAddress({ id, cb }) {
  const confirmed = yield call(confirm, messages.ADDRESS_DELETE_CONFIRM);

  if (confirmed) {
    const { error } = yield call(Api.deleteShippingAddress, id);

    if (error) {
      yield put(actions.deleteFailure(error));
    } else {
      yield put(actions.deleteSuccess(id));
      if (cb) {
        cb();
      }
    }
  }
}

function* watch() {
  yield all([
    takeLatest(types.SHIPPING_ADDRESSES_WILL_REFRESH, refresh),
    takeLatest(types.SHIPPING_ADDRESSES_WILL_DELETE, deleteShippingAddress),
  ]);
}

export default watch;

export { refresh, deleteShippingAddress };
