import React from "react";
import { theplant } from "../../proto";

import { getTables } from "./helper";

const TableItem = ({
  titles,
  contents
}: {
  titles: Array<string>;
  contents: Array<Array<{ size: string; bold?: boolean }>>;
}) => (
  <div className="table-wrapper">
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {titles.map((title, i) => (
              <th key={title || i}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {contents.map((sizes, i) => (
            <tr key={i}>
              {sizes.map(({ size, bold }, j) =>
                bold ? (
                  <td key={size || j} className="boldTD">
                    {size}
                  </td>
                ) : (
                  <td key={size || j}>{size}</td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const specificSizeColumns = ["ラベルサイズ"];

const Table = ({
  rows
}: {
  rows: theplant.ec.service.sizeguide.ISizeGuideTableRow[];
}) => {
  const { titles, contents } = getTables({ rows, specificSizeColumns });

  return <TableItem titles={titles} contents={contents} />;
};

export default Table;
