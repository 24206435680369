import * as React from "react";

import { confirm } from "../../js/shared/alert";
import {
  selectCartItemProperty,
  selectItemIsOutOfStock,
  selectItemIsRestricted,
  CartProps,
} from "../../orders";
import { imgProps, ProductImageSize } from "../../product/productImage";

import { QuantitySelect } from "./QuantitySelect";
import { Price } from "../shared/Price";

import { isInStoreApp } from "./helper";
import { uploadRemoveFormCart } from "../../gtm/GA";

import { NotAvailable } from "../../components/shared/NotAvailable";

import { theplant } from "../../proto";
type IOrderItem = theplant.ec.service.orders.IOrderItem;

const REMOVE_FROM_CART_CONFIRMATION_MESSAGE = "本当によろしいですか？";

const selectItemIsRestrictedInInstoreApp = (orderItem: IOrderItem): boolean =>
  isInStoreApp && selectItemIsRestricted(orderItem);

const ProductTile = ({
  orderItem,
  onChangeQuantity,
}: {
  orderItem: IOrderItem;
  onChangeQuantity: CartProps["cart"]["updateQuantity"];
}) => {
  const productName = orderItem.productName;
  const colorName = selectCartItemProperty(orderItem, "ColorName");
  const japanSize = selectCartItemProperty(orderItem, "JapanSize");
  const size = selectCartItemProperty(orderItem, "Size");
  const europeSize = selectCartItemProperty(orderItem, "EuropeSize");

  const productCode = orderItem.productCode;
  const colorCode = selectCartItemProperty(orderItem, "ColorCode");
  const productPath = `/products/${productCode}/${colorCode}`;

  return (
    <div className="product-tile" data-article-code={orderItem.articleCode}>
      <figure>
        <a href={productPath}>
          <img
            {...imgProps(orderItem.productImageUrl || "", ProductImageSize.MN)}
            alt={productName || ""}
          />
        </a>
      </figure>
      <div className="product-tile_info">
        <div className="name-color-and-size">
          <h4 title={productName || ""}>
            <a href={productPath}>{productName}</a>
          </h4>
          <div className="only-mobile">
            <Price orderItem={orderItem} />
          </div>
          <p className="color">
            <span className="label">カラー：</span>
            {colorName}
          </p>
          <p className="size">
            <span className="label">サイズ：</span>
            {japanSize || europeSize || size}
          </p>
          <div className="quantity">
            {selectItemIsOutOfStock(orderItem) ||
            selectItemIsRestrictedInInstoreApp(orderItem) ? (
              <NotAvailable />
            ) : (
              <>
                <span className="label">数量：</span>
                <QuantitySelect
                  orderItem={orderItem}
                  onChange={onChangeQuantity}
                />
              </>
            )}
          </div>
        </div>
        <div className="actions-and-price">
          <div className="actions">
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                confirm(REMOVE_FROM_CART_CONFIRMATION_MESSAGE).then(
                  (isConfirmed) => {
                    if (isConfirmed && orderItem.articleCode) {
                      onChangeQuantity(orderItem.articleCode, 0);
                      const quantity = Number(orderItem.quantity);
                      uploadRemoveFormCart(orderItem, quantity);
                    }
                  }
                );
              }}
            >
              {deleteSVG}
            </a>
          </div>
          <div className="price-wrapper only-desktop">
            <Price orderItem={orderItem} />
          </div>
        </div>
      </div>
    </div>
  );
};

const deleteSVG = (
  <svg viewBox="0 0 1024 1024" width="20" height="20">
    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z" />
  </svg>
);

export { ProductTile, REMOVE_FROM_CART_CONFIRMATION_MESSAGE };
