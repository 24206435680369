import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { mapProtoOrderItemToOrderItemDataProps } from "../mappers/checkout";

class CartNumberApp extends React.Component {
  render() {
    const { cartItemNum } = this.props;

    return (
      <>{cartItemNum}</>
    );
  }
}

CartNumberApp.defaultProps = {
  cartItemNum: 0
};

CartNumberApp.propTypes = {
  cartItemNum: PropTypes.number
};

const mapStateToProps = ({ cartItems: { data } }) => {
  const cartItems = (data.order_items || []).map(
    mapProtoOrderItemToOrderItemDataProps
  );

  // only loaded quantity
  if (data.total_quantity !== undefined) {
    return {
      cartItemNum: data.total_quantity,
      loading: true
    };
  }

  return {
    cartItemNum: cartItems.reduce((sum, { quantity }) => {
      sum += quantity;
      return sum;
    }, 0)
  };
};

export default connect(
  mapStateToProps
)(CartNumberApp);
