import UAParser from "ua-parser-js";

export const searchStores = (value: string) => {
  if (!("google" in window)) return;
  let google: any = window.google;

  const googleMapsGeocoder = new google.maps.Geocoder();
  return new Promise((resolve) => {
    // @ts-ignore
    googleMapsGeocoder.geocode({ address: value }, (result, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        // > Addresses are returned in the order of best to least matches.
        //
        // So we can just use the first item in `result`.
        // Ref: https://developers.google.com/maps/documentation/javascript/geocoding#ReverseGeocoding
        resolve(result[0]);
      } else {
        resolve(undefined);
      }
    });
  });
};

export const SHOP_FORM_MAP = {
  "0": "すべて",
  "3": "ブティック",
  "1": "百貨店",
  "2": "アウトレット",
};

export const SHOP_FORM_MAP_OPTION = ["0", "3", "1", "2"].map((key) => [
  key,
  SHOP_FORM_MAP[key as keyof typeof SHOP_FORM_MAP],
]);

export const workTimeDisplay = (workTime?: string) => {
  if (!workTime) return;
  return workTime.replace(/(\d{2}:\d{2})～(\d{2}:\d{2})/g, "$1-$2");
};

export const whichBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";
  // use UAparese to help detect prod multiple browser and apps
  // refer: https://github.com/theplant/ecjs/pull/3886#discussion_r1615441657
  const browser = new UAParser(userAgent).getBrowser();

  browserName = (browser.name || "").split(" ").join("-"); // Chrome Webview to Chrome-Webview for better Url

  return browserName;
};
