import $ from "jquery";
import debounce from "lodash.debounce";

const STICKY_ELEMENT_OFFSET_TOP = 90; // 30 text-banner + 60 header

const initSticky = () => {
  $(window).off("scroll", updateStickyElementStatus);

  const windowWidth = window.innerWidth;
  // make element sticky on desktop
  if (windowWidth) {
    if(windowWidth >=768){
      updateStickyElementStatus();
      $(window).on("scroll", updateStickyElementStatus);
    } else { 
      $("#sticky-element").removeAttr("style");
      // pdp remove id #sticky-element in mobile, so select by its direct child div
      $(".pdp-info.sticky-element-wrapper>div").removeAttr("style");  
    }
  } else {
    $("#sticky-element").removeAttr("style");
  }
};

const updateStickyElementStatus = () => {
  const $stickyElement = $("#sticky-element");
  const $stickyContainer = $("#sticky-container");

  const stickyElementWrapperHeight = $stickyElement.parent().outerHeight() || 0;
  const stickyElementHeight = $stickyElement.outerHeight() || 0;
  const stickyContainerHeight = $stickyContainer.outerHeight() || 0;
  // sticky section should be shorter than its container
  if (stickyElementWrapperHeight < stickyContainerHeight) {
    const windowScrollTop = $(window).scrollTop() || 0;

    const stickyContainerOffset = $stickyContainer.offset();
    const stickyContainerOffsetTop = stickyContainerOffset
      ? stickyContainerOffset.top
      : 0;

    if (
      windowScrollTop >=
      stickyContainerOffsetTop - STICKY_ELEMENT_OFFSET_TOP
    ) {
      // sticky element reaches the bottom of its container
      const isAtBottom =
        windowScrollTop + stickyElementHeight + STICKY_ELEMENT_OFFSET_TOP >=
        stickyContainerHeight + stickyContainerOffsetTop;

      const $stickyElementParent = $stickyElement.parent();

      $stickyElement.css({
        position: isAtBottom ? "absolute" : "fixed",
        top: isAtBottom ? "auto" : STICKY_ELEMENT_OFFSET_TOP,
        bottom: isAtBottom ? 0 : "auto",
        left: $stickyElementParent.scrollLeft || 0,
        width: $stickyElementParent.width() || 0,
        // fixes safari flick when set fixed on scroll
        // refer: https://muffinman.io/blog/ios-safari-scroll-position-fixed/
        // force GPU acceleration by adding transform: translate3d(0,0,0);
        transform: isAtBottom ? "inherit" : `translate3d(0,0,0)`
      });
    } else {
      $stickyElement.removeAttr("style");
    }
  }
};

const init = () => {
  initSticky();
  $(window).on("resize", debounce(initSticky, 300));
};

const destroy = () => {
  $(window).off("resize", debounce(initSticky, 300));
  $(window).off("scroll", updateStickyElementStatus);
};

export { init, destroy };
