import React from "react";
import cx from "classnames";
import { alert } from "../../js/shared/alert";
import { Input } from "./Input";
import {
  selectAbilityIsEnabled,
  selectCannotUsePoints,
  selectNotice,
  selectIsPayingAllAmountWithPoints,
  selectCODFee,
  checkoutForm,
  withPoints,
  PointsProps,
  OrderServiceProps,
  DataProps,
  CartProps,
} from "../../orders";
import { Errors } from "./Errors";
import { Form } from "../../form";
import { theplant } from "../../proto";
import { DeleteIcon } from "./Icon";
import { Loading } from "../Loading";
type IOrder = theplant.ec.service.orders.IOrder;
type PaymentType = theplant.ec.service.orders.PaymentType;
const { PAY_ALL_WITH_POINT } = theplant.ec.service.orders.PaymentType;
const Ability = theplant.ec.api.orders.Ability;

const input = Form.on(checkoutForm, "form");
const pointsForm = Form.on(input, "points");

type Props = {
  data: DataProps["data"]["data"];
  cart: CartProps["cart"]["cart"];
  checkout: OrderServiceProps["checkout"];
  isAmazonPay?: boolean;
  disabled?: boolean;
  updating?: boolean;
  confirmAmazonPay?: boolean;
};

const getPointsUsedFromOrder = (o: IOrder | null | null) =>
  Number((o && o.pointsUsed) || 0);

interface State {
  previousPaymentType: PaymentType | null | undefined;
}

class _Points extends React.Component<Props & PointsProps, State> {
  readonly state: State = {
    previousPaymentType: null,
  };

  componentDidMount() {
    const {
      checkoutPoints: { fetchPoints },
      checkout: {
        input: { form },
      },
    } = this.props;

    fetchPoints(form);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props & PointsProps) {
    const {
      checkoutPoints: { availableUsePoint, state, fetchPoints },
      checkout: {
        clearAppliedPoints,
        input: { form },
      },
      cart,
      confirmAmazonPay,
    } = nextProps;

    if (confirmAmazonPay) {
      return;
    }

    // call fetch point with new form
    if (
      (form.giftWrapping || {}).specified !==
      (this.props.checkout.input.form.giftWrapping || {}).specified
    ) {
      fetchPoints(form);
    }

    // fix [LAX-5321] previous payment type missing
    // update previousPaymentType payment type based on props.paymentType
    // but not for PAY_ALL_WITH_POINT
    // because it need to reply on point input to cover all money
    if (
      form.paymentType !== PAY_ALL_WITH_POINT &&
      form.paymentType !== this.state.previousPaymentType
    ) {
      this.setState({ previousPaymentType: form.paymentType });
    }
    if (
      state.type !== "fetching" &&
      this.props.checkoutPoints.state.type === "fetching"
    ) {
      const maxUsablePoints = Number(
        (availableUsePoint && availableUsePoint.maximumUsablePoints) || 0
      );

      const usedPoints = Number((cart && cart.pointsUsed) || 0);

      if (usedPoints > maxUsablePoints) {
        clearAppliedPoints();

        alert("有効なポイントを入力してください。");
      }
    }
  }

  render() {
    const {
      isAmazonPay,
      data,
      cart,
      checkout: {
        specifyPaymentType,
        input: {
          form: { paymentType },
        },
      },
      checkoutPoints: {
        availableUsePoint,
        pointsInput,
        updatePointsInput,
        state,
      },
      disabled,
      updating,
    } = this.props;

    const { previousPaymentType } = this.state;

    if (selectAbilityIsEnabled(data, Ability.CAN_APPLY_POINT) === false) {
      return null;
    }

    if (selectCannotUsePoints(availableUsePoint) === true) {
      const notice = selectNotice(availableUsePoint);
      if (notice) {
        return (
          <section className="section-wrapper white-panel">
            <p>{notice}</p>
          </section>
        );
      } else {
        return null;
      }
    }

    const maxUasblePoints = Number(
      (availableUsePoint && availableUsePoint.maximumUsablePoints) || 0
    );

    const customerOwnedPoints = Number(
      (availableUsePoint && availableUsePoint.customerOwnedPoints) || 0
    );

    const usedPoints = getPointsUsedFromOrder(cart);

    if (state.type === "fetching") {
      return (
        <section
          className="section-wrapper grey-panel points"
          style={{ height: 150 }}
        >
          <Loading />
        </section>
      );
    }

    if (state.type === "error") {
      const fieldViolations = state.error.errors.fieldViolations;
      // Ref: https://github.com/theplant/ec/pull/1194/files#diff-a594960396de99be67e5d3f0124f00da
      const cannotGetPointsError =
        fieldViolations &&
        fieldViolations.find(
          ({ field, code }) => field === "Points" && code === "INVALID"
        );

      if (cannotGetPointsError) {
        return (
          <section className="section-wrapper white-panel points">
            <p>ポイントは現在ご利用いただけません。</p>
          </section>
        );
      }
    }

    return (
      <section className="section-wrapper white-panel points">
        <h4 className="section-title">ポイント</h4>

        <p className="availble-points">
          総保有ポイント：<span>{customerOwnedPoints}ポイント</span>
        </p>
        <p className="availble-points">
          利用可能ポイント：
          <span>
            {maxUasblePoints > customerOwnedPoints
              ? customerOwnedPoints
              : maxUasblePoints}
            ポイント
          </span>
        </p>
        <div className="form-input-group mt-16">
          <Input
            type="tel"
            placeholder="ポイントの入力"
            value={pointsInput || ""}
            name="point"
            updateInput={updatePointsInput}
            onlyNumberInput
            disabled={disabled}
          />

          <pointsForm.field
            // fix: [LAX-5377] pass new paymentType to apply point
            // in case switch before click applied point button
            key={String(pointsInput) + updating + disabled + paymentType}
            component={({ updateInput, touchInput }) => (
              <>
                <button
                  type="button"
                  className={cx("outline-btn", {
                    disabled:
                      disabled ||
                      !pointsInput ||
                      (pointsInput && pointsInput > maxUasblePoints) ||
                      updating,
                  })}
                  onClick={() => {
                    if (pointsInput && pointsInput <= maxUasblePoints) {
                      const isPayingAllAmountWithPoints =
                        selectIsPayingAllAmountWithPoints(
                          cart,
                          paymentType,
                          selectCODFee(data),
                          pointsInput
                        );
                      if (isPayingAllAmountWithPoints) {
                        if (isAmazonPay) {
                          alert(`
                          Amazonペイでは全額をポイントでお支払いいただくことができません。
                          全額ポイントをご利用の場合は、カートページに戻って購入手続きをしてください。
                        `);
                          return;
                        }
                        specifyPaymentType(PAY_ALL_WITH_POINT);
                        this.setState({ previousPaymentType: paymentType });
                      } else {
                        if (previousPaymentType) {
                          specifyPaymentType(previousPaymentType);
                        } else {
                          if (isAmazonPay) {
                            specifyPaymentType(
                              theplant.ec.service.orders.PaymentType.AMAZON_PAY
                            );
                          } else {
                            specifyPaymentType(
                              theplant.ec.service.orders.PaymentType.CREDIT_CARD
                            );
                          }
                        }
                        this.setState({ previousPaymentType: null });
                      }
                      updateInput(Number(pointsInput));
                      updatePointsInput(0);
                      if (!disabled) {
                        touchInput();
                      }
                    }
                  }}
                >
                  適用
                </button>
              </>
            )}
          />
        </div>

        <pointsForm.field
          component={({ dirty, errors }) => (
            <Errors errors={dirty ? errors : []} />
          )}
        />

        {usedPoints ? (
          <pointsForm.field
            key={String(usedPoints) + disabled}
            component={({ updateInput, touchInput }) => (
              <ul className="used-section">
                <li>
                  <span>ポイント利用：{usedPoints}ポイント</span>
                  <DeleteIcon
                    onClick={() => {
                      if (disabled) {
                        return;
                      }

                      const isPayingAllAmountWithPoints =
                        selectIsPayingAllAmountWithPoints(
                          cart,
                          paymentType,
                          selectCODFee(data)
                        );

                      if (isPayingAllAmountWithPoints) {
                        if (previousPaymentType) {
                          specifyPaymentType(previousPaymentType);
                        } else {
                          if (isAmazonPay) {
                            specifyPaymentType(
                              theplant.ec.service.orders.PaymentType.AMAZON_PAY
                            );
                          } else {
                            specifyPaymentType(
                              theplant.ec.service.orders.PaymentType.CREDIT_CARD
                            );
                          }
                        }
                      }
                      updateInput(0);
                      if (!disabled) {
                        touchInput();
                      }
                    }}
                  />
                </li>
              </ul>
            )}
          />
        ) : null}

        <p className="point-taxcalculation-text">
          ※ポイントご利用金額には消費税分10%が加算されて値引されます。
        </p>
      </section>
    );
  }
}

const Points = withPoints(_Points) as React.ComponentType<Props>;

export { Points };
