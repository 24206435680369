export const ROOT_PATH = "/";

export const REGISTER_PATH = "/auth/register";
export const LOGIN_PATH = "/auth/login";
export const LOGOUT_PATH = "/auth/logout";
export const RECOVER_PATH = "/auth/recover";
export const REGISTER_SUCCESS_PATH = "/authn/register-success";

export const ACCOUNT_PATH = "/account";
export const ACCOUNT_PERSONAL_PATH = "/account/personal";
export const ACCOUNT_PROFILE_PATH = "/account/profile";
export const ACCOUNT_CREDIT_CARD_PATH = "/account/credit_card";
export const ACCOUNT_ADDRESS_PATH = "/account/address";
export const ACCOUNT_WISHLIST_PATH = "/account/wishlist";
export const ACCOUNT_ORDERS_PATH = "/account/orders";
export const ACCOUNT_RECENTLY_VIEWED_PATH = "/account/viewed_products";
export const ACCOUNT_MYSTORE_PATH = "/account/mystore";
export const ACCOUNT_POINTS_PATH = "/account/points";

export const ACCOUNT_POINTS_ABOUT_PATH = "/account/points/about";
export const ACCOUNT_POINTS_HOWTO_PATH = "/account/points/howto";
export const ACCOUNT_POINTS_HISTORY_PATH = "/account/points/history";
export const ACCOUNT_POINTS_STATUS_PATH = "/account/points/status";

export const QUERY_STATE_MUST_UPDATE_PROFILE = "must-update-profile";

export const CHECKOUT_PATH = "/checkout";
export const CHECKOUT_CART_PATH = "/cart";
export const CHECKOUT_SHIPPING_PATH = "/checkout/shipping";
export const CHECKOUT_LOGIN_PATH = "/checkout/login";
export const CHECKOUT_REGISTER_PATH = "/checkout/register";
export const CHECKOUT_PAYMENT_PATH = "/checkout/payment";
export const CHECKOUT_AMAZON_PAY_PATH = "/checkout/amazon-pay";
export const CHECKOUT_COMPLETE_PAYMENT_PATH = "/checkout/complete-payment";
export const CHECKOUT_CONFIRMATION_PATH = "/checkout/thank-you";
// combini callback path
export const CHECKOUT_COMBINI_PATH = "/checkout/combini-callback";

// Refer: https://theplant.slack.com/archives/aigle/p1487413431000692
export const PRIVACY_POLICY_PATH = "/member/term#member-term-content1";
export const T_AND_C_USAGE_PATH = "/member/term#member-term-content2";

export const getMustUpdateProfilePath = (location = "") =>
  `${ACCOUNT_PERSONAL_PATH}?state=${QUERY_STATE_MUST_UPDATE_PROFILE}&redir=${location}`;
export const getCheckoutThankyouPath = (orderCode: string) =>
  `/checkout/thank-you?order_code=${orderCode}`;
