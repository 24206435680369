import { parse, format } from "url";

// Refer: https://github.com/theplant/lacoste3/issues/94#issuecomment-389102833
enum ProductImageSize {
  CA = "ca", // 68x68
  // don't support thest size in Aigle
  // CS = "cs", // 111x111
  // LT = "lt", // 230x297
  // MOBILE = "mobile", // 240x240
  // SMN = "smn", // 360x360
  MN = "mn", // 480x480
  MEDIUM = "medium", // 800x800
  ZM = "zm" // 1476x1476
}

// Refer: https://github.com/theplant/mastani/blob/master/frontend/products/productImage.ts#L18-L39
const getProductImageUrl = (
  originalImageUrl: string,
  size: ProductImageSize
): string => {
  if (!/[http(s):]?\/\//.test(originalImageUrl)) {
    return originalImageUrl;
  }

  const url = parse(originalImageUrl);

  const parts = (url.pathname && url.pathname.split(".")) || [];

  if (parts.length === 1) {
    return originalImageUrl;
  }

  const ext = parts.pop();
  parts.push(`${size}.${ext}`);
  url.pathname = parts.join(".");

  return format(url);
};

// Refer: https://github.com/theplant/mastani/blob/master/frontend/products/productImage.ts#L41-L57
const retinaSize: {
  [key: string]: [ProductImageSize, ProductImageSize];
} = {
  [ProductImageSize.MN]: [ProductImageSize.MN, ProductImageSize.MEDIUM], // cart item
  [ProductImageSize.CA]: [ProductImageSize.CA, ProductImageSize.CA]
};

// Refer: https://github.com/theplant/mastani/blob/master/frontend/products/productImage.ts#L50-L57
const imgProps = (id: string, size: ProductImageSize) => {
  const srcSet = retinaSize[size].map(s => getProductImageUrl(id, s));

  return {
    src: getProductImageUrl(id, size),
    srcSet: `${srcSet[0]} 2x, ${srcSet[1]} 3x`
  };
};

export { ProductImageSize, getProductImageUrl, imgProps };
