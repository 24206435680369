const translatePrefecture = (
  address = {},
  prefectureNameToJapaneseMap = {}
) => {
  const prefectureName = address.prefecture;
  const prefectureJapanese = prefectureNameToJapaneseMap[prefectureName];

  return { ...address, prefectureJapanese };
};

// getShippingAddresses returns a list of shipping addresses (object)
// for the given shippingAddresses state.
const getShippingAddresses = (
  { ids = [], data = {} },
  prefectureNameToJapaneseMap = {}
) => ids.map(id => translatePrefecture(data[id], prefectureNameToJapaneseMap));

export { translatePrefecture, getShippingAddresses };
