/* 
Step of complete payment
1. check if transaction id (finishOrder param)
2. check if data in local storage => to cart
3. to finishOrder (fetching) => 
    * failed: show error modal, not go back to cart, after user read the error, they need to manaully go back
    * success: to thank you page
*/

import React, { useState, useEffect } from "react";
import { CheckoutProps } from "../.";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Popup from "../AmazonPay/Popup";
import { EmptyCart } from "../Cart/EmptyCart";
import { Loading } from "../Loading";
import { pushData } from "../UploadTransactionData";
import {
  CHECKOUT_CONFIRMATION_PATH,
  CHECKOUT_CART_PATH,
} from "../../constants/Paths";
import { clearLocalStorageSaveForm } from "../shared/PrefillInputFromLocalStorage";
import { useCheckBrowserIsOrigin } from "../../components/hooks";
type ICompletePayment = CheckoutProps & RouteComponentProps<{}>;

const CompletePaymentInner = (props: ICompletePayment) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { checkout, confirmResult, history } = props;
  const state = checkout.input.state;

  const { confirm: finishOrder } = checkout;
  const finishOrderCallback = () => {
    // set loading to false
    setLoading(false);
    // clear local storage data when finish order
    // if not clear next time it will be set in checkout form
    clearLocalStorageSaveForm();
  };

  useEffect(() => {
    setLoading(true);
    finishOrder(
      (order) => {
        // the GTM trigger is listening on history change,
        // need to set the data before history change,
        // otherwise the GA Transaction Tag cannot work.
        pushData(order || {});
        finishOrderCallback();
        // avoid see empty thank-you page
        // if has order returned, go to thank-you page
        // if not has order returned, go to cart page
        if (order) {
          history.replace(CHECKOUT_CONFIRMATION_PATH);
        } else {
          history.replace(CHECKOUT_CART_PATH);
        }
      },
      () => {
        finishOrderCallback();
      },
      true // use finish order
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!!checkout.error?.fieldViolations?.length) {
    return (
      <>
        {checkout.error?.fieldViolations && (
          <Popup
            showErrorAndRedirectToCart={!loading}
            errors={
              checkout?.error?.fieldViolations?.length
                ? checkout.error.fieldViolations
                : // loading false means confirm complete
                  // and set the error as UNAVAILABLE
                  [
                    {
                      code: "UNAVAILABLE",
                      field: "Form.Checkout",
                    },
                  ]
            }
          />
        )}
        <EmptyCart />
      </>
    );
  }

  return (
    <>
      {(loading ||
        confirmResult.state.type === "fetching" ||
        state === "validating") && <Loading />}
    </>
  );
};

// fix: [LAX-5371]
// check origin browser with current broswer, if they are different
// ask user to manually go back
const CheckCompletePayent = (props: any) => {
  const { notTheSamePaymentEnv } = useCheckBrowserIsOrigin();
  if (notTheSamePaymentEnv === undefined) {
    return <Loading />;
  }

  if (notTheSamePaymentEnv) {
    return (
      <div className="wrapper flex justify-center">
        <div className="white-panel">
          <p className="desc black font-16">
            現在、デフォルトのブラウザが開かれています。
            <br />
            以前のお支払いページに戻り、ご注文の状況をご確認ください。
          </p>
        </div>
      </div>
    );
  } else {
    return <CompletePaymentInner {...props} />;
  }
};

const CompletePayment = withRouter(CheckCompletePayent);

export default CompletePayment;
