import React from "react";

const About = () => (
  <div className="content points-sub-tab points-about-sub-tab">
    <h6>AIGLE POINTとは</h6>
    <p>
      AIGLE POINTとは、AIGLE
      オンラインショップでアカウント登録いただいたお客様とAIGLE
      CLUBメンバーさまを対象としたポイントプログラムです。
      <br />
      <br />
      貯まったポイントは、AIGLE
      オンラインショップとブティックで1ポイント=1円として共通してご利用いただけます。
      <br />
      <br />
      <span>
        ※
        オンラインショップでは1ポイント単位、ブティックでは500ポイント単位でのご利用になります。
      </span>
    </p>
    <div className="text-hr">
      <p>ポイントの種類</p>
    </div>
    <p>AIGLE POINTの種類は下記の通り。</p>
    <div className="text-hr">
      <p className="with-aigle-coin">通常ポイント</p>
    </div>
    <div className="normal-coin-desc">
      <figure>
        <img src="/img/coin-huge.png" alt="coin" />
      </figure>
      <p>
        AIGLE
        オンラインショップ・ブティック・百貨店でのお買上げ額100円につき1ポイントの、仮ポイントが付与されます。ポイントの有効期間は、ポイントが確定されてから1年間です。
        <br />
        <br />
        <span>※消費税、送料、手数料は付与対象外です。</span>
        <br />
        <span>※獲得ポイントが、仮ポイント期間はご利用いただけません。</span>
      </p>
    </div>
    <div className="text-hr">
      <p className="with-aigle-coin-seasonal">期間限定ポイント</p>
    </div>
    <div className="limited-coin-desc">
      <figure>
        <img src="/img/calendar-huge.png" alt="calendar" loading="lazy" />
      </figure>
      <p>
        期間限定ポイントは、特定のキャンペーンなどで付与される、利用期間が限定されているポイントです。有効期限を過ぎると自動的に失効します。
      </p>
    </div>
    <div className="text-hr">
      <p>ポイントの状態</p>
    </div>
    <p>
      AIGLE
      オンラインショップにて付与されるポイントには仮ポイントと確定ポイントの2種類がございます。
    </p>
    <table>
      <tbody>
        <tr>
          <th>状態</th>
          <th>内容</th>
        </tr>
        <tr>
          <td>
            <p>仮ポイント</p>
          </td>
          <td>
            購入から 20
            日間は、一時的に仮ポイントの状態になります。仮ポイントは利用できません。
          </td>
        </tr>
        <tr>
          <td>
            <p>利用可能ポイント</p>
          </td>
          <td>
            仮ポイント期間経過後、自動的にご利用可能なポイントになります。
          </td>
        </tr>
        <tr>
          <td>
            <p>保有ポイント</p>
          </td>
          <td>
            仮ポイントと確定ポイントを合わせた、現在保有している合計ポイント数です。
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default About;
