import React from "react";
import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";
import PhotoSwipeLightbox from "photoswipe/dist/photoswipe-lightbox.esm.js";
//not load dynamic, load at first, otherwise click image will open another tab
import PhotoSwipe from "photoswipe/dist/photoswipe.esm.js";
import { leftArrowSVGString, rightArrowSvgString } from "./svg";
import $ from "jquery";
import { useIsMobile } from "../../components/hooks";

const PDPImageSwiper = () => {
  const isMobile = useIsMobile();

  React.useEffect(() => {
    let imageThumbs;
    if (!isMobile) {
      imageThumbs = new Swiper(".pdp-thumbs", {
        slidesPerView: "auto",
        spaceBetween: 10,
        direction: "vertical",
        autoHeight: true,
      });
    }

    new Swiper(".pdp-images", {
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      direction: "horizontal",
      modules: [Navigation, Thumbs],
      watchOverflow: true,
      simulateTouch: false,
      thumbs: {
        swiper: imageThumbs,
      },
      breakpoints: {
        767: {
          direction: "vertical",
          autoHeight: true,
        },
      },
    });

    let lightbox = new PhotoSwipeLightbox({
      gallery: "#image-gallery",
      children: "a",
      pswpModule: PhotoSwipe,
      initialZoomLevel: "fill",
      // options
      bgOpacity: 0.85,
      arrowPrevSVG: leftArrowSVGString,
      arrowNextSVG: rightArrowSvgString,
      wheelToZoom: true,
    });

    lightbox.addFilter("useContentPlaceholder", () => {
      return false;
    });

    lightbox.init();

    if (!isMobile) {
      lightbox.on("beforeOpen", () => {
        const pswp = lightbox.pswp;

        $("body").append('<div class="photoSwipe_innerthumbs"></div>');
        $("#image-gallery img").clone().appendTo("div.photoSwipe_innerthumbs");
        const thumbnailImages = $("div.photoSwipe_innerthumbs img");
        const maxHeight = `calc(100vh / ${thumbnailImages.length + 2})`;

        thumbnailImages.css({ "max-height": maxHeight });
        thumbnailImages.eq(pswp.currIndex || 0).addClass("active");

        //Handle the swaping of images
        $("body").on("click", "div.photoSwipe_innerthumbs img", function () {
          thumbnailImages.removeClass("active");
          $(this).addClass("active");
          pswp.goTo(thumbnailImages.index($(this)));
        });
      });

      lightbox.on("change", function () {
        const pswp = lightbox.pswp;

        $("div.photoSwipe_innerthumbs img").removeClass("active");
        // add new matched
        $("div.photoSwipe_innerthumbs img")
          .eq(pswp.currIndex || 0)
          .addClass("active");
      });

      lightbox.on("close", function () {
        $(".photoSwipe_innerthumbs").remove();
      });
    }

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, [isMobile]);

  return null;
};

export default PDPImageSwiper;
