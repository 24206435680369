import { put, call, select, takeLatest, all } from "redux-saga/effects";

import { getFormData } from "../reducers/accountShippingAddressForm";
import { getShippingAddressById } from "../reducers/shippingAddresses";
import * as actions from "../actions/accountShippingAddressForm";
import {
  update as updateShippingAddresses,
  refreshSuccess,
  refreshFailure,
  refresh,
} from "../actions/shippingAddresses";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* submit(parameters) {
  const formData = yield select(getFormData);
  const { response, error } = yield call(Api.postShippingAddress, formData);

  if (error) {
    yield put(actions.submitFailure(error));
  } else {
    yield put(updateShippingAddresses(response.data));
    yield put(actions.submitSuccess(response.data));

    yield put(refresh());
    if (parameters && parameters.callback) {
      yield call(parameters.callback);
    }
  }
}

function* loadForm({ id }) {
  const data = yield select(getShippingAddressById, id);
  yield put(actions.load(data));
}

function* selectAddressAsDefault({ address }) {
  const { response, error } = yield call(Api.postShippingAddress, address);
  const { response: fetchAddressesResponse, error: fetchAddressesError } =
    yield call(Api.getShippingAddresses);

  if (error || fetchAddressesError) {
    yield put(refreshFailure(fetchAddressesError));
    yield put(actions.selectAddressAsDefaultFailed(error));
  } else {
    if (response && fetchAddressesResponse) {
      yield put(refreshSuccess(fetchAddressesResponse));
      yield put(actions.selectAddressAsDefaultSuccess(response.data));
    }
  }
}

function* watch() {
  yield all([
    takeLatest(types.ACCOUNT_SHIPPING_ADDRESS_FORM_WILL_SUBMIT, submit),
    takeLatest(types.ACCOUNT_SHIPPING_ADDRESS_FORM_EDIT, loadForm),
    takeLatest(
      types.ACCOUNT_SHIPPING_ADDRESS_WILL_SELECT_AS_DEFAULT,
      selectAddressAsDefault
    ),
  ]);
}

export default watch;

export { submit, loadForm };
