import React from "react";
import { Form } from "../../form";
import { checkoutForm } from "../../orders";
import { Input } from "../shared/Input";
import { proto } from "../../proto";
import { Errors } from "../shared/Errors";
const input = Form.on(checkoutForm, "form");

const Email = ({
  isGuestUser,
  emailExistError,
}: {
  isGuestUser?: boolean | null;
  emailExistError?: proto.ValidationError.IFieldViolation[];
}) =>
  isGuestUser ? (
    <input.on
      field="email"
      key={JSON.stringify(emailExistError)}
      component={(props) => (
        <>
          <Input
            {...props}
            label="メールアドレス"
            type="email"
            placeholder="例）taro@aigle.jp"
            errors={(props.errors || []).slice(0, 1)}
          />
          {emailExistError && <Errors errors={emailExistError} />}
        </>
      )}
    />
  ) : null;

export { Email };
