import { ADDRESS_TYPE_SHIPPING, ADDRESS_TYPE_BILLING } from "./Misc";

export const CHECKOUT_ADDRESS_POPUP_TITLE_MAP = {
  [ADDRESS_TYPE_SHIPPING]: "お届け先住所",
  [ADDRESS_TYPE_BILLING]: "請求書先住所",
};

export const LOGOUT = "ログアウト";

export const ACCOUNT_PERSONAL = "個人情報";
export const ACCOUNT_PROFILE = "メールアドレス・パスワードの変更";
export const ACCOUNT_ADDRESS = "配送先住所の変更・追加";
export const ACCOUNT_CREDIT_CARD = "クレジットカード情報の変更・追加";
export const ACCOUNT_WISHLIST = "お気に入り商品一覧";
export const ACCOUNT_WISHLIST__MOBILE = "お気に入り商品";
export const ACCOUNT_ORDERS = "購入履歴";
export const ACCOUNT_POINTS = "ポイント履歴";
export const ACCOUNT_RECENTLY_VIEWED = "閲覧履歴";
export const ACCOUNT_MYSTORE = "登録店舗";

// Refer: https://github.com/theplant/ec/blob/master/orders/proto/spec.proto#L143-L152
export const CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP: {
  [key: string]: string | undefined;
} = {
  // 'NO_SUBITEM_TYPE': 'NO_SUBITEM_TYPE', useless value
  DELIVERY_FEE: "配送料：",
  GIFT_WRAPPING_FEE: "ギフトラッピング：",
  COD_FEE: "代引き手数料：",
  ADJUSTMENT_FEE: "裾上げ：",

  TOTAL_AMOUNT: "ご注文合計：",

  POINTS_DEDUCTION: "ポイント：",
  POINTS_TAX_DEDUCTION: "ポイント分消費税：",
  GIFT_CARD_DEDUCTION: "カードギフト：",

  // NOTE: the `PROMOTION_DISCOUNT` and `COUPON_DISCOUNT` is contained in the `TOTAL_DISCOUNT`.
  // 'PROMOTION_DISCOUNT': 'PROMOTION_DISCOUNT',
  // 'COUPON_DISCOUNT': 'COUPON_DISCOUNT',
  TOTAL_DISCOUNT: "割引合計：",

  // Extra items in the design
  QUOTE_AMOUNT: "商品の小計：",
  BILLING_AMOUNT: "合計金額 (税込)：",
};

// DRAFT = 0;　注文確認中
// CONFIRMED = 1;  注文受付済
// PAID = 2;  注文受付済
// FREEZED = 3; 倉庫へ発注済
// READY_FOR_SHIPPING = 4; 出荷可能（日通の翌営業日に出荷予定）
// SHIPPED = 5; 出荷済み
// PARTIALLY_SHIPPED = 6; 出荷済み
// CANCELED = 7; 商品キャンセル
// PARTIALLY_RETURNED = 8; 返品
// RETURNED = 9; 返品
//
// Please refer the translation texts here:
// https://qortex.com/theplant/#groups/57a2f3a78d93e32e280a46e0/entry/58b51dcc8d93e316d10a25b6/cid/58b52da88d93e316d10a4a62
export const ORDER_STATES = [
  "注文作成中",
  "注文受付中",
  "注文完了",
  "倉庫へ発注済み",
  "出荷準備完了",
  "出荷済み",
  "出荷済み",
  "全商品取消し",
  "一部返品",
  "全商品返品",
];

export const CUSTOMER_CALL_NUMBER = "0120-810-378";

export const CHECKOUT = "ご注文を確定する";

export const CHECKOUT_AGREEMENT =
  "ご注文を確定すると、お客様は当サイトのプライバシー規約および利用規約に同意の上、商品を購入されたことになります。";
