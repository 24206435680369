import { uploadPLPToGA } from "../GA";
import { toCamelCase, getStartIndex } from "./helper";

const uploadPLPImpressionsData = () => {
  const $productsPage = document.querySelector(".js-product-list");

  if ($productsPage) {
    const $products = $productsPage.querySelector("div[data-tracing-source]");

    if ($products) {
      const products = $products.dataset.tracingSource;
      uploadPLPToGA({
        products: JSON.parse(products, toCamelCase),
        startIndex: getStartIndex()
      });
    }
  }
};

class UploadProductsData {
  constructor() {
    this.init();
  }

  init() {
    uploadPLPImpressionsData();
  }
}

export default UploadProductsData;

export { uploadPLPImpressionsData };
