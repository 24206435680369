import { isValidationError } from "../../Api";

export const removeFieldErrorMessages = (error = {}, field = "") => {
  if (error && isValidationError(error)) {
    let newError = { ...error };
    if (newError.value.errors[field]) {
      newError.value.errors[field] = [];
    }
    return newError;
  }
  return error;
};
