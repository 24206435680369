import $ from "jquery";
import ProductCard from "./productCard.js";
import { lazyLoadProductCardImages } from "./productCard";
import { uploadPLPImpressionsData } from "../gtm/uploadDataComponent";

class FilterProduct {
  constructor(element = ".collection.wrapper") {
    this._element = element;
    this.bindEvents();
  }

  bindEvents() {
    $(this._element).on(
      "change",
      ".js-filter-option",
      this.handleFilterOptionClick.bind(this)
    );
    $(".js-product-list").on(
      "click",
      ".js-product-pager li[data-page]",
      this.handlePagingClick.bind(this)
    );
    $(".js-reset-all-filters-button").on(
      "click",
      this.handleResetFiltersClick.bind(this)
    );
    $(".js-filter-options__main").on(
      "click",
      ".js-filter-options__group-header",
      this.handleClickFilterGroupHeader.bind(this)
    );
    $(".js-filter-options__main").on(
      "click",
      ".js-filter-options__clear-filter",
      this.handleClickResetButton.bind(this)
    );
    $(".aigle-breadcrumb").on(
      "click",
      ".js-aigle-breadcrumb__filter-clear-button",
      this.handleClickClearButtonInBreadcrumb.bind(this)
    );
    // Slide up options by default
    $(".js-filter-options__group-content").slideUp(0);
  }

  handlePagingClick(event) {
    const $target = $(event.currentTarget);
    if ($target.hasClass("active")) {
      return false;
    }
    this.handleSearchOptionsChange($target.data("page"), null, null, true);
    return false;
  }

  handleFilterOptionClick(event) {
    this.handleSearchOptionsChange(1);

    // close the sorter dropdown
    const $target = $(event.currentTarget);
    if ($target.attr("name") === "sorter") {
      $target
        .closest(".dropdown")
        .find(".js-dropdown__btn")
        .removeClass("active");
    }
  }

  handleSearchOptionsChange(
    page,
    key,
    value,
    shouldScrollTop,
    shouldClearAllOptions
  ) {
    this._FilterProductsDeferred(page, key, value, shouldClearAllOptions).done(
      (data) => {
        this._changePageContent(data);

        if (shouldClearAllOptions) {
          $(".js-filter-options").animate({ scrollTop: 0 }, 200);
        }

        if (shouldScrollTop) {
          let count = 0;
          // Safari scrolls the body, Chrome scrolls the html,
          // to avoid trigger the `lazyLoadProductCardImages` twice,
          // add a variable `count` to make sure both html and body finished scrolling
          $("html, body").animate({ scrollTop: 0 }, 300, () => {
            count++;
            if (count === 2) {
              window._PLPLazyLoad = lazyLoadProductCardImages(); // need to trigger the lazyload function manually when click grid view button in plp.js
            }
          });
        } else {
          window._PLPLazyLoad = lazyLoadProductCardImages(); // need to trigger the lazyload function manually when click grid view button in plp.js
        }
      }
    );
  }

  handleResetFiltersClick() {
    this.handleSearchOptionsChange(1, null, null, null, true);
  }

  handleClickFilterGroupHeader(event) {
    const $target = $(event.currentTarget);
    $target.toggleClass("active");
    $target.siblings(".js-filter-options__group-content").slideToggle(200);
  }

  handleClickResetButton(event) {
    const $target = $(event.currentTarget);
    this.handleSearchOptionsChange(1, $target.data("key"));
    return false;
  }

  handleClickClearButtonInBreadcrumb(event) {
    const $target = $(event.currentTarget);
    this.handleSearchOptionsChange(
      1,
      $target.data("key"),
      $target.data("value")
    );
  }

  _changePageContent(html) {
    const $nextDoc = $(html);
    const $currentDoc = $(document);

    // Remember the display state of the `js-filter-options__group-content`
    const filterOptionsContentInlineDisplayStyleMap = {};
    $currentDoc.find(".js-filter-options__group-content").each(function () {
      const key = $(this).data("key");
      filterOptionsContentInlineDisplayStyleMap[key] = $(this).css("display");
    });
    $nextDoc
      .find(".js-filter-options__main")
      .find(".js-filter-options__group-content")
      .each(function () {
        const key = $(this).data("key");
        $(this).css("display", filterOptionsContentInlineDisplayStyleMap[key]);
      });

    $currentDoc
      .find(".aigle-breadcrumb")
      .html($nextDoc.find(".aigle-breadcrumb").html());
    $currentDoc
      .find(".js-off-rate-content")
      .html($nextDoc.find(".js-off-rate-content").html());
    $currentDoc
      .find(".js-product-list")
      .html($nextDoc.find(".js-product-list").html());
    $currentDoc
      .find(".js-filter-options__main")
      .html($nextDoc.find(".js-filter-options__main").html());
    $currentDoc
      .find(".js-sorter-button")
      .html($nextDoc.find(".js-sorter-button").html());
    $currentDoc
      .find(".js-sorter-content")
      .html($nextDoc.find(".js-sorter-content").html());
    $currentDoc
      .find(".js-product-list-header")
      .html($nextDoc.find(".js-product-list-header").html());

    // this need to be done ubtil dom is updated again.
    uploadPLPImpressionsData();

    this._reBindEvents();

    if (window.be) {
      window.be.emit("ecView", {
        type: "category",
      });
    }
  }

  _reBindEvents() {
    new ProductCard();
  }

  _FilterProductsDeferred(page, key, value, shouldClearAllOptions) {
    const nextPath = this._nextSearchPath(
      page,
      key,
      value,
      shouldClearAllOptions
    );
    return $.get(nextPath).done(() => {
      if (window.history.pushState) {
        window.history.pushState({}, "", nextPath);
      }
    });
  }

  _nextSearchPath(page, key, value, shouldClearAllOptions) {
    return `${window.location.pathname}?${this._serializeToSearchQuery(
      shouldClearAllOptions ? [] : this._filtersFormValues(),
      page,
      key,
      value
    )}`;
  }

  _filtersFormValues() {
    return $(this._element).find(".js-filters-form").serializeArray();
  }

  _keyword() {
    const keyword = $(this._element).find(".js-filters-form").data("keyword");

    return String(keyword).trim();
  }

  /**
   *
   * @param {*} key the name of the filter to clear
   * @param {*} value the value of the filter of the key to clear
   */
  _serializeToSearchQuery(arr, page, key, value) {
    let keyArr = {};
    $.each(arr, (_, item) => {
      // clear all options related to the `key`
      if (key && !value && item.name === key) {
        return;
      }

      if (keyArr[item.name] == null) {
        keyArr[item.name] = [];
      }

      // clear specific `value` of the `key`
      if (key && value && item.name === key && item.value === value) {
        return;
      }

      keyArr[item.name].push(item.value);
    });

    let keyStr = {};
    $.each(keyArr, (key, value) => {
      keyStr[key] = value.join("--");
      // clear empty key
      if (!keyStr[key]) {
        delete keyStr[key];
      }
    });

    let keyword = this._keyword();
    if (keyword !== "") {
      keyStr["keyword"] = keyword;
    }

    keyStr["page"] = page;

    return $.param(keyStr);
  }
}

export default FilterProduct;
