import React, { Fragment, useCallback } from "react";
import SearchAndShopList from "../../StoreFinder/SearchAndShopList";
import { _jsonRequest as jsonRequest } from "../../../Api";

interface IProps {
  setFavStore: (newStore: any) => void;
  setShowList: (show: boolean) => void;
}
const SearchShop = ({ setFavStore, setShowList }: IProps) => {
  const addToFav = useCallback(
    (store) => {
      jsonRequest("PUT", "/account/mystore.json", store.id).then(() => {
        setFavStore(store);
        setShowList(false);
      });
    },
    [setFavStore, setShowList]
  );

  return (
    <Fragment>
      <h3>お気に入り店舗を登録</h3>
      <p className="desc">
        この画面ではお気に入りショップを変更できます。
        変更した先のお気に入りショップから素敵な特典をお知らせするメールマガジンが届きます。お気に入りショップを検索し、登録するショップを選択してください。
      </p>
      <h3 onClick={addToFav}>店舗を検索</h3>
      <SearchAndShopList addToFav={addToFav}></SearchAndShopList>
    </Fragment>
  );
};

export default SearchShop;
