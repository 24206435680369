import * as profile from "./profile";
import * as checkout from "./checkout";
import * as wishItem from "./wishItem";

// eslint-disable-next-line 
export default {
  ...profile,
  ...checkout,
  ...wishItem
};
