import React from "react";

import { RegisterApp } from "../RegisterApp";
import { CHECKOUT_SHIPPING_PATH } from "../../constants/Paths";
import { gtmDataLayerPush } from "../../gtm/index";
import { withRouter } from "react-router-dom";
import { withConfig } from "../../config";

const Register = withRouter(
  withConfig((props: any) => {
    React.useEffect(() => {
      window.scrollTo(0, 0);

      gtmDataLayerPush({
        event: "checkout",
        label: "register",
      });
    }, []);

    return (
      <div className="register-screen">
        <div id="register-app">
          <RegisterApp
            {...props}
            redirPath={CHECKOUT_SHIPPING_PATH}
            inRegisterPage={true}
          />
        </div>
      </div>
    );
  })
);

export { Register };
