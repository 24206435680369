import { pushDataForGA4, event } from "../gtm/GA4";

const GA4PageView = () => {
  const user = (window as any).user || {};

  pushDataForGA4({
    event: event.c_gtmga_page_view,
    user_data: {
      user_id: user.memberNumber || "",
      login_status: user.id ? "logged" : "not-logged",
      user_type: user.userType?.toLowerCase() || "guest",
      newsletter_optin: user.isSubScribe === "true" ? true : false,
      returning_customer: user.returningCustomer === "true" ? "yes" : "no",
      // user_email: user.email || "",
    },
  });
};

export default GA4PageView;
