import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";
// const initialState = {
//   ids: ["9099124", "13992738"],
//   data: {
//     9099124: {
//       id: "9099124",
//       customer_id: "65817",
//       brand: "visa",
//       number: "************4242",
//       expiry_date: "2029/01"
//     },
//     13992738: {
//       id: "13992738",
//       customer_id: "65817",
//       brand: "master",
//       number: "************2424",
//       name: "3",
//       expiry_date: "2028/06"
//     }
//   },
//   ui: {
//     fetching: false,
//     error: null
//   }
// }
const initialState = {
  ids: [],
  data: {},
  ui: {
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.CREDIT_CARDS_WILL_REFRESH:
    case types.CREDIT_CARDS_WILL_DELETE:
      return { ...s, fetching: true, error: null };
    case types.CREDIT_CARDS_DID_REFRESH_FAILED:
    case types.CREDIT_CARDS_DID_DELETE_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.CREDIT_CARDS_DID_REFRESH:
    case types.CREDIT_CARDS_DID_DELETE:
      return { ...s, fetching: false, error: null };
    default:
      return s;
  }
};

const ids = (s = initialState.ids, a) => {
  switch (a.type) {
    case types.CREDIT_CARDS_DID_REFRESH:
      return (a.data || []).map(r => r.id);
    case types.CREDIT_CARDS_DID_DELETE:
      let newState = s.slice();
      const index = s.indexOf(a.id);
      if (index >= 0) {
        newState.splice(index, 1);
      }
      return newState;
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.CREDIT_CARDS_DID_REFRESH:
      return (a.data || []).reduce((result, r) => {
        result[r.id] = r;
        return result;
      }, s);
    default:
      return s;
  }
};

export default combineReducers({ ui, ids, data });

const getCreditCardById = (state, id) => state.creditCards.data[id];

export { initialState, getCreditCardById };
