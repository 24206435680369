import React from "react";

const Errors = ({ className, errors, msg }: Props) =>
  (errors || []).length === 0 ? null : (
    <div className={className}>
      {msg ? (
        <span className="error">{msg}</span>
      ) : (
        errors.map((error, idx) => (
          <span key={idx} className="error">
            {JSON.stringify(error)}
          </span>
        ))
      )}
    </div>
  );

type Props = {
  className: string;
  errors: Array<string>;
  msg?: string;
};

export default Errors;
