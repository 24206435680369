import React, { useEffect } from "react";
import { useIsTablet } from "./hooks";
import { ArrowDown } from "../atoms/svgs";
import { ExpandContent, TabProps } from "./ExpandContent";

type Props = {
  tabs: TabProps[];
};

const Tabs = ({ tabs }: Props) => {
  const isDesktop = useIsTablet();
  const [current, setCurrent] = React.useState<number | undefined>(0);
  const [openTabs, setOpenTabs] = React.useState<{
    [key: string]: boolean | undefined;
  }>({});
  const desktopClickTab = (index: number) => {
    // desktop click tab will close other opened tabs
    // means only 1 tab is open
    if (index === current) {
      if (!isDesktop) {
        setCurrent(undefined);
      }
      return;
    } else {
      setCurrent(index);
    }
  };

  useEffect(() => {
    const firstTab = tabs.length > 0 && tabs[0];

    if (firstTab && firstTab.title) {
      setOpenTabs({
        [firstTab.title]: true,
      });
    }
  }, [tabs]);

  const mobileSwitchTab = (title: string) => {
    // only for mobile: Once open a tab, stay it open when other tab is clicked.
    // means: mobile tab only toggle for itself
    // use map{[title]: true/false} to track each tab
    // refer: https://theplanttokyo.atlassian.net/browse/LAX-5211?focusedCommentId=296314
    setOpenTabs({ ...openTabs, [title]: !openTabs[title] });
  };

  return isDesktop ? (
    <>
      <div className="tab">
        {tabs.map(({ title }, index) => (
          <button
            key={index}
            className={current === index ? "tablinks active" : "tablinks"}
            onClick={() => desktopClickTab(index)}
          >
            {title}
          </button>
        ))}
      </div>

      {tabs.map(({ title, content, className }, index) => (
        <ExpandContent
          key={index}
          title={title}
          maxHeight={200}
          selected={current === index}
          content={content}
          className={className}
        >
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </ExpandContent>
      ))}
    </>
  ) : (
    <div>
      {tabs.map(({ title, content, className }, index) => (
        <React.Fragment key={index}>
          <div className="tab">
            <button
              key={index}
              className={!!openTabs[title] ? "tablinks active" : "tablinks"}
              onClick={() => mobileSwitchTab(title)}
            >
              {title}
              <ArrowDown />
            </button>
          </div>
          <ExpandContent
            key={index}
            title={title}
            maxHeight={300}
            selected={openTabs[title]}
            content={content}
            className={className}
          >
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </ExpandContent>
        </React.Fragment>
      ))}
    </div>
  );
};

const _Tabs = ({ productData }: { productData: any }) => {
  const [tabs, setTabs] = React.useState<Props["tabs"]>([]);
  const getData = React.useCallback(() => {
    const materialDesc = document.querySelector(
      ".description-wrapper .material-desc"
    );
    const designDesc = document.querySelector(".design-desc");
    const sizeDesc = document.querySelector(".size-desc");
    const specifialDesc = document.querySelector(".detailMessage");
    const plpLink = document.querySelector(".pdpToPlpInterLink");

    let tabs = [];

    if (productData.description) {
      tabs.push({
        title: "説明",
        content: productData.description,
      });
    }

    if (materialDesc && materialDesc.innerHTML) {
      tabs.push({
        title: "素材",
        content: materialDesc ? materialDesc.innerHTML : "",
      });
    }

    if (designDesc && designDesc.innerHTML) {
      tabs.push({
        title: "デザイン",
        content: designDesc && designDesc.innerHTML,
      });
    }

    if (specifialDesc && specifialDesc.innerHTML) {
      tabs.push({
        title: "商品詳細",
        content:
          specifialDesc &&
          `<ul class="detailMessage">${specifialDesc.innerHTML}</ul>`,
      });
    }

    if (sizeDesc && sizeDesc.innerHTML) {
      tabs.push({
        title: "サイズ感",
        content: sizeDesc && sizeDesc.innerHTML,
      });
    }
    if (plpLink && plpLink.innerHTML) {
      tabs.push({
        title: "関連コレクション",
        content: plpLink && plpLink.innerHTML,
        className: "pdpToPlpInterLink",
      });
    }

    setTabs(tabs);
  }, [productData.description]);

  useEffect(() => {
    getData();
  }, [getData]);

  return <Tabs tabs={tabs} />;
};

export default _Tabs;
