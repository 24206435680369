import React from "react";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";

import { Cart } from "./Cart";
import { Shipping } from "./Shipping";
import { Payment } from "./Payment";
import { AmazonPay } from "./AmazonPay";
import CompletePayment from "./CompletePayment";
import { Confirmation } from "./Confirmation";
import { Login } from "./Login";

import {
  CHECKOUT_CART_PATH,
  CHECKOUT_SHIPPING_PATH,
  CHECKOUT_PAYMENT_PATH,
  CHECKOUT_AMAZON_PAY_PATH,
  CHECKOUT_CONFIRMATION_PATH,
  CHECKOUT_LOGIN_PATH,
  CHECKOUT_REGISTER_PATH,
  CHECKOUT_COMPLETE_PAYMENT_PATH,
} from "../constants/Paths";

import {
  withUsersService,
  UsersProps,
  withCartService,
  CartProps,
  withConfirmResult,
  ConfirmResultProps,
  withData,
  DataProps,
  withOrder,
  OrderServiceProps,
} from "../orders";
import { Register } from "./Register";

const ROUTES: { path: string; component: React.ComponentType<any> }[] = [
  { path: CHECKOUT_CART_PATH, component: Cart },
  { path: CHECKOUT_SHIPPING_PATH, component: Shipping },
  { path: CHECKOUT_PAYMENT_PATH, component: Payment },
  { path: CHECKOUT_AMAZON_PAY_PATH, component: AmazonPay },
  { path: CHECKOUT_COMPLETE_PAYMENT_PATH, component: CompletePayment },
  { path: CHECKOUT_CONFIRMATION_PATH, component: Confirmation },
  { path: CHECKOUT_LOGIN_PATH, component: Login },
  { path: CHECKOUT_REGISTER_PATH, component: Register },
];

export type CheckoutProps = UsersProps &
  CartProps &
  ConfirmResultProps &
  DataProps &
  OrderServiceProps;

const Checkout = (props: CheckoutProps) => {
  React.useEffect(() => {
    const menu = document.querySelector<HTMLElement>(".header__hamburger");
    if (menu) {
      if (menu.style.display !== "none") {
        menu.style.display = "none";
      }
    }
  }, []);
  return (
    <Router>
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              classNames="fade"
              timeout={{ enter: 300, exit: 0 }}
            >
              <Switch location={location}>
                {ROUTES.map((r, idx) => (
                  <Route
                    exact
                    path={r.path}
                    render={() => <r.component {...props} />}
                    key={idx}
                  />
                ))}
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />
    </Router>
  );
};

const CheckoutApp = withCartService(
  "cart",
  withConfirmResult(
    withData(withOrder("order", withUsersService("users", Checkout)))
  )
);

export { CheckoutApp };
