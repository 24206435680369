import React from "react";

import { prefectures } from "../../orders/prefectureData";
import { CheckboxInput } from "../shared/CheckboxInput";

import { Input } from "../shared/Input";
import { Selector } from "../shared/Selector";

import { Form } from "../../form";
import { checkoutForm } from "../../orders";

import { UploadEnterShippingAddress } from "../../gtm/GA";

import { theplant } from "../../proto";
type IAddress = theplant.ec.service.users.IAddress;

const input = Form.on(checkoutForm, "form");

const NamesForm = ({ form }: { form: Form<IAddress, any> }) => (
  <div className="grid-row-2-only">
    <form.on
      field="familyName"
      component={(props) => (
        <Input
          {...props}
          name="family_name"
          label="姓"
          placeholder="例）山田"
          maxLength={20}
        />
      )}
    />
    <form.on
      field="givenName"
      component={(props) => (
        <Input
          {...props}
          name="given_name"
          placeholder="例）太郎"
          maxLength={20}
          label="名"
        />
      )}
    />
    <form.on
      field="katakanaFamilyName"
      component={(props) => (
        <Input
          {...props}
          label="セイ"
          name="katakana_family_name"
          placeholder="例）ヤマダ"
          maxLength={20}
        />
      )}
    />
    <form.on
      field="katakanaGivenName"
      component={(props) => (
        <Input
          {...props}
          label="メイ"
          name="katakana_given_name"
          placeholder="例）タロウ"
          maxLength={20}
        />
      )}
    />
  </div>
);

const AddressForm = ({
  form,
  EmailField,
  addressOptional,
  registerPageUseEnglishPrefecture,
  inRegisterPage,
}: {
  form: Form<IAddress, any>;
  EmailField?: React.ReactNode;
  addressOptional?: boolean;
  registerPageUseEnglishPrefecture?: boolean;
  inRegisterPage?: boolean;
}) => {
  const [showAddress, setShowAddress] = React.useState(
    addressOptional ? false : true
  );

  return (
    <>
      {inRegisterPage && (
        <div className="grid-row grid-gap">
          <form.on
            field="phone"
            component={(props) => (
              <Input
                {...props}
                placeholder="例）012 3456 7890"
                label="電話番号"
                name="phone"
                maxLength={13}
                type="tel"
              />
            )}
          />
        </div>
      )}
      <div className="address mt-24">
        {addressOptional ? (
          <CheckboxInput
            checked={showAddress}
            onChange={() => setShowAddress(!showAddress)}
          >
            <p className="title">
              {addressOptional ? "住所を登録する" : "ご住所"}
            </p>
          </CheckboxInput>
        ) : null}

        {showAddress ? (
          <>
            <div className="grid-row-8-and-4">
              <div className="form-input-group md-margin-bottom">
                <form.on
                  field="zipcode"
                  component={(props) => (
                    <Input
                      {...props}
                      placeholder="例）150 0001"
                      type="tel"
                      label="郵便番号"
                      name="zipcode"
                    />
                  )}
                />
                <form.field
                  component={({ value, prefillFromPostalCode }) => (
                    <button
                      type="button"
                      className="outline-btn search-location-btn"
                      onClick={() =>
                        prefillFromPostalCode(form.path, value || {}, true)
                      }
                    >
                      住所検索
                    </button>
                  )}
                />
              </div>
              <div className="" />
            </div>
            <div className="grid-row">
              <form.on
                field="prefecture"
                component={(props) => (
                  <Selector
                    {...props}
                    options={prefectures}
                    name="prefecture"
                    label="都道府県"
                    placeholder={{
                      value: "",
                      coveredValue: "都道府県を選択してください。",
                      position: 0,
                    }}
                    maxLength={4}
                    registerPageUseEnglishPrefecture={
                      registerPageUseEnglishPrefecture
                    }
                  />
                )}
              />
              <form.on
                field="city"
                component={(props) => (
                  <Input
                    {...props}
                    label="市区郡町村"
                    name="city"
                    placeholder="例）渋谷区神宮前"
                    maxLength={16}
                  />
                )}
              />
            </div>
            <div
              className="grid-row mt-24"
              onClick={UploadEnterShippingAddress}
            >
              <form.on
                field="address1"
                component={(props) => (
                  <Input
                    {...props}
                    name="address1"
                    label="番地"
                    placeholder="例）1-2-3"
                    maxLength={16}
                  />
                )}
              />
              <form.on
                field="address2"
                component={(props) => (
                  <Input
                    {...props}
                    name="address2"
                    label="アパート・マンション・部屋番号"
                    placeholder="例）エーグルビル"
                    maxLength={16}
                  />
                )}
              />
            </div>
          </>
        ) : null}
        {inRegisterPage ? null : (
          <div className="grid-row mt-24">
            <form.on
              field="phone"
              component={(props) => (
                <Input
                  {...props}
                  placeholder="例）012 3456 7890"
                  label="電話番号"
                  name="phone"
                  maxLength={13}
                />
              )}
            />
            <div className="">{EmailField}</div>
          </div>
        )}
      </div>
    </>
  );
};

const NameAndAddress = ({
  form,
  EmailField,
}: {
  form: Form<IAddress, typeof input.actions>;
  EmailField?: React.ReactNode;
}) => (
  <div className="name-and-address flex-direction-row mt-24">
    <NamesForm form={form} />
    <AddressForm form={form} EmailField={EmailField} />
  </div>
);

export { NameAndAddress, NamesForm, AddressForm };
