import $ from "jquery";
import "magnific-popup";
import { getSilvereggCookie } from "../CheckoutApp/SilvereggCookieHelper";
import { DESKTOP_HEADER_BREAKPOINT } from "../constants/Misc";
import { EEaddToCart, uploadViewedProductsToGA } from "../gtm/GA";
import mappers from "../mappers";
import { toCamel } from "../product/dataTransfer";
import {
  calcPrice,
  getProductAndVariantExtraProperties,
  isVariantsOutOfStock,
} from "../product/helper";
import {
  getCategoryCode,
  getGenderName,
  getColorCode,
  getSize,
  getDiscountLabelBackgroundColors,
  getDiscountLabels,
} from "../product/productField";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";

import { parseJSONHandlerFuncResponseForAjax } from "./helper";
import { alert, confirm } from "./shared/alert";
import { updateProductAndVariantLabel } from "./shared/updateProductAndVariantLabel";
import { formatCurrency } from "../components/helpers/currency";
import { scrollToFirstError } from "../CheckoutApp/shared/scrollToFirstError";

import { UploadProductCardData } from "../gtm/uploadDataComponent";
import { initSmartpayMessaging } from "./smartpayMessage";
import { isInStoreApp } from "../CheckoutApp/Cart/helper";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

const pushSlivereggCookieId = () => {
  window.dataLayer &&
    window.dataLayer.push({ silvereggCookieId: getSilvereggCookie() });
};

class PDP {
  constructor(element = ".js-pdp-wrapper") {
    this._element = element;

    // push cookie id for sliveregg script
    pushSlivereggCookieId();

    this.insertViewedProductsSection();
    this.autoSelectSize();
    this.postDataToRecordViewedProducts();

    this.bindEvents();
    this._initialSmartpayMessage();
  }

  _initialSmartpayMessage() {
    const smartpayContainer = document.querySelector(
      ".pdp_smartpay_message_container"
    );
    if (smartpayContainer) {
      const isApply =
        smartpayContainer.dataset.apply === "true" && !isInStoreApp;
      initSmartpayMessaging(".pdp_smartpay_message_container", {
        amount: smartpayContainer.dataset.price,
        styleType: "inline",
        enable: isApply,
      });
    }
  }

  _getArticleCodeForViewedProducts() {
    return (
      $(`.js-pdp-info__size-option:checked`).val() ||
      $(`.js-pdp-info__size-option:first-child`).val()
    );
  }

  postDataToRecordViewedProducts() {
    const articleCode = this._getArticleCodeForViewedProducts();
    $.ajax({
      url: "/account/viewed-product.json",
      type: "POST",
      data: JSON.stringify({ params: [articleCode] }),
    });
  }

  insertViewedProductsSection() {
    insertBeforeViewedProductsTo(
      "footer",
      this._getArticleCodeForViewedProducts()
    );
  }

  autoSelectSize() {
    const inInventoryInputs = $(
      "input[data-quantity-in-stock=true].js-pdp-info__size-option"
    );
    if (inInventoryInputs.length === 1) {
      inInventoryInputs.attr("checked", "checked");
    }
  }

  bindEvents() {
    $(".js-mobile-pdp-info__continue-shopping").on(
      "click",
      this.handleClosePopUp.bind(this)
    );

    $(".js-add-to-cart").on("click", this.handleClickAddToCart.bind(this));

    const closeMarkup = `<button title="Close (Esc)" type="button" class="mfp-close">
      <svg class="mfp-close" xmlns="http://www.w3.org/2000/svg" width="24.04" height="24" viewBox="0 0 24.04 24">
        <path d="M30.548,9l-9.9,10.063L30.708,29.123a1.067,1.067,0,0,1,0,1.6,1.22,1.22,0,0,1-.8.319c-.319,0-.479-.16-.8-.319L19.048,20.657,8.984,30.72a1.22,1.22,0,0,1-.8.319c-.319,0-.479-.16-.8-.319a1.067,1.067,0,0,1,0-1.6L17.45,19.059,7.387,9a1.067,1.067,0,0,1,0-1.6,1.067,1.067,0,0,1,1.6,0L19.048,17.462,29.111,7.4a1.067,1.067,0,0,1,1.6,0A1.078,1.078,0,0,1,30.548,9Z" transform="translate(-7.028 -7.039)"/>
      </svg>
    </button>`;

    if ($(".js-show-size-guide").length > 0) {
      $(".js-show-size-guide").magnificPopup({
        type: "inline",
        closeMarkup,
        callbacks: {
          // fix: [LAX-5596] disable body scroll when open size guide popup
          open: function () {
            // Will fire when this exact popup is opened
            // this - is Magnific Popup object
            disableBodyScroll(document.querySelector("#size-guide-popup"), {
              // feat: allow touch move inside sizeguide
              // https://www.npmjs.com/package/body-scroll-lock#allowtouchmove
              allowTouchMove: (el) => {
                while (el && el !== document.body) {
                  if (el.getAttribute("body-scroll-lock-ignore") !== null) {
                    return true;
                  }

                  el = el.parentElement;
                }
              },
            });
            $("body").addClass("mfp-active");
          },
          close: function () {
            // Will fire when popup is closed
            clearAllBodyScrollLocks();
            $("body").removeClass("mfp-active");
          },
        },
      });
    }

    if ($(".js-show-store-inventory-popup").length > 0) {
      $(".js-show-store-inventory-popup").magnificPopup({
        type: "inline",
        closeMarkup,
        callbacks: {
          open: function () {
            // Will fire when this exact popup is opened
            // this - is Magnific Popup object
            disableBodyScroll(document.querySelector("#store-inventory-popup"));
            $("body").addClass("mfp-active");
          },
          close: function () {
            // Will fire when popup is closed
            clearAllBodyScrollLocks();
            $("body").removeClass("mfp-active");
          },
        },
      });
    }

    $(`.js-pdp-info__size-option`).on(
      "click",
      this.removeSizeNotSelectedError.bind(this)
    );
    $(`.js-pdp-info__size-option`).on(
      "click",
      this.updatePromotionLabel.bind(this)
    );
    $(`.js-pdp-info__size-option`).on("click", this.handleUv.bind(this));
    $(`.js-pdp-info__size-option`).on("click", this.handleClickSize.bind(this));
  }

  _renderErrorMessages(...messages) {
    if ($(".pdp-info .error").length > 0) {
      $(".pdp-info .error").remove();
    }

    messages.forEach((message, idx) => {
      $(`<span class="error">
          ${message}
        </span>
        `).appendTo(".pdp-info .js-pdp-info__sizes .title");
      scrollToFirstError();
    });
  }

  addSizeNotSelectedError() {
    this._renderErrorMessages("サイズを選択してください");
  }

  removeSizeNotSelectedError() {
    $(".pdp-info .error").remove();
  }

  handleClosePopUp() {
    $(".js-add-to-cart:visible").removeClass("is-active");
    $(".js-mobile-pdp-popup").removeClass("is-active");
  }

  addToCart(articleCode) {
    if (articleCode) {
      if ($(".pdp-info .error").length > 0) {
        scrollToFirstError();
      } else {
        this.putCartProduct(articleCode);
      }
    } else {
      this.addSizeNotSelectedError();

      // $("html, body").animate(
      //   { scrollTop: $(".js-pdp-info__sizes").offset().top - 100 },
      //   500
      // );
    }
  }

  handleClickAddToCart() {
    if ($(".js-add-to-cart:visible.is-active").length > 0) {
      return;
    }
    this.addToCart(this._getArticleCode());
  }

  _getArticleCode() {
    return $(`.js-pdp-info__size-option:checked`).val();
  }

  putCartProduct(articleCode) {
    var _this = this;
    $.ajax({
      url: "/carts.json",
      type: "PUT",
      data: JSON.stringify({ params: [articleCode, 1] }),
      success: function (data) {
        const { error, errorMessages } =
          parseJSONHandlerFuncResponseForAjax(data);

        if (!error) {
          _this.openAndRefreshCartPopup();

          _this.setStateInAddToCartButton();

          _this.setArticleCodeInAddToCartButton(articleCode);

          _this.uploadAddToCartDataToGA(articleCode);

          if ($(".pdp-info .error").length > 0) {
            $(".pdp-info .error").remove();
          }

          return;
        }

        if (!error.value) {
          // render normal error
          _this._renderErrorMessages(...errorMessages);

          return;
        }

        // display out of stock error
        // {"results":[{"error":"申し訳ありません。商品は在庫切れのため購入できません。","value":{}}]}
        if (error.error) {
          _this._renderErrorMessages(error.error);
        }

        error.value.code === "ADD_AMOUNT_LIMIT_PRODUCT" &&
          alert(error.value.msg);

        // error: cannot add lucky bag with normal products into the cart at the
        // same time confirmed: clear cart, then put the lucky bag into cart
        if (error.value.notice_info) {
          confirm(error.value.notice_info).then((isConfirmed) => {
            isConfirmed &&
              $.ajax({
                url: "/clear_cart.json",
                type: "DELETE",
                success: function (data) {
                  const { error, errorMessages } =
                    parseJSONHandlerFuncResponseForAjax(data);
                  if (error) {
                    _this._renderErrorMessages(...errorMessages);
                    return;
                  }

                  _this.putCartProduct(articleCode);
                },
              });
          });
        }
      },
    });
  }

  closeAddToCartPopup() {
    enableBodyScroll(document.querySelector(".js-mobile-pdp-popup"));
    clearAllBodyScrollLocks();
    $(".js-add-to-cart:visible").removeClass("is-active");
    // reset sticky-add-to-cart style
    $(".sticky-add-to-cart-wrapper").css({
      position: "fixed",
    });
    $(".js-mobile-pdp-popup").removeClass("is-active");
    $("#product-sticky .overlay.open").removeClass("open");
  }

  setStateInAddToCartButton() {
    $(".js-add-to-cart:visible").addClass("is-active");
    if ($(window).width() < DESKTOP_HEADER_BREAKPOINT) {
      disableBodyScroll(document.querySelector(".js-mobile-pdp-popup"));
      $(".js-mobile-pdp-popup").addClass("is-active");
      $("#product-sticky .overlay.open").removeClass("open");
      // avoid cover above popup
      $(".sticky-add-to-cart-wrapper").css({
        position: "unset",
      });
    }
    $(".js-mobile-pdp-popup.is-active .closeBtn").on(
      "click",
      this.closeAddToCartPopup
    );

    setTimeout(() => {
      this.closeAddToCartPopup();
    }, 3000);
  }

  setArticleCodeInAddToCartButton(articleCode) {
    const $addToCartButton = $(".js-add-to-cart");
    if ($addToCartButton) {
      $addToCartButton.attr("data-articleCode", articleCode);
    }
  }

  openAndRefreshCartPopup() {
    // open popup and hide it after 3 seconds if no interactions
    $(".js-header__link-item-cart").addClass("is-active");

    const removeActiveClassTimeout = setTimeout(() => {
      $(".js-header__link-item-cart").removeClass("is-active");

      $(".js-header__link-item-cart").off("mouseenter", eventHandler);
    }, 3000);
    const eventHandler = () => {
      removeActiveClassTimeout && clearTimeout(removeActiveClassTimeout);

      $(".js-header__link-item-cart").off("mouseenter", eventHandler);
    };
    $(".js-header__link-item-cart").on("mouseenter", eventHandler);

    // refresh cart
    $("#cart-widget-app-refresh-trigger").click();
  }

  emitProductUv(selectedDom) {
    const code = selectedDom.attr("data-product-code");
    const articleCode = selectedDom.attr("value");
    const name = selectedDom.attr("data-product-name");
    const categories = selectedDom.attr("data-categories");
    const seasonName = selectedDom.attr("data-season-name");
    const genderSlug = selectedDom.attr("data-gender");
    const onSale = selectedDom.attr("data-sale");
    const stock = parseInt(selectedDom.attr("data-stock"), 10);
    const price = parseInt(selectedDom.attr("data-price"), 10);

    if (window.be) {
      window.be.emit("ecProduct", {
        eventType: "linked_product",
        product: {
          sku: articleCode,
          productId: code,
          name: name,
          categories: [categories, "", seasonName],
          gender: genderSlug,
          onSale: Boolean(onSale),
          stock: stock,
          price: { currency: "JPY", value: price },
        },
      });
    }
  }

  handleUv() {
    this.emitProductUv($(`.js-pdp-info__size-option:checked`));
  }

  formatSize(japanSize, europeSize) {
    var matchedJapanSizes = ["XS", "S", "M", "L", "XL", "XXL"];

    let displaySize = japanSize;
    matchedJapanSizes.forEach((s) => {
      if (s === japanSize && europeSize) {
        displaySize = `${japanSize}(${europeSize})`;
        return;
      } else {
        return;
      }
    });

    if (japanSize && japanSize.indexOf("cm") > -1 && europeSize) {
      displaySize = `${japanSize}(${europeSize})`;
    }

    return displaySize;
  }

  handleClickSize(e) {
    const value = $(e.target).val();
    $(`.js-pdp-info__size-option[value='${value}']:not(:checked)`).prop(
      "checked",
      true
    );
    const $addToCartBtn = $(`.pdp-info__add-to-cart-btn:disabled`);
    const japanSize = $(e.target).data("japansize");
    const europeSize = $(e.target).data("europesize");
    const size = $(e.target).data("size");
    const sizeHtml = this.formatSize(japanSize, europeSize) || size;
    $(".js--sticky-open-size span.selected").removeClass("init").html(sizeHtml);

    // remove disable attribute
    $addToCartBtn.removeAttr("disabled");
    $addToCartBtn.find(".pdp-info__add-to-cart").text("カートへ追加");

    const ctas = document.querySelectorAll(
      ".js-add-to-cart .pdp-info__add-to-cart"
    );
    if (ctas) {
      for (let i = 0; i < ctas.length; i++) {
        ctas[i].textContent = "カートへ追加";
      }
    }

    $(".pdp-sticky .preview-btn").addClass("hidden");
    // update sticky add to cart btn text
    $(".pdp-sticky .preview-btn+.pdp-actions").addClass("show");
  }

  updatePromotionLabel(e) {
    const $target = $(e.currentTarget);
    const articleCode = e.target.value;
    const product = this.getProductData();

    if (!product) {
      return;
    }

    const selectedVariant = this.getSelectedVariant({ product, articleCode });

    if (!selectedVariant) {
      return;
    }

    const properties = getProductAndVariantExtraProperties({
      product,
      variants: [selectedVariant],
    });

    let discountLabels = getDiscountLabels(properties);
    let discountLabelBackgroundColors =
      getDiscountLabelBackgroundColors(properties);

    let showEvenThoughOutOfStock = product.showEvenThoughOutOfStock;
    let showOutOfStockLabel;
    if (showEvenThoughOutOfStock) {
      showOutOfStockLabel = isVariantsOutOfStock(product.variants);
      if (showOutOfStockLabel) {
        discountLabels = discountLabels.append("SOLD OUT");
        discountLabelBackgroundColors =
          discountLabelBackgroundColors.append("Grey");
      }
    }

    updateProductAndVariantLabel(
      discountLabels,
      discountLabelBackgroundColors,
      $target.parent().parent().parent().parent().parent()
    );
  }

  getProductData() {
    const sourceData =
      $("[data-tracing-source]") &&
      $("[data-tracing-source]").length > 0 &&
      $("[data-tracing-source]")[0];

    if (!sourceData) {
      return null;
    }

    return toCamel($(sourceData).data("tracing-source"));
  }

  getSelectedVariant({ product, articleCode }) {
    return (
      product &&
      product.variants &&
      product.variants.find((v) => v.articleCode === articleCode)
    );
  }

  uploadAddToCartDataToGA(articleCode) {
    const product = this.getProductData();

    if (!product) {
      return;
    }

    const selectedVariant = this.getSelectedVariant({ product, articleCode });

    if (product && selectedVariant) {
      const properties = getProductAndVariantExtraProperties({
        product,
        variants: [selectedVariant],
      });

      const categoryCode = getCategoryCode(properties);
      const { price, discounted } = calcPrice(selectedVariant);
      const addToCartProduct = {
        id: product.code,
        name: product.name,
        price: price || discounted,
        discount: price && discounted ? price - discounted : 0,
        brand: "Aigle",
        category: categoryCode,
        gender: getGenderName(properties),
        color: getColorCode(properties),
        size: getSize(properties),
        variant: selectedVariant.articleCode,
        quantity: 1,
      };

      EEaddToCart([addToCartProduct]);
    }
  }
}

export function animatedInsertBefore(element, content) {
  $(content)
    .hide()
    .insertBefore(element)
    .slideDown(400)
    .animate({ opacity: 1 }, 400);
}

export function animatedInsertAfter(element, content) {
  $(content)
    .hide()
    .insertAfter(element)
    .slideDown(400)
    .animate({ opacity: 1 }, 400);
}

export function bindSwiper(element) {
  new Swiper(".view-product-swiper", {
    slidesPerView: 1.1,
    slidesPerGroup: 1,
    spaceBetween: 10,
    centeredSlides: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    // namespace
    containerModifierClass: "swiper-container",
    modules: [Navigation],
    breakpoints: {
      767: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        centeredSlides: false,
      },
    },
  });
}

export function insertBeforeViewedProductsTo(parent, articleCode) {
  $.ajax({
    url: `/account/viewed-products.json?size=11${
      articleCode ? "&code=" + articleCode : ""
    }`,
    type: "GET",
    success: (data) => {
      const results = JSON.parse(data).results || [];
      const productsData = (results[0] || []).map(
        mappers.mapProtoWishItemToWishItemDataProp
      );

      if (productsData.length === 0) {
        return;
      }

      const pathname = window.location.pathname;

      const impression = (results[0] || []).map((item, index) => {
        const { price, sellingPrice } = productsData[index];

        return {
          id: item.product_code,
          name: item.product_name,
          price: price,
          discount: price && sellingPrice ? price - sellingPrice : 0,
          brand: getSize(item.properties),
          category: getCategoryCode(item.properties),
          item_category3: getGenderName(item.properties),
          item_category5: item.article_code,
          variant: getColorCode(item.properties),
          list:
            pathname === "/"
              ? "home | recommendation"
              : pathname.indexOf("/products") > -1
              ? "product | recommendation"
              : pathname.indexOf("/search") > -1
              ? "search"
              : "",
          listname:
            pathname === "/"
              ? "recommendations"
              : pathname.indexOf("/products") > -1
              ? "recommendations"
              : pathname.indexOf("/search") > -1
              ? "instant search results"
              : "",
          position: index + 1,
          coupon: "",
          quantity: 1,
        };
      });

      uploadViewedProductsToGA(impression);

      const products = productsData.map((item, idx) => {
        if (idx < 11) {
          const {
            id,
            path,
            imageURL,
            name,
            price,
            sellingPrice,
            discountRate,
          } = item;

          return `
          <div class="view-product-item swiper-slide viewed-product-${idx} product-card ga-product-click" data-position='${idx}' data-category="${
            impression[idx] && impression[idx].category
          }" data-articlecode="${id}" data-product-code="${
            impression[idx] && impression[idx].id
          }" data-colorcode="${
            impression[idx] && impression[idx].variant
          }" data-sizecode="${
            impression[idx] && impression[idx].brand
          }" data-gendername="${
            impression[idx] && impression[idx].item_category3
          }">
            <a href="${path}" class="product-card-images-wrapper">
              <img src="${imageURL}" alt="${name}" loading="lazy">
            </a>
            <div class="product-detail-wrapper">
              <h6 title="${name}" class="title">${name}</h6>
              <div class="price">
                ${
                  sellingPrice < price
                    ? `
                      <span class="original" data-price='${price}'>${formatCurrency(
                        price,
                        true
                      )}</span>
                      <span class="sale" data-price='${sellingPrice}'>${formatCurrency(
                        sellingPrice
                      )}<span class="tax">&nbsp;(税込)</span>
                      ${
                        discountRate &&
                        `<span class="off-tag">-${discountRate}%</span>`
                      }
                      </span>
                      `
                    : `<span data-price='${price}'>${formatCurrency(
                        price
                      )}<span class="tax">&nbsp;(税込)</span></span>`
                }
              </div>
            </div>
          </div>
          `;
        } else {
          return null;
        }
      });

      const minProductsLength = $(window).width() < 768 ? 2 : 4;
      let viewAllLink = "";
      if (products.length < minProductsLength) {
        [...Array(minProductsLength - products.length).keys()].forEach(() => {
          products.push(
            `<div class="placeholder-product" aria-hidden="true"></div>`
          );
        });
      } else {
        viewAllLink = `
          <div style="text-align: right;">
            <a class="view_all" href="/account/viewed_products">もっと見る</a>
          </div>
      `;
      }

      const content = `
        <div class="wrapper recently-viewed recently-viewed-swiper">
            <div class="title-and-more-wrapper">
            <h6 class="products-slider-title">閲覧履歴</h6>
            ${viewAllLink}
            </div>
            
            <div class="view-product-swiper swiper-container">
              <div class="swiper-wrapper">
                  ${products.join("")}
              </div>
              <button class="left-rounded-arrow prev-arrow swiper-button-prev"></button>
              <button class="right-rounded-arrow next-arrow swiper-button-next"></button>
            </div>
        </div>
      `;

      animatedInsertBefore(parent, content);
      bindSwiper($(".view-product-swiper")[0]);

      new UploadProductCardData();
    },
  });
}

export default PDP;
