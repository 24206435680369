import { scrollToFirstError } from "../../../CheckoutApp/shared/scrollToFirstError";
import $ from "jquery";

const getSelectedArticleCode = () => {
  const selectedSize = document.querySelector(
    `.js-pdp-info__size-option:checked`
  ) as HTMLInputElement;
  return selectedSize ? selectedSize.value : undefined;
};

const renderErrorMessages = (messages: string[]) => {
  if ($(".pdp-info .error").length > 0) {
    $(".pdp-info .error").remove();
  }

  messages.forEach((message) => {
    $(`<span class="error">
          ${message}
        </span>
        `).appendTo(".pdp-info .js-pdp-info__sizes .title");

    scrollToFirstError();
  });
};

const addSizeNotSelectedError = () => {
  renderErrorMessages(["サイズを選択してください"]);
};

export { getSelectedArticleCode, renderErrorMessages, addSizeNotSelectedError };
