import * as React from "react";

import { proto } from "../../proto";
import { translate } from "../../constants/ErrorMessages";

const Errors = ({
  errors,
  msg,
}: {
  errors: proto.ValidationError.IFieldViolation[];
  msg?: string;
}) =>
  errors && errors.length > 0 ? (
    <span className="error">
      {msg ? (
        <span>{msg}</span>
      ) : (
        translate(errors).map((error, idx) => (
          <span key={`error-${idx}-${error.code}`}>
            {error.msg}
            {error.code === "EMAIL_ALREADY_EXISTS" && (
              <a href="/auth/login?redir=/cart">
                ログイン画面にてログインして下さい。
              </a>
            )}
          </span>
        ))
      )}
    </span>
  ) : null;

const hasInvalidAddressError = (error?: proto.IValidationError) =>
  !!(
    error &&
    error.fieldViolations &&
    error.fieldViolations.find((e) => (e.code || "").indexOf("INVALID_") > -1)
  );

const AddressInCompleteError = ({
  error,
}: {
  error?: proto.IValidationError;
}) => {
  if (!hasInvalidAddressError(error)) {
    return null;
  }

  // unify all amazon pay address to one invalid address error
  const err = [{ code: "INVALID_AMAZON_PAY_ADDRESS" }];

  return (
    <div className="mb-15">
      <Errors
        errors={err}
        msg="※ お届け住所に問題が発生しています。変更するにはAmazonのウェブサイトでの変更が必要になります。"
      />
    </div>
  );
};

export { Errors, AddressInCompleteError };
