import React from "react";
import { proto } from "../../proto";
import {
  OrderServiceProps,
  DataProps,
  hasAddressFieldError,
  withInvalidAddressFormEditable as withInvalidAddressFormEditableStore,
  WithInvalidAddressFormEditableProps as WithInvalidAddressFormEditableStoreProps
} from "../../orders";

export type ShowAddressForm = {
  shippingAddress: boolean;
  billingAddress: boolean;
};

export type InvalidAddressFormEditableProps = {
  showAddressForm: ShowAddressForm;
  onChangeShowAddressForm: (
    type: "shipping" | "billing",
    show: boolean
  ) => void;
  onConfirm: (
    onResolve?: () => void,
    onReject?: (error: proto.IValidationError) => void
  ) => void;
};

const withInvalidAddressFormEditable = <P extends {}>(
  C: React.ComponentType<P & InvalidAddressFormEditableProps>
): React.ComponentType<P & OrderServiceProps & DataProps> =>
  withInvalidAddressFormEditableStore(
    class extends React.Component<
      P &
        OrderServiceProps &
        DataProps &
        WithInvalidAddressFormEditableStoreProps
    > {
      putSelectedAddressIfFormIsEmpty = (
        type: "shippingAddress" | "billingAddress"
      ) => {
        const {
          data: { data },
          checkout: {
            updateAddressForm,
            input: { form }
          }
        } = this.props;

        const currentAddress = form[type];
        const addressId =
          form[
            type === "shippingAddress"
              ? "shippingAddressId"
              : "billingAddressId"
          ];

        if (!currentAddress) {
          const selectedAddress =
            data &&
            data.userShippingAddress &&
            data.userShippingAddress.find(({ id }) => id === addressId);

          if (selectedAddress && selectedAddress.address) {
            updateAddressForm(type, selectedAddress.address);
          }
        }
      };

      onConfirm: InvalidAddressFormEditableProps["onConfirm"] = (
        onResolve,
        onReject
      ) => {
        const {
          checkout: {
            validateShippingInput,
            input: {
              form: { specifyBillingAddress }
            }
          },
          invalidAddressFormEditable: {
            setShippingEditorState,
            setBillingEditorState
          }
        } = this.props;

        this.putSelectedAddressIfFormIsEmpty("shippingAddress");
        if (specifyBillingAddress) {
          this.putSelectedAddressIfFormIsEmpty("billingAddress");
        }
        validateShippingInput(onResolve, error => {
          // Should not close the editor even if the error field has been fixed
          // because if close the editor, the AddressListItem is still not right.
          if (hasAddressFieldError("shipping", error)) {
            setShippingEditorState(true);
          }
          if (hasAddressFieldError("billing", error)) {
            setBillingEditorState(true);
          }
          onReject && onReject(error);
        });
      };

      onChangeShowAddressForm: InvalidAddressFormEditableProps["onChangeShowAddressForm"] = (
        type,
        show
      ) => {
        if (show) {
          this.putSelectedAddressIfFormIsEmpty(
            type === "shipping" ? "shippingAddress" : "billingAddress"
          );
          // Validate input to fetch and show the address field errors via
          // `validateShippingInput`(Why not use `validateInput`? As it not
          // "sumbit error", just "set error", which means it won't show the
          // error if the correspond field is not dirty.
          this.props.checkout.validateShippingInput();
        } else {
          // If close the AddressForm, clean up the AddressForm info
          this.props.checkout.updateAddressForm(
            type === "shipping" ? "shippingAddress" : "billingAddress",
            undefined
          );
        }
        if (type === "shipping") {
          this.props.invalidAddressFormEditable.setShippingEditorState(show);
        } else {
          this.props.invalidAddressFormEditable.setBillingEditorState(show);
        }
      };

      render() {
        return (
          <C
            showAddressForm={this.props.invalidAddressFormEditable}
            onChangeShowAddressForm={this.onChangeShowAddressForm}
            onConfirm={this.onConfirm}
            {...(this.props as P)}
          />
        );
      }
    }
  );

export { withInvalidAddressFormEditable };
