import React from "react";
import { imgProps, ProductImageSize } from "../productImage";

const ProductImage = ({ imgSrc }: { imgSrc?: string | null | false }) => (
  <figure>
    {imgSrc ? (
      <img {...imgProps(imgSrc, ProductImageSize.MN)} alt={imgSrc} />
    ) : null}
  </figure>
);

export { ProductImage };
