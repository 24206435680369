import * as React from "react";

import VISA from "../../img/VISA.svg";
import DinersClub from "../../img/DinersClub.svg";
import JCB from "../../img/JCB.svg";
import MasterCard from "../../img/MasterCard.svg";
import AmericanExpress from "../../img/AmericanExpress.svg";
import AmazonPay from "../../img/amazonpay.svg";

const PaymentList = ({ withAmazonPay }: { withAmazonPay?: boolean }) => (
  <ul className="payment-list">
    <li>
      <img src={MasterCard} alt="MasterCard" />
    </li>
    <li>
      <img src={VISA} alt="VISA" />
    </li>
    <li>
      <img src={AmericanExpress} alt="AmericanExpress" />
    </li>
    <li>
      <img src={JCB} alt="JCB" />
    </li>
    <li>
      <img src={DinersClub} alt="DinersClub" />
    </li>
    {withAmazonPay ? (
      <li>
        <img src={AmazonPay} alt="amazon pay" />
      </li>
    ) : null}
  </ul>
);

export { PaymentList };
