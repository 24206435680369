// Copy and update from https://github.com/theplant/asics3/blob/master/frontend/src/pagebuilder/js/index.js

import $ from "jquery";
import { start } from "../App";
import { PREVIEW_MAIN } from "../constants/Misc";

function preventLinkJump() {
  $(document).on("click", event => {
    let $target = $(event.target);
    let isLink = event.target.tagName.toLowerCase() === "a";
    if (!isLink) {
      $target = $target.closest("a");
    }
    const hrefProp = $target.prop("href"); // only a tag have href prop
    const hrefAttr = $target.attr("href");
    isLink =
      hrefProp &&
      // eslint-disable-next-line
      !hrefAttr.startsWith("javascript:") &&
      !hrefAttr.startsWith("#");
    isLink && event.preventDefault();
  });
}

class Preview {
  constructor() {
    this.selector = PREVIEW_MAIN;
    this.receiveMessage = this.receiveMessage.bind(this);
    window.addEventListener("message", this.receiveMessage, false);
    preventLinkJump();
  }

  destroy() {
    window.removeEventListener("message", this.receiveMessage);
  }

  sendMessage(id) {
    window.parent.postMessage(id, "*");
  }

  receiveMessage(event) {
    console.log("Received from parent", event.data);

    const { type, data } = event.data;

    switch (type) {
      case "UPDATE_CONTAINERS":
        this.updateContainers(data);
        break;
      case "SELECT_CURRENT_CONTAINER":
        this.selectCurrentContainer(data);
        break;
      default:
    }
  }

  selectCurrentContainer(currentIndex) {
    const $current = $(this.selector)
      .children()
      .eq(currentIndex);

    $current.removeClass("highlight");

    void $current[0].offsetWidth;

    $current.addClass("highlight");

    $("html, body").animate(
      {
        scrollTop:
          $current.offset().top - parseInt($("body").css("padding-top"), 10)
      },
      300
    );
  }

  updateContainers(containers) {
    if (!Array.isArray(containers) || containers.length === 0) {
      return;
    }

    const length = containers.length;
    const first = containers[0];
    const last = containers[length - 1];
    const header = /header/gi.test(first.Name) ? containers.shift() : null;
    const footer = /footer/gi.test(last.Name) ? containers.pop() : null;

    if ((containers && length) || header || footer) {
      this.render(header, containers, footer);
    }
  }

  render(header, containers, footer) {
    $("#header-container").remove();
    $("#footer-container").remove();

    header && $("body").prepend(header.Value);
    footer && $("body").append(footer.Value);

    if (!containers || !Array.isArray(containers)) {
      return;
    }

    const me = this;
    const fragment = document.createDocumentFragment();

    containers.forEach((container, index) => {
      const $container = $(container.Value).data("container-index", index);

      if ($container.css("position").search(/relative|absolute/) === -1) {
        $container.css("position", "relative");
      }

      fragment.appendChild($container[0]);
    });

    const $containers = $(this.selector);

    $containers
      .off("click", ">*")
      .empty()
      .append(fragment);

    $containers.on("click", ">*", event => {
      const index = $(event.currentTarget).data("container-index");
      me.sendMessage(index);
    });

    this.apply();
  }

  apply() {
    start();
  }
}

export { PREVIEW_MAIN, Preview };
