import { theplant } from "../proto";
import { usedPoints, deliveryFee } from "../orders/helper";
import { calcPrice, calcPriceWithoutTax, calcAmount } from "../product/helper";
import { getProperty } from "../protoHelper";
import { getSilvereggCookie } from "./SilvereggCookieHelper";
import { gtmDataLayerPush } from "../gtm";

// Copied from https://github.com/theplant/lacoste3/blob/4854474f95b9f93701554b0b53ab60870b98d574/frontend/containers/Checkout/GATransactionHelper.ts#L8-L46
const pushData = (order: theplant.ec.service.orders.IOrder) => {
  const orderCode = order.code || "";
  const orderItems = order.orderItems || [];
  const sumUp = order.sumUp || {};

  const orderBillingAmountTax = Number(sumUp.billingAmountTax || 0);
  const orderBillingAmountWithTax = Number(sumUp.billingAmountWithTax || 0);
  const orderDeliveryFee = Number(deliveryFee(sumUp.subItems || []));
  const orderUsedPoints = Math.abs(Number(usedPoints(sumUp.subItems || [])));

  const transactionProducts: Array<{
    [key: string]: string | number | null | undefined;
  }> = [];
  orderItems.forEach(item => {
    const { price, discounted } = calcPrice(item);
    const {
      price: priceWithoutTax,
      discounted: discountedPriceWithoutTax
    } = calcPriceWithoutTax(item);
    const { price: amount, discounted: discountedAmount } = calcAmount(item);

    const topCategory = getProperty("TopCategory", item.discountContexts);
    const subCategories = getProperty("SubCategories", item.discountContexts);
    const category =
      (subCategories && subCategories.join(",")) ||
      (topCategory && topCategory.join(","));

    // refer: https://github.com/theplant/lacoste/blob/master/app/views/shared/track_codes/_main.html.erb#L335
    transactionProducts.push({
      // data for silveregg
      orderCode,
      productCode: item.productCode,
      priceWithoutTax: discountedPriceWithoutTax || priceWithoutTax,
      amount: discountedAmount || amount,
      // data for silveregg end
      sku: item.articleCode,
      name: item.productName,
      category,
      price: discounted || price,
      quantity: Number(item.quantity || 0)
    });
  });

  gtmDataLayerPush({
    silvereggCookieId: getSilvereggCookie(), // Required by silveregg
    transactionId: order.code,
    transactionAffiliation: "Aigle EC SITE", // Updated it to `Aigle EC SITE`
    transactionTotal: orderBillingAmountWithTax + orderUsedPoints,
    transactionTax: orderBillingAmountTax,
    transactionShipping: orderDeliveryFee,
    transactionProducts: transactionProducts
  });
};
export { pushData };
