import React from "react";
import Content from "./Content";
import { storeInventoryButtonClick } from "../../gtm/storeInventoryClickEvent";
import { useProductHasAnyStoreInventory } from "./getProduct";
import { Color } from "../../components/molecules/product/Color";

const StoreInventory = ({
  code,
  colorCode,
}: {
  code: string;
  colorCode: string;
}) => {
  const [selectedSizeCode, setSelectedSizeCode] = React.useState<string | null>(
    null
  );

  const [display, setDisplay] = React.useState(false);

  const { product, hasStoreStock } = useProductHasAnyStoreInventory(code);

  const checkDisplayOnClick = () => {
    if (document.querySelector(".mfp-content #store-inventory-popup")) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  };

  React.useEffect(() => {
    document.body.addEventListener("click", checkDisplayOnClick);

    const storeInventoryBtn = document.querySelector(".store-inventory-button");
    if (storeInventoryBtn) {
      storeInventoryBtn.addEventListener("click", () => {
        const firstSelected = document.querySelector(
          ".js-pdp-info__sizes .pdp-info__size-item input:checked"
        );

        if (firstSelected) {
          setSelectedSizeCode(firstSelected.getAttribute("data-size"));
        }

        storeInventoryButtonClick("check");
      });
    }

    return () => {
      document.body.removeEventListener("click", checkDisplayOnClick);
    };
  }, []);

  if (!product || !hasStoreStock) {
    return null;
  }

  return (
    <div className="store-inventory-content">
      <h2 className="story-inventory-title">取扱店舗の在庫を確認</h2>
      <Content
        product={product}
        colorCode={colorCode}
        selectedSizeCode={selectedSizeCode}
        // add display as key, when close popup, should clear all states
        // refer: https://theplanttokyo.atlassian.net/browse/LAC-1860
        key={`${display}-${selectedSizeCode}`}
        display={display}
      />
      <Color />
    </div>
  );
};

export default StoreInventory;
