import * as React from "react";

import { CartProps } from "../../orders";
import { Input } from "../shared/Input";

import { Errors } from "../shared/Errors";
import { DeleteIcon } from "../shared/Icon";
import { theplant } from "../../proto";

import { UploadCartCoupon } from "../../gtm/GA";
import { formatCurrency } from "../../components/helpers/currency";

const UsedCoupon = ({
  couponsUsed,
  cancelUsedCoupon,
  couponDiscountValue,
}: {
  couponsUsed: string[] | null | undefined;
  cancelUsedCoupon: () => void;
  couponDiscountValue?: number;
}) =>
  couponsUsed && couponsUsed.length > 0 && couponsUsed.some((c) => c !== "") ? (
    <ul className="used-section">
      {couponsUsed.map((c, idx) =>
        c === "" ? null : (
          <li key={`used-coupon-${idx}`}>
            <span>
              「{c}」が適用されました{" "}
              {Number(couponDiscountValue) > 0 &&
                formatCurrency(couponDiscountValue)}
            </span>
            <DeleteIcon onClick={cancelUsedCoupon} />
          </li>
        )
      )}
    </ul>
  ) : null;

const SubItemType = theplant.ec.service.accounting.SubItemType;

const Coupon = ({ cart }: { cart: CartProps["cart"] }) => {
  const {
    couponCodeInput,
    updateCouponCodeInput,
    applyCouponCode,
    cancelCouponCode,
    couponCodeError,
    cart: _cart,
  } = cart;

  const couponDiscount =
    _cart &&
    _cart.sumUp &&
    _cart.sumUp.subItems &&
    _cart.sumUp.subItems.find(
      (item) => item.subType === SubItemType["COUPON_DISCOUNT"]
    );
  const couponDiscountValue = couponDiscount?.amount;

  return (
    <section className="section-wrapper coupon-panel white-panel">
      <label className="title">クーポンコード</label>
      <div className="form-input-group mt-16">
        <Input
          type="text"
          placeholder="クーポンコードを入力"
          value={couponCodeInput || ""}
          update
          updateInput={updateCouponCodeInput}
          onKeyPressEnter={(evt: React.KeyboardEvent<HTMLInputElement>) => {
            if (evt.key === "Enter") {
              applyCouponCode(couponCodeInput || "");
              UploadCartCoupon(couponCodeInput || "");
            }
          }}
          error={
            ((couponCodeError && couponCodeError.fieldViolations) || [])
              .length > 0
          }
        />

        <button
          type="button"
          className={`outline-btn ${!couponCodeInput && `disabled`}`}
          onClick={() => {
            applyCouponCode(couponCodeInput || "");
            UploadCartCoupon(couponCodeInput || "");
          }}
          disabled={!couponCodeInput}
        >
          適用
        </button>
      </div>
      <Errors
        errors={(couponCodeError && couponCodeError.fieldViolations) || []}
      />

      <UsedCoupon
        couponsUsed={cart.cart && cart.cart.couponsUsed}
        couponDiscountValue={Number(couponDiscountValue || 0)}
        cancelUsedCoupon={cancelCouponCode}
      />
    </section>
  );
};

export { Coupon };
