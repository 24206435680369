import React from "react";

import { Errors } from "../Errors";

import { checkoutForm } from "../../../orders";
import { Form } from "../../../form";

const input = Form.on(checkoutForm, "form");

const storeInfoForm = Form.on(input, "storeInfo");

// the combini pick up error msg is added on the `storeInfo` field
const CombiniPickUpError = () => (
  <storeInfoForm.field
    component={({ dirty, errors }) => <Errors errors={dirty ? errors : []} />}
  />
);

export { CombiniPickUpError };
