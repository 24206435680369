import React from "react";
import { formatCurrency } from "../helpers/currency";

type ProductPriceDataPropType = {
  price: number;
  sellingPrice: number;
  discountRate: number;
};

const _isDiscounted = ({ discountRate }: ProductPriceDataPropType) =>
  discountRate && discountRate !== 0;
const getPrice = (productPriceData: ProductPriceDataPropType) =>
  _isDiscounted(productPriceData)
    ? productPriceData.sellingPrice
    : productPriceData.price;

const Price = ({
  price,
  className,
  smaller,
}: ProductPriceDataPropType & { className?: string; smaller?: boolean }) => (
  <p className={`price ${className ? className : ""}`}>
    <span>
      {formatCurrency(price)}
      {smaller ? null : <span className="tax">&nbsp;(税込)</span>}
    </span>
  </p>
);

const SellingPrice = ({
  price,
  sellingPrice,
  discountRate,
  className,
  smaller,
}: ProductPriceDataPropType & { className?: string; smaller?: boolean }) => (
  <p className={`price ${className}`}>
    <span className="original">{formatCurrency(price, smaller)}</span>
    <span className="sale">
      {formatCurrency(sellingPrice)}
      {smaller ? null : <span className="tax">&nbsp;(税込)</span>}
    </span>
    {discountRate && <span className="off-tag">{`-${discountRate}%`}</span>}
  </p>
);

const ProductPrice = (props: {
  data: ProductPriceDataPropType;
  className?: string;
  smaller?: boolean;
}) => {
  const { data, className } = props;
  return _isDiscounted(data) ? (
    <SellingPrice {...data} className={className} smaller />
  ) : (
    <Price {...data} className={className} smaller />
  );
};

export default ProductPrice;

export { getPrice, Price, SellingPrice };
