import React, { useState } from "react";
import cx from "classnames";

import { Input } from "../shared/Input";
import { usersForm, UsersProps } from "../../orders";
import { Form } from "../../form";
import { ACCOUNT_PERSONAL_PATH } from "../../constants/Paths";
import { theplant } from "../../proto";
import { Loading } from "../Loading";
type ILoginInfo = theplant.ec.api.users.ILoginInfo;
const form = Form.on(usersForm, "loginForm");

const LoginFormView = (props: {
  email: string;
  orderCode: string;
  login: UsersProps["users"]["login"];
  loginForm: ILoginInfo;
}) => {
  const [fetching, setFetching] = useState(false);
  const [redirecting, setRedirecting] = useState(false);

  const { login, loginForm, email, orderCode } = props;

  if (redirecting) {
    return <Loading />;
  }

  return (
    <div className="checkout-form section-wrapper login-panel white-panel">
      <h4 className="section-title">既にアカウントをお持ちです</h4>
      <p className="section-desc">
        今回ご利用になられたメールアドレス（{email}
        ）は既にAIGLEオンラインショップに登録されています。今回のご購入で獲得されたポイントをお持ちのアカウントに追加される場合は、下記よりパスワードを入力してログインしてください。
      </p>

      <div className="form-group row">
        <div className="col">
          <form.on
            field="password"
            component={(props) => (
              <Input
                {...props}
                label="パスワード"
                type="password"
                placeholder="例） Aiglepass123"
                maxLength={20}
                name="password"
              />
            )}
          />
        </div>
      </div>

      <div className="form-group btn-wrapper">
        <button
          type="button"
          className={cx("primary-btn", { disabled: fetching })}
          onClick={() => {
            if (fetching) {
              return;
            }

            // disable the button
            setFetching(true);

            login(email, loginForm.password || "", orderCode)
              .then((user) => {
                setFetching(false);

                if (user) {
                  setRedirecting(true);
                  (window as any).location = ACCOUNT_PERSONAL_PATH;
                }
              })
              .catch(() => {
                setFetching(false);
              });
          }}
        >
          ログイン
        </button>
      </div>
    </div>
  );
};

export { LoginFormView as LoginForm };
