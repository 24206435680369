import $ from "jquery";
import React from "react";
import Player from "@vimeo/player";
import { normalVideo } from "./videoHelper";

const onPlayerReady = (event: any, autoplay) => {
  const windowSize = window.innerWidth;
  if (windowSize < 768 && windowSize >= 320) {
    // only mobile need
    if (autoplay) {
      // mute before play, this is important,
      // because only mute video can autoplay in mobile browser
      event.target.mute();
      event.target.playVideo();
      event.target.hideVideoInfo();
    }
  }
};

const downloadYoutubeIframe = () => {
  if (!Window.YT) {
    const tag = document.createElement("script");
    tag.id = "iframe-demo";
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    if (firstScriptTag && firstScriptTag.parentNode) {
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
  }
};

const VideoApp = ({
  url,
  videoId,
  autoplay,
  mute,
  className,
  videoType,
  proportion,
}) => {
  const ref = React.useRef();
  const playButtonRef = React.useRef();
  const muteButtonRef = React.useRef();
  const videoRef = React.useRef(null);
  let youtubePlayer;
  let vimeoPlayer;
  let timer = null;

  const showVideo = () => {
    const video = videoRef && videoRef.current;
    if (video) {
      video.style.visibility = "visible";
      video.style.height = "auto";
    }
  };

  const downloadNormalVideo = () => {
    const video = videoRef && videoRef.current;
    const source = video?.querySelector("source");

    if (video && source) {
      video.autoplay = Boolean(autoplay);
      if (source) {
        source.src = source?.dataset.src;

        video.load();
        video.addEventListener("loadedmetadata", () => {
          const width = video.videoWidth;
          const height = video.videoHeight;
          const [pw, ph] = (proportion || "16:9").split(":");

          if ((height * Number(pw)) / Number(ph) === width) {
            video.style.maxWidth = "100vw";
          }

          const dimision = (height / width) * 100;
          const videoContainer = video.closest(".video-container");
          if (videoContainer) {
            const oldPaddingBottom = videoContainer.style.paddingBottom;
            if (oldPaddingBottom !== "45%") {
              // 45% is for desktop screen size to allow user to see all content
              videoContainer.style.paddingBottom = `${dimision}%`;
            }
          }
        });
        video.addEventListener("canplay", () => {
          if (autoplay) {
            const playPromise = video.play();
            if (playPromise) {
              playPromise.then(
                () => {
                  showVideo();
                },
                () => {
                  console.log("cannot play, show when touch");
                  document.addEventListener(
                    "touchstart",
                    () => {
                      console.log("document touch start");
                      showVideo();
                      if (autoplay) {
                        video.play();
                      }
                    },
                    { once: true }
                  );
                  document.addEventListener(
                    "click",
                    () => {
                      showVideo();

                      if (autoplay) {
                        video.play();
                      }
                    },
                    { once: true }
                  );
                }
              );
            }
          } else {
            showVideo();
          }
        });
      }

      video.muted = autoplay ? true : mute ? true : false;

      if (typeof video.loop == "boolean") {
        // loop supported
        video.loop = true;
      } else {
        // loop property not supported
        video.addEventListener(
          "ended",
          function () {
            video.play();
          },
          false
        );
      }
    }
  };

  React.useEffect(() => {
    if (videoType === "youtubeVideo") {
      downloadYoutubeIframe();
    }

    let playButton = playButtonRef.current;
    let muteButton = muteButtonRef.current;
    const video = videoRef && videoRef.current;
    let controlPlay;
    let controlMute;

    if (ref || videoRef.current) {
      switch (videoType) {
        case "youtubeVideo":
          newAndPlayYoutubeVideo();
          break;
        case "vimeoVideo":
          newAndPlayVimeoVideo();
          break;
        default:
          downloadNormalVideo();
          break;
      }

      $(playButton).attr("data-played", autoplay);
      $(muteButton).attr("data-muted", mute);

      if (autoplay) {
        $(muteButton).attr("data-muted", true);
        $(playButton).attr("data-played", true);
        $(playButton).attr("data-resume", true);
      }

      controlPlay = (e) => {
        e.preventDefault();
        switch (videoType) {
          case "youtubeVideo":
            youtubePlayer.playVideo();
            break;
          case "vimeoVideo":
            vimeoPlayer.play();
            break;
          default:
            if (video) {
              showVideo();
              video.play();
            }
            break;
        }
        $(playButton).attr("data-played", true);
        $(playButton).attr("data-resume", true);
      };

      $(playButton).bind("click", controlPlay);

      controlMute = (e) => {
        e.preventDefault();
        if ($(muteButton).attr("data-muted") === "true") {
          switch (videoType) {
            case "youtubeVideo":
              youtubePlayer.unMute();
              break;
            case "vimeoVideo":
              vimeoPlayer.setMuted(false);
              break;
            default:
              if (video) {
                video.muted = false;
              }
              break;
          }

          $(muteButton).attr("data-muted", false);
        } else {
          switch (videoType) {
            case "youtubeVideo":
              youtubePlayer.mute();
              break;
            case "vimeoVideo":
              vimeoPlayer.setMuted(true);
              break;
            default:
              if (video) {
                video.muted = true;
              }
              break;
          }
          $(muteButton).attr("data-muted", true);
        }
      };

      $(muteButton).bind("click", controlMute);

      const $videoBanner = $(ref.current).closest(".js-video-banner-slider");
      $videoBanner.on(
        "beforeChange",
        function (event, slick, currentSlide, nextSlide) {
          const activeSlide = slick.$slides.get(nextSlide);

          let currentActiveClass = $(activeSlide)
            .find(".videoTarget div")
            .attr("class");

          if (!currentActiveClass) {
            currentActiveClass = $(activeSlide)
              .find(".videoTarget iframe")
              .attr("class");
          }

          if (currentActiveClass === className) {
            if ($(playButton).attr("data-resume") === "true") {
              switch (videoType) {
                case "youtubeVideo":
                  youtubePlayer.playVideo();
                  break;
                case "vimeoVideo":
                  vimeoPlayer.play();
                  break;
                default:
                  break;
              }

              // after touch video banner, next slider video banner will autoplay
              // so need to set it's playbutton to played
              // the muteButton will displayed for user to mute or not
              $(playButton).attr("data-played", true);
            }
          } else {
            switch (videoType) {
              case "youtubeVideo":
                if (youtubePlayer && youtubePlayer.stopVideo) {
                  youtubePlayer.stopVideo();
                }
                break;
              case "vimeoVideo":
                vimeoPlayer.pause();
                break;
              default:
                break;
            }
            // reset playbutton when slide is non-active
            $(playButton).attr("data-played", false);
          }
        }
      );
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
      $(playButton).unbind("click", controlPlay);
      $(muteButton).unbind("click", controlMute);
    };
    // eslint-disable-next-line
  }, []);

  const newAndPlayYoutubeVideo = () => {
    timer = setInterval(() => {
      if (window.YT) {
        if (timer) {
          clearInterval(timer);
        }

        window.YT.ready(function () {
          youtubePlayer = new window.YT.Player(ref.current, {
            events: {
              onReady: (e) => onPlayerReady(e, autoplay),
            },
            videoId,
            playerVars: {
              autoplay: autoplay ? 1 : 0,
              controls: 0,
              autohide: 1,
              mute: autoplay ? 1 : mute ? 1 : 0, // autoplay must be muted
              wmode: "transparent",
              loop: 1,
              rel: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              playlist: videoId,
            },
          });
        });
      }
    }, 200);
  };

  const newAndPlayVimeoVideo = () => {
    vimeoPlayer = new Player(ref.current, {
      id: videoId,
      controls: false,
      loop: true,
      muted: autoplay ? true : mute,
      background: autoplay,
    });
  };

  return (
    <>
      <div className={className}>
        {videoType === normalVideo ? (
          <video
            ref={videoRef}
            preload="none"
            loop={true}
            muted={autoplay ? true : mute}
            autoPlay={autoplay}
            playsInline
            webkitplaysinline="true"
          >
            <source data-src={url} type="video/mp4" />
            <p>Your browser doesn't support HTML5 video.</p>
          </video>
        ) : (
          <div ref={ref} />
        )}
      </div>
      <div className="banner-video-button">
        <div className="banner-video-play-button" ref={playButtonRef}>
          <svg
            viewBox="0 0 39 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.2 24.7713C39.3333 23.5396 39.3333 20.4604 37.2 19.2287L4.8 0.522569C2.66666 -0.709112 0 0.830491 0 3.29385V40.7062C0 43.1695 2.66667 44.7091 4.8 43.4774L37.2 24.7713Z"
              fill="#fff"
            />
          </svg>
        </div>
        <div className="banner-video-mute-button" ref={muteButtonRef}>
          <svg viewBox="0 0 64 48" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.2688 47.4291C54.6243 48.403 56.5314 48.1108 57.4191 46.7055C61.709 39.9132 64 32.0484 64 24C64 15.9516 61.709 8.08683 57.419 1.2945C56.5314 -0.11085 54.6243 -0.403031 53.2688 0.570912C51.9133 1.54485 51.6246 3.41547 52.4959 4.83073C56.0417 10.5898 57.9326 17.2191 57.9326 24C57.9326 30.7809 56.0417 37.4102 52.4959 43.1693C51.6246 44.5845 51.9133 46.4551 53.2688 47.4291ZM12.8 32.999H2.8C1.2536 32.999 0 31.7454 0 30.199V17.799C0 16.2526 1.2536 14.999 2.8 14.999H12.8L26.7514 3.37282C28.8357 1.63594 32 3.11805 32 5.83113V42.1669C32 44.8799 28.8357 46.362 26.7514 44.6252L12.8 32.999ZM43.8724 35.2769C43.0808 36.2293 41.5979 36.2221 40.6912 35.3661C39.7845 34.5101 39.8022 33.1305 40.5551 32.1506C41.23 31.2721 41.7951 30.3198 42.237 29.3125C42.976 27.6282 43.3564 25.823 43.3564 24C43.3564 22.177 42.976 20.3718 42.237 18.6875C41.7951 17.6802 41.23 16.7279 40.5551 15.8494C39.8022 14.8695 39.7845 13.4899 40.6912 12.6339C41.5979 11.7779 43.0808 11.7707 43.8724 12.7231C44.9577 14.0286 45.8517 15.4704 46.5272 17.0099C47.4995 19.226 48 21.6013 48 24C48 26.3987 47.4995 28.774 46.5272 30.9901C45.8517 32.5296 44.9577 33.9714 43.8724 35.2769Z"
                fill="#fff"
              />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
};

export default VideoApp;
