import * as types from "../constants/ActionTypes";

const close = () => ({ type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_CLOSE });

const changeField = (field, value) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_CHANGE_FIELD,
  field,
  value,
});

const newShippingAddress = () => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_NEW,
});

const edit = (id) => ({ type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_EDIT, id });

const load = (data) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_LOAD,
  data,
});

const submit = (callback) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_WILL_SUBMIT,
  callback,
});

const submitSuccess = (data) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT,
  data,
});

const submitFailure = (error) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_FORM_DID_SUBMIT_FAILED,
  error,
});

const selectAddressAsDefault = (address) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_WILL_SELECT_AS_DEFAULT,
  address,
});

const selectAddressAsDefaultSuccess = (address) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT,
  address,
});

const selectAddressAsDefaultFailed = (error) => ({
  type: types.ACCOUNT_SHIPPING_ADDRESS_DID_SELECT_AS_DEFAULT_FAILED,
  error,
});

export {
  close,
  changeField,
  newShippingAddress,
  edit,
  load,
  submit,
  submitSuccess,
  submitFailure,
  selectAddressAsDefault,
  selectAddressAsDefaultSuccess,
  selectAddressAsDefaultFailed,
};
