import React, { useEffect } from "react";
import { connect } from "react-redux";
import { refresh, switchPointsSubTabs } from "../../actions/points";

import {
  Redirect,
  Switch,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";

import { TABS as routes, RouteWithSubRoutes } from "../AccountApp";
import SharePoint from "./SharePoint";

import * as paths from "../../constants/Paths";

type Props = RouteComponentProps<{}> & {
  confirmPoint: number;
  temporaryPoint: number;
  rankDefaultRatios: number;
  refresh: () => void;
  switchPointsSubTabs: (pathname: string) => void;
  history: { push: (pathname: string) => void };
  onlySharedPart?: boolean | null;
  rankName?: string;
  nextRankName?: string;
  neededAmount?: number;
  campaignPoint: number;
  expiresoonMonthPoint: number;
  location: {
    pathname: string;
  };
};

const Points = (props: Props) => {
  const {
    confirmPoint = 0,
    temporaryPoint = 0,
    rankDefaultRatios,
    rankName,
    nextRankName,
    neededAmount,
    onlySharedPart,
    campaignPoint = 0,
    expiresoonMonthPoint = 0,
    location: { pathname },
    switchPointsSubTabs,
    refresh,
  } = props;

  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (onlySharedPart) {
      return;
    }
    switchPointsSubTabs(pathname);
    // eslint-disable-next-line
  }, [pathname]);

  const linkTo = (path: string) => {
    return () => props.history.push(path);
  };

  const pointRoute = routes.find(
    ({ path }) => path === paths.ACCOUNT_POINTS_PATH
  );

  if (onlySharedPart) {
    return (
      <SharePoint
        confirmPoint={confirmPoint}
        temporaryPoint={temporaryPoint}
        rankName={rankName}
        rankDefaultRatios={rankDefaultRatios}
        campaignPoint={campaignPoint}
        expiresoonMonthPoint={expiresoonMonthPoint}
        onlySharedPart={onlySharedPart}
      />
    );
  }

  return (
    <div className="points tab-content">
      <div className="white-panel">
        <SharePoint
          confirmPoint={confirmPoint}
          temporaryPoint={temporaryPoint}
          rankName={rankName}
          rankDefaultRatios={rankDefaultRatios}
          campaignPoint={campaignPoint}
          expiresoonMonthPoint={expiresoonMonthPoint}
          nextRankName={nextRankName}
          neededAmount={neededAmount}
        />
      </div>

      <div className="title white-panel">
        <ul className="flex points-menu-wrapper tab">
          {((pointRoute && pointRoute.routes) || []).map((tab, idx) => (
            <li
              key={`subTab-${idx}`}
              className={`tab-item ${pathname === tab.path ? "active" : ""}`}
            >
              {/* eslint-disable-next-line */}
              <a onClick={linkTo(tab.path)}>
                <span
                  onClick={(e: any) => {
                    if (e && e.target) {
                      e.target.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                >
                  {tab.label}
                </span>
              </a>
            </li>
          ))}
        </ul>
        <Switch>
          {((pointRoute && pointRoute.routes) || []).map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          {/* Redirect to the first sub route */}
          <Redirect from="/" to={paths.ACCOUNT_POINTS_HISTORY_PATH} />
        </Switch>
      </div>
    </div>
  );
};

Points.defaultProps = {
  refresh: () => null,
  switchPointsSubTabs: () => null,
  confirmPoint: 0,
  temporaryPoint: 0,
  rankDefaultRatios: 0,
  location: {},
  history: {},
};

const mapStateToProps = ({ points: { data } }: any) => ({
  confirmPoint: data.base && data.base.confirm_point,
  temporaryPoint: data.base && data.base.temporary_point,
  campaignPoint: data.campaign_point,
  expiresoonMonthPoint: data.expiresoon_month_point,
  rankName: data.rank_name,
  nextRankName: data.next_rank_name,
  neededAmount: data.needed_amount,
  rankDefaultRatios: data.rank_default_ratios,
});

export default connect<any, any, { onlySharedPart?: boolean }>(
  mapStateToProps,
  {
    refresh,
    switchPointsSubTabs,
  }
)(withRouter(Points as any)); // FIXME: any
