import React from "react";

const CombiniCallback = () => {
  React.useEffect(() => {
    const query = window.location.search;

    window.top &&
      window.top.postMessage({ combiniResult: query }, window.location.origin);
  }, []);

  return (
    <div>
      <p>処理中...</p>
    </div>
  );
};

export { CombiniCallback };
