import React from "react";

import { Errors } from "../Errors";

import { checkoutForm } from "../../../orders";
import { Form } from "../../../form";

const input = Form.on(checkoutForm, "form");

const deliveryInfoForm = Form.on(input, "deliveryInfo");

const DeliveryDateError = () => (
  <deliveryInfoForm.on
    field="specifiedDate"
    component={({ dirty, errors }) => <Errors errors={dirty ? errors : []} />}
  />
);

const DeliveryHoursError = () => (
  <deliveryInfoForm.on
    field="specifiedHours"
    component={({ dirty, errors }) => <Errors errors={dirty ? errors : []} />}
  />
);

export { DeliveryDateError, DeliveryHoursError };
