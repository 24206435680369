import React from "react";

import { Errors } from "../Errors";

import { checkoutForm } from "../../../orders";
import { Form } from "../../../form";

const input = Form.on(checkoutForm, "form");

const emailForm = Form.on(input, "email");

// the user is blocked, the error code is INVALIDARGUMENT,
// the user is logged out while confirming the order, the error code is INVALID_EMAIL_FORMAT
const UserError = () => (
  <emailForm.field
    component={({ dirty, errors }) => <Errors errors={dirty ? errors : []} />}
  />
);

export { UserError };
