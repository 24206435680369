import React from "react";
const CheckedSVG = ({ color }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.79508 15.8749L4.62508 11.7049L3.20508 13.1149L8.79508 18.7049L20.7951 6.70492L19.3851 5.29492L8.79508 15.8749Z"
      fill={color || "black"}
    />
  </svg>
);

const ExpandSvg = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6 14.2951L7.41 15.7051L12 11.1251L16.59 15.7051L18 14.2951L12 8.29508L6 14.2951Z"
      fill="black"
    />
  </svg>
);

const UserInfoSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 3H3.5V6M18.5 3H21.5V6M6.5 21H3.5V18M7.5 18V17C7.5 15.6739 8.02678 14.4021 8.96447 13.4645C9.90215 12.5268 11.1739 12 12.5 12M12.5 12C13.8261 12 15.0979 12.5268 16.0355 13.4645C16.9732 14.4021 17.5 15.6739 17.5 17V18M12.5 12C13.2956 12 14.0587 11.6839 14.6213 11.1213C15.1839 10.5587 15.5 9.79565 15.5 9C15.5 8.20435 15.1839 7.44129 14.6213 6.87868C14.0587 6.31607 13.2956 6 12.5 6C11.7044 6 10.9413 6.31607 10.3787 6.87868C9.81607 7.44129 9.5 8.20435 9.5 9C9.5 9.79565 9.81607 10.5587 10.3787 11.1213C10.9413 11.6839 11.7044 12 12.5 12ZM18.5 21H21.5V18"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UserProfileSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 12C10.5 13.0609 10.0786 14.0783 9.32843 14.8284C8.57828 15.5786 7.56087 16 6.5 16C5.43913 16 4.42172 15.5786 3.67157 14.8284C2.92143 14.0783 2.5 13.0609 2.5 12C2.5 10.9391 2.92143 9.92172 3.67157 9.17157C4.42172 8.42143 5.43913 8 6.5 8C7.56087 8 8.57828 8.42143 9.32843 9.17157C10.0786 9.92172 10.5 10.9391 10.5 12ZM10.5 12H22.5V15M18.5 12V15"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const AddressSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5 10C20.5 14.418 12.5 22 12.5 22C12.5 22 4.5 14.418 4.5 10C4.5 7.87827 5.34285 5.84344 6.84315 4.34315C8.34344 2.84285 10.3783 2 12.5 2C14.6217 2 16.6566 2.84285 18.1569 4.34315C19.6571 5.84344 20.5 7.87827 20.5 10Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M12.5 11C12.7652 11 13.0196 10.8946 13.2071 10.7071C13.3946 10.5196 13.5 10.2652 13.5 10C13.5 9.73478 13.3946 9.48043 13.2071 9.29289C13.0196 9.10536 12.7652 9 12.5 9C12.2348 9 11.9804 9.10536 11.7929 9.29289C11.6054 9.48043 11.5 9.73478 11.5 10C11.5 10.2652 11.6054 10.5196 11.7929 10.7071C11.9804 10.8946 12.2348 11 12.5 11Z"
      fill="black"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UserCreditCardSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5 11.429V18C22.5 18.5304 22.2893 19.0391 21.9142 19.4142C21.5391 19.7893 21.0304 20 20.5 20H7.5C6.96957 20 6.46086 19.7893 6.08579 19.4142C5.71071 19.0391 5.5 18.5304 5.5 18V16.5M22.5 11.429V10C22.5 9.46957 22.2893 8.96086 21.9142 8.58579C21.5391 8.21071 21.0304 8 20.5 8H19.5M22.5 11.429H19.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 8V14.5C19.5 15.0304 19.2893 15.5391 18.9142 15.9142C18.5391 16.2893 18.0304 16.5 17.5 16.5H4.5C3.96957 16.5 3.46086 16.2893 3.08579 15.9142C2.71071 15.5391 2.5 15.0304 2.5 14.5V6.5C2.5 5.96957 2.71071 5.46086 3.08579 5.08579C3.46086 4.71071 3.96957 4.5 4.5 4.5H17.5C18.0304 4.5 18.5391 4.71071 18.9142 5.08579C19.2893 5.46086 19.5 5.96957 19.5 6.5V8ZM19.5 8H6"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const WishListSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5002 8.862C22.5037 10.4007 21.9109 11.881 20.8462 12.992C18.4052 15.523 16.0372 18.162 13.5062 20.6C12.9252 21.15 12.0042 21.13 11.4492 20.555L4.15424 12.993C1.94924 10.707 1.94924 7.017 4.15424 4.732C4.67538 4.185 5.30218 3.74952 5.99663 3.45197C6.69108 3.15442 7.43872 3.001 8.19424 3.001C8.94975 3.001 9.6974 3.15442 10.3918 3.45197C11.0863 3.74952 11.7131 4.185 12.2342 4.732L12.5002 5.006L12.7652 4.732C13.2874 4.18629 13.9144 3.75161 14.6086 3.454C15.3028 3.15639 16.0499 3.00197 16.8052 3C18.3252 3 19.7782 3.624 20.8452 4.732C21.9102 5.84284 22.5034 7.32311 22.5002 8.862Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);

const OrderSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 6V12H18.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.388 10.5C21.664 5.689 17.513 2 12.5 2C6.977 2 2.5 6.477 2.5 12C2.5 17.523 6.977 22 12.5 22C16.6 22 20.125 19.532 21.668 16"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 16H21.9C21.9788 16 22.0568 16.0155 22.1296 16.0457C22.2024 16.0758 22.2685 16.12 22.3243 16.1757C22.38 16.2315 22.4242 16.2976 22.4543 16.3704C22.4845 16.4432 22.5 16.5212 22.5 16.6V21"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PointSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 14.5C11.5 15.88 13.739 17 16.5 17C19.261 17 21.5 15.88 21.5 14.5M3.5 9.5C3.5 10.88 5.739 12 8.5 12C9.626 12 10.665 11.814 11.5 11.5M3.5 13C3.5 14.38 5.739 15.5 8.5 15.5C9.626 15.5 10.664 15.314 11.5 15M16.5 13C13.739 13 11.5 11.88 11.5 10.5C11.5 9.12 13.739 8 16.5 8C19.261 8 21.5 9.12 21.5 10.5C21.5 11.88 19.261 13 16.5 13Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 5.5V16.5C3.5 17.88 5.739 19 8.5 19C9.626 19 10.664 18.814 11.5 18.5M11.5 18.5V10.5M11.5 18.5C11.5 19.88 13.739 21 16.5 21C19.261 21 21.5 19.88 21.5 18.5V10.5M13.5 8.5V5.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 8C5.739 8 3.5 6.88 3.5 5.5C3.5 4.12 5.739 3 8.5 3C11.261 3 13.5 4.12 13.5 5.5C13.5 6.88 11.261 8 8.5 8Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RecentlyViewSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_521_41150)">
      <path
        d="M6.50015 4H9.50015C9.50015 4 9.50015 7 12.5002 7C15.5002 7 15.5002 4 15.5002 4H18.5002M18.5002 11V19.4C18.5002 19.5591 18.4369 19.7117 18.3244 19.8243C18.2119 19.9368 18.0593 20 17.9002 20H7.10015C6.94102 20 6.78841 19.9368 6.67589 19.8243C6.56337 19.7117 6.50015 19.5591 6.50015 19.4V11M18.5002 4L22.9432 5.777C23.0909 5.83615 23.2091 5.95155 23.2717 6.09783C23.3343 6.24411 23.3363 6.40927 23.2772 6.557L21.6512 10.623C21.6066 10.7343 21.5297 10.8297 21.4304 10.8969C21.3312 10.9641 21.214 11 21.0942 11H18.5002M6.50015 4L2.05715 5.777C1.90943 5.83615 1.79125 5.95155 1.72862 6.09783C1.66598 6.24411 1.66401 6.40927 1.72315 6.557L3.34915 10.623C3.39371 10.7343 3.47058 10.8297 3.56986 10.8969C3.66914 10.9641 3.78627 11 3.90615 11H6.50015"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_521_41150">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

const MyStoreSvg = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.318 9.364L20.624 3.435C20.5881 3.30965 20.5124 3.19939 20.4083 3.1209C20.3042 3.04241 20.1774 2.99997 20.047 3H16L16.475 8.704C16.4822 8.79572 16.5112 8.8844 16.5595 8.96267C16.6079 9.04093 16.6742 9.10653 16.753 9.154C17.143 9.387 17.905 9.817 18.5 10C19.516 10.313 21 10.2 21.846 10.096C21.9282 10.0854 22.0072 10.0569 22.0773 10.0126C22.1474 9.96835 22.207 9.90929 22.252 9.8396C22.2969 9.7699 22.3261 9.69123 22.3375 9.60909C22.3489 9.52695 22.3423 9.44331 22.318 9.364Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M14.4998 10C15.0678 9.825 15.7878 9.426 16.1898 9.188C16.2835 9.13222 16.3595 9.05108 16.4091 8.95393C16.4586 8.85678 16.4797 8.7476 16.4698 8.639L15.9998 3H8.9998L8.5298 8.639C8.51974 8.74777 8.5407 8.85716 8.59025 8.9545C8.63981 9.05184 8.71593 9.13315 8.8098 9.189C9.2118 9.426 9.9318 9.825 10.4998 10C11.9928 10.46 13.0068 10.46 14.4998 10Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M4.37605 3.435L2.68205 9.365C2.65814 9.44418 2.65175 9.52762 2.66332 9.60951C2.67489 9.69141 2.70414 9.76981 2.74905 9.83927C2.79396 9.90873 2.85345 9.96758 2.92339 10.0117C2.99332 10.0559 3.07203 10.0843 3.15405 10.095C3.99905 10.2 5.48405 10.312 6.50005 10C7.09505 9.817 7.85805 9.387 8.24705 9.155C8.32599 9.10743 8.39241 9.04168 8.44077 8.96323C8.48913 8.88477 8.51803 8.79589 8.52505 8.704L9.00005 3H4.95305C4.82267 2.99997 4.69583 3.04241 4.59172 3.1209C4.48762 3.19939 4.41191 3.30965 4.37605 3.435Z"
      stroke="black"
      strokeWidth="1.5"
    />
    <path
      d="M3.5 10V19C3.5 19.5304 3.71071 20.0391 4.08579 20.4142C4.46086 20.7893 4.96957 21 5.5 21H19.5C20.0304 21 20.5391 20.7893 20.9142 20.4142C21.2893 20.0391 21.5 19.5304 21.5 19V10"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

const OpenEyeSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 4C11.867 4 11.9337 4.00069 12 4.00205C12.0663 4.00069 12.133 4 12.2 4C15.52 4 19.45 6.67 24 12C19.45 17.33 15.52 20 12.2 20C12.133 20 12.0663 19.9993 12 19.9979C11.9337 19.9993 11.867 20 11.8 20C8.48 20 4.55 17.33 0 12C4.55 6.67 8.48 4 11.8 4ZM21.34 12C21.34 12 16 6 12 6.0027C8 6 2.66 12 2.66 12C2.66 12 8 18 12 17.9973C16 18 21.34 12 21.34 12Z"
      fill="black"
    />
    <circle cx="12" cy="12" r="3" stroke="black" strokeWidth="1.6" />
    <rect
      x="1.59961"
      y="4.80005"
      width="1.6"
      height="24"
      transform="rotate(-45 1.59961 4.80005)"
      fill="black"
    />
    <rect
      x="2.73047"
      y="3.6687"
      width="1.6"
      height="24"
      transform="rotate(-45 2.73047 3.6687)"
      fill="white"
    />
  </svg>
);

const CloseEyeSvg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8 4C11.867 4 11.9337 4.00069 12 4.00205C12.0663 4.00069 12.133 4 12.2 4C15.52 4 19.45 6.67 24 12C19.45 17.33 15.52 20 12.2 20C12.133 20 12.0663 19.9993 12 19.9979C11.9337 19.9993 11.867 20 11.8 20C8.48 20 4.55 17.33 0 12C4.55 6.67 8.48 4 11.8 4ZM21.34 12C21.34 12 16 6 12 6.0027C8 6 2.66 12 2.66 12C2.66 12 8 18 12 17.9973C16 18 21.34 12 21.34 12Z"
      fill="black"
    />
    <circle cx="12" cy="12" r="3" stroke="black" strokeWidth="1.6" />
  </svg>
);

const CloseSvg = () => (
  <svg width="48" height="48" viewBox="0 0 48 48">
    <rect width="48" height="48" rx="2" fill="#F5F5F5" />
    <path
      d="M23.998 25.6952L33.049 34.7462L34.746 33.0491L25.6951 23.9982L34.7462 14.9471L33.0491 13.25L23.998 22.3011L14.9471 13.2501L13.25 14.9472L22.301 23.9982L13.2501 33.049L14.9472 34.746L23.998 25.6952Z"
      fill="black"
    />
  </svg>
);

export {
  CheckedSVG,
  ExpandSvg,
  UserInfoSvg,
  UserProfileSvg,
  AddressSvg,
  UserCreditCardSvg,
  WishListSvg,
  OrderSvg,
  PointSvg,
  RecentlyViewSvg,
  MyStoreSvg,
  OpenEyeSvg,
  CloseEyeSvg,
  CloseSvg,
};
