import $ from "jquery";

import "./js/authn.js"; // TODO
import PDP from "./js/pdp.js";
import ProductDetailPopup from "./js/productDetailPopup.js";
import USPPopup from "./js/uspPopup.js";
import FilterProduct from "./js/filterProduct.js";
import Authn from "./js/authn.js";
import PasswordStrengthTester from "./js/passwordStrengthTester.js";
import TopPage from "./js/topPage.js";
import Alert from "./js/alert.js";
import BlogPage from "./js/blogPage.js";
import Faq from "./js/faq.js";
import Anchor from "./js/anchor";
import Contact from "./js/contact.js";
import Newsletter from "./js/newsletter.js";
import AboutPage from "./js/aboutPage.js";
import TextBanner from "./js/textBanner.js";
import InstoreAppHome from "./js/instoreAppHome.js";
import Header from "./js/header.js";
import Container from "./js/container.js";
import Tabs from "./js/tabs.js";
import PLP from "./js/plp";
import Dropdown from "./js/dropdown";
import StickyContent from "./js/StickyContent";
import {
  UploadProductCardData,
  UploadProductsData,
  UploadProductData,
  UploadRecommendsData,
} from "./gtm/uploadDataComponent";
import HeroBanner from "./js/heroBanner";
import BackToTop from "./js/backToTop";
import { VideoBanner } from "./js/videoBanner";
import CategoryLanding from "./js/categoryLanding";
import GA4PageView from "./js/ga4PageView";

import { PREVIEW_MAIN } from "./constants/Misc";
import { Preview } from "./pagebuilder";
$(function () {
  if ($(PREVIEW_MAIN).length !== 0) {
    new Preview().sendMessage("IFRAME_READY");
  }
});

const startIfNeed = (EntryClass, selector) => {
  if ($(selector).length !== 0) {
    new EntryClass();
  }
};

const start = () => {
  const components = [
    [Container, "body"],
    [TopPage, "body"],

    [Header, ".header"],
    [Tabs, ".js-flexible-tabs-container"],
    [InstoreAppHome, ".js-top-level-tile"],
    [TextBanner, ".text-banner-container"],
    [PDP, ".js-pdp-wrapper"],
    [ProductDetailPopup, ".material-desc"],
    [USPPopup, ".usp-wrapper"],
    [UploadProductCardData, ".ga-product-click"],
    [PLP, ".collection.wrapper"],
    [FilterProduct, ".collection.wrapper"],
    [Dropdown, ".js-dropdown__btn"],
    [Authn.Login, ".js-login-form"],
    [Authn.Recover, ".js-forget-pw-form"],
    [Authn.RecoverComplete, ".js-forget-pw-complete-form"],
    [PasswordStrengthTester, ".registration .password-strength"],
    [Alert, ".alert"],
    [BlogPage, ".blog-top-page"],
    [Faq, ".faq"],
    [Contact, ".js-contact-form"],
    [Newsletter, ".js-newsletter-form"],
    [AboutPage, ".wrapper.heritage"],
    [UploadProductsData, ".js-product-list"],
    [UploadProductData, ".js-product-detail"],
    [UploadRecommendsData, ".js-recommendations"],
    [StickyContent, ".pdp-info"],
    [HeroBanner, ".banner-image-picture"],
    [BackToTop, ".js-back-to-top"],
    [VideoBanner, ".js-video-banner-slider"],
    [CategoryLanding, ".js-category-landing"],
    [Anchor, "body"],
    [GA4PageView, "body"],
  ];

  components.forEach(([componentName, selector]) => {
    startIfNeed(componentName, selector);
  });
};

$(start);

export { start };
