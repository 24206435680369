import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.POINTS_WILL_REFRESH });

const refreshSuccess = data => ({ type: types.POINTS_DID_REFRESH, data });

const refreshFailure = error => ({
  type: types.POINTS_DID_REFRESH_FAILED,
  error
});

const switchPointsSubTabs = pathname => ({
  type: types.SWTICH_POINTS_SUB_TABS,
  pathname
});

export { refresh, refreshSuccess, refreshFailure, switchPointsSubTabs };
