import PropTypes from "prop-types";
import React from "react";
import Errors from "../Errors";

// BaseInput is the super class of common input components.
//
// Basically, it is used to unify the onChange callback
// behavior. In order to pass the changed value to the
// onChange callback instead of the event object.
class BaseInput extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    return null;
  }
}

BaseInput.defaultProps = {
  onChange: () => null,
};

BaseInput.propTypes = {
  onChange: PropTypes.func,
};

// Base is the super class of common input with errors
// supported components.
class Base extends BaseInput {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  onChange(event) {
    this.props.onChange(event.target.value);
  }

  onFocus() {
    this.props.onFocus && this.props.onFocus();
  }

  onTouchEnd() {
    this.props.onTouchEnd && this.props.onTouchEnd();
  }

  getErrorsClassName() {
    const { errors } = this.props;
    return errors && errors.length > 0 ? "error" : null;
  }

  renderErrors(props = {}) {
    return (
      <Errors {...props} errors={this.props.errors} msg={this.props.msg} />
    );
  }

  render() {
    return null;
  }
}

Base.defaultProps = {
  ...BaseInput.defaultProps,
  errors: [],
};

Base.propTypes = {
  ...BaseInput.propTypes,
  errors: PropTypes.arrayOf(PropTypes.any),
};

export default Base;

export { BaseInput };
