import { put, call, takeLatest } from "redux-saga/effects";

import * as actions from "../actions/prefectures";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* refresh() {
  try {
    const data = yield call(Api.getPrefectures);
    yield put(actions.refreshSuccess(data));
  } catch (error) {
    yield put(actions.refreshFailure(error));
  }
}

function* watchRefresh() {
  yield takeLatest(types.PREFECTURES_WILL_REFRESH, refresh);
}

export default watchRefresh;

export { refresh };
