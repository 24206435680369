import PropTypes from "prop-types";
import React from "react";

import ZipcodeInput from "./Input/ZipcodeInput";
import SelectInput from "./Input/SelectInput";
import TextInput from "./Input/TextInput";
import PhoneInput from "./Input/PhoneInput";

import Api, { filterValidationErrors } from "../../Api";
import { CheckboxInput } from "../../CheckoutApp/shared/CheckboxInput";
import { errorMessages } from "../../context";

class ZipcodeFinder {
  constructor({
    onSearch = () => new Promise(),
    onFailure = () => {},
    onSuccess = () => {},
  }) {
    this.onSearch = onSearch;
    this.onFailure = onFailure;
    this.onSuccess = onSuccess;
    this.state = { fetching: false, error: null, data: null };
  }

  _willSearch() {
    this.state = { ...this.state, fetching: true, error: null };
  }

  _didSearch(data = {}) {
    this.state = { ...this.state, fetching: false, error: null, data };
  }

  _didSearchFailed(error) {
    this.state = { ...this.state, fetching: false, error };
  }

  getState() {
    return this.state;
  }

  search(zipcode = "") {
    this._willSearch();
    return this.onSearch(zipcode)
      .then((result) => {
        this._didSearch(result);
        this.onSuccess();
      })
      .catch((error) => {
        this._didSearchFailed(error);
        this.onFailure();
      });
  }
}

class Address extends React.Component {
  constructor(props) {
    super(props);

    this.changeField = this.changeField.bind(this);
    this.filterFieldErrors = this.filterFieldErrors.bind(this);
    this.searchZipcode = this.searchZipcode.bind(this);
    this.setShowAddress = this.setShowAddress.bind(this);
    this._clearError = this._clearError.bind(this);

    this.zipcodeFinder = new ZipcodeFinder({
      onSearch: this._onSearchZipcode.bind(this),
      onSuccess: this._onSearchZipcodeSuccess.bind(this),
      onFailure: this._onSearchZipcodeFailed.bind(this),
    });

    this.state = {
      zipcodeInputSearchLinkClassName: "",
      showAddress: this.props.addressOptional ? false : true,
    };
  }

  changeField(field) {
    return (value) => this.props.onChange(field, value);
  }

  filterFieldErrors(field) {
    return this.props.errors[field];
  }

  searchZipcode(e) {
    // prevent default behavior to call submit form
    e.preventDefault();
    return this.zipcodeFinder.search(this.props.value.zipcode);
  }

  _onSearchZipcode(zipcode) {
    this._clearError();
    return Api.getZipcode(zipcode);
  }

  _onSearchZipcodeSuccess() {
    const { prefecture, city } = this.zipcodeFinder.getState().data;
    this.props.onChange("prefecture", prefecture);
    this.props.onChange("city", city);
  }
  _onSearchZipcodeFailed() {
    const { error } = this.zipcodeFinder.getState();
    if (error) {
      const validateError = filterValidationErrors(error, "zipcode");
      this.setState({
        errors: validateError
          ? validateError
          : error.error === "zipcode not found"
          ? ["入力内容を確認してください。"]
          : [errorMessages["http-error"]],
      });
    }
  }
  _clearError() {
    this.setState({ errors: null });
  }

  setShowAddress() {
    this.setState({
      showAddress: !this.state.showAddress,
    });
  }

  render() {
    // Set default value to avoid the following warning when changing the field value.
    // > Warning: TextInput is changing an uncontrolled input of type text to be controlled. Input elements should not switch from uncontrolled to controlled (or vice versa). Decide between using a controlled or uncontrolled input element for the lifetime of the component. More info: https://fb.me/react-controlled-components
    const {
      zipcode = "",
      prefecture = "",
      city = "",
      address1 = "",
      address2 = "",
      phone = "",
      is_default,
    } = this.props.value;
    const { addressOptional, addressTitle, className } = this.props;
    const { showAddress } = this.state;

    return (
      <div className={`address ${className ? className : ""}`}>
        <div
          className={`address-title align-baseline ${
            addressTitle ? "grid-gap" : ""
          }`}
        >
          {addressOptional ? (
            <CheckboxInput
              value={showAddress}
              checked={showAddress}
              onChange={() => this.setShowAddress()}
            />
          ) : null}
          {this.props.noTitle ? null : (
            <p className="title">
              {addressTitle
                ? addressTitle
                : addressOptional
                ? "住所を登録する"
                : "ご住所"}
            </p>
          )}
        </div>
        {showAddress ? (
          <>
            <ZipcodeInput
              value={zipcode}
              name="zipcode"
              onChange={this.changeField("zipcode")}
              errors={this.state.errors || this.filterFieldErrors("zipcode")}
              onSearch={this.searchZipcode}
              touchInput={this._clearError}
            />
            <div className="form-group grid-row">
              <SelectInput
                label="都道府県"
                name="prefecture"
                value={prefecture}
                options={this.props.prefectureOptions}
                blankLabel="都道府県"
                onChange={this.changeField("prefecture")}
                errors={this.filterFieldErrors("prefecture")}
                placeholder={{
                  value: "",
                  coveredValue: "都道府県",
                  position: 0,
                }}
              />
              <TextInput
                label="市区郡町村"
                name="city"
                value={city}
                placeholder="杉並区南田中"
                onChange={this.changeField("city")}
                errors={this.filterFieldErrors("city")}
              />
              <TextInput
                label="番地"
                name="address1"
                value={address1}
                onChange={this.changeField("address1")}
                errors={this.filterFieldErrors("address1")}
                placeholder="3-45-123"
              />
              <TextInput
                label="建物・マンション名（任意）"
                name="address2"
                onChange={this.changeField("address2")}
                errors={this.filterFieldErrors("address2")}
                value={address2}
                placeholder="ハイム杉並"
              />
            </div>
          </>
        ) : null}
        <div className="phone-wrapper grid-row grid-gap">
          <PhoneInput
            value={phone}
            name="phone"
            onChange={this.changeField("phone")}
            errors={this.filterFieldErrors("phone")}
          />
        </div>
        {this.props.hideSelectDefault ? null : (
          <>
            <p className="form-divider" />
            <div className="form-group is_default">
              <CheckboxInput
                checked={!!is_default}
                onChange={this.changeField("is_default")}
                label="通常の送付先アドレスに設定する"
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

Address.defaultProps = {
  value: {},
  errors: {},
};

Address.propTypes = {
  value: PropTypes.object,
  errors: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  prefectureOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onChange: PropTypes.func,
  hideSelectDefault: PropTypes.bool,
  noTitle: PropTypes.bool,
};

export default Address;

export { ZipcodeFinder };
