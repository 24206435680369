import React from "react";

const RemoveFilter = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g>
      <circle fill="#3F3F3F" fillRule="nonzero" cx="10" cy="10" r="10" />
      <path
        d="M5.5,5.5 L13.891,13.891 M13.891,5.5 L5.5,13.891"
        stroke="#FFFFFF"
        strokeWidth="1"
      />
    </g>
  </svg>
);

const ArrowDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 14.2951L7.41 15.7051L12 11.1251L16.59 15.7051L18 14.2951L12 8.29508L6 14.2951Z"
      fill="black"
    />
  </svg>
);

export { RemoveFilter, ArrowDown };
