const toCamelCase = (key, value) => {
  if (value && typeof value === "object") {
    const under_score = /_([a-z])/g;
    for (var k in value) {
      if (under_score.test(k) && Object.hasOwnProperty.call(value, k)) {
        value[
          k.replace(under_score, function(m, w) {
            return w.toUpperCase();
          })
        ] = value[k];
        delete value[k];
      }
    }
  }
  return value;
};

const getStartIndex = () => {
  const $pageDOM = document.querySelector(".pager.js-product-pager");
  const perPage = $pageDOM && $pageDOM.dataset.perpage;
  const $currentPageDOM = document.querySelector(
    ".pager.js-product-pager nav ul li.active"
  );
  const currentPage = $currentPageDOM && $currentPageDOM.innerText;
  const startIndex = Number(perPage) * (Number(currentPage) - 1) || 0;

  return startIndex;
};

export { toCamelCase, getStartIndex };
