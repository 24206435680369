const scrollToFirstError = () => {
  const errorElement = document.querySelector("span.error");

  // if error is inside the sticky element, no need scrolls
  const stickyElement = document.querySelector("#sticky-element");
  if (stickyElement && errorElement && stickyElement.contains(errorElement)) {
    return;
  }

  if (errorElement && errorElement.parentElement) {
    window.scrollBy({
      top: errorElement.parentElement.getBoundingClientRect().top - 95,
      left: 0,
      behavior: "smooth"
    });
  }
};

export { scrollToFirstError };
