import React from "react";
import SearchAndShopList from "./StoreFinder/SearchAndShopList";

const ShopFinderApp = () => {
  return (
    <div className="shop-finder-content">
      <h2 className="shop-finder-title">店舗検索</h2>
      <div className="shop-finder-divider"></div>

      <SearchAndShopList></SearchAndShopList>
    </div>
  );
};

export default ShopFinderApp;
