import React from "react";
import { theplant } from "../../proto";
import { prefectures } from "../../orders/prefectureData";
export type IAddress = theplant.ec.service.users.IAddress;

const AddressDetail = ({
  address,
  noDetailName,
}: {
  address?: IAddress | null;
  noDetailName?: boolean;
}) =>
  address ? (
    <div className="address-detail">
      <p>
        {address.familyName} {address.givenName}
      </p>
      {!noDetailName && (
        <p>
          {address.katakanaFamilyName} {address.katakanaGivenName}
        </p>
      )}
      <p> 〒{address.zipcode} </p>
      <p>
        {
          (
            prefectures.find(
              ({ key, english }) =>
                key === address.prefecture || english === address.prefecture
            ) || {}
          ).name
        }
        {address.city} {address.address1} {address.address2}{" "}
      </p>
      <p>{address.phone}</p>
    </div>
  ) : null;

export default AddressDetail;
