import React from "react";
import { useDate } from "./useDate";

const Description = React.memo(() => {
  const { year, month, day, hour, minutes } = useDate();

  return (
    <div className="description">
      <h4>【注意事項】</h4>
      <p>
        <span className="warning">
          ※表示されている在庫は、{year}年{month}月{day}日{hour}:{minutes}
          時点の情報になります。
        </span>
        <br />
        <span className="warning">
          ※在庫ありの表示でも売り切れの場合がありますので、詳しくは直接店舗にお問い合わせください。
        </span>
        <br />
        ※在庫のない店舗、取り扱いがない店舗は表示されません。
        <br />
        ※店舗にお問合せの際は、商品コードをお伝えください。
        <br />
        ※表示されている価格はオンラインストアでの販売価格です。店頭の価格と異なる場合がございますのでご注意ください。
      </p>
    </div>
  );
});

export { Description };
