import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import $ from "jquery";

import CartItem from "./CartWidgetApp/CartItem";

import { refresh, refreshQuantity } from "../actions/cartItems";
import { mapProtoOrderItemToOrderItemDataProps } from "../mappers/checkout";

import { CHECKOUT_CART_PATH } from "../constants/Paths";
import { DESKTOP_HEADER_BREAKPOINT } from "../constants/Misc";
import { uploadAddToCart } from "../gtm/GA";
import { InlineLoading } from "./AccountApp/Loading";
import { CloseSvg } from "./shared/svg";

class CartWidgetApp extends React.Component {
  isCartFetched = false;

  UNSAFE_componentWillMount() {
    this.props.refreshQuantity();
  }

  uploadAddToCartProductData(cartItems) {
    const $addToCartButton = document.querySelector(".add-to-cart");
    if ($addToCartButton) {
      const currentAddToCartProductArticleCode =
        $addToCartButton.dataset.articlecode;

      if (
        currentAddToCartProductArticleCode &&
        currentAddToCartProductArticleCode !== "undefined"
      ) {
        const cartItem = cartItems.find(
          ({ articleCode }) =>
            articleCode === currentAddToCartProductArticleCode
        );

        if (cartItem) {
          uploadAddToCart(cartItem);
        }

        // clear the articleCode
        $addToCartButton.dataset.articlecode = undefined;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.cartItemNum !== nextProps.cartItemNum &&
      !!nextProps.cartItems &&
      !!nextProps.sumUp
    ) {
      this.emitQubitItemsData(
        nextProps.cartItemNum,
        nextProps.cartItems,
        nextProps.sumUp
      );

      this.uploadAddToCartProductData(nextProps.cartItems);
    }
  }

  _refresh = () => {
    this.props.refresh();
  };

  renderCartItems = () =>
    (this.props.cartItems || [])
      .slice(
        this.props.isMobilePopup ? this.props.cartItems.length - 1 : 0,
        this.props.cartItems.length
      )
      .map((cartItem, idx) => <CartItem key={idx} data={cartItem} />);

  emitQubitItemsData = (cartItemNum, cartItems, sumUp) => {
    var be = window.be;
    if (be) {
      let basketSummary = {
        subtotal: {
          currency: "JPY",
          value: sumUp.quote_amount_with_tax,
        },
        total: {
          currency: "JPY",
          value: sumUp.quote_amount_with_tax,
        },
      };
      if (cartItemNum > 0) {
        cartItems.forEach(function (item) {
          be.emit("ecBasketItem", {
            basket: basketSummary,
            product: {
              sku: item.productCode,
              productId: item.articleCode,
              name: item.productName,
              stock: item.quantityInStock,
              price: {
                currency: "JPY",
                value: item.sellingPrice,
              },
            },
            quantity: item.quantity,
            subtotal: {
              currency: "JPY",
              value: item.actualAmount,
            },
          });
        });
      }
    }
  };

  continueShopping = () => {
    // desktop
    $(".js-header__link-item-cart.is-active").removeClass("is-active");
    // mobile
    $(".js-add-to-cart:visible").removeClass("is-active");
    $(".js-mobile-pdp-popup").removeClass("is-active");
  };

  renderCart = () => {
    if (this.props.loading) {
      return <InlineLoading />;
    }

    return (
      <>
        <ul className="cart-widget__list">{this.renderCartItems()}</ul>
        <a href={CHECKOUT_CART_PATH} className="primary-btn cart-link mb-12">
          カートを見る
        </a>
        <button
          className="continue-shopping js-continue-shopping"
          onClick={this.continueShopping}
        >
          <span>買い物を続ける</span>
        </button>
      </>
    );
  };

  fetchCart = () => {
    if ($(window).width() < DESKTOP_HEADER_BREAKPOINT) {
      return;
    }

    if (!this.isCartFetched) {
      this.isCartFetched = true;
      this._refresh();
    }
  };

  render() {
    const { cartItemNum, isMobilePopup } = this.props;
    return (
      <>
        {isMobilePopup ? null : (
          <>
            <a
              href={CHECKOUT_CART_PATH}
              className="paragraph header__link header__link-cart"
              onMouseEnter={this.fetchCart}
            >
              <span className="header__link-cart-number">{cartItemNum}</span>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 6.5H17C17 3.74 14.76 1.5 12 1.5C9.24 1.5 7 3.74 7 6.5H5C3.9 6.5 3 7.4 3 8.5V20.5C3 21.6 3.9 22.5 5 22.5H19C20.1 22.5 21 21.6 21 20.5V8.5C21 7.4 20.1 6.5 19 6.5ZM12 3.5C13.66 3.5 15 4.84 15 6.5H9C9 4.84 10.34 3.5 12 3.5ZM19 20.5H5V8.5H19V20.5Z" />
              </svg>
            </a>
            <button
              type="button"
              onClick={this._refresh}
              id="cart-widget-app-refresh-trigger"
            />
          </>
        )}
        {cartItemNum > 0 && (
          <div className="cart-widget">
            {isMobilePopup && (
              <div className="popup-title">
                <p className="title">カートに追加済み</p>
                <div className="closeBtn">
                  <CloseSvg />
                </div>
              </div>
            )}
            {this.renderCart()}
          </div>
        )}
        {isMobilePopup ? null : (
          <div className="header__link-tooltip">カート</div>
        )}
      </>
    );
  }
}

CartWidgetApp.defaultProps = {
  refresh: () => null,
  refreshQuantity: () => null,
  cartItems: [],
  loading: false,
  sumUp: {},
  cartItemNum: 0,
  isMobilePopup: false,
};

CartWidgetApp.propTypes = {
  refresh: PropTypes.func,
  refreshQuantity: PropTypes.func,
  cartItems: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  sumUp: PropTypes.object,
  cartItemNum: PropTypes.number,
  isMobilePopup: PropTypes.bool,
};

const mapStateToProps = ({ cartItems: { data, ui } }) => {
  const cartItems = (data.order_items || []).map(
    mapProtoOrderItemToOrderItemDataProps
  );

  // only loaded quantity
  if (data.total_quantity !== undefined) {
    return {
      cartItemNum: data.total_quantity,
      loading: true,
    };
  }

  return {
    cartItems,
    sumUp: data.sum_up,
    loading: ui.fetching,
    cartItemNum: cartItems.reduce((sum, { quantity }) => {
      sum += quantity;
      return sum;
    }, 0),
  };
};

export default connect(mapStateToProps, {
  refresh,
  refreshQuantity,
})(CartWidgetApp);
