import * as React from "react";

import { useHistory } from "react-router-dom";

import cx from "classnames";

type Stages = Array<{
  name: JSX.Element;
  pathname?: string;
}>;

type ProgressBarProps = {
  stages: Stages;
  currentStage: number;
  disabled?: boolean;
};

const ProgressBar = ({ stages, currentStage, disabled }: ProgressBarProps) => {
  const history = useHistory();

  return (
    <div className="checkout-progress-bar">
      <LinkWrapper disabled={disabled}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
            fill="black"
          />
        </svg>
      </LinkWrapper>

      <ul>
        {stages.map(({ name, pathname }, idx) => {
          const active = currentStage === idx + 1;
          const completed = currentStage > idx + 1;

          return (
            <li
              className={cx({ active }, { done: completed })}
              key={`stage-${idx}`}
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (currentStage > idx) {
                  history.push(pathname);
                }
              }}
            >
              STEP{idx + 1}&nbsp;&nbsp;
              {name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const LinkWrapper = ({
  children,
  disabled,
}: {
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  const history = useHistory();
  const handleBack = (e: React.MouseEvent) => {
    e.preventDefault();
    if (disabled) {
      return;
    }
    history.goBack();
  };

  return (
    <div className="back-button" onClick={handleBack}>
      {children}
    </div>
  );
};

export { ProgressBar };
