import React, { useState, useEffect } from "react";
import $ from "jquery";
import { _getURL } from "../../Api";

export type PrefectureProps = {
  prefectures: (string | null)[];
  selectedPrefectures: string[];
  add: (p: string) => void;
  remove: (p: string) => void;
  clearSelectedPrefecture: () => void;
};

const usePrefecture = (display: boolean) => {
  const [prefectures, setPrefectures] = useState<Array<string | null>>([]);

  const [selectedPrefectures, setSelectedPrefectures] = React.useState<
    string[]
  >([]);

  useEffect(() => {
    if (display) {
      $.ajax({
        url: _getURL("/prefectures"),
        xhrFields: {
          withCredentials: true
        },
        success: prefectures => {
          let result: Array<string> = [];
          prefectures.forEach((p: { name: string }) => {
            if (p && p.name) {
              result.push(p.name);
            }
          });
          if (result) {
            setPrefectures(result);
          }
        }
      }).fail(e => {
        console.log("get prefectures failed, ", e);
      });
    }
  }, [display]);

  const add = (p: string) => {
    if (selectedPrefectures.indexOf(p) === -1) {
      setSelectedPrefectures(selectedPrefectures.concat([p]));
    }
  };

  const remove = (p: string) => {
    setSelectedPrefectures(
      selectedPrefectures.filter(prefecture => prefecture !== p)
    );
  };

  const clearSelectedPrefecture = () => {
    setSelectedPrefectures([]);
  };

  return {
    prefectures,
    selectedPrefectures,
    add,
    remove,
    clearSelectedPrefecture
  };
};

export { usePrefecture };
