import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const initialState = {
  ids: [],
  data: {},
  ui: {
    fetching: false,
    error: null
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.SHIPPING_ADDRESSES_WILL_REFRESH:
    case types.SHIPPING_ADDRESSES_WILL_DELETE:
      return { ...s, fetching: true, error: null };
    case types.SHIPPING_ADDRESSES_DID_REFRESH_FAILED:
    case types.SHIPPING_ADDRESSES_DID_DELETE_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.SHIPPING_ADDRESSES_DID_REFRESH:
    case types.SHIPPING_ADDRESSES_DID_DELETE:
      return { ...s, fetching: false, error: null };
    default:
      return s;
  }
};

const ids = (s = initialState.ids, a) => {
  switch (a.type) {
    case types.SHIPPING_ADDRESSES_DID_REFRESH:
      return (a.data || []).map(r => r.id);
    case types.SHIPPING_ADDRESSES_UPDATE:
      return s.includes(a.data.id) ? s : s.concat(a.data.id);
    case types.SHIPPING_ADDRESSES_DID_DELETE:
      let newState = s.slice();
      const index = s.indexOf(a.id);
      if (index >= 0) {
        newState.splice(index, 1);
      }
      return newState;
    default:
      return s;
  }
};

const data = (s = initialState.data, a) => {
  switch (a.type) {
    case types.SHIPPING_ADDRESSES_DID_REFRESH:
      return (a.data || []).reduce((result, r) => {
        result[r.id] = r;
        return result;
      }, s);
    case types.SHIPPING_ADDRESSES_UPDATE:
      s[a.data.id] = a.data;
      return s;
    default:
      return s;
  }
};

export default combineReducers({ ui, ids, data });

const getShippingAddressById = (state, id) => state.shippingAddresses.data[id];

export { initialState, getShippingAddressById };
