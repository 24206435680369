import React, { useEffect } from "react";
import { connect } from "react-redux";

import Order, { OrderDataPropType } from "./Orders/Order";
import { getOrders } from "../helpers/orders";
import { mapToNameAndJapanese } from "../helpers/prefectures";
import { refresh, cancel } from "../../actions/orders";
import { mapProtoOrderToOrderDataProp } from "../../mappers/orders";

const styles: { [key: string]: React.CSSProperties } = {
  emptyContent: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "100px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "30px",
    paddingBottom: "0px",
    fontSize: "16px",
    lineHeight: "28px",
  },
  description: {
    margin: "48px 15px 24px",
    textAlign: "center",
  },
};
const EmptyContent = () => (
  <div style={styles.emptyContent} className="white-panel">
    <p>
      旧サイト（2017年2月17日以前）でご購入いただきました商品の購入履歴は引き継がれません。
    </p>
  </div>
);

const OrderDescription = () => (
  <div style={styles.description || ""} className="order-description">
    <p>
      株式会社 ラコステ ジャパン　
      <br className="sp-only" />
      適格請求書発行事業者番号：T8-0110-0104-7245
      <br />
      ※消費税率10%　
      <br className="sp-only" />
      ※こちらはインボイス制度のインボイスにあたります。
    </p>
  </div>
);

const Orders = (props: Props) => {
  const { orders, cancel, refresh } = props;
  useEffect(() => {
    refresh();
    // eslint-disable-next-line
  }, []);

  const cancelOrder = (id: String) => {
    return () => cancel(id);
  };

  return (
    <>
      <div className="orders-content">
        {orders.length === 0 ? (
          <EmptyContent />
        ) : (
          orders.map((order, idx) => (
            <Order key={idx} data={order} cancel={cancelOrder(order.id)} />
          ))
        )}
      </div>
      <OrderDescription />
    </>
  );
};

Orders.defaultProps = {
  refresh: () => null,
  orders: [],
};

type Props = {
  orders: Array<OrderDataPropType>;
  fetching: boolean;
  refresh: () => {};
  cancel: (id: String) => {};
};

const mapStateToProps = ({ orders, prefectures }: any) => {
  const prefectureNameToJapaneseMap = mapToNameAndJapanese(prefectures.data);

  return {
    orders: getOrders(orders).map((order) =>
      mapProtoOrderToOrderDataProp(order, prefectureNameToJapaneseMap)
    ),
    fetching: orders.ui.fetching,
  };
};

export default connect(mapStateToProps, { refresh, cancel })(Orders as any);

export { Orders, EmptyContent };
