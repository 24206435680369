import Cookie from "js-cookie";
import uuidv4 from "uuid/v4";

const COOKIE_KEY = "silveregg";

const getSilvereggCookie = () => {
  const value = Cookie.get(COOKIE_KEY);
  if (value) {
    return value;
  }

  const uuid = uuidv4();
  Cookie.set(
    COOKIE_KEY,
    uuid,
    Object.assign({}, [], {
      // set expires time to 1 year
      // refer: https://theplant.slack.com/archives/C2R8Q1LR2/p1586142342144600?thread_ts=1585548195.062200&cid=C2R8Q1LR2
      expires: new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000),
    })
  );
  return uuid;
};

export { getSilvereggCookie };
