import * as React from "react";
import { FieldProps } from "../../form";
import { Errors } from "./Errors";
import TextField from "@material-ui/core/TextField";
import AutoComplete from "@material-ui/lab/Autocomplete";
import NativeSelect from "@material-ui/core/NativeSelect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { useIsMobile } from "../../components/hooks";

type Option = {
  value: string;
  name: string;
  key: string;
  english: string;
};

export type Props = FieldProps<string, any> & {
  type?: "text" | "password";
  placeholder?: { value: string; position?: number; hidden?: boolean };
  maxLength?: number;
  mask?: (string | RegExp)[];
  onKeyPressEnter?: () => void;
  autoFocus?: boolean;
  msg?: string;
  label?: string;
  name: string;
  onlyNumberInput?: boolean;
  notUpdateOnTouch?: boolean;
  options: Option[];
  registerPageUseEnglishPrefecture?: boolean;
  disableSearch?: boolean;
};

const renderOptions = ({
  placeholder,
  options,
}: Pick<Props, "placeholder"> & {
  options: Option[];
}) => {
  if (!placeholder) {
    return options.map(({ key, name }) => (
      <option key={key} value={key}>
        {name}
      </option>
    ));
  } else {
    // Default on top.
    const placeholderPosition = placeholder.position || 0;

    return (
      <>
        {options.slice(0, placeholderPosition).map(({ key, name }) => (
          <option key={key} value={key}>
            {name}
          </option>
        ))}
        <option hidden={placeholder.hidden} value={placeholder.value}>
          {placeholder.value}
        </option>
        {options
          .slice(placeholder.position, options.length)
          .map(({ key, name }) => (
            <option key={key} value={key}>
              {name}
            </option>
          ))}
      </>
    );
  }
};

const Selector = ({
  id,
  value,
  updateInput,
  touchInput,
  name,
  dirty,
  errors,
  onlyNumberInput,
  notUpdateOnTouch,
  options,
  placeholder,
  label,
  maxLength,
  msg,
  className,
  registerPageUseEnglishPrefecture,
  disabled,
  onOpen,
  disableSearch,
}: Props) => {
  if (name === "prefecture") {
    options = options.map((option: Option) => ({
      ...option,
      key: option.english,
    }));
  }

  // find by value or name, refer: https://theplanttokyo.atlassian.net/browse/LAX-3919
  const find = options.find(
    ({ key, name }: Option) => key === value || name === value
  );

  const placeholderPosition = (placeholder || {}).position || 0;
  let newOptions: Props["options"];
  if (placeholder) {
    newOptions = newOptions = options.slice(0, placeholderPosition);
    newOptions = newOptions.concat([
      {
        key: "placeholder",
        name: (placeholder || {}).value || "",
      },
    ]);
    newOptions = newOptions.concat(
      options.slice(placeholder.position, options.length)
    );
  } else {
    newOptions = options;
  }

  const hasError = dirty ? (errors || []).length > 0 : false;

  const disableAutoComplete = useIsMobile() || disableSearch;

  return disableAutoComplete ? (
    <FormControl fullWidth className={className}>
      <InputLabel variant="standard" htmlFor="uncontrolled-native">
        {label}
      </InputLabel>
      <NativeSelect
        defaultValue={value}
        key={value}
        inputProps={{
          name,
          id: "uncontrolled-native",
        }}
        disabled={disabled}
        onChange={({ target: { value } }) => {
          if (value) {
            updateInput(value);
            if (touchInput) {
              touchInput();
            }
          } else {
            updateInput("");
          }
        }}
        error={hasError}
      >
        {renderOptions({ placeholder, options })}
      </NativeSelect>
      {hasError && (
        <p className="MuiFormHelperText-root Mui-error">
          <Errors errors={dirty ? errors : []} msg={msg} />
        </p>
      )}
    </FormControl>
  ) : (
    <AutoComplete
      key={JSON.stringify(find)}
      id={id}
      freeSolo
      forcePopupIcon
      options={newOptions}
      className={className}
      value={find}
      defaultValue={find}
      placeholder={placeholder || label}
      onChange={(_, newValue) => {
        if (newValue && (newValue as { key: string }).key) {
          updateInput((newValue as { key: string }).key);
          if (touchInput) {
            touchInput();
          }
        } else {
          // clear value
          updateInput("");
        }
      }}
      onOpen={onOpen}
      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (onlyNumberInput) {
          if (
            e.charCode < 48 || // 0
            e.charCode > 57 // 9
          ) {
            e.preventDefault();
          }
        }
      }}
      onBlur={({ target }) => {
        const value = (target as any).value;
        if (notUpdateOnTouch) {
          return;
        }

        if (value) {
          updateInput(value);
        } else {
          updateInput("");
        }
      }}
      getOptionLabel={(option) =>
        (option as { name: string }).name
          ? (option as { name: string }).name
          : ""
      }
      renderOption={(props) => (
        <div {...props} data-name={props.name}>
          {props.name}
        </div>
      )}
      renderInput={(params) => (
        <>
          {registerPageUseEnglishPrefecture && (
            <input name={name} value={find && find.key} type="hidden" />
          )}
          <TextField
            {...params}
            name={registerPageUseEnglishPrefecture ? undefined : name}
            label={label}
            inputProps={{
              ...params.inputProps,
              maxLength,
            }}
            error={hasError}
            variant="standard"
            helperText={<Errors errors={dirty ? errors : []} msg={msg} />}
          />
        </>
      )}
      getOptionDisabled={(option) => (option as any).key === "placeholder"}
    />
  );
};

export { Selector };
