import { gtmDataLayerPush } from ".";
import { theplant } from "../proto";

import {
  getImpresssion,
  getImpresssions,
  getDataFromOrderItem,
  getUploadTransactionData,
  getUploadDataFormOrder,
} from "./helper";

import { UploadProductProps, IImpression } from "./GAtype";
import { currencyCode, brand } from "./constants";

import { useEffect } from "react";
import { CheckoutProps } from "./GAtype";
import {
  withRecordOrderSentInBrowser,
  withRecordOrderSentInBrowserProps,
} from "../withRecordOrderSentInBrowser";

import { pushDataForGA4, event, Data } from "./GA4";

const uploadEcommerceData = ({
  event,
  data,
}: {
  event?: string;
  data: object;
}) => {
  gtmDataLayerPush({
    event,
    ecommerce: {
      currencyCode,
      ...data,
    },
  });
};

type IProduct = theplant.ec.service.products.IProduct;

const uploadPLPToGA = ({
  products,
  startIndex,
}: {
  products: IProduct[];
  startIndex: number;
}) => {
  // google analytics has max-length limit
  // pass product impression data by batch, 24 per group
  // refer: https://theplanttokyo.atlassian.net/browse/LAX-2239
  if (products) {
    const length = products.length;
    const batchLength = 24;
    let batchLevel = 0;

    for (let i = 0; i < length; i += batchLength) {
      batchLevel = Math.floor(i / batchLength);

      const impressions = getImpresssions({
        products: products.slice(i, i + batchLength),
        startIndex: startIndex + batchLevel * batchLength,
      });

      const plpListName = () => {
        let listname = "";
        const $list_name =
          (document.querySelector(
            ".js-product-list-header h1"
          ) as HTMLElement) ||
          (document.querySelector(
            ".product-listing-container > .h2"
          ) as HTMLElement);
        if ($list_name) {
          listname = $list_name.innerText;
        }
        return listname;
      };

      const listname = plpListName();

      uploadEcommerceData({
        event: "EEproductImpressions",
        data: {
          impressions,
        },
      });

      const pathname = window.location.pathname;

      impressions.forEach((item) => {
        item.list =
          pathname === "/"
            ? "home | recommendation"
            : pathname.indexOf("/products") > -1
            ? "product | recommendation"
            : pathname.indexOf("/search") > -1
            ? "search"
            : item.list;
        item.listname =
          pathname === "/"
            ? "recommendations"
            : pathname.indexOf("/products") > -1
            ? "recommendations"
            : pathname.indexOf("/search") > -1
            ? "instant search results"
            : listname;
        item.brand = item.size;
        item.item_category3 = item.gender;
        item.item_category5 = item.variant;
        item.variant = item.color;

        item.quantity = 1;
        item.coupon = "";

        delete item.color;
        delete item.size;
      });

      pushDataForGA4({
        event: event.view_item_list,
        data: impressions,
      });
    }
  }
};

const uploadViewedProductsToGA = (product: [IImpression]) => {
  pushDataForGA4({
    event: event.view_item_list,
    data: product,
  });
};

const uploadPLPProductClick = (product: IImpression) => {
  uploadEcommerceData({
    event: "EEproductClick",
    data: {
      click: {
        actionField: { list: product.list },
        products: [
          {
            ...product,
            brand,
          },
        ],
      },
    },
  });

  pushDataForGA4({
    event: event.select_item,
    data: [
      {
        ...product,
        brand: product.brand || brand,
      },
    ],
  });
};

const uploadPDPData = ({
  product,
  colorCode,
}: {
  product: IProduct;
  colorCode?: string | null;
}) => {
  uploadEcommerceData({
    event: "EEproductView",
    data: {
      detail: {
        products: [getImpresssion({ product, colorCode })],
      },
    },
  });

  const data = [getImpresssion({ product, colorCode }) as Data];
  data.forEach((item) => {
    item.index = 1;
    item.quantity = 1;
    item.coupon = "";
    item.brand = item.size;
    item.item_category3 = item.gender;
    item.item_category5 = item.variant;
    item.variant = item.color;

    delete item.color;
    delete item.size;
  });

  pushDataForGA4({
    event: event.view_item,
    data,
    currency: currencyCode,
    value: calcTotalPrice([
      getImpresssion({ product, colorCode }) as Data,
    ] as UploadProductProps[]),
  });
};

type IOrderItem = theplant.ec.service.orders.IOrderItem;

const uploadAddToCart = (item: IOrderItem, quantity?: number) => {
  let orderItem = getDataFromOrderItem({
    item,
  });

  if (quantity) {
    orderItem.quantity = quantity;
  }

  EEaddToCart([orderItem]);
};

const calcTotalPrice = (products: UploadProductProps[]) => {
  let totalPrice = 0;
  products.forEach((item) => {
    const price = item.price ? item.price : 0;
    const discount = item.discount ? item.discount : 0;
    totalPrice += (price - discount) * (item.quantity ? item.quantity : 1);
  });
  return totalPrice;
};

const EEaddToCart = (products: IImpression[]) => {
  uploadEcommerceData({
    event: "EEaddToCart",
    data: {
      add: {
        products,
      },
    },
  });

  products.forEach((item) => {
    item.position = 1;
    item.coupon = "";
    item.brand = item.size;
    item.item_category3 = item.gender;
    item.item_category5 = item.variant;
    item.variant = item.color;

    delete item.color;
    delete item.size;
  });

  pushDataForGA4({
    event: event.add_to_cart,
    data: products,
    currency: currencyCode,
    value: calcTotalPrice(products),
  });
};

const uploadRemoveFormCart = (item: IOrderItem, quantity?: number) => {
  let orderItem = getDataFromOrderItem({
    item,
  });

  if (quantity) {
    orderItem.quantity = quantity;
  }

  uploadEcommerceData({
    event: "EEremoveFromCart",
    data: {
      remove: {
        products: [orderItem],
      },
    },
  });

  const products = [orderItem] as IImpression[];

  products.forEach((item) => {
    item.position = 1;
    item.list = "cart";
    item.listname = "カート";

    item.brand = item.size;
    item.item_category3 = item.gender;
    item.item_category5 = item.variant;
    item.variant = item.color;

    delete item.color;
    delete item.size;
  });

  pushDataForGA4({
    event: event.remove_from_cart,
    data: products,
  });
};

const uploadCartItemQuantityChangeToGTM = ({
  orderItem,
  originalQuantity,
  updateQuantity,
}: {
  orderItem: IOrderItem;
  originalQuantity: number;
  updateQuantity: number;
}) => {
  const changedQuantity = Math.abs(updateQuantity - originalQuantity);
  if (updateQuantity > originalQuantity) {
    uploadAddToCart(orderItem, changedQuantity);
  } else if (updateQuantity < originalQuantity) {
    uploadRemoveFormCart(orderItem, changedQuantity);
  }
};

const UploadCheckoutProgressDataToGTM = (
  props: CheckoutProps & { fieldStep: number }
) => {
  useEffect(() => {
    const { orderECPurchaseItems, coupon } = getUploadDataFormOrder(
      props.order
    );

    const value = props.order?.sumUp?.billingAmountWithTax;

    uploadEcommerceData({
      event: "checkout",
      data: {
        checkout: {
          actionField: {
            step: props.fieldStep,
            coupon,
          },
          products: orderECPurchaseItems,
        },
      },
    });

    // GA4 view_cart
    if (props.fieldStep === 1) {
      const data = (orderECPurchaseItems as Data[]) || [];

      data.forEach((item) => {
        item.item_list_id = "cart";
        item.item_list_name = "カート";

        item.brand = item.size;
        item.item_category3 = item.gender;
        item.item_category5 = item.variant;
        item.variant = item.color;

        delete item.color;
        delete item.size;
      });

      pushDataForGA4({
        event: event.view_cart,
        data,
        currency: currencyCode,
        value: Number(value),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const getDeliveryMethod = (value: any) => {
  return value === theplant.ec.api.orders.DeliveryMethod.HOME_DELIVERY
    ? "home-delivery"
    : value === theplant.ec.api.orders.DeliveryMethod.STORE_PICKUP
    ? "store-pickup"
    : value === theplant.ec.api.orders.DeliveryMethod.CONVENIENCE_PICKUP
    ? "convenience-store"
    : "";
};

const getPaymentType = (value: any) => {
  return value === theplant.ec.service.orders.PaymentType.CREDIT_CARD
    ? "credit-card"
    : value === theplant.ec.service.orders.PaymentType.COD
    ? "cash"
    : value === theplant.ec.service.orders.PaymentType.AMAZON_PAY
    ? "amazon-pay"
    : "";
};

const getDeliveryFee = (order: IOrderItem | any) => {
  const item = order?.sumUp?.subItems.find((item: any) => {
    return item.name === "DELIVERY_FEE";
  });
  return Number(item?.amount) || 0;
};

const UploadCheckoutEventDataToGTM = ({
  order,
  eventName,
  deliveryMethod,
  paymentType,
  giftWrapping,
  checkoutType,
}: {
  order: IOrderItem | any;
  eventName: string;
  deliveryMethod?: any;
  paymentType?: any;
  giftWrapping?: any;
  checkoutType?: string;
}) => {
  const { orderECPurchaseItems } = getUploadDataFormOrder(order);

  const data = (orderECPurchaseItems as Data[]) || [];

  data.forEach((item) => {
    item.brand = item.size;
    item.item_category3 = item.gender;
    item.item_category5 = item.variant;
    item.variant = item.color;

    delete item.color;
    delete item.size;
  });

  const ecommerce = {
    value: order?.sumUp?.billingAmountWithTax,
    shipping: getDeliveryFee(order),
    shipping_tier: getDeliveryMethod(deliveryMethod),
    payment_type: getPaymentType(paymentType),
    gift_box: giftWrapping?.specified ? 1 : 0,
    payment_surcharge: order?.feeSetting?.codFee,
    loyalty_discount: Number(order?.pointsUsed),
    coupon: order?.couponsUsed.join(","),
    coupon_value:
      order?.sumUp?.discounts &&
      order?.sumUp?.discounts.reduce(
        (acc: number, cur: { [x: string]: any; amount: any }) =>
          acc + (cur["isCoupon"] ? Number(cur.amount) : 0),
        0
      ),
  };

  if (eventName === event.begin_checkout) {
    pushDataForGA4({
      event: event.begin_checkout,
      checkout_type: checkoutType,
      checkout_step:
        checkoutType === "amazon-pay" ? "checkout amazonpay" : "checkout cart",
      data,
      currency: currencyCode,
      value: ecommerce.value,
      shipping: ecommerce.shipping,
      coupon: ecommerce.coupon,
      coupon_value: ecommerce.coupon_value || 0,
    });
  }

  if (eventName === event.add_shipping_info) {
    pushDataForGA4({
      event: event.add_shipping_info,
      checkout_step: "checkout shipping",
      data,
      currency: currencyCode,
      value: ecommerce.value,
      shipping: ecommerce.shipping,
      shipping_tier: ecommerce.shipping_tier,
      gift_box: ecommerce.gift_box,
      coupon: ecommerce.coupon,
      coupon_value: ecommerce.coupon_value || 0,
    });
  }
};

const UploadConfirmDataToGTM = withRecordOrderSentInBrowser(
  ({
    orderFirstSent,
    ...props
  }: CheckoutProps & withRecordOrderSentInBrowserProps) => {
    useEffect(() => {
      const purchase = getUploadTransactionData(props.order);

      const ecommerce = {
        shipping_tier: getDeliveryMethod(props.checkout?.deliveryMethod),
        payment_type: getPaymentType(props.checkout?.paymentType),
        gift_box: props.checkout?.giftWrapping?.specified ? 1 : 0,
        transaction_id: props.order?.code,
        shipping: getDeliveryFee(props.order),
        coupon: props.checkout?.couponCode,
        coupon_value:
          props.order?.sumUp?.discounts &&
          props.order?.sumUp?.discounts.reduce(
            (acc, cur) => acc + (cur["isCoupon"] ? Number(cur.amount) : 0),
            0
          ),
        payment_surcharge: Number(props.data?.feeSetting?.codFee),
        loyalty_discount: Number(props.order?.pointsUsed),
      };

      const orderCode = props.order?.code;

      if (orderCode && orderFirstSent && orderFirstSent(orderCode)) {
        uploadEcommerceData({
          event: "checkout",
          data: {
            purchase,
          },
        });

        const data = (purchase?.products as Data[]) || [];

        data.forEach((item) => {
          item.brand = item.size;
          item.item_category3 = item.gender;
          item.item_category5 = item.variant;
          item.variant = item.color;

          delete item.color;
          delete item.size;
        });

        pushDataForGA4({
          event: event.purchase,
          checkout_step: "checkout order confirmation",
          data,
          currency: currencyCode,
          transaction_id: ecommerce.transaction_id,
          value: Number(props.order?.sumUp?.billingAmountWithTax),
          subtotal_value:
            Number(props.order?.sumUp?.quoteAmount || 0) -
            (ecommerce.coupon_value || 0) -
            (ecommerce.loyalty_discount || 0),
          // affiliation: purchase?.actionField.affiliation,
          coupon: ecommerce.coupon,
          coupon_value: ecommerce.coupon_value || 0,
          shipping: ecommerce.shipping,
          tax: purchase?.actionField.tax,
          shipping_tier: ecommerce.shipping_tier,
          payment_type: ecommerce.payment_type,
          gift_box: ecommerce.gift_box,
          payment_surcharge: ecommerce.payment_surcharge,
          loyalty_discount: ecommerce.loyalty_discount,
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.order]);

    return null;
  }
);

const UploadConfirmOrderToGTM = ({ ...props }: CheckoutProps) => {
  const purchase = getUploadTransactionData(props.order);

  const ecommerce = {
    shipping_tier: getDeliveryMethod(props.checkout?.deliveryMethod),
    payment_type: getPaymentType(props.checkout?.paymentType),
    gift_box: props.checkout?.giftWrapping?.specified ? 1 : 0,
    shipping: getDeliveryFee(props.order),
    coupon: props.checkout?.couponCode,
    coupon_value:
      props.order?.sumUp?.discounts &&
      props.order?.sumUp?.discounts.reduce(
        (acc, cur) => acc + (cur["isCoupon"] ? Number(cur.amount) : 0),
        0
      ),
    payment_surcharge: Number(props.data?.feeSetting?.codFee),
    loyalty_discount: Number(props.order?.pointsUsed),
  };

  const data = (purchase?.products as Data[]) || [];

  data.forEach((item) => {
    item.brand = item.size;
    item.item_category3 = item.gender;
    item.item_category5 = item.variant;
    item.variant = item.color;

    delete item.color;
    delete item.size;
  });

  pushDataForGA4({
    event: event.add_payment_info,
    checkout_step: "checkout payment",
    data,
    currency: currencyCode,
    value: Number(props.order?.sumUp?.billingAmountWithTax),
    coupon: ecommerce.coupon,
    coupon_value: ecommerce.coupon_value || 0,
    shipping: ecommerce.shipping,
    shipping_tier: ecommerce.shipping_tier,
    payment_type: ecommerce.payment_type,
    gift_box: ecommerce.gift_box,
    payment_surcharge: ecommerce.payment_surcharge,
    loyalty_discount: ecommerce.loyalty_discount,
  });
};

const registerSuccess = () => {
  gtmDataLayerPush({
    event: "ExtraEvent",
    originalevent: "MemberSignUp",
    eventlabel: "MemberSignUp",
  });
};

const UploadCartCoupon = (code: string) => {
  gtmDataLayerPush({
    ecommerce: null,
  });

  gtmDataLayerPush({
    event: "checkout_apply_coupon",
    coupon: code,
  });
};

const UploadCheckoutEmail = () => {
  gtmDataLayerPush({
    ecommerce: null,
  });

  gtmDataLayerPush({
    event: "checkout_enter_email",
  });
};

const UploadGiftBox = (checked: boolean) => {
  gtmDataLayerPush({
    ecommerce: null,
  });

  gtmDataLayerPush({
    event: checked ? "checkout_add_gift_box" : "checkout_remove_gift_box",
  });
};

const UploadSavePayment = (checked: boolean) => {
  gtmDataLayerPush({
    ecommerce: null,
  });

  gtmDataLayerPush({
    event: "checkout_save_payment_info",
    value: checked ? 1 : 0,
  });
};

const UploadEnterShippingAddress = () => {
  gtmDataLayerPush({
    ecommerce: null,
  });

  gtmDataLayerPush({
    event: "checkout_enter_shipping_address",
  });
};

export {
  uploadEcommerceData,
  uploadPLPToGA,
  uploadPLPProductClick,
  uploadPDPData,
  EEaddToCart,
  uploadAddToCart,
  uploadRemoveFormCart,
  uploadCartItemQuantityChangeToGTM,
  UploadCheckoutProgressDataToGTM,
  UploadCheckoutEventDataToGTM,
  UploadConfirmDataToGTM,
  registerSuccess,
  UploadCartCoupon,
  UploadCheckoutEmail,
  UploadGiftBox,
  UploadSavePayment,
  UploadEnterShippingAddress,
  UploadConfirmOrderToGTM,
  uploadViewedProductsToGA,
};
