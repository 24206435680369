import React from "react";
import Modal from "react-modal";
import { CloseSvg as PopupCloseSvg } from "../shared/svg";

export type Props = {
  isOpen: boolean;
  close: () => void;
  children?: React.ReactNode;
  title?: string;
  isConfirmPopup?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  confirmTitle?: string;
};

const Popup = ({
  isOpen,
  close,
  children,
  title,
  isConfirmPopup,
  onConfirm,
  confirmText,
  confirmTitle,
}: Props) => (
  <Modal
    isOpen={isOpen}
    contentLabel={title}
    overlayClassName="modal-overlay"
    className="popup-modal modal-content-wrapper"
    onRequestClose={close}
    closeTimeoutMS={300}
  >
    {/* eslint-disable-next-line */}
    {title && <p className="title">{title}</p>}
    <div className="close-wrapper" onClick={close}>
      <PopupCloseSvg />
    </div>
    {confirmTitle && <p className="confirmTitle">{confirmTitle}</p>}
    {children}
    {isConfirmPopup && (
      <div className="button-area grid-row-2-only">
        <button className="outline-btn" onClick={close}>
          キャンセル
        </button>
        <button className="primary-btn " onClick={onConfirm}>
          {confirmText}
        </button>
      </div>
    )}
  </Modal>
);

export default Popup;
