import { put, call, select, takeLatest, all } from "redux-saga/effects";

import * as actions from "../actions/accountWishItems";
import { deleteWishItems as didDeleteWishItems } from "../actions/wishItems";
import { getCheckedIds } from "../reducers/accountWishItems";
import * as types from "../constants/ActionTypes";
import { alert, redirect } from "./common";
import Api from "../Api";
import * as messages from "../constants/Messages";
import * as paths from "../constants/Paths";

function* deleteWishItems() {
  const checkedIds = yield select(getCheckedIds);
  const { error } = yield call(Api.deleteWishItems, checkedIds);

  if (error) {
    yield put(actions.deleteFailure(error));
    // FIXME shouldn't handle some API error?
    yield call(alert, messages.INTERNAL_SERVER_ERROR);
  } else {
    yield put(actions.deleteSuccess());
    yield put(didDeleteWishItems(checkedIds));
  }
}

function* moveWishItemsToCart() {
  const checkedIds = yield select(getCheckedIds);
  const { error } = yield call(Api.moveWishItemsToCart, checkedIds);

  if (error) {
    yield put(actions.moveToCartFailure(error));
    // FIXME shouldn't handle some API error?
    yield call(alert, messages.INTERNAL_SERVER_ERROR);
  } else {
    yield put(actions.moveToCartSuccess());
    yield put(didDeleteWishItems(checkedIds));
    yield call(redirect, paths.CHECKOUT_CART_PATH);
  }
}

function* watch() {
  yield all([
    takeLatest(types.ACCOUNT_WISH_ITEMS_WILL_DELETE, deleteWishItems),
    takeLatest(types.ACCOUNT_WISH_ITEMS_WILL_MOVE_TO_CART, moveWishItemsToCart)
  ]);
}

export default watch;

export { deleteWishItems, moveWishItemsToCart };
