import * as types from "../constants/ActionTypes";

const toggleChecked = id => ({
  type: types.ACCOUNT_WISH_ITEMS_TOGGLE_CHECKED,
  id
});

const deleteWishItems = () => ({ type: types.ACCOUNT_WISH_ITEMS_WILL_DELETE });

const deleteSuccess = (ids = []) => ({
  type: types.ACCOUNT_WISH_ITEMS_DID_DELETE
});

const deleteFailure = error => ({
  type: types.ACCOUNT_WISH_ITEMS_DID_DELETE_FAILED,
  error
});

const moveToCart = () => ({ type: types.ACCOUNT_WISH_ITEMS_WILL_MOVE_TO_CART });

const moveToCartSuccess = (ids = []) => ({
  type: types.ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART
});

const moveToCartFailure = error => ({
  type: types.ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART_FAILED,
  error
});

export {
  toggleChecked,
  deleteWishItems,
  deleteSuccess,
  deleteFailure,
  moveToCart,
  moveToCartSuccess,
  moveToCartFailure
};
