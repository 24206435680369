import "imports-loader?define=>false!jquery-validation"; // eslint-disable-line import/no-webpack-loader-syntax
import "imports-loader?define=>false!jquery-validation/dist/localization/messages_ja.js"; // eslint-disable-line import/no-webpack-loader-syntax
import $ from "jquery";
import React from "react";
import Validator from "./validator.js";
import Api from "../Api";
import Popup from "./shared/Popup";
import { RECOVER_PATH } from "../constants/Paths";
import { registerSuccess } from "../gtm/GA";
import { REGISTER_SUCCESS_PATH } from "../constants/Paths";

class Utils {
  // Copy from http://stackoverflow.com/questions/19491336/get-url-parameter-jquery-or-how-to-get-query-string-values-in-js
  static getUrlParameter(sParam) {
    let sPageURL = decodeURIComponent(window.location.search.substring(1));
    let sURLVariables = sPageURL.split("&");

    for (let i = 0; i < sURLVariables.length; i++) {
      let sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? undefined : sParameterName[1];
      }
    }
  }

  // For authboss redirection after login.
  static addRedirToElementAttr($element, attrName) {
    let redir = Utils.getUrlParameter("redir");
    if (redir !== undefined) {
      let attrVal = $element.attr(attrName);
      $element.attr(attrName, attrVal + "?redir=" + redir);
    }
  }
}

class Login {
  constructor() {
    this.$loginForm = $(".js-login-form");
    this.$headerLoginForm = $(".js-header-login-form");
    this.$registerButton = $(".js-register-button");
    this.$resetPasswordPopup = new Popup({
      wrapperElementID: "existing-account-reset-password-message",
      contentClassName: "login-message",
      contentHtml: `<h3>大切なお知らせ</h3>
<p>旧サイト（2017 年 2 月 17 日以前）から会員登録いただきましたお客様は、パスワードが引き継がれません。</p>
<a href="${RECOVER_PATH}">こちらよりリセットしてください</a>`,
    });

    this.popUpResetPasswordMessageIfExistingAccount =
      this.popUpResetPasswordMessageIfExistingAccount.bind(this);

    Validator.addCustomValidate();
    this.addLoginValidation();
    Utils.addRedirToElementAttr(this.$loginForm, "action");
    Utils.addRedirToElementAttr(this.$registerButton, "href");
    this.addURLPathToHeaderFormAsRedirectPath();
  }

  addURLPathToHeaderFormAsRedirectPath() {
    if (Utils.getUrlParameter("redir") === undefined) {
      const actionValue = this.$headerLoginForm.attr("action");
      this.$headerLoginForm.attr(
        "action",
        actionValue + "?redir=" + decodeURIComponent(window.location.pathname)
      );
    }
  }

  addLoginValidation() {
    var _this = this;
    this.$loginForm.each(function () {
      $(this).validate({
        errorClass: "error",
        errorElement: "span",
        rules: {
          email: {
            required: true,
            emailCustom: true,
            maxlength: 50,
          },
          password: {
            required: true,
            rangelength: [6, 20],
          },
        },
        submitHandler: _this.popUpResetPasswordMessageIfExistingAccount,
      });
    });
  }

  popUpResetPasswordMessageIfExistingAccount(form) {
    const _this = this;
    const email = $(form).find('[name="email"]').val();
    Api.validatesEmail(email)
      .then(({ response: { data }, error }) => {
        if (!error && data.is_existing_account) {
          _this.$resetPasswordPopup.show();
          return false;
        }
        form.submit();
      })
      .catch(() => form.submit());
  }
}

class Register extends React.Component {
  constructor(props) {
    super(props);
    this._validator = null;
    this._emailErrorMessage = "";

    this.$regForm = $(".js-reg-form");
    this.$addressSearchLink = $(".js-reg-form .js-address-search");
    this.$zipCodeInput = $(".js-reg-form .js-zipcode");
    this.$cityInput = $(".js-reg-form .js-city");
    this.$prefectureSelect = $(".js-reg-form .js-prefecture-select");
    this.$emailInput = $(".js-register-email-input");

    Validator.addCustomValidate();
    this.addRegisterValidation();
    this.bindEvents();
    Utils.addRedirToElementAttr(this.$regForm, "action");
    this.fillEmailInput();
  }

  render() {
    return null;
  }

  bindEvents() {
    this.$addressSearchLink.on("click", this.searchAddress.bind(this));

    $("#unchecked_all_options").on(
      "click",
      this.handleChangeAllOptions.bind(this)
    );

    $(
      "#dm_by_mail_field, #is_subscribed_field, #dm_by_sms_field, #dm_by_phone_field"
    ).on("click", this.handleChangeOption.bind(this));
  }

  handleChangeAllOptions(event) {
    if ($("#unchecked_all_options").is(":checked") !== true) {
      event.preventDefault();
    } else {
      $("#is_subscribed_field").prop("checked", false);
      $("#dm_by_mail_field").prop("checked", false);
      $("#dm_by_sms_field").prop("checked", false);
      $("#dm_by_phone_field").prop("checked", false);
    }
  }

  handleChangeOption() {
    if (
      $("#is_subscribed_field").is(":checked") !== false ||
      $("#dm_by_mail_field").is(":checked") !== false ||
      $("#dm_by_sms_field").is(":checked") !== false ||
      $("#dm_by_phone_field").is(":checked") !== false
    ) {
      $("#unchecked_all_options").prop("checked", false);
    }
    if (
      $("#is_subscribed_field").is(":checked") !== true &&
      $("#dm_by_mail_field").is(":checked") !== true &&
      $("#dm_by_sms_field").is(":checked") !== true &&
      $("#dm_by_phone_field").is(":checked") !== true
    ) {
      $("#unchecked_all_options").prop("checked", true);
    }
  }

  fillEmailInput() {
    let email = Utils.getUrlParameter("email");
    if (email !== undefined) {
      this.$emailInput.val(email);
    }
  }

  searchAddress() {
    const zipcode = this.$zipCodeInput.val();
    Api.getZipcode(zipcode)
      .then(({ prefecture, city }) => {
        this.$prefectureSelect.val(prefecture);
        this.$cityInput.val(city);
        this._validator.element(".js-reg-form .js-prefecture-select");
        this._validator.element(".js-reg-form .js-city");
      })
      .catch(() => null);
  }

  addRegisterValidation() {
    let _this = this;
    this.$regForm.on("keydown", (e) => {
      // prevent user enter key will submit the form
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
    this._validator = this.$regForm.validate({
      errorClass: "error",
      errorElement: "span",
      errorPlacement: function (error, element) {
        if (element.attr("name") === "gender_field") {
          $(".radio-error-container").html(error);
        } else if (element.is("select")) {
          element.next(".error-wrapper").html(error);
        } else if (element.attr("name") === "is_terms_and_privacy_agreed") {
          $(".term-and-condition-error-container").html(error);
        } else {
          error.insertAfter(element);
        }
      },
      rules: {
        family_name: {
          required: true,
          maxlength: 20,
        },
        given_name: {
          required: true,
          maxlength: 20,
        },
        katakana_family_name: {
          required: true,
          maxlength: 20,
        },
        katakana_given_name: {
          required: true,
          maxlength: 20,
        },
        date_of_birth_year: {
          required: false,
        },
        date_of_birth_month: {
          required: false,
        },
        date_of_birth_day: {
          required: false,
        },
        gender_field: {
          required: false,
        },
        zipcode: {
          required: false,
          minlength: 7,
          maxlength: 8,
          isZip: true,
        },
        prefecture: {
          required: false,
        },
        city: {
          required: false,
          maxlength: 16,
        },
        address1: {
          required: false,
          maxlength: 16,
        },
        address2: {
          maxlength: 16,
        },
        phone: {
          required: true,
          maxlength: 13,
          isPhone: true,
        },
        email: {
          required: true,
          emailCustom: true,
          maxlength: 50,
          remote: {
            url: "/account/validates-email.json",
            dataFilter: (dataJSON) => {
              const json = JSON.parse(dataJSON);
              const isUsedValidationSuccess = json.results[0].is_used === false;
              const isValidValidationSuccess =
                json.results[0].is_valid === true;

              if (!isUsedValidationSuccess) {
                _this._emailErrorMessage =
                  "このメールアドレスは他のユーザーにより既に利用されています";
              }

              if (!isValidValidationSuccess) {
                _this._emailErrorMessage =
                  "有効なEメールアドレスを入力してください";
              }

              return isUsedValidationSuccess && isValidValidationSuccess;
            },
          },
        },
        password: {
          required: true,
          rangelength: [6, 20],
        },
        confirm_password: {
          required: true,
          equalTo: ".js-reg-form input[name='password']",
        },
        is_terms_and_privacy_agreed: {
          required: true,
        },
      },
      messages: {
        email: {
          remote: function () {
            return _this._emailErrorMessage;
          },
        },
      },
      submitHandler: function (form, event) {
        event.preventDefault();
        if (window.grecaptcha.getResponse() === "") {
          $(".g-recaptcha").append(
            `<span class="error">このフィールドは必須です。</span>`
          );
        } else {
          const registerUrl = $(form).attr("action");
          const registerFormData = $(form).serialize();
          $.post(registerUrl, registerFormData).done(function (data) {
            if (data) {
              registerSuccess();
              const { redirPath, history } = _this.props;
              if (redirPath) {
                history.push(redirPath);
                return;
              } else {
                const redirUrl = Utils.getUrlParameter("redir");
                if (typeof redirUrl === "undefined") {
                  window.location = REGISTER_SUCCESS_PATH;
                } else {
                  window.location = redirUrl;
                }
              }
            }
          });
        }
      },
    });
  }
}

class Recover {
  constructor() {
    this.$recoverForm = $(".js-forget-pw-form");
    this.$emailInput = $(".js-forget-pw-form .js-email");
    this.$confirmEmailInput = $(".js-forget-pw-form .js-confirm-email");

    this.bindEvents();

    Validator.addCustomValidate();
    this.addValidation();
  }

  bindEvents() {
    this.$emailInput.on("change", this.syncEmailInput.bind(this));
  }

  syncEmailInput() {
    this.$confirmEmailInput.val(this.$emailInput.val());
  }

  addValidation() {
    this.$recoverForm.validate({
      errorClass: "error",
      errorElement: "span",
      rules: {
        email: {
          required: true,
          emailCustom: true,
          maxlength: 50,
        },
      },
    });
  }
}

class RecoverComplete {
  constructor() {
    this.$recoverCompleteForm = $(".js-forget-pw-complete-form");

    this.addValidation();
  }

  addValidation() {
    this.$recoverCompleteForm.validate({
      errorClass: "error",
      errorElement: "span",
      rules: {
        password: {
          required: true,
          rangelength: [6, 20],
        },
        confirm_password: {
          required: true,
          equalTo: ".js-forget-pw-complete-form #password",
        },
      },
    });
  }
}

// eslint-disable-next-line 
export default { Login, Register, Recover, RecoverComplete, Utils };
