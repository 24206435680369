import { useState, useRef, useEffect } from "react";
import { whichBrowser } from "./helper";

const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const clearAllBodyScrollLocks = bodyScrollLock.clearAllBodyScrollLocks;

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useAddSuccess = () => {
  const [success, setSuccess] = useState(false);

  const addSuccess = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  return {
    success,
    addSuccess,
  };
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = () => {
    if (window) {
      setIsMobile(window.innerWidth < 768);
    }
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return isMobile;
};

const useOpenMenuDisableBodyScroll = (
  openMenu: boolean,
  target?: HTMLDivElement | null
) => {
  useEffect(() => {
    const sidebarMenu = document.querySelector(".js-hamburger");

    if (openMenu) {
      // pass target to disable body scroll but allow menu to scroll in ios safari
      disableBodyScroll(target);
      // disable open menu to avoid menu cover
      sidebarMenu!.setAttribute("disabled", "true");
    } else {
      clearAllBodyScrollLocks();
      sidebarMenu!.removeAttribute("disabled");
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [openMenu, target]);

  return null;
};

const useFix = ({
  setFix,
  setFixed,
  clearFixed,
  height = 1,
}: {
  setFix: boolean;
  setFixed: () => void;
  clearFixed: () => void;
  height: number;
}) => {
  const [goingUp, setGoingUp] = useState(false);

  const scrollToFixNav = () => {
    const currentTop = window.scrollY;
    if (currentTop >= height && !goingUp) {
      setGoingUp(true);
      setFixed();
    }
    if (currentTop < height && goingUp) {
      setGoingUp(false);
      clearFixed();
    }
  };

  useEffect(() => {
    if (setFix) {
      window.addEventListener("scroll", scrollToFixNav);
    } else {
      clearFixed();
      window.removeEventListener("scroll", scrollToFixNav);
      clearAllBodyScrollLocks();
    }

    return () => {
      if (setFix) {
        window.removeEventListener("scroll", scrollToFixNav);
      }
      clearAllBodyScrollLocks();
    };
    // eslint-disable-next-line
  }, [goingUp, setFix]);
  return null;
};

const useFixMenu = (setFix: true) => {
  const navRef = useRef();

  const setFixed = () => {
    const nav = navRef.current as HTMLDivElement | undefined;
    const header = document.querySelector(".header")?.getBoundingClientRect();
    const accountSection = document.querySelector(
      ".account-section"
    ) as HTMLDivElement;
    const menuSection = document.querySelector(
      ".menu-section"
    ) as HTMLDivElement;
    const sidebarMenu = document.querySelector(".js-hamburger");
    if (nav && header && accountSection) {
      nav.classList.add("fixed");
      nav.style.top = `${header.height - 1}px`;
      accountSection.style.marginTop = `${
        nav.getBoundingClientRect().height + 24
      }px`;
      const navClient = nav.getBoundingClientRect();
      const menuTop = navClient.top + navClient.height;
      menuSection.style.top = `${menuTop}px`;
    }
    // fix: TypeError: Attempting to define property on object that is not extensible.
    if(navRef && navRef.current){
      disableBodyScroll(navRef.current);
    }
    // disable open menu to avoid menu cover
    sidebarMenu!.setAttribute("disabled", "true");
  };

  const clearFixed = () => {
    const nav = navRef.current as HTMLDivElement | undefined;
    const header = document.querySelector(".header")?.getBoundingClientRect();
    const accountSection = document.querySelector(
      ".account-section"
    ) as HTMLDivElement;
    const menuSection = document.querySelector(
      ".menu-section"
    ) as HTMLDivElement;
    const sidebarMenu = document.querySelector(".js-hamburger");
    if (nav && header && accountSection) {
      nav.classList.remove("fixed");
      nav.style.top = "auto";
      accountSection.style.marginTop = "24px";
      menuSection.style.top = "unset";
    }
    clearAllBodyScrollLocks();
    sidebarMenu!.removeAttribute("disabled");
  };

  useFix({ setFix, setFixed, clearFixed, height: 1 });

  return navRef;
};

const useClickOutSide = (
  ref: any,
  callback: () => void,
  dependiences: Array<boolean>
) => {
  const closeWhenClickOutside = (e: any) => {
    const target = e.target;
    const current = ref.current;

    if (current && current.contains(target)) {
      return;
    } else {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", closeWhenClickOutside);

    return () => {
      document.removeEventListener("click", closeWhenClickOutside);
    };
    // eslint-disable-next-line
  }, dependiences);

  return null;
};

// fix [LAX-5371] jump to default browser without user message will not call finish order
// show messages to ask user to manually jump back to old payment browser/app
// then can call finish order in our system, no need to wait
// 1. pass the old browser to SuccessUrl by `successUrt?user-agent=broswer`
// 2. in successUrl page, show loading first, then get current browser, compare to the url query
//    if the same, then call finish order
//    if not the same, then show message to ask user to go back
//    if no query, maybe some one directly access, checked by reudx to see if the browser is an order-code, if no order-code, go back to cart
const useCheckBrowserIsOrigin = () => {
  const [notTheSamePaymentEnv, setNotTheSamePaymentEnv] = useState<
    boolean | undefined
  >(undefined);
  const [currentBrowser, setCurrentBrowser] = useState<string | null>("");
  const [originBrowser, setOrginBrowser] = useState<string | null>("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query && query.get("userAgent")) {
      const currentBrowser = whichBrowser();
      let originBrowser = query.get("userAgent");
      if (currentBrowser !== originBrowser) {
        setNotTheSamePaymentEnv(true);
        setCurrentBrowser(currentBrowser);
        setOrginBrowser(originBrowser);
      } else {
        setNotTheSamePaymentEnv(false);
      }
    } else {
      setNotTheSamePaymentEnv(false); // add default is no query
    }
  }, []);

  return {
    notTheSamePaymentEnv,
    currentBrowser,
    originBrowser,
  };
};

export {
  usePrevious,
  useAddSuccess,
  useIsMobile,
  useOpenMenuDisableBodyScroll,
  useFixMenu,
  useClickOutSide,
  useFix,
  useCheckBrowserIsOrigin,
};
