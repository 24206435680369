import { combineReducers, Action, Reducer } from "redux";

import { orderReducer, OrderState, orderInitialState } from "./order";
import { dataReducer, DataState, dataInitialState } from "./data";
import {
  confirmResultReducer,
  ConfirmResultState,
  confirmResultInitialState
} from "./confirmation";
import { usersReducer, UsersState, usersInitialState } from "./users";
import { pointsReducer, PointsState, pointsInitialState } from "./points";
import { keyed } from "@theplant/ecjs/projection";
import {
  invalidAddressFormEditableReducer,
  InvalidAddressFormEditableState,
  invalidAddressFormEditableInitialState
} from "./invalidAddressFormEditable";

export type CheckoutState = {
  data: DataState;
  points: PointsState;
  confirmResult: ConfirmResultState;
  users: UsersState;
  order: OrderState;
  invalidAddressFormEditable: InvalidAddressFormEditableState;
};

const checkoutInitialState = {
  data: dataInitialState,
  points: pointsInitialState,
  confirmResult: confirmResultInitialState,
  users: usersInitialState,
  order: orderInitialState,
  invalidAddressFormEditable: invalidAddressFormEditableInitialState
};

// App Reducers are defined in terms of their own actions, but
// `combineReducers` expects a reducer that can accept a "Redux
// Action" (defined as `{ type: any }`). This causes problems when a
// reducer has no actions that are of type `{ type: string }` (ie all
// actions have extra properties), as Typescript can't reconcile it
// with the type of `combineReducers`.
//
// An alternative to this approach is to add `{ type: "" }` to the
// reducer's input actions.
const r = <S, A extends Action>(reducer: (s: S, a: A) => S) =>
  reducer as Reducer<S>;

const checkoutReducer = combineReducers<CheckoutState>({
  data: dataReducer,
  points: pointsReducer,
  confirmResult: confirmResultReducer,
  users: r(keyed("users", usersReducer)),
  order: r(keyed("order", orderReducer)),
  invalidAddressFormEditable: r(invalidAddressFormEditableReducer)
});

export { checkoutReducer, checkoutInitialState };
