import React from "react";
import $ from "jquery";

const Color = () => {
  const setColorName = (colorname?: string) => {
    const colorLabel = $(".color-list .title span.color-name");
    if (colorname) {
      colorLabel.text(colorname);
    }
  };

  const setdefault = () => {
    const selectedColorName = $(`.color-list li.active`).attr("data-colorname");
    setColorName(selectedColorName);
  };

  const handleMouseOverColors = (e: any) => {
    const colorname = $(e.target).attr("data-colorname");
    setColorName(colorname);
  };

  const handleMouseLeaveColors = () => {
    setdefault();
  };

  React.useEffect(() => {
    const selectColorLabel = $(".color-list .title span.color-name");
    if (!selectColorLabel.text()) {
      setdefault();
    }

    $(`.color-list li`).on("mouseover", handleMouseOverColors);
    $(`.color-list li`).on("mouseleave", handleMouseLeaveColors);
    // eslint-disable-next-line
  }, []);

  return null;
};

export { Color };
