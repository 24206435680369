import { useEffect } from "react";
import { OrderServiceProps } from "../../orders/order";

export const saveInLocalStorage = (
  checkoutInputForm?: OrderServiceProps["checkout"]["input"]["form"],
  saveAddress?: boolean
) => {
  if (checkoutInputForm) {
    const {
      giftWrapping,
      points,
      deliveryInfo,
      shippingAddress,
      billingAddress,
      storeId,
      deliveryMethod,
      specifyBillingAddress,
    } = checkoutInputForm;

    if (
      (deliveryInfo && deliveryInfo.specified) ||
      (giftWrapping && giftWrapping.specified) ||
      (points && points !== 0)
    ) {
      localStorage.setItem(
        "checkout-input-form",
        JSON.stringify({
          giftWrapping,
          points,
          deliveryInfo,
          storeId,
          deliveryMethod,
          specifyBillingAddress,
        })
      );
    }

    if (saveAddress && (shippingAddress || billingAddress)) {
      let newShippingAddress = checkoutInputForm.newShippingAddress;
      let newBillingAddress = checkoutInputForm.newBillingAddress;
      let shippingAddressId = checkoutInputForm.shippingAddressId;
      let billingAddressId = checkoutInputForm.billingAddressId;
      let email = checkoutInputForm.email;

      localStorage.setItem(
        "checkout-input-form-address",
        JSON.stringify({
          shippingAddress,
          billingAddress,
          newShippingAddress,
          newBillingAddress,
          shippingAddressId,
          billingAddressId,
          email,
          storeId,
          deliveryMethod,
          specifyBillingAddress,
        })
      );
    }
  }
};

export const getDataFromLocalStorage = (
  props: OrderServiceProps,
  saveAddress?: boolean
) => {
  const {
    checkout: { updateDataForm, updateAddressForm },
  } = props;

  const localStorageData = localStorage.getItem("checkout-input-form");
  const localStorageAddressData = localStorage.getItem(
    "checkout-input-form-address"
  );

  let deliveryInfo,
    giftWrapping,
    points,
    shippingAddress,
    billingAddress,
    newShippingAddress,
    newBillingAddress,
    shippingAddressId,
    billingAddressId,
    email,
    storeId,
    deliveryMethod,
    specifyBillingAddress;

  if (!!localStorageData) {
    const checkoutInputForm = JSON.parse(
      localStorageData
    ) as OrderServiceProps["checkout"]["input"]["form"];
    deliveryInfo = checkoutInputForm.deliveryInfo;
    giftWrapping = checkoutInputForm.giftWrapping;
    points = checkoutInputForm.points;
    storeId = checkoutInputForm.storeId;
    deliveryMethod = checkoutInputForm.deliveryMethod;
    specifyBillingAddress = checkoutInputForm.specifyBillingAddress;

    if (deliveryInfo && deliveryInfo.specified) {
      updateDataForm("deliveryInfo", deliveryInfo);
    }

    if (giftWrapping && giftWrapping.specified) {
      updateDataForm("giftWrapping", giftWrapping);
    }

    if (points && Number(points) > 0) {
      updateDataForm("points", points);
    }

    if (storeId) {
      updateDataForm("storeId", storeId);
    }

    if (deliveryMethod) {
      updateDataForm("deliveryMethod", deliveryMethod);
    }

    if (specifyBillingAddress) {
      updateDataForm("specifyBillingAddress", specifyBillingAddress);
    }

    // clear localStorage
    localStorage.setItem("checkout-input-form", "");
  }

  if (!!localStorageAddressData && saveAddress) {
    const checkoutInputForm = JSON.parse(localStorageAddressData);
    shippingAddress = checkoutInputForm.shippingAddress;
    billingAddress = checkoutInputForm.billingAddress;
    newShippingAddress = checkoutInputForm.newShippingAddress;
    newBillingAddress = checkoutInputForm.newBillingAddress;
    shippingAddressId = checkoutInputForm.shippingAddressId;
    billingAddressId = checkoutInputForm.billingAddressId;
    email = checkoutInputForm.email;
    storeId = checkoutInputForm.storeId;
    deliveryMethod = checkoutInputForm.deliveryMethod;
    specifyBillingAddress = checkoutInputForm.specifyBillingAddress;

    if (shippingAddress) {
      updateAddressForm("shippingAddress", shippingAddress);
    }

    if (billingAddress) {
      updateAddressForm("billingAddress", billingAddress);
    }

    if (newShippingAddress) {
      updateDataForm("newShippingAddress", newShippingAddress);
    }

    if (newBillingAddress) {
      updateDataForm("newBillingAddress", newBillingAddress);
    }

    if (shippingAddressId) {
      updateDataForm("shippingAddressId", shippingAddressId);
    }

    if (billingAddressId) {
      updateDataForm("billingAddressId", billingAddressId);
    }

    if (email) {
      updateDataForm("email", email);
    }

    if (storeId) {
      updateDataForm("storeId", storeId);
    }

    if (deliveryMethod) {
      updateDataForm("deliveryMethod", deliveryMethod);
    }

    if (specifyBillingAddress) {
      updateDataForm("specifyBillingAddress", specifyBillingAddress);
    }

    // clear localStorage
    localStorage.setItem("checkout-input-form-address", "");
  }

  if (
    (deliveryInfo && deliveryInfo.specified) ||
    (giftWrapping && giftWrapping.specified) ||
    (points && points !== 0) ||
    shippingAddress ||
    billingAddress
  ) {
    return true;
  } else {
    return false;
  }
};

export const PrefillInputFromLocalStorage = (
  props: OrderServiceProps & { saveAddress?: boolean }
) => {
  useEffect(() => {
    const {
      checkout: { validateInput },
      saveAddress,
    } = props;

    const update = getDataFromLocalStorage(props, saveAddress);
    if (update) {
      validateInput();
    }

    return () => {
      if (localStorage) {
        // clear localStorage
        localStorage.setItem("checkout-input-form", "");
        localStorage.setItem("checkout-input-form-address", "");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};



export const clearLocalStorageSaveForm = () => {
  if (localStorage) {
    // clear localStorage
    localStorage.setItem("checkout-input-form", "");
    localStorage.setItem("checkout-input-form-address", "");
  }
};