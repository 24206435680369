import { combineReducers } from "redux";
import * as types from "../constants/ActionTypes";

const initialState = {
  checkedIds: [],
  ui: {
    fetching: false,
    error: null
  }
};

const checkedIds = (s = initialState.checkedIds, a) => {
  switch (a.type) {
    case types.ACCOUNT_WISH_ITEMS_TOGGLE_CHECKED:
      let newState = s.slice();
      const index = s.indexOf(a.id);
      if (index === -1) {
        newState.push(a.id);
      } else {
        newState.splice(index, 1);
      }
      return newState;
    case types.ACCOUNT_WISH_ITEMS_DID_DELETE:
    case types.ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART:
      return [];
    default:
      return s;
  }
};

const ui = (s = initialState.ui, a) => {
  switch (a.type) {
    case types.ACCOUNT_WISH_ITEMS_WILL_DELETE:
    case types.ACCOUNT_WISH_ITEMS_WILL_MOVE_TO_CART:
      return { ...s, fetching: true, error: null };
    case types.ACCOUNT_WISH_ITEMS_DID_DELETE_FAILED:
    case types.ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART_FAILED:
      return { ...s, fetching: false, error: a.error };
    case types.ACCOUNT_WISH_ITEMS_DID_DELETE:
    case types.ACCOUNT_WISH_ITEMS_DID_MOVE_TO_CART:
      return { ...s, fetching: false, error: null };
    default:
      return s;
  }
};

export default combineReducers({ checkedIds, ui });

const getCheckedIds = (state, id) => state.accountWishItems.checkedIds;

export { initialState, getCheckedIds };
