import * as types from "../constants/ActionTypes";

// update a single address
const update = (data) => ({ type: types.SHIPPING_ADDRESSES_UPDATE, data });

const refresh = () => ({ type: types.SHIPPING_ADDRESSES_WILL_REFRESH });

const refreshSuccess = (data) => ({
  type: types.SHIPPING_ADDRESSES_DID_REFRESH,
  data,
});

const refreshFailure = (error) => ({
  type: types.SHIPPING_ADDRESSES_DID_REFRESH_FAILED,
  error,
});

// can't use `delete` as the function name.
const deleteShippingAddress = (id, cb) => ({
  type: types.SHIPPING_ADDRESSES_WILL_DELETE,
  id,
  cb,
});

const deleteSuccess = (id) => ({
  type: types.SHIPPING_ADDRESSES_DID_DELETE,
  id,
});

const deleteFailure = (error) => ({
  type: types.SHIPPING_ADDRESSES_DID_DELETE_FAILED,
  error,
});

export {
  update,
  refresh,
  refreshSuccess,
  refreshFailure,
  deleteShippingAddress,
  deleteSuccess,
  deleteFailure,
};
