import React from "react";

import { formatCurrency } from "../../components/helpers/currency";

import { theplant } from "../../proto";

import { CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP } from "../../constants/Texts";

const SubItemType = theplant.ec.service.accounting.SubItemType;
type ISubItem = theplant.ec.service.accounting.ISubItem;
type ISumUp = theplant.ec.service.accounting.ISumUp;

const selectSumUpItems = (
  sumUp: ISumUp | null | undefined
): ISubItem[] | null => {
  if (!sumUp) {
    return null;
  }

  const { quoteAmountWithTax, billingAmountWithTax, subItems } = sumUp;

  let displaySubItems: ISubItem[] = [];

  displaySubItems.push({ name: "QUOTE_AMOUNT", amount: quoteAmountWithTax });

  (subItems || [])
    .filter(
      // COUPON_DISCOUNT & PROMOTION_DISCOUNT are contained in TOTAL_DISCOUNT
      ({ subType }) =>
        subType !== SubItemType.COUPON_DISCOUNT &&
        subType !== SubItemType.PROMOTION_DISCOUNT
    )
    .forEach(({ subType, amount, name }) => {
      // Should not display TOTAL_DISCOUNT when the amount is 0,
      // requested at https://github.com/theplant/lacoste3/issues/1616#issuecomment-455094556.
      if (subType === SubItemType.TOTAL_DISCOUNT && Number(amount || 0) === 0) {
        return;
      }

      subType &&
        displaySubItems.push({
          name,
          amount,
        });
    });

  displaySubItems.push({
    name: "BILLING_AMOUNT",
    amount: billingAmountWithTax,
  });

  return displaySubItems;
};

const SubItem = ({ data }: { data: ISubItem }) => {
  const label =
    data.name && CHECKOUT_SUM_UP_SUB_ITEM_NAME_TO_LABEL_MAP[data.name];

  return label ? (
    <>
      {data.name === "BILLING_AMOUNT" ? <div className="divider" /> : null}
      <div className="sumup__subItem">
        <span
          className={data.name === "BILLING_AMOUNT" ? "sumup-label" : "label"}
        >
          {label}
        </span>
        <span className="number">{formatCurrency(data.amount)}</span>
      </div>
    </>
  ) : null;
};

const SumUp = ({ sumUp }: { sumUp: ISumUp | null | undefined }) => (
  <div className="sumup">
    {(selectSumUpItems(sumUp) || []).map((item, idx) => (
      <SubItem data={item} key={`sub-item-${idx}`} />
    ))}
  </div>
);

export { SumUp, selectSumUpItems };
