import React from "react";
import { getQuarterMinute } from "./helper";

const moveTimeQuarterAgo = () => {
  let currentDate: number | Date = new Date();
  currentDate = currentDate.getTime() - 1000 * 60 * 15;

  return new Date(currentDate);
};

const useDate = () => {
  const timer = React.useRef<NodeJS.Timeout | null>(null);
  const [date, setDate] = React.useState(moveTimeQuarterAgo());

  React.useEffect(() => {
    timer.current = setInterval(() => {
      setDate(moveTimeQuarterAgo());
    }, 60 * 1000);

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return {
    year: date.getFullYear().toString(),
    month: (date.getMonth() + 1).toString(),
    day: date.getDate().toString(),
    hour: date.getHours().toString(),
    minutes: getQuarterMinute(date.getMinutes()),
  };
};

export { useDate };
