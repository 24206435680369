import { theplant } from "../proto";
import { firstPropertyValue, getAllProperties } from "../protoHelper";

type IProperty = theplant.ec.service.base.IProperty;

enum PropertyField {
  // Variant Properties, ref: https://admin.aigle.theplant-dev.com/admin/variant_properties
  Size = "Size",
  JapanSize = "JapanSize",
  EuropeSize = "EuropeSize",
  ColorCode = "ColorCode",
  Collection = "Collection",
  Gender = "Gender",
  DiscountLabel = "DiscountLabel",
  DiscountLabelBackgroundColor = "DiscountLabelBackgroundColor",
  ColorName = "ColorName",
}

const getColorCode = (properties?: IProperty[] | null): string | null =>
  firstPropertyValue(PropertyField.ColorCode, properties);

const getSize = (properties?: IProperty[] | null): string | null =>
  firstPropertyValue(PropertyField.Size, properties);

const getJapanSize = (properties?: IProperty[] | null): string | null =>
  firstPropertyValue(PropertyField.JapanSize, properties);

const getEuropeSize = (properties?: IProperty[] | null): string | null =>
  firstPropertyValue(PropertyField.EuropeSize, properties);

const getCategoryCode = (properties?: IProperty[] | null): string | null =>
  firstPropertyValue(PropertyField.Collection, properties);

const getGenderName = (properties?: IProperty[] | null): string | null =>
  firstPropertyValue(PropertyField.Gender, properties);

const getDiscountLabels = (properties?: IProperty[] | null): string[] | null =>
  getAllProperties(PropertyField.DiscountLabel, properties);

const getDiscountLabelBackgroundColors = (
  properties?: IProperty[] | null
): string[] | null =>
  getAllProperties(PropertyField.DiscountLabelBackgroundColor, properties);

const getColorName = (
    properties?: IProperty[] | null
  ): string[] | null =>
    getAllProperties(PropertyField.ColorName, properties);
  
export {
  getColorCode,
  getSize,
  getCategoryCode,
  getGenderName,
  getDiscountLabels,
  getDiscountLabelBackgroundColors,
  getJapanSize,
  getEuropeSize,
  getColorName
};
