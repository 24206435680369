import React from "react";

const Howto = () => (
  <div className="content points-sub-tab points-howto-sub-tab">
    <h6>ポイントのご利用方法</h6>
    <div className="text-hr">
      <p>AIGLE オンラインショップ</p>
    </div>
    <p>
      AIGLE オンラインショップ にてポイントをご利用の場合は、1
      ポイントからご利用いただけます。
      <br />
      支払い手続き画面にて、ご利用希望ポイント数を入力して「適用」ボタンをクリックしてください。
      <br />
      合計金額は、ご入力ポイント数に応じて自動計算されます。
    </p>
    <figure className="point-usage-img-wrapper">
      <img src="/img/point-usage.jpg" alt="point usage" loading="lazy" />
    </figure>
    <div className="text-hr">
      <p>MY PAGE にアクセス</p>
    </div>
    <p>
      MY PAGE ではお客様のご登録内容の確認や変更、 ご利用情報が確認できます。
      <br />
      お客様の QR コードを表示できます。
      <br />
      ご来店時もしくはお会計時に QR コードを スタッフにご提示ください。
    </p>
    <div className="login-url-section clearfix">
      <a href="https://www.aigle.co.jp/auth/login">
        https://www.aigle.co.jp/auth/login
      </a>
      <figure>
        <img
          src="/img/login-url-qr-code.svg"
          alt="login url qr code"
          loading="lazy"
        />
      </figure>
    </div>
    <p>
      <span>
        ※AIGLE オンラインショップ
        でアカウント登録いただいたお客様も共通のメールアドレスとパスワードでログインできます。
      </span>
      <br />
      <span>
        ※ポイントのご利用は、1 カ月合計 100,000 ポイントが上限となります。
      </span>
    </p>
  </div>
);

export default Howto;
