import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import configureStore from "./store/configureStore";
import { setConfig, env } from "./config";

import Modal from "react-modal";

import AccountApp from "./components/AccountApp";
import CartWidgetApp from "./components/CartWidgetApp";
import CartNumberApp from "./components/CartNumberApp";
import ShopFinderApp from "./components/ShopFinderApp";
import RegisterApp from "./CheckoutApp/RegisterApp";
import LoginApp from "./CheckoutApp/LoginApp";
import SizeGuide from "./product/SizeGuide";
import { SnapboardTargetForPDP } from "./snapboard";
import StoreInventory from "./product/StoreInventory";
import { toCamelCase } from "./gtm/uploadDataComponent/helper";

import { CheckoutApp } from "./CheckoutApp";
import { CombiniCallback } from "./CheckoutApp/shared/Delivery/CombiniCallback";

import { refresh as refreshPrefectures } from "./actions/prefectures";

import { ContextProvider } from "@theplant/ecjs/context";
import { context } from "./context";
import Tabs from "./product/molecules/Tabs";
import PDPSticky from "./product/molecules/PDPSticky";
import PDPImageSwiper from "./product/molecules/PDPImageSwiper";

import * as proto from "./proto";
import { imprint } from "@theplant/ecjs/prottp";
imprint(proto);

const store = configureStore();

// set env to the store
store.dispatch(setConfig(env));

if (document.getElementById("account-app")) {
  store.dispatch(refreshPrefectures());

  Modal.setAppElement("#account-app");

  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <AccountApp />
      </Router>
    </Provider>,
    document.getElementById("account-app")
  );
}

if (document.getElementById("register-app")) {
  Modal.setAppElement("#register-app");
  store.dispatch(refreshPrefectures());

  ReactDOM.render(
    <ContextProvider requestContext={context}>
      <Provider store={store}>
        <Router>
          <RegisterApp inRegisterPage={true} />
        </Router>
      </Provider>
    </ContextProvider>,
    document.getElementById("register-app")
  );
}

if (document.getElementById("login-app")) {
  Modal.setAppElement("#login-app");

  ReactDOM.render(<LoginApp />, document.getElementById("login-app"));
}

if (document.getElementById("checkout-app")) {
  Modal.setAppElement("#checkout-app");

  ReactDOM.render(
    <ContextProvider requestContext={context}>
      <Provider store={store}>
        <CheckoutApp />
      </Provider>
    </ContextProvider>,
    document.getElementById("checkout-app")
  );
}

if (document.getElementById("combini-app")) {
  ReactDOM.render(<CombiniCallback />, document.getElementById("combini-app"));
}

if (document.getElementById("size-guide-popup")) {
  ReactDOM.render(<SizeGuide />, document.getElementById("size-guide-popup"));
}

const productDataDOM = document.querySelector(".js-product-detail > div");
const productDataInDOM =
  productDataDOM && productDataDOM.getAttribute("data-tracing-source");

const productData = JSON.parse(productDataInDOM, toCamelCase);

if (document.getElementById("store-inventory-popup")) {
  const selectedColor = document.querySelector(".pdp-info__colors li.active");
  let colorCode;
  if (selectedColor) {
    colorCode = selectedColor.getAttribute("data-colorCode");
  }

  if (productData && productData.code) {
    ReactDOM.render(
      <StoreInventory code={productData.code} colorCode={colorCode} />,
      document.getElementById("store-inventory-popup")
    );
  }
}

if (document.getElementById("cart-widget-app")) {
  ReactDOM.render(
    <Provider store={store}>
      <CartWidgetApp />
    </Provider>,
    document.getElementById("cart-widget-app")
  );
}

if (document.querySelector(".mobile-pdp-popup")) {
  ReactDOM.render(
    <Provider store={store}>
      <CartWidgetApp isMobilePopup />
    </Provider>,
    document.querySelector(".mobile-pdp-popup")
  );
}

if (document.getElementById("siderbar-cart-number-app")) {
  ReactDOM.render(
    <Provider store={store}>
      <CartNumberApp />
    </Provider>,
    document.getElementById("siderbar-cart-number-app")
  );
}

if (document.getElementById("shop-finder-app")) {
  ReactDOM.render(
    <Provider store={store}>
      <ShopFinderApp />
    </Provider>,
    document.getElementById("shop-finder-app")
  );
}

if (document.getElementById("snapboard-pdp")) {
  if (productData && productData.code) {
    const productCode = productData.code;
    ReactDOM.render(
      <SnapboardTargetForPDP productCode={productCode} />,
      document.getElementById("snapboard-pdp")
    );
  }
}

if (document.getElementById("product-tabs")) {
  ReactDOM.render(
    <Tabs productData={productData} />,
    document.getElementById("product-tabs")
  );
}

const PDPStickyWrapper = document.getElementById("product-sticky");
if (PDPStickyWrapper) {
  const isOutOfStock = PDPStickyWrapper.getAttribute("data-showoutofstocktext");
  ReactDOM.render(
    <PDPSticky isOutOfStock={isOutOfStock === "true"} />,
    PDPStickyWrapper
  );
}

const PDPImageWrapper = document.getElementById("pdp-image-swiper");
if (PDPImageWrapper) {
  ReactDOM.render(<PDPImageSwiper />, PDPImageWrapper);
}
