const mapProtoProfileToProfileState = (profile = {}) => {
  let {
    date_of_birth: dateOfBirth,
    is_subscribed: isSubscribed,
    dm_by_mail: dmByMail,
    dm_by_sms: dmBySms,
    dm_by_phone: dmByPhone,
    gender,
    member_number: memberNumber,
    billing_address: {
      zipcode,
      prefecture,
      city,
      address1,
      address2,
      phone,
      family_name: familyName,
      given_name: givenName,
      katakana_family_name: katakanaFamilyName,
      katakana_given_name: katakanaGivenName,
    },
  } = profile;

  return {
    dateOfBirth,
    isSubscribed,
    dmByMail,
    dmBySms,
    dmByPhone,
    gender: `${gender || 0}`, // gender is a Number that returned by the API.
    phone,
    familyName,
    givenName,
    katakanaFamilyName,
    katakanaGivenName,
    zipcode,
    address1,
    address2,
    city,
    prefecture,
    memberNumber,
  };
};

const mapProfileStateToProtoProfile = (profile = {}) => {
  let {
    dateOfBirth,
    isSubscribed,
    dmByMail,
    dmByPhone,
    dmBySms,
    gender,
    phone,
    familyName,
    givenName,
    katakanaFamilyName,
    katakanaGivenName,
    zipcode,
    address1,
    address2,
    city,
    prefecture,
  } = profile;

  return {
    date_of_birth: dateOfBirth,
    is_subscribed: isSubscribed,
    dm_by_mail: dmByMail,
    dm_by_sms: dmBySms,
    dm_by_phone: dmByPhone,
    gender: Number(gender),
    billing_address: {
      zipcode,
      prefecture,
      city,
      address1,
      address2,
      phone,
      family_name: familyName,
      given_name: givenName,
      katakana_family_name: katakanaFamilyName,
      katakana_given_name: katakanaGivenName,
    },
  };
};

const mapProtoAccountUserToProfileState = ({ email, profile }) => {
  return { email, ...mapProtoProfileToProfileState(profile) };
};

const mapProtoProfileValidationErrorToProfileState = ({ error, value }) => {
  let {
    gender,
    phone,
    zipcode,
    address1,
    address2,
    city,
    prefecture,
    date_of_birth: dateOfBirth,
    is_subscribed: isSubscribed,
    family_name: familyName,
    given_name: givenName,
    katakana_family_name: katakanaFamilyName,
    katakana_given_name: katakanaGivenName,
  } = value.errors;

  return {
    error,
    value: {
      errors: {
        gender,
        phone,
        zipcode,
        address1,
        address2,
        city,
        prefecture,
        dateOfBirth,
        isSubscribed,
        familyName,
        givenName,
        katakanaFamilyName,
        katakanaGivenName,
      },
    },
  };
};

export {
  mapProtoAccountUserToProfileState,
  mapProtoProfileToProfileState,
  mapProfileStateToProtoProfile,
  mapProtoProfileValidationErrorToProfileState,
};
