import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.ORDERS_WILL_REFRESH });

const refreshSuccess = data => ({ type: types.ORDERS_DID_REFRESH, data });

const refreshFailure = error => ({
  type: types.ORDERS_DID_REFRESH_FAILED,
  error
});

const cancel = id => ({ type: types.ORDERS_WILL_CANCEL, id });

const cancelSuccess = data => ({ type: types.ORDERS_DID_CANCEL, data });

const cancelFailure = error => ({
  type: types.ORDERS_DID_CANCEL_FAILED,
  error
});

export {
  refresh,
  refreshSuccess,
  refreshFailure,
  cancel,
  cancelSuccess,
  cancelFailure
};
