import { OrderServiceProps } from "../../orders/order";
import { scrollToFirstError } from "../shared/scrollToFirstError";
import { alert } from "../../js/shared/alert";

const confirmAmazonOrder = ({
  confirm,
  amazonpayRedirectUrl,
  setRedirecting,
  updateAddressToAmazonPayAddress,
}: {
  confirm: OrderServiceProps["checkout"]["confirm"];
  amazonpayRedirectUrl?: string | null;
  setRedirecting: (redirect: boolean) => void;
  updateAddressToAmazonPayAddress: () => void;
}) => {
  setRedirecting(true);
  // only update address when click confirm button
  // avoid only enter to amazon pay and go back break normal checkout
  // fix: [LAX-5360] and [LAX-5361]
  // update after redirect state but before call confirm
  updateAddressToAmazonPayAddress();

  confirm(
    () => {
      if (amazonpayRedirectUrl) {
        window.location.replace(amazonpayRedirectUrl);
      } else {
        // FIXME: better way to info user
        alert("cannot get amazonpayRedirectUrl and redirect");
      }
    },
    () => {
      scrollToFirstError();
      setRedirecting(false);
    }
  );
};

export { confirmAmazonOrder };
