import { take, put, call } from "redux-saga/effects";
import {
  PROFILE_DID_REFRESH,
  PROFILE_FORM_DID_SUBMIT
} from "../constants/ActionTypes";
import {
  ACCOUNT_PERSONAL_PATH,
  QUERY_STATE_MUST_UPDATE_PROFILE
} from "../constants/Paths";
import * as actions from "../actions/mustUpdateProfile";
import { submit as submitProfileForm } from "../actions/profileForm";
import { redirect } from "./common";
import queryString from "query-string";

const _shouldShowingMustUpdateProfilePopup = location => {
  const { pathname, search } = location;
  const parsed = queryString.parse(search);
  return (
    pathname === ACCOUNT_PERSONAL_PATH &&
    parsed.state === QUERY_STATE_MUST_UPDATE_PROFILE
  );
};

function* mustUpdateProfileFlow() {
  const location = window.location;

  const shouldShowing = yield call(
    _shouldShowingMustUpdateProfilePopup,
    location
  );

  if (shouldShowing) {
    const { search = "" } = location || {};
    const parsed = queryString.parse(search);

    yield put(actions.show());

    yield take(PROFILE_DID_REFRESH);
    yield put(submitProfileForm());

    yield take(PROFILE_FORM_DID_SUBMIT);
    yield call(redirect, parsed.redir || ACCOUNT_PERSONAL_PATH);
  }
}

export default mustUpdateProfileFlow;

export { _shouldShowingMustUpdateProfilePopup };
