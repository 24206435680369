import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.WISH_ITEMS_WILL_REFRESH });

const refreshSuccess = data => ({ type: types.WISH_ITEMS_DID_REFRESH, data });

const refreshFailure = error => ({
  type: types.WISH_ITEMS_DID_REFRESH_FAILED,
  error
});

const deleteWishItems = (ids = []) => ({
  type: types.WISH_ITEMS_DID_DELETE,
  ids
});

export { refresh, refreshSuccess, refreshFailure, deleteWishItems };
