import React from "react";
import SeparatedInput from "./SeparatedInput";
import SelectInput from "./SelectInput";
import { Errors } from "../../../CheckoutApp/shared/Errors";
import { pad } from "../../helpers/common";

// genRangeOptions is used to generate a list of options for birthday select
// inputs. For example:
// genRangeOptions(1, 31, 2) #=> [ ['01'], ['02'] ... ['31'] ]
const genRangeOptions = (min, max, padWidth) => {
  let options = [];

  for (let i = min; i < max + 1; i++) {
    options.push([pad(i, padWidth)]);
  }

  return options;
};

const FIRST_YEAR = 1920;
const LAST_YEAR = new Date().getFullYear() - 1;

class BirthdayInput extends SeparatedInput {
  constructor(props) {
    super(props);
    this.initOptions();
  }

  initOptions() {
    this.yearOptions = genRangeOptions(FIRST_YEAR, LAST_YEAR, 4);
    this.monthOptions = genRangeOptions(1, 12, 2);
    this.dayOptions = genRangeOptions(1, 31, 2);
  }

  _preselectLastYearOption = (year) => {
    if (year.value === "") {
      this.onChangeField(year.name)(LAST_YEAR);
    }
  };

  render() {
    const [year, month, day] = this.getSeparatedParts();
    const { errors, onChange, registerApp } = this.props;

    return (
      <div className="form-group birthday">
        <label className="input-label">生年月日（任意）</label>
        <div className="birthday-select-group-wrapper grid-row-3-only mb-15">
          <SelectInput
            value={year.value}
            name="date_of_birth_year"
            id="year"
            htmlFor="year"
            label="年"
            onChange={
              registerApp
                ? (value) => onChange("date_of_birth_year", value)
                : this.onChangeField(year.name)
            }
            onFocus={this._preselectLastYearOption.bind(this, year)}
            onTouchEnd={this._preselectLastYearOption.bind(this, year)}
            options={this.yearOptions}
            placeholder={{
              value: "",
              coveredValue: "年",
              position: 60,
            }}
            onOpen={() => {
              setTimeout(() => {
                // client review
                // should automatically start at 1980 when you click on it.
                const optionEl = document.querySelector(`[data-name="1984"]`);
                if (optionEl) {
                  optionEl.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                  });
                }
              }, 100);
            }}
          />
          <SelectInput
            value={month.value}
            name="date_of_birth_month"
            label="月"
            id="month"
            htmlFor="month"
            onChange={
              registerApp
                ? (value) => onChange("date_of_birth_month", value)
                : this.onChangeField(month.name)
            }
            placeholder={{
              value: "",
              coveredValue: "月",
              position: 0,
            }}
            options={this.monthOptions}
          />
          <SelectInput
            name="date_of_birth_day"
            value={day.value}
            label="日"
            id="day"
            htmlFor="day"
            placeholder={{
              value: "",
              coveredValue: "日",
              position: 0,
            }}
            onChange={
              registerApp
                ? (value) => onChange("date_of_birth_day", value)
                : this.onChangeField(day.name)
            }
            options={this.dayOptions}
          />
        </div>
        <p className="desc mt-0">
          生年月日を入力いただければ誕生月特典がもらえます。
        </p>
        <Errors errors={errors} />
      </div>
    );
  }
}

BirthdayInput.defaultProps = {
  ...SeparatedInput.defaultProps,
};

BirthdayInput.propTypes = {
  ...SeparatedInput.propTypes,
};

export default BirthdayInput;

export { genRangeOptions };
