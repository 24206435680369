import { theplant } from "../proto";

const tax = 0.1;

const getActualValue = (cart: theplant.ec.service.orders.IOrder) => {
  let quoteAmountWithTax = Number((cart.sumUp || {}).quoteAmountWithTax || 0);
  quoteAmountWithTax += ((cart.sumUp && cart.sumUp.subItems) || [])
    .filter(
      item =>
        item.subType ===
        theplant.ec.service.accounting.SubItemType.TOTAL_DISCOUNT
    )
    .reduce((count, item) => count + Number(item.amount), 0);

  return quoteAmountWithTax;
};

const getDeliveryFee = (cart: theplant.ec.service.orders.IOrder) => {
  const deliverFee = ((cart.sumUp && cart.sumUp.subItems) || [])
    .filter(
      item =>
        item.subType === theplant.ec.service.accounting.SubItemType.DELIVERY_FEE
    )
    .reduce((count, item) => count + Number(item.amount), 0);

  return deliverFee;
};

const getExpectPoint = (cart: theplant.ec.service.orders.IOrder) => {
  let quoteAmountWithTax = getActualValue(cart);

  const pointFees = ((cart.sumUp && cart.sumUp.subItems) || [])
    .filter(
      item =>
        item.subType ===
        theplant.ec.service.accounting.SubItemType.POINTS_DEDUCTION
      // || item.subType === theplant.ec.service.accounting.SubItemType.POINTS_TAX_DEDUCTION
    )
    .reduce((count, item) => count + Number(item.amount), 0);

  const expectPoint =
    quoteAmountWithTax > Math.abs(pointFees)
      ? quoteAmountWithTax + pointFees
      : 0;

  return Math.round(expectPoint / (1 + tax) / 100);
};

const getAmountToFreeDelivery = (cart: theplant.ec.service.orders.IOrder) => {
  const FREE_SHIPPING_FEE = 11000;

  let quoteAmountWithTax = getActualValue(cart);
  let deliveryFee = getDeliveryFee(cart);
  let amountToFreeDelivery = 0;
  if (deliveryFee === 0) {
    amountToFreeDelivery = 0;
    return amountToFreeDelivery;
  }

  if (quoteAmountWithTax < FREE_SHIPPING_FEE) {
    amountToFreeDelivery = FREE_SHIPPING_FEE - quoteAmountWithTax;
  }
  return amountToFreeDelivery;
};
export { getExpectPoint, getAmountToFreeDelivery };
