import { Context } from "@theplant/ecjs/context";
import { browserContext } from "@theplant/ecjs/context/browser";

import { env } from "./config";

import { alert } from "./js/shared/alert";

const context: Context = {
  ...browserContext("browser", env),
  onError: error => {
    if (error.type !== "validation-error") {
      const errorMsg = errorMessages[error.type];
      alert(errorMsg);
    }
    return error;
  }
};

const errorMessages: { [key: string]: string } = {
  "network-error":
    "接続できませんでした。ご利用のネットワークをご確認の上、再度お試しください。",
  "http-error": "エラーが発生しました。しばらく経ってから再度お試しください。",
  "authentication-error": "ログインしてください。"
};

export { context, errorMessages };
