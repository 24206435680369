import * as types from "../constants/ActionTypes";

const refresh = () => ({ type: types.CART_ITEMS_WILL_REFRESH });

const refreshSuccess = data => ({ type: types.CART_ITEMS_DID_REFRESH, data });

const refreshFailure = error => ({
  type: types.CART_ITEMS_DID_REFRESH_FAILED,
  error
});

const refreshQuantity = () => ({
  type: types.CART_ITEMS_TOTAL_QUANTITY_WILL_REFRESH
});

const refreshQuantitySuccess = data => ({
  type: types.CART_ITEMS_TOTAL_QUANTITY_DID_REFRESH,
  data
});

const refreshQuantityFailure = error => ({
  type: types.CART_ITEMS_TOTAL_QUANTITY_DID_REFRESH_FAILED,
  error
});

export {
  refresh,
  refreshSuccess,
  refreshFailure,
  refreshQuantity,
  refreshQuantitySuccess,
  refreshQuantityFailure
};
