import * as React from "react";

import { makeService } from "@theplant/ecjs/prottp";
import { theplant } from "../../proto";
import { withContext, ContextProps } from "@theplant/ecjs/context";

import { withConfig, ConfigProps } from "../../config";
import {
  AMAZON_BUTTON_ID,
  downloadAmazonScriptAndRunCallback,
  getAmazonPayloadUrl,
  renderAmazonButton,
} from "../AmazonPay/helper";
const CheckoutService = theplant.ec.api.orders.CheckoutService;

const AmazonPayButton = (
  props: ConfigProps &
    ContextProps & {
      onClickAmazonButton?: () => void;
    }
) => {
  const showButton = React.useCallback(() => {
    const btnContainer = document.querySelector(`#${AMAZON_BUTTON_ID}`);

    // chrome and other browser: btnContainer.shadowRoot is null
    // ie11: btnContainer.shadowRoot is undefined
    if (!window.amazon || !btnContainer || !!btnContainer.shadowRoot) {
      return;
    }

    const {
      AMAZON_PAY_MERCHANT_ID,
      AMAZON_PAY_ACCESSKEY,
      AMAZON_PAY_CLIENT_ID,
      AMAZON_PAY_IS_SANDBOX,
    } = props.config;

    const { checkoutReviewReturnUrl } = getAmazonPayloadUrl();

    const payload = {
      webCheckoutDetails: {
        checkoutReviewReturnUrl,
      },
      storeId: AMAZON_PAY_CLIENT_ID,
    };

    if (btnContainer && !btnContainer.shadowRoot) {
      makeService(CheckoutService, props.requestContext)
        .signature(payload)
        .then(({ signature }) => {
          if (signature) {
            if (btnContainer && !btnContainer.shadowRoot) {
              renderAmazonButton({
                merchantId: AMAZON_PAY_MERCHANT_ID,
                payloadJSON: JSON.stringify(payload),
                signature,
                publicKeyId: AMAZON_PAY_ACCESSKEY,
                sandbox: AMAZON_PAY_IS_SANDBOX === "true",
              });

              const shadowRoot = btnContainer.shadowRoot;
              const timer = setInterval(() => {
                if (shadowRoot) {
                  const backgroundColor = "#000";
                  const height = "44px";
                  const maxWidth = "302px";
                  const padding = "10px";

                  const view3 = (shadowRoot as any).querySelector(
                    ".amazonpay-button-view3"
                  );
                  if (view3) {
                    view3.setAttribute("style", "display:none");
                    clearInterval(timer);
                  }

                  const logo = (shadowRoot as any).querySelector(
                    ".amazonpay-button-sandbox-logo"
                  );
                  if (logo) {
                    logo.setAttribute("style", "display:none");
                  }

                  const chevrons = (shadowRoot as any).querySelector(
                    ".amazonpay-button-chevrons"
                  );
                  if (chevrons) {
                    chevrons.setAttribute("style", "display:none");
                  }

                  const container = (shadowRoot as any).querySelector(
                    ".amazonpay-button-container"
                  );
                  if (container) {
                    container.setAttribute("style", `min-height: ${height};`);
                  }

                  const view1 = (shadowRoot as any).querySelector(
                    ".amazonpay-button-view1"
                  );
                  if (view1) {
                    view1.setAttribute(
                      "style",
                      `
                      height: ${height};
                      padding: ${padding};
                      background: ${backgroundColor};
                      max-width: ${maxWidth};
                      margin-left: auto;
                      margin-right: auto;
                      border: none;
                      border-radius:0;
                     `
                    );
                  }
                }
              }, 100);
            }
          }
        })
        .catch((e) => {
          props.requestContext.errorNotifier.notify({
            error: e,
          });
        });
    }
  }, [props.config, props.requestContext]);

  React.useEffect(() => {
    downloadAmazonScriptAndRunCallback({
      url: props.config.AMAZON_PAY_SCRIPT,
      callback: showButton,
    });
  }, [props.config.AMAZON_PAY_SCRIPT, showButton]);

  return <div id={AMAZON_BUTTON_ID} onClick={props.onClickAmazonButton} />;
};

const withConfigAmazonPayButton = withConfig<{
  onClickAmazonButton?: () => void;
}>(withContext(AmazonPayButton));

export { withConfigAmazonPayButton as AmazonPayButton };
