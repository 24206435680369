import { put, call, takeLatest } from "redux-saga/effects";

import * as actions from "../actions/profile";
import * as types from "../constants/ActionTypes";
import Api from "../Api";

function* refreshProfile(a) {
  try {
    const data = yield call(Api.getProfile);
    yield put(actions.refreshSuccess(data));
  } catch (error) {
    yield put(actions.refreshFailure(error));
  }
}

function* didRefreshProfile(a) {
  yield put(actions.refreshSuccess(a.data));
}

function* watchRefreshProfile() {
  yield takeLatest(types.PROFILE_WILL_REFRESH, refreshProfile);
}

export default watchRefreshProfile;

export { refreshProfile, didRefreshProfile };
