import * as React from "react";
import { CheckboxInput } from "../shared/CheckboxInput";
import cx from "classnames";

import { NameAndAddress } from "./NameAndAddress";
import { Errors } from "../shared/Errors";

import { checkoutForm } from "../../orders";
import { Form } from "../../form";

import { theplant } from "../../proto";
import AddressDetail from "../shared/AddressDetail";
type IShippingAddress = theplant.ec.service.users.IShippingAddress;
type IAddress = theplant.ec.service.users.IAddress;

const input = Form.on(checkoutForm, "form");
const shippingAddressIdForm = Form.on(input, "shippingAddressId");
const billingAddressIdForm = Form.on(input, "billingAddressId");

const AddressSelector = (props: {
  addresses: IShippingAddress[];
  form: Form<IAddress, typeof input.actions>;
  addressId?: string | null;
  selectAddress: (
    prefix: "shippingAddress" | "billingAddress",
    id: string
  ) => void;
  isShowAddressForm: boolean;
  onChangeShowAddressForm: (
    type: "shipping" | "billing",
    show: boolean
  ) => void;
  newShippingAddress?: boolean;
  newBillingAddress?: boolean;
  toSetNewAddress: (
    prefix: "shippingAddress" | "billingAddress",
    newAddress: boolean
  ) => void;
}) => {
  const {
    addresses,
    addressId,
    form,
    selectAddress,
    isShowAddressForm,
    onChangeShowAddressForm,
    toSetNewAddress,
    newShippingAddress,
    newBillingAddress,
  } = props;

  // form.path = ["form", "shippingAddress" || "billingAddress"]
  const addressType = form.path[1];

  const isShippingAddress = addressType === "shippingAddress";
  const type = isShippingAddress ? "shipping" : "billing";
  const previousSelectedId = React.useRef<string | null>(addressId || "");

  const prefix = isShippingAddress ? "shippingAddress" : "billingAddress";

  const newAddress = isShippingAddress ? newShippingAddress : newBillingAddress;

  return (
    <>
      <div className="grid-row">
        {addresses.map((a) => (
          <ExistingAddress
            key={addressType + "-" + a.id}
            address={a}
            addressId={addressId}
            selectAddress={(id) => {
              onChangeShowAddressForm(type, false);
              selectAddress(prefix, id);
              toSetNewAddress(prefix, false);
            }}
            onClickShowAddressForm={() => onChangeShowAddressForm(type, true)}
            prefix={prefix}
          />
        ))}
      </div>
      {addresses.map(
        (a) =>
          a.id === addressId &&
          isShowAddressForm &&
          form && <NameAndAddress form={form} />
      )}

      <CheckboxInput
        checked={newAddress}
        className="mt-15"
        onChange={() => {
          onChangeShowAddressForm(type, false);

          toSetNewAddress(prefix, !newAddress);

          if (!newAddress) {
            if (addressId) {
              previousSelectedId.current = addressId;
            }
            selectAddress(prefix, "");
          } else {
            if (previousSelectedId.current) {
              selectAddress(prefix, previousSelectedId.current || "");
            }
          }
        }}
      >
        {isShippingAddress ? "新しい配送先住所に送る" : "新しい住所を追加"}
      </CheckboxInput>
      {newAddress ? <NameAndAddress form={form} /> : null}
    </>
  );
};

const EditButton = ({
  errors,
  onClick,
}: {
  errors: any;
  onClick: () => void;
}) =>
  errors && errors.length > 0 ? (
    <button
      type="button"
      className="outline-btn edit-address small"
      onClick={onClick}
    >
      編集
    </button>
  ) : null;

const ExistingAddress = ({
  address: { id, address },
  addressId,
  selectAddress,
  prefix,
  onClickShowAddressForm,
}: {
  address: IShippingAddress;
  addressId?: string | null;
  selectAddress: (id: string) => void;
  prefix: "shippingAddress" | "billingAddress";
  onClickShowAddressForm: () => void;
}) => {
  if (!address) {
    return null;
  }

  const isSelected = addressId === id;

  return (
    <div>
      <div
        className={cx("existing-address", { active: isSelected })}
        onClick={() => {
          if (isSelected) {
            return;
          }
          selectAddress(id || "");
        }}
      >
        <AddressDetail address={address} noDetailName />
        <div className="selected-icon">
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.79508 10.8749L1.62508 6.70492L0.205078 8.11492L5.79508 13.7049L17.7951 1.70492L16.3851 0.294922L5.79508 10.8749Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      {isSelected ? (
        prefix === "shippingAddress" ? (
          <shippingAddressIdForm.field
            component={({ errors }) => (
              <>
                <Errors errors={errors} />
                <EditButton errors={errors} onClick={onClickShowAddressForm} />
              </>
            )}
          />
        ) : (
          <billingAddressIdForm.field
            component={({ errors }) => (
              <>
                <Errors errors={errors} />
                <EditButton errors={errors} onClick={onClickShowAddressForm} />
              </>
            )}
          />
        )
      ) : null}
    </div>
  );
};

export { AddressSelector };
